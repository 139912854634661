/* eslint-disable default-param-last */
/*
 *
 * Tickets reducer
 *
 */

import { fromJS } from 'immutable';

import {
    SET_TICKET_ID, SET_TICKET_INFO, ADD_NEW_TICKET, SET_ACTIVE_TICKET, ERR_TICKET_INFO,
    SET_REGISTERED_ID_FLOW, SET_USER_ID, SET_USER_INFO, SET_USER_NOT_REGISTERED,
    SUBMIT_TICKET_ID, SUBMIT_USER_ID, GET_INITIAL_AUTH_FACTOR, SET_INITIAL_AUTH_INFO,
    SET_AUTH_FACTOR_VALUE, VERIFY_AUTH_FACTOR, SET_AUTH_INFO_AFTER_VERIFICATION,
    SET_ASYNC_AUTH_INFO, ASYNC_AUTH_FACTOR_PENDING, ERR_AUTH_INFO,
    SENSITIVE_SCREENS, CALLBACK_USER, SET_CALLBACK_USER,
    ERR_CALLBACK_USER, SET_CALLBACK_USER_FLOW, SET_AUTH_EXPIRED_SCREEN, LESS_SENSITIVE_SCREENS,
    SET_ACTION_AUTH_INFO, DISCARD_ACTION_AUTH_INFO, CLOSE_TICKET, ERR_INITIAL_AUTH_INFO,
    SET_AUTH_INFO_LOADER, GET_WAITLIST_USER, SET_WAITLIST_USER, ERR_WAITLIST_USER,
    GET_OTP_ATTEMPTS, SET_OTP_ATTEMPTS, ERR_OTP_ATTEMPTS, SET_ACCOUNT_FREEZE_INFO, ERR_ACCOUNT_FREEZE_INFO,
    GET_CALL_LIST, SET_CALL_LIST, ERR_CALL_LIST, SET_SELECTED_CALL_INFO, GET_TICKET_LIST_FOR_CALL,
    SET_TICKET_LIST_FOR_CALL, ERR_TICKET_LIST_FOR_CALL, UPDATE_CALL_INFO,
    SET_UPDATED_CALL_INFO, ERR_UPDATED_CALL_INFO, CREATE_TICKET_FOR_CALL,
    SET_CREATED_TICKET_FOR_CALL, ERR_CREATED_TICKET_FOR_CALL,
    GET_TICKET_AND_USER_INFO_FOR_CALL, SET_TICKET_AND_USER_INFO_FOR_CALL,
    ERR_TICKET_AND_USER_INFO_FOR_CALL, GET_USER_INFO_FOR_CALL, SET_USER_INFO_FOR_CALL,
    ERR_USER_INFO_FOR_CALL, USER_CONFIRMATION_FOR_CALL, SET_USER_CONFIRMATION_FOR_CALL,
    ERR_USER_CONFIRMATION_FOR_CALL, SET_AGENT_FEEDBACK, ERR_AGENT_FEEDBACK, MODERATELY_SENSITIVE_SCREENS,
    INSENSITIVE_SCREENS, SET_AUTH_UPGRADE_INFO, SET_CUSTOMER_AUTH, SHERLOCK_SCRIPTS_KEY, GET_SHERLOCK_SCRIPTS_SEARCH_RESULTS,
    SET_SHERLOCK_SCRIPTS_SEARCH_RESULTS, ERR_SHERLOCK_SCRIPTS_SEARCH_RESULTS,
} from './constants';
import { getDifferentAuthFactorList, getModifiedDataForAuthFactors } from './utils';

const initialTicketState = {
    ticketId: '',
    userId: '', // email id or phone number
    isActive: true,
    hasBasicInfo: false,
    basicInfo: {},
    errLabel: '',
    isCallbackUserFlow: true,
    callbackInfo: {},
    isRegisteredPhoneNumber: false,
    isRegisteredEmailId: false,
    isUserNotRegistered: false,
    hasUserInfo: false,
    userInfo: {
        phoneNumber: '',
        email: '',
        name: '',
    },
    hasAuthInfo: false,
    authInfo: {},
    hasKycInfo: false,
    kycInfo: {},
    hasOnboardingInfo: false,
    onboardingInfo: {},
    loading: {
        basicInfo: false,
        userInfo: false,
        authInfo: false,
        kycInfo: false,
        onboardingInfo: false,
        accountInfo: false,
        callbackUser: false,
        waitlistUser: false,
    },
    err: {
        kycInfo: '',
        onboardingInfo: '',
    },
    isAuthorizedForLessSensitiveScreen: false,
    isAuthorizedForModeratelySensitiveScreen: false,
    isAuthorizedForSensitiveScreen: false,
    isAuthExpiredScreen: false,
    isAuthorizedForInsensitiveScreen: false,
    hasAccountInfo: false,
    isAuthUpgradeInProgress: false,
    accountInfo: {},
    pendingAuth: {},
    waitlistUserInfo: {},
    accountFreezeInfo: {},
    customerAuth: {
        isActive: false,
        sensitivityLevel: null,
        authPathname: '',
    },
};

const initialCallState = {
    callList: {},
    ticketListForCall: {},
    selectedCallInfo: {},
    updatedCallInfo: {},
    createdTicketInfoForCall: {},
};

const initialState = fromJS({
    loading: {
        basicInfo: false,
        userInfo: false,
        authInfo: false,
        callListView: false,
        ticketListForCallView: false,
        createNewTicketView: false,
    },
    ticketList: [initialTicketState],
    ...initialCallState,
    [SHERLOCK_SCRIPTS_KEY]: {
        searchResults: {},
    },
});

function ticketsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CALL_LIST: {
            return state
                .setIn(['loading', 'callListView'], true)
                .set('createdTicketInfoForCall', fromJS({}))
                .setIn(['callList', 'err'], '');
        }

        case SET_CALL_LIST: {
            const { callList } = action.data;
            const { data, prev, next } = callList;

            return state
                .setIn(['loading', 'callListView'], false)
                .setIn(['callList', 'data'], fromJS(data))
                .setIn(['callList', 'prev'], fromJS(prev))
                .setIn(['callList', 'next'], fromJS(next));
        }

        case ERR_CALL_LIST: {
            const { err } = action.data;

            return state
                .setIn(['loading', 'callListView'], false)
                .setIn(['callList', 'data'], fromJS([]))
                .setIn(['callList', 'prev'], fromJS({}))
                .setIn(['callList', 'next'], fromJS({}))
                .setIn(['callList', 'err'], err);
        }

        case SET_SELECTED_CALL_INFO: {
            // data consists of ucid & callerUserId
            return state.set('selectedCallInfo', fromJS(action.data));
        }

        case GET_TICKET_AND_USER_INFO_FOR_CALL:
        case GET_USER_INFO_FOR_CALL:
        case USER_CONFIRMATION_FOR_CALL:
            return state
                .setIn(['loading', 'callListView'], true);

        case SET_TICKET_AND_USER_INFO_FOR_CALL:
        case SET_USER_INFO_FOR_CALL:
        case SET_USER_CONFIRMATION_FOR_CALL:
            return state
                .setIn(['loading', 'callListView'], false);

        case ERR_TICKET_AND_USER_INFO_FOR_CALL:
        case ERR_USER_INFO_FOR_CALL:
        case ERR_USER_CONFIRMATION_FOR_CALL:
            return state
                .setIn(['loading', 'callListView'], false);

        case GET_TICKET_LIST_FOR_CALL:
            return state
                .setIn(['loading', 'ticketListForCallView'], true);

        case SET_TICKET_LIST_FOR_CALL: {
            const { ticketList } = action.data;
            const { data, prev, next } = ticketList;

            return state
                .setIn(['loading', 'ticketListForCallView'], false)
                .setIn(['ticketListForCall', 'data'], fromJS(data))
                .setIn(['ticketListForCall', 'prev'], fromJS(prev))
                .setIn(['ticketListForCall', 'next'], fromJS(next))
                .setIn(['ticketListForCall', 'err'], '');
        }

        case ERR_TICKET_LIST_FOR_CALL: {
            const { err } = action.data;

            return state
                .setIn(['loading', 'ticketListForCallView'], false)
                .setIn(['ticketListForCall', 'data'], fromJS([]))
                .setIn(['ticketListForCall', 'prev'], fromJS({}))
                .setIn(['ticketListForCall', 'next'], fromJS({}))
                .setIn(['ticketListForCall', 'err'], err);
        }

        case UPDATE_CALL_INFO: {
            return state
                .setIn(['loading', 'ticketListForCallView'], true);
        }

        case SET_UPDATED_CALL_INFO:
        case ERR_UPDATED_CALL_INFO: {
            const { updatedCallInfo = {}, isErrFlow } = action.data;
            const { errMsgList } = updatedCallInfo;

            return state
                .setIn(['loading', 'ticketListForCallView'], false)
                .set('updatedCallInfo', fromJS({ errMsgList, isErrFlow }));
        }

        case CREATE_TICKET_FOR_CALL: {
            return state
                .setIn(['loading', 'createNewTicketView'], true)
                .set('createdTicketInfoForCall', fromJS({ hasErrMsg: false }));
        }

        case SET_CREATED_TICKET_FOR_CALL:
        case ERR_CREATED_TICKET_FOR_CALL: {
            const { createdTicketInfoForCall = {} } = action.data;

            return state
                .setIn(['loading', 'createNewTicketView'], false)
                .set('createdTicketInfoForCall', fromJS(createdTicketInfoForCall));
        }

        case SET_TICKET_ID: {
            const { ticketId, index } = action.data;

            if (ticketId === '') { // reset ticket id
                return state.setIn(['ticketList', index], fromJS(initialTicketState));
            }

            return state.setIn(['ticketList', index, 'ticketId'], ticketId);
        }

        case SUBMIT_TICKET_ID: {
            const { ticketId, index, isCallFlow } = action.data;

            return state
                .setIn(['loading', 'basicInfo'], true)
                .setIn(['ticketList', index, 'ticketId'], ticketId)
                .setIn(['ticketList', index, 'isCallFlow'], isCallFlow);
        }

        case CLOSE_TICKET: {
            const { index } = action.data;
            const ticketList = state.get('ticketList').toJS();
            const modifiedTicketList = ticketList.filter((listItem, idx) => idx !== index);

            return state.set('ticketList', fromJS(modifiedTicketList));
        }

        case SET_REGISTERED_ID_FLOW: {
            const { index, type } = action.data;

            if (type === 'phoneNumber') {
                return state.setIn(['ticketList', index, 'isRegisteredPhoneNumber'], true);
            }

            if (type === 'emailId') {
                return state
                    .setIn(['ticketList', index, 'errLabel'], '')
                    .setIn(['ticketList', index, 'hasUserInfo'], false)
                    .setIn(['ticketList', index, 'userInfo'], fromJS({}))
                    .setIn(['ticketList', index, 'isRegisteredPhoneNumber'], false)
                    .setIn(['ticketList', index, 'isRegisteredEmailId'], true)
                    .setIn(['ticketList', index, 'userId'], '');
            }

            return state
                .setIn(['ticketList', index, 'errLabel'], '')
                .setIn(['ticketList', index, 'hasUserInfo'], false)
                .setIn(['ticketList', index, 'userInfo'], fromJS({}))
                .setIn(['ticketList', index, 'isRegisteredPhoneNumber'], false)
                .setIn(['ticketList', index, 'isRegisteredEmailId'], false)
                .setIn(['ticketList', index, 'userId'], '')
                .setIn(['ticketList', index, 'hasWaitlistUserInfo'], false)
                .setIn(['ticketList', index, 'waitlistUserInfo'], fromJS({}));
        }

        case SET_USER_ID: {
            const { userId, index } = action.data;

            return state.setIn(['ticketList', index, 'userId'], userId);
        }

        case SUBMIT_USER_ID:
            return state.setIn(['loading', 'userInfo'], true);

        case ERR_TICKET_INFO: {
            const { type, index, err } = action.data;
            let errLabel = '';

            if (type === 'ticket') {
                return state
                    .setIn(['loading', 'basicInfo'], false)
                    .setIn(['ticketList', index, 'ticketId'], '')
                    .setIn(['ticketList', index, 'errLabel'], err);
            }

            if (type === 'user') {
                errLabel = 'No user found!';

                return state
                    .setIn(['loading', 'userInfo'], false)
                    .setIn(['ticketList', index, 'userId'], '')
                    .setIn(['ticketList', index, 'errLabel'], errLabel);
            }

            return state;
        }

        case SET_TICKET_INFO: {
            const {
                index, source, email, name, phoneNumber, isUserExistInEpifi, callbackUser, isUserExistInWL,
                ticketPhoneNumber, shouldFetchAuthInfo, isChatFlow, shouldFetchAuthInfoOnCallbackFlow,
            } = action.data;

            const info = {
                source,
                phoneNumber,
                name,
                email,
                isUserExistInEpifi,
                callbackUser,
                ticketPhoneNumber,
                isChatFlow,
                isUserExistInWL,
                isUserExistInSystem: isUserExistInEpifi || isUserExistInWL,
            };

            return state
                .setIn(['loading', 'basicInfo'], false)
                .setIn(['loading', 'authInfo'], shouldFetchAuthInfo)
                .set('createdTicketInfoForCall', fromJS({})) // need to reset the call list flow
                .setIn(['ticketList', index, 'errLabel'], '')
                .setIn(['ticketList', index, 'skipUserConfirmation'], shouldFetchAuthInfo)
                .setIn(['ticketList', index, 'shouldFetchAuthInfoOnCallbackFlow'], shouldFetchAuthInfoOnCallbackFlow)
                .setIn(['ticketList', index, 'hasBasicInfo'], true)
                .setIn(['ticketList', index, 'basicInfo'], fromJS(info));
        }

        case SET_USER_INFO: {
            const {
                email, name, phoneNumber, index,
            } = action.data;

            const info = {
                phoneNumber,
                name,
                email,
            };

            return state
                .setIn(['loading', 'userInfo'], false)
                .setIn(['ticketList', index, 'errLabel'], '')
                .setIn(['ticketList', index, 'hasUserInfo'], true)
                .setIn(['ticketList', index, 'userInfo'], fromJS(info));
        }

        case SET_USER_NOT_REGISTERED:
            return state.setIn(['ticketList', action.data.index, 'isUserNotRegistered'], true);

        case GET_INITIAL_AUTH_FACTOR: {
            const { index, userId } = action.data;

            return state
                .setIn(['loading', 'authInfo'], true)
                .setIn(['ticketList', index, 'hasConfirmedUserInfo'], !!userId);
        }

        case SET_INITIAL_AUTH_INFO: {
            const { screen, screenOptions, index } = action.data;
            const ticket = state.getIn(['ticketList', index]).toJS();

            let {
                isAuthorizedForSensitiveScreen, isAuthorizedForLessSensitiveScreen, isAuthorizedForInsensitiveScreen,
                isAuthorizedForModeratelySensitiveScreen,
            } = ticket;

            const { isAuthUpgradeInProgress } = ticket;

            const lists = getModifiedDataForAuthFactors(screenOptions, ticket);

            const info = {
                screen,
                ...screenOptions,
                ...lists,
            };

            if (!isAuthUpgradeInProgress) {
                if (SENSITIVE_SCREENS.includes(screen)) {
                    isAuthorizedForInsensitiveScreen = true;
                    isAuthorizedForLessSensitiveScreen = true;
                    isAuthorizedForModeratelySensitiveScreen = true;
                    isAuthorizedForSensitiveScreen = true;
                } else if (MODERATELY_SENSITIVE_SCREENS.includes(screen)) {
                    isAuthorizedForInsensitiveScreen = true;
                    isAuthorizedForLessSensitiveScreen = true;
                    isAuthorizedForModeratelySensitiveScreen = true;
                    isAuthorizedForSensitiveScreen = false;
                } else if (LESS_SENSITIVE_SCREENS.includes(screen)) {
                    isAuthorizedForInsensitiveScreen = true;
                    isAuthorizedForLessSensitiveScreen = true;
                    isAuthorizedForModeratelySensitiveScreen = false;
                    isAuthorizedForSensitiveScreen = false;
                } else if (INSENSITIVE_SCREENS.includes(screen)) {
                    isAuthorizedForInsensitiveScreen = true;
                }
            }

            return state
                .setIn(['loading', 'authInfo'], false)
                .setIn(['ticketList', index, 'isAuthorizedForLessSensitiveScreen'], isAuthorizedForLessSensitiveScreen)
                .setIn(['ticketList', index, 'isAuthorizedForSensitiveScreen'], isAuthorizedForSensitiveScreen)
                .setIn(['ticketList', index, 'isAuthorizedForModeratelySensitiveScreen'], isAuthorizedForModeratelySensitiveScreen)
                .setIn(['ticketList', index, 'isAuthorizedForInsensitiveScreen'], isAuthorizedForInsensitiveScreen)
                .setIn(['ticketList', index, 'hasAuthInfo'], true)
                .setIn(['ticketList', index, 'authInfo'], fromJS(info));
        }

        case ERR_INITIAL_AUTH_INFO: {
            return state.setIn(['loading', 'authInfo'], false);
        }

        case SET_ACTION_AUTH_INFO: {
            const {
                screen, screenOptions, type, index,
            } = action.data;
            const ticket = state.getIn(['ticketList', index]).toJS();

            const lists = getModifiedDataForAuthFactors(screenOptions, ticket);

            const info = {
                screen,
                ...screenOptions,
                ...lists,
            };

            return state
                .setIn(['loading', 'authInfo'], false)
                .setIn(['ticketList', index, 'pendingAuth', type], true)
                .setIn(['ticketList', index, 'hasAuthInfo'], true)
                .setIn(['ticketList', index, 'authInfo'], fromJS(info));
        }

        case DISCARD_ACTION_AUTH_INFO: {
            const { type, index } = action.data;

            return state
                .setIn(['ticketList', index, 'pendingAuth', type], false)
                .setIn(['ticketList', index, 'hasAuthInfo'], false)
                .setIn(['ticketList', index, 'authInfo'], fromJS({}));
        }

        case ERR_AUTH_INFO: {
            const { index, description: errLabel, authFactor } = action.data;
            const ticket = state.getIn(['ticketList', index]).toJS();

            const { activeAuthFactorList } = ticket.authInfo;

            // add the err description to the current active auth factor
            const modifiedActiveAuthFactorList = activeAuthFactorList.map((listItem) => {
                if (authFactor.name === listItem.name) {
                    return {
                        ...listItem,
                        errLabel,
                    };
                }

                return listItem;
            });

            const info = {
                ...ticket.authInfo,
                activeAuthFactorList: modifiedActiveAuthFactorList,
            };

            return state
                .setIn(['loading', 'authInfo'], false)
                .setIn(['ticketList', index, 'authInfo'], fromJS(info));
        }

        case VERIFY_AUTH_FACTOR:
            return state.setIn(['loading', 'authInfo'], true);

        case SET_ASYNC_AUTH_INFO:
        case SET_AUTH_INFO_AFTER_VERIFICATION: {
            const {
                screen, screenOptions, authFactor, ticketId, type,
            } = action.data;
            let { index } = action.data;

            if (typeof index === 'undefined') {
                index = state.get('ticketList').toJS().findIndex((listItem) => listItem.ticketId.toString() === ticketId.toString());
            }

            const ticket = state.getIn(['ticketList', index]).toJS();

            const { authFactorList } = ticket.authInfo;

            let info = ticket.authInfo;

            if (authFactorList) {
                const modifiedAuthFactorList = authFactorList.map((listItem) => {
                    if (authFactor.name === listItem.name) {
                        return {
                            ...listItem,
                            ...authFactor,
                        };
                    }

                    return listItem;
                });

                const lists = getDifferentAuthFactorList(modifiedAuthFactorList);

                info = {
                    screen,
                    ...screenOptions,
                    authFactorList: modifiedAuthFactorList,
                    ...lists,
                };
            }

            if (screen === 1) { // auth screen
                let isPendingAuthInfo = false;

                // keep loader active for async auth factor incase of verification pending
                if (ASYNC_AUTH_FACTOR_PENDING.includes(authFactor.status)) {
                    isPendingAuthInfo = true;
                }

                return state
                    .setIn(['loading', 'authInfo'], isPendingAuthInfo)
                    .setIn(['ticketList', index, 'enableCheckStatusCta'], false)
                    .setIn(['ticketList', index, 'isAuthorizedForLessSensitiveScreen'], false)
                    // .setIn(['ticketList', index, 'isAuthorizedForModeratelySensitiveScreen'], false)
                    // .setIn(['ticketList', index, 'isAuthorizedForInsensitiveScreen'], false)
                    .setIn(['ticketList', index, 'isAuthorizedForSensitiveScreen'], false)
                    .setIn(['ticketList', index, 'hasAuthInfo'], true)
                    .setIn(['ticketList', index, 'authInfo'], fromJS(info));
            }

            if (screen === 2) { // landing page screen

            }

            if (screen === 7) { // auth error screen

            }

            if (screen === 8) { // confirm action screen

            }

            let isAuthorizedForLessSensitiveScreen = false;
            let isAuthorizedForSensitiveScreen = false;
            let isAuthorizedForInsensitiveScreen = false;
            let isAuthorizedForModeratelySensitiveScreen = false;

            if (SENSITIVE_SCREENS.includes(screen)) {
                isAuthorizedForInsensitiveScreen = true;
                isAuthorizedForLessSensitiveScreen = true;
                isAuthorizedForModeratelySensitiveScreen = true;
                isAuthorizedForSensitiveScreen = true;
            } else if (MODERATELY_SENSITIVE_SCREENS.includes(screen)) {
                isAuthorizedForInsensitiveScreen = true;
                isAuthorizedForLessSensitiveScreen = true;
                isAuthorizedForModeratelySensitiveScreen = true;
                isAuthorizedForSensitiveScreen = false;
            } else if (LESS_SENSITIVE_SCREENS.includes(screen)) {
                isAuthorizedForInsensitiveScreen = true;
                isAuthorizedForLessSensitiveScreen = true;
                isAuthorizedForModeratelySensitiveScreen = false;
                isAuthorizedForSensitiveScreen = false;
            } else if (INSENSITIVE_SCREENS.includes(screen)) {
                isAuthorizedForInsensitiveScreen = true;
            }

            // sensitive screen
            return state
                .setIn(['loading', 'authInfo'], false)
                .setIn(['ticketList', index, 'enableCheckStatusCta'], false)
                .setIn(['ticketList', index, 'isAuthorizedForLessSensitiveScreen'], isAuthorizedForLessSensitiveScreen)
                .setIn(['ticketList', index, 'isAuthorizedForModeratelySensitiveScreen'], isAuthorizedForModeratelySensitiveScreen)
                .setIn(['ticketList', index, 'isAuthorizedForSensitiveScreen'], isAuthorizedForSensitiveScreen)
                .setIn(['ticketList', index, 'isAuthorizedForInsensitiveScreen'], isAuthorizedForInsensitiveScreen)
                .setIn(['ticketList', index, 'isAuthUpgradeInProgress'], false)
                .setIn(['ticketList', index, 'hasAuthInfo'], !type)
                .setIn(['ticketList', index, 'authInfo'], fromJS(info));
        }

        // toggle the loader and enable the check status cta for async auth factors
        case SET_AUTH_INFO_LOADER: {
            const { index, flag } = action.data;

            return state
                .setIn(['loading', 'authInfo'], flag)
                .setIn(['ticketList', index, 'enableCheckStatusCta'], !flag);
        }

        case SET_AUTH_FACTOR_VALUE: {
            const {
                authFactor, index, value, key,
            } = action.data;
            const ticket = state.getIn(['ticketList', index]).toJS();
            const { authFactorList } = ticket.authInfo;

            const modifiedAuthFactorList = authFactorList.map((listItem) => {
                if (authFactor.name === listItem.name) {
                    if (key) {
                        return {
                            ...listItem,
                            formValue: {
                                ...listItem.formValue,
                                [key]: value,
                            },
                        };
                    }

                    return {
                        ...listItem,
                        formValue: value,
                    };
                }

                return listItem;
            });
            const { activeAuthFactorList } = getDifferentAuthFactorList(modifiedAuthFactorList, false, true, false);

            return state
                .setIn(['ticketList', index, 'authInfo', 'authFactorList'], fromJS(modifiedAuthFactorList))
                .setIn(['ticketList', index, 'authInfo', 'activeAuthFactorList'], fromJS(activeAuthFactorList));
        }

        case SET_AUTH_EXPIRED_SCREEN: {
            const { flag, index } = action.data;

            if (flag === true) {
                return state.setIn(['ticketList', index, 'isAuthExpiredScreen'], true);
            }

            return state
                .setIn(['loading', 'authInfo'], true)
                .setIn(['ticketList', index, 'isAuthExpiredScreen'], false)
                .setIn(['ticketList', index, 'isAuthorizedForModeratelySensitiveScreen'], false)
                .setIn(['ticketList', index, 'isAuthorizedForInsensitiveScreen'], false)
                .setIn(['ticketList', index, 'isAuthorizedForLessSensitiveScreen'], false)
                .setIn(['ticketList', index, 'isAuthorizedForSensitiveScreen'], false)
                .setIn(['ticketList', index, 'isAuthorize'], false)
                .setIn(['ticketList', index, 'hasAuthInfo'], false)
                .setIn(['ticketList', index, 'authInfo'], fromJS({}));
        }

        case ADD_NEW_TICKET: {
            const ticketList = state.get('ticketList').toJS();

            const modifiedTicketList = ticketList.map((item) => ({
                ...item,
                isActive: false,
            }));

            modifiedTicketList.push(initialTicketState);

            return state.set('ticketList', fromJS(modifiedTicketList));
        }

        case SET_ACTIVE_TICKET: {
            const ticketList = state.get('ticketList').toJS();

            const modifiedTicketList = ticketList.map((item, index) => {
                let isActive = false;

                if (index === action.data.index) {
                    isActive = true;
                }

                return {
                    ...item,
                    isActive,
                };
            });

            return state.set('ticketList', fromJS(modifiedTicketList));
        }

        case CALLBACK_USER: {
            const { index } = action.data;

            return state
                .setIn(['ticketList', index, 'loading', 'callbackUser'], true);
        }

        case SET_CALLBACK_USER: {
            const { index, status } = action.data;

            return state
                .setIn(['ticketList', index, 'loading', 'callbackUser'], false)
                .setIn(['ticketList', index, 'callbackInfo'], fromJS({ success: true, status }));
        }

        case ERR_CALLBACK_USER: {
            const { index } = action.data;

            return state
                .setIn(['ticketList', index, 'loading', 'callbackUser'], false)
                .setIn(['ticketList', index, 'callbackInfo'], fromJS({ success: false }));
        }

        case SET_CALLBACK_USER_FLOW: {
            const { index, flag } = action.data;

            return state.setIn(['ticketList', index, 'isCallbackUserFlow'], flag);
        }

        case GET_WAITLIST_USER: {
            const { index } = action.data;

            return state
                .setIn(['ticketList', index, 'loading', 'waitlistUser'], true)
                .setIn(['ticketList', index, 'hasWaitlistUserInfo'], false);
        }

        case SET_WAITLIST_USER: {
            const { index, waitlistUserInfo, showWaitlistUserInfo } = action.data;

            return state
                .setIn(['ticketList', index, 'loading', 'waitlistUser'], false)
                .setIn(['ticketList', index, 'hasWaitlistUserInfo'], true)
                .setIn(['ticketList', index, 'waitlistUserInfo'], fromJS(waitlistUserInfo))
                .setIn(['ticketList', index, 'showWaitlistUserInfo'], showWaitlistUserInfo);
        }

        case ERR_WAITLIST_USER: {
            const { index } = action.data;

            return state
                .setIn(['ticketList', index, 'loading', 'waitlistUser'], false)
                .setIn(['ticketList', index, 'waitlistUserInfo'], fromJS({}));
        }

        case GET_OTP_ATTEMPTS: {
            const { index } = action.data;

            return state
                .setIn(['ticketList', index, 'loading', 'otpAttempts'], true);
        }

        case SET_OTP_ATTEMPTS: {
            const { index, otpAttemptList } = action.data;

            return state
                .setIn(['ticketList', index, 'loading', 'otpAttempts'], false)
                .setIn(['ticketList', index, 'hasOTPAttemptInfo'], true)
                .setIn(['ticketList', index, 'otpAttemptList'], fromJS(otpAttemptList));
        }

        case ERR_OTP_ATTEMPTS: {
            const { index } = action.data;

            return state
                .setIn(['ticketList', index, 'loading', 'otpAttempts'], false)
                .setIn(['ticketList', index, 'hasOTPAttemptInfo'], false)
                .setIn(['ticketList', index, 'otpAttemptList'], fromJS([]));
        }

        case SET_ACCOUNT_FREEZE_INFO: {
            const { index, accountFreezeInfo } = action.data;

            return state.setIn(['ticketList', index, 'accountFreezeInfo'], fromJS(accountFreezeInfo));
        }

        case ERR_ACCOUNT_FREEZE_INFO: {
            const { index } = action.data;

            return state.setIn(['ticketList', index, 'accountFreezeInfo'], fromJS({}));
        }

        case SET_AGENT_FEEDBACK: {
            const { sherlockFeedbackDetails: agentFeedback } = action.data;

            return state
                .setIn(['agentFeedback', 'agentFeedback'], agentFeedback);
        }

        case ERR_AGENT_FEEDBACK: {
            const { err } = action.data;

            return state
                .setIn(['agentFeedback', 'loading'], false)
                .setIn(['agentFeedback', 'err'], err);
        }

        case SET_AUTH_UPGRADE_INFO: {
            const { index, isAuthUpgradeInProgress } = action.data;

            return state
                .setIn(['ticketList', index, 'isAuthUpgradeInProgress'], isAuthUpgradeInProgress);
        }

        case SET_CUSTOMER_AUTH: {
            const {
                index, isActive, sensitivityLevel, authPathname,
            } = action.data;

            const data = { isActive, sensitivityLevel, authPathname };

            return state
                .setIn(['ticketList', index, 'customerAuth'], fromJS(data));
        }

        case GET_SHERLOCK_SCRIPTS_SEARCH_RESULTS: {
            const key = [SHERLOCK_SCRIPTS_KEY, 'searchResults'];
            const values = {
                loading: true,
                data: {},
                hasData: false,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case SET_SHERLOCK_SCRIPTS_SEARCH_RESULTS: {
            let hasData = true;
            if (!action.data) hasData = false;
            const key = [SHERLOCK_SCRIPTS_KEY, 'searchResults'];
            const values = {
                loading: false,
                data: fromJS(action.data),
                hasData,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case ERR_SHERLOCK_SCRIPTS_SEARCH_RESULTS: {
            const { err } = action.data;
            const key = [SHERLOCK_SCRIPTS_KEY, 'searchResults'];
            const values = {
                loading: false,
                data: {},
                hasData: false,
                err,
            };
            return state.mergeIn(key, values);
        }

        default:
            return state;
    }
}

export default ticketsReducer;

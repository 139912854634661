/**
 *
 * @file USSPortfolioDetails : Show views according to Portfolio Details or Activity Details.
 *
 */

import React, { memo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
    getPortfolioDetailsAction, setUsStocksViewAction,
} from '../actions';
import { VIEW_PORTFOLIO_DETAILS, VIEW_PORTFOLIO_DETAILS_LIST } from '../constants';
import { selectUSSPortfolioDetails, selectUsStocksView } from '../selectors';

import RenderView from './RenderView';

const USSPortfolioDetails = (props) => {
    const {
        ticketId, userId, index,
    } = props;

    const dispatch = useDispatch();

    const portfolioDetailList = useSelector(selectUSSPortfolioDetails, shallowEqual);
    const usStocksView = useSelector(selectUsStocksView, shallowEqual);

    useEffect(() => {
        if (!portfolioDetailList) {
            makePayloadAndCallApi();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioDetailList]);

    useEffect(() => {
        if (usStocksView !== VIEW_PORTFOLIO_DETAILS_LIST) {
            const view = VIEW_PORTFOLIO_DETAILS;
            dispatch(setUsStocksViewAction(view));
        }
    }, [dispatch, usStocksView]);

    const makePayloadAndCallApi = () => {
        const payload = {
            ticketId,
            userId,
            index,
        };

        dispatch(getPortfolioDetailsAction(payload));
    };

    return (
        <RenderView
            usStocksView={usStocksView}
            ticketId={ticketId}
            userId={userId}
            index={index}
            portfolioDetailList={portfolioDetailList}
        />
    );
};

export default memo(USSPortfolioDetails);

/**
 * URLStatus - Component to get the last instant indexing URL status by hitting Google Instant Indexing APIs
 */

import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, FormWithSingleInput, Loader, KVPairInfo,
} from '../../../components';

import { getUrlStatus } from '../actions';
import { getUrlStatusInfoList } from '../utils';
import { makeSelectLoading, makeSelectUrlStatusInfo } from '../selectors';

import './style.scss';

const URLStatus = () => {
    const dispatch = useDispatch();

    // reducer state variables
    const isLoading = useSelector(makeSelectLoading(), shallowEqual);
    const urlStatusInfo = useSelector(makeSelectUrlStatusInfo(), shallowEqual);

    // local state variables
    const [url, setUrl] = useState('');
    const [errLabel, setErrLabel] = useState('');

    const onInputChange = (key) => (e) => {
        const value = e.target.value;

        switch (key) {
            case 'url':
                setUrl(value);
                break;

            default:
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // check if all the fields are filled
        if (!url) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        setErrLabel('');

        const callGetUrlStatusAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(getUrlStatus({
                    url,
                    resolve,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callGetUrlStatusAction().then(() => {
            setUrl('');
        });
    };

    return (
        <React.Fragment>
            <div className='ii-url-status'>
                <FormWithSingleInput
                    disableFormSubmitOnEnter
                    label='Enter URL*'
                    labelId='ii-url-status'
                    formType='textarea'
                    input={{
                        value: url,
                        onChange: onInputChange('url'),
                        rows: 4,
                        cols: 50,
                    }}
                    extClasses={{
                        container: 'ii-url-status-fc',
                        label: 'ii-url-status-fc__lb',
                        inputField: 'ii-url-status-fc__ip',
                    }}
                />
                <div className='err-label ii-url-status-el'>{errLabel}</div>
                <Button
                    v2
                    primary
                    label='Submit'
                    onClick={handleSubmit}
                    extClasses='ii-url-status-cta'
                />
                {
                    urlStatusInfo.hasInfo ? (
                        <KVPairInfo
                            title='URL Status Info'
                            data={getUrlStatusInfoList(urlStatusInfo.data)}
                        />
                    ) : null
                }
            </div>
            <Loader visible={isLoading} />
        </React.Fragment>
    );
};

export default URLStatus;

/**
 * @file UserProducts selectors
 */

import { createSelector } from 'reselect';

import { USER_RISK_PRODUCTS_KEY } from './constants';

export const selectUserRiskProductList = createSelector(
    (state) => state.getIn([USER_RISK_PRODUCTS_KEY, 'userProductList']),
    (subState) => subState?.toJS() || [],
);

export const selectUserRiskProductListError = createSelector(
    (state) => state.getIn([USER_RISK_PRODUCTS_KEY, 'productListError']),
    (subState) => subState,
);

export const selectUserRiskProductInfoLoading = createSelector(
    (state) => state.getIn([USER_RISK_PRODUCTS_KEY, 'loading']),
    (subState) => subState,
);

export const selectUserRiskProductInfoError = createSelector(
    (state) => state.getIn([USER_RISK_PRODUCTS_KEY, 'productInfoError']),
    (subState) => subState,
);

export const selectUserRiskProductInfo = createSelector(
    (state) => state.getIn([USER_RISK_PRODUCTS_KEY, 'userProductInfo']),
    (subState) => subState?.toJS() || [],
);

export const selectProduct = createSelector(
    (state) => state.getIn([USER_RISK_PRODUCTS_KEY, 'product']),
    (subState) => subState?.toJS() || null,
);

/*
 *
 * LogsInfo reducer
 *
 */

import { fromJS } from 'immutable';
import {
    GET_LOGS, SET_LOGS, ERR_LOGS, GET_LOG_INFO, SET_LOG_INFO, ERR_LOG_INFO, SET_LOG_FILTER, ERR_SEARCH,
} from './constants';

const initialState = fromJS({
    loading: {
        logsList: false,
        logInfo: false,
    },
    err: {
        search: '',
        logsList: '',
        logInfo: '',
    },
    logsList: [],
    prev: {},
    next: {},
    logInfo: {},
    logFilter: {},
});

/* Added a default value for action as according to eslint defualt parameters should be at the last position and
 * reducer function should have a format of (state,action)
 * Ref: https://stackoverflow.com/questions/62259351/react-redux-error-default-parameters-should-be-last-default-param-last
 */
function logsInfoReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_LOGS:
            return state
                .setIn(['loading', 'logsList'], true)
                .setIn(['err', 'logsList'], '');

        case SET_LOGS: {
            const { logsList, prev, next } = action.data;

            return state
                .setIn(['loading', 'logsList'], false)
                .set('logsList', fromJS(logsList))
                .set('prev', fromJS(prev))
                .set('next', fromJS(next));
        }

        case ERR_SEARCH: {
            const { err } = action.data;
            return state.setIn(['err', 'search'], err);
        }

        case ERR_LOGS: {
            const { err } = action.data;

            return state
                .setIn(['loading', 'logsList'], false)
                .setIn(['err', 'logsList'], err)
                .set('logsList', fromJS([]))
                .set('prev', fromJS({}))
                .set('next', fromJS({}))
                .set('logInfo', fromJS({}));
        }

        case GET_LOG_INFO:
            return state
                .setIn(['loading', 'logInfo'], true)
                .setIn(['err', 'logInfo'], '')
                .set('logInfo', fromJS({}));

        case SET_LOG_INFO: {
            const { logInfo } = action.data;

            return state
                .setIn(['loading', 'logInfo'], false)
                .set('logInfo', fromJS(logInfo));
        }

        case ERR_LOG_INFO: {
            const { err } = action.data;

            return state
                .setIn(['loading', 'logInfo'], false)
                .setIn(['err', 'logInfo'], err)
                .set('logInfo', fromJS({}));
        }

        case SET_LOG_FILTER: {
            return state.set('logFilter', action.data);
        }

        default:
            return state;
    }
}

export default logsInfoReducer;

import { fromJS } from 'immutable';

import {
    ATTACH_ENTITY_TICKET, SET_ATTACH_ENTITY_TICKET, ERR_ATTACH_ENTITY_TICKET,
} from '../Tickets/constants';
import {
    GET_TXNS_INFO, SET_TXNS_INFO, ERR_TXNS_INFO, GET_CHANNELS_INFO, SET_CHANNELS_INFO,
    ERR_CHANNELS_INFO, GET_QUESTIONS_INFO, SET_QUESTIONS_INFO, ERR_QUESTIONS_INFO,
    MODIFY_QUESTIONS_INFO, INITIATE_RAISE_DISPUTE, SUCCESS_RAISE_DISPUTE,
    ERR_RAISE_DISPUTE, RESET_STATE, GET_DISPUTE_INFO, SET_DISPUTE_INFO, ERR_DISPUTE_INFO,
    GET_FIRST_OR_LAST_SET_TXN_LIST, SET_FIRST_OR_LAST_SET_TXN_LIST, ERR_FIRST_OR_LAST_SET_TXN_LIST,
    SET_SEARCH_PARAMS, RESET_SEARCH_PARAMS, SET_ORDER_STATUS_LIST, SET_PAYMENT_PROTOCOL_LIST,
} from './constants';

const initialState = fromJS({
    orderStatusList: [],
    paymentProtocolList: [],
    searchParameters: {},
    transactionsList: {},
    channelList: {},
    questionList: {},
    disputeInfo: {},
    selectedTxnDisputeInfo: {},
    firstOrLastSetTxnList: {},
});

function transactionsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ORDER_STATUS_LIST: {
            const { orderStatusList } = action.data;

            return state.set('orderStatusList', fromJS(orderStatusList));
        }

        case SET_PAYMENT_PROTOCOL_LIST: {
            const { paymentProtocolList } = action.data;

            return state.set('paymentProtocolList', fromJS(paymentProtocolList));
        }

        case SET_SEARCH_PARAMS: {
            const {
                ticketId, key, value, fromDate, toDate,
            } = action.data;

            // set key-value pair with selected value or clear selected value
            if (key && (value || value === '')) {
                return state.setIn(['searchParameters', ticketId, key], value);
            }

            if (fromDate && toDate) {
                return state
                    .setIn(['searchParameters', ticketId, 'fromDate'], fromJS(fromDate))
                    .setIn(['searchParameters', ticketId, 'toDate'], fromJS(toDate));
            }

            return state;
        }

        case RESET_SEARCH_PARAMS: {
            const { ticketId } = action.data;

            return state
                .setIn(['searchParameters', ticketId], fromJS({}))
                .setIn(['transactionsList', ticketId], fromJS({}));
        }

        case GET_TXNS_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['transactionsList', ticketId, 'loading'], true)
                .setIn(['transactionsList', ticketId, 'err'], '');
        }

        case SET_TXNS_INFO: {
            const {
                ticketId, transactionList, prev, next,
            } = action.data;

            return state
                .setIn(['transactionsList', ticketId, 'loading'], false)
                .setIn(['transactionsList', ticketId, 'data'], fromJS(transactionList))
                .setIn(['transactionsList', ticketId, 'prev'], fromJS(prev))
                .setIn(['transactionsList', ticketId, 'next'], fromJS(next));
        }

        case ERR_TXNS_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['transactionsList', ticketId, 'loading'], false)
                .setIn(['transactionsList', ticketId, 'err'], err)
                .setIn(['transactionsList', ticketId, 'data'], fromJS([]))
                .setIn(['transactionsList', ticketId, 'prev'], fromJS({}))
                .setIn(['transactionsList', ticketId, 'next'], fromJS({}));
        }

        case GET_FIRST_OR_LAST_SET_TXN_LIST: {
            const { ticketId } = action.data;

            return state
                .setIn(['firstOrLastSetTxnList', ticketId, 'loading'], true)
                .setIn(['firstOrLastSetTxnList', ticketId, 'err'], '');
        }

        case SET_FIRST_OR_LAST_SET_TXN_LIST: {
            const {
                ticketId, transactionList, firstSet, lastSet,
            } = action.data;

            return state
                .setIn(['firstOrLastSetTxnList', ticketId, 'loading'], false)
                .setIn(['firstOrLastSetTxnList', ticketId, 'data'], fromJS(transactionList))
                .setIn(['firstOrLastSetTxnList', ticketId, 'firstSet'], firstSet)
                .setIn(['firstOrLastSetTxnList', ticketId, 'lastSet'], lastSet);
        }

        case ERR_FIRST_OR_LAST_SET_TXN_LIST: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['firstOrLastSetTxnList', ticketId, 'loading'], false)
                .setIn(['firstOrLastSetTxnList', ticketId, 'err'], err)
                .setIn(['firstOrLastSetTxnList', ticketId, 'data'], fromJS([]));
        }

        case GET_CHANNELS_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['channelList', ticketId, 'loading'], true);
        }

        case SET_CHANNELS_INFO: {
            const { ticketId, channelList } = action.data;

            return state
                .setIn(['channelList', ticketId, 'loading'], false)
                .setIn(['channelList', ticketId, 'data'], fromJS(channelList));
        }

        case ERR_CHANNELS_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['channelList', ticketId, 'loading'], false)
                .setIn(['channelList', ticketId, 'data'], fromJS([]));
        }

        case GET_QUESTIONS_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['questionList', ticketId, 'loading'], true);
        }

        case SET_QUESTIONS_INFO: {
            const {
                ticketId, questionList, questionIndex, reset,
            } = action.data;

            let reqdQuestionsFilled = true;
            const modifiedQuestionList = questionList.map((item) => {
                if (!item.isOptional) {
                    reqdQuestionsFilled = false;
                }

                return {
                    ...item,
                    answer: '',
                    isDDOpen: false,
                };
            });

            if (reset) {
                return state
                    .setIn(['questionList', ticketId, 'loading'], false)
                    .setIn(['questionList', ticketId, 'data'], fromJS(modifiedQuestionList))
                    .setIn(['disputeInfo', ticketId, 'reqdQuestionsFilled'], reqdQuestionsFilled);
            }

            const filteredQuestionList = state
                .getIn(['questionList', ticketId, 'data']).toJS()
                .filter((item, idx) => idx <= questionIndex);

            return state
                .setIn(['questionList', ticketId, 'loading'], false)
                .setIn(['questionList', ticketId, 'data'], fromJS([...filteredQuestionList, ...modifiedQuestionList]))
                .setIn(['disputeInfo', ticketId, 'reqdQuestionsFilled'], reqdQuestionsFilled);
        }

        case MODIFY_QUESTIONS_INFO: {
            const {
                ticketId, code, dd, answer,
            } = action.data;

            const questionList = state.getIn(['questionList', ticketId, 'data']).toJS();
            const questionIdx = questionList.findIndex((item) => item.code === code);
            const modifiedQuestionList = [...questionList];
            modifiedQuestionList[questionIdx] = {
                ...modifiedQuestionList[questionIdx],
                isDDOpen: dd,
                answer,
            };

            const reqdQuestionsFilled = modifiedQuestionList.some((item) => !item.isOptional && item.answer);

            return state
                .setIn(['questionList', ticketId, 'data'], fromJS(modifiedQuestionList))
                .setIn(['disputeInfo', ticketId, 'reqdQuestionsFilled'], reqdQuestionsFilled);
        }

        case ERR_QUESTIONS_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['questionList', ticketId, 'loading'], false)
                .setIn(['questionList', ticketId, 'data'], fromJS([]));
        }

        case INITIATE_RAISE_DISPUTE: {
            const { ticketId } = action.data;

            return state
                .setIn(['disputeInfo', ticketId, 'loading'], true)
                .setIn(['disputeInfo', ticketId, 'err'], '')
                .setIn(['disputeInfo', ticketId, 'data'], fromJS({}));
        }

        case SUCCESS_RAISE_DISPUTE: {
            const { ticketId, disputeInfo } = action.data;

            return state
                .setIn(['disputeInfo', ticketId, 'loading'], false)
                .setIn(['disputeInfo', ticketId, 'data'], fromJS(disputeInfo))
                .setIn(['disputeInfo', ticketId, 'success'], true);
        }

        case ERR_RAISE_DISPUTE: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['disputeInfo', ticketId, 'loading'], false)
                .setIn(['disputeInfo', ticketId, 'err'], err)
                .setIn(['disputeInfo', ticketId, 'data'], fromJS({}));
        }

        case RESET_STATE: {
            const { ticketId } = action.data;

            return state
                .setIn(['transactionsList', ticketId], fromJS({}))
                .setIn(['questionList', ticketId], fromJS({}))
                .setIn(['disputeInfo', ticketId], fromJS({}))
                .setIn(['firstOrLastSetTxnList', ticketId], fromJS({}));
        }

        case ATTACH_ENTITY_TICKET: {
            const { ticketId } = action.data;

            return state
                .setIn(['transactionsList', ticketId, 'loading'], true);
        }

        case SET_ATTACH_ENTITY_TICKET:
        case ERR_ATTACH_ENTITY_TICKET: {
            const { ticketId } = action.data;

            return state
                .setIn(['transactionsList', ticketId, 'loading'], false);
        }

        case GET_DISPUTE_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['transactionsList', ticketId, 'loading'], true)
                .setIn(['selectedTxnDisputeInfo', ticketId, 'hasInfo'], false)
                .setIn(['selectedTxnDisputeInfo', ticketId, 'data'], fromJS({}));
        }

        case SET_DISPUTE_INFO: {
            const { ticketId, disputeInfo, hasDisputeInfo } = action.data;

            return state
                .setIn(['transactionsList', ticketId, 'loading'], false)
                .setIn(['selectedTxnDisputeInfo', ticketId, 'hasInfo'], hasDisputeInfo)
                .setIn(['selectedTxnDisputeInfo', ticketId, 'data'], fromJS(disputeInfo));
        }

        case ERR_DISPUTE_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['transactionsList', ticketId, 'loading'], false);
        }

        default:
            return state;
    }
}

export default transactionsReducer;

/* eslint-disable default-param-last */
/* eslint-disable no-fallthrough */
import { fromJS } from 'immutable';

import {
    GET_CARDS_INFO, SET_CARDS_INFO, ERR_CARDS_INFO, GET_UPI_INFO, SET_UPI_INFO, ERR_UPI_INFO,
    TOGGLE_UPI_STATUS, SET_UPI_STATUS, ERR_UPI_STATUS, SET_UPI_TOGGLE_CONFIRM_SCREEN,
    INITIATE_SUSPEND_CARD, SET_SUSPEND_CARD, ERR_SUSPEND_CARD, SET_SUSPEND_CARD_CONFIRM_SCREEN,
    INITIATE_BLOCK_CARD, SET_BLOCK_CARD, ERR_BLOCK_CARD, SET_BLOCK_CARD_CONFIRM_SCREEN,
    GET_CARD_INFO, SET_CARD_INFO, ERR_CARD_INFO, TOGGLE_SCREEN, GET_CARD_TRACKING_INFO,
    SET_CARD_TRACKING_INFO, ERR_CARD_TRACKING_INFO, GET_CARD_LIST, SET_CARD_LIST, ERR_CARD_LIST,
    UPDATE_FREE_CARD_REPLACEMENT, SET_FREE_CARD_REPLACEMENT_LOADING_STATE, GET_FOREX_TXN_INFO, SET_FOREX_TXN_INFO, ERR_FOREX_TXN_INFO,
    GET_CARD_REQUEST_STATUS, SET_CARD_REQUEST_STATUS, ERR_CARD_REQUEST_STATUS, GET_CARD_AMC, SET_CARD_AMC, ERR_CARD_AMC,
} from './constants';

const initialState = fromJS({
    cardsList: {},
    upiInfo: {},
    forexTxnsInfo: [],
});

function transactionsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CARDS_INFO: {
            const { ticketId, type } = action.data;

            if (type === 'debit') {
                return state.setIn(['cardsList', ticketId, 'loading', 'debitCardInfo'], true);
            }
        }

        case SET_CARDS_INFO: {
            const {
                ticketId, cardList, type, activated,
            } = action.data;

            if (type === 'debit') {
                if (activated === true) {
                    return state
                        .setIn(['cardsList', ticketId, 'loading', 'debitCardInfo'], false)
                        .setIn(['cardsList', ticketId, 'hasActivatedDebitCards'], true)
                        .setIn(['cardsList', ticketId, 'activatedDebitCards'], fromJS(cardList));
                }

                return state
                    .setIn(['cardsList', ticketId, 'loading', 'debitCardInfo'], false)
                    .setIn(['cardsList', ticketId, 'hasDeactivatedDebitCards'], true)
                    .setIn(['cardsList', ticketId, 'deactivatedDebitCards'], fromJS(cardList));
            }
        }

        case ERR_CARDS_INFO: {
            const { ticketId } = action.data;

            return state.setIn(['cardsList', ticketId, 'loading', 'debitCardInfo'], false);
        }

        case INITIATE_SUSPEND_CARD: {
            const { ticketId } = action.data;

            return state.setIn(['cardsList', ticketId, 'loading', 'suspendDebitCard'], true);
        }

        case SET_SUSPEND_CARD: {
            const { ticketId, isConfirmAction } = action.data;

            if (isConfirmAction) {
                return state
                    .setIn(['cardsList', ticketId, 'loading', 'suspendDebitCard'], false)
                    .setIn(['cardsList', ticketId, 'isSuspendConfirmScreen'], false)
                    .setIn(['cardsList', ticketId, 'loading', 'debitCardInfo'], true)
                    .setIn(['cardsList', ticketId, 'hasActivatedDebitCards'], false)
                    .setIn(['cardsList', ticketId, 'activatedDebitCards'], fromJS([]))
                    .setIn(['cardsList', ticketId, 'data'], fromJS([]));
            }

            return state
                .setIn(['cardsList', ticketId, 'loading', 'suspendDebitCard'], false)
                .setIn(['cardsList', ticketId, 'isSuspendConfirmScreen'], true);
        }

        case SET_SUSPEND_CARD_CONFIRM_SCREEN: {
            const { ticketId, flag } = action.data;

            return state.setIn(['cardsList', ticketId, 'isSuspendConfirmScreen'], flag);
        }

        case ERR_SUSPEND_CARD: {
            const { ticketId } = action.data;

            return state.setIn(['cardsList', ticketId, 'loading', 'suspendDebitCard'], false);
        }

        case INITIATE_BLOCK_CARD: {
            const { ticketId } = action.data;

            return state.setIn(['cardsList', ticketId, 'loading', 'blockDebitCard'], true);
        }

        case SET_BLOCK_CARD: {
            const { ticketId, isConfirmAction } = action.data;

            if (isConfirmAction) {
                return state
                    .setIn(['cardsList', ticketId, 'loading', 'blockDebitCard'], false)
                    .setIn(['cardsList', ticketId, 'isBlockConfirmScreen'], false)
                    .setIn(['cardsList', ticketId, 'loading', 'debitCardInfo'], true)
                    .setIn(['cardsList', ticketId, 'hasActivatedDebitCards'], false)
                    .setIn(['cardsList', ticketId, 'activatedDebitCards'], fromJS([]))
                    .setIn(['cardsList', ticketId, 'data'], fromJS([]));
            }

            return state
                .setIn(['cardsList', ticketId, 'loading', 'blockDebitCard'], false)
                .setIn(['cardsList', ticketId, 'isBlockConfirmScreen'], true);
        }

        case SET_BLOCK_CARD_CONFIRM_SCREEN: {
            const { ticketId, flag } = action.data;

            return state.setIn(['cardsList', ticketId, 'isBlockConfirmScreen'], flag);
        }

        case ERR_BLOCK_CARD: {
            const { ticketId } = action.data;

            return state.setIn(['cardsList', ticketId, 'loading', 'blockDebitCard'], false);
        }

        case GET_CARD_INFO: {
            const { ticketId } = action.data;

            return state.setIn(['cardsList', ticketId, 'loading', 'cardInfo'], true);
        }

        case SET_CARD_INFO: {
            const {
                ticketId, cardInfo, isCardLimitsScreen, isCardSettingsScreen,
            } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardInfo'], false)
                .setIn(['cardsList', ticketId, 'isCardLimitsScreen'], isCardLimitsScreen)
                .setIn(['cardsList', ticketId, 'isCardSettingsScreen'], isCardSettingsScreen)
                .setIn(['cardsList', ticketId, 'selectedCardInfo'], fromJS(cardInfo));
        }

        case ERR_CARD_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardInfo'], false)
                .setIn(['cardsList', ticketId, 'selectedCardInfo'], fromJS({}));
        }

        case GET_CARD_TRACKING_INFO: {
            const { ticketId } = action.data;

            return state.setIn(['cardsList', ticketId, 'loading', 'cardTrackingInfo'], true);
        }

        case SET_CARD_TRACKING_INFO: {
            const { ticketId, cardTrackingInfo } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardTrackingInfo'], false)
                .setIn(['cardsList', ticketId, 'selectedCardTrackingInfo'], fromJS(cardTrackingInfo));
        }

        case ERR_CARD_TRACKING_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardTrackingInfo'], false)
                .setIn(['cardsList', ticketId, 'selectedCardTrackingInfo'], fromJS({}));
        }

        case GET_CARD_REQUEST_STATUS: {
            const { ticketId } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardRequestStatus'], true)
                .setIn(['cardsList', ticketId, 'err', 'cardRequestStatus'], '');
        }

        case SET_CARD_REQUEST_STATUS: {
            const { ticketId, cardRequestStatus, extraText } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardRequestStatus'], false)
                .setIn(['cardsList', ticketId, 'selectedCardRequestStatus'], fromJS({ status: cardRequestStatus, extraText }));
        }

        case ERR_CARD_REQUEST_STATUS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardRequestStatus'], false)
                .setIn(['cardsList', ticketId, 'err', 'cardRequestStatus'], err)
                .setIn(['cardsList', ticketId, 'selectedCardRequestStatus'], fromJS({}));
        }

        case GET_CARD_AMC: {
            const { ticketId } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardAmcDetails'], true)
                .setIn(['cardsList', ticketId, 'err', 'cardAmcDetails'], '');
        }

        case SET_CARD_AMC: {
            const { ticketId, cardAmcDetails } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardAmcDetails'], false)
                .setIn(['cardsList', ticketId, 'selectedCardAmcDetails'], fromJS({ details: cardAmcDetails }));
        }

        case ERR_CARD_AMC: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardAmcDetails'], false)
                .setIn(['cardsList', ticketId, 'err', 'cardAmcDetails'], err)
                .setIn(['cardsList', ticketId, 'selectedCardAmcDetails'], fromJS({}));
        }

        case TOGGLE_SCREEN: {
            const {
                ticketId, isSuspendConfirmScreen, isBlockConfirmScreen, isCardLimitsScreen, isCardSettingsScreen,
            } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'isSuspendConfirmScreen'], !!isSuspendConfirmScreen)
                .setIn(['cardsList', ticketId, 'isBlockConfirmScreen'], !!isBlockConfirmScreen)
                .setIn(['cardsList', ticketId, 'isCardLimitsScreen'], !!isCardLimitsScreen)
                .setIn(['cardsList', ticketId, 'isCardSettingsScreen'], !!isCardSettingsScreen);
        }

        case GET_UPI_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['upiInfo', ticketId, 'loading', 'upiInfo'], true)
                .setIn(['upiInfo', ticketId, 'err', 'upiInfo'], '');
        }

        case SET_UPI_INFO: {
            const { ticketId, upiInfo } = action.data;

            return state
                .setIn(['upiInfo', ticketId, 'loading', 'upiInfo'], false)
                .setIn(['upiInfo', ticketId, 'hasUPIInfo'], true)
                .setIn(['upiInfo', ticketId, 'upiInfo'], fromJS(upiInfo));
        }

        case ERR_UPI_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['upiInfo', ticketId, 'loading', 'upiInfo'], false)
                .setIn(['upiInfo', ticketId, 'err', 'upiInfo'], err);
        }

        case TOGGLE_UPI_STATUS: {
            const { ticketId } = action.data;

            return state
                .setIn(['upiInfo', ticketId, 'loading', 'toggleUpi'], true)
                .setIn(['upiInfo', ticketId, 'err', 'toggleUpi'], '');
        }

        case SET_UPI_STATUS: {
            const { ticketId, isConfirmAction } = action.data;

            if (isConfirmAction) {
                return state
                    .setIn(['upiInfo', ticketId, 'loading', 'toggleUpi'], false)
                    .setIn(['upiInfo', ticketId, 'isUPIConfirmScreen'], false)
                    .setIn(['upiInfo', ticketId, 'loading', 'upiInfo'], true)
                    .setIn(['upiInfo', ticketId, 'hasUPIInfo'], false)
                    .setIn(['upiInfo', ticketId, 'upiInfo'], fromJS({}));
            }

            return state
                .setIn(['upiInfo', ticketId, 'loading', 'toggleUpi'], false)
                .setIn(['upiInfo', ticketId, 'isUPIConfirmScreen'], true);
        }

        case SET_UPI_TOGGLE_CONFIRM_SCREEN: {
            const { ticketId, flag } = action.data;

            return state.setIn(['upiInfo', ticketId, 'isUPIConfirmScreen'], flag);
        }

        case ERR_UPI_STATUS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['upiInfo', ticketId, 'loading', 'toggleUpi'], false)
                .setIn(['upiInfo', ticketId, 'err', 'toggleUpi'], err);
        }

        case GET_CARD_LIST: {
            const { ticketId } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardList'], true)
                .setIn(['cardsList', ticketId, 'data'], fromJS([]));
        }

        case SET_CARD_LIST: {
            const { ticketId, cardList } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardList'], false)
                .setIn(['cardsList', ticketId, 'data'], fromJS(cardList));
        }

        case ERR_CARD_LIST: {
            const { ticketId } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'cardList'], false)
                .setIn(['cardsList', ticketId, 'data'], fromJS([]));
        }

        case UPDATE_FREE_CARD_REPLACEMENT: {
            const { ticketId } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'updateFreeCardReplacement'], true);
        }

        case SET_FREE_CARD_REPLACEMENT_LOADING_STATE: {
            const { ticketId } = action.data;

            return state
                .setIn(['cardsList', ticketId, 'loading', 'updateFreeCardReplacement'], false);
        }

        case GET_FOREX_TXN_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['forexTxnsInfo', ticketId, 'loading'], true);
        }

        case SET_FOREX_TXN_INFO: {
            const { ticketId, forexTxnInfo } = action.data;

            // error handling if accountStatementInfo is null saga does not crash
            if (!forexTxnInfo) {
                return state;
            }

            return state
                .setIn(['forexTxnsInfo', ticketId, 'loading'], false)
                .setIn(['forexTxnsInfo', ticketId, 'data'], fromJS(forexTxnInfo))
                .setIn(['forexTxnsInfo', ticketId, 'hasData'], true);
        }

        case ERR_FOREX_TXN_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['forexTxnsInfo', ticketId, 'loading'], false)
                .setIn(['forexTxnsInfo', ticketId, 'data'], fromJS({}))
                .setIn(['forexTxnsInfo', ticketId, 'hasData'], false);
        }

        default:
            return state;
    }
}

export default transactionsReducer;

/**
*
* OutsideAlerter - component that alerts if you click outside of it
*
*/

import React from 'react';

class OutsideAlerter extends React.PureComponent {
    componentDidMount() {
        const { visible } = this.props;

        if (visible) {
            this.isMouseDownEventListener = true;
            document.addEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentDidUpdate({ visible: prevVisible }) {
        const { visible } = this.props;

        if (prevVisible !== visible) {
            if (this.isMouseDownEventListener) {
                this.isMouseDownEventListener = false;
                document.removeEventListener('mousedown', this.handleClickOutside);
            } else {
                this.isMouseDownEventListener = true;
                document.addEventListener('mousedown', this.handleClickOutside);
            }
        }
    }

    componentWillUnmount() {
        if (this.isMouseDownEventListener) {
            this.isMouseDownEventListener = false;
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    isValidElementClick = (event) => {
        const { elementsToIgnore } = this.props;

        if (this.wrapperRef) {
            if (this.wrapperRef.contains(event.target)) {
                return false;
            }

            if (elementsToIgnore) {
                const elementClicked = elementsToIgnore();

                if (elementClicked && elementClicked.contains && elementClicked.contains(event.target)) {
                    return false;
                }
            }

            return true;
        }

        return false;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside = (event) => {
        // console.log('check for click');
        if (this.isValidElementClick(event)) {
            const { onClickHandler } = this.props;

            if (onClickHandler) {
                // console.log('on click', onClickHandler);
                onClickHandler();
            }
        }
    }

    render() {
        const { children, containerClass  } = this.props;

        return (
            <div className={containerClass} ref={this.setWrapperRef}>
                {children}
            </div>
        );
    }
}

export default OutsideAlerter;

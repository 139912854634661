/* eslint-disable default-param-last */

/**
 * @file Manages the reducer state & function for transaction view V2
 */

import { fromJS } from 'immutable';

import {
    GET_TRANSACTION_VIEW_V2, SET_TRANSACTION_VIEW_V2, SET_TRANSACTION_VIEW_V2_STREAM,
    ERR_TRANSACTION_VIEW_V2, SET_TRANSACTION_VIEW_V2_STREAM_COMPLETE, SET_TRANSACTION_VIEW_V2_PERIOD,
    defaultPeriod, TRANSACTION_VIEW_SET_FILTER_FIELD, INITIAL_DATE_RANGE, RESET_TRANSACTION_VIEW, SET_AGGREGATED_DATA_POINTS,
    SET_ANNOTATIONS, SET_TXN_ANNOTATION_MODAL_VISIBILITY,
} from './constants';

const initialState = fromJS({
    loading: false,
    fetching: false,
    transactions: [],
    period: defaultPeriod,
    err: '',
    filterField: {
        fromDate: INITIAL_DATE_RANGE.fromDate,
        toDate: INITIAL_DATE_RANGE.toDate,
    },
    aggregateDataPoints: null,
    annotationDetails: null,
    txnAnnotationModalVisibility: false,
});

function transactionViewV2Reducer(state = initialState, action) {
    switch (action.type) {
        case GET_TRANSACTION_VIEW_V2:
            return state
                .setIn(['loading'], true)
                .setIn(['fetching'], true)
                .setIn(['transactions'], fromJS([])) // setting transactions to empty
                .setIn(['err'], '');

        case SET_TRANSACTION_VIEW_V2: {
            const transactions = action.data;

            return state
                .setIn(['transactions'], fromJS(transactions)) // replacing the existing transactions
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }

        case SET_TRANSACTION_VIEW_V2_STREAM: {
            const transactions = action.data;

            return state
                .updateIn(['transactions'], (list) => list.concat(transactions)) // updating with existing transactions
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }

        case SET_TRANSACTION_VIEW_V2_STREAM_COMPLETE: {
            return state.setIn(['fetching'], false);
        }

        case SET_TRANSACTION_VIEW_V2_PERIOD: {
            const period = action.data;
            return state.setIn(['period'], fromJS(period));
        }

        case ERR_TRANSACTION_VIEW_V2: {
            const { err } = action.data;

            return state
                .setIn(['transactions'], fromJS({}))
                .setIn(['loading'], false)
                .setIn(['fetching'], false)
                .setIn(['err'], err);
        }

        case TRANSACTION_VIEW_SET_FILTER_FIELD: {
            const { fromDate, toDate } = action.data;
            if (fromDate) {
                return state.setIn(['filterField', 'fromDate'], fromJS(fromDate));
            }

            if (toDate) {
                return state.setIn(['filterField', 'toDate'], fromJS(toDate));
            }

            return state;
        }

        case RESET_TRANSACTION_VIEW: {
            return initialState;
        }

        case SET_AGGREGATED_DATA_POINTS: {
            return state.setIn(['aggregateDataPoints'], fromJS(action.data));
        }

        case SET_ANNOTATIONS: {
            const availableAnnotations = state.toJS().annotationDetails || {};
            return state.setIn(['annotationDetails'], fromJS({
                ...availableAnnotations,
                ...action.data,
            }))
                .setIn(['loading'], false);
        }

        case SET_TXN_ANNOTATION_MODAL_VISIBILITY: {
            return state.setIn(['txnAnnotationModalVisibility'], action.data);
        }
        default:
            return state;
    }
}

export default transactionViewV2Reducer;

/*
 *
 * DevActions constants
 *
 */

export const GET_ACTION_LIST = 'app/DevActions/GET_ACTION_LIST';
export const SET_ACTION_LIST = 'app/DevActions/SET_ACTION_LIST';
export const ERR_ACTION_LIST = 'app/DevActions/ERR_ACTION_LIST';
export const GET_PARAMETER_LIST = 'app/DevActions/GET_PARAMETER_LIST';
export const SET_PARAMETER_LIST = 'app/DevActions/SET_PARAMETER_LIST';
export const SET_PARAMETER_VALUE = 'app/DevActions/SET_PARAMETER_VALUE';
export const ERR_PARAMETER_LIST = 'app/DevActions/ERR_PARAMETER_LIST';
export const EXECUTE_ACTION = 'app/DevActions/EXECUTE_ACTION';
export const SUCCESS_EXECUTE_ACTION = 'app/DevActions/SUCCESS_EXECUTE_ACTION';
export const ERR_EXECUTE_ACTION = 'app/DevActions/ERR_EXECUTE_ACTION';
export const SET_MONORAIL_ID = 'app/DevActions/SET_MONORAIL_ID';
export const SET_PARAMETER_VALUE_V2 = 'app/DevActions/SET_PARAMETER_VALUEV2';

// array of dev actions for which the 'remarks' value should be copied to 'reason' field
export const COPY_REMARKS_TO_REASON_ACTIONS = ['MARK_LIVENESS_PASSED', 'RETRY_LIVENESS', 'MARK_FACEMATCH_PASSED'];

// array of dev actions for which type=4 (TIMESTAMP) should not have a max date set as the current date. Any range can be selected.
export const NO_MAX_DATE_DEV_ACTIONS = ['MARK_WEALTH_ONBOARDING_EXPIRY_PASSED', 'AA_CONSENT_STATUS_UPDATE', 'SEGMENT_METADATA_ACTION'];

/*
 *
 * todo(auto injection of enum) : https://monorail.pointz.in/p/fi-app/issues/detail?id=75252
 *
 */

export const fieldType = {
    STRING: 1,
    INTEGER: 2,
    DOUBLE: 3,
    TIMESTAMP: 4,
    DROPDOWN: 5,
    MONEY: 6,
    NAME: 7,
    PHONE_NUMBER: 8,
    MULTI_SELECT_DROPDOWN: 9,
    PAGE_CONTEXT: 10,
    FILE: 11,
    FILE_LIST: 12,
    MULTILINE_TEXT: 13,
    REASON: 100,
};

export const GET_MESSAGE_LIST = 'app/Comms/GET_MESSAGE_LIST';
export const SET_MESSAGE_LIST = 'app/Comms/SET_MESSAGE_LIST';
export const ERR_MESSAGE_LIST = 'app/Comms/ERR_MESSAGE_LIST';
export const GET_MESSAGE_INFO = 'app/Comms/GET_MESSAGE_INFO';
export const SET_MESSAGE_INFO = 'app/Comms/SET_MESSAGE_INFO';
export const ERR_MESSAGE_INFO = 'app/Comms/ERR_MESSAGE_INFO';
export const CLOSE_INFO_MODAL = 'app/Comms/CLOSE_INFO_MODAL';

export const COMMS_MEDIUM = [
    {
        label: 'SMS',
        value: 'SMS'
    },
    {
        label: 'EMAIL',
        value: 'EMAIL'
    },
    {
        label: 'NOTIFICATION',
        value: 'NOTIFICATION'
    }
];

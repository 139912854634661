import { createSelector } from 'reselect';

const selectProfile = (state) => state.get('profile');

export const makeSelectProfileDetails = createSelector(
    selectProfile,
    (subState) => subState.get('profile').toJS(),
);

export const selectUserIssueInfo = createSelector(
    selectProfile,
    (subState) => subState.get('userIssueInfo').toJS(),
);

export const selectUserBanners = createSelector(
    selectProfile,
    (subState) => subState.get('userBanners').toJS(),
);

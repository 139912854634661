/**
 * @file Manages the utility functions for Wealth P2P component
 */

const renderInvestorInfo = (data) => ([
    {
        label: 'Has user checked eligibility?',
        value: data.hasUserCheckedEligibility,
    },
    {
        label: 'Is user eligible?',
        value: data.isEligible,
    },
    {
        label: 'Reason for ineligibility',
        value: data.reasons,
    },
    {
        label: 'Total number of Slots unlocked for user',
        value: data.availableSlots,
    },
    {
        label: 'Number of Slots consumed',
        value: data.consumedSlots,
    },
    {
        label: 'Overall limit available',
        value: data.overallLimitAvailable,
    },
    {
        label: 'Investor ID',
        value: data.investorId,
    },
]);

const renderInvestmentTxnFailureInfo = (data) => ([
    {
        label: 'Stage',
        value: data.stage,
    },
    {
        label: 'Status',
        value: data.status,
    },
    {
        label: 'Timestamp',
        value: data.ts,
    },
]);

export {
    renderInvestorInfo,
    renderInvestmentTxnFailureInfo,
};

/**
 *
 * MutualFundOrderInfo - Component to show the mutual fund order details like updates, etc
 *
 */

import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button, Table } from '../../../components';

import { MUTUAL_FUND_ORDER_INFO_LABEL_DATA } from '../constants';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '10%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 900,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        maxHeight: '85%',
    },
};

const MutualFundOrderInfo = (props) => {
    const { visible, data, toggleHandler } = props;

    if (!visible) return null;

    const { updates } = data;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='Mutual Fund Order Info Modal'
        >
            <div className='mfs-modal-mfoicr'>
                <Table
                    header='Order Details'
                    labelData={MUTUAL_FUND_ORDER_INFO_LABEL_DATA}
                    contentData={updates}
                />
            </div>
            <Button
                extClasses='mfs-modal-cta'
                primary
                label='Close'
                onClick={toggleHandler}
            />
        </Modal>
    );
};

export default memo(MutualFundOrderInfo);

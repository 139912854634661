/* eslint-disable jsx-a11y/no-static-element-interactions,react/jsx-props-no-spreading */
/**
 *
 * App - Main entry point component to handle all the routes related logic & necessary API requests to authenticate the user
 *
 */

import React, { memo, useEffect } from 'react';
import {
    Route, Switch, Redirect, withRouter, useLocation, useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames';

import { getBannerList } from '../../appGlobal/actions';
import { Loader } from '../../components';
import { addMessageEventListener, postMessageEvent } from '../../messageEvents';

import Header from './Header';
import SelectAccessLevel from './SelectAccessLevel';

import { setAccessLevelInMetaTag } from './utils';
import { ROUTE_LIST } from './constants';
import {
    ROOT_PATH, FEDERAL_PATH, BIZ_ADMIN_PATH, DEVELOPER_PATH, WAITLIST_APPROVAL_PATH, ADMIN_TOOLS_PATH,
    WEB_DEVELOPER_PATH, ACCOUNT_OPS_PATH, SALARY_ACCOUNT_OPS_PATH, LOGIN_PATH,
} from './routes';
import { setAccessLevel as setAccessLevelAction } from './actions';
import {
    makeSelectHasAgentInfo, makeSelectLoading, makeSelectErr,
    makeSelectAccessLevel, makeSelectMetaInfo,
} from './selectors';

import './style.scss';

const getContainerClasses = (location, bannersVersion) => {
    const { pathname } = location;
    const containerClasses = {
        'layout-container': bannersVersion === 1,
        'layout-container-v2': bannersVersion === 2,
        f1Wrapper: true,
    };

    const firstVariantRoutes = [];
    const secondVariantRoutes = [BIZ_ADMIN_PATH, DEVELOPER_PATH];
    const thirdVariantRoutes = [
        WAITLIST_APPROVAL_PATH, ADMIN_TOOLS_PATH, WEB_DEVELOPER_PATH, ACCOUNT_OPS_PATH, SALARY_ACCOUNT_OPS_PATH, FEDERAL_PATH,
    ];

    firstVariantRoutes.forEach((item) => {
        if (pathname.includes(item)) {
            containerClasses['layout-container--var1'] = true;
        }
    });

    secondVariantRoutes.forEach((item) => {
        if (pathname.includes(item)) {
            containerClasses['layout-container--var2'] = true;
        }
    });

    thirdVariantRoutes.forEach((item) => {
        if (pathname.includes(item)) {
            containerClasses['layout-container--var3'] = true;
        }
    });

    return classNames(containerClasses);
};

const App = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    // reducer state variables
    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const hasAgentInfo = useSelector(makeSelectHasAgentInfo(), shallowEqual);
    const selectedAccessLevel = useSelector(makeSelectAccessLevel(), shallowEqual);
    const metaInfo = useSelector(makeSelectMetaInfo(), shallowEqual);

    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    const { appConfig: { enableAgentManagementV2 } } = metaInfo;

    /*  Moved to App Global(Deprecated)
    const history = useHistory();

    useEffect(() => {
        dispatch(getAuthStatus());
    }, [dispatch]);

    useEffect(() => {
        if (selectedAccessLevel) {
            const intervalId = setInterval(() => {
                dispatch(getBannerList({ roles: [selectedAccessLevel] }));
            }, 30000);

            return () => clearInterval(intervalId);
        }
        return () => {};
    }, [dispatch, selectedAccessLevel]);
    */

    /**
     * Ensure that no routes can be accessed if user is not authenticated
     * If user tries to access a route and is not authenticated, it immediately redirects back to login page
     */
    /*  Moved to App Global(Deprecated)
    useEffect(() => {
        if (!hasAgentInfo && location.pathname !== LOGIN_PATH) {
            history.push(LOGIN_PATH);
        }
    }, [hasAgentInfo, history, location.pathname]);
    */

    useEffect(() => {
        const removeMessageListener = addMessageEventListener((event) => {
            const { provideAccessLevel } = event.data || {};
            if (provideAccessLevel) {
                // Posts the current access level to the event source as a message if all the conditions are satisfied.
                postMessageEvent(event.source, { accessLevel: selectedAccessLevel }, event.source.origin);
            }
        });
        return removeMessageListener;
    }, [selectedAccessLevel]);

    const preventDefaultBehavior = (e) => {
        const DISABLE_RIGHT_CLICK_ROUTES = [FEDERAL_PATH];
        const isDisableRightClickRoute = DISABLE_RIGHT_CLICK_ROUTES.includes(location.pathname);

        if (isDisableRightClickRoute) {
            e.preventDefault();
        }
    };

    /** Function to set access level in reducer state & in meta tag */
    const setAccessLevel = (accessLevel) => {
        dispatch(setAccessLevelAction({ accessLevel }));
        // need to fetch banners for the selected access level
        dispatch(getBannerList({ roles: [accessLevel] }));

        // set access level in meta tag to read it while making API requests
        setAccessLevelInMetaTag(accessLevel);

        // Redirects the page to the last location if the prevPath property is given in the location state while coming to this page.
        if (location?.state?.prevPath) {
            history.replace(location?.state?.prevPath);
        }
    };

    const renderRoutes = () => {
        // if the Agent Management v2 flow is enabled, user need to select user role before going to main tab if they have more than 1 role
        if (enableAgentManagementV2 && hasAgentInfo && !selectedAccessLevel) {
            return <SelectAccessLevel err={err.selectedAccessLevel} setAccessLevel={setAccessLevel} />;
        }

        return (
            <Switch>
                {ROUTE_LIST(selectedAccessLevel)
                    .filter((route) => {
                        // if user is not authenticated, only render the Login route inside <Switch></Switch>
                        if (!hasAgentInfo) {
                            return route.path === LOGIN_PATH;
                        }

                        // if user is logged in, render all routes
                        return true;
                    })
                    .map((route) => {
                    /**
                    * if explicitly mentioned to redirect the particular route
                    * e.g. home page url should be redirected to ticket page
                    */
                        if (route.redirect) {
                            return (
                                <Route
                                    {...route}
                                    key={route.path}
                                    render={() => <Redirect to={{ pathname: route.redirect }} />}
                                />
                            );
                        }

                        /**
                    * if user is not allowed to access the particular route
                    * e.g. agents list route for agents should be redirected to home page
                    */
                        if (!selectedAccessLevel || route.accessible.includes(selectedAccessLevel)) {
                            return <Route key={route.path} {...route} />;
                        }

                        return (
                            <Route
                                key={route.path}
                                path={route.path}
                                render={() => <Redirect to={{ pathname: ROOT_PATH }} />}
                            />
                        );
                    })}
                {/* in case of unmatched url, redirect the user to home page */}
                <Redirect to={{ pathname: ROOT_PATH }} />;
            </Switch>
        );
    };

    if (loading.authStatus || loading.agentInfo) {
        return <Loader visible />;
    }

    const containerClasses = getContainerClasses(location, bannersVersion);
    /*  Moved to App Global(Deprecated)
    const isRootRoute = location.pathname === '/';

    // if the Agent Management v2 flow is enabled, redirect to root path if user role is not selected
    if (enableAgentManagementV2 && !isRootRoute && hasAgentInfo && !selectedAccessLevel) {
        return <Redirect to={{ pathname: ROOT_PATH }} />;
    }
    */

    return (
        <div
            className='app fcWrapper f1Wrapper'
            onMouseDown={preventDefaultBehavior}
            onContextMenu={preventDefaultBehavior}
        >
            <Header setAccessLevel={setAccessLevel} />
            <div className={containerClasses}>
                {
                    err.agentInfo ? (
                        <div className='err-content'>{`${err.agentInfo}. Please contact admin for the access.`}</div>
                    ) : (
                        renderRoutes()
                    )
                }
            </div>
        </div>
    );
};

/*  Moved to App Global(Deprecated)
const withReducer = injectReducer({ key: APP_KEY, reducer });
const withSaga = injectSaga({ key: APP_KEY, saga });
*/
export default compose(
    withRouter,
)(memo(App));

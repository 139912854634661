/**
 * @file LivenessCaseInfo
 * Displays the details of a single liveness case
 */

/* eslint-disable jsx-a11y/media-has-caption */
import React, {
    memo, useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { Button, DisableContextMenu, Link } from '../../../components';
import { downloadFile } from '../../../utils';
import { MetaInfoContext, UserDetailsContext } from '../../../context';
import { useMemoizedSelector } from '../../../hooks';

import RenderMedia from '../../UserRiskReview/RenderMedia';
import { getAdditionalFilters, getDataFromLivenessCase, getPreFilledValuesObj } from '../../RiskOps/utils';
import {
    selectRiskOpsCurCase, selectRiskOpsPayloadType, selectRiskOpsQueueFilters, selectRiskOpsReviewVideo,
} from '../../RiskOps/selectors';
import { auditLogMediaDownloadAction, dequeueRiskOpsCaseAction, getRiskOpsReviewVideoAction } from '../../RiskOps/actions';
import { PAYLOAD_TYPE_PAL_APPLICATION_LIVENESS_FEDERAL_REVIEW, PAL_DOWNLOAD_ENDPOINT } from '../../LoanLivenessReviewer/constants';
import { extractVideoPathFromURL } from '../../UserRiskReview/utils';
import DevActions from '../../DevActions';
import { CustomerDetailsModal, LivenessSummaryModal, ViewAnnotationDetails } from '../../RiskOps';

import {
    DEV_ACTION_TYPES, PAYLOAD_TYPE_L2_LIVENESS, PAYLOAD_TYPE_LIVENESS_PRE_APPROVED_LOAN, DEV_ACTIONS_FOR_LIVENESS_PRE_APPROVED_LOAN,
    DEV_ACTIONS_FOR_FEDERAL_LOAN_LIVENESS_REVIEW,
} from '../constants';

const LivenessCaseInfo = (props) => {
    const { containerKey, dequeueOnSubmit = true, extraActions } = props;

    const dispatch = useDispatch();

    const metaInfo = useContext(MetaInfoContext);
    const userInfo = useContext(UserDetailsContext);
    const { basicInfo } = userInfo;
    const {
        basicInfo: {
            accessLevelInfo: {
                isFederalLoanLivenessReviewer,
            },
        },
    } = userInfo;

    // reducer state variables
    const videoUrl = useMemoizedSelector(selectRiskOpsReviewVideo, [containerKey, isFederalLoanLivenessReviewer]);
    const payloadType = useMemoizedSelector(selectRiskOpsPayloadType, [containerKey]);
    const { fromDate, toDate, sortOption } = useMemoizedSelector(selectRiskOpsQueueFilters, [containerKey]);
    const curCase = useMemoizedSelector(selectRiskOpsCurCase, [containerKey]);
    const {
        actorId, requestId, recordId, referenceId, externalId, domainKnowledge,
    } = getDataFromLivenessCase(curCase);
    const { emailId } = basicInfo;
    const { media_units: mediaUnits } = domainKnowledge;

    // local state variables
    const [preFilledValues, setPreFilledValues] = useState(null);
    const [showLivenessSummaryModal, setLivenessSummaryModalVisibility] = useState(false);
    const [showCustomerDetailsModal, setCustomerDetailsModalVisibility] = useState(false);
    const [downloadableURL, setDownloadableURL] = useState('');

    useEffect(() => {
        setPreFilledValues(getPreFilledValuesObj({
            actorId, requestId, emailId, referenceId, externalId,
        }));

        /**
         * fetch video only if media units are not there
         * media units takes higher priority over the standard actor id, request id
         */
        if (!mediaUnits) dispatch(getRiskOpsReviewVideoAction({ containerKey, queryParams: { actorId, requestId } }));
    }, [dispatch, curCase.id]);

    const getLivenessReviewDevActionType = useMemo(() => {
        if (payloadType.value === PAYLOAD_TYPE_LIVENESS_PRE_APPROVED_LOAN) {
            return DEV_ACTIONS_FOR_LIVENESS_PRE_APPROVED_LOAN;
        }

        if (payloadType.value === PAYLOAD_TYPE_PAL_APPLICATION_LIVENESS_FEDERAL_REVIEW) {
            return DEV_ACTIONS_FOR_FEDERAL_LOAN_LIVENESS_REVIEW;
        }

        return DEV_ACTION_TYPES;
    }, [payloadType.value]);

    const {
        riskOps: { downloadMedia: { allowDownloadEvenIfTrackingApiFails, allowedEmails } }, componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;
    const { index } = curCase;

    const isL2Queue = payloadType.value === PAYLOAD_TYPE_L2_LIVENESS;
    const annotationCaseDetails = {
        isL2Queue,
        queryParams: {
            actorId,
            requestId,
            elementId: recordId,
            reReviewedBy: emailId,
            payloadType: payloadType.value,
        },
        getCasesQueryParams: {
            payloadType: payloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        },
        containerKey,
        index,
    };

    const dequeueAction = dequeueRiskOpsCaseAction({
        containerKey,
        actorId,
        recordId,
        index,
        queryParams: {
            payloadType: payloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        },
        customContext: {
            bannersVersion,
        },
    });

    const defaultExtraActions = dequeueOnSubmit ? [dequeueAction] : [];

    const downloadLivenessVideo = () => {
        const callAuditMedialDownloadApi = () => new Promise((resolve, reject) => {
            dispatch(auditLogMediaDownloadAction({
                requestBody: {
                    elementId: recordId || 'NA',
                    actorId,
                    agentName: emailId,
                    videoLocation: videoUrl,
                    payloadType: payloadType.value,
                },
                containerKey,
                resolve,
                reject,
            }));
        });

        callAuditMedialDownloadApi().then(() => {
            downloadFile(`liveness-video_${actorId}.mp4`, videoUrl);
        }).catch(() => {
            if (allowDownloadEvenIfTrackingApiFails) {
                downloadFile(`liveness-video_${actorId}.mp4`, videoUrl);
            }
        });
    };

    const setMediaSrcExt = (mediaSrc) => {
        if (mediaSrc) { setDownloadableURL(extractVideoPathFromURL(mediaSrc)); }
    };

    return (
        <React.Fragment>
            {/* If media units exist, render that. If not, render video */}
            {mediaUnits ? (
                <RenderMedia
                    mediaUnits={mediaUnits}
                    extClasses={{
                        container: 'my-30',
                    }}
                    setMediaSrcExt={setMediaSrcExt}
                />
            ) : (
                <div className='lreviewV2-video'>
                    {videoUrl ? (
                        <DisableContextMenu>
                            <video width='480' height='540' controls disablePictureInPicture controlsList='nodownload'>
                                <source src={videoUrl} type='video/mp4' />
                                Your browser does not support the video tag.
                            </video>
                        </DisableContextMenu>
                    ) : null}
                </div>
            )}

            <div className='frwpWrapper'>
                <Button
                    v2
                    secondary
                    label='View Liveness Summary Details'
                    onClick={() => setLivenessSummaryModalVisibility(true)}
                />
                <Button
                    v2
                    secondary
                    label='View Customer Details'
                    extClasses='ml-30'
                    onClick={() => setCustomerDetailsModalVisibility(true)}
                />
                {allowedEmails.includes(emailId) && (
                    <Button
                        v2
                        primary
                        label='Download Video'
                        extClasses='ml-30'
                        onClick={downloadLivenessVideo}
                    />
                )}
                {
                    payloadType.value === PAYLOAD_TYPE_PAL_APPLICATION_LIVENESS_FEDERAL_REVIEW && (
                        <Link
                            button
                            label='Download Video'
                            extClasses='mx-30'
                            href={`${PAL_DOWNLOAD_ENDPOINT}?path=${downloadableURL}`}
                        />
                    )
                }
            </div>

            <ViewAnnotationDetails
                caseDetails={annotationCaseDetails}
            />

            {/* if not L2 queue, only then show dev actions */}
            {!isL2Queue && preFilledValues && (
                <DevActions
                    containerKey='liveness-v2'
                    actionTypes={getLivenessReviewDevActionType}
                    preFilledValues={preFilledValues}
                    extraActions={extraActions || defaultExtraActions}
                    autoActionSelection={false}
                />
            )}

            <LivenessSummaryModal
                visible={showLivenessSummaryModal}
                actorId={actorId}
                closeCta={setLivenessSummaryModalVisibility}
            />

            <CustomerDetailsModal
                visible={showCustomerDetailsModal}
                actorId={actorId}
                closeCta={setCustomerDetailsModalVisibility}
            />
        </React.Fragment>
    );
};

export default memo(LivenessCaseInfo);

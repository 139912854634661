import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button, Table } from '../../../components';

import { USSTOCKS_ACTIVITY_DETAILS } from '../constants';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '10%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 900,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        maxHeight: '85%',
    },
};

const USSActivityInfo = (props) => {
    const { visible, toggleHandler, data } = props;

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='Activity Detail'
        >
            <div className='uss-modal-mfoicr'>
                <Table
                    header='Order Details'
                    labelData={USSTOCKS_ACTIVITY_DETAILS}
                    contentData={data}
                />
            </div>
            <Button
                primary
                onClick={toggleHandler}
                extClasses='uss-modal-cta'
                label='Close'
            />
        </Modal>
    );
};

export default memo(USSActivityInfo);

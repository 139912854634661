/**
 *
 * VKycModal
 *
 */

import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button } from '../../../components';
import { renderDetailedVKycInfo } from '../utils';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '15%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px 20px',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 640,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const VKycModal = (props) => {
    const {
        visible, data, toggleHandler, error,
    } = props;

    let summaryInfo;
    let troubleshootingList;

    if (!visible) return null;
    if (data)({ summaryInfo, troubleshootingList } = data);

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='VKYC Status Modal'
        >
            <div className='kob-modal-hwr'>VKYC Summary</div>
            <div className='kob-modal-ccr'>
                {
                    summaryInfo ? renderDetailedVKycInfo(summaryInfo).map((item) => (
                        <div className='kob-modal-cwr' key={item.label}>
                            <div className='kob-modal-cwr__cl kob-modal-cwr__cl--v2'>{item.label}</div>
                            <div className='kob-modal-cwr__sp'>:</div>
                            <div className='kob-modal-cwr__cv kob-modal-cwr__cv--v2'>{item.value}</div>
                        </div>
                    )) : (
                        <div className='error-label1 my-30'>
                            { error ? 'Failed to fetch VKyc status' : 'Not Available' }
                        </div>
                    )
                }
            </div>
            <div className='kob-modal-hwr mt-15'>VKYC Troubleshooting</div>
            <div className='kob-modal-ccr'>
                {
                    troubleshootingList ? troubleshootingList?.map((item) => (
                        <div className='kob-modal-cwr' key={item.label}>
                            <div className='kob-modal-cwr__cl kob-modal-cwr__cl--v2'>{item.label}</div>
                            <div className='kob-modal-cwr__sp'>:</div>
                            <div className='kob-modal-cwr__cv kob-modal-cwr__cv--v2'>{item.value[0]}</div>
                        </div>
                    )) : (
                        <div className='error-label1 my-30'>
                            {error ? 'Failed to fetch VKyc status' : 'No Troubleshooting details found' }
                        </div>
                    )
                }
            </div>
            <Button
                primary
                extClasses='kob-modal-bwr'
                label='Close'
                onClick={() => toggleHandler(false)}
            />
        </Modal>
    );
};

export default memo(VKycModal);

import { APP_V2_KEY } from '../AppV2/constants';

export const VKYC_KEY = `${APP_V2_KEY}/VKYC`;

export const AUDITOR_SCREEN_TYPE = {
    INITIATE_SCREEN: 'INITIATE_SCREEN',
    REVIEW_SCREEN: 'REVIEW_SCREEN',
    FINAL_SCREEN: 'FINAL_SCREEN',
};

export const FETCH_AUDIT_CASE = 'FETCH_AUDIT_CASE';
export const SET_AUDIT_REPORT = 'SET_AUDIT_REPORT';
export const SET_RECORDING_URL = 'SET_RECORDING_URL';
export const REVIEW_REPORT = 'REVIEW_REPORT';
export const SET_SCREEN = 'SET_SCREEN';
export const SET_CASE_COUNT = 'SET_CASE_COUNT';
export const FETCH_REVIEW_COUNT = 'FETCH_REVIEW_COUNT';

export const GET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT = `${VKYC_KEY}/GET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT`;
export const SET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT = `${VKYC_KEY}/SET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT`;

/*
 *
 * PayoutRequests actions
 *
 */

import {
    GET_RAISED_PAYOUT_REQUESTS, GET_ALL_PAYOUT_REQUESTS, UPDATE_PAYOUT_REQUEST,
} from './constants';

export const getRaisedPayoutRequests = data => ({
    type: GET_RAISED_PAYOUT_REQUESTS,
    data
});

export const getAllPayoutRequests = data => ({
    type: GET_ALL_PAYOUT_REQUESTS,
    data
});

export const updatePayoutRequest = data => ({
    type: UPDATE_PAYOUT_REQUEST,
    data
});

/**
 *
 * AppV2 - Main entry point component to handle all the routes related logic for SherlockV2
 *
 */
import React from 'react';

import Layout from './Layout';

const AppV2 = () => (<Layout />);

export default AppV2;

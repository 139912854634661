import { createSelector } from 'reselect';

const makeSelectAccountsList = () => createSelector(
    state => state.getIn(['connectedAccounts', 'accountsList']),
    substate => substate.toJS(),
);

export {
    makeSelectAccountsList,
};

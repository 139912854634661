/**
 * @file RaiseVerificationRequest
 * To pre-emptively raise a verification request for salary active user
 */

import React, { memo } from 'react';

import { Loader } from '../../../components';
import { useMemoizedSelector } from '../../../hooks';

import {
    SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY, VIEW_RAISE_VERIFICATION_CASES, VIEW_RAISE_VERIFICATION_SINGLE_CASE,
} from '../constants';
import { selectSalaryAccountOpsCurView, selectSalaryAccountOpsErr, selectSalaryAccountOpsLoading } from '../selectors';

import ViewRaiseVerificationRequestCases from './ViewRaiseVerificationRequestCases';
import ViewSingleRaiseVerificationRequestCase from './ViewSingleRaiseVerificationRequestCase';

const RenderCurView = ({ curView }) => {
    switch (curView) {
        case VIEW_RAISE_VERIFICATION_CASES: {
            return <ViewRaiseVerificationRequestCases />;
        }

        case VIEW_RAISE_VERIFICATION_SINGLE_CASE: {
            return <ViewSingleRaiseVerificationRequestCase />;
        }

        default: {
            return null;
        }
    }
};

const RaiseVerificationRequest = () => {
    const loading = useMemoizedSelector(selectSalaryAccountOpsLoading, [SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY]);
    const err = useMemoizedSelector(selectSalaryAccountOpsErr, [SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY]);
    const curView = useMemoizedSelector(selectSalaryAccountOpsCurView, [SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY]);

    return (
        <React.Fragment>
            <RenderCurView
                curView={curView}
            />
            {err && <div className='err-label salops-err'>{err}</div>}
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(RaiseVerificationRequest);

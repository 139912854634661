/**
 * @file SalaryVerificationQueue
 * A queue to verify the salary details of the users
 * Agents can filter the queue based on verification status
 * Agents can compare the employment details with the remitter name to check if they match
 * Agents can update the employer name by searching through the DB
 * Agents can accept, reject or escalate the case based on partial match, full match or no match
 */

import React, { memo } from 'react';

import { Loader } from '../../../components';
import { useMemoizedSelector } from '../../../hooks';

import { SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY, VIEW_SINGLE_VERIFICATION_CASE, VIEW_VERIFICATION_CASES } from '../constants';
import { selectSalaryAccountOpsCurView, selectSalaryAccountOpsErr, selectSalaryAccountOpsLoading } from '../selectors';

import ViewSingleVerificationCase from '../ViewSingleVerificationCase';
import ViewVerificationCases from '../ViewVerificationCases';

const RenderCurView = ({ curView }) => {
    switch (curView) {
        case VIEW_VERIFICATION_CASES: {
            return <ViewVerificationCases />;
        }

        case VIEW_SINGLE_VERIFICATION_CASE: {
            return <ViewSingleVerificationCase />;
        }

        default: {
            return null;
        }
    }
};

const SalaryVerificationQueue = () => {
    const loading = useMemoizedSelector(selectSalaryAccountOpsLoading, [SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY]);
    const loadingCasesCount = useMemoizedSelector(selectSalaryAccountOpsLoading, [SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY, 'verificationCasesCount']);
    const err = useMemoizedSelector(selectSalaryAccountOpsErr, [SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY]);
    const curView = useMemoizedSelector(selectSalaryAccountOpsCurView, [SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY]);

    return (
        <React.Fragment>
            <RenderCurView
                curView={curView}
            />
            {err && <div className='err-label salops-err'>{err}</div>}
            <Loader visible={loading || loadingCasesCount} />
        </React.Fragment>
    );
};

export default memo(SalaryVerificationQueue);

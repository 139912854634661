/**
 *
 * Fittt Landing Page
 *
 */

import React from 'react';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';

import UserRules from './UserRules';
import ActiveRules from './ActiveRules';

import reducer from './reducer';
import saga from './saga';

import './style.scss';

const Fittt = (props) => {
    const { ticketId, userId, index } = props;

    return (
        <div className='fittt'>
            <UserRules
                ticketId={ticketId}
                userId={userId}
                index={index}
            />
            <ActiveRules
                ticketId={ticketId}
                userId={userId}
                index={index}
            />
        </div>
    );
};

const withReducer = injectReducer({ key: 'fittt', reducer });
const withSaga = injectSaga({ key: 'fittt', saga });

export default compose(
    withReducer,
    withSaga,
)(Fittt);

/**
 *
 * useMemoizedSelector:
 * Custom hook to be used while using 'parameterized' selectors throughout the app
 * If you are not using a 'parameterized' selector you probably do not need to use this
 *
 * Use this hook rather than the useSelector() hook as it wraps the selector in useMemo()
 * which does not cause unnecessary re-renders in a component
 * This is needed because we wrap createSelector in a function and every time a component re-renders the reference of the function changes:
 * Example:
 * const makeSelectLoading = () => createSelector(
 *     (state) => state.loading,
 *     (subState) => subState.
 * );
 * makeSelectLoading will be called on all re-renders if you only use a useSelector() hook
 * To avoid this, use the useMemoizedSelector() so that the computation happens only when required
 *
 * Refer this article for further information: https://github.com/amsterdamharu/selectors#parameterized-and-memoized
 *
 * Usage: Without a parameterized selector
 * This is the traditional way you use a selector in a React component:
 * const loading = useSelector(selectLoading(), shallowEqual);
 *
 * Using App selector:
 * const loading = useMemoizedSelector(selectLoading);
 *
 * Advanced usage: For selectors that take parameters in the function
 * This is the traditional way you use a selector in a React component:
 * const filteredCases = useSelector(selectCases(id, date), shallowEqual);
 *
 * Using memoized selector:
 * const loading = useMemoizedSelector(selectCases, [id, date]);
 *
 */

import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const useMemoizedSelector = (makeSelectorFunction, additionParameters = []) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedSelectorFn = useMemo(() => makeSelectorFunction(...additionParameters), additionParameters);
    return useSelector(memoizedSelectorFn, shallowEqual);
};

export default useMemoizedSelector;

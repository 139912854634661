/*
 *
 * Login reducer
 *
 */

import { fromJS } from 'immutable';
import {
    LOGIN_START,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    UPDATE_LOGIN_USER,
    SIGN_IN_WITH_EMAIL,
    UPDATE_AUTH_STATE,
    REGISTERED_EMAIL,
    UPDATE_FORGOT_PASSWORD_STATE,
} from './constants';

const initialState = fromJS({
    loading: false,
    err: '',
    authState: SIGN_IN_WITH_EMAIL,
    forgotPwdState: REGISTERED_EMAIL,
    userInfo: {
        username: '',
        email: '',
        challengeParameters: {},
    }
});

function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_START:
            return state
                .set('loading', true)
                .set('err', '');
        
        case LOGIN_ERROR: {
            const { err } = action.data;
            return state
                .set('loading', false)
                .set('err', err);
        }

        case LOGIN_SUCCESS:
            return state
                .set('loading', false)
                .set('err', '');

        case UPDATE_LOGIN_USER: {
            const { username, email, challengeParameters } = action.data;

            const existingUsername = state.getIn(['userInfo', 'username']);
            const newUsername = username || existingUsername;

            const existingEmail = state.getIn(['userInfo', 'email']);
            const newEmail = email || existingEmail;

            const existingChallengeParameters = state.getIn(['userInfo', 'challengeParameters']);
            const newChallengeParameters = challengeParameters || existingChallengeParameters;

            return state
                .setIn(['userInfo', 'username'], newUsername)
                .setIn(['userInfo', 'email'], newEmail)
                .setIn(['userInfo', 'challengeParameters'], fromJS(newChallengeParameters));
        }

        case UPDATE_AUTH_STATE: {
            const { newAuthState } = action.data;
            return state.set('authState', newAuthState);
        }

        case UPDATE_FORGOT_PASSWORD_STATE: {
            const { newForgotPwdState } = action.data;
            return state.set('forgotPwdState', newForgotPwdState);
        }

        default:
            return state;
    }
}

export default loginReducer;

/**
 * @file Manages the reducer state & function for Bulk Ticket Update component
 */

import { fromJS } from 'immutable';

import {
    SET_UPLOAD_CSV_FORM_PARAMS, RESET_UPLOAD_CSV_FORM_PARAMS, SET_SEARCH_PARAMS, RESET_SEARCH_PARAMS,
    UPDATE_BULK_TICKETS_INFO, SET_UPDATE_BULK_TICKETS_INFO, ERR_UPDATE_BULK_TICKETS_INFO,
    GET_BULK_TICKET_JOB_LIST, SET_BULK_TICKET_JOB_LIST, ERR_BULK_TICKET_JOB_LIST,
    GET_BULK_TICKET_JOB_FAILURE_INFO, SET_BULK_TICKET_JOB_FAILURE_INFO, ERR_BULK_TICKET_JOB_FAILURE_INFO,
    KILL_BULK_TICKET_JOB, RESET_LOADER,
} from './constants';

const initialState = fromJS({
    uploadCSVFormParams: {
        description: '',
        emailId: '',
        csvFile: null,
    },
    searchParams: {},
    isLoading: false,
    updatedBulkTicketJobInfo: {},
    bulkTicketJobList: {},
    bulkTicketJobFailureInfo: {},
});

function bulkTicketUpdateReducer(state = initialState, action) {
    switch (action.type) {
        case SET_UPLOAD_CSV_FORM_PARAMS: {
            const { key, value } = action.data;

            if (key && value) {
                return state.setIn(['uploadCSVFormParams', key], value);
            }

            return state;
        }
        
        case RESET_UPLOAD_CSV_FORM_PARAMS: {
            return state.set('uploadCSVFormParams', fromJS(initialState.toJS().uploadCSVFormParams));
        }

        case SET_SEARCH_PARAMS: {
            const {
                key, value, fromDate, toDate,
            } = action.data;

            if (key && value) {
                return state.setIn(['searchParams', key], value);
            }

            if (fromDate && toDate) {
                return state
                    .setIn(['searchParams', 'fromDate'], fromJS(fromDate))
                    .setIn(['searchParams', 'toDate'], fromJS(toDate));
            }

            return state;
        }

        case RESET_SEARCH_PARAMS: {
            return state.set('searchParams', fromJS({}));
        }

        case UPDATE_BULK_TICKETS_INFO:
            return state
                .set('isLoading', true)
                .set('updatedBulkTicketJobInfo', fromJS({}));

        case SET_UPDATE_BULK_TICKETS_INFO: {
            const { jobInfo } = action.data;

            return state
                .set('isLoading', false)
                .set('updatedBulkTicketJobInfo', fromJS(jobInfo));
        }

        case ERR_UPDATE_BULK_TICKETS_INFO: {
            return state
                .set('isLoading', false)
                .set('updatedBulkTicketJobInfo', fromJS({}));
        }

        case GET_BULK_TICKET_JOB_LIST:
            return state.set('isLoading', true);

        case SET_BULK_TICKET_JOB_LIST: {
            const { jobList: { data, prev, next } } = action.data;

            return state
                .set('isLoading', false)
                .setIn(['bulkTicketJobList', 'searchCompleted'], true)
                .setIn(['bulkTicketJobList', 'err'], '')
                .setIn(['bulkTicketJobList', 'data'], fromJS(data))
                .setIn(['bulkTicketJobList', 'prev'], fromJS(prev))
                .setIn(['bulkTicketJobList', 'next'], fromJS(next));
        }

        case ERR_BULK_TICKET_JOB_LIST: {
            const { err } = action.data;

            return state
                .set('isLoading', false)
                .setIn(['bulkTicketJobList', 'searchCompleted'], true)
                .setIn(['bulkTicketJobList', 'err'], err)
                .setIn(['bulkTicketJobList', 'data'], fromJS([]))
                .setIn(['bulkTicketJobList', 'prev'], fromJS({}))
                .setIn(['bulkTicketJobList', 'next'], fromJS({}));;
        }

        case GET_BULK_TICKET_JOB_FAILURE_INFO:
            return state
                .set('isLoading', true)
                .setIn(['bulkTicketJobFailureInfo', 'hasInfo'], false);

        case SET_BULK_TICKET_JOB_FAILURE_INFO: {
            const { failureList } = action.data;

            return state
                .set('isLoading', false)
                .setIn(['bulkTicketJobFailureInfo', 'hasInfo'], true)
                .setIn(['bulkTicketJobFailureInfo', 'failureList'], fromJS(failureList));
        }

        case ERR_BULK_TICKET_JOB_FAILURE_INFO:
            return state
                .set('isLoading', false)
                .set('bulkTicketJobFailureInfo', fromJS({}));

        case KILL_BULK_TICKET_JOB:
            return state.set('isLoading', true);

        case RESET_LOADER:
            return state.set('isLoading', false);

        default:
            return state;
    }
}

export default bulkTicketUpdateReducer;

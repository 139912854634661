/**
 * CreateNewTicketCTA - CTA to submit the request to create a new ticket in freshdesk
 */

import React, { memo, useContext, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Button, Loader, Modal, RadioButtonList } from '../../../components';
import { MetaInfoContext } from '../../../context';
import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';
import { makeSelectAgentInfo } from '../../AppV2/selectors';

import { createTicketForCall, setSelectedCallInfo } from '../actions';
import { makeSelectLoading, makeSelectSelectedCallInfo } from '../selectors';

// custom styles for reason modal
const customModalStyles = {
    content: {
        padding: 32,
        width: 800,
        maxHeight: 500,
    },
};

const CreateNewTicketCTA = (props) => {
    const { isLinkButtonType, reasonList, selectedRowData } = props;

    const metaInfo = useContext(MetaInfoContext);
    const {
        tickets: {
            reasonInfo: {
                enableReasonFlow,
            },
        },
    } = metaInfo;

    const dispatch = useDispatch();

    // reducer state variables
    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const selectedCallInfo = useSelector(makeSelectSelectedCallInfo(), shallowEqual);
    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);

    // local state variables
    const [reasonModalVisibility, setReasonModalVisibility] = useState(false);
    const [reasonErr, setReasonErr] = useState(false);
    const [reasonText, setReasonText] = useState('');

    // destructured variables
    const { createNewTicketView: createNewTicketViewLoading } = loading;
    const { ucid: selectedCallUcid } = selectedCallInfo;

    const createNewTicketForCall = (curCallUcid) => {
        dispatch(createTicketForCall({
            ucid: curCallUcid,
            reason: [reasonText], // wrap reason in an array since rpc expects a list
        }));
    };

    const resetReasonState = () => {
        setReasonErr(false);
        setReasonText('');
        setReasonModalVisibility(false);
    };

    const handleReasonSubmit = () => {
        if (!reasonText) {
            setReasonErr(true);
            return;
        }

        const rudderProperties = { ucid: selectedCallUcid, reasonText };
        pushRudderEvent(RudderEvent.SherlockV1Agent.AgentClickCreateANewTicket, agentInfo.emailId, rudderProperties);

        createNewTicketForCall(selectedCallUcid);
        resetReasonState();
    };

    const handleCreateNewTicketCTA = () => {
        let curCallUcid = selectedCallUcid;

        if (selectedRowData) {
            const { ucid, callerUserId } = selectedRowData;
            curCallUcid = ucid;

            dispatch(setSelectedCallInfo({ ucid, callerUserId }));
        }

        // if reason flow enabled, show modal with reason list
        if (enableReasonFlow) {
            setReasonModalVisibility(true);
            return;
        }

        // else, directly call create new ticket
        createNewTicketForCall(curCallUcid);
    };

    let isLink = false;
    let label = 'create a new ticket in freshdesk';

    if (isLinkButtonType) {
        isLink = true;
        label = 'Create New Ticket';
    }

    return (
        <React.Fragment>
            <Button
                v2
                link={isLink}
                primary={!isLink}
                label={label}
                onClick={handleCreateNewTicketCTA}
            />

            <Modal
                visible={reasonModalVisibility}
                modalStyles={customModalStyles}
                contentLabel='Please enter reason for creating new ticket'
            >
                <div className='defr-ua-modal-hd'>Please enter reason for creating new ticket</div>

                <RadioButtonList
                    dataList={reasonList}
                    listName='new-ticket-reason-list'
                    setSelectedData={setReasonText}
                    extClasses={{
                        container: 'mb-60 of-auto',
                    }}
                />

                {reasonErr && <div className='err-label mb-15'>Please select an option from the above list</div>}

                <div className='frccWrapper'>
                    <Button
                        v2
                        primary
                        label='Submit'
                        onClick={handleReasonSubmit}
                    />
                    <Button
                        v2
                        secondary
                        label='Cancel'
                        onClick={resetReasonState}
                        extClasses='ml-30'
                    />
                </div>

            </Modal>
            <Loader visible={createNewTicketViewLoading} />
        </React.Fragment>
    );
};

export default memo(CreateNewTicketCTA);

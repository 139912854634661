/**
 *
 * ClaimedOffersView - Component to show the claimed offers list by current user
 *
 */

import React, { useState } from 'react';
import Modal from 'react-modal';

import { Button, Table } from '../../../components';

import MarkAgainstTicketCheckbox from '../MarkAgainstTicketCheckbox';

import { CLAIMED_OFFERS_LABEL_DATA } from '../constants';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 500,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const ClaimedOffersView = (props) => {
    // local state variables
    const [selectedRedeemedOfferDetails, setSelectedRedeemedOfferDetails] = useState({});
    const [showRedeemedOfferDetailsModal, setRedeemedOfferDetailsModal] = useState(false);

    const {
        data, prev, next, getPaginatedData,
    } = props;

    const renderActions = (item) => (
        <div className='frccWrapper'>
            <Button
                link
                label='View Details'
                onClick={() => {
                    setSelectedRedeemedOfferDetails(item.redeemedOfferDetails);
                    setRedeemedOfferDetailsModal(true);
                }}
            />
        </div>
    );

    const renderCheckboxColumn = (item) => {
        const { entityMeta: itemEntityMeta } = item;

        return <MarkAgainstTicketCheckbox isClaimsView entityMeta={itemEntityMeta} />;
    };

    return (
        <React.Fragment>
            <Table
                labelData={CLAIMED_OFFERS_LABEL_DATA}
                contentData={data}
                renderCheckboxColumn={renderCheckboxColumn}
                renderActionableColumn={renderActions}
                prevToken={prev}
                nextToken={next}
                paginatedClickHandler={getPaginatedData}
                extClasses={{
                    container: 'rewards-src3',
                }}
            />
            <Modal
                isOpen={showRedeemedOfferDetailsModal}
                style={customStylesForModal}
                contentLabel='Redeemed Offer Details Modal'
            >
                <div className='rewards-modal-wrap'>
                    {JSON.stringify(selectedRedeemedOfferDetails, null, 4)}
                </div>
                <Button
                    extClasses='rewards-modal-cta'
                    primary
                    label='Close'
                    onClick={() => {
                        setSelectedRedeemedOfferDetails({});
                        setRedeemedOfferDetailsModal(false);
                    }}
                />
            </Modal>
        </React.Fragment>
    );
};

export default ClaimedOffersView;

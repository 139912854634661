/**
 * @file Manages the saga generator functions for Tiering component
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { TIERING_ROOT } from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_TIERING_DETAILS,
    SET_TIERING_DETAILS,
    ERR_TIERING_DETAILS,
    SET_TIERING_GRACE,
    ERR_TIERING_GRACE,
    SET_TIERING_COOLOFF,
    ERR_TIERING_COOLOFF,
    GET_TIERING_SENSITIVE_DETAILS,
    SET_TIERING_SENSITIVE_DETAILS, ERR_TIERING_SENSITIVE_DETAILS,
} from './constants';

function* getUserTieringInfo(action) {
    const { ticketId, userId, index } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TIERING_ROOT}/user-details?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_TIERING_DETAILS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_TIERING_DETAILS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TIERING_DETAILS, data: { ticketId, err } });
    }
}

function* getUserTieringSensitiveInfo(action) {
    const { ticketId, index, userId } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TIERING_ROOT}/user-details/movement-histories?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_TIERING_SENSITIVE_DETAILS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_TIERING_SENSITIVE_DETAILS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TIERING_SENSITIVE_DETAILS, data: { ticketId, err } });
    }
}

function* overrideGracePeriod(action) {
    const { ticketId, userId, overrideTimestamp } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId, overrideTimestamp });

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${TIERING_ROOT}/override-grace?${queryString}`,
        );

        yield call(getUserTieringInfo, { data: action.data });

        toastify('Grace Period Overridden Successfully', 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TIERING_GRACE, data: { ticketId, err } });
    }
}

function* overrideCooloffPeriod(action) {
    const { ticketId, userId } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${TIERING_ROOT}/override-cooloff?${queryString}`,
        );

        yield call(getUserTieringInfo, { data: action.data });

        toastify('CoolOff Period Overridden Successfully', 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TIERING_COOLOFF, data: { ticketId, err } });
    }
}

export default function* tieringSaga() {
    yield all(
        [
            yield takeLatest(GET_TIERING_DETAILS, getUserTieringInfo),
            yield takeLatest(SET_TIERING_GRACE, overrideGracePeriod),
            yield takeLatest(SET_TIERING_COOLOFF, overrideCooloffPeriod),
            yield takeLatest(GET_TIERING_SENSITIVE_DETAILS, getUserTieringSensitiveInfo),
        ],
    );
}

/**
 * @file Manages the selector functions for Issue Config
 */

import { createSelector } from 'reselect';

import { ISSUE_CONFIG_FILTER_KEY, ISSUE_CONFIG_HISTORY_KEY, ISSUE_CONFIG_KEY, UPDATE_ISSUE_CONFIG_KEY } from './constants';

const selectIssueConfig = (state) => state.getIn([ISSUE_CONFIG_KEY, ISSUE_CONFIG_KEY]);
const selectIssueConfigFilterOptions = (state) => state.getIn([ISSUE_CONFIG_KEY, ISSUE_CONFIG_FILTER_KEY]);
const selectIssueConfigHistory = (state) => state.getIn([ISSUE_CONFIG_KEY, ISSUE_CONFIG_HISTORY_KEY]);
const selectUpdateIssueConfig = (state) => state.getIn([ISSUE_CONFIG_KEY, UPDATE_ISSUE_CONFIG_KEY]);

const makeSelectIssueConfig = () => createSelector(
    selectIssueConfig,
    (substate) => substate.toJS(),
);

const makeSelectIssueConfigFilterOptions = () => createSelector(
    selectIssueConfigFilterOptions,
    (substate) => substate.toJS(),
);

const makeSelectIssueConfigHistory = () => createSelector(
    selectIssueConfigHistory,
    (substate) => substate.toJS(),
);

const makeSelectUpdateIssueConfig = () => createSelector(
    selectUpdateIssueConfig,
    (substate) => substate.toJS(),
);

export {
    makeSelectIssueConfig,
    makeSelectIssueConfigFilterOptions,
    makeSelectIssueConfigHistory,
    makeSelectUpdateIssueConfig,
};

/*
 *
 * ActionsActions actions
 *
 */

import {
    GET_ACTION_LIST, GET_PARAMETER_LIST, SET_PARAMETER_VALUE, EXECUTE_ACTION,
} from './constants';

export const getActionList = data => ({
    type: GET_ACTION_LIST,
    data
});

export const getParameterList = data => ({
    type: GET_PARAMETER_LIST,
    data
});

export const setParameterValue = data => ({
    type: SET_PARAMETER_VALUE,
    data
});

export const executeAction = data => ({
    type: EXECUTE_ACTION,
    data
});

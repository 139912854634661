import {
    SET_MONORAIL_ID, GET_USER_DETAILS, SET_LOCAL_USER_DETAILS, SET_ACTIVE_ID, CLOSE_NAV_ITEM,
} from './constants';

export const setMonorailId = (data) => ({
    type: SET_MONORAIL_ID,
    data,
});

export const getUserDetails = (data) => ({
    type: GET_USER_DETAILS,
    data,
});

export const setLocalUserDetails = (data) => ({
    type: SET_LOCAL_USER_DETAILS,
    data,
});

export const setActiveId = (data) => ({
    type: SET_ACTIVE_ID,
    data,
});

export const closeNavItem = (data) => ({
    type: CLOSE_NAV_ITEM,
    data,
});

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { ACCOUNTS_ROOT } from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_DEPOSITS_LIST, SET_DEPOSITS_LIST, ERR_DEPOSITS_LIST,
    GET_DEPOSIT_REQUEST_LIST, SET_DEPOSIT_REQUEST_LIST, ERR_DEPOSIT_REQUEST_LIST,
    GET_DEPOSIT_TXN_LIST, SET_DEPOSIT_TXN_LIST, ERR_DEPOSIT_TXN_LIST,
} from './constants';

function* getDepositsList(action) {
    const { ticketId, userId, index, accountType, depositNumber, searchBy,
        fromCreatedDate, toCreatedDate, fromMaturityDate, toMaturityDate, } = action.data;

    let queryParams = `?ticketId=${ticketId}&userId=${userId}&accountType=${accountType}`;

    if (searchBy === 'Deposit Number') {
        queryParams += `&depositNumber=${depositNumber}`;
    } else if (searchBy === 'Created Date') {
        queryParams += `&fromCreatedDate=${fromCreatedDate}&toCreatedDate=${toCreatedDate}`;
    } else if (searchBy === 'Maturity Date') {
        queryParams += `&fromMaturityDate=${fromMaturityDate}&toMaturityDate=${toMaturityDate}`;
    }

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ACCOUNTS_ROOT}/${queryParams}`
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_DEPOSITS_LIST, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_DEPOSITS_LIST, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_DEPOSITS_LIST, data: { ticketId, err: e.message } });
    }
}

function* getDepositRequestList(action) {
    const { ticketId, userId, index, fromCreatedDate, toCreatedDate } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId, from: fromCreatedDate, to: toCreatedDate });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ACCOUNTS_ROOT}/requests?${queryString}`
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_DEPOSIT_REQUEST_LIST, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_DEPOSIT_REQUEST_LIST, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_DEPOSIT_REQUEST_LIST, data: { ticketId, err: e.message } });
    }
}

function* getDepositTxnList(action) {
    const { ticketId, userId, index, depositId, prev, next, setIsTxnsModalOpen } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId, prev, next });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ACCOUNTS_ROOT}/${btoa(depositId)}/txns?${queryString}`
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_DEPOSIT_TXN_LIST, data: { ticketId, ...response }});
            
            if (setIsTxnsModalOpen) {
                yield call(setIsTxnsModalOpen, true);
            }
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_DEPOSIT_TXN_LIST, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_DEPOSIT_TXN_LIST, data: { ticketId, err: e.message } });
    }
}

export default function* depositsSaga() {
    yield all(
        [
            yield takeLatest(GET_DEPOSITS_LIST, getDepositsList),
            yield takeLatest(GET_DEPOSIT_REQUEST_LIST, getDepositRequestList),
            yield takeLatest(GET_DEPOSIT_TXN_LIST, getDepositTxnList),
        ]
    );
}

/*
 *
 * AgentCreateForm constants
 *
 */

export const FORM_INITIAL_VALUES = 'app/AgentCreateForm/FORM_INITIAL_VALUES';
export const GET_ACCESS_LEVEL = 'app/AgentCreateForm/GET_ACCESS_LEVEL';
export const SET_ACCESS_LEVEL = 'app/AgentCreateForm/SET_ACCESS_LEVEL';

export const AGENT_TYPES = [
    {
        label: 'EMPLOYEE',
        value: 'EMPLOYEE',
    },
    {
        label: 'CONSULTANT',
        value: 'CONSULTANT',
    },
    {
        label: 'CUSTOMER_SUPPORT',
        value: 'CUSTOMER_SUPPORT',
    },
];

export const AGENT_STATUS = [
    {
        label: 'ACTIVE',
        value: 'ACTIVE',
    },
    {
        label: 'INACTIVE',
        value: 'INACTIVE',
    },
];

export const TICKET_SCOPES = [
    {
        label: 'GLOBAL_ACCESS',
        value: 'GLOBAL_ACCESS',
    },
    {
        label: 'GROUP_ACCESS',
        value: 'GROUP_ACCESS',
    },
    {
        label: 'RESTRICTED_ACCESS',
        value: 'RESTRICTED_ACCESS',
    },
];

export const FRESHDESK_AGENT_TYPES = [
    {
        label: 'FULL_TIME',
        value: 'FULL_TIME',
    },
    {
        label: 'OCCASIONAL',
        value: 'OCCASIONAL',
    },
];

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { NON_SENSITIVE_INFO_ROOT } from '../../api/routes';

import {
    GET_WAITLIST_USER_INFO, SET_WAITLIST_USER_INFO, ERR_WAITLIST_USER_INFO,
} from './constants';

function* getWaitlistUserInfo(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${NON_SENSITIVE_INFO_ROOT}/waitlist?${queryString}`
        );

        yield put({ type: SET_WAITLIST_USER_INFO, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_WAITLIST_USER_INFO, data: { err: e.message } });
    }
}

export default function* waitlistDetailsSaga() {
    yield all(
        [
            yield takeLatest(GET_WAITLIST_USER_INFO, getWaitlistUserInfo),
        ]
    );
}

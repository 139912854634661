/**
 * @file Manages the utility functions for Pre Approved Loans component
 */

const getUserInfoList = (data) => ([
    {
        label: 'Is User Being Suggested Loan Feature From Fi For current month?',
        value: data.isUserSuggestedForLoan,
    },
    {
        label: 'Reason for ineligibility',
        value: data.reasonForIneligibility,
    },
]);

const getUserLoanInfoList = (data) => ([
    {
        label: 'Loan application Status',
        value: data.loanApplicationStatus,
    },
    {
        label: 'Loan application sub status',
        value: data.loanApplicationSubStatus,
    },
    {
        label: 'Loan Amount Applied',
        value: data.loanAmountApplied,
    },
    {
        label: 'Tenure',
        value: data.tenureAppliedMonths,
    },
    {
        label: 'Vendor',
        value: data.loanApplicationVendor,
    },
    {
        label: 'Loan Program',
        value: data.loanApplicationLoanProgram,
    },
]);

export {
    getUserInfoList,
    getUserLoanInfoList,
};

/*
 *
 * Liveness review selectors
 *
 */

import { createSelector } from 'reselect';

export const selectLoading = () => createSelector(
    (state) => state.getIn(['livenessReview', 'loading']),
    (subState) => subState,
);

export const selectErr = () => createSelector(
    (state) => state.getIn(['livenessReview', 'err']),
    (subState) => subState,
);

export const selectLivenessCasesList = () => createSelector(
    (state) => state.getIn(['livenessReview', 'livenessCasesList']),
    (subState) => subState.toJS(),
);

export const selectLivenessView = () => createSelector(
    (state) => state.getIn(['livenessReview', 'livenessView']),
    (subState) => subState,
);

export const selectLivenessVideoUrl = () => createSelector(
    (state) => state.getIn(['livenessReview', 'livenessVideoUrl']),
    (subState) => subState,
);

export const selectLivenessPayloadType = () => createSelector(
    (state) => state.getIn(['livenessReview', 'livenessPayloadType']),
    (subState) => subState.toJS(),
);

export const selectCurLivenessCase = () => createSelector(
    (state) => state.getIn(['livenessReview', 'curLivenessCase']),
    (subState) => subState.toJS(),
);

export const selectLivenessPendingReviewCount = () => createSelector(
    (state) => state.getIn(['livenessReview', 'pendingReviewCount']),
    (subState) => subState,
);

export const selectLivenessQueueFilters = () => createSelector(
    (state) => state.getIn(['livenessReview', 'queueFilters']),
    (subState) => subState.toJS(),
);

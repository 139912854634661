/**
 * @file Debit vs Credit Bar chart
 */

import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import BarChart from '../../components/d3/BarChartV2';

import { BARCHART_DIMENSIONS, staticColorMapping } from './constants';
import './style.scss';
import { useBarCustomXScale } from '../../components/d3/useBarXScale';
import { getAggregateDataPointsTV2 } from './actions';
import { selectAggregatedValue } from './selectors';
import {
    ExpandableCard,
} from '../../components';
import { aggregatedDataGrouper } from './utils';

const CustomBarChart = (props) => {
    const {
        yValue, aggregation, onboardingDate, caseId,
    } = props;

    const data = useSelector(selectAggregatedValue, shallowEqual);

    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(true);

    const {
        xScale, setXScale, xScaleTemplate, zoomTransform, setZoomTransform,
    } = useBarCustomXScale({
        aggregation,
        dimensions: BARCHART_DIMENSIONS,
        data: (data || {}).dataPoints || [],
    });

    useEffect(() => {
        if (caseId) dispatch(getAggregateDataPointsTV2({ caseId }));
    }, [caseId, dispatch]);

    const aggregatedData = useMemo(
        () => aggregatedDataGrouper((data || {}).dataPoints, aggregation, 'type'),
        [aggregation, data],
    );

    if (!data) return null;

    return (
        <ExpandableCard
            extClasses='trv2-expand-card mb-30 pb-20'
            isExpanded={isExpanded}
            setIsExpanded={() => setIsExpanded((prev) => !prev)}
            heading={data.title || ''}
        >
            <div className='trv2-dcc-c'>
                <div className='m-auto'>
                    {
                        data.title && (
                            <BarChart
                                enableGraphTypeDropdown={false}
                                aggregation={aggregation}
                                yValue={yValue}
                                dimensions={BARCHART_DIMENSIONS}
                                data={aggregatedData}
                                bars={data.bars}
                                legendPlacement='bottom'
                                headingPlacement='top'
                                heading={data.title}
                                xScale={xScale}
                                setXScale={setXScale}
                                xScaleTemplate={xScaleTemplate}
                                zoomTransform={zoomTransform}
                                setZoomTransform={setZoomTransform}
                                onboardingDate={onboardingDate}
                                graphTypeValue='GROUPED'
                                horizontalRefLines={data.xReferenceLines}
                                xRefs={data.xRefs}
                                barsCount={data.barCount}
                                staticColorMapping={staticColorMapping}
                            />
                        )
                    }
                </div>
                <div>*Timeline for this graph is lifetime and will not vary based on time filters</div>
            </div>
        </ExpandableCard>

    );
};
export default React.memo(CustomBarChart);

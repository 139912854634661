/**
 * WebDeveloper
 * Landing Page for Web Developer Route
 * All routes related to Web Developer flow would be listed here
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { VerticalNavbar } from '../../components';
import {
    INSTANT_INDEXING_PATH,
} from '../App/routes';

import InstantIndexing from '../InstantIndexing';

import { getSideNavbarItems } from './utils';

import './style.scss';

const WebDeveloper = () => {
    const renderRoutes = () => {
        return (
            <Switch>
                <Route
                    path={INSTANT_INDEXING_PATH}
                    component={InstantIndexing}
                />
                {/* in case of unmatched url, redirect the user to Instant Indexing page */}
                <Redirect to={{ pathname: INSTANT_INDEXING_PATH }} />
            </Switch>
        );
    };

    return (
        <div className='web-dev-cr'>
            <VerticalNavbar
                hideHorizontalNavbar
                navItems={getSideNavbarItems()}
            />
            <div className='web-dev-cc'>
                {renderRoutes()}
            </div>
        </div>
    );
};

export default WebDeveloper;

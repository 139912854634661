/**
 *
 * @file USSOrderView : Expandable card to store order id for searching.
 *
 */

import React, { useState, memo } from 'react';

import {
    Button, ExpandableCard, FormWithSingleInput,
} from '../../../components';

const USSOrderView = (props) => {
    const {
        searchAction,
        heading = 'View user investment details - Search by Order Id',
        inputLabel = 'Order Id',
    } = props;

    const [isSearchCardExpanded, setSearchCardExpanded] = useState(true);
    const [errLabel, setErrLabel] = useState('');
    const [textInput, setTextInput] = useState();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!textInput) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        setErrLabel(null);
        searchAction({ textInput });
    };

    const handleInputChange = (e) => {
        setTextInput(e.target.value);
    };

    return (
        <div className='uss-cr'>
            <ExpandableCard
                extClasses='expandable-card uss-expandable-card'
                heading={heading}
                isExpanded={isSearchCardExpanded}
                setIsExpanded={() => setSearchCardExpanded(!isSearchCardExpanded)}
            >
                <div className='uss-sc'>
                    <div className='frfssbWrapper'>
                        <FormWithSingleInput
                            disableFormSubmitOnEnter
                            extClasses={{
                                container: 'uss-fc',
                                label: 'uss-fc__lb',
                                inputField: 'uss-fc__ip',
                                errLabel: 'uss-fc__err',
                            }}
                            label={`${inputLabel}*`}
                            labelId='mf-order-id'
                            formType='text'
                            input={{
                                value: textInput,
                                onChange: handleInputChange,
                                minLength: 11,
                            }}
                            errLabel={errLabel}
                        />
                        <Button
                            extClasses='uss-sc__cta'
                            primary
                            label='Search'
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </ExpandableCard>
        </div>
    );
};

export default memo(USSOrderView);

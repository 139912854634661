/**
 * @file Key Transaction Trends
 */

import React, { useEffect, useMemo, useState } from 'react';

import BarChart from '../../components/d3/BarChart';
import useBarXScale from '../../components/d3/useBarXScale';

import { SUCCESS, BARCHART_DIMENSIONS } from './constants';
import { dataGrouper } from './utils';

const KeyTransactionTrends = (props) => {
    const {
        data, yValue, aggregation, cdType, onboardingDate,
    } = props;

    const [modifiedData, setModifiedData] = useState([]);

    const {
        xScale, setXScale, xScaleTemplate, zoomTransform, setZoomTransform,
    } = useBarXScale({
        aggregation,
        dimensions: BARCHART_DIMENSIONS,
        data: modifiedData,
    });

    useEffect(() => {
        if (data) {
            // filtering data based on cdType and mapping the required values
            const modData = data
                .filter((item) => (
                    // remove credit or debit transction based on cdType
                    item.accounting_entry_type === cdType)
                    // either credited_at or debited_at should be valid
                    && (!!item?.executed_at)
                    // filter success transactions only
                    && (item.transaction_status && item.transaction_status.includes(SUCCESS)))
                .map((item) => ({
                    amount: item.amount,
                    date: item?.executed_at,
                    payment_protocol: item.payment_protocol,
                    provenance: item.provenance,
                    money_flow: item.money_flow,
                }));

            setModifiedData(modData);
        }
    }, [data, cdType]);

    const protocolData = useMemo(() => dataGrouper(modifiedData, aggregation, 'payment_protocol'), [modifiedData, aggregation]);

    const provenanceData = useMemo(() => dataGrouper(modifiedData, aggregation, 'provenance'), [modifiedData, aggregation]);

    const moneyFlowData = useMemo(() => dataGrouper(modifiedData, aggregation, 'money_flow'), [modifiedData, aggregation]);

    return (

        <div className='trv2-ktt-c'>
            {
                modifiedData.length
                    ? (
                        <React.Fragment>
                            <div className='m-auto'>
                                <BarChart
                                    aggregation={aggregation}
                                    yValue={yValue}
                                    barKey='payment_protocol'
                                    data={protocolData}
                                    dimensions={BARCHART_DIMENSIONS}
                                    legendPlacement='bottom'
                                    headingPlacement='top'
                                    heading='Protocol'
                                    xScale={xScale}
                                    setXScale={setXScale}
                                    xScaleTemplate={xScaleTemplate}
                                    zoomTransform={zoomTransform}
                                    setZoomTransform={setZoomTransform}
                                    onboardingDate={onboardingDate}
                                />
                            </div>
                            <div className='m-auto'>
                                <BarChart
                                    aggregation={aggregation}
                                    yValue={yValue}
                                    barKey='provenance'
                                    data={provenanceData}
                                    dimensions={BARCHART_DIMENSIONS}
                                    legendPlacement='bottom'
                                    headingPlacement='top'
                                    heading='Provenance'
                                    xScale={xScale}
                                    setXScale={setXScale}
                                    xScaleTemplate={xScaleTemplate}
                                    zoomTransform={zoomTransform}
                                    setZoomTransform={setZoomTransform}
                                    onboardingDate={onboardingDate}
                                />
                            </div>
                            <div className='m-auto'>
                                <BarChart
                                    aggregation={aggregation}
                                    yValue={yValue}
                                    barKey='money_flow'
                                    data={moneyFlowData}
                                    dimensions={BARCHART_DIMENSIONS}
                                    legendPlacement='bottom'
                                    headingPlacement='top'
                                    heading='P2P & P2M'
                                    xScale={xScale}
                                    setXScale={setXScale}
                                    xScaleTemplate={xScaleTemplate}
                                    zoomTransform={zoomTransform}
                                    setZoomTransform={setZoomTransform}
                                    onboardingDate={onboardingDate}
                                />
                            </div>
                        </React.Fragment>
                    )
                    : null
            }
        </div>
    );
};
export default React.memo(KeyTransactionTrends);

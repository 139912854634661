import { fromJS } from 'immutable';

import {
    GET_ACCOUNTS_LIST, SET_ACCOUNTS_LIST, ERR_ACCOUNTS_LIST,
} from './constants';

const initialState = fromJS({
    accountsList: {},
});

function userActionsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ACCOUNTS_LIST: {
            const { ticketId } = action.data;

            return state
                .setIn(['accountsList', ticketId, 'loading'], true);
        }

        case SET_ACCOUNTS_LIST: {
            const { ticketId, accountsList } = action.data;

            return state
                .setIn(['accountsList', ticketId, 'loading'], false)
                .setIn(['accountsList', ticketId, 'data'], fromJS(accountsList))
                .setIn(['accountsList', ticketId, 'hasData'], true);
        }

        case ERR_ACCOUNTS_LIST: {
            const { ticketId } = action.data;

            return state
                .setIn(['accountsList', ticketId, 'loading'], false)
                .setIn(['accountsList', ticketId, 'data'], fromJS([]))
                .setIn(['accountsList', ticketId, 'hasData'], false);
        }

        default:
            return state;
    }
}

export default userActionsReducer;

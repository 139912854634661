/**
 *
 * ClaimedFiStoreRewardsView - Component to show the claimed Fi store rewards list by current user
 *
 */

import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { getParsedJSON } from '../../../utils/helpers';

import { Button, Table, Modal, KVPairInfo } from '../../../components';
import MarkAgainstTicketCheckbox from '../MarkAgainstTicketCheckbox';
import { getFiStoreRewardAdditionalDetails } from '../actions';

const ClaimedFiStoreRewardsView = (props) => {
    const dispatch = useDispatch();

    const {
        getPaginatedData, ticketId, data, additionalData,
    } = props;

    // local state variables
    const [showAdditionalDetailsModal, setShowAdditionalDetailsModal] = useState(false);

    const handleAdditionalDetailsFetching = (meta) => () => {
        const callAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(getFiStoreRewardAdditionalDetails({
                    ticketId, meta, resolve,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callAction().then(() => {
            setShowAdditionalDetailsModal(true);
        });
    };

    const renderActions = (item) => {
        const { meta: itemEntityMeta } = item;
        return (
            <div className='frccWrapper'>
                <Button
                    link
                    label='View Details'
                    onClick={handleAdditionalDetailsFetching(getParsedJSON(itemEntityMeta) || {})}
                />
            </div>
        );
    };

    const renderCheckboxColumn = (item) => {
        const { meta: itemEntityMeta } = item;

        return <MarkAgainstTicketCheckbox isClaimsView entityMeta={getParsedJSON(itemEntityMeta) || itemEntityMeta} />;
    };

    return (
        <React.Fragment>
            <Table
                labelData={data?.columnData}
                contentData={data?.rowData}
                renderCheckboxColumn={renderCheckboxColumn}
                renderActionableColumn={renderActions}
                prevToken={data?.prevToken}
                nextToken={data?.nextToken}
                paginatedClickHandler={getPaginatedData}
                extClasses={{
                    container: 'rewards-src3',
                }}
            />
            <Modal
                visible={showAdditionalDetailsModal}
                contentLabel='Redeemed Offer Details Modal'
            >
                <div className='rewards-modal-wrap'>
                    <KVPairInfo v2 data={additionalData} />
                </div>
                <Button
                    extClasses='rewards-modal-cta'
                    primary
                    label='Close'
                    onClick={() => {
                        setShowAdditionalDetailsModal(false);
                    }}
                />
            </Modal>
        </React.Fragment>
    );
};

ClaimedFiStoreRewardsView.prototypes = {
    getPaginatedData: PropTypes.func,
    ticketId: PropTypes.number,
    data: PropTypes.shape({
        actions: PropTypes.arrayOf(
            PropTypes.string,
        ),
        columnData: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                header_key: PropTypes.string,
                key: PropTypes.string,
                is_visible: PropTypes.bool,
                is_sortable: PropTypes.bool,
            }),
        ),
        rowData: PropTypes.arrayOf(
            PropTypes.objectOf(PropTypes.string),
        ),
        prevToken: PropTypes.shape({
            token: PropTypes.string,
        }),
        nextToken: PropTypes.shape({
            token: PropTypes.string,
        }),
    }),
    additionalData: PropTypes.arrayOf(
        PropTypes.shape({
            data_type: PropTypes.string,
            label: PropTypes.string,
            string_value: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
};

export default memo(ClaimedFiStoreRewardsView);

/**
 * @file Manages the reducer state & function for Autopay component
 */
/* eslint-disable default-param-last */
import { fromJS } from 'immutable';

import {
    GET_EXISTING_MANDATE_DETAILS, SET_EXISTING_MANDATE_DETAILS, ERR_EXISTING_MANDATE_DETAILS,
    GET_UPCOMING_MANDATE_DETAILS, SET_UPCOMING_MANDATE_DETAILS, ERR_UPCOMING_MANDATE_DETAILS,
} from './constants';

const initialState = fromJS({
    upcoming_transaction_details: {},
    existing_mandate_details: {},
});

function autopayReducer(state = initialState, action) {
    switch (action.type) {
        case GET_EXISTING_MANDATE_DETAILS: {
            const { ticketId } = action.data;
            return state
                .setIn(['existing_mandate_details', ticketId, 'loading'], true)
                .setIn(['existing_mandate_details', ticketId, 'err'], '');
        }

        case SET_EXISTING_MANDATE_DETAILS: {
            const { ticketId, existingMandateDetails } = action.data;
            return state
                .setIn(['existing_mandate_details', ticketId, 'data'], fromJS(existingMandateDetails))
                .setIn(['existing_mandate_details', ticketId, 'hasData'], true)
                .setIn(['existing_mandate_details', ticketId, 'loading'], false)
                .setIn(['existing_mandate_details', ticketId, 'err'], false);
        }

        case ERR_EXISTING_MANDATE_DETAILS: {
            const { ticketId, err } = action.data;
            return state
                .setIn(['existing_mandate_details', ticketId, 'data'], fromJS({}))
                .setIn(['existing_mandate_details', ticketId, 'hasData'], false)
                .setIn(['existing_mandate_details', ticketId, 'loading'], false)
                .setIn(['existing_mandate_details', ticketId, 'err'], err);
        }

        case GET_UPCOMING_MANDATE_DETAILS: {
            const { ticketId } = action.data;
            return state
                .setIn(['upcoming_transaction_details', ticketId, 'loading'], true)
                .setIn(['upcoming_transaction_details', ticketId, 'err'], '');
        }

        case SET_UPCOMING_MANDATE_DETAILS: {
            const { ticketId, upcomingTransactionDetails } = action.data;
            return state
                .setIn(['upcoming_transaction_details', ticketId, 'data'], fromJS(upcomingTransactionDetails))
                .setIn(['upcoming_transaction_details', ticketId, 'hasData'], true)
                .setIn(['upcoming_transaction_details', ticketId, 'loading'], false)
                .setIn(['upcoming_transaction_details', ticketId, 'err'], false);
        }

        case ERR_UPCOMING_MANDATE_DETAILS: {
            const { ticketId, err } = action.data;
            return state
                .setIn(['upcoming_transaction_details', ticketId, 'data'], fromJS({}))
                .setIn(['upcoming_transaction_details', ticketId, 'hasData'], false)
                .setIn(['upcoming_transaction_details', ticketId, 'loading'], false)
                .setIn(['upcoming_transaction_details', ticketId, 'err'], err);
        }

        default:
            return state;
    }
}

export default autopayReducer;

import { createSelector } from 'reselect';

const selectMergeTickets = state => state.get('mergeTickets');

const makeSelectRelatedTickets = () => createSelector(
    selectMergeTickets,
    substate => substate.get('relatedTickets').toJS(),
);

export {
    makeSelectRelatedTickets,
};

/**
 * @file Credit Card: Component to show the Credit Card Details.
*/

import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { compose } from 'redux';

import {
    Table, Loader, ActionDialog, CardTrackingInfoModal, TableV1, Toggle,
} from '../../components';
import { injectReducer, injectSaga } from '../../utils';
import { useMemoizedSelector } from '../../hooks';

import { SCREEN, SENSITIVE_ACTIONS } from '../Tickets/constants';
import kycOnboardingReducer from '../KycOnboarding/reducer';
import kycOnboardingSaga from '../KycOnboarding/saga';

import OlderCards from './OlderCards';
import CardLimitsScreen from './CardLimitsScreen';
import CardSettingsScreen from './CardSettingsScreen';
import EligibleScreen from './EligibleScreen';
import LoanAccountDetailsScreen from './LoanAccountDetailsScreen';
import EligibleTransactionsForEmisScreen from './EligibleTransactionsForEmisScreen';
import CardStatus from './CardStatus';
import AuthScreen from './AuthScreen';
import {
    getCurrentCreditCardDetailsAction, getCreditCardControlDetailsAction, getCreditCardTrackingInfoAction, getCreditCardUsageDetailsAction,
    getCurrentLimitUsageDetailsAction, getCreditCardBillingInfoAction, getAllActiveLoanAccountsAction,
    getAllClosedLoanAccountsAction, getLoanAccountDetailsAction, getAllEligibleTransactionsForEmisAction, initiateBlockCardAction,
    setBlockConfirmScreenAction, initiateSuspendCardAction, setSuspendConfirmScreenAction, getFDCreditCardDetails,
    getEligibilityDetailsAction, getCreditCardOnboardingDetailsAction, toggleScreenAction, getCreditCardMilestonRewardsDetails,
} from './actions';
import {
    selectCurrentCreditCardDetails, selectCreditCardLoader, selectCreditCardControlDetails, selectCreditCardTrackingInfo,
    selectCreditCardUsageDetails, selectCreditCardOnboardingDetails, selectCurrentLimitUsageDetails, selectCreditCardBillingInfo,
    selectAllActiveLoanAccounts, selectUpcomingEmiInfo, selectAllClosedLoanAccounts, selectCreditCardError,
    selectAllEligibleTransactionsForEmis, selectLoanAccountDetails, selectBlockCardDetails, selectSuspendCardDetails, selectFDCreditCardDetails,
    selectCreditCardEligibilityDetails, selectSelectedCreditCardDetails,
    selectCreditCardCurrentStageDetails, selectCreditCardNextStageDetails, selectCreditCardKycDetails, selectCreditCardMilestoneRewardsDetails,
} from './selectors';
import {
    CREDIT_CARD_KEY, CURRENT_CREDIT_CARD_DETAILS_LABEL_DATA, CURRENT_LIMIT_USAGE_DETAILS_LABEL_DATA, CREDIT_CARD_BILLING_INFO_LABEL_DATA,
    ALL_ACTIVE_LOAN_ACCOUNTS_LABEL_DATA, ALL_CLOSED_LOAN_ACCOUNTS_LABEL_DATA, IS_LOAN_ACCOUNT_DETAILS_SCREEN, IS_ELIGIBLE_TRANSACTIONS_FOR_EMI_SCREEN,
    IS_BLOCK_CONFIRM_SCREEN, IS_SUSPEND_CONFIRM_SCREEN, CARD_ACTION_TYPE, IS_SELECTED_CREDIT_CARD_SCREEN,
    IS_CREDIT_CARD_CURRENT_STAGE_SCREEN, IS_CREDIT_CARD_NEXT_STAGE_SCREEN, IS_CREDIT_CARD_MILESTONE_REWARDS_SCREEN,
} from './constants';
import { getCardTrackingLabels, getUpcomingEmiBill } from './utils';
import EligibleScreenV2 from './EligibleScreen/v2';
import TableDetailScreen from './TableDetailScreen';
import reducer from './reducer';
import saga from './saga';
import './style.scss';
import { makeSelectVKycDetails } from '../KycOnboarding/selectors';
import KycDetailsScreen from './KycDetailsScreen';
import { MetaInfoContext } from '../../context';
import { hasAccessToThePage } from '../../utils/helpers';
import { setCustomerAuth } from '../Tickets/actions';
import MilestoneRewardsScreen from './MilestoneRewardsScreen';

const CreditCard = (props) => {
    const {
        ticketId, userId, index, pendingAuth, activeTicket,
    } = props;

    const dispatch = useDispatch();

    const { pathname } = useLocation();

    const loader = useSelector(selectCreditCardLoader, shallowEqual);
    const err = useSelector(selectCreditCardError, shallowEqual);

    const currentCreditCardDetails = useMemoizedSelector(selectCurrentCreditCardDetails, [ticketId]);
    const creditCardTrackingInfo = useMemoizedSelector(selectCreditCardTrackingInfo, [ticketId]);
    const creditCardControlDetails = useMemoizedSelector(selectCreditCardControlDetails, [ticketId]);
    const creditCardUsageDetails = useMemoizedSelector(selectCreditCardUsageDetails, [ticketId]);
    const creditCardOnboardingDetails = useMemoizedSelector(selectCreditCardOnboardingDetails, [ticketId]);
    const fdCreditCardDetails = useMemoizedSelector(selectFDCreditCardDetails, [ticketId]);
    const currentLimitUsageDetails = useMemoizedSelector(selectCurrentLimitUsageDetails, [ticketId]);
    const creditCardBillingInfo = useMemoizedSelector(selectCreditCardBillingInfo, [ticketId]);
    const allActiveLoanAccounts = useMemoizedSelector(selectAllActiveLoanAccounts, [ticketId]);
    const upcomingEmiInfo = useMemoizedSelector(selectUpcomingEmiInfo, [ticketId]);
    const allClosedLoanAccounts = useMemoizedSelector(selectAllClosedLoanAccounts, [ticketId]);
    const loanAccountDetails = useMemoizedSelector(selectLoanAccountDetails, [ticketId]);
    const allEligibleTransactionsForEmis = useMemoizedSelector(selectAllEligibleTransactionsForEmis, [ticketId]);
    const blockCardDetails = useMemoizedSelector(selectBlockCardDetails, [ticketId]);
    const suspendCardDetails = useMemoizedSelector(selectSuspendCardDetails, [ticketId]);

    // version 2
    const creditCardEligibilityDetails = useMemoizedSelector(selectCreditCardEligibilityDetails, [ticketId]);
    const kycDetails = useMemoizedSelector(selectCreditCardKycDetails, [ticketId]);
    const selectedCardDetails = useMemoizedSelector(selectSelectedCreditCardDetails, [ticketId]);
    const currentStageDetails = useMemoizedSelector(selectCreditCardCurrentStageDetails, [ticketId]);
    const nextStageDetails = useMemoizedSelector(selectCreditCardNextStageDetails, [ticketId]);
    const milestoneRewardDetails = useMemoizedSelector(selectCreditCardMilestoneRewardsDetails, [ticketId]);
    const vKycOnboardingDetails = useSelector(makeSelectVKycDetails(), shallowEqual);

    const [openedActionsCardId, setOpenedActionsCardId] = useState('');
    const [showCardTrackingModal, setCardTrackingModal] = useState(false);

    const { err: errInCurrentCardDetails, data: currentCardDetails, creditCardNumber } = currentCreditCardDetails || {};
    const { data: cardTrackingInfoData } = creditCardTrackingInfo || {};
    const { isCardLimitsScreen, data: viewUsageLimits } = creditCardControlDetails || {};
    const { isCardSettingsScreen, data: viewUsageSettings } = creditCardUsageDetails || {};
    const { data: creditCardOnboardingDetailsData } = creditCardOnboardingDetails || {};
    // eslint-disable-next-line no-unused-vars
    const { creditCardExists, cardProgramTypeEnum, cardProgramType } = creditCardOnboardingDetailsData ?? {};
    const { err: errInCurrentLimitUsageDetails, data: currentLimitUsageDetailsData } = currentLimitUsageDetails || {};
    const { err: errInCreditCardBillingInfo, data: creditCardBillingInfoData } = creditCardBillingInfo || {};
    const { err: errInAllActiveLoanAccounts, data: allActiveLoanAccountsData } = allActiveLoanAccounts || {};
    const { data: upcomingEmiInfoData } = upcomingEmiInfo || {};
    const { err: errInAllClosedLoanAccounts, data: allClosedLoanAccountsData } = allClosedLoanAccounts || {};
    const { data: loanAccountDetailsData, isLoanAccountDetailsScreen } = loanAccountDetails || {};
    const {
        err: errInAllEligibleTransactionsForEmis,
        data: allEligibleTransactionsForEmisData,
        isEligibleTransactionsForEmiScreen,
    } = allEligibleTransactionsForEmis || {};
    const { isBlockConfirmScreen } = blockCardDetails || {};
    const { isSuspendConfirmScreen } = suspendCardDetails || {};
    const {
        eligibilityDetails,
        offerDetails,
        isCreditCardExist, cardProgramType: cardProgramTypeV2, cardProgramName,
    } = creditCardEligibilityDetails?.data || {};
    const { isCreditCardKycScreen } = kycDetails || {};
    const kycOnboardingDetails = vKycOnboardingDetails[ticketId] || {};
    const { data: selectedCardData, isSelectedCreditCardScreen } = selectedCardDetails || {};
    const { data: currentStageData, isCurrentStageScreen } = currentStageDetails || {};
    const { data: nextStageData, isNextStageScreen } = nextStageDetails || {};
    const {
        data: milestoneRewardData,
        err: milestoneRewardError,
        loading: milestoneRewardLoading,
        isMilestoneRewardsScreen,
    } = milestoneRewardDetails || {};

    const isSuspendCreditPending = pendingAuth[SENSITIVE_ACTIONS.SUSPENDCREDIT];
    const isBlockCreditPending = pendingAuth[SENSITIVE_ACTIONS.BLOCKCREDIT];

    const isPendingAuth = isSuspendCreditPending || isBlockCreditPending;

    const metaInfo = useContext(MetaInfoContext);

    const [showCreditCardDetails, setShowCreditCardDetails] = useState(false);

    const {
        pageVersions: {
            creditCard: creditCardVersion,
        },
    } = metaInfo;

    const isVersion2 = creditCardVersion !== 1;

    const CARD_INFORMATION = useMemo(() => ({
        [CARD_ACTION_TYPE.BLOCK_CARD]: {
            title: 'Block Credit Card',
            buttonLabel: 'Confirm Block',
            descriptionOne: `Are you sure you want to block credit card ${creditCardNumber}?`,
            descriptionTwo:
            'This action cannot be reversed. Blocked card can no longer be used and user will have to request for a new card from the Fi app.',
            onClick: () => handleCardAction(CARD_ACTION_TYPE.BLOCK_CARD, true),
            discardConfirmScreen: () => discardConfirmScreen(CARD_ACTION_TYPE.BLOCK_CARD),
            discardCurrentScreen: IS_BLOCK_CONFIRM_SCREEN,
        },
        [CARD_ACTION_TYPE.SUSPEND_CARD]: {
            title: 'Suspend Credit Card',
            buttonLabel: 'Confirm Suspend',
            descriptionOne: `Are you sure you want to suspend credit card ${creditCardNumber}?`,
            descriptionTwo: 'Suspended card cannot be used temporarily. User can un-suspend the card from the Fi app.',
            onClick: () => handleCardAction(CARD_ACTION_TYPE.SUSPEND_CARD, true),
            discardConfirmScreen: () => discardConfirmScreen(CARD_ACTION_TYPE.SUSPEND_CARD),
            discardCurrentScreen: IS_SUSPEND_CONFIRM_SCREEN,
        },
    }), [creditCardNumber]);

    const handleShowCreditCardDetails = () => {
        if (hasAccessToThePage(activeTicket, SCREEN.MODERATELY_SENSITIVE_SCREEN)) {
            setShowCreditCardDetails((pre) => !pre);
        } else {
            dispatch(setCustomerAuth({
                index,
                isActive: true,
                sensitivityLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                authPathname: pathname,
            }));
        }
    };

    const resetCardState = () => {
        setOpenedActionsCardId('');
    };

    const handleCardAction = (cardAction, isConfirmAction) => {
        const actionFunction = cardAction === CARD_ACTION_TYPE.BLOCK_CARD ? initiateBlockCardAction : initiateSuspendCardAction;

        const payload = {
            ticketId,
            userId,
            index,
            isConfirmAction,
        };

        dispatch(actionFunction(payload));
    };

    const discardConfirmScreen = (cardAction) => {
        const actionFunction = cardAction === CARD_ACTION_TYPE.BLOCK_CARD ? setBlockConfirmScreenAction : setSuspendConfirmScreenAction;

        const payload = {
            ticketId,
            flag: false,
        };

        dispatch(actionFunction(payload));
    };

    const getCardTracking = () => {
        resetCardState();

        const callAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(getCreditCardTrackingInfoAction({
                    ticketId,
                    userId,
                    resolve,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callAction().then(() => {
            setCardTrackingModal(true);
        });
    };

    const handleViewCardUsageLimits = () => {
        resetCardState();

        dispatch(getCreditCardControlDetailsAction({
            ticketId,
            isCardLimitsScreen: true,
        }));
    };

    const handleViewCardUsageSettings = () => {
        resetCardState();

        dispatch(getCreditCardUsageDetailsAction({
            ticketId,
            isCardSettingsScreen: true,
        }));
    };

    const onViewEligbleTransactionClick = () => {
        dispatch(getAllEligibleTransactionsForEmisAction({
            ticketId,
            [IS_ELIGIBLE_TRANSACTIONS_FOR_EMI_SCREEN]: true,
        }));
    };

    const onBlockCardClick = (item) => () => {
        if (!(item.disabledBlockCardAction)) {
            resetCardState();
            handleCardAction(CARD_ACTION_TYPE.BLOCK_CARD);
        }
    };

    const onSuspendCardClick = (item) => () => {
        if (!(item.disabledBlockCardAction || item.disabledSuspendCardAction)) {
            resetCardState();
            handleCardAction(CARD_ACTION_TYPE.SUSPEND_CARD);
        }
    };

    const onMilestoneRewardDetailsClick = () => {
        dispatch(getCreditCardMilestonRewardsDetails({ ticketId }));
        dispatch(toggleScreenAction({ ticketId, isMilestoneRewardsScreen: true }));
    };

    const renderActions = (item) => (
        <ActionDialog item={item} openedActionsCardId={openedActionsCardId} setOpenedActionsCardId={setOpenedActionsCardId}>
            <div className='credit-card-ad-cc-wr'>
                <button
                    disabled={item.disabledBlockCardAction}
                    onClick={onBlockCardClick(item)}
                    type='button'
                    className='credit-card-ad-cc-wr__label'
                >
                    Block Card
                </button>
                <button
                    disabled={item.disabledBlockCardAction || item.disabledSuspendCardAction}
                    onClick={onSuspendCardClick(item)}
                    type='button'
                    className='credit-card-ad-cc-wr__label'
                >
                    Suspend Card
                </button>
                <button
                    onClick={handleViewCardUsageLimits}
                    type='button'
                    className='credit-card-ad-cc-wr__label'
                >
                    View Usage Limits
                </button>
                <button
                    onClick={handleViewCardUsageSettings}
                    type='button'
                    className='credit-card-ad-cc-wr__label'
                >
                    View Usage Settings
                </button>
                <button
                    onClick={getCardTracking}
                    type='button'
                    className='credit-card-ad-cc-wr__label'
                >
                    Tracking Details
                </button>
            </div>
        </ActionDialog>
    );

    const viewMoreEmiDetails = (item) => {
        const { loanAccountId } = item || {};

        const payload = {
            loanAccountId,
            ticketId,
            userId,
            [IS_LOAN_ACCOUNT_DETAILS_SCREEN]: true,
        };

        dispatch(getLoanAccountDetailsAction(payload));
    };

    useEffect(() => {
        if (isVersion2) {
            dispatch(getEligibilityDetailsAction({ ticketId, userId }));
        } else {
            dispatch(getCreditCardOnboardingDetailsAction({ ticketId, userId }));
        }
    }, [ticketId, userId]);

    useEffect(() => {
        if (showCreditCardDetails && (cardProgramType === 'CARD_PROGRAM_TYPE_SECURED' || cardProgramTypeV2 === 'CARD_PROGRAM_TYPE_SECURED')) {
            dispatch(getFDCreditCardDetails({ ticketId, userId }));
        }
    }, [cardProgramType, cardProgramTypeV2, showCreditCardDetails]);

    useEffect(() => {
        if ((creditCardExists || isCreditCardExist) && showCreditCardDetails) {
            dispatch(getCurrentCreditCardDetailsAction({ ticketId, userId }));
            dispatch(getCurrentLimitUsageDetailsAction({ ticketId, userId }));
            dispatch(getCreditCardBillingInfoAction({ ticketId, userId }));
            dispatch(getAllActiveLoanAccountsAction({ ticketId, userId }));
            dispatch(getAllClosedLoanAccountsAction({ ticketId, userId }));
        }
    }, [ticketId, userId, creditCardExists, isCreditCardExist, showCreditCardDetails]);

    if (isCardLimitsScreen) {
        return (
            <CardLimitsScreen viewUsageLimits={viewUsageLimits} ticketId={ticketId} />
        );
    }

    if (isCardSettingsScreen) {
        return (
            <CardSettingsScreen
                viewUsageSettings={viewUsageSettings}
                ticketId={ticketId}
                userId={userId}
                loader={loader}
            />
        );
    }

    if (showCardTrackingModal) {
        return (
            <CardTrackingInfoModal
                visible={showCardTrackingModal}
                data={cardTrackingInfoData}
                toggleHandler={setCardTrackingModal}
                getCardTrackingLabels={getCardTrackingLabels}
            />
        );
    }

    if (isLoanAccountDetailsScreen) {
        return (
            <LoanAccountDetailsScreen
                ticketId={ticketId}
                userId={userId}
                data={loanAccountDetailsData}
            />
        );
    }

    if (isEligibleTransactionsForEmiScreen) {
        return (
            <EligibleTransactionsForEmisScreen
                ticketId={ticketId}
                userId={userId}
                allEligibleTransactionsForEmisData={allEligibleTransactionsForEmisData}
                errInAllEligibleTransactionsForEmis={errInAllEligibleTransactionsForEmis}
                loader={loader}
            />
        );
    }

    if (isBlockConfirmScreen || isSuspendConfirmScreen) {
        const cardActionType = isBlockConfirmScreen ? CARD_ACTION_TYPE.BLOCK_CARD : CARD_ACTION_TYPE.SUSPEND_CARD;

        return (
            <CardStatus
                cardInformation={CARD_INFORMATION[cardActionType]}
                loader={loader}
                ticketId={ticketId}
            />
        );
    }

    if (isCreditCardKycScreen) {
        return (
            <KycDetailsScreen
                data={kycOnboardingDetails}
                ticketId={ticketId}
            />
        );
    }

    if (isSelectedCreditCardScreen) {
        return (
            <TableDetailScreen
                ticketId={ticketId}
                title={selectedCardData.tableName || 'Card program'}
                data={selectedCardData}
                currentScreen={IS_SELECTED_CREDIT_CARD_SCREEN}
            />
        );
    }

    if (isCurrentStageScreen) {
        return (
            <TableDetailScreen
                ticketId={ticketId}
                title={currentStageData.tableName || 'Current Stage'}
                data={currentStageData}
                currentScreen={IS_CREDIT_CARD_CURRENT_STAGE_SCREEN}
            />
        );
    }

    if (isNextStageScreen) {
        return (
            <TableDetailScreen
                ticketId={ticketId}
                title={nextStageData.tableName || 'Next Stage'}
                data={nextStageData}
                currentScreen={IS_CREDIT_CARD_NEXT_STAGE_SCREEN}
            />
        );
    }

    if (isMilestoneRewardsScreen) {
        return (
            <MilestoneRewardsScreen
                ticketId={ticketId}
                data={milestoneRewardData}
                err={milestoneRewardError}
                loading={milestoneRewardLoading}
                currentScreen={IS_CREDIT_CARD_MILESTONE_REWARDS_SCREEN}
            />
        );
    }

    if (isPendingAuth) {
        return (
            <AuthScreen
                isSuspendCreditPending={isSuspendCreditPending}
                isBlockCreditPending={isBlockCreditPending}
                handleCardAction={handleCardAction}
                index={index}
            />
        );
    }

    if (currentCardDetails && currentCardDetails.length === 0) {
        return <div className='table-nsr'>No Cards Found</div>;
    }

    if (loader) {
        return <Loader visible={loader} />;
    }

    const creditCardOnbaordingViewTablesData = [
        {
            header: '',
            labelData: CURRENT_CREDIT_CARD_DETAILS_LABEL_DATA,
            contentData: currentCardDetails,
            errLabel: errInCurrentCardDetails,
            renderActionableColumn: renderActions,
            extClasses: 'mt-30',
        },
        {
            header: 'Current Limit Usage Detail',
            labelData: CURRENT_LIMIT_USAGE_DETAILS_LABEL_DATA,
            contentData: currentLimitUsageDetailsData,
            errLabel: errInCurrentLimitUsageDetails,
            extClasses: 'mt-30',
        },
        {
            header: 'Past Statements',
            labelData: CREDIT_CARD_BILLING_INFO_LABEL_DATA,
            contentData: creditCardBillingInfoData,
            errLabel: errInCreditCardBillingInfo,
            extClasses: 'mt-30',
        },
    ];

    const emiTablesData = [
        {
            header: 'Active EMIs',
            labelData: ALL_ACTIVE_LOAN_ACCOUNTS_LABEL_DATA,
            contentData: allActiveLoanAccountsData,
            errLabel: errInAllActiveLoanAccounts,
            detailsColumnLabel: 'View Details',
            handleDetailsClick: viewMoreEmiDetails,
            extClasses: 'mt-30',
            nsrLabel: 'No Active EMIs Found',
        },
        {
            header: 'Closed EMIs',
            labelData: ALL_CLOSED_LOAN_ACCOUNTS_LABEL_DATA,
            contentData: allClosedLoanAccountsData,
            errLabel: errInAllClosedLoanAccounts,
            detailsColumnLabel: 'View Details',
            handleDetailsClick: viewMoreEmiDetails,
            extClasses: 'mt-30',
            nsrLabel: 'No Closed EMIs Found',
        },
    ];

    return (
        <div className='credit-card-cr'>
            <div className='heading1 text-center mb-30'>Credit Card
                {
                    isVersion2
                        ? `${cardProgramName ? ` (${cardProgramName})` : ''}`
                        : `${cardProgramType === 'CARD_PROGRAM_TYPE_SECURED' ? '(for secured)' : ''}`
                }
            </div>
            {creditCardExists || isCreditCardExist ? (
                <React.Fragment>
                    <div className='cc-es2-heading-container mt-30'>
                        <div className='cc-es2-heading'>{showCreditCardDetails ? 'Hide' : 'Show'} Credit Card Details</div>
                        <Toggle
                            value={showCreditCardDetails}
                            onChange={handleShowCreditCardDetails}
                        />
                    </div>
                    {
                        showCreditCardDetails
                            ? (
                                <React.Fragment>
                                    {creditCardOnbaordingViewTablesData.map((item) => {
                                        const {
                                            header, labelData, contentData, errLabel, renderActionableColumn, extClasses,
                                        } = item;
                                        if (contentData && contentData.length > 0) {
                                            return (
                                                <Table
                                                    header={header}
                                                    labelData={labelData}
                                                    contentData={contentData}
                                                    errLabel={errLabel}
                                                    renderActionableColumn={renderActionableColumn}
                                                    extClasses={{
                                                        container: extClasses,
                                                    }}
                                                />
                                            );
                                        }
                                        return <div />;
                                    })}

                                    <div className='credit-card-rewards-container'>
                                        <div className='credit-card-rewards-container__head'>
                                            Renewal Fees & Milestone Reward Details
                                        </div>
                                        <button
                                            onClick={onMilestoneRewardDetailsClick}
                                            className='credit-card-rewards-container__button'
                                            type='button'
                                        >
                                            View Details
                                        </button>
                                    </div>

                                    {
                                        fdCreditCardDetails && fdCreditCardDetails.data
                        && (
                            <Table
                                objKey='header_key'
                                nsrLabel='FD Credit Card Details'
                                labelData={fdCreditCardDetails?.data.columnData}
                                contentData={fdCreditCardDetails?.data.rowData}
                                header={fdCreditCardDetails?.data.tableName}
                                extClasses={{
                                    container: 'mt-15',
                                }}
                            />
                        )
                                    }

                                    <TableV1
                                        heading='Emi In Upcoming Bill'
                                        contentData={getUpcomingEmiBill(upcomingEmiInfoData)}
                                        errLabel={errInAllActiveLoanAccounts}
                                    />

                                    {emiTablesData.map((item) => {
                                        const {
                                            header, labelData, contentData, errLabel, detailsColumnLabel, handleDetailsClick,
                                            extClasses, nsrLabel,
                                        } = item;

                                        return (
                                            <Table
                                                header={header}
                                                labelData={labelData}
                                                contentData={contentData}
                                                errLabel={errLabel}
                                                detailsColumnLabel={detailsColumnLabel}
                                                handleDetailsClick={handleDetailsClick}
                                                nsrLabel={nsrLabel}
                                                extClasses={{
                                                    container: extClasses,
                                                }}
                                            />
                                        );
                                    })}

                                    <button onClick={onViewEligbleTransactionClick} className='mt-60 mb-30 link' type='button'>
                                        View Eligble Transactions for EMIs & Preview EMIs
                                    </button>

                                    <OlderCards ticketId={ticketId} userId={userId} />
                                </React.Fragment>
                            )
                            : null
                    }

                </React.Fragment>
            ) : (
                <React.Fragment>
                    {
                        isVersion2
                            ? (
                                <EligibleScreenV2
                                    eligibilityDetails={eligibilityDetails}
                                    offerDetails={offerDetails}
                                    activeTicket={activeTicket}
                                    ticketId={ticketId}
                                    userId={userId}
                                    index={index}
                                />
                            )
                            : null
                    }
                    {
                        !isVersion2
                            ? (
                                <EligibleScreen
                                    creditCardOnboardingDetailsData={creditCardOnboardingDetailsData}
                                />
                            )
                            : null
                    }
                </React.Fragment>
            )}

            {err ? <div className='error-label1 wspace-pre-wrap'>{err}</div> : null}
        </div>
    );
};

const withReducer = injectReducer({ key: CREDIT_CARD_KEY, reducer });
const withSaga = injectSaga({ key: CREDIT_CARD_KEY, saga });
const withKycOnboardingReducer = injectReducer({ key: 'kycOnboarding', reducer: kycOnboardingReducer });
const withKycOnboardingSaga = injectSaga({ key: 'kycOnboarding', saga: kycOnboardingSaga });

export default compose(
    withReducer,
    withSaga,
    withKycOnboardingReducer,
    withKycOnboardingSaga,
)(CreditCard);

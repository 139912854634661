/*
 *
 * DBStates constants
 *
 */

export const GET_SERVICE_LIST = 'app/DBStates/GET_SERVICE_LIST';
export const SET_SERVICE_LIST = 'app/DBStates/SET_SERVICE_LIST';
export const ERR_SERVICE_LIST = 'app/DBStates/ERR_SERVICE_LIST';
export const GET_ENTITY_LIST = 'app/DBStates/GET_ENTITY_LIST';
export const SET_ENTITY_LIST = 'app/DBStates/SET_ENTITY_LIST';
export const ERR_ENTITY_LIST = 'app/DBStates/ERR_ENTITY_LIST';
export const GET_OPTION_LIST = 'app/DBStates/GET_OPTION_LIST';
export const SET_OPTION_LIST = 'app/DBStates/SET_OPTION_LIST';
export const SET_OPTION_VALUE = 'app/DBStates/SET_OPTION_VALUE';
export const ERR_OPTION_LIST = 'app/DBStates/ERR_OPTION_LIST';
export const GET_DB_INFO = 'app/DBStates/GET_DB_INFO';
export const SET_DB_INFO = 'app/DBStates/SET_DB_INFO';
export const ERR_DB_INFO = 'app/DBStates/ERR_DB_INFO';
export const SET_MONORAIL_ID = 'app/DBStates/SET_MONORAIL_ID';

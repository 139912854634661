/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 *
 * OnboardingInfo
 *
 */

import React, { memo, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';

import backArrow from '../../../assets/images/svgs/back-arrow.svg';
import { Button } from '../../../components';
import { copyTextWithStyle } from '../../../utils/uiHelpers';

import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';
import { makeSelectAgentInfo } from '../../AppV2/selectors';

import CurrentStageOnboardingTroubleshoot from '../CurrentStageOnboardingTroubleshoot';
import OnboardingTroubleshoot from '../OnboardingTroubleshoot';
import OnboardingMetaInfo from '../Modals/OnboardingMetaInfo';

const InAppScreeningId = 'in-app-screening';

const OnboardingInfo = (props) => {
    const [metaInfo, setMetaInfo] = useState({});

    const {
        data, toggleHandler, isInAppScreeningFlow, currentStageTroubleshootInfo,
    } = props;

    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);

    const copyDataClickHandler = () => {
        copyTextWithStyle(InAppScreeningId);
        pushRudderEvent(RudderEvent.KYCOnboarding.InAppScreenDetailsCopyButtonClick, agentInfo.emailId);
    };

    return (
        <React.Fragment>
            <div className='kob' id={InAppScreeningId}>
                <div className='kob-hw'>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                    <img className='kob-backarrow' src={backArrow} onClick={() => toggleHandler(false)} alt='' />
                    <div className='kob-hw__hd'>
                        {`${isInAppScreeningFlow ? 'In-app Screening' : 'Onboarding'} Details`}
                    </div>
                    <Button
                        v2
                        secondary
                        onClick={copyDataClickHandler}
                        label='Copy Data'
                    />
                </div>
                {
                    !isInAppScreeningFlow ? (
                        <CurrentStageOnboardingTroubleshoot data={currentStageTroubleshootInfo} />
                    ) : null
                }
                <div className='table-sr table-sr--mt0'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th f15Wrapper'>Stage</div>
                        <div className='table-sr__th'>Status</div>
                        <div className='table-sr__th f05Wrapper'>Updated At</div>
                        <div className='table-sr__th f2Wrapper'>Description</div>
                        <div className='table-sr__th f05Wrapper'>Meta Info</div>
                    </div>
                    <div className='table-sr__tlc kob-onbtable'>
                        {
                            data.map((item) => (// Map data structure
                                <div className='table-sr__tlw table-sr__tlw--aic' key={item[0]}>
                                    <div className='table-sr__tl f15Wrapper'>{item[0]}</div>
                                    <div className='table-sr__tl'>{item[1].status}</div>
                                    <div className='table-sr__tl f05Wrapper'>{item[1].updatedTs}</div>
                                    <div className='table-sr__tl f2Wrapper'>{item[1].description}</div>
                                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                                    <div
                                        className={classNames(
                                            'table-sr__tl f05Wrapper',
                                            item[1].metaInfo && 'link',
                                        )}
                                        onClick={() => setMetaInfo(item[1].metaInfo || {})}
                                    >
                                        {item[1].metaInfo ? 'View Details' : '-'}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                {
                    isInAppScreeningFlow ? (
                        <OnboardingTroubleshoot isInAppScreeningFlow />
                    ) : null
                }
            </div>
            <OnboardingMetaInfo
                isInAppScreeningFlow={isInAppScreeningFlow}
                visible={Object.keys(metaInfo).length}
                data={metaInfo}
                toggleHandler={() => setMetaInfo({})}
            />
        </React.Fragment>
    );
};

export default memo(OnboardingInfo);

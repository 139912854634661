/**
 * @file RecentActivity/actions.js - Contains actions for recent activity
 */
import {
    GET_RECENT_ACTIVITY, GET_RECENT_ACTIVITY_DETAILS, SET_RECENT_ACTIVITY, GET_RECENT_ACTIVITY_AREA, SET_LOADER,
} from './constants';

export const getRecentActivityAreas = () => ({
    type: GET_RECENT_ACTIVITY_AREA,
});

export const getRecentActivities = (data) => ({
    type: GET_RECENT_ACTIVITY,
    data,
});

export const getRecentActivityDetails = (data) => ({
    type: GET_RECENT_ACTIVITY_DETAILS,
    data,
});

export const setLoader = (data) => ({
    type: SET_LOADER,
    data,
});

/**
 * clearRecentActivities clears all the recent activity from redux state,
 * it does not call any API call for clearing hence data is unaffected
 * @param {*} data empty or null for clearing
 * @returns state type and data to be added
 */
export const clearRecentActivities = (data) => ({
    type: SET_RECENT_ACTIVITY,
    data,
});

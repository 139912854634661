/**
 *
 * Review a single facematch case and update the status
 *
 */

import React, { memo, useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';
import { Button, DisableContextMenu } from '../../../components';
import { downloadFile, isObjEmpty } from '../../../utils';
import { MetaInfoContext, UserDetailsContext } from '../../../context';

import DevActions from '../../DevActions';
import { CustomerDetailsModal, DequeueCaseModal, LivenessSummaryModal, ViewAnnotationDetails } from '../../RiskOps';
import { auditLogMediaDownloadAction } from '../../LivenessReview/actions';
import { getAdditionalFilters } from '../../RiskOps/utils';
import { selectRiskOpsL2AnnotationDetails } from '../../RiskOps/selectors';

import { dequeueFacematchCaseAction, getBase64ImgAction, setFacematchViewAction } from '../actions';
import {
    DEV_ACTION_TYPES, PAYLOAD_TYPE_L2_FACEMATCH, SET_FACEMATCH_L2_ANNOTATION_DETAILS, VIEW_ALL_FACEMATCH_CASES, FACEMATCH_REVIEW_KEY,
} from '../constants';
import {
    selectKycImg, selectVideoFrameImg, selectCurFacematchCase, selectFacematchPayloadType, selectFacematchQueueFilters,
} from '../selectors';

const getDataFromCase = (curFacematchCase) => {
    const { payload, id: recordId } = curFacematchCase;
    const {
        actor_id: actorId,
        request_id: requestId,
        video_frame: videoFrameImgPath,
        kyc_image: kycImgPath,
    } = curFacematchCase[payload];
    return {
        actorId,
        requestId,
        recordId,
        videoFrameImgPath,
        kycImgPath,
    };
};

const getPreFilledValuesObj = (curFacematchCase, emailId) => {
    const { actorId, requestId } = getDataFromCase(curFacematchCase);

    return {
        mergeKey: 'label',
        paramList: [
            { label: 'Actor ID', value: actorId, isDisabled: true },
            { label: 'Request ID', value: requestId },
            { label: 'Reviewed By (ex: yourname@epifi.com)', value: emailId, isDisabled: true },
        ],
    };
};

const SingleFacematchCase = () => {
    const metaInfo = useContext(MetaInfoContext);
    const {
        riskOps: { downloadMedia: { allowDownloadEvenIfTrackingApiFails, allowedEmails } }, componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    const { basicInfo } = useContext(UserDetailsContext);
    const dispatch = useDispatch();

    // global state
    const videoFrameImg = useSelector(selectVideoFrameImg(), shallowEqual);
    const kycImg = useSelector(selectKycImg(), shallowEqual);
    const facematchPayloadType = useSelector(selectFacematchPayloadType(), shallowEqual);
    const curFacematchCase = useSelector(selectCurFacematchCase(), shallowEqual);
    const { actorId, requestId, recordId } = getDataFromCase(curFacematchCase);
    const { index } = curFacematchCase;
    const { emailId } = basicInfo;

    const { fromDate, toDate, sortOption } = useSelector(selectFacematchQueueFilters(), shallowEqual);
    const l2AnnotationDetails = useSelector(selectRiskOpsL2AnnotationDetails(FACEMATCH_REVIEW_KEY), shallowEqual);

    // local state
    const [showDequeueCaseModal, setDequeueCaseModalVisibility] = useState(false);
    const [preFilledValues, setPreFilledValues] = useState(null);
    const [showLivenessSummaryModal, setLivenessSummaryModalVisibility] = useState(false);
    const [showCustomerDetailsModal, setCustomerDetailsModalVisibility] = useState(false);

    const isL2Queue = facematchPayloadType.value === PAYLOAD_TYPE_L2_FACEMATCH;
    const annotationCaseDetails = {
        isL2Queue,
        queryParams: {
            actorId,
            requestId,
            payloadType: facematchPayloadType.value,
        },
        actionType: SET_FACEMATCH_L2_ANNOTATION_DETAILS,
    };

    useEffect(() => {
        if (!isObjEmpty(curFacematchCase)) {
            const { videoFrameImgPath, kycImgPath } = getDataFromCase(curFacematchCase);
            dispatch(getBase64ImgAction({ imageType: 'videoFrameImg', imagePath: videoFrameImgPath }));
            dispatch(getBase64ImgAction({ imageType: 'kycImg', imagePath: kycImgPath }));

            setPreFilledValues(getPreFilledValuesObj(curFacematchCase, emailId));
        }
    }, [dispatch, curFacematchCase.id]);

    // if no facematch case is set, return null
    if (isObjEmpty(curFacematchCase)) {
        return null;
    }

    const onBackArrowCTA = () => {
        dispatch(setFacematchViewAction(VIEW_ALL_FACEMATCH_CASES));
    };

    const dequeueAction = dequeueFacematchCaseAction({
        actorId,
        recordId,
        index,
        queryParams: {
            payloadType: facematchPayloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        },
        customContext: {
            bannersVersion,
        },
    });

    const extraActions = [dequeueAction];

    const handleDequeueCase = () => {
        dispatch(dequeueAction);
        setDequeueCaseModalVisibility(false);
    };

    const downloadFiles = () => {
        downloadFile(`video-frame-image_${actorId}`, videoFrameImg);
        downloadFile(`kyc-image_${actorId}`, kycImg);
    };

    const downloadFacematchImages = () => {
        const callAuditMedialDownloadApi = () => new Promise((resolve, reject) => {
            dispatch(auditLogMediaDownloadAction({
                requestBody: {
                    elementId: recordId,
                    actorId,
                    agentName: emailId,
                    videoFrameLocation: videoFrameImg,
                    kycImageLocation: kycImg,
                    payloadType: facematchPayloadType.value,
                },
                resolve,
                reject,
            }));
        });

        callAuditMedialDownloadApi().then(() => {
            downloadFiles();
        }).catch(() => {
            if (allowDownloadEvenIfTrackingApiFails) {
                downloadFiles();
            }
        });
    };

    return (
        <React.Fragment>
            <div className='fmreview-cr'>
                <div className='fmreview-hl--cr'>
                    <button
                        className='fmreview-backarrow--cr'
                        type='button'
                        onClick={onBackArrowCTA}
                    >
                        <img
                            className='fmreview-backarrow'
                            alt='back-arrow'
                            src={backArrowIcon}
                        />
                    </button>
                    <div className='fmreview-hl1'>Review Facematch Case</div>
                </div>

                <DisableContextMenu>
                    <div className='fmreview-img--cr'>
                        <div className='fmreview-img--itm1'>
                            <div className='fmreview-hl2'>Video Frame Image</div>
                            {videoFrameImg && (
                                <img
                                    src={videoFrameImg}
                                    height='400'
                                    alt='video-frame'
                                />
                            )}
                        </div>
                        <div className='fmreview-img--itm2'>
                            <div className='fmreview-hl2'>KYC Image</div>
                            {kycImg && <img src={kycImg} height='400' alt='kyc' />}
                        </div>
                    </div>
                </DisableContextMenu>

                <div className='frwpWrapper'>
                    <Button
                        secondary
                        label='View Liveness Summary Details'
                        onClick={() => setLivenessSummaryModalVisibility(true)}
                    />
                    <Button
                        secondary
                        label='View Customer Details'
                        extClasses='ml-30'
                        onClick={() => setCustomerDetailsModalVisibility(true)}
                    />
                    {allowedEmails.includes(emailId) && (
                        <Button
                            primary
                            label='Download Images'
                            extClasses='ml-30'
                            onClick={downloadFacematchImages}
                        />
                    )}
                </div>

                <ViewAnnotationDetails
                    l2AnnotationDetails={l2AnnotationDetails}
                    caseDetails={annotationCaseDetails}
                />

                {/* if not L2 queue, only then show dev actions */}
                {!isL2Queue && preFilledValues && (
                    <DevActions
                        containerKey='facematch-v1'
                        actionTypes={DEV_ACTION_TYPES}
                        preFilledValues={preFilledValues}
                        extraActions={extraActions}
                        autoActionSelection={false}
                    />
                )}

                <div className='fmreview-cr1'>
                    <div className='fmreview-hl3'>
                        If the above action does not suit your need, you can dequeue
                        this case
                    </div>
                    <Button
                        secondary
                        label='Remove User From Queue'
                        onClick={() => setDequeueCaseModalVisibility(true)}
                    />
                </div>
            </div>

            <DequeueCaseModal
                visible={showDequeueCaseModal}
                submitCta={handleDequeueCase}
                closeCta={setDequeueCaseModalVisibility}
            />

            <LivenessSummaryModal
                visible={showLivenessSummaryModal}
                actorId={actorId}
                closeCta={setLivenessSummaryModalVisibility}
            />

            <CustomerDetailsModal
                visible={showCustomerDetailsModal}
                actorId={actorId}
                closeCta={setCustomerDetailsModalVisibility}
            />
        </React.Fragment>
    );
};

export default memo(SingleFacematchCase);

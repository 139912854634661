/**
 * @file SherlockV2 contains rudder event names for Sherlock V2 Agent level
 */

const SherlockV2TabActive = 'SherlockV2TabActive';
const SherlockV2TabInActive = 'SherlockV2TabInActive';
const SherlockV2TabOpen = 'SherlockV2TabOpen';
const SherlockV2TabClosed = 'SherlockV2TabClosed';

export {
    SherlockV2TabActive,
    SherlockV2TabInActive,
    SherlockV2TabOpen,
    SherlockV2TabClosed,
};

import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button } from '../../../components';

import { getDetailedInfo } from '../utils';
import '../style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content : {
        top: '15%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 900,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        maxHeight: 700,
    }
};

const DetailsModal = ({ selectedDeposit, toggleModal }) => (
    <Modal
        isOpen
        style={customStylesForModal}
        contentLabel='View Deposit Details Modal'
    >
        <div className='deposits-modal-hwr'>View Details</div>
        {
            getDetailedInfo(selectedDeposit).map(item => (
                <div className='deposits-modal-cwr' key={item.label}>
                    <div className='deposits-modal-cwr__cl'>{item.label}</div>
                    <div className='deposits-modal-cwr__sp'>:</div>
                    <div>
                        {
                            item.isArray ? (
                                item.value.map(si => (
                                    <div className='deposits-modal-extradata'>
                                        {item.valueFields.map((iv, idx, arr) => (
                                            <div className={`deposits-modal-col1 ${(idx === arr.length - 1) ? 'deposits-modal-col2': ''}`}>
                                                {si[iv] || '-'}
                                            </div>
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <div className='deposits-modal-cwr__cv'>{item.value}</div>
                            )
                        }
                    </div>
                </div>
            ))
        }
        <Button primary extClasses='deposits-modal-bwr' label='Close' onClick={() => toggleModal(false)} />
    </Modal>
);

export default memo(DetailsModal);

/*
 *
 * LivenessSummary constants
 *
 */

export const GET_LIVENESS_SUMMARY = 'app/LivenessSummary/GET_LIVENESS_SUMMARY';
export const SET_LIVENESS_SUMMARY = 'app/LivenessSummary/SET_LIVENESS_SUMMARY';
export const ERR_LIVENESS_SUMMARY = 'app/LivenessSummary/ERR_LIVENESS_SUMMARY';
export const SET_MONORAIL_ID = 'app/LivenessSummary/SET_MONORAIL_ID';

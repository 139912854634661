/**
 *
 * Remittance Order Details Modal
 *
 */

import React, { memo, useEffect } from 'react';
import Modal from 'react-modal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, Loader, Table } from '../../../components';
import { getRemittanceOrderDetails } from '../actions';
import { selectRemittance } from '../selectors';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 1000,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const RemittanceOrderDetailsModal = (props) => {
    const {
        visible, remittanceId, toggleHandler, ticketId, userId, index,
    } = props;

    const dispatch = useDispatch();

    const remittanceData = useSelector(selectRemittance, shallowEqual);

    useEffect(() => {
        if (visible && remittanceId) {
            const payload = {
                ticketId,
                userId,
                index,
                remittanceId,
            };
            dispatch(getRemittanceOrderDetails(payload));
        }
    }, [remittanceId, visible]);

    return (
        <Modal
            isOpen={visible}
            style={customStylesForModal}
            contentLabel='Remittance Order Details'
        >
            <div className='table-sr table-sr--mt0'>
                {remittanceData
                && remittanceData[ticketId]
                && remittanceData[ticketId]['order-details']
                && remittanceData[ticketId]['order-details'].data
                && Array.isArray(remittanceData[ticketId]['order-details'].data)
                && remittanceData[ticketId]['order-details'].data.map((remittanceDatum) => (
                    <Table
                        objKey='header_key'
                        header={remittanceDatum.tableName}
                        labelData={remittanceDatum.columnData}
                        contentData={remittanceDatum.rowData}
                        key={remittanceDatum.rowData && remittanceDatum.rowData[0].meta}
                        prevToken={remittanceData.prev}
                        nextToken={remittanceData.next}
                        paginatedClickHandler={() => {}}
                        extClasses={{
                            container: 'p-20',
                        }}
                    />
                ))}
            </div>
            <Button
                primary
                extClasses='upi-modal-bwr'
                label='Close'
                onClick={() => toggleHandler(false)}
            />
            <Loader visible={remittanceData
                && remittanceData[ticketId]
                && remittanceData[ticketId]['order-details']
                && remittanceData[ticketId]['order-details'].loading}
            />
        </Modal>
    );
};

export default memo(RemittanceOrderDetailsModal);

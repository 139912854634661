import React, { useEffect, useState } from 'react';
import { path } from 'ramda';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Button } from '../../../components';
import { selectTransactionCaseDetails } from '../../TransactionCaseDetails/selectors';
import { getAllowedAnnotations, createTransactionsAnnotations } from '../actions';
import { selectAnnotationDetails, selectApp } from '../selectors';
import { ANNOTATION_MAP } from '../constants';
import { toastify } from '../../../utils';
import './style.scss';
import { checkMultipleInclusionsInText } from '../../../utils/helpers';

const FormUi = (props) => {
    const { selectedRows, flowType, extClass } = props;

    const annotationDetails = useSelector(selectAnnotationDetails, shallowEqual) || {};
    const transactionCaseDetails = useSelector(selectTransactionCaseDetails, shallowEqual);
    const appState = useSelector(selectApp, shallowEqual);

    const { annotationTypeList, annotationOptionMapping } = annotationDetails[flowType] || {};

    const [selectedAnnotations, selectAnnotations] = useState({});
    const animatedComponents = makeAnimated();
    const dispatch = useDispatch();

    useEffect(() => {
        const annotationRequestPayload = ANNOTATION_MAP.PAYLOAD_MAP[flowType];
        dispatch(getAllowedAnnotations({
            annotationRequestPayload, flowType,
        }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createPayloadFlowTypeTransactionPageBottom = () => {
        try {
            const caseId = transactionCaseDetails?.caseDetails?.find((item) => item?.id)?.value;
            const actorId = transactionCaseDetails?.caseDetails?.find((item) => item?.label === 'Actor ID')?.value;
            const addedByEmail = path(['agentInfo', 'emailId'], appState);
            const annotationsList = [];
            const commonField = { case_id: caseId, added_by_email: addedByEmail, entity_id: actorId };

            if (Array.isArray(Object.entries(selectedAnnotations))) {
                Object.entries(selectedAnnotations).forEach(([key, annotationOption]) => {
                    const annotationTypeValue = path([key, 'value'], annotationTypeList);

                    if (Array.isArray(annotationOption)) {
                        annotationOption.forEach((item) => {
                            annotationsList.push({
                                ...commonField,
                                allowed_annotation_id: item.value,
                                entity_type: path([annotationTypeValue, 'entityType'], annotationOptionMapping),
                            });
                        });
                    } else {
                        annotationsList.push({
                            ...commonField,
                            allowed_annotation_id: annotationOption.value,
                            entity_type: path([annotationTypeValue, 'entityType'], annotationOptionMapping),
                        });
                    }
                });
            }

            return annotationsList;
        } catch (error) {
            return null;
        }
    };

    const createPayloadFlowTypeListTransaction = () => {
        try {
            const caseId = transactionCaseDetails?.caseDetails?.find((item) => item?.id)?.value;
            const addedByEmail = path(['agentInfo', 'emailId'], appState);
            const annotationsList = [];
            const commonField = { case_id: caseId, added_by_email: addedByEmail };

            if (Array.isArray(Object.entries(selectedAnnotations))) {
                Object.entries(selectedAnnotations).forEach(([key, annotationOption]) => {
                    const annotationTypeValue = path([key, 'value'], annotationTypeList);

                    if (Array.isArray(annotationOption)) {
                        annotationOption.forEach((item) => {
                            annotationsList.push({
                                ...commonField,
                                allowed_annotation_id: item.value,
                                entity_type: path([annotationTypeValue, 'entityType'], annotationOptionMapping),
                            });
                        });
                    } else {
                        annotationsList.push({
                            ...commonField,
                            allowed_annotation_id: annotationOption.value,
                            entity_type: path([annotationTypeValue, 'entityType'], annotationOptionMapping),
                        });
                    }
                });
            }

            const mappingList = [];

            selectedRows.forEach((item) => {
                annotationsList.forEach((annotation) => {
                    mappingList.push({
                        ...annotation,
                        entity_id: item.transaction_id,
                    });
                });
            });
            return mappingList;
        } catch (error) {
            return null;
        }
    };

    const createAnnotations = () => {
        let mappingList;

        switch (flowType) {
            case ANNOTATION_MAP.TYPE.LIST_TRANSACTION:
                mappingList = createPayloadFlowTypeListTransaction();
                break;
            case ANNOTATION_MAP.TYPE.TRANSACTION_PAGE_BOTTOM:
                mappingList = createPayloadFlowTypeTransactionPageBottom();
                break;
            default:
                mappingList = null;
                break;
        }

        if (mappingList) return dispatch(createTransactionsAnnotations({ mappingList, flowType }));
        return toastify('Unable Process Request', 'error');
    };

    return (
        <div className={`${extClass || ''} annotations-form-container`}>
            {
                Array.isArray(annotationTypeList) && (
                    <div className='annotations-modal-field'>
                        {
                            annotationTypeList.map((item, index) => (
                                <div key={item?.value}>
                                    <div className='annotations-modal-field-label'>
                                        {item?.label}{path(
                                            [item?.value, 'isMandatory'],
                                            annotationOptionMapping,
                                        ) && <span className='annotations-modal-mark-mandatory'>*</span>}
                                    </div>
                                    <div className='annotations-modal-field'>
                                        <Select
                                            isMulti={path([item?.value, 'isMultiSelect'], annotationOptionMapping)}
                                            closeMenuOnSelect
                                            components={animatedComponents}
                                            options={path([item?.value, 'options'], annotationOptionMapping) || []}
                                            onChange={(selectedOption) => {
                                                selectAnnotations((prev) => ({ ...prev, [index]: selectedOption }));
                                            }}
                                            value={path([index], selectAnnotations)}
                                            className='Select-container'
                                            classNamePrefix='annotation'
                                            filterOption={(option, searchText) => {
                                                const formattedTest = searchText.split(' ');
                                                return checkMultipleInclusionsInText(formattedTest, option.label);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            }

            <div className='annotations-button-container'>
                <Button
                    v2
                    primary
                    label='Annotate'
                    onClick={createAnnotations}
                    disabled={Array.isArray(Object.values(selectedAnnotations)) && !Object.values(selectedAnnotations).length > 0}
                    extClasses='annotations-button'
                />
            </div>
        </div>
    );
};

export default FormUi;

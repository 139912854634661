import { createSelector } from 'reselect';

const CONTAINER_KEY = 'vkycAuditor';

export const selectRecordingUrl = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'recordingUrl']),
    (substate) => substate,
);

export const selectReport = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'report']),
    (substate) => substate,
);

export const selectFaceMatchScore = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'report', 'face_match_results']),
    (substate) => substate,
);

export const selectUser = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'customerName']),
    (substate) => substate,
);

export const selectMeetingId = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'meetingId']),
    (substate) => substate,
);

export const selectScreenKey = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'screenKey']),
    (substate) => substate,
);

export const selectPermanentAddress = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'report', 'permanent_address']),
    (substate) => substate,
);

export const selectRejectedReasons = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'rejectionReasons']),
    (substate) => substate,
);

export const selectVkycAvailableReviewCount = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'waitingReports']),
    (substate) => substate,
);

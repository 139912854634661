/**
 * @file FreshdeskTicketInfo
 * Fetches the dropdown values of product category, sub category, etc.
 * Fetches the ticket info from Freshdesk and renders the info along with input fields and dropdowns
 * Allows updating the Freshdesk ticket info directly from Sherlock
 * The freshdesk info is shown in an expandable sidebar at the right of the screen
 */

import React, { memo, useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

// common
import { ExpandableSidebar, Loader } from '../../components';
import { injectReducer, injectSaga } from '../../utils';
import { MetaInfoContext } from '../../context';

// redux
import { getFreshdeskCategoriesAction } from './actions';
import {
    FRESHDESK_TICKET_INFO_KEY as CONTAINER_KEY,
} from './constants';
import { selectFDTicketInfoError, selectFDTicketInfoLoading } from './selectors';

// react components
import RenderForm from './RenderForm';
import RenderTicketInfo from './RenderTicketInfo';

// reducer, saga, styles
import reducer from './reducer';
import saga from './saga';
import './style.scss';

const FreshdeskTicketInfo = (props) => {
    const { ticketId } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const dispatch = useDispatch();

    const metaInfo = useContext(MetaInfoContext);

    const {
        componentVersions: {
            freshdeskIssueInfo,
        },
    } = metaInfo;

    const loader = useSelector(selectFDTicketInfoLoading, shallowEqual);
    const err = useSelector(selectFDTicketInfoError, shallowEqual);

    // fetch categories information of freshdesk
    useEffect(() => {
        if (freshdeskIssueInfo === 0) return;

        dispatch(getFreshdeskCategoriesAction());
    }, [dispatch, freshdeskIssueInfo]);

    // if ticket is not there, or if component version is 0, do not render component
    if (!ticketId || freshdeskIssueInfo === 0) return null;

    const header = () => <div className={!isExpanded && 'fd-info-sb-header__hide'}>Freshdesk Information</div>;

    return (
        <ExpandableSidebar
            data={{
                header,
                isExpanded,
                setIsExpanded,
            }}
            extClasses={{
                item: { 'fd-info-sb-items__hide': !isExpanded },
            }}
        >
            <RenderTicketInfo
                ticketId={ticketId}
            />
            <RenderForm
                ticketId={ticketId}
            />

            {err ? <div className='error-label1'>{err}</div> : null}
            <Loader visible={loader} />
        </ExpandableSidebar>
    );
};

const withReducer = injectReducer({ key: CONTAINER_KEY, reducer });
const withSaga = injectSaga({ key: CONTAINER_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(memo(FreshdeskTicketInfo));

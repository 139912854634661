/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
/**
 * BulkUserDetailsByTicketIds
 * Component to fetch the bulk user details by ticket ids or csv of ticket ids
 * It's getting used for Admin Tools flow
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'ramda';
import Modal from 'react-modal';

import {
    Button, FormWithSingleInput, Loader, Table, CSVReader, DropdownWrapper, DropdownContent,
} from '../../components';
import {
    injectReducer, injectSaga, validators,
} from '../../utils';
import backArrowIcon from '../../assets/images/svgs/back-arrow.svg';

import { LABEL_DATA, SEARCH_BY_LIST_MAP, SEARCH_BY_LIST } from './constants';
import { getBulkUserInfo, resetBulkUserInfo } from './actions';
import { makeSelectBulkUserInfo } from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '32px 20px',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 600,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const BulkUserDetailsByTicketIds = () => {
    const dispatch = useDispatch();

    const userInfo = useSelector(makeSelectBulkUserInfo(), shallowEqual);

    const [showSearchByDD, setSearchByDD] = useState(true);
    const [searchBy, setSearchBy] = useState(false);
    const [csvData, setCSVData] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [fileData, setFileData] = useState('');
    const [ticketIds, setTicketIds] = useState('');
    const [passedTicketIds, setPassedTicketIds] = useState('');
    const [passedTicketIdsCount, setPassedTicketIdsCount] = useState(null);
    const [failedTicketIds, setFailedTicketIds] = useState('');
    const [reason, setReason] = useState('');
    const [errLabel, setErrLabel] = useState('');
    const [showTicketIdsModal, setTicketIdsModal] = useState(false);

    useEffect(() => {
        if (csvData) {
            const passedTicketIdsArr = [];
            const failedTicketIdsArr = [];

            // parse csv data & fill passed or failed ticket ids if its valid ticket id or not respectively
            csvData.forEach((item, idx) => {
                if (idx !== 0) {
                    const data = item[0];
                    const parsedData = parseInt(data, 10);

                    if (validators.isInputtedNumberValid(parsedData)) {
                        passedTicketIdsArr.push(parsedData);
                    } else {
                        failedTicketIdsArr.push(data);
                    }
                }
            });

            setPassedTicketIds(passedTicketIdsArr.join(','));
            setPassedTicketIdsCount(passedTicketIdsArr.length);
            setFailedTicketIds(failedTicketIdsArr.join(','));
        } else {
            setPassedTicketIds('');
            setFailedTicketIds('');
        }
    }, [csvData]);

    const { data, hasData, loading } = userInfo;

    const toggleDD = (key) => () => {
        switch (key) {
            case 'searchBy': {
                setSearchByDD(!showSearchByDD);

                break;
            }

            default:
        }
    };

    const handleDDChange = (key, value) => {
        setSearchBy(value);

        toggleDD(key)();
    };

    const onInputChange = (key) => (event) => {
        const { value } = event.target;

        switch (key) {
            case 'ticketIds':
                setTicketIds(value);
                break;

            case 'reason':
                setReason(value);
                break;

            default:
        }
    };

    const onSubmitCTA = () => {
        let formattedTicketIds;

        if (searchBy === SEARCH_BY_LIST_MAP.uploadCSV) {
            if (isEmpty(passedTicketIds) || isEmpty(reason)) {
                setErrLabel('Please fill all the * marked fields');
                return;
            }

            formattedTicketIds = passedTicketIds;
        } else {
            if (isEmpty(ticketIds) || isEmpty(reason)) {
                setErrLabel('Please fill all the * marked fields');
                return;
            }

            formattedTicketIds = ticketIds;
        }

        // using this regex to replace any space in the ticket ids list
        formattedTicketIds = formattedTicketIds.replace(/\s+/g, '');

        if (!validators.isInputtedTicketIdsValid(formattedTicketIds)) {
            setErrLabel('Please enter a valid comma separated ticket ids');
            return;
        }

        if (!validators.isInputtedStringValid(reason)) {
            setErrLabel('Please enter a valid reason');
            return;
        }

        setErrLabel('');
        dispatch(getBulkUserInfo({ ticketIds: formattedTicketIds, reason }));
    };

    const onBackArrowCTA = () => {
        dispatch(resetBulkUserInfo());
    };

    const renderUserDetails = () => {
        const { fileUrl, failedTicketList } = data;
        const filename = `bulk-user-info_${new Date().toISOString()}.csv`;

        return (
            <React.Fragment>
                <div className='bud-userinfo-hwr'>
                    <img className='bud-backarrow' alt='back arrow' src={backArrowIcon} onClick={onBackArrowCTA} />
                    <div className='bud-userinfo-hwr__hd'>Bulk User Details</div>
                </div>
                <a className='link bud-link' href={fileUrl} download={filename} target='_blank' rel='noreferrer'>
                    Download Bulk User Info CSV
                </a>
                {
                    !isEmpty(failedTicketList) ? (
                        <div className='bud-table'>
                            <Table
                                header='Failed Ticket List'
                                labelData={LABEL_DATA}
                                contentData={failedTicketList}
                                extClasses={{
                                    container: 'bud-table-sr',
                                    headerCr: 'bud-table-sr__thw',
                                    contentCr: 'bud-table-sr__tlc',
                                }}
                            />
                        </div>
                    ) : null
                }
            </React.Fragment>
        );
    };

    const renderUserDetailsForm = () => (
        <React.Fragment>
            <div className='frcWrapper bud-fc'>
                <div className='bud-label bud-fc__lb'>Search By</div>
                <DropdownWrapper
                    visible={showSearchByDD}
                    onOutsideClickHandler={toggleDD('searchBy')}
                    extClasses={{
                        container: 'custom-dd bud-dd',
                    }}
                >
                    <div onClick={toggleDD('searchBy')}>
                        {searchBy || 'Choose...'}
                    </div>
                    <DropdownContent
                        visible
                        extClasses={{
                            container: ['custom-dd__cc bud-dd__cc'],
                        }}
                        extStyles={{
                            height: showSearchByDD ? 98 : 0,
                            overflow: showSearchByDD ? 'auto' : 'hidden',
                        }}
                    >
                        {
                            SEARCH_BY_LIST.map((item) => (
                                <div key={item} className='custom-dd__cl' onClick={() => handleDDChange('searchBy', item)}>{item}</div>
                            ))
                        }
                    </DropdownContent>
                </DropdownWrapper>
            </div>
            {
                searchBy === SEARCH_BY_LIST_MAP.uploadCSV ? (
                    <React.Fragment>
                        <div className='frfsWrapper bud-fc'>
                            <div className='bud-label bud-fc__lb'>Upload Ticket IDs*</div>
                            <div className='bud-csv-cr'>
                                <CSVReader setCSVData={setCSVData} setFileData={setFileData} />
                            </div>
                            <a
                                className='link bud-download-link'
                                href='/assets/ticketids-sample.csv'
                                download='ticketids-sample'
                            >
                                Download Sample Format
                            </a>
                        </div>
                        {
                            passedTicketIds || failedTicketIds ? (
                                <div className='frcWrapper bud-fc'>
                                    <div className='bud-label bud-fc__lb'>Successfully Uploaded{'\n'}Ticket IDs Count</div>
                                    <div className='bud-label'>
                                        {`${passedTicketIdsCount}`}
                                    </div>
                                    <div className='link bud-view-link' onClick={() => setTicketIdsModal(true)}>View Passed/Failed Ticket IDs</div>
                                </div>
                            ) : null
                        }
                    </React.Fragment>
                ) : null
            }
            {
                searchBy === SEARCH_BY_LIST_MAP.enterCSV ? (
                    <FormWithSingleInput
                        disableFormSubmitOnEnter
                        label='Enter Ticket IDs*'
                        labelId='ticket-ids'
                        input={{
                            value: ticketIds,
                            onChange: onInputChange('ticketIds'),
                        }}
                        extClasses={{
                            container: 'bud-fc',
                            label: 'bud-fc__lb',
                            inputField: 'bud-fc__ip',
                        }}
                    />
                ) : null
            }
            {
                searchBy ? (
                    <React.Fragment>
                        <div className='form-container bud-fc'>
                            <label
                                className='form-wrapper-label bud-fc__lb'
                                htmlFor='bud-reason'
                            >
                                Enter Reason*
                            </label>
                            <textarea
                                id='bud-reason'
                                className='bud-fc__ip'
                                value={reason}
                                onChange={onInputChange('reason')}
                                rows={4}
                                cols={50}
                            />
                        </div>
                        <div className='err-label bud-errlb'>{errLabel}</div>
                        <Button
                            primary
                            label='View Details'
                            onClick={onSubmitCTA}
                            extClasses='bud-cta'
                        />
                    </React.Fragment>
                ) : null
            }
            <Loader visible={loading} />
            <Modal
                isOpen={showTicketIdsModal}
                style={customStylesForModal}
                contentLabel='Uploaded Ticket IDs Modal'
            >
                <div className='bud-modal__hl'>Uploaded Ticket IDs</div>
                {
                    passedTicketIds ? (
                        <div className='bud-modal__hl'>
                            {`Passed Ticket IDs - ${passedTicketIds}`}
                        </div>
                    ) : null
                }
                {
                    failedTicketIds ? (
                        <div className='bud-modal__hl'>
                            {`Failed Ticket IDs - ${failedTicketIds}`}
                        </div>
                    ) : null
                }
                <Button
                    extClasses='bud-modal__cta'
                    secondary
                    label='Close'
                    onClick={() => setTicketIdsModal(false)}
                />
            </Modal>
        </React.Fragment>
    );

    return (
        <div className='bud'>
            {
                hasData ? (
                    renderUserDetails()
                ) : (
                    renderUserDetailsForm()
                )
            }
        </div>
    );
};

const withReducer = injectReducer({ key: 'bulkUserDetailsByTicketIds', reducer });
const withSaga = injectSaga({ key: 'bulkUserDetailsByTicketIds', saga });

export default compose(
    withReducer,
    withSaga,
)(BulkUserDetailsByTicketIds);

/**
 *
 * TicketInfo
 *
 */

import React, { memo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Button, DropdownContent, DropdownWrapper } from '../../../components';
import { validators } from '../../../utils';
import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';

import { getFormattedTicketInfo } from '../utils';
import { setTicketNotes, updateTicketGroup } from '../actions';
import { makeSelectTicketGroups, makeSelectTicketInfo } from '../selectors';

import '../style.scss';

const TicketInfo = () => {
    const dispatch = useDispatch();

    const ticketGroups = useSelector(makeSelectTicketGroups(), shallowEqual);
    const ticketInfo = useSelector(makeSelectTicketInfo(), shallowEqual);

    const [reason, setReason] = useState('');
    const [group, setGroup] = useState('');
    const [errLabel, setErrLabel] = useState('');
    const [showGroupDropdown, setGroupDropdown] = useState(false);

    const handleInputChange = (key) => (e) => {
        const value = e.target.value;

        switch (key) {
            case 'reason':
                setReason(value);
                break;

            default:
        }
    };

    const onSubmitCTA = () => {
        if (!reason && !group) {
            setErrLabel('Please fill either reply or group or both');
            return;
        }

        if (reason && !validators.isInputtedStringValidv2(reason)) {
            setErrLabel('Please enter a valid reply');
            return;
        }

        setErrLabel('');

        const callUpdateTicketGroupAction = () => new Promise((resolve) => {
            dispatch(updateTicketGroup({
                ticket: ticketInfo,
                reason,
                group,
                resolve,
            }));
        });

        callUpdateTicketGroupAction().then(() => {
            setReason('');
            setGroup('');
        });
    };

    const onBackArrowCTA = () => {
        dispatch(setTicketNotes({}));
    };

    return (
        <div className='fdags-tktinfo'>
            <div className='fdags-tktinfo-cr'>
                <div className='fdags-tktinfo-hw'>
                    <img className='fdags-backarrow' src={backArrowIcon} onClick={onBackArrowCTA} />
                    <div className='fdags-tktinfo-hw__hd'>Ticket Details</div>
                </div>
                {
                    getFormattedTicketInfo(ticketInfo)
                        .map(item => (
                            <div className='fdags-tktinfo-cwr' key={item.label}>
                                <div className='fdags-tktinfo-cwr__cl'>{item.label}</div>
                                <div className='fdags-tktinfo-cwr__sp'>:</div>
                                <div className='fdags-tktinfo-cwr__cv'>
                                    {
                                        item.isArray ? (
                                            item.value.map(si => (
                                                <div
                                                    className='fdags-tktinfo-cwr__cv--sp'
                                                    dangerouslySetInnerHTML={{ __html: si }}
                                                />
                                            ))
                                        ) : (
                                            item.value
                                        )
                                    }
                                </div>
                            </div>
                        ))
                }
                <div className='form-container fdags-tktinfo-rfc'>
                    <label
                        className='form-wrapper-label fdags-tktinfo-rfc__lb'
                        htmlFor='wl-reason'
                    >
                        Attach Reply
                    </label>
                    <textarea
                        id='fdags-reason'
                        className='fdags-tktinfo-rfc__ta'
                        value={reason}
                        onChange={handleInputChange('reason')}
                        onMouseDown={(e) => { e.stopPropagation(); }}
                        rows={4}
                        cols={70}
                    />
                </div>
                <div className='frcWrapper'>
                    <div className='fdags-tktinfo-flb'>Select Group</div>
                        <DropdownWrapper
                            visible={showGroupDropdown}
                            onOutsideClickHandler={() => setGroupDropdown(!showGroupDropdown)}
                            extClasses={{
                                container: 'custom-dd fdags-dd'
                            }}
                        >
                            <div onClick={() => setGroupDropdown(!showGroupDropdown)}>
                                {group ? group.split('_').join(' ').toLowerCase() : 'Choose...'}
                            </div>
                            <DropdownContent
                                visible
                                extClasses = {{
                                    container: [
                                        'custom-dd__cc fdags-dd__cc height-transition',
                                        showGroupDropdown && 'fdags-dd__cc--ac']
                                }}
                            >
                                {
                                    ticketGroups.map(item => (
                                        <div
                                            key={item}
                                            className='custom-dd__cl'
                                            onClick={() => {
                                                setGroup(item);
                                                setGroupDropdown(false);
                                            }}
                                        >
                                            {item}
                                        </div>
                                    ))
                                }
                            </DropdownContent>
                        </DropdownWrapper>
                </div>
                {
                    errLabel ? (
                        <div className='err-label fdags-tktinfo-errlb'>{errLabel}</div>
                    ) : null
                }
                <Button
                    primary
                    label='Submit'
                    onClick={onSubmitCTA}
                    extClasses='fdags-tktinfo-cta'
                />
            </div>
        </div>
    );
};

export default memo(TicketInfo);

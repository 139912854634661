/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { getProxiedUrl } from '../../../utils/downloadFile';
import { ICONS_URL } from '../../../constants/assets';
import { caseConstants } from '../constant';

const PDFViewer = (props) => {
    const { url } = props;
    const canvasContainerRef = useRef(null);

    useEffect(() => {
        const loadPDF = async () => {
            try {
                const loadingTask = window.pdfjsLib.getDocument(url);
                const pdf = await loadingTask.promise;

                const container = canvasContainerRef.current;

                // Load all the pages of the PDF
                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    const scale = 1.5;
                    const viewport = page.getViewport({ scale });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = container.clientWidth;

                    const renderContext = {
                        canvasContext: context,
                        viewport,
                    };

                    await page.render(renderContext).promise;

                    canvasContainerRef.current.appendChild(canvas);
                }
            } catch (error) {
                const errorElemnets = document.createElement('div');
                errorElemnets.innerHTML = 'Unable to load PDF';
                canvasContainerRef.current.appendChild(errorElemnets);
            }
        };

        loadPDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className='user-case-review-pdf-container'
            ref={canvasContainerRef}
        />
    );
};

const ImageViewer = (props) => {
    const { url } = props;
    return (
        <div className='user-case-review-image-viewer'>
            <img src={url} alt='doc' />
        </div>
    );
};

const DocViewer = (props) => {
    const { btnClassname, url, type } = props;
    const [isVisible, setVisibility] = useState(false);

    const customStylesForModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 1,
        },
        content: {
            top: '5%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: '32px 20px',
            transform: 'translateX(-50%)',
            display: 'flex',
            flexDirection: 'column',
            fontSize: 16,
            width: '80%',
            border: 0,
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
            position: 'relative',
        },
    };

    const close = () => setVisibility((prev) => !prev);

    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    const getDoc = () => {
        switch (type) {
            case caseConstants.IMAGE:
                return (
                    <ImageViewer
                        url={getProxiedUrl(url)}
                    />
                );
            case caseConstants.PDF:
                return (
                    <PDFViewer url={getProxiedUrl(url)} />
                );
            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            {
                isVisible && (
                    <Modal
                        isOpen={isVisible}
                        style={customStylesForModal}
                        contentLabel='Uploaded Data Modal'
                    >
                        <div onContextMenu={handleContextMenu}>
                            <button
                                className='user-case-review-modal-close-btn'
                                type='button'
                                onClick={close}
                            >
                                <img src={ICONS_URL.CLOSE} alt='close' />
                            </button>
                            {
                                getDoc()
                            }

                        </div>
                    </Modal>
                )
            }
            <div className={btnClassname}>
                <button
                    onClick={close}
                    type='button'
                >View
                </button>
            </div>
        </React.Fragment>
    );
};

export default DocViewer;

import {
    REVIEWED_AUTH_FACTOR, ACTIVE_AUTH_FACTOR, INACTIVE_AUTH_FACTOR,
    AUTH_FACTOR_FORM_FIELDS,
} from './constants';

const getDifferentAuthFactorList = (
    authFactorList,
    reqdReviewedAuthFactorList = true,
    reqdActiveAuthFactorList = true,
    reqdInactiveAuthFactorList = true,
) => {
    let reviewedAuthFactorList;
    let activeAuthFactorList;
    let inactiveAuthFactorList;

    /**
     * this list contains those auth factors on which the complete
     * action has already been performed
     */
    if (reqdReviewedAuthFactorList) {
        reviewedAuthFactorList = authFactorList
            .filter((listItem) => {
                if (REVIEWED_AUTH_FACTOR.includes(listItem.status)) {
                    return true;
                }

                return false;
            })
            .map((listItem) => {
                let statusLabel;
                let errLabel;

                if (listItem.status === 1) {
                    statusLabel = listItem.async ? 'User cannot access' : 'User doesn\'t know';
                } else if (listItem.status === 2) {
                    statusLabel = 'User info not available';
                } else if (listItem.status === 6) {
                    statusLabel = 'Verified';
                } else if (listItem.status === 10) {
                    statusLabel = 'Retry limit reached';
                    errLabel = 'No more retries allowed';
                }

                return {
                    ...listItem,
                    statusLabel,
                    errLabel,
                };
            });
    }

    /**
     * this list contains those auth factors on which the current
     * action should be performed
     * for now, it contains only 1 auth factor
     */
    if (reqdActiveAuthFactorList) {
        let activeAuthFactorCounter = 0;

        activeAuthFactorList = authFactorList
            .filter((listItem) => {
                if (activeAuthFactorCounter >= 1) return false;

                if (ACTIVE_AUTH_FACTOR.includes(listItem.status)) {
                    activeAuthFactorCounter += 1;
                    return true;
                }

                return false;
            })
            .map((listItem) => {
                let errLabel;

                if (listItem.status === 3) { // async
                    errLabel = 'Delivery Pending';
                } else if (listItem.status === 4) { // async
                    errLabel = 'Delivery Failed';
                } else if (listItem.status === 5) { // async
                    errLabel = 'Verification Pending';
                } else if (listItem.status === 7) {
                    errLabel = 'Verification Failed. Please try again!';
                } else if (listItem.status === 8) {
                    errLabel = 'Verification Failed. Please try again!';
                } else if (listItem.status === 9) { // async
                    errLabel = 'Verification Failed Rejected';
                }

                return {
                    ...listItem,
                    errLabel,
                };
            });
    }

    /**
     * this list contains those auth factors on which the next
     * action should be performed
     * for now, it contains only 1 auth factor
     */
    if (reqdInactiveAuthFactorList) {
        let inactiveAuthFactorCounter = 0;

        inactiveAuthFactorList = authFactorList
            .filter((listItem) => listItem.name !== (activeAuthFactorList.length && activeAuthFactorList[0].name))
            .filter((listItem) => {
                if (inactiveAuthFactorCounter >= 1) return false;

                if (INACTIVE_AUTH_FACTOR.includes(listItem.status)) {
                    inactiveAuthFactorCounter += 1;
                    return true;
                }

                return false;
            });
    }

    return {
        reviewedAuthFactorList,
        activeAuthFactorList,
        inactiveAuthFactorList,
    };
};

const getAuthFactorValue = (authFactor, ticket) => {
    let value;

    switch (authFactor) {
        case 'MOBILE_PROMPT': {
            if (ticket.hasUserInfo) {
                value = ticket.userInfo.phoneNumber;
            } else {
                value = ticket.basicInfo.phoneNumber;
            }

            break;
        }

        case 'EMAIL_VERIFICATION': {
            if (ticket.hasUserInfo) {
                value = ticket.userInfo.email;
            } else {
                value = ticket.basicInfo.email;
            }

            break;
        }

        case 'TRANSACTION_AMOUNT': {
            value = {
                currency_code: 'INR',
                units: '',
            };

            break;
        }

        case 'MOTHERS_NAME':
        case 'FATHERS_NAME': {
            value = {
                honorific: '',
                first_name: '',
                middle_name: '',
                last_name: '',
            };

            break;
        }

        case 'DOB': {
            value = new Date();

            break;
        }

        /**
         * LAST_FIVE_PAN_CHARACTERS
         * PERMANENT_ADDRESS_PIN_CODE
         */
        default:
            value = '';
    }

    return value;
};

const getModifiedDataForAuthFactors = (screenOptions, ticket) => {
    if (!screenOptions) return null;

    const { authFactorList } = screenOptions;

    const modifiedAuthFactorList = authFactorList.map((listItem) => {
        const {
            labelId: formLabelId, label: formLabel, type: formType, description: formDescription, async,
        } = AUTH_FACTOR_FORM_FIELDS[listItem.name];
        const formValue = getAuthFactorValue(listItem.name, ticket);

        return {
            ...listItem,
            formLabel,
            formLabelId,
            formValue,
            formType,
            formDescription,
            async,
        };
    });

    return {
        ...getDifferentAuthFactorList(modifiedAuthFactorList),
        authFactorList: modifiedAuthFactorList,
    };
};

const getWaitlistInfo = (data) => [
    {
        label: 'Name',
        value: data.name,
    },
    {
        label: 'Registered Mobile Number',
        value: data.phoneNumber,
    },
    {
        label: 'Registered Email ID',
        value: data.personalEmail,
    },
    {
        label: 'Work Email ID',
        value: data.workEmail,
    },
    {
        label: 'Access Status',
        value: data.accessStatus,
    },
    {
        label: 'Failure Reason',
        value: data.failureReason,
    },
];

const getAccountFreezeInfo = (data) => [
    {
        label: 'Status',
        value: data?.status || '',
    },
    {
        label: 'Reason',
        value: data?.reason || '',
    },
    {
        label: 'Lien Amount',
        value: data?.lienBalance || '',
    },
];

export {
    getDifferentAuthFactorList,
    getAuthFactorValue,
    getModifiedDataForAuthFactors,
    getWaitlistInfo,
    getAccountFreezeInfo,
};

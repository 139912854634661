/**
*
* SelectAccessLevel - Component to select the access level at the entry point of App
*
*/

import React, { memo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { makeSelectAgentInfo } from '../selectors';

import './style.scss';

const SelectAccessLevel = (props) => {
    // reducer state variables
    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);

    const { err, setAccessLevel } = props;

    const onClickHandler = (value) => () => {
        setAccessLevel(value);
    };

    return (
        <div className='accesslevel'>
            <div className='accesslevel-label'>
                Select your role to proceed
            </div>
            <div className='accesslevel-cr'>
                {
                    agentInfo.accessLevelList.map((item) => (
                        <div
                            key={item.label}
                            className='accesslevel-item'
                            onClick={onClickHandler(item.value)}
                        >
                            {item.value}
                        </div>
                    ))
                }
            </div>
            <div className='accesslevel-errlb'>{err}</div>
        </div>
    );
};

export default memo(SelectAccessLevel);

/**
 *
 * Search card for CX disputes flow
 *
 */
import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Button, DateRangePicker, ExpandableCard, FormDropdown, FormWithSingleInput,
} from '../../../components';
import { getDisputesInfoAction } from '../actions';

import {
    CHANNEL_OPTIONS, FROM_DATE_INFO, INITIAL_DATE_RANGE, TO_DATE_INFO, DISPUTE_TYPE_OPTIONS, DISPUTE_STATE_OPTIONS, DATE_PICKER_CLS,
} from '../constants';

const SearchCard = (props) => {
    const { ticketId, index } = props;

    const dispatch = useDispatch();

    // local state
    const [isCardExpanded, setIsCardExpanded] = useState(false);
    // form
    const [fromDate, setFromDate] = useState(INITIAL_DATE_RANGE.fromDate);
    const [toDate, setToDate] = useState(INITIAL_DATE_RANGE.toDate);
    const [formErrLabel, setFormErrLabel] = useState('');
    const [actorId, setActorId] = useState('');
    const [formTicketId, setFormTicketId] = useState('');
    const [channel, setChannel] = useState([]);
    const [disputeType, setDisputeType] = useState([]);
    const [disputeState, setDisputeState] = useState([]);

    const handleSearchDisputes = (e) => {
        e.preventDefault();

        if (!fromDate.value || !toDate.value) {
            setFormErrLabel('Please fill all the * marked fields');
            return;
        }
        setFormErrLabel('');

        const getValueFn = (item) => item.value;

        const payload = {
            fromDate: fromDate.value,
            toDate: toDate.value,
            actorId,
            ticketId: formTicketId,
            channel: channel.length > 0 ? channel.map(getValueFn) : null,
            disputeType: disputeType.length > 0 ? disputeType.map(getValueFn) : null,
            disputeState: disputeState.length > 0 ? disputeState.map(getValueFn) : null,
        };

        const data = {
            ticketId,
            index,
            payload,
        };

        dispatch(getDisputesInfoAction(data));
        setIsCardExpanded(!isCardExpanded);
    };

    const formConfig = [
        {
            formType: 'date-range',
            dateInfo: {
                fromDateInfo: FROM_DATE_INFO,
                toDateInfo: TO_DATE_INFO,
                initialDateRange: INITIAL_DATE_RANGE,
                extClasses: DATE_PICKER_CLS,
            }
        },
        {
            formType: 'input',
            label: 'Enter Actor Id',
            labelId: 'disputes-actor-id',
            input: {
                value: actorId,
                onChange: (e) => setActorId(e.target.value),
            },
        },
        {
            formType: 'input',
            label: 'Enter Ticket Id',
            labelId: 'disputes-ticket-id',
            input: {
                type: 'number',
                value: formTicketId,
                onChange: (e) => setFormTicketId(e.target.value),
            },
        },
        {
            formType: 'multi-dd',
            label: 'Select Channel',
            labelId: 'channel-multi-dd',
            options: CHANNEL_OPTIONS,
            input: {
                value: channel,
                onChange: (value) => setChannel(value),
            },
        },
        {
            formType: 'multi-dd',
            label: 'Select Dispute Type',
            labelId: 'dispute-type-multi-dd',
            options: DISPUTE_TYPE_OPTIONS,
            input: {
                value: disputeType,
                onChange: (value) => setDisputeType(value),
            },
        },
        {
            formType: 'multi-dd',
            label: 'Select Dispute State',
            labelId: 'dispute-state-multi-dd',
            options: DISPUTE_STATE_OPTIONS,
            input: {
                value: disputeState,
                onChange: (value) => setDisputeState(value),
            },
        },

    ];

    const renderFormFields = (item) => {
        switch (item.formType) {
            case 'date-range':
                return (
                    <DateRangePicker
                        key='cx-disputes-date-range'
                        dateInfo={item.dateInfo}
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                    />
                );

            case 'input':
                return (
                    <FormWithSingleInput
                        key={item.labelId}
                        label={item.label}
                        labelId={item.labelId}
                        input={item.input}
                        extClasses={{
                            container: 'disputes-fc',
                            inputField: 'disputes-dd',
                            label: 'disputes-dd__lb1',
                        }}
                        hideErrLabel
                    />
                );

            case 'multi-dd':
                return (
                    <div className='frcWrapper' key={item.labelId}>
                        <div className='disputes-dd__lb'>{item.label}</div>
                        <FormDropdown
                            isMulti
                            options={item.options}
                            input={item.input}
                            cacheKey={item.labelId}
                            extStyles={{
                                container: 'disputes-dd',
                            }}
                        />
                    </div>

                );

            default:
                throw new Error('invalid form type passed');
        }
    };

    return (
        <ExpandableCard
            heading='Search CX Disputes In Manual Intervention'
            extClasses='disputes-ec'
            isExpanded={isCardExpanded}
            setIsExpanded={() => setIsCardExpanded(!isCardExpanded)}
        >
            <div className='disputes-sc'>
                <div className='frwpWrapper disputes-sc__gap'>
                    {formConfig.map((formItem) => renderFormFields(formItem))}
                </div>

                {formErrLabel && <div className='err-label'>{formErrLabel}</div>}

                <div className='frWrapper disputes-sc__3r'>
                    <Button
                        extClasses='disputes-sc__cta'
                        primary
                        label='Search'
                        onClick={handleSearchDisputes}
                    />
                </div>
            </div>
        </ExpandableCard>
    );
};

export default memo(SearchCard);

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import {
    PAYMENT_INSTRUMENTS_CARD_ROOT, PAYMENT_INSTRUMENTS_CARD_ROOT_V2, PAYMENT_INSTRUMENTS_UPI_ROOT,
    PAYMENT_INSTRUMENTS_UPI_ROOT_V2, PAYMENT_INSTRUMENTS_ROOT,
} from '../../api/routes';

import {
    GET_CARDS_INFO, SET_CARDS_INFO, ERR_CARDS_INFO, GET_UPI_INFO, SET_UPI_INFO,
    ERR_UPI_INFO, TOGGLE_UPI_STATUS, SET_UPI_STATUS, ERR_UPI_STATUS, INITIATE_SUSPEND_CARD,
    ERR_SUSPEND_CARD, SET_SUSPEND_CARD, INITIATE_BLOCK_CARD, SET_BLOCK_CARD, ERR_BLOCK_CARD,
    GET_CARD_INFO, SET_CARD_INFO, ERR_CARD_INFO, GET_CARD_TRACKING_INFO, SET_CARD_TRACKING_INFO,
    ERR_CARD_TRACKING_INFO, GET_CARD_LIST, SET_CARD_LIST, ERR_CARD_LIST, UPDATE_FREE_CARD_REPLACEMENT,
    SET_FREE_CARD_REPLACEMENT_LOADING_STATE,
    SET_FOREX_TXN_INFO,
    ERR_FOREX_TXN_INFO,
    GET_FOREX_TXN_INFO,
    GET_CARD_REQUEST_STATUS,
    SET_CARD_REQUEST_STATUS,
    ERR_CARD_REQUEST_STATUS,
    GET_CARD_AMC,
    SET_CARD_AMC,
    ERR_CARD_AMC,
} from './constants';
import { SENSITIVE_ACTIONS } from '../Tickets/constants';
import { setAuthScreen, discardActionAuthInfo } from '../Tickets/saga';

function* getCardsInfo(action) {
    const {
        ticketId, index, userId, activated, type,
    } = action.data;

    const queryParams = `?ticketId=${ticketId}&userId=${userId}&activated=${activated}&type=${type}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAYMENT_INSTRUMENTS_CARD_ROOT}/${queryParams}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({
                type: SET_CARDS_INFO,
                data: {
                    ticketId, ...response, type, activated,
                },
            });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_CARDS_INFO, data: { ticketId } });
        }
    } catch (e) {
        yield put({ type: ERR_CARDS_INFO, data: { ticketId } });
    }
}

function* initiateSuspendCard(action) {
    const {
        ticketId, index, userId, cardId, isConfirmAction,
    } = action.data;

    const queryParams = `?ticketId=${ticketId}&userId=${userId}`;
    const payload = {
        cardId,
        isConfirmAction,
    };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${PAYMENT_INSTRUMENTS_CARD_ROOT}/suspend${queryParams}`,
            payload,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response, type: SENSITIVE_ACTIONS.SUSPENDDEBIT } });

        if (!result) {
            yield put({ type: SET_SUSPEND_CARD, data: { ticketId, ...response, isConfirmAction } });
            toastify(response.message, 'success');
            yield put({ type: GET_CARD_LIST, data: { ticketId, userId, index } });
        } else if (result === 'confirm-screen') {
            yield put({ type: SET_SUSPEND_CARD, data: { ticketId, isConfirmAction } });
            yield call(discardActionAuthInfo, { data: { type: SENSITIVE_ACTIONS.SUSPENDDEBIT, index } });
        } else {
            yield put({ type: ERR_SUSPEND_CARD, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_SUSPEND_CARD, data: { ticketId } });
    }
}

function* initiateBlockCard(action) {
    const {
        ticketId, index, userId, cardId, isConfirmAction,
    } = action.data;

    const queryParams = `?ticketId=${ticketId}&userId=${userId}`;
    const payload = {
        cardId,
        isConfirmAction,
    };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${PAYMENT_INSTRUMENTS_CARD_ROOT}/block${queryParams}`,
            payload,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response, type: SENSITIVE_ACTIONS.BLOCKDEBIT } });

        if (!result) {
            yield put({ type: SET_BLOCK_CARD, data: { ticketId, ...response, isConfirmAction } });
            toastify(response.message, 'success');
            yield put({ type: GET_CARD_LIST, data: { ticketId, userId, index } });
        } else if (result === 'confirm-screen') {
            yield put({ type: SET_BLOCK_CARD, data: { ticketId, isConfirmAction } });
            yield call(discardActionAuthInfo, { data: { type: SENSITIVE_ACTIONS.BLOCKDEBIT, index } });
        } else {
            yield put({ type: ERR_BLOCK_CARD, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_BLOCK_CARD, data: { ticketId } });
    }
}

function* getCardInfo(action) {
    const {
        ticketId, index, userId, cardId, issuerBank, isCardLimitsScreen, isCardSettingsScreen,
    } = action.data;

    const queryString = getQueryStringFromObject({ ticketId, userId, issuerBank });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAYMENT_INSTRUMENTS_CARD_ROOT}/${cardId}/info?${queryString}`,
        );

        const screenType = isCardLimitsScreen ? SENSITIVE_ACTIONS.CARDLIMITS : SENSITIVE_ACTIONS.CARDSETTINGS;
        const result = yield call(setAuthScreen, { data: { index, ...response, type: screenType } });

        if (!result) {
            yield put({
                type: SET_CARD_INFO,
                data: {
                    ticketId, ...response, isCardLimitsScreen, isCardSettingsScreen,
                },
            });
            yield call(discardActionAuthInfo, { data: { type: screenType, index } });
        } else {
            yield put({ type: ERR_CARD_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CARD_INFO, data: { ticketId } });
    }
}

function* getCardTrackingInfo(action) {
    const {
        ticketId, userId, cardId, resolve,
    } = action.data;

    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAYMENT_INSTRUMENTS_CARD_ROOT_V2}/${cardId}/tracking-info?${queryString}`,
        );

        yield put({ type: SET_CARD_TRACKING_INFO, data: { ticketId, ...response } });
        yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CARD_TRACKING_INFO, data: { ticketId } });
    }
}

function* getCardRequestStatus(action) {
    const {
        ticketId, userId, cardId, resolve,
    } = action.data;

    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAYMENT_INSTRUMENTS_CARD_ROOT}/${cardId}/status?${queryString}`,
        );

        yield put({ type: SET_CARD_REQUEST_STATUS, data: { ticketId, ...response } });
        yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CARD_REQUEST_STATUS, data: { ticketId, err } });
    }
}

function* getCardAMC(action) {
    const {
        ticketId, userId, cardId, resolve,
    } = action.data;

    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAYMENT_INSTRUMENTS_CARD_ROOT}/${cardId}/amc?${queryString}`,
        );

        yield put({ type: SET_CARD_AMC, data: { ticketId, ...response } });
        yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CARD_AMC, data: { ticketId, err } });
    }
}

function* getUPIInfo(action) {
    const { ticketId, index, userId } = action.data;

    const queryParams = `?ticketId=${ticketId}&userId=${userId}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAYMENT_INSTRUMENTS_UPI_ROOT_V2}/${queryParams}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_UPI_INFO, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_UPI_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_UPI_INFO, data: { ticketId, err: e.message } });
    }
}

function* toggleUPIStatus(action) {
    const {
        ticketId, index, userId, enable, isConfirmAction, vpaHandle, piId,
    } = action.data;

    const queryParams = `?ticketId=${ticketId}&userId=${userId}`;
    const payload = {
        enable,
        isConfirmAction,
        vpaHandle,
        piId,
    };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${PAYMENT_INSTRUMENTS_UPI_ROOT}/status${queryParams}`,
            payload,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response, type: SENSITIVE_ACTIONS.TOGGLEUPI } });

        if (!result) {
            yield put({ type: SET_UPI_STATUS, data: { ticketId, ...response, isConfirmAction } });
            yield put({ type: GET_UPI_INFO, data: { ticketId, userId, index } });
        } else if (result === 'confirm-screen') {
            yield put({ type: SET_UPI_STATUS, data: { ticketId, isConfirmAction } });
            yield call(discardActionAuthInfo, { data: { type: SENSITIVE_ACTIONS.TOGGLEUPI, index } });
        } else {
            yield put({ type: ERR_UPI_STATUS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_UPI_STATUS, data: { ticketId } });
    }
}

function* getCardList(action) {
    const { ticketId, index, userId } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAYMENT_INSTRUMENTS_ROOT}/cards?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_CARD_LIST, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_CARD_LIST, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CARD_LIST, data: { ticketId } });
    }
}

function* updateFreeCardReplacement(action) {
    const { ticketId, index, userId } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${PAYMENT_INSTRUMENTS_ROOT}/card-replacement?${queryString}`,
            {},
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            toastify(response.description, 'success');
        }

        yield put({ type: SET_FREE_CARD_REPLACEMENT_LOADING_STATE, data: { ticketId } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_FREE_CARD_REPLACEMENT_LOADING_STATE, data: { ticketId } });
    }
}

function* getForexTxnInfo(action) {
    const {
        ticketId, startDate, prev, next, index,
    } = action.data;

    const queryString = getQueryStringFromObject({
        ticketId, prev, next, startDate,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAYMENT_INSTRUMENTS_ROOT}/forex-txn?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });
        if (!result) {
            yield put({ type: SET_FOREX_TXN_INFO, data: { ticketId, forexTxnInfo: response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_FOREX_TXN_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_FOREX_TXN_INFO, data: { ticketId } });
    }
}

export default function* paymentInstrumentsSaga() {
    yield all(
        [
            yield takeLatest(GET_CARDS_INFO, getCardsInfo),
            yield takeLatest(INITIATE_SUSPEND_CARD, initiateSuspendCard),
            yield takeLatest(INITIATE_BLOCK_CARD, initiateBlockCard),
            yield takeLatest(GET_CARD_INFO, getCardInfo),
            yield takeLatest(GET_CARD_TRACKING_INFO, getCardTrackingInfo),
            yield takeLatest(GET_CARD_REQUEST_STATUS, getCardRequestStatus),
            yield takeLatest(GET_CARD_AMC, getCardAMC),
            yield takeLatest(GET_UPI_INFO, getUPIInfo),
            yield takeLatest(GET_FOREX_TXN_INFO, getForexTxnInfo),
            yield takeLatest(TOGGLE_UPI_STATUS, toggleUPIStatus),
            yield takeLatest(GET_CARD_LIST, getCardList),
            yield takeLatest(UPDATE_FREE_CARD_REPLACEMENT, updateFreeCardReplacement),
        ],
    );
}

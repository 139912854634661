/**
 *
 * RenderDocument:
 * Component that renders a PDF or image
 *
 */

import React, { memo } from 'react';
import { isEmpty } from 'ramda';
import classNames from 'classnames';
// import { TokensContext } from '../../../context';

import './style.scss';

const DOCUMENT_TYPE_MAPPING = {
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    PDF: 'application/pdf',
    TIFF: 'image/tiff',
};

const RenderDocument = (props) => {
    const {
        index,
        data = {},
        disableToolbar = false,
        showNotFound = false,
    } = props;

    if (isEmpty(data)) return null;

    const {
        base64String,
        docType = 'PNG',
        srcUrl,
    } = data;

    // If no source data, render nothing
    if (!(srcUrl || base64String)) return (showNotFound) ? <div> Media not found! </div> : null;

    // currently nonce is not used because we are setting style-src 'unsafe-inline', but should be used in the future
    // const { cspNonce } = useContext(TokensContext);

    // fallback to PNG if docType is not passed
    const docDataType = DOCUMENT_TYPE_MAPPING[docType];
    const base64DataString = `data:${docDataType};base64, ${base64String}`;
    const srcData = srcUrl || base64DataString;

    const disableToolbarStr = disableToolbar ? '#toolbar=0' : '';
    const imgClasses = classNames('redoc-img', {
        'ml-30': index !== 0,
    });

    if (docType === 'PDF') {
        return (
            <iframe
                title='document'
                src={`${srcData}${disableToolbarStr}`}
                type={docDataType}
                className='redoc-pdf'
                // nonce={cspNonce}
            />
        );
    }

    return (
        <img
            src={srcData}
            className={imgClasses}
            alt='document'
        />
    );
};

export default memo(RenderDocument);

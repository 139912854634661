/**
 * @file d3 utilities
 */

import * as d3 from 'd3';
import { MONTH, WEEK } from './constants';

export const dateHour = (timestamp) => {
    const dateHourFormat = d3.timeFormat('%Y-%m-%d %H:%M:%S')(new Date(timestamp));
    return dateHourFormat.split(' ')[0];
};

export const extendModifier = (data) => {
    const [min, max] = data;

    if (min === max) {
        return [d3.timeMonth.offset(new Date(min), -1).getTime(), d3.timeMonth.offset(new Date(min), 1).getTime()];
    }

    return data;
};

export const getD3Time = (val) => {
    if (val === MONTH) return d3.timeMonth;

    if (val === WEEK) return d3.timeWeek;

    return d3.timeDay;
};

/**
 * Get timeformat function based on aggregation (day,week,month) for x-scale
 * @param {string} val  - aggregation
 * @returns {*} - timeFormat function
 */
export const getD3TimeFormat = (val) => {
    if (val === MONTH) return d3.timeFormat('%b');

    if (val === WEEK) return d3.timeFormat('%b %e');

    return d3.timeFormat('%b %e');
};

export const tooltipFormat = (aggregation) => {
    if (aggregation === MONTH) {
        return d3.timeFormat('%Y-%B');
    }
    if (aggregation === WEEK) {
        return d3.timeFormat('%Y-%B');
    }
    return d3.timeFormat('%Y-%B-%d');
};

/**
 * Date formatted eg. Aug 20, 2023
 * @param {Date}
 * @returns {string} formatted date
 */
export const dateFormat = d3.timeFormat('%b %d, %Y');

/**
 * Get height based on 16:9 aspect ratio using width
 * @param {number} width
 * @param {number} margins
 * @returns {number}
 */
export const getHeightBasedOnWidth = (width, margins = 0) => ((width * 9) / 16) + margins;

/**
 * This color schema is defined on Transaction view for case management.
 */

export const colorSchema = {
    TEAL_GREEN: '#1b9e77',
    ORANGE: '#d95f02',
    PURPLE: '#7570b3',
    PINK: '#e7298a',
    LIME_GREEN: '#66a61e',
    MUSTARD_YELLOW: '#e6ab02',
    BROWN: '#a6761d',
    GRAY: '#666666',
    DARK_BLUE: '#336699',
    LIGHT_BLUE: '#99CCFF',
    LIGHT_GREEN: '#99CC66',
    BEIGE: '#CCCC99',
    LIGHT_YELLOW: '#FFFF99',
    LIGHT_ORANGE: '#FF9933',
    BRIGHT_RED: '#FF3333',
    FOREST_GREEN: '#228B22',
    OLIVE: '#808000',
    SANDY_BROWN: '#F4A460',
    SKY_BLUE: '#87CEEB',
    OCEAN_BLUE: '#4682B4',
    SUNSET_ORANGE: '#FD5E53',
    MINT_GREEN: '#98FF98',
    LAVENDER: '#E6E6FA',
    LIGHT_PINK: '#FFB6C1',
    SOFT_BLUE: '#ADD8E6',
    PEACH: '#FFE5B4',
    MISTY_ROSE: '#FFE4E1',
    DimGray: '#696969',
    LightSlateGray: '#778899',
    MediumSlateBlue: '#7B68EE',
    Aquamarine: '#7FFFD4',
    Maroon: '#800000',
    Purple: '#800080',
    Olive: '#808000',
    Gray: '#808080',
    PaleGoldenRod: '#EEE8AA',
    LightCoral: '#F08080',
    Khaki: '#F0E68C',
    HoneyDew: '#F0FFF0',
    SandyBrown: '#F4A460',
    Wheat: '#F5DEB3',
    Beige: '#F5F5DC',
    LightGoldenRodYellow: '#FAFAD2',
};

/**
 *
 * @param {*} staticCategoriesMapping : containing color mapping color -> category
 * @returns Dynamic color mapping is applied to each category, while maintaining the color mapping for a given category in staticCategoriesMapping.
 */

export const dynamicColorMapping = (staticCategoriesMapping = {}) => {
    const staticMapping = new Map();
    const random = [];

    Object.entries(colorSchema).forEach(([key, value]) => {
        if (staticCategoriesMapping[key]) {
            staticMapping[staticCategoriesMapping[key]] = value;
        } else {
            random.push(value);
        }
    });

    return {
        ...staticMapping,
        random,
    };
};

export const createColorMapping = (categories, selectedColorSchema) => {
    const colorMapping = {};
    let randomIndex = 0;
    categories.forEach((item) => {
        if (selectedColorSchema[item]) {
            colorMapping[item] = selectedColorSchema[item];
        } else {
            colorMapping[item] = selectedColorSchema?.random[randomIndex];
            randomIndex += 1;
            if (Array.isArray(selectedColorSchema?.random) && selectedColorSchema.random.length === randomIndex) randomIndex = 0;
        }
    });
    return (colorTag) => colorMapping[colorTag];
};

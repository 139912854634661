import { createSelector } from 'reselect';

// eslint-disable-next-line import/no-cycle
import { APP_KEY } from './constants';

const selectApp = (state) => state.get('app');

const makeSelectLoading = () => createSelector(
    selectApp,
    (substate) => substate.get('loading').toJS(),
);

const makeSelectHasAgentInfo = () => createSelector(
    selectApp,
    (substate) => substate.get('hasAgentInfo'),
);

const makeSelectAgentInfo = () => createSelector(
    selectApp,
    (substate) => substate.get('agentInfo').toJS(),
);

const makeSelectErr = () => createSelector(
    selectApp,
    (substate) => substate.get('err').toJS(),
);

const makeSelectCSPNonce = () => createSelector(
    selectApp,
    (substate) => substate.get('cspNonce'),
);

const makeSelectLoginUrl = () => createSelector(
    selectApp,
    (substate) => substate.get('loginUrl'),
);

const makeSelectAccessLevel = () => createSelector(
    (state) => state.getIn([APP_KEY, 'selectedAccessLevel']),
    (substate) => substate,
);

const makeSelectMetaInfo = () => createSelector(
    (state) => state.getIn([APP_KEY, 'metaInfo']),
    (substate) => substate.toJS(),
);

const makeSelectAccessLevelList = createSelector(
    (state) => state.getIn([APP_KEY, 'accessLevelList']),
    (substate) => substate.toJS(),
);

const makeSelectBannerList = createSelector(
    (state) => state.getIn([APP_KEY, 'bannerList']),
    (substate) => substate.toJS(),
);

const makeSelectBannersVisibility = createSelector(
    (state) => state.getIn([APP_KEY, 'showBanners']),
    (substate) => substate,
);

export {
    makeSelectLoading,
    makeSelectHasAgentInfo,
    makeSelectAgentInfo,
    makeSelectErr,
    makeSelectCSPNonce,
    makeSelectLoginUrl,
    makeSelectAccessLevel,
    makeSelectMetaInfo,
    makeSelectAccessLevelList,
    makeSelectBannerList,
    makeSelectBannersVisibility,
};

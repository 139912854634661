import { createSelector } from 'reselect';

const selectDBStates = state => state.get('dbStates');

const makeSelectLoading = () => createSelector(
    selectDBStates,
    substate => substate.get('loading')
);

const makeSelectErr = () => createSelector(
    selectDBStates,
    substate => substate.get('err')
);

const makeSelectServiceList = () => createSelector(
    selectDBStates,
    substate => substate.get('serviceList').toJS()
);

const makeSelectEntityList = () => createSelector(
    selectDBStates,
    substate => substate.get('entityList').toJS()
);

const makeSelectOptionList = () => createSelector(
    selectDBStates,
    substate => substate.get('optionList').toJS()
);

const makeSelectDBInfo = () => createSelector(
    selectDBStates,
    substate => substate.get('dbInfo').toJS()
);

const makeSelectHasDBInfo = () => createSelector(
    selectDBStates,
    substate => substate.get('hasDBInfo')
);

const makeSelectMonorailInfo = () => createSelector(
    selectDBStates,
    substate => substate.get('monorailInfo').toJS()
);

const makeSelectService = () => createSelector(
    selectDBStates,
    substate => substate.get('selectedService').toJS(),
);

const makeSelectEntity = () => createSelector(
    selectDBStates,
    substate => substate.get('selectedEntity').toJS(),
);

export {
    makeSelectLoading,
    makeSelectErr,
    makeSelectServiceList,
    makeSelectEntityList,
    makeSelectOptionList,
    makeSelectDBInfo,
    makeSelectHasDBInfo,
    makeSelectMonorailInfo,
    makeSelectService,
    makeSelectEntity,
};

import { createSelector } from 'reselect';

const selectFederalAgents = state => state.get('federal');

const makeSelectLoading = () => createSelector(
    selectFederalAgents,
    substate => substate.get('loading')
);

const makeSelectTicketList = () => createSelector(
    selectFederalAgents,
    substate => substate.get('ticketList').toJS()
);

const makeSelectTicketInfo = () => createSelector(
    selectFederalAgents,
    substate => substate.get('ticketInfo').toJS()
);

const makeSelectTicketGroups = () => createSelector(
    selectFederalAgents,
    substate => substate.get('ticketGroups').toJS()
);

export {
    makeSelectLoading,
    makeSelectTicketList,
    makeSelectTicketInfo,
    makeSelectTicketGroups,
};

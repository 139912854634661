/**
 * Layout/index.js - File that imports all the Layouts and provides the layout based on the access level
 */

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { makeSelectAccessLevel } from '../../../containers/App/selectors';
import SherlockV2Generic from './SherlockV2Generic';
import { ROLE_OPTIONS, ROLE_TO_SCREEN_MAPPING, SCREENS } from '../utils/pathsHelper';

const Layout = () => {
    const selectedAccessLevel = useSelector(makeSelectAccessLevel(), shallowEqual);

    const options = ROLE_OPTIONS[selectedAccessLevel];

    switch (ROLE_TO_SCREEN_MAPPING[selectedAccessLevel]) {
        case SCREENS.SHERLOCK_V2_GENERIC:
            return <SherlockV2Generic isSideBarExpanded={options?.isSideBarExpanded} isSearchBarVisible={options?.isSearchBarVisible} />;
        default:
            return <SherlockV2Generic isSideBarExpanded={options?.isSideBarExpanded} isSearchBarVisible={options?.isSearchBarVisible} />;
    }
};

export default Layout;

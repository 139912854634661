/**
 * @file AttachDocument Modal
 * The Modal lets users attach files for a given dispute in the DMP flow
 */

import React, { memo, useState } from 'react';
import Modal from 'react-modal';

import { Button, LoaderOrError } from '../../../components';
import {
    getErrMsg, onFileChange, onFileClick, showErrorToastMsg,
} from '../../../utils';

import { DEFAULT_STYLES_FOR_MODAL } from '../constants';

const customStylesForModal = {
    overlay: {
        ...DEFAULT_STYLES_FOR_MODAL.overlay,
    },
    content: {
        ...DEFAULT_STYLES_FOR_MODAL.content,
        width: 700,
        height: 500,
    },
};

const UploadCSV = (props) => {
    const {
        visible,
        toggleHandler,
        onSubmit,
    } = props;

    const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorLabel, setErrorLabel] = useState('');

    const setValue = (value) => {
        setFileData(value);
    };

    const fileParams = {
        item: { type: 11 }, // type 11 is used for single file upload and type 12 for multiple file upload
        setErrLabel: setErrorLabel,
        setValue,
        setFileName,
    };

    const handleFileUploadSubmit = async () => {
        if (!fileData) {
            setErrorLabel('You need to select a file before clicking submit');
            return;
        }

        try {
            const payload = {
                fileData,
                fileName,
            };

            setLoading(true);
            setErrorLabel('');

            onSubmit(payload);
        } catch (error) {
            showErrorToastMsg(error);
            const errorMsg = getErrMsg(error);
            setErrorLabel(errorMsg);
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => {
        toggleHandler(false);
        setFileData(null);
        setErrorLabel('');
    };

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='Add Dispute Details'
        >
            <div className='fcWrapper mb-30'>
                <div className='heading3 mb-15'>
                    Upload File
                </div>
                <div className='frcsbWrapper'>
                    <input
                        type='file'
                        className='btu-upload-csv-fc__ip'
                        accept='.csv,.txt,.jpeg,.xls,.xlsx,.pdf,.doc,.docx'
                        onClick={onFileClick(fileParams)}
                        onChange={onFileChange(fileParams)}
                    />
                    <Button
                        v2
                        primary
                        label='Submit'
                        disabled={!fileData}
                        onClick={handleFileUploadSubmit}
                    />
                </div>
            </div>

            <Button
                v2
                secondary
                extClasses='disputes-modal-cta'
                label='Close'
                onClick={closeModal}
            />

            <LoaderOrError
                loading={loading}
                errorLabel={errorLabel}
            />
        </Modal>
    );
};

export default memo(UploadCSV);

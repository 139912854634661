/**
 * @file LivenessAndFacematch constants
 */

import { FACEMATCH_SPECIFIC_STATE } from '../FacematchReviewV2/constants';
import { LIVENESS_SPECIFIC_STATE } from '../LivenessReviewV2/constants';
import { RISK_OPS_COMMON_STATE } from '../RiskOps/constants';

// container key
export const LIVENESS_AND_FACEMATCH_REVIEW_KEY = 'livenessAndFacematchReview';

// value constants
const PAYLOAD_TYPE_LIVENESS_AND_FACEMATCH = 'PAYLOAD_TYPE_LIVENESS_AND_FACEMATCH';
const PAYLOAD_TYPE_AFU_LIVENESS_AND_FACEMATCH = 'PAYLOAD_TYPE_AFU_LIVENESS_AND_FACEMATCH';

export const QUEUE_PAYLOAD_TYPES = [PAYLOAD_TYPE_LIVENESS_AND_FACEMATCH, PAYLOAD_TYPE_AFU_LIVENESS_AND_FACEMATCH];

export const PAYLOAD_DD_OG_STATE = {
    value: PAYLOAD_TYPE_LIVENESS_AND_FACEMATCH,
    isVisible: false,
};

export const LIVENESS_AND_FACEMATCH_REDUCER_STATE = {
    ...RISK_OPS_COMMON_STATE,
    ...LIVENESS_SPECIFIC_STATE,
    ...FACEMATCH_SPECIFIC_STATE,
    payloadType: PAYLOAD_DD_OG_STATE,
};

/**
 * @file ViewDocuments
 * View all the files uploaded for a particular dispute in table format with the link of the files
 * The files open in a new tab for viewing
 */

import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-modal';

import { Button, LoaderOrError, Table } from '../../../components';

import {
    clientApiWrapper, getErrMsg, getQueryStringFromObject, showErrorToastMsg,
} from '../../../utils';
import { DISPUTE_DOCUMENT_ENDPOINT } from '../../../api/routes';

import { DEFAULT_STYLES_FOR_MODAL } from '../constants';

const customStylesForModal = {
    overlay: {
        ...DEFAULT_STYLES_FOR_MODAL.overlay,
    },
    content: {
        ...DEFAULT_STYLES_FOR_MODAL.content,
        width: 700,
        height: 700,
    },
};

const ViewDocuments = (props) => {
    const {
        visible,
        toggleHandler,
        data,
        setData,
        parentProps: {
            ticketId = 0,
        },
    } = props;

    const [loading, setLoading] = useState(false);
    const [errorLabel, setErrorLabel] = useState('');
    const [documentsData, setDocumentsData] = useState({});

    const { document_details_list: documentDetails } = documentsData;

    const getDocumentDetails = async () => {
        if (!visible) return;

        try {
            const queryParams = {
                payload: {
                    disputeId: data.id,
                    ticketId,
                },
            };

            const queryString = getQueryStringFromObject(queryParams);

            setLoading(true);
            setErrorLabel('');

            const response = await clientApiWrapper.get(`${DISPUTE_DOCUMENT_ENDPOINT}?${queryString}`);

            if (response === 'auth-expired') {
                throw new Error('Auth expired');
            }

            setDocumentsData(response);
        } catch (error) {
            showErrorToastMsg(error);
            const errorMsg = getErrMsg(error);
            setErrorLabel(errorMsg);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDocumentDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const closeModal = () => {
        setData({});
        toggleHandler(false);
        setErrorLabel('');
        setDocumentsData({});
    };

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='Dispute Details'
        >
            <Table
                v2
                objKey='header_key'
                nsrLabel='No document details found!'
                labelData={documentDetails?.table_headers}
                contentData={documentDetails?.table_rows}
                header={documentDetails?.table_name}
                extClasses={{
                    container: 'mb-60',
                }}
            />
            <Button
                v2
                secondary
                extClasses='disputes-modal-cta'
                label='Close'
                onClick={closeModal}
            />

            <LoaderOrError
                loading={loading}
                errorLabel={errorLabel}
            />
        </Modal>
    );
};

export default memo(ViewDocuments);

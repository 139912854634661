/*
 *
 * PayoutRequests reducer
 *
 */

import { fromJS } from 'immutable';
import {
    GET_RAISED_PAYOUT_REQUESTS, SET_RAISED_PAYOUT_REQUESTS, ERR_RAISED_PAYOUT_REQUESTS,
    GET_ALL_PAYOUT_REQUESTS, SET_ALL_PAYOUT_REQUESTS, ERR_ALL_PAYOUT_REQUESTS,
    UPDATE_PAYOUT_REQUEST, SET_UPDATE_PAYOUT_REQUEST,
} from './constants';

const initialState = fromJS({
    loading: true,
    raisedPayoutRequests: [],
    pendingPayoutRequests: [],
    allPayoutRequests: [],
    prev: {
        raisedPayoutRequests: {},
        pendingPayoutRequests: {},
        allPayoutRequests: {},
    },
    next: {
        raisedPayoutRequests: {},
        pendingPayoutRequests: {},
        allPayoutRequests: {},
    },
});

function payoutRequestsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_RAISED_PAYOUT_REQUESTS:
        case GET_ALL_PAYOUT_REQUESTS:
        case UPDATE_PAYOUT_REQUEST:
            return state.set('loading', true);

        case SET_RAISED_PAYOUT_REQUESTS: {
            const { payoutList, prev, next } = action.data;

            return state
                .set('loading', false)
                .set('raisedPayoutRequests', fromJS(payoutList))
                .setIn(['prev', 'raisedPayoutRequests'], fromJS(prev))
                .setIn(['next', 'raisedPayoutRequests'], fromJS(next));
        }

        case ERR_RAISED_PAYOUT_REQUESTS: {
            return state
                .set('loading', false)
                .set('raisedPayoutRequests', fromJS([]))
                .setIn(['prev', 'raisedPayoutRequests'], fromJS({}))
                .setIn(['next', 'raisedPayoutRequests'], fromJS({}));
        }

        case SET_ALL_PAYOUT_REQUESTS: {
            const { payoutList, state: approvalState, prev, next } = action.data;

            if (approvalState === 'PENDING') {
                return state
                    .set('loading', false)
                    .set('pendingPayoutRequests', fromJS(payoutList))
                    .setIn(['prev', 'pendingPayoutRequests'], fromJS(prev))
                    .setIn(['next', 'pendingPayoutRequests'], fromJS(next));
            }

            return state
                .set('loading', false)
                .set('allPayoutRequests', fromJS(payoutList))
                .setIn(['prev', 'allPayoutRequests'], fromJS(prev))
                .setIn(['next', 'allPayoutRequests'], fromJS(next));
        }

        case ERR_ALL_PAYOUT_REQUESTS: {
            const { state: approvalState } = action.data;

            if (approvalState === 'PENDING') {
                return state
                    .set('loading', false)
                    .set('pendingPayoutRequests', fromJS([]))
                    .setIn(['prev', 'pendingPayoutRequests'], fromJS({}))
                    .setIn(['next', 'pendingPayoutRequests'], fromJS({}));
            }

            return state
                .set('loading', false)
                .set('allPayoutRequests', fromJS([]))
                .setIn(['prev', 'allPayoutRequests'], fromJS({}))
                .setIn(['next', 'allPayoutRequests'], fromJS({}));
        }

        case SET_UPDATE_PAYOUT_REQUEST:
            return state.set('loading', false);

        default:
            return state;
    }
}

export default payoutRequestsReducer;

/**
 * @file Manages the reducer state & functions for Scripts component
 */

import { fromJS } from 'immutable';
import {
    ERR_AGENT_CALLS, GET_AGENT_CALLS, SET_AGENT_CALLS,
} from './constants';

const initialState = fromJS({
    loading: false,
    err: '',
    data: null,
});

function homeReducer(state = initialState, action = {}) {
    switch (action.type) {
        // Getter changes
        case GET_AGENT_CALLS: {
            const { keyArray, key: propertyKey, clearData } = action.data;
            let key;
            if (keyArray) {
                key = [keyArray];
                if (Array.isArray(keyArray)) {
                    key = keyArray;
                }
                const values = {
                    loading: true,
                    err: '',
                };
                if (clearData) values.data = null;
                return state.mergeIn(key, values);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '')
                .setIn([propertyKey], null);
        }
        // Setter merge changes
        case SET_AGENT_CALLS: {
            const { keyArray, key: propertyKey, value } = action.data;
            let key;
            if (keyArray) {
                let values = {
                    loading: false,
                    err: '',
                };
                if (value) {
                    values = {
                        ...values,
                        ...value,
                    };
                }
                key = [keyArray];
                if (Array.isArray(keyArray)) {
                    key = keyArray;
                }
                return state.mergeIn(key, values);
            }
            return state
                .setIn([propertyKey], value)
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }
        // Err Changes
        case ERR_AGENT_CALLS: {
            const { keyArray, key: propertyKey, err } = action.data;
            let key;
            if (keyArray) {
                key = [keyArray];
                if (Array.isArray(keyArray)) {
                    key = keyArray;
                }
                const values = {
                    loading: false,
                    err,
                };
                return state.mergeIn(key, values);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['err'], err)
                .setIn([propertyKey], null);
        }

        default:
            return state;
    }
}

export default homeReducer;

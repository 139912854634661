/*
 *
 * WaitlistDetails reducer
 *
 */

import { fromJS } from 'immutable';

import {
    GET_WAITLIST_USER_INFO, SET_WAITLIST_USER_INFO, ERR_WAITLIST_USER_INFO, RESET_WAITLIST_USER_INFO,
} from './constants';

const initialState = fromJS({
    loading: false,
    waitlistUserInfo: {},
    hasWaitlistUserInfo: false,
});

function waitlistDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_WAITLIST_USER_INFO:
            return state
                .set('loading', true)
                .set('waitlistUserInfo', fromJS({}))
                .set('hasWaitlistUserInfo', false);

        case SET_WAITLIST_USER_INFO: {
            const { waitlistUserInfo } = action.data;

            return state
                .set('loading', false)
                .set('waitlistUserInfo', fromJS(waitlistUserInfo))
                .set('hasWaitlistUserInfo', true);
        }

        case ERR_WAITLIST_USER_INFO: {
            return state.set('loading', false);
        }

        case RESET_WAITLIST_USER_INFO: {
            return initialState;
        }

        default:
            return state;
    }
}

export default waitlistDetailsReducer;

/**
 * @file Manages the actions for Home component
 */
import { ERR_AGENT_CALLS, GET_AGENT_CALLS, SET_AGENT_CALLS } from './constants';

export const getCallsAction = (data) => ({
    type: GET_AGENT_CALLS,
    data,
});

export const setCallsAction = (data) => ({
    type: SET_AGENT_CALLS,
    data,
});

export const errCallsAction = (data) => ({
    type: ERR_AGENT_CALLS,
    data,
});

/* eslint-disable react-hooks/exhaustive-deps */

/*
 * @file Transaction View
 * Page to view the details of the Transaction View
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import {
    Table, Loader, Button, ParameterList, AppDropdown,
} from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import {
    TRANSACTION_VIEW_KEY, PARAMETER_FILTERS_CONFIG,
    PARAMETER_DEFAULT_VALUE_CONFIG, SORTING_OPTIONS,
    SORT_DD_OG_STATE,
} from './constants';
import {
    selectTransactionViewDetails, selectTransactionViewError,
    selectTransactionViewLoader, selectTransactionViewParameterList,
} from './selectors';
import { getTransactionViewAction, getTransactionViewFilterAction } from './actions';
import reducer from './reducer';
import saga from './saga';

import './styles.scss';

const TransactionView = (props) => {
    const { caseId } = props;

    const dispatch = useDispatch();

    const tableData = useSelector(selectTransactionViewDetails, shallowEqual);
    const loading = useSelector(selectTransactionViewLoader, shallowEqual);
    const parameterList = useSelector(selectTransactionViewParameterList, shallowEqual);
    const transactionViewError = useSelector(selectTransactionViewError, shallowEqual);

    const [filterList, setFiltersList] = useState(PARAMETER_DEFAULT_VALUE_CONFIG || []);
    const [sortOption, setSortOption] = useState(SORT_DD_OG_STATE);

    const makePayloadAndCallApi = (params = {}) => {
        const {
            prev, next, filters, sortBy = sortOption?.value,
        } = params;

        const payload = {
            prevToken: prev,
            nextToken: next,
            filters,
            caseId,
            sortBy,
        };
        dispatch(getTransactionViewAction(payload));
    };

    const getPaginatedTransactionQueue = ({ prev, next }) => {
        makePayloadAndCallApi({ prev, next, filters: filterList });
    };

    const onFilterSubmit = (curFilterList) => {
        setFiltersList(curFilterList);
        makePayloadAndCallApi({
            filters: curFilterList,
        });
    };

    const onSortBySubmit = (value) => {
        makePayloadAndCallApi({
            filters: filterList,
            sortBy: value,
        });
    };

    const handleRefreshClick = () => {
        makePayloadAndCallApi({
            filters: filterList,
        });
    };

    useEffect(() => {
        makePayloadAndCallApi({
            filters: filterList,
        });
        if (caseId) {
            dispatch(getTransactionViewFilterAction({ caseId }));
        }
    }, [caseId]);

    return (
        <React.Fragment>
            <div className='tv-details app-cc-v1'>
                <ParameterList
                    config={PARAMETER_FILTERS_CONFIG}
                    data={{
                        parameterList,
                    }}
                    onSubmit={onFilterSubmit}
                />

                <div className='frcsbWrapper mt-30'>
                    <div className='heading1'>File Entries</div>
                    <div className='frcfeWrapper'>
                        <div className='frcfeWrapper mr-30'>
                            <div className='heading3'>Sort By</div>
                            <AppDropdown
                                defaultDDLabel='Select Case Sorting'
                                ddWrapperCls={{
                                    container: 'custom-dd tv-details-idd',
                                }}
                                ddContentCls={{
                                    container: ['tv-details-idd__cc'],
                                }}
                                ddState={sortOption}
                                setDDState={setSortOption}
                                onValueChange={onSortBySubmit}
                                ddList={SORTING_OPTIONS}
                                ddItemCls='custom-dd__cl'
                            />
                        </div>
                        <Button v2 secondary label='Refresh' onClick={handleRefreshClick} />
                    </div>
                </div>

                <Table
                    v2
                    enableScrollView
                    labelData={tableData.columnData}
                    contentData={tableData.rowData}
                    prevToken={tableData.prevToken}
                    nextToken={tableData.nextToken}
                    paginatedClickHandler={getPaginatedTransactionQueue}
                    errLabel={transactionViewError}
                />
            </div>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: TRANSACTION_VIEW_KEY, reducer });
const withSaga = injectSaga({ key: TRANSACTION_VIEW_KEY, saga });

export default compose(withReducer, withSaga)(TransactionView);

/* eslint-disable import/no-cycle */
/**
 *
 * Chequebook Requests
 *
 */

import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import GeneralKVPairInfo from '../../../components/GeneralKVPairInfo';
import { Loader } from '../../../components';

import { getUserChequebookDetails } from '../actions';
import { makeSelectChequebookRequests } from '../selectors';
import { getUserInfoList } from '../utils';

import './styles.scss';

const ChequebookRequestDetails = (props) => {
    const { ticketId } = props;

    const dispatch = useDispatch();

    const chequebookRequestsData = useSelector(makeSelectChequebookRequests, shallowEqual);

    let loading;
    let data;
    let hasData;

    if (chequebookRequestsData[ticketId]) {
        ({ loading, data, hasData } = chequebookRequestsData[ticketId]);
    }

    const { requestList, signStatus } = data || {};

    useEffect(() => {
        if (!hasData) {
            dispatch(getUserChequebookDetails({ ticketId }));
        }
    }, [dispatch, ticketId]);

    return (
        <div className='fcWrapper m-auto'>
            <div className='chequebook-req-label-value-cont'>
                <div className='chequebook-req-label'>Sign Status</div>
                <div className='chequebook-req-value'>{signStatus}</div>
            </div>
            {
                (hasData && requestList && requestList.length > 0)
                    ? requestList.map((userData, index) => (
                        <div className='chequebook-req-container'>
                            <div className='chequebook-req-value mb-30'>Chequebook Request {index + 1}</div>
                            <GeneralKVPairInfo data={getUserInfoList(userData)} />
                        </div>
                    ))
                    : <div className='heading3'>No Chequebook Requests found!</div>
            }
            <Loader visible={loading} />
        </div>
    );
};

export default memo(ChequebookRequestDetails);

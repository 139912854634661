/*
 *
 * Tickets constants
 *
 */

export const SET_AGENT_FEEDBACK = 'app/Profile/SET_AGENT_FEEDBACK';
export const ERR_AGENT_FEEDBACK = 'app/Profile/ERR_AGENT_FEEDBACK';

// call list
export const GET_CALL_LIST = 'app/Tickets/GET_CALL_LIST';
export const SET_CALL_LIST = 'app/Tickets/SET_CALL_LIST';
export const ERR_CALL_LIST = 'app/Tickets/ERR_CALL_LIST';

// set selected call info
export const SET_SELECTED_CALL_INFO = 'app/Tickets/SET_SELECTED_CALL_INFO';

// ticket and user info for call
export const GET_TICKET_AND_USER_INFO_FOR_CALL = 'app/Tickets/GET_TICKET_AND_USER_INFO_FOR_CALL';
export const SET_TICKET_AND_USER_INFO_FOR_CALL = 'app/Tickets/SET_TICKET_AND_USER_INFO_FOR_CALL';
export const ERR_TICKET_AND_USER_INFO_FOR_CALL = 'app/Tickets/ERR_TICKET_AND_USER_INFO_FOR_CALL';

// user info for call
export const GET_USER_INFO_FOR_CALL = 'app/Tickets/GET_USER_INFO_FOR_CALL';
export const SET_USER_INFO_FOR_CALL = 'app/Tickets/SET_USER_INFO_FOR_CALL';
export const ERR_USER_INFO_FOR_CALL = 'app/Tickets/ERR_USER_INFO_FOR_CALL';

// user confirmation for call
export const USER_CONFIRMATION_FOR_CALL = 'app/Tickets/USER_CONFIRMATION_FOR_CALL';
export const SET_USER_CONFIRMATION_FOR_CALL = 'app/Tickets/SET_USER_CONFIRMATION_FOR_CALL';
export const ERR_USER_CONFIRMATION_FOR_CALL = 'app/Tickets/ERR_USER_CONFIRMATION_FOR_CALL';

// ticket list for call
export const GET_TICKET_LIST_FOR_CALL = 'app/Tickets/GET_TICKET_LIST_FOR_CALL';
export const SET_TICKET_LIST_FOR_CALL = 'app/Tickets/SET_TICKET_LIST_FOR_CALL';
export const ERR_TICKET_LIST_FOR_CALL = 'app/Tickets/ERR_TICKET_LIST_FOR_CALL';

// call info
export const UPDATE_CALL_INFO = 'app/Tickets/UPDATE_CALL_INFO';
export const SET_UPDATED_CALL_INFO = 'app/Tickets/SET_UPDATED_CALL_INFO';
export const ERR_UPDATED_CALL_INFO = 'app/Tickets/ERR_UPDATED_CALL_INFO';

// create ticket for call
export const CREATE_TICKET_FOR_CALL = 'app/Tickets/CREATE_TICKET_FOR_CALL';
export const SET_CREATED_TICKET_FOR_CALL = 'app/Tickets/SET_CREATED_TICKET_FOR_CALL';
export const ERR_CREATED_TICKET_FOR_CALL = 'app/Tickets/ERR_CREATED_TICKET_FOR_CALL';

export const SET_TICKET_ID = 'app/Tickets/SET_TICKET_ID';
export const RESET_TICKET_ID = 'app/Tickets/RESET_TICKET_ID';
export const SUBMIT_TICKET_ID = 'app/Tickets/SUBMIT_TICKET_ID';
export const ADD_NEW_TICKET = 'app/Tickets/ADD_NEW_TICKET';
export const CLOSE_TICKET = 'app/Tickets/CLOSE_TICKET';
export const SET_ACTIVE_TICKET = 'app/Tickets/SET_ACTIVE_TICKET';
export const SET_TICKET_INFO = 'app/Tickets/SET_TICKET_INFO';
export const ERR_TICKET_INFO = 'app/Tickets/ERR_TICKET_INFO';
export const SET_USER_INFO = 'app/Tickets/SET_USER_INFO';
export const SET_REGISTERED_ID_FLOW = 'app/Tickets/SET_REGISTERED_ID_FLOW';
export const SET_USER_ID = 'app/Tickets/SET_USER_ID';
export const SUBMIT_USER_ID = 'app/Tickets/SUBMIT_USER_ID';
export const SET_USER_NOT_REGISTERED = 'app/Tickets/SET_USER_NOT_REGISTERED';

export const GET_INITIAL_AUTH_FACTOR = 'app/Tickets/GET_INITIAL_AUTH_FACTOR';
export const SET_INITIAL_AUTH_INFO = 'app/Tickets/SET_INITIAL_AUTH_INFO';
export const ERR_INITIAL_AUTH_INFO = 'app/Tickets/ERR_INITIAL_AUTH_INFO';
export const SET_AUTH_FACTOR_VALUE = 'app/Tickets/SET_AUTH_FACTOR_VALUE';
export const VERIFY_AUTH_FACTOR = 'app/Tickets/VERIFY_AUTH_FACTOR';
export const SET_AUTH_INFO_AFTER_VERIFICATION = 'app/Tickets/SET_AUTH_INFO_AFTER_VERIFICATION';
export const SET_ASYNC_AUTH_INFO = 'app/Tickets/SET_ASYNC_AUTH_INFO';
export const SET_AUTH_INFO_LOADER = 'app/Tickets/SET_AUTH_INFO_LOADER';
export const ERR_AUTH_INFO = 'app/Tickets/ERR_AUTH_INFO';
export const SET_AUTH_EXPIRED_SCREEN = 'app/Tickets/SET_AUTH_EXPIRED_SCREEN';
export const SET_ACTION_AUTH_INFO = 'app/Tickets/SET_ACTION_AUTH_INFO';
export const DISCARD_ACTION_AUTH_INFO = 'app/Tickets/DISCARD_ACTION_AUTH_INFO';

// call back user
export const CALLBACK_USER = 'app/Tickets/CALLBACK_USER';
export const SET_CALLBACK_USER = 'app/Tickets/SET_CALLBACK_USER';
export const ERR_CALLBACK_USER = 'app/Tickets/ERR_CALLBACK_USER';
export const SET_CALLBACK_USER_FLOW = 'app/Tickets/SET_CALLBACK_USER_FLOW';

// waitlist user
export const GET_WAITLIST_USER = 'app/Tickets/GET_WAITLIST_USER';
export const SET_WAITLIST_USER = 'app/Tickets/SET_WAITLIST_USER';
export const ERR_WAITLIST_USER = 'app/Tickets/ERR_WAITLIST_USER';

// attach entity to ticket
export const ATTACH_ENTITY_TICKET = 'app/Tickets/ATTACH_ENTITY_TICKET';
export const SET_ATTACH_ENTITY_TICKET = 'app/Tickets/SET_ATTACH_ENTITY_TICKET';
export const ERR_ATTACH_ENTITY_TICKET = 'app/Tickets/ERR_ATTACH_ENTITY_TICKET';

// otp attempts
export const GET_OTP_ATTEMPTS = 'app/Tickets/GET_OTP_ATTEMPTS';
export const SET_OTP_ATTEMPTS = 'app/Tickets/SET_OTP_ATTEMPTS';
export const ERR_OTP_ATTEMPTS = 'app/Tickets/ERR_OTP_ATTEMPTS';

// account freeze info
export const GET_ACCOUNT_FREEZE_INFO = 'app/Tickets/GET_ACCOUNT_FREEZE_INFO';
export const SET_ACCOUNT_FREEZE_INFO = 'app/Tickets/SET_ACCOUNT_FREEZE_INFO';
export const ERR_ACCOUNT_FREEZE_INFO = 'app/Tickets/ERR_ACCOUNT_FREEZE_INFO';

// category 4 auth upgrade
export const SET_AUTH_UPGRADE_INFO = 'app/Tickets/SET_AUTH_UPGRADE_INFO';
export const SET_CUSTOMER_AUTH = 'app/Tickets/SET_CUSTOMER_AUTH';

/**
 * AUTH_FACTOR_STATUS_UNSPECIFIED = 0;
 * FACTOR_UNAVAILABLE_FOR_USER = 1;
 * FACTOR_UNAVAILABLE_IN_SYSTEM = 2;
 * DELIVERY_PENDING = 3;
 * DELIVERY_FAILED = 4;
 * VERIFICATION_PENDING = 5;
 * VERIFICATION_SUCCESS = 6;
 * VERIFICATION_FAILED_INTERNAL = 7;
 * VERIFICATION_FAILED_INVALID_INFO = 8;
 * VERIFICATION_FAILED_REJECTED = 9;
 * MAX_TRIES_EXHAUSTED = 10;
 */
export const REVIEWED_AUTH_FACTOR = [1, 2, 6, 10];
export const ACTIVE_AUTH_FACTOR = [0, 3, 4, 5, 7, 8, 9];
export const INACTIVE_AUTH_FACTOR = [0];
export const ASYNC_AUTH_FACTOR_PENDING = [5];

export const AUTH_FACTOR_FORM_FIELDS = {
    MOBILE_PROMPT: {
        type: 'text',
        async: true,
        labelId: 'auth-factor-mobile-prompt',
        label: 'Mobile Prompt Verification',
        description: '',
    },
    EMAIL_VERIFICATION: {
        type: 'text',
        async: true,
        labelId: 'auth-factor-email',
        label: 'Email Verification',
    },
    TRANSACTION_AMOUNT: {
        type: 'txn',
        async: false,
        labelId: 'auth-factor-txn',
        label: 'Any one of last 3 transactions amount',
    },
    LAST_FIVE_PAN_CHARACTERS: {
        type: 'text',
        async: false,
        labelId: 'auth-factor-pan',
        label: 'Last 5 chars of PAN',
    },
    MOTHERS_NAME: {
        type: 'name',
        async: false,
        labelId: 'auth-factor-mother-name',
        label: 'Mother\'s Name',
    },
    FATHERS_NAME: {
        type: 'name',
        async: false,
        labelId: 'auth-factor-father-name',
        label: 'Father\'s Name',
    },
    DOB: {
        type: 'dob',
        async: false,
        labelId: 'auth-factor-dob',
        label: 'Date of Birth',
    },
    PERMANENT_ADDRESS_PIN_CODE: {
        type: 'text',
        async: false,
        labelId: 'auth-factor-pincode',
        label: 'Pincode of Permanent Address',
    },
};

export const SCREEN = {
    SCREEN_UNSPECIFIED: 0,
    AUTH_SCREEN: 1,
    LANDING_PAGE: 2,
    HIGHLY_SENSITIVE_SCREEN: 3,
    MODERATELY_SENSITIVE_SCREEN: 4,
    RESTRICTED_REVERSIBLE_SCREEN: 5,
    AUTH_EXPIRED_SCREEN: 6,
    AUTH_ERROR_SCREEN: 7,
    CONFIRM_ACTION_SCREEN: 8,
    LESS_SENSITIVE_SCREEN: 9,
    PAST_TICKET_DETAILS_FOR_USER_SCREEN: 10,
    INSENSITIVE_SCREEN: 11,
};

/**
 * SCREEN_UNSPECIFIED = 0;
 * AUTH_SCREEN = 1;
 * LANDING_PAGE = 2;
 * HIGHLY_SENSITIVE_SCREEN = 3;
 * MODERATELY_SENSITIVE_SCREEN = 4;
 * RESTRICTED_REVERSIBLE_SCREEN = 5;
 * AUTH_EXPIRED_SCREEN = 6;
 * AUTH_ERROR_SCREEN = 7;
 * CONFIRM_ACTION_SCREEN = 8;
 * LESS_SENSITIVE_SCREEN = 9;
 * PAST_TICKET_DETAILS_FOR_USER_SCREEN = 10;
 * INSENSITIVE_SCREEN = 11;
 */
export const SENSITIVE_SCREENS = [3];
export const MODERATELY_SENSITIVE_SCREENS = [4];
export const LESS_SENSITIVE_SCREENS = [5, 9];
export const ACTIONABLE_SCREENS = [3, 4, 5, 9];
export const INSENSITIVE_SCREENS = [2, 11];

export const ACCOUNT_TYPE = [
    {
        label: 'Fixed Deposit',
        value: 'FIXED_DEPOSIT',
    },
    {
        label: 'Recurring Deposit',
        value: 'RECURRING_DEPOSIT',
    },
    {
        label: 'Smart Deposit',
        value: 'SMART_DEPOSIT',
    },
];

export const SENSITIVE_ACTIONS = {
    FREEZEACCOUNT: 'freezeAccount',
    BLOCKDEBIT: 'blockDebit',
    SUSPENDDEBIT: 'suspendDebit',
    TOGGLEUPI: 'toggleUPI',
    CARDLIMITS: 'cardLimits',
    CARDSETTINGS: 'cardSettings',
    BLOCKCREDIT: 'blockCredit',
    SUSPENDCREDIT: 'suspendCredit',
};

// key to be used for reducers & selectors for tickets container
export const TICKETS_KEY = 'tickets';

export const CALL_LIST_LABEL_DATA = [
    {
        id: 1,
        label: 'Caller Phone Number',
        key: 'maskedCallerPhoneNumber',
    },
    {
        id: 2,
        label: 'Campaign Did',
        key: 'campaignDid',
    },
    {
        id: 3,
        label: 'Call Type',
        key: 'callType',
    },
    {
        id: 4,
        label: 'Call Handle Status',
        key: 'callHandleStatus',
    },
    {
        id: 5,
        label: 'Call Stage',
        key: 'callStage',
    },
    {
        id: 6,
        label: 'Started At',
        key: 'callStartedAt',
    },
    {
        id: 7,
        label: 'Ticket ID',
        key: 'ticketId',
    },
];

export const CALL_TICKET_LIST_LABEL_DATA = [
    {
        label: 'Ticket ID',
        key: 'ticketId',
    },
    {
        label: 'Subject',
        key: 'subject',
    },
    {
        label: 'Type',
        key: 'type',
    },
    {
        label: 'Category',
        key: 'category',
    },
    {
        label: 'Sub-Category',
        key: 'subCategory',
    },
    {
        label: 'Status',
        key: 'status',
    },
    {
        label: 'Reported At',
        key: 'reportedAt',
    },
    {
        label: 'Severity',
        key: 'severity',
    },
];

export const FEEDBACK_CATEGORIES = [
    {
        label: 'FEATURE REQUEST',
        value: 'FEEDBACK_CATEGORY_FEATURE_REQUEST',
    },
    {
        label: 'REPORT PRODUCT ISSUE',
        value: 'FEEDBACK_CATEGORY_REPORT_PRODUCT_ISSUE',
    },
    {
        label: 'REPORT SHERLOCK ISSUE',
        value: 'FEEDBACK_CATEGORY_REPORT_SHERLOCK_ISSUE',
    },
    {
        label: 'USER OPINION',
        value: 'FEEDBACK_CATEGORY_USER_OPINION',
    },
    {
        label: 'USER RESEARCH',
        value: 'FEEDBACK_CATEGORY_USER_RESEARCH',
    },
];

export const FILTER_TYPES = [
    {
        id: 'Frequent',
        label: 'Frequent',
        value: 'FEEDBACK_FREQUENCY_FREQUENT',
    },
    {
        id: 'One-time',
        label: 'One-time',
        value: 'FEEDBACK_FREQUENCY_ONE_TIME',
    },
    {
        id: 'Rare',
        label: 'Rare',
        value: 'FEEDBACK_FREQUENCY_RARE',
    },
];

export const URGENCY_TYPES = [
    {
        id: 'Urgent',
        label: 'Urgent',
        value: 'TRUE',
    },
    {
        id: 'Not Urgent',
        label: 'Not Urgent',
        value: 'FALSE',
    },
];

export const HIGHLIGHTED_TYPES = [
    {
        id: 'Yes',
        label: 'Yes',
        value: 'TRUE',
    },
    {
        id: 'No',
        label: 'No',
        value: 'FALSE',
    },
];

export const LoansOptions = {
    PreApprovedLoans: 'PreApprovedLoans',
    LoanAgainstMF: 'LoanAgainstMF',
};

// Commented for future usage of anonymous feedback
// export const MARK_ANONYMOUS = [
//     {
//         id: 'Yes',
//         label: 'Yes',
//         value: 'TRUE',
//     },
//     {
//         id: 'No',
//         label: 'No',
//         value: 'FALSE',
//     },
// ];

// scripts search results
export const GET_SHERLOCK_SCRIPTS_SEARCH_RESULTS = 'app/Tickets/GET_SHERLOCK_SCRIPTS_SEARCH_RESULTS';
export const SET_SHERLOCK_SCRIPTS_SEARCH_RESULTS = 'app/Tickets/SET_SHERLOCK_SCRIPTS_SEARCH_RESULTS';
export const ERR_SHERLOCK_SCRIPTS_SEARCH_RESULTS = 'app/Tickets/ERR_SHERLOCK_SCRIPTS_SEARCH_RESULTS';

export const SHERLOCK_SCRIPTS_KEY = 'sherlockScripts';

export const SHERLOCK_V1_ENTRY_POINT = 'SHERLOCK_V1_ENTRY_POINT';

import { createSelector } from 'reselect';

const selectLivenessVideo = state => state.get('livenessVideo');

const makeSelectLoading = () => createSelector(
    selectLivenessVideo,
    substate => substate.get('loading')
);

const makeSelectErr = () => createSelector(
    selectLivenessVideo,
    substate => substate.get('err')
);

const makeSelectVideoUrl = () => createSelector(
    selectLivenessVideo,
    substate => substate.get('videoUrl')
);

const makeSelectMonorailInfo = () => createSelector(
    selectLivenessVideo,
    substate => substate.get('monorailInfo').toJS()
);

export {
    makeSelectLoading,
    makeSelectErr,
    makeSelectVideoUrl,
    makeSelectMonorailInfo,
};

/**
 * @file Manages the action functions for Issue Config component
 */

import {
    CLEAR_FILE_DOWNLOAD_DATA, GET_FILTER_OPTIONS, GET_ISSUE_CONFIG, GET_ISSUE_CONFIG_HISTORY, UPDATE_ISSUE_CONFIG,
} from './constants';

export const getIssueConfigFilters = () => ({
    type: GET_FILTER_OPTIONS,
});

export const getIssueConfig = (data) => ({
    type: GET_ISSUE_CONFIG,
    data,
});

export const getIssueConfigHistory = (data) => ({
    type: GET_ISSUE_CONFIG_HISTORY,
    data,
});

export const updateIssueConfig = (data) => ({
    type: UPDATE_ISSUE_CONFIG,
    data,
});

export const clearFileDownloadURL = () => ({
    type: CLEAR_FILE_DOWNLOAD_DATA,
    data: null,
});

const getUserInfo = (data) => [
    {
        label: 'Name',
        value: data.name,
    },
    {
        label: 'Registered Mobile Number',
        value: data.phoneNumber,
    },
    {
        label: 'Registered Email ID',
        value: data.email,
    },
    {
        label: 'Actor ID',
        value: data.actorId,
    },
    {
        label: 'Account Number',
        value: data.accountNumber,
    },
];


export {
    getUserInfo,
};

/*
 *
 * Waitlist reducer
 *
 */

import { fromJS } from 'immutable';
import {
    GET_FREELANCER_INFO, SET_FREELANCER_INFO, ERR_FREELANCER_INFO, UPDATE_FL_WL_STATUS,
    ERR_UPDATE_FL_WL_STATUS, GET_PENDING_STATUS_FL_LIST, SET_PENDING_STATUS_FL_LIST,
    ERR_PENDING_STATUS_FL_LIST,
} from './constants';

const initialState = fromJS({
    loading: {
        userInfo: false,
        userList: false,
    },
    err: {
        userList: '',
        userInfo: ''
    },
    userList: [],
    userInfo: {}
});

function waitlistReducer(state = initialState, action) {
    switch (action.type) {
        case GET_FREELANCER_INFO:
        case UPDATE_FL_WL_STATUS:
            return state
                .setIn(['loading', 'userInfo'], true)
                .setIn(['err', 'userInfo'], '');

        case SET_FREELANCER_INFO: {
            const { freelancerInfo } = action.data;

            return state
                .setIn(['loading', 'userInfo'], false)
                .set('userInfo', fromJS(freelancerInfo));
        }

        case ERR_FREELANCER_INFO: {
            const { err } = action.data;

            return state
                .setIn(['loading', 'userInfo'], false)
                .setIn(['err', 'userInfo'], err)
                .set('userInfo', fromJS([]));
        }

        case ERR_UPDATE_FL_WL_STATUS: {
            const { err } = action.data;

            return state
                .setIn(['loading', 'userInfo'], false)
                .setIn(['err', 'userInfo'], err);
        }

        case GET_PENDING_STATUS_FL_LIST:
            return state
                .setIn(['loading', 'userList'], true)
                .setIn(['err', 'userList'], '');

        case SET_PENDING_STATUS_FL_LIST: {
            const { freelancerList } = action.data;

            return state
                .setIn(['loading', 'userList'], false)
                .set('userList', fromJS(freelancerList));
        }

        case ERR_PENDING_STATUS_FL_LIST: {
            const { err } = action.data;

            return state
                .setIn(['loading', 'userList'], false)
                .setIn(['err', 'userList'], err)
                .set('userList', fromJS([]));
        }

        default:
            return state;
    }
}

export default waitlistReducer;

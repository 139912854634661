/**
 * @file Selectors for SalaryWhitelistB2B
 */
import { createSelector } from 'reselect';
import { DEVELOPER_KEY } from './constants';

export const selectTabData = createSelector(
    (state) => state.getIn([DEVELOPER_KEY, 'tabs']),
    (subState) => subState?.toJS() || {},
);

/**
 * @file Manages the saga generator functions for risk rule container
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    clientApiWrapper, toastify, getQueryStringFromObject, getErrorObjectForSaga, showErrorToastMsg,
} from '../../utils';
import {
    CREATE_RULE_ENDPOINT, UPDATE_RULE_ENDPOINT, RULE_QUEUE_ENDPOINT, GET_ALL_TAGS_ENDPOINT,
} from '../../api/routes';

import {
    GET_RULE_QUEUE, SET_RULE_QUEUE, ERR_RULE_QUEUE, CREATE_RULE, UPADATE_RULE,
    ERR_CREATE_RULE, ERR_UPADATE_RULE, GET_TAGS, SET_TAGS,
} from './constants';

const getErrorAction = (actionType, containerKey) => ({
    type: actionType,
    data: { containerKey },
});

function* getRuleQueueSaga(action) {
    const queryString = getQueryStringFromObject(action.data || {});

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RULE_QUEUE_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_RULE_QUEUE, data: response });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_RULE_QUEUE), error));
    }
}

function* createRuleSaga(action) {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${CREATE_RULE_ENDPOINT}`,
            action?.data?.payload,
        );

        toastify(response?.message, 'success');
        yield call(getRuleQueueSaga, { data: { filters: action?.data?.filters } });
        yield call(action?.data?.resolve);
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREATE_RULE), error));
    }
}

function* updateRuleSaga(action) {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${UPDATE_RULE_ENDPOINT}`,
            action?.data?.payload,
        );

        toastify(response?.message, 'success');
        yield call(getRuleQueueSaga, { data: { filters: action?.data?.filters } });
        yield call(action?.data?.resolve);
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_UPADATE_RULE), error));
    }
}

function* fetchTags() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_ALL_TAGS_ENDPOINT}`,
        );
        yield put({ type: SET_TAGS, data: response?.tags });
    } catch (error) {
        yield put({ type: SET_TAGS, data: [] });
        showErrorToastMsg(error);
    }
}

export default function* ruleQueueSaga() {
    yield all([
        yield takeLatest(GET_RULE_QUEUE, getRuleQueueSaga),
        yield takeLatest(CREATE_RULE, createRuleSaga),
        yield takeLatest(UPADATE_RULE, updateRuleSaga),
        yield takeLatest(GET_TAGS, fetchTags),

    ]);
}

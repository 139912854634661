/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { useCSVReader } from 'react-papaparse';

import classNames from 'classnames';
import Button from '../Button';

/* Not used anymore after upgrading to v4 of react-papaparse
const buttonRef = React.createRef();
*/

const CustomCSVReader = (props) => {
    const {
        setCSVData, setFileData, isV2Button, extClasses = {},
    } = props;

    const { CSVReader } = useCSVReader();

    const containerClasses = classNames(extClasses && extClasses.aside);
    const removeButtonClasses = classNames(extClasses && extClasses.remove);

    /* Not used anymore after upgrading to v4 of react-papaparse
    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };
    */

    const handleOnFileLoad = (parseResult, file) => {
        if (setCSVData) {
            setCSVData(parseResult.data);
        }

        if (setFileData) {
            setFileData(file);
        }
    };

    const handleOnError = (file, event) => {
        // TODO: need to add support to handle error cases
        console.warn('Error while parsing CSV: ', file, event);
    };

    const handleOnRemoveFile = () => {
        setCSVData('');

        if (setFileData) {
            setFileData('');
        }
    };

    /* Not used anymore after upgrading to v4 of react-papaparse
    const handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e);
        }
    };
    */

    return (
        <CSVReader
            noDrag
            onUploadAccepted={handleOnFileLoad}
            onUploadRejected={handleOnError}
            // below props are used anymore after upgrading to v4 of react-papaparse
            // ref={buttonRef}
            // onRemoveFile={handleOnRemoveFile}
        >
            {({
                getRootProps,
                acceptedFile,
                ProgressBar,
                getRemoveFileProps,
            }) => (
                <aside
                    className={containerClasses}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <Button
                        v2={isV2Button}
                        type='button'
                        primary
                        {...getRootProps()}
                        label='Upload CSV'
                        style={{
                            borderRadius: 0,
                            cursor: 'pointer',
                            marginLeft: 0,
                            marginRight: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            backgroundColor: '#ffffff',
                        }}
                    >
                        Upload CSV
                    </Button>
                    {
                        acceptedFile ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: 8,
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: 400,
                                    }}
                                >
                                    {acceptedFile.name}
                                </div>
                                <button
                                    className={removeButtonClasses}
                                    style={{
                                        cursor: 'pointer',
                                        borderRadius: 0,
                                        marginLeft: 0,
                                        marginRight: 0,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                    }}
                                    {...getRemoveFileProps()}
                                    onClick={(event) => {
                                        getRemoveFileProps().onClick(event);
                                        handleOnRemoveFile();
                                    }}
                                    type='button'
                                >
                                    Remove
                                </button>
                            </div>
                        ) : null
                    }
                    <ProgressBar
                        style={{
                            backgroundColor: '#00b899',
                        }}
                    />
                </aside>
            )}
        </CSVReader>
    );
};

export default CustomCSVReader;

import {
    GET_MUTUAL_FUND_AMC, GET_MUTUAL_FUND_TXN_TYPE, GET_MUTUAL_FUNDS, GET_MUTUAL_FUND_ORDERS,
    GET_MUTUAL_FUND_ORDER_INFO, SET_SEARCH_PARAMS, RESET_SEARCH_PARAMS, GET_MUTUAL_FUND_ORDER_FILTER_STATUS,
} from './constants';

export const getMFAmcList = (data) => ({
    type: GET_MUTUAL_FUND_AMC,
    data,
});

export const getMFTxnTypeList = (data) => ({
    type: GET_MUTUAL_FUND_TXN_TYPE,
    data,
});

export const getMFOrderFilterStatusListAction = (data) => ({
    type: GET_MUTUAL_FUND_ORDER_FILTER_STATUS,
    data,
});

export const getMutualFunds = (data) => ({
    type: GET_MUTUAL_FUNDS,
    data,
});

export const getMutualFundOrders = (data) => ({
    type: GET_MUTUAL_FUND_ORDERS,
    data,
});

export const getMutualFundOrderInfo = (data) => ({
    type: GET_MUTUAL_FUND_ORDER_INFO,
    data,
});

export const setSearchParams = (data) => ({
    type: SET_SEARCH_PARAMS,
    data,
});

export const resetSearchParams = (data) => ({
    type: RESET_SEARCH_PARAMS,
    data,
});

/**
 * @file Manages the constants for Banners component
 */

// key to be used for reducers & selectors for banners
export const BANNER_KEY = 'banners';

export const SET_BANNER_FORM_FIELDS = `app/${BANNER_KEY}/SET_BANNER_FORM_FIELDS`;
export const RESET_BANNER_FORM_FIELDS = `app/${BANNER_KEY}/RESET_BANNER_FORM_FIELDS`;

export const CREATE_BANNER = `app/${BANNER_KEY}/CREATE_BANNER`;
export const UPDATE_BANNER = `app/${BANNER_KEY}/UPDATE_BANNER`;
export const SET_CREATE_OR_UPDATE_BANNER_LOADER = `app/${BANNER_KEY}/SET_CREATE_OR_UPDATE_BANNER_LOADER`;

export const SET_BANNER_INFO = `app/${BANNER_KEY}/SET_BANNER_INFO`;

export const GET_BANNER_LIST = `app/${BANNER_KEY}/GET_BANNER_LIST`;
export const SET_BANNER_LIST = `app/${BANNER_KEY}/SET_BANNER_LIST`;
export const ERR_BANNER_LIST = `app/${BANNER_KEY}/ERR_BANNER_LIST`;

export const BANNER_LIST_LABEL_DATA = [
    {
        id: 1,
        label: 'Banner Title',
        key: 'title',
    },
    {
        id: 2,
        label: 'Banner Body',
        key: 'body',
        extClasses: 'f2Wrapper',
    },
    {
        id: 3,
        label: 'Start Time',
        key: 'startTime',
    },
    {
        id: 4,
        label: 'End Time',
        key: 'endTime',
    },
    {
        id: 5,
        label: 'Status',
        key: 'status',
    },
    {
        id: 6,
        label: 'Created At',
        key: 'createdAt',
    },
    {
        id: 7,
        label: 'Last Updated At',
        key: 'updatedAt',
    },
    {
        id: 8,
        label: 'Roles',
        key: 'rolesStr',
    },
];

export const BANNER_FORM_FIELDS = {
    TITLE: 'title',
    BODY: 'body',
    START_TIME: 'startTime',
    END_TIME: 'endTime',
    VISIBILITY_STATE: 'visibilityState',
    ROLES: 'roles',
};

export const BANNER_LIST_FORM_FIELDS = {
    STATUS: 'status',
    ROLES: 'roles',
};

export const BANNER_STATUS_LIST = [
    {
        label: 'Active Banners',
        value: true,
    },
    {
        label: 'All Banners',
        value: false,
    },
];

/*
 *
 * AppLogs actions
 *
 */

import {
    GET_LOGS, GET_LOGS_ENUMS, GET_LOG_INFO, SET_LOGS_ENUMS, SET_MONORAIL_ID,
} from './constants';

export const getLogsEnumsAction = () => ({
    type: GET_LOGS_ENUMS,
});

export const setLogsEnumsAction = (data) => ({
    type: SET_LOGS_ENUMS,
    data,
});

export const getLogs = (data) => ({
    type: GET_LOGS,
    data,
});

export const getLogInfo = (data) => ({
    type: GET_LOG_INFO,
    data,
});

export const setMonorailId = (data) => ({
    type: SET_MONORAIL_ID,
    data,
});

/**
 *
 * MutualFundOrdersView - Component to allow search for mutual fund orders placed by the current user
 *
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, DropdownContent, DropdownWrapper, ExpandableCard, FormWithSingleInput, Loader,
} from '../../../components';
import { dateUtils, getDropdownHeight } from '../../../utils';
import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';

import { SEARCH_PARAMS_KEYS } from '../constants';
import {
    setSearchParams, resetSearchParams, getMFTxnTypeList, getMutualFundOrders,
    getMutualFundOrderInfo, getMFOrderFilterStatusListAction,
} from '../actions';
import {
    makeSelectSearchParameters, makeSelectMFTxnTypeList, makeSelectMutualFundOrders,
    makeSelectMutualFundOrderInfo, selectMFOrderFilterStatusList,
} from '../selectors';

import MutualFundOrdersList from './MutualFundOrdersList';

const MutualFundOrdersView = (props) => {
    const dispatch = useDispatch();

    const searchParameters = useSelector(makeSelectSearchParameters(), shallowEqual);
    const mfTxnTypeList = useSelector(makeSelectMFTxnTypeList(), shallowEqual);
    const mfOrderFilterStatusList = useSelector(selectMFOrderFilterStatusList, shallowEqual);
    const mutualFundOrdersList = useSelector(makeSelectMutualFundOrders(), shallowEqual);
    const mutualFundOrderInfo = useSelector(makeSelectMutualFundOrderInfo(), shallowEqual);

    const [isSearchCardExpanded, setSearchCardExpanded] = useState(false);
    const [showTxnTypeDD, setTxnTypeDD] = useState(false);
    const [showOrderFilterStatusDD, setShowOrderFilterStatusDD] = useState(false);
    const [errLabel, setErrLabel] = useState('');

    const { fromDate: fromInitialDate, toDate: toInitialDate } = dateUtils.getInitialDateRange('01-01-2022', true); // 01 Jan 2022
    const { ticketId, userId, index } = props;

    let fromDate;
    let toDate;
    let mfOrderId;
    let mfId;
    let mfName;
    let txnType;
    let orderFilterStatus;
    let isSubmitted;

    // extract the search parameters for passed ticket id
    if (searchParameters[ticketId] && searchParameters[ticketId].mutualFundOrdersView) {
        ({
            fromDate, toDate, mfOrderId, mfId, mfName, txnType, isSubmitted, orderFilterStatus,
        } = searchParameters[ticketId].mutualFundOrdersView);
    }

    useEffect(() => {
        if (!mfTxnTypeList.length) {
            dispatch(getMFTxnTypeList());
        }

        if (!mfOrderFilterStatusList.length) {
            dispatch(getMFOrderFilterStatusListAction());
        }
    }, [mfTxnTypeList.length, mfOrderFilterStatusList.length]);

    /**
     * initialise from and to date with the date utils value
     * this won't get called again & again
     */
    useEffect(() => {
        if (!fromDate && !toDate) {
            dispatch(setSearchParams({
                ticketId,
                mutualFundOrdersView: true,
                fromDate: fromInitialDate,
                toDate: toInitialDate, 
            }));
        }
    }, [fromDate, toDate]);

    let loadingMutualFundOrders;
    let mutualFundOrdersData;
    let mutualFundName;
    let mutualFundOrdersDataPrev;
    let mutualFundOrdersDataNext;
    let loadingMutualFundOrderInfo;
    let mutualFundOrderInfoData;
    let hasMutualFundOrderInfoData;

    if (mutualFundOrdersList[ticketId]) {
        ({
            loading: loadingMutualFundOrders, data: mutualFundOrdersData, fundName: mutualFundName,
            prev: mutualFundOrdersDataPrev, next: mutualFundOrdersDataNext,
        } = mutualFundOrdersList[ticketId]);
    }

    if (mutualFundOrderInfo[ticketId]) {
        ({
            loading: loadingMutualFundOrderInfo, data: mutualFundOrderInfoData, hasData: hasMutualFundOrderInfoData,
        } = mutualFundOrderInfo[ticketId]);
    }

    const handleInputChange = (key) => (event) => {
        dispatch(setSearchParams({
            ticketId,
            mutualFundOrdersView: true,
            key,
            value: event.target.value,
        }));
    };

    const handleViewDetails = (orderId) => {
        dispatch(getMutualFundOrderInfo({
            ticketId,
            userId,
            index,
            orderId,
        }));
    };

    const makePayloadAndCallApi = (params = {}) => {
        const { prev, next } = params;

        let payload = {
            ticketId,
            userId,
            index,
            prev,
            next,
        };

        if (mfId) {
            payload = {
                ...payload,
                mutualFundId: btoa(mfId),
                from: fromDate.value,
                to: toDate.value,
                txnType,
                orderFilterStatus,
            };
        } else if (mfOrderId) {
            payload = {
                ...payload,
                mutualFundOrderId: btoa(mfOrderId),
            };
        }

        dispatch(getMutualFundOrders(payload));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // search by mutual fund id - need mutual fund id, from data & to date
        if (mfId && (!fromDate.value || !toDate.value)) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        // search by mutual fund order id only when mutual fund is not there in search parameters
        if (!mfId && !mfOrderId) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        makePayloadAndCallApi();
        setSearchCardExpanded(false);
    };

    const getPaginatedList = ({ prev, next }) => {
        makePayloadAndCallApi({ prev, next });
    };

    const handleDateChange = key => value => {
        const data = {
            key,
            value,
            fromDate,
            toDate,
        };
        const { fromDate: modifiedFromDate, toDate: modifiedToDate } = dateUtils.getModifiedDate(data);;

        dispatch(setSearchParams({
            ticketId,
            mutualFundOrdersView: true,
            fromDate: modifiedFromDate,
            toDate: modifiedToDate, 
        }));
    };

    const renderDateBlock = (key) => {
        let label;
        let labelId;
        let value;
        let maxDate;
        let minDate;

        switch (key) {
            case 'fromDate':
                label = 'From Date*';
                labelId = 'from-date';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = fromDate);
                break;

            case 'toDate':
                label = 'To Date*';
                labelId = 'to-date';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = toDate);
                break;

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'mfs-fc',
                    label: 'mfs-dd__lb',
                    inputField: 'mfs-dd__ip',
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: handleDateChange(key),
                    maxDate,
                    minDate,
                }}
                hideErrLabel
            />
        );
    };

    const onBackArrowCTA = () => {
        dispatch(resetSearchParams({
            ticketId,
            mutualFundOrdersView: true,
        }));
    };

    /**
     * set search parameters for given key, value pair
     * and toggle the selected dropdown incase non-empty value is passed
     */
    const handleOptionsChange = (key, value) => {
        dispatch(setSearchParams({
            ticketId,
            mutualFundOrdersView: true,
            key,
            value,
        }));

        if (value) {
            toggleDD(key)();
        }
    };

    const toggleDD = (id) => () => {
        switch (id) {
            case SEARCH_PARAMS_KEYS.TXN_TYPE: {
                setTxnTypeDD(!showTxnTypeDD);

                break;
            }

            case SEARCH_PARAMS_KEYS.ORDER_FILTER_STATUS: {
                setShowOrderFilterStatusDD(!showOrderFilterStatusDD);

                break;
            }

            default:
        }
    };

    const renderDD = (id) => {
        let label;
        let value;
        let options;
        let showDD;
        let mandatoryField = false;

        switch (id) {
            case SEARCH_PARAMS_KEYS.TXN_TYPE: {
                label = 'Transaction Type';
                value = txnType;
                options = mfTxnTypeList;
                showDD = showTxnTypeDD;
                mandatoryField = false;

                break;
            }

            case SEARCH_PARAMS_KEYS.ORDER_FILTER_STATUS: {
                label = 'Order Filter Status';
                value = orderFilterStatus;
                options = mfOrderFilterStatusList;
                showDD = showOrderFilterStatusDD;
                mandatoryField = false;

                break;
            }

            default:
                label = '';
                options = [];
        }

        const { length: optionsLength } = options;

        if (optionsLength === 0) {
            return null;
        }

        const optionListDDHeight = getDropdownHeight(optionsLength);

        return (
            <div className='frcWrapper'>
                <div className='mfs-dd__lb'>{label}</div>
                <DropdownWrapper
                    visible={showDD}
                    onOutsideClickHandler={toggleDD(id)}
                    extClasses={{
                        container: 'custom-dd mfs-dd',
                    }}
                >
                    <div className='mfs-dd__vl' onClick={toggleDD(id)}>
                        {value ? value.split('_').join(' ').toLowerCase() : 'Choose...'}
                        { // cross icon to clear selected option
                            value && !mandatoryField ? (
                                <div
                                    className='mfs-dd__clear'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOptionsChange(id, '')
                                    }}
                                >
                                    x
                                </div>
                            ) : null
                        }
                    </div>
                    <DropdownContent
                        visible
                        extClasses = {{
                            container: ['custom-dd__cc mfs-dd__cc'],
                        }}
                        extStyles = {{
                            height: showDD ? optionListDDHeight : 0,
                            overflow: showDD ? 'auto' : 'hidden',
                        }}
                    >
                        {
                            options.map(item => (
                                <div
                                    key={item}
                                    className='custom-dd__cl'
                                    onClick={() => handleOptionsChange(id, item)}
                                >
                                    {item}
                                </div>
                            ))
                        }
                    </DropdownContent>
                </DropdownWrapper>
            </div>
        );
    };

    const renderHeaderForMFOrdersList = () => {
        return (
            <React.Fragment>
                <div className='mfs-userinfo-hwr'>
                    <img className='mfs-backarrow' src={backArrowIcon} onClick={onBackArrowCTA} />
                    <div className='mfs-userinfo-hwr__hd'>
                        {`History for ${mfName || mutualFundName}`}
                    </div>
                </div>
                { // show search filters only when mutual fund id & name are present
                    mfId && mfName ? (
                        <div className='mfs-sc mfs-sc--v1'>
                            <div className='frwpWrapper mfs-sc__gap'>
                                {renderDateBlock('fromDate')}
                                {renderDateBlock('toDate')}
                                {renderDD(SEARCH_PARAMS_KEYS.TXN_TYPE)}
                                {renderDD(SEARCH_PARAMS_KEYS.ORDER_FILTER_STATUS)}
                                <Button
                                    extClasses='mfs-sc__cta'
                                    primary
                                    label='Search'
                                    onClick={handleSubmit}
                                />
                            </div>
                            {errLabel ? <div className='err-label mfs-el'>{errLabel}</div> : null}
                        </div>
                    ) : null
                }
            </React.Fragment>
        );
    }

    /*
    show only when
    1. Click on 'View Details' in mutual funds list
    2. Search by Order id
    */
    if (isSubmitted) {
        return (
            <React.Fragment>
                {renderHeaderForMFOrdersList()}
                <MutualFundOrdersList
                    data={mutualFundOrdersData}
                    prev={mutualFundOrdersDataPrev}
                    next={mutualFundOrdersDataNext}
                    getPaginatedData={getPaginatedList}
                    handleViewDetails={handleViewDetails}
                    mutualFundOrderInfoData={mutualFundOrderInfoData}
                    hasMutualFundOrderInfoData={hasMutualFundOrderInfoData}
                />
                <Loader visible={loadingMutualFundOrders || loadingMutualFundOrderInfo} />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className='mfs-cr'>
                <ExpandableCard
                    extClasses='mfs-ec'
                    heading='View user investment details - Search by Order Id'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='mfs-sc'>
                        <div className='frfssbWrapper'>
                            <FormWithSingleInput
                                disableFormSubmitOnEnter
                                extClasses={{
                                    container: 'mfs-fc',
                                    label: 'mfs-fc__lb',
                                    inputField: 'mfs-fc__ip',
                                    errLabel: 'mfs-fc__err',
                                }}
                                label={'Order Id*'}
                                labelId='mf-order-id'
                                formType='text'
                                input={{
                                    value: mfOrderId,
                                    onChange: handleInputChange('mfOrderId'),
                                    minLength: 11,
                                }}
                                errLabel={errLabel}
                            />
                            <Button
                                extClasses='mfs-sc__cta'
                                primary
                                label='Search'
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </ExpandableCard>
                {/* to keep some empty space b/w two search cards */}
                <div className='mfs-src' />
            </div>
            <Loader visible={loadingMutualFundOrders} />
        </React.Fragment>
    );
};

export default MutualFundOrdersView;

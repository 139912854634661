/* eslint-disable
    jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
*/
/* eslint-disable react-hooks/exhaustive-deps */

/**
 *
 * DebitCard
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, DropdownContent, DropdownWrapper, Loader } from '../../../components';

import UserAuth from '../../Tickets/UserAuth';
import { SENSITIVE_ACTIONS } from '../../Tickets/constants';
import backArrow from '../../../assets/images/svgs/back-arrow.svg';

import {
    initiateSuspendCard, initiateBlockCard, setSuspendConfirmScreen, setBlockConfirmScreen,
    getCardInfo, toggleScreen, getCardTrackingInfo, getCardList, updateFreeCardReplacement, getCardRequestStatus, getCardAMC,
} from '../actions';
import { CARD_CHANNEL_LIST } from '../constants';
import { getCardTrackingLabels } from '../utils';

import CardTrackingInfoModal from './CardTrackingInfoModal';
import ForexTransaction from './ForexTransaction';

import './style.scss';
import TableModal from '../TableModal';

const DebitCard = (props) => {
    const dispatch = useDispatch();

    const [selectedCard, setSelectedCard] = useState({});
    const [openedActionsCardId, setOpenedActionsCardId] = useState('');
    const [showCardTrackingModal, setCardTrackingModal] = useState(false);
    const [showCardRequestStatusModal, setCardRequestStatusModal] = useState(false);
    const [showCardAMCModal, setCardAMCModal] = useState(false);

    const {
        index, ticketId, userId, loading, data: cardList, isSuspendConfirmScreen,
        isBlockConfirmScreen, isCardLimitsScreen, isCardSettingsScreen, selectedCardInfo,
        pendingAuth, selectedCardTrackingInfo = {}, activeTicket, selectedCardRequestStatus = {}, selectedCardAmcDetails = {},
    } = props;

    useEffect(() => {
        dispatch(getCardList({ index, ticketId, userId }));
    }, [dispatch]);

    const suspendDebitCard = (isConfirmAction) => {
        dispatch(initiateSuspendCard({
            index,
            ticketId,
            userId,
            cardId: selectedCard.id,
            isConfirmAction,
        }));
    };

    const blockDebitCard = (isConfirmAction) => {
        dispatch(initiateBlockCard({
            index,
            ticketId,
            userId,
            cardId: selectedCard.id,
            isConfirmAction,
        }));
    };

    const discardConfirmScreen = (isSuspend) => () => {
        if (isSuspend) {
            dispatch(setSuspendConfirmScreen({ ticketId, flag: false }));
        } else {
            dispatch(setBlockConfirmScreen({ ticketId, flag: false }));
        }
    };

    const discardScreen = (data) => () => {
        dispatch(toggleScreen({ ticketId, ...data }));
    };

    const getCardLimitAndSettings = (curIsCardLimitsScreen, curIsCardSettingsScreen) => {
        dispatch(getCardInfo({
            index,
            ticketId,
            userId,
            cardId: selectedCard.id,
            issuerBank: selectedCard.issuerBank,
            isCardLimitsScreen: curIsCardLimitsScreen,
            isCardSettingsScreen: curIsCardSettingsScreen,
        }));
    };

    const getCardTracking = (cardId) => () => {
        setOpenedActionsCardId('');

        const callAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(getCardTrackingInfo({
                    ticketId,
                    userId,
                    cardId,
                    resolve,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callAction().then(() => {
            setCardTrackingModal(true);
        });
    };

    const getCardRequestStatusDetails = (cardId) => () => {
        setOpenedActionsCardId('');

        const callAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(getCardRequestStatus({
                    ticketId,
                    userId,
                    cardId,
                    resolve,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callAction().then(() => {
            setCardRequestStatusModal(true);
        });
    };

    const getAMCDetails = (cardId) => () => {
        setOpenedActionsCardId('');

        const callAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(getCardAMC({
                    ticketId,
                    userId,
                    cardId,
                    resolve,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callAction().then(() => {
            setCardAMCModal(true);
        });
    };

    const initiateFreeCardReplacement = () => {
        setOpenedActionsCardId('');

        dispatch(updateFreeCardReplacement({
            ticketId,
            userId,
            index,
        }));
    };

    const getPhysicalCardStatus = (item) => () => {
        setSelectedCard(item);
        getCardTracking(item.id)();
    };

    const renderSearchResults = () => {
        if (!cardList) {
            return null;
        }

        if (cardList.length === 0) {
            return <div className='table-nsr'>No cards found!</div>;
        }

        return (
            <div className='table-sr'>
                <div className='table-sr__thw'>
                    <div className='table-sr__th'>Issuing{'\n'}Bank</div>
                    <div className='table-sr__th'>Card{'\n'}Number</div>
                    <div className='table-sr__th'>Card{'\n'}Type</div>
                    <div className='table-sr__th'>Card{'\n'}Form</div>
                    <div className='table-sr__th'>Digital Card Status</div>
                    <div className='table-sr__th'>Physical Card Status</div>
                    <div className='table-sr__th'>Card{'\n'}Network</div>
                    <div className='table-sr__th'>ATM PIN{'\n'}Set Status</div>
                    <div className='table-sr__th'>Created{'\n'}At</div>
                    <div className='table-sr__th'>Updated{'\n'}At</div>
                    <div className='table-sr__th'>Issuance{'\n'}Fee</div>
                    <div className='table-sr__th table-sr__th--actions' />
                </div>
                <div className='table-sr__tlc'>
                    {
                        cardList.map((item) => (
                            <div key={item.id} className='table-sr__tlw table-sr__tlw--aic'>
                                <div className='table-sr__tl'>{item.issuerBank}</div>
                                <div className='table-sr__tl'>{item.lastXDigits}</div>
                                <div className='table-sr__tl'>{item.type}</div>
                                <div className='table-sr__tl'>{item.cardForm}</div>
                                <div className='table-sr__tl'>{item.status}</div>
                                {
                                    item.cardForm === 'DIGITAL' ? (
                                        <div className='table-sr__tl'>NA</div>
                                    ) : (
                                        <div className='table-sr__tl link' onClick={getPhysicalCardStatus(item)}>Check Status</div>
                                    )
                                }
                                <div className='table-sr__tl'>{item.networkType}</div>
                                <div className='table-sr__tl'>{item.pinStatus}</div>
                                <div className='table-sr__tl'>{item.createdTs}</div>
                                <div className='table-sr__tl'>{item.updatedTs}</div>
                                <div className='table-sr__tl'>{item.issuanceFee}</div>
                                <div className='table-sr__tl table-sr__tl--actions'>
                                    <DropdownWrapper
                                        visible={openedActionsCardId === item.id}
                                        onOutsideClickHandler={() => setOpenedActionsCardId('')}
                                        extClasses={{
                                            container: 'debitcard-ad',
                                        }}
                                    >
                                        <div
                                            className='debitcard-ad-label'
                                            onClick={() => {
                                                setOpenedActionsCardId(item.id);
                                                setSelectedCard(item);
                                            }}
                                        >
                                            ...
                                        </div>
                                        <DropdownContent
                                            visible
                                            extClasses={{
                                                container: [
                                                    'debitcard-ad-cc height-transition',
                                                    openedActionsCardId === item.id && 'debitcard-ad-cc--active',
                                                ],
                                            }}
                                        >
                                            <div className='debitcard-ad-cc-wr'>
                                                <div
                                                    disabled={item.disabledBlockCardAction || item.disabledSuspendCardAction}
                                                    className='debitcard-ad-cc-wr__label'
                                                    onClick={() => {
                                                        if (!(item.disabledBlockCardAction || item.disabledSuspendCardAction)) {
                                                            setOpenedActionsCardId('');
                                                            suspendDebitCard();
                                                        }
                                                    }}
                                                >
                                                    Suspend Card
                                                </div>
                                                <div
                                                    disabled={item.disabledBlockCardAction}
                                                    className='debitcard-ad-cc-wr__label'
                                                    onClick={() => {
                                                        if (!(item.disabledBlockCardAction)) {
                                                            setOpenedActionsCardId('');
                                                            blockDebitCard();
                                                        }
                                                    }}
                                                >
                                                    Block Card
                                                </div>
                                                <div
                                                    className='debitcard-ad-cc-wr__label'
                                                    onClick={() => {
                                                        setOpenedActionsCardId('');
                                                        getCardLimitAndSettings(true, false);
                                                    }}
                                                >
                                                    View Usage Limits
                                                </div>
                                                <div
                                                    className='debitcard-ad-cc-wr__label'
                                                    onClick={() => {
                                                        setOpenedActionsCardId('');
                                                        getCardLimitAndSettings(false, true);
                                                    }}
                                                >
                                                    View Usage Settings
                                                </div>
                                                <div className='debitcard-ad-cc-wr__label' onClick={getCardTracking(item.id)}>
                                                    Tracking Details
                                                </div>
                                                <div
                                                    className='debitcard-ad-cc-wr__label'
                                                    onClick={initiateFreeCardReplacement}
                                                >
                                                    Update Free Card Replacement
                                                </div>
                                                <div
                                                    className='debitcard-ad-cc-wr__label'
                                                    onClick={getAMCDetails(item.id)}
                                                >
                                                    View AMC Details
                                                </div>
                                                <div
                                                    className='debitcard-ad-cc-wr__label'
                                                    onClick={getCardRequestStatusDetails(item.id)}
                                                >
                                                    View Card request stage details
                                                </div>
                                            </div>
                                        </DropdownContent>
                                    </DropdownWrapper>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    };

    if (isSuspendConfirmScreen) {
        const isLoading = loading && loading.suspendDebitCard;

        return (
            <React.Fragment>
                <div className='debitcard-cr debitcard-cr--cs'>
                    <div className='debitcard-cc__heading-wrapper'>
                        <img className='debitcard-backarrow' alt='back-arrow' src={backArrow} onClick={discardConfirmScreen(true)} />
                        <div className='debitcard-cc__heading'>Suspend Debit Card</div>
                    </div>
                    <div className='debitcard-cc__subheading'>
                        <div>{`Are you sure you want to suspend debit card ${selectedCard.lastXDigits}?`}</div>
                        <div className='debitcard-cc__subheading--rv2'>
                            Suspended card cannot be used temporarily. User can un-suspend the card from the Fi app.
                        </div>
                    </div>
                    <div className='debitcard-cc__ctas-wrapper'>
                        <Button
                            primary
                            label='Confirm Suspend'
                            extClasses='debitcard-cc__ctas'
                            onClick={() => suspendDebitCard(true)}
                        />
                        <Button
                            secondary
                            label='Discard'
                            extClasses='debitcard-cc__ctas'
                            onClick={discardConfirmScreen(true)}
                        />
                    </div>
                </div>
                <Loader visible={isLoading} />
            </React.Fragment>
        );
    }

    if (isBlockConfirmScreen) {
        const isLoading = loading && loading.blockDebitCard;

        return (
            <React.Fragment>
                <div className='debitcard-cr debitcard-cr--cs'>
                    <div className='debitcard-cc__heading-wrapper'>
                        <img className='debitcard-backarrow' alt='back-arrow' src={backArrow} onClick={discardConfirmScreen(false)} />
                        <div className='debitcard-cc__heading'>Block Debit Card</div>
                    </div>
                    <div className='debitcard-cc__subheading'>
                        <div>{`Are you sure you want to block debit card ${selectedCard.lastXDigits}?`}</div>
                        <div className='debitcard-cc__subheading--rv2'>
                            This action cannot be reversed. Blocked card can no longer be used and
                            user will have to request for a new card from the Fi app.
                        </div>
                    </div>
                    <div className='debitcard-cc__ctas-wrapper'>
                        <Button
                            primary
                            label='Confirm Block'
                            extClasses='debitcard-cc__ctas'
                            onClick={() => blockDebitCard(true)}
                        />
                        <Button
                            secondary
                            label='Discard'
                            extClasses='upi-cc__ctas'
                            onClick={discardConfirmScreen(false)}
                        />
                    </div>
                </div>
                <Loader visible={isLoading} />
            </React.Fragment>
        );
    }

    if (isCardLimitsScreen) {
        const { limitInfo } = selectedCardInfo;

        return (
            <div className='debitcard-cr debitcard-cr--cs'>
                <div className='debitcard-clc-hwr'>
                    <img className='debitcard-backarrow' alt='back-arrow' src={backArrow} onClick={discardScreen({ isCardLimitsScreen: false })} />
                    <div className='debitcard-clc-hl'>Debit Card Usage Limits - {selectedCard.lastXDigits}</div>
                </div>
                <div className='debitcard-clc-cwr'>
                    <div className='frWrapper'>
                        <div className='debitcard-clc-cwr__hl debitcard-clc-cwr__f1'>Channel</div>
                        <div className='debitcard-clc-cwr__hl debitcard-clc-cwr__f2'>Domestic</div>
                        <div className='debitcard-clc-cwr__hl debitcard-clc-cwr__f2'>International</div>
                    </div>
                    <div className='frWrapper'>
                        <div className='debitcard-clc-cwr__f1' />
                        <div className='debitcard-clc-cwr__f1'>Current</div>
                        <div className='debitcard-clc-cwr__f1'>Max Allowed</div>
                        <div className='debitcard-clc-cwr__f1'>Current</div>
                        <div className='debitcard-clc-cwr__f1'>Max Allowed</div>
                    </div>
                    {
                        CARD_CHANNEL_LIST.map((item) => (
                            <div className='frWrapper' key={item.key}>
                                <div className='debitcard-clc-cwr__hl debitcard-clc-cwr__f1'>{item.limitLabel}</div>
                                <div className='debitcard-clc-cwr__f1'>{limitInfo[item.key].domestic.currentAllowedAmount}</div>
                                <div className='debitcard-clc-cwr__f1'>{limitInfo[item.key].domestic.maxAllowedAmount}</div>
                                <div className='debitcard-clc-cwr__f1'>{limitInfo[item.key].international.currentAllowedAmount}</div>
                                <div className='debitcard-clc-cwr__f1'>{limitInfo[item.key].international.maxAllowedAmount}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }

    if (isCardSettingsScreen) {
        const { settingInfo: { txnStates } } = selectedCardInfo;

        return (
            <div className='debitcard-cr debitcard-cr--cs'>
                <div className='debitcard-clc-hwr'>
                    <img className='debitcard-backarrow' alt='back-arrow' src={backArrow} onClick={discardScreen({ isCardSettingsScreen: false })} />
                    <div className='debitcard-clc-hl'>Debit Card Usage Settings - {selectedCard.lastXDigits}</div>
                </div>
                <div>
                    {
                        CARD_CHANNEL_LIST.map((item) => (
                            <div className='frWrapper debitcard-csc-wr' key={item.key}>
                                <div className='debitcard-csc__hl'>{item.settingLabel}</div>
                                <div className='debitcard-csc__sp'>:</div>
                                <div className='debitcard-csc__cl'>{txnStates[item.key.toUpperCase()] || '-'}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }

    const isSuspendDebitPending = pendingAuth[SENSITIVE_ACTIONS.SUSPENDDEBIT];
    const isBlockDebitPending = pendingAuth[SENSITIVE_ACTIONS.BLOCKDEBIT];
    const isCardLimitsScreenPending = pendingAuth[SENSITIVE_ACTIONS.CARDLIMITS];
    const isCardSettingsScreenPending = pendingAuth[SENSITIVE_ACTIONS.CARDSETTINGS];

    const isPendingAuth = isSuspendDebitPending || isBlockDebitPending || isCardLimitsScreenPending || isCardSettingsScreenPending;

    if (isPendingAuth) {
        let label;
        let type;
        let nextAction;
        let warningLabel;

        if (isSuspendDebitPending) {
            label = 'Suspend Debit Card';
            type = SENSITIVE_ACTIONS.SUSPENDDEBIT;
            nextAction = () => suspendDebitCard();
        } else if (isBlockDebitPending) {
            label = 'Block Debit Card';
            type = SENSITIVE_ACTIONS.BLOCKDEBIT;
            nextAction = () => blockDebitCard();
            warningLabel = 'Since required authorisation level could not be passed, card can only be suspended.'
                           + ' You can go back and choose the Suspend Card option.';
        } else if (isCardLimitsScreenPending) {
            label = 'Debit Card Usage Limits';
            type = SENSITIVE_ACTIONS.CARDLIMITS;
            nextAction = () => getCardLimitAndSettings(true, false);
        } else if (isCardSettingsScreenPending) {
            label = 'Debit Card Usage Settings';
            type = SENSITIVE_ACTIONS.CARDSETTINGS;
            nextAction = () => getCardLimitAndSettings(false, true);
        }

        return (
            <UserAuth label={label} type={type} index={index} nextAction={nextAction} warningLabel={warningLabel} />
        );
    }

    const isLoading = loading && (
        loading.cardList
        || loading?.cardAmcDetails
        || loading?.cardRequestStatus
        || loading.suspendDebitCard
        || loading.blockDebitCard
        || loading.cardInfo
        || loading.cardTrackingInfo
        || loading.updateFreeCardReplacement
    );

    return (
        <React.Fragment>
            <div className='debitcard-cr'>
                <div>
                    <div className='debitcard-heading'>Cards</div>
                    {renderSearchResults()}
                </div>

                <div className='debitcard-forex-txn-cr'>
                    <ForexTransaction
                        ticketId={ticketId}
                        index={index}
                        activeTicket={activeTicket}
                    />
                </div>
            </div>

            <CardTrackingInfoModal
                visible={showCardTrackingModal}
                data={selectedCardTrackingInfo}
                toggleHandler={setCardTrackingModal}
                getCardTrackingLabels={getCardTrackingLabels}
            />
            <TableModal
                visible={showCardRequestStatusModal}
                data={selectedCardRequestStatus?.status}
                toggleHandler={setCardRequestStatusModal}
            >
                {selectedCardRequestStatus?.extraText}
            </TableModal>
            <TableModal
                visible={showCardAMCModal}
                data={selectedCardAmcDetails?.details}
                toggleHandler={setCardAMCModal}
            />

            <Loader visible={isLoading} />
        </React.Fragment>
    );
};

export default memo(DebitCard);

/**
 *
 * TxnList
 *
 */

import React, { memo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Modal from 'react-modal';
import { equals } from 'ramda';
import classNames from 'classnames';

import {
    Button, DropdownContent, DropdownWrapper, Loader, Pagination, Table,
} from '../../../components';

import { getDisputeInfoAction, setDisputeInfo } from '../actions';
import {
    getTxnDetails, getCreditCardTxnDetails, getTxnDisputeDetails, getCreditCardTxnDisputeDetails, getUDIRTxnDisputeDetails,
} from '../utils';
import { makeSelectTxnsList, makeSelectTxnDisputeInfo, makeSelectFirstOrLastSetTxnList } from '../selectors';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '5%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const customStylesForTxnDetailsModal = {
    ...customStylesForModal,
    content: {
        ...customStylesForModal.content,
        maxHeight: 700,
        width: 700,
    },
};

const customStylesForDisputeModal = {
    ...customStylesForModal,
    content: {
        ...customStylesForModal.content,
        maxHeight: 700,
        width: 900,
    },
};

const customStylesForTransactionStatusModal = {
    ...customStylesForModal,
    content: {
        ...customStylesForModal.content,
        top: '20%',
        maxHeight: 580,
    },
};

const TxnList = (props) => {
    const dispatch = useDispatch();
    const [openedActionsTxnId, setOpenedActionsTxnId] = useState('');
    const [showTxnDetailsModal, setTxnDetailsModal] = useState(false);
    const [selectedEntityMeta, setSelectedEntityMeta] = useState(null);
    const [detailedStatusModalIsOpen, setDetailedStatusModalIsOpen] = useState(false);

    const txnList = useSelector(makeSelectTxnsList, shallowEqual);
    const firstOrLastSetTxnList = useSelector(makeSelectFirstOrLastSetTxnList, shallowEqual);
    const selectedTxnDisputeInfo = useSelector(makeSelectTxnDisputeInfo, shallowEqual);

    const {
        ticketId, userId, index, isDisputeFlow, selectedTxn, setSelectedTxn, toggleHandler, handleSubmit,
        attachEntityAgainstTicket, firstOrLastSet, creditCardTransaction,
    } = props;

    let data;
    let prev;
    let next;
    let err;
    let loading;
    let label;

    if (firstOrLastSet && firstOrLastSetTxnList[ticketId]) {
        ({ data, err, loading } = firstOrLastSetTxnList[ticketId]);

        const { firstSet, lastSet } = firstOrLastSetTxnList[ticketId];

        if (firstSet) {
            label = 'First 5 Transactions';
        } else if (lastSet) {
            label = 'Last 5 Transactions';
        }
    } else if (!firstOrLastSet && txnList[ticketId]) {
        ({
            data, prev, next, err, loading,
        } = txnList[ticketId]);
    }

    if (!data) {
        return <Loader visible={loading} />;
    }

    if (err) {
        return (
            <React.Fragment>
                <div className='container-err-label'>{err}</div>
                <Loader visible={loading} />
            </React.Fragment>
        );
    }

    if (data.length === 0) {
        return (
            <React.Fragment>
                {
                    label ? (
                        <div className='txns-first-last-set-cr__lb'>{label}</div>
                    ) : null
                }
                <div className='table-nsr table-nsr--mt20'>
                    No search results found!
                </div>
                <Loader visible={loading} />
            </React.Fragment>
        );
    }

    let selectedTxnDisputeInfoData;
    let hasSelectedTxnDisputeInfo;

    if (selectedTxnDisputeInfo[ticketId]) {
        ({ data: selectedTxnDisputeInfoData, hasInfo: hasSelectedTxnDisputeInfo } = selectedTxnDisputeInfo[ticketId]);
    }

    const onChangeSelectedEntityMeta = (meta) => () => {
        if (equals(selectedEntityMeta, meta)) {
            setSelectedEntityMeta(null);
        } else {
            setSelectedEntityMeta(meta);
        }
    };

    const attachEntityToTicket = () => {
        const callAttachEntityAction = () => new Promise((resolve, reject) => {
            try {
                attachEntityAgainstTicket(selectedEntityMeta, resolve);
            } catch (e) {
                reject(e);
            }
        });

        callAttachEntityAction().then(() => {
            setSelectedEntityMeta(null);
        });
    };

    const fetchDisputeInfo = () => {
        setOpenedActionsTxnId('');

        const payload = {
            ticketId,
            userId,
            index,
            txnId: selectedTxn.txn.intId,
        };

        if (creditCardTransaction) {
            dispatch(getDisputeInfoAction({ ...payload, creditCardDisputeInfo: true }));
        } else {
            dispatch(getDisputeInfoAction(payload));
        }
    };

    const raiseTxnDispute = () => {
        setOpenedActionsTxnId('');

        const callDisputeInfoAction = () => new Promise((resolve, reject) => {
            try {
                const payload = {
                    ticketId,
                    userId,
                    index,
                    txnId: selectedTxn.txn.intId,
                    reject,
                };

                if (creditCardTransaction) {
                    dispatch(getDisputeInfoAction({ ...payload, creditCardDisputeInfo: true }));
                } else {
                    dispatch(getDisputeInfoAction(payload));
                }
            } catch (e) {
                reject(e);
            }
        });

        callDisputeInfoAction()
            .catch(() => {
                toggleHandler('dispute-flow')();
            });
    };

    const handleMoreOptionsClick = (item) => () => {
        setOpenedActionsTxnId(item.txn.intId);
        setSelectedTxn(item);
    };

    const handleCheckboxChange = (item) => () => setSelectedTxn((selectedTxn.txn && item.txn.intId === selectedTxn.txn.intId) ? {} : item);

    const tableColumns = {
        fiTxnId: <div className='table-sr__th'>Fi Txn ID</div>,
        amount: <div className='table-sr__th'>Amount</div>,
        incomingOutgoing: <div className='table-sr__th'>Incoming / Outgoing</div>,
        txnMode: <div className='table-sr__th'>Txn Mode</div>,
        status: <div className='table-sr__th'>Status</div>,
        createdAt: <div className='table-sr__th'>Created At</div>,
        merchantName: <div className='table-sr__th'>Merchant</div>,
        utrNo: <div className='table-sr__th'>UTR no / URN</div>,
        fromInstr: <div className='table-sr__th'>From Instr</div>,
        toInstr: <div className='table-sr__th'>To Instr</div>,
        orderTags: <div className='table-sr__th'>Order Tags</div>,
        fundsDebitedAt: <div className='table-sr__th'>Funds Debited At</div>,
    };

    const {
        fiTxnId, amount, incomingOutgoing, txnMode, status, createdAt, merchantName,
        utrNo, fromInstr, toInstr, orderTags, fundsDebitedAt,
    } = tableColumns;

    /**
     * This function returns transaction details based on the type of transaction selected.
     * If the transaction is a credit card transaction, it calls the `getCreditCardTxnDetails` function with the selected transaction as a parameter.
     * Otherwise, it calls the `getTxnDetails` function.
     */
    const getTxnDetailsBasedOnTxnType = () => {
        if (creditCardTransaction) {
            return getCreditCardTxnDetails(selectedTxn);
        }

        // Checks if the selected Transaction has the txnMiscData variable and if it's an array
        // and if its true then renders all the label values pairs which have string data type value
        // from its array and concatenate its resultant array with the normal Transaction Details array
        if (selectedTxn && selectedTxn?.txnMiscData && Array.isArray(selectedTxn?.txnMiscData)) {
            const txnMiscData = selectedTxn?.txnMiscData
                .filter((item) => (item?.data_type === 'DATA_TYPE_STRING'))
                .map((item) => {
                    const { value, label: lbl } = item;
                    return {
                        label: lbl,
                        value: item[value],
                    };
                });
            return getTxnDetails(selectedTxn).concat(txnMiscData);
        }

        return getTxnDetails(selectedTxn);
    };

    return (
        <React.Fragment>
            {
                label ? (
                    <div className='txns-first-last-set-cr__lb'>{label}</div>
                ) : null
            }
            <div className='table-sr table-sr--mt20'>
                <div className='table-sr__thw'>
                    {
                        !isDisputeFlow ? (
                            <div className='table-sr__th table-sr__tl--actions' />
                        ) : null
                    }
                    {creditCardTransaction ? (
                        <React.Fragment>
                            {fiTxnId}
                            {amount}
                            {incomingOutgoing}
                            {txnMode}
                            {status}
                            {createdAt}
                            {merchantName}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {fiTxnId}
                            {utrNo}
                            {amount}
                            {incomingOutgoing}
                            {txnMode}
                            {status}
                            {fromInstr}
                            {toInstr}
                            {createdAt}
                            {orderTags}
                            {merchantName}
                            {fundsDebitedAt}
                        </React.Fragment>
                    )}
                    <div className='table-sr__th table-sr__th--actions' />
                </div>
                <div className='table-sr__tlc'>
                    {
                        data.map((item) => (
                            <div className='table-sr__tlw' key={item.txn.intId}>
                                {
                                    !isDisputeFlow ? (
                                        <div className='table-sr__th table-sr__tl--actions'>
                                            <input
                                                type='checkbox'
                                                checked={equals(selectedEntityMeta, item.entityMeta)}
                                                onChange={onChangeSelectedEntityMeta(item.entityMeta)}
                                            />
                                        </div>
                                    ) : null
                                }
                                {creditCardTransaction ? (
                                    <React.Fragment>
                                        <div className='table-sr__tl'>{item.extId}</div>
                                        <div className='table-sr__tl'>{item.amount}</div>
                                        <div className='table-sr__tl table-sr__tl--cc'>{item.txn.type}</div>
                                        <div className='table-sr__tl'>{item.txn.txnMode}</div>
                                        <div className='table-sr__tl'>{item.txn.status}</div>
                                        <div className='table-sr__tl'>{item.txn.createdTs}</div>
                                        <div className='table-sr__tl'>{item.merchantName}</div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className='table-sr__tl'>{item.order.extId}</div>
                                        <div className='table-sr__tl'>{item.utr}</div>
                                        <div className='table-sr__tl'>{item.amount}</div>
                                        <div className='table-sr__tl table-sr__tl--cc'>{item.txn.type}</div>
                                        <div className='table-sr__tl'>{item.paymentProtocol.toUpperCase()}</div>
                                        <div className='table-sr__tl'>{item.txn.status}</div>
                                        <div className='table-sr__tl'>{item.pi.from.toUpperCase()}</div>
                                        <div className='table-sr__tl'>{item.pi.to.toUpperCase()}</div>
                                        <div className='table-sr__tl'>{item.txn.createdTs}</div>
                                        <div className='table-sr__tl'>{item.order.tags}</div>
                                        <div className='table-sr__tl'>{item.merchantName}</div>
                                        <div className='table-sr__tl'>{item.fundsDebitedAt === '-' ? 'No Debit' : item.fundsDebitedAt}</div>
                                    </React.Fragment>
                                )}
                                {
                                    isDisputeFlow ? (
                                        <div className='table-sr__tl table-sr__tl--actions'>
                                            <input
                                                type='checkbox'
                                                checked={selectedTxn.txn && item.txn.intId === selectedTxn.txn.intId}
                                                onChange={handleCheckboxChange(item)}
                                            />
                                        </div>
                                    ) : (
                                        <div className='table-sr__tl table-sr__tl--actions'>
                                            <DropdownWrapper
                                                visible={openedActionsTxnId === item.txn.intId}
                                                onOutsideClickHandler={() => setOpenedActionsTxnId('')}
                                                extClasses={{
                                                    container: 'txns-ad',
                                                }}
                                            >
                                                <div
                                                    className='txns-ad-label'
                                                    onClick={handleMoreOptionsClick(item)}
                                                    role='presentation'
                                                >
                                                    ...
                                                </div>
                                                <DropdownContent
                                                    visible
                                                    extClasses={{
                                                        container: ['txns-ad-cc height-transition',
                                                            openedActionsTxnId === item.txn.intId && 'txns-ad-cc--active'],
                                                    }}
                                                >
                                                    <div className='txns-ad-cc-wr'>
                                                        <div
                                                            className='txns-ad-cc-wr__label'
                                                            onClick={() => {
                                                                setOpenedActionsTxnId('');
                                                                setTxnDetailsModal(!showTxnDetailsModal);
                                                            }}
                                                            role='presentation'
                                                        >
                                                            View Details
                                                        </div>
                                                        <div
                                                            className='txns-ad-cc-wr__label'
                                                            onClick={fetchDisputeInfo}
                                                            role='presentation'
                                                        >
                                                            View Dispute Details
                                                        </div>
                                                        <div
                                                            className='txns-ad-cc-wr__label'
                                                            onClick={raiseTxnDispute}
                                                            role='presentation'
                                                        >
                                                            Raise Dispute
                                                        </div>
                                                    </div>
                                                </DropdownContent>
                                            </DropdownWrapper>
                                        </div>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            { // show pagination only for normal search results, not for first or last set of txns
                prev && next ? (
                    <Pagination prev={prev} next={next} onClickHandler={handleSubmit} />
                ) : null
            }
            {
                !isDisputeFlow ? (
                    <Button
                        primary
                        extClasses='deposits-aecta'
                        disabled={!selectedEntityMeta}
                        label='Mark against Ticket'
                        onClick={attachEntityToTicket}
                    />
                ) : null
            }
            {
                showTxnDetailsModal ? (
                    <Modal
                        isOpen
                        style={customStylesForTxnDetailsModal}
                        contentLabel='Txn Details Modal'
                        shouldCloseOnOverlayClick
                        onRequestClose={() => setTxnDetailsModal(!showTxnDetailsModal)}
                    >
                        <div className='txns-modal-hwr'>Txn Details</div>
                        {
                            getTxnDetailsBasedOnTxnType().map((item) => (
                                <div className='txns-modal-cwr' key={item.label}>
                                    <div className='txns-modal-cwr__cl'>{item.label}</div>
                                    <div className='txns-modal-cwr__sp'>:</div>
                                    <div className='txns-modal-cwr__cv'>{item.value}</div>
                                    {
                                        item.cta ? (
                                            <div
                                                className='link kob-wrapper__cta'
                                                onClick={() => setDetailedStatusModalIsOpen(!detailedStatusModalIsOpen)}
                                                role='presentation'
                                            >
                                                View History
                                            </div>
                                        ) : null
                                    }
                                </div>
                            ))
                        }
                        <Button primary extClasses='txns-modal-bwr' label='Close' onClick={() => setTxnDetailsModal(!showTxnDetailsModal)} />
                        {selectedTxn?.txn?.detailedStatus && (
                            <Modal
                                isOpen={detailedStatusModalIsOpen}
                                style={customStylesForTransactionStatusModal}
                                contentLabel='Transaction Status Modal'
                            >
                                <div className='txns-modal-hwr'>Transaction Detailed Status</div>
                                <div className='txns-modal-ccr'>
                                    <Table
                                        labelData={selectedTxn.txn.detailedStatus.columnData}
                                        contentData={selectedTxn.txn.detailedStatus.rowData}
                                        enableScrollView
                                        extClasses={{
                                            contentCr: 'txns-modal-ccr-tccr',
                                        }}
                                    />
                                </div>
                                <Button
                                    primary
                                    extClasses='txns-modal-bwr'
                                    label='Close'
                                    onClick={() => setDetailedStatusModalIsOpen(!detailedStatusModalIsOpen)}
                                />
                            </Modal>
                        )}
                    </Modal>
                ) : null
            }
            {
                hasSelectedTxnDisputeInfo && creditCardTransaction ? (
                    <Modal
                        isOpen
                        style={customStylesForTxnDetailsModal}
                        contentLabel='Txn Dispute Details Modal'
                    >
                        <div className='txns-modal-hwr'>Txn Details</div>
                        {
                            getCreditCardTxnDisputeDetails(selectedTxnDisputeInfoData).map((item) => (
                                <div className='txns-modal-cwr' key={item.label}>
                                    <div className='txns-modal-cwr__cl'>{item.label}</div>
                                    <div className='txns-modal-cwr__sp'>:</div>
                                    <div className='txns-modal-cwr__cv'>{item.value}</div>
                                </div>
                            ))
                        }
                        <Button
                            primary
                            extClasses='txns-modal-bwr'
                            label='Close'
                            onClick={() => {
                                dispatch(
                                    setDisputeInfo({
                                        ticketId,
                                        disputeInfo: {},
                                        hasDisputeInfo: false,
                                    }),
                                );
                            }}
                        />
                    </Modal>
                ) : null
            }
            {
                hasSelectedTxnDisputeInfo && !creditCardTransaction ? (
                    <Modal
                        isOpen
                        style={customStylesForDisputeModal}
                        contentLabel='Txn Dispute Details Modal'
                    >
                        <div className='txns-modal-red-highlight'>
                            Active dispute already exists for given transaction, please don&apos;t raise a new dispute
                        </div>
                        <div className='txns-modal-hwr'>Txn Dispute Details</div>
                        {
                            (selectedTxnDisputeInfoData.escalationMode === 'ESCALATION_MODE_UDIR'
                                ? getUDIRTxnDisputeDetails(selectedTxnDisputeInfoData) : getTxnDisputeDetails(selectedTxnDisputeInfoData))
                                .map((item) => (
                                    <div className='txns-modal-cwr' key={item.label}>
                                        <div className='txns-modal-cwr__cl'>{item.label}</div>
                                        <div className='txns-modal-cwr__sp'>:</div>
                                        {
                                            // eslint-disable-next-line no-nested-ternary
                                            item.isArray ? ( // not getting used, retaining for future generic table use
                                                <div>
                                                    {
                                                        item.value.map((subItem) => (
                                                            item.arrayFields.map((arrField) => (
                                                                <div className='frWrapper'>
                                                                    <div className='txns-modal-sbcwr__cl'>{arrField}</div>
                                                                    <div className='txns-modal-sbcwr__sp'>-</div>
                                                                    <div className='txns-modal-sbcwr__cv'>{subItem[arrField]}</div>
                                                                </div>
                                                            ))
                                                        ))
                                                    }
                                                    {
                                                        item.value.map((subItem) => (
                                                            item.arrayFields.map((arrField) => (
                                                                <div className='frWrapper'>
                                                                    <div className='txns-modal-sbcwr__cl'>{arrField}</div>
                                                                    <div className='txns-modal-sbcwr__sp'>-</div>
                                                                    <div className='txns-modal-sbcwr__cv'>{subItem[arrField]}</div>
                                                                </div>
                                                            ))
                                                        ))
                                                    }
                                                </div>
                                            ) : (
                                                <div className={classNames('txns-modal-cwr__cv', { 'txns-modal-cwr__cv-bold': item?.style?.bold })}>
                                                    {item.value}
                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                        }
                        <div className='table-sr table-sr--mt0'>
                            <div className='table-sr__thw'>
                                <div className='table-sr__th f2Wrapper'>Question</div>
                                <div className='table-sr__th'>Answer</div>
                            </div>
                            <div className='table-sr__tlc'>
                                {
                                    selectedTxnDisputeInfoData.qna.map((item) => (
                                        <div className='table-sr__tlw'>
                                            <div className='table-sr__tl table-sr__tl--nc f2Wrapper'>{item.question}</div>
                                            <div className='table-sr__tl table-sr__tl--nc'>{item.answer}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <Button
                            primary
                            extClasses='txns-modal-bwr'
                            label='Close'
                            onClick={() => {
                                dispatch(
                                    setDisputeInfo({
                                        ticketId,
                                        disputeInfo: {},
                                        hasDisputeInfo: false,
                                    }),
                                );
                            }}
                        />
                    </Modal>
                ) : null
            }
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(TxnList);

/**
 * @file CardTrackingInfoModal: Card Tracking Info Modal
 */

import React, { memo, useState } from 'react';
import Modal from 'react-modal';

import { Button, ExpandableCard } from '../index';

import './style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '8%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '32px 32px 24px',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 800,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const CardTrackingInfoModal = (props) => {
    const {
        visible, data, toggleHandler, getCardTrackingLabels,
    } = props;

    const [isCardExpanded, setIsCardExpanded] = useState(false);

    if (!visible) return null;

    return (
        <Modal
            isOpen={visible}
            style={customStylesForModal}
            contentLabel='Card Tracking Details Modal'
        >
            <div className='ctri-modal-hwr'>Card Tracking Details</div>
            {
                getCardTrackingLabels(data).map((item) => (
                    <div className='ctri-modal-cwr' key={item.label}>
                        <div className='ctri-modal-cwr__cl'>{item.label}</div>
                        <div className='ctri-modal-cwr__sp'>:</div>
                        <div className='ctri-modal-cwr__cv'>{item.value}</div>
                    </div>
                ))
            }
            {
                data.scans && (
                    <ExpandableCard
                        isExpanded={isCardExpanded}
                        setIsExpanded={() => setIsCardExpanded(!isCardExpanded)}
                        extClasses='ctri-modal-ec'
                        extHeadingClasses='ctri-modal-ec-hd'
                        heading='Show Delivery details'
                    >
                        <div className='ctri-modal-ec-wr'>
                            {
                                data.scans.length > 0 ? (
                                    <div className='table-sr table-sr--mt0'>
                                        <div className='table-sr__thw'>
                                            <div className='table-sr__th'>Location</div>
                                            <div className='table-sr__th'>Updated At</div>
                                            <div className='table-sr__th'>Status Description</div>
                                            <div className='table-sr__th'>Delivery State</div>
                                        </div>
                                        <div className='table-sr__tlc ctri-modal-ec-tlc'>
                                            {
                                                data.scans.map((item) => (
                                                    <div className='table-sr__tlw table-sr__tlw--aic' key={item.updatedTs}>
                                                        <div className='table-sr__tl'>{item.location}</div>
                                                        <div className='table-sr__tl'>{item.updatedTs}</div>
                                                        <div className='table-sr__tl'>{item.status}</div>
                                                        <div className='table-sr__tl table-sr__tl--cc'>{item.deliveryState}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    'No data found for delivery details!'
                                )
                            }
                        </div>
                    </ExpandableCard>
                )
            }

            <Button
                primary
                onClick={() => toggleHandler(false)}
                extClasses='ctri-modal-bwr'
                label='Close'
            />
        </Modal>
    );
};

export default memo(CardTrackingInfoModal);

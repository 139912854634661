/**
 * @file Manages the selector functions for Transaction view V2
 */

import { createSelector } from 'reselect';

import { TRANSACTION_VIEW_V2_KEY } from './constants';

export const selectTransactionViewV2Details = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_V2_KEY, 'transactions']),
    (substate) => substate?.toJS(),
);

export const selectTransactionViewLoader = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_V2_KEY, 'loading']),
    (substate) => substate,
);

export const selectTransactionFetchingStatus = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_V2_KEY, 'fetching']),
    (substate) => substate,
);

export const selectTransactionViewError = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_V2_KEY, 'err']),
    (subState) => subState,
);

export const selectTransactionViewPeriod = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_V2_KEY, 'period']),
    (subState) => subState?.toJS(),
);

export const selectApp = createSelector(
    (state) => state.getIn(['app']),
    (substate) => substate?.toJS(),
);

export const selectFilterValue = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_V2_KEY, 'filterField']),
    (substate) => substate?.toJS(),
);

export const selectAggregatedValue = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_V2_KEY, 'aggregateDataPoints']),
    (substate) => substate?.toJS(),
);

export const selectAnnotationDetails = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_V2_KEY, 'annotationDetails']),
    (substate) => substate?.toJS(),
);

export const selectTxnAnnotationStatus = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_V2_KEY, 'txnAnnotationModalVisibility']),
    (subState) => subState,
);

/**
 * Constants file for Issue Config
 */

export const ISSUE_CONFIG_KEY = 'issueConfig';
export const ISSUE_CONFIG_FILTER_KEY = 'filterOptions';
export const ISSUE_CONFIG_HISTORY_KEY = 'issueConfigHistory';
export const UPDATE_ISSUE_CONFIG_KEY = 'updateIssueConfig';

const ISSUE_CONFIG_PREFIX = 'app/ISSUE_CONFIG';

export const GET_FILTER_OPTIONS = `${ISSUE_CONFIG_PREFIX}/GET_FILTER_OPTIONS`;
export const SET_FILTER_OPTIONS = `${ISSUE_CONFIG_PREFIX}/SET_FILTER_OPTIONS`;
export const ERR_FILTER_OPTIONS = `${ISSUE_CONFIG_PREFIX}/ERR_FILTER_OPTIONS`;

export const GET_ISSUE_CONFIG = `${ISSUE_CONFIG_PREFIX}/GET_ISSUE_CONFIG`;
export const SET_ISSUE_CONFIG = `${ISSUE_CONFIG_PREFIX}/SET_ISSUE_CONFIG`;
export const ERR_ISSUE_CONFIG = `${ISSUE_CONFIG_PREFIX}/ERR_ISSUE_CONFIG`;

export const UPDATE_ISSUE_CONFIG = `${ISSUE_CONFIG_PREFIX}/UPDATE_ISSUE_CONFIG`;
export const SET_UPDATE_ISSUE_CONFIG = `${ISSUE_CONFIG_PREFIX}/SET_UPDATE_ISSUE_CONFIG`;
export const ERR_UPDATE_ISSUE_CONFIG = `${ISSUE_CONFIG_PREFIX}/ERR_UPDATE_ISSUE_CONFIG`;

export const GET_ISSUE_CONFIG_HISTORY = `${ISSUE_CONFIG_PREFIX}/GET_ISSUE_CONFIG_HISTORY`;
export const SET_ISSUE_CONFIG_HISTORY = `${ISSUE_CONFIG_PREFIX}/SET_ISSUE_CONFIG_HISTORY`;
export const ERR_ISSUE_CONFIG_HISTORY = `${ISSUE_CONFIG_PREFIX}/ERR_ISSUE_CONFIG_HISTORY`;

export const CLEAR_FILE_DOWNLOAD_DATA = `${ISSUE_CONFIG_PREFIX}/CLEAR_FILE_DOWNLOAD_DATA`;

export const LevelMapping = {
    ISSUE_CATEGORY_MASK_PRODUCT_CATEGORY: 'L1',
    ISSUE_CATEGORY_MASK_PRODUCT_CATEGORY_DETAILS: 'L2',
    ISSUE_CATEGORY_MASK_SUB_CATEGORY: 'L3',
};

export const DEFAULT_STYLES_FOR_MODAL = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '10%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        fontSize: 16,
        width: 500,
        height: 300,
        border: 0,
        backgroundColor: '#F5F5F5',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        'max-width': '1200px',
        'max-height': '3000px',
    },
};

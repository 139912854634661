import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify, downloadFile,
} from '../../utils';
import { APP_LOGS_ROOT } from '../../api/routes';

import {
    GET_LOGS, SET_LOGS, ERR_LOGS, GET_LOG_INFO, SET_LOG_INFO, ERR_LOG_INFO, DOWNLOAD_FILE, GET_LOGS_ENUMS,
} from './constants';
import { setLogsEnumsAction } from './actions';

function* getLogsEnums() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${APP_LOGS_ROOT}/enums`,
        );

        yield put(setLogsEnumsAction(response));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LOGS, data: { err: e.message } });
    }
}

function* getLogs(action) {
    const {
        identifierType, identifierValue, from, to, monorailId,
    } = action.data;
    const nonSensitiveParams = getQueryStringFromObject({ from, to, monorailId });
    const sensitiveParams = getQueryStringFromObject({ identifierType, identifierValue }, true);
    const queryString = `${sensitiveParams}&${nonSensitiveParams}`;
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${APP_LOGS_ROOT}?${queryString}`,
        );

        yield put({ type: SET_LOGS, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LOGS, data: { err: e.message } });
    }
}

function* getLogInfo(action) {
    const {
        logId, prev, next, monorailId, isDownloadFn,
    } = action.data;
    const queryString = getQueryStringFromObject({
        prev, // not getting used as we load logs further only
        next,
        logId,
        monorailId,
        isDownloadFn,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${APP_LOGS_ROOT}/get-log?${queryString}`,
        );

        // download & save the file
        if (isDownloadFn) {
            try {
                downloadFile(`applogs_${atob(logId)}.txt`, `data:text/plain;charset=utf-8,${encodeURIComponent(response.logs)}`);
            } catch (err) {
                toastify(err, 'error');
            }

            yield put({ type: DOWNLOAD_FILE });
        } else {
            yield put({ type: SET_LOG_INFO, data: { ...response, calledWithNextToken: !!next } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LOG_INFO, data: { err: e.description } });
    }
}

export default function* logsInfoSaga() {
    yield all(
        [
            yield takeLatest(GET_LOGS, getLogs),
            yield takeLatest(GET_LOG_INFO, getLogInfo),
            yield takeLatest(GET_LOGS_ENUMS, getLogsEnums),
        ],
    );
}

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { KYC_ROOT, ONBOARDING_ROOT } from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_KYC_INFO, SET_KYC_INFO, ERR_KYC_INFO, GET_ONBOARDING_INFO, SET_ONBOARDING_INFO,
    ERR_ONBOARDING_INFO, GET_REOOBE_INFO, SET_REOOBE_INFO, ERR_REOOBE_INFO, GET_VKYC_INFO,
    SET_VKYC_INFO, ERR_VKYC_INFO, RETRY_LIVENESS, UPDATE_RETRY_LIVENESS,
} from './constants';

function* getKycInfo(action) {
    const {
        authType, authValue, userId, index, authSubValue,
    } = action.data;
    const queryString = getQueryStringFromObject({
        authType, authValue, userId, authSubValue,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${KYC_ROOT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_KYC_INFO, data: { authValue, authSubValue, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_KYC_INFO, data: { authSubValue, authValue } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_KYC_INFO, data: { authValue, authSubValue, err: e.message } });
    }
}

function* getOnboardingInfo(action) {
    const {
        authType, authValue, userId, index, authSubValue,
    } = action.data;
    const queryString = getQueryStringFromObject({
        authType, authValue, userId, authSubValue,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ONBOARDING_ROOT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_ONBOARDING_INFO, data: { authValue, authSubValue, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_ONBOARDING_INFO, data: { authSubValue, authValue } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ONBOARDING_INFO, data: { authValue, authSubValue, err: e.message } });
    }
}

function* getReoobeInfo(action) {
    const {
        authType, authValue, userId, index, authSubValue,
    } = action.data;
    const queryString = getQueryStringFromObject({
        authType, authValue, userId, authSubValue,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ONBOARDING_ROOT}/reoobe?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_REOOBE_INFO, data: { authValue, authSubValue, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_REOOBE_INFO, data: { authSubValue, authValue } });
        }
    } catch (e) {
        const err = getErrLabel(e);

        if (e.successToast) {
            toastify(err, 'success');
        } else {
            toastify(err, 'error');
        }

        yield put({ type: ERR_REOOBE_INFO, data: { authValue, authSubValue, err: e.message } });
    }
}

function* getVKycInfo(action) {
    const {
        authType, authValue, userId, index, authSubValue,
    } = action.data;
    const queryString = getQueryStringFromObject({
        authType, authValue, userId, authSubValue,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${KYC_ROOT}/vkyc?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_VKYC_INFO, data: { authValue, authSubValue, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_VKYC_INFO, data: { authSubValue, authValue } });
        }
    } catch (e) {
        const err = getErrLabel(e);

        if (e.successToast) {
            toastify(err, 'success');
        } else {
            toastify(err, 'error');
        }

        yield put({ type: ERR_VKYC_INFO, data: { authValue, authSubValue, err: e.message } });
    }
}

function* retryLiveness(action) {
    const { ticketId, userId, index } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${KYC_ROOT}/retry-liveness?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            toastify(response.description, 'success');
        }

        yield put({ type: UPDATE_RETRY_LIVENESS, data: { ticketId } });
        // update the KYC info if success in liveness retry action
        yield put({
            type: GET_KYC_INFO,
            data: action.data,
        });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: UPDATE_RETRY_LIVENESS, data: { ticketId } });
    }
}

export default function* depositsSaga() {
    yield all(
        [
            yield takeLatest(GET_KYC_INFO, getKycInfo),
            yield takeLatest(GET_ONBOARDING_INFO, getOnboardingInfo),
            yield takeLatest(GET_REOOBE_INFO, getReoobeInfo),
            yield takeLatest(GET_VKYC_INFO, getVKycInfo),
            yield takeLatest(RETRY_LIVENESS, retryLiveness),
        ],
    );
}

/**
 * @file Manages the actions for Search component
 */

import {
    ERR_SEARCH_SOP, ERR_SEARCH_SOP_META, GET_SEARCH_SOP,
    GET_SEARCH_SOP_META, SET_SEARCH_SOP, SET_SEARCH_SOP_META,
} from './constants';

export const getSearchSopAction = (data) => ({
    type: GET_SEARCH_SOP,
    data,
});

export const setSearchSopAction = (data) => ({
    type: SET_SEARCH_SOP,
    data,
});

export const errSearchSopAction = (data) => ({
    type: ERR_SEARCH_SOP,
    data,
});

export const getSearchSopMetaAction = (data) => ({
    type: GET_SEARCH_SOP_META,
    data,
});

export const setSearchSopMetaAction = (data) => ({
    type: SET_SEARCH_SOP_META,
    data,
});

export const errSearchSopMetaAction = (data) => ({
    type: ERR_SEARCH_SOP_META,
    data,
});

/**
 * @file ViewCorrespondence
 * Renders all the correspondence details in a table format
 * User needs to select the date range and to search the correspondence details
 */

import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-modal';

import {
    Button, DateRangePicker, LoaderOrError, Table,
} from '../../../components';
import {
    clientApiWrapper, getErrMsg, getQueryStringFromObject, showErrorToastMsg,
} from '../../../utils';
import { DISPUTE_CORRESPONDENCE_DETAILS_ENDPOINT } from '../../../api/routes';

import { DEFAULT_STYLES_FOR_MODAL, INITIAL_DATE_RANGE, dateInfoForViewCorrespondence } from '../constants';

const customStylesForModal = {
    overlay: {
        ...DEFAULT_STYLES_FOR_MODAL.overlay,
    },
    content: {
        ...DEFAULT_STYLES_FOR_MODAL.content,
        width: 900,
        height: 700,
    },
};

const CORRESPONDENCE_TABLE_LABEL_DATA = [
    {
        id: 1,
        label: 'ID',
        key: 'id',
    },
    /* keeping for reference and if needed for future use cases
    {
        id: 2,
        label: 'Dispute ID',
        key: 'dispute_id',
    },
     */
    {
        id: 3,
        label: 'Organisation',
        key: 'organisation',
    },
    {
        id: 4,
        label: 'Correspondence Text',
        key: 'correspondence_text',
    },
    {
        id: 5,
        label: 'Created At',
        key: 'created_at',
    },
    {
        id: 6,
        label: 'Updated At',
        key: 'updated_at',
    },
    {
        id: 7,
        label: 'Agent Email',
        key: 'agent_email',
    },
];

export const ViewCorrespondence = memo((props) => {
    const { parentProps, isEscalation } = props;

    const {
        visible,
        data,
        parentProps: {
            ticketId = 0,
        },
    } = parentProps;

    const [loading, setLoading] = useState(false);
    const [errorLabel, setErrorLabel] = useState('');
    const [correspondenceData, setCorrespondenceData] = useState({});
    const [fromDate, setFromDate] = useState(INITIAL_DATE_RANGE.fromDate);
    const [toDate, setToDate] = useState(INITIAL_DATE_RANGE.toDate);

    const fromDateVal = fromDate.value;
    const toDateVal = toDate.value;

    const getCorrespondenceDetails = async (paginationData) => {
        if (!visible) return;

        if (!fromDateVal || !toDateVal) {
            setErrorLabel('Please select a date range');
            return;
        }

        try {
            const queryParams = {
                payload: {
                    disputeId: data.id,
                    ticketId,
                    fromTime: fromDate.value,
                    toTime: toDate.value,
                    isEscalation,
                },
                prev: paginationData?.prev,
                next: paginationData?.next,
            };

            const queryString = getQueryStringFromObject(queryParams);

            setLoading(true);
            setErrorLabel('');

            const response = await clientApiWrapper.get(`${DISPUTE_CORRESPONDENCE_DETAILS_ENDPOINT}?${queryString}`);

            if (response === 'auth-expired') {
                throw new Error('Auth expired');
            }

            setCorrespondenceData(response);
        } catch (error) {
            showErrorToastMsg(error);
            const errorMsg = getErrMsg(error);
            setErrorLabel(errorMsg);
        } finally {
            setLoading(false);
        }
    };

    const getPaginatedCorrespondenceDetails = (paginationData) => {
        getCorrespondenceDetails(paginationData);
    };

    useEffect(() => {
        getCorrespondenceDetails();
        setErrorLabel('');
        setCorrespondenceData({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    if (!visible) return null;

    return (
        <React.Fragment>
            <div className='frccWrapper mb-60'>
                <DateRangePicker
                    key='correspondence-date-picker'
                    dateInfo={dateInfoForViewCorrespondence}
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                />
                <Button
                    v2
                    extClasses='disputes-sc__cta'
                    primary
                    label='Search'
                    disabled={!fromDateVal || !toDateVal}
                    onClick={getCorrespondenceDetails}
                />
            </div>
            <Table
                v2
                header='Correspondence Details'
                labelData={CORRESPONDENCE_TABLE_LABEL_DATA}
                contentData={correspondenceData.data}
                prevToken={correspondenceData.prev}
                nextToken={correspondenceData.next}
                paginatedClickHandler={getPaginatedCorrespondenceDetails}
                extClasses={{
                    container: 'mb-60',
                }}
            />

            <LoaderOrError
                loading={loading}
                errorLabel={errorLabel}
            />
        </React.Fragment>
    );
});

const ParentComponent = (props) => {
    const {
        toggleHandler,
        setData,
        visible,
    } = props;

    const closeModal = () => {
        setData({});
        toggleHandler();
    };

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
        >
            <ViewCorrespondence
                parentProps={props}
            />
            <Button
                v2
                secondary
                extClasses='disputes-modal-cta'
                label='Close'
                onClick={closeModal}
            />
        </Modal>
    );
};

export default memo(ParentComponent);

import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import VideoStreamLayout from '../VideoStreamLayout';
import CurrentStage from './CurrentStage';
import BottomOptions from './BottomOptions';
import { selectVkycData } from '../selectors';

const CallScreen = (props) => {
    const { stopRecording } = props;
    const [nextStepEnable, setNextStepEnable] = useState(false);
    const vkycData = useSelector(selectVkycData, shallowEqual);
    const meetingId = vkycData?.data?.meetingId;
    const currentStep = vkycData?.meetings?.[meetingId]?.stepNumber;
    const stages = vkycData?.meetings?.[meetingId]?.stages;

    return (
        <div>
            <div className='vkyc-container'>
                {currentStep < stages?.length
                && (
                    <div className='vkyc-left-video-container'>

                        <VideoStreamLayout />
                    </div>
                )}
                <div className={`vkyc-right-qna-container ${currentStep >= stages?.length && 'vkyc-w-100'}`}>
                    <div className='vkyc-qna-container'>
                        <CurrentStage stopRecording={stopRecording} setNextStepEnable={setNextStepEnable} />
                    </div>
                    {currentStep < stages?.length
                        && <BottomOptions nextStepEnable={nextStepEnable} setNextStepEnable={setNextStepEnable} stopRecording={stopRecording} />}
                </div>
            </div>
        </div>
    );
};

export default CallScreen;

/**
 *
 * FormWithSingleInput
 *
 */

import React, { memo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames';
import DatePicker from 'react-datepicker';

import { calcTextAreaHeight } from '../../utils/uiHelpers';

import Button from '../Button';

import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

const InputField = (props) => {
    /**
     * nameFieldArr - prop to render the name input field according to required labels
     * nameFieldPlaceholderArr - prop to show the name input field placeholder according to required labels
     * autosize - prop to set the height based on the size of the prefilled content
     */
    const {
        labelId, formType, extClasses, input: {
            value, onChange, pattern, disabled, minLength, maxLength, showYearDropdown, yearDropdownItemNumber,
            maxDate, minDate, ref, onKeyDown, type, nameFieldArr, placeholder, nameFieldPlaceholderArr = [],
            rows, cols, showTimeInDateInput, filterPassedTime, autosize = false,
        },
    } = props;

    const inputFieldClass = classNames('input-label', extClasses && extClasses.inputField);
    const nameInputFieldClass = classNames('input-label--smaller', extClasses && extClasses.nameInputField);

    let dateFormat = 'dd MMMM, yyyy';
    let showTimeInput = false;

    if (showTimeInDateInput) {
        dateFormat = 'dd MMMM, yyyy, hh:mm aa';
        showTimeInput = true;
    }

    switch (formType) {
        case 'name': {
            const nameFieldItems = nameFieldArr || ['first_name', 'last_name'];

            return (
                nameFieldItems.map((item, index) => (
                    <input
                        key={item}
                        id={labelId}
                        className={nameInputFieldClass}
                        type='text'
                        value={value[item]}
                        onChange={onChange && onChange(item)}
                        pattern={pattern}
                        disabled={disabled}
                        minLength={minLength}
                        maxLength={maxLength}
                        placeholder={nameFieldPlaceholderArr[index] || item.split('_').join(' ')}
                    />
                ))
            );
        }

        case 'txn': {
            return (
                <input
                    id={labelId}
                    className='input-label'
                    type='number'
                    value={value.units}
                    onChange={onChange && onChange('units')}
                    pattern={pattern}
                    disabled={disabled}
                    minLength={minLength}
                    placeholder={placeholder}
                />
            );
        }

        case 'dob': {
            return (
                <DatePicker
                    dateFormat={dateFormat}
                    placeholderText='Choose Date'
                    className={inputFieldClass}
                    selected={value}
                    onChange={onChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={disabled}
                    showYearDropdown={showYearDropdown}
                    yearDropdownItemNumber={yearDropdownItemNumber}
                    scrollableYearDropdown
                    showTimeSelect={showTimeInput}
                    timeIntervals={30}
                    timeFormat='h:mm aa'
                    filterTime={filterPassedTime}
                />
            );
        }

        case 'textarea': {
            const defaultStyles = {
                height: (autosize) ? calcTextAreaHeight(value) : 'initial',
            };

            return (
                <textarea
                    style={defaultStyles}
                    id={labelId}
                    className={inputFieldClass}
                    value={value}
                    onChange={onChange}
                    rows={rows}
                    cols={cols}
                    placeholder={placeholder}
                />
            );
        }

        default: {
            return (
                <input
                    id={labelId}
                    className={inputFieldClass}
                    type={type || 'text'}
                    value={value}
                    onChange={onChange}
                    pattern={pattern}
                    disabled={disabled}
                    minLength={minLength}
                    maxLength={maxLength}
                    ref={ref}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder}
                />
            );
        }
    }
};

const FormWithSingleInput = (props) => {
    const {
        labelId, label, description, formType, input, disableFormSubmitOnEnter,
        cta, errLabel, statusLabel, extClasses, hideErrLabel, onFormSubmit,
    } = props;

    let primaryLabel; let onPrimaryClick; let primaryDisabled; let secondaryLabel; let onSecondaryClick;
    let secondaryDisabled; let tertiaryLabel; let onTertiaryClick; let tertiaryDisabled; let v2Buttons;

    if (cta) {
        ({
            primaryLabel, onPrimaryClick, primaryDisabled, secondaryLabel, onSecondaryClick,
            secondaryDisabled, tertiaryLabel, onTertiaryClick, tertiaryDisabled, v2Buttons = false,
        } = cta);
    }

    const containerClass = classNames('form-container', extClasses && extClasses.container);
    const wrapperClass = classNames('form-wrapper', extClasses && extClasses.wrapper);
    const labelClass = classNames('form-wrapper-label', extClasses && extClasses.label);
    const descriptionClass = classNames(extClasses && extClasses.description);
    const errLabelClass = classNames('error-label', extClasses && extClasses.errLabel);

    return (
        <div className={containerClass}>
            <form
                className={wrapperClass}
                onSubmit={(e) => {
                    if (disableFormSubmitOnEnter) {
                        e.preventDefault();
                    }

                    if (onFormSubmit) {
                        onFormSubmit(e);
                    }
                }}
            >
                {
                    label ? (
                        <div className={labelClass}>
                            <label htmlFor={labelId}>{label}</label>
                            {
                                description ? (
                                    <div className={descriptionClass}>{description}</div>
                                ) : null
                            }
                        </div>
                    ) : null
                }
                <InputField formType={formType} input={input} labelId={labelId} extClasses={extClasses} />
                {
                    cta ? (
                        <React.Fragment>
                            <Button
                                v2={v2Buttons}
                                primary
                                label={primaryLabel}
                                disabled={primaryDisabled}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onPrimaryClick(e);
                                }}
                            />
                            {
                                secondaryLabel ? (
                                    <Button
                                        v2={v2Buttons}
                                        secondary
                                        label={secondaryLabel}
                                        disabled={secondaryDisabled}
                                        extClasses='secondary-btn'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSecondaryClick();
                                        }}
                                    />
                                ) : null
                            }
                            {
                                tertiaryLabel ? (
                                    <Button
                                        v2={v2Buttons}
                                        secondary
                                        label={tertiaryLabel}
                                        disabled={tertiaryDisabled}
                                        extClasses='secondary-btn'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onTertiaryClick();
                                        }}
                                    />
                                ) : null
                            }
                        </React.Fragment>
                    ) : null
                }
                {
                    statusLabel ? (
                        <div className='status-label'>{statusLabel}</div>
                    ) : null
                }
            </form>
            {!hideErrLabel ? (<div className={errLabelClass}>{errLabel}</div>) : null}
        </div>
    );
};

export default memo(FormWithSingleInput);

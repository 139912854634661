/**
 * @file Manages the reducer state & functions for Scripts component
 */
import { fromJS } from 'immutable';
import { path } from 'ramda';
import {
    AUDITOR_SCREEN_TYPE, SET_AUDIT_REPORT, SET_SCREEN, SET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT,
} from './constants';

const initialState = fromJS({
    loading: false,
    err: '',
    activeMeeting: null,
    step: 0,
    meetings: {},
    data: null,
    recordingUrl: null,
    report: null,
    meetingId: null,
    customerName: null,
    screenKey: AUDITOR_SCREEN_TYPE.INITIATE_SCREEN,
    waitingReports: 0,
    rejectionReasons: [],
    availableReviewCount: 0,
});

function vkycAuditor(state = initialState, action = {}) {
    switch (action.type) {
        case SET_AUDIT_REPORT: {
            const {
                recordingUrl, report, meetingId, screenKey, rejectionReasons,
            } = action.data;
            const customerName = path(['user_details', 'rowData', '0', 'applicant_form_data', 'string_value'], report);
            return state
                .setIn(['recordingUrl'], recordingUrl)
                .setIn(['report'], report)
                .setIn(['meetingId'], meetingId)
                .setIn(['customerName'], customerName)
                .setIn(['screenKey'], screenKey)
                .setIn(['rejectionReasons'], rejectionReasons);
        }

        case SET_SCREEN: {
            return state
                .setIn(['screenKey'], action.data);
        }

        case SET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT: {
            const { waitingReports } = action.data;
            return state.setIn(['waitingReports'], waitingReports);
        }
        default:
            return state;
    }
}

export default vkycAuditor;

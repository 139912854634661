/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * @file RenderMedia
 * Renders different types of media used in user risk review
 * Media could include images, videos, etc.
 */

import React, {
    memo, useMemo, useEffect, useState, useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { RenderDocument, RenderVideo, AppTabs } from '../../../components';
import { isNilOrEmpty } from '../../../utils';

import { getMediaForUserRiskReviewAction } from '../actions';
import { MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO } from '../constants';
import { UserDetailsContext } from '../../../context';
import { AWS_S3_PROXY_PREFIX } from '../../RiskOps/constants';

const MediaUnit = (props) => {
    const { mediaInfo, setMediaSrcExt } = props;

    const dispatch = useDispatch();

    const [mediaSrc, setMediaSrc] = useState('');
    const [proxiedMediaSrc, setProxiedMediaSrc] = useState(mediaSrc);

    const userInfo = useContext(UserDetailsContext);
    const {
        basicInfo: {
            accessLevelInfo: {
                isFederalLoanLivenessReviewer,
            },
        },
    } = userInfo;

    function getProxiedUrl(videoUrlFromState, proxyNeeded) {
        if (!videoUrlFromState) {
            return videoUrlFromState;
        }
        // TODO : Find alternative to get the domain name to proxy to from config : https://monorail.pointz.in/p/fi-app/issues/detail?id=47155
        const currentDomain = window.location.origin;
        // If the current access level is 'FEDERAL_LOAN_LIVENESS_REVIEWER'
        // then create the proxied url for the video url
        // This is needed because agents from federal are behind a VPN with S3 urls blocked
        // hence video urls for those roles are being proxied
        const videoUrlObject = new URL(videoUrlFromState);
        if (proxyNeeded) {
            // @example
            // videoUrlFromState - https://s3.ap-south-1.amazonaws.com/epifi-liveness/liveness_videos/{actor_id}/*
            // videoUrlObject.origin - https://s3.ap-south-1.amazonaws.com
            // proxiedVideoUrl - https://{sherlock_domain}/AWS_S3_Proxy/epifi-liveness/liveness_videos/{actor_id}/*
            const proxiedVideoUrl = videoUrlFromState.replace(videoUrlObject.origin, `${currentDomain}${AWS_S3_PROXY_PREFIX}`);
            return proxiedVideoUrl;
        }
        return videoUrlFromState;
    }

    useEffect(() => {
        dispatch(getMediaForUserRiskReviewAction({
            mediaInfo,
            setMediaSrc,
        }));
    }, [dispatch, mediaInfo, isFederalLoanLivenessReviewer]);

    useEffect(() => {
        if (setMediaSrcExt) { setMediaSrcExt(mediaSrc); }
        setProxiedMediaSrc(getProxiedUrl(mediaSrc, isFederalLoanLivenessReviewer));
    }, [mediaSrc, isFederalLoanLivenessReviewer]);

    if (isNilOrEmpty(mediaInfo)) return null;

    const {
        media_type: mediaType,
        base64_kyc_image_data: base64String,
    } = mediaInfo;

    return (
        <React.Fragment>
            {mediaType === MEDIA_TYPE_VIDEO && (
                <div className='urr-video__cr'>
                    <RenderVideo
                        videoSrc={proxiedMediaSrc}
                    />
                </div>
            )}

            {mediaType === MEDIA_TYPE_IMAGE && (
                <RenderDocument
                    index={0}
                    data={{
                        base64String,
                        srcUrl: mediaSrc,
                    }}
                    showNotFound
                />
            )}
        </React.Fragment>
    );
};

const getVideoUnit = (mediaInfo, setMediaSrcExt) => (
    <MediaUnit
        mediaInfo={mediaInfo}
        setMediaSrcExt={setMediaSrcExt}
    />
);

const getImageUnit = (mediaInfo) => (
    <div className='frccWrapper'>
        <MediaUnit
            key={mediaInfo.id}
            mediaInfo={mediaInfo}
        />
    </div>
);

const RenderImage = (props) => {
    const { imageInfo } = props;
    return (
        <div className='urr-tab-container'>
            {imageInfo.map((info) => (
                <div>
                    {info.title ? <div className='heading3 text-center pb-20'>{info.title}</div> : ''}
                    {getImageUnit(info)}
                </div>
            ))}
        </div>
    );
};

const RenderMedia = (props) => {
    const {
        mediaUnits, extClasses, setMediaSrcExt, onSelectMedia,
    } = props;
    const containerClass = classNames('frcseWrapper of-auto m-0-auto-40', extClasses && extClasses.container);

    const videoTabData = useMemo(() => {
        if (isNilOrEmpty(mediaUnits)) return [];

        const sectionMediaType = mediaUnits[0].media_type;

        // If current section is of image type, return empty array
        if (sectionMediaType === MEDIA_TYPE_IMAGE) return [];

        return mediaUnits.map((mediaUnitInfo, index) => ({
            ...mediaUnitInfo,
            label: mediaUnitInfo.label || `Video ${index + 1}`,
            component: getVideoUnit(mediaUnitInfo, setMediaSrcExt),
        }));
    }, [mediaUnits]);

    const imageTabData = useMemo(() => {
        if (isNilOrEmpty(mediaUnits)) return [];

        const sectionMediaType = mediaUnits[0].media_type;

        // If current section is not an image type, return empty array
        if (sectionMediaType !== MEDIA_TYPE_IMAGE) return [];

        return mediaUnits.map((mediaUnitInfo, index) => ({
            ...mediaUnitInfo,
            label: mediaUnitInfo.label || `Image ${index + 1}`,
            component: <RenderImage imageInfo={mediaUnitInfo.items} />,
        }));
    }, [mediaUnits]);

    if (isNilOrEmpty(mediaUnits)) return null;

    const sectionMediaType = mediaUnits[0].media_type;

    return (
        <div className={containerClass}>
            {sectionMediaType === MEDIA_TYPE_IMAGE && (
                <div className='urr-cr__image'>
                    <AppTabs
                        extClasses={{
                            tabListContainer: 'urr-tab-list',
                        }}
                        data={imageTabData}
                        defaultIndex={imageTabData.length - 1}
                        onSelectTab={onSelectMedia}
                    />
                </div>
            )}

            {sectionMediaType === MEDIA_TYPE_VIDEO && (
                <div className='urr-cr__vid text-center'>
                    <AppTabs
                        extClasses={{
                            tabListContainer: 'urr-tab-list',
                        }}
                        data={videoTabData}
                        defaultIndex={videoTabData.length - 1}
                        onSelectTab={onSelectMedia}
                    />
                </div>
            )}
        </div>
    );
};

export default memo(RenderMedia);

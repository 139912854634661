/**
 * @file CxRiskReview selectors
 */

import { createSelector } from 'reselect';
import { CX_RISK_REVIEW_KEY } from './constants';

export const selectCxRiskTickets = createSelector(
    (state) => state.getIn([CX_RISK_REVIEW_KEY, 'cxRiskTickets']),
    (subState) => subState.toJS(),
);

export const selectCxRiskLoader = createSelector(
    (state) => state.getIn([CX_RISK_REVIEW_KEY, 'loading']),
    (subState) => subState,
);

export const selectCxRiskErr = createSelector(
    (state) => state.getIn([CX_RISK_REVIEW_KEY, 'err']),
    (subState) => subState,
);

export const selectCxRiskCurView = createSelector(
    (state) => state.getIn([CX_RISK_REVIEW_KEY, 'curView']),
    (subState) => subState,
);

export const selectCxRiskCurTicketDetails = createSelector(
    (state) => state.getIn([CX_RISK_REVIEW_KEY, 'curTicketDetails']),
    (subState) => subState?.toJS(),
);

export const selectCxRiskRequestsList = (actorId) => createSelector(
    (state) => state.getIn([CX_RISK_REVIEW_KEY, 'requestsList']),
    (subState) => {
        const casesList = subState?.toJS()[actorId];

        if (!casesList) return null;

        const modifiedCasesList = [];

        casesList.forEach((item, index) => {
            const curRowObj = {
                sr_no: index + 1,
            };

            const { payload } = item;
            curRowObj.actor_id = item[payload].actor_id;
            curRowObj.request_id = item[payload].request_id || item[payload].liveness_request_id || item[payload].facematch_request_id;
            curRowObj.created_at = item[payload].created_at;
            curRowObj.ogData = item;

            modifiedCasesList.push(curRowObj);
        });

        return modifiedCasesList;
    },
);

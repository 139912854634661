/*
 *
 * CustomerDetails Actions
 *
 */

import {
    GET_CUSTOMER_DETAILS, SET_MONORAIL_ID_FOR_CUSTOMER_DETAILS,
} from './constants';

export const getCustomerDetailsAction = (data) => ({
    type: GET_CUSTOMER_DETAILS,
    data,
});

export const setMonorailIdForCustomerDetailsAction = (data) => ({
    type: SET_MONORAIL_ID_FOR_CUSTOMER_DETAILS,
    data,
});

/**
 * @file View all details for a particular dispute
 * The details are in the form of JSON and it is stringified and displayed to the user
 */

import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button } from '../../../components';

import { DEFAULT_STYLES_FOR_MODAL } from '../constants';

const customStylesForModal = {
    overlay: {
        ...DEFAULT_STYLES_FOR_MODAL.overlay,
    },
    content: {
        ...DEFAULT_STYLES_FOR_MODAL.content,
        width: 700,
        height: 700,
    },
};

const ViewDetails = (props) => {
    const {
        visible,
        toggleHandler,
        data,
        setData,
    } = props;

    const closeModal = () => {
        setData({});
        toggleHandler(false);
    };

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='Dispute Details'
        >
            <div className='heading3 mb-15'>
                View Dispute Details
            </div>

            <div className='disputes-modal-wrap'>
                {JSON.stringify(data, null, 4)}
            </div>

            <Button
                v2
                secondary
                extClasses='disputes-modal-cta'
                label='Close'
                onClick={closeModal}
            />
        </Modal>
    );
};

export default memo(ViewDetails);

/**
 * MarkAgainstTicketCheckbox - Checkbox to set the entity meta info for marking some data to a ticket
 */

import React, { memo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { equals } from 'ramda';

import { setActiveViewEntityMeta, setClaimsViewEntityMeta } from '../actions';
import { makeSelectActiveViewEntityMeta, makeSelectClaimsViewEntityMeta } from '../selectors';

const MarkAgainstTicketCheckbox = (props) => {
    const dispatch = useDispatch();

    // global state variables
    const activeViewEntityMeta = useSelector(makeSelectActiveViewEntityMeta(), shallowEqual);
    const claimsViewEntityMeta = useSelector(makeSelectClaimsViewEntityMeta(), shallowEqual);

    const {
        entityMeta, isActiveView, isClaimsView, onChange, tabIndex, disabled,
    } = props;

    let selectedEntityMeta;

    if (isActiveView) {
        selectedEntityMeta = activeViewEntityMeta;
    } else if (isClaimsView) {
        selectedEntityMeta = claimsViewEntityMeta;
    }

    const onChangeHandler = (e) => {
        if (equals(entityMeta, selectedEntityMeta) && onChange) {
            onChange(true, (e.nativeEvent).shiftKey);
        }
        if (isActiveView) {
            dispatch(setActiveViewEntityMeta({ meta: entityMeta }));
        } else if (isClaimsView) {
            dispatch(setClaimsViewEntityMeta({ meta: entityMeta }));
        }
    };

    return (
        <input
            disabled={disabled}
            tabIndex={tabIndex}
            type='checkbox'
            checked={equals(entityMeta, selectedEntityMeta)}
            onChange={onChangeHandler}
        />
    );
};

export default memo(MarkAgainstTicketCheckbox);

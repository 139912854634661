/**
 *
 * OffersView - Component to show the active offers for all users
 *
 */

import React, { useState } from 'react';

import { Button, TableV2 } from '../../../components';

import MarkAgainstTicketCheckbox from '../MarkAgainstTicketCheckbox';
import TncAndStepsToRedeemModal from '../Modals/TncAndStepsToRedeem';

import { ACTIVE_OFFERS_LABEL_DATA } from '../constants';

const OffersView = (props) => {
    // local state variables
    const [selectedOfferDetails, setSelectedOfferDetails] = useState({});
    const [showOfferDetailsModal, setOfferDetailsModal] = useState(false);

    const {
        data, prev, next, getPaginatedData, loading,
    } = props;

    const renderActions = (item) => (
        <div className='frccWrapper rewards-tblv2-col'>
            <Button
                link
                label='View TnC & Steps to Redeem'
                onClick={() => {
                    setSelectedOfferDetails(item);
                    setOfferDetailsModal(true);
                }}
            />
        </div>
    );

    const renderCheckboxColumn = (checkboxProps) => {
        const {
            checked: itemEntityMeta, onChange, tabIndex, disabled,
        } = checkboxProps;

        return (
            <MarkAgainstTicketCheckbox
                isActiveView
                entityMeta={itemEntityMeta}
                onChange={onChange}
                tabIndex={tabIndex}
                disabled={disabled}
            />
        );
    };

    return (
        <React.Fragment>
            <TableV2
                renderCheckbox
                columns={ACTIVE_OFFERS_LABEL_DATA}
                rows={data}
                rowClass={() => 'rewards-tblv2-row'}
                prevToken={prev}
                nextToken={next}
                paginatedClickHandler={getPaginatedData}
                renderActionableColumn={renderActions}
                loading={loading}
                renderers={{
                    renderCheckbox: renderCheckboxColumn,
                }}
                enableVirtualization={false}
                extClasses={{
                    container: 'rewards-src4',
                    content: 'rewards-tblv2',
                }}
            />
            <TncAndStepsToRedeemModal
                visible={showOfferDetailsModal}
                data={selectedOfferDetails}
                toggleHandler={setOfferDetailsModal}
            />
        </React.Fragment>
    );
};

export default OffersView;

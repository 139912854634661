import { call, put, takeLatest, all } from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';

import { clientApiWrapper, getErrLabel } from '../../utils';
import { AUTH_ROOT } from '../../api/routes';

import {
    LOGIN_WITH_EMAIL_PWD,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    UPDATE_AUTH_STATE,
    UPDATE_LOGIN_USER,
    LOGIN_START,
    UPDATE_NEW_PASSWORD,
    SIGN_IN_WITH_EMAIL,
    ACCEPTED_AUTH_CHALLENGES,
    VALIDATE_SMS_MFA,
    SEND_OTP_CODE_ON_EMAIL,
    SET_NEW_PASSWORD_WITH_OTP,
    SET_FORGOT_PASSWORD,
    UPDATE_FORGOT_PASSWORD_STATE,
} from './constants';

const resetUserInfo = {
    username: '',
    email: '',
    challengeParameters: {},
};

function* handleAdditionalChallenges(user, email) {
    // accepted challenge's flow
    const acceptedChallenge = ACCEPTED_AUTH_CHALLENGES.find(challenge => challenge.name === user.ChallengeName);
    if (acceptedChallenge) {
        yield put({ type: LOGIN_SUCCESS });
        yield put({ type: UPDATE_AUTH_STATE, data: {
            newAuthState: acceptedChallenge.value,
        }});
        yield put({ type: UPDATE_LOGIN_USER, data: {
            username: user.ChallengeParameters.USER_ID_FOR_SRP,
            email,
            challengeParameters: user.ChallengeParameters,
        }});
    } else {
        // if another challenge is encountered, throw error
        throw new Error('Unexpected error encountered. Contact support team.')
    }
}

function* handleApiError(err) {
    yield put({ type: LOGIN_ERROR, data: { err } });
    yield put({ type: UPDATE_AUTH_STATE, data: {
        newAuthState: SIGN_IN_WITH_EMAIL,
    }});
    yield put({ type: UPDATE_LOGIN_USER, data: resetUserInfo });
}

// email password saga
function* loginWithEmailPassword(action) {
    const { email, password } = action.data;

    try {
        const requestBody = {
            email: btoa(email),
            password: btoa(password),
        };
        yield put({ type: LOGIN_START });

        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${AUTH_ROOT}/login`,
            requestBody,
        );
        
        const { user } = response;
        
        // additional auth challenges needed
        if (user.hasOwnProperty('ChallengeName')) {
            yield* handleAdditionalChallenges(user, email);
            return;
        }

        // login successful, redirect to root page
        window.location.replace('/');
        // yield put(push('/')); // BUG: using push does not call ComponentDidMount() in App/index.js and hence the getAuthStatus() call does not happen. Hence the dropdown does not load.
    } catch (e) {
        const err = getErrLabel(e);
        yield put({ type: LOGIN_ERROR, data: { err } });
    }
}

// new password required saga
function* updateNewPassword(action) {
    const { username, password, email } = action.data;

    try {
        const requestBody = {
            username: btoa(username),
            password: btoa(password),
            email: btoa(email),
        }
        yield put({ type: LOGIN_START });

        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${AUTH_ROOT}/set-new-password`,
            requestBody,
        );
        const { user } = response;
        
        // additional auth challenges needed
        if (user.hasOwnProperty('ChallengeName')) {
            yield* handleAdditionalChallenges(user, email);
            return;
        }

        // login successful, redirect to root page
        window.location.replace('/');
        // yield put(push('/'));
    } catch (e) {
        const err = getErrLabel(e);
        yield* handleApiError(err);
    }
}

// 2fa sms otp saga
function* validateSmsOtpSaga(action) {
    const { username, otpCode, email } = action.data;

    try {
        const requestBody = {
            username: btoa(username),
            otpCode: btoa(otpCode),
        }
        yield put({ type: LOGIN_START });

        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${AUTH_ROOT}/verify-otp`,
            requestBody,
        );
        const { user } = response;

        // additional auth challenges needed
        if (user.hasOwnProperty('ChallengeName')) {
            yield* handleAdditionalChallenges(user, email);
            return;
        }

        // login successful, redirect to root page
        window.location.replace('/');
        // yield put(push('/'));
    } catch (e) {
        let err = getErrLabel(e);

        // incorrect otp
        if (err === 'Invalid code or auth state for the user.') {
            err = 'Incorrect otp code. Please try again.';
            yield put({ type: LOGIN_ERROR, data: { err } });
            return;
        }

        // other error
        yield* handleApiError(err);
    }
}

function* sendOtpCodeOnEmailSaga(action) {
    const { email } = action.data;

    try {
        const requestBody = {
            email: btoa(email),
        };

        yield put({ type: LOGIN_START });

        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${AUTH_ROOT}/reset-password-otp-code`,
            requestBody,
        );
        
        const { user } = response;
        if (user) {
            yield put({ type: LOGIN_SUCCESS });
            yield put({ type: UPDATE_LOGIN_USER, data: {
                email,
            }});
            yield put({ type: UPDATE_FORGOT_PASSWORD_STATE, data: {
                newForgotPwdState: SET_FORGOT_PASSWORD,
            }});
        }
    } catch (e) {
        const err = getErrLabel(e);
        yield put({ type: LOGIN_ERROR, data: { err } });
    }
}

function* setNewPasswordWithOtpCodeSaga(action) {
    const { email, otpCode, newPassword } = action.data;

    try {
        const requestBody = {
            email: btoa(email),
            otpCode: btoa(otpCode),
            newPassword: btoa(newPassword),
        };

        yield put({ type: LOGIN_START });

        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${AUTH_ROOT}/reset-password`,
            requestBody,
        );
        
        const { user } = response;
        if (user) {
            yield put({ type: LOGIN_SUCCESS });
            yield put({ type: UPDATE_AUTH_STATE, data: {
                newAuthState: SIGN_IN_WITH_EMAIL,
            }});
        }
    } catch (e) {
        const err = getErrLabel(e);
        yield put({ type: LOGIN_ERROR, data: { err } });
    }
}

export default function* loginSaga() {
    yield all(
        [
            yield takeLatest(LOGIN_WITH_EMAIL_PWD, loginWithEmailPassword),
            yield takeLatest(UPDATE_NEW_PASSWORD, updateNewPassword),
            yield takeLatest(VALIDATE_SMS_MFA, validateSmsOtpSaga),
            yield takeLatest(SEND_OTP_CODE_ON_EMAIL, sendOtpCodeOnEmailSaga),
            yield takeLatest(SET_NEW_PASSWORD_WITH_OTP, setNewPasswordWithOtpCodeSaga),
        ]
    );
}

/**
 * BizAdmin
 * Landing Page for Biz Admin users
 * All routes related to Biz Admin flow would be listed here
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { VerticalNavbar } from '../../components';
import {
    BIZ_ADMIN_USER_DETAILS_PATH, BIZ_ADMIN_BULK_USER_DETAILS_PATH,
} from '../App/routes';

import UserDetailsById from '../UserDetailsById';
import BulkUserDetailsByIds from '../BulkUserDetailsByIds';

import { getSideNavbarItems } from './utils';

import './style.scss';

const BizAdmin = () => {
    const renderRoutes = () => {
        return (
            <Switch>
                <Route
                    path={BIZ_ADMIN_USER_DETAILS_PATH}
                    component={UserDetailsById}
                />
                <Route
                    path={BIZ_ADMIN_BULK_USER_DETAILS_PATH}
                    component={BulkUserDetailsByIds}
                />
                {/* in case of unmatched url, redirect the user to Get User Details page */}
                <Redirect to={{ pathname: BIZ_ADMIN_USER_DETAILS_PATH }} />
            </Switch>
        );
    };

    return (
        <div className='bizad-cr'>
            <VerticalNavbar
                initialActiveNavItem='get-user-details'
                navItems={getSideNavbarItems()}
            />
            <div className='bizad-cc'>
                {renderRoutes()}
            </div>
        </div>
    );
};

export default BizAdmin;

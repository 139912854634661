import { createSelector } from 'reselect';
import { APP_KEY } from '../../containers/App/constants';

const getVkyc = (state) => state.get('vkyc');

const VkycKey = 'vkyc';

export const selectVkycData = createSelector(
    getVkyc,
    (substate) => substate?.toJS(),
);

export const selectVkycCalls = createSelector(
    (state) => getVkyc(state).getIn(['calls']),
    (substate) => substate?.toJS(),
);

export const selectMeetingId = createSelector(
    (state) => state.getIn([VkycKey, 'data', 'meetingId']),
    (substate) => substate,
);

export const selectVideoSdkConfig = createSelector(
    (state) => state.getIn([APP_KEY, 'metaInfo', 'videoSdkConfig']),
    (substate) => substate?.toJS(),
);

export const selectAudioMuteFlag = createSelector(
    (state) => state.getIn([VkycKey, 'muteAudioFlag']),
    (substate) => substate,
);

export const selectRejectedReasons = createSelector(
    (state) => state.getIn([VkycKey, 'rejectionReasons']),
    (substate) => substate,
);

export const selectVkycAvailableCallsCount = createSelector(
    (state) => state.getIn([VkycKey, 'availableCallCount']),
    (substate) => substate,
);

export const selectFlowProgress = createSelector(
    (state) => state.getIn([VkycKey, 'flow', 'progress']),
    (substate) => substate,
);

export const selectCurrentStage = (meetingId) => createSelector(
    (state) => state.getIn([VkycKey, 'meetings', meetingId, 'stepNumber']),
    (substate) => substate,
);

export const selectCurrentSubStage = (meetingId) => createSelector(
    (state) => state.getIn([VkycKey, 'meetings', meetingId, 'subStepNumber']),
    (substate) => substate,
);

export const selectFileLocalStoreStatus = createSelector(
    (state) => state.getIn([VkycKey, 'localFileStoredStatus']),
    (substate) => substate,
);

export const selectVKYCConfig = createSelector(
    (state) => state.getIn([APP_KEY, 'metaInfo', 'vkycConfig']),
    (substate) => substate?.toJS(),
);

/**
 *
 * UPI
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Button, DropdownContent, DropdownWrapper, Loader,
} from '../../../components';
import UserAuth from '../../Tickets/UserAuth';
import { SENSITIVE_ACTIONS } from '../../Tickets/constants';
import backArrow from '../../../assets/images/svgs/back-arrow.svg';

import { setUPIToggleConfirmScreen } from '../actions';
import UPIDetailsModal from './UPIDetailsModal';
import PINSetDetailsModal from './PINSetDetailsModal';

import './style.scss';

const UPI = (props) => {
    const dispatch = useDispatch();
    const [selectedUPI, setSelectedUPI] = useState({});
    const [openedActionsCardId, setOpenedActionsCardId] = useState('');
    const [showUPIDetailsModal, setUPIDetailsModal] = useState(false);
    const [showPINSetDetailsModal, setPINSetDetailsModal] = useState(false);
    const {
        index, ticketId, userId, loading, hasUPIInfo, getUPIInfo, toggleUPIStatus,
        isUPIConfirmScreen, upiInfo, err, pendingAuth, isChatFlow,
    } = props;

    let toggleLabel = '';

    if (hasUPIInfo && selectedUPI.enable) {
        toggleLabel = 'Enable UPI';
    } else if (hasUPIInfo && selectedUPI.disable) {
        toggleLabel = 'Disable UPI';
    }

    useEffect(() => {
        if (!hasUPIInfo) {
            getUPIInfo({ index, ticketId, userId });
        }
    }, []);

    const discardConfirmScreen = () => {
        dispatch(setUPIToggleConfirmScreen({ ticketId, flag: false }));
    };

    const toggleUPIHandle = (isConfirmAction, vpaHandle) => {
        toggleUPIStatus({
            index,
            ticketId,
            userId,
            isConfirmAction,
            vpaHandle: vpaHandle || selectedUPI.vpaHandle,
            piId: selectedUPI.piId,
        });
    };

    const renderSearchResults = () => {
        if (!hasUPIInfo) {
            return null;
        }

        if (upiInfo.length === 0) {
            return <div className='upi-no-search-results'>No UPI info found!</div>;
        }

        return (
            <div className='table-sr'>
                <div className='table-sr__thw'>
                    <div className='table-sr__th'>VPA Handle</div>
                    <div className='table-sr__th'>Source</div>
                    <div className='table-sr__th'>Status</div>
                    <div className='table-sr__th'>Bank Name</div>
                    <div className='table-sr__th'>Account Type</div>
                    <div className='table-sr__th'>Updated At</div>
                    <div className='table-sr__th'>Action</div>
                    <div className='table-sr__th table-sr__th--actions' />
                </div>
                <div className='table-sr__tlc'>
                    {
                        upiInfo.map((item) => {
                            const disabledToggleUPIHandle = isChatFlow || (hasUPIInfo && !item.disable);

                            return (
                                <div className='table-sr__tlw' key={item.id}>
                                    <div className='table-sr__tl table-sr__tl--lc'>{item.vpaHandle}</div>
                                    <div className='table-sr__tl'>{item.source}</div>
                                    <div className='table-sr__tl'>{item.status}</div>
                                    <div className='table-sr__tl'>{item.bankName}</div>
                                    <div className='table-sr__tl'>{item.accountType}</div>
                                    <div className='table-sr__tl'>{item.updatedTs}</div>
                                    <div
                                        className={`table-sr__tl link ${disabledToggleUPIHandle ? 'link--disabled' : ''}`}
                                        onClick={() => {
                                            if (!disabledToggleUPIHandle) {
                                                setSelectedUPI(item);
                                                toggleUPIHandle(false, item.vpaHandle);
                                            }
                                        }}
                                        role='presentation'
                                    >
                                        Disable UPI
                                    </div>
                                    <div className='table-sr__tl table-sr__tl--actions'>
                                        <DropdownWrapper
                                            visible={openedActionsCardId === item.id}
                                            onOutsideClickHandler={() => setOpenedActionsCardId('')}
                                            extClasses={{
                                                container: 'upi-ad',
                                            }}
                                        >
                                            <div
                                                className='upi-ad-label'
                                                onClick={() => {
                                                    setOpenedActionsCardId(item.id);
                                                    setSelectedUPI(item);
                                                }}
                                                role='presentation'
                                            >
                                                ...
                                            </div>
                                            <DropdownContent
                                                visible
                                                extClasses={{
                                                    container: [
                                                        'upi-ad-cc height-transition',
                                                        openedActionsCardId === item.id && 'upi-ad-cc--active',
                                                    ],
                                                }}
                                            >
                                                <div className='upi-ad-cc-wr'>
                                                    <div
                                                        className='upi-ad-cc-wr__label'
                                                        onClick={() => {
                                                            setUPIDetailsModal(true);
                                                        }}
                                                        role='presentation'
                                                    >
                                                        View UPI Details
                                                    </div>
                                                    <div
                                                        className='upi-ad-cc-wr__label'
                                                        onClick={() => {
                                                            setPINSetDetailsModal(true);
                                                        }}
                                                        role='presentation'
                                                    >
                                                        View PIN Set Details
                                                    </div>
                                                </div>
                                            </DropdownContent>
                                        </DropdownWrapper>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    };

    if (isUPIConfirmScreen) {
        return (
            <React.Fragment>
                <div className='upi-container'>
                    <div className='upi-cc__heading-wrapper'>
                        <img className='upi-backarrow' src={backArrow} onClick={discardConfirmScreen} alt='' role='presentation' />
                        <div className='upi-cc__heading'>{toggleLabel}</div>
                    </div>
                    <div className='upi-cc__subheading'>
                        Are you sure you want to {toggleLabel} handle {selectedUPI.vpaHandle}?
                    </div>
                    <div className='upi-cc__ctas-wrapper'>
                        <Button
                            primary
                            label={`Confirm ${toggleLabel}`}
                            extClasses='upi-cc__ctas'
                            onClick={() => toggleUPIHandle(true)}
                        />
                        <Button
                            secondary
                            label='Discard'
                            extClasses='upi-cc__ctas'
                            onClick={discardConfirmScreen}
                        />
                    </div>
                </div>
                <Loader visible={loading && loading.toggleUpi} />
            </React.Fragment>
        );
    }

    if (pendingAuth[SENSITIVE_ACTIONS.TOGGLEUPI]) {
        return (
            <UserAuth
                index={index}
                label={toggleLabel}
                type={SENSITIVE_ACTIONS.TOGGLEUPI}
                nextAction={() => toggleUPIHandle(false)}
            />
        );
    }

    return (
        <React.Fragment>
            <div className='upi-container'>
                <div className='upi-heading'>UPI</div>
                {renderSearchResults()}
                {
                    err && err.upiInfo ? (
                        <div className='container-err-label'>{err.upiInfo}</div>
                    ) : null
                }
            </div>
            <UPIDetailsModal
                visible={showUPIDetailsModal}
                data={selectedUPI}
                toggleHandler={setUPIDetailsModal}
            />
            <PINSetDetailsModal
                visible={showPINSetDetailsModal}
                data={selectedUPI}
                toggleHandler={setPINSetDetailsModal}
            />
            <Loader visible={loading && (loading.upiInfo || loading.toggleUpi)} />
        </React.Fragment>
    );
};

export default memo(UPI);

/*
 *
 * PayoutRequests constants
 *
 */

// access to admin, & super-admin
export const GET_RAISED_PAYOUT_REQUESTS = 'app/PayoutRequests/GET_RAISED_PAYOUT_REQUESTS';
export const SET_RAISED_PAYOUT_REQUESTS = 'app/PayoutRequests/SET_RAISED_PAYOUT_REQUESTS';
export const ERR_RAISED_PAYOUT_REQUESTS = 'app/PayoutRequests/ERR_RAISED_PAYOUT_REQUESTS';

// access to super-admin
export const GET_ALL_PAYOUT_REQUESTS = 'app/PayoutRequests/GET_ALL_PAYOUT_REQUESTS';
export const SET_ALL_PAYOUT_REQUESTS = 'app/PayoutRequests/SET_ALL_PAYOUT_REQUESTS';
export const ERR_ALL_PAYOUT_REQUESTS = 'app/PayoutRequests/ERR_ALL_PAYOUT_REQUESTS';

// access to super-admin
export const UPDATE_PAYOUT_REQUEST = 'app/PayoutRequests/UPDATE_PAYOUT_REQUEST';
export const SET_UPDATE_PAYOUT_REQUEST = 'app/PayoutRequests/ERR_UPDATE_PAYOUT_REQUEST';

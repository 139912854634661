/*
 *
 * @file Actions for US Stocks agent view.
 *
 */

import {
    GET_ACCOUNT_BASIC_DETAILS, GET_ACCOUNT_STAGES_DETAILS, GET_ACTIVITIES, GET_ACTIVITY_DETAILS, GET_ACTIVITY_TYPE_LIST,
    GET_PORTFOLIO_DETAILS, GET_REMITTANCES, GET_REMITTANCE_ORDER_DETAILS, GET_REMITTANCE_TYPE_LIST, RESET_ACTIVITIES,
    RESET_REMITTANCES, RESET_SEARCH_PARAMS, SET_ACCOUNT_VIEW, SET_ACTIVITY_TYPE, SET_SEARCH_PARAMS, SET_USSTOCKS_VIEW,
} from './constants';

export const getPortfolioDetailsAction = (data) => ({
    type: GET_PORTFOLIO_DETAILS,
    data,
});

export const getActivitiesAction = (data) => ({
    type: GET_ACTIVITIES,
    data,
});

export const getActivityDetailsAction = (data) => ({
    type: GET_ACTIVITY_DETAILS,
    data,
});

export const getAccountBasicDetailsAction = (data) => ({
    type: GET_ACCOUNT_BASIC_DETAILS,
    data,
});

export const getAccountStagesDetailsAction = (data) => ({
    type: GET_ACCOUNT_STAGES_DETAILS,
    data,
});

export const setSearchParamsAction = (data) => ({
    type: SET_SEARCH_PARAMS,
    data,
});

export const resetSearchParamsAction = (data) => ({
    type: RESET_SEARCH_PARAMS,
    data,
});

export const setUsStocksViewAction = (data) => ({
    type: SET_USSTOCKS_VIEW,
    data,
});

export const setAccountViewAction = (data) => ({
    type: SET_ACCOUNT_VIEW,
    data,
});

export const getActivityTypeListAction = (data) => ({
    type: GET_ACTIVITY_TYPE_LIST,
    data,
});

export const getRemittanceTypeListAction = (data) => ({
    type: GET_REMITTANCE_TYPE_LIST,
    data,
});

export const setActivityTypeAction = (data) => ({
    type: SET_ACTIVITY_TYPE,
    data,
});

export const resetActivitiesAction = (data) => ({
    type: RESET_ACTIVITIES,
    data,
});

export const getRemittances = (data) => ({
    type: GET_REMITTANCES,
    data,
});

export const resetRemittances = (data) => ({
    type: RESET_REMITTANCES,
    data,
});

export const getRemittanceOrderDetails = (data) => ({
    type: GET_REMITTANCE_ORDER_DETAILS,
    data,
});

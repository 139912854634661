/**
 * @file BackArrow
 * Component to render back arrow
 */

import React, { memo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames';

import backArrowIcon from '../../assets/images/svgs/back-arrow.svg';
import whiteBackArrowIcon from '../../assets/images/svgs/white-back-arrow.svg';

import './style.scss';

//  BackArrow Icon Variant List
const backArrowIconVariantList = {
    black: backArrowIcon,
    white: whiteBackArrowIcon,
};

const BackArrow = (props) => {
    const { handleClick, extClasses, variant = 'black' } = props;

    const containerClass = classNames('barw-cr', extClasses && extClasses.container);
    const imageClass = classNames('barw-img', extClasses && extClasses.image);

    return (
        <button className={containerClass} type='button' onClick={handleClick}>
            <img className={imageClass} alt='back-arrow' src={backArrowIconVariantList[variant]} />
        </button>
    );
};

export default memo(BackArrow);

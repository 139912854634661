/*
 *
 * CustomerDetails reducer
 *
 */

import { fromJS } from 'immutable';

import {
    GET_CUSTOMER_DETAILS, SET_CUSTOMER_DETAILS, ERR_CUSTOMER_DETAILS, SET_MONORAIL_ID_FOR_CUSTOMER_DETAILS,
} from './constants';

const initialState = fromJS({
    monorailInfo: {
        id: '',
    },
    loading: false,
    err: '',
    customerDetails: '',
});

function customerDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CUSTOMER_DETAILS:
            return state
                .set('loading', true)
                .set('err', '')
                .set('customerDetails', '');

        case SET_CUSTOMER_DETAILS: {
            return state
                .set('loading', false)
                .set('customerDetails', action.data);
        }

        case ERR_CUSTOMER_DETAILS: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err);
        }

        case SET_MONORAIL_ID_FOR_CUSTOMER_DETAILS: {
            const { value } = action.data;

            if (value) {
                return state.setIn(['monorailInfo', 'id'], value);
            }

            return initialState;
        }

        default:
            return state;
    }
}

export default customerDetailsReducer;

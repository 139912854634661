import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { REWARDS_ROOT } from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_ACTIVE_REWARDS, SET_ACTIVE_REWARDS, ERR_ACTIVE_REWARDS, GET_ACTIVE_OFFERS,
    SET_ACTIVE_OFFERS, ERR_ACTIVE_OFFERS, GET_USER_REWARDS, SET_USER_REWARDS,
    ERR_USER_REWARDS, GET_USER_REDEEMED_OFFERS, SET_USER_REDEEMED_OFFERS,
    ERR_USER_REDEEMED_OFFERS, GET_USER_REWARD_INFO, SET_USER_REWARD_INFO, ERR_USER_REWARD_INFO,
    GET_ACTIVE_EXCHANGER_OFFERS, SET_ACTIVE_EXCHANGER_OFFERS, ERR_ACTIVE_EXCHANGER_OFFERS,
    GET_USER_REDEEMED_EXCHANGER_OFFERS, SET_USER_REDEEMED_EXCHANGER_OFFERS, ERR_USER_REDEEMED_EXCHANGER_OFFERS,
    GET_USER_TOTAL_REWARDS, SET_USER_TOTAL_REWARDS, ERR_USER_TOTAL_REWARDS, SET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS,
    ERR_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS, GET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS, SET_ELIGIBLE_REWARDS, ERR_ELIGIBLE_REWARDS, GET_ELIGIBLE_REWARDS,
    GET_FI_STORE_REWARDS, GET_FI_STORE_REWARD_DETAILS,
} from './constants';
import {
    setFiStoreRewards,
    errFiStoreRewards,
    setFiStoreRewardAdditionalDetails,
    errFiStoreRewardAdditionalDetails,
} from './actions';

function* getActiveRewards(action) {
    const {
        ticketId, userId, fromDate, toDate, index, rewardsApplicableToUser,
    } = action.data;
    const queryString = getQueryStringFromObject({
        ticketId, userId, rewardsApplicableToUser, fromDate, toDate,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REWARDS_ROOT}/active-rewards?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_ACTIVE_REWARDS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_ACTIVE_REWARDS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ACTIVE_REWARDS, data: { ticketId, err: e.message } });
    }
}

function* getActiveOffers(action) {
    const { ticketId, index } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REWARDS_ROOT}/active-offers?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_ACTIVE_OFFERS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_ACTIVE_OFFERS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ACTIVE_OFFERS, data: { ticketId, err: e.message } });
    }
}

function* getUserRewards(action) {
    const { ticketId, index } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REWARDS_ROOT}/user-rewards?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_USER_REWARDS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_USER_REWARDS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_REWARDS, data: { ticketId, err: e.message } });
    }
}

function* getUserRedeemedOffers(action) {
    const { ticketId, index } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REWARDS_ROOT}/user-offers?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_USER_REDEEMED_OFFERS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_USER_REDEEMED_OFFERS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_REDEEMED_OFFERS, data: { ticketId, err: e.message } });
    }
}

function* getTotalRewardsFromOfferId(action) {
    const { ticketId, index } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REWARDS_ROOT}/total-rewards?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_USER_TOTAL_REWARDS, data: { ticketId, totalRewardsList: response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_USER_TOTAL_REWARDS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_TOTAL_REWARDS, data: { ticketId, err: e.message } });
    }
}

function* getUserRewardInfo(action) {
    const {
        ticketId, userId, index, rewardOfferId,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId, rewardOfferId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REWARDS_ROOT}/user-reward-info?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_USER_REWARD_INFO, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_USER_REWARD_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_REWARD_INFO, data: { ticketId, err: e.message } });
    }
}

function* getActiveExchangerOffers(action) {
    const {
        ticketId, userId, index, fromDate, toDate,
    } = action.data;
    const queryString = getQueryStringFromObject({
        ticketId, userId, fromDate, toDate,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REWARDS_ROOT}/active-exchanger-offers?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_ACTIVE_EXCHANGER_OFFERS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_ACTIVE_EXCHANGER_OFFERS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ACTIVE_EXCHANGER_OFFERS, data: { ticketId, err: e.message } });
    }
}

function* getUserRedeemedExchangerOffers(action) {
    const { ticketId, index } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REWARDS_ROOT}/user-exchanger-offers?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_USER_REDEEMED_EXCHANGER_OFFERS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_USER_REDEEMED_EXCHANGER_OFFERS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_REDEEMED_EXCHANGER_OFFERS, data: { ticketId, err: e.message } });
    }
}

function* getEligibleRewardsDropdownOptions(action) {
    const { ticketId, index } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REWARDS_ROOT}/eligible-rewards-dropdown-options?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS, data: { ticketId, err: e.message } });
    }
}

function* getEligibleRewards(action) {
    const { ticketId, index } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REWARDS_ROOT}/eligible-rewards?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_ELIGIBLE_REWARDS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_ELIGIBLE_REWARDS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ELIGIBLE_REWARDS, data: { ticketId, err: e.message } });
    }
}

function* getFiStoreRewardsList(action) {
    const {
        ticketId, index, from, to, orderId, prev, next,
    } = action.data;
    const queryString = getQueryStringFromObject({
        ticketId, from, to, orderId, prev, next,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REWARDS_ROOT}/fi-store-redeemed?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put(setFiStoreRewards({ ticketId, ...response }));
        } else if (result === 'auth-expired') {
            yield put(errFiStoreRewards({ ticketId }));
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errFiStoreRewards({ ticketId, err: e.message }));
    }
}

function* getFiStoreRewardAdditionalDetails(action) {
    const {
        ticketId, index, meta, resolve,
    } = action.data;
    const queryString = getQueryStringFromObject({
        ticketId, meta,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REWARDS_ROOT}/fi-store-redeemed-details?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put(setFiStoreRewardAdditionalDetails({ ticketId, ...response }));
            if (resolve) yield call(resolve);
        } else if (result === 'auth-expired') {
            yield put(errFiStoreRewardAdditionalDetails({ ticketId }));
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errFiStoreRewardAdditionalDetails({ ticketId, err: e.message }));
    }
}

export default function* rewardsSaga() {
    yield all(
        [
            yield takeLatest(GET_ACTIVE_REWARDS, getActiveRewards),
            yield takeLatest(GET_ACTIVE_OFFERS, getActiveOffers),
            yield takeLatest(GET_USER_REWARDS, getUserRewards),
            yield takeLatest(GET_USER_REDEEMED_OFFERS, getUserRedeemedOffers),
            yield takeLatest(GET_USER_TOTAL_REWARDS, getTotalRewardsFromOfferId),
            yield takeLatest(GET_USER_REWARD_INFO, getUserRewardInfo),
            yield takeLatest(GET_ACTIVE_EXCHANGER_OFFERS, getActiveExchangerOffers),
            yield takeLatest(GET_USER_REDEEMED_EXCHANGER_OFFERS, getUserRedeemedExchangerOffers),
            yield takeLatest(GET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS, getEligibleRewardsDropdownOptions),
            yield takeLatest(GET_ELIGIBLE_REWARDS, getEligibleRewards),
            yield takeLatest(GET_FI_STORE_REWARDS, getFiStoreRewardsList),
            yield takeLatest(GET_FI_STORE_REWARD_DETAILS, getFiStoreRewardAdditionalDetails),
        ],
    );
}

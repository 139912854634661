/**
 * @file CaseInfo
 * Displays the facematch images and dev actions for it
 */

import React, {
    memo, useContext, useEffect, useState, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';

import { Button, DisableContextMenu } from '../../../components';
import { downloadFile, isObjEmpty } from '../../../utils';
import { MetaInfoContext, UserDetailsContext } from '../../../context';
import { useMemoizedSelector } from '../../../hooks';

import DevActions from '../../DevActions';
import { CustomerDetailsModal, DequeueCaseModal, LivenessSummaryModal, ViewAnnotationDetails } from '../../RiskOps';
import {
    selectRiskOpsCurCase, selectRiskOpsKycImg, selectRiskOpsPayloadType,
    selectRiskOpsQueueFilters, selectRiskOpsVideoFrameImg,
} from '../../RiskOps/selectors';
import {
    auditLogMediaDownloadAction, dequeueRiskOpsCaseAction, getRiskOpsBase64ImageAction, setRiskOpsBase64ImageAction,
} from '../../RiskOps/actions';
import { getAdditionalFilters, getDataFromFacematchCase, getPreFilledValuesObj } from '../../RiskOps/utils';

import {
    DEV_ACTION_TYPES, PAYLOAD_TYPE_L2_FACEMATCH, PAYLOAD_TYPE_PRE_APPROVED_LOAN_FACEMATCH,
    DEV_ACTIONS_FOR_FACEMATCH_PRE_APPROVED_LOAN,
} from '../constants';

const CaseInfo = (props) => {
    const { containerKey, dequeueOnSubmit = true } = props;

    const dispatch = useDispatch();

    const metaInfo = useContext(MetaInfoContext);
    const { basicInfo } = useContext(UserDetailsContext);
    const { emailId } = basicInfo;

    const payloadType = useMemoizedSelector(selectRiskOpsPayloadType, [containerKey]);
    const videoFrameImg = useMemoizedSelector(selectRiskOpsVideoFrameImg, [containerKey]);
    const kycImg = useMemoizedSelector(selectRiskOpsKycImg, [containerKey]);
    const { fromDate, toDate, sortOption } = useMemoizedSelector(selectRiskOpsQueueFilters, [containerKey]);
    const curCase = useMemoizedSelector(selectRiskOpsCurCase, [containerKey]);
    const { actorId, requestId, recordId } = getDataFromFacematchCase(curCase);

    // local state
    const [showDequeueCaseModal, setDequeueCaseModalVisibility] = useState(false);
    const [preFilledValues, setPreFilledValues] = useState(null);
    const [showLivenessSummaryModal, setLivenessSummaryModalVisibility] = useState(false);
    const [showCustomerDetailsModal, setCustomerDetailsModalVisibility] = useState(false);

    useEffect(() => {
        if (!isObjEmpty(curCase)) {
            const { videoFrameImgPath, kycImgPath, base64KycImg } = getDataFromFacematchCase(curCase);
            dispatch(getRiskOpsBase64ImageAction({ containerKey, imageInfo: { imageType: 'videoFrameImg', imagePath: videoFrameImgPath } }));

            if (base64KycImg) { // if base64 string is sent in the queue elements response use that directly
                dispatch(setRiskOpsBase64ImageAction({ containerKey, imageInfo: { imageType: 'kycImg', imageSrc: base64KycImg } }));
            } else { // else fetch kyc image using the kyc path
                dispatch(getRiskOpsBase64ImageAction({ containerKey, imageInfo: { imageType: 'kycImg', imagePath: kycImgPath } }));
            }

            setPreFilledValues(getPreFilledValuesObj({ actorId, requestId, emailId }));
        }
    }, [dispatch, curCase.id]);

    const getFacematchReviewDevActionType = useMemo(() => {
        if (payloadType.value === PAYLOAD_TYPE_PRE_APPROVED_LOAN_FACEMATCH) {
            return DEV_ACTIONS_FOR_FACEMATCH_PRE_APPROVED_LOAN;
        }
        return DEV_ACTION_TYPES;
    }, [payloadType.value]);

    const {
        riskOps: { downloadMedia: { allowDownloadEvenIfTrackingApiFails, allowedEmails } }, componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;
    const { index } = curCase;

    const isL2Queue = payloadType.value === PAYLOAD_TYPE_L2_FACEMATCH;
    const annotationCaseDetails = {
        isL2Queue,
        queryParams: {
            actorId,
            requestId,
            elementId: recordId,
            reReviewedBy: emailId,
            payloadType: payloadType.value,
        },
        getCasesQueryParams: {
            payloadType: payloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        },
        containerKey,
        index,
    };

    // if no facematch case is set, return null
    if (isObjEmpty(curCase)) {
        return null;
    }

    const dequeueAction = dequeueRiskOpsCaseAction({
        containerKey,
        actorId,
        recordId,
        index,
        queryParams: {
            payloadType: payloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        },
        customContext: {
            bannersVersion,
        },
    });

    const extraActions = dequeueOnSubmit ? [dequeueAction] : [];

    const handleDequeueCase = () => {
        dispatch(dequeueAction);
        setDequeueCaseModalVisibility(false);
    };

    const downloadFiles = () => {
        downloadFile(`video-frame-image_${actorId}`, videoFrameImg);
        downloadFile(`kyc-image_${actorId}`, kycImg);
    };

    const downloadFacematchImages = () => {
        const callAuditMedialDownloadApi = () => new Promise((resolve, reject) => {
            dispatch(auditLogMediaDownloadAction({
                requestBody: {
                    elementId: recordId,
                    actorId,
                    agentName: emailId,
                    videoFrameLocation: videoFrameImg,
                    kycImageLocation: kycImg,
                    payloadType: payloadType.value,
                },
                containerKey,
                resolve,
                reject,
            }));
        });

        callAuditMedialDownloadApi().then(() => {
            downloadFiles();
        }).catch(() => {
            if (allowDownloadEvenIfTrackingApiFails) {
                downloadFiles();
            }
        });
    };

    return (
        <React.Fragment>
            <DisableContextMenu>
                <div className='fmreviewV2-img__cr'>
                    <div className='fmreviewV2-img__itm1'>
                        <div className='fmreviewV2-hl1'>Video Frame Image</div>
                        {videoFrameImg && (
                            <img
                                src={videoFrameImg}
                                height='400'
                                alt='video-frame'
                            />
                        )}
                    </div>
                    <div className='fmreviewV2-img__itm2'>
                        <div className='fmreviewV2-hl1'>KYC Image</div>
                        {kycImg && <img src={kycImg} height='400' alt='kyc' />}
                    </div>
                </div>
            </DisableContextMenu>

            <div className='frwpWrapper'>
                <Button
                    v2
                    secondary
                    label='View Liveness Summary Details'
                    onClick={() => setLivenessSummaryModalVisibility(true)}
                />
                <Button
                    v2
                    secondary
                    label='View Customer Details'
                    extClasses='ml-30'
                    onClick={() => setCustomerDetailsModalVisibility(true)}
                />
                {allowedEmails.includes(emailId) && (
                    <Button
                        v2
                        primary
                        label='Download Images'
                        extClasses='ml-30'
                        onClick={downloadFacematchImages}
                    />
                )}
            </div>

            <ViewAnnotationDetails
                caseDetails={annotationCaseDetails}
            />

            {/* if not L2 queue, only then show dev actions */}
            {!isL2Queue && preFilledValues && (
                <DevActions
                    containerKey='facematch-v2'
                    actionTypes={getFacematchReviewDevActionType}
                    preFilledValues={preFilledValues}
                    extraActions={extraActions}
                    autoActionSelection={false}
                />
            )}

            <DequeueCaseModal
                visible={showDequeueCaseModal}
                submitCta={handleDequeueCase}
                closeCta={setDequeueCaseModalVisibility}
            />

            <LivenessSummaryModal
                visible={showLivenessSummaryModal}
                actorId={actorId}
                closeCta={setLivenessSummaryModalVisibility}
            />

            <CustomerDetailsModal
                visible={showCustomerDetailsModal}
                actorId={actorId}
                closeCta={setCustomerDetailsModalVisibility}
            />
        </React.Fragment>
    );
};

export default memo(CaseInfo);

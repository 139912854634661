/**
 *
 * All static sagas to be loaded on initial web page load should be added here
 *
 */

import {
    all,
    spawn,
} from 'redux-saga/effects';

// Example usage commented out
import riskOpsSaga from './containers/RiskOps/saga';
import devActionsSaga from './containers/DevActions/saga';
import userRiskReview from './containers/UserRiskReview/saga';
import transactionQueueSaga from './containers/TransactionQueue/saga';
import logsInfoSaga from './containers/LogsInfo/saga';
import caseSearchSaga from './containers/CaseSearch/saga';

// watcher saga
function* rootSaga() {
    yield all([
        spawn(riskOpsSaga),
        spawn(devActionsSaga),
        spawn(userRiskReview),
        spawn(transactionQueueSaga),
        spawn(logsInfoSaga),
        spawn(caseSearchSaga),
    ]);
}

export default rootSaga;

import { createSelector } from 'reselect';

const selectWaitlistDetails = state => state.get('waitlistDetails');

const makeSelectLoading = () => createSelector(
    selectWaitlistDetails,
    substate => substate.get('loading')
);

const makeSelectWaitlistUserInfo = () => createSelector(
    selectWaitlistDetails,
    substate => substate.get('waitlistUserInfo').toJS()
);

const makeSelectHasWaitlistUserInfo = () => createSelector(
    selectWaitlistDetails,
    substate => substate.get('hasWaitlistUserInfo')
);

export {
    makeSelectLoading,
    makeSelectWaitlistUserInfo,
    makeSelectHasWaitlistUserInfo,
};

/* eslint-disable camelcase */
/**
 * @file Constants for risk rule container
 */

// action & reducer constants
export const RISK_RULE_KEY = 'riskRule';

// reducer constants
export const REDUCER_KEYS = {
    RULE_FILE_ENTRIES: 'fileEntries',
    RULE_LOADING: 'loading',
    RULE_ERROR: 'err',
    TAGS: 'tags',
};

export const GET_RULE_QUEUE = `app/${RISK_RULE_KEY}/GET_RULE_QUEUE`;
export const SET_RULE_QUEUE = `app/${RISK_RULE_KEY}/SET_RULE_QUEUE`;
export const ERR_RULE_QUEUE = `app/${RISK_RULE_KEY}/ERR_RULE_QUEUE`;

export const CREATE_RULE = `app/${RISK_RULE_KEY}/CREATE_RULE`;
export const SET_CREATE_RULE = `app/${RISK_RULE_KEY}/SET_CREATE_RULE`;
export const ERR_CREATE_RULE = `app/${RISK_RULE_KEY}/ERR_CREATE_RULE`;

export const UPADATE_RULE = `app/${RISK_RULE_KEY}/UPADATE_RULE`;
export const SET_UPADATE_RULE = `app/${RISK_RULE_KEY}/SET_UPADATE_RULE`;
export const ERR_UPADATE_RULE = `app/${RISK_RULE_KEY}/ERR_UPADATE_RULE`;
export const GET_TAGS = `app/${RISK_RULE_KEY}/GET_TAGS`;
export const SET_TAGS = `app/${RISK_RULE_KEY}/SET_TAGS`;

export const RESET_RULE = `app/${RISK_RULE_KEY}/RESET_RULE`;

// UI constants
export const PARAMETER_FILTERS_CONFIG = {
    cardTitle: 'Filters',
    paramsTitle: 'Fields',
    defaultIsExpanded: false,
    collapseOnSubmit: true,
    warningModal: {
        required: false,
        message: 'Are you sure you want to perform this action?',
        description: 'This change is irreversible and affects production data',
    },
};

export const RULE_FORM_PARAMETER_FILTERS_CONFIG = {
    cardTitle: 'Rule Information',
    paramsTitle: 'Fields',
    defaultIsExpanded: true,
    isCollapsible: false,
    collapseOnSubmit: false,
    warningModal: {
        required: false,
        message: 'Are you sure you want to perform this action?',
        description: 'This change is irreversible and affects production data',
    },
};

// Default filter for risk rule
export const DEFAULT_FILTER_DATA = {
    parameterList: [
        {
            label: 'Filter By',
            name: 'filter_by',
            type: 5,
            options: ['RULE_FIELD_MASK_EXTERNAL_ID', 'RULE_FIELD_MASK_NAME', 'RULE_FIELD_MASK_RULE_GROUP'],
            isOptional: false,
        },
        {
            label: 'Name',
            name: 'rule_name',
            type: 1,
            isOptional: true,
        },
        {
            label: 'External Id',
            name: 'external_id',
            type: 1,
            isOptional: true,
        },
        {
            label: 'Rule Group',
            name: 'rule_group',
            type: 5,
            options: ['RULE_GROUP_CRYPTO', 'RULE_GROUP_ATM', 'RULE_GROUP_AML'],
            isOptional: true,
        },
    ],
};

export const RULE_LABEL_DATA = [
    {
        id: 1,
        label: 'ID',
        key: 'id',
    },
    {
        id: 2,
        label: 'Name',
        key: 'name',
    },
    {
        id: 3,
        label: 'Description',
        key: 'description',
    },
    {
        id: 4,
        label: 'Evaluation Method',
        key: 'evaluation_method',
    },
    {
        id: 5,
        label: 'Provenance',
        key: 'provenance',
    },
    {
        id: 6,
        label: 'State',
        key: 'state',
    },
    {
        id: 8,
        label: 'External Id',
        key: 'external_id',
    },
    {
        id: 9,
        label: 'Assessed Entity',
        key: 'assessed_entity_type',
    },
    {
        id: 10,
        label: 'Txn Suspect Entity',
        key: 'txn_suspect_entity',
    },
    {
        id: 11,
        label: 'Rule Group',
        key: 'rule_group',
    },
    {
        id: 12,
        label: 'Seed Precision',
        key: 'seed_precision',
    },
    {
        id: 13,
        label: 'Force Use Seed Precision',
        key: 'force_use_seed_precision',
    },
];

export const getRuleForm = ({
    name,
    description,
    provenance,
    state,
    evaluation_method,
    external_id,
    txn_suspect_entity,
    assessed_entity_type,
    rule_group,
    seed_precision,
    force_use_seed_precision,
    tags,
    serverDriverOptionsMap = {},
} = {}, isDisabled = false) => ({
    parameterList: [
        {
            label: 'Name',
            name: 'name',
            type: 1,
            value: name,
            isOptional: false,
            isDisabled,
        },
        {
            label: 'Description',
            name: 'description',
            value: description,
            type: 1,
            isOptional: false,
        },
        {
            label: 'Evaluation Method',
            name: 'evaluation_method',
            value: evaluation_method,
            type: 5,
            options: serverDriverOptionsMap?.EvaluationMethod
            || ['EVALUATION_METHOD_UNSPECIFIED', 'EVALUATION_METHOD_CONDITIONAL', 'EVALUATION_METHOD_HEURISTIC'],
            isOptional: false,
            isDisabled,
            className: 'risk-rule-from-field-type-5',
        },
        {
            label: 'Provenance',
            name: 'provenance',
            value: provenance,
            type: 5,
            options: serverDriverOptionsMap?.Provenance
            || ['PROVENANCE_UNSPECIFIED',
                'PROVENANCE_DRONAPAY',
                'PROVENANCE_DS_RISK_MODEL',
                'PROVENANCE_SNOWFLAKE_RULES',
                'PROVENANCE_LEA',
                'PROVENANCE_RISK_SCREENER'],
            isOptional: false,
            isDisabled,
            className: 'risk-rule-from-field-type-5',
        },
        {
            label: 'State',
            name: 'state',
            value: state,
            type: 5,
            options: serverDriverOptionsMap?.RuleState
            || ['RULE_STATE_UNSPECIFIED', 'RULE_STATE_INACTIVE', 'RULE_STATE_IN_REVIEW', 'RULE_STATE_ACTIVE', 'RULE_STATE_SHADOW'],
            isOptional: false,
            className: 'risk-rule-from-field-type-5',
        },
        {
            label: 'External Id',
            name: 'external_id',
            value: `${external_id || ''}`,
            type: 1,
            isOptional: false,
            isDisabled,
            className: 'risk-rule-from-field-type-5',
        },
        {
            label: 'Assessed Entity Type',
            name: 'assessed_entity_type',
            value: assessed_entity_type,
            type: 5,
            options: serverDriverOptionsMap?.EntityType
            || ['ENTITY_TYPE_UNSPECIFIED', 'ENTITY_TYPE_USER', 'ENTITY_TYPE_TRANSACTION', 'ENTITY_TYPE_LIVENESS'],
            isOptional: false,
            isDisabled,
            className: 'risk-rule-from-field-type-5',
        },
        {
            label: 'Txn Suspect Entity',
            name: 'txn_suspect_entity',
            value: txn_suspect_entity,
            type: 5,
            options: serverDriverOptionsMap?.TxnSuspectEntity
            || ['TXN_SUSPECT_ENTITY_UNSPECIFIED', 'TXN_SUSPECT_ENTITY_PAYER', 'TXN_SUSPECT_ENTITY_PAYEE'],
            isOptional: false,
            className: 'risk-rule-from-field-type-5',
        },
        {
            label: 'Rule Group',
            name: 'rule_group',
            value: rule_group,
            type: 5,

            options: serverDriverOptionsMap?.RuleGroup
            || ['RULE_GROUP_UNSPECIFIED', 'RULE_GROUP_CRYPTO', 'RULE_GROUP_ATM', 'RULE_GROUP_AML',
                // new rules
                'RULE_GROUP_SA_FEDERAL_ONBOARDING', 'RULE_GROUP_FI_LITE_LIQUILOANS_ONBOARDING',
                'RULE_GROUP_FI_LITE_FEDERAL_CC_ONBOARDING'],
            isOptional: false,
            className: 'risk-rule-from-field-type-5',
        },
        {
            label: 'Seed Precision',
            name: 'seed_precision',
            value: `${seed_precision || ''}`,
            type: 1,
            isOptional: false,
        },
        {
            label: 'force_use_seed_precision',
            name: 'force_use_seed_precision',
            value: !!force_use_seed_precision,
            type: 14,
            options: [{ id: 'true', value: true }, { id: 'false', value: false }],
            isOptional: false,
            className: 'risk-rule-from-field-type-5',
        },
        {
            label: 'tags',
            name: 'tags',
            value: tags,
            type: 9,
            options: serverDriverOptionsMap?.tags || [],
            isOptional: true,
        },
    ],
});

export const FILTER_BY_VALUE = {
    RULE_FIELD_MASK_EXTERNAL_ID: 'external_id',
    RULE_FIELD_MASK_NAME: 'rule_name',
    RULE_FIELD_MASK_RULE_GROUP: 'rule_group',
};

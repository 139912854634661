/**
 * @file Manages the saga functions for Scripts component
 */
import {
    call, put, takeLatest, all,
} from 'redux-saga/effects';

import { GET_SCRIPT_ENDPOINT } from '../../api/routes';
import {
    clientApiWrapper, getErrLabel, getErrMsg, getQueryStringFromObject, toastify,
} from '../../utils';

import {
    errScriptAction, setScriptAction,
} from './actions';
import {
    GET_SCRIPT, SCREEN_KEY,
} from './constants';

function* getScriptSaga(action) {
    const { scriptId } = action.data;
    const queryString = getQueryStringFromObject({ scriptId });
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_SCRIPT_ENDPOINT}?${queryString}`,
        );
        yield put(setScriptAction({ screenKey: SCREEN_KEY.script, value: response }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errScriptAction({ screenKey: SCREEN_KEY.script, err: getErrMsg(e) }));
    }
}

export default function* ScriptsSaga() {
    yield all(
        [
            yield takeLatest(GET_SCRIPT, getScriptSaga),
        ],
    );
}

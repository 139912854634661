/**
 * @file Modal component to render the react-modal with common styles
 */

import React, { memo } from 'react';
import Modal from 'react-modal';

// common styles for modal
const customModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '20%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '32px 20px',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Inter, sans-serif',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '20px',
        width: 600,
        border: 0,
        borderRadius: 12,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const CustomModal = (props) => {
    const {
        children, visible, modalStyles = {}, contentLabel, ...restProps
    } = props;

    const modifiedModalStyles = {
        ...customModalStyles,
        overlay: {
            ...customModalStyles.overlay,
            ...modalStyles.overlay,
        },
        content: {
            ...customModalStyles.content,
            ...modalStyles.content,
        },
    };

    return (
        <Modal
            isOpen={visible}
            style={modifiedModalStyles}
            contentLabel={contentLabel || 'Custom Modal'}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
        >
            {children}
        </Modal>
    );
};

export default memo(CustomModal);

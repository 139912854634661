/* eslint-disable no-unused-vars, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, react/jsx-props-no-spreading, no-shadow, import/no-cycle */
/**
 *
 * Tickets
 *
 */

import React, { useState, useContext, useCallback } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import Modal from 'react-modal';

import { setBannersVisibility } from '../../appGlobal/actions';
import { Button, Loader } from '../../components';
import { injectReducer, injectSaga, isDevEnviroment } from '../../utils';

import { DEFAULT_TICKET_PATH, TICKET_PATH } from '../App/routes';
import { MetaInfoContext } from '../../context';
import { RudderEvent, pushRudderEvent } from '../../rudderEvents';
import { makeSelectAgentInfo } from '../AppV2/selectors';

import DefaultRoute from './DefaultRoute';
import TicketInfo from './TicketInfo';
import SideNavbar from './SideNavbar';
import TicketAndUserInfo from './TicketAndUserInfo';

import { TICKETS_KEY } from './constants';
import {
    submitTicketId, addNewTicket as addNewTicketApi, setActiveTicket as setActiveTicketApi,
    getInitialAuthFactor, setAuthExpiredScreen, closeTicket as closeTicketApi, setErrTicketInfo,
} from './actions';
import { makeSelectTickets, makeSelectLoading } from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';
import AgentFeedbackForm from './AgentFeedbackForm';
import FreshdeskTicketInfo from '../FreshdeskTicketInfo';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 16,
        width: 600,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const Tickets = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // reducer state variables
    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const ticketList = useSelector(makeSelectTickets(), shallowEqual);
    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);

    // local state variables
    const [showCloseModal, setCloseModal] = useState(false);
    const [closeTicketIndex, setCloseTicketIndex] = useState(0);

    const metaInfo = useContext(MetaInfoContext);

    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    const getTicketInfo = () => {
        const activeIndex = ticketList.findIndex((item) => item.isActive);
        const activeTicket = ticketList[activeIndex];

        return {
            activeTicket,
            activeIndex,
        };
    };

    /*
    const handleChange = (index) => (e) => {
        dispatch(setTicketId({ ticketId: e.target.value, index }));
    };
    */

    const handleSubmit = (ticketId, index, isCallFlow) => (e) => {
        if (e) {
            e.preventDefault();
        }

        pushRudderEvent(RudderEvent.SherlockV1Agent.AgentClickTicketIdSubmit, agentInfo.emailId, { ticketId, isCallFlow });

        if (!ticketId) {
            dispatch(setErrTicketInfo({
                type: 'ticket',
                index,
                err: 'Please enter a valid ticket id',
            }));

            return;
        }

        dispatch(submitTicketId({ ticketId, index, isCallFlow }));
    };

    const addNewTicket = () => {
        dispatch(addNewTicketApi());
        // need to show the banners whenever agent clicks on new tab
        dispatch(setBannersVisibility({ showBanners: true }));

        history.push(DEFAULT_TICKET_PATH);
    };

    const setActiveTicket = (index) => {
        dispatch(setActiveTicketApi({ index }));

        const {
            isAuthorizedForSensitiveScreen, isAuthorizedForLessSensitiveScreen, isAuthorizedForInsensitiveScreen, ticketId,
        } = ticketList[index];
        const isAuthorizedForScreens = isAuthorizedForInsensitiveScreen || isAuthorizedForLessSensitiveScreen || isAuthorizedForSensitiveScreen;

        const TICKET_PATH = `${DEFAULT_TICKET_PATH}${ticketId}`;

        const { activeTicket } = getTicketInfo();

        if (activeTicket.isAuthUpgradeInProgress) {
            // auth upgrade in progress hence not pushing history
            return;
        }

        if (isAuthorizedForScreens) {
            history.push(`${TICKET_PATH}/banking/profile`);
        } else {
            history.push(TICKET_PATH);
        }
    };

    const renderDefaultRoute = (ticket, index) => (
        <DefaultRoute ticket={ticket} index={index} handleSubmit={handleSubmit} />
    );

    /*
    const renderDefaultRoute = (ticket, index) => {
        const { ticketId, errLabel } = ticket;

        return (
            <FormWithSingleInput
                label='Ticket ID'
                labelId='ticket'
                extClasses={{
                    container: 'ticket-form-container',
                    label: 'ticket-form-container__label',
                    errLabel: 'ticket-form-container__err-label'
                }}
                input={{
                    value: ticketId,
                    pattern: '\\d+',
                    onChange: handleChange(index),
                }}
                cta={{
                    primaryLabel: 'Submit',
                    onPrimaryClick: handleSubmit(ticketId, index),
                }}
                errLabel={errLabel}
            />
        );
    };
    */

    const renderRoutes = (ticket, index) => (
        <Switch>
            <Route
                path={TICKET_PATH}
                render={
                    (props) => (
                        <TicketInfo
                            {...props}
                            ticket={ticket}
                            index={index}
                            submitTicketId={handleSubmit}
                            toggleCloseTicketModal={toggleCloseTicketModal}
                        />
                    )
                }
            />
            <Route
                exact
                path={DEFAULT_TICKET_PATH}
                render={() => renderDefaultRoute(ticket, index)}
            />
        </Switch>
    );

    const toggleCloseTicketModal = (index, flag) => (e) => {
        e.preventDefault();
        e.stopPropagation();

        setCloseModal(flag);
        setCloseTicketIndex(index);
    };

    const closeTicket = (index) => {
        const { activeIndex } = getTicketInfo();

        if (activeIndex === index) { // closing active tab
            if (ticketList.length - 1 === index) { // last tab
                addNewTicket();
            } else { // make the next tab active
                setActiveTicket(index + 1);
            }
        }

        setCloseModal(false);
        setCloseTicketIndex(0);

        setTimeout(() => dispatch(closeTicketApi({ index })), 200);
    };

    const renderTabs = (ticketId) => {
        const showNewTab = !!Object.keys(ticketList[ticketList.length - 1].basicInfo).length;

        return (
            <div className={isDevEnviroment() ? 'ticket-tabs bg-np' : 'ticket-tabs bg'}>
                <div className='ticket-tabs-group'>
                    {
                        ticketList.map((item, index) => (
                            <div
                                // eslint-disable-next-line react/no-array-index-key
                                key={item.ticketId + index}
                                className={classNames({
                                    'ticket-tab': true,
                                    'ticket-tab--active': item.isActive,
                                })}
                                onClick={() => setActiveTicket(index)}
                            >
                                {
                                    Object.keys(item.basicInfo).length ? (
                                        <React.Fragment>
                                            <span>{`ID - ${item.ticketId}`}</span>
                                            <div className='ticket-tab__cb' onClick={toggleCloseTicketModal(index, true)}>X</div>
                                        </React.Fragment>
                                    ) : (
                                        <span>New Ticket</span>
                                    )
                                }
                            </div>
                        ))
                    }
                    {
                        showNewTab ? (
                            <div
                                className='ticket-tab'
                                onClick={addNewTicket}
                            >
                                <span>Add Ticket Id</span>
                            </div>
                        ) : null
                    }
                </div>

                <div className='frcfeWrapper ticket-feedback'>
                    <AgentFeedbackForm ticketId={ticketId} />
                </div>
            </div>
        );
    };

    const renderAuthExpiredScreen = (ticket, index) => {
        const { isAuthExpiredScreen, ticketId, userId } = ticket;

        return (
            <Modal
                isOpen={isAuthExpiredScreen}
                style={customStylesForModal}
                contentLabel='Auth Expired Screen Modal'
            >
                <div className='ticket-modal__heading'>
                    User Authorisation for this action has expired. You need to collect authorisation from the user again.
                </div>
                <div className='ticket-modal__subheading'>Press button below to go to the authorisation flow</div>
                <Button
                    primary
                    label='Go to authorisation flow'
                    onClick={() => {
                        dispatch(setAuthExpiredScreen({ flag: false, index }));
                        dispatch(getInitialAuthFactor({ ticketId, userId, index }));

                        history.push(`${DEFAULT_TICKET_PATH}${ticketId}`);
                    }}
                />
            </Modal>
        );
    };

    const renderCloseTicketScreen = () => {
        const ticket = ticketList[closeTicketIndex];
        const {
            ticketId, hasConfirmedUserInfo, userInfo, basicInfo: {
                source, phoneNumber, ticketPhoneNumber, name,
            },
        } = ticket;
        const ticketAndUserInfo = {
            ticketId,
            source,
            phoneNumber: ticketPhoneNumber || phoneNumber,
            name: hasConfirmedUserInfo ? userInfo.name : name,
        };

        return (
            <Modal
                isOpen={showCloseModal}
                style={customStylesForModal}
                contentLabel='Close Ticket Screen Modal'
            >
                <TicketAndUserInfo {...ticketAndUserInfo} extClasses='ticket-modal__ctw' />
                <div className='ticket-modal__heading'>
                    Are you sure you want to close this ticket?
                </div>
                <div className='frcsbWrapper ticket-modal__ct-ctas'>
                    <Button
                        secondary
                        label='Cancel'
                        onClick={toggleCloseTicketModal(closeTicketIndex, false)}
                    />
                    <Button
                        primary
                        label='Yes, close'
                        onClick={() => closeTicket(closeTicketIndex)}
                    />
                </div>
            </Modal>
        );
    };

    const { activeTicket, activeIndex } = getTicketInfo();
    const isLoading = loading.basicInfo || loading.userInfo || loading.authInfo;
    const {
        ticketId, isAuthorizedForSensitiveScreen, isAuthorizedForLessSensitiveScreen, isAuthorizedForInsensitiveScreen,
    } = activeTicket;
    const isAuthorizedForScreens = isAuthorizedForLessSensitiveScreen || isAuthorizedForSensitiveScreen || isAuthorizedForInsensitiveScreen;

    const ticketContentContainerClass = classNames({
        'ticket-content-container-v2': bannersVersion === 2,
        'ticket-content-container': bannersVersion === 1,
    });

    return (
        <React.Fragment>
            <div className='ticket-container'>
                {renderTabs(ticketId)}
                <div className='ticket-wrapper'>
                    <SideNavbar
                        isAuthorizedForLessSensitiveScreen={isAuthorizedForLessSensitiveScreen}
                        ticketId={isAuthorizedForScreens && ticketId}
                        activeTicket={activeTicket}
                    />
                    <div className={classNames({
                        'ticket-content-container': bannersVersion === 1,
                        'ticket-content-container-v2': bannersVersion === 2,
                    })}
                    >
                        {renderRoutes(activeTicket, activeIndex)}
                    </div>

                    <FreshdeskTicketInfo
                        ticketId={ticketId}
                    />
                </div>
            </div>
            {renderAuthExpiredScreen(activeTicket, activeIndex)}
            {renderCloseTicketScreen()}
            <Loader visible={isLoading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: TICKETS_KEY, reducer });
const withSaga = injectSaga({ key: TICKETS_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(Tickets);

/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, no-unused-vars */

/**
*
* ProfileDropdown
* TODO: Remove the ESlint disable rules and wrap the div and images with button tag
*
*/

import React, { memo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { getAuthLogout } from '../../../appGlobal/actions';
import { DropdownContent, DropdownWrapper } from '../../../components';
import { ICONS_URL } from '../../../constants/assets';
// import arrowIcon from '../../assets/images/svgs/chevron-bottom.svg';

import { DEFAULT_TICKET_PATH } from '../routes';
import { makeSelectHasAgentInfo, makeSelectAgentInfo, makeSelectAccessLevel } from '../selectors';

import './style.scss';
import { PRODUCTION } from '../../../utils/constants';

const ProfileDropdown = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const hasUserInfo = useSelector(makeSelectHasAgentInfo(), shallowEqual);
    const userInfo = useSelector(makeSelectAgentInfo(), shallowEqual);
    const selectedAccessLevel = useSelector(makeSelectAccessLevel(), shallowEqual);

    const [isDDOpen, setDDOpen] = useState(false);

    // do not render anything if we do not have agent info
    if (!hasUserInfo) {
        return null;
    }

    const { extClasses = {} } = props;

    const {
        firstName, fullNameStr, emailId, accessLevelInfo = {},
    } = userInfo;
    const {
        isAdmin, isAgent, isDeveloper, isQA, isViewLivenessVideo, isSuperAdmin, isWaitlistApprover,
        isWaitlistAdminApprover, isQALead, isFederalAgent, isRiskOps, isFitAdmin, isAdminRestricted,
        isBizAdmin,
    } = accessLevelInfo;

    const labelClass = classNames('label', extClasses.label);
    const imgClass = classNames(
        {
            'transform-transition': true,
            'arrow-icon': !isDDOpen,
            'collapsed-arrow-icon': isDDOpen,
        },
        extClasses.arrow,
    );

    // content container ext class
    const ccExtClass = classNames({
        'content-container': isDDOpen,
        // 'height-transition': true,
        // 'content-container--agent': visible && isAgent,
        // 'content-container--admin': visible && isAdmin,
        // 'content-container--qa': visible && isQA,
        // 'content-container--developer': visible && isDeveloper,
        // 'content-container--vlv': visible && isViewLivenessVideo,
        // 'content-container--superadmin': visible && isSuperAdmin,
        // 'content-container--wl': visible && (isWaitlistApprover || isWaitlistAdminApprover),
        // 'content-container--qal': visible && isQALead,
        // 'content-container--fa': visible && isFederalAgent,
        // 'content-container--rops': visible && isRiskOps,
        // 'content-container--fitadmin': visible && isFitAdmin,
        // 'content-container--adminrestricted': visible && isAdminRestricted,
    });

    const showUserActionWrapper = (
        isAdmin
        || isQA
        || isDeveloper
        || isViewLivenessVideo
        || isSuperAdmin
        || isWaitlistApprover
        || isWaitlistAdminApprover
        || isFitAdmin
        || isAdminRestricted
        || isBizAdmin
    );
    const showAgentManagement = (
        isAdmin
        || isQA
        || (process.env.NODE_ENV !== PRODUCTION && isDeveloper) // disable agent management for developer in production
        || isViewLivenessVideo
        || isSuperAdmin
        || isWaitlistApprover
        || isWaitlistAdminApprover
        || isFitAdmin
        || isBizAdmin
    );
    const showAuditLogs = isAdmin || isQA || isSuperAdmin; // access to admin, qa & super admin
    const showPayoutRequests = isAdmin || isQA || isSuperAdmin || isAgent; // access to admin, qa, super admin & agent
    // const showWaitlistDetails = isAdmin || isAdminRestricted; // access to admin & admin restricted

    const toggleDropdown = () => {
        setDDOpen(!isDDOpen);
    };

    const onClickHandler = (key) => () => {
        toggleDropdown();

        let route;

        switch (key) {
            case 'agent-management':
                route = '/agent-management';
                break;

            case 'payout-requests':
                route = '/payouts';
                break;

            case 'admin-tools':
                route = '/admin-tools';
                break;

            case 'audit-logs':
                route = '/logs';
                break;

            default:
        }

        history.push(route);
    };

    // do not show new profile label for ticket route
    const isDefaultTicketRoute = location.pathname.includes(DEFAULT_TICKET_PATH);

    return (
        <DropdownWrapper
            visible={isDDOpen}
            onOutsideClickHandler={toggleDropdown}
            extClasses={{
                container: 'profile-dropdown',
            }}
        >
            {
                isDefaultTicketRoute ? (
                    <div onClick={toggleDropdown}>
                        <span className='name'>{firstName}</span>
                        <img className={imgClass} src={ICONS_URL.CHEVRON_BOTTOM} alt='arrow-icon' />
                    </div>
                ) : (
                    <div className='frcWrapper' onClick={toggleDropdown}>
                        <div className={labelClass}>{firstName.charAt(0)}</div>
                        <img className={imgClass} src={ICONS_URL.CHEVRON_BOTTOM} alt='arrow-icon' />
                    </div>
                )
            }
            <DropdownContent
                visible={isDDOpen}
                extClasses={{
                    container: ccExtClass,
                }}
            >
                <div className='user-info-wrapper'>
                    <div className='label'>{fullNameStr}</div>
                    <div className='sublabel'>
                        <span>{emailId}</span>
                        <span className='sublabel--role'>{selectedAccessLevel}</span>
                    </div>
                </div>
                {
                    showUserActionWrapper ? (
                        <div className='user-action-wrapper'>
                            {
                                showAgentManagement ? (
                                    <div
                                        className='label'
                                        onClick={onClickHandler('agent-management')}
                                    >
                                        Agent Management
                                    </div>
                                ) : null
                            }
                            {
                                showPayoutRequests ? (
                                    <div
                                        className='label'
                                        onClick={onClickHandler('payout-requests')}
                                    >
                                        Payout Requests
                                    </div>
                                ) : null
                            }
                            <div
                                className='label'
                                onClick={onClickHandler('admin-tools')}
                            >
                                Admin Tools
                            </div>
                            {
                                showAuditLogs ? (
                                    <div
                                        className='label'
                                        onClick={onClickHandler('audit-logs')}
                                    >
                                        Logs
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : null
                }
                <div className='user-logout-wrapper'>
                    <div className='label' onClick={() => dispatch(getAuthLogout())}>
                        Log Out
                    </div>
                </div>
            </DropdownContent>
        </DropdownWrapper>
    );
};

export default memo(ProfileDropdown);

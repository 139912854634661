/**
 * @file WealthOps Saga
 */

import {
    call, put, takeLatest, all,
} from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { LIVENESS_ROOT_V2, WEALTH_ROOT } from '../../api/routes';

import {
    GET_WEALTH_OPS_QUEUE, SET_WEALTH_OPS_QUEUE, ERR_WEALTH_OPS, GET_WEALTH_OPS_LIVENESS_VIDEO, SET_WEALTH_OPS_LIVENESS_VIDEO,
    VIEW_ALL_WEALTH_OPS_CASES, VIEW_SINGLE_WEALTH_OPS_CASE, DEQUEUE_WEALTH_OPS_CASE, GET_WEALTH_OPS_PENDING_REVIEW_COUNT,
} from './constants';
import {
    getWealthOpsPendingReviewCountAction, setWealthOpsCurCaseAction, setWealthOpsCurViewAction, setWealthOpsPendingReviewCountAction,
} from './actions';

function* getWealthOpsQueueSaga(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_ROOT}/risk-ops?${queryString}`,
        );

        yield put({ type: SET_WEALTH_OPS_QUEUE, data: response.elements });

        return response.elements;
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_WEALTH_OPS, data: { err: e.message } });

        return [];
    }
}

function* getWealthOpsLivenessVideoSaga(action) {
    const { actorId, requestId } = action.data;
    const queryString = `actorId=${btoa(actorId)}&requestId=${btoa(requestId)}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LIVENESS_ROOT_V2}/video-url?${queryString}`,
        );

        yield put({ type: SET_WEALTH_OPS_LIVENESS_VIDEO, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_WEALTH_OPS, data: { err: e.message } });
    }
}

function* dequeueWealthOpsCaseSaga(action) {
    let { actorId, recordId } = action.data;
    const { payloadType, index, customContext } = action.data;
    actorId = btoa(actorId);
    recordId = btoa(recordId);
    const queryString = getQueryStringFromObject({ actorId, recordId, payloadType });
    const { bannersVersion } = customContext;

    const bannerStyle = {
        1: '.devs-cc',
        2: '.devs-v2-cc',
    };

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.delete],
            `${WEALTH_ROOT}/risk-ops?${queryString}`,
        );

        const queueElements = yield call(getWealthOpsQueueSaga, { data: { payloadType } });
        yield put(getWealthOpsPendingReviewCountAction({ payloadType }));

        let wealthOpsView;
        let curCase;
        const queueLen = queueElements.length;

        if (queueLen === 0) { // if queue is empty, show all wealth ops cases
            wealthOpsView = VIEW_ALL_WEALTH_OPS_CASES;
            curCase = {};
        } else { // else show the next case in the queue
            wealthOpsView = VIEW_SINGLE_WEALTH_OPS_CASE;
            /**
             * if index is less than queue length, set the current case at that index
             * else set the current case as the last case in the queue
             */
            curCase = index < queueLen ? queueElements[index] : queueElements[queueLen - 1];
        }

        yield put(setWealthOpsCurViewAction(wealthOpsView));
        yield put(setWealthOpsCurCaseAction({ ...curCase, index }));

        /**
         * scroll to the top for the next case
         * need to add 500ms delay otherwise it scrolls to the top & then again back to the bottom
         */
        setTimeout(() => document.querySelector(bannerStyle[bannersVersion]).scrollTo(0, 0), 500);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_WEALTH_OPS, data: { err: e.message } });
    }
}

function* getWealthOpsPendingReviewCountSaga(action) {
    const { payloadType } = action.data;
    const queryString = getQueryStringFromObject({ payloadType });

    try {
        const { count: pendingReviewCount } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_ROOT}/risk-ops/pending-review-count?${queryString}`,
        );

        yield put(setWealthOpsPendingReviewCountAction({ pendingReviewCount }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_WEALTH_OPS, data: { err: e.message } });
    }
}

export default function* wealthOpsSaga() {
    yield all(
        [
            yield takeLatest(GET_WEALTH_OPS_QUEUE, getWealthOpsQueueSaga),
            yield takeLatest(GET_WEALTH_OPS_LIVENESS_VIDEO, getWealthOpsLivenessVideoSaga),
            yield takeLatest(DEQUEUE_WEALTH_OPS_CASE, dequeueWealthOpsCaseSaga),
            yield takeLatest(GET_WEALTH_OPS_PENDING_REVIEW_COUNT, getWealthOpsPendingReviewCountSaga),
        ],
    );
}

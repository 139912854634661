/**
 * @file Constants for SalaryAccountOps
 */

import { getInitialDateRange } from '../../utils';

// container key
export const SALARY_ACCOUNT_OPS_KEY = 'salaryAccountOps';
export const SALARY_ACCOUNT_DETAILS_KEY = 'accountDetails';
export const SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY = 'verificationQueue';
export const SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY = 'raiseVerificationRequest';
export const SALARY_ACCOUNT_HEALTH_INSURANCE_KEY = 'healthInsurance';
export const SALARY_ACCOUNT_MANDATES = 'mandateDetails';
export const SALARY_ACCOUNT_EXECUTED_MANDATES = 'executedMandateDetails';

// actions
export const GET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS`;
export const SET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS`;
export const GET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS`;
export const SET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS`;
export const ERR_VIEW_SALARY_ACCOUNT_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/ERR_VIEW_SALARY_ACCOUNT_DETAILS`;
export const ERR_SALARY_OPS = `app/${SALARY_ACCOUNT_OPS_KEY}/ERR_SALARY_OPS`;
export const SET_SALARY_OPS_CUR_VIEW = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_SALARY_OPS_CUR_VIEW`;
export const GET_EMPLOYER_DETAILS_SALARY_ACCOUNT = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_EMPLOYER_DETAILS_SALARY_ACCOUNT`;
export const SET_EMPLOYER_DETAILS_SALARY_ACCOUNT = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_EMPLOYER_DETAILS_SALARY_ACCOUNT`;
export const UPDATE_EMPLOYER_DETAILS_SALARY_ACCOUNT = `app/${SALARY_ACCOUNT_OPS_KEY}/UPDATE_EMPLOYER_DETAILS_SALARY_ACCOUNT`;
export const SUCCESSFULLY_UPDATED_EMPLOYER_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/SUCCESSFULLY_UPDATED_EMPLOYER_DETAILS`;
export const SET_SALARY_ACCOUNT_DETAILS_USER_IDENTIFIER = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_SALARY_ACCOUNT_DETAILS_USER_IDENTIFIER`;
export const GET_SALARY_OPS_ENUMS = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_SALARY_OPS_ENUMS`;
export const SET_SALARY_OPS_ENUMS = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_SALARY_OPS_ENUMS`;
export const GET_SALARY_OPS_EMPLOYER_LIST = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_SALARY_OPS_EMPLOYER_LIST`;
export const SET_SALARY_OPS_EMPLOYER_LIST = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_SALARY_OPS_EMPLOYER_LIST`;
export const ERR_SALARY_OPS_EMPLOYER_LIST = `app/${SALARY_ACCOUNT_OPS_KEY}/ERR_SALARY_OPS_EMPLOYER_LIST`;
export const GET_SALARY_VERIFICATION_CASES = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_VERIFICATION_CASES`;
export const SET_SALARY_VERIFICATION_CASES = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_VERIFICATION_CASES`;
export const SET_SALARY_VERIFICATION_CASES_EMPLOYER_TYPE = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_VERIFICATION_CASES_EMPLOYER_TYPE`;
export const SET_SALARY_VERIFICATION_CASES_EMPLOYER_NAME = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_VERIFICATION_CASES_EMPLOYER_NAME`;
export const GET_SALARY_VERIFICATION_CASES_COUNT = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_SALARY_VERIFICATION_CASES_COUNT`;
export const SET_SALARY_VERIFICATION_CASES_COUNT = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_SALARY_VERIFICATION_CASES_COUNT`;
export const SET_SALARY_VERIFICATION_CASE_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_VERIFICATION_CASE_DETAILS`;
export const SET_SALARY_VERIFICATION_NEW_EMPLOYER_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_VERIFICATION_NEW_EMPLOYER_DETAILS`;
export const GET_SALARY_VERIFICATION_CASE_EMPLOYER_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_VERIFICATION_CASE_EMPLOYER_DETAILS`;
export const SET_SALARY_VERIFICATION_CASE_EMPLOYER_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_VERIFICATION_CASE_EMPLOYER_DETAILS`;
export const GET_SALARY_VERIFICATION_CASE_TXN_HISTORY = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_VERIFICATION_CASE_TXN_HISTORY`;
export const SET_SALARY_VERIFICATION_CASE_TXN_HISTORY = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_VERIFICATION_CASE_TXN_HISTORY`;
export const UPDATE_SALARY_VERIFICATION_CASE_STATUS = `app/${SALARY_ACCOUNT_OPS_KEY}/UPDATE_VERIFICATION_CASE_STATUS`;
export const SUCCESSFULLY_UPDATED_SALARY_VERIFICATION_CASE_STATUS = `app/${SALARY_ACCOUNT_OPS_KEY}/SUCCESSFULLY_UPDATED_VERIFICATION_CASE_STATUS`;
export const SET_SALARY_VERIFICATION_SEARCH_FILTER = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_VERIFICATION_SEARCH_FILTER`;
export const GET_SALARY_VERIFICATION_REQUESTS_FOR_USER = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_VERIFICATION_REQUESTS_FOR_USER`;
export const SET_SALARY_VERIFICATION_REQUESTS_FOR_USER = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_VERIFICATION_REQUESTS_FOR_USER`;
export const GET_SALARY_EMPLOYER_DETAILS_FOR_USER = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_EMPLOYER_DETAILS_FOR_USER`;
export const SET_SALARY_EMPLOYER_DETAILS_FOR_USER = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_EMPLOYER_DETAILS_FOR_USER`;
export const GET_SALARY_ACCOUNT_MANDATES = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_SALARY_ACCOUNT_MANDATES`;
export const SET_SALARY_ACCOUNT_MANDATES = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_SALARY_ACCOUNT_MANDATES`;
export const ERR_SALARY_ACCOUNT_MANDATES = `app/${SALARY_ACCOUNT_OPS_KEY}/ERR_SALARY_ACCOUNT_MANDATES`;
export const GET_SALARY_ACCOUNT_EXECUTED_MANDATES = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_SALARY_ACCOUNT_EXECUTED_MANDATES`;
export const SET_SALARY_ACCOUNT_EXECUTED_MANDATES = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_SALARY_ACCOUNT_EXECUTED_MANDATES`;
export const ERR_SALARY_ACCOUNT_EXECUTED_MANDATES = `app/${SALARY_ACCOUNT_OPS_KEY}/ERR_SALARY_ACCOUNT_EXECUTED_MANDATES`;

export const SET_RAISE_VERIFICATION_REQUEST_FILTER = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_RAISE_VERIFICATION_REQUEST_FILTER`;
export const GET_RAISE_VERIFICATION_REQUEST_CASES = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_RAISE_VERIFICATION_REQUEST_CASES`;
export const SET_RAISE_VERIFICATION_REQUEST_CASES = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_RAISE_VERIFICATION_REQUEST_CASES`;
export const SET_RAISE_VERIFICATION_SELECTED_ACTOR = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_RAISE_VERIFICATION_SELECTED_ACTOR`;
export const GET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS`;
export const SET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS`;
export const GET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS`;
export const SET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS`;
export const UPDATE_RAISE_VERIFICATION_REQUEST_STATUS = `app/${SALARY_ACCOUNT_OPS_KEY}/UPDATE_RAISE_VERIFICATION_REQUEST_STATUS`;
export const UPDATE_RAISE_VERIFICATION_REQUEST_STATUS_SUCCESS = `app/${SALARY_ACCOUNT_OPS_KEY}/UPDATE_RAISE_VERIFICATION_REQUEST_STATUS_SUCCESS`;

// health insurance
export const GET_USER_HEALTH_INSURANCE_POLICY_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_USER_HEALTH_INSURANCE_POLICY_DETAILS`;
export const SET_USER_HEALTH_INSURANCE_POLICY_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_USER_HEALTH_INSURANCE_POLICY_DETAILS`;
export const ERROR_USER_HEALTH_INSURANCE_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/ERROR_USER_HEALTH_INSURANCE_DETAILS`;

export const GET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS`;
export const SET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS`;
export const ERROR_GENERAL_HEALTH_INSURANCE_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/ERROR_GENERAL_HEALTH_INSURANCE_DETAILS`;

export const GET_SALARY_ACTIVATION_STATUS = `app/${SALARY_ACCOUNT_OPS_KEY}/GET_SALARY_ACTIVATION_STATUS`;
export const SET_SALARY_ACTIVATION_STATUS = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_SALARY_ACTIVATION_STATUS`;
export const ERROR_SALARY_ACTIVATION_STATUS = `app/${SALARY_ACCOUNT_OPS_KEY}/ERROR_SALARY_ACTIVATION_STATUS`;

// views
export const VIEW_SALARY_ACCOUNT_DETAILS = `app/${SALARY_ACCOUNT_OPS_KEY}/STATE/VIEW_SALARY_ACCOUNT_DETAILS`;
export const UPDATE_EMPLOYER_NAME = `app/${SALARY_ACCOUNT_OPS_KEY}/STATE/UPDATE_EMPLOYER_NAME`;
export const VIEW_VERIFICATION_CASES = `app/${SALARY_ACCOUNT_OPS_KEY}/STATE/VIEW_VERIFICATION_CASES`;
export const VIEW_SINGLE_VERIFICATION_CASE = `app/${SALARY_ACCOUNT_OPS_KEY}/STATE/VIEW_SINGLE_VERIFICATION_CASE`;
export const VIEW_RAISE_VERIFICATION_CASES = `APP/${SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY}/STATE/VIEW_RAISE_VERIFICATION_CASES`;
export const VIEW_RAISE_VERIFICATION_SINGLE_CASE = `APP/${SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY}/STATE/VIEW_RAISE_VERIFICATION_SINGLE_CASE`;

export const SET_IDENTIFIER_LIST = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_IDENTIFIER_LIST`;
export const SET_ACTIVE_TAB_INDEX = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_ACTIVE_TAB_INDEX`;
export const SET_NEW_TAB_ACTIVE = `app/${SALARY_ACCOUNT_OPS_KEY}/SET_NEW_TAB_ACTIVE`;

// raise verification request constants
export const RAISE_VERIFICATION_REQUEST_STATUS = {
    new: {
        label: 'New',
        value: 'USER_SALARY_VERIFICATION_ELIGIBILITY_STATUS_NEW',
    },
    pending: {
        label: 'Pending',
        value: 'USER_SALARY_VERIFICATION_ELIGIBILITY_STATUS_PENDING',
    },
    complete: {
        label: 'Complete',
        value: 'USER_SALARY_VERIFICATION_ELIGIBILITY_STATUS_COMPLETE',
    },
};

// reducer keys
export const REDUCER_KEYS = {
    ACTIVATION_STATUS: 'activationStatus',
    USER_DETAILS: 'userDetails',
    GENERAL_DETAILS: 'generalDetails',
};

// for single date picker component
const EXTEND_TO_DATE_INFO = {
    label: 'Check Salary Active Status on Date*',
    labelId: 'check-salary-active-status-on-date',
};

export const INITIAL_DATE_RANGE = getInitialDateRange();

export const SALARY_ACTIVE_STATUS_DATE_INFO = {
    toDateInfo: EXTEND_TO_DATE_INFO,
    initialDateRange: INITIAL_DATE_RANGE,
    extClasses: {
        container: 'salops-date__ctn',
        label: 'salops-date__label',
    },
};

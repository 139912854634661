/**
 * @file ExpandableCard component
 * Can pass any React component to be expanded or collapsed using this component
 */

import React, { memo } from 'react';
import classNames from 'classnames';

import { ICONS_URL } from '../../constants/assets';
// import arrowIcon from '../../assets/images/svgs/arrow.svg';

import './style.scss';

const ExpandableCard = (props) => {
    // send v2 prop
    const {
        heading, children, setIsExpanded, isExpanded, extClasses = '', extHeadingClasses = '',
        extChildElementsClass = '', version = 1, isCollapsible = true,
    } = props;

    const containerClass = classNames('expandablecard', extClasses);
    const headingClass = classNames('expandablecard-heading', extHeadingClasses);
    const visibleChildElementsClass = classNames(extChildElementsClass);
    const hiddenChildElementsClass = classNames('expandablecard-vis-hidden', extChildElementsClass);
    const imgClass = classNames({
        'expandablecard-heading__arrowicon': true,
        'expandablecard-heading__arrowicon--expanded': isExpanded,
        'transform-transition': true,
    });

    const onCardClick = () => {
        if (!isCollapsible) return;

        setIsExpanded();
    };

    return (
        <div className={containerClass}>
            <button
                className='expandablecard-heading-button'
                type='button'
                onClick={onCardClick}
            >
                <div className={headingClass}>
                    <div>{heading}</div>
                    {isCollapsible && <img className={imgClass} src={ICONS_URL.ARROW} alt='arrow-icon' />}
                </div>
            </button>
            {/**
             * In v1, we remove the children from the DOM when the card is closed
             * This will cause all the local states to reset
             */}
            {/* eslint-disable-next-line no-nested-ternary */}
            {version === 1 ? (
                isExpanded ? children : null
            ) : (
                /**
                 * In v2, we make the container display:none & height 0 but the children elements still exist on the DOM
                 * Hence the local state of component does not reset
                 */
                <div
                    className={`${isExpanded ? visibleChildElementsClass : hiddenChildElementsClass}`}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

export default memo(ExpandableCard);

/**
 * @file Manages the reducer state & functions for Scripts component
 */
import { fromJS } from 'immutable';
import {
    ERR_SCRIPT, GET_SCRIPT,
    SET_SCRIPT,
} from './constants';

const initialState = fromJS({
    loading: false,
    err: '',
    L1List: null,
    search: {
        meta: {
            originalArgs: {},
            loading: false,
            err: '',
            categoryMap: null,
            totalResults: 0,
        },
        results: {
            originalArgs: {},
            loading: false,
            err: '',
            from: 0,
            size: 10,
            data: null,
        },
    },
    script: {
        loading: false,
        err: '',
        data: null,
    },
});

function scriptsReducer(state = initialState, action = {}) {
    switch (action.type) {
        // Getter changes
        case GET_SCRIPT: {
            const { screenKey } = action.data;
            let key;
            if (screenKey) {
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                const initialValues = initialState.getIn(key)?.toJS();
                const values = {
                    ...initialValues,
                    loading: true,
                    err: '',
                };
                return state.mergeIn(key, values);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '')
                .setIn(['L1List'], null);
        }
        // Setter merge changes
        case SET_SCRIPT: {
            const { screenKey, key: keyArg, value } = action.data;
            let key;
            if (screenKey) {
                let values = {
                    loading: false,
                    err: '',
                };
                if (value) {
                    values = {
                        ...values,
                        ...value,
                    };
                }
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                return state.mergeIn(key, values);
            }

            return state
                .setIn([keyArg], value)
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }

        // Err Changes
        case ERR_SCRIPT: {
            const { screenKey, err } = action.data;
            let key;
            if (screenKey) {
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                const initialValues = initialState.getIn(key)?.toJS();
                const values = {
                    ...initialValues,
                    loading: false,
                    err,
                };
                return state.mergeIn(key, values);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['err'], err)
                .setIn(['L1List'], null);
        }
        default:
            return state;
    }
}

export default scriptsReducer;

/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
/**
 * @file Bar chart
 * contains stacked and grouped bars
 */

import React, {
    useState, useEffect, useMemo,
} from 'react';
import * as d3 from 'd3';

import { getCurrencyAmountFormat } from '../../utils/currencyHelper';

import FormDropdown from '../FormDropdown';

import {
    dateFormat, getD3Time, getD3TimeFormat, dynamicColorMapping, createColorMapping,
} from './utils';
import { GraphTypeOptions, STACKED } from './constants';
import Legend from './Legend';
import { debounce } from '../../utils/helpers';

const ToolTip = React.memo((props) => {
    const {
        bars, aggregation, yValue, data, indexKey, precession,
    } = props;

    let total;
    // formatted date eg: aug 20, 2023
    const formattedDate = dateFormat(getD3Time(aggregation)(data[0][indexKey].data[0]));

    // array of bar values based on yValue key
    const values = bars.map((bar, ind) => {
        let value;
        try {
            // try getting the value for bars based on yValue
            value = data[ind][indexKey]?.data[1]?.get(bar)[yValue];
        } catch (e) {
            value = undefined;
        }
        return value;
    });
    // check if values array is having more than one valid values
    const isTotalValid = values.filter((item) => !!item).length > 1;

    if (isTotalValid) {
        total = d3.sum(values); // sum all the values
    }

    return (
        <title>
            {formattedDate}
            {'\n'}
            {
                bars.map((bar, ind) => {
                    const value = values[ind];

                    if (!value) return null;

                    return (
                        <React.Fragment>
                            {ind !== 0 ? '\n' : ''}
                            {bar} - {
                                getCurrencyAmountFormat(value, precession)
                            }
                        </React.Fragment>
                    );
                })
            }
            {
                total
                    ? `\nTotal - ${getCurrencyAmountFormat(total, precession)}`
                    : null
            }
        </title>
    );
});

const BarSummary = React.memo((props) => {
    const {
        bars, aggregation, yValue, data, indexKey, precession, width, annotations,
    } = props;
    let total;
    // formatted date eg: aug 20, 2023
    const formattedDate = dateFormat(getD3Time(aggregation)(data[0][indexKey].data[0]));

    // array of bar values based on yValue key
    const values = bars.map((bar, ind) => {
        let value;
        try {
            // try getting the value for bars based on yValue
            value = data[ind][indexKey]?.data[1]?.get(bar)[yValue];
        } catch (e) {
            value = undefined;
        }
        return value;
    });
    // check if values array is having more than one valid values
    const isTotalValid = values.filter((item) => !!item).length > 1;

    if (isTotalValid) {
        total = d3.sum(values); // sum all the values
    }

    return (
        <React.Fragment>
            <div className='d3-bts-container' style={{ width }}>
                <strong>
                    {formattedDate}
                </strong>
                {'->'}
                {'\t'}
                {
                    bars.map((bar, ind) => {
                        const value = values[ind];

                        if (!value) return null;

                        return (
                            <React.Fragment>
                                <strong>
                                    {ind !== 0 ? '\t,\t' : ''}
                                </strong>
                                <strong>{bar} : {'\t'}</strong>
                                {getCurrencyAmountFormat(value, precession)}
                            </React.Fragment>
                        );
                    })
                }
                {
                    total
                        ? (
                            <React.Fragment>
                                <strong>
                                    {'\t'}Total{'\t'}:
                                </strong>
                                {`\t${getCurrencyAmountFormat(total, precession)}`}
                            </React.Fragment>
                        )
                        : null
                }
            </div>
            <div className='d3-annotation-container'>
                {
                    Array.isArray(annotations) && (
                        annotations.map((item) => (
                            <div className='d3-bts-container' style={{ width }}>
                                {'\t'}
                                <strong>
                                    {'\t'}{item.label}{'\t'}:
                                </strong>
                                {item.value}
                            </div>
                        ))
                    )
                }
            </div>

        </React.Fragment>
    );
});

const getMaxHorizontalRefValue = (horizontalRefLines) => {
    if (Array.isArray(horizontalRefLines) && horizontalRefLines.length > 0) {
        return [0, ...horizontalRefLines].reduce((a, b) => Math.max(a, b.value));
    }
    return 0;
};

const BarChartV2 = (props) => {
    const {
        data, aggregation, yValue, barKey, dimensions = {}, heading,
        enableGraphTypeDropdown, legendPlacement = 'top', headingPlacement = 'bottom',
        tooTipPrecession, graphTypeValue = STACKED,
        xScale, setXScale, xScaleTemplate,
        zoomTransform, setZoomTransform, bars, onboardingDate, horizontalRefLines, xRefs, staticColorMapping = {},
    } = props;

    const legendKeys = [...bars, ...xRefs];
    const maxHorizontalRefValue = getMaxHorizontalRefValue(horizontalRefLines) || 0;

    // Specify the chart’s dimensions.
    const {
        width = 640,
        height = 400,
        marginTop = 20,
        marginRight = 0,
        marginBottom = 30,
        marginLeft = 40,
        barPadding = 5,
    } = dimensions;

    const [graphType, setGraphType] = useState(() => GraphTypeOptions.find((item) => item.value === graphTypeValue));

    // Index of the selected bar to show bar summary
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedAnnotations, setAnnotations] = useState();

    // group data based on date
    const grouped = useMemo(() => d3.group(data, (d) => d.date), [data]);

    const maxYVal = useMemo(() => d3.max(Array.from(grouped, ([, dt]) => ({
        total: d3.sum(dt, (d) => d[yValue]),
    })), (da) => da.total), [grouped, yValue]);

    const [maxYValue, setMaxYValue] = useState(Math.max((maxYVal || 0), maxHorizontalRefValue));

    const [intervalRange, setIntervalRange] = useState(1); // to set tick intervals of zoom

    const chartColorSchema = dynamicColorMapping(staticColorMapping);

    const color = createColorMapping(legendKeys, chartColorSchema);// array of colors

    const svgRef = React.useRef();

    const gx = React.useRef();

    const gy = React.useRef();

    // Y-axis linear scale
    const yScale = d3.scaleLinear()
        .domain([0, Math.max(maxYValue, 10)]) // set max Y value should be atleast 10 for y axis
        .rangeRound([height - marginBottom, marginTop])
        .nice();

    const stacked = d3.stack()
        .keys(bars)
        .value((g, key) => {
            const [, group] = g;
            return (group.get(key) || {})[yValue] || 0;
        })(d3.index(data, (d) => d.date, (d) => d.type));

    const barsCount = stacked.length;

    // render y-axis
    useEffect(
        () => {
            d3.select(gy.current)
                .call(d3.axisLeft(yScale)
                    .tickFormat((val) => Number(val).toLocaleString('en-IN'))); // number as indian currency format
        },
        [gy, yScale],
    );

    useEffect(() => {
        setMaxYValue(Math.max(maxHorizontalRefValue || 0, d3.max(data, (d) => d[yValue]) || 0));
    }, [yValue, data, maxHorizontalRefValue]);

    const handleGraphTypeChange = (option) => {
        setGraphType(option);
    };

    const handleOnBarClick = (index, annotations) => {
        setSelectedIndex(index);
        setAnnotations(annotations);
    };

    const zoomExtent = [[marginLeft, marginTop], [width, height - marginTop]];

    // Debouncer function to update zoomTransform
    const handleZoomTransform = debounce((evt) => {
        setZoomTransform({ ...evt.transform });
    }, 500);

    function updateChart(evt) {
        // recover the new scale
        const { transform } = evt;
        const newX = transform.rescaleX(xScaleTemplate);
        setXScale({ xScale: newX });

        // enters when zoomTransform and transform data from zoom event not valid
        if (zoomTransform.x !== transform.x
            || zoomTransform.y !== transform.y
            || zoomTransform.k !== transform.k) {
            handleZoomTransform(evt);
        }

        // get interval range by dividing current interval range by zoom constant (k)
        const range = Math.floor(intervalRange / (transform.k));
        // setting upto two then null so d3 can use default tick intervals
        setIntervalRange(range <= 2 ? range : null);
    }

    // const onBoardDate = getD3Time(aggregation)(onboardingDate);

    const zoom = d3.zoom()
        .scaleExtent([0.1, 1]) // This control how much you can unzoom (x0.1) and zoom (x1)
        .extent(zoomExtent)
        .on('zoom', updateChart);

    // zoom event listener
    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const { x, y, k } = (zoomTransform || {});

        if (svgRef.current && zoomTransform) {
            svg.call(zoom
                // prevent zooming event on scrolling
                .filter((event) => (event.ctrlKey || event.type !== 'wheel') && !event.button))
                // setting the zoomTransform values to the chart
                .call(zoom.transform, d3.zoomIdentity.translate(x, y).scale(k));
        }

        return () => {
            svg.on('.zoom', null); // remove zoom listener
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [svgRef, zoomTransform]);

    const interval = getD3Time(aggregation).every(intervalRange); // get interval function using intervalRange or null

    const axisBottom = d3.axisBottom(xScale)
        .ticks(interval)
        .tickFormat((date) => {
            if (d3.timeYear(date).getTime() === date.getTime()) { // enters if its first day of a year
                return d3.timeFormat('%Y')(date);
            }
            return getD3TimeFormat(aggregation)(date);
        })
        .tickSizeOuter(0);

    useEffect(
        () => {
            d3.select(gx.current).call(axisBottom);
        },
        [gx, axisBottom],
    );

    const calcWidth = (date) => {
        const next = getD3Time(aggregation).offset(date, 1);
        return (xScale(next) - xScale(date)) - barPadding;
    };

    const onBoardDate = getD3Time(aggregation)(onboardingDate);

    const getOnboardingDateXPosition = () => {
        const xPos = xScale(onBoardDate);
        // checking if the x value is in the chart view port
        if (xPos > marginLeft && xPos < (width - marginRight)) {
            return xPos;
        }
        return undefined;
    };

    const onboardXPosition = getOnboardingDateXPosition();

    const headingRenderer = (
        <div className='d3-bc-hwrapper'>
            <div className='d3-bc-heading'>
                {heading}
            </div>
            {
                enableGraphTypeDropdown
                    ? (
                        <FormDropdown
                            label=''
                            options={GraphTypeOptions}
                            input={{
                                value: graphType,
                                onChange: handleGraphTypeChange,
                                placeholder: 'Choose...',
                            }}
                            cacheKey='bar-chart-toggle-graph'
                            extStyles={{
                                container: 'frcsbWrapper d3-bc-gtd',
                            }}
                        />
                    )
                    : null
            }
        </div>
    );

    const dataPointsXaxisValue = (sample, index) => {
        if (graphType.value === STACKED) return (xScale(sample.data[0]) - (calcWidth(sample.data[0]) / 2));
        return (xScale(sample.data[0]) + (calcWidth(sample.data[0]) / barsCount) * index)
        - (calcWidth(sample.data[0]) / 2);
    };

    return (
        <React.Fragment>
            {
                headingPlacement === 'top' && heading
                    ? headingRenderer : null
            }
            {
                legendPlacement === 'top'
                    ? <Legend data={legendKeys} color={color} maxWidth={width} />
                    : null
            }
            <div style={{
                height: 'auto', width,
            }}
            >
                <svg
                    viewBox={`0 0 ${width} ${height}`}
                    style={{
                        maxWidth: `${width}px`,
                        height: '100%',
                        font: '10px sans-serif',
                        overflow: 'visible',
                    }}
                    ref={svgRef}
                >
                    <defs>
                        <clipPath id={`clip-${barKey}`} transform={`translate(${marginLeft})`}>
                            <rect width={width - marginLeft} startOffset={marginLeft} height={height} />
                        </clipPath>
                    </defs>
                    {
                        Array.isArray(horizontalRefLines) && horizontalRefLines.map((xrefLine) => (
                            !Number.isNaN(xrefLine.value) && (
                                <line
                                    x1='40'
                                    y1={yScale(xrefLine.value)}
                                    x2='1100'
                                    y2={yScale(xrefLine.value)}
                                    strokeDasharray='0 4 0'
                                    stroke={color(xrefLine.label)}
                                    strokeWidth={1}
                                />
                            )
                        ))
                    }
                    <g id='g'>
                        {stacked.map((stack, index) => (
                            <g>
                                {stack.map((item, sIndex) => {
                                    const samplePoint = [];

                                    item.data[1].values().forEach((value) => {
                                        samplePoint.push(value);
                                    });

                                    const bwidth = graphType.value === STACKED ? calcWidth(item.data[0]) : calcWidth(item.data[0]) / barsCount;
                                    const barX = dataPointsXaxisValue(item, index);
                                    const barWidth = calcWidth(item.data[0]);
                                    const uniqueKey = `${index},${sIndex}`;

                                    const { annotations } = samplePoint[0];
                                    if (bwidth <= 0) return null;

                                    // return null when bar is out of view port
                                    if ((barWidth + barX) < 0 || barX > (width - marginRight)) {
                                        return null;
                                    }

                                    return (
                                        <React.Fragment>
                                            {
                                                index === 0 && Array.isArray(annotations) && annotations.length > 0
                                                && (dataPointsXaxisValue(item, index)) > 50 && (
                                                    <React.Fragment>
                                                        {
                                                            annotations.map((annotationItem, annotationIndex) => (
                                                                <text
                                                                    x={dataPointsXaxisValue(item, index)}
                                                                    y={yScale(item[1] - item[0]) + (annotationIndex * 15)}
                                                                    fill={color(annotationItem?.label)}
                                                                    fontSize='40px'
                                                                >*
                                                                </text>
                                                            ))
                                                        }

                                                    </React.Fragment>
                                                )
                                            }
                                            {
                                                graphType.value === STACKED ? (
                                                    <rect
                                                        x={barX}
                                                        y={yScale(item[1])}
                                                        width={barWidth}
                                                        key={uniqueKey}
                                                        height={yScale(item[0]) - yScale(item[1])}
                                                        style={{ mixBlendMode: 'multiply' }}
                                                        fill={color(stack.key)}
                                                        clipPath={`url(#clip-${barKey})`}
                                                        onClick={() => handleOnBarClick(sIndex, annotations)}
                                                    >
                                                        <ToolTip
                                                            bars={bars}
                                                            aggregation={aggregation}
                                                            yValue={yValue}
                                                            data={stacked}
                                                            indexKey={sIndex}
                                                            precession={tooTipPrecession}
                                                        />
                                                    </rect>
                                                )
                                                    : (
                                                        <rect
                                                            x={dataPointsXaxisValue(item, index)}
                                                            y={yScale(item[1] - item[0])}
                                                            width={calcWidth(item.data[0]) / barsCount}
                                                            height={yScale(item[0]) - yScale(item[1])}
                                                            style={{ mixBlendMode: 'multiply' }}
                                                            fill={color(stack.key)}
                                                            clipPath={`url(#clip-${barKey})`}
                                                            key={uniqueKey}
                                                            onClick={() => handleOnBarClick(sIndex, annotations)}
                                                        >
                                                            <ToolTip
                                                                bars={bars}
                                                                aggregation={aggregation}
                                                                yValue={yValue}
                                                                data={stacked}
                                                                indexKey={sIndex}
                                                                precession={tooTipPrecession}
                                                            />
                                                        </rect>
                                                    )
                                            }

                                        </React.Fragment>
                                    );
                                })}
                            </g>
                        ))}
                    </g>
                    {
                        onboardXPosition
                            ? (
                                <g clipPath={`url(#clip-${barKey})`}>
                                    <text
                                        x={onboardXPosition}
                                        y={(height - marginBottom - marginTop) / 2}
                                        textAnchor='middle'
                                        transform={`rotate(90 ${onboardXPosition} ${(height - marginBottom - marginTop) / 2})`}
                                        dy={15}
                                    >
                                        Onboarding Date
                                    </text>
                                    <line
                                        x1={onboardXPosition}
                                        y1={marginTop}
                                        x2={onboardXPosition}
                                        y2={height - marginBottom}
                                        strokeWidth={4}
                                        stroke='gray'
                                        strokeDasharray='5,5'
                                    />
                                </g>
                            )
                            : null
                    }
                    <g ref={gx} transform={`translate(0,${height - marginBottom})`} />
                    <g ref={gy} transform={`translate(${marginLeft},0)`} />
                </svg>
                {
                    selectedIndex >= 0
                        ? (
                            <BarSummary
                                bars={bars}
                                aggregation={aggregation}
                                yValue={yValue}
                                data={stacked}
                                indexKey={selectedIndex}
                                precession={tooTipPrecession}
                                width={width - marginLeft}
                                annotations={selectedAnnotations}
                            />
                        )
                        : null
                }
                {
                    headingPlacement === 'bottom' && heading
                        ? headingRenderer : null
                }
                {
                    legendPlacement === 'bottom'
                        ? <Legend data={legendKeys} color={color} maxWidth={width} />
                        : null
                }
            </div>
        </React.Fragment>
    );
};

export default BarChartV2;

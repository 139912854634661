/**
 * @file ActionDialog: Table Action Dialog
 */

import React, { memo } from 'react';

import { DropdownContent, DropdownWrapper } from '../../index';

import './style.scss';

const ActionDialog = (props) => {
    const {
        item, children, openedActionsCardId, setOpenedActionsCardId,
    } = props;

    const onOutsideClickHandler = () => {
        setOpenedActionsCardId('');
    };

    const onLabelClick = (id) => () => {
        setOpenedActionsCardId(id);
    };

    return (
        <DropdownWrapper
            visible={openedActionsCardId === item.id}
            onOutsideClickHandler={onOutsideClickHandler}
        >
            <div
                onClick={onLabelClick(item.id)}
                onKeyDown={onLabelClick(item.id)}
                role='button'
                tabIndex={0}
                className='table-ad-label'
            >
                ...
            </div>
            <DropdownContent
                visible
                extClasses={{
                    container: [
                        'table-ad-cc height-transition',
                        openedActionsCardId === item.id && 'table-ad-cc--active',
                    ],
                }}
            >
                {children}
            </DropdownContent>
        </DropdownWrapper>
    );
};

export default memo(ActionDialog);

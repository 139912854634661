/*
* @file LogFilter
* Page to set the filter condition for logs for the Admins and Super Admins
*/

import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedSelector } from '../../hooks';
import {
    Button, DropdownWrapper, DropdownContent, LoaderOrError,
} from '../../components';
import { isNilOrEmpty } from '../../utils';

import { setLogFilterAction } from '../LogsInfo/actions';
import { makeSelectLogFilter } from '../LogsInfo/selectors';

import { FILTER_BY_LIST, FILTER_BY_LIST_MAP } from './constants';
import './styles.scss';

const LogFilter = () => {
    const dispatch = useDispatch();

    const filter = useMemoizedSelector(makeSelectLogFilter, ['logs']);

    const [showFilterByDD, setFilterByDD] = useState(true);
    const [filterBy, setFilterBy] = useState('');
    const [range, setRange] = useState({ start: '', end: '' });
    const [disabled, setDisabled] = useState(true);
    const [err, setErr] = useState('');

    useEffect(() => {
        if (range?.start > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [range]);

    const toggleDD = (key) => () => {
        if (key === 'filterBy') {
            setFilterByDD(!showFilterByDD);
        }
    };

    const handleDDChange = (key, value) => {
        toggleDD(key)();
        setFilterBy(value);
    };

    const validate = (value) => {
        const digit = /^\d+$/;

        if (!isNilOrEmpty(value) && !(digit.test(value))) {
            setErr('Only digits are allowed for Ticket ID');
            return false;
        }

        return true;
    };

    const handleTicketIdChange = (condtion) => (e) => {
        const { value } = e.target;

        setErr('');

        if (!validate(value)) return;

        if (condtion === 'start') {
            setRange({ ...range, start: value });
        } else if (condtion === 'end') {
            setRange({ ...range, end: value });
        } else {
            setErr('Invalid field');
        }
    };

    const handleFilter = () => {
        const filterCondition = filterBy;

        if (filterCondition === FILTER_BY_LIST_MAP.ticketId) {
            if (!isNilOrEmpty(range.start) && !isNilOrEmpty(range.end)) {
                if (Number(range.start) > Number(range.end)) {
                    setErr('Start value should be less than End value');
                    return;
                }
            }

            dispatch(setLogFilterAction({
                ticketId: {
                    ...range,
                },
            }));

            setRange({ start: '', end: '' });
        }

        setErr('');
    };

    return (
        <div className='log-filter'>
            <div className='log-filter-cont'>
                <div className='fcfsWrapper'>
                    <div className='log-filter-label'>FILTER CONDITION</div>
                    <DropdownWrapper
                        visible={showFilterByDD}
                        onOutsideClickHandler={toggleDD('filterBy')}
                        extClasses={{
                            container: 'custom-dd log-filter-dd',
                        }}
                    >
                        <div onClick={toggleDD('filterBy')} onKeyDown={() => {}} role='listbox' tabIndex={0}>
                            {filterBy || 'Choose...'}
                        </div>
                        <DropdownContent
                            visible
                            extClasses={{
                                container: ['custom-dd__cc log-filter-dd__cc'],
                            }}
                            extStyles={{
                                height: showFilterByDD ? 'fit-content' : 0,
                                overflow: showFilterByDD ? 'auto' : 'hidden',
                                margin: '2px 0',
                            }}
                        >
                            {
                                FILTER_BY_LIST.map((item) => (
                                    <div
                                        key={item}
                                        className='custom-dd__cl'
                                        onClick={() => handleDDChange('filterBy', item)}
                                        onKeyDown={() => {}}
                                        role='menuitem'
                                        tabIndex={0}
                                    >
                                        {item}
                                    </div>
                                ))
                            }
                        </DropdownContent>
                    </DropdownWrapper>
                </div>
                {
                    filterBy === FILTER_BY_LIST_MAP.ticketId ? (
                        <div className='fcfsWrapper form-group'>
                            <label htmlFor='range'>RANGE</label>
                            <input
                                id='start'
                                type='text'
                                className='form-control'
                                placeholder='Start'
                                value={range.start}
                                onChange={handleTicketIdChange('start')}
                            />
                            <input
                                id='end'
                                type='text'
                                className='form-control'
                                placeholder='End'
                                value={range.end}
                                onChange={handleTicketIdChange('end')}
                                disabled={disabled}
                            />
                            <Button
                                v2
                                label='Filter'
                                primary
                                extClasses='af-btn'
                                onClick={handleFilter}
                            />
                        </div>
                    ) : null
                }
            </div>
            {filter.size !== 0 && !showFilterByDD ? (
                <div className='log-filter-store frccWrapper'>
                    <div className='fccWrapper'>Log Filter Details : </div>
                    {
                        Object.keys(filter).map((item) => {
                            if (item === 'ticketId') {
                                return (
                                    <div key={item}>
                                        <span>
                                            TICKET ID
                                        </span> =&gt; {filter[item].start} - {filter[item].end ? filter[item].end : '∞'}
                                    </div>
                                );
                            }
                            // Other conditions can be added in the future
                            return (
                                <div key={item}>
                                    <span>{item}</span> : {filter[item]}
                                </div>
                            );
                        })
                    }
                </div>
            ) : null}
            <LoaderOrError errorLabel={err} />
        </div>
    );
};

export default memo(LogFilter);

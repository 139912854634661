import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { WAITLIST_ROOT } from '../../api/routes';

import {
    GET_FREELANCER_INFO, SET_FREELANCER_INFO, ERR_FREELANCER_INFO,
    UPDATE_FL_WL_STATUS, ERR_UPDATE_FL_WL_STATUS, GET_PENDING_STATUS_FL_LIST,
    SET_PENDING_STATUS_FL_LIST, ERR_PENDING_STATUS_FL_LIST,
} from './constants';

function* getFreelancerInfo(action) {
    const { ticketId } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WAITLIST_ROOT}/freelancer?ticketId=${ticketId}`
        );

        yield put({ type: SET_FREELANCER_INFO, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_FREELANCER_INFO, data: { err: e.message } });
    }
}

function* updateFreelancerWLStatus(action) {
    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${WAITLIST_ROOT}/freelancer`,
            action.data
        );

        toastify('Action has been performed successfully', 'success');

        const { ticketId, from, to } = action.data;

        if (ticketId) { // waitlist approver
            yield call(getFreelancerInfo, { data: { ticketId } });
        } else if (from && to) { // waitlist admin approver
            yield put({ type: SET_FREELANCER_INFO, data: { freelancerInfo: {} } });
            yield call(getPendingStatusFreelancerList, { data: { from, to } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_UPDATE_FL_WL_STATUS, data: { err: e.message } });
    }
}

function* getPendingStatusFreelancerList(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WAITLIST_ROOT}/freelancer-list?${queryString}`
        );

        yield put({ type: SET_PENDING_STATUS_FL_LIST, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_PENDING_STATUS_FL_LIST, data: { err: e.message } });
    }
}

export default function* waitlistSaga() {
    yield all(
        [
            yield takeLatest(GET_FREELANCER_INFO, getFreelancerInfo),
            yield takeLatest(UPDATE_FL_WL_STATUS, updateFreelancerWLStatus),
            yield takeLatest(GET_PENDING_STATUS_FL_LIST, getPendingStatusFreelancerList),
        ]
    );
}

/**
 * @file V2 Facematch review constants
 */

import { RISK_OPS_COMMON_STATE } from '../RiskOps/constants';

export const FACEMATCH_REVIEW_KEY = 'facematchReviewV2';

export const DEV_ACTION_TYPES = ['MARK_FACEMATCH_PASSED', 'RETRY_LIVENESS'];

// value constants
const PAYLOAD_TYPE_FACEMATCH = 'PAYLOAD_TYPE_FACEMATCH';
export const PAYLOAD_TYPE_FACEMATCH_SAMPLE = 'PAYLOAD_TYPE_FACEMATCH_SAMPLE';
const PAYLOAD_TYPE_AFU_FACEMATCH = 'PAYLOAD_TYPE_AFU_FACEMATCH';
export const PAYLOAD_TYPE_L2_FACEMATCH = 'PAYLOAD_TYPE_QA_FACEMATCH';
export const PAYLOAD_TYPE_PRE_APPROVED_LOAN_FACEMATCH = 'PAYLOAD_TYPE_FACEMATCH_PRE_APPROVED_LOAN_APPLICATION';

export const QUEUE_PAYLOAD_TYPES = [
    PAYLOAD_TYPE_FACEMATCH,
    PAYLOAD_TYPE_FACEMATCH_SAMPLE,
    PAYLOAD_TYPE_AFU_FACEMATCH,
    PAYLOAD_TYPE_L2_FACEMATCH,
    PAYLOAD_TYPE_PRE_APPROVED_LOAN_FACEMATCH,
];

export const PRE_APPROVED_LOAN_MANUAL_REVIEW = 'PRE_APPROVED_LOAN_MANUAL_REVIEW';

export const DEV_ACTIONS_FOR_FACEMATCH_PRE_APPROVED_LOAN = [PRE_APPROVED_LOAN_MANUAL_REVIEW];

const PAYLOAD_DD_OG_STATE = {
    value: PAYLOAD_TYPE_FACEMATCH,
    isVisible: false,
};

export const FACEMATCH_SPECIFIC_STATE = {
    payloadType: PAYLOAD_DD_OG_STATE,
    videoFrameImg: '',
    kycImg: '',
};

export const FACEMATCH_REDUCER_STATE = {
    ...RISK_OPS_COMMON_STATE,
    ...FACEMATCH_SPECIFIC_STATE,
};

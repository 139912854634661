/**
 *
 * Liveness Status
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import {
    Button, DropdownContent, DropdownWrapper, ExpandableCard, FormWithSingleInput, Loader,
    MonorailIdInput, HorizontalIdNavbar,
} from '../../components';
import { injectReducer, injectSaga, validators } from '../../utils';

import {
    getLivenessStatusList, updateLivenessStatus, setMonorailId,
} from './actions';
import {
    makeSelectLoading, makeSelectStatusList, makeSelectMonorailInfo,
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const LivenessStatus = () => {
    const dispatch = useDispatch();
    const actorIdRef = useRef(null);

    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const statusList = useSelector(makeSelectStatusList(), shallowEqual);
    const monorailInfo = useSelector(makeSelectMonorailInfo(), shallowEqual);

    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(false);
    const [formErrLabel, setFormErrLabel] = useState('');
    const [actorId, setActorId] = useState('');
    const [imagePath, setImagePath] = useState('');
    const [livenessStatus, setLivenessStatus] = useState('');
    const [reason, setReason] = useState('');
    const [showLivenessStatusDropdown, setLivenessStatusDropdown] = useState(false);

    const { id: monorailId } = monorailInfo;

    // set new ticket to true on initial render or when no monorail id present
    const [newTicket, setNewTicket] = useState(!monorailId);
    // set navbar items to empty array on initial render or when no monorail id present
    const [navItems, setNavItems] = useState(monorailId && [{ id: monorailId }] || []);

    useEffect(() => {
        dispatch(getLivenessStatusList());
    }, [dispatch]);

    useEffect(() => {
        if (monorailId && isSearchCardExpanded) {
            actorIdRef.current.focus();
        }
    }, [monorailId, isSearchCardExpanded]);

    const resetMonorailId = () => {
        setNewTicket(true);
        setNavItems([]);
        dispatch(setMonorailId({ value: '' }));
    };

    const handleMonorailIdSubmit = (value) => {
        setActorId('');
        setImagePath('');
        setLivenessStatus('');
        setReason('');
        setIsSearchCardExpanded(true);
        setNewTicket(false);
        setNavItems([{ id: value }]);
        dispatch(setMonorailId({ value }));
    };

    const renderIdNavbar = (
        <HorizontalIdNavbar
            isFixed
            hasSingleItem
            isNewTicketActive={newTicket}
            activeId={monorailId}
            navItems={navItems}
            onItemCloseIconClick={resetMonorailId}
        />
    );

    if (!monorailId) {
        return (
            <React.Fragment>
                {renderIdNavbar}
                <MonorailIdInput submitMonorailId={handleMonorailIdSubmit} />
            </React.Fragment>
        );
    }

    const handleInputChange = key => event => {
        const value = event.target.value;

        switch (key) {
            case 'actorId':
                setActorId(value);
                break;

            case 'imagePath':
                setImagePath(value);
                break;

            case 'reason':
                setReason(value);
                break;

            default:
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const modifiedActorId = actorId.trim();

        if (!modifiedActorId || !livenessStatus || !reason) {
            setFormErrLabel('Please fill all the * marked fields');
            return;
        }

        if (reason && !validators.isInputtedStringValidv2(reason)) {
            setFormErrLabel('Please enter a valid reply');
            return;
        }

        setFormErrLabel('');

        const callUpdateLivenessStatusAction = () => new Promise((resolve) => {
            dispatch(updateLivenessStatus({
                monorailId,
                actorId: modifiedActorId,
                imagePath: imagePath.trim(),
                livenessStatus,
                reason,
                resolve,
            }));
        });

        callUpdateLivenessStatusAction().then(() => {
            setActorId('');
            setImagePath('');
            setLivenessStatus('');
            setReason('');
        });
    };

    return (
        <React.Fragment>
            {renderIdNavbar}
            <div className='lstus-cr'>
                <ExpandableCard
                    extClasses='lstus-ec'
                    heading='Update Liveness Status'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='lstus-sc'>
                        <FormWithSingleInput
                            hideErrLabel
                            disableFormSubmitOnEnter
                            extClasses={{
                                container: 'lstus-fc--m0',
                                label: 'lstus-ff-lb',
                                inputField: 'lstus-ff-ip',
                            }}
                            label='Actor Id*'
                            labelId='actor-id'
                            input={{
                                value: actorId,
                                onChange: handleInputChange('actorId'),
                                ref: actorIdRef,
                            }}
                            onFormSubmit={handleSubmit}
                        />
                        <FormWithSingleInput
                            hideErrLabel
                            disableFormSubmitOnEnter
                            extClasses={{
                                container: 'lstus-fc--m0',
                                label: 'lstus-ff-lb',
                                inputField: 'lstus-ff-ip',
                            }}
                            label='Image Path'
                            labelId='image-path'
                            input={{
                                value: imagePath,
                                onChange: handleInputChange('imagePath')
                            }}
                            onFormSubmit={handleSubmit}
                        />
                        <div className='frcWrapper'>
                            <div className='lstus-ff-lb'>Select Status*</div>
                            <DropdownWrapper
                                visible={showLivenessStatusDropdown}
                                onOutsideClickHandler={() => setLivenessStatusDropdown(!showLivenessStatusDropdown)}
                                extClasses={{
                                    container: 'custom-dd lstus-dd'
                                }}
                            >
                                <div onClick={() => setLivenessStatusDropdown(!showLivenessStatusDropdown)}>
                                    {livenessStatus ? livenessStatus.split('_').join(' ').toLowerCase() : 'Choose...'}
                                </div>
                                <DropdownContent
                                    visible
                                    extClasses = {{
                                        container: [
                                            'custom-dd__cc lstus-dd__cc height-transition',
                                            showLivenessStatusDropdown && 'lstus-dd__cc--ac']
                                    }}
                                >
                                    {
                                        statusList.map(item => (
                                            <div
                                                key={item}
                                                className='custom-dd__cl'
                                                onClick={() => {
                                                    setLivenessStatus(item);
                                                    setLivenessStatusDropdown(false);
                                                }}
                                            >
                                                {item}
                                            </div>
                                        ))
                                    }
                                </DropdownContent>
                            </DropdownWrapper>
                        </div>
                        <div className='form-container lstus-ff-rfc'>
                            <label
                                className='form-wrapper-label lstus-ff-rfc__lb'
                                htmlFor='wl-reason'
                            >
                                Reason*
                            </label>
                            <textarea
                                id='lstus-reason'
                                className='lstus-ff-rfc__ta'
                                value={reason}
                                onChange={handleInputChange('reason')}
                                rows={4}
                                cols={70}
                            />
                        </div>
                        {formErrLabel ? <div className='err-label lstus-el'>{formErrLabel}</div> : null}
                        <Button
                            extClasses={`lstus-sc-cta ${formErrLabel && 'lstus-sc-cta--err'}`}
                            primary
                            label='Search'
                            onClick={handleSubmit}
                        />
                    </div>
                </ExpandableCard>
            </div>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'livenessStatus', reducer });
const withSaga = injectSaga({ key: 'livenessStatus', saga });

export default compose(
    withReducer,
    withSaga,
)(LivenessStatus);

import { createSelector } from 'reselect';

const selectReferrals = state => state.get('referrals');
const selectUserReferralInfo = state => state.getIn(['referrals', 'userReferralInfo']);
const selectUserReferrerInfo = state => state.getIn(['referrals', 'userReferrerInfo']);
const selectUserRefereesList = state => state.getIn(['referrals', 'userRefereesList']);
const selectUserRefereeInfo = state => state.getIn(['referrals', 'userRefereeInfo']);
const selectReferralMetaInfo = state => state.getIn(['referrals', 'referralMetaInfo']);

const makeSelectUserReferralInfo = () => createSelector(
    selectUserReferralInfo,
    substate => substate.toJS(),
);

const makeSelectUserReferrerInfo = () => createSelector(
    selectUserReferrerInfo,
    substate => substate.toJS(),
);

const makeSelectUserRefereesList = () => createSelector(
    selectUserRefereesList,
    substate => substate.toJS(),
);

const makeSelectUserRefereeInfo = () => createSelector(
    selectUserRefereeInfo,
    substate => substate.toJS(),
);

/*
const makeSelectUserReferralInfo = () => createSelector(
    selectUserReferralInfo,
    (substate) => {
        const userReferralInfo = substate.toJS();
        const { referralInfo, rewardInfo } = userReferralInfo;

        return {
            referralData: getFormattedReferralInfo(referralInfo),
            rewardData: getFormattedRewardInfo(rewardInfo),
        };
    }
);

const makeSelectUserReferrerInfo = () => createSelector(
    selectUserReferrerInfo,
    (substate) => {
        const userReferrerInfo = substate.toJS();
        const { referrerInfo, rewardInfo } = userReferrerInfo;

        return getFormattedReferrerInfo({
            ...referrerInfo,
            ...rewardInfo,
        });
    }
);

const makeSelectUserRefereeInfo = () => createSelector(
    selectUserRefereeInfo,
    (substate) => {
        const userRefereeInfo = substate.toJS();
        const { refereeInfo, rewardInfo } = userRefereeInfo;

        return {
            data: getFormattedRefereeInfo({
                ...refereeInfo,
                ...rewardInfo
            }),
        };
    }
);
*/

const makeSelectReferralMetaInfo = () => createSelector(
    selectReferralMetaInfo,
    substate => substate.toJS(),
);

export {
    makeSelectUserReferralInfo,
    makeSelectUserReferrerInfo,
    makeSelectUserRefereesList,
    makeSelectUserRefereeInfo,
    makeSelectReferralMetaInfo,
};

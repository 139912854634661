/**
 * @file TableDetailScreen: Generic Component to show the Table related data.
 */

import React, { memo } from 'react';

import { useScrollToPosition } from '../../../hooks';

import TableDetails from '../TableDetails';
import CardBanner from '../CardBanner';

import '../style.scss';

const TableDetailScreen = (props) => {
    const {
        data, title, ticketId, currentScreen,
    } = props;
    useScrollToPosition();
    return (
        <CardBanner title={title} discardCurrentScreen={currentScreen} ticketId={ticketId}>
            <TableDetails
                data={data}
            />
        </CardBanner>
    );
};

export default memo(TableDetailScreen);

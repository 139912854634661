const getFormattedTicketInfo = (data) => [
    {
        label: 'Ticket ID',
        value: data.ticketId,
    },
    {
        label: 'Subject',
        value: data.subject,
        copy: 'Copy Link', 
    },
    {
        label: 'Type',
        value: data.type,
    },
    {
        label: 'Status',
        value: data.status,
    },
    {
        label: 'Priority',
        value: data.priority,
    },
    {
        label: 'Product Category',
        value: data.productCategory,
    },
    {
        label: 'Product Category Details',
        value: data.productCategoryDetails,
    },
    {
        label: 'Private Notes',
        value: data.privateNotes,
        isArray: true,
    },
    {
        label: 'Public Notes',
        value: data.publicNotes,
        isArray: true,
    },
];

export {
    getFormattedTicketInfo,
};

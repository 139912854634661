/*
 *
 * LivenessVideo actions
 *
 */

import {
    GET_LIVENESS_VIDEO_URL, SET_MONORAIL_ID,
} from './constants';

export const getLivenessVideoURL = data => ({
    type: GET_LIVENESS_VIDEO_URL,
    data
});

export const setMonorailId = data => ({
    type: SET_MONORAIL_ID,
    data
});

/**
 * @file Sagas for FreshdeskTicketInfo
 */

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { clientApiWrapper, toastify } from '../../utils';

import { FRESHDESK_CATEGORIES_ENDPOINT, FRESHDESK_DETAILS_ENDPOINT } from '../../api/routes';
import { getErrorObjectForSaga, showErrorToastMsg } from '../../utils/errFormatter';

import {
    ERR_FRESHDESK_TICKET_INFO, GET_FRESHDESK_CATEGORIES, GET_FRESHDESK_TICKET_INFO, SET_FRESHDESK_CATEGORIES, SET_FRESHDESK_TICKET_INFO,
    SUCCESSFUL_UPDATE_FRESHDESK_TICKET_INFO, UPDATE_FRESHDESK_TICKET_INFO,
} from './constants';

const getErrActionObj = (action) => ({
    type: ERR_FRESHDESK_TICKET_INFO,
    data: action.data,
});

function* getFreshdeskCategoriesSaga(action) {
    try {
        const categoryInfo = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${FRESHDESK_CATEGORIES_ENDPOINT}`,
        );

        yield put({ type: SET_FRESHDESK_CATEGORIES, data: { categoryInfo } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrActionObj(action), error));
    }
}

function* getFreshdeskTicketInfoSaga(action) {
    const { ticketId } = action.data;

    try {
        const ticketInfo = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${FRESHDESK_DETAILS_ENDPOINT}/${ticketId}`,
        );

        const ticketInfoKv = Object.keys(ticketInfo).map((keyName) => ({
            label: keyName,
            value: ticketInfo[keyName],
        }));

        yield put({ type: SET_FRESHDESK_TICKET_INFO, data: { ticketInfo: ticketInfoKv, ticketId } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrActionObj(action), error));
    }
}

function* updateFreshdeskTicketInfoSaga(action) {
    const { ticketId } = action.data;

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.put],
            `${FRESHDESK_DETAILS_ENDPOINT}/${ticketId}`,
            action.data,
        );

        toastify('Successfully updated ticket information', 'success');
        yield put({ type: SUCCESSFUL_UPDATE_FRESHDESK_TICKET_INFO });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrActionObj(action), error));
    }
}

function* freshdeskTicketInfoSaga() {
    yield all(
        [
            yield takeLatest(GET_FRESHDESK_CATEGORIES, getFreshdeskCategoriesSaga),
            yield takeLatest(GET_FRESHDESK_TICKET_INFO, getFreshdeskTicketInfoSaga),
            yield takeLatest(UPDATE_FRESHDESK_TICKET_INFO, updateFreshdeskTicketInfoSaga),
        ],
    );
}

export default freshdeskTicketInfoSaga;

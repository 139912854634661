/*
 *
 * LivenessStatus constants
 *
 */

export const GET_LIVENESS_STATUS_LIST = 'app/LivenessStatus/GET_LIVENESS_STATUS_LIST';
export const SET_LIVENESS_STATUS_LIST = 'app/LivenessStatus/SET_LIVENESS_STATUS_LIST';
export const SET_LIVENESS_STATUS_LOADER = 'app/LivenessStatus/SET_LIVENESS_STATUS_LOADER';
export const UPDATE_LIVENESS_STATUS = 'app/LivenessStatus/UPDATE_LIVENESS_STATUS';
export const SET_MONORAIL_ID = 'app/LivenessStatus/SET_MONORAIL_ID';

export const LIVENESS_STATUS_LIST = [
    'VERIFY_PASS_LIVENESS',
    'VERIFY_PASS_FACEMATCH',
];

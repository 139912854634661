/**
 *
 * @file RenderView: Renders the page according to account details view.
 *
 */
import React, { memo } from 'react';

import { VIEW_ACCOUNT_BASIC_DETAILS, VIEW_ACCOUNT_STAGES_DETAILS } from '../constants';

import USSAccountBasicDetails from './USSAccountBasicDetails';
import USSAccountStageDetails from './USSAccountStageDetails';

const RenderView = (props) => {
    const {
        accountView, ticketId,
    } = props;

    switch (accountView) {
        case VIEW_ACCOUNT_BASIC_DETAILS: {
            return (
                <div className='uss'>
                    <USSAccountBasicDetails ticketId={ticketId} />
                </div>
            );
        }

        case VIEW_ACCOUNT_STAGES_DETAILS: {
            return (
                <div className='uss'>
                    <USSAccountStageDetails ticketId={ticketId} />
                </div>
            );
        }

        default:
            return null;
    }
};

export default memo(RenderView);

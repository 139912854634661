/**
 * @file Manages the action functions for Banners component
 */

import {
    SET_BANNER_FORM_FIELDS, RESET_BANNER_FORM_FIELDS, CREATE_BANNER,
    UPDATE_BANNER, GET_BANNER_LIST, SET_BANNER_INFO,
} from './constants';

const setBannerFormFields = (data) => ({
    type: SET_BANNER_FORM_FIELDS,
    data,
});

const resetBannerFormFields = () => ({
    type: RESET_BANNER_FORM_FIELDS,
});

const createBanner = (data) => ({
    type: CREATE_BANNER,
    data,
});

const updateBanner = (data) => ({
    type: UPDATE_BANNER,
    data,
});

const setBannerInfo = (data) => ({
    type: SET_BANNER_INFO,
    data,
});

const getBannerList = (data) => ({
    type: GET_BANNER_LIST,
    data,
});

export {
    setBannerFormFields,
    resetBannerFormFields,
    createBanner,
    setBannerInfo,
    updateBanner,
    getBannerList,
};

/**
 * @file VerificationCasesTable
 * Renders the verification cases for data ops as well as CX agent view
 */

import React, { memo } from 'react';

import { Button, Table } from '../../../components';

const QUEUE_LABEL_DATA = [
    // {
    //     label: 'Request ID',
    //     key: 'requestId',
    // },
    {
        label: 'Actor Name',
        key: 'actorName',
    },
    {
        label: 'Actor Phone Number',
        key: 'actorPhoneNumber',
    },
    {
        label: 'Txn ID',
        key: 'txnId',
    },
    // {
    //     label: 'Remitter Name',
    //     key: 'remitterName',
    // },
    {
        label: 'Payment Protocol',
        key: 'paymentProtocol',
    },
    {
        label: 'Particulars',
        key: 'particulars',
    },
    {
        label: 'Txn Timestamp',
        key: 'txnTime',
    },
    {
        label: 'Request Timestamp',
        key: 'txnVerificationRequest',
        hasSortConfig: true,
    },
];

const VerificationCasesTable = (props) => {
    const {
        casesList, prevToken, nextToken, getPaginatedVerificationCases, handleVerify, v2Table,
        getSortingDataBasedOnSortingOrder,
    } = props;

    const renderActions = (item, index) => (
        <div className='frfscWrapper'>
            <Button
                link
                label='Check'
                onClick={() => handleVerify(item, index)}
            />
        </div>
    );

    return (
        <Table
            v2={v2Table}
            labelData={QUEUE_LABEL_DATA}
            contentData={casesList}
            renderActionableColumn={renderActions}
            getSortingDataBasedOnSortingOrder={getSortingDataBasedOnSortingOrder}
            prevToken={prevToken}
            nextToken={nextToken}
            paginatedClickHandler={getPaginatedVerificationCases}
            extClasses={{
                container: 'salops-uetb__cr',
            }}
        />
    );
};

export default memo(VerificationCasesTable);

/*
 *
 * LivenessVideo reducer
 *
 */

import { fromJS } from 'immutable';
import {
    GET_LIVENESS_VIDEO_URL, SET_LIVENESS_VIDEO_URL, ERR_LIVENESS_VIDEO_URL,
    SET_MONORAIL_ID,
} from './constants';

const initialState = fromJS({
    monorailInfo: {
        id: '',
    },
    loading: false,
    err: '',
    videoUrl: '',
});

function livenessVideoReducer(state = initialState, action) {
    switch (action.type) {
        case GET_LIVENESS_VIDEO_URL:
            return state
                .set('loading', true)
                .set('err', '');

        case SET_LIVENESS_VIDEO_URL: {
            const { videoUrl } = action.data;

            return state
                .set('loading', false)
                .set('videoUrl', videoUrl);
        }

        case ERR_LIVENESS_VIDEO_URL: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err);
        }

        case SET_MONORAIL_ID: {
            const { value } = action.data;

            if (value) {
                return state.setIn(['monorailInfo', 'id'], value);
            }

            return initialState;
        }

        default:
            return state;
    }
}

export default livenessVideoReducer;

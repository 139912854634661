/**
*
* InputName
*
*/

import React from 'react';
import PropTypes from 'prop-types'
import './style.scss';

class InputName extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { label, input: { name, value, onChange } } = this.props;
    const { first_name, last_name, middle_name } = value;

    return (
      <div className="form-group name">
        <label>{label}</label>
        {// TODO: Change to dropdown
        }
        <div className="input-wrapper">
          <input 
            id={name+"-first-name"} 
            type="text" 
            className="form-control first-name" 
            placeholder={"First Name"} 
            value={first_name}
            onChange={(e) => onChange({ ...value, first_name: e.target.value })}
          />
          <input 
            id={name+"-middle-name"}
            type="text"
            className="form-control middle-name" 
            placeholder={"Middle Name"} 
            value={middle_name}
            onChange={(e) => onChange({ ...value, middle_name: e.target.value })}
          />
          <input 
            id={name+"-last-name"}
            type="text"
            className="form-control last-name" 
            placeholder={"Last Name"} 
            value={last_name}
            onChange={(e) => onChange({ ...value, last_name: e.target.value })}
          />
        </div>
      </div>
    );
  }
}

InputName.propTypes = {

};

export default InputName;

import React from 'react';
import { toast } from 'react-toastify';
import Toast from '../components/Toast';

const TOAST_NOTIFICATION = 'toast-notification';
const TOAST_SUCCESS = 'toast-success';
const TOAST_ERROR = 'toast-error';
const TOAST_WARNING = 'toast-warning';

export const TOAST_CONFIG = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    pauseOnHover: false,
    closeOnClick: false,
    newestOnTop: false,
};

function getConfig(type, autoClose) {
    const config = {
        autoClose
    };
    switch(type) {
        case 'success':
           return {...config ,className: `${TOAST_NOTIFICATION} ${TOAST_SUCCESS}`};
        case 'error':
           return {...config ,className: `${TOAST_NOTIFICATION} ${TOAST_ERROR}`};
        case 'warning':
           return {...config ,className: `${TOAST_NOTIFICATION} ${TOAST_WARNING}`};
        default:
            return {...config, className: `${TOAST_NOTIFICATION}`};
    }
}

function getIconType(type) {
    return type === 'success' ? 'check' : 'warning';
}
const dismissAll = () => toast.dismiss();

export default function toastify(
    message,
    type = 'warning',
    autoClose,
    ...rest
) {
    toast(
        <Toast
            content={message}
            iconType={getIconType(type)}
            dismissAll={dismissAll}
        />,
        {
            ...getConfig(type, autoClose),
            ...rest
        }
    );
}

/*
 *
 * LogsInfo actions
 *
 */

import {
    ERR_SEARCH, GET_LOGS, GET_LOG_INFO, SET_LOG_FILTER,
} from './constants';

export const getLogs = (data) => ({
    type: GET_LOGS,
    data,
});

export const getLogInfo = (data) => ({
    type: GET_LOG_INFO,
    data,
});

export const setLogFilterAction = (data) => ({
    type: SET_LOG_FILTER,
    data,
});

export const setErrSearchAction = (data) => ({
    type: ERR_SEARCH,
    data,
});

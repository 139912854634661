/**
 *
 * ViewAgent
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import ShowInfo from '../../components/ShowInfo';
import Button from '../../components/Button';
import Loader from '../../components/Loader';

import { makeRequest, getAgentInfoV2 } from '../../api/requestFunctions';
import injectReducer from '../../utils/injectReducer';
import { AGENT_PATH } from '../App/routes';

import makeSelectViewAgent from './selectors';
import reducer from './reducer';
import { FormatAgentInfo } from './constants';

import './style.scss';


class ViewAgent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            agentInfo: null,
            isLoading: false,
            error: false,
        }
    }

    componentDidMount = () => {
        this.setState({ isLoading: true }, this.loadAgentInfo());
    }

    loadAgentInfo = () => {
        const { match } = this.props;
        const agentEmail = match.params.agentEmail;
        makeRequest(getAgentInfoV2(agentEmail), {
            onSuccess: (response) => {
                this.setState({ isLoading: false, agentInfo: response.agentInfo });
            },
            onError: (error) => {
                console.log(error);
                this.setState({ isLoading: false, error: error });
            }
        })
    }

    render() {
        const { isLoading, error, agentInfo } = this.state;
        const { history } = this.props;

        return (
            <React.Fragment>
                <div className='agent-info-wrapper'>
                    <div className='header-bar'>
                        <h2>Agent Information</h2>
                        <Button secondary onClick={() => history.push(AGENT_PATH)} label='Back to list' />
                    </div>
                    {isLoading && 'Loading...'}
                    {error && 'Failed to load Agent information'}
                    {agentInfo && <ShowInfo data={FormatAgentInfo(agentInfo)} />}
                </div>
                <Loader visible={isLoading} />
            </React.Fragment>
        );
    }
}

ViewAgent.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    viewagent: makeSelectViewAgent(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'viewAgent', reducer });

export default compose(
    withReducer,
    withConnect,
)(ViewAgent);

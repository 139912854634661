/**
 *  @file Component to get the confirmation for redirecting to the next case id
 */

import React, { memo } from 'react';

import { Button, Modal } from '../../../components';

const PrioritizedTaskModal = (props) => {
    const { visible, handleProceed, handleCancel } = props;

    if (!visible) return null;

    return (
        <Modal
            visible={visible}
            contentLabel='Next prioritized case'
        >
            <div className='txnrisk-modal__hl'>
                Are you sure you want redirect to next prioritized case?
            </div>
            <div className='frcsbWrapper txnrisk-modal__ctas'>
                <Button
                    v2
                    secondary
                    label='Proceed'
                    onClick={handleProceed}
                />
                <Button
                    v2
                    primary
                    label='Cancel'
                    onClick={handleCancel}
                />
            </div>
        </Modal>
    );
};

export default memo(PrioritizedTaskModal);

/**
 * @file OnboardingStages: Component to show the Credit Card Onboarding Stages Table.
 */

import React, { memo } from 'react';

import { Table } from '../../../components';

import { CREDIT_CARD_ONBOARDING_STAGES_DATA } from '../constants';

const OnboardingStages = (props) => {
    const { stageDetails } = props;

    return (
        <React.Fragment>
            <div className='heading4 mb-15 mt-15'>Onboarding Stages</div>
            <Table
                header=''
                labelData={CREDIT_CARD_ONBOARDING_STAGES_DATA}
                contentData={stageDetails}
                nsrLabel='Onboarding not started yet!'
            />
        </React.Fragment>
    );
};

export default memo(OnboardingStages);

/**
 * @file UserProfileAndOnboardingDetailsWrapper - Contains a wrapper component for the Profile and KycOnboarding components
 */
import React, { memo } from 'react';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';

import ProfileReducer from '../Profile/reducer';
import ProfileSaga from '../Profile/saga';
import OnboardingReducer from '../KycOnboarding/reducer';
import OnboardingSaga from '../KycOnboarding/saga';

import Profile from '../Profile';
import KycOnboarding from '../KycOnboarding';

const UserProfileAndOnboardingDetailsWrapper = (props) => {
    const {
        isProfile, isOnboarding, componentProps,
    } = props;

    return (
        <React.Fragment>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {isProfile && (<Profile {...componentProps} />)}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {isOnboarding && (<KycOnboarding {...componentProps} />)}
        </React.Fragment>
    );
};

const withProfileReducer = injectReducer({ key: 'profile', reducer: ProfileReducer });
const withProfileSaga = injectSaga({ key: 'profile', saga: ProfileSaga });
const withOnboardingReducer = injectReducer({ key: 'kycOnboarding', reducer: OnboardingReducer });
const withOnboardingSaga = injectSaga({ key: 'kycOnboarding', saga: OnboardingSaga });

export default compose(
    withProfileReducer,
    withProfileSaga,
    withOnboardingReducer,
    withOnboardingSaga,
)(memo(UserProfileAndOnboardingDetailsWrapper));

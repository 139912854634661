/**
 * @file Common risk ops utilities
 */

import { PAYLOAD_TYPE_FACEMATCH_SAMPLE } from '../FacematchReviewV2/constants';
import { LIVENESS_AND_FACEMATCH_REVIEW_KEY } from '../LivenessAndFacematchReview/constants';
import { PAYLOAD_TYPE_LIVENESS_SAMPLE } from '../LivenessReviewV2/constants';
import { NAMEMATCH_REVIEW_KEY } from '../NamematchReview/constants';
import { USER_RISK_REVIEW_KEY } from '../UserRiskReview/constants';

import {
    OLDEST_CASES_FIRST, DATE_INFO, DD_ITEM_CLS, SORTING_OPTIONS,
} from './constants';

/**
  * The risk ops queues - Liveness, Facematch, Namematch can be filtered for a particular date range.
  * They can also be sorted based on the latest cases first or oldest cases first.
  * This function creates the required object to be sent to the API for filtering and sorting
  * @param {object} fromDate The date from which the user wants to filter
  * @param {object} toDate The date to which the user wants to filter
  * @param {string} sortOption Whether the latest cases should be showed first or oldest cases should be showed first
  * @returns {object} An object containing the filter and sort values
  */
const getAdditionalFilters = (fromDate, toDate, sortOption) => {
    const additionalFilters = {};

    // attach fromDate & toDate only if both values are present
    if (fromDate.value && toDate.value) {
        additionalFilters.fromDate = fromDate.value;
        additionalFilters.toDate = toDate.value;
    }

    // wrap boolean value with string otherwise it does not get sent using getQueryStringFromObject()
    let isFifo = 'false';

    if (sortOption.value === OLDEST_CASES_FIRST) isFifo = 'true';

    additionalFilters.isFifo = isFifo;

    return additionalFilters;
};

const getRiskOpsTableLabels = (containerKey, payloadType) => {
    let commonLabels = [
        {
            label: 'Sr. No',
            key: 'sr_no',
        },
        {
            label: 'Actor ID',
            key: 'actor_id',
        },
    ];

    if (containerKey !== NAMEMATCH_REVIEW_KEY) {
        commonLabels = commonLabels.concat([
            {
                label: 'Created At',
                key: 'created_at',
            },
        ]);
    }

    if ([PAYLOAD_TYPE_LIVENESS_SAMPLE, PAYLOAD_TYPE_FACEMATCH_SAMPLE].includes(payloadType.value)) {
        commonLabels = commonLabels.concat([
            {
                label: 'Attempt Date',
                key: 'attempt_date',
            },
        ]);
    }

    if (containerKey === LIVENESS_AND_FACEMATCH_REVIEW_KEY) {
        commonLabels = commonLabels.concat([
            {
                label: 'Review Type',
                key: 'review_type',
            },
        ]);
    }

    if (containerKey !== USER_RISK_REVIEW_KEY) {
        commonLabels = commonLabels.concat([
            {
                label: 'Request ID',
                key: 'request_id',
            },
        ]);
    }

    return commonLabels;
};

const getIdsFromCase = (curCase) => {
    const { payload, id: recordId, caseId } = curCase;

    const {
        actor_id: actorId, reference_id: referenceId, review_reasons: reviewReasons, external_id: externalId,
    } = curCase[payload];

    return {
        actorId, recordId, referenceId, reviewReasons, externalId, caseId,
    };
};

const getDomainKnowledge = (curCase) => {
    const { payload } = curCase;
    const caseInfo = curCase[payload];
    const { DomainKnowledge } = caseInfo;

    const info = {
        domainKnowledge: {},
    };

    if (DomainKnowledge) {
        info.domainKnowledge = caseInfo[DomainKnowledge];
    }

    return info;
};

const getDataFromLivenessCase = (curCase) => {
    // return empty object if curCase is empty
    if (!curCase || !Object.keys(curCase).length) return {};

    const { payload } = curCase;
    const { request_id: reqId, liveness_request_id: livReqId } = curCase[payload];
    const requestId = reqId || livReqId;

    return {
        requestId,
        ...getIdsFromCase(curCase),
        ...getDomainKnowledge(curCase),
    };
};

const getDataFromFacematchCase = (curCase) => {
    const { payload } = curCase;
    const {
        video_frame: videoFrameImgPath,
        kyc_image: kycImgPath,
        base64_kyc_image_data: base64KycStr,
    } = curCase[payload];

    const { request_id: reqId, facematch_request_id: fmReqId } = curCase[payload];
    const requestId = reqId || fmReqId;

    const base64KycImg = base64KycStr ? `data:image/png;base64, ${base64KycStr}` : '';

    return {
        requestId,
        videoFrameImgPath,
        kycImgPath,
        base64KycImg,
        ...getIdsFromCase(curCase),
    };
};

const getPreFilledValuesObj = (data) => {
    const {
        actorId, requestId, emailId, referenceId, disableRequestId = false, externalId,
    } = data;

    return {
        mergeKey: 'name',
        paramList: [
            { name: 'actor-id', value: actorId, isDisabled: true },
            { name: 'Request Id', value: requestId, isDisabled: disableRequestId },
            { name: 'reference-id', value: referenceId },
            { name: 'external-id', value: externalId, isDisabled: true },
            { name: 'Reviewed By', value: emailId, isDisabled: true },
        ],
    };
};

const getQueueSpecificInfo = (containerKey) => {
    const ddWrapperCls = {
        container: 'custom-dd rops-idd',
    };
    const ddContentCls = {
        container: ['rops-idd__cc'],
    };
    if (containerKey === LIVENESS_AND_FACEMATCH_REVIEW_KEY) {
        ddWrapperCls.container = 'custom-dd rops-idd1';
        ddContentCls.container = ['rops-idd__cc1'];
    }

    return {
        DATE_INFO,
        DD_ITEM_CLS,
        SORTING_OPTIONS,
        DD_CONTENT_CLS: ddContentCls,
        DD_WRAPPER_CLS: ddWrapperCls,
    };
};

const getL2CaseDetailsKVData = (data) => ([
    {
        label: 'Actor ID',
        value: data.actorId,
    },
    {
        label: 'Request ID',
        value: data.requestId,
    },
    {
        label: 'Element ID',
        value: data.elementId,
    },
    {
        label: 'Case Re Reviewed By',
        value: data.reReviewedBy,
    },
]);

export {
    getAdditionalFilters,
    getRiskOpsTableLabels,
    getIdsFromCase,
    getPreFilledValuesObj,
    getDataFromFacematchCase,
    getDataFromLivenessCase,
    getQueueSpecificInfo,
    getL2CaseDetailsKVData,
};

/* eslint-disable jsx-a11y/media-has-caption */
/**
 *
 * Review a single liveness case and update the status
 *
 */

import React, { memo, useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';
import { Button, DisableContextMenu } from '../../../components';
import { downloadFile } from '../../../utils';
import { MetaInfoContext, UserDetailsContext } from '../../../context';

import DevActions from '../../DevActions';
import { CustomerDetailsModal, DequeueCaseModal, LivenessSummaryModal, ViewAnnotationDetails } from '../../RiskOps';
import { getAdditionalFilters } from '../../RiskOps/utils';
import { selectRiskOpsL2AnnotationDetails } from '../../RiskOps/selectors';

import { auditLogMediaDownloadAction, dequeueLivenessCaseAction, getLivenessReviewVideoAction, setLivenessViewAction } from '../actions';
import {
    DEV_ACTION_TYPES, LIVENESS_REVIEW_KEY, PAYLOAD_TYPE_L2_LIVENESS, SET_LIVENESS_L2_ANNOTATION_DETAILS, VIEW_ALL_LIVENESS_CASES,
} from '../constants';
import { selectLivenessPayloadType, selectLivenessVideoUrl, selectCurLivenessCase, selectLivenessQueueFilters } from '../selectors';

const getIdsFromCase = (curLivenessCase) => {
    const { payload, id: recordId } = curLivenessCase;
    const {
        actor_id: actorId,
        request_id: requestId,
    } = curLivenessCase[payload];
    return { actorId, requestId, recordId };
};

const getPreFilledValuesObj = (curLivenessCase, emailId) => {
    const { actorId, requestId } = getIdsFromCase(curLivenessCase);

    return {
        mergeKey: 'label',
        paramList: [
            { label: 'Actor ID', value: actorId, isDisabled: true },
            { label: 'Request ID', value: requestId },
            { label: 'Reviewed By (ex: yourname@epifi.com)', value: emailId, isDisabled: true },
        ],
    };
};

const SingleLivenessCase = () => {
    const metaInfo = useContext(MetaInfoContext);
    const {
        riskOps: { downloadMedia: { allowDownloadEvenIfTrackingApiFails, allowedEmails } },
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    const { basicInfo } = useContext(UserDetailsContext);
    const dispatch = useDispatch();

    // global state
    const livenessVideoUrl = useSelector(selectLivenessVideoUrl(), shallowEqual);
    const livenessPayloadType = useSelector(selectLivenessPayloadType(), shallowEqual);
    const { emailId } = basicInfo;

    const l2AnnotationDetails = useSelector(selectRiskOpsL2AnnotationDetails(LIVENESS_REVIEW_KEY), shallowEqual);
    const curLivenessCase = useSelector(selectCurLivenessCase(), shallowEqual);
    const { actorId, requestId, recordId } = getIdsFromCase(curLivenessCase);
    const { index } = curLivenessCase;

    const { fromDate, toDate, sortOption } = useSelector(selectLivenessQueueFilters(), shallowEqual);

    // local state
    const [showDequeueCaseModal, setDequeueCaseModalVisibility] = useState(false);
    const [preFilledValues, setPreFilledValues] = useState(null);
    const [showLivenessSummaryModal, setLivenessSummaryModalVisibility] = useState(false);
    const [showCustomerDetailsModal, setCustomerDetailsModalVisibility] = useState(false);

    const isL2Queue = livenessPayloadType.value === PAYLOAD_TYPE_L2_LIVENESS;
    const annotationCaseDetails = {
        isL2Queue,
        queryParams: {
            actorId,
            requestId,
            payloadType: livenessPayloadType.value,
        },
        actionType: SET_LIVENESS_L2_ANNOTATION_DETAILS,
    };

    useEffect(() => {
        setPreFilledValues(getPreFilledValuesObj(curLivenessCase, emailId));
        dispatch(getLivenessReviewVideoAction(getIdsFromCase(curLivenessCase)));
    }, [dispatch, curLivenessCase.id]);

    const dequeueAction = dequeueLivenessCaseAction({
        actorId,
        recordId,
        index,
        queryParams: {
            payloadType: livenessPayloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        },
        customContext: {
            bannersVersion,
        },
    });

    const extraActions = [dequeueAction];

    const onBackArrowCTA = () => {
        dispatch(setLivenessViewAction(VIEW_ALL_LIVENESS_CASES));
    };

    const handleDequeueCase = () => {
        dispatch(dequeueAction);
        setDequeueCaseModalVisibility(false);
    };

    const downloadLivenessVideo = () => {
        const callAuditMedialDownloadApi = () => new Promise((resolve, reject) => {
            dispatch(auditLogMediaDownloadAction({
                requestBody: {
                    elementId: recordId,
                    actorId,
                    agentName: emailId,
                    videoLocation: livenessVideoUrl,
                    payloadType: livenessPayloadType.value,
                },
                resolve,
                reject,
            }));
        });

        callAuditMedialDownloadApi().then(() => {
            downloadFile(`liveness-video_${actorId}.mp4`, livenessVideoUrl);
        }).catch(() => {
            if (allowDownloadEvenIfTrackingApiFails) {
                downloadFile(`liveness-video_${actorId}.mp4`, livenessVideoUrl);
            }
        });
    };

    return (
        <React.Fragment>
            <div className='lreview-cr'>
                <div className='lreview-hl--cr'>
                    <button className='lreview-backarrow--cr' type='button' onClick={onBackArrowCTA}>
                        <img className='lreview-backarrow' alt='back-arrow' src={backArrowIcon} />
                    </button>
                    <div className='lreview-hl1'>Review Liveness Case</div>
                </div>

                <div className='lreview-video'>
                    {livenessVideoUrl && (
                        <DisableContextMenu>
                            <video width='480' height='540' controls disablePictureInPicture controlsList='nodownload'>
                                <source src={livenessVideoUrl} type='video/mp4' />
                                Your browser does not support the video tag.
                            </video>
                        </DisableContextMenu>
                    )}
                </div>

                <div className='frwpWrapper'>
                    <Button
                        secondary
                        label='View Liveness Summary Details'
                        onClick={() => setLivenessSummaryModalVisibility(true)}
                    />
                    <Button
                        secondary
                        label='View Customer Details'
                        extClasses='ml-30'
                        onClick={() => setCustomerDetailsModalVisibility(true)}
                    />
                    {allowedEmails.includes(emailId) && (
                        <Button
                            primary
                            label='Download Video'
                            extClasses='ml-30'
                            onClick={downloadLivenessVideo}
                        />
                    )}
                </div>

                <ViewAnnotationDetails
                    l2AnnotationDetails={l2AnnotationDetails}
                    caseDetails={annotationCaseDetails}
                />

                {
                    // if not L2 queue, only then show dev actions
                    !isL2Queue && preFilledValues && (
                        <DevActions
                            containerKey='liveness-v1'
                            actionTypes={DEV_ACTION_TYPES}
                            preFilledValues={preFilledValues}
                            extraActions={extraActions}
                            autoActionSelection={false}
                        />
                    )
                }

                <div className='lreview-cr1'>
                    <div className='lreview-hl2'>If the above action does not suit your need, you can dequeue this case</div>
                    <Button
                        secondary
                        label='Remove User From Queue'
                        onClick={() => setDequeueCaseModalVisibility(true)}
                    />
                </div>
            </div>

            <DequeueCaseModal
                visible={showDequeueCaseModal}
                submitCta={handleDequeueCase}
                closeCta={setDequeueCaseModalVisibility}
            />

            <LivenessSummaryModal
                visible={showLivenessSummaryModal}
                actorId={actorId}
                closeCta={setLivenessSummaryModalVisibility}
            />

            <CustomerDetailsModal
                visible={showCustomerDetailsModal}
                actorId={actorId}
                closeCta={setCustomerDetailsModalVisibility}
            />
        </React.Fragment>
    );
};

export default memo(SingleLivenessCase);

/*
 *
 * TicketSummary reducer
 *
 */

import { fromJS } from 'immutable';
import {
    GET_TICKET_INFO, SET_TICKET_INFO, ERR_TICKET_INFO,
    GET_TICKET_NOTES, SET_TICKET_NOTES, ERR_TICKET_NOTES,
    SET_TICKET_ID,
} from './constants';

const initialState = fromJS({
    loading: false,
    err: '',
    ticketInfo: {},
    ticketNotes: {},
    hasTicketInfo: false,
    hasTicketNotes: false,
    ticketId: '',
});

function ticketSummaryReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TICKET_INFO:
        case GET_TICKET_NOTES:
            return state
                .set('loading', true)
                .set('err', '');

        case SET_TICKET_INFO: {
            const { ticketInfo } = action.data;

            return state
                .set('loading', false)
                .set('hasTicketInfo', true)
                .set('ticketInfo', fromJS(ticketInfo))
                .set('hasTicketNotes', false)
                .set('ticketNotes', fromJS({}));
        }

        case ERR_TICKET_INFO: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err)
                .set('hasTicketInfo', false)
                .set('ticketInfo', fromJS({}))
                .set('hasTicketNotes', false)
                .set('ticketNotes', fromJS({}));
        }

        case SET_TICKET_NOTES: {
            const { publicNotes, privateNotes  } = action.data;

            return state
                .set('loading', false)
                .set('hasTicketNotes', true)
                .set('ticketNotes', fromJS({ publicNotes, privateNotes }));
        }

        case ERR_TICKET_NOTES:
            return state
                .set('loading', false)
                .set('hasTicketNotes', false)
                .set('ticketNotes', fromJS({}));

        case SET_TICKET_ID: {
            const { ticketId } = action.data;

            return state.set('ticketId', ticketId);
        }

        default:
            return state;
    }
}

export default ticketSummaryReducer;

/**
 * @file UpdateEmployerName
 * Agents can search based on employer name or GSTIN number
 * Searching gives a list of results with information about the employer
 * Agents can select one row from the list and click submit to update the employer name for the user
 */

import { equals, isEmpty } from 'ramda';
import React, { memo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { BackArrow, Button, FormWithSingleInput, Table } from '../../../components';

import { getEmployerDetailsAction, setEmployerDetailsAction } from '../actions';
import { selectSalaryAccountEmployerDetails } from '../selectors';

const EMPLOYER_DETAILS_LABEL_DATA = [
    {
        label: 'Employer Legal Name',
        key: 'name_by_source',
    },
    {
        label: 'Employer Trade Name',
        key: 'trade_name',
    },
    {
        label: 'GSTIN',
        key: 'gstin',
    },
];

const UpdateEmployerName = (props) => {
    const { onEmployerDetailsSubmit, onBackClick } = props;
    const dispatch = useDispatch();

    const employerDetails = useSelector(selectSalaryAccountEmployerDetails, shallowEqual);

    const [searchString, setSearchString] = useState('');
    const [searchStringErrLabel, setSearchStringErrLabel] = useState('');
    const [selectedEmployerDetails, setSelectedEmployerDetails] = useState({});

    useEffect(() => {
        dispatch(setEmployerDetailsAction(null));
    }, [dispatch]);

    const handleSearchEmployer = (e) => {
        e.preventDefault();

        if (searchString.length < 3) {
            setSearchStringErrLabel('You need to enter minimum 3 characters');
            return;
        }

        setSearchStringErrLabel('');
        dispatch(getEmployerDetailsAction({ searchString }));
    };

    const handleEmployerNameUpdate = () => {
        if (isEmpty(selectedEmployerDetails)) {
            setSearchStringErrLabel('Please select a row from the table');
            return;
        }

        setSearchStringErrLabel('');

        onEmployerDetailsSubmit(selectedEmployerDetails);
    };

    const renderCheckboxColumn = (item) => {
        const { employer_id: employerId } = item;

        const onChangeHandler = () => {
            setSelectedEmployerDetails(item);
        };

        return (
            <input
                type='checkbox'
                checked={equals(employerId, selectedEmployerDetails.employer_id)}
                onChange={onChangeHandler}
            />
        );
    };

    return (
        <React.Fragment>
            <div className='frWrapper mb-30'>
                <BackArrow
                    handleClick={onBackClick}
                />
                <div className='heading1'>Update Employer Name</div>
            </div>
            <FormWithSingleInput
                disableFormSubmitOnEnter
                onFormSubmit={handleSearchEmployer}
                extClasses={{
                    container: 'salops-uefc mb-15',
                    label: 'salops-uefc__lb',
                    inputField: 'salops-uefc__ip',
                    errLabel: 'salops-err',
                }}
                label='Search Employer'
                labelId='salary-search-employer'
                formType='text'
                errLabel={searchStringErrLabel}
                input={{
                    value: searchString,
                    onChange: (e) => setSearchString(e.target.value),
                }}
                cta={{
                    v2Buttons: true,
                    primaryLabel: 'Search',
                    onPrimaryClick: handleSearchEmployer,
                }}
            />
            <Table
                v2
                labelData={EMPLOYER_DETAILS_LABEL_DATA}
                contentData={employerDetails}
                renderCheckboxColumn={renderCheckboxColumn}
                extClasses={{
                    container: 'salops-uetb__cr',
                }}
            />
            {employerDetails && employerDetails.length > 0 && (
                <Button
                    v2
                    label='Update Employer Name'
                    primary
                    onClick={handleEmployerNameUpdate}
                />
            )}
        </React.Fragment>
    );
};

export default memo(UpdateEmployerName);

/**
 *
 * @file InputField - Component to render a specific type of input field based on the field data type
 *
 */

import React, { memo, useState } from 'react';

import Cross from '../../../assets/images/svgs/cross.svg';
import { FormDropdown, FormWithSingleInput } from '../../../components';

import { BOOLEAN_OPTIONS, SUPPORTED_ARRAY_FIELD_DATA_TYPES, SUPPORTED_FIELD_DATA_TYPES } from '../constants';
import { createSelectOptionsFromEnums } from '../utils';

const InputField = (props) => {
    const {
        handleFieldInput: onChange, field,
    } = props;

    const {
        data_type: dataType, enums, pre_defined_value: value, array_data_type: arrayDataType,
    } = field || {};

    const [currentArrayItem, setCurrentArrayItem] = useState('');

    switch (dataType) {
        case SUPPORTED_FIELD_DATA_TYPES.string: {
            if (enums && Array.isArray(enums) && enums.length > 0) {
                return (
                    <FormDropdown
                        options={createSelectOptionsFromEnums(enums)}
                        input={{
                            value,
                            onChange,
                            placeholder: 'Choose...',
                        }}
                        cacheKey='enum-field-value-selection'
                    />
                );
            }
            return (
                <FormWithSingleInput
                    disableFormSubmitOnEnter
                    input={{
                        value,
                        onChange: (e) => onChange(e.target.value),
                        placeholder: 'Enter...',
                    }}
                    hideErrLabel
                    extClasses={{
                        container: 'sop-input-box',
                        wrapper: 'sop-input-box',
                        inputField: 'sop-input-box',
                    }}
                />
            );
        }
        case SUPPORTED_FIELD_DATA_TYPES.bool: {
            return (
                <FormDropdown
                    options={BOOLEAN_OPTIONS}
                    input={{
                        value,
                        onChange,
                        placeholder: 'Choose...',
                    }}
                    cacheKey='boolean-field-value-selection'
                />
            );
        }
        case SUPPORTED_FIELD_DATA_TYPES.date:
        case SUPPORTED_FIELD_DATA_TYPES.dateAndTime: {
            return (
                <FormWithSingleInput
                    disableFormSubmitOnEnter
                    formType='dob'
                    input={{
                        showTimeInDateInput: dataType === SUPPORTED_FIELD_DATA_TYPES.dateAndTime,
                        value,
                        onChange,
                        placeholder: 'Select...',
                    }}
                    hideErrLabel
                    extClasses={{
                        container: 'sop-input-box',
                        wrapper: 'sop-input-box',
                        inputField: 'sop-input-box',
                    }}
                />
            );
        }
        case SUPPORTED_FIELD_DATA_TYPES.integer:
        case SUPPORTED_FIELD_DATA_TYPES.decimal: {
            return (
                <input
                    type='number'
                    className='sop-input-box'
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onKeyDown={(event) => {
                        if (
                            event.key === '.'
                            && dataType === SUPPORTED_FIELD_DATA_TYPES.integer
                        ) { // Prevents addition of decimals when the dataType is integer
                            event.preventDefault();
                        }
                    }}
                    placeholder='Enter...'
                />
            );
        }
        case SUPPORTED_FIELD_DATA_TYPES.list: {
            if (arrayDataType === SUPPORTED_ARRAY_FIELD_DATA_TYPES.string) {
                if (enums && Array.isArray(enums) && enums.length > 0) {
                    return (
                        <FormDropdown
                            isMulti
                            options={createSelectOptionsFromEnums(enums)}
                            input={{
                                value,
                                onChange,
                                placeholder: 'Choose...',
                            }}
                            cacheKey='enum-field-value-selection'
                        />
                    );
                }
                return (
                    <div>
                        <FormWithSingleInput
                            input={{
                                value: currentArrayItem,
                                onChange: (e) => setCurrentArrayItem(e.target.value),
                                placeholder: 'Enter...',
                            }}
                            hideErrLabel
                            onFormSubmit={(e) => {
                                e.preventDefault();
                                if (currentArrayItem && currentArrayItem !== '') {
                                    let newValue = value;
                                    if (newValue && Array.isArray(newValue)) {
                                        newValue.push(currentArrayItem);
                                    } else {
                                        newValue = [currentArrayItem];
                                    }
                                    onChange(newValue);
                                    setCurrentArrayItem('');
                                }
                            }}
                            extClasses={{
                                container: 'sop-input-box',
                                wrapper: 'sop-input-box',
                                inputField: 'sop-input-box',
                            }}
                        />
                        {
                            value && Array.isArray(value) && (
                                <div className='frcWrapper frwpWrapper p-5'>
                                    {
                                        value.map((item, index) => (
                                            <div className='frcWrapper p-5 sop-array-item'>
                                                <div className='p-5'>{item}</div>
                                                <img
                                                    role='presentation'
                                                    src={Cross}
                                                    alt='Cross'
                                                    onClick={() => {
                                                        const newValue = value.filter((valueItem, idx) => idx !== index);
                                                        onChange(newValue);
                                                    }}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                );
            }
            return <div className='sop-text-info'>Field list data type is not supported</div>;
        }
        default: {
            return <div className='sop-text-info'>Field data type is not supported</div>;
        }
    }
};

export default memo(InputField);

/**
 *
 * AccountOperationsStatusInfo
 *
 */

import React, { memo } from 'react';

import backArrow from '../../../assets/images/svgs/back-arrow.svg';
import { accountInformationGuidelines } from '../constants';

const AccountOperationsStatusInfo = (props) => {
    const {
        toggleHandler,
        status,
    } = props;

    const renderListOfInformation = () => {
        if (status === 'dormant' || status === 'inactive') {
            return (
                <div className='mt-30'>
                    {accountInformationGuidelines[status]?.reason
                    && (
                        <div className='kob-wrapper--v2 mb-30'>
                            <div className='kob-wrapper__label'>Reason</div>
                            <div className='kob-wrapper__value--v2'>{accountInformationGuidelines[status]?.reason || '-'}</div>
                        </div>
                    )}
                    {
                        accountInformationGuidelines[status]?.resolutionSteps
                        && (
                            <div className='kob-wrapper--v2 mb-30'>
                                <div className='kob-wrapper__label'>Resolution Steps</div>
                                <div className='kob-wrapper__value--v2'>
                                    <ol>
                                        {accountInformationGuidelines[status]?.resolutionSteps.map((guideline) => (
                                            <li className='mb-15'>
                                                {guideline}
                                            </li>
                                        ))}
                                    </ol>
                                </div>
                            </div>
                        )
                    }
                    {accountInformationGuidelines[status]?.note
                    && (
                        <div className='kob-wrapper--v2 mb-30'>
                            <div className='kob-wrapper__label'>Note: </div>
                            <div className='kob-wrapper__value--v2'>{accountInformationGuidelines[status]?.note || '-'}</div>
                        </div>
                    )}
                </div>
            );
        }
        return null;
    };

    return (
        <div className='kob'>
            <div className='kob-hw'>
                <img className='kob-backarrow' alt='' src={backArrow} onClick={() => toggleHandler(false)} role='presentation' />
                <div className='kob-hw__hd'>Account {status} Information</div>
            </div>
            <div className='table-sr table-sr--mt0'>
                <ol className='kob-wrapper_li'>
                    {renderListOfInformation()}
                </ol>
            </div>
        </div>
    );
};

export default memo(AccountOperationsStatusInfo);

/*
 *
 * Facematch review actions
 *
 */

import {
    GET_FACEMATCH_CASES, DEQUEUE_FACEMATCH_CASE, SET_FACEMATCH_VIEW, GET_BASE64_IMAGE,
    SET_CUR_FACEMATCH_CASE, SET_FACEMATCH_PAYLOAD_TYPE, GET_FACEMATCH_PENDING_REVIEW_COUNT,
    SET_FACEMATCH_QUEUE_FILTERS,
} from './constants';

export const getFacematchCasesAction = (data) => ({
    type: GET_FACEMATCH_CASES,
    data,
});

export const dequeueFacematchCaseAction = (data) => ({
    type: DEQUEUE_FACEMATCH_CASE,
    data,
});

export const setFacematchViewAction = (data) => ({
    type: SET_FACEMATCH_VIEW,
    data,
});

export const getBase64ImgAction = (data) => ({
    type: GET_BASE64_IMAGE,
    data,
});

export const setCurFacematchCaseAction = (data) => ({
    type: SET_CUR_FACEMATCH_CASE,
    data,
});

export const setFacematchPayloadTypeAction = (data) => ({
    type: SET_FACEMATCH_PAYLOAD_TYPE,
    data,
});

export const getFacematchPendingReviewCountAction = (data) => ({
    type: GET_FACEMATCH_PENDING_REVIEW_COUNT,
    data,
});

export const setFacematchQueueFiltersAction = (data) => ({
    type: SET_FACEMATCH_QUEUE_FILTERS,
    data,
});

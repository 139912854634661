import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { ADMIN_ACTIONS_ROOT } from '../../api/routes';

import {
    GET_ACTION_LIST, SET_ACTION_LIST, ERR_ACTION_LIST,
    GET_PARAMETER_LIST, SET_PARAMETER_LIST, ERR_PARAMETER_LIST,
    EXECUTE_ACTION, SUCCESS_EXECUTE_ACTION, ERR_EXECUTE_ACTION,
} from './constants';

function* getActionList(action) {
    const { ticketId, userId } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ADMIN_ACTIONS_ROOT}?${queryString}`,
        );

        yield put({ type: SET_ACTION_LIST, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ACTION_LIST, data: { err: e.message } });
    }
}

function* getParameterList(action) {
    const { action: actionStr, ticketId, userId } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ADMIN_ACTIONS_ROOT}/${actionStr}/parameters?${queryString}`,
        );

        yield put({ type: SET_PARAMETER_LIST, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_PARAMETER_LIST, data: { err: e.message } });
    }
}

function* executeAction(action) {
    const {
        action: actionStr, parameters, ticketId, userId, resolve,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });
    const requestBody = {
        parameters,
        action: actionStr,
    };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${ADMIN_ACTIONS_ROOT}/execute?${queryString}`,
            requestBody,
        );

        yield call(resolve);
        yield put({ type: SUCCESS_EXECUTE_ACTION, data: response });

        toastify('Action has been performed successfully', 'success');
    } catch (e) {
        // const err = getErrLabel(e);
        // show error message as toast
        toastify(e.message, 'error');
        // show error description on UI
        yield put({ type: ERR_EXECUTE_ACTION, data: { err: e.description } });
    }
}

export default function* adminActionsSaga() {
    yield all(
        [
            yield takeLatest(GET_ACTION_LIST, getActionList),
            yield takeLatest(GET_PARAMETER_LIST, getParameterList),
            yield takeLatest(EXECUTE_ACTION, executeAction),
        ],
    );
}

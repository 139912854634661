/**
 * @file Manages the selector functions for Agent Feedback Details component
 */

import { createSelector } from 'reselect';

import { AGENT_FB_DETAILS_KEY } from './constants';

const selectAgentFeedbackDetails = createSelector(
    (state) => state.getIn([AGENT_FB_DETAILS_KEY, 'agentFeedbackDetails']),
    (substate) => substate?.toJS(),
);

export {
    selectAgentFeedbackDetails,
};

/**
 *
 * CreateAgent - Component to create the new agent & update the existing agent info
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { equals } from 'ramda';
import classNames from 'classnames';

import {
    Button, DropdownContent, DropdownWrapper, FormDropdown, FormWithSingleInput, Loader,
} from '../../../components';
import { getDropdownHeight, toastify, validators } from '../../../utils';
import backArrow from '../../../assets/images/svgs/back-arrow.svg';

import { AGENT_PATH } from '../../App/routes';
import { makeSelectAgentInfo as makeSelectLoggedInAgentInfo } from '../../App/selectors';

import {
    getAccessLevelList, getAgentTypeList, getAgentStatusList, setAgentFormFields,
    createAgent, updateAgent,
} from '../actions';
import {
    makeSelectAccessLevelList, makeSelectAgentTypeList, makeSelectAgentStatusList,
    makeSelectAgentFormFields, makeSelectAgentInfo, makeSelectCreateOrUpdateAgentInfo,
} from '../selectors';
import { AGENT_FORM_FIELDS, NAME_FIELD_ARR, NAME_FIELD_PLACEHOLDER_ARR } from '../constants';

import './style.scss';

const CreateOrUpdateAgent = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    // reducer state variables
    const accessLevelList = useSelector(makeSelectAccessLevelList(), shallowEqual);
    const agentTypeList = useSelector(makeSelectAgentTypeList(), shallowEqual);
    const agentStatusList = useSelector(makeSelectAgentStatusList(), shallowEqual);
    const agentFormFields = useSelector(makeSelectAgentFormFields(), shallowEqual);
    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);
    const createOrUpdateAgentInfo = useSelector(makeSelectCreateOrUpdateAgentInfo(), shallowEqual);
    const loggedInAgentInfo = useSelector(makeSelectLoggedInAgentInfo(), shallowEqual);

    // local state variables
    const [formErrLabel, setFormErrLabel] = useState({});
    const [showAgentTypeDD, setAgentTypeDD] = useState(false);
    const [showAgentStatusDD, setAgentStatusDD] = useState(false);

    const { isEdit } = props;

    // get list of access level
    useEffect(() => {
        if (accessLevelList.length === 0) {
            dispatch(getAccessLevelList());
        }
    }, [dispatch, accessLevelList.length]);

    // get list of agent type
    useEffect(() => {
        if (agentTypeList.length === 0) {
            dispatch(getAgentTypeList());
        }
    }, [dispatch, agentTypeList.length]);

    // get list of agent status
    useEffect(() => {
        if (agentStatusList.length === 0) {
            dispatch(getAgentStatusList());
        }
    }, [dispatch, agentStatusList.length]);

    const {
        emailId, phoneNumber, fullName, agentType, agentStatus,
        accessLevelList: selectedAccessLevel, ozonetelId,
    } = agentFormFields;

    const handleInputChange = (key) => (e) => {
        dispatch(setAgentFormFields({
            key,
            value: e.target.value,
        }));
    };

    const handleNameInputChange = (key) => (e) => {
        dispatch(setAgentFormFields({
            key,
            value: e.target.value,
        }));
    };

    const toggleDD = (id) => () => {
        switch (id) {
            case AGENT_FORM_FIELDS.AGENT_TYPE: {
                setAgentTypeDD(!showAgentTypeDD);

                break;
            }

            case AGENT_FORM_FIELDS.AGENT_STATUS: {
                setAgentStatusDD(!showAgentStatusDD);

                break;
            }

            default:
        }
    };

    const handleOptionsChange = (key, value) => {
        dispatch(setAgentFormFields({ key, value }));

        if (value) {
            toggleDD(key)();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // check if valid email id is inputted
        if (!validators.isInputtedEmailValid(emailId)) {
            setFormErrLabel({ emailId: 'Please enter a valid email id' });
            return;
        }

        // check if valid phone number is inputted
        if (!phoneNumber || !validators.isInputtedMobileNumberValid(phoneNumber)) {
            setFormErrLabel({ phoneNumber: 'Please enter a valid 10 digits phone number' });
            return;
        }

        // check if valid name is inputted
        if (
            !fullName
            || !fullName.firstName
            || !fullName.lastName
            || !validators.isInputtedAlphabetStringValid(fullName.firstName)
            || !validators.isInputtedAlphabetStringValid(fullName.lastName)
            || (fullName.middleName && !validators.isInputtedAlphabetStringValid(fullName.middleName))
        ) {
            setFormErrLabel({ fullName: 'Please enter a valid name. First & Last Name are mandatory' });
            return;
        }

        // check if agent type is selected
        if (!agentType) {
            setFormErrLabel({ agentType: 'Please select agent type' });
            return;
        }

        // check if access level is selected
        if (!selectedAccessLevel || selectedAccessLevel.length === 0) {
            setFormErrLabel({ selectedAccessLevel: 'Please select access level' });
            return;
        }

        // check if agent status is selected
        if (!agentStatus) {
            setFormErrLabel({ agentStatus: 'Please select agent status' });
            return;
        }

        // check if valid ozonetel id is inputted
        /* this is no longer required as we are not sure what all characters are there in the ozonetel id
        if (ozonetelId && !validators.isInputtedStringValid(ozonetelId)) {
            setFormErrLabel({ ozonetelId: 'Please enter a valid ozonetel id' });
            return;
        }
        */

        setFormErrLabel({});

        if (isEdit) {
            const updatedFields = [];

            // if the updated & existing info are not same, push the key name into the updatedFields array
            Object.keys(agentFormFields).forEach(key => {
                if (!equals(agentFormFields[key], agentInfo[key])) {
                    updatedFields.push(key);
                }
            });
            
            if (updatedFields.length === 0) {
                toastify('You need to update any field before proceeding', 'error');

                return;
            }

            const emailIdKey = AGENT_FORM_FIELDS.EMAIL_ID;
            let refreshAgentInfo = false;

            // need to fetch agent info again incase user is making changes in self info
            if (loggedInAgentInfo[emailIdKey] === agentFormFields[emailIdKey]) {
                refreshAgentInfo = true;
            }

            dispatch(updateAgent({ ...agentFormFields, updatedFields, refreshAgentInfo }));
        } else {
            dispatch(createAgent({ ...agentFormFields }));
        }
    };

    const renderErrorField = (key) => (
        <div className='create-agent-fc__errlb'>{formErrLabel[key]}</div>
    );

    const renderFormInput = (key) => {
        let label;
        let labelId;
        let type;
        let placeholder;
        let value;
        let formType;
        let onInputChange;
        let nameFieldArr;
        let nameFieldPlaceholderArr;
        let maxLength;
        let disabled;

        switch (key) {
            case AGENT_FORM_FIELDS.EMAIL_ID: {
                label = 'Email Id';
                labelId = 'email-id';
                type = 'email';
                placeholder = 'enter email id'
                value = emailId;
                formType = 'text';
                onInputChange = handleInputChange(key);
                disabled = isEdit;

                break;
            }

            case AGENT_FORM_FIELDS.PHONE_NUMBER: {
                label = 'Phone Number';
                labelId = 'phone-number';
                type = 'number';
                placeholder = 'enter only 10 digits of phone number'
                value = phoneNumber;
                onInputChange = handleInputChange(key);
                formType = 'text';
                maxLength = 10;

                break;
            }

            case AGENT_FORM_FIELDS.FULL_NAME: {
                label = 'Full Name';
                labelId = 'full-name';
                value = fullName;
                formType = 'name';
                nameFieldArr = NAME_FIELD_ARR;
                nameFieldPlaceholderArr = NAME_FIELD_PLACEHOLDER_ARR
                onInputChange = handleNameInputChange;

                break;
            }

            case AGENT_FORM_FIELDS.OZONETEL_ID: {
                label = 'Ozonetel Id';
                labelId = 'ozonetel-id';
                placeholder = 'enter ozonetel id'
                value = ozonetelId;
                formType = 'text';
                onInputChange = handleInputChange(key);

                break;
            }

            default:
        }

        return (
            <React.Fragment>
                <FormWithSingleInput
                    onFormSubmit={handleSubmit}
                    extClasses={{
                        container: 'create-agent-fc',
                        label: 'create-agent-fc__lb',
                        inputField: 'create-agent-fc__ip',
                        nameInputField: 'create-agent-fc__ip-name'
                    }}
                    label={label}
                    labelId={labelId}
                    formType={formType}
                    input={{
                        value,
                        onChange: onInputChange,
                        nameFieldArr,
                        nameFieldPlaceholderArr,
                        placeholder,
                        type,
                        maxLength,
                        disabled,
                    }}
                />
                {renderErrorField(key)}
            </React.Fragment>
        );
    };

    const renderFormDD = (key) => {
        let label;
        let value;
        let options;
        let showDD;

        switch (key) {
            case AGENT_FORM_FIELDS.AGENT_TYPE: {
                label = 'agent type';
                value = agentType;
                options = agentTypeList;
                showDD = showAgentTypeDD;

                break;
            }

            case AGENT_FORM_FIELDS.AGENT_STATUS: {
                label = 'agent status';
                value = agentStatus;
                options = agentStatusList;
                showDD = showAgentStatusDD;

                break;
            }

            default:
                label = '';
                options = [];
        }

        const { length: optionsLength } = options;

        if (optionsLength === 0) {
            return null;
        }

        const optionListDDHeight = getDropdownHeight(optionsLength);

        return (
            <React.Fragment>
                <div className='create-agent-fc'>
                    <div className='create-agent-fc__lb'>{label}</div>
                    <DropdownWrapper
                        visible={showDD}
                        onOutsideClickHandler={toggleDD(key)}
                        extClasses={{
                            container: 'custom-dd create-agent-dd',
                        }}
                    >
                        <div
                            className={classNames('create-agent-dd__vl', !value && 'create-agent-dd__vl--not-selected')}
                            onClick={toggleDD(key)}
                        >
                            {value ? value.split('_').join(' ').toLowerCase() : `select ${label}`}
                        </div>
                        <DropdownContent
                            visible
                            extClasses = {{
                                container: ['custom-dd__cc create-agent-dd__cc'],
                            }}
                            extStyles = {{
                                height: showDD ? optionListDDHeight : 0,
                                overflow: showDD ? 'auto' : 'hidden',
                            }}
                        >
                            {
                                options.map(item => (
                                    <div
                                        key={item.label}
                                        className='custom-dd__cl'
                                        onClick={() => handleOptionsChange(key, item.value)}
                                    >
                                        {item.label}
                                    </div>
                                ))
                            }
                        </DropdownContent>
                    </DropdownWrapper>
                </div>
                {renderErrorField(key)}
            </React.Fragment>
        );
    };

    const renderFormMultiSelectDD = (key) => {
        let label;
        let placeholder;
        let value;
        let options;

        switch (key) {
            case AGENT_FORM_FIELDS.ACCESS_LEVEL: {
                label = 'Access Level';
                placeholder = 'select multiple access level if required'
                value = selectedAccessLevel;
                options = accessLevelList;

                break;
            }

            default:
                label = '';
                options = [];
        }

        return (
            <React.Fragment>
                <div className='create-agent-fc'>
                    <div className='create-agent-fc__lb'>{label}</div>
                    <FormDropdown
                        isMulti
                        options={options}
                        input={{
                            value,
                            onChange: (value) => {
                                dispatch(setAgentFormFields({ key, value }));
                            },
                            placeholder,
                        }}
                        cacheKey='agentform-accesslevel'
                        extStyles={{
                            container: 'create-agent-dd',
                        }}
                    />
                </div>
                {renderErrorField(key)}
            </React.Fragment>
        );
    };

    const resetAgentFormFields = () => {
        dispatch(setAgentFormFields({}));
        history.push(AGENT_PATH);
    };

    const label = isEdit ? 'Update Agent' : 'Create Agent';

    return (
        <React.Fragment>
            <div className='create-agent-cr'>
                <div className='create-agent-hwr'>
                    <img
                        className='create-agent-hwr__backarrow'
                        src={backArrow}
                        onClick={resetAgentFormFields}
                    />
                    <div className='create-agent-hwr__hd'>{label}</div>
                </div>
                <div className='fcWrapper'>
                    {renderFormInput(AGENT_FORM_FIELDS.EMAIL_ID)}
                    {renderFormInput(AGENT_FORM_FIELDS.PHONE_NUMBER)}
                    {renderFormInput(AGENT_FORM_FIELDS.FULL_NAME)}
                    {renderFormDD(AGENT_FORM_FIELDS.AGENT_TYPE)}
                    {renderFormMultiSelectDD(AGENT_FORM_FIELDS.ACCESS_LEVEL)}
                    {renderFormDD(AGENT_FORM_FIELDS.AGENT_STATUS)}
                    {renderFormInput(AGENT_FORM_FIELDS.OZONETEL_ID)}
                    <Button
                        v2
                        primary
                        label='submit'
                        onClick={handleSubmit}
                    />
                </div>
            </div>
            <Loader visible={createOrUpdateAgentInfo.loading} />
        </React.Fragment>
    );
};

export default memo(CreateOrUpdateAgent);

/**
 * @file RaiseVerificationRequestCaseDetails
 * Component to display the employer details, last verified transaction details and
 * available transactions for the selected verification case
 */

import React, { memo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
    BackArrow, Button, ExpandableCard, KVPairInfo, Loader,
} from '../../../../components';
import { SALARY_ACCOUNT_RAISE_VERIFICATION_AND_VERIFY_TXN_ENDPOINT } from '../../../../api/routes';
import { clientApiWrapper, getErrMsg, showErrorToastMsg, toastify } from '../../../../utils';

import {
    selectRaiseVerificationRequestCaseDetails, selectRaiseVerificationRequestStatus, selectSalaryVerificationCaseTxnHistory, selectSelectedActor,
} from '../../selectors';
import {
    getRaiseSalaryVerificationCasesAction, setSalaryAccountOpsCurViewAction, updateRaiseVerificationRequestStatusAction,
} from '../../actions';
import {
    SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY, VIEW_RAISE_VERIFICATION_CASES, RAISE_VERIFICATION_REQUEST_STATUS,
} from '../../constants';

import ViewEmploymentDetails from '../../ViewEmploymentDetails';
import AvailableTransactionTable from '../AvailableTransactionTable';
import TransactionsHistory from '../../VerificationInfo/TransactionsHistory';

const getTxnKVData = (lastVerifiedTxn, employerDetails) => ([

    {
        label: 'Txn ID',
        value: lastVerifiedTxn?.txnId,
    },
    {
        label: 'Remitter Name',
        value: employerDetails?.remitterName,
    },
    {
        label: 'Fallback Remitter Name',
        value: employerDetails?.piRemitterName,
    },
    {
        label: 'Payment Protocol',
        value: lastVerifiedTxn?.paymentProtocol,
    },
    {
        label: 'Particulars',
        value: lastVerifiedTxn?.particulars,
    },
    {
        label: 'Txn Timestamp',
        value: lastVerifiedTxn?.txnTime,
    },
]);

const RaiseVerificationRequestCaseDetails = (props) => {
    const { handleBackClick } = props;

    const dispatch = useDispatch();

    const { employmentInfo: employerDetails, lastVerifiedTxnDetails } = useSelector(selectRaiseVerificationRequestCaseDetails, shallowEqual);
    const raiseVerificationStatus = useSelector(selectRaiseVerificationRequestStatus, shallowEqual);
    const selectedActor = useSelector(selectSelectedActor, shallowEqual);
    const txnHistory = useSelector(selectSalaryVerificationCaseTxnHistory, shallowEqual);

    const [loading, setLoading] = useState(false);
    const [errorLabel, setErrorLabel] = useState('');
    const [isCardExpanded, setIsCardExpanded] = useState(true);

    const selectedActorVerificationStatus = selectedActor.verificationRequestStatus;

    const onClickChangeStatus = (txnId, updatedStatus) => {
        const payload = {
            actorId: selectedActor?.actorId,
            txnId,
            updatedStatus,
        };
        dispatch(updateRaiseVerificationRequestStatusAction(payload));
        dispatch(getRaiseSalaryVerificationCasesAction({
            raiseVerificationStatus: raiseVerificationStatus.value,
        }));
        dispatch(setSalaryAccountOpsCurViewAction({
            screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
            value: VIEW_RAISE_VERIFICATION_CASES,
        }));
    };

    const handleActivateSalaryBenefits = async (txnId) => {
        if (!txnId) {
            setErrorLabel('No transaction ID found for the select row');
            return;
        }

        try {
            const payload = {
                actorId: selectedActor?.actorId,
                txnId,
                employerId: employerDetails.employerId,
            };

            setLoading(true);
            setErrorLabel('');

            const response = await clientApiWrapper.post(SALARY_ACCOUNT_RAISE_VERIFICATION_AND_VERIFY_TXN_ENDPOINT, { payload });

            const { message } = response;

            toastify(message, 'success');

            // fetch cases again
            dispatch(getRaiseSalaryVerificationCasesAction({
                raiseVerificationStatus: raiseVerificationStatus.value,
            }));
            // redirect to main table view
            dispatch(setSalaryAccountOpsCurViewAction({
                screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
                value: VIEW_RAISE_VERIFICATION_CASES,
            }));
        } catch (error) {
            showErrorToastMsg(error);
            const errorMsg = getErrMsg(error);
            setErrorLabel(errorMsg);
        } finally {
            setLoading(false);
        }
    };

    const statusNew = RAISE_VERIFICATION_REQUEST_STATUS.new.label;
    const statusPending = RAISE_VERIFICATION_REQUEST_STATUS.pending.value;
    const isDetails = employerDetails && lastVerifiedTxnDetails;

    return (
        <React.Fragment>
            <div className='frWrapper mb-60'>
                <BackArrow
                    handleClick={handleBackClick}
                />
                <div className='heading1'>Salary Transaction Details</div>
            </div>
            {employerDetails ? (
                <ViewEmploymentDetails
                    employmentInfo={employerDetails}
                />
            ) : null}
            {lastVerifiedTxnDetails && Object.keys(lastVerifiedTxnDetails).length > 0 && (
                <KVPairInfo
                    title='Last Verified Transaction Details'
                    data={getTxnKVData(lastVerifiedTxnDetails)}
                    extClasses={{
                        container: 'salops-kv__cr',
                        titleHeading: 'salops-kv__hd',
                        contentLabel: 'salops-kv__cl',
                        contentValue: 'salops-kv__cv',
                    }}
                />
            )}
            {isDetails
                && (
                    <AvailableTransactionTable
                        v2
                        handleRaiseRequest={onClickChangeStatus}
                        handleActivateSalaryBenefits={handleActivateSalaryBenefits}
                    />
                )}

            {txnHistory && txnHistory.data && Array.isArray(txnHistory.data)
            && (
                <div className='mb-30'>
                    <ExpandableCard
                        isExpanded={isCardExpanded}
                        setIsExpanded={() => setIsCardExpanded(!isCardExpanded)}
                        extClasses='salops-expandable__cont'
                        extHeadingClasses='salops-expandable-heading'
                        heading='Historical Verified Transactions'
                    >
                        {
                            txnHistory.data.map((datum, index) => (
                                <TransactionsHistory
                                    id={index + 1}
                                    data={datum}
                                    key={datum.txnId}
                                />
                            ))
                        }
                    </ExpandableCard>
                </div>
            )}

            {errorLabel ? (
                <div className='error-label1 my-30'>{errorLabel}</div>
            ) : null}
            <Loader visible={loading} />

            <div className='salops-btnWrapper'>
                {selectedActorVerificationStatus?.trim() === statusNew && isDetails ? (
                    <Button
                        v2
                        label='Mark as Pending'
                        primary
                        onClick={() => onClickChangeStatus('', statusPending)}
                    />
                ) : null}
                <Button
                    v2
                    label='Close'
                    secondary
                    onClick={handleBackClick}
                />
            </div>
        </React.Fragment>
    );
};

export default memo(RaiseVerificationRequestCaseDetails);

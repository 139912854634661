/**
 * @file Autopay main container
 */
import { compose } from 'redux';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { injectReducer, injectSaga } from '../../utils';
import { AUTOPAY_KEY } from './constants';
import reducer from './reducer';
import saga from './saga';
import { BA_AUTOPAY_EXISTING_TICKET_PATH, BA_AUTOPAY_UPCOMING_TICKET_PATH } from '../App/routes';
import ExistingMandates from './ExistingMandates';
import UpcomingTransactions from './UpcomingTransactions';
import './style.scss';

const AutoPay = (props) => (
    <Switch>
        <Route path={BA_AUTOPAY_EXISTING_TICKET_PATH}>
            <ExistingMandates
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...props}
            />
        </Route>
        <Route path={BA_AUTOPAY_UPCOMING_TICKET_PATH}>
            <UpcomingTransactions
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...props}
            />
        </Route>
    </Switch>
);

const withReducer = injectReducer({ key: AUTOPAY_KEY, reducer });
const withSaga = injectSaga({ key: AUTOPAY_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(AutoPay);

import { createSelector } from 'reselect';

const selectApp = (state) => state.get('app');

const selectHasAgentInfo = () => createSelector(
    selectApp,
    (substate) => substate.get('hasAgentInfo'),
);

const selectLoading = () => createSelector(
    selectApp,
    (substate) => substate.get('loading').toJS(),
);

const selectAccessLevel = () => createSelector(
    (state) => state.getIn(['app', 'selectedAccessLevel']),
    (substate) => substate,
);

const selectMetaInfo = () => createSelector(
    (state) => state.getIn(['app', 'metaInfo']),
    (substate) => substate.toJS(),
);

const selectCSPNonce = () => createSelector(
    selectApp,
    (substate) => substate.get('cspNonce'),
);

const selectAgentInfo = () => createSelector(
    selectApp,
    (substate) => substate.get('agentInfo').toJS(),
);

export {
    selectAccessLevel,
    selectLoading,
    selectHasAgentInfo,
    selectAgentInfo,
    selectMetaInfo,
    selectCSPNonce,
};

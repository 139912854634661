/**
 * @file AddCorrespondence Modal
 * The Modal lets users add correspondence text for a given dispute in the DMP flow
 */

import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-modal';

import { Button, LoaderOrError } from '../../../components';
import {
    clientApiWrapper, getErrMsg, toastify, showErrorToastMsg,
} from '../../../utils';
import { DISPUTE_CORRESPONDENCE_DETAILS_ENDPOINT } from '../../../api/routes';

import { DEFAULT_STYLES_FOR_MODAL } from '../constants';

const customStylesForModal = {
    overlay: {
        ...DEFAULT_STYLES_FOR_MODAL.overlay,
    },
    content: {
        ...DEFAULT_STYLES_FOR_MODAL.content,
        width: 700,
        height: 500,
    },
};

export const AddCorrespondence = memo((props) => {
    const { parentProps, closeModal, isEscalation } = props;

    const {
        visible,
        data,
        parentProps: {
            ticketId = 0,
        },
    } = parentProps;

    const [correspondenceText, setCorrespondenceText] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorLabel, setErrorLabel] = useState('');

    const handleCorrespondenceSubmit = async () => {
        if (!correspondenceText) {
            setErrorLabel('You need to enter some text before clicking submit');
            return;
        }

        try {
            const payload = {
                disputeId: data.id,
                ticketId,
                correspondenceText,
                isEscalation,
            };

            setLoading(true);
            setErrorLabel('');

            const response = await clientApiWrapper.post(DISPUTE_CORRESPONDENCE_DETAILS_ENDPOINT, { payload });

            if (response === 'auth-expired') {
                throw new Error('Auth expired');
            }

            const { message } = response;

            toastify(message, 'success');
            closeModal();
        } catch (error) {
            showErrorToastMsg(error);
            const errorMsg = getErrMsg(error);
            setErrorLabel(errorMsg);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setCorrespondenceText('');
        setErrorLabel('');
    }, [visible]);

    if (!visible) return null;

    return (
        <React.Fragment>
            <div className='fcWrapper mb-30'>
                <div className='heading3 mb-15'>
                    Add details
                </div>
                <div className='frcsbWrapper'>
                    <textarea
                        className='disputes-txtarea'
                        rows={3}
                        value={correspondenceText}
                        onChange={(event) => setCorrespondenceText(event.target.value)}
                    />
                    <Button
                        v2
                        primary
                        label='Submit'
                        disabled={!correspondenceText}
                        onClick={handleCorrespondenceSubmit}
                    />
                </div>

            </div>

            <LoaderOrError
                loading={loading}
                errorLabel={errorLabel}
            />
        </React.Fragment>
    );
});

const ParentComponent = (props) => {
    const {
        toggleHandler,
        setData,
        visible,
    } = props;

    const closeModal = () => {
        setData({});
        toggleHandler();
    };

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
        >
            <AddCorrespondence
                closeModal={closeModal}
                parentProps={props}
            />
            <Button
                v2
                secondary
                extClasses='disputes-modal-cta'
                label='Close'
                onClick={closeModal}
            />
        </Modal>
    );
};

export default memo(ParentComponent);

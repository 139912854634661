/**
 *
 * InfoDisplayStep - Component to handle the Info Display Step type and render the different sections with their responses and the request fields if any
 *
 */

import React, { memo, useEffect, useState } from 'react';

import HelpCircle from '../../../assets/images/svgs/help-circle.svg';
import { Button, Table } from '../../../components';

import {
    CONDITION_TYPE, LABEL_VALUE_ARRAY_DATA_TYPE, MASKING_TYPE, SUPPORTED_ARRAY_FIELD_DATA_TYPES, SUPPORTED_FIELD_DATA_TYPES,
    TABLE_DATA_TYPE,
} from '../constants';
import {
    getMaskedEmailAddress, maskAlternateCharacters, maskFullValue, maskLeadingCharacters, maskTrailingCharacters,
} from '../utils';
import InputField from '../InputField';

const InfoDisplayStep = (props) => {
    const {
        step, currentStep, getNextNode, setCurrentStep,
    } = props;

    // Keeps the record of all the which are to be called (like rpcs which have undefined request fields and rpcs which threw an error while calling them)
    const [rpcsToBeCalled, setRpcsToBeCalled] = useState([]);
    const [error, setError] = useState('');

    const {
        title, description, faq, nextNode, sections, requiredFieldInput, errorRpcs, nodeId,
    } = step || {};

    const { next_step_condition_type: conditionType, mappings } = nextNode || {};

    useEffect(() => {
        setRpcsToBeCalled([...requiredFieldInput, ...errorRpcs]);
    }, [requiredFieldInput, errorRpcs]);

    const applyMasking = (value, maskingType) => {
        switch (maskingType) {
            case MASKING_TYPE.email: {
                return getMaskedEmailAddress(value);
            }
            case MASKING_TYPE.trailing: {
                return maskTrailingCharacters(value);
            }
            case MASKING_TYPE.leading: {
                return maskLeadingCharacters(value);
            }
            case MASKING_TYPE.full: {
                return maskFullValue(value);
            }
            case MASKING_TYPE.alternate: {
                return maskAlternateCharacters(value);
            }
            default: {
                return value;
            }
        }
    };

    const handleFieldInput = (grpcIndex, fieldIndex) => (value) => {
        const newRpcsToBeCalled = [...rpcsToBeCalled];
        if (newRpcsToBeCalled[grpcIndex] && newRpcsToBeCalled[grpcIndex]?.fields && newRpcsToBeCalled[grpcIndex]?.fields[fieldIndex]) {
            newRpcsToBeCalled[grpcIndex].fields[fieldIndex].pre_defined_value = value;
            newRpcsToBeCalled[grpcIndex].fields[fieldIndex].is_value_predefined = true;
            setRpcsToBeCalled([...newRpcsToBeCalled]);
        }
    };

    const handleOnSubmitRequestField = () => {
        setError('');
        try {
            const rpcsAfterLabelRemovalFromDropdownFields = rpcsToBeCalled && Array.isArray(rpcsToBeCalled) && rpcsToBeCalled.map((rpc) => {
                const fields = rpc?.fields && Array.isArray(rpc?.fields) && rpc?.fields.map((field) => {
                    const {
                        data_type: dataType, enums, pre_defined_value: value, validation,
                    } = field;
                    if (!value) {
                        throw new Error('Please fill the required Fields correctly');
                    }
                    let newValue = value;
                    if ((enums && Array.isArray(enums) && enums.length > 0) || dataType === 'boolean') {
                        if (Array.isArray(newValue)) {
                            newValue = newValue.map((item) => item?.value);
                        } else {
                            newValue = newValue?.value;
                        }
                    } else if (typeof newValue === 'string' && validation) {
                        const { pattern, flag } = validation || {};
                        if (pattern && flag) {
                            const regExpression = new RegExp(pattern, flag);
                            if (!regExpression.test(newValue)) {
                                throw new Error('Invalid Input');
                            }
                        }
                    }
                    return {
                        ...field,
                        pre_defined_value: newValue,
                    };
                });
                return {
                    ...rpc,
                    fields,
                };
            });
            getNextNode(nodeId, false, { isSecondRpcCall: true, rpcsToBeCalled: rpcsAfterLabelRemovalFromDropdownFields });
            setCurrentStep((stepIndex) => stepIndex - 1);
        } catch (err) {
            setError(err);
        }
    };

    return (
        <React.Fragment>
            <div className='frcsbWrapper mt-15 mb-15'>
                <div>
                    <div className='sop-step-no'>Step {currentStep + 1}</div>
                    <div className='sop-step-title'>{title}</div>
                    <div className='sop-step-des'>{description}</div>
                </div>
                {
                    faq && (
                        <div className='sop-step-faq'>
                            <div className='frcWrapper'>
                                <img src={HelpCircle} alt='Help Circle' />
                                FAQs
                            </div>
                            <div>
                                {faq}
                            </div>
                        </div>
                    )
                }
            </div>
            {
                rpcsToBeCalled && Array.isArray(rpcsToBeCalled) && rpcsToBeCalled.filter((rpc) => !!rpc.fields).length > 0 && (
                    <div className='sop-cc sop-box'>
                        {
                            rpcsToBeCalled.map((grpcFields, grpcIndex) => (
                                <React.Fragment>
                                    <div className='sop-box-values sop-box-grid-input'>
                                        {
                                            grpcFields?.fields && Array.isArray(grpcFields?.fields)
                                                && grpcFields?.fields.map((field, fieldIndex) => (
                                                    <div className='sop-box-cell'>
                                                        <div className='sop-box-cell-title'>{field.label}</div>
                                                        <InputField
                                                            handleFieldInput={handleFieldInput(grpcIndex, fieldIndex)}
                                                            field={field}
                                                        />
                                                    </div>
                                                ))
                                        }
                                    </div>
                                    <div className='line' />
                                </React.Fragment>
                            ))
                        }
                        <div className='frcWrapper err-label mt-15' style={{ width: '100%' }}>{error.message}</div>
                        <div className='frccWrapper p-20'>
                            <Button
                                v2
                                primary
                                label='Submit'
                                onClick={handleOnSubmitRequestField}
                            />
                        </div>
                    </div>
                )
            }
            {
                sections && (
                    <div className='sop-cc'>
                        {
                            Array.isArray(sections) && sections.map((section, index) => {
                                const tableResponseValues = section?.values
                                    && Array.isArray(section?.values)
                                    && section?.values.filter((labelValue) => labelValue.dataType === TABLE_DATA_TYPE);
                                const labelValueArrayResonseValues = section?.values
                                    && Array.isArray(section?.values)
                                    && section?.values.filter((labelValue) => labelValue.dataType === LABEL_VALUE_ARRAY_DATA_TYPE);

                                return (
                                    <React.Fragment>
                                        <div className='frWrapper'>
                                            <div className='p-20'>
                                                <div className='sop-box-title sop-box-cell-title'>{section?.label}</div>
                                            </div>
                                            <div className='sop-box-values'>
                                                <div className='sop-box-grid'>
                                                    {
                                                        section?.values
                                                        && Array.isArray(section?.values)
                                                        && section?.values.filter((labelValue) => {
                                                            const {
                                                                hasRequiredFields, dataType, hasError,
                                                            } = labelValue;

                                                            return (
                                                                (
                                                                    hasError
                                                                    || hasRequiredFields
                                                                ) || (
                                                                    dataType !== TABLE_DATA_TYPE
                                                                    && dataType !== LABEL_VALUE_ARRAY_DATA_TYPE
                                                                )
                                                            );
                                                        })
                                                            .map((labelValue) => {
                                                                const {
                                                                    isSensitive, maskingType, hasRequiredFields, dataType, arrayDataType,
                                                                } = labelValue;
                                                                let {
                                                                    label, value, hasError,
                                                                } = labelValue;
                                                                let err = 'Error in Fetching Data';
                                                                if (Object.values(SUPPORTED_FIELD_DATA_TYPES).includes(dataType)) {
                                                                    if (dataType === SUPPORTED_FIELD_DATA_TYPES.list) {
                                                                        if (arrayDataType === SUPPORTED_ARRAY_FIELD_DATA_TYPES.string) {
                                                                            value = value
                                                                                && Array.isArray(value)
                                                                                && value.join(', ');
                                                                        } else if (
                                                                            arrayDataType === SUPPORTED_ARRAY_FIELD_DATA_TYPES.labelValuePairV2
                                                                        ) {
                                                                            if (!hasError && !hasRequiredFields) {
                                                                                if (value && Array.isArray(value)) {
                                                                                    return value.map((item) => (
                                                                                        <div className='sop-box-cell'>
                                                                                            <div className='sop-box-cell-title'>
                                                                                                {item?.label || '-'}
                                                                                            </div>
                                                                                            <div className='sop-box-cell-value'>
                                                                                                {
                                                                                                    (
                                                                                                        item?.value
                                                                                                        && item?.data_type === 'DATA_TYPE_STRING'
                                                                                                        && item[item?.value]
                                                                                                    ) || '-'
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    ));
                                                                                }
                                                                                value = '';
                                                                                hasError = true;
                                                                                err = 'Error in formatting field';
                                                                            }
                                                                        } else {
                                                                            value = '';
                                                                            hasError = true;
                                                                            err = 'Field type is not supported';
                                                                        }
                                                                    }
                                                                    if (dataType === SUPPORTED_FIELD_DATA_TYPES.labelValuePairV2) {
                                                                        label = value?.label || label;
                                                                        value = value?.value && value[value?.value];
                                                                    }
                                                                    if (typeof value === 'object') {
                                                                        value = '';
                                                                        hasError = true;
                                                                        err = 'Object as value is not supported';
                                                                    }
                                                                } else if (
                                                                    dataType === TABLE_DATA_TYPE
                                                                    || dataType === LABEL_VALUE_ARRAY_DATA_TYPE
                                                                ) {
                                                                    value = '';
                                                                } else {
                                                                    value = '';
                                                                    hasError = true;
                                                                    err = 'Field type is not supported';
                                                                }
                                                                return (
                                                                    <div className='sop-box-cell'>
                                                                        <div className='sop-box-cell-title'>{label}</div>
                                                                        <div className='sop-box-cell-value'>
                                                                            {
                                                                                (isSensitive ? (applyMasking(value, maskingType)) : value)
                                                                            || '-'
                                                                            }
                                                                        </div>
                                                                        {
                                                                            hasError && (
                                                                                <div className='err-label sop-text-info'>{err}</div>
                                                                            )
                                                                        }
                                                                        {
                                                                            hasRequiredFields && (
                                                                                <div className='sop-text-info'>
                                                                                    Enter request values to fetch data
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                    }
                                                </div>
                                                {
                                                    labelValueArrayResonseValues
                                                    && Array.isArray(labelValueArrayResonseValues)
                                                    && labelValueArrayResonseValues.length > 0
                                                    && labelValueArrayResonseValues.map((labelValueArray) => {
                                                        const {
                                                            value, hasError, hasRequiredFields,
                                                        } = labelValueArray;

                                                        if (hasError || hasRequiredFields) return '';

                                                        if (!value || !(typeof value === 'object' && !Array.isArray(value))) return '';

                                                        const { label, value: arrayOfStrings } = value || {};

                                                        return (
                                                            <div className='sop-box-cell'>
                                                                <div className='sop-box-cell-title'>{label}</div>
                                                                <div className='sop-box-cell-value'>
                                                                    {
                                                                        (
                                                                            arrayOfStrings
                                                                            && Array.isArray(arrayOfStrings)
                                                                            && (
                                                                                arrayOfStrings.map((item) => (
                                                                                    <div>• {item}</div>
                                                                                ))
                                                                            )
                                                                        ) || '-'
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                        {
                                            tableResponseValues
                                            && Array.isArray(tableResponseValues)
                                            && tableResponseValues.length > 0
                                            && tableResponseValues.map((labelValueTable) => {
                                                const {
                                                    hasRequiredFields, label, value, hasError,
                                                } = labelValueTable;

                                                if (hasError || hasRequiredFields) return '';

                                                if (!value || !(typeof value === 'object' && !Array.isArray(value))) return '';

                                                return (
                                                    <div className='sop-box-cell'>
                                                        {
                                                            value && (
                                                                <Table
                                                                    v2
                                                                    header={value?.tableName || label}
                                                                    rowDataVersion={2}
                                                                    labelData={value?.columnData}
                                                                    contentData={value?.rowData}
                                                                    extClasses={{
                                                                        title: 'sop-step-table-title',
                                                                        headerCr: 'sop-step-table-label',
                                                                        contentCr: 'sop-step-table-content',
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                );
                                            })
                                        }
                                        {
                                            (index !== (sections.length - 1)) && <div className='line' />
                                        }
                                    </React.Fragment>
                                );
                            })
                        }
                    </div>
                )
            }
            <Button
                v2
                primary
                // disables the next cta if the condition type is complex as complex is calculated in the node layer
                disabled={conditionType === CONDITION_TYPE.complex}
                label='Next'
                onClick={() => getNextNode(mappings?.default)}
            />
        </React.Fragment>
    );
};

export default memo(InfoDisplayStep);

/**
 * @file BankingAccountDetails
 * Parent component that calls the account details API for the given ticket and passes the information to ViewSalaryAccountDetails component
 */

import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedSelector } from '../../../hooks';
import { isNilOrEmpty } from '../../../utils';
import { Button, Loader, SingleDatePicker } from '../../../components';

import { getSalaryAccountDetailsForAgentsAction, getSalaryActivationStatusAction } from '../actions';
import { INITIAL_DATE_RANGE, SALARY_ACTIVE_STATUS_DATE_INFO } from '../constants';
import { selectSalaryActivationStatus, selectViewSalaryAccountData } from '../selectors';
import { getTicketDetails } from '../utils';

import ViewSalaryAccountDetails from '../ViewSalaryAccountDetails';

const BankingAccountDetails = (props) => {
    const { screenKey } = props;

    const dispatch = useDispatch();

    const salaryData = useMemoizedSelector(selectViewSalaryAccountData, [screenKey]);
    const salaryActivationStatus = useMemoizedSelector(selectSalaryActivationStatus, [screenKey]);

    const [activeStatusDate, setActiveStatusDate] = useState(INITIAL_DATE_RANGE.toDate);

    const { loading, err, value: salaryActivationValue } = salaryActivationStatus || {};

    useEffect(() => {
        if (isNilOrEmpty(salaryData)) {
            dispatch(getSalaryAccountDetailsForAgentsAction({
                screenKey,
                payload: getTicketDetails(props),
            }));
        }
    }, []);

    const handleDateSubmit = () => {
        if (!activeStatusDate) return;

        dispatch(getSalaryActivationStatusAction({
            screenKey,
            payload: {
                ...getTicketDetails(props),
                timestamp: activeStatusDate.value,
            },
        }));
    };

    return (
        <div className='salops-ci'>
            <ViewSalaryAccountDetails
                screenKey={screenKey}
            />
            <div className='my-60'>
                <div className='frcsbWrapper'>
                    <SingleDatePicker
                        key='salary-account-active-status-date'
                        dateInfo={SALARY_ACTIVE_STATUS_DATE_INFO}
                        toDate={activeStatusDate}
                        setToDate={setActiveStatusDate}
                    />
                    <Button
                        v2
                        primary
                        label='Submit'
                        onClick={handleDateSubmit}
                        disabled={!activeStatusDate.value}
                    />
                </div>
                {salaryActivationValue ? (
                    <div className='mt-30 text-m'>Activation status for the selected date is: <b>{salaryActivationValue}</b></div>
                ) : null}
            </div>
            {err ? (
                <div className='err-label1'>
                    Could not fetch the activation status for the given user
                    {'\n'}
                    {err}
                </div>
            ) : null}
            <Loader visible={loading} />
        </div>
    );
};

export default memo(BankingAccountDetails);

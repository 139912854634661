/**
 *
 * KycAttemptList
 *
 */

import React, { memo, useContext } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { UserDetailsContext } from '../../../context';
import { Button } from '../../../components';
import backArrow from '../../../assets/images/svgs/back-arrow.svg';
import { copyTextWithStyle } from '../../../utils/uiHelpers';

import OnboardingTroubleshoot from '../OnboardingTroubleshoot';
import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';
import { makeSelectAgentInfo } from '../../AppV2/selectors';

const SHOW_RETRY_BUTTON = false;
const KycAttemptId = 'kyc-attempt';

const KycAttemptList = (props) => {
    const userInfo = useContext(UserDetailsContext);
    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);

    const {
        basicInfo: {
            accessLevelInfo: {
                isAdmin,
            },
        },
    } = userInfo;

    const {
        data, toggleHandler, allowRetryLiveness, onRetryLivenessCTA,
    } = props;

    const copyDataClickHandler = () => {
        copyTextWithStyle(KycAttemptId);
        pushRudderEvent(RudderEvent.KYCOnboarding.KYCDetailsCopyButtonClick, agentInfo.emailId);
    };

    return (
        <div className='kob'>
            <div className='kob-hw'>
                {/* eslint-disable-next-line max-len  */}
                {/* eslint-disable-next-line jsx-a11y/alt-text, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                <img className='kob-backarrow' src={backArrow} onClick={() => toggleHandler(false)} />
                <div className='kob-hw__hd'>KYC Details</div>
                <Button
                    v2
                    secondary
                    onClick={copyDataClickHandler}
                    label='Copy Data'
                />
            </div>
            <div className='table-sr table-sr--mt0' id={KycAttemptId}>
                <div className='table-sr__thw'>
                    <div className='table-sr__th'>Source</div>
                    <div className='table-sr__th'>Type</div>
                    <div className='table-sr__th'>Status</div>
                    <div className='table-sr__th'>Updated At</div>
                    <div className='table-sr__th f15Wrapper'>
                        OTP
                        <br />
                        Score/Threshold
                    </div>
                    <div className='table-sr__th f15Wrapper'>
                        Liveness
                        <br />
                        Score/Threshold
                    </div>
                    <div className='table-sr__th f25Wrapper'>
                        FaceMatch
                        <br />
                        (Score, Threshold, Status)
                    </div>
                    <div className='table-sr__th f15Wrapper'>Failure Reason</div>
                </div>
                <div className='table-sr__tlc'>
                    {
                        data.map((item) => (
                            <div className='table-sr__tlw table-sr__tlw--aic' key={item.id}>
                                <div className='table-sr__tl'>{item.source}</div>
                                <div className='table-sr__tl'>{item.type}</div>
                                <div className='table-sr__tl'>{item.status}</div>
                                <div className='table-sr__tl'>{item.updatedTs}</div>
                                <div className='table-sr__tl f15Wrapper'>{item.otpInfo.score} / {item.otpInfo.threshold}</div>
                                <div className='table-sr__tl f15Wrapper'>{item.livenessInfo.score} / {item.livenessInfo.threshold}</div>
                                <div className='table-sr__tl f25Wrapper'>
                                    {
                                        item.faceMatchInfo.map((fmItem) => (
                                            <div className='kob-modal-sicr' key={fmItem.attemptId}>
                                                <div className='kob-modal-siwr'>{fmItem.score}</div>
                                                <div className='kob-modal-siwr'>{fmItem.threshold}</div>
                                                <div className='kob-modal-siwr kob-modal-siwr--v1'>{fmItem.status} </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className='table-sr__tl f15Wrapper'>
                                    {
                                        item.failureReason ? (
                                            item.failureReason.map((frItem) => (
                                                <div className='kob-modal-sicr'>
                                                    <div className='kob-modal-siwr'>{frItem.type}</div>
                                                    <div className='kob-modal-siwr'>{frItem.description}</div>
                                                </div>
                                            ))
                                        ) : null
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                SHOW_RETRY_BUTTON && isAdmin ? (
                    <Button
                        primary
                        disabled={!allowRetryLiveness}
                        extClasses='kob-kycattempt__cta'
                        label='Allow user to Retry Liveness'
                        onClick={onRetryLivenessCTA}
                    />
                ) : null
            }
            <OnboardingTroubleshoot />
        </div>
    );
};

export default memo(KycAttemptList);

/**
 *
 * Liveness Summary
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import {
    Button, ExpandableCard, FormWithSingleInput, Loader, MonorailIdInput, HorizontalIdNavbar,
} from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import {
    getLivenessSummary, setMonorailId,
} from './actions';
import {
    makeSelectLoading, makeSelectErr, makeSelectSummaryInfo, makeSelectMonorailInfo,
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const LivenessSummary = (props) => {
    const { externalData = {} } = props;

    const dispatch = useDispatch();
    const actorIdRef = useRef(null);

    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const summaryInfo = useSelector(makeSelectSummaryInfo(), shallowEqual);
    const monorailInfo = useSelector(makeSelectMonorailInfo(), shallowEqual);

    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(true);
    const [actorId, setActorId] = useState('');
    const [formErrLabel, setFormErrLabel] = useState('');

    const { id: monorailId } = monorailInfo;

    // Set new ticket to true on initial render or when no monorail id present
    const [newTicket, setNewTicket] = useState(!monorailId);
    // Set navbar items to empty array on initial render or when no monorail id present
    const [navItems, setNavItems] = useState((monorailId && [{ id: monorailId }]) || []);

    const fetchLivenessSummary = (reqActorId, reqMonorailId) => {
        setFormErrLabel('');

        /**
         * Send '0' in case monorail ID is not passed since it is a mandatory param
         * This is required when LivenessSummary component is being used as a child in another component
         */
        dispatch(getLivenessSummary({
            monorailId: reqMonorailId || '0',
            actorId: reqActorId,
        }));
        setIsSearchCardExpanded(false);
    };

    useEffect(() => {
        if (monorailId && isSearchCardExpanded) {
            actorIdRef.current.focus();
        }
    }, [monorailId, isSearchCardExpanded]);

    useEffect(() => {
        // If actor ID is already passed through props, fetch liveness summary
        if (externalData.actorId) {
            setActorId(externalData.actorId);
            fetchLivenessSummary(externalData.actorId, '0');
        }
    }, [dispatch, externalData.actorId]);

    const resetMonorailId = () => {
        setNewTicket(true);
        setNavItems([]);
        dispatch(setMonorailId({ value: '' }));
    };

    const handleMonorailIdSubmit = (value) => {
        setActorId('');
        setIsSearchCardExpanded(true);
        setNewTicket(false);
        setNavItems([{ id: value }]);
        dispatch(setMonorailId({ value }));
    };

    const renderIdNavbar = (
        <HorizontalIdNavbar
            isFixed
            hasSingleItem
            isNewTicketActive={newTicket}
            activeId={monorailId}
            navItems={navItems}
            onItemCloseIconClick={resetMonorailId}
        />
    );

    if (!externalData.actorId && !monorailId) {
        return (
            <React.Fragment>
                {renderIdNavbar}
                <MonorailIdInput submitMonorailId={handleMonorailIdSubmit} />
            </React.Fragment>
        );
    }

    const handleInputChange = (key) => (event) => {
        const { value } = event.target;

        switch (key) {
            case 'actorId':
                setActorId(value);
                break;

            default:
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const modifiedActorId = actorId.trim();

        if (!modifiedActorId) {
            setFormErrLabel('Please fill all the * marked fields');
            return;
        }

        fetchLivenessSummary(modifiedActorId, monorailId);
    };

    const renderLivenessSummaryInfo = () => {
        if (!summaryInfo) {
            return null;
        }

        if (summaryInfo.length === 0) {
            return (<div className='lsmry-src'>No Liveness Summary info found.</div>);
        }

        return (
            <div className='lsmry-src'>
                {JSON.stringify(summaryInfo, null, 4)}
            </div>
        );
    };

    return (
        <React.Fragment>
            {/**
             * Show monorail id tab only when:
             * If monorail id is present. If it is not present, it causes side effect on clicking the cross button
             * If externalData.actorId is not present. If it is present, that means the component is getting used as a child component somewhere else
            */}
            {!externalData.actorId && monorailId && (
                renderIdNavbar
            )}

            <div className='lsmry-cr'>
                <ExpandableCard
                    extClasses='lsmry-ec'
                    heading='Search for Liveness Summary'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='lsmry-sc'>
                        <FormWithSingleInput
                            hideErrLabel
                            disableFormSubmitOnEnter
                            extClasses={{
                                container: 'lsmry-fc--m0',
                                label: 'lsmry-fc__lb',
                                inputField: 'lsmry-fc__ip',
                            }}
                            label='Actor Id*'
                            labelId='actor-id'
                            input={{
                                value: actorId,
                                onChange: handleInputChange('actorId'),
                                ref: actorIdRef,
                            }}
                            onFormSubmit={handleSubmit}
                        />
                        {formErrLabel ? <div className='err-label lsmry-el'>{formErrLabel}</div> : null}
                        <Button
                            extClasses={`lsmry-sc-cta ${formErrLabel && 'lsmry-sc-cta--err'}`}
                            primary
                            label='Search'
                            onClick={handleSubmit}
                        />
                    </div>
                </ExpandableCard>
                {err ? (<div className='container-err-label lsmry-crel'>{err}</div>) : null}
                {renderLivenessSummaryInfo()}
            </div>

            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'livenessSummary', reducer });
const withSaga = injectSaga({ key: 'livenessSummary', saga });

export default compose(
    withReducer,
    withSaga,
)(LivenessSummary);

/**
* @file Link: Link component
*/

import React, { memo } from 'react';
import classNames from 'classnames';

import './style.scss';

const Link = (props) => {
    /**
     * label: the text that will be rendered in the link
     * link: href link
     * button: boolean - makes the link look like a button
     */
    const {
        label, href, extClasses, button,
    } = props;

    const linkClasses = classNames({
        'link-wrapper-btn': button,
    }, extClasses);

    return (
        <a
            href={href}
            className={linkClasses}
        >
            {label}
        </a>
    );
};

export default memo(Link);

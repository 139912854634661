/**
* @file CopyToClipboard: component allows to copy text to clipboard
*/

import React, { memo, useState } from 'react';
import copy from 'copy-text-to-clipboard';

import CopyIcon from '../../assets/images/icons/copy.png';
import CopiedIcon from '../../assets/images/icons/green-tick.png';

import './style.scss';

/**
 * text: copy text string
*/
const CopyToClipboard = (props) => {
    const {
        text,
    } = props;

    // local state variables
    const [isCopied, setIsCopied] = useState(false);

    const onCopyLinkClick = (item) => () => {
        setIsCopied(true);
        copy(`${item}`); // need to pass it as string, else it won't copy

        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    };

    return (
        text ? (
            <button type='button' onClick={onCopyLinkClick(text)}>
                <img
                    className='copy-text-icon'
                    src={!isCopied ? CopyIcon : CopiedIcon}
                    alt={!isCopied ? 'copy' : 'copied'}
                />
            </button>
        ) : null
    );
};

export default memo(CopyToClipboard);

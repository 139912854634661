/**
 *
 * Allows users to set a new password.
 *
 */

import React, { memo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// styling
import '../style.scss';
import { Button, FormWithSingleInput } from '../../../components';
import { validators } from '../../../utils';
import { makeSelectUserInfo } from '../selectors';
import { updateNewPassword } from '../actions';
// redux
// import { setNewPassword } from '../actions';

const NewPassword = () => {
    const dispatch = useDispatch();
    // global state
    const userInfo = useSelector(makeSelectUserInfo(), shallowEqual);
    // local state
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errNewPassword, setErrNewPassword] = useState('');
    const [errConfirmPassword, setErrConfirmPassword] = useState('');

    const handleNewPassword = (e) => {
        setNewPassword(e.target.value);
        if (errNewPassword) setErrNewPassword('');
    };
    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);

        if (errConfirmPassword) setErrConfirmPassword('');
    };

    const validateInput = () => {
        let isValid = true;

        if (!validators.isAWSPasswordValid(newPassword)) {
            setErrNewPassword('Password is not set according to required policy');
            isValid = false;
        }

        if (newPassword !== confirmPassword) {
            setErrConfirmPassword('Passwords do not match');
            isValid = false;
        }

        return isValid;
    };

    const handleNewPwdSubmit = () => {
        const valid = validateInput();

        if (!valid) return;

        dispatch(updateNewPassword({
            username: userInfo.username,
            password: newPassword,
            email: userInfo.email,
        }));
    };

    return (
        <div className='login-cr2'>
            <div className='login-header login-item--fit'>
                Set a New Password
            </div>
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'login-ff-cr',
                    label: 'login-ff-lb2',
                    errLabel: 'login-ff-errlb2',
                    inputField: 'login-ff-ip',
                }}
                label='New Password'
                labelId='login-new-password'
                description='Minimum 8 characters. At least 1 numeric character, 1 special character, 1 uppercase character, 1 lowercase character'
                input={{
                    value: newPassword,
                    onChange: handleNewPassword,
                    type: 'password',
                }}
                errLabel={errNewPassword}
                onFormSubmit={handleNewPwdSubmit}
            />
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'login-ff-cr',
                    label: 'login-ff-lb2',
                    errLabel: 'login-ff-errlb2',
                    inputField: 'login-ff-ip',
                }}
                label='Confirm Password'
                labelId='login-confirm-password'
                input={{
                    value: confirmPassword,
                    onChange: handleConfirmPassword,
                    type: 'password',
                }}
                errLabel={errConfirmPassword}
                onFormSubmit={handleNewPwdSubmit}
            />
            <Button
                extClasses='login-item'
                primary
                label={'Set New Password'}
                onClick={handleNewPwdSubmit}
            />
        </div>
    );
};

export default memo(NewPassword);

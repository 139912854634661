/*
 *
 * LivenessSummary reducer
 *
 */

import { fromJS } from 'immutable';
import {
    GET_LIVENESS_SUMMARY, SET_LIVENESS_SUMMARY, ERR_LIVENESS_SUMMARY,
    SET_MONORAIL_ID,
} from './constants';

const initialState = fromJS({
    monorailInfo: {
        id: '',
    },
    loading: false,
    err: '',
    summaryInfo: '',
});

function livenessSummaryReducer(state = initialState, action) {
    switch (action.type) {
        case GET_LIVENESS_SUMMARY:
            return state
                .set('loading', true)
                .set('err', '')
                .set('summaryInfo', '');

        case SET_LIVENESS_SUMMARY: {
            const { livenessSummaryInfo } = action.data;

            return state
                .set('loading', false)
                .set('summaryInfo', livenessSummaryInfo);
        }

        case ERR_LIVENESS_SUMMARY: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err);
        }

        case SET_MONORAIL_ID: {
            const { value } = action.data;

            if (value) {
                return state.setIn(['monorailInfo', 'id'], value);
            }

            return initialState;
        }

        default:
            return state;
    }
}

export default livenessSummaryReducer;

/**
 *
 * Profile - Component to show all the available SOP .
 *
 */

import React, { useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, Redirect } from 'react-router-dom';

import { Button, LoaderOrError, Table } from '../../../../components';
import { MetaInfoContext } from '../../../../context';

import CreateNewTicketModal from '../../../Home/CreateNewTicketModal';
import { HOME_PATH, VIEW_SOP_USER_DETAILS_PATH } from '../../../AppV2/routes';
import { selectCreatedTicket } from '../../../AppV2/selectors';

import { selectCallerId, selectUserTickets } from '../../selector';
import { getUserTicketsAction } from '../../actions';

const renderActions = (item, index) => (
    <Link
        key={item?.id || index + 1}
        to={{
            pathname: VIEW_SOP_USER_DETAILS_PATH({ ticketId: item?.meta }),
        }}
        className='sop-table-cta-link'
    >
        {
            item?.actions[0]?.label || 'Select'
        }
    </Link>
);

const Tickets = () => {
    const dispatch = useDispatch();
    const callerId = useSelector(selectCallerId, shallowEqual);
    const userTickets = useSelector(selectUserTickets, shallowEqual);
    const createTicketForCall = useSelector(selectCreatedTicket, shallowEqual);
    const {
        loading: createTicketLoading, data: createdTicket, err: createTicketErr,
    } = createTicketForCall || {};

    const { state } = useLocation();
    const { ucid } = state || {};
    const metaInfo = useContext(MetaInfoContext);
    const {
        tickets: {
            reasonInfo: {
                callListTable,
            },
        },
    } = metaInfo;

    const [loadingText, setLoadingText] = useState('');
    const [reasonModalVisibility, setReasonModalVisibility] = useState(false);
    const [statusFilterValue, setStatusFilterValue] = useState();

    const {
        loading: userTicketsLoading, err: userTicketsErr, data,
    } = userTickets || {};

    const { ticketsTable } = data || {};

    const getTickets = (loaderText, extraParams = {}) => new Promise((resolve, reject) => {
        try {
            setLoadingText(loaderText);
            dispatch(getUserTicketsAction({
                screenKey: ['userTickets'], callerUserId: callerId, resolve, clearData: true, ...extraParams,
            }));
        } catch (e) {
            reject(e);
        }
    });

    useEffect(() => {
        if (callerId) {
            getTickets('Connecting to Call...').finally(() => setLoadingText(''));
        }
    }, [callerId]);

    if (!callerId) {
        return (
            <Redirect to={HOME_PATH} />
        );
    }

    return (
        <React.Fragment>
            <div>
                <div className='frcsbWrapper'>
                    <div className='sop-heading'>User Tickets</div>
                    <div className='frcWrapper'>
                        <div className='p-5'>
                            <Button
                                v2
                                primary
                                label='Create Ticket'
                                onClick={() => setReasonModalVisibility(true)}
                            />
                        </div>
                        <div className='p-5'>
                            <Button
                                v2
                                secondary
                                label='Force refresh data'
                                onClick={() => {
                                    setStatusFilterValue();
                                    getTickets('Fetching Tickets...', { fetchFromSource: true }).finally(() => setLoadingText(''));
                                }}
                            />
                        </div>
                    </div>
                </div>
                <Table
                    v2
                    rowDataVersion={2}
                    objKey='header_key'
                    labelData={ticketsTable?.columnData}
                    contentData={ticketsTable?.rowData}
                    renderActionableColumn={renderActions}
                    prevToken={ticketsTable?.prevToken}
                    nextToken={ticketsTable?.nextToken}
                    paginatedClickHandler={({ prev, next }) => (
                        getTickets('Fetching Tickets...', { prev, next, statusList: statusFilterValue }).finally(() => setLoadingText(''))
                    )}
                    filterDetails={{
                        Status: {
                            value: statusFilterValue,
                            setValue: setStatusFilterValue,
                            onSubmit: (value) => getTickets('Filtering Tickets...', { statusList: value }).finally(() => setLoadingText('')),
                        },
                    }}
                    extClasses={{
                        container: 'sop-cc sop-table',
                        headerCr: 'sop-table-header',
                        contentCr: 'sop-table-cont',
                        rowCr: 'sop-table-row',
                        status: {
                            label: 'frccWrapper frwpWrapper',
                        },
                        productCategory: {
                            label: 'sop-table-column-large',
                            content: 'sop-table-column-large',
                        },
                        productCategoryDetails: {
                            label: 'sop-table-column-large',
                            content: 'sop-table-column-large',
                        },
                        subCategory: {
                            label: 'sop-table-column-large',
                            content: 'sop-table-column-large',
                        },
                        id: {
                            label: 'sop-table-column-short',
                            content: 'sop-table-column-short',
                        },
                    }}
                />
            </div>
            <CreateNewTicketModal
                reasonList={callListTable}
                setLoadingText={setLoadingText}
                ucid={ucid}
                reasonModalVisibility={reasonModalVisibility}
                createdTicket={createdTicket}
                onResetReasonState={() => setReasonModalVisibility(false)}
            />
            <LoaderOrError
                loading={createTicketLoading || userTicketsLoading}
                errorLabel={createTicketErr || userTicketsErr}
                loadingText={loadingText}
            />
        </React.Fragment>
    );
};

export default Tickets;

/**
 * @file DefaultRoute component to render the flow for call history or directly enter ticket id flow
 */

import React, { memo, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MetaInfoContext } from '../../../context';
import { FormWithSingleInput } from '../../../components';

import CallFlow from '../CallFlow';
import { setTicketId } from '../actions';

const DefaultRoute = (props) => {
    const dispatch = useDispatch();
    const metaInfo = useContext(MetaInfoContext);

    // context api variables
    const {
        appConfig: {
            enableSingleTicketCreationFlow,
        },
    } = metaInfo;

    // local state variables
    const [showTicketIdInputPage, setTicketIdInputPage] = useState(false);

    const { ticket, index, handleSubmit } = props;
    const { ticketId, errLabel } = ticket;

    const handleInputChange = () => (e) => {
        dispatch(setTicketId({ ticketId: e.target.value, index }));
    };

    const setTicketIdAndSubmit = (callTicketId) => {
        dispatch(setTicketId({ ticketId: callTicketId, index }));
        handleSubmit(callTicketId, index, true)();
    };

    // render ticket id input page
    if (!enableSingleTicketCreationFlow || showTicketIdInputPage) {
        return (
            <FormWithSingleInput
                label='Ticket ID'
                labelId='ticket'
                extClasses={{
                    container: 'ticket-form-container',
                    label: 'ticket-form-container__label',
                    errLabel: 'ticket-form-container__err-label',
                }}
                input={{
                    value: ticketId,
                    pattern: '\\d+',
                    onChange: handleInputChange(),
                }}
                cta={{
                    primaryLabel: 'Submit',
                    onPrimaryClick: handleSubmit(ticketId, index),
                }}
                errLabel={errLabel}
                onFormSubmit={handleSubmit(ticketId, index)}
            />
        );
    }

    return (
        <CallFlow
            setTicketIdInputPage={setTicketIdInputPage}
            setTicketIdAndSubmit={setTicketIdAndSubmit}
        />
    );
};

export default memo(DefaultRoute);

/**
 *
 * Onboarding Info - Component to show the Wealth Current Stage Onboarding Troubleshoot Info and all the stages detailed info
 *
 */

import React, { memo, useState } from 'react';
import classNames from 'classnames';

import backArrow from '../../../assets/images/svgs/back-arrow.svg';

import CurrentStageOnboardingTroubleshoot from '../CurrentStageOnboardingTroubleshoot';
import OnboardingMetaInfo from '../Modals/OnboardingMetaInfo';

const OnboardingInfo = (props) => {
    const [metaInfo, setMetaInfo] = useState({});

    const { data, toggleHandler, currentStageTroubleshootInfo } = props;

    return (
        <React.Fragment>
            <div className='wonb'>
                <div className='wonb-hw'>
                    <img className='wonb-backarrow' src={backArrow} onClick={() => toggleHandler(false)} />
                    <div className='wonb-hw__hd'>
                        Wealth Onboarding Details
                    </div>
                </div>
                <CurrentStageOnboardingTroubleshoot data={currentStageTroubleshootInfo} />
                <div className='table-sr table-sr--mt0'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th f15Wrapper'>Stage</div>
                        <div className='table-sr__th'>Status</div>
                        <div className='table-sr__th f05Wrapper'>Updated At</div>
                        <div className='table-sr__th f15Wrapper'>Description</div>
                        <div className='table-sr__th f05Wrapper'>Meta Info</div>
                    </div>
                    <div className='table-sr__tlc wonb-onbtable'>
                        {
                            data.map(item => (// Map data structure
                                <div className='table-sr__tlw table-sr__tlw--aic' key={item[0]}>
                                    <div className='table-sr__tl f15Wrapper'>{item[0]}</div>
                                    <div className='table-sr__tl'>{item[1].status}</div>
                                    <div className='table-sr__tl f05Wrapper'>{item[1].updatedTs}</div>
                                    <div className='table-sr__tl f15Wrapper'>{item[1].description}</div>
                                    <div
                                        className={classNames(
                                            'table-sr__tl f05Wrapper',
                                            item[1].metaInfo && 'link'
                                        )}
                                        onClick={() => setMetaInfo(item[1].metaInfo || {})}
                                    >
                                        {item[1].metaInfo ? 'View Details' : '-'}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <OnboardingMetaInfo
                visible={Object.keys(metaInfo).length}
                data={metaInfo}
                toggleHandler={() => setMetaInfo({})}
            />
        </React.Fragment>
    );
};

export default memo(OnboardingInfo);

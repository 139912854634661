/**
 * @file SherlockV1Search contains rudder event names for Sherlock V1 ticket summary page and it's entry points to Sherlock v2
 */

const ClickedSherlockSearchBar = 'ClickedSherlockSearchBar';
const SearchedSherlockScriptSearchBar = 'SearchedSherlockScriptSearchBar';
const ClickedSherlockIndividualScriptSearchBar = 'ClickedSherlockIndividualScriptSearchBar';
const ClickedSherlockSearchBarAllScripts = 'ClickedSherlockSearchBarAllScripts';

export {
    ClickedSherlockSearchBar,
    SearchedSherlockScriptSearchBar,
    ClickedSherlockIndividualScriptSearchBar,
    ClickedSherlockSearchBarAllScripts,
};

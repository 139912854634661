/**
 * @file ViewRequestsForActor
 * View all the requests like liveness for a particular actor ID
 */

import React, { memo, useEffect, useState } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, Table } from '../../../components';
import { useMemoizedSelector } from '../../../hooks';
import { setRiskOpsCurCaseAction } from '../../RiskOps/actions';
import SingleCaseHeading from '../../RiskOps/SingleCaseHeading';
import { getCxRiskReviewRequestsForActorAction, setCxRiskReviewCurViewAction } from '../actions';

import {
    CX_PAYLOAD_TYPE_OPTIONS, CX_REQUESTS_LABEL_DATA, CX_RISK_REVIEW_KEY, VIEW_ALL_TICKETS, VIEW_SINGLE_CASE,
} from '../constants';
import { selectCxRiskCurTicketDetails, selectCxRiskRequestsList } from '../selectors';

const ViewRequestsForActor = () => {
    const dispatch = useDispatch();

    const ticketDetails = useSelector(selectCxRiskCurTicketDetails, shallowEqual);
    const { actorId } = ticketDetails;
    const requestsList = useMemoizedSelector(selectCxRiskRequestsList, [actorId]);

    const [payloadType] = useState(CX_PAYLOAD_TYPE_OPTIONS[0]);

    useEffect(() => {
        if (!requestsList) dispatch(getCxRiskReviewRequestsForActorAction({ actorId, payloadType: payloadType.value }));
    }, [dispatch, actorId, payloadType.value]);

    const handleReviewClick = (caseData) => {
        dispatch(setRiskOpsCurCaseAction({ containerKey: CX_RISK_REVIEW_KEY, caseData }));
        dispatch(setCxRiskReviewCurViewAction(VIEW_SINGLE_CASE));
    };

    const handBackArrow = () => {
        dispatch(setCxRiskReviewCurViewAction(VIEW_ALL_TICKETS));
    };

    const renderActions = (item, index) => (
        <div className='frfscWrapper'>
            <Button
                v2
                link
                label='Review'
                onClick={() => handleReviewClick({
                    ...item.ogData,
                    index,
                })}
            />
        </div>
    );

    return (
        <React.Fragment>
            <div className='frcsbWrapper mb-30'>
                <SingleCaseHeading
                    title={`Requests For Actor: ${actorId}`}
                    onBackArrowCTA={handBackArrow}
                />
                {/* <FormDropdown
                    options={CX_PAYLOAD_TYPE_OPTIONS}
                    input={{
                        value: payloadType,
                        onChange: (newValue) => setPayloadType(newValue),
                        placeholder: 'Select Payload',
                    }}
                    cacheKey='cx-risk-review-payload-type'
                    extStyles={{
                        container: 'cxrisk-fc1',
                        label: 'cxrisk-fc__lb',
                        inputField: 'cxrisk-fc__ip',
                    }}
                /> */}
            </div>
            <Table
                v2
                labelData={CX_REQUESTS_LABEL_DATA}
                contentData={requestsList}
                renderActionableColumn={renderActions}
            />
        </React.Fragment>
    );
};

export default memo(ViewRequestsForActor);

/**
 * @file Manages the selector functions for Agent Management component
 */

import { createSelector } from 'reselect';

import { AGENT_MANAGEMENT_KEY } from './constants';

// const selectAgentManagement = state => state.get(AGENT_MANAGEMENT_KEY);

const makeSelectAccessLevelList = () => createSelector(
    (state) => state.getIn([AGENT_MANAGEMENT_KEY, 'accessLevelList']),
    substate => substate.toJS(),
);

const makeSelectAgentTypeList = () => createSelector(
    (state) => state.getIn([AGENT_MANAGEMENT_KEY, 'agentTypeList']),
    substate => substate.toJS(),
);

const makeSelectAgentStatusList = () => createSelector(
    (state) => state.getIn([AGENT_MANAGEMENT_KEY, 'agentStatusList']),
    substate => substate.toJS(),
);

const makeSelectAgentList = () => createSelector(
    (state) => state.getIn([AGENT_MANAGEMENT_KEY, 'agentList']),
    substate => substate.toJS(),
);

const makeSelectAgentInfo = () => createSelector(
    (state) => state.getIn([AGENT_MANAGEMENT_KEY, 'agentInfo']),
    substate => substate.toJS(),
);

const makeSelectAgentFormFields = () => createSelector(
    (state) => state.getIn([AGENT_MANAGEMENT_KEY, 'agentFormFields']),
    substate => substate.toJS(),
);

const makeSelectCreateOrUpdateAgentInfo = () => createSelector(
    (state) => state.getIn([AGENT_MANAGEMENT_KEY, 'createOrUpdateAgent']),
    substate => substate.toJS(),
);

export {
    makeSelectAccessLevelList,
    makeSelectAgentTypeList,
    makeSelectAgentStatusList,
    makeSelectAgentList,
    makeSelectAgentInfo,
    makeSelectAgentFormFields,
    makeSelectCreateOrUpdateAgentInfo,
};

/**
 * PathsHelper.js
 * File to store all the helping constants and functions for defining Navbar paths and routes in Layout
 * Used Object.freeze because of https://dev.to/bytebodger/javascript-constants-with-objectfreeze-4beg
 */

import Search from '../../Search';
import Home from '../../Home';
import Tickets from '../../Tickets';
import Scripts from '../../Scripts';
import SOP from '../../SOP';
import VKYCFlow from '../../VKYCFlow';
import VkycAuditorFlow from '../../VkycAuditorFlow';

import {
    HOME_PATH, SCRIPTS_PATH, SOP_PATH, TICKETS_PATH,
    VKYC_PATH, VKYC_AUDITOR_PATH, SEARCH_PATH,
} from '../routes';

// Define role constants
export const ROLES_FOR_NORMAL_SHERLOCK_V2 = Object.freeze({
    AGENT: 'AGENT',
    ADMIN: 'ADMIN',
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN_RESTRICTED: 'ADMIN_RESTRICTED',
});

export const ROLES_FOR_VKYC = Object.freeze({
    VKYC_CALL_AGENT: 'VKYC_CALL_AGENT',
    VKYC_CALL_AUDITOR: 'VKYC_CALL_AUDITOR',
});

export const ROLES = Object.freeze({
    ...ROLES_FOR_NORMAL_SHERLOCK_V2,
    ...ROLES_FOR_VKYC,
});

// Define screen constants
export const SCREENS = Object.freeze({
    SHERLOCK_V2_GENERIC: 'SHERLOCK_V2_GENERIC',
});

// Map roles to screens
export const ROLE_TO_SCREEN_MAPPING = Object.freeze({
    [ROLES.ADMIN]: SCREENS.SHERLOCK_V2_GENERIC,
    [ROLES.AGENT]: SCREENS.SHERLOCK_V2_GENERIC,
    [ROLES.SUPER_ADMIN]: SCREENS.SHERLOCK_V2_GENERIC,
    [ROLES.ADMIN_RESTRICTED]: SCREENS.SHERLOCK_V2_GENERIC,
    [ROLES.VKYC_CALL_AGENT]: SCREENS.SHERLOCK_V2_GENERIC,
    [ROLES.VKYC_CALL_AUDITOR]: SCREENS.SHERLOCK_V2_GENERIC,
});

export const ROLE_OPTIONS = Object.freeze({
    [ROLES.VKYC_CALL_AGENT]: {
        isSideBarExpanded: false,
    },
    [ROLES.VKYC_CALL_AUDITOR]: {
        isSideBarExpanded: false,
        isSearchBarVisible: false,
    },
    [ROLES.VKYC_CALL_AGENT]: {
        isSideBarExpanded: false,
        isSearchBarVisible: false,
    },
});

const ADMIN_NAVBAR_OPTIONS = {
    HOME: {
        label: 'Home',
        path: HOME_PATH,
        component: Home,
        img: './svgs/home.svg',
        id: 'home',
        subLinks: [],
    },
    SEARCH: {
        label: 'Search',
        path: SEARCH_PATH,
        component: Search,
        img: './svgs/search.svg',
        id: 'search',
        subLinks: [],
    },
    MY_TICKETS: {
        label: 'My Tickets',
        path: TICKETS_PATH,
        component: Tickets,
        img: './svgs/tickets.svg',
        id: 'tickets',
        subLinks: [],
    },
    SCRIPTS: {
        label: 'Scripts',
        path: SCRIPTS_PATH,
        component: Scripts,
        img: './svgs/scripts.svg',
        id: 'scripts',
        subLinks: [],
    },
    SOP: {
        label: 'SOP',
        path: SOP_PATH,
        component: SOP,
        img: './svgs/sop.svg',
        id: 'sop',
        subLinks: [],
    },
    DEFAULT: {
        label: 'Home',
        path: HOME_PATH,
        component: Home,
        img: './svgs/home.svg',
        id: 'home',
        subLinks: [],
    },
};

// Define navbar mappings for roles
export const ROLE_TO_NAVBAR_MAPPING = Object.freeze({
    [ROLES.ADMIN]: Object.freeze(ADMIN_NAVBAR_OPTIONS),
    [ROLES.AGENT]: Object.freeze(ADMIN_NAVBAR_OPTIONS),
    [ROLES.SUPER_ADMIN]: Object.freeze(ADMIN_NAVBAR_OPTIONS),
    [ROLES.ADMIN_RESTRICTED]: Object.freeze(ADMIN_NAVBAR_OPTIONS),
    [ROLES.VKYC_CALL_AGENT]: Object.freeze({
        VKYC: {
            label: 'VKYC',
            path: VKYC_PATH,
            component: VKYCFlow,
            img: './svgs/sop.svg',
            id: 'vkyc',
            subLinks: [],
        },
        DEFAULT: {
            label: 'VKYC',
            path: VKYC_PATH,
            component: VKYCFlow,
            img: './svgs/sop.svg',
            id: 'vkyc',
            subLinks: [],
        },
    }),
    [ROLES.VKYC_CALL_AUDITOR]: Object.freeze({
        VKYC: {
            label: 'VKYC AUDITOR',
            path: VKYC_AUDITOR_PATH,
            component: VkycAuditorFlow,
            img: './svgs/sop.svg',
            id: 'vkyc-auditor',
            subLinks: [],
        },
        DEFAULT: {
            label: 'VKYC AUDITOR',
            path: VKYC_AUDITOR_PATH,
            component: VkycAuditorFlow,
            img: './svgs/sop.svg',
            id: 'vkyc-auditor',
            subLinks: [],
        },
    }),
});

/**
 * @file CardStatus: Component to show the status of the card (e.g. 'blocked', 'suspended')
 */

import React, { memo } from 'react';

import { Button, Loader } from '../../../components';

import CardBanner from '../CardBanner';

import './style.scss';

const CardStatus = (props) => {
    const { cardInformation, loader, ticketId } = props;
    const {
        title, buttonLabel, descriptionOne, descriptionTwo, onClick, discardConfirmScreen,
        discardKey,
    } = cardInformation || {};

    return (
        <CardBanner title={title} discardKey={discardKey} ticketId={ticketId}>
            <React.Fragment>
                <div className='heading6 text-center mb-40'>
                    <div>{descriptionOne}</div>
                    <div className='heading6 text-center mt-15'>
                        {descriptionTwo}
                    </div>
                </div>
                <div className='frcseWrapper'>
                    <Button
                        primary
                        label={buttonLabel}
                        extClasses='credit-card-cs__bw'
                        onClick={onClick}
                    />
                    <Button
                        secondary
                        label='Discard'
                        extClasses='credit-card-cs__bw'
                        onClick={discardConfirmScreen}
                    />
                </div>
                <Loader visible={loader} />
            </React.Fragment>
        </CardBanner>
    );
};

export default memo(CardStatus);

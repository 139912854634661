/**
 * @file Manages the action functions for risk rule container
 */

import {
    CREATE_RULE, GET_RULE_QUEUE, GET_TAGS, RESET_RULE, UPADATE_RULE,
} from './constants';

export const getRuleQueueAction = (data) => ({
    type: GET_RULE_QUEUE,
    data,
});

export const createRuleAction = (data) => ({
    type: CREATE_RULE,
    data,
});

export const updateRuleAction = (data) => ({
    type: UPADATE_RULE,
    data,
});

export const resetRuleAction = (data) => ({
    type: RESET_RULE,
    data,
});

export const getTags = () => ({ type: GET_TAGS });

/**
*
* DropdownWrapper
*
*/

import React, { memo } from 'react';

import OutsideAlerter from '../OutsideAlerter';

const DropdownWrapper = props => {
    const { children, visible, onOutsideClickHandler, extClasses } = props;

    return (
        <OutsideAlerter
            visible = {visible}
            onClickHandler = {visible && onOutsideClickHandler}
            containerClass = {extClasses && extClasses.container}
        >
            {children}
        </OutsideAlerter>
    );
};

export default memo(DropdownWrapper);

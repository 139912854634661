/**
 * @file Selectors for FreshdeskTicketInfo
 */

import { createSelector } from 'reselect';

import { FRESHDESK_TICKET_INFO_KEY, REDUCER } from './constants';

export const selectFDTicketInfoLoading = createSelector(
    (state) => state.getIn([FRESHDESK_TICKET_INFO_KEY, REDUCER.LOADING]),
    (subState) => subState,
);

export const selectFDTicketInfoError = createSelector(
    (state) => state.getIn([FRESHDESK_TICKET_INFO_KEY, REDUCER.ERR]),
    (subState) => subState,
);

export const selectFDTicketInfoCategories = createSelector(
    (state) => state.getIn([FRESHDESK_TICKET_INFO_KEY, REDUCER.CATEGORY_INFO]),
    (subState) => subState.toJS(),
);

export const selectFDTicketInfo = (ticketId) => createSelector(
    (state) => state.getIn([FRESHDESK_TICKET_INFO_KEY, REDUCER.TICKET_INFO, ticketId]),
    (subState) => (subState ? subState.toJS() : {}),
);

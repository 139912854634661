/* eslint-disable */
/*
 *
 * File Moved to App Global (deprecated)
 * 
 */
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';
import { pathOr } from 'ramda';

// import { history } from '../../reducers';
import {
    clientApiWrapper, getErrLabel, toastify, setCookie, deleteCookie, getQueryStringFromObject,
    getErrorObjectForSaga, showErrorToastMsg,
} from '../../utils';
import {
    AUTH_ROOT, AGENT_ROOT_V2, GET_LOGGED_IN_AGENT_ENDPOINT, META_INFO_ENDPOINT, ACCESS_LEVEL_LIST_ENDPOINT, BANNER_LIST_ENDPOINT,
} from '../../api/routes';
import { SET_LOGIN_URL, SET_ACCESS_LEVEL, GET_AGENT_INFO } from '../../constants/actions';
import {
    GET_AUTH_STATUS, SET_AUTH_STATUS, SET_AGENT_INFO, ERR_AGENT_INFO,
    GET_AUTH_LOGOUT, SET_AUTH_STATUS_LOADER, SET_SECURITY_TOKEN, GET_META_INFO,
    SET_META_INFO, GET_ACCESS_LEVEL_LIST, SET_ACCESS_LEVEL_LIST,
    GET_BANNER_LIST, SET_BANNER_LIST, ERR_BANNER_LIST,
} from './constants';
import { setAccessLevelInMetaTag } from './utils';

const setCSRFMetaTag = (csrfToken) => {
    const meta = document.createElement('meta');
    meta.name = 'csrf-token';
    meta.content = csrfToken;

    document.getElementsByTagName('head')[0].appendChild(meta);
};

const getErrorAction = (actionType, containerKey) => ({
    type: actionType,
    data: { containerKey },
});

function* getEnableAgentManagementV2Flag(action) {
    const enableAgentManagementV2 = pathOr(false, ['metaInfo', 'appConfig', 'enableAgentManagementV2'], action.data);

    return enableAgentManagementV2;
}

function* getEnableSingleTicketCreationFlowFlag(action) {
    const enableSingleTicketCreationFlowFlag = pathOr(false, ['metaInfo', 'appConfig', 'enableSingleTicketCreationFlow'], action.data);

    return enableSingleTicketCreationFlowFlag;
}

function* getMetaInfo(action) {
    const { returnEnableAgentManagementV2Flag } = action.data;

    let response;

    try {
        response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            META_INFO_ENDPOINT,
        );

        yield put({ type: SET_META_INFO, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
    }

    const enableAgentManagementV2 = yield call(getEnableAgentManagementV2Flag, { data: response });

    // set auth_version cookie with v2 value if the Agent Management v2 flag is enabled, else v1
    if (enableAgentManagementV2) {
        setCookie('auth_version', 'v2');
    } else {
        setCookie('auth_version', 'v1');
    }

    const enableSingleTicketCreationFlowFlag = yield call(getEnableSingleTicketCreationFlowFlag, { data: response });

    // set single_ticket_creation_flow cookie with true value if the Single Ticket Creation Flow flag is enabled
    if (enableSingleTicketCreationFlowFlag) {
        setCookie('single_ticket_creation_flow', true);
    } else {
        deleteCookie('single_ticket_creation_flow');
    }

    if (returnEnableAgentManagementV2Flag) {
        return enableAgentManagementV2;
    }
}

function* getAuthStatus(action) {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${AUTH_ROOT}/status`
        );

        const { csrfToken } = response;

        // only for development, handled from server side
        if (csrfToken) {
            setCSRFMetaTag(csrfToken);
        }

        yield put({
            type: SET_AUTH_STATUS,
            data: {
                cspNonce: response.cspNonce,
            },
        });

        yield put({ type: GET_ACCESS_LEVEL_LIST });

        // get Agent Management v2 flag from consul meta info
        const enableAgentManagementV2 = yield call(
            getMetaInfo,
            {
                data: {
                    returnEnableAgentManagementV2Flag: true,
                }
            },
        );

        // get agent info with different endpoint depending on the Agent Management v2 flag
        yield call(
            getAgentInfo,
            {
                data: {
                    email: response.email,
                    enableAgentManagementV2,
                },
            },
        );

        // reset loginUrl so that login screen is not shown when user in authenticated
        yield put({
            type: SET_LOGIN_URL,
            data: {
                loginUrl: '',
            },
        });
    } catch (e) {
        yield put({
            type: SET_AUTH_STATUS_LOADER,
            data: {
                flag: false,
            },
        });
        yield put({
            type: SET_LOGIN_URL,
            data: {
                loginUrl: e.loginUrl,
            },
        });

        // only for development, handled from server side
        if (e.csrfToken) {
            setCSRFMetaTag(e.csrfToken);
        }

        yield put({
            type: SET_SECURITY_TOKEN,
            data: {
                cspNonce: e.cspNonce,
            }
        });

        /* DEPRECATED: Was used when auth status call was used to redirect to login page

        const curPath = history.location.pathname;

        if (curPath !== '/login') {
            yield put(push('/login'));
        }
        */
    }
}

// set access level in reducer state variable & in meta tag
function* setAccessLevel(action) {
    const { accessLevel } = action.data;

    yield put({ type: SET_ACCESS_LEVEL, data: { accessLevel }});
    // need to fetch banners for the selected access level
    yield put({ type: GET_BANNER_LIST, data: { roles: [accessLevel] }});
    // set access level in meta tag to read it while making API requests
    setAccessLevelInMetaTag(accessLevel);
};

export function* getAgentInfo(action) {
    const { email, enableAgentManagementV2 } = action.data;
    let endpoint = `${AGENT_ROOT_V2}/${email}`;

    if (enableAgentManagementV2) {
        endpoint = GET_LOGGED_IN_AGENT_ENDPOINT;
    }

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            endpoint,
        );

        yield put({ type: SET_AGENT_INFO, data: response });

        if (enableAgentManagementV2) {
            const { accessLevelList } = response.agentInfo;

            // pre-select the user role if only 1 role is available
            if (accessLevelList.length === 1) {
                const accessLevel = accessLevelList[0].value;

                yield call(setAccessLevel, { data: { accessLevel }});
            }
            // Use this if you want to skip selecting the role on the main entry screen. You can set the access level according to the use case.
            /*
            else {
                yield call(setAccessLevel, { data: { accessLevel: 'ADMIN' }});
            }
            */
        } else { // set access level with the single role from the old agent info API request
            const { accessLevel } = response.agentInfo;

            yield call(setAccessLevel, { data: { accessLevel }});
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_AGENT_INFO, data: { description: e.description } });
    }
}

function* getAuthLogout() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${AUTH_ROOT}/logout`
        );

        // redirect the client to aws logout url
        window.location.replace(response.logoutUrl);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
    }
}

function* getAccessLevelList() {
    const response = yield call(
        [clientApiWrapper, clientApiWrapper.get],
        ACCESS_LEVEL_LIST_ENDPOINT,
    );

    yield put({ type: SET_ACCESS_LEVEL_LIST, data: response });
}

function* getBannerList(action) {
    const { roles } = action.data;
    // need to fetch only active banners for the selected access level to show it in the header
    const queryString = getQueryStringFromObject({ fetchActiveBanners: true, roles });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${BANNER_LIST_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_BANNER_LIST, data: response });
    } catch (e) {
        yield put({ type: ERR_BANNER_LIST, data: { err: e.message } });
    }
}

export default function* appSaga() {
    yield all(
        [
            yield takeLatest(GET_AUTH_STATUS, getAuthStatus),
            yield takeLatest(GET_AGENT_INFO, getAgentInfo),
            yield takeLatest(GET_AUTH_LOGOUT, getAuthLogout),
            yield takeLatest(GET_META_INFO, getMetaInfo),
            yield takeLatest(GET_ACCESS_LEVEL_LIST, getAccessLevelList),
            yield takeLatest(GET_BANNER_LIST, getBannerList),
        ]
    );
}

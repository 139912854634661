/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
/**
*
* KVPairInfo - Component to show the object's key-value pair info
*
*/

import React, { memo, useContext } from 'react';
import classNames from 'classnames';

import backArrow from '../../assets/images/svgs/back-arrow.svg';
import { isNilOrEmpty } from '../../utils';

import CopyToClipboard from '../CopyToClipboard';
import { MetaInfoContext } from '../../context';

import './style.scss';

const LABEL_VALUE_DATA_TYPE = {
    string: 'DATA_TYPE_STRING',
    table: 'DATA_TYPE_TABLE',
};

const RenderValue = (props) => {
    const {
        parentProps, item, style = {}, valueKey = 'value',
    } = props;
    const { valuesHaveHtml, extClasses } = parentProps;

    const contentValueClasses = classNames('kvpair-info-cwr__cv', extClasses && extClasses.contentValue);

    if (isNilOrEmpty(item)) return null;

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment>
            {valuesHaveHtml ? (
                <div
                    className={contentValueClasses}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: item[valueKey] || '-' }}
                    style={style}
                />
            ) : (
                <div className={contentValueClasses} style={style}>
                    {!isNilOrEmpty(item[valueKey]) ? item[valueKey] : '-'}
                    {item?.allowCopyText ? <CopyToClipboard text={item[valueKey]} /> : null}
                </div>
            )}
        </React.Fragment>
    );
};

const KVPairInfo = (props) => {
    const {
        title, onBackArrowClickHandler, data, extClasses, valueKey = 'value', v2 = false,
    } = props;
    const metaInfo = useContext(MetaInfoContext);
    const { componentVersions: { banners: bannersVersion } } = metaInfo;

    const containerClasses = classNames(
        { [`kvpair-info-cr-v${bannersVersion}`]: bannersVersion === 1, fccWrapper: bannersVersion === 2 },
        extClasses && extClasses.container,
    );
    const titleContainerClasses = classNames(
        `kvpair-info-hwr-v${bannersVersion}`,
        extClasses && extClasses.titleContainer,
        'frcWrapper',
    );
    const titleHeadingClasses = classNames(`kvpair-info-hwr-v${bannersVersion}__hd`, extClasses && extClasses.titleHeading);
    const contentWrClasses = classNames('kvpair-info-cwr', extClasses && extClasses.contentWr);
    const contentLabelClasses = classNames('kvpair-info-cwr__cl', extClasses && extClasses.contentLabel);

    if (isNilOrEmpty(data)) return null;

    const getValueKey = (item) => {
        const { data_type: dataType, value } = item;
        switch (dataType) {
            case LABEL_VALUE_DATA_TYPE.string: {
                return value;
            }
            default:
                return 'value';
        }
    };

    return (
        <div className={containerClasses}>
            {
                (title || onBackArrowClickHandler) ? (
                    <div className={titleContainerClasses}>
                        {
                            onBackArrowClickHandler ? (
                                <img
                                    className='kvpair-info-backarrow'
                                    alt='back arrow'
                                    src={backArrow}
                                    onClick={onBackArrowClickHandler}
                                />
                            ) : null
                        }
                        {
                            title ? (
                                <div className={titleHeadingClasses}>{title}</div>
                            ) : null
                        }
                    </div>
                ) : null
            }
            {
                data.map((item) => (
                    <div className={contentWrClasses} key={item.label}>
                        <div style={item?.label_element?.style} className={contentLabelClasses}>{item.label}</div>
                        {Array.isArray(item[valueKey]) ? (
                            // If the value is an array, then render each value as a different div
                            <div>
                                {item[valueKey].map((valueItem, index) => (
                                    <RenderValue
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        parentProps={props}
                                        item={{ value: valueItem }}
                                        style={item?.value_element?.style}
                                    />
                                ))}
                            </div>
                        ) : (
                            <RenderValue
                                parentProps={props}
                                item={item}
                                valueKey={v2 ? getValueKey(item) : valueKey}
                                style={item?.value_element?.style}
                            />
                        )}
                    </div>
                ))
            }
        </div>
    );
};

export default memo(KVPairInfo);

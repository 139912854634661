export const CHEQUEBOOK_KEY = 'chequebookReqInfo';
export const NOTIFICATION_KEY = 'userNotifications';

// account closure info
export const GET_ACCOUNT_CLOSURE_INFO = 'app/UR/GET_ACCOUNT_CLOSURE_INFO';
export const SET_ACCOUNT_CLOSURE_INFO = 'app/UR/SET_ACCOUNT_CLOSURE_INFO';
export const ERR_ACCOUNT_CLOSURE_INFO = 'app/UR/ERR_ACCOUNT_CLOSURE_INFO';

// user chquebook details
export const GET_CHEQUEBOOK_REQUEST_DETAILS = 'app/UR/GET_CHEQUEBOOK_REQUEST_DETAILS';
export const SET_CHEQUEBOOK_REQUEST_DETAILS = 'app/UR/SET_CHEQUEBOOK_REQUEST_DETAILS';
export const ERR_CHEQUEBOOK_REQUEST_DETAILS = 'app/UR/ERR_CHEQUEBOOK_REQUEST_DETAILS';

// user notification
export const GET_AGENT_PROMPTS_ACTION = 'app/UR/GET_AGENT_PROMPTS';
export const SET_AGENT_PROMPTS_ACTION = 'app/UR/SET_AGENT_PROMPTS';
export const ERR_AGENT_PROMPTS_ACTION = 'app/UR/ERR_AGENT_PROMPTS_ACTION';

export const GET_SEND_NOTIFICATION_BY_PROMPTS = 'app/UR/GET_SEND_NOTIFICATION_BY_PROMPTS';
export const SET_SEND_NOTIFICATION_BY_PROMPTS = 'app/UR/SET_SEND_NOTIFICATION_BY_PROMPTS';
export const ERR_SEND_NOTIFICATION_BY_PROMPTS = 'app/UR/ERR_SEND_NOTIFICATION_BY_PROMPTS';

// account statement form
export const GET_ACCOUNT_STATEMENT_FORM_DETAILS = 'app/UR/GET_ACCOUNT_STATEMENT_FORM_DETAILS';
export const SET_ACCOUNT_STATEMENT_FORM_DETAILS = 'app/UR/SET_ACCOUNT_STATEMENT_FORM_DETAILS';
export const ERR_ACCOUNT_STATEMENT_FORM_DETAILS = 'app/UR/ERR_ACCOUNT_STATEMENT_FORM_DETAILS';

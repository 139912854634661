/**
 * @file Manages the utility functions for Agent Management component
 */

const getAgentInfoList = (data) => ([
    {
        label: 'Name',
        value: data.fullNameStr,
    },
    {
        label: 'Email',
        value: data.emailId,
    },
    {
        label: 'Phone Number',
        value: data.phoneNumber,
    },
    {
        label: 'Agent Type',
        value: data.agentType,
    },
    {
        label: 'Access Level',
        value: data.accessLevelStr,
    },
    {
        label: 'Status',
        value: data.agentStatus,
    },
    {
        label: 'Ozonetel ID',
        value: data.ozonetelId || '-',
    },
]);

export {
    getAgentInfoList,
};

/**
 *
 * Send Notifications
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Button, FormDropdown, Loader } from '../../../components';

import { getAgentPrompts, sendNotificationByPrompt, setSendNotificationByPrompt } from '../actions';
import { makeSelectPromptNotification } from '../selectors';

import './styles.scss';

const PromptNotification = (props) => {
    const { ticketId } = props;

    const dispatch = useDispatch();

    const sendNotificationData = useSelector(makeSelectPromptNotification, shallowEqual);
    const [selectedPrompt, setSelectedPrompt] = useState();

    let promptDetails;
    let notification;
    let promptsLoading;
    let promptsData;
    let promptsHasData;
    let promptsErr;
    let notificationLoading;
    let notificationData;
    let notificationHasData;
    let notificationErr;

    if (sendNotificationData && sendNotificationData[ticketId]) {
        ({ prompts: promptDetails, notification } = sendNotificationData[ticketId]);
    }

    if (promptDetails) {
        ({
            data: promptsData, hasData: promptsHasData, loading: promptsLoading, err: promptsErr,
        } = promptDetails);
    }

    if (notification) {
        ({
            data: notificationData, hasData: notificationHasData, loading: notificationLoading, err: notificationErr,
        } = notification);
    }

    useEffect(() => {
        if (!promptsHasData) {
            dispatch(getAgentPrompts({ ticketId }));
        }
        if (notificationHasData) {
            dispatch(setSendNotificationByPrompt({ ticketId, message: '' }));
        }
    }, [dispatch, ticketId]);

    /**
     * @function renderDD used to render a dropdown
     * @param {*} options must be an array of objects in which each object has label and value
     * @returns Dropdown to be rendered
     */
    const renderDD = (options, isDDOpenDefault, selectedValue, setSelectedValue) => (
        <FormDropdown
            options={options}
            input={{
                defaultMenuIsOpen: isDDOpenDefault,
                value: selectedValue,
                onChange: (newSelectedItem) => {
                    setSelectedValue(newSelectedItem);
                },
            }}
            cacheKey='devactions-single'
            extStyles={{
                container: 'rewards-dd__ip',
            }}
        />
    );

    const objectToLabelValue = (inputObjects) => {
        if (!inputObjects) return [];

        return Object.keys(inputObjects).map((inputObjectKey) => ({
            label: inputObjects[inputObjectKey],
            value: inputObjects[inputObjectKey],
        }));
    };

    const handleSendNotification = () => {
        dispatch(setSendNotificationByPrompt({ ticketId, message: '' }));
        dispatch(sendNotificationByPrompt({ ticketId, prompt: selectedPrompt?.value }));
        setSelectedPrompt({});
    };

    return (
        <div className='fcWrapper m-auto'>
            {
                promptsData && promptsData?.prompts && promptsData?.description ? (
                    <div>
                        <div className='prompt-label-value-cont'>
                            <div className='prompt-label'>Select type of notification </div>
                            <div className='prompt-value'>
                                {renderDD(objectToLabelValue(promptsData.prompts), false, selectedPrompt, setSelectedPrompt)}
                            </div>
                        </div>
                        <div className='prompt-label-value-cont'>
                            <div className='prompt-label'>Description</div>
                            <div className='prompt-value'>
                                {promptsData?.description[selectedPrompt?.value] || '-'}
                            </div>
                        </div>
                    </div>
                ) : (<div>No Data</div>)
            }
            <div className='m-auto'>
                <Button
                    v2
                    primary
                    label='Send Notification'
                    onClick={handleSendNotification}
                    disabled={!selectedPrompt}
                    extClasses='account-statement-ec__cta'
                />
            </div>
            <div className='prompt-info'>
                {notificationHasData ? notificationData?.message : ''}
            </div>
            {(promptsErr || notificationErr) ? <div className='err-label prompt-err-label'>{promptsErr || notificationErr}</div> : null}
            <Loader visible={promptsLoading || notificationLoading} />
        </div>
    );
};

export default memo(PromptNotification);

/*
 *
 * Liveness review constants
 *
 */

import { getInitialDateRange } from '../../utils';
import { LATEST_CASES_FIRST, OLDEST_CASES_FIRST } from '../RiskOps/constants';

export const LIVENESS_REVIEW_KEY = 'livenessReview';

// action constants
export const GET_LIVENESS_CASES = 'app/LivenessReview/GET_LIVENESS_CASES';
export const SET_LIVENESS_CASES = 'app/LivenessReview/SET_LIVENESS_CASES';
export const ERR_LIVENESS_CASES = 'app/LivenessReview/ERR_LIVENESS_CASES';
export const DEQUEUE_LIVENESS_CASE = 'app/LivenessReview/DEQUEUE_LIVENESS_CASE';
export const SET_LIVENESS_VIEW = 'app/LivenessReview/SET_LIVENESS_VIEW';
export const GET_LIVENESS_REVIEW_VIDEO = 'app/LivenessReview/GET_LIVENESS_REVIEW_VIDEO';
export const SET_LIVENESS_REVIEW_VIDEO = 'app/LivenessReview/SET_LIVENESS_REVIEW_VIDEO';
export const SET_LIVENESS_PAYLOAD_TYPE = 'app/LivenessReview/SET_LIVENESS_PAYLOAD_TYPE';
export const SET_CUR_LIVENESS_CASE = 'app/LivenessReview/SET_CUR_LIVENESS_CASE';
export const GET_LIVENESS_PENDING_REVIEW_COUNT = 'app/LivenessReview/GET_LIVENESS_PENDING_REVIEW_COUNT';
export const SET_LIVENESS_PENDING_REVIEW_COUNT = 'app/LivenessReview/SET_LIVENESS_PENDING_REVIEW_COUNT';
export const AUDIT_LOG_MEDIA_DOWNLOAD = 'app/LivenessReview/AUDIT_LOG_MEDIA_DOWNLOAD';
export const SET_LIVENESS_QUEUE_FILTERS = 'app/LivenessReview/SET_LIVENESS_QUEUE_FILTERS';
export const SET_LIVENESS_L2_ANNOTATION_DETAILS = 'app/LivenessReview/SET_LIVENESS_L2_ANNOTATION_DETAILS';

// state constants
export const VIEW_ALL_LIVENESS_CASES = 'app/LivenessReview/State/VIEW_ALL_LIVENESS_CASES';
export const VIEW_SINGLE_LIVENESS_CASE = 'app/LivenessReview/State/VIEW_SINGLE_LIVENESS_CASE';
export const DEV_ACTION_TYPES = ['MARK_LIVENESS_PASSED', 'RETRY_LIVENESS'];

// value constants
export const PAYLOAD_TYPE_LIVENESS = 'PAYLOAD_TYPE_LIVENESS';
export const PAYLOAD_TYPE_LIVENESS_SAMPLE = 'PAYLOAD_TYPE_LIVENESS_SAMPLE';
export const PAYLOAD_TYPE_AFU_LIVENESS = 'PAYLOAD_TYPE_AFU_LIVENESS';
export const PAYLOAD_TYPE_L2_LIVENESS = 'PAYLOAD_TYPE_QA_LIVENESS';

export const INITIAL_DATE_RANGE = getInitialDateRange();

const FROM_DATE_INFO = {
    label: 'From Date',
    labelId: 'liveness-review-from-date',
};

const TO_DATE_INFO = {
    label: 'To Date',
    labelId: 'liveness-review-to-date',
};

const DATE_PICKER_CLS = {
    container: ['lreview-fc'],
    inputField: ['lreview-fc__ip'],
};

export const DATE_INFO = {
    fromDateInfo: FROM_DATE_INFO,
    toDateInfo: TO_DATE_INFO,
    initialDateRange: INITIAL_DATE_RANGE,
    extClasses: DATE_PICKER_CLS,
};

export const QUEUE_PAYLOAD_TYPES = [PAYLOAD_TYPE_LIVENESS, PAYLOAD_TYPE_LIVENESS_SAMPLE, PAYLOAD_TYPE_AFU_LIVENESS, PAYLOAD_TYPE_L2_LIVENESS];
export const SORTING_OPTIONS = [OLDEST_CASES_FIRST, LATEST_CASES_FIRST];

export const SORT_DD_OG_STATE = {
    value: LATEST_CASES_FIRST,
    isVisible: false,
};

export const PAYLOAD_DD_OG_STATE = {
    value: PAYLOAD_TYPE_LIVENESS,
    isVisible: false,
};

// class constants
export const DD_WRAPPER_CLS = {
    container: 'custom-dd lreview-idd',
};

export const DD_CONTENT_CLS = {
    container: ['lreview-idd__cc'],
};

export const DD_ITEM_CLS = 'custom-dd__cl lreview-idd__cl';

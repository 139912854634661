/**
 * @file Manages the utility functions for Account Ops component
 */

import { getSideNavbarItems } from '../../utils';

import {
    ACCOUNT_OPS_PATH, BALANCE_TRANSFER_UTRS_PATH, BULK_ACCOUNT_VERIFICATION_PATH, UPDATE_BALANCES_PATH,
} from '../App/routes';
import SubmitAccountInfo from '../SubmitAccountInfo';

const NAV_ITEMS = {
    ROOT_PATH: ACCOUNT_OPS_PATH,
    DEFAULT_REDIRECT_PATH_INDEX: 0, // 0 means first element of ITEMS array
    ITEMS: [
        {
            label: 'Bulk Account Verification',
            component: SubmitAccountInfo,
            route: BULK_ACCOUNT_VERIFICATION_PATH,
        },
        {
            label: 'Update Balances',
            component: SubmitAccountInfo,
            route: UPDATE_BALANCES_PATH,
        },
        {
            label: 'Successful Balance Transfer to User',
            component: SubmitAccountInfo,
            route: BALANCE_TRANSFER_UTRS_PATH,
        },
    ],
};

const SIDE_NAVBAR_INFO = getSideNavbarItems(NAV_ITEMS);

export {
    getSideNavbarItems,
    SIDE_NAVBAR_INFO,
};

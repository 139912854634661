/*
 *
 * @file Facematch review reducer
 *
 */

import { fromJS } from 'immutable';

import {
    ERR_USS_FM,
    GET_USS_FM_REVIEW_ITEMS, GET_USS_FM_REVIEW_ITEM_DETAILS,
    SET_USS_FM_FACEMATCH_PAYLOAD_TYPE, SET_USS_FM_FACEMATCH_VIEW, SET_USS_FM_PAYLOAD_TYPE_LIST, SET_USS_FM_REVIEW_ACTION,
    SET_USS_FM_REVIEW_ACTION_TYPE, SET_USS_FM_REVIEW_ITEMS, SET_USS_FM_REVIEW_ITEM_DETAILS, SET_USS_FM_REVIEW_REASON,
    VIEW_ALL_FACEMATCH_CASES, SET_USS_FM_REVIEW_REASON_TYPE,
} from './constants';

const initialState = fromJS({
    loading: false,
    err: '',
    payloadTypes: [],
    reviewActions: [],
    reviewReasons: [],
    reviewItemList: {},
    facematchView: VIEW_ALL_FACEMATCH_CASES,
    facematchPayloadType: {},
    reviewActionType: {},
    reviewReasonType: {},
});

// eslint-disable-next-line default-param-last
function ussFacematchReviewReducer(state = initialState, action) {
    switch (action.type) {
        case SET_USS_FM_PAYLOAD_TYPE_LIST: {
            const { list } = action.data;

            return state.set('payloadTypes', fromJS(list));
        }

        case SET_USS_FM_FACEMATCH_PAYLOAD_TYPE: {
            const facematchPayloadType = action.data;

            return state.set('facematchPayloadType', fromJS(facematchPayloadType));
        }

        case SET_USS_FM_REVIEW_ACTION: {
            const { list } = action.data;

            return state.set('reviewActions', fromJS(list));
        }

        case SET_USS_FM_REVIEW_ACTION_TYPE: {
            const actionType = action.data;

            return state.set('reviewActionType', fromJS(actionType));
        }

        case SET_USS_FM_REVIEW_REASON: {
            const { list } = action.data;

            return state.set('reviewReasons', fromJS(list));
        }

        case SET_USS_FM_REVIEW_REASON_TYPE: {
            const reasonType = action.data;

            return state.set('reviewReasonType', fromJS(reasonType));
        }

        case GET_USS_FM_REVIEW_ITEMS: {
            return state
                .set('loading', true)
                .set('err', '');
        }

        case SET_USS_FM_REVIEW_ITEMS: {
            const { reviewElementsList } = action.data;

            return state
                .set('loading', false)
                .set('reviewItemList', fromJS(reviewElementsList));
        }

        case GET_USS_FM_REVIEW_ITEM_DETAILS: {
            return state
                .set('loading', true)
                .set('err', '');
        }

        case SET_USS_FM_REVIEW_ITEM_DETAILS: {
            const { reviewItemDetails } = action.data;

            return state
                .set('loading', false)
                .set('reviewItemDetails', reviewItemDetails);
        }

        case SET_USS_FM_FACEMATCH_VIEW: {
            return state.set('facematchView', fromJS(action.data));
        }

        case ERR_USS_FM: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err);
        }

        default:
            return state;
    }
}

export default ussFacematchReviewReducer;

/**
 *  @file AgentFeedbackForm - Component to render the Give Feedback Button and then consequently open a modal to fill feedback
 */

import React, { memo, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Button, Modal, FormDropdown, RadioButtonList, FormWithSingleInput,
} from '../../../components';
import { UserDetailsContext } from '../../../context';

import { setAgentFeedback } from '../actions';
import { FILTER_TYPES, URGENCY_TYPES, HIGHLIGHTED_TYPES, FEEDBACK_CATEGORIES } from '../constants';

import './style.scss';

const customModalStyles = {
    content: {
        padding: '40px 80px',
        width: 1100,
    },
};

const AgentFeedbackForm = (props) => {
    const { ticketId } = props;

    const dispatch = useDispatch();

    const { basicInfo } = useContext(UserDetailsContext);
    const { emailId } = basicInfo;

    const [showFeedbackFormModal, setFeedbackFormModal] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [feedbackCategory, setFeedbackCategory] = useState({});
    const [userTicket, setUserTicket] = useState('N/A');
    const [manualTicketId, setManualTicketId] = useState(null);
    const [filterType, setFilterType] = useState('');
    const [urgencyType, setUrgencyType] = useState('');
    const [highlightedBeforeOption, setHighlightedBeforeOption] = useState('');
    const [errLabel, setErrLabel] = useState('');
    // Commented for future usage of anonymous feedback
    // const [isAnonymous, setIsAnonymous] = useState('');

    const USER_TICKET_TYPES = [
        {
            id: 'N/A',
            value: 'N/A',
            label: 'N/A',
        },
        {
            id: 'Current Ticket Id',
            value: ticketId,
            label: 'Current Ticket Id',
        },
        {
            id: 'Enter Manually',
            value: manualTicketId,
            label: 'Enter manually',
        },
    ];

    const handleSubmit = () => {
        if (!feedbackCategory || !feedback || (!userTicket && !manualTicketId)) {
            setErrLabel('Please fill the mandatory fields');

            return;
        }

        dispatch(setAgentFeedback({
            agentEmail: emailId,
            sherlockFeedbackDetails: {
                feedback_category: feedbackCategory.value,
                feedback_message: feedback,
                feedback_meta_data: {
                    frequency: filterType,
                    is_highlighted_before: highlightedBeforeOption,
                    is_urgent: urgencyType,
                },
                agent_email: emailId,
                feedback_identifier_type: 'TICKET_ID',
                feedback_identifier_value: manualTicketId ?? ticketId,
            },
            // Commented for future usage of anonymous feedback
            // is_anonymous: isAnonymous,
        }));

        setFeedbackFormModal(false);
        setFeedback('');
        setFeedbackCategory('');
    };

    const handleFeedbackBtnClick = () => {
        setFeedbackFormModal(true);
    };

    const handleCancelClick = () => {
        setFeedbackFormModal(false);
    };

    const handleFeedbackChange = (value) => {
        setFeedbackCategory(value);
    };

    const handleFeedbackEntry = (e) => {
        setFeedback(e.target.value);
    };

    const handleManualTicketIdEntry = (e) => {
        setManualTicketId(e.target.value);
    };

    return (
        <React.Fragment>
            <Button
                v2
                label='Give Feedback'
                primary
                extClasses='af-btn'
                onClick={handleFeedbackBtnClick}
            />

            <Modal
                visible={showFeedbackFormModal}
                modalStyles={customModalStyles}
                contentLabel='User Feedback Modal'
            >
                <div className='fcWrapper'>
                    <div className='accesslevel-dd-modal__lb1'>
                        Give Feedback to the User
                    </div>

                    <FormDropdown
                        label='Choose Feedback Category*'
                        options={FEEDBACK_CATEGORIES}
                        input={{
                            value: feedbackCategory,
                            onChange: handleFeedbackChange,
                            placeholder: 'Choose...',
                            isClearable: true,
                        }}
                        cacheKey='feedback-category'
                        extStyles={{
                            container: 'frcsbWrapper my-30',
                            inputField: 'af-input',
                        }}
                    />

                    <div className='frcsbWrapper mb-30'>
                        <label
                            htmlFor='feedback'
                        >
                            Enter feedback*
                        </label>
                        <textarea
                            id='feedback'
                            className='af-input'
                            value={feedback}
                            onChange={handleFeedbackEntry}
                            rows={4}
                            cols={50}
                        />
                    </div>

                    <div className='frcsbWrapper mb-30'>
                        <label htmlFor='user-ticket-type-list'>Ticket ID*</label>
                        <div className='fcWrapper'>
                            <RadioButtonList
                                dataList={USER_TICKET_TYPES}
                                listName='user-ticket-type-list'
                                setSelectedData={setUserTicket}
                                extClasses={{
                                    container: 'of-auto af-rbcont',
                                    itemContainer: 'mr-60',
                                }}
                            />

                            {userTicket === '' ? (
                                <FormWithSingleInput
                                    label='Enter Ticket ID'
                                    labelId='ticketId'
                                    formType='text'
                                    input={{
                                        value: manualTicketId,
                                        onChange: handleManualTicketIdEntry,
                                    }}
                                    hideErrLabel
                                    extClasses={{
                                        container: 'af-mtcont',
                                    }}
                                />
                            ) : null}
                        </div>
                    </div>

                    {feedbackCategory && (feedbackCategory.value === 'FEEDBACK_CATEGORY_FEATURE_REQUEST'
                        || feedbackCategory.value === 'FEEDBACK_CATEGORY_REPORT_PRODUCT_ISSUE'
                        || feedbackCategory.value === 'FEEDBACK_CATEGORY_REPORT_SHERLOCK_ISSUE')
                        ? (
                            <React.Fragment>
                                <div className='frcsbWrapper mb-30'>
                                    <div htmlFor='filter-type-list'>Frequency</div>
                                    <RadioButtonList
                                        dataList={FILTER_TYPES}
                                        listName='filter-type-list'
                                        setSelectedData={setFilterType}
                                        extClasses={{
                                            container: 'of-auto af-rbcont',
                                            itemContainer: 'mr-60',
                                        }}
                                    />
                                </div>

                                <div className='frcsbWrapper mb-30'>
                                    <div htmlFor='urgent-type-list'>Urgency</div>
                                    <RadioButtonList
                                        dataList={URGENCY_TYPES}
                                        listName='urgent-type-list'
                                        setSelectedData={setUrgencyType}
                                        extClasses={{
                                            container: 'of-auto af-rbcont',
                                            itemContainer: 'mr-60',
                                        }}
                                    />
                                </div>

                                <div className='frcsbWrapper mb-30'>
                                    <div htmlFor='highlighted-before-options'>Highlighted before?</div>
                                    <RadioButtonList
                                        dataList={HIGHLIGHTED_TYPES}
                                        listName='highlighted-before-options'
                                        setSelectedData={setHighlightedBeforeOption}
                                        extClasses={{
                                            container: 'of-auto af-rbcont',
                                            itemContainer: 'mr-60',
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        ) : null }

                    {/* Commented for future usage of anonymous feedback */}
                    {/* <div className='frcsbWrapper mb-30'>
                        <div htmlFor='is-user-anonymous'>Mark as Anonymous?</div>
                        <RadioButtonList
                            dataList={MARK_ANONYMOUS}
                            listName='is-user-anonymous'
                            setSelectedData={setIsAnonymous}
                            extClasses={{
                                container: 'of-auto af-rbcont',
                                itemContainer: 'mr-60',
                            }}
                        />
                    </div> */}

                    <div className='err-label'>{errLabel}</div>

                    <div className='frccWrapper'>
                        <Button
                            v2
                            primary
                            label='Submit'
                            extClasses='accesslevel-dd-modal__cta af-btn'
                            onClick={handleSubmit}
                        />
                        <Button
                            v2
                            secondary
                            label='Cancel'
                            extClasses='accesslevel-dd-modal__cta af-btn'
                            onClick={handleCancelClick}
                        />
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default memo(AgentFeedbackForm);

/**
 * @file Manages the reducer state & function for Issue config component
 */

import { fromJS } from 'immutable';

import {
    CLEAR_FILE_DOWNLOAD_DATA,
    ERR_FILTER_OPTIONS, ERR_ISSUE_CONFIG, ERR_ISSUE_CONFIG_HISTORY, ERR_UPDATE_ISSUE_CONFIG, GET_FILTER_OPTIONS,
    GET_ISSUE_CONFIG, GET_ISSUE_CONFIG_HISTORY, ISSUE_CONFIG_FILTER_KEY, ISSUE_CONFIG_HISTORY_KEY, ISSUE_CONFIG_KEY, SET_FILTER_OPTIONS,
    SET_ISSUE_CONFIG, SET_ISSUE_CONFIG_HISTORY, SET_UPDATE_ISSUE_CONFIG, UPDATE_ISSUE_CONFIG, UPDATE_ISSUE_CONFIG_KEY,
} from './constants';

const initialState = fromJS({
    filterOptions: {},
    issueConfig: {},
    issueConfigHistory: {},
    updateIssueConfig: {},
});

// eslint-disable-next-line default-param-last
function issueConfigReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ISSUE_CONFIG: {
            const key = [ISSUE_CONFIG_KEY];
            const values = {
                loading: true,
                data: {},
                hasData: false,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case SET_ISSUE_CONFIG: {
            let hasData = true;
            if (!action.data) hasData = false;
            const key = [ISSUE_CONFIG_KEY];
            const values = {
                loading: false,
                data: fromJS(action.data),
                hasData,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case ERR_ISSUE_CONFIG: {
            const { err } = action.data;
            const key = [ISSUE_CONFIG_KEY];
            const values = {
                loading: false,
                data: {},
                hasData: false,
                err,
            };
            return state.mergeIn(key, values);
        }

        case GET_FILTER_OPTIONS: {
            const key = [ISSUE_CONFIG_FILTER_KEY];
            const values = {
                loading: true,
                data: {},
                hasData: false,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case SET_FILTER_OPTIONS: {
            let hasData = true;
            if (!action.data) hasData = false;
            const key = [ISSUE_CONFIG_FILTER_KEY];
            const values = {
                loading: false,
                data: fromJS(action.data),
                hasData,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case ERR_FILTER_OPTIONS: {
            const { err } = action.data;
            const key = [ISSUE_CONFIG_FILTER_KEY];
            const values = {
                loading: false,
                data: {},
                hasData: false,
                err,
            };
            return state.mergeIn(key, values);
        }

        case GET_ISSUE_CONFIG_HISTORY: {
            const key = [ISSUE_CONFIG_HISTORY_KEY];
            const values = {
                loading: true,
                data: {},
                hasData: false,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case SET_ISSUE_CONFIG_HISTORY: {
            let hasData = true;
            if (!action.data) hasData = false;
            const key = [ISSUE_CONFIG_HISTORY_KEY];
            const values = {
                loading: false,
                data: fromJS(action.data),
                hasData,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case ERR_ISSUE_CONFIG_HISTORY: {
            const { err } = action.data;
            const key = [ISSUE_CONFIG_HISTORY_KEY];
            const values = {
                loading: false,
                data: {},
                hasData: false,
                err,
            };
            return state.mergeIn(key, values);
        }

        case UPDATE_ISSUE_CONFIG: {
            const key = [UPDATE_ISSUE_CONFIG_KEY];
            const values = {
                loading: true,
                data: {},
                hasData: false,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case SET_UPDATE_ISSUE_CONFIG: {
            let hasData = true;
            if (!action.data) hasData = false;
            const key = [UPDATE_ISSUE_CONFIG_KEY];
            const values = {
                loading: false,
                data: fromJS(action.data),
                hasData,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case ERR_UPDATE_ISSUE_CONFIG: {
            const { err } = action.data;
            const key = [UPDATE_ISSUE_CONFIG_KEY];
            const values = {
                loading: false,
                data: {},
                hasData: false,
                err,
            };
            return state.mergeIn(key, values);
        }

        case CLEAR_FILE_DOWNLOAD_DATA: {
            const key = [ISSUE_CONFIG_KEY];
            const values = {
                loading: false,
                data: {
                    ...state.data,
                    issueConfigFile: '',
                },
                hasData: true,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        default:
            return state;
    }
}

export default issueConfigReducer;

/**
 * @file Manages the sagas for Search component
 */

import {
    all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import {
    checkObjectsEquality,
    clientApiWrapper, getErrLabel, getErrMsg, getQueryStringFromObject, toastify,
} from '../../utils';
import {
    GET_SEARCH_SOP_ENDPOINT, GET_SEARCH_SOP_META_ENDPOINT,
} from '../../api/routes';
import {
    GET_SEARCH_SOP, GET_SEARCH_SOP_META, TIME_IN_MILLISECONDS,
} from './constants';
import {
    errSearchSopAction, errSearchSopMetaAction, setSearchSopAction, setSearchSopMetaAction,
} from './actions';

function* getSearchSopSaga(action) {
    const {
        query, l1, l2, l3, paginationParams, resultType, screenKey,
    } = action.data;
    const queryString = getQueryStringFromObject({
        query, l1, l2, l3, paginationParams, resultType,
    });
    try {
        const originalArgs = yield select((state) => state.getIn(['search', ...screenKey, 'originalArgs']));
        const { timestamp, params = {} } = originalArgs || {};
        if (!(checkObjectsEquality(params, {
            query, l1, l2, l3, resultType, ...paginationParams,
        }) && (Date.now() - timestamp) < TIME_IN_MILLISECONDS * 300)) { // Caching Timeout = 5 min
            const response = yield call(
                [clientApiWrapper, clientApiWrapper.get],
                `${GET_SEARCH_SOP_ENDPOINT}?${queryString}`,
            );
            yield put(setSearchSopAction({
                screenKey,
                value: {
                    originalArgs: {
                        timestamp: Date.now(),
                        params: {
                            query, l1, l2, l3, resultType, ...paginationParams,
                        },
                    },
                    ...response,
                },
            }));
        } else {
            yield put(setSearchSopAction({ screenKey }));
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errSearchSopAction({ screenKey, err: getErrMsg(e) }));
    }
}

function* getSearchSopMetaSaga(action) {
    const {
        query, l1, l2, l3, resultType,
    } = action.data;
    const queryString = getQueryStringFromObject({
        query, l1, l2, l3, resultType,
    });
    const key = ['meta'];
    try {
        const originalArgs = yield select((state) => state.getIn(['search', ...key, 'originalArgs']));
        const { timestamp, params = {} } = originalArgs || {};
        if (!(checkObjectsEquality(params, {
            query, l1, l2, l3, resultType,
        }) && (Date.now() - timestamp) < TIME_IN_MILLISECONDS * 300)) { // Caching Timeout = 5 min
            const response = yield call(
                [clientApiWrapper, clientApiWrapper.get],
                `${GET_SEARCH_SOP_META_ENDPOINT}?${queryString}`,
            );
            yield put(setSearchSopMetaAction({
                screenKey: key,
                value: {
                    originalArgs: {
                        timestamp: Date.now(),
                        params: {
                            query, l1, l2, l3, resultType,
                        },
                    },
                    ...response,
                },
            }));
        } else {
            yield put(setSearchSopMetaAction({ screenKey: key }));
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errSearchSopMetaAction({ screenKey: key, err: getErrMsg(e) }));
    }
}
export default function* SearchSaga() {
    yield all(
        [
            yield takeLatest(GET_SEARCH_SOP, getSearchSopSaga),
            yield takeLatest(GET_SEARCH_SOP_META, getSearchSopMetaSaga),
        ],
    );
}

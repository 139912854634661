/**
 * @file Manages the selector functions for Transaction view container
 */

import { createSelector } from 'reselect';

import { TRANSACTION_VIEW_KEY } from './constants';

export const selectTransactionViewDetails = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_KEY, 'fileEntries']),
    (substate) => substate?.toJS(),
);

export const selectTransactionViewLoader = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_KEY, 'loading']),
    (substate) => substate,
);

export const selectTransactionViewParameterList = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_KEY, 'parameterList']),
    (substate) => substate?.toJS(),
);

export const selectTransactionViewError = createSelector(
    (state) => state.getIn([TRANSACTION_VIEW_KEY, 'err']),
    (subState) => subState,
);

/**
 *
 * Approver
 *
 */

import React, { memo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { FormWithSingleInput, Loader } from '../../../components';
import { validators } from '../../../utils';

import UserInfo from '../UserInfo';
import { getFreelancerInfo } from '../actions';
import {
    makeSelectLoading, makeSelectUserInfo,
} from '../selectors';

import './style.scss';

const Approver = () => {
    const dispatch = useDispatch();
    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const userInfo = useSelector(makeSelectUserInfo(), shallowEqual);
    const [ticketId, setTicketId] = useState('');
    const [errLabel, setErrLabel] = useState('');

    const hasUserInfo = !!Object.keys(userInfo).length;

    const handleInputChange = (key) => (e) => {
        const value = e.target.value;

        switch (key) {
            case 'ticketId':
                setTicketId(value);
                break;

            default:
        }
    };

    const handleTicketIdSubmit = (e) => {
        e.preventDefault();

        if (!validators.isInputtedNumberValid(ticketId)) {
            setErrLabel('Please enter a valid ticket id');
            return;
        }

        setErrLabel('');
        dispatch(getFreelancerInfo({ ticketId }));
    };

    return (
        <React.Fragment>
            {
                ticketId && hasUserInfo ? (
                    <UserInfo
                        ticketId={ticketId}
                        setTicketId={setTicketId}
                    />
                ) : (
                    <div className='wlappr-cr'>
                        <FormWithSingleInput
                            label='Ticket ID'
                            labelId='ticket-id'
                            formType='text'
                            input={{
                                value: ticketId,
                                onChange: handleInputChange('ticketId')
                            }}
                            cta={{
                                primaryLabel: 'Submit',
                                onPrimaryClick: handleTicketIdSubmit
                            }}
                            errLabel={errLabel}
                        />
                    </div>
                )
            }
            <Loader visible={loading && loading.userInfo} />
        </React.Fragment>
    );

};

export default memo(Approver);

/**
*
* InputText
*
*/

import React from 'react';
import PropTypes from 'prop-types'
// import './style.scss';

class InputText extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { label, placeHolder, disabled, input: { name, value, onChange } } = this.props;

    return (
      <div className="form-group">
        <label>{label}</label>
        <input id={name} type="text" className="form-control" placeholder={placeHolder} value={value} onChange={(e) => onChange(e.target.value)} disabled={disabled} />
      </div>
    );
  }
}

InputText.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  placeHolder: PropTypes.string,
};

export default InputText;

/*
 *
 * @file Declared constants for Facematch review items
 *
 */

// key to be used for reducers & selectors for uss facematch review
export const USS_FACEMATCH_REVIEW = 'ussFacematchReview';

export const SET_USS_FM_FACEMATCH_VIEW = `app/${USS_FACEMATCH_REVIEW}/SET_USS_FM_FACEMATCH_VIEW`;

export const VIEW_ALL_FACEMATCH_CASES = `app/${USS_FACEMATCH_REVIEW}/State/VIEW_ALL_FACEMATCH_CASES`;
export const VIEW_SINGLE_FACEMATCH_CASE = `app/${USS_FACEMATCH_REVIEW}/State/VIEW_SINGLE_FACEMATCH_CASE`;

// Error for US stocks
export const ERR_USS_FM = `app/${USS_FACEMATCH_REVIEW}/State/ERR_USS_FM`;

// payload type
export const GET_USS_FM_PAYLOAD_TYPE_LIST = `app/${USS_FACEMATCH_REVIEW}/GET_USS_FM_PAYLOAD_TYPE_LIST`;
export const SET_USS_FM_PAYLOAD_TYPE_LIST = `app/${USS_FACEMATCH_REVIEW}/SET_USS_FM_PAYLOAD_TYPE_LIST`;

// review reason
export const GET_USS_FM_REVIEW_REASON = `app/${USS_FACEMATCH_REVIEW}/GET_USS_FM_REVIEW_REASON`;
export const SET_USS_FM_REVIEW_REASON = `app/${USS_FACEMATCH_REVIEW}/SET_USS_FM_REVIEW_REASON`;

// review action
export const GET_USS_FM_REVIEW_ACTION = `app/${USS_FACEMATCH_REVIEW}/GET_USS_FM_REVIEW_ACTION`;
export const SET_USS_FM_REVIEW_ACTION = `app/${USS_FACEMATCH_REVIEW}/SET_USS_FM_REVIEW_ACTION`;

// get review items
export const GET_USS_FM_REVIEW_ITEMS = `app/${USS_FACEMATCH_REVIEW}/GET_USS_FM_REVIEW_ITEMS`;
export const SET_USS_FM_REVIEW_ITEMS = `app/${USS_FACEMATCH_REVIEW}/SET_USS_FM_REVIEW_ITEMS`;

// get review item details
export const GET_USS_FM_REVIEW_ITEM_DETAILS = `app/${USS_FACEMATCH_REVIEW}/GET_USS_FM_REVIEW_ITEM_DETAILS`;
export const SET_USS_FM_REVIEW_ITEM_DETAILS = `app/${USS_FACEMATCH_REVIEW}/SET_USS_FM_REVIEW_ITEM_DETAILS`;

// set review as done
export const GET_USS_FM_REVIEW_AS_DONE = `app/${USS_FACEMATCH_REVIEW}/GET_USS_FM_REVIEW_AS_DONE`;
export const SET_USS_FM_REVIEW_AS_DONE = `app/${USS_FACEMATCH_REVIEW}/SET_USS_FM_REVIEW_AS_DONE`;

export const SET_USS_FM_FACEMATCH_PAYLOAD_TYPE = `app/${USS_FACEMATCH_REVIEW}/SET_USS_FM_FACEMATCH_PAYLOAD_TYPE`;
export const SET_USS_FM_REVIEW_ACTION_TYPE = `app/${USS_FACEMATCH_REVIEW}/SET_USS_FM_REVIEW_ACTION_TYPE`;
export const SET_USS_FM_REVIEW_REASON_TYPE = `app/${USS_FACEMATCH_REVIEW}/SET_USS_FM_REVIEW_REASON_TYPE`;

export const SUCCESS_MESSAGE = 'Action has been performed successfully';

export const REVIEW_LABEL_DATA = [
    {
        label: 'Sr. No',
        key: 'srNo',
    },
    {
        label: 'Review ID',
        key: 'id',
    },
    {
        label: 'Actor ID',
        key: 'actorId',
    },
    {
        label: 'Created At',
        key: 'createAtTs',
    },
];

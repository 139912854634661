/**
 * This function helps to get the proper query string from the object
 * that we can use to send in query params in GET call
 * @param {object} data data object consists of key-value pairs
 * @param {boolean} mask whether the values should be masked by converting to base64 strings
 * @returns {string} query string joined with &
 */
export const getQueryStringFromObject = (data, mask = false) => (
    Object.keys(data)
        .filter((key) => data[key])
        .map((key) => {
            let value = data[key];

            if (typeof value === 'object') {
                value = JSON.stringify(value);
            }

            if (mask) value = btoa(value);

            value = encodeURIComponent(value);

            return `${key}=${value}`;
        }).join('&')
);

export const getRedirectionUrlFromGuideType = (type, meta) => {
    switch (type) {
        case 'script': return `/v2/${type}s/${meta}`;
        case 'sop': return `/v2/${type}s/${meta}`;
        default: return '';
    }
};

export const getParsedStringFromSnakeCase = (str, clipIndex = 0, textStyle = 'lowerCase') => {
    if (!str || str.includes('UNSPECIFIED')) { // case of default enum value
        return '-';
    }

    let modifiedStr = str;

    // e.g. - LIVENESS_STATUS_EXPIRED --> EXPIRED
    if (clipIndex) {
        modifiedStr = modifiedStr.slice(clipIndex);
    }

    const strSplitArr = modifiedStr.split('_');
    // e.g. - LIVENESS_STATUS_EXPIRED --> LIVENESS STATUS EXPIRED
    modifiedStr = strSplitArr.join(' ');

    // e.g. - LIVENESS_STATUS_EXPIRED --> Liveness Status Expired
    if (textStyle === 'capitalize') {
        modifiedStr = strSplitArr.map((word) => word.charAt(0) + word.slice(1).toLowerCase()).join(' ');
    } else if (textStyle === 'lowerCase') {
        modifiedStr = modifiedStr.toLowerCase();
    }

    return modifiedStr;
};

/**
 * This function helps to get the concatenated key from the value and subValue
 * @param {string} value value to be concatenated
 * @param {string} subValue subValue to be concatenated
 * @returns {string} concatenated key
 */

export const getKeyFromValueAndSubValue = (value, subValue) => {
    if (typeof value !== 'string' || (subValue && typeof subValue !== 'string')) return '';

    if (subValue) {
        return `${subValue}${value}`;
    }
    return value;
};

/**
 * This function helps to get the masked phone number
 * @param {string} phoneNumber phone number to be masked
 * @returns {string} masked phone number
 */
export const maskPhoneNumber = (phoneNumber) => phoneNumber && `${phoneNumber.slice(0, 6)}****`;

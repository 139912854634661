import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, toastify } from '../../utils';
import { TICKETS_ROOT } from '../../api/routes';

import {
    GET_CALL_RECORDING_URL, SET_CALL_RECORDING_URL, ERR_CALL_RECORDING_URL,
    GET_CALL_TRANSCRIPT, SET_CALL_TRANSCRIPT, ERR_CALL_TRANSCRIPT,
} from './constants';

function* getCallRecordingURL(action) {
    const { recordingId, ticketId } = action.data;
    const queryString = `recordingId=${btoa(recordingId)}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKETS_ROOT}/${ticketId}/call-recording?${queryString}`
        );

        yield put({ type: SET_CALL_RECORDING_URL, data: { ticketId, ...response } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CALL_RECORDING_URL, data: { ticketId, err: e.message } });
    }
}

function* getCallTranscript(action) {
    const { recordingId, ticketId } = action.data;
    const queryString = `recordingId=${btoa(recordingId)}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKETS_ROOT}/${ticketId}/call-transcript?${queryString}`
        );

        yield put({ type: SET_CALL_TRANSCRIPT, data: { ticketId, ...response } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CALL_TRANSCRIPT, data: { ticketId, err: e.message } });
    }
}

export default function* callDetailsSaga() {
    yield all(
        [
            yield takeLatest(GET_CALL_RECORDING_URL, getCallRecordingURL),
            yield takeLatest(GET_CALL_TRANSCRIPT, getCallTranscript),
        ]
    );
}

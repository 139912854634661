import {
    DEVELOPMENT, DEVELOPMENT_DOMAIN, PRODUCTION, PRODUCTION_DOMAIN, QA, QA_DOMAIN, STAGING, STAGING_DOMAIN, UAT, UAT_DOMAIN,
} from './constants';

export const getEnvironmentFromURL = () => {
    const url = new URL(window.location.href);

    switch (url.hostname) {
        case DEVELOPMENT_DOMAIN: return DEVELOPMENT;
        case STAGING_DOMAIN: return STAGING;
        case UAT_DOMAIN: return UAT;
        case QA_DOMAIN: return QA;
        case PRODUCTION_DOMAIN: return PRODUCTION;
        default: return 'INVALID';
    }
};

export const isFeatureAccessibleInEnvironments = (environments) => {
    const currentEnv = getEnvironmentFromURL();

    if (environments && Array.isArray(environments) && (environments.indexOf(currentEnv) !== -1)) {
        return true;
    }
    return false;
};

export const getStrapiLoginURL = () => {
    const env = getEnvironmentFromURL();
    switch (env) {
        case DEVELOPMENT: return 'http://localhost:1337/strapi-plugin-sso/cognito';
        case STAGING:
        case QA:
        case UAT: return `https://strapi.${env}.pointz.in/strapi-plugin-sso/cognito`;
        case PRODUCTION: return 'https://strapi.epifi.in/strapi-plugin-sso/cognito';
        default: return '';
    }
};

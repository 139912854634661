/* eslint-disable react-hooks/exhaustive-deps */

/*
* @file Transaction Case Details
* Page to view the details of the Transaction case details
*/

import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import {
    Loader, ExpandableCard, KVPairInfo,
    Table, CommentListView,
} from '../../components';
import { injectReducer, injectSaga, isNilOrEmpty } from '../../utils';

import { TRANSACTION_CASE_DETAILS_KEY } from './constants';
import {
    selectTransactionCaseDetails,
    selectTransactionCaseError,
    selectTransactionCaseLoader,
    selectTransactionCaseAnnotation,
    selectTransactionCaseComments,
} from './selectors';
import {
    getTransactionCaseDetailsAction,
    getTransactionCaseAnnotation,
    getTransactionCaseComments,
} from './actions';
import reducer from './reducer';
import saga from './saga';

import './styles.scss';

const renderCommentBody = ({ comment, commentType }) => (
    <React.Fragment>
        <span><b> Comment Type: </b>{commentType}</span>
        <pre className='tc-details-pre'>{comment}</pre>
    </React.Fragment>
);

const TransactionCaseDetails = (props) => {
    const { caseId } = props;

    const dispatch = useDispatch();

    const transactionCaseDetailsLoadder = useSelector(selectTransactionCaseLoader, shallowEqual);
    const transactionCaseDetailsError = useSelector(selectTransactionCaseError, shallowEqual);
    const transactionCaseDetails = useSelector(selectTransactionCaseDetails, shallowEqual);
    const transactionCaseAnnotation = useSelector(selectTransactionCaseAnnotation, shallowEqual);
    const transactionCaseComments = useSelector(selectTransactionCaseComments, shallowEqual);

    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(true);

    const {
        caseDetails, tableSections = [],
    } = transactionCaseDetails;

    const actorId = useMemo(() => transactionCaseDetails?.caseDetails?.find((item) => item?.actor_id)?.value, [transactionCaseDetails]);

    const makePayloadAndCallApi = () => {
        const payload = {
            caseId,
        };
        dispatch(getTransactionCaseDetailsAction(payload));
    };

    const makePayloadAndCallAnnotationAndComments = () => {
        const commentsAndAnnotationPayload = {
            actorId,
        };
        dispatch(getTransactionCaseAnnotation(commentsAndAnnotationPayload));
        dispatch(getTransactionCaseComments(commentsAndAnnotationPayload));
    };

    useEffect(() => {
        if (actorId) {
            makePayloadAndCallAnnotationAndComments();
        }
    }, [actorId]);

    useEffect(() => {
        if (caseId) {
            makePayloadAndCallApi();
        }
    }, [caseId]);

    return (
        <React.Fragment>
            <div className='tc-details app-cc-v1'>
                <ExpandableCard
                    version={2}
                    heading='Case Details'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                    extClasses='mb-30 tc-details-expandablecard'
                    extHeadingClasses='heading3'
                    extChildElementsClass='p-0-20-20'
                >
                    {!isNilOrEmpty(caseDetails) && (
                        <KVPairInfo
                            data={caseDetails}
                            extClasses={{
                                container: 'tc-details-kv-cr m-0',
                                contentLabel: 'tc-details-kv-cl',
                                contentValue: 'tc-details-kv-cv',
                                contentWr: 'tc-details-kv-cw',
                            }}
                        />
                    )}

                </ExpandableCard>

                {tableSections?.map((table) => (
                    <div className='my-30' key={table.title}>
                        <div className='heading3'>{table.title}</div>
                        <Table
                            v2
                            enableScrollView
                            labelData={table?.columnData}
                            contentData={table?.rowData || []}
                            errLabel={transactionCaseDetailsError}
                        />

                    </div>
                ))}

                {transactionCaseAnnotation?.title ? (
                    <div className='my-30'>
                        <div className='heading3'>{transactionCaseAnnotation.title}</div>
                        <Table
                            v2
                            enableScrollView
                            labelData={transactionCaseAnnotation?.columnData}
                            contentData={transactionCaseAnnotation?.rowData || []}
                        />
                    </div>
                ) : ''}

                {transactionCaseComments?.title ? (
                    <div className='my-30'>
                        <div className='heading3'>{transactionCaseComments.title}</div>
                        <div className='mt-20'>
                            <CommentListView
                                comments={transactionCaseComments?.comments || []}
                                caseId={caseId}
                                renderCommentBody={renderCommentBody}
                            />
                        </div>

                    </div>
                ) : ''}
            </div>
            <Loader visible={transactionCaseDetailsLoadder} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: TRANSACTION_CASE_DETAILS_KEY, reducer });
const withSaga = injectSaga({ key: TRANSACTION_CASE_DETAILS_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(TransactionCaseDetails);

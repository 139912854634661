/**
 * @file Manages the utils for different date functions
 */

/**
 * Function returns object with date's upper limit, lower limit, from & to date
 * for date picker to get initialise with these values
 *
 * @param {string} lowerLimitStr string to make lower limit for from/to date e.g. '02-01-2021' ->  01 Feb 2021
 * @param {boolean} initialiseToDateValue boolean to initialise the toDate value with the current date
 * @return {object} object with date's upper limit, lower limit, from & to date
 */
export const getInitialDateRange = (lowerLimitStr, initialiseToDateValue, defaultValues = {}) => {
    const upperLimitFromDate = new Date();
    upperLimitFromDate.setHours(0, 0, 0, 0);

    const upperLimitToDate = new Date();
    upperLimitToDate.setHours(23, 59, 59, 999);

    const lowerLimitFromDate = new Date(lowerLimitStr || '01-01-2020');
    lowerLimitFromDate.setHours(0, 0, 0, 0);

    const lowerLimitToDate = new Date(lowerLimitStr || '01-01-2020');
    lowerLimitToDate.setHours(23, 59, 59, 999);

    const { defaultFromDate, defaultToDate } = defaultValues;

    let fromDateValue;

    // 1st preference
    if (defaultFromDate) fromDateValue = defaultFromDate;

    // fallback
    if (!fromDateValue) fromDateValue = '';

    let toDateValue;

    // 1st preference
    if (defaultToDate) toDateValue = defaultToDate;

    // 2nd preference
    if (!toDateValue && initialiseToDateValue) toDateValue = upperLimitToDate;

    // fallback
    if (!toDateValue) toDateValue = '';

    return {
        lowerLimitDate: lowerLimitFromDate,
        upperLimitDate: upperLimitToDate,
        fromDate: {
            lowerLimit: lowerLimitFromDate,
            upperLimit: upperLimitFromDate,
            value: fromDateValue,
        },
        toDate: {
            lowerLimit: lowerLimitToDate,
            upperLimit: upperLimitToDate,
            value: toDateValue,
        },
    };
};

/**
 * Function returns modified values for from & to date
 * whenever user select any date in from/to date range
 *
 * @param {object} { toDate, fromDate, prevState } object with from date, to date & prev state values
 * @return {object} object with modified values for from & to date
 */
const getModifiedDateRange = ({ toDate, fromDate, prevState }) => {
    let modifiedToDate;
    let modifiedFromDate;

    const { fromDate: fromDatePrevState, toDate: toDatePrevState } = prevState;

    if (toDate) {
        const newToDate = new Date(toDate);
        newToDate.setHours(23, 59, 59, 999);

        modifiedToDate = {
            ...toDatePrevState,
            value: newToDate,
        };

        const upperLimitFromDate = new Date(toDate);
        upperLimitFromDate.setHours(0, 0, 0, 0);

        modifiedFromDate = {
            ...fromDatePrevState,
            upperLimit: upperLimitFromDate,
        };
    } else if (fromDate) {
        modifiedFromDate = {
            ...fromDatePrevState,
            value: fromDate,
        };

        const lowerLimitToDate = new Date(fromDate);
        lowerLimitToDate.setHours(23, 59, 59, 999);

        modifiedToDate = {
            ...toDatePrevState,
            lowerLimit: lowerLimitToDate,
        };
    }

    return {
        fromDate: modifiedFromDate,
        toDate: modifiedToDate,
    };
};

/**
 * Function returns modified values for from & to date
 * whenever user select any date in from/to date range
 *
 * @param {object} data object with key, value, from date, & to date values
 * @return {object} object with modified values for from & to date
 */
const getModifiedDate = (data) => {
    const {
        key, value, fromDate, toDate,
    } = data;
    const modifiedData = {
        prevState: {
            fromDate,
            toDate,
        },
    };

    switch (key) {
        case 'fromDate':
            modifiedData.fromDate = value;
            break;

        case 'toDate':
            modifiedData.toDate = value;
            break;

        default:
    }

    return getModifiedDateRange(modifiedData);
};

export default {
    getInitialDateRange,
    getModifiedDateRange,
    getModifiedDate,
};

/**
 * @file ExecutedMandateDetails
 * Shows the Executed mandates and their details for salary account
 */

import React, { memo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ReactDatePicker from 'react-datepicker';

import {
    Button, ExpandableCard, FormWithSingleInput, Loader, Table,
} from '../../../components';

import { selectSalaryAccountExecutedMandates } from '../selectors';
import { getSalaryAccountExecutedMandatesAction, setSalaryAccountExecutedMandatesAction } from '../actions';

import './style.scss';

const ExecutedMandateDetails = (props) => {
    const { screenKey, parentProps } = props;
    const { ticketId, userId, index } = parentProps;

    const dispatch = useDispatch();

    const salaryAccountExecutedMandates = useSelector(selectSalaryAccountExecutedMandates(screenKey), shallowEqual);

    let salaryAccountExecutedMandatesData;
    let loadingSalaryAccountExecutedMandates;
    let hasData;
    let executedMandateDetailsTable;
    let nextToken = null;
    let prevToken = null;

    if (salaryAccountExecutedMandates) {
        ({
            loading: loadingSalaryAccountExecutedMandates, data: salaryAccountExecutedMandatesData, hasData,
        } = salaryAccountExecutedMandates);
        if (hasData) ({ mandateExecutionsTable: executedMandateDetailsTable, prev: prevToken, next: nextToken } = salaryAccountExecutedMandatesData);
    }

    const [isCardExpanded, setIsCardExpanded] = useState(true);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [mandateId, setMandateId] = useState('');
    const [errLabel, setErrLabel] = useState('');
    const [searched, setSearched] = useState(false);

    /**
     * @function onDateChange
     * @param {*} key it can be toDate or fromDate
     * @returns function which takes e as date object input and sets the date in corresponding state.
     */
    const onDateChange = (key) => (e) => {
        switch (key) {
            case 'toDate': setToDate(new Date(e));
                break;
            case 'fromDate': setFromDate(new Date(e));
                break;
            default: return null;
        }
        return null;
    };

    const onMandateIdChange = (newMandateId) => {
        setMandateId(newMandateId.target.value);
    };

    const handleSubmit = () => {
        if (!mandateId) {
            setErrLabel('Please fill the mandate id field');
            return;
        }

        dispatch(getSalaryAccountExecutedMandatesAction({
            screenKey,
            payload: {
                ticketId, userId, index, fromDate, toDate, mandateId,
            },
        }));
        setErrLabel('');
        setSearched(true);
    };

    const clearAllSelection = () => {
        setToDate();
        setFromDate();
        setMandateId('');
        setSearched(false);
        dispatch(setSalaryAccountExecutedMandatesAction({
            screenKey,
            payload: {
                ticketId, userId, index, data: [],
            },
        }));
    };

    const paginatedClickHandler = (tokens) => {
        const { prev, next } = tokens;
        dispatch(getSalaryAccountExecutedMandatesAction({
            screenKey,
            payload: {
                ticketId, userId, index, prevToken: prev, nextToken: next, fromDate, toDate, mandateId,
            },
        }));
    };

    const renderExecutedMandatesTable = () => (
        hasData ? (
            <Table
                objKey='header_key'
                labelData={executedMandateDetailsTable?.columnData}
                contentData={executedMandateDetailsTable?.rowData}
                header={executedMandateDetailsTable?.tableName}
                prevToken={prevToken}
                nextToken={nextToken}
                paginatedClickHandler={paginatedClickHandler}
                extClasses={{
                    container: 'mt-30',
                }}
            />
        ) : <div className='frccWrapper mt-30'>No data available for given parameters!!!</div>
    );

    return (
        <div className='salops-cr__v2'>
            <ExpandableCard
                extClasses='exe-manda-expandable'
                heading='List of Executed Mandate Details'
                isExpanded={isCardExpanded}
                setIsExpanded={() => setIsCardExpanded(!isCardExpanded)}
            >
                <div className='exe-manda-expandable-sc'>
                    <div className='frcseWrapper form-container exe-manda-expandable-form-container'>
                        <div className='form-wrapper mr-30'>
                            <div className='form-wrapper-label exe-manda-expandable-sc__lb'>From Date</div>
                            <ReactDatePicker
                                dateFormat='dd-MM-yyyy'
                                className='exe-manda-expandable-sc__ip'
                                selected={fromDate}
                                onChange={onDateChange('fromDate')}
                            />
                        </div>
                        <div className='form-wrapper'>
                            <div className='form-wrapper-label exe-manda-expandable-sc__lb'>To Date</div>
                            <ReactDatePicker
                                dateFormat='dd-MM-yyyy'
                                className='exe-manda-expandable-sc__ip'
                                selected={toDate}
                                onChange={onDateChange('toDate')}
                                minDate={fromDate}
                            />
                        </div>
                    </div>
                    <div className='mb-15 mt-15'>
                        <FormWithSingleInput
                            hideErrLabel
                            disableFormSubmitOnEnter
                            extClasses={{
                                container: 'm-0',
                                label: 'exe-manda-expandable-sc__lb',
                                inputField: 'exe-manda-expandable-sc__ip',
                            }}
                            label='Mandate ID*'
                            formType='text'
                            input={{
                                value: mandateId,
                                onChange: onMandateIdChange,
                            }}
                        />
                    </div>

                    {errLabel ? <div className='err-label exe-manda-el'>{errLabel}</div> : null}
                    <div className='frcWrapper exe-manda-expandable-sc__3r'>
                        <Button
                            extClasses='exe-manda-expandable-sc__cta'
                            primary
                            label='Search'
                            onClick={handleSubmit}
                        />
                        <div
                            className='link exe-manda-expandable-sc__clear'
                            onClick={clearAllSelection}
                            role='presentation'
                        >
                            Clear All Selection
                        </div>
                    </div>
                </div>
            </ExpandableCard>
            { searched && renderExecutedMandatesTable()}
            <Loader visible={loadingSalaryAccountExecutedMandates} />
        </div>
    );
};

export default memo(ExecutedMandateDetails);

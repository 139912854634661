/*
 *
 * AgentCreateForm reducer
 *
 */

import { fromJS, Map } from 'immutable';
import {
    FORM_INITIAL_VALUES, SET_ACCESS_LEVEL,
} from './constants';

const initialState = fromJS({
    formInitialValues: {},
    accessLevelList: [],
});

const getInitialValues = data => ({
    ...data,
    agent_status: {
        label: data.agent_status,
        value: data.agent_status
    },
    access_level: {
        label: data.access_level,
        value: data.access_level
    },
    agent_type: {
        label: data.agent_type,
        value: data.agent_type
    },
    ticket_scope: {
        label: data.ticket_scope,
        value: data.ticket_scope
    },
    freshdesk_agent_type: {
        label: data.freshdesk_agent_type,
        value: data.freshdesk_agent_type
    }
});

function agentCreateFormReducer(state = initialState, action) {
    switch (action.type) {
        case FORM_INITIAL_VALUES: {
            if (Object.keys(action.data).length === 0) {
                return state.set('formInitialValues', Map({}));
            }

            const initialValues = getInitialValues(action.data);

            return state.set('formInitialValues', Map(initialValues));
        }

        case SET_ACCESS_LEVEL: {
            const { accessLevelList } = action.data;

            return state.set('accessLevelList', fromJS(accessLevelList));
        }

        default:
            return state;
    }
}

export default agentCreateFormReducer;

/**
 *
 * AppLogs
 *
 */

import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import { MonorailIdInput, HorizontalIdNavbar } from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import { APP_LOGS_PATH, VIEW_APP_LOG_PATH } from '../App/routes';

import AppLogsList from './AppLogsList';
import AppLogInfo from './AppLogInfo';

import { setMonorailId } from './actions';
import { makeSelectMonorailInfo } from './selectors';
import reducer from './reducer';
import saga from './saga';

const AppLogs = () => {
    const dispatch = useDispatch();
    const monorailInfo = useSelector(makeSelectMonorailInfo(), shallowEqual);

    const { id: monorailId } = monorailInfo;

    // set new ticket to true on initial render or when no monorail id present
    const [newTicket, setNewTicket] = useState(!monorailId);
    // set navbar items to empty array on initial render or when no monorail id present
    const [navItems, setNavItems] = useState(monorailId && [{ id: monorailId }] || []);

    const resetMonorailId = () => {
        setNewTicket(true);
        setNavItems([]);
        dispatch(setMonorailId({ value: '' }));
    };

    const handleMonorailIdSubmit = (value) => {
        setNewTicket(false);
        setNavItems([{ id: value }]);
        dispatch(setMonorailId({ value }));
    };

    const renderIdNavbar = (
        <HorizontalIdNavbar
            isFixed
            hasSingleItem
            isNewTicketActive={newTicket}
            activeId={monorailId}
            navItems={navItems}
            onItemCloseIconClick={resetMonorailId}
        />
    );

    if (!monorailId) {
        return (
            <React.Fragment>
                {renderIdNavbar}
                <MonorailIdInput submitMonorailId={handleMonorailIdSubmit} />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {renderIdNavbar}
            <Switch>
                <Route
                    path={VIEW_APP_LOG_PATH}
                    render={props => (<AppLogInfo {...props} monorailId={monorailId} />)}
                />
                <Route
                    path={APP_LOGS_PATH}
                    render={props => (<AppLogsList {...props} monorailId={monorailId} />)}
                />
            </Switch>
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'appLogs', reducer });
const withSaga = injectSaga({ key: 'appLogs', saga });

export default compose(
    withReducer,
    withSaga,
)(AppLogs);

/**
 * @file Manages the reducer state & function for Banners component
 */

import { fromJS } from 'immutable';

import {
    SET_BANNER_FORM_FIELDS, RESET_BANNER_FORM_FIELDS, CREATE_BANNER, UPDATE_BANNER, SET_CREATE_OR_UPDATE_BANNER_LOADER,
    SET_BANNER_INFO, GET_BANNER_LIST, SET_BANNER_LIST, ERR_BANNER_LIST, BANNER_FORM_FIELDS,
} from './constants';

const initialState = fromJS({
    bannerVisibilityStateList: [],
    bannerList: {
        loading: false,
        err: '',
        data: [],
    },
    bannerInfo: {},
    bannerFormFields: {
        [BANNER_FORM_FIELDS.TITLE]: '',
        [BANNER_FORM_FIELDS.BODY]: '',
        [BANNER_FORM_FIELDS.START_TIME]: '',
        [BANNER_FORM_FIELDS.END_TIME]: '',
        [BANNER_FORM_FIELDS.VISIBILITY_STATE]: '',
        [BANNER_FORM_FIELDS.ROLES]: [],
    },
    createOrUpdateBanner: {
        loading: false,
    },
});

function bannersReducer(state = initialState, action) {
    switch (action.type) {
        case SET_BANNER_FORM_FIELDS: {
            const { key, value } = action.data;

            // need to set banner form fields with the whole action data
            if (!key) {
                return state.set('bannerFormFields', fromJS(action.data));
            }
            
            const bannerFormFields = state.get('bannerFormFields').toJS();
            const modifiedBannerFormFields = { ...bannerFormFields };

            modifiedBannerFormFields[key] = value;

            return state.set('bannerFormFields', fromJS(modifiedBannerFormFields));
        }

        case RESET_BANNER_FORM_FIELDS: {
            const { bannerFormFields } = initialState.toJS();

            return state.set('bannerFormFields', fromJS(bannerFormFields));
        }

        case CREATE_BANNER:
        case UPDATE_BANNER:
            return state.setIn(['createOrUpdateBanner', 'loading'], true);

        case SET_CREATE_OR_UPDATE_BANNER_LOADER: {
            const { loading } = action.data;

            return state.setIn(['createOrUpdateBanner', 'loading'], loading);
        }

        case SET_BANNER_INFO: {
            return state.set('bannerInfo', fromJS(action.data));
        }

        case GET_BANNER_LIST:
            return state
                .setIn(['bannerList', 'loading'], true)
                .setIn(['bannerList', 'err'], '');

        case SET_BANNER_LIST: {
            const { bannerList: { data, prev, next } } = action.data;

            return state
                .setIn(['bannerList', 'loading'], false)
                .setIn(['bannerList', 'data'], fromJS(data))
                .setIn(['bannerList', 'prev'], fromJS(prev))
                .setIn(['bannerList', 'next'], fromJS(next));
        }

        case ERR_BANNER_LIST: {
            const { err } = action.data;

            return state
                .setIn(['bannerList', 'loading'], false)
                .setIn(['bannerList', 'err'], err)
                .setIn(['bannerList', 'data'], fromJS([]))
                .setIn(['bannerList', 'prev'], fromJS({}))
                .setIn(['bannerList', 'next'], fromJS({}));;
        }

        default:
            return state;
    }
}

export default bannersReducer;

/**
 *
 * UserRules
 *
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Loader, Pagination } from '../../../components';

import UserRuleInfoModal from '../Modals/UserRuleInfo';
import { getUserRules, getUserRuleInfo } from '../actions';
import { makeSelectUserRules, makeSelectUserRuleInfo } from '../selectors';

import '../style.scss';

const UserRules = (props) => {
    const dispatch = useDispatch();

    const userRuleList = useSelector(makeSelectUserRules(), shallowEqual);
    const userRuleInfo = useSelector(makeSelectUserRuleInfo(), shallowEqual);

    const [selectedUserRule, setSelectedUserRule] = useState({});
    const [showUserRuleInfoModal, setUserRuleInfoModal] = useState(false);

    const { ticketId, userId, index } = props;

    let loadingUserRules;
    let userRulesData;
    let userRulesPrev;
    let userRulesNext;
    let loadingUserRuleInfo;
    let userRuleInfoData;
    let userRuleInfoPrev;
    let userRuleInfoNext;
    let hasUserRuleInfoData;

    if (userRuleList[ticketId]) {
        ({
            loading: loadingUserRules, data: userRulesData, prev: userRulesPrev,
            next: userRulesNext,
        } = userRuleList[ticketId]);
    }

    if (userRuleInfo[ticketId]) {
        ({
            loading: loadingUserRuleInfo, data: userRuleInfoData, hasData: hasUserRuleInfoData,
            prev: userRuleInfoPrev, next: userRuleInfoNext,
        } = userRuleInfo[ticketId]);
    }

    useEffect(() => {
        dispatch(getUserRules({ ticketId, userId, index }));
    }, []);

    const getPaginatedUserRules = ({ prev, next }) => {
        dispatch(getUserRules({ ticketId, userId, index, prev, next }));
    };

    const getRuleInfo = (item) => {
        setSelectedUserRule(item);
        setUserRuleInfoModal(true);

        dispatch(getUserRuleInfo({
            ticketId,
            userId,
            index,
            subscriptionId: item.id,
        }));
    };

    const getPaginatedRuleInfo = ({ prev, next }) => {
        dispatch(getUserRuleInfo({
            ticketId,
            userId,
            index,
            subscriptionId: selectedUserRule.id,
            prev,
            next,
        }));
    };

    const renderUserRuleList = () => {
        if (!userRulesData) {
            return <div className='fittt-src' />;
        }

        if (userRulesData.length === 0) {
            return (
                <div className='fittt-src'>
                    <div className='table-nsr table-nsr--mt0'>No subscribed rules found!</div>
                </div>
            );
        }

        return (
            <div className='fittt-src'>
                <div className='table-sr table-sr--mt0'>
                    <div className='table-sr__label'>Subscribed Rules</div>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th'>Rule Title</div>
                        <div className='table-sr__th f25Wrapper'>Rule Description</div>
                        <div className='table-sr__th'>Rule Type</div>
                        <div className='table-sr__th'>Status</div>
                        <div className='table-sr__th'>History</div>
                    </div>
                    <div className='table-sr__tlc'>
                        {
                            userRulesData.map(item => (
                                <div className='table-sr__tlw' key={item.id}>
                                    <div className='table-sr__tl'>{item.ruleInfo.title}</div>
                                    <div className='table-sr__tl f25Wrapper'>{item.descriptionStr}</div>
                                    <div className='table-sr__tl table-sr__tl--cc'>{item.ruleInfo.category}</div>
                                    <div className='table-sr__tl'>{item.status}</div>
                                    <div
                                        className='table-sr__tl link'
                                        onClick={() => {
                                            getRuleInfo(item);
                                        }}
                                    >
                                        View Details
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <Pagination prev={userRulesPrev} next={userRulesNext} onClickHandler={getPaginatedUserRules} />
                </div>
            </div>
        );
    };

    return (
        <div className='fittt-cr'>
            {renderUserRuleList()}
            <UserRuleInfoModal
                visible={showUserRuleInfoModal && hasUserRuleInfoData}
                data={userRuleInfoData}
                selectedUserRule={selectedUserRule}
                pagination={{
                    prev: userRuleInfoPrev,
                    next: userRuleInfoNext,
                    onClickHandler: getPaginatedRuleInfo,
                }}
                toggleHandler={() => {
                    setSelectedUserRule({});
                    setUserRuleInfoModal(false);
                }}
            />
            <Loader visible={loadingUserRules || loadingUserRuleInfo} />
        </div>
    );
};

export default UserRules;

/**
 * @file UserProducts actions
 */

import {
    GET_USER_RISK_PRODUCT_INFO_DATA, GET_USER_RISK_PRODUCT_LIST_DATA, RESET_USER_RISK_PRODUCT_INFO_DATA,
} from './constants';

export const getUserProductInfoAction = (data) => ({
    type: GET_USER_RISK_PRODUCT_INFO_DATA,
    data,
});

export const getUserProductListAction = (data) => ({
    type: GET_USER_RISK_PRODUCT_LIST_DATA,
    data,
});

export const resetUserProductInfoData = () => ({
    type: RESET_USER_RISK_PRODUCT_INFO_DATA,
});

/**
 * @file FileInput
 * Whitelisting users for B2B Onboarding using phone number or email id in a CSV file
 */

import React, { memo, useEffect, useState } from 'react';

import CustomCSVReader from '../../../components/CSVReader';
import { getImageInBufferFormat } from '../../../utils/fileUploadEvents';
import B2B_SAMPLE_EMAIL_CSV from '../../../assets/csvs/SampleB2BEmail.csv';
import B2B_SAMPLE_PHONE_CSV from '../../../assets/csvs/SampleB2BPhone.csv';

const FileInput = (props) => {
    const {
        csv, setCSV, setErr, setValidInput, extClasses = {},
    } = props;

    // local state variables
    const [file, setFile] = useState(null);

    useEffect(() => {
        if (csv) {
            setValidInput(true);
        } else {
            setValidInput(false);
        }
    }, [csv]);

    useEffect(() => {
        if (!file) {
            return;
        }
        getImageInBufferFormat(file)
            .then(({ contentBase64 }) => {
                setCSV(contentBase64);
            })
            .catch((err) => {
                setErr(err);
            });
    }, [file]);

    return (
        <div className={extClasses.container}>
            <div className='frcsbWrapper'>
                <div className={extClasses.csv}>
                    <CustomCSVReader
                        isV2Button
                        setFileData={setFile}
                        extClasses={{
                            aside: [extClasses.aside],
                            remove: [extClasses.remove],
                        }}
                    />
                </div>
                <a
                    className={extClasses.sample}
                    href={B2B_SAMPLE_EMAIL_CSV}
                    download='sample-email.csv'
                >
                    Sample Email Format
                </a>
                <a
                    className={extClasses.sample}
                    href={B2B_SAMPLE_PHONE_CSV}
                    download='sample-phone-number.csv'
                >
                    Sample Phone Format
                </a>
            </div>
        </div>
    );
};
export default memo(FileInput);

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 *
 * PayoutRequests
 *
 */

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';
import Modal from 'react-modal';
import classNames from 'classnames';

import {
    Button, Loader, Pagination,
} from '../../components';
import { injectReducer, injectSaga, validators } from '../../utils';
import { UserDetailsContext } from '../../context';

import {
    getRaisedPayoutRequests, getAllPayoutRequests, updatePayoutRequest,
} from './actions';
import {
    makeSelectLoading, makeSelectRaisedPayoutRequests,
    makeSelectPendingPayoutRequests, makeSelectAllPayoutRequests,
    makeSelectPrev, makeSelectNext,
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 600,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const PayoutRequests = () => {
    const dispatch = useDispatch();
    const userInfo = useContext(UserDetailsContext);
    const {
        basicInfo: {
            accessLevelInfo: {
                isSuperAdmin,
            },
        },
    } = userInfo;

    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const [updateRequestData, setUpdateRequestData] = useState({});
    const [reason, setReason] = useState('');
    const [errLabel, setErrLabel] = useState('');

    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const raisedPayoutRequests = useSelector(makeSelectRaisedPayoutRequests(), shallowEqual);
    const pendingPayoutRequests = useSelector(makeSelectPendingPayoutRequests(), shallowEqual);
    const allPayoutRequests = useSelector(makeSelectAllPayoutRequests(), shallowEqual);
    const prevObj = useSelector(makeSelectPrev(), shallowEqual);
    const nextObj = useSelector(makeSelectNext(), shallowEqual);

    useEffect(() => {
        if (isSuperAdmin) {
            dispatch(getAllPayoutRequests({ state: 'PENDING' }));
            dispatch(getAllPayoutRequests({}));
        } else {
            dispatch(getRaisedPayoutRequests({}));
        }
    }, []);

    const onReasonChange = (e) => {
        setReason(e.target.value);
    };

    const initiateConfirmationModal = (data) => () => {
        setUpdateRequestData(data);
        setConfirmationModal(true);
    };

    const confirmAction = ({ isConfirm }) => () => {
        if (isConfirm) {
            if (!reason || !validators.isInputtedStringValidv2(reason)) {
                setErrLabel('Please enter a valid comment');
                return;
            }

            const callUpdateRequestAction = () => new Promise((resolve, reject) => {
                try {
                    dispatch(updatePayoutRequest({ ...updateRequestData, reason, resolve }));
                } catch (e) {
                    reject(e);
                }
            });

            callUpdateRequestAction().then(() => {
                dispatch(getAllPayoutRequests({ state: 'PENDING' }));
                dispatch(getAllPayoutRequests({}));
            });
        }

        setConfirmationModal(false);
    };

    const getPaginatedRaisedPayoutRequests = ({ prev, next }) => {
        dispatch(getRaisedPayoutRequests({ prev, next }));
    };

    const getPaginatedPendingPayoutRequests = ({ prev, next }) => {
        dispatch(getAllPayoutRequests({ prev, next, state: 'PENDING' }));
    };

    const getPaginatedAllPayoutRequests = ({ prev, next }) => {
        dispatch(getAllPayoutRequests({ prev, next }));
    };

    const renderSearchResults = (data) => {
        const {
            requests, isRaisedPR, isPendingPR, isAllPR,
        } = data;
        let onClickHandler;
        let label;
        let prev;
        let next;

        if (isRaisedPR) {
            label = 'Raised Payout Requests';
            prev = prevObj.raisedPayoutRequests;
            next = nextObj.raisedPayoutRequests;
            onClickHandler = getPaginatedRaisedPayoutRequests;
        } else if (isPendingPR) {
            label = 'Pending Payout Requests';
            prev = prevObj.pendingPayoutRequests;
            next = nextObj.pendingPayoutRequests;
            onClickHandler = getPaginatedPendingPayoutRequests;
        } else if (isAllPR) {
            label = 'All Payout Requests';
            prev = prevObj.allPayoutRequests;
            next = nextObj.allPayoutRequests;
            onClickHandler = getPaginatedAllPayoutRequests;
        }

        if (requests.length === 0) {
            // eslint-disable-next-line no-shadow
            let errLabel = 'No raised payout requests found!';

            if (isSuperAdmin) {
                if (isPendingPR) {
                    errLabel = 'No approval pending payouts found!';
                } else {
                    errLabel = '';
                }
            }

            return <div className='table-nsr'>{errLabel}</div>;
        }

        return (
            <React.Fragment>
                <div className='table-sr table-sr--mt20'>
                    <div className='table-sr__label'>{label}</div>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th'>Amount</div>
                        <div className='table-sr__th f05Wrapper'>Payout Type</div>
                        <div className='table-sr__th'>Reason</div>
                        <div className='table-sr__th'>Ticket ID</div>
                        <div className='table-sr__th'>Request{'\n'}Date</div>
                        <div className='table-sr__th'>
                            {isRaisedPR ? `Request${'\n'}Status` : `Requested${'\n'}By`}
                        </div>
                        {
                            !isPendingPR ? (
                                <React.Fragment>
                                    <div className='table-sr__th'>Payout{'\n'}Date</div>
                                    <div className='table-sr__th'>Payout ID</div>
                                    {
                                        isAllPR ? (
                                            <div className='table-sr__th'>Payout{'\n'}Status</div>
                                        ) : null
                                    }
                                    <div className='table-sr__th'>Action</div>
                                    <div className='table-sr__th'>Actioned{'\n'}By</div>
                                    <div className='table-sr__th'>Remarks</div>
                                </React.Fragment>
                            ) : (
                                <div className='table-sr__th f15Wrapper'>Actions</div>
                            )
                        }
                    </div>
                    <div className='table-sr__tlc'>
                        {
                            requests.map((item) => (
                                <div className='table-sr__tlw table-sr__tlw--aic' key={item.id}>
                                    <div className='table-sr__tl'>{item.payout_value}</div>
                                    <div className='table-sr__tl f05Wrapper'>{item.payout_type}</div>
                                    <div className='table-sr__tl'>{item.reason}</div>
                                    <div className='table-sr__tl'>{item.ticketId}</div>
                                    <div className='table-sr__tl table-sr__tl--nc'>{item.requestDate}</div>
                                    <div
                                        className={classNames({
                                            'table-sr__tl': true,
                                            'table-sr__tl--lc': !isRaisedPR,
                                        })}
                                    >
                                        {isRaisedPR ? item.payoutStatus : item.requester}
                                    </div>
                                    {
                                        !isPendingPR ? (
                                            <React.Fragment>
                                                <div className='table-sr__tl table-sr__tl--nc'>{item.payoutDate}</div>
                                                <div className='table-sr__tl'>{item.id}</div>
                                                {
                                                    isAllPR ? (
                                                        <div className='table-sr__tl table-sr__tl--cc'>{item.payoutStatus}</div>
                                                    ) : null
                                                }
                                                <div className='table-sr__tl table-sr__tl--cc'>{item.approvalStatus}</div>
                                                <div className='table-sr__tl'>{item.approver}</div>
                                                <div className='table-sr__tl'>{item.approverComment}</div>
                                            </React.Fragment>
                                        ) : (
                                            <div className='table-sr__tl frccWrapper f15Wrapper'>
                                                <div
                                                    className='link payouts-mr40'
                                                    onClick={initiateConfirmationModal({ requestId: item.id, state: 'APPROVED' })}
                                                >
                                                    Approve
                                                </div>
                                                <div
                                                    className='link'
                                                    onClick={initiateConfirmationModal({ requestId: item.id, state: 'REJECTED' })}
                                                >
                                                    Reject
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Pagination prev={prev} next={next} onClickHandler={onClickHandler} />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className='payouts-container'>
                <div className='payouts-src'>
                    {
                        isSuperAdmin ? (
                            <React.Fragment>
                                {renderSearchResults({ requests: pendingPayoutRequests, isPendingPR: true })}
                                {renderSearchResults({ requests: allPayoutRequests, isAllPR: true })}
                            </React.Fragment>
                        ) : (
                            renderSearchResults({ requests: raisedPayoutRequests, isRaisedPR: true })
                        )
                    }
                </div>
            </div>
            <Modal
                isOpen={showConfirmationModal}
                style={customStylesForModal}
                contentLabel='Confirmation Modal'
            >
                <div className='payouts-modal__hl'>
                    Are you sure you want to perform this action?
                </div>
                <div className='form-container payouts-modal-ff__cr'>
                    <label
                        className='form-wrapper-label payouts-modal-ff__lb'
                        htmlFor='pay-reason'
                    >
                        Enter Comment*
                    </label>
                    <textarea
                        id='payout-reason'
                        className='payouts-modal-ff__ta'
                        value={reason}
                        onChange={onReasonChange}
                        rows={4}
                        cols={70}
                    />
                </div>
                <div className='err-label'>{errLabel}</div>
                <div className='frcsbWrapper payouts-modal__ctas'>
                    <Button
                        primary
                        label='Confirm'
                        onClick={confirmAction({ isConfirm: true })}
                    />
                    <Button
                        secondary
                        label='Cancel'
                        onClick={confirmAction({ isCancel: true })}
                    />
                </div>
            </Modal>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'payoutRequests', reducer });
const withSaga = injectSaga({ key: 'payoutRequests', saga });

export default compose(
    withReducer,
    withSaga,
)(PayoutRequests);

/**
 * This file contains reusable generic helper functions
 */
import { useEffect, useState } from 'react';
import { either, isEmpty, isNil } from 'ramda';
import { SCREEN } from '../containers/Tickets/constants';

/**
 * Check if an object is empty or not
 * Using for-in loop is the fastest
 * Object.keys(obj).length === 0 is much slower
 * Reference for speed analysis - https://stackoverflow.com/a/59787784
 *
 * @param {object} obj The object to test if it is empty or not
 * @returns {boolean} True if object is empty, else false
 */
export const isObjEmpty = (obj) => {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax,no-unreachable-loop
    for (const key in obj) return false;
    return true;
};

/**
 * Returns `true` if the given value is its type's empty value, `null` or `undefined`.
 *
 * @param {*} val The value to test
 * @return {boolean}
 * @example
 *
 * RA.isNilOrEmpty([1, 2, 3]); //=> false
 * RA.isNilOrEmpty([]); //=> true
 * RA.isNilOrEmpty(''); //=> true
 * RA.isNilOrEmpty(null); //=> true
 * RA.isNilOrEmpty(undefined): //=> true
 * RA.isNilOrEmpty({}); //=> true
 * RA.isNilOrEmpty({length: 0}); //=> false
 */
export const isNilOrEmpty = either(isNil, isEmpty);

export const getCurrentAuthorizedLevel = (activeTicket) => {
    let level = 5;
    if (activeTicket.isAuthorizedForSensitiveScreen) {
        level = 1;
    } else if (activeTicket.isAuthorizedForModeratelySensitiveScreen) {
        level = 2;
    } else if (activeTicket.isAuthorizedForLessSensitiveScreen) {
        level = 3;
    } else if (activeTicket.isAuthorizedForInsensitiveScreen) {
        level = 4;
    }
    return level;
};

// Auth Sensitivity to Access Level mapping.
const INFORMATION_LEVEL_TO_ACCESS_LEVEL = {
    [SCREEN.HIGHLY_SENSITIVE_SCREEN]: 1,
    [SCREEN.MODERATELY_SENSITIVE_SCREEN]: 2,
    [SCREEN.LESS_SENSITIVE_SCREEN]: 3,
    [SCREEN.INSENSITIVE_SCREEN]: 4,
};

// returns the auth sensitivity to access level mapping
// access level 4 is returned if the information level passed is not in the map.
// this ensures at least access level 4 (insensitive) is required to view the pages.
export const getInformationLevelToAccessLevel = (currentScreenInformationLevel) => {
    if (INFORMATION_LEVEL_TO_ACCESS_LEVEL[currentScreenInformationLevel]) {
        return INFORMATION_LEVEL_TO_ACCESS_LEVEL[currentScreenInformationLevel];
    }

    return 4;
};

/**
 * Method to check whether the current authorized AccessLevel meets the access level defined for the screen.
 * @param activeTicket activeTicket object
 * @param pageInformationLevel The sensitivity level set for the screen.
 * @returns {boolean} returns true if current authorized level meets the requirement for requested page.
 */
export const hasAccessToThePage = (activeTicket, pageInformationLevel) => {
    const currentAuthorizedAuthLevel = getCurrentAuthorizedLevel(activeTicket);
    const informationLevelOfTheScreen = getInformationLevelToAccessLevel(pageInformationLevel);
    return currentAuthorizedAuthLevel <= informationLevelOfTheScreen;
};

/**
 * Debouncer to delay the function call
 * @param {function} func
 * @param {number} delay
 * @returns {function}
 */
export function debounce(func, delay) {
    let timeoutId;

    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
}
/**
 * Object Equality to compare two objects which are not nested.
 * @param {object} obj1
 * @param {object} obj2
 * @returns {boolean}
 */
// this would work only for guides which don't have nested object and primitive values.
export const checkObjectsEquality = (obj1, obj2) => {
    // stores the object keys for comparison
    const objectOneKeys = Object.keys(obj1);
    const objectTwoKeys = Object.keys(obj2);

    // function to remove keys with undefined or null values
    const filteredObjectKeys = (keys, obj) => keys.filter((key) => {
        if (obj[key] === null || obj[key] === undefined) return false;
        return true;
    });

    const filteredObj1Keys = filteredObjectKeys(objectOneKeys, obj1);
    const filteredObj2Keys = filteredObjectKeys(objectTwoKeys, obj2);

    // if the number of keys differ, directly return false
    if (filteredObj1Keys.length !== filteredObj2Keys.length) {
        return false;
    }
    // Returns true if both the objects have same key value pair
    return (!filteredObj1Keys.some((key) => (!(obj2.hasOwnProperty(key) && obj2[key] === obj1[key]))));
};

/**
 * Object Equality to compare two objects which are not nested.
 * @param {string} text
 * @returns {boolean | object}
 * @example
 *
 * getParsedJSON("123456") => false
 * getParsedJSON("Hello") => false
 * getParsedJSON("false") => false
 * getParsedJSON("[1,2,3,4]") => false
 * getParsedJSON("{\"abc\":123}") => {abc: 123}
 */
// Function to parse string representation of JSON object to parsed JSON object. Returns false if it is not a JSON object representation.
export const getParsedJSON = (text) => {
    if (!text || typeof text !== 'string') {
        return false;
    }
    try {
        const json = JSON.parse(text);
        // Checking if the parsed JSON is an javascript object or not. Returns false if it is not a JSON object representation.
        return Object.prototype.toString.call(json) === '[object Object]' ? json : false;
    } catch (error) {
        return false;
    }
};

/**
 *
 * @param {*} sampleTexts : Array<String>
 * @param {*} stringValue
 * @returns boolean : true if any of element of sampleTexts match with stringValue
 */

export function checkMultipleInclusionsInText(words, stringValue) {
    let counter = 0;
    for (let index = 0; index < words.length; index += 1) {
        const regex = new RegExp(words[index], 'i');
        if (regex.test(stringValue)) {
            counter += 1;
        }
    }
    return counter === words.length;
}

/**
 *
 * @param {*} deadline end timer
 * @returns time and setTimerStart
 */
export const useTimer = (deadline) => {
    const [time, setTime] = useState(deadline);
    const [timerStart, setTimerStart] = useState(false);

    useEffect(() => {
        if (timerStart) {
            const timer = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime === 0) {
                        clearInterval(timer);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }
    }, [timerStart]);

    return [time, setTimerStart];
};

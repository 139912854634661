/**
 *
 * AdminActions
 *
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';
import Modal from 'react-modal';

import {
    Button, DropdownContent, DropdownWrapper, ExpandableCard, FormDropdown, FormWithSingleInput, Loader,
} from '../../components';
import {
    injectReducer, injectSaga, getDropdownHeight, getRequiredFieldsFilledFlag, getModifiedParameterList,
    onFileClick, onFileChange,
} from '../../utils';

import {
    getActionList, getParameterList, setParameterValue, executeAction,
} from './actions';
import {
    makeSelectLoading, makeSelectErr, makeSelectActionList, makeSelectParameterList,
    makeSelectExecuteInfo, makeSelectHasExecuteInfo,
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content : {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '32px 20px',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 600,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    }
};

const AdminActions = (props) => {
    const dispatch = useDispatch();

    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const actionList = useSelector(makeSelectActionList(), shallowEqual);
    const parameterList = useSelector(makeSelectParameterList(), shallowEqual);
    const executeInfo = useSelector(makeSelectExecuteInfo(), shallowEqual);
    const hasExecuteInfo = useSelector(makeSelectHasExecuteInfo(), shallowEqual);

    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(true);
    const [formErrLabel, setFormErrLabel] = useState('');
    const [selectedAction, setSelectedAction] = useState('');
    const [showActionDD, setActionDD] = useState(false);
    const [showExecuteInfoModal, setExecuteInfoModal] = useState(false);
    const [fileUploadErrLabel, setFileUploadErrLabel] = useState('');

    const { ticketId, userId } = props;

    useEffect(() => {
        dispatch(getActionList({ ticketId, userId }));
        setActionDD(true);
    }, [dispatch]);

    const handleActionChange = value => {
        setSelectedAction(value);
        setActionDD(false);
        setFormErrLabel('');

        dispatch(getParameterList({ action: value, ticketId, userId }));
    };

    const showExecuteConfirmationModal = (e) => {
        e.preventDefault();

        const hasAllRequiredFieldsFilled = getRequiredFieldsFilledFlag(parameterList);

        if (!hasAllRequiredFieldsFilled) {
            setFormErrLabel('Please fill all the * marked fields');
            return;
        }

        setFormErrLabel('');
        setExecuteInfoModal(true);
    };

    const handleSubmit = () => {
        const modifiedParameters = getModifiedParameterList(parameterList);

        setFormErrLabel('');
        setExecuteInfoModal(false);

        const callExecuteAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(executeAction({
                    action: selectedAction,
                    parameters: modifiedParameters,
                    ticketId,
                    userId,
                    resolve,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callExecuteAction().then(() => {
            setSelectedAction('');
        });
        setIsSearchCardExpanded(false);
    };

    const renderParameterValue = item => {
        /**
         * STRING = 1;
         * INTEGER = 2;
         * DOUBLE = 3;
         * TIMESTAMP = 4;
         * DROPDOWN = 5;
         * MONEY = 6;
         * NAME = 7;
         * PHONE_NUMBER = 8;
         * MULTI_SELECT_DROPDOWN = 9;
         * PAGE_CONTEXT = 10;
         * FILE = 11;
         * REASON = 100;
         */
        switch (item.type) {
            case 4: {
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'adminactions-fc--m0',
                            inputField: 'adminactions-fc__ip',
                        }}
                        labelId={item.name}
                        formType='dob'
                        input={{
                            value: item.value,
                            onChange: (val) => {
                                dispatch(setParameterValue({ item, value: val }));
                            },
                            maxDate: new Date(),
                            minDate: new Date('10-01-2020'),
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }

            case 5: {
                const { length: optionsLength } = item.options;
                const optionListDDHeight = getDropdownHeight(optionsLength);

                return (
                    <DropdownWrapper
                        visible={item.isDDOpen}
                        onOutsideClickHandler={() => dispatch(setParameterValue({ item, dd: false }))}
                        extClasses={{
                            container: 'custom-dd adminactions-dd adminactions-dd--ml0'
                        }}
                    >
                        <div onClick={() => {dispatch(setParameterValue({ item, dd: true }))}}>
                            {item.value || 'Choose...'}
                        </div>
                        <DropdownContent
                            visible
                            extClasses = {{
                                container: ['custom-dd__cc adminactions-dd__cc']
                            }}
                            extStyles = {{
                                height: item.isDDOpen ? optionListDDHeight : 0,
                                overflow: item.isDDOpen ? 'auto' : 'hidden'
                            }}
                        >
                            {
                                item.options.map(ansOpt => (
                                    <div
                                        key={ansOpt}
                                        className='custom-dd__cl'
                                        onClick={() => {
                                            dispatch(setParameterValue({ item, value: ansOpt, dd: false }));
                                        }}
                                    >
                                        {ansOpt}
                                    </div>
                                ))
                            }
                        </DropdownContent>
                    </DropdownWrapper>
                );
            }

            case 7: {
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'adminactions-fc--m0'
                        }}
                        labelId={item.name}
                        formType='name'
                        input={{
                            value: item.value,
                            onChange: (key) => (e) => {
                                dispatch(setParameterValue({ item, key, value: e.target.value }));
                            }
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }

            case 9: {
                return (
                    <FormDropdown
                        isMulti
                        options={item.options}
                        input={{
                            value: item.value,
                            onChange: (value) => {
                                dispatch(setParameterValue({ item, value }));
                            }
                        }}
                        cacheKey='adminactions'
                        extStyles={{
                            container: 'adminactions-dd',
                        }}
                    />
                );
            }

            case 11: {
                const fileParams = {
                    item,
                    dispatch,
                    setParameterValue,
                    setErrLabel: setFileUploadErrLabel,
                };

                return (
                    <div className='fcWrapper'>
                        <input
                            type='file'
                            className='adminactions-fc__ip'
                            accept='.csv,.txt'
                            onClick={onFileClick(fileParams)}
                            onChange={onFileChange(fileParams)}
                        />
                        {
                            fileUploadErrLabel ? (
                                <div className='err-label adminactions-fuel'>{fileUploadErrLabel}</div>
                            ) : null
                        }
                    </div>
                );
            }

            default: { // 1, 2, 3, 6, 8, 100
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'adminactions-fc--m0',
                            inputField: 'adminactions-fc__ip'
                        }}
                        labelId={item.name}
                        input={{
                            value: item.value,
                            onChange: (e) => {
                                dispatch(setParameterValue({ item, value: e.target.value }));
                            }
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }
        }
    };

    const renderParameterList = () => {
        if (parameterList.length === 0) return null;

        return (
            <div className='adminactions-sc-ocr'>
                <div className='adminactions-sc-ocr__hl'>Search Parameters</div>
                {
                    parameterList
                        .filter(item => item.type !== 10) // filter page context option type
                        .map((item, idx) => (
                            <div className='adminactions-sc-owr' key={item.label}>
                                <div className='adminactions-sc-owr__ol'>{`${idx + 1}. ${item.label}${!item.isOptional ? '*' : ''}`}</div>
                                <div className='adminactions-sc-owr__ov'>
                                    {renderParameterValue(item)}
                                </div>
                            </div>
                        ))
                }
            </div>
        );
    };

    const renderExecuteInfo = () => {
        if (!hasExecuteInfo) {
            return null;
        }

        if (executeInfo.length === 0) {
            return (<div className='adminactions-src'>No execute info found for the above search parameters.</div>)
        }

        return (
            <div className='adminactions-src'>
                {JSON.stringify(executeInfo, null, 4)}
            </div>
        )
    };

    const { length: actionListLength } = actionList;
    const actionListDDHeight = getDropdownHeight(actionListLength);

    return (
        <React.Fragment>
            <div className='adminactions-cr'>
                <ExpandableCard
                    extClasses='adminactions-ec'
                    heading='Select Actions to perform'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='adminactions-sc'>
                        <div className='frcWrapper adminactions-sc-rw1'>
                            <div className='adminactions-ffl'>Action</div>
                            <DropdownWrapper
                                visible={showActionDD}
                                onOutsideClickHandler={() => setActionDD(!showActionDD)}
                                extClasses={{
                                    container: 'custom-dd adminactions-dd'
                                }}
                            >
                                <div onClick={() => setActionDD(!showActionDD)}>
                                    {selectedAction || 'Choose...'}
                                </div>
                                <DropdownContent
                                    visible
                                    extClasses = {{
                                        container: [
                                            'custom-dd__cc adminactions-dd__cc',
                                            // showActionDD && 'custom-dd__cc--ac'
                                        ]
                                    }}
                                    extStyles = {{
                                        height: showActionDD ? actionListDDHeight : 0,
                                        overflow: showActionDD ? 'auto' : 'hidden'
                                    }}
                                >
                                    {
                                        actionList.map(item => (
                                            <div
                                                key={item}
                                                className='custom-dd__cl'
                                                onClick={() => {
                                                    handleActionChange(item);
                                                }}
                                            >
                                                {item}
                                            </div>
                                        ))
                                    }
                                </DropdownContent>
                            </DropdownWrapper>
                        </div>
                        {
                            selectedAction ? (
                                <React.Fragment>
                                    {renderParameterList()}
                                    {formErrLabel ? <div className='err-label adminactions-el'>{formErrLabel}</div> : null}
                                    <Button
                                        extClasses={`adminactions-sc-cta ${formErrLabel && 'adminactions-sc-cta--err'}`}
                                        primary
                                        label='Retry'
                                        onClick={showExecuteConfirmationModal}
                                    />
                                </React.Fragment>
                            ) : null
                        }
                    </div>
                </ExpandableCard>
                {renderExecuteInfo()}
                {err ? (<div className='container-err-label adminactions-crel'>{err}</div>) : null}
            </div>
            {
                showExecuteInfoModal ? (
                    <Modal
                        isOpen
                        style={customStylesForModal}
                        contentLabel='Action Execution Modal'
                    >
                        <div className='adminactions-modal__hl'>
                            Are you sure you want to perform {selectedAction}?
                        </div>
                        <div className='adminactions-modal__sbhl'>
                            This change is irreversible and affects production data
                        </div>
                        <div className='frcsbWrapper adminactions-modal__ctas'>
                            <Button
                                secondary
                                label='Submit'
                                onClick={handleSubmit}
                            />
                            <Button
                                primary
                                label='Cancel'
                                onClick={() => setExecuteInfoModal(false)}
                            />
                        </div>
                    </Modal>
                ) : null
            }
            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'adminActions', reducer });
const withSaga = injectSaga({ key: 'adminActions', saga });

export default compose(
    withReducer,
    withSaga,
)(AdminActions);

/**
 *
 * @file USSDateView : Expandable card to store from date, to date and activity type for searching.
 *
 */

import React, { useState, memo } from 'react';

import {
    Button, DateRangePicker, ExpandableCard, FormDropdown,
} from '../../../components';

import { DATE_INFO, INITIAL_DATE_RANGE } from '../constants';

const USSDateView = (props) => {
    const {
        showDropdown,
        searchAction,
        heading = 'View user investment details - Search by other parameters',
        dropdownLabel,
        dropdownValue,
        handleDropdownChange,
        dropdownOptions,
    } = props;

    const [isSearchCardExpanded, setSearchCardExpanded] = useState(true);
    const [fromDate, setFromDate] = useState(INITIAL_DATE_RANGE.fromDate);
    const [toDate, setToDate] = useState(INITIAL_DATE_RANGE.toDate);
    const [errLabel, setErrLabel] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        let payload = {};

        if (!showDropdown && (fromDate.value || toDate.value)) {
            payload = { fromDate, toDate };
        }

        if (showDropdown && (fromDate.value || toDate.value || dropdownValue.value)) {
            payload = { fromDate, toDate, dropdownValue };
        }

        searchAction(payload);

        setErrLabel('');
    };

    const setFromDateValue = (newFromDate) => {
        // call API only if fromDate value has changed
        if (newFromDate.value !== fromDate.value) {
            setFromDate(newFromDate);
        }
    };

    const setToDateValue = (newToDate) => {
        if (newToDate.value !== toDate.value) {
            setToDate(newToDate);
        }
    };

    return (
        <div className='uss-cr uss-datetime'>
            <ExpandableCard
                extClasses='expandable-card uss-expandable-card'
                heading={heading}
                isExpanded={isSearchCardExpanded}
                setIsExpanded={() => setSearchCardExpanded(!isSearchCardExpanded)}
            >
                <div className='uss-sc'>
                    <div className='fcfsWrapper uss-sc__gap'>
                        {isSearchCardExpanded ? (
                            <DateRangePicker
                                dateInfo={DATE_INFO}
                                fromDate={fromDate}
                                setFromDate={setFromDateValue}
                                toDate={toDate}
                                setToDate={setToDateValue}
                            />
                        ) : null }

                        {showDropdown ? (
                            <div className='form-container uss-fc'>
                                <FormDropdown
                                    label={dropdownLabel}
                                    options={dropdownOptions}
                                    input={{
                                        value: dropdownValue,
                                        onChange: handleDropdownChange,
                                        placeholder: 'Choose...',
                                    }}
                                    cacheKey='uss-action-dropdown'
                                    extStyles={{
                                        container: 'uss-fcwsd',
                                        label: 'uss-dd__lb uss-dd__at',
                                    }}
                                />
                            </div>
                        ) : null }
                    </div>
                    <div className='error-label'>{errLabel}</div>
                    <div className='frcWrapper uss-sc__3r'>
                        <Button
                            extClasses='uss-sc__cta'
                            primary
                            label='Search'
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </ExpandableCard>
        </div>
    );
};

export default memo(USSDateView);

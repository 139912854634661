/**
 *
 * ClaimedRewardInfoModal
 *
 */

import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button } from '../../../components';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '10%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 900,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        maxHeight: '85%',
    },
};

const ClaimedRewardInfo = (props) => {
    const {
        visible, data, rewardInfoData, toggleHandler,
    } = props;

    if (!visible) return null;

    const {
        id, refId, rewardId, rewardOfferId, chosenReward, rewardOptions,
    } = data;

    const { tnc, stepsToRedeem, unlockSteps } = rewardInfoData;

    const rewardOptionsDetails = rewardOptions && rewardOptions.options;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='Claimed Reward Details Modal'
        >
            <div className='rewards-modal-clrwcr'>
                <div className='rewards-modal-lb'>Reference Id - {refId}</div>
                <div className='rewards-modal-lb'>Reward Id - {id}</div>
                <div className='rewards-modal-lb'>External Reward Id - {rewardId}</div>
                <div className='rewards-modal-lb'>Reward Offer Id - {rewardOfferId}</div>
                {
                    chosenReward ? (
                        <div className='table-sr table-sr--mt20'>
                            <div className='table-sr__label'>Chosen Reward</div>
                            <div className='table-sr__thw'>
                                <div className='table-sr__th'>Type</div>
                                <div className='table-sr__th'>Value</div>
                                <div className='table-sr__th'>Expiry At</div>
                                <div className='table-sr__th'>Maturity At</div>
                                <div className='table-sr__th'>Processing At</div>
                                <div className='table-sr__th'>Processing Time</div>
                            </div>
                            <div className='table-sr__tlc'>
                                <div className='table-sr__tlw'>
                                    <div className='table-sr__tl'>{chosenReward.type}</div>
                                    <div className='table-sr__tl'>{chosenReward.value}</div>
                                    <div className='table-sr__tl'>{chosenReward.expiryTs}</div>
                                    <div className='table-sr__tl'>{chosenReward.maturityTs}</div>
                                    <div className='table-sr__tl'>{chosenReward.processingTs}</div>
                                    <div className='table-sr__tl'>{chosenReward.processingTime}</div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                {
                    rewardOptionsDetails ? (
                        <div className='table-sr table-sr--mt20'>
                            <div className='table-sr__label'>Reward Options</div>
                            <div className='table-sr__thw'>
                                <div className='table-sr__th'>Type</div>
                                <div className='table-sr__th'>Value</div>
                                <div className='table-sr__th'>Processing Time</div>
                            </div>
                            <div className='table-sr__tlc'>
                                {
                                    rewardOptionsDetails.map((item) => (
                                        <div className='table-sr__tlw' key={item.id}>
                                            <div className='table-sr__tl'>{item.type}</div>
                                            <div className='table-sr__tl'>{item.value}</div>
                                            <div className='table-sr__tl'>{item.processingTime}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ) : null
                }
                {
                    rewardInfoData ? (
                        <div className='rewards-modal-rwwr'>
                            {
                                tnc?.value?.length ? (
                                    <React.Fragment>
                                        <div className='rewards-modal-hd'>{tnc?.label || 'Terms and Conditions'}</div>
                                        <div className='rewards-modal-ctwr2'>
                                            {tnc?.value.map((si) => (
                                                <div className='rewards-modal-ct'>• {si}</div>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                ) : null
                            }
                            {
                                stepsToRedeem?.value?.length ? (
                                    <React.Fragment>
                                        <div className='rewards-modal-hd'>{stepsToRedeem?.label || 'Steps to Redeem'}</div>
                                        <div className='rewards-modal-ctwr2'>
                                            {stepsToRedeem?.value.map((si) => (
                                                <div className='rewards-modal-ct'>• {si}</div>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                ) : null
                            }
                            {
                                unlockSteps?.value ? (
                                    <React.Fragment>
                                        <div className='rewards-modal-hd'>{unlockSteps?.label || 'Unlock Steps'}</div>
                                        <div className='rewards-modal-ctwr2'>
                                            {unlockSteps?.value.map((si) => (
                                                <div className='rewards-modal-ct'>• {si}</div>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                ) : null
                            }
                        </div>
                    ) : null
                }
            </div>
            <Button
                extClasses='rewards-modal-cta'
                primary
                label='Close'
                onClick={toggleHandler}
            />
        </Modal>
    );
};

export default memo(ClaimedRewardInfo);

/*
 *
 * Facematch review constants
 *
 */
import { getInitialDateRange } from '../../utils';
import { LATEST_CASES_FIRST, OLDEST_CASES_FIRST } from '../RiskOps/constants';

export const FACEMATCH_REVIEW_KEY = 'facematchReview';

// action constants
export const GET_FACEMATCH_CASES = 'app/FacematchReview/GET_FACEMATCH_CASES';
export const SET_FACEMATCH_CASES = 'app/FacematchReview/SET_FACEMATCH_CASES';
export const ERR_FACEMATCH_CASES = 'app/FacematchReview/ERR_FACEMATCH_CASES';
export const DEQUEUE_FACEMATCH_CASE = 'app/FacematchReview/DEQUEUE_FACEMATCH_CASE';
export const SET_FACEMATCH_VIEW = 'app/FacematchReview/SET_FACEMATCH_VIEW';
export const GET_BASE64_IMAGE = 'app/FacematchReview/GET_BASE64_IMAGE';
export const SET_BASE64_IMAGE = 'app/FacematchReview/SET_BASE64_IMAGE';
export const SET_CUR_FACEMATCH_CASE = 'app/FacematchReview/SET_CUR_FACEMATCH_CASE';
export const SET_FACEMATCH_PAYLOAD_TYPE = 'app/FacematchReview/SET_FACEMATCH_PAYLOAD_TYPE';
export const GET_FACEMATCH_PENDING_REVIEW_COUNT = 'app/FacematchReview/GET_FACEMATCH_PENDING_REVIEW_COUNT';
export const SET_FACEMATCH_PENDING_REVIEW_COUNT = 'app/FacematchReview/SET_FACEMATCH_PENDING_REVIEW_COUNT';
export const SET_FACEMATCH_QUEUE_FILTERS = 'app/FacematchReview/SET_FACEMATCH_QUEUE_FILTERS';
export const SET_FACEMATCH_L2_ANNOTATION_DETAILS = 'app/FacematchReview/SET_FACEMATCH_L2_ANNOTATION_DETAILS';

// state constants
export const VIEW_ALL_FACEMATCH_CASES = 'app/FacematchReview/State/VIEW_ALL_FACEMATCH_CASES';
export const VIEW_SINGLE_FACEMATCH_CASE = 'app/FacematchReview/State/VIEW_SINGLE_FACEMATCH_CASE';
export const DEV_ACTION_TYPES = ['MARK_FACEMATCH_PASSED', 'RETRY_LIVENESS'];

// value constants
export const PAYLOAD_TYPE_FACEMATCH = 'PAYLOAD_TYPE_FACEMATCH';
export const PAYLOAD_TYPE_FACEMATCH_SAMPLE = 'PAYLOAD_TYPE_FACEMATCH_SAMPLE';
export const PAYLOAD_TYPE_AFU_FACEMATCH = 'PAYLOAD_TYPE_AFU_FACEMATCH';
export const PAYLOAD_TYPE_L2_FACEMATCH = 'PAYLOAD_TYPE_QA_FACEMATCH';

export const INITIAL_DATE_RANGE = getInitialDateRange();

const FROM_DATE_INFO = {
    label: 'From Date',
    labelId: 'facematch-review-from-date',
};

const TO_DATE_INFO = {
    label: 'To Date',
    labelId: 'facematch-review-to-date',
};

const DATE_PICKER_CLS = {
    container: ['fmreview-fc'],
    inputField: ['fmreview-fc__ip'],
};

export const DATE_INFO = {
    fromDateInfo: FROM_DATE_INFO,
    toDateInfo: TO_DATE_INFO,
    initialDateRange: INITIAL_DATE_RANGE,
    extClasses: DATE_PICKER_CLS,
};

export const SORTING_OPTIONS = [OLDEST_CASES_FIRST, LATEST_CASES_FIRST];

export const SORT_DD_OG_STATE = {
    value: LATEST_CASES_FIRST,
    isVisible: false,
};

export const QUEUE_PAYLOAD_TYPES = [PAYLOAD_TYPE_FACEMATCH, PAYLOAD_TYPE_FACEMATCH_SAMPLE, PAYLOAD_TYPE_AFU_FACEMATCH, PAYLOAD_TYPE_L2_FACEMATCH];

export const PAYLOAD_DD_OG_STATE = {
    value: PAYLOAD_TYPE_FACEMATCH,
    isVisible: false,
};

// class constants
export const DD_WRAPPER_CLS = {
    container: 'custom-dd fmreview-idd',
};

export const DD_CONTENT_CLS = {
    container: ['fmreview-idd__cc'],
};

export const DD_ITEM_CLS = 'custom-dd__cl fmreview-idd__cl';

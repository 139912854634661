/**
 *
 * AppGlobal - Main entry point component to handle app versioning related logic & necessary redirection requests to authenticate the user
 *
 */
import React, { memo, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    Switch, Route, useLocation, useHistory, Redirect,
} from 'react-router-dom';
import { compose } from 'redux';
import { ToastContainer } from 'react-toastify';

import AppV2 from '../containersV2/AppV2';
import App from '../containers/App';
import { TOAST_CONFIG } from '../utils/toastify';
import { TokensContext, UserDetailsContext, MetaInfoContext } from '../context';
import { injectReducer, injectSaga } from '../utils';
import { Loader } from '../components';

import { getAuthStatus, getBannerList } from './actions';
import {
    selectAccessLevel, selectAgentInfo, selectCSPNonce, selectHasAgentInfo, selectLoading, selectMetaInfo,
} from './selectors';
import { APP_V1_PATH, APP_V2_PATH, LOGIN_PATH, ROOT_PATH } from './constants';

import reducer from './reducer';
import saga from './saga';

const GET_BANNERS_GRPC_TIMOUT = 300000;

const CloseButton = ({ closeToast }) => (
    <button
        type='button'
        className='close ml-1 mr-2'
        aria-label='Close'
        onClick={() => closeToast()}
    >
        <span aria-hidden='true'>&times;</span>
    </button>
);

const AppGlobal = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const selectedAccessLevel = useSelector(selectAccessLevel(), shallowEqual);
    const hasAgentInfo = useSelector(selectHasAgentInfo(), shallowEqual);
    const agentInfo = useSelector(selectAgentInfo(), shallowEqual);
    const loading = useSelector(selectLoading(), shallowEqual);
    const metaInfo = useSelector(selectMetaInfo(), shallowEqual);
    const cspNonce = useSelector(selectCSPNonce(), shallowEqual);

    const { appConfig: { enableAgentManagementV2 } } = metaInfo;

    const cspNonceMemoized = useMemo(() => ({ cspNonce }), [cspNonce]);

    const userDetailsMemoized = useMemo(() => ({ basicInfo: agentInfo, selectedAccessLevel }), [agentInfo, selectedAccessLevel]);

    useEffect(() => {
        dispatch(getAuthStatus({ location }));
    }, [dispatch]);

    useEffect(() => {
        if (selectedAccessLevel) {
            const intervalId = setInterval(() => {
                dispatch(getBannerList({ roles: [selectedAccessLevel] }));
            }, GET_BANNERS_GRPC_TIMOUT);

            return () => clearInterval(intervalId);
        }
        return () => {};
    }, [dispatch, selectedAccessLevel]);

    // checks whether the current url path starts with '/v2' (Sherlock V2 path) or not.
    const isV2Route = location.pathname.startsWith(APP_V2_PATH);
    const isLoginRoute = location.pathname === LOGIN_PATH;
    /**
     * Ensure that no routes can be accessed if user is not authenticated
     * If user tries to access a route and is not authenticated, it immediately redirects back to login page
     */
    useEffect(() => {
        if (!hasAgentInfo && !isLoginRoute) {
            const { pathname, search } = location;
            history.replace({
                pathname: LOGIN_PATH,
                // add the current url path for redirection after login only if it corresponds to Sherlock V2 path ('/v2') in the prevPath state.
                state: { prevPath: isV2Route ? `${pathname}${search}` : null },
            });
        }
    }, [hasAgentInfo, history, location.pathname, location.search]);

    // if the Agent Management v2 flow is enabled, redirect to root path if its a login route and does not have agent info.
    if (enableAgentManagementV2 && isLoginRoute && hasAgentInfo) {
        return <Redirect to={location?.state?.prevPath || ROOT_PATH} />;
    }

    if (loading.authStatus || loading.agentInfo) return <Loader visible />;

    return (
        <TokensContext.Provider value={cspNonceMemoized}>
            <UserDetailsContext.Provider value={userDetailsMemoized}>
                <MetaInfoContext.Provider value={metaInfo}>
                    <React.Fragment>
                        <Switch>
                            <Route
                                path={APP_V2_PATH}
                                component={AppV2}
                            />
                            <Route
                                path={APP_V1_PATH}
                                component={App}
                            />
                        </Switch>
                        <ToastContainer
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...TOAST_CONFIG}
                            className='toast-container'
                            toastClassName='toast-notification'
                            closeButton={CloseButton}
                        />
                    </React.Fragment>
                </MetaInfoContext.Provider>
            </UserDetailsContext.Provider>
        </TokensContext.Provider>
    );
};

const withReducer = injectReducer({ key: 'app', reducer });
const withSaga = injectSaga({ key: 'app', saga });

export default compose(
    withReducer,
    withSaga,
)(memo(AppGlobal));

/**
 * @file Manages the action functions for Case Search container
 */

import { GET_CASE_SEARCH, RESET_CASE_SEARCH } from './constants';

export const getCaseSearchAction = (data, callback = () => {}) => ({
    type: GET_CASE_SEARCH,
    data,
    callback,
});

export const resetCaseSearchAction = (data) => ({
    type: RESET_CASE_SEARCH,
    data,
});

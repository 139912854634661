import { fromJS } from 'immutable';

import {
    GET_ACTIVE_RULES, SET_ACTIVE_RULES, ERR_ACTIVE_RULES, GET_USER_RULES, SET_USER_RULES,
    ERR_USER_RULES, GET_USER_RULE_INFO, SET_USER_RULE_INFO, ERR_USER_RULE_INFO,
} from './constants';

const initialState = fromJS({
    activeRuleList: {},
    userRuleList: {},
    userRuleInfo: {},
});

function fitttReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ACTIVE_RULES: {
            const { ticketId } = action.data;

            return state
                .setIn(['activeRuleList', ticketId, 'loading'], true)
                .setIn(['activeRuleList', ticketId, 'err'], '');
        }

        case SET_ACTIVE_RULES: {
            const { ticketId, ruleList, prev, next } = action.data;

            return state
                .setIn(['activeRuleList', ticketId, 'loading'], false)
                .setIn(['activeRuleList', ticketId, 'data'], fromJS(ruleList))
                .setIn(['activeRuleList', ticketId, 'prev'], fromJS(prev))
                .setIn(['activeRuleList', ticketId, 'next'], fromJS(next));
        }

        case ERR_ACTIVE_RULES: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['activeRuleList', ticketId, 'loading'], false)
                .setIn(['activeRuleList', ticketId, 'err'], err)
                .setIn(['activeRuleList', ticketId, 'data'], fromJS([]))
                .setIn(['activeRuleList', ticketId, 'prev'], fromJS({}))
                .setIn(['activeRuleList', ticketId, 'next'], fromJS({}));
        }

        case GET_USER_RULES: {
            const { ticketId } = action.data;

            return state
                .setIn(['userRuleList', ticketId, 'loading'], true)
                .setIn(['userRuleList', ticketId, 'err'], '');
        }

        case SET_USER_RULES: {
            const { ticketId, subscriptionList, prev, next } = action.data;

            return state
                .setIn(['userRuleList', ticketId, 'loading'], false)
                .setIn(['userRuleList', ticketId, 'data'], fromJS(subscriptionList))
                .setIn(['userRuleList', ticketId, 'prev'], fromJS(prev))
                .setIn(['userRuleList', ticketId, 'next'], fromJS(next));
        }

        case ERR_USER_RULES: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['userRuleList', ticketId, 'loading'], false)
                .setIn(['userRuleList', ticketId, 'err'], err)
                .setIn(['userRuleList', ticketId, 'data'], fromJS([]))
                .setIn(['userRuleList', ticketId, 'prev'], fromJS({}))
                .setIn(['userRuleList', ticketId, 'next'], fromJS({}));
        }

        case GET_USER_RULE_INFO: {
            const { ticketId, prev, next } = action.data;

            if (prev || next) {
                return state
                    .setIn(['userRuleInfo', ticketId, 'loading'], true)
                    .setIn(['userRuleInfo', ticketId, 'err'], '')
            }

            return state
                .setIn(['userRuleInfo', ticketId, 'loading'], true)
                .setIn(['userRuleInfo', ticketId, 'err'], '')
                .setIn(['userRuleInfo', ticketId, 'data'], fromJS([]))
                .setIn(['userRuleInfo', ticketId, 'hasData'], false);
        }

        case SET_USER_RULE_INFO: {
            const { ticketId, ruleExecutionList, prev, next } = action.data;

            return state
                .setIn(['userRuleInfo', ticketId, 'loading'], false)
                .setIn(['userRuleInfo', ticketId, 'hasData'], true)
                .setIn(['userRuleInfo', ticketId, 'data'], fromJS(ruleExecutionList))
                .setIn(['userRuleInfo', ticketId, 'prev'], fromJS(prev))
                .setIn(['userRuleInfo', ticketId, 'next'], fromJS(next));
        }

        case ERR_USER_RULE_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['userRuleInfo', ticketId, 'loading'], false)
                .setIn(['userRuleInfo', ticketId, 'err'], err)
                .setIn(['userRuleInfo', ticketId, 'hasData'], false)
                .setIn(['userRuleInfo', ticketId, 'data'], fromJS([]))
                .setIn(['userRuleInfo', ticketId, 'prev'], fromJS({}))
                .setIn(['userRuleInfo', ticketId, 'next'], fromJS({}));
        }

        default:
            return state;
    }
}

export default fitttReducer;

/**
 * @file SherlockV2 contains rudder event names for Sherlock V2 scripts
 */

const LandedSherlockV2ScriptsSearchPage = 'LandedSherlockV2ScriptsSearchPage';
const LandedSherlockV2ScriptsPage = 'LandedSherlockV2ScriptsPage';
const ClickedPictureGuideStep = 'ClickedPictureGuideStep';
const ClickedPictureGuideDoneButton = 'ClickedPictureGuideDoneButton';

export {
    LandedSherlockV2ScriptsSearchPage,
    LandedSherlockV2ScriptsPage,
    ClickedPictureGuideStep,
    ClickedPictureGuideDoneButton,
};

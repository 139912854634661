import 'whatwg-fetch';

import { setAccessLevelInMetaTag } from '../containers/App/utils';
import { LOGIN_PATH } from '../containers/App/routes';

import { SET_LOGIN_URL, SET_ACCESS_LEVEL, RESET_AGENT_INFO } from '../constants/actions';

import store from '../configureStore';
import { history } from '../reducers';

import { setCookie } from './cookies';

class ClientAPIWrapper {
    constructor() {
        this.headers = {
            'Content-Type': 'application/json',
        };
    }

    // not getting used as of now
    setHeaders(key, value) {
        this.headers = {
            ...this.headers,
            [key]: value,
        };
    }

    getHeaders() {
        return this.headers;
    }

    static isUnauthorized(response) {
        return response.status === 401;
    }

    static async checkStatusAndExtractData(response) {
        // successful responses
        if (response.status >= 200 && response.status < 300) {
            if (response.status === 204 || response.status === 205) {
                return null;
            }

            return response.json();
        }

        // 504 Gateway Timeout Error
        if (response.status === 504) {
            // exposes an error event with two params name & message
            throw new Error('Gateway Timeout Error, please try again!');
        }

        // client & server errors
        const error = await response.json();

        // If any API throws 401, redirect to login page and reset agent info
        if (ClientAPIWrapper.isUnauthorized(response)) {
            // set log in URL
            store.dispatch({
                type: SET_LOGIN_URL,
                data: {
                    loginUrl: error.loginUrl,
                },
            });

            // reset selected role
            store.dispatch({
                type: SET_ACCESS_LEVEL,
                data: { accessLevel: '' },
            });

            // reset agent info
            store.dispatch({
                type: RESET_AGENT_INFO,
            });
            setAccessLevelInMetaTag('');

            // redirect to login screen
            history.push(LOGIN_PATH);
        }

        throw error;
    }

    getOptions(method, body, headers) {
        const csrfTokenMetaTag = document.querySelector('meta[name="csrf-token"]');
        const accessLevelMetaTag = document.querySelector('meta[name="access-level"]');
        let csrfToken;
        let accessLevel;

        if (csrfTokenMetaTag) {
            csrfToken = csrfTokenMetaTag.getAttribute('content');
        }

        /*
        for multi-tab support, we need to set cookie with the meta tag value that gets selected when user selects the access level.
        users can have different selected role & meta tag value in a particular tab, we need to set the access_level cookie with the meta tag value
        as we don't want to read it from reducer
        */
        if (accessLevelMetaTag) {
            accessLevel = accessLevelMetaTag.getAttribute('content');
            setCookie('access_level', accessLevel);
        }

        const options = {
            method,
            headers: { ...this.getHeaders(), ...headers },
            body: JSON.stringify(body),
        };

        if (csrfToken) {
            options.headers['csrf-token'] = csrfToken;
        }

        return options;
    }

    executeCall(method, url, body, headers) {
        const options = this.getOptions(method, body, headers);

        return fetch(url, options)
            .then(ClientAPIWrapper.checkStatusAndExtractData);
    }

    get(url, body, headers) {
        return this.executeCall('GET', url, body, headers);
    }

    post(url, body = {}, headers = {}) {
        return this.executeCall('POST', url, body, headers);
    }

    put(url, body = {}, headers = {}) {
        return this.executeCall('PUT', url, body, headers);
    }

    delete(url, body = {}, headers = {}) {
        return this.executeCall('DELETE', url, body, headers);
    }

    async fileUploadByFormData(method, url, body, headers) {
        const options = this.getOptions(method, body, headers);

        if (options?.headers['Content-Type']) {
            delete options.headers['Content-Type'];
            options.body = body;
        }

        return fetch(url, options)
            .then(ClientAPIWrapper.checkStatusAndExtractData);
    }

    async executeStreamCall(method, url, body, headers) {
        const options = this.getOptions(method, body, headers);

        const response = await fetch(url, options);

        if (!response.ok) {
            throw await ClientAPIWrapper.checkStatusAndExtractData(response);
        }

        return response.body;
    }

    async getStream(url, body, headers) {
        return this.executeStreamCall('GET', url, body, headers);
    }
}

const clientAPIWrapperInstance = new ClientAPIWrapper();
Object.freeze(clientAPIWrapperInstance);

export default clientAPIWrapperInstance;

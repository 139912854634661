/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/**
 * @file Manages the reducer state & function for Case Search container
 */

import { fromJS } from 'immutable';

import {
    GET_CASE_SEARCH, SET_CASE_SEARCH, ERR_CASE_SEARCH, REDUCER_KEYS,
} from './constants';

const initialState = fromJS({
    [REDUCER_KEYS.CASE_SEARCH_FILE_ENTRIES]: {
        [REDUCER_KEYS.CASE_SEARCH_COLUMN_DATA]: [],
        [REDUCER_KEYS.CASE_SEARCH_ROW_DATA]: [],
        [REDUCER_KEYS.CASE_SEARCH_PREV_TOKEN]: '',
        [REDUCER_KEYS.CASE_SEARCH_NEXT_TOKEN]: '',
    },
    [REDUCER_KEYS.CASE_SEARCH_LOADING]: false,
    [REDUCER_KEYS.CASE_SEARCH_ERROR]: '',
});

function caseSearchReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_CASE_SEARCH: {
            return state
                .setIn([REDUCER_KEYS.CASE_SEARCH_LOADING], true)
                .setIn([REDUCER_KEYS.CASE_SEARCH_ERROR], '');
        }

        case SET_CASE_SEARCH: {
            const { fileEntries } = action.data;

            return state
                .setIn([REDUCER_KEYS.CASE_SEARCH_FILE_ENTRIES], fromJS(fileEntries))
                .setIn([REDUCER_KEYS.CASE_SEARCH_LOADING], false)
                .setIn([REDUCER_KEYS.CASE_SEARCH_ERROR], '');
        }

        case ERR_CASE_SEARCH: {
            const { err } = action.data;

            return state
                .setIn([REDUCER_KEYS.CASE_SEARCH_FILE_ENTRIES], fromJS({}))
                .setIn([REDUCER_KEYS.CASE_SEARCH_LOADING], false)
                .setIn([REDUCER_KEYS.CASE_SEARCH_ERROR], err);
        }

        default:
            return state;
    }
}

export default caseSearchReducer;

/**
 *
 * Kyc Onboarding
 *
 */

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { copyTextWithStyle } from '../../utils/uiHelpers';
import { getKeyFromValueAndSubValue } from '../../utils/formatter';
import { RudderEvent, pushRudderEvent } from '../../rudderEvents';
import { Button, Loader } from '../../components';
import { isNilOrEmpty } from '../../utils';

import { getProfileInfo } from '../Profile/actions';
import { makeSelectProfileDetails } from '../Profile/selectors';
import { renderAccountInfo, renderAddressInfo } from '../Profile/utils';
import { makeSelectAgentInfo } from '../AppV2/selectors';
import { CUSTOMER_AUTH_TYPES } from '../UserDetailsWithPhoneOrEmail/constants';

import TableModal from './Modals/TableModal';
import VKycModal from './Modals/VKycModal';
import KycAttemptList from './KycAttemptList';
import OnboardingInfo from './OnboardingInfo';
import ReOnboardingInfo from './ReOnboardingInfo';
import VKycAttemptList from './VKycAttemptList';
import {
    getKycInfo, getOnboardingInfo, getReoobeInfo, getVKycInfo, retryLiveness,
} from './actions';
import {
    renderKycInfo, renderOnboardingInfo, renderVKycSummaryInfo,
    renderInAppScreeningInfo, renderUserFiOnboardingInfo, labelValuesConcatenation, renderKycStatus,
} from './utils';
import { makeSelectKycDetails, makeSelectOnboardingDetails, makeSelectVKycDetails } from './selectors';
import AccountOperationsStatusInfo from './AccountOperationsStatusInfo';
import ReKycDetails from './ReKycDetails';
import { LABEL_VALUE_DATA_TYPE } from './constants';

import './style.scss';

const KycContentId = 'kyc-content';

const KycOnboarding = (props) => {
    const dispatch = useDispatch();

    const {
        accountFreezeInfo: {
            status: freezeStatus = null,
        } = {},
        ticketId, userId, index, notTicket, tabData,
    } = props || {};

    const [isVKycModalOpen, setIsVKycModalOpen] = useState(false);
    const [ACRModalData, setACRModalData] = useState(null);
    const [showReKycDetails, setShowReKycDetails] = useState(false);
    const [showKycInfo, setKycInfo] = useState(false);
    const [showOnboardingInfo, setOnboardingInfo] = useState(false);
    const [showReOnboardingInfo, setReOnboardingInfo] = useState(false);
    const [showVKycAttemptInfo, setVKycAttemptInfo] = useState(false);
    const [showInAppScreeningInfo, setInAppScreeningInfo] = useState(false);
    const [showAccountOperationStatusInfo, setShowAccountOperationStatusInfo] = useState(false);
    const [showAccountOperationStatus, setShowAccountOperationStatus] = useState(false);

    const kycDetails = useSelector(makeSelectKycDetails(), shallowEqual);
    const vkycDetails = useSelector(makeSelectVKycDetails(), shallowEqual);
    const onboardingDetails = useSelector(makeSelectOnboardingDetails(), shallowEqual);
    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);
    const profileDetails = useSelector(makeSelectProfileDetails, shallowEqual);
    const { identifierList, activeTabIndex } = tabData || {};

    let currentTabData = identifierList && identifierList[activeTabIndex];

    if (!currentTabData && !notTicket) {
        currentTabData = {
            type: CUSTOMER_AUTH_TYPES.ticket,
            value: ticketId,
        };
    }

    let loadingKycInfo;
    let kycInfo;
    let hasKycInfo;
    let loadingVKycInfo;
    let vkycInfo;
    let hasVKycInfo;
    let loadingOnboardingInfo;
    let onboardingInfo;
    let hasOnboardingInfo;
    let reoobeInfo;
    let hasReoobeInfo;
    let profileInfo;
    let hasProfileInfo;
    let loadingProfileInfo;
    let profileError;
    let troubleshootingList;
    let summaryInfo;
    let errorVKycInfo;

    const currentKey = getKeyFromValueAndSubValue(currentTabData?.value, currentTabData?.subValue);

    if (profileDetails && profileDetails[currentKey]) {
        ({
            loading: loadingProfileInfo = true, profileInfo, hasProfileInfo = false, profileError,
        } = profileDetails[currentKey]);
    }
    if (onboardingDetails && onboardingDetails[currentKey]) {
        ({
            loading: loadingOnboardingInfo, onboardingInfo, hasOnboardingInfo = false,
            reoobeInfo, hasReoobeInfo = false,
        } = onboardingDetails[currentKey]);
    }

    if (kycDetails && kycDetails[currentKey]) {
        ({
            loading: loadingKycInfo, kycInfo, hasKycInfo = false,
        } = kycDetails[currentKey]);
    }

    if (vkycDetails && kycDetails[currentKey]) {
        ({
            loading: loadingVKycInfo, vkycInfo, hasVKycInfo = false, error: errorVKycInfo,
        } = vkycDetails[currentKey]);
        if (hasVKycInfo) ({ summaryInfo, troubleshootingList } = vkycInfo);
    }

    useEffect(() => {
        if (!hasProfileInfo || !hasOnboardingInfo || !hasKycInfo || !hasVKycInfo || !hasReoobeInfo) {
            const data = {
                userId, index, authType: currentTabData?.type, authValue: currentTabData?.value, authSubValue: currentTabData?.subValue,
            };

            if (!hasProfileInfo) {
                dispatch(getProfileInfo(data));
            }

            if (!hasOnboardingInfo) {
                dispatch(getOnboardingInfo(data));
            }

            if (!hasKycInfo) {
                dispatch(getKycInfo(data));
            }

            if (!hasVKycInfo) {
                dispatch(getVKycInfo(data));
            }

            if (!hasReoobeInfo) {
                dispatch(getReoobeInfo(data));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRetryLivenessCTA = () => {
        dispatch(retryLiveness({ ticketId, userId, index }));
    };

    const userFiOnboardingInfo = useMemo(() => {
        if (hasOnboardingInfo) {
            return renderUserFiOnboardingInfo(onboardingInfo);
        }
        return null;
    }, [hasOnboardingInfo, onboardingInfo]);

    const accountInfo = useMemo(() => {
        if (hasProfileInfo) {
            return renderAccountInfo(profileInfo);
        }
        return null;
    }, [hasProfileInfo, profileInfo]);

    const labelValues = useMemo(() => {
        if (hasProfileInfo) {
            return profileInfo?.labelValues ? labelValuesConcatenation(profileInfo.labelValues) : null;
        }
        return null;
    }, [hasProfileInfo, profileInfo]);

    const addressInfo = useMemo(() => {
        if (hasProfileInfo) {
            return renderAddressInfo(profileInfo.accountInfo);
        }
        return null;
    }, [hasProfileInfo, profileInfo?.accountInfo]);

    const { accountClosureRequestSection } = profileInfo || {};

    if (showInAppScreeningInfo) {
        return (
            <OnboardingInfo
                isInAppScreeningFlow
                data={onboardingInfo && onboardingInfo.inAppScreeningInfoDetailedMap}
                toggleHandler={setInAppScreeningInfo}
            />
        );
    }

    if (showKycInfo) {
        return (
            <React.Fragment>
                <KycAttemptList
                    data={kycInfo && kycInfo.attemptList}
                    toggleHandler={setKycInfo}
                    allowRetryLiveness={kycInfo && kycInfo.allowRetryLiveness}
                    onRetryLivenessCTA={onRetryLivenessCTA}
                />
                <Loader visible={loadingKycInfo} />
            </React.Fragment>
        );
    }

    if (showReKycDetails) {
        return (
            <ReKycDetails
                data={hasKycInfo && kycInfo.labelValues}
                toggleHandler={setShowReKycDetails}
            />
        );
    }

    if (showVKycAttemptInfo) {
        return (
            <VKycAttemptList
                data={vkycInfo && vkycInfo.attemptList}
                toggleHandler={setVKycAttemptInfo}
                customerData={vkycInfo && vkycInfo.customerInfoList}
            />
        );
    }

    if (showReOnboardingInfo) {
        return (
            <ReOnboardingInfo
                data={reoobeInfo}
                toggleHandler={setReOnboardingInfo}
            />
        );
    }

    if (showOnboardingInfo) {
        return (
            <OnboardingInfo
                data={onboardingInfo && onboardingInfo.onboardingInfoDetailedMap}
                toggleHandler={setOnboardingInfo}
                currentStageTroubleshootInfo={onboardingInfo && onboardingInfo.currentStageTroubleshootInfo}
            />
        );
    }

    const copyDataClickHandler = () => {
        const properties = {
            ticketId,
        };
        pushRudderEvent(RudderEvent.KYCOnboarding.KYCMainScreenCopyButtonClick, agentInfo.emailId, properties);
        copyTextWithStyle(KycContentId);
    };

    if (showAccountOperationStatusInfo) {
        return (
            <AccountOperationsStatusInfo
                toggleHandler={(event) => {
                    setShowAccountOperationStatusInfo(event);
                }}
                status={showAccountOperationStatus}
            />
        );
    }

    const extraOptionsForLabelValues = (label, value) => {
        if (value.toLowerCase().includes('dormant')) {
            return (
                <div>
                    <Button
                        onClick={() => {
                            setShowAccountOperationStatusInfo(!showAccountOperationStatusInfo);
                            setShowAccountOperationStatus('dormant');
                        }}
                        secondary
                        extClasses='kob-wrapper__cta'
                        label='View Details'
                    />
                </div>
            );
        }
        if (value.toLowerCase().includes('inactive')) {
            return (
                <div>
                    <Button
                        onClick={() => {
                            setShowAccountOperationStatusInfo(!showAccountOperationStatusInfo);
                            setShowAccountOperationStatus('inactive');
                        }}
                        secondary
                        extClasses='kob-wrapper__cta'
                        label='View Details'
                    />
                </div>
            );
        }

        return null;
    };

    const refreshBalanceCTAHandler = () => {
        const forceBalanceRefresh = true;

        dispatch(getProfileInfo({
            authType: currentTabData?.type,
            authValue: currentTabData?.value,
            authSubValue: currentTabData?.subValue,
            userId,
            index,
            forceBalanceRefresh,
        }));
    };

    return (
        <React.Fragment>
            <div className='kob profile'>
                <div className='frcfeWrapper'>
                    <Button
                        v2
                        secondary
                        onClick={copyDataClickHandler}
                        label='Copy Data'
                    />
                </div>
                <div id={KycContentId}>
                    {
                        hasOnboardingInfo ? (
                            Array.isArray(userFiOnboardingInfo) && userFiOnboardingInfo.map((item) => (
                                <div key={item.label} className='kob-wrapper'>
                                    <div className='kob-wrapper__label'>{item.label}</div>
                                    <div className='kob-wrapper__value'>{item.data || '-'}</div>

                                </div>
                            ))
                        ) : null
                    }
                    {
                        hasOnboardingInfo ? (
                            renderInAppScreeningInfo(onboardingInfo)
                                .map((item) => (
                                    <div key={item.label} className='kob-wrapper'>
                                        <div className='kob-wrapper__label'>{item.label}</div>
                                        <div className='kob-wrapper__value'>{item.data || '-'}</div>
                                        {
                                            !onboardingInfo.hideInAppScreenerDetailsCTA ? (
                                                <Button
                                                    secondary
                                                    extClasses='kob-wrapper__cta'
                                                    label='View Details'
                                                    onClick={() => setInAppScreeningInfo(true)}
                                                />
                                            ) : null
                                        }
                                    </div>
                                ))
                        ) : null
                    }
                    {
                        hasKycInfo ? (
                            renderKycInfo(kycInfo)
                                .map((item) => (
                                    <div key={item.label} className='kob-wrapper'>
                                        <div className='kob-wrapper__label'>{item.label}</div>
                                        <div className='kob-wrapper__value'>{item.data || '-'}</div>
                                        {
                                            item.cta ? (
                                                <Button
                                                    secondary
                                                    extClasses='kob-wrapper__cta'
                                                    label='View Details'
                                                    onClick={() => setKycInfo(true)}
                                                />
                                            ) : null
                                        }
                                    </div>
                                ))
                        ) : null
                    }
                    {
                        hasVKycInfo && vkycInfo.summaryInfo && renderVKycSummaryInfo(vkycInfo.summaryInfo)
                            .map((item) => (
                                <div key={item.label} className='kob-wrapper'>
                                    <div className='kob-wrapper__label'>{item.label}</div>
                                    <div className='kob-wrapper__value'>{item.data || '-'}</div>
                                    {
                                        item.cta ? (
                                            <React.Fragment>
                                                <Button
                                                    secondary
                                                    extClasses='kob-wrapper__cta'
                                                    label='View Summary'
                                                    onClick={() => setIsVKycModalOpen(true)}
                                                />
                                                <div className='link kob-wrapper__cta' onClick={() => setVKycAttemptInfo(true)} role='presentation'>
                                                    View Older Details
                                                </div>
                                            </React.Fragment>
                                        ) : null
                                    }
                                </div>
                            ))
                    }
                    {
                        hasOnboardingInfo ? (
                            renderOnboardingInfo(onboardingInfo)
                                .map((item) => (
                                    <div key={item.label} className='kob-wrapper'>
                                        <div className='kob-wrapper__label'>{item.label}</div>
                                        <div className='kob-wrapper__value'>{item.data || '-'}</div>
                                        <Button
                                            secondary
                                            extClasses='kob-wrapper__cta'
                                            label='View Details'
                                            onClick={() => setOnboardingInfo(true)}
                                        />
                                        {
                                            hasReoobeInfo ? (
                                                <Button
                                                    secondary
                                                    extClasses='kob-wrapper__cta'
                                                    label='View Re-onboarding Details'
                                                    onClick={() => setReOnboardingInfo(true)}
                                                />
                                            ) : null
                                        }
                                    </div>
                                ))
                        ) : null
                    }
                </div>
                {

                    hasKycInfo ? (
                        <div className='profile-sec'>
                            <h4 className='profile-sec__label'>KYC Status</h4>
                            {
                                renderKycStatus(kycInfo)
                                    .map((item) => (
                                        <div key={item.label} className='kob-wrapper'>
                                            <div className='kob-wrapper__label'>{item?.label}</div>
                                            <div className='kob-wrapper__value'>{item?.data || '-'}</div>
                                            {
                                                item?.cta ? (
                                                    <Button
                                                        secondary
                                                        extClasses='kob-wrapper__cta'
                                                        label='View Details'
                                                        onClick={() => setShowReKycDetails(true)}
                                                    />
                                                ) : null
                                            }
                                        </div>
                                    ))
                            }
                        </div>
                    ) : null
                }
                <div className='profile-sec'>
                    <h4 className='profile-sec__label'>Savings Account Details</h4>
                    {
                        Array.isArray(accountInfo) && accountInfo.map((item) => {
                            // if item data is empty and hideIfNoValue flag is true, do not render the item
                            if (isNilOrEmpty(item.data) && item.hideIfNoValue) return null;

                            return (
                                <div key={item.label} className='profile-wrapper'>
                                    <div className='profile-wrapper__label'>{item.label}</div>
                                    <div className={`profile-wrapper__value ${item.hideTextTransform ? 'profile-wrapper__value--v1' : ''}`}>
                                        {item.data || '-'}
                                    </div>
                                </div>
                            );
                        })
                    }
                    {
                        labelValues && Array.isArray(labelValues) && labelValues.map((labelValue) => (
                            <div key={labelValue.label} className='profile-wrapper'>
                                <div className='profile-wrapper__label'>{labelValue.label}</div>
                                <div className='profile-wrapper__value profile-wrapper__value--v1'>
                                    {labelValue.value || '-'}
                                </div>
                                {extraOptionsForLabelValues(labelValue.label, labelValue.value)}
                            </div>
                        ))
                    }
                    <div className='profile-wrapper'>
                        <div className='profile-wrapper__label'>Freeze Status</div>
                        <div className='profile-wrapper__value profile-wrapper__value--v1'>
                            {freezeStatus || '-'}
                        </div>
                    </div>
                    <div className='profile-wrapper'>
                        <div className='profile-wrapper__label'>
                            <Button
                                primary
                                label='Refresh Balance'
                                onClick={refreshBalanceCTAHandler}
                            />
                        </div>
                        <div className='profile-wrapper__value profile-wrapper__value--v1'>
                            Last successful refresh balance action : {profileInfo?.balanceRefreshInfo?.lastUpdatedTime || '-'}
                        </div>
                    </div>
                </div>
                <div className='profile-sec'>
                    <h4 className='profile-sec__label'>Address Details</h4>
                    {
                        Array.isArray(addressInfo) && addressInfo.map((item) => (
                            <div key={item.label} className='profile-wrapper'>
                                <div className='profile-wrapper__label'>{item.label}</div>
                                <div>
                                    <div>
                                        Pincode -&nbsp;
                                        <span className='profile-wrapper__value'>{item.pincode || '-'}</span>
                                    </div>
                                    <div>
                                        Address -&nbsp;
                                        <span className='profile-wrapper__value'>{item.address || '-'}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {
                    hasProfileInfo && accountClosureRequestSection && (
                        <div className='profile-sec'>
                            <h4 className='profile-sec__label'>{ accountClosureRequestSection?.title}</h4>
                            {
                                accountClosureRequestSection?.labelValues
                                && Array.isArray(accountClosureRequestSection?.labelValues)
                                && accountClosureRequestSection?.labelValues.map((item) => (
                                    <div key={item.label} className='profile-wrapper'>
                                        <div className='profile-wrapper__label'>{item?.label}</div>
                                        {
                                            item?.type === LABEL_VALUE_DATA_TYPE.table ? (
                                                <Button
                                                    secondary
                                                    extClasses='profile-wrapper__value'
                                                    label='View Details'
                                                    onClick={() => setACRModalData(item?.value)}
                                                />
                                            ) : (
                                                <div className='profile-wrapper__value'>{item?.value || '-'}</div>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                {
                    !hasProfileInfo && profileError ? (
                        <div className='profile-err'>{`* ${profileError}`}</div>
                    ) : null
                }
            </div>
            <VKycModal
                visible={isVKycModalOpen}
                data={{ summaryInfo, troubleshootingList }}
                error={errorVKycInfo}
                toggleHandler={setIsVKycModalOpen}
            />
            <TableModal
                visible={!!ACRModalData}
                data={ACRModalData}
                error={profileError}
                toggleHandler={setACRModalData}
            />
            <Loader visible={loadingKycInfo || loadingOnboardingInfo || loadingVKycInfo || loadingProfileInfo} />
        </React.Fragment>
    );
};

export default KycOnboarding;

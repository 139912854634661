/**
 * @file UploadCSV - Component to verify the bulk account details by uploading csv in specified format
 */

import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import backArrow from '../../../assets/images/svgs/back-arrow.svg';
import { Button, FileInput, Loader } from '../../../components';
import { useMemoizedSelector } from '../../../hooks';
import { isNilOrEmpty } from '../../../utils';

import { setUploadCSVFormParamsAction } from '../actions';
import { selectUploadCSVFormParameters, selectLoading, selectSuccessMsg, selectError } from '../selectors';

import './style.scss';

const UploadCSV = (props) => {
    const { data } = props;
    const {
        inputLabel, onSubmitAction, containerKey, sampleCsvLink, downloadedFileName,
    } = data;

    const dispatch = useDispatch();

    // reducer state variables
    const uploadCSVFormParameters = useMemoizedSelector(selectUploadCSVFormParameters, [containerKey]);
    const loading = useMemoizedSelector(selectLoading, [containerKey]);
    const successMsg = useMemoizedSelector(selectSuccessMsg, [containerKey]);
    const errorMsg = useMemoizedSelector(selectError, [containerKey]);

    // local state variables
    const [errLabel, setErrLabel] = useState('');
    const [showSuccessScreen, setSuccessScreen] = useState(false);

    const { csvFile } = uploadCSVFormParameters;

    const resetCSVInputFile = () => {
        const file = document.getElementById('sai-upload-csv');
        file.value = '';
    };

    const onFileClick = () => {
        resetCSVInputFile();
        // remove the previously selected file
        dispatch(setUploadCSVFormParamsAction({ containerKey, key: 'csvFile', value: null }));
    };

    const onFileChange = (value) => {
        dispatch(setUploadCSVFormParamsAction({ containerKey, key: 'csvFile', value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // check if all the fields are filled
        if (!csvFile) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        setErrLabel('');

        const onSubmitActionPromise = () => new Promise((resolve, reject) => {
            try {
                dispatch(onSubmitAction({ containerKey, csvFile, resolve }));
            } catch (error) {
                reject(error);
            }
        });

        onSubmitActionPromise().then(() => {
            dispatch(setUploadCSVFormParamsAction({ containerKey, key: 'csvFile', value: null }));
            resetCSVInputFile();
            setSuccessScreen(true);
        });
    };

    if (isNilOrEmpty(data)) return null;

    if (showSuccessScreen) {
        return (
            <div className='sai-upload-csv'>
                <div className='sai-upload-csv-success-screen'>
                    <button
                        type='button'
                        onClick={() => setSuccessScreen(false)}
                    >
                        <img
                            alt='back-arrow'
                            className='sai-upload-csv-success-screen__backarrow'
                            src={backArrow}
                        />
                    </button>
                    <div className='sai-upload-csv-success-screen__hd'>Request Submitted</div>
                </div>
                <div className='sai-upload-csv-success-screen__desc'>
                    {successMsg}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className='sai-upload-csv'>
                <div className='frcWrapper sai-upload-csv-fc'>
                    <div className='sai-upload-csv-fc__lb'>
                        {inputLabel}*
                    </div>
                    <div className='fcWrapper'>
                        <FileInput
                            id='sai-upload-csv'
                            className='sai-upload-csv-fc__ip'
                            accept='.csv'
                            onClick={onFileClick}
                            onChange={onFileChange}
                        />
                        <a
                            className='link sai-upload-csv-sample-link'
                            href={sampleCsvLink}
                            download={downloadedFileName}
                        >
                            Download Sample Format
                        </a>
                    </div>
                </div>
                <div className='err-label sai-upload-csv-el'>{errLabel}</div>
                <Button
                    v2
                    primary
                    label='Submit'
                    onClick={handleSubmit}
                    extClasses='sai-upload-csv-cta'
                />
            </div>
            {errorMsg ? <div className='error-label1 mt-30'>{errorMsg}</div> : null}
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(UploadCSV);

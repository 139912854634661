/* eslint-disable object-property-newline */

/*
 *
 * WealthOps utils
 *
 */
import {
    DOCUMENT_EXPIRY_DEV_ACTION_TYPES, DOCUMENT_REDACTION_DEV_ACTION_TYPES,
} from './constants';

/**
 * Get the item types that need to be reviewed in the current case
 * Between AADHAAR_REDACTION & DOCUMENT_EXPIRY only 1 will be present for a single case
 * Hence change the 2nd card information based on the type of review required
 */
export const getWealthData = (curCase) => {
    const itemTypes = curCase.wealth_data.item_types;
    const isDocRedactionReview = itemTypes.includes('ITEM_TYPE_AADHAAR_REDACTION');
    const reviewType = isDocRedactionReview ? 'Redaction' : 'Expiry';
    const docReviewCardHeading = `Document ${reviewType} Review`;
    const devActionOptions = isDocRedactionReview ? DOCUMENT_REDACTION_DEV_ACTION_TYPES : DOCUMENT_EXPIRY_DEV_ACTION_TYPES;
    const actorId = curCase.wealth_data.actor_id;

    return {
        isDocRedactionReview, reviewType, docReviewCardHeading, devActionOptions, actorId,
    };
};

export const getActorId = (curCase) => {
    if (curCase.wealth_data) return curCase.wealth_data.actor_id;

    return curCase.kyc_docket_data.actor_id;
};

export const getLivenessReviewData = (curCase) => {
    const { liveness_data: livenessData, actor_id: actorId } = curCase.wealth_data;

    if (livenessData) {
        const { manual_review_id: manualReviewId, request_id: requestId } = livenessData;

        return {
            actorId, manualReviewId, requestId, hasData: true,
        };
    }

    return {
        actorId: '', manualReviewId: '', requestId: '', hasData: false,
    };
};

export const getDocumentReviewData = (curCase) => {
    const { ocr_data: ocrData } = curCase.wealth_data;

    if (ocrData) {
        const { manual_review_id: manualReviewId } = ocrData;
        const {
            threshold_score: thresholdScore, confidence_score: confidenceScore, vendor_review_flag: boolVendorReviewFlag,
        } = ocrData.processed_document;
        const vendorReviewFlag = boolVendorReviewFlag.toString();
        const { photo: ogDocList } = ocrData.original_document;
        const { photo: processedDocList } = ocrData.processed_document.document_proof;
        let { expiry } = ocrData.processed_document.document_proof;

        if (!expiry) expiry = {};

        const { year = '', month = '', day = '' } = expiry;

        return {
            manualReviewId, thresholdScore, confidenceScore, vendorReviewFlag, year, month, day, processedDocList, ogDocList, hasData: true,
        };
    }

    return {
        manualReviewId: '', thresholdScore: '', confidenceScore: '', vendorReviewFlag: '', year: '', month: '', day: '',
        processedDocList: [], ogDocList: [], hasData: false,
    };
};

export const getLivenessPreFilledValuesObj = (curCase) => {
    const { manualReviewId } = getLivenessReviewData(curCase);

    return {
        mergeKey: 'label',
        paramList: [
            { label: 'Review Id', value: manualReviewId },
        ],
    };
};

export const getDocumentPreFilledValuesObj = (curCase) => {
    const { manualReviewId } = getDocumentReviewData(curCase);

    return {
        mergeKey: 'label',
        paramList: [
            { label: 'Review Id', value: manualReviewId },
        ],
    };
};

/**
 * The wealth ops queue can be paginated. Later support can be added for different filters.
 * This function creates the required object to be sent to the API for filtering
 * @param {number} limit The limit for fetching the maximum number of cases in 1 API call
 * @returns {object} An object containing the filter values
 */
export const getAdditionalFilters = (limit, kycFilterType, kycFilterValue) => {
    const additionalFilters = {};

    // attach limit for getting number of cases in a 1 API call
    if (limit) additionalFilters.limit = limit;

    // attach filters for kyc docket payload type where user can filter using KRA status, PAN number, etc.
    if (kycFilterType && kycFilterValue) {
        additionalFilters.kycFilters = {
            type: kycFilterType,
            value: kycFilterValue,
        };
    }

    return additionalFilters;
};

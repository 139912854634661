/*
 *
 * Wealth Investment Reducer
 *
 */

import { fromJS } from 'immutable';

import {
    GET_ONBOARDING_INFO, SET_ONBOARDING_INFO, ERR_ONBOARDING_INFO,
    SET_INV_READINESS_STATUS, ERR_INV_READINESS_STATUS,
} from './constants';

const initialState = fromJS({
    onboarding: {},
});

function wealthInvestmentReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ONBOARDING_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['onboarding', ticketId, 'loading'], true)
                .setIn(['onboarding', ticketId, 'err'], '');
        }

        case SET_ONBOARDING_INFO: {
            const { ticketId, onboardingInfo } = action.data;

            return state
                .setIn(['onboarding', ticketId, 'loading'], false)
                .setIn(['onboarding', ticketId, 'hasOnboardingInfo'], true)
                .setIn(['onboarding', ticketId, 'onboardingInfo'], fromJS(onboardingInfo));
        }

        case ERR_ONBOARDING_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['onboarding', ticketId, 'loading'], false)
                .setIn(['onboarding', ticketId, 'hasOnboardingInfo'], false)
                .setIn(['onboarding', ticketId, 'onboardingInfo'], fromJS({}))
                .setIn(['onboarding', ticketId, 'err'], err);
        }

        case SET_INV_READINESS_STATUS: {
            const { ticketId, investmentInfo } = action.data;

            return state
                .setIn(['onboarding', ticketId, 'hasInvestmentInfo'], true)
                .setIn(['onboarding', ticketId, 'investmentInfo'], fromJS(investmentInfo))
                .setIn(['onboarding', ticketId, 'errInLoadingInvestmentInfo'], '');
        }

        case ERR_INV_READINESS_STATUS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['onboarding', ticketId, 'hasInvestmentInfo'], false)
                .setIn(['onboarding', ticketId, 'investmentInfo'], fromJS({}))
                .setIn(['onboarding', ticketId, 'errInLoadingInvestmentInfo'], err);
        }

        default:
            return state;
    }
}

export default wealthInvestmentReducer;

/**
 * @file Component to show the Older Credit Card Details.
 */

import React, { useState, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';

import { Table, Toggle, Loader } from '../../../components';
import { useMemoizedSelector } from '../../../hooks';

import { getAllCreditCardDetailsAction } from '../actions';
import { selectAllCreditCardDetails } from '../selectors';
import { CURRENT_CREDIT_CARD_DETAILS_LABEL_DATA } from '../constants';

const OlderCards = (props) => {
    const { ticketId, userId } = props;

    const dispatch = useDispatch();

    const allCreditCardDetails = useMemoizedSelector(selectAllCreditCardDetails, [ticketId]);

    const [showOlderCards, setShowOlderCards] = useState(false);

    const { loading, err: errInAllCardDetails, data: allCardDetails } = allCreditCardDetails || {};

    const toggleOlderCardsValue = () => {
        setShowOlderCards(!showOlderCards);
    };

    useEffect(() => {
        if (showOlderCards) {
            dispatch(getAllCreditCardDetailsAction({ ticketId, userId }));
        }
    }, [showOlderCards]);

    return (
        <React.Fragment>
            <div className='mt-30 mb-30'>
                <Toggle label='Older Cards' value={showOlderCards} onChange={toggleOlderCardsValue} />
            </div>

            {showOlderCards ? (
                <Table
                    header=''
                    labelData={CURRENT_CREDIT_CARD_DETAILS_LABEL_DATA}
                    contentData={allCardDetails}
                    errLabel={errInAllCardDetails}
                />
            ) : null}

            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(OlderCards);

export const getSmsVendorInfo = (data) => [
    {
        label: 'Created At',
        value: data.createdTs
    },
    {
        label: 'Updated At',
        value: data.updatedTs
    },
    {
        label: 'Sent At',
        value: data.sentTs
    },
    {
        label: 'Failure Reason',
        value: data.status
    },
];

/**
 * @file Manages the action functions for Agent Management component
 */

import {
    GET_ACCESS_LEVEL_LIST, GET_AGENT_TYPE_LIST, GET_AGENT_STATUS_LIST, GET_AGENT_LIST, SET_AGENT_INFO,
    SET_AGENT_FORM_FIELDS, RESET_AGENT_FORM_FIELDS, CREATE_AGENT, UPDATE_AGENT,
} from './constants';

export const getAccessLevelList = () => ({
    type: GET_ACCESS_LEVEL_LIST,
});

export const getAgentTypeList = () => ({
    type: GET_AGENT_TYPE_LIST,
});

export const getAgentStatusList = () => ({
    type: GET_AGENT_STATUS_LIST,
});

export const getAgentList = (data) => ({
    type: GET_AGENT_LIST,
    data,
});

export const setAgentInfo = (data) => ({
    type: SET_AGENT_INFO,
    data,
});

export const setAgentFormFields = (data) => ({
    type: SET_AGENT_FORM_FIELDS,
    data,
});

export const resetAgentFormFields = () => ({
    type: RESET_AGENT_FORM_FIELDS,
});

export const createAgent = (data) => ({
    type: CREATE_AGENT,
    data,
});

export const updateAgent = (data) => ({
    type: UPDATE_AGENT,
    data,
});

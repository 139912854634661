/**
 * @file Component to create the new banner & update the existing banner info
 */

import React, { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { equals } from 'ramda';

import { Button, FormDropdown, FormWithSingleInput, Loader } from '../../../components';
import { toastify, validators, isNilOrEmpty } from '../../../utils';
import backArrow from '../../../assets/images/svgs/back-arrow.svg';

import { BANNERS_PATH } from '../../App/routes';
import { makeSelectAccessLevelList } from '../../App/selectors';

import { setBannerFormFields, createBanner, updateBanner } from '../actions';
import {
    makeSelectBannerFormFields, makeSelectBannerInfo, makeSelectCreateOrUpdateBannerInfo,
} from '../selectors';
import { BANNER_FORM_FIELDS } from '../constants';

import './style.scss';

const CreateOrUpdateBanner = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    // reducer state variables
    const rolesList = useSelector(makeSelectAccessLevelList, shallowEqual);
    const bannerFormFields = useSelector(makeSelectBannerFormFields, shallowEqual);
    const bannerInfo = useSelector(makeSelectBannerInfo, shallowEqual);
    const createOrUpdateBannerInfo = useSelector(makeSelectCreateOrUpdateBannerInfo, shallowEqual);

    // local state variables
    const [formErrLabel, setFormErrLabel] = useState({});

    // passed props
    const { isEdit } = props;

    const {
        title, body, startTime, endTime, roles,
    } = bannerFormFields;

    const handleInputChange = (key) => (e) => {
        dispatch(setBannerFormFields({
            key,
            value: e.target.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // check if banner title is inputted
        if (!title) {
            setFormErrLabel({ title: 'Please enter a banner title' });
            return;
        }

        // check if banner body is inputted
        if (!body) {
            setFormErrLabel({ body: 'Please enter a banner body' });
            return;
        }

        // check if start time is inputted & valid
        if (!validators.isInputtedTimeStrValid(startTime)) {
            setFormErrLabel({ startTime: 'Please enter a valid start time' });
            return;
        }

        // check if end time is inputted & valid
        if (!validators.isInputtedTimeStrValid(endTime)) {
            setFormErrLabel({ endTime: 'Please enter a valid end time' });
            return;
        }

        // check if roles is selected
        if (isNilOrEmpty(roles)) {
            setFormErrLabel({ roles: 'Please select roles' });
            return;
        }

        setFormErrLabel({});

        if (isEdit) {
            const updatedFields = [];

            // if the updated & existing info are not same, push the key name into the updatedFields array
            Object.keys(bannerFormFields).forEach(key => {
                if (!equals(bannerFormFields[key], bannerInfo[key])) {
                    updatedFields.push(key);
                }
            });
            
            if (updatedFields.length === 0) {
                toastify('You need to update any field before proceeding', 'error');

                return;
            }

            dispatch(updateBanner({ ...bannerFormFields, updatedFields }));
        } else {
            dispatch(createBanner({ ...bannerFormFields }));
        }
    };

    const renderErrorField = (key) => (
        <div className='create-banner-fc__errlb'>{formErrLabel[key]}</div>
    );

    const renderFormInput = (key) => {
        let label;
        let labelId;
        let description;
        let placeholder;
        let value;
        let formType;
        let onInputChange;

        switch (key) {
            case BANNER_FORM_FIELDS.TITLE: {
                label = 'Banner Title';
                labelId = 'banner-title';
                placeholder = 'enter banner title';
                formType = 'textarea';
                onInputChange = handleInputChange(key);
                value = title;

                break;
            }

            case BANNER_FORM_FIELDS.BODY: {
                label = 'Banner Body';
                labelId = 'banner-body';
                placeholder = 'enter banner body';
                formType = 'textarea';
                onInputChange = handleInputChange(key);
                value = body;

                break;
            }

            case BANNER_FORM_FIELDS.START_TIME: {
                label = 'Start Time';
                labelId = 'banner-start-time';
                placeholder = 'enter start time in this format: YYYY-MM-DDTHH:MM:SS';
                description = 'eg: for 18th July 2022 5:30 am IST, the format is 2022-07-18T05:30:00';
                onInputChange = handleInputChange(key);
                value = startTime;

                break;
            }

            case BANNER_FORM_FIELDS.END_TIME: {
                label = 'End Time';
                labelId = 'banner-end-time';
                placeholder = 'enter end time in this format: YYYY-MM-DDTHH:MM:SS';
                description = 'eg: for 31st July 2022 3:30 pm IST, the format is 2022-07-31T15:30:00';
                onInputChange = handleInputChange(key);
                value = endTime;

                break;
            }

            default:
        }

        return (
            <React.Fragment>
                <FormWithSingleInput
                    onFormSubmit={handleSubmit}
                    extClasses={{
                        container: 'create-banner-fc',
                        label: 'create-banner-fc__lb',
                        inputField: 'create-banner-fc__ip',
                        nameInputField: 'create-banner-fc__ip-name',
                        description: 'create-banner-fc__desc',
                    }}
                    label={label}
                    labelId={labelId}
                    description={description}
                    formType={formType}
                    input={{
                        value,
                        onChange: onInputChange,
                        placeholder,
                    }}
                />
                {renderErrorField(key)}
            </React.Fragment>
        );
    };

    const renderFormMultiSelectDD = (key) => {
        let label;
        let placeholder;
        let value;
        let options;
        let isMultiSelectDD;

        switch (key) {
            case BANNER_FORM_FIELDS.ROLES: {
                label = 'Roles';
                placeholder = 'select multiple roles if required';
                value = roles;
                options = rolesList;
                isMultiSelectDD = true;

                break;
            }

            default:
                label = '';
                options = [];
        }

        return (
            <React.Fragment>
                <div className='create-banner-fc'>
                    <div className='create-banner-fc__lb'>{label}</div>
                    <FormDropdown
                        isMulti={isMultiSelectDD}
                        options={options}
                        input={{
                            value,
                            onChange: (value) => {
                                dispatch(setBannerFormFields({ key, value }));
                            },
                            placeholder,
                        }}
                        cacheKey='banner-form-accesslevel'
                        extStyles={{
                            container: 'create-banner-fc__ip',
                        }}
                    />
                </div>
                {renderErrorField(key)}
            </React.Fragment>
        );
    };

    const resetBannerFormFields = () => {
        dispatch(setBannerFormFields({}));
        history.push(BANNERS_PATH);
    };

    const label = isEdit ? 'Update Banner' : 'Create Banner';

    return (
        <React.Fragment>
            <div className='create-banner-cr'>
                <div className='create-banner-hwr'>
                    <img
                        className='create-banner-hwr__backarrow'
                        src={backArrow}
                        onClick={resetBannerFormFields}
                    />
                    <div className='create-banner-hwr__hd'>{label}</div>
                </div>
                <div className='fcWrapper'>
                    {renderFormInput(BANNER_FORM_FIELDS.TITLE)}
                    {renderFormInput(BANNER_FORM_FIELDS.BODY)}
                    {renderFormInput(BANNER_FORM_FIELDS.START_TIME)}
                    {renderFormInput(BANNER_FORM_FIELDS.END_TIME)}
                    {renderFormMultiSelectDD(BANNER_FORM_FIELDS.ROLES)}
                    <Button
                        v2
                        primary
                        label='submit'
                        onClick={handleSubmit}
                    />
                </div>
            </div>
            <Loader visible={createOrUpdateBannerInfo.loading} />
        </React.Fragment>
    );
};

export default memo(CreateOrUpdateBanner);

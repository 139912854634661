/**
 * @file Reducer for US Stocks flow
 */

import { fromJS } from 'immutable';

import {
    ERR_ADDITIONAL_ACTIONS, ERR_FILE_ENTRIES, ERR_US_STOCKS_NAV_BAR_ENTITIES, GET_ADDITIONAL_ACTIONS, GET_FILE_ENTRIES, GET_NAV_BAR_ENTITIES,
    SET_ADDITIONAL_ACTIONS, SET_FILE_ENTRIES, SET_NAV_BAR_ENTITIES, SET_US_STOCKS_SCREEN_ACTION_DATA, SET_US_STOCKS_VIEW_DATA,
    GET_US_STOCKS_TABLE_FILTERS, SET_US_STOCKS_TABLE_FILTERS, ERR_US_STOCKS_TABLE_FILTERS, SET_US_STOCKS_FILE_TYPE, GET_US_STOCKS_GENERATE_FILE,
    SET_US_STOCKS_GENERATE_FILE, ERR_US_STOCKS_GENERATE_FILE, GET_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY, ERR_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY,
    US_STOCK_SCREENS, SET_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY,
} from './constants';

const initialState = fromJS({
    // API state
    navBar: {
        entities: [],
        defaultEntity: {},
        hasNavBarInfo: false,
    },
    fileEntries: {},
    additionalActions: [],
    parameterList: [],
    generatedFileData: [],
    // UI state
    loading: false,
    err: '',
    fileType: {},
    curView: US_STOCK_SCREENS.FILE_ENTRIES,
    screenActionData: {},
});

function usStocksReducer(state = initialState, action = {}) {
    switch (action.type) {
        // API actions
        case GET_NAV_BAR_ENTITIES:
        case GET_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY: {
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '');
        }

        case SET_NAV_BAR_ENTITIES: {
            const { entities, defaultEntity } = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['navBar', 'hasNavBarInfo'], true)
                .setIn(['navBar', 'entities'], fromJS(entities))
                .setIn(['navBar', 'defaultEntity'], fromJS(defaultEntity));
        }

        case SET_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY: {
            return state
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }

        case ERR_US_STOCKS_NAV_BAR_ENTITIES:
        case ERR_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY: {
            const { err } = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['err'], err);
        }

        case GET_FILE_ENTRIES: {
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '');
        }

        case SET_FILE_ENTRIES: {
            const { fileEntries } = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['fileEntries'], fromJS(fileEntries));
        }

        case ERR_FILE_ENTRIES: {
            const { err } = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['err'], err)
                .setIn(['fileEntries'], fromJS({}));
        }

        case GET_ADDITIONAL_ACTIONS: {
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '');
        }

        case SET_ADDITIONAL_ACTIONS: {
            const { additionalActions } = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['additionalActions'], fromJS(additionalActions));
        }

        case ERR_ADDITIONAL_ACTIONS: {
            const { err } = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['err'], err)
                .setIn(['additionalActions'], fromJS([]));
        }

        case GET_US_STOCKS_TABLE_FILTERS: {
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '');
        }

        case SET_US_STOCKS_TABLE_FILTERS: {
            const { parameterList } = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['parameterList'], fromJS(parameterList));
        }

        case ERR_US_STOCKS_TABLE_FILTERS: {
            const { err } = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['err'], err)
                .setIn(['parameterList'], fromJS([]));
        }

        case GET_US_STOCKS_GENERATE_FILE: {
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '')
                .setIn(['generatedFileData'], fromJS([]));
        }

        case SET_US_STOCKS_GENERATE_FILE: {
            const { generatedFileData } = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['generatedFileData'], fromJS(generatedFileData));
        }

        case ERR_US_STOCKS_GENERATE_FILE: {
            const { err } = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['err'], err)
                .setIn(['generatedFileData'], fromJS([]));
        }

        // UI actions
        case SET_US_STOCKS_VIEW_DATA: {
            const { curView } = action.data;

            return state
                .setIn(['curView'], curView);
        }

        case SET_US_STOCKS_SCREEN_ACTION_DATA: {
            const { screenActionData } = action.data;

            return state
                .setIn(['screenActionData'], fromJS(screenActionData));
        }

        case SET_US_STOCKS_FILE_TYPE: {
            const { fileId } = action.data;

            const { navBar: { entities } } = state.toJS();

            // Find the file object using the fileId from the URL path
            let fileType = entities.find((fileItem) => {
                // If the nav item has nested links, check that first since the parent will not have required information
                if (fileItem.nestedLinks) {
                    return fileItem.nestedLinks.find((nestedFileItem) => nestedFileItem.pathId === fileId);
                }

                // If no nested links, check parent
                return fileItem.pathId === fileId;
            });

            // Previous file type will return the object which has nested links. Find the child which matches the current fileId
            if (fileType && fileType.nestedLinks) {
                fileType = fileType.nestedLinks.find((nestedFileItem) => nestedFileItem.pathId === fileId);
            }

            /**
             * if no fileType is found that means default file type in not present in entity list
             * so set the file type as the value at 0 index
             */
            if (!fileType) fileType = entities[0] || {};

            return state
                .setIn(['fileType'], fileType);
        }

        default:
            return state;
    }
}

export default usStocksReducer;

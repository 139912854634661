/**
 * @file LoaderError
 * Common component that renders the loader and error for a particular risk ops queue
 */

import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedSelector } from '../../../hooks';
import { Loader } from '../../../components';

import { getRiskOpsCasesAction, getRiskOpsPendingReviewCountAction } from '../actions';
import { getAdditionalFilters } from '../utils';
import {
    selectRiskOpsErr, selectRiskOpsLoading, selectRiskOpsPayloadType, selectRiskOpsQueueFilters,
} from '../selectors';

const LoaderError = (props) => {
    const { containerKey } = props;
    const dispatch = useDispatch();

    // global state
    const loading = useMemoizedSelector(selectRiskOpsLoading, [containerKey]);
    const err = useMemoizedSelector(selectRiskOpsErr, [containerKey]);
    const payloadType = useMemoizedSelector(selectRiskOpsPayloadType, [containerKey]);
    const { fromDate, toDate, sortOption } = useMemoizedSelector(selectRiskOpsQueueFilters, [containerKey]);

    // Get pending review count if payload type is changed in following states:
    useEffect(() => {
        dispatch(getRiskOpsPendingReviewCountAction({ containerKey, queryParams: { payloadType: payloadType.value } }));
    }, [payloadType.value, dispatch]);

    /**
     * Get cases again if any of the filter changes
     * When a dequeue action happens, then we need to call with the current payload type from saga
     */
    useEffect(() => {
        if ((fromDate.value && toDate.value) || (!fromDate.value && !toDate.value)) {
            dispatch(getRiskOpsCasesAction({
                queryParams: {
                    payloadType: payloadType.value,
                    ...getAdditionalFilters(fromDate, toDate, sortOption),
                },
                containerKey,
            }));
        }
    }, [fromDate.value, toDate.value, payloadType.value, sortOption.value]);

    return (
        <React.Fragment>
            <div className='rops-cr'>
                {err && <div className='rops-crel err-label'>{err}</div>}
            </div>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(LoaderError);

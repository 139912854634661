/**
 * @file Action constants which are used at root level
 * Need to keep them here and not in the App/constants.js to avoid cyclic dependency
 */

export const SET_ACCESS_LEVEL = 'app/App/SET_ACCESS_LEVEL';

export const RESET_AGENT_INFO = 'app/App/RESET_AGENT_INFO';

export const SET_LOGIN_URL = 'app/App/SET_LOGIN_URL';

export const GET_AGENT_INFO = 'app/App/GET_AGENT_INFO';

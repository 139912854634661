/**
 *
 * VKycAttemptCallInfoModal
 *
 */

import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button } from '../../../components';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '15%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '40px 20px',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 1000,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const VKycAttemptCallInfo = (props) => {
    const { visible, data, toggleHandler } = props;

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='Call Info Details Modal'
        >
            <div className='kob-modal-hwr kob-modal-hwr--kyc'>Call Info Details</div>
            <div className='table-sr'>
                <div className='table-sr__thw'>
                    <div className='table-sr__th f15Wrapper'>Call Info</div>
                    <div className='table-sr__th'>Call Schedule</div>
                </div>
                <div className='table-sr__thw'>
                    <div className='table-sr__th'>Type</div>
                    <div className='table-sr__th'>Status</div>
                    <div className='table-sr__th'>Substatus</div>
                    <div className='table-sr__th f15Wrapper'>Interpretation</div>
                    <div className='table-sr__th'>Created At</div>
                    <div className='table-sr__th'>Updated At</div>
                    <div className='table-sr__th'>Status</div>
                    <div className='table-sr__th'>Substatus</div>
                    <div className='table-sr__th'>Start At</div>
                    <div className='table-sr__th'>End At</div>
                </div>
                <div className='table-sr__tlc'>
                    {
                        data.map((item, idx) => (
                            <div className='table-sr__tlw kob-modal-tlw' key={idx}>
                                <div className='table-sr__tl table-sr__tl--nc'>{item.callInfo.type}</div>
                                <div className='table-sr__tl table-sr__tl--nc'>{item.callInfo.status}</div>
                                <div className='table-sr__tl table-sr__tl--nc'>{item.callInfo.subStatus}</div>
                                <div className='table-sr__tl table-sr__tl--nc f15Wrapper'>{item.callInfo.interpretation}</div>
                                <div className='table-sr__tl'>{item.callInfo.createdTs}</div>
                                <div className='table-sr__tl'>{item.callInfo.updatedTs}</div>
                                <div className='table-sr__tl table-sr__tl--nc'>{item.callScheduleInfo.status}</div>
                                <div className='table-sr__tl table-sr__tl--nc'>{item.callScheduleInfo.subStatus}</div>
                                <div className='table-sr__tl'>{item.callScheduleInfo.startTs}</div>
                                <div className='table-sr__tl'>{item.callScheduleInfo.endTs}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <Button
                primary
                extClasses='kob-modal-bwr'
                label='Close'
                onClick={() => toggleHandler(false)}
            />
        </Modal>
    );
};

export default memo(VKycAttemptCallInfo);

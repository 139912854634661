/**
 *
 * Referrals Landing Page
 *
 */

import React from 'react';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';

import SignupView from './SignupView';
import ReferralView from './ReferralView';

import reducer from './reducer';
import saga from './saga';

import './style.scss';

const Referrals = (props) => {
    const { ticketId, userId, index } = props;

    return (
        <div className='referrals'>
            <SignupView
                ticketId={ticketId}
                userId={userId}
                index={index}
            />
            <ReferralView
                ticketId={ticketId}
                userId={userId}
                index={index}
            />
        </div>
    );
};

const withReducer = injectReducer({ key: 'referrals', reducer });
const withSaga = injectSaga({ key: 'referrals', saga });

export default compose(
    withReducer,
    withSaga,
)(Referrals);

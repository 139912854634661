/**
 *
 * OTPAttempt
 *
 */

import React, { memo } from 'react';

import backArrow from '../../../assets/images/svgs/back-arrow.svg';

import './style.scss';

const OTPAttempt = props => {
    const { data, toggleHandler } = props;

    return (
        <div className='otpattmpt'>
            <div className='otpattmpt-hw'>
                <img className='otpattmpt-backarrow' src={backArrow} onClick={() => toggleHandler('')} />
                <div className='otpattmpt-hw__hd'>OTP Attempt Results</div>
            </div>
            <div className='table-sr'>
                <div className='table-sr__thw'>
                    <div className='table-sr__th'>Device{'\n'}Manufacturer</div>
                    <div className='table-sr__th'>Device{'\n'}Model</div>
                    <div className='table-sr__th'>Device{'\n'}SW</div>
                    <div className='table-sr__th'>Device{'\n'}OS API</div>
                    <div className='table-sr__th'># of times{'\n'}OTP input</div>
                    <div className='table-sr__th'># of times{'\n'}SMS sent</div>
                    <div className='table-sr__th'>SMS{'\n'}Sent At</div>
                    <div className='table-sr__th'>Created{'\n'}At</div>
                    <div className='table-sr__th'>Updated{'\n'}At</div>
                </div>
                <div className='table-sr__tlc'>
                    {
                        data.map((item) => (
                            <div className='table-sr__tlw table-sr__tlw--aic' key={item.id}>
                                <div className='table-sr__tl'>{item.device.manufacturer}</div>
                                <div className='table-sr__tl'>{item.device.model}</div>
                                <div className='table-sr__tl'>{item.device.sw_version}</div>
                                <div className='table-sr__tl'>{item.device.os_api_version}</div>
                                <div className='table-sr__tl'>{item.verifyAttempts}</div>
                                <div className='table-sr__tl'>{item.smsAttempts}</div>
                                <div className='table-sr__tl'>{item.smsTs}</div>
                                <div className='table-sr__tl'>{item.createdTs}</div>
                                <div className='table-sr__tl'>{item.updatedTs}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default memo(OTPAttempt);

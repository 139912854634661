import {
    call, put, takeLatest, all,
} from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import {
    TICKETS_ROOT, CUSTOMER_AUTH_ROOT, ACCOUNTS_ROOT, TICKET_LIST_FOR_CALL_ENDPOINT, CALL_LIST_ENDPOINT,
    CALL_INFO_ENDPOINT, CALL_CREATE_TICKET_ENDPOINT, SHERLOCK_FEEDBACK_ROOT, GET_SEARCH_SOP_ENDPOINT,
} from '../../api/routes';
import { DEFAULT_TICKET_PATH } from '../App/routes';

import {
    GET_CALL_LIST, SET_CALL_LIST, ERR_CALL_LIST, GET_TICKET_AND_USER_INFO_FOR_CALL,
    SET_TICKET_AND_USER_INFO_FOR_CALL, ERR_TICKET_AND_USER_INFO_FOR_CALL,
    GET_USER_INFO_FOR_CALL, SET_USER_INFO_FOR_CALL, ERR_USER_INFO_FOR_CALL,
    USER_CONFIRMATION_FOR_CALL, SET_USER_CONFIRMATION_FOR_CALL, ERR_USER_CONFIRMATION_FOR_CALL,
    GET_TICKET_LIST_FOR_CALL, SET_TICKET_LIST_FOR_CALL, ERR_TICKET_LIST_FOR_CALL,
    UPDATE_CALL_INFO, SET_UPDATED_CALL_INFO, ERR_UPDATED_CALL_INFO,
    CREATE_TICKET_FOR_CALL, SET_CREATED_TICKET_FOR_CALL, ERR_CREATED_TICKET_FOR_CALL,
    SUBMIT_TICKET_ID, SET_TICKET_INFO, ERR_TICKET_INFO, SET_USER_INFO, SUBMIT_USER_ID,
    GET_INITIAL_AUTH_FACTOR, SET_INITIAL_AUTH_INFO, VERIFY_AUTH_FACTOR,
    SET_AUTH_INFO_AFTER_VERIFICATION, ERR_AUTH_INFO, ASYNC_AUTH_FACTOR_PENDING, SENSITIVE_SCREENS,
    CALLBACK_USER, ERR_CALLBACK_USER, SET_CALLBACK_USER, SET_AUTH_EXPIRED_SCREEN,
    LESS_SENSITIVE_SCREENS, SET_ACTION_AUTH_INFO, ACTIONABLE_SCREENS, DISCARD_ACTION_AUTH_INFO,
    ERR_INITIAL_AUTH_INFO, SET_AUTH_INFO_LOADER, GET_WAITLIST_USER, SET_WAITLIST_USER,
    ERR_WAITLIST_USER, ATTACH_ENTITY_TICKET, SET_ATTACH_ENTITY_TICKET, ERR_ATTACH_ENTITY_TICKET,
    GET_OTP_ATTEMPTS, SET_OTP_ATTEMPTS, ERR_OTP_ATTEMPTS, GET_ACCOUNT_FREEZE_INFO, SET_ACCOUNT_FREEZE_INFO,
    ERR_ACCOUNT_FREEZE_INFO, ERR_AGENT_FEEDBACK, SET_AGENT_FEEDBACK, MODERATELY_SENSITIVE_SCREENS, INSENSITIVE_SCREENS,
    SET_SHERLOCK_SCRIPTS_SEARCH_RESULTS, ERR_SHERLOCK_SCRIPTS_SEARCH_RESULTS, GET_SHERLOCK_SCRIPTS_SEARCH_RESULTS,
} from './constants';
import { RudderEvent, pushRudderEvent } from '../../rudderEvents';

export function* setAuthScreen(action) {
    const {
        index, screen, screenOptions, type,
    } = action.data;

    if (typeof screen !== 'undefined') {
        if (screen === 6) {
            yield put({ type: SET_AUTH_EXPIRED_SCREEN, data: { flag: true, index } });
            return 'auth-expired';
        }

        if (screen === 8) {
            return 'confirm-screen';
        }

        yield put({
            type: SET_ACTION_AUTH_INFO,
            data: {
                index, screen, screenOptions, type,
            },
        });
        return 'initial-auth';
    }

    return '';
}

export function* discardActionAuthInfo(action) {
    yield put({ type: DISCARD_ACTION_AUTH_INFO, data: action.data });
}

function* getAccountFreezeInfo(action) {
    const { ticketId, userId, index } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ACCOUNTS_ROOT}/${ticketId}/account-freeze?${queryString}`,
        );

        yield put({ type: SET_ACCOUNT_FREEZE_INFO, data: { index, ...response } });
    } catch (e) {
        yield put({ type: ERR_ACCOUNT_FREEZE_INFO, data: { index, err: e.message } });
    }
}

function* checkForAuthScreens(action) {
    const {
        screen, ticketId, userId, index, handleRedirect,
    } = action.data;

    if (LESS_SENSITIVE_SCREENS.includes(screen)
        || SENSITIVE_SCREENS.includes(screen)
        || MODERATELY_SENSITIVE_SCREENS.includes(screen)
        || INSENSITIVE_SCREENS.includes(screen)
    ) {
        yield call(getAccountFreezeInfo, { data: { ticketId, userId, index } });
        if (!handleRedirect) {
            yield put(push(`${DEFAULT_TICKET_PATH}${ticketId}/banking/profile`));
        } else {
            yield call(handleRedirect, undefined);
        }
    }
}

function* getInitialAuthFactor(action) {
    const {
        ticketId, userId, index, isInsensitiveAuth,
    } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CUSTOMER_AUTH_ROOT}/?ticketId=${ticketId}${userId ? `&userId=${userId}` : ''}${isInsensitiveAuth ? '&is_insensitive_auth=true' : ''}`,
        );

        yield call(checkForAuthScreens, {
            data: {
                screen: response.screen, ticketId, userId, index,
            },
        });

        yield put({ type: SET_INITIAL_AUTH_INFO, data: { index, ...response } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_INITIAL_AUTH_INFO, data: { index } });
    }
}

function* getCallList(action) {
    // action.data consists of ozonetelId, prev & next
    const queryStr = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CALL_LIST_ENDPOINT}?${queryStr}`,
        );

        yield put({ type: SET_CALL_LIST, data: response });
    } catch (e) {
        yield put({ type: ERR_CALL_LIST, data: { err: e.errMsgForUI } });
    }
}

function* getTicketAndUserInfoForCall(action) {
    const { ucid, resolve, reject } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKETS_ROOT}/ticket-and-user-info/${ucid}`,
        );

        yield call(resolve, response);

        yield put({ type: SET_TICKET_AND_USER_INFO_FOR_CALL });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');

        // something went wrong while fetching the call related info, so we need to show the UI to the agent to create a new ticket manually
        if (e.showNewTicketUI) {
            yield call(reject, e);
        }

        yield put({ type: ERR_TICKET_AND_USER_INFO_FOR_CALL });
    }
}

function* getUserInfoForCall(action) {
    const {
        ucid, userId, resolve, reject,
    } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKETS_ROOT}/user-info/${ucid}/${userId}`,
        );

        yield call(resolve, response);

        yield put({ type: SET_USER_INFO_FOR_CALL });
    } catch (e) {
        const err = getErrLabel(e);

        if (e.statusCode === 404) { // not found user details
            toastify('Did not find user details, creating new ticket', 'success');
        } else {
            toastify(err, 'error');
            yield put({ type: ERR_USER_INFO_FOR_CALL });
        }
        yield call(reject, e);
    }
}

function* userConfirmationForCall(action) {
    const { ucid, userId, resolve } = action.data;
    const queryString = getQueryStringFromObject({ ucid, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CUSTOMER_AUTH_ROOT}?${queryString}`,
        );

        yield call(resolve, response);

        yield put({ type: SET_USER_CONFIRMATION_FOR_CALL });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_CONFIRMATION_FOR_CALL });
    }
}

function* getTicketListForCall(action) {
    // action.data consists of callerUserId, prev, & next
    const queryStr = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKET_LIST_FOR_CALL_ENDPOINT}?${queryStr}`,
        );

        yield put({ type: SET_TICKET_LIST_FOR_CALL, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TICKET_LIST_FOR_CALL, data: { err: e.errMsgForUI } });
    }
}

function* updateCallInfo(action) {
    // action.data consists of ucid, & ticketId
    const {
        ucid, ticketId, resolve, reject,
    } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.put],
            CALL_INFO_ENDPOINT,
            {
                ucid,
                ticketId,
            },
        );

        yield put({ type: SET_UPDATED_CALL_INFO, data: response });

        if (resolve) {
            yield call(resolve, response);
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_UPDATED_CALL_INFO, data: { ...e.response, isErrFlow: true } });

        if (reject) {
            yield call(reject, e);
        }
    }
}

function* createTicketForCall(action) {
    // action.data consists of ucid
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            CALL_CREATE_TICKET_ENDPOINT,
            action.data,
        );

        yield put({ type: SET_CREATED_TICKET_FOR_CALL, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CREATED_TICKET_FOR_CALL, data: e.response });
    }
}

function* getTicketAndUserDetails(action) {
    const { ticketId, index } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKETS_ROOT}/${ticketId}`,
        );
        const {
            source, phoneNumber, ticketPhoneNumber, isUserExistInEpifi, callbackUser,
        } = response;

        const isChatFlow = source.toLowerCase() === 'chat';
        const hasAnotherUserMapped = isUserExistInEpifi && (phoneNumber !== ticketPhoneNumber);
        const shouldFetchAuthInfo = !callbackUser && (isChatFlow || hasAnotherUserMapped);
        const shouldFetchAuthInfoOnCallbackFlow = isChatFlow || hasAnotherUserMapped;

        yield put({
            type: SET_TICKET_INFO,
            data: {
                ...response,
                ticketId,
                index,
                shouldFetchAuthInfo,
                isChatFlow,
                shouldFetchAuthInfoOnCallbackFlow,
            },
        });
        yield put(push(`${DEFAULT_TICKET_PATH}${ticketId}`));

        const isInsensitiveAuth = true;

        // chat flow or another user mapped for the ticket
        if (shouldFetchAuthInfo) {
            yield call(getInitialAuthFactor, { data: { ticketId, index, isInsensitiveAuth } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TICKET_INFO, data: { type: 'ticket', index, err } });
        yield put(push(DEFAULT_TICKET_PATH));
    }
}

function* getUserDetails(action) {
    const {
        ticketId, index, userId, reject,
    } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKETS_ROOT}/${ticketId}/user/${userId}`,
        );

        yield put({ type: SET_USER_INFO, data: { ...response, ticketId, index } });
    } catch (e) {
        yield put({ type: ERR_TICKET_INFO, data: { type: 'user', index } });

        yield call(reject);
    }
}

function* verifyAuthFactor(action) {
    const {
        ticketId, userId, authFactor, index, unavailable, setupWebSocket, type, nextAction, handleRedirect,
    } = action.data;

    try {
        const payload = {
            ticketId,
            userId,
            authFactor: {
                name: authFactor.name,
                value: !unavailable ? authFactor.formValue : '',
                unavailable,
            },
        };

        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            CUSTOMER_AUTH_ROOT,
            payload,
        );

        const isAsyncAuthFactorPending = authFactor.async
            && !unavailable
            && ASYNC_AUTH_FACTOR_PENDING.includes(response.authFactor.status);

        if (isAsyncAuthFactorPending) {
            yield call(setupWebSocket);
        }

        if (!type) {
            yield call(checkForAuthScreens, { data: { screen: response.screen, ticketId, handleRedirect } });
        } else if (ACTIONABLE_SCREENS.includes(response.screen)) {
            yield call(nextAction);
        }

        yield put({ type: SET_AUTH_INFO_AFTER_VERIFICATION, data: { index, ...response, type } });

        if (isAsyncAuthFactorPending) { // enable retry cool off after 15s for async auth factors
            // As the manal cool off delay is impacting FRT, this is being turned off.
            // yield delay(15000);
            yield put({ type: SET_AUTH_INFO_LOADER, data: { index, flag: false } });
        }
    } catch (e) {
        yield put({ type: ERR_AUTH_INFO, data: { index, ...e, authFactor } });
    }
}

function* callbackUserSaga(action) {
    const { ticketId, index, phoneNumber } = action.data;
    const queryString = getQueryStringFromObject({ phoneNumber });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKETS_ROOT}/${ticketId}/callback?${queryString}`,
        );

        yield put({ type: SET_CALLBACK_USER, data: { ...response, index } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CALLBACK_USER, data: { index } });
    }
}

function* getWaitlistUserDetails(action) {
    const {
        ticketId, index, userId, showWaitlistUserInfo,
    } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKETS_ROOT}/${ticketId}/waitlist?userId=${userId}`,
        );

        yield put({ type: SET_WAITLIST_USER, data: { ...response, index, showWaitlistUserInfo } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_WAITLIST_USER, data: { index } });
    }
}

function* attachEntityToTicket(action) {
    const {
        ticketId, userId, meta, type, resolve, metaString,
    } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${TICKETS_ROOT}/${ticketId}/attach?userId=${userId}`,
            {
                meta,
                type,
                metaString,
            },
        );

        if (resolve) {
            yield call(resolve);
        }

        toastify(response.description, 'success');
        yield put({ type: SET_ATTACH_ENTITY_TICKET, data: { ticketId } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ATTACH_ENTITY_TICKET, data: { ticketId } });
    }
}

function* getOTPAttempts(action) {
    const {
        ticketId, index, phoneNumber, resolve, reject,
    } = action.data;
    const queryStr = getQueryStringFromObject({ phoneNumber });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKETS_ROOT}/${ticketId}/otp-attempts?${queryStr}`,
        );

        yield put({ type: SET_OTP_ATTEMPTS, data: { ...response, index } });

        yield call(resolve);
    } catch (e) {
        yield put({ type: ERR_OTP_ATTEMPTS, data: { index } });

        yield call(reject);
    }
}

function* setAgentFeedbackSaga(action) {
    const { agentEmail, sherlockFeedbackDetails } = action.data;

    const requestBody = {
        agentEmail,
        sherlockFeedbackDetails,
    };

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${SHERLOCK_FEEDBACK_ROOT}/agent`,
            requestBody,
        );

        toastify('Successfully submitted feedback', 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_AGENT_FEEDBACK, data: { err: e.message } });
    }
}

function* getScriptsSearchResults(action) {
    const {
        index, text, L1, L2, L3, agentEmail, rudderProperties, resultType, paginationParams,
    } = action.data;
    const queryStr = getQueryStringFromObject({
        query: text, l1: L1, l2: L2, l3: L3, resultType, paginationParams,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_SEARCH_SOP_ENDPOINT}?${queryStr}`,
        );

        if (agentEmail && rudderProperties) {
            const searchRudderProperties = {
                queryText: text,
                L1,
                L2,
                L3,
                results: response?.data?.rowData.map((rowDatum) => rowDatum.meta),
                ...rudderProperties,
            };
            pushRudderEvent(RudderEvent.SherlockV1Scripts.SearchedSherlockScriptSearchBar, agentEmail, searchRudderProperties);
        }

        yield put({ type: SET_SHERLOCK_SCRIPTS_SEARCH_RESULTS, data: { ...response, index } });
    } catch (e) {
        yield put({ type: ERR_SHERLOCK_SCRIPTS_SEARCH_RESULTS, data: { index } });
    }
}

export default function* ticketSaga() {
    yield all(
        [
            yield takeLatest(GET_CALL_LIST, getCallList),
            yield takeLatest(GET_TICKET_AND_USER_INFO_FOR_CALL, getTicketAndUserInfoForCall),
            yield takeLatest(GET_USER_INFO_FOR_CALL, getUserInfoForCall),
            yield takeLatest(USER_CONFIRMATION_FOR_CALL, userConfirmationForCall),
            yield takeLatest(GET_TICKET_LIST_FOR_CALL, getTicketListForCall),
            yield takeLatest(UPDATE_CALL_INFO, updateCallInfo),
            yield takeLatest(CREATE_TICKET_FOR_CALL, createTicketForCall),
            yield takeLatest(SUBMIT_TICKET_ID, getTicketAndUserDetails),
            yield takeLatest(SUBMIT_USER_ID, getUserDetails),
            yield takeLatest(GET_INITIAL_AUTH_FACTOR, getInitialAuthFactor),
            yield takeLatest(VERIFY_AUTH_FACTOR, verifyAuthFactor),
            yield takeLatest(CALLBACK_USER, callbackUserSaga),
            yield takeLatest(GET_WAITLIST_USER, getWaitlistUserDetails),
            yield takeLatest(ATTACH_ENTITY_TICKET, attachEntityToTicket),
            yield takeLatest(GET_OTP_ATTEMPTS, getOTPAttempts),
            yield takeLatest(GET_ACCOUNT_FREEZE_INFO, getAccountFreezeInfo),
            yield takeLatest(SET_AGENT_FEEDBACK, setAgentFeedbackSaga),
            yield takeLatest(GET_SHERLOCK_SCRIPTS_SEARCH_RESULTS, getScriptsSearchResults),
        ],
    );
}

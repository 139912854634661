/**
 * @file Common risk ops selectors
 */

import { createSelector } from 'reselect';

import { RISK_OPS_KEY, AWS_S3_PROXY_PREFIX } from './constants';

export const selectRiskOpsLoading = (containerKey) => createSelector(
    (state) => state.getIn([RISK_OPS_KEY, containerKey, 'loading']),
    (subState) => subState,
);

export const selectRiskOpsErr = (containerKey) => createSelector(
    (state) => state.getIn([RISK_OPS_KEY, containerKey, 'err']),
    (subState) => subState,
);

export const selectRiskOpsCasesList = (containerKey) => createSelector(
    (state) => state.getIn([RISK_OPS_KEY, containerKey, 'casesList']),
    (subState) => {
        const casesList = subState?.toJS();
        const modifiedCasesList = [];

        casesList.forEach((item, index) => {
            const curRowObj = {
                sr_no: index + 1,
            };

            const { payload } = item;
            curRowObj.actor_id = item[payload].actor_id;
            curRowObj.request_id = item[payload].request_id || item[payload].liveness_request_id || item[payload].facematch_request_id;
            curRowObj.created_at = item[payload].created_at;
            curRowObj.review_type = item[payload]?.review_type?.replace('REVIEW_TYPE_', '');
            const attemptDate = item[payload].attempt_date;

            if (attemptDate) {
                const { year, month, day } = attemptDate;
                const formattedAttemptDate = `${day}/${month}/${year}`;
                curRowObj.attempt_date = formattedAttemptDate;
            }

            curRowObj.ogData = item;
            modifiedCasesList.push(curRowObj);
        });

        return modifiedCasesList;
    },
);

export const selectRiskOpsPayloadType = (containerKey) => createSelector(
    (state) => state.getIn([RISK_OPS_KEY, containerKey, 'payloadType']),
    (subState) => subState?.toJS(),
);

export const selectRiskOpsCurView = (containerKey) => createSelector(
    (state) => state.getIn([RISK_OPS_KEY, containerKey, 'curView']),
    (subState) => subState,
);

export const selectRiskOpsCurCase = (containerKey) => createSelector(
    (state) => state.getIn([RISK_OPS_KEY, containerKey, 'curCase']),
    (subState) => subState.toJS(),
);

export const selectRiskOpsPendingReviewCount = (containerKey) => createSelector(
    (state) => state.getIn([RISK_OPS_KEY, containerKey, 'pendingReviewCount']),
    (subState) => subState,
);

export const selectRiskOpsQueueFilters = (containerKey) => createSelector(
    (state) => state.getIn([RISK_OPS_KEY, containerKey, 'queueFilters']),
    (subState) => subState?.toJS(),
);

export const selectRiskOpsL2AnnotationDetails = (containerKey) => createSelector(
    (state) => state.getIn([RISK_OPS_KEY, containerKey, 'l2AnnotationDetails']),
    (subState) => subState?.toJS(),
);

export const selectRiskOpsReviewVideo = (containerKey, isFederalLoanLivenessReviewer) => createSelector(
    (state) => {
        const videoUrlFromState = state.getIn([RISK_OPS_KEY, containerKey, 'videoUrl']);
        if (!videoUrlFromState) {
            return videoUrlFromState;
        }
        // TODO : Find alternative to get the domain name to proxy to from config : https://monorail.pointz.in/p/fi-app/issues/detail?id=47155
        const currentDomain = window.location.origin;
        // If the current access level is 'FEDERAL_LOAN_LIVENESS_REVIEWER'
        // then create the proxied url for the video url
        // This is needed because agents from federal are behind a VPN with S3 urls blocked
        // hence video urls for those roles are being proxied
        if (isFederalLoanLivenessReviewer) {
            const videoUrlObject = new URL(videoUrlFromState);
            // @example
            // videoUrlFromState - https://s3.ap-south-1.amazonaws.com/epifi-liveness/liveness_videos/{actor_id}/*
            // videoUrlObject.origin - https://s3.ap-south-1.amazonaws.com
            // proxiedVideoUrl - https://{sherlock_domain}/AWS_S3_Proxy/epifi-liveness/liveness_videos/{actor_id}/*
            const proxiedVideoUrl = videoUrlFromState.replace(videoUrlObject.origin, `${currentDomain}/${AWS_S3_PROXY_PREFIX}`);
            return proxiedVideoUrl;
        }
        return videoUrlFromState;
    },
    (subState) => subState,
);

export const selectRiskOpsKycImg = (containerKey) => createSelector(
    (state) => state.getIn([RISK_OPS_KEY, containerKey, 'kycImg']),
    (subState) => subState,
);

export const selectRiskOpsVideoFrameImg = (containerKey) => createSelector(
    (state) => state.getIn([RISK_OPS_KEY, containerKey, 'videoFrameImg']),
    (subState) => subState,
);

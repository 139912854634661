/**
 * @file SingleCase
 * Review a single facematch case and update the status
 */

import React, { memo, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedSelector } from '../../../hooks';
import { MetaInfoContext } from '../../../context';

import { DequeueCase, SingleCaseHeading } from '../../RiskOps';
import { getAdditionalFilters, getDataFromFacematchCase } from '../../RiskOps/utils';
import { selectRiskOpsCurCase, selectRiskOpsPayloadType, selectRiskOpsQueueFilters } from '../../RiskOps/selectors';
import { dequeueRiskOpsCaseAction, setRiskOpsCurViewAction } from '../../RiskOps/actions';
import { RISK_OPS_VIEW_ALL_CASES } from '../../RiskOps/constants';

import { FACEMATCH_REVIEW_KEY as CONTAINER_KEY } from '../constants';
import FacematchCaseInfo from '../CaseInfo';

const SingleCase = () => {
    const metaInfo = useContext(MetaInfoContext);
    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;
    const dispatch = useDispatch();

    // global state
    const payloadType = useMemoizedSelector(selectRiskOpsPayloadType, [CONTAINER_KEY]);
    const curCase = useMemoizedSelector(selectRiskOpsCurCase, [CONTAINER_KEY]);
    const { fromDate, toDate, sortOption } = useMemoizedSelector(selectRiskOpsQueueFilters, [CONTAINER_KEY]);

    const { actorId, recordId } = getDataFromFacematchCase(curCase);
    const { index } = curCase;

    const onBackArrowCTA = () => {
        dispatch(setRiskOpsCurViewAction({ containerKey: CONTAINER_KEY, newView: RISK_OPS_VIEW_ALL_CASES }));
    };

    const dequeueAction = dequeueRiskOpsCaseAction({
        containerKey: CONTAINER_KEY,
        actorId,
        recordId,
        index,
        queryParams: {
            payloadType: payloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        },
        customContext: {
            bannersVersion,
        },
    });

    return (
        <div className='rops-cr'>
            <SingleCaseHeading
                title='Review Facematch Case'
                onBackArrowCTA={onBackArrowCTA}
            />
            <FacematchCaseInfo containerKey={CONTAINER_KEY} />
            <DequeueCase dequeueAction={dequeueAction} />
        </div>
    );
};

export default memo(SingleCase);

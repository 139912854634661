import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { isEmpty } from 'ramda';

import {
    Button, DropdownContent, DropdownWrapper, ExpandableCard, FormDropdown,
    FormWithSingleInput, Loader,
} from '../../components';
import { MetaInfoContext } from '../../context';
import { dateUtils, getDropdownHeight, injectReducer, injectSaga } from '../../utils';

import { attachEntityToTicket as attachEntityToTicketApi } from '../Tickets/actions';

import TxnList from './TxnList';
import DisputeQuestionnaire from './DisputeQuestionnaire';
import {
    TXNS_VIEW_SEARCH_PARAMS_KEYS, SEARCH_BY_MAP, CREDIT_CARD_SEARCH_BY_MAP, CARD_ACTION_TYPE,
    DISPUTE_TYPE,
} from './constants';
import {
    getTxnsInfo as getTxnsInfoApi, getChannelsInfo as getChannelsInfoApi, raiseTxnDispute, resetState,
    getFirstOrLastSetTxnList, setSearchParams as setSearchParamsApi, resetSearchParams as resetSearchParamsApi,
    getOrderStatusList as getOrderStatusListApi, getPaymentProtocolList as getPaymentProtocolListApi,
    getCreditCardTxnsInfoAction, getFirstOrLastSetCreditCardTxnsListAction, raiseDisputeForCreditCardTxnAction,
} from './actions';
import {
    makeSelectQuestionList, makeSelectDisputeInfo, makeSelectSearchParameters, makeSelectOrderStatusList,
    makeSelectPaymentProtocolList,
} from './selectors';

import reducer from './reducer';
import saga from './saga';
import './style.scss';

class Transactions extends React.Component {
    constructor(props) {
        super(props);

        const {
            upperLimitDate, lowerLimitDate, fromDate, toDate,
        } = dateUtils.getInitialDateRange('02-01-2021', true); // 01 Feb

        // set fromDate 30 days earlier than toDate
        const modifiedFromDateValue = new Date(toDate.value);
        modifiedFromDateValue.setDate(modifiedFromDateValue.getDate() - 29); // 29 days earlier date (1 month)
        modifiedFromDateValue.setHours(0, 0, 0, 0);

        this.state = {
            isSearchCardExpanded: false,
            isDisputeCardExpanded: false,
            isTxnCardExpanded: false,
            showSearchByDD: false,
            showOrderStatusDD: false,
            errLabel: '',
            selectedTxn: {},
            isDisputeFlow: false,
            channel: '',
            channelOption: '',
            // eslint-disable-next-line react/no-unused-state
            upperLimitDate,
            // eslint-disable-next-line react/no-unused-state
            lowerLimitDate,
            toDate,
            fromDate: {
                ...fromDate,
                value: modifiedFromDateValue,
            },
            amount: 0,
        };
    }

    componentDidMount() {
        const {
            ticketId, index, userId, orderStatusList, paymentProtocolList,
            getChannelsInfo, getOrderStatusList, getPaymentProtocolList,
        } = this.props;

        getChannelsInfo({ ticketId, userId, index });

        if (isEmpty(orderStatusList)) {
            getOrderStatusList();
        }

        if (isEmpty(paymentProtocolList)) {
            getPaymentProtocolList();
        }
    }

    /**
     * This is a lifecycle method in a React component that has been marked as unsafe
     * because it can cause side-effects and can be potentially harmful. It is called
     * when the component receives new props.
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { creditCardTransaction } = this.props;

        /**
         * Check if the `creditCardTransaction` prop from the `nextProps` is not equal to
         * the `creditCardTransaction` prop from `this.props`. If they are not equal, it means
         * that `creditCardTransaction` has changed in the parent component and we need to
         * reset some state values in this component.
         */
        if (nextProps.creditCardTransaction !== creditCardTransaction) {
            this.resetAllStateValues();
        }
    }

    resetAllStateValues = () => {
        const { ticketId, resetAllState, resetSearchParams } = this.props;

        resetAllState({ ticketId });

        resetSearchParams({ ticketId });

        this.setState({
            isSearchCardExpanded: false,
            isDisputeCardExpanded: false,
            isTxnCardExpanded: false,
            showSearchByDD: false,
            showOrderStatusDD: false,
            errLabel: '',
            selectedTxn: {},
            isDisputeFlow: false,
            channel: '',
            channelOption: '',
        });
    };

    setErrLabel = (value) => {
        this.setState({
            errLabel: value,
        });
    };

    setSelectedTxn = (txn) => {
        this.setState({
            selectedTxn: txn,
        });
    };

    getSearchParameters = () => {
        const { ticketId, searchParameters } = this.props;
        let searchBy;
        let txnId;
        let amount;
        let orderStatus;
        let partialUtr;
        let fullUtr;
        let paymentProtocol;

        if (searchParameters[ticketId]) {
            ({
                searchBy, txnId, amount, orderStatus, partialUtr, fullUtr, paymentProtocol = [],
            } = searchParameters[ticketId]);
        }

        return {
            searchBy,
            txnId,
            amount,
            orderStatus,
            partialUtr,
            fullUtr,
            paymentProtocol,
        };
    };

    onDisputeAmountChange = (disputeAmount) => {
        this.setState({
            amount: disputeAmount,
        });
    };

    toggleHandler = (id) => () => {
        this.setState((prevState) => {
            switch (id) {
                case 'search-card':
                    return {
                        isSearchCardExpanded: !prevState.isSearchCardExpanded,
                    };

                case 'dispute-card':
                    return {
                        isDisputeCardExpanded: !prevState.isDisputeCardExpanded,
                    };

                case 'txn-card':
                    return {
                        isTxnCardExpanded: !prevState.isTxnCardExpanded,
                    };

                case TXNS_VIEW_SEARCH_PARAMS_KEYS.SEARCH_BY:
                    return {
                        showSearchByDD: !prevState.showSearchByDD,
                    };

                case 'orderStatus':
                    return {
                        showOrderStatusDD: !prevState.showOrderStatusDD,
                    };

                case 'dispute-flow':
                    // reset channel option, when 'Raise Dispute' CTA is clicked
                    this.setState({
                        channelOption: '',
                    });

                    // scroll to top of page since dispute questions are rendered at the top
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    });

                    return {
                        isDisputeFlow: !prevState.isDisputeFlow,
                    };

                default:
                    return {};
            }
        });
    };

    // eslint-disable-next-line class-methods-use-this
    getDateLimits = ({ toDate, fromDate, prevState }) => {
        let modifiedToDate;
        let modifiedFromDate;

        const {
            fromDate: fromDatePrevState, toDate: toDatePrevState, upperLimitDate, lowerLimitDate,
        } = prevState;

        /**
         * on selecting 25th as from date and 27th as to date, we get
         * From Date obj -
         * {
         *      upperLimit: Fri Nov 27 2020 00:00:00 GMT+0530 (India Standard Time),
         *      lowerLimit: Sun May 31 2020 00:00:00 GMT+0530 (India Standard Time),
         *      value: Wed Nov 25 2020 00:00:00 GMT+0530 (India Standard Time)
         * }
         * To Date obj -
         * {
         *      upperLimit: Fri Nov 27 2020 23:59:59 GMT+0530 (India Standard Time),
         *      lowerLimit: Wed Nov 25 2020 23:59:59 GMT+0530 (India Standard Time),
         *      value: Fri Nov 27 2020 23:59:59 GMT+0530 (India Standard Time)
         * }
         */
        if (toDate) {
            const newToDate = new Date(toDate);
            newToDate.setHours(23, 59, 59, 999);

            modifiedToDate = {
                ...toDatePrevState,
                value: newToDate,
            };

            const upperLimitFromDate = new Date(toDate);
            upperLimitFromDate.setHours(0, 0, 0, 0);

            const lowerLimitFromDate = new Date(toDate);
            lowerLimitFromDate.setDate(lowerLimitFromDate.getDate() - 89); // 89 days earlier date (3 months)
            lowerLimitFromDate.setHours(0, 0, 0, 0);

            const fromDateValue = fromDatePrevState.value;

            modifiedFromDate = { // new lower limit should not exceed the overall lower limit
                upperLimit: upperLimitFromDate,
                lowerLimit: lowerLimitFromDate > lowerLimitDate ? lowerLimitFromDate : lowerLimitDate,
            };

            // if old value does not lie in the new range, then empty its value
            if (fromDateValue >= modifiedFromDate.lowerLimit && fromDateValue <= modifiedFromDate.upperLimit) {
                modifiedFromDate.value = fromDateValue;
            } else {
                modifiedFromDate.value = '';
            }
        } else if (fromDate) {
            modifiedFromDate = {
                ...fromDatePrevState,
                value: fromDate,
            };

            const upperLimitFromDate = new Date(fromDate);
            upperLimitFromDate.setDate(upperLimitFromDate.getDate() + 89); // 89 days later date (3 months)
            upperLimitFromDate.setHours(23, 59, 59, 999);

            const lowerLimitToDate = new Date(fromDate);
            lowerLimitToDate.setHours(23, 59, 59, 999);

            const toDateValue = toDatePrevState.value;

            modifiedToDate = { // new upper limit should not exceed the overall upper limit
                upperLimit: upperLimitFromDate > upperLimitDate ? upperLimitDate : upperLimitFromDate,
                lowerLimit: lowerLimitToDate,
            };

            // if old value does not lie in the new range, then empty its value
            if (toDateValue >= modifiedToDate.lowerLimit && toDateValue <= modifiedToDate.upperLimit) {
                modifiedToDate.value = toDateValue;
            } else {
                modifiedToDate.value = '';
            }
        }

        return {
            fromDate: modifiedFromDate,
            toDate: modifiedToDate,
        };
    };

    attachEntityAgainstTicket = (meta, resolve) => {
        const {
            ticketId, userId, index, attachEntityToTicket,
        } = this.props;

        attachEntityToTicket({
            ticketId,
            userId,
            index,
            type: 'TRANSACTION',
            meta: [meta], // only single txn can be marked
            resolve,
        });
    };

    handleInputChange = (key) => (event) => {
        const { ticketId, setSearchParams } = this.props;

        setSearchParams({
            ticketId,
            key,
            value: event.target.value,
        });
    };

    handleDateChange = (key) => (value) => {
        this.setState((prevState) => {
            const { fromDate, toDate } = this.getDateLimits({ [key]: value, prevState });

            return {
                fromDate,
                toDate,
            };
        });
    };

    handleDDChange = (key, value) => {
        const { ticketId, setSearchParams } = this.props;

        setSearchParams({
            ticketId,
            key,
            value,
        });

        if (value) {
            this.toggleHandler(key)();
        }
    };

    handleChannelChange = (value) => {
        this.setState({
            channel: value,
            channelOption: '',
        });
    };

    handleChannelOptionChange = (value) => {
        this.setState({
            channelOption: value,
        });
    };

    handleSubmit = ({ prev, next }) => {
        // event.preventDefault();

        const { fromDate, toDate } = this.state;
        const {
            ticketId, index, userId, getTxnsInfo, getCreditCardTxnsInfo,
            creditCardTransaction,
        } = this.props;

        const {
            searchBy, txnId, amount, orderStatus, partialUtr, fullUtr, paymentProtocol,
        } = this.getSearchParameters();
        let hasError = false;

        if (!fromDate.value || !toDate.value) {
            hasError = true;
        } else if (searchBy === SEARCH_BY_MAP.FI_TXN_ID) {
            if (!txnId) {
                hasError = true;
            } else if (creditCardTransaction && txnId.length < 12) {
                this.setErrLabel('Minimum number of characters for the Fi txn id is 12');
                return;
            } else if (!creditCardTransaction && txnId.length !== 4) {
                this.setErrLabel('Please enter last 4 chars of Fi txn id');
                return;
            }
        } else if (searchBy === SEARCH_BY_MAP.TXN_AMOUNT && !amount) {
            hasError = true;
        } else if (searchBy === SEARCH_BY_MAP.PARTIAL_UTR && !partialUtr) {
            hasError = true;
        } else if (searchBy === SEARCH_BY_MAP.FULL_UTR && !fullUtr) {
            hasError = true;
        } else if (searchBy === SEARCH_BY_MAP.PAYMENT_PROTOCOL && isEmpty(paymentProtocol)) {
            hasError = true;
        }

        if (hasError) {
            this.setErrLabel('Please fill all the * marked fields');
            return;
        }

        const data = {
            ticketId,
            index,
            userId,
            from: fromDate.value,
            to: toDate.value,
            prev,
            next,
        };

        if (searchBy === SEARCH_BY_MAP.FI_TXN_ID) {
            data.txnId = txnId;
        } else if (searchBy === SEARCH_BY_MAP.TXN_AMOUNT) {
            data.amount = amount;
            data.status = orderStatus;
        } else if (searchBy === SEARCH_BY_MAP.PARTIAL_UTR) {
            data.partialUtr = partialUtr;
        } else if (searchBy === SEARCH_BY_MAP.FULL_UTR) {
            data.fullUtr = fullUtr;
        } else if (searchBy === SEARCH_BY_MAP.PAYMENT_PROTOCOL) {
            data.paymentProtocol = paymentProtocol;
        }

        this.setState({
            isSearchCardExpanded: false,
            errLabel: '',
        });

        if (creditCardTransaction) {
            getCreditCardTxnsInfo(data);
        } else {
            getTxnsInfo(data);
        }
    };

    handleFirstOrLastSetSubmit = ({ firstSet, lastSet }) => () => {
        const {
            ticketId, userId, index, getFirstOrLastSetTxns,
            creditCardTransaction, getFirstOrLastSetCreditCardTxns,
        } = this.props;

        const data = {
            ticketId,
            index,
            userId,
            firstSet,
            lastSet,
        };

        if (creditCardTransaction) {
            getFirstOrLastSetCreditCardTxns(data);
        } else {
            getFirstOrLastSetTxns(data);
        }
    };

    renderDateBlock = (key) => {
        let label;
        let labelId;
        let value;
        let minDate;
        let maxDate;

        const { fromDate, toDate } = this.state;

        switch (key) {
            case 'fromDate':
                label = 'From Date*';
                labelId = 'txn_from-date';
                maxDate = fromDate.upperLimit;
                minDate = fromDate.lowerLimit;
                value = fromDate.value;
                break;

            case 'toDate':
                label = 'To Date*';
                labelId = 'txn_to-date';
                maxDate = toDate.upperLimit;
                minDate = toDate.lowerLimit;
                value = toDate.value;
                break;

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'txns-fc--m0',
                    label: 'txns-fc__lb',
                    inputField: 'txns-fc__ip',
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: this.handleDateChange(key),
                    showYearDropdown: true,
                    yearDropdownItemNumber: 1,
                    maxDate,
                    minDate,
                }}
            />
        );
    };

    renderOrderStatusBlock = () => {
        const { orderStatusList } = this.props;
        const { orderStatus } = this.getSearchParameters();
        const { showOrderStatusDD } = this.state;

        const { length: optionsLength } = orderStatusList;
        const optionListDDHeight = getDropdownHeight(optionsLength);

        return (
            <div className='frWrapper txns-fc'>
                <div className='txns-fc__lb txns-m10'>Status</div>
                <DropdownWrapper
                    visible={showOrderStatusDD}
                    onOutsideClickHandler={this.toggleHandler('orderStatus')}
                    extClasses={{
                        container: 'custom-dd txns-sp-dd',
                    }}
                >
                    <button
                        type='button'
                        className='txns-dd__vl'
                        onClick={this.toggleHandler('orderStatus')}
                    >
                        {orderStatus ? orderStatus.split('_').join(' ').toLowerCase() : 'Choose...'}
                        { // cross icon to clear selected option
                            orderStatus ? (
                                <button
                                    type='button'
                                    className='txns-dd__clear'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.handleDDChange('orderStatus', '');
                                    }}
                                >
                                    x
                                </button>
                            ) : null
                        }
                    </button>
                    <DropdownContent
                        visible
                        extClasses={{
                            container: ['custom-dd__cc txns-sp-dd__cc'],
                        }}
                        extStyles={{
                            height: showOrderStatusDD ? optionListDDHeight : 0,
                            overflow: showOrderStatusDD ? 'auto' : 'hidden',
                        }}
                    >
                        {
                            orderStatusList.map((item) => (
                                <button
                                    type='button'
                                    key={item.label}
                                    className='custom-dd__cl'
                                    onClick={() => this.handleDDChange('orderStatus', item.value)}
                                >
                                    {item.label}
                                </button>
                            ))
                        }
                    </DropdownContent>
                </DropdownWrapper>
            </div>
        );
    };

    renderSearchByBlock = (creditCardTransaction) => {
        const { searchBy } = this.getSearchParameters();
        const { showSearchByDD } = this.state;

        let searchByKeys;

        if (creditCardTransaction) {
            searchByKeys = Object.keys(CREDIT_CARD_SEARCH_BY_MAP);
        } else {
            searchByKeys = Object.keys(SEARCH_BY_MAP);
        }

        const searchByDDHeight = getDropdownHeight(searchByKeys.length);

        return (
            <div className='frcWrapper'>
                <div className='txns-fc__lb'>Search By</div>
                <DropdownWrapper
                    visible={showSearchByDD}
                    onOutsideClickHandler={this.toggleHandler('searchBy')}
                    extClasses={{
                        container: 'custom-dd txns-sp-dd',
                    }}
                >
                    <button
                        type='button'
                        onClick={this.toggleHandler('searchBy')}
                    >
                        {searchBy || 'Choose...'}
                    </button>
                    <DropdownContent
                        visible
                        extClasses={{
                            container: ['custom-dd__cc txns-sp-dd__cc'],
                        }}
                        extStyles={{
                            height: showSearchByDD ? searchByDDHeight : 0,
                            overflow: showSearchByDD ? 'auto' : 'hidden',
                        }}
                    >
                        {
                            searchByKeys.map((item) => {
                                const value = SEARCH_BY_MAP[item];

                                return (
                                    <button
                                        type='button'
                                        key={value}
                                        className='custom-dd__cl'
                                        onClick={() => this.handleDDChange('searchBy', value)}
                                    >
                                        {value}
                                    </button>
                                );
                            })
                        }
                    </DropdownContent>
                </DropdownWrapper>
            </div>
        );
    };

    renderFormField = () => {
        const { creditCardTransaction } = this.props;
        const {
            searchBy, txnId, amount, partialUtr, fullUtr,
        } = this.getSearchParameters();

        let label;
        let labelId;
        let key;
        let value;
        let minLength;
        let maxLength;

        switch (searchBy) {
            case SEARCH_BY_MAP.FI_TXN_ID: {
                label = creditCardTransaction ? 'Fi txn id*' : 'Last 4 chars of Fi txn id*';
                labelId = 'txn-id';
                key = 'txnId';
                value = txnId;
                minLength = creditCardTransaction ? 12 : 4;
                maxLength = creditCardTransaction ? 20 : 4;
                break;
            }

            case SEARCH_BY_MAP.TXN_AMOUNT: {
                label = 'Amount*';
                labelId = 'txn-amount';
                key = 'amount';
                value = amount;
                break;
            }

            case SEARCH_BY_MAP.PARTIAL_UTR: {
                label = 'Partial UTR*';
                labelId = 'txn-partial-utr';
                key = 'partialUtr';
                value = partialUtr;
                break;
            }

            case SEARCH_BY_MAP.FULL_UTR: {
                label = 'Full UTR*';
                labelId = 'txn-full-utr';
                key = 'fullUtr';
                value = fullUtr;
                break;
            }

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'txns-fc',
                    label: 'txns-fc__lb',
                    inputField: 'txns-fc__ip',
                }}
                label={label}
                labelId={labelId}
                formType='text'
                input={{
                    value,
                    onChange: this.handleInputChange(key),
                    minLength,
                    maxLength,
                }}
            />
        );
    };

    renderPaymentProtocolBlock = () => {
        const { paymentProtocolList } = this.props;
        const { paymentProtocol } = this.getSearchParameters();

        return (
            <div className='frcWrapper txns-fc txns-fc--mb20'>
                <div className='txns-fc__lb'>Payment Protocol</div>
                <FormDropdown
                    isMulti
                    options={paymentProtocolList}
                    input={{
                        value: paymentProtocol,
                        onChange: (value) => {
                            const { ticketId, setSearchParams } = this.props;

                            setSearchParams({
                                ticketId,
                                key: 'paymentProtocol',
                                value,
                            });
                        },
                    }}
                    cacheKey='txn-payment-protocol-list'
                    extStyles={{
                        container: 'txns-fc__ip',
                    }}
                />
            </div>
        );
    };

    renderSearchResults = (firstOrLastSet) => {
        const {
            ticketId, userId, index, creditCardTransaction,
        } = this.props;
        const { isDisputeFlow, selectedTxn } = this.state;

        return (
            <TxnList
                ticketId={ticketId}
                userId={userId}
                index={index}
                isDisputeFlow={isDisputeFlow}
                selectedTxn={selectedTxn}
                setSelectedTxn={this.setSelectedTxn}
                toggleHandler={this.toggleHandler}
                handleSubmit={this.handleSubmit}
                attachEntityAgainstTicket={this.attachEntityAgainstTicket}
                firstOrLastSet={firstOrLastSet}
                creditCardTransaction={creditCardTransaction}
            />
        );
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    renderDisputeCard = () => {
        const { isDisputeCardExpanded } = this.state;

        return (
            <ExpandableCard
                heading='Disputes'
                extClasses='txns-exp-card'
                isExpanded={isDisputeCardExpanded}
                setIsExpanded={this.toggleHandler('dispute-card')}
            >
                <div className='txns-exp-card__cr2'>
                    <Button primary label='Raise Dispute' onClick={this.toggleHandler('dispute-flow')} />
                </div>
            </ExpandableCard>
        );
    };

    renderSearchCard = (creditCardTransaction) => {
        const {
            searchBy,
            // txnId, amount, partialUtr, fullUtr,
        } = this.getSearchParameters();
        const { isSearchCardExpanded, errLabel } = this.state;
        const { ticketId, resetSearchParams } = this.props;

        return (
            <ExpandableCard
                heading='Search for transactions'
                extClasses='txns-exp-card txns-exp-card--m0'
                isExpanded={isSearchCardExpanded}
                setIsExpanded={this.toggleHandler('search-card')}
            >
                <div className='txns-exp-card__cr1'>
                    {this.renderSearchByBlock(creditCardTransaction)}
                    {
                        searchBy === SEARCH_BY_MAP.FI_TXN_ID ? (
                            this.renderFormField()
                        ) : null
                    }
                    {
                        searchBy === SEARCH_BY_MAP.TXN_AMOUNT ? (
                            <div className='frWrapper'>
                                {this.renderFormField()}
                                {!creditCardTransaction ? this.renderOrderStatusBlock() : null}
                            </div>
                        ) : null
                    }
                    {
                        searchBy === SEARCH_BY_MAP.PARTIAL_UTR ? (
                            this.renderFormField()
                        ) : null
                    }
                    {
                        searchBy === SEARCH_BY_MAP.FULL_UTR ? (
                            this.renderFormField()
                        ) : null
                    }
                    {
                        searchBy === SEARCH_BY_MAP.PAYMENT_PROTOCOL ? (
                            this.renderPaymentProtocolBlock()
                        ) : null
                    }
                    {
                        searchBy ? (
                            <React.Fragment>
                                <div className='frWrapper'>
                                    {this.renderDateBlock('fromDate')}
                                    {this.renderDateBlock('toDate')}
                                </div>
                                {errLabel ? <div className='err-label txns-err-label'>{errLabel}</div> : null}
                                <div className={`frcWrapper txns-sc ${errLabel && 'txns-sc--err'}`}>
                                    <Button
                                        extClasses='txns-sc__cta'
                                        primary
                                        label='Search'
                                        onClick={this.handleSubmit}
                                    />
                                    <button
                                        type='button'
                                        className='link txns-sc__clear'
                                        onClick={() => resetSearchParams({ ticketId })}
                                    >
                                        Clear All Selection
                                    </button>
                                </div>
                            </React.Fragment>
                        ) : null
                    }
                </div>
            </ExpandableCard>
        );
    };

    renderTxnCard = () => {
        const { isTxnCardExpanded } = this.state;

        return (
            <ExpandableCard
                heading='First or Last 5 Transactions'
                extClasses='txns-exp-card'
                isExpanded={isTxnCardExpanded}
                setIsExpanded={this.toggleHandler('txn-card')}
            >
                <div className='txns-exp-card__cr2'>
                    <Button
                        primary
                        label='Get First 5 Transactions'
                        onClick={this.handleFirstOrLastSetSubmit({ firstSet: true })}
                    />
                    <Button
                        primary
                        label='Get Last 5 Transactions'
                        onClick={this.handleFirstOrLastSetSubmit({ lastSet: true })}
                    />
                </div>
                <div className='txns-first-last-set-cr'>
                    {this.renderSearchResults(true)}
                </div>
            </ExpandableCard>
        );
    };

    raiseDispute = () => {
        const {
            ticketId, userId, index, raiseDispute, questionList,
            raiseDisputeForCreditCardTxn, creditCardTransaction,
        } = this.props;
        const {
            channel, channelOption, selectedTxn, amount,
        } = this.state;
        const { data } = questionList[ticketId];

        const modifiedQuestionList = data.map((item) => ({
            question_code: item.code,
            question: item.question,
            answer: item.answer,
        }));

        const commonPayload = {
            ticketId,
            userId,
            index,
            txnId: selectedTxn.txn.intId,
            questionList: modifiedQuestionList,
        };

        if (creditCardTransaction) {
            raiseDisputeForCreditCardTxn({
                ...commonPayload,
                cardActionType: CARD_ACTION_TYPE.CARD_ACTION_TYPE_PROCESS_DISPUTE,
                disputeType: DISPUTE_TYPE.DISPUTE_TYPE_TRANSACTION,
                amount,
            });
        } else {
            raiseDispute({
                ...commonPayload,
                channel,
                channelOption,
            });
        }
    };

    renderDisputeRaisedSuccessScreen = (disputeData = {}) => {
        const {
            ticketId, resetAllState, index, toggleCloseTicketModal,
        } = this.props;
        const { message } = disputeData;

        return (
            <div className='txns-container txns-container--ss'>
                <div className='txns-rdc-hw'>
                    <div className='txns-rdc-hw__hd'>Raise Dispute</div>
                </div>
                <div className='txns-rdc-ss__hd'>{message}</div>
                <div className='frcsbWrapper'>
                    <Button
                        secondary
                        label='Go to Transactions'
                        onClick={() => { this.toggleHandler('dispute-flow')(); resetAllState({ ticketId }); }}
                    />
                    <Button
                        primary
                        label='Close Ticket'
                        onClick={toggleCloseTicketModal(index, true)}
                    />
                </div>
            </div>
        );
    };

    render() {
        const {
            ticketId, userId, index, disputeInfo, creditCardTransaction,
        } = this.props;
        const {
            isDisputeFlow, selectedTxn, channel, channelOption, amount,
        } = this.state;

        let reqdQuestionsFilled;
        let loading;
        let successfulDisputeRaised;
        let disputeData;

        if (disputeInfo[ticketId]) {
            ({
                loading, reqdQuestionsFilled, success: successfulDisputeRaised, data: disputeData,
            } = disputeInfo[ticketId]);
        }

        if (successfulDisputeRaised) {
            return this.renderDisputeRaisedSuccessScreen(disputeData);
        }

        const {
            componentVersions: {
                txnDispute: disputesVersion,
            },
        } = this.context;

        const areRequiredQuestionsAnswered = reqdQuestionsFilled && selectedTxn.txn;

        const disputeValidationForCreditCardTxn = !(areRequiredQuestionsAnswered && amount > 0);

        const disputeValidationForOtherTxn = !areRequiredQuestionsAnswered;

        const isDisputeSubmissionDisabled = creditCardTransaction ? disputeValidationForCreditCardTxn : disputeValidationForOtherTxn;

        return (
            <React.Fragment>
                <div className='txns-container'>
                    {
                        isDisputeFlow ? (
                            <DisputeQuestionnaire
                                ticketId={ticketId}
                                userId={userId}
                                index={index}
                                channel={channel}
                                channelOption={channelOption}
                                selectedTxn={selectedTxn}
                                handleChannelChange={this.handleChannelChange}
                                handleChannelOptionChange={this.handleChannelOptionChange}
                                toggleHandler={this.toggleHandler}
                                creditCardTransaction={creditCardTransaction}
                                onDisputeAmountChange={this.onDisputeAmountChange}
                            />
                        ) : null
                    }
                    {this.renderSearchCard(creditCardTransaction)}
                    {this.renderSearchResults()}
                    {/*
                        V2 Dispute flow no longer needs to show the Dispute Card.
                        Disputes are only raised for the txn list options dropdown.
                        Show disputes card only for version 1
                    */}
                    {(!isDisputeFlow && disputesVersion === 1)
                        ? this.renderDisputeCard()
                        : null}
                    {this.renderTxnCard()}
                    {
                        isDisputeFlow ? (
                            <Button
                                primary
                                disabled={isDisputeSubmissionDisabled}
                                extClasses='txns-search-cta txns-search-cta--dp'
                                label='Submit details for dispute'
                                onClick={this.raiseDispute}
                            />
                        ) : null
                    }
                </div>
                <Loader visible={loading} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    orderStatusList: makeSelectOrderStatusList,
    paymentProtocolList: makeSelectPaymentProtocolList,
    searchParameters: makeSelectSearchParameters,
    questionList: makeSelectQuestionList,
    disputeInfo: makeSelectDisputeInfo,
});

const mapDispatchToProps = (dispatch) => ({
    getOrderStatusList: (data) => dispatch(getOrderStatusListApi(data)),
    getPaymentProtocolList: (data) => dispatch(getPaymentProtocolListApi(data)),
    setSearchParams: (data) => dispatch(setSearchParamsApi(data)),
    resetSearchParams: (data) => dispatch(resetSearchParamsApi(data)),
    getTxnsInfo: (data) => dispatch(getTxnsInfoApi(data)),
    getChannelsInfo: (data) => dispatch(getChannelsInfoApi(data)),
    raiseDispute: (data) => dispatch(raiseTxnDispute(data)),
    resetAllState: (data) => dispatch(resetState(data)),
    attachEntityToTicket: (data) => dispatch(attachEntityToTicketApi(data)),
    getFirstOrLastSetTxns: (data) => dispatch(getFirstOrLastSetTxnList(data)),
    getFirstOrLastSetCreditCardTxns: (data) => dispatch(getFirstOrLastSetCreditCardTxnsListAction(data)),
    getCreditCardTxnsInfo: (data) => dispatch(getCreditCardTxnsInfoAction(data)),
    raiseDisputeForCreditCardTxn: (data) => dispatch(raiseDisputeForCreditCardTxnAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'transactions', reducer });
const withSaga = injectSaga({ key: 'transactions', saga });

Transactions.contextType = MetaInfoContext;

export default compose(
    withReducer,
    withSaga,
    withConnect,
)(Transactions);

/**
 *
 * QuestionStep - Component to handle the Question Step type and and all the different answer types we support
 *
 */

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import HelpCircle from '../../../assets/images/svgs/help-circle.svg';
import { Button, FormDropdown, FormWithSingleInput } from '../../../components';

import { ANSWER_TYPE, CONDITION_TYPE } from '../constants';
import { addSOPNodeResponseAction } from '../actions';

const QuestionStep = (props) => {
    const dispatch = useDispatch();
    const { step, currentStep, getNextNode } = props;
    const [answer, setAnswer] = useState('');

    const {
        description, questionText, faq, answerType, nextNode,
    } = step || {};

    const { next_step_condition_type: conditionType, mappings, answers } = nextNode;

    // Assigns an object of possible answers if answer type is checkbox otherwise assigns a string.
    useEffect(() => {
        if (answerType === ANSWER_TYPE.checkbox && conditionType === CONDITION_TYPE.simple) {
            const options = answers;
            const newAnswers = {};
            // eslint-disable-next-line no-unused-expressions
            options && options.forEach((option) => {
                newAnswers[option] = false;
            });
            setAnswer(newAnswers);
        } else {
            setAnswer('');
        }
    }, [step]);

    // Renders the options according to the answer type.
    const getAnswerOptions = useMemo(() => {
        if (conditionType === CONDITION_TYPE.label) {
            let options = mappings && Object.keys(mappings);
            if (answerType === ANSWER_TYPE.radioButton) {
                return options && Array.isArray(options) && options.map((item) => (
                    <Button
                        primary
                        v2
                        label={item}
                        onClick={() => {
                            dispatch(addSOPNodeResponseAction({ stepIndex: currentStep, value: item }));
                            getNextNode(mappings[item]);
                        }}
                        extClasses='sop-step-option'
                    />
                ));
            // eslint-disable-next-line no-else-return
            } else if (answerType === ANSWER_TYPE.dropdown) {
                options = options.map((option) => ({
                    label: option,
                    value: option,
                }));
                return (
                    <React.Fragment>
                        <FormDropdown
                            options={options}
                            input={{
                                value: answer,
                                onChange: (value) => setAnswer(value),
                                placeholder: 'Choose...',
                                isClearable: true,
                            }}
                            cacheKey='answer'
                            extStyles={{
                                container: 'mr-16',
                            }}
                        />
                        <div className='ml-15'>
                            <Button
                                primary
                                v2
                                label='Next'
                                onClick={() => {
                                    dispatch(addSOPNodeResponseAction({ stepIndex: currentStep, value: answer?.value }));
                                    getNextNode(mappings[answer?.value]);
                                }}
                            />
                        </div>
                    </React.Fragment>
                );
            } else {
                return '';
            }
        } else if (conditionType === CONDITION_TYPE.simple) {
            if (answerType === ANSWER_TYPE.freeform) {
                return (
                    <React.Fragment>
                        <FormWithSingleInput
                            disableFormSubmitOnEnter
                            hideErrLabel
                            extClasses={{
                                container: 'mr-16',
                            }}
                            input={{
                                value: answer,
                                onChange: (e) => setAnswer(e.target.value),
                            }}
                        />
                        <div className='ml-15'>
                            <Button
                                primary
                                v2
                                label='Next'
                                onClick={() => {
                                    dispatch(addSOPNodeResponseAction({ stepIndex: currentStep, value: answer }));
                                    getNextNode(mappings?.default);
                                }}
                            />
                        </div>
                    </React.Fragment>
                );
                // eslint-disable-next-line no-else-return
            } else if (answerType === ANSWER_TYPE.checkbox) {
                const options = answers;
                return (
                    <div>
                        {
                            options && options.map((item) => (
                                <div className='frcWrapper'>
                                    <div className='mr-16 mb-15'>
                                        <input
                                            type='checkbox'
                                            checked={answer[item]}
                                            onChange={() => setAnswer({ ...answer, [item]: !answer[item] })}
                                        />
                                    </div>
                                    <div className='mb-15'>{item}</div>
                                </div>
                            ))
                        }
                        <div className='p-20'>
                            <Button
                                primary
                                v2
                                label='Next'
                                onClick={() => {
                                    const currentAnswers = [];
                                    // eslint-disable-next-line no-unused-expressions
                                    answer && Object.keys(answer).forEach((key) => {
                                        if (answer[key]) currentAnswers.push(key);
                                    });
                                    dispatch(addSOPNodeResponseAction({ stepIndex: currentStep, value: currentAnswers }));
                                    getNextNode(mappings?.default);
                                }}
                            />
                        </div>
                    </div>
                );
            } else {
                return '';
            }
        } else {
            return '';
        }
    }, [step, answer]);

    return (
        <div className='frcsbWrapper'>
            <div>
                <div className='sop-step-no'>Step {currentStep + 1}</div>
                <div className='sop-step-title'>{questionText}</div>
                <div className='sop-step-des'>{description}</div>
                <div className='frwpWrapper mt-20'>
                    {getAnswerOptions}
                </div>
            </div>
            {
                faq && (
                    <div className='sop-step-faq'>
                        <div className='frcWrapper'>
                            <img src={HelpCircle} alt='Help Circle' />
                            FAQs
                        </div>
                        <div>
                            {faq}
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export const QuestionStepPropTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    questionText: PropTypes.string,
    faq: PropTypes.string,
    answerType: PropTypes.oneOf(Object.values(ANSWER_TYPE)),
    nodeId: PropTypes.string,
    nextNode: PropTypes.shape({
        next_step_condition_type: PropTypes.oneOf(Object.values(CONDITION_TYPE)),
        mappings: PropTypes.objectOf(
            PropTypes.string,
        ),
    }),
};

QuestionStep.propTypes = {
    step: PropTypes.shape(QuestionStepPropTypes).isRequired,
    currentStep: PropTypes.number.isRequired,
    getNextNode: PropTypes.func.isRequired,
};

export default QuestionStep;

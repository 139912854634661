/**
*
* FormBuilder
*
*/

import React from 'react';
import PropTypes from 'prop-types'
import { FIELD_TYPES } from './constants';
import { Field } from 'redux-form/immutable';
import InputText from '../InputText';
import PhoneNumberField from '../PhoneNumberField';
import './style.scss';
import FormDropdown from '../FormDropdown';
import InputName from '../InputName';
import Toggle from '../Toggle';

class FormBuilder extends React.Component { // eslint-disable-line react/prefer-stateless-function
    renderFormField = (field) => {
        if (field.hidden) {
            return null;
        }
        switch (field.type) {
            case FIELD_TYPES.INPUT:
                return <Field
                    name={field.name}
                    className={field.className || ''}
                    component={InputText}
                    {...field.props}
                />;
            case FIELD_TYPES.PHONE_NUMBER:
                return <Field
                    name={field.name}
                    className={field.className || ''}
                    component={PhoneNumberField}
                    {...field.props}
                />;
            case FIELD_TYPES.DROPDOWN:
                return <Field
                    name={field.name}
                    className={field.className || ''}
                    component={FormDropdown}
                    {...field.props}
                    cacheKey='agent'
                />;
            case FIELD_TYPES.NAME:
                return <Field
                    name={field.name}
                    className={field.className || ''}
                    component={InputName}
                    {...field.props}
                />
            case FIELD_TYPES.TOGGLE:
                return <Field
                    name={field.name}
                    className={field.className || ''}
                    component={Toggle}
                    {...field.props}
                />
            default:
                return null;
        }
    }

    render() {
        const { formStructure } = this.props;

        return (
            <div className='ca-form-wrapper'>
                {formStructure.map((field) => {
                    return this.renderFormField(field);
                })}
            </div>
        );
    }
}

FormBuilder.propTypes = {
    formStructure: PropTypes.array,
};

export default FormBuilder;

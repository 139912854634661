/**
 * @file Manages the action functions for Autopay component
 */

import {
    GET_EXISTING_MANDATE_DETAILS,
    GET_UPCOMING_MANDATE_DETAILS,
} from './constants';

export const getExistingMandateDetails = (data) => ({
    type: GET_EXISTING_MANDATE_DETAILS,
    data,
});

export const getUpcomingTransactionDetails = (data) => ({
    type: GET_UPCOMING_MANDATE_DETAILS,
    data,
});

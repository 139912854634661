/**
 *
 * Allows users to enter sms otp sent on their registered mobile number.
 *
 */

import React, { memo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// styling
import '../style.scss';
import { Button, FormWithSingleInputV2 } from '../../../components';
import { makeSelectUserInfo } from '../selectors';
import { validateSmsMfa } from '../actions';

const SmsMfa = () => {
    const dispatch = useDispatch();
    // global state
    const userInfo = useSelector(makeSelectUserInfo(), shallowEqual);
    // local state
    const [otpCode, setOtpCode] = useState('');
    const [errOtpCode, setErrOtpCode] = useState('');

    const handleOtpCode = (e) => {
        const { value } = e.target;
        if (isNaN(value)) return;

        setOtpCode(value);
        if (errOtpCode) setErrOtpCode('');
    };

    const validateInput = () => {
        let isValid = true;

        if (otpCode.length !== 6) {
            setErrOtpCode('Please enter a 6 digit otp code');
            isValid = false;
        }

        return isValid;
    };

    const handleOtpCodeSubmit = () => {
        const valid = validateInput();

        if (!valid) return;

        dispatch(validateSmsMfa({
            username: userInfo.username,
            email: userInfo.email,
            otpCode,
        }));
    };

    return (
        <div className='login-cr2'>
            <div className='login-header login-item--fit'>
                Enter SMS OTP code sent on { userInfo.challengeParameters.CODE_DELIVERY_DESTINATION }
            </div>
            <FormWithSingleInputV2
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'login-ff-cr',
                }}
                label='OTP Code'
                labelId='login-sms-mfa'
                input={{
                    value: otpCode,
                    onChange: handleOtpCode,
                    type: 'number',
                }}
                errLabel={errOtpCode}
                onFormSubmit={handleOtpCodeSubmit}
            />
            <Button
                extClasses='login-item'
                primary
                label={'Submit OTP Code'}
                onClick={handleOtpCodeSubmit}
            />
        </div>
    );
};

export default memo(SmsMfa);

/**
 * @file Manages the saga generator functions for Banners component
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { BANNERS_ROOT, BANNER_LIST_ENDPOINT } from '../../api/routes';

import {
    CREATE_BANNER, UPDATE_BANNER, SET_CREATE_OR_UPDATE_BANNER_LOADER, GET_BANNER_LIST, SET_BANNER_LIST, ERR_BANNER_LIST,
} from './constants';

function* createBanner(action) {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            BANNERS_ROOT,
            action.data,
        );

        toastify(response.message, 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
    } finally {
        yield put({ type: SET_CREATE_OR_UPDATE_BANNER_LOADER, data: { loading: false }});
    }
}

function* updateBanner(action) {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.put],
            BANNERS_ROOT,
            action.data,
        );

        toastify(response.message, 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
    } finally {
        yield put({ type: SET_CREATE_OR_UPDATE_BANNER_LOADER, data: { loading: false }});
    }
}

function* getBannerList(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${BANNER_LIST_ENDPOINT}?${queryString}`
        );

        yield put({ type: SET_BANNER_LIST, data: response });
    } catch (e) {
        yield put({ type: ERR_BANNER_LIST, data: { err: e.errMsgForUI } });
    }
}

export default function* bannersSaga() {
    yield all(
        [
            yield takeLatest(CREATE_BANNER, createBanner),
            yield takeLatest(UPDATE_BANNER, updateBanner),
            yield takeLatest(GET_BANNER_LIST, getBannerList),
        ]
    );
}

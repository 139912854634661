/**
 * @file User Risk Review
 * User can see the existing cases at a user level
 * User can select a case for review
 * For review, user can view different sections like facematch, liveness, etc. which is configurable from the backend RPC
 * Once review is done, user can dequeue the case
 */

import React, { memo } from 'react';

import { RenderView } from '../RiskOps';

import SingleCase from './SingleCase';
import { USER_RISK_REVIEW_KEY as CONTAINER_KEY, QUEUE_PAYLOAD_TYPES } from './constants';

import './style.scss';

const UserRiskReview = (props) => {
    const { caseId, actorId } = props;

    return (
        <RenderView
            singleCaseComponent={<SingleCase />}
            data={{
                containerKey: CONTAINER_KEY,
                queuePayloadTypes: QUEUE_PAYLOAD_TYPES,
                queueName: 'User Risk',
                caseId,
                actorId,
            }}
        />
    );
};

export default memo(UserRiskReview);

/**
 * @file Manages the selector functions for Wealth US Stocks component
 */

import { createSelector } from 'reselect';

import { WEALTH_USSTOCKS_KEY } from './constants';

export const selectUSSLoading = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'loading']),
    (substate) => substate,
);

export const selectUSSErr = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'err']),
    (substate) => substate,
);

export const selectUSSPortfolioDetails = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'investedAssetsList']),
    (substate) => substate?.toJS(),
);

export const selectUSSActivities = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'activities']),
    (substate) => substate?.toJS(),
);

export const selectUsStocksView = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'usStocksView']),
    (substate) => substate,
);

export const selectUSSSearchParams = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'searchParams']),
    (substate) => substate?.toJS(),
);

export const selectUSSActivityDetail = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'activityDetail']),
    (substate) => substate?.toJS(),
);

export const selectUSSActivityTypeList = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'activityTypes']),
    (substate) => substate?.toJS(),
);

export const selectUSSActivityType = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'activityType']),
    (substate) => substate?.toJS(),
);

export const selectUSSAccountBasicDetails = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'accountBasicDetails']),
    (substate) => substate?.toJS(),
);

export const selectUSSAccountStageDetails = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'accountStageDetails']),
    (substate) => substate?.toJS(),
);

export const selectUSSAccountView = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'accountView']),
    (substate) => substate,
);

export const selectRemittance = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'remittances']),
    (substate) => substate?.toJS(),
);

export const selectRemittanceTypes = createSelector(
    (state) => state.getIn([WEALTH_USSTOCKS_KEY, 'remittanceTypes']),
    (substate) => substate?.toJS(),
);

/**
 *
 * MessageList
 *
 */

import React, { memo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Modal from 'react-modal';

import { Button, Loader, Pagination } from '../../../components';

import { getSmsVendorInfo } from '../utils';
import { getMessageInfo as getMessageInfoApi, closeInfoModal } from '../actions';
import { makeSelectMsgList } from '../selectors';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content : {
        top: '12%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 28,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 800,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    }
};

const MessageList = (props) => {
    const dispatch = useDispatch();
    const msgList = useSelector(makeSelectMsgList(), shallowEqual);
    const { ticketId, userId, index, phoneNumber, handleSubmit } = props;

    let data;
    let prev;
    let next;
    let listErr;
    let loading;
    let showInfoModal;
    let selectedMsgData;

    if (msgList[ticketId]) {
        ({ data, prev, next, listErr, loading, showInfoModal, selectedMsgData } = msgList[ticketId]);
    }

    if (!data) {
        return <Loader visible={loading} />;
    }

    if (listErr) {
        return (
            <React.Fragment>
                <div className='container-err-label'>{listErr}</div>
                <Loader visible={loading} />
            </React.Fragment>
        );
    }

    if (data.length === 0) {
        return (
            <React.Fragment>
                <div className='table-nsr'>No search results found!</div>
                <Loader visible={loading} />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className='table-sr'>
                <div className='table-sr__label'>Search Results</div>
                <div className='table-sr__thw'>
                    <div className='table-sr__th'>Mobile No</div>
                    <div className='table-sr__th f15Wrapper'>Status</div>
                    <div className='table-sr__th'>QOS</div>
                    <div className='table-sr__th'>SMS Vendor</div>
                    <div className='table-sr__th'>SMS Type</div>
                    <div className='table-sr__th'>Retries</div>
                    <div className='table-sr__th'>Created At</div>
                    <div className='table-sr__th'>Updated At</div>
                    <div className='table-sr__th'>Actions</div>
                </div>
                <div className='table-sr__tlc'>
                    {
                        data.map(item => (
                            <div className='table-sr__tlw' key={item.vendorId}>
                                <div className='table-sr__tl'>{phoneNumber}</div>
                                <div className='table-sr__tl f15Wrapper'>{item.status}</div>
                                <div className='table-sr__tl'>{item.qos}</div>
                                <div className='table-sr__tl'>{item.vendor}</div>
                                <div className='table-sr__tl'>{item.type}</div>
                                <div className='table-sr__tl'>{item.retries}</div>
                                <div className='table-sr__tl table-sr__tl--nc'>{item.createdTs}</div>
                                <div className='table-sr__tl table-sr__tl--nc'>{item.updatedTs}</div>
                                <div
                                    className='link table-sr__tl'
                                    onClick={() => {
                                        dispatch(getMessageInfoApi({ ticketId, userId, index, id: item.vendorId }));
                                    }}
                                >
                                    View Details
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <Pagination prev={prev} next={next} onClickHandler={handleSubmit} />
            {
                showInfoModal ? (
                    <Modal
                        isOpen
                        style={customStylesForModal}
                        contentLabel='SMS Details Modal'
                    >
                        <div className='comms-modal-hwr'>SMS Details</div>
                        {
                            getSmsVendorInfo(selectedMsgData.smsVendorInfo).map(item => (
                                <div className='comms-modal-cwr' key={item.label}>
                                    <div className='comms-modal-cwr__cl'>{item.label}</div>
                                    <div className='comms-modal-cwr__sp'>:</div>
                                    <div className='comms-modal-cwr__cv'>{item.value}</div>
                                </div>
                            ))
                        }
                        {
                            selectedMsgData.messageInfo && selectedMsgData.messageInfo.metaInfo ? (
                                <div className='comms-modal-miwr'>
                                    <div className='comms-modal-miwr__cl'>Meta Info</div>
                                    <div className='comms-modal-miwr__cv'>
                                        {JSON.stringify(selectedMsgData.messageInfo.metaInfo, null, 4)}
                                    </div>
                                </div>
                                
                            ) : null
                        }
                        <Button primary extClasses='comms-modal-bwr' label='Close' onClick={() => dispatch(closeInfoModal({ ticketId }))} />
                    </Modal>
                ) : null
            }
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(MessageList);

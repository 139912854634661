import { fromJS } from 'immutable';

import {
    GET_RELATED_TICKETS, SET_RELATED_TICKETS, ERR_RELATED_TICKETS,
    MERGE_RELATED_TICKETS, SUCCESS_MERGE_RELATED_TICKETS,
} from './constants';

const initialState = fromJS({
    relatedTickets: {},
});

function userActionsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_RELATED_TICKETS:
        case MERGE_RELATED_TICKETS: {
            const { ticketId } = action.data;

            return state
                .setIn(['relatedTickets', ticketId, 'loading'], true);
        }
    

        case SET_RELATED_TICKETS: {
            const { ticketId, ticketsList } = action.data;

            return state
                .setIn(['relatedTickets', ticketId, 'loading'], false)
                .setIn(['relatedTickets', ticketId, 'data'], fromJS(ticketsList));
        }

        case ERR_RELATED_TICKETS:
        case SUCCESS_MERGE_RELATED_TICKETS: {
            const { ticketId } = action.data;

            return state
                .setIn(['relatedTickets', ticketId, 'loading'], false);
        }

        default:
            return state;
    }
}

export default userActionsReducer;

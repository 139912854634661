/**
 * 
 * Step 2 of forgot password flow: User enters OTP sent on their email along with the new password they want to set
 * 
 */

import React, { memo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
 
import '../../style.scss';
import { Button, FormWithSingleInput } from '../../../../components';
import { validators } from '../../../../utils';
import { setNewPasswordWithOtpCode } from '../../actions';

 import { makeSelectUserInfo } from '../../selectors';

const ConfirmNewPassword = () => {
    const dispatch = useDispatch();
    // global state
    const userInfo = useSelector(makeSelectUserInfo(), shallowEqual);
    // local state
    const [otpCode, setOtpCode] = useState(''); // also called confirmation code in AWS docs
    const [otpCodeErr, setOtpCodeErr] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errNewPassword, setErrNewPassword] = useState('');
    const [errConfirmPassword, setErrConfirmPassword] = useState('');

    const handleInputChange = (e, setValue, curError, setError) => {
        setValue(e.target.value);
        if (curError) setError('');
    };

    const validateNewPasswordInput = () => {
        let isValid = true;

        if (otpCode.length < 6) {
            setOtpCodeErr('Enter 6 digit confirmation code sent on your email');
            isValid = false;
        }

        if (!validators.isAWSPasswordValid(newPassword)) {
            setErrNewPassword('Password is not set according to required policy');
            isValid = false;
        }

        if (newPassword !== confirmPassword) {
            setErrConfirmPassword('Passwords do not match');
            isValid = false;
        }

        return isValid;
    };

    const handleNewPwdSubmit = () => {
        const valid = validateNewPasswordInput();

        if (!valid) return;

        dispatch(setNewPasswordWithOtpCode({
            otpCode,
            newPassword,
            email: userInfo.email,
        }));
    };

    return (
        <React.Fragment>
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'login-ff-cr',
                    label: 'login-ff-lb2',
                    errLabel: 'login-ff-errlb2',
                    inputField: 'login-ff-ip',
                }}
                label='OTP Code'
                labelId='otp-code'
                input={{
                    value: otpCode,
                    onChange: (e) => {
                        handleInputChange(
                            e,
                            setOtpCode,
                            otpCodeErr,
                            setOtpCodeErr,
                        );
                    },
                    type: 'number',
                }}
                errLabel={otpCodeErr}
                onFormSubmit={handleNewPwdSubmit}
            />
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'login-ff-cr',
                    label: 'login-ff-lb2',
                    errLabel: 'login-ff-errlb2',
                    inputField: 'login-ff-ip',
                }}
                label='New Password'
                labelId='login-reset-password'
                description='Minimum 8 characters. At least 1 numeric character, 1 special character, 1 uppercase character, 1 lowercase character'
                input={{
                    value: newPassword,
                    onChange: (e) => {
                        handleInputChange(
                            e,
                            setNewPassword,
                            errNewPassword,
                            setErrNewPassword,
                        );
                    },
                    type: 'password',
                }}
                errLabel={errNewPassword}
                onFormSubmit={handleNewPwdSubmit}
            />
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'login-ff-cr',
                    label: 'login-ff-lb2',
                    errLabel: 'login-ff-errlb2',
                    inputField: 'login-ff-ip',
                }}
                label='Confirm Password'
                labelId='login-confirm-reset-password'
                input={{
                    value: confirmPassword,
                    onChange: (e) => {
                        handleInputChange(
                            e,
                            setConfirmPassword,
                            errConfirmPassword,
                            setErrConfirmPassword,
                        );
                    },
                    type: 'password',
                }}
                errLabel={errConfirmPassword}
                onFormSubmit={handleNewPwdSubmit}
            />
            <Button
                extClasses='login-item'
                primary
                label={'Set New Password'}
                onClick={handleNewPwdSubmit}
            />
        </React.Fragment>
    );
};

export default memo(ConfirmNewPassword);

/**
 *
 * ClaimedRewardsView - Component to show the claimed rewards list by current user
 *
 */

import React, { useState } from 'react';
import classNames from 'classnames';

import { Pagination } from '../../../components';

import MarkAgainstTicketCheckbox from '../MarkAgainstTicketCheckbox';
import ClaimedRewardInfoModal from '../Modals/ClaimedRewardInfo';

const ClaimedRewardsView = (props) => {
    // local state variables
    const [selectedClaimedRewardDetails, setSelectedClaimedRewardDetails] = useState({});
    const [showClaimedRewardDetailsModal, setClaimedRewardDetailsModal] = useState(false);

    const {
        data, prev, next, rewardInfoData, getPaginatedData, getRewardInfo,
    } = props;

    if (!data) return null;

    const containerClass = classNames(
        'rewards-src3',
    );

    if (data.length === 0) {
        return (
            <div className={containerClass}>
                <div className='table-nsr table-nsr--mt0'>No search results found!</div>
            </div>
        );
    }

    const hasSelectedClaimedRewardsDetails = Object.keys(selectedClaimedRewardDetails).length;

    return (
        <React.Fragment>
            <div className={containerClass}>
                <div className='table-sr table-sr--mt0'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th f05Wrapper' />
                        <div className='table-sr__th'>Action Details</div>
                        <div className='table-sr__th'>Status</div>
                        <div className='table-sr__th'>Sub Status</div>
                        <div className='table-sr__th'>Created At</div>
                        <div className='table-sr__th'>Unlock At</div>
                        <div className='table-sr__th'>Reward Type</div>
                        <div className='table-sr__th'>Reward Value</div>
                        <div className='table-sr__th table-sr__th--actions' />
                    </div>
                    <div className='table-sr__tlc'>
                        {
                            data.map((item) => (
                                <div className='table-sr__tlw' key={item.id}>
                                    <div className='table-sr__tl f05Wrapper'>
                                        <MarkAgainstTicketCheckbox isClaimsView entityMeta={item.entityMeta} />
                                    </div>
                                    <div className='table-sr__tl'>{item.rewardOptions.actionDetails}</div>
                                    <div className='table-sr__tl'>{item.status}</div>
                                    <div className='table-sr__tl'>{item.subStatus}</div>
                                    <div className='table-sr__tl'>{item.createdTs}</div>
                                    <div className='table-sr__tl'>{item.rewardOptions.unlockTs}</div>
                                    <div className='table-sr__tl'>{item.chosenReward?.type}</div>
                                    <div className='table-sr__tl'>{item.chosenReward?.value}</div>
                                    <div
                                        role='presentation'
                                        className='table-sr__tl table-sr__tl--actions link'
                                        onClick={() => {
                                            setSelectedClaimedRewardDetails(item);
                                            setClaimedRewardDetailsModal(true);
                                            getRewardInfo(item);
                                        }}
                                    >
                                        View Details
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Pagination prev={prev} next={next} onClickHandler={getPaginatedData} />
            </div>
            <ClaimedRewardInfoModal
                visible={showClaimedRewardDetailsModal && hasSelectedClaimedRewardsDetails}
                data={selectedClaimedRewardDetails}
                rewardInfoData={rewardInfoData}
                toggleHandler={() => {
                    setSelectedClaimedRewardDetails({});
                    setClaimedRewardDetailsModal(false);
                }}
            />
        </React.Fragment>
    );
};

export default ClaimedRewardsView;

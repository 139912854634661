/**
 *
 * AppLogInfo
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import classNames from 'classnames';

import { Button, Loader } from '../../../components';
import { APP_LOGS_PATH } from '../../App/routes';

import { getLogInfo } from '../actions';
import { makeSelectLoading, makeSelectErr, makeSelectLogInfo } from '../selectors';
import '../style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: 0,
        left: 0,
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: '100%',
        height: '100%',
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const AppLogInfo = (props) => {
    const { search } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const searchParams = new URLSearchParams(search);
    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const { data, next, hasNext } = useSelector(makeSelectLogInfo(), shallowEqual);
    const [showFullScreenModal, setFullScreenModal] = useState(false);
    const { monorailId } = props;
    const logId = searchParams.get('logId');

    useEffect(() => {
        dispatch(getLogInfo({ logId, monorailId }));
    }, []);

    useEffect(() => {
        if (err.logInfo === 'Mandatory parameters are missing') history.push(APP_LOGS_PATH);
    }, [err, err.logInfo]);

    const renderLogs = (fullScreenMode) => {
        const contentCrClass = classNames({
            'apploginfo-cc': !fullScreenMode,
            'apploginfo-cc--modal': fullScreenMode,
        });

        const contentWrClass = classNames({
            'apploginfo-cc__cwr': true,
            'apploginfo-cc__cwr--modal': fullScreenMode,
        });

        return (
            <div className={contentCrClass}>
                {
                    data ? (
                        <div className={contentWrClass}>
                            {data}
                        </div>
                    ) : null
                }
                <div className='frcsbWrapper apploginfo-cc__fwr'>
                    {
                        hasNext ? (
                            <Button
                                extClasses='link'
                                label='Load More Logs'
                                onClick={() => {
                                    dispatch(getLogInfo({
                                        logId, next, monorailId,
                                    }));
                                }}
                            />
                        ) : null
                    }
                    {
                        data ? (
                            <React.Fragment>
                                <Button
                                    extClasses='link'
                                    label={`${!fullScreenMode ? 'Open' : 'Close'} Full Screen`}
                                    onClick={() => setFullScreenModal(!fullScreenMode)}
                                />
                                {
                                    !fullScreenMode ? (
                                        <Button
                                            extClasses='link'
                                            label='Download Logs'
                                            onClick={() => {
                                                dispatch(getLogInfo({
                                                    logId, monorailId, isDownloadFn: true,
                                                }));
                                            }}
                                        />
                                    ) : null
                                }
                            </React.Fragment>
                        ) : null
                    }
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className='applogs-container'>
                <div className='frcsbWrapper apploginfo-hw'>
                    <div className='apploginfo-hw__hl'>App Log Info</div>
                    <Button secondary onClick={() => history.push(APP_LOGS_PATH)} label='Back to list' />
                </div>
                {renderLogs()}
                {
                    err && err.logInfo ? (
                        <div className='container-err-label'>{err.logInfo}</div>
                    ) : null
                }
            </div>
            <Modal
                isOpen={showFullScreenModal}
                style={customStylesForModal}
                contentLabel='Full Screen Modal'
            >
                {renderLogs(true)}
            </Modal>
            <Loader visible={loading && loading.logInfo} />
        </React.Fragment>
    );
};

export default memo(AppLogInfo);

/**
 * @file Manages the selectors for Home component
 */

import { createSelector } from 'reselect';

const getHome = (state) => state.get('home');

export const selectHome = createSelector(
    getHome,
    (substate) => substate?.toJS(),
);

/**
 * @file ViewAnnotationDetails
 * Show annotation details for liveness review and facematch review with L2 payload type
 * When an analyst reviews a case, some cases are pushed to a new queue - L2 review queue
 * This is done for QA purpose to see how accurately the annotations are done
 * This component fetches the annotation details that the analyst has submitted and displays it
 *
 */
import React, { memo, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Button, ExpandableCard, FormDropdown, FormWithSingleInput, KVPairInfo,
} from '../../../components';
import { MetaInfoContext } from '../../../context';
import { useMemoizedSelector } from '../../../hooks';

import { PAYLOAD_TYPE_L2_LIVENESS } from '../../LivenessReviewV2/constants';

import { getRiskOpsL2AnnotationDetailsAction, submitRiskOpsQaReviewAction } from '../actions';
import { L2_ERROR_TYPE_OPTIONS, L2_FACEMATCH_VERDICT_OPTIONS, L2_LIVENESS_VERDICT_OPTIONS, SUB_CATEGORY_OPTIONS } from '../constants';
import { selectRiskOpsL2AnnotationDetails } from '../selectors';
import { getL2CaseDetailsKVData } from '../utils';

const ViewAnnotationDetails = (props) => {
    const { caseDetails } = props;
    const {
        isL2Queue, queryParams, getCasesQueryParams, containerKey, index,
    } = caseDetails;
    const { payloadType, elementId } = queryParams;

    const metaInfo = useContext(MetaInfoContext);
    const { componentVersions: { banners: bannersVersion } } = metaInfo;

    const dispatch = useDispatch();

    const l2AnnotationDetails = useMemoizedSelector(selectRiskOpsL2AnnotationDetails, [containerKey]);

    // local state
    const [isAnnotationDetailsCardExpanded, setAnnotationDetailsCardExpanded] = useState(true);
    const [reReviewVerdict, setReReviewVerdict] = useState('');
    const [errorType, setErrorType] = useState([]);
    const [errorSubCategory, setErrorSubCategory] = useState([]);
    const [remarks, setRemarks] = useState('');
    const [errLabel, setErrLabel] = useState('');

    const VERDICT_OPTIONS = payloadType === PAYLOAD_TYPE_L2_LIVENESS ? L2_LIVENESS_VERDICT_OPTIONS : L2_FACEMATCH_VERDICT_OPTIONS;

    useEffect(() => {
        // Fetch data from API when new element is loaded in the queue
        if (isL2Queue) {
            dispatch(getRiskOpsL2AnnotationDetailsAction({ queryParams, containerKey }));
        }
    }, [elementId]);

    // If no annotation details or if its not the L2 queue, don't show anything
    if (!l2AnnotationDetails || !isL2Queue) return null;

    const clearInputs = () => {
        setReReviewVerdict('');
        setErrorType([]);
        setErrorSubCategory([]);
        setRemarks('');
    };

    const handleSubmit = () => {
        if (!reReviewVerdict || !remarks) {
            setErrLabel('Please fill all * marked fields');

            return;
        }

        setErrLabel('');

        const requestPayload = {
            payload: {
                ...queryParams,
                reReviewVerdict: reReviewVerdict.value,
                errorType: errorType.map((errTypeItem) => errTypeItem.value),
                errorSubCategory: errorSubCategory.map((errSubCatItem) => errSubCatItem.value),
                remarks,
            },
            getCasesQueryParams,
            containerKey,
            index,
            customContext: {
                bannersVersion,
            },
        };

        dispatch(submitRiskOpsQaReviewAction(requestPayload));

        clearInputs();
    };

    return (
        <React.Fragment>
            <KVPairInfo
                title='Case Information'
                data={getL2CaseDetailsKVData(queryParams)}
                extClasses={{
                    container: 'rops-l2-kvcr',
                }}
            />
            <ExpandableCard
                extClasses='rops-ec my-30'
                extHeadingClasses='p-30'
                heading='Annotation Details'
                isExpanded={isAnnotationDetailsCardExpanded}
                setIsExpanded={() => setAnnotationDetailsCardExpanded(!isAnnotationDetailsCardExpanded)}
            >
                <div className='rops-l2-cr'>
                    {JSON.stringify(l2AnnotationDetails, null, 4)}
                </div>
            </ExpandableCard>
            <div className='my-30'>
                <FormDropdown
                    label='Re Review Verdict*'
                    options={VERDICT_OPTIONS}
                    input={{
                        value: reReviewVerdict,
                        onChange: setReReviewVerdict,
                        placeholder: 'Choose...',
                    }}
                    cacheKey='re-review-verdict'
                    extStyles={{
                        container: 'rops-l2-fc1 mb-15',
                        label: 'rops-l2-fc__lb',
                        inputField: 'rops-l2-fc__ip',
                    }}
                />
                <FormDropdown
                    isMulti
                    label='Error Type'
                    options={L2_ERROR_TYPE_OPTIONS}
                    input={{
                        value: errorType,
                        onChange: setErrorType,
                        placeholder: 'Choose...',
                    }}
                    cacheKey='re-review-error-type'
                    extStyles={{
                        container: 'rops-l2-fc1 mb-15',
                        label: 'rops-l2-fc__lb',
                        inputField: 'rops-l2-fc__ip',
                    }}
                />
                <FormDropdown
                    isMulti
                    label='Sub Category'
                    options={SUB_CATEGORY_OPTIONS}
                    input={{
                        value: errorSubCategory,
                        onChange: setErrorSubCategory,
                        placeholder: 'Choose...',
                    }}
                    cacheKey='re-review-error-sub-category'
                    extStyles={{
                        container: 'rops-l2-fc1 mb-15',
                        label: 'rops-l2-fc__lb',
                        inputField: 'rops-l2-fc__ip',
                    }}
                />
                <FormWithSingleInput
                    disableFormSubmitOnEnter
                    onFormSubmit={handleSubmit}
                    extClasses={{
                        container: 'rops-l2-fc2 mb-15',
                        label: 'rops-l2-fc__lb',
                        inputField: 'rops-l2-fc__ip',
                    }}
                    label='Remarks*'
                    labelId='re-review-remarks'
                    formType='text'
                    input={{
                        value: remarks,
                        onChange: (e) => setRemarks(e.target.value),
                    }}
                />
                {errLabel && <div className='err-label label1 rops-elb'>{errLabel}</div>}
                <Button
                    v2
                    label='Submit'
                    primary
                    onClick={handleSubmit}
                />
            </div>
        </React.Fragment>
    );
};

export default memo(ViewAnnotationDetails);

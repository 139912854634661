/**
 * @file RenderTicketInfo
 * Shows the freshdesk ticket information for the selected ticket ID
 */

import React, { memo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import GeneralKVPairInfo from '../../../components/GeneralKVPairInfo';

import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';
import { Button, Modal } from '../../../components';
import { useMemoizedSelector } from '../../../hooks';
import { makeSelectAgentInfo } from '../../AppV2/selectors';

import { getFreshdeskTicketInfoAction } from '../actions';
import { MODAL_STYLES, RenderTicketInfoClickItems } from '../constants';
import { selectFDTicketInfo } from '../selectors';

const RenderTicketInfo = (props) => {
    const { ticketId } = props;

    const dispatch = useDispatch();
    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);

    const fdTicketInfo = useMemoizedSelector(selectFDTicketInfo, [ticketId]);

    const [showModal, setShowModal] = useState(false);

    const showIssueDetails = () => {
        dispatch(getFreshdeskTicketInfoAction({ ticketId }));
        setShowModal(true);
    };

    const onClickHandler = (id) => () => {
        switch (id) {
            case RenderTicketInfoClickItems.GURU_LINK: {
                const properties = {};
                fdTicketInfo.forEach((ticketInfo) => {
                    const { label } = ticketInfo;
                    if (label === 'product_category' || label === 'subcategory' || label === 'product_category_detail') {
                        properties[ticketInfo.label] = ticketInfo.value.text;
                    }
                });
                properties.ticketId = ticketId;
                pushRudderEvent(RudderEvent.FreshDeskInfo.ClickedGuruCardLink, agentInfo.emailId, properties);
            }
                break;
            default: {
                // Default currently do not do anything but default onClick can be added later
            }
        }
    };

    return (
        <React.Fragment>
            <Button
                v2
                secondary
                extClasses='mb-30'
                label='Issue Details'
                onClick={showIssueDetails}
            />
            <Modal
                visible={showModal}
                modalStyles={MODAL_STYLES}
            >
                <GeneralKVPairInfo
                    valuesHaveHtml
                    data={fdTicketInfo}
                    title='Freshdesk Issue Details'
                    extClasses={{
                        container: 'fd-info-kv__cr',
                        titleContainer: 'fd-info-kv__hd',
                        contentLabel: 'fd-info-kv__vc',
                    }}
                    onClick={onClickHandler}
                />
                <div className='frccWrapper'>
                    <Button
                        v2
                        primary
                        label='Close'
                        onClick={() => setShowModal(false)}
                    />
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default memo(RenderTicketInfo);

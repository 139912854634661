import axios, { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';


function extractData({ status, data }) {
    if(status === 204 || status === 205) {
        return null;
    }
    return data;
}

const isUnauthorized = (response) => {
    return response.status === 401;
}

function checkStatus(error) {
    const { data } = error.response;
    let customError = new Error(data.message);

    // TODO: need to move redirect logic from app saga - Reference from ClientAPIWrapper
    /*
    if(isUnauthorized(error.response)) {
        window.location.replace(data.loginUrl);
    }
    */

    throw customError;
}

export default function request(url, options={}) {
    const source = CancelToken.source();
    const tempOptions = options;
    tempOptions.headers = options.headers || {};

    const axiosRequest = axios(url, {
        ...tempOptions,
        cancelToken: source.token,
    })
    .then(extractData)
    .catch(checkStatus)

    axiosRequest[CANCEL] = () => source.cancelToken();

    return axiosRequest;
}

/**
 * @file Manages the constants for Table component
 */
export const TABLECELL_DATA_TYPE = {
    string: 'DATA_TYPE_STRING',
    json: 'DATA_TYPE_JSON',
    labelValueMatrix: 'DATA_TYPE_LABEL_VALUE_MATRIX',
};

export const COLUMN_FILTER_TYPES = {
    select: 'COLUMN_FILTER_TYPE_SINGLE_SELECT',
    multiSelect: 'COLUMN_FILTER_TYPE_MULTI_SELECT',
    date: 'COLUMN_FILTER_TYPE_DATE_RANGE',
    dateAndTime: 'COLUMN_FILTER_TYPE_DATE_TIME_RANGE',
    text: 'COLUMN_FILTER_TYPE_TEXT',
    number: 'COLUMN_FILTER_TYPE_NUMBER_RANGE',
};

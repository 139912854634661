/*
 *
 * LivenessSummary actions
 *
 */

import {
    GET_LIVENESS_SUMMARY, SET_MONORAIL_ID,
} from './constants';

export const getLivenessSummary = data => ({
    type: GET_LIVENESS_SUMMARY,
    data
});

export const setMonorailId = data => ({
    type: SET_MONORAIL_ID,
    data
});

/**
 *
 * KycDocketReview:
 * Review a single wealth ops case for payload type PAYLOAD_TYPE_KYC_DOCKET & check the following:
 * 1. Check if pdf form against the data shown in the table
 * 2. If any issues, the analyst will need to take action offline
 *
 */

import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { DisableContextMenu, KVPairInfo, RenderDocument } from '../../../components';

import { selectWealthOpsCurCase } from '../selectors';

const getKycInfoList = (kycData, recordId) => {
    const kycFormData = kycData.kra_form_payload;
    const { year, month, day } = kycFormData.upload_date;
    const uploadDate = `${day}/${month}/${year}`;

    return [
        {
            label: 'Actor ID',
            value: kycData.actor_id,
        },
        {
            label: 'Record ID',
            value: recordId,
        },
        {
            label: 'PAN Number',
            value: kycData.pan,
        },
        {
            label: 'Manual Review ID',
            value: kycFormData.manual_review_id,
        },
        {
            label: 'Upload Date',
            value: uploadDate,
        },
        {
            label: 'KRA Status',
            value: kycFormData.kra_status,
        },
        {
            label: 'KRA Reject Reason',
            value: kycFormData.kra_reject_reason,
        },
        {
            label: 'Hold or Deactivate Remarks',
            value: kycFormData.hold_or_deactivate_remarks,
        },
        {
            label: 'KRA Update Remarks',
            value: kycFormData.kra_update_remarks,
        },
        {
            label: 'Pending User Data',
            value: kycFormData.pending_user_data,
        },
    ];
};

const KycDocketReview = () => {
    // Global state
    const curCase = useSelector(selectWealthOpsCurCase(), shallowEqual);

    const kycData = curCase?.kyc_docket_data;

    if (!kycData) return null;

    const kycFormDocuments = kycData?.kra_form_payload?.kyc_form?.photo;

    return (
        <React.Fragment>
            <KVPairInfo
                title='KYC Information'
                data={getKycInfoList(kycData, curCase.id)}
            />
            <DisableContextMenu>
                <div className='wops-pdf my-30'>
                    {kycFormDocuments.map((item, ocrIndex) => (
                        <RenderDocument
                            // eslint-disable-next-line react/no-array-index-key
                            key={ocrIndex}
                            index={ocrIndex}
                            disableToolbar
                            data={{
                                base64String: item.image_data_base64,
                                srcUrl: item.image_url,
                                docType: item.image_type,
                            }}
                        />
                    ))}
                </div>
            </DisableContextMenu>
        </React.Fragment>
    );
};

export default memo(KycDocketReview);

import { createSelector } from 'reselect';

const selectComms = state => state.get('communications');

const makeSelectMsgList = () => createSelector(
    selectComms,
    substate => substate.get('messageList').toJS()
);

export {
    makeSelectMsgList,
};

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { VIEW_SOP_USER_DETAILS_PATH, VIEW_SOP_USER_TICKETS_PATH } from '../../AppV2/routes';

import Profile from './Profile';
import Tickets from './Tickets';

const Customer = () => (
    <div className='sop-cont p-30'>
        <Switch>
            <Route path={VIEW_SOP_USER_DETAILS_PATH()}>
                <Profile />
            </Route>
            <Route exact path={VIEW_SOP_USER_TICKETS_PATH}>
                <Tickets />
            </Route>
        </Switch>
    </div>
);

export default Customer;

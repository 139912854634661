/**
 * @file Component to show the Wealth P2P Investment Transaction Failure Info
 */

import React, { memo } from 'react';

import { Button, KVPairInfo, Modal } from '../../../components';

const InvestmentTxnFailureInfo = (props) => {
    const { visible, data, toggleHandler } = props;

    if (!visible) return null;

    return (
        <Modal
            visible={visible}
            contentLabel='Investment Txn Failure Info Modal'
        >
            <KVPairInfo
                title='Failure Info'
                data={data}
                extClasses={{
                    container: 'wp2p-modal-kvpair',
                }}
            />
            <Button
                v2
                primary
                label='Close'
                onClick={toggleHandler}
                extClasses='wp2p-modal-cta'
            />
        </Modal>
    );
};

export default memo(InvestmentTxnFailureInfo);

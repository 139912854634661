/* eslint-disable import/no-extraneous-dependencies, jsx-a11y/media-has-caption */

/**
 *
 * Review a single wealth ops case & check the following:
 * 1. Check if the liveness video is correct
 * 2. Check if images are masked correctly
 * 3. Check if the expiry date is correct
 *
 */

import React, { useState, memo, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';

import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';
import { Button } from '../../../components';
import { MetaInfoContext } from '../../../context';

import WealthReview from '../WealthReview';
import KycDocketReview from '../KycDocketReview';

import { selectWealthOpsCurCase, selectWealthOpsPayloadType } from '../selectors';
import { dequeueWealthOpsCaseAction, setWealthOpsCurViewAction } from '../actions';
import { PAYLOAD_TYPE_KYC_DOCKET, PAYLOAD_TYPE_WEALTH_DATA, VIEW_ALL_WEALTH_OPS_CASES } from '../constants';
import { getActorId } from '../utils';

const removeQueueModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 500,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        backgroundColor: '#F5F5F5',
    },
};

const RenderInformation = () => {
    const payloadType = useSelector(selectWealthOpsPayloadType(), shallowEqual);

    switch (payloadType.value) {
        case PAYLOAD_TYPE_WEALTH_DATA: {
            return <WealthReview />;
        }

        case PAYLOAD_TYPE_KYC_DOCKET: {
            return <KycDocketReview />;
        }

        default:
            return null;
    }
};

const SingleCase = () => {
    const metaInfo = useContext(MetaInfoContext);
    const dispatch = useDispatch();

    // Global state
    const payloadType = useSelector(selectWealthOpsPayloadType(), shallowEqual);
    const curCase = useSelector(selectWealthOpsCurCase(), shallowEqual);

    // Local state
    const [showExecuteInfoModal, setExecuteInfoModal] = useState(false);

    // Extract information from the case
    const { index, id: recordId } = curCase;
    const { componentVersions: { banners: bannersVersion } } = metaInfo;

    const handleDequeueCase = () => {
        dispatch(dequeueWealthOpsCaseAction({
            actorId: getActorId(curCase), recordId, index, payloadType: payloadType.value, customContext: { bannersVersion },
        }));
        setExecuteInfoModal(false);
    };

    return (
        <React.Fragment>
            <div className='wops-hl__cr'>
                <button
                    className='wops-backarrow__cr'
                    type='button'
                    onClick={() => dispatch(setWealthOpsCurViewAction(VIEW_ALL_WEALTH_OPS_CASES))}
                >
                    <img
                        className='wops-backarrow'
                        alt='back-arrow'
                        src={backArrowIcon}
                    />
                </button>
                <div className='wops-hl1'>Review Wealth Ops Case</div>
            </div>

            <RenderInformation />

            <div className='frcWrapper'>
                <Button
                    v2
                    primary
                    label='Close Case'
                    onClick={() => setExecuteInfoModal(true)}
                />

                <div className='wops-hl2 ml-30'>Use this to load the next case once you finish reviewing the current case</div>
            </div>

            {/* Confirmation modal */}
            {showExecuteInfoModal && (
                <Modal
                    isOpen
                    style={removeQueueModalStyles}
                    contentLabel='Action Execution Modal'
                >
                    <div className='wops-modal__hl'>
                        Are you sure you want to dequeue this case?
                    </div>
                    <div className='wops-modal__sbhl'>
                        This change is irreversible and affects production data
                    </div>
                    <div className='frcsbWrapper wops-modal__ctas'>
                        <Button
                            v2
                            secondary
                            label='Submit'
                            onClick={handleDequeueCase}
                        />
                        <Button
                            v2
                            primary
                            label='Cancel'
                            onClick={() => setExecuteInfoModal(false)}
                        />
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default memo(SingleCase);

/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * @file RenderMediaV2
 * Renders different types of media used in user risk review
 * Media could include images, videos, etc.
 */

import React, {
    memo, useEffect, useState, useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import {
    RenderDocument, RenderVideo, ParameterList, KVPairInfo, ExpandableCard,
} from '../../../components';
import { isNilOrEmpty } from '../../../utils';

import { getMediaForUserRiskReviewAction } from '../actions';
import { MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO, PARAMETER_FILTERS_CONFIG } from '../constants';
import { UserDetailsContext } from '../../../context';
import { AWS_S3_PROXY_PREFIX } from '../../RiskOps/constants';
import HistoryCards from './HistoryCards';

const MediaUnit = (props) => {
    const { mediaInfo, setMediaSrcExt } = props;

    const dispatch = useDispatch();

    const [mediaSrc, setMediaSrc] = useState('');
    const [proxiedMediaSrc, setProxiedMediaSrc] = useState(mediaSrc);

    const userInfo = useContext(UserDetailsContext);

    const {
        basicInfo: {
            accessLevelInfo: {
                isFederalLoanLivenessReviewer,
            },
        },
    } = userInfo;

    function getProxiedUrl(videoUrlFromState, proxyNeeded) {
        if (!videoUrlFromState) {
            return videoUrlFromState;
        }
        // TODO : Find alternative to get the domain name to proxy to from config : [VEERAKUMAR] https://monorail.pointz.in/p/fi-app/issues/detail?id=47155
        const currentDomain = window.location.origin;
        // If the current access level is 'FEDERAL_LOAN_LIVENESS_REVIEWER'
        // then create the proxied url for the video url
        // This is needed because agents from federal are behind a VPN with S3 urls blocked
        // hence video urls for those roles are being proxied
        const videoUrlObject = new URL(videoUrlFromState);
        if (proxyNeeded) {
            // @example
            // videoUrlFromState - https://s3.ap-south-1.amazonaws.com/epifi-liveness/liveness_videos/{actor_id}/*
            // videoUrlObject.origin - https://s3.ap-south-1.amazonaws.com
            // proxiedVideoUrl - https://{sherlock_domain}/AWS_S3_Proxy/epifi-liveness/liveness_videos/{actor_id}/*
            const proxiedVideoUrl = videoUrlFromState.replace(videoUrlObject.origin, `${currentDomain}${AWS_S3_PROXY_PREFIX}`);
            return proxiedVideoUrl;
        }
        return videoUrlFromState;
    }

    useEffect(() => {
        dispatch(getMediaForUserRiskReviewAction({
            mediaInfo,
            setMediaSrc,
        }));
    }, [dispatch, mediaInfo, isFederalLoanLivenessReviewer]);

    useEffect(() => {
        if (setMediaSrcExt) { setMediaSrcExt(mediaSrc); }
        setProxiedMediaSrc(getProxiedUrl(mediaSrc, isFederalLoanLivenessReviewer));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaSrc, isFederalLoanLivenessReviewer]);

    if (isNilOrEmpty(mediaInfo)) return null;

    const {
        media_type: mediaType,
        base64_kyc_image_data: base64String,
    } = mediaInfo;

    const imgContainerClasses = classNames('frccWrapper', 'urr-imgWrapper', { 'urr-imgWrapper-h': (base64String || mediaSrc) });

    return (
        <React.Fragment>
            {mediaType === MEDIA_TYPE_VIDEO && (
                <div className='urr-video__cr'>
                    <RenderVideo
                        videoSrc={proxiedMediaSrc}
                        key={proxiedMediaSrc}
                    />
                </div>
            )}

            {mediaType === MEDIA_TYPE_IMAGE && (
                <div className={imgContainerClasses}>
                    <RenderDocument
                        index={0}
                        data={{
                            base64String,
                            srcUrl: mediaSrc,
                        }}
                        showNotFound
                    />
                </div>
            )}
        </React.Fragment>
    );
};

const getVideoUnit = (mediaInfo, setMediaSrcExt) => (
    <MediaUnit
        mediaInfo={mediaInfo}
        setMediaSrcExt={setMediaSrcExt}
    />
);

const getImageUnit = (mediaInfo) => (
    <MediaUnit
        key={mediaInfo.id}
        mediaInfo={mediaInfo}
    />
);

const RenderImage = (props) => {
    const { imageInfo } = props;

    return (
        <div className='urr-tab-container'>
            {imageInfo.map((info) => (
                <div>
                    {info.title ? <div className='heading3 text-center pb-20'>{info.title}</div> : ''}
                    {getImageUnit(info)}
                </div>
            ))}
        </div>
    );
};

const RenderMediaV2 = (props) => {
    const {
        mediaUnits, extClasses, setMediaSrcExt, currentReviewForm, onSubmit, infoData,
    } = props;

    const [mediaIndex, setMediaIndex] = useState(-1);
    const [info, setInfo] = useState([]);
    const [infoLabel, setInfoLabel] = useState('');
    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(true);
    const mediaInfo = mediaIndex >= 0 ? mediaUnits[mediaIndex] : null;

    const containerClass = classNames(' urr-rmV2-container frcseWrapper of-auto m-0-auto-40', extClasses && extClasses.container);
    const historyCardClass = classNames({
        'urr-rmV2-hc-container': mediaInfo && mediaInfo.media_type === MEDIA_TYPE_IMAGE,
    });

    const onClickHistoryCard = (index) => {
        setMediaIndex(index);
    };

    useEffect(() => {
        if (!isNilOrEmpty(mediaUnits)) {
            setMediaIndex(0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mediaIndex >= 0 && !!infoData.length) {
            const data = infoData[mediaIndex];
            const stringKeyValuePair = data.value.trim().split('\n');
            const obj = [];
            stringKeyValuePair.forEach((item) => {
                const [label, value] = item.split(':');
                obj.push({
                    label,
                    value,
                });
            });
            setInfoLabel(data.label || '');
            setInfo(obj);
        }
    }, [infoData, mediaIndex]);

    if (isNilOrEmpty(mediaUnits)) return null;

    return (
        <React.Fragment>
            <div className={containerClass}>
                <HistoryCards data={mediaUnits} className={historyCardClass} selectedIndex={mediaIndex} onClick={onClickHistoryCard} />
                {
                    mediaInfo && mediaInfo.media_type === MEDIA_TYPE_VIDEO
                        ? getVideoUnit(mediaInfo, setMediaSrcExt)
                        : null
                }
                {
                    mediaInfo && mediaInfo.media_type === MEDIA_TYPE_IMAGE
                        ? <RenderImage imageInfo={mediaInfo.items} />
                        : null
                }
            </div>
            <div className='urr-rmV2-expandable_card_wrapper'>
                {
                    mediaInfo
                        ? (
                            <ExpandableCard
                                isCollapsible={false}
                                version={2}
                                heading={infoLabel}
                                isExpanded={isSearchCardExpanded}
                                setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                                extClasses='urr-rmV2-expandable_card'
                            >
                                <KVPairInfo
                                    data={info}
                                    extClasses={{
                                        container: 'urr-kv-ca ml-15',
                                        contentLabel: 'urr-kv-cl',
                                        contentValue: 'urr-kv-cv',
                                        contentWr: 'urr-kv-cw',
                                    }}
                                />
                            </ExpandableCard>
                        )
                        : null
                }
                <ParameterList
                    defaultIsExpanded
                    config={{ ...PARAMETER_FILTERS_CONFIG, isCollapsible: false }}
                    data={currentReviewForm}
                    onSubmit={onSubmit}
                    extClasses='urr-rmV2-expandable_card'
                />
            </div>
        </React.Fragment>
    );
};

export default memo(RenderMediaV2);

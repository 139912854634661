/**
*
* PaginationV2 - Component to show the pagination of a table which contains results from a elastic search
*
*/

import React, { memo } from 'react';
import classNames from 'classnames';

import { ReactComponent as Arrow } from '../../assets/images/svgs/arrow.svg';

import Button from '../Button';

import './style.scss';

const PaginationV2 = (props) => {
    const {
        paginationParams: {
            from, size, totalResults,
        }, onClickHandler, enableScrollView = false,
    } = props;
    const page = (from / size) + 1;
    const totalPages = Math.ceil(totalResults / size);
    const hasPrevPage = page !== 1;
    const hasNextPage = page !== totalPages;

    const onPrevClick = () => {
        if (hasPrevPage) {
            onClickHandler({
                from, size, isPrev: true,
            });
        }
    };

    const onNextClick = () => {
        if (hasNextPage) {
            onClickHandler({
                from, size, isNext: true,
            });
        }
    };

    if (!hasPrevPage && !hasNextPage) {
        return null;
    }

    const tableContainerClass = classNames('pagination__v2-container', enableScrollView && 'pagination__v2-sv');
    const prevActionClass = classNames('pagination__v2-cta pagination__v2-cta-prev');
    const nextActionClass = classNames('pagination__v2-cta pagination__v2-cta-next');

    return (
        <div className={tableContainerClass}>
            <Button
                link
                extClasses={prevActionClass}
                label={<Arrow />}
                onClick={onPrevClick}
                disabled={!hasPrevPage}
            />
            <div>
                {page}/{totalPages}
            </div>
            <Button
                link
                extClasses={nextActionClass}
                label={<Arrow />}
                onClick={onNextClick}
                disabled={!hasNextPage}
            />
        </div>
    );
};

export default memo(PaginationV2);

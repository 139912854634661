/**
 * @file Constants for US stocks flow
 */

// container key
export const US_STOCKS_KEY = 'usStocks';

// API actions
export const GET_NAV_BAR_ENTITIES = `app/${US_STOCKS_KEY}/GET_NAV_BAR_ENTITIES`;
export const SET_NAV_BAR_ENTITIES = `app/${US_STOCKS_KEY}/SET_NAV_BAR_ENTITIES`;
export const ERR_US_STOCKS_NAV_BAR_ENTITIES = `app/${US_STOCKS_KEY}/ERR_US_STOCKS_NAV_BAR_ENTITIES`;

export const GET_FILE_ENTRIES = `app/${US_STOCKS_KEY}/GET_FILE_ENTRIES`;
export const SET_FILE_ENTRIES = `app/${US_STOCKS_KEY}/SET_FILE_ENTRIES`;
export const ERR_FILE_ENTRIES = `app/${US_STOCKS_KEY}/ERR_FILE_ENTRIES`;

export const GET_ADDITIONAL_ACTIONS = `app/${US_STOCKS_KEY}/GET_ADDITIONAL_ACTIONS`;
export const SET_ADDITIONAL_ACTIONS = `app/${US_STOCKS_KEY}/SET_ADDITIONAL_ACTIONS`;
export const ERR_ADDITIONAL_ACTIONS = `app/${US_STOCKS_KEY}/ERR_ADDITIONAL_ACTIONS`;

export const GET_US_STOCKS_TABLE_FILTERS = `app/${US_STOCKS_KEY}/GET_TABLE_FILTERS`;
export const SET_US_STOCKS_TABLE_FILTERS = `app/${US_STOCKS_KEY}/SET_TABLE_FILTERS`;
export const ERR_US_STOCKS_TABLE_FILTERS = `app/${US_STOCKS_KEY}/ERR_TABLE_FILTERS`;

export const GET_US_STOCKS_GENERATE_FILE = `app/${US_STOCKS_KEY}/GET_GENERATE`;
export const SET_US_STOCKS_GENERATE_FILE = `app/${US_STOCKS_KEY}/SET_GENERATE`;
export const ERR_US_STOCKS_GENERATE_FILE = `app/${US_STOCKS_KEY}/ERR_GENERATE`;

export const GET_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY = `app/${US_STOCKS_KEY}/GET_ACKNOWLEDGE_FILE_ENTRY`;
export const SET_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY = `app/${US_STOCKS_KEY}/SET_ACKNOWLEDGE_FILE_ENTRY`;
export const ERR_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY = `app/${US_STOCKS_KEY}/ERR_ACKNOWLEDGE_FILE_ENTRY`;

// UI actions
export const SET_US_STOCKS_SCREEN_ACTION_DATA = `app/${US_STOCKS_KEY}/SET_SCREEN_ACTION_DATA`;
export const SET_US_STOCKS_VIEW_DATA = `app/${US_STOCKS_KEY}/SET_VIEW_DATA`;
export const SET_US_STOCKS_FILE_TYPE = `app/${US_STOCKS_KEY}/SET_FILE_TYPE`;

// UI constants
export const PARAMETER_FILTERS_CONFIG = {
    cardTitle: 'Filters',
    paramsTitle: 'Fields',
    defaultIsExpanded: false,
    collapseOnSubmit: true,
    warningModal: {
        required: false,
        message: 'Are you sure you want to perform this action?',
        description: 'This change is irreversible and affects production data',
    },
};

export const US_STOCK_SCREENS = {
    FILE_ENTRIES: `view/${US_STOCKS_KEY}/FILE_ENTRIES`,
    SCREEN_ACTION: `view/${US_STOCKS_KEY}/SCREEN_ACTION`,
};

export const SCREEN_TYPES = {
    INPUT_SCREEN: 'SCREEN_TYPE_INPUT_SCREEN',
    FILE_ENTRY: 'SCREEN_TYPE_FILE_ENTRY',
    ACKNOWLEDGE: 'SCREEN_TYPE_ACKNOWLEDGE',
};

/**
 * @file Reducer for SalaryAccountOps
 */

import { fromJS } from 'immutable';

import {
    GET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS, SET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS, ERR_VIEW_SALARY_ACCOUNT_DETAILS, SET_SALARY_OPS_CUR_VIEW,
    VIEW_SALARY_ACCOUNT_DETAILS, SET_EMPLOYER_DETAILS_SALARY_ACCOUNT, GET_EMPLOYER_DETAILS_SALARY_ACCOUNT, UPDATE_EMPLOYER_DETAILS_SALARY_ACCOUNT,
    SET_SALARY_ACCOUNT_DETAILS_USER_IDENTIFIER, ERR_SALARY_OPS, SUCCESSFULLY_UPDATED_EMPLOYER_DETAILS, VIEW_VERIFICATION_CASES, SET_SALARY_OPS_ENUMS,
    GET_SALARY_VERIFICATION_CASES, SET_SALARY_VERIFICATION_CASES, SET_SALARY_VERIFICATION_CASE_DETAILS, SET_SALARY_VERIFICATION_NEW_EMPLOYER_DETAILS,
    GET_SALARY_VERIFICATION_CASE_EMPLOYER_DETAILS, SET_SALARY_VERIFICATION_CASE_EMPLOYER_DETAILS, UPDATE_SALARY_VERIFICATION_CASE_STATUS,
    SUCCESSFULLY_UPDATED_SALARY_VERIFICATION_CASE_STATUS, SET_SALARY_VERIFICATION_SEARCH_FILTER, GET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS,
    SET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS, GET_SALARY_VERIFICATION_REQUESTS_FOR_USER, SET_SALARY_VERIFICATION_REQUESTS_FOR_USER,
    GET_SALARY_EMPLOYER_DETAILS_FOR_USER, SET_SALARY_EMPLOYER_DETAILS_FOR_USER, VIEW_RAISE_VERIFICATION_CASES,
    SET_RAISE_VERIFICATION_REQUEST_FILTER, GET_RAISE_VERIFICATION_REQUEST_CASES, SET_RAISE_VERIFICATION_REQUEST_CASES,
    SET_RAISE_VERIFICATION_SELECTED_ACTOR, GET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS, SET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS,
    GET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS, SET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS, UPDATE_RAISE_VERIFICATION_REQUEST_STATUS,
    UPDATE_RAISE_VERIFICATION_REQUEST_STATUS_SUCCESS, SALARY_ACCOUNT_HEALTH_INSURANCE_KEY, GET_SALARY_ACTIVATION_STATUS,
    SET_SALARY_ACTIVATION_STATUS, GET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS, GET_USER_HEALTH_INSURANCE_POLICY_DETAILS,
    SET_USER_HEALTH_INSURANCE_POLICY_DETAILS, SET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS, ERROR_USER_HEALTH_INSURANCE_DETAILS,
    ERROR_GENERAL_HEALTH_INSURANCE_DETAILS, ERROR_SALARY_ACTIVATION_STATUS, SET_SALARY_VERIFICATION_CASE_TXN_HISTORY, GET_SALARY_ACCOUNT_MANDATES,
    SET_SALARY_ACCOUNT_MANDATES, ERR_SALARY_ACCOUNT_MANDATES, GET_SALARY_ACCOUNT_EXECUTED_MANDATES, SET_SALARY_ACCOUNT_EXECUTED_MANDATES,
    ERR_SALARY_ACCOUNT_EXECUTED_MANDATES, GET_SALARY_VERIFICATION_CASES_COUNT, SET_SALARY_VERIFICATION_CASES_COUNT, SET_SALARY_OPS_EMPLOYER_LIST,
    GET_SALARY_OPS_EMPLOYER_LIST, ERR_SALARY_OPS_EMPLOYER_LIST, GET_SALARY_OPS_ENUMS, SET_SALARY_VERIFICATION_CASES_EMPLOYER_NAME,
    SET_SALARY_VERIFICATION_CASES_EMPLOYER_TYPE, SET_IDENTIFIER_LIST, SET_ACTIVE_TAB_INDEX, SET_NEW_TAB_ACTIVE,
} from './constants';

const initialState = fromJS({
    accountDetails: {
        curView: VIEW_SALARY_ACCOUNT_DETAILS,
        loading: false,
        err: '',
        employerDetails: null,
        userIdentifier: {},
    },
    verificationQueue: {
        curView: VIEW_VERIFICATION_CASES,
        loading: false,
        err: '',
        caseEmployerDetails: null,
        newEmployerDetails: null,
        caseDetails: null,
        verificationCases: {},
        verificationCasesCount: {
            loading: false,
            value: null,
        },
        verificationStatus: {},
        userIdentifier: {},
        employerType: null,
        employerName: null,
        loadingEmployerList: false,
    },
    raiseVerificationRequest: {
        curView: VIEW_RAISE_VERIFICATION_CASES,
        loading: false,
        err: '',
        raiseVerificationStatus: {},
        raiseVerificationCases: {},
        raiseVerificationStage: {},
        selectedActor: {},
        raiseVerificationCaseDetails: {},
        raiseVerificationEligibleTxns: {},
        userIdentifier: {},
    },
    [SALARY_ACCOUNT_HEALTH_INSURANCE_KEY]: {},
    enums: {},
    tabs: {
        identifierList: [],
        newTabActive: true,
        activeTabIndex: 0,
    },
});

function salaryAccountOpsReducer(state = initialState, action = '') {
    switch (action.type) {
        case GET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS:
        case GET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS:
        case GET_SALARY_VERIFICATION_REQUESTS_FOR_USER:
        case GET_SALARY_EMPLOYER_DETAILS_FOR_USER: {
            const { screenKey, subScreen } = action.data;

            let modifiedState = state
                .setIn(['accountDetails', screenKey, 'loading'], true)
                .setIn(['accountDetails', screenKey, 'err'], '');

            if (subScreen === 'accountDetails') {
                modifiedState = modifiedState.setIn(['accountDetails', screenKey, 'salaryData'], fromJS({}));
            }

            if (subScreen === 'verificationRequests') {
                modifiedState = modifiedState.setIn(['accountDetails', screenKey, 'verificationRequests'], fromJS({}));
            }

            if (subScreen === 'employerDetails') {
                modifiedState = modifiedState.setIn(['accountDetails', screenKey, 'employerDetails'], fromJS({}));
            }

            return modifiedState;
        }

        case SET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS:
        case SET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS:
        case SET_SALARY_VERIFICATION_REQUESTS_FOR_USER:
        case SET_SALARY_EMPLOYER_DETAILS_FOR_USER: {
            const {
                screenKey, salaryData, verificationRequests, employerDetails,
            } = action.data;

            let modifiedState = state.setIn(['accountDetails', screenKey, 'loading'], false);

            if (salaryData) modifiedState = modifiedState.setIn(['accountDetails', screenKey, 'salaryData'], fromJS(salaryData));

            if (verificationRequests) {
                modifiedState = modifiedState.setIn(['accountDetails', screenKey, 'verificationRequests'], fromJS(verificationRequests));
            }

            if (employerDetails) {
                modifiedState = modifiedState.setIn(['accountDetails', screenKey, 'employerDetails'], fromJS(employerDetails));
            }

            return modifiedState;
        }

        case ERR_VIEW_SALARY_ACCOUNT_DETAILS: {
            const { screenKey, subScreen, err } = action.data;

            let modifiedState = state
                .setIn(['accountDetails', screenKey, 'loading'], false)
                .setIn(['accountDetails', screenKey, 'err'], err);

            if (subScreen === 'accountDetails') {
                modifiedState = modifiedState.setIn(['accountDetails', screenKey, 'salaryData'], fromJS({}));
            }

            if (subScreen === 'verificationRequests') {
                modifiedState = modifiedState.setIn(['accountDetails', screenKey, 'verificationRequests'], fromJS({}));
            }

            if (subScreen === 'employerDetails') {
                modifiedState = modifiedState.setIn(['accountDetails', screenKey, 'employerDetails'], fromJS({}));
            }

            return modifiedState;
        }

        case ERR_SALARY_OPS:
        case ERROR_USER_HEALTH_INSURANCE_DETAILS:
        case ERROR_GENERAL_HEALTH_INSURANCE_DETAILS:
        case ERROR_SALARY_ACTIVATION_STATUS: {
            const { screenKey, setterArray, err } = action.data;

            if (setterArray) {
                return state
                    .setIn([...setterArray, 'loading'], false)
                    .setIn([...setterArray, 'err'], err);
            }

            return state
                .setIn([screenKey, 'loading'], false)
                .setIn([screenKey, 'err'], err);
        }

        // non api state changes
        case SET_SALARY_VERIFICATION_CASES_EMPLOYER_TYPE:
        case SET_SALARY_VERIFICATION_CASES_EMPLOYER_NAME:
        case SET_SALARY_OPS_CUR_VIEW:
        case SET_SALARY_ACCOUNT_DETAILS_USER_IDENTIFIER:
        case SET_SALARY_VERIFICATION_CASE_DETAILS:
        case SET_SALARY_VERIFICATION_NEW_EMPLOYER_DETAILS:
        case SET_SALARY_VERIFICATION_SEARCH_FILTER:
        case SET_RAISE_VERIFICATION_REQUEST_FILTER:
        case SET_RAISE_VERIFICATION_SELECTED_ACTOR: {
            const { screenKey, key, value } = action.data;

            return state
                .setIn([screenKey, key], fromJS(value));
        }

        // api getter changes
        case GET_EMPLOYER_DETAILS_SALARY_ACCOUNT:
        case UPDATE_EMPLOYER_DETAILS_SALARY_ACCOUNT:
        case GET_SALARY_VERIFICATION_CASES:
        case GET_SALARY_VERIFICATION_CASES_COUNT:
        case GET_SALARY_VERIFICATION_CASE_EMPLOYER_DETAILS:
        case UPDATE_SALARY_VERIFICATION_CASE_STATUS:
        case GET_RAISE_VERIFICATION_REQUEST_CASES:
        case GET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS:
        case GET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS:
        case UPDATE_RAISE_VERIFICATION_REQUEST_STATUS:
        case GET_USER_HEALTH_INSURANCE_POLICY_DETAILS:
        case GET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS:
        case GET_SALARY_ACTIVATION_STATUS: {
            const {
                screenKey, key, setterArray, resetValue,
            } = action.data;

            let modifiedState = state;

            if (screenKey) {
                modifiedState = modifiedState
                    .setIn([screenKey, 'loading'], true)
                    .setIn([screenKey, 'err'], '');

                if (key && resetValue !== undefined) {
                    modifiedState = modifiedState
                        .setIn([screenKey, key], fromJS(resetValue));
                }
            }

            if (setterArray) {
                modifiedState = modifiedState
                    .setIn([...setterArray, 'loading'], true)
                    .setIn([...setterArray, 'err'], '');

                if (resetValue !== undefined) {
                    modifiedState = modifiedState
                        .setIn([...setterArray, 'value'], fromJS(resetValue));
                }
            }

            return modifiedState;
        }

        // api setter changes
        case SUCCESSFULLY_UPDATED_EMPLOYER_DETAILS:
        case SET_EMPLOYER_DETAILS_SALARY_ACCOUNT:
        case SET_SALARY_VERIFICATION_CASES:
        case SET_SALARY_VERIFICATION_CASES_COUNT:
        case SET_SALARY_VERIFICATION_CASE_EMPLOYER_DETAILS:
        case SET_SALARY_VERIFICATION_CASE_TXN_HISTORY:
        case SUCCESSFULLY_UPDATED_SALARY_VERIFICATION_CASE_STATUS:
        case SET_RAISE_VERIFICATION_REQUEST_CASES:
        case SET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS:
        case SET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS:
        case UPDATE_RAISE_VERIFICATION_REQUEST_STATUS_SUCCESS:
        case SET_USER_HEALTH_INSURANCE_POLICY_DETAILS:
        case SET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS:
        case SET_SALARY_ACTIVATION_STATUS: {
            const {
                screenKey, key, setterArray, value,
            } = action.data;

            let modifiedState = state;

            if (screenKey) {
                modifiedState = modifiedState
                    .setIn([screenKey, 'loading'], false);

                if (key && value !== undefined) {
                    modifiedState = modifiedState
                        .setIn([screenKey, key], fromJS(value));
                }
            }

            if (setterArray) {
                modifiedState = modifiedState
                    .setIn([...setterArray, 'loading'], false);

                if (value !== undefined) {
                    modifiedState = modifiedState
                        .setIn([...setterArray, 'value'], fromJS(value));
                }
            }

            return modifiedState;
        }

        case GET_SALARY_OPS_ENUMS: {
            // Adding the loading option here to the first screen so that only after enums are loading then we can access the screens
            return state.setIn(['accountDetails', 'loading'], true);
        }

        case SET_SALARY_OPS_ENUMS: {
            const { enums } = action.data;
            const { verificationStatusList, raiseVerificationStatusList, raiseVerificationStageList } = enums;

            return state
                .setIn(['enums'], fromJS(enums))
                .setIn(['verificationQueue', 'verificationStatus'], fromJS(verificationStatusList[0]))
                .setIn(['raiseVerificationRequest', 'raiseVerificationStatus'], fromJS(raiseVerificationStatusList[0]))
                .setIn(['raiseVerificationRequest', 'raiseVerificationStage'], fromJS(raiseVerificationStageList[1]))
                .setIn(['accountDetails', 'loading'], false);
        }

        case GET_SALARY_ACCOUNT_MANDATES: {
            const { screenKey } = action.data;
            const key = ['mandateDetails', screenKey];
            const values = {
                loading: true,
                err: '',
                data: fromJS({}),
                hasData: false,
            };
            return state.mergeIn(key, values);
        }

        case SET_SALARY_ACCOUNT_MANDATES: {
            const { data, screenKey } = action.data;
            let hasData = true;
            if (!data) hasData = false;
            const key = ['mandateDetails', screenKey];
            const values = {
                loading: false,
                data: fromJS(data),
                hasData,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case ERR_SALARY_ACCOUNT_MANDATES: {
            const { err, screenKey } = action.data;
            const key = ['mandateDetails', screenKey];
            const values = {
                loading: false,
                data: fromJS({}),
                hasData: false,
                err,
            };
            return state.mergeIn(key, values);
        }

        case GET_SALARY_ACCOUNT_EXECUTED_MANDATES: {
            const { screenKey } = action.data;
            const key = ['executedMandateDetails', screenKey];
            const values = {
                loading: true,
                err: '',
                data: fromJS({}),
                hasData: false,
            };
            return state.mergeIn(key, values);
        }

        case SET_SALARY_ACCOUNT_EXECUTED_MANDATES: {
            const { data, screenKey } = action.data;
            let hasData = true;
            if (!data) hasData = false;
            const key = ['executedMandateDetails', screenKey];
            const values = {
                loading: false,
                data: fromJS(data),
                hasData,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case ERR_SALARY_ACCOUNT_EXECUTED_MANDATES: {
            const { err, screenKey } = action.data;
            const key = ['executedMandateDetails', screenKey];
            const values = {
                loading: false,
                data: fromJS({}),
                hasData: false,
                err,
            };
            return state.mergeIn(key, values);
        }

        case GET_SALARY_OPS_EMPLOYER_LIST: {
            const key = ['verificationQueue'];
            const values = {
                loadingEmployerList: true,
                employerNameList: [],
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case SET_SALARY_OPS_EMPLOYER_LIST: {
            let { employer_details: employerDetails } = action.data;
            if (employerDetails) {
                employerDetails = employerDetails.map((employer) => ({
                    label: employer.name_by_source,
                    value: employer.employer_id,
                }));
            }
            const key = ['verificationQueue'];
            const values = {
                loadingEmployerList: false,
                employerNameList: employerDetails,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case ERR_SALARY_OPS_EMPLOYER_LIST: {
            const { err } = action.data;
            const key = ['verificationQueue'];
            const values = {
                loadingEmployerList: false,
                employerNameList: [],
                err,
            };
            return state.mergeIn(key, values);
        }

        case SET_IDENTIFIER_LIST: {
            const { index, data, isActive = false } = action.data;
            let modifiedIdentifierList = state.getIn(['tabs', 'identifierList']).toJS();

            if (modifiedIdentifierList && Array.isArray(modifiedIdentifierList)) {
                if (data) {
                    if (modifiedIdentifierList.length - 1 < index) {
                        modifiedIdentifierList.push(data);
                    } else {
                        modifiedIdentifierList[index] = data;
                    }
                } else {
                    modifiedIdentifierList = modifiedIdentifierList.filter((item, idx) => idx !== index);
                }
            }

            if (isActive) {
                return state
                    .setIn(['tabs', 'identifierList'], fromJS(modifiedIdentifierList))
                    .setIn(['tabs', 'activeTabIndex'], index);
            }
            return state.setIn(['tabs', 'identifierList'], fromJS(modifiedIdentifierList));
        }

        case SET_ACTIVE_TAB_INDEX: {
            const { index } = action.data;
            return state.setIn(['tabs', 'activeTabIndex'], index);
        }

        case SET_NEW_TAB_ACTIVE: {
            const { value } = action.data;
            return state.setIn(['tabs', 'newTabActive'], value);
        }

        default:
            return state;
    }
}

export default salaryAccountOpsReducer;

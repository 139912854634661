/**
 * @file Manages the reducer state & function for Instant Indexing component
 */

import { fromJS } from 'immutable';

import {
    INITIATE_UPDATE_URL, SET_UPDATE_URL, END_UPDATE_URL, GET_URL_STATUS, SET_URL_STATUS, END_URL_STATUS,
} from './constants';

const initialState = fromJS({
    isLoading: false,
    updatedUrlInfo: {},
    urlStatusInfo: {},
});

function instantIndexingReducer(state = initialState, action) {
    switch (action.type) {
        case INITIATE_UPDATE_URL:
            return state.set('isLoading', true);

        case SET_UPDATE_URL: {
            const { updatedUrlInfo } = action.data;

            return state
                .set('isLoading', false)
                .setIn(['updatedUrlInfo', 'hasInfo'], true)
                .setIn(['updatedUrlInfo', 'data'], fromJS(updatedUrlInfo));
        }
        
        case END_UPDATE_URL:
            return state
                .set('isLoading', false)
                .set(['updatedUrlInfo'], fromJS({}));

        case GET_URL_STATUS:
            return state.set('isLoading', true);
        
        case SET_URL_STATUS: {
            const { statusInfo } = action.data;

            return state
                .set('isLoading', false)
                .setIn(['urlStatusInfo', 'hasInfo'], true)
                .setIn(['urlStatusInfo', 'data'], fromJS(statusInfo));
        }

        case END_URL_STATUS:
            return state
                .set('isLoading', false)
                .set('urlStatusInfo', fromJS({}));

        default:
            return state;
    }
}

export default instantIndexingReducer;

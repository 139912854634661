/**
 * @file Manages the constants for Wealth P2P component
 */

// key to be used for reducers & selectors for wealth p2p
export const WEALTH_P2P_KEY = 'wealthP2P';

// p2p investor info
export const GET_INVESTOR_INFO = `app/${WEALTH_P2P_KEY}/GET_INVESTOR_INFO`;
export const SET_INVESTOR_INFO = `app/${WEALTH_P2P_KEY}/SET_INVESTOR_INFO`;
export const ERR_INVESTOR_INFO = `app/${WEALTH_P2P_KEY}/ERR_INVESTOR_INFO`;

// p2p investment list
export const GET_INVESTMENT_LIST = `app/${WEALTH_P2P_KEY}/GET_INVESTMENT_LIST`;
export const SET_INVESTMENT_LIST = `app/${WEALTH_P2P_KEY}/SET_INVESTMENT_LIST`;
export const ERR_INVESTMENT_LIST = `app/${WEALTH_P2P_KEY}/ERR_INVESTMENT_LIST`;

export const INVESTMENT_LIST_LABEL_DATA = [
    {
        label: 'Txn Timestamp',
        key: 'txnAt',
    },
    {
        label: 'Txn Type',
        key: 'txnType',
    },
    {
        label: 'Status',
        key: 'status',
    },
    {
        label: 'Order Id',
        key: 'orderId',
    },
    {
        label: 'Fi UTR Number',
        key: 'utr',
    },
    {
        label: 'Liquiloan Scheme Id',
        key: 'schemeId',
    },
    {
        label: 'Created At',
        key: 'createdAt',
    },
    {
        label: 'Updated At',
        key: 'updatedAt',
    },
    {
        label: 'ETA',
        key: 'eta',
    },
];

export const INVESTOR_INFO_LABEL_DATA = [
    {
        label: 'Plans',
        key: 'planName',
    },
    {
        label: 'User eligible?',
        key: 'isUserEligible',
    },
    {
        label: 'Reason for ineligibility',
        key: 'ineligibilityReason',
    },
    {
        label: 'Unlocked limit',
        key: 'unlockedLimit',
    },
    {
        label: 'Limit left',
        key: 'limitLeft',
    },
];

/**
 * @file CxRiskReview constants
 */

import { QUEUE_PAYLOAD_TYPES } from '../LivenessReviewV2/constants';

export const CX_RISK_REVIEW_KEY = 'cxRiskReview';

export const GET_CX_RISK_REVIEW_TICKETS = `app/${CX_RISK_REVIEW_KEY}/GET_TICKETS`;
export const SET_CX_RISK_REVIEW_TICKETS = `app/${CX_RISK_REVIEW_KEY}/SET_TICKETS`;

export const GET_CX_RISK_REVIEW_REQUESTS_FOR_ACTOR = `app/${CX_RISK_REVIEW_KEY}/GET_REQUESTS_FOR_ACTOR`;
export const SET_CX_RISK_REVIEW_REQUESTS_FOR_ACTOR = `app/${CX_RISK_REVIEW_KEY}/SET_REQUESTS_FOR_ACTOR`;

export const SET_CX_RISK_REVIEW_ERR = `app/${CX_RISK_REVIEW_KEY}/SET_ERR`;
export const SET_CX_RISK_REVIEW_CUR_VIEW = `app/${CX_RISK_REVIEW_KEY}/SET_CUR_VIEW`;
export const SET_CX_RISK_REVIEW_CUR_TICKET_DETAILS = `app/${CX_RISK_REVIEW_KEY}/SET_CUR_TICKET_DETAILS`;

export const VIEW_ALL_TICKETS = `app/${CX_RISK_REVIEW_KEY}/VIEW/ALL_TICKETS`;
export const VIEW_ALL_REQUESTS_FOR_TICKET = `app/${CX_RISK_REVIEW_KEY}/VIEW/ALL_REQUESTS_FOR_TICKET`;
export const VIEW_SINGLE_CASE = `app/${CX_RISK_REVIEW_KEY}/VIEW/SINGLE_CASE`;

export const TICKET_FILTERS = {
    dateRange: {
        days: 89,
        fromKey: 'from_time',
        toKey: 'to_time',
    },
    statusList: [
        'STATUS_OPEN',
        'STATUS_PENDING',
    ],
    groupList: [
        'GROUP_RISK_OPS',
    ],
};

export const CX_TICKET_LIST_LABEL_DATA = [
    {
        label: 'Ticket ID',
        key: 'ticketId',
    },
    {
        label: 'Actor Id',
        key: 'actorId',
    },
    {
        label: 'Status',
        key: 'status',
    },
    {
        label: 'Reported At',
        key: 'reportedAt',
    },
    {
        label: 'Updated At',
        key: 'updatedAt',
    },
];

export const CX_REQUESTS_LABEL_DATA = [
    {
        label: 'Sr. No',
        key: 'sr_no',
    },
    {
        label: 'Request ID',
        key: 'request_id',
    },
    {
        label: 'Created At',
        key: 'created_at',
    },
];

export const CX_PAYLOAD_TYPE_OPTIONS = QUEUE_PAYLOAD_TYPES.map((item) => ({
    label: item,
    value: item,
}));

/**
 * @file RecentActivity/constants.js - Contains reducers used for recent activity
 */
import { fromJS } from 'immutable';

import {
    SET_RECENT_ACTIVITY,
    GET_RECENT_ACTIVITY_DETAILS,
    SET_RECENT_ACTIVITY_DETAILS,
    RECENT_ACTIVITY_DETAILS_KEY,
    SET_RECENT_ACTIVITY_AREA,
    SET_LOADER,
    SET_ERROR,
} from './constants';

const initialState = fromJS({
    loading: false,
    activityAreas: [],
    errorScreenVisibility: false,
});

/**
 * ES Lint is necessary in below line because the first parameter is always state
 * that parameter needs to have an initial state.
 */
// eslint-disable-next-line default-param-last
function RecentActivityReducer(state = initialState, action) {
    switch (action.type) {
        case SET_RECENT_ACTIVITY_AREA: {
            const { recentActivities, loading } = action.data;
            return state
                .setIn(['activityAreas'], fromJS(recentActivities))
                .setIn(['loading'], loading);
        }

        case SET_RECENT_ACTIVITY: {
            const {
                activityDetails,
                next,
                prev,
                loading,
            } = action.data;
            return state
                .setIn(['data'], fromJS(activityDetails))
                .setIn(['next'], fromJS(next))
                .setIn(['prev'], fromJS(prev))
                .setIn(['loading'], loading);
        }

        case GET_RECENT_ACTIVITY_DETAILS: {
            const { ticketId } = action.data;

            return state
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'data'], fromJS([]))
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'loading'], true)
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'err'], '');
        }

        case SET_RECENT_ACTIVITY_DETAILS: {
            const {
                activityDetails, prev, next, loading,
            } = action.data;

            return state
                .setIn(['data'], fromJS(activityDetails))
                .setIn(['prev'], fromJS(prev))
                .setIn(['next'], fromJS(next))
                .setIn(['loading'], loading);
        }

        case SET_LOADER: {
            const {
                loading,
            } = action.data;
            return state
                .setIn(['loading'], loading);
        }

        case SET_ERROR: {
            const {
                error, loading, activityDetails, errorScreenVisibility,
            } = action.data;
            return state.setIn(['error'], error)
                .setIn(['loading'], loading)
                .setIn(['data'], fromJS(activityDetails))
                .setIn(['errorScreenVisibility'], errorScreenVisibility);
        }

        default:
            return state;
    }
}

export default RecentActivityReducer;

import { useEffect } from 'react';

/**
 * This custom hook is used to scroll the window to a specified position
 * whenever the x or y scroll position changes. By default, it scrolls
 * the window to the top left corner of the page (scrollX = 0, scrollY = 0).
 *
 * @param {number} scrollX - The x-coordinate of the position to scroll to.
 * @param {number} scrollY - The y-coordinate of the position to scroll to.
 */
const useScrollToPosition = (scrollX = 0, scrollY = 0) => {
    useEffect(() => {
        window.scrollTo(scrollX, scrollY);
    }, [scrollX, scrollY]);

    return null;
};

export default useScrollToPosition;

import { createSelector } from 'reselect';

const selectPayoutRequests = state => state.get('payoutRequests');

const makeSelectLoading = () => createSelector(
    selectPayoutRequests,
    substate => substate.get('loading')
);

const makeSelectRaisedPayoutRequests = () => createSelector(
    selectPayoutRequests,
    substate => substate.get('raisedPayoutRequests').toJS()
);

const makeSelectPendingPayoutRequests = () => createSelector(
    selectPayoutRequests,
    substate => substate.get('pendingPayoutRequests').toJS()
);

const makeSelectAllPayoutRequests = () => createSelector(
    selectPayoutRequests,
    substate => substate.get('allPayoutRequests').toJS()
);

const makeSelectPrev = () => createSelector(
    selectPayoutRequests,
    substate => substate.get('prev').toJS()
);

const makeSelectNext = () => createSelector(
    selectPayoutRequests,
    substate => substate.get('next').toJS()
);


export {
    makeSelectLoading,
    makeSelectRaisedPayoutRequests,
    makeSelectPendingPayoutRequests,
    makeSelectAllPayoutRequests,
    makeSelectPrev,
    makeSelectNext,
};

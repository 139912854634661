/**
 * @file AllCases
 * Show all the cases for a particular queue
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { AppDropdown, Button, DateRangePicker, Table } from '../../../components';
import { useMemoizedSelector } from '../../../hooks';

import { getQueueSpecificInfo, getRiskOpsTableLabels } from '../utils';
import { setRiskOpsPayloadTypeAction, setRiskOpsQueueFiltersAction } from '../actions';
import {
    selectRiskOpsCasesList, selectRiskOpsPayloadType, selectRiskOpsPendingReviewCount, selectRiskOpsQueueFilters,
} from '../selectors';

const AllCases = (props) => {
    const { handleReviewClick, data } = props;
    const { containerKey, queuePayloadTypes, queueName } = data;

    const dispatch = useDispatch();

    // reducer state variables
    const casesList = useMemoizedSelector(selectRiskOpsCasesList, [containerKey]);
    const payloadType = useMemoizedSelector(selectRiskOpsPayloadType, [containerKey]);
    const pendingReviewCount = useMemoizedSelector(selectRiskOpsPendingReviewCount, [containerKey]);
    const { fromDate, toDate, sortOption } = useMemoizedSelector(selectRiskOpsQueueFilters, [containerKey]);

    const {
        DATE_INFO, DD_CONTENT_CLS, DD_ITEM_CLS, DD_WRAPPER_CLS, SORTING_OPTIONS,
    } = getQueueSpecificInfo(containerKey);

    const setPayloadTypeValue = (newPayload) => {
        dispatch(setRiskOpsPayloadTypeAction({ containerKey, newPayload }));
    };

    const setSortOption = (newOptionValue) => {
        dispatch(setRiskOpsQueueFiltersAction({ containerKey, queueFilters: { key: 'sortOption', value: newOptionValue } }));
    };

    const setNewDateValue = (dateType) => (newDate) => {
        dispatch(setRiskOpsQueueFiltersAction({ containerKey, queueFilters: { key: dateType, value: newDate } }));
    };

    const renderActions = (item, index) => (
        <div className='frfscWrapper'>
            <Button
                link
                label='Review'
                onClick={handleReviewClick({
                    ...item.ogData,
                    index,
                })}
            />
        </div>
    );

    return (
        <div className='rops-cr'>
            <div className='rops-hl1'>Total {queueName} Cases - {pendingReviewCount}</div>

            <div className='frcsbWrapper my-30'>
                <div className='fcWrapper'>
                    <DateRangePicker
                        dateInfo={DATE_INFO}
                        fromDate={fromDate}
                        setFromDate={setNewDateValue('fromDate')}
                        toDate={toDate}
                        setToDate={setNewDateValue('toDate')}
                        showTimeInDateInput
                    />
                </div>
                <div className='fcWrapper'>
                    <div className='frcfeWrapper mb-15'>
                        <div className='rops-idd__lb'>Payload</div>
                        <AppDropdown
                            ddWrapperCls={DD_WRAPPER_CLS}
                            defaultDDLabel='Select Payload Type'
                            ddContentCls={DD_CONTENT_CLS}
                            ddState={payloadType}
                            setDDState={setPayloadTypeValue}
                            ddList={queuePayloadTypes}
                            ddItemCls={DD_ITEM_CLS}
                        />
                    </div>
                    <div className='frcfeWrapper mb-15'>
                        <div className='rops-idd__lb'>Sort By</div>
                        <AppDropdown
                            ddWrapperCls={DD_WRAPPER_CLS}
                            defaultDDLabel='Select Case Sorting'
                            ddContentCls={DD_CONTENT_CLS}
                            ddState={sortOption}
                            setDDState={setSortOption}
                            ddList={SORTING_OPTIONS}
                            ddItemCls={DD_ITEM_CLS}
                        />
                    </div>
                </div>
            </div>

            <Table
                v2
                labelData={getRiskOpsTableLabels(containerKey, payloadType)}
                contentData={casesList}
                renderActionableColumn={renderActions}
                extClasses={{
                    container: 'rops-src',
                }}
            />
        </div>
    );
};

export default memo(AllCases);

/**
 * @file Reducer for Developer
 */
import { fromJS } from 'immutable';
import { SET_IDENTIFIER_LIST, SET_ACTIVE_TAB_INDEX, SET_NEW_TAB_ACTIVE } from './constants';

const initialState = fromJS({
    tabs: {
        identifierList: [],
        newTabActive: true,
        activeTabIndex: 0,
    },
});

function developerReducer(state = initialState, action = '') {
    switch (action.type) {
        case SET_IDENTIFIER_LIST: {
            const { index, data, isActive = false } = action.data;
            let modifiedIdentifierList = state.getIn(['tabs', 'identifierList']).toJS();

            if (modifiedIdentifierList && Array.isArray(modifiedIdentifierList)) {
                if (data) {
                    if (modifiedIdentifierList.length - 1 < index) {
                        modifiedIdentifierList.push(data);
                    } else {
                        modifiedIdentifierList[index] = data;
                    }
                } else {
                    modifiedIdentifierList = modifiedIdentifierList.filter((item, idx) => idx !== index);
                }
            }

            if (isActive) {
                return state
                    .setIn(['tabs', 'identifierList'], fromJS(modifiedIdentifierList))
                    .setIn(['tabs', 'activeTabIndex'], index);
            }
            return state.setIn(['tabs', 'identifierList'], fromJS(modifiedIdentifierList));
        }

        case SET_ACTIVE_TAB_INDEX: {
            const { index } = action.data;
            return state.setIn(['tabs', 'activeTabIndex'], index);
        }

        case SET_NEW_TAB_ACTIVE: {
            const { value } = action.data;
            return state.setIn(['tabs', 'newTabActive'], value);
        }

        default:
            return state;
    }
}

export default developerReducer;

/*
 *
 * Liveness review actions
 *
 */

import {
    DEQUEUE_LIVENESS_CASE, GET_LIVENESS_CASES, GET_LIVENESS_PENDING_REVIEW_COUNT, GET_LIVENESS_REVIEW_VIDEO, SET_CUR_LIVENESS_CASE,
    SET_LIVENESS_PAYLOAD_TYPE, SET_LIVENESS_VIEW, AUDIT_LOG_MEDIA_DOWNLOAD, SET_LIVENESS_QUEUE_FILTERS,
} from './constants';

export const getLivenessCasesAction = (data) => ({
    type: GET_LIVENESS_CASES,
    data,
});

export const dequeueLivenessCaseAction = (data) => ({
    type: DEQUEUE_LIVENESS_CASE,
    data,
});

export const setLivenessViewAction = (data) => ({
    type: SET_LIVENESS_VIEW,
    data,
});

export const getLivenessReviewVideoAction = (data) => ({
    type: GET_LIVENESS_REVIEW_VIDEO,
    data,
});

export const setLivenessPayloadTypeAction = (data) => ({
    type: SET_LIVENESS_PAYLOAD_TYPE,
    data,
});

export const setCurLivenessCaseAction = (data) => ({
    type: SET_CUR_LIVENESS_CASE,
    data,
});

export const getLivenessPendingReviewCountAction = (data) => ({
    type: GET_LIVENESS_PENDING_REVIEW_COUNT,
    data,
});

export const auditLogMediaDownloadAction = (data) => ({
    type: AUDIT_LOG_MEDIA_DOWNLOAD,
    data,
});

export const setLivenessQueueFiltersAction = (data) => ({
    type: SET_LIVENESS_QUEUE_FILTERS,
    data,
});

/*
 *
 * Pan name match review saga
 *
 */

import {
    call, put, takeLatest, all,
} from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify,
} from '../../utils';
import { RISK_OPS_ROOT } from '../../api/routes';

import {
    GET_NAMEMATCH_CASES, SET_NAMEMATCH_CASES, ERR_NAMEMATCH_CASES, DEQUEUE_NAMEMATCH_CASE,
    VIEW_ALL_NAMEMATCH_CASES, VIEW_SINGLE_NAMEMATCH_CASE, SET_NAMEMATCH_PENDING_REVIEW_COUNT, GET_NAMEMATCH_PENDING_REVIEW_COUNT,
} from './constants';
import { getNamematchPendingReviewCountAction, setCurNamematchCaseAction, setNamematchViewAction } from './actions';

function* getNamematchCasesSaga(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_ROOT}/queue?${queryString}`,
        );

        yield put({ type: SET_NAMEMATCH_CASES, data: response.elements });
        return response.elements;
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_NAMEMATCH_CASES, data: { err: e.message } });
        yield put({ type: SET_NAMEMATCH_CASES, data: [] });
        return [];
    }
}

function* dequeueNamematchCaseSaga(action) {
    let { actorId, recordId } = action.data;
    const { queryParams, index, customContext } = action.data;
    const { payloadType } = queryParams;
    const { bannersVersion } = customContext;

    const bannerStyle = {
        1: '.devs-cc',
        2: '.devs-v2-cc',
    };

    actorId = btoa(actorId);
    recordId = btoa(recordId);
    const queryString = getQueryStringFromObject({ actorId, recordId, payloadType });

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.delete],
            `${RISK_OPS_ROOT}/queue?${queryString}`,
        );

        const queueElements = yield call(getNamematchCasesSaga, { data: queryParams });
        yield put(getNamematchPendingReviewCountAction({ ...queryParams }));

        let namematchView;
        let curNamematchCase;
        const queueLen = queueElements.length;

        if (queueLen === 0) { // if queue is empty, show all namematch cases view
            namematchView = VIEW_ALL_NAMEMATCH_CASES;
            curNamematchCase = {};
        } else { // else show the next case in the queue
            namematchView = VIEW_SINGLE_NAMEMATCH_CASE;
            /**
             * if index is less than queue length, set the current case at that index
             * else set the current case as the last case in the queue
             */
            curNamematchCase = index < queueLen ? queueElements[index] : queueElements[queueLen - 1];
        }

        yield put(setNamematchViewAction(namematchView));
        yield put(setCurNamematchCaseAction({ ...curNamematchCase, index }));

        // scroll to the top for the next case
        document.querySelector(bannerStyle[bannersVersion]).scrollTo(0, 0);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_NAMEMATCH_CASES, data: { err: e.message } });
    }
}

function* getPendingReviewCountSaga(action) {
    const { payloadType } = action.data;
    const queryString = getQueryStringFromObject({ payloadType });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_ROOT}/pending-review-count?${queryString}`,
        );

        yield put({ type: SET_NAMEMATCH_PENDING_REVIEW_COUNT, data: response.count });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_NAMEMATCH_CASES, data: { err: e.message } });
    }
}

export default function* namematchReviewSaga() {
    yield all(
        [
            yield takeLatest(GET_NAMEMATCH_CASES, getNamematchCasesSaga),
            yield takeLatest(DEQUEUE_NAMEMATCH_CASE, dequeueNamematchCaseSaga),
            yield takeLatest(GET_NAMEMATCH_PENDING_REVIEW_COUNT, getPendingReviewCountSaga),
        ],
    );
}

/**
 *
 * AgentInfo - Component to show the agent information in detail
 *
 */

import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { KVPairInfo } from '../../../components';
import { AGENT_PATH } from '../../App/routes';

import { setAgentInfo } from '../actions';
import { getAgentInfoList } from '../utils';
import { makeSelectAgentInfo } from '../selectors';

import './style.scss';

const AgentInfo = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);

    const resetAgentInfo = () => {
        dispatch(setAgentInfo({}));
        history.push(AGENT_PATH);
    };

    return (
        <div className='agent-info-cr'>
            <KVPairInfo
                title='Agent Information'
                onBackArrowClickHandler={resetAgentInfo}
                data={getAgentInfoList(agentInfo)}
            />
        </div>
    );
};

export default memo(AgentInfo);

/**
 * @file FederalOps: This is the root component that redirects to different flows based on the current role
 * All the flows are related to federal ops and are accessed by federal agents
 */

import React, { memo, useContext, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { UserDetailsContext } from '../../context';
import { FEDERAL_AGENT_PATH, FEDERAL_US_STOCKS_PATH, FEDERAL_LENDING_PATH } from '../App/routes';

import UsStocks from '../UsStocks';
import FederalAgents from '../FederalAgents';
import LoanLivenessReviewer from '../LoanLivenessReviewer';

const FederalOps = () => {
    const userInfo = useContext(UserDetailsContext);

    const {
        basicInfo: {
            accessLevelInfo: {
                isFederalAgent,
                isFederalInwardRemitter,
                isFederalOutwardRemitter,
                isFederalMasterRemitter,
                isFederalLoanLivenessReviewer,
                isFederalMasterRemittanceOps,
            },
        },
    } = userInfo;

    const isFederalUsStocksRole = useMemo(() => (
        isFederalInwardRemitter || isFederalOutwardRemitter || isFederalMasterRemitter || isFederalMasterRemittanceOps
    ), [isFederalMasterRemitter, isFederalOutwardRemitter, isFederalInwardRemitter, isFederalMasterRemittanceOps]);

    const isFederalLendingRole = useMemo(() => isFederalLoanLivenessReviewer, [isFederalLoanLivenessReviewer]);

    return (
        <Switch>
            <Route
                path={FEDERAL_AGENT_PATH}
                render={() => {
                    if (isFederalUsStocksRole) {
                        return <Redirect to={{ pathname: FEDERAL_US_STOCKS_PATH }} />;
                    }

                    if (isFederalLendingRole) {
                        return <Redirect to={{ pathname: FEDERAL_LENDING_PATH }} />;
                    }

                    return <FederalAgents />;
                }}
            />
            <Route
                path={FEDERAL_US_STOCKS_PATH}
                render={() => {
                    if (isFederalAgent) {
                        return <Redirect to={{ pathname: FEDERAL_AGENT_PATH }} />;
                    }

                    return <UsStocks />;
                }}
            />
            <Route
                path={FEDERAL_LENDING_PATH}
                render={() => {
                    if (!isFederalLendingRole) {
                        return null;
                    }

                    return <LoanLivenessReviewer />;
                }}
            />
            {/* in case of unmatched url, redirect the user to federal agent path */}
            <Redirect to={{ pathname: FEDERAL_AGENT_PATH }} />
        </Switch>
    );
};

export default memo(FederalOps);

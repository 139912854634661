/**
 *
 * @file Single case for user risk review
 * Container for displaying a single liveness case
 *
 */

import React, { memo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../../components';
import { useMemoizedSelector } from '../../../hooks';

import { RISK_OPS_VIEW_ALL_CASES } from '../../RiskOps/constants';
import { SingleCaseHeading } from '../../RiskOps';
import { setRiskOpsCurViewAction } from '../../RiskOps/actions';
import { selectRiskOpsCurCase } from '../../RiskOps/selectors';
import { getIdsFromCase } from '../../RiskOps/utils';

import { errUserRiskAction } from '../actions';
import { selectUserRiskReviewError, selectUserRiskReviewLoading } from '../selectors';
import { USER_RISK_REVIEW_KEY as CONTAINER_KEY } from '../constants';
import RiskReview from '../RiskReview';

const SingleUserRiskReviewCase = () => {
    const dispatch = useDispatch();

    const loading = useSelector(selectUserRiskReviewLoading, shallowEqual);
    const err = useSelector(selectUserRiskReviewError, shallowEqual);
    const curCase = useMemoizedSelector(selectRiskOpsCurCase, [CONTAINER_KEY]);
    const { caseId, actorId } = getIdsFromCase(curCase);

    const onBackArrowCTA = () => {
        dispatch(setRiskOpsCurViewAction({ containerKey: CONTAINER_KEY, newView: RISK_OPS_VIEW_ALL_CASES }));
    };

    // throw error when actorId not availabile and caseId available.
    useEffect(() => {
        if (caseId && !actorId) {
            dispatch(errUserRiskAction({
                err: 'Actor Id Not Available',
            }));
        }
    }, [actorId, caseId, dispatch]);

    return (
        <React.Fragment>
            <div className='rops-cr urr-sec'>
                {!caseId ? (
                    <SingleCaseHeading
                        title='Review User Risk Case'
                        onBackArrowCTA={onBackArrowCTA}
                    />
                ) : null}
                <RiskReview />
                {err && <div className='err-label1'>{err}</div>}
            </div>

            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(SingleUserRiskReviewCase);

/**
 * @file Has all information regarding the assets used at different places throughout the app
 */

// Main root URLs
const EPIFI_ICONS_S3_URL = 'https://epifi-icons.s3.ap-south-1.amazonaws.com/sherlock';
const EPIFI_ICONS_CLOUDFRONT_URL = 'https://dza2kd7rioahk.cloudfront.net/sherlock';

// Please change this URL for development purpose
const DEV_URL = EPIFI_ICONS_S3_URL;

// High level folder URLs
const ASSETS_URL = `${DEV_URL}/assets`;

// Assets folders
const SVGS_URL = `${ASSETS_URL}/images/svgs`;

// Icon files
const ICONS_URL = {
    ARROW: `${SVGS_URL}/arrow.svg`,
    CLOSE: `${SVGS_URL}/close.svg`,
    WHITE_CLOSE: `${EPIFI_ICONS_CLOUDFRONT_URL}/assets/images/svgs/icons8-close-250.png`,
    CHEVRON_BOTTOM: `${SVGS_URL}/chevron-bottom.svg`,
    NOTIFICATION_BELL: `${SVGS_URL}/notification-bell.svg`,
    NOTIFICATION_BELL_WHITE: `${SVGS_URL}/notification-bell--white.svg`,
    SIGN_IN_WITH_GOOGLE: `${SVGS_URL}/signin-with-google.svg`,
};

export {
    ICONS_URL,
};

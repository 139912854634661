/**
 *
 * Accounts - component to show all the connected accounts list
 *
 */

import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Loader, Table } from '../../../components';

import { getAccountsList } from '../actions';
import { makeSelectAccountsList } from '../selectors';

import { LABEL_DATA } from './constants';
import './style.scss';

const Accounts = (props) => {
    const { ticketId, userId, index } = props;

    const dispatch = useDispatch();

    const accountsList = useSelector(makeSelectAccountsList(), shallowEqual);

    useEffect(() => {
        dispatch(getAccountsList({ ticketId, userId, index }));
    }, [dispatch]);

    let loading;
    let data;

    if (accountsList[ticketId]) {
        ({ loading, data } = accountsList[ticketId]);
    }

    return (
        <div className='ca-list'>
            <Table
                labelData={LABEL_DATA}
                contentData={data}
            />
            <Loader visible={loading} />
        </div>
    );
};

export default memo(Accounts);

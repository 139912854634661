/**
 *
 * Render results for CX disputes
 *
 */

import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    DropdownContent, DropdownWrapper, Pagination,
} from '../../../components';
import { useMemoizedSelector } from '../../../hooks';

import { getDisputesInfoAction } from '../actions';
import { selectDisputesInfo, selectDisputesInfoForActor } from '../selectors';
import UpdateTicketIdModal from '../Modals/UpdateTicketId';
import ViewAllDetailsModal from '../Modals/ViewDetails';
import UpdateDisputeStateModal from '../Modals/UpdateDisputeStatus';

const SearchResults = (props) => {
    const { ticketId, index } = props;

    const dispatch = useDispatch();

    // global state
    const disputesInfoForActor = useMemoizedSelector(selectDisputesInfoForActor, [ticketId]);
    const disputesInfo = useMemoizedSelector(selectDisputesInfo, [ticketId]);

    // local state
    const [selectedDisputeDetails, setSelectedDisputeDetails] = useState({});
    const [openedActionsCardId, setOpenedActionsCardId] = useState('');

    // modals
    const [viewAllDetailsModal, setViewAllDetailsModal] = useState(false);
    const [updateTicketIdModal, setUpdateTicketIdModal] = useState(false);
    const [updateDisputeStateModal, setUpdateDisputeStateModal] = useState(false);

    let disputesData;
    let disputesPrev;
    let disputesNext;
    let payload;

    if (disputesInfo) {
        ({
            disputesData, prev: disputesPrev, next: disputesNext, filters: payload,
        } = disputesInfo);
    }

    if (disputesInfoForActor) {
        ({
            disputesData, prev: disputesPrev, next: disputesNext, filters: payload,
        } = disputesInfoForActor);
    }

    const getPaginatedDisputes = ({ prev, next }) => {
        dispatch(getDisputesInfoAction({
            ticketId,
            index,
            payload,
            prev,
            next,
        }));
    };

    if (!disputesData) return null;

    if (disputesData.length === 0) {
        return (
            <div className='disputes-src'>
                <div className='table-nsr table-nsr--mt0'>No search results found!</div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className='disputes-src'>
                <div className='table-sr table-sr--mt0'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th'>Dispute ID</div>
                        <div className='table-sr__th'>Created At</div>
                        <div className='table-sr__th'>Updated At</div>
                        <div className='table-sr__th'>Freshdesk Ticket ID</div>
                        <div className='table-sr__th'>Dispute State</div>
                        <div className='table-sr__th'>Source</div>
                        <div className='table-sr__th'>Channel</div>
                        <div className='table-sr__th'>Escalation Mode</div>
                        <div className='table-sr__th table-sr__th--actions' />
                    </div>
                    <div className='table-sr__tlc'>
                        {
                            disputesData.map((item, tableIndex) => (
                                <div className='table-sr__tlw pos__rel' key={item.id}>
                                    <div className='table-sr__tl'>{item.id}</div>
                                    <div className='table-sr__tl'>{item.created_at}</div>
                                    <div className='table-sr__tl'>{item.updated_at}</div>
                                    <div className='table-sr__tl'>{item.ticket_id}</div>
                                    <div className='table-sr__tl'>{item.dispute_state}</div>
                                    <div className='table-sr__tl'>{item.source}</div>
                                    <div className='table-sr__tl'>{item.channel}</div>
                                    <div className='table-sr__tl'>{item.escalation_mode}</div>
                                    <div className='table-sr__tl table-sr__tl--actions'>
                                        <DropdownWrapper
                                            visible={openedActionsCardId === item.id}
                                            onOutsideClickHandler={() => setOpenedActionsCardId('')}
                                        >
                                            <button
                                                type='button'
                                                className='disputes-ad-label'
                                                onClick={() => {
                                                    setOpenedActionsCardId(item.id);
                                                }}
                                            >
                                                ...
                                            </button>
                                            <DropdownContent
                                                visible
                                                extClasses={{
                                                    container: [
                                                        'disputes-ad-cc height-transition',
                                                        openedActionsCardId === item.id && 'disputes-ad-cc--active',
                                                    ],
                                                }}
                                            >
                                                <button
                                                    type='button'
                                                    className='disputes-ad-cc-wr__label'
                                                    onClick={() => {
                                                        setSelectedDisputeDetails({ ...item, tableIndex });
                                                        setViewAllDetailsModal(true);
                                                        setOpenedActionsCardId('');
                                                    }}
                                                >
                                                    View All Details
                                                </button>
                                                <button
                                                    type='button'
                                                    className='disputes-ad-cc-wr__label'
                                                    onClick={() => {
                                                        setSelectedDisputeDetails({ ...item, tableIndex });
                                                        setUpdateTicketIdModal(true);
                                                        setOpenedActionsCardId('');
                                                    }}
                                                >
                                                    Update Ticket ID
                                                </button>
                                                <button
                                                    type='button'
                                                    className='disputes-ad-cc-wr__label'
                                                    onClick={() => {
                                                        setSelectedDisputeDetails({ ...item, tableIndex });
                                                        setUpdateDisputeStateModal(true);
                                                        setOpenedActionsCardId('');
                                                    }}
                                                >
                                                    Update Dispute Status
                                                </button>
                                            </DropdownContent>
                                        </DropdownWrapper>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Pagination prev={disputesPrev} next={disputesNext} onClickHandler={getPaginatedDisputes} />
            </div>
            {/* modals */}
            <ViewAllDetailsModal
                visible={viewAllDetailsModal}
                toggleHandler={setViewAllDetailsModal}
                data={selectedDisputeDetails}
                setData={setSelectedDisputeDetails}
            />
            <UpdateTicketIdModal
                visible={updateTicketIdModal}
                toggleHandler={setUpdateTicketIdModal}
                data={selectedDisputeDetails}
                setData={setSelectedDisputeDetails}
                ticketId={ticketId}
            />
            <UpdateDisputeStateModal
                visible={updateDisputeStateModal}
                toggleHandler={setUpdateDisputeStateModal}
                data={selectedDisputeDetails}
                setData={setSelectedDisputeDetails}
                ticketId={ticketId}
            />
        </React.Fragment>
    );
};

export default memo(SearchResults);

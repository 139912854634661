/**
 * @file SherlockV2SOP contains rudder event names for Sherlock V2 SOP flows
 */

const SherlockV2SOPLanded = 'SherlockV2SOPLanded';
const SherlockV2SOPStepViewed = 'SherlockV2SOPStepViewed';
const SherlockV2SOPClickedCompleteCall = 'SherlockV2SOPClickedCompleteCall';
const SherlockV2SOPClickedGoBackToPreviousStep = 'SherlockV2SOPClickedGoBackToPreviousStep';
const SherlockV2SOPClickedGoBackToHistoricalStep = 'SherlockV2SOPClickedGoBackToHistoricalStep';
const SherlockV2SOPClickedRestartFlow = 'SherlockV2SOPClickedRestartFlow';
const SherlockV2SOPClickedGoHome = 'SherlockV2SOPClickedGoHome';
const SherlockV2SOPCompleted = 'SherlockV2SOPCompleted';

export {
    SherlockV2SOPLanded,
    SherlockV2SOPStepViewed,
    SherlockV2SOPClickedCompleteCall,
    SherlockV2SOPClickedGoBackToPreviousStep,
    SherlockV2SOPClickedGoBackToHistoricalStep,
    SherlockV2SOPClickedRestartFlow,
    SherlockV2SOPClickedGoHome,
    SherlockV2SOPCompleted,
};

/**
 * @file Manages the utility functions for Wealth Us Stocks component
 */

const renderAccountDetailInfo = (data) => ([
    {
        label: 'Current Stage',
        value: data.account_stage,
    },
    {
        label: 'Satus',
        value: data.account_status,
    },
    {
        label: 'RCA',
        value: data.rca,
    },
    {
        label: 'Troubleshooting Advice',
        value: data.troubleshooting_advice,
    },
    {
        label: 'Additional Details',
        value: data.additional_details,
    },
]);

export {
    renderAccountDetailInfo,
};

/**
 *
 * WealthReview:
 * Review a single wealth ops case for payload type PAYLOAD_TYPE_WEALTH_DATA & check the following:
 * 1. Check if the liveness video is correct
 * 2. Check if images are masked correctly
 * 3. Check if the expiry date is correct
 *
 */

import React, { memo, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
    Button, ExpandableCard, Table, RenderDocument, DisableContextMenu,
} from '../../../components';
import { downloadFile } from '../../../utils';

import DevActions from '../../DevActions';

import {
    getDocumentPreFilledValuesObj, getDocumentReviewData, getLivenessPreFilledValuesObj, getLivenessReviewData, getWealthData,
} from '../utils';
import {
    LIVENESS_DEV_ACTION_TYPES, WEALTH_OPS_DOCUMENT_TABLE, WEALTH_OPS_LIVENESS_TABLE,
} from '../constants';
import { selectWealthOpsCurCase, selectWealthOpsLivenessVideoUrl } from '../selectors';
import { getWealthOpsLivenessVideoAction } from '../actions';

const WealthReview = () => {
    const dispatch = useDispatch();

    const documentCardRef = useRef(null);

    const curCase = useSelector(selectWealthOpsCurCase(), shallowEqual);
    const livenessVideoUrl = useSelector(selectWealthOpsLivenessVideoUrl(), shallowEqual);

    // Extract information from the case
    const { id: recordId } = curCase;
    const wealthData = getWealthData(curCase);
    const livenessData = getLivenessReviewData(curCase);
    const documentData = getDocumentReviewData(curCase);

    let defaultLivenessCardState = true;
    let defaultDocumentCardState = false;

    if (!livenessData.hasData) {
        defaultLivenessCardState = false;
        defaultDocumentCardState = true;
    }

    const [isLivenessCardExpanded, setLivenessCardExpanded] = useState(defaultLivenessCardState);
    const [isDocumentCardExpanded, setDocumentCardExpanded] = useState(defaultDocumentCardState);
    const [livenessPreFilledValues, setLivenessPreFilledValues] = useState(null);
    const [documentPreFilledValues, setDocumentPreFilledValues] = useState(null);

    useEffect(() => {
        setLivenessPreFilledValues(getLivenessPreFilledValuesObj(curCase));
        setDocumentPreFilledValues(getDocumentPreFilledValuesObj(curCase));
        const curLivenessData = getLivenessReviewData(curCase);

        if (curLivenessData.hasData) dispatch(getWealthOpsLivenessVideoAction(getLivenessReviewData(curCase)));
    }, [dispatch, curCase.id]);

    useEffect(() => {
        if (isDocumentCardExpanded) documentCardRef.current.scrollIntoView();
    }, [isDocumentCardExpanded]);

    const downloadDocImgs = () => {
        documentData.ogDocList.forEach((item, docNo) => {
            const { image_data_base64: docData, image_type: docType, image_url: imageUrl } = item;

            const formattedDocType = docType.toLowerCase();
            let fileFormat = `image/${formattedDocType}`;

            if (formattedDocType === 'pdf') fileFormat = `application/${formattedDocType}`;

            let imgSrc = imageUrl;
            let openInNewTab = true;

            if (docData) {
                imgSrc = `data:${fileFormat};base64, ${docData}`;
                openInNewTab = false;
            }

            downloadFile(`${recordId}-doc-#${docNo + 1}`, imgSrc, openInNewTab);
        });
    };

    const getLivenessTableData = () => (
        [
            {
                ...curCase.wealth_data.liveness_data,
                actor_id: curCase.wealth_data.actor_id,
            },
        ]
    );

    return (
        <React.Fragment>
            {livenessData.hasData && (
                <ExpandableCard
                    extClasses='wops-ec my-30 p-30'
                    extHeadingClasses='p-0'
                    heading='Liveness Review'
                    isExpanded={isLivenessCardExpanded}
                    setIsExpanded={() => {
                        setLivenessCardExpanded(!isLivenessCardExpanded);
                        setDocumentCardExpanded(false);
                    }}
                >
                    <div className='wops-video'>
                        {livenessVideoUrl && (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            <video width='480' height='540' controls disablePictureInPicture controlsList='nodownload'>
                                <source src={livenessVideoUrl} type='video/mp4' />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>

                    <Table
                        labelData={WEALTH_OPS_LIVENESS_TABLE}
                        contentData={getLivenessTableData()}
                        extClasses={{ container: 'py-30' }}
                    />

                    {livenessPreFilledValues && !isDocumentCardExpanded && (
                        <DevActions
                            containerKey='wealth-ops-liveness'
                            actionTypes={LIVENESS_DEV_ACTION_TYPES}
                            preFilledValues={livenessPreFilledValues}
                        />
                    )}

                </ExpandableCard>
            )}

            {documentData.hasData && (
                <div ref={documentCardRef}>
                    <ExpandableCard
                        extClasses='wops-ec my-30 p-30'
                        extHeadingClasses='p-0'
                        heading={wealthData.docReviewCardHeading}
                        isExpanded={isDocumentCardExpanded}
                        setIsExpanded={() => {
                            setDocumentCardExpanded(!isDocumentCardExpanded);
                            setLivenessCardExpanded(false);
                        }}
                    >

                        <div className='wops-hl2 mt-30'>Original Document</div>
                        <DisableContextMenu>
                            <div className='wops-img my-30'>
                                {documentData.ogDocList.map((item, ocrIndex) => (
                                    <RenderDocument
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={ocrIndex}
                                        index={ocrIndex}
                                        disableToolbar
                                        data={{
                                            base64String: item.image_data_base64,
                                            srcUrl: item.image_url,
                                            docType: item.image_type,
                                        }}
                                    />
                                ))}
                            </div>
                        </DisableContextMenu>

                        <div className='my-30'>
                            <Button
                                v2
                                primary
                                label='Download'
                                onClick={downloadDocImgs}
                            />
                        </div>

                        {wealthData.isDocRedactionReview && (
                            <React.Fragment>
                                <div className='wops-hl2 mt-30'>Processed Document</div>
                                <DisableContextMenu>
                                    <div className='wops-img my-30' id='pdf-container'>
                                        {documentData.processedDocList.map((item, ocrIndex) => (
                                            <RenderDocument
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={ocrIndex}
                                                index={ocrIndex}
                                                disableToolbar
                                                data={{
                                                    base64String: item.image_data_base64,
                                                    srcUrl: item.image_url,
                                                    docType: item.image_type,
                                                }}
                                            />
                                        ))}
                                    </div>
                                </DisableContextMenu>
                            </React.Fragment>
                        )}

                        <div className='table-sr table-sr--mt50'>
                            {/* header row */}
                            <div className='table-sr__thw'>
                                {WEALTH_OPS_DOCUMENT_TABLE.map((item) => {
                                    if (wealthData.isDocRedactionReview && item.view === 'onlyExpiryReview') return null;

                                    return (<div key={item.key} className='table-sr__th'>{item.label}</div>);
                                })}
                            </div>
                            {/* table values */}
                            <div className='table-sr__tlc'>
                                <div className='table-sr__tlw'>
                                    <div className='table-sr__tl'>{documentData.manualReviewId}</div>
                                    <div className='table-sr__tl'>{documentData.confidenceScore}</div>
                                    <div className='table-sr__tl'>{documentData.thresholdScore}</div>
                                    <div className='table-sr__tl'>{documentData.vendorReviewFlag}</div>
                                    {!wealthData.isDocRedactionReview && (
                                        <React.Fragment>
                                            <div className='table-sr__tl'>{documentData.year}</div>
                                            <div className='table-sr__tl'>{documentData.month}</div>
                                            <div className='table-sr__tl'>{documentData.day}</div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* keep client check if same number of pages are uploaded */}
                        {documentPreFilledValues && !isLivenessCardExpanded && (
                            <DevActions
                                containerKey='wealth-ops-doc-data'
                                actionTypes={wealthData.devActionOptions}
                                preFilledValues={documentPreFilledValues}
                            />
                        )}

                    </ExpandableCard>
                </div>
            )}
        </React.Fragment>
    );
};

export default memo(WealthReview);

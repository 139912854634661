/**
 * @file Manages the selectors for Search component
 */

import { createSelector } from 'reselect';

const getSearch = (state) => state.get('search');

export const selectSearchData = createSelector(
    getSearch,
    (substate) => substate?.toJS(),
);

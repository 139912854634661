
export const GET_USER_REFERRAL_INFO = 'app/Referrals/GET_USER_REFERRAL_INFO';
export const SET_USER_REFERRAL_INFO = 'app/Referrals/SET_USER_REFERRAL_INFO';
export const ERR_USER_REFERRAL_INFO = 'app/Referrals/ERR_USER_REFERRAL_INFO';

export const GET_USER_REFERRER_INFO = 'app/Referrals/GET_USER_REFERRER_INFO';
export const SET_USER_REFERRER_INFO = 'app/Referrals/SET_USER_REFERRER_INFO';
export const ERR_USER_REFERRER_INFO = 'app/Referrals/ERR_USER_REFERRER_INFO';

export const GET_USER_REFEREES = 'app/Referrals/GET_USER_REFEREES';
export const SET_USER_REFEREES = 'app/Referrals/SET_USER_REFEREES';
export const ERR_USER_REFEREES = 'app/Referrals/ERR_USER_REFEREES';

export const GET_USER_REFEREE_INFO = 'app/Referrals/GET_USER_REFEREE_INFO';
export const SET_USER_REFEREE_INFO = 'app/Referrals/SET_USER_REFEREE_INFO';
export const ERR_USER_REFEREE_INFO = 'app/Referrals/ERR_USER_REFEREE_INFO';

export const GET_REFERRAL_META_INFO = 'app/Referrals/GET_REFERRAL_META_INFO';
export const SET_REFERRAL_META_INFO = 'app/Referrals/SET_REFERRAL_META_INFO';
export const ERR_REFERRAL_META_INFO = 'app/Referrals/ERR_REFERRAL_META_INFO';

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { LIVENESS_ROOT } from '../../api/routes';

import {
    GET_LIVENESS_SUMMARY, SET_LIVENESS_SUMMARY, ERR_LIVENESS_SUMMARY,
} from './constants';

function* getLivenessSummary(action) {
    const { monorailId, actorId } = action.data;
    let queryString = getQueryStringFromObject({ monorailId });
    queryString = `${queryString}&actorId=${btoa(actorId)}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LIVENESS_ROOT}/summary?${queryString}`
        );

        yield put({ type: SET_LIVENESS_SUMMARY, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LIVENESS_SUMMARY, data: { err: e.message } });
    }
}

export default function* livenessSummarySaga() {
    yield all(
        [
            yield takeLatest(GET_LIVENESS_SUMMARY, getLivenessSummary),
        ]
    );
}

/**
 * @file CreditCardOffer Screen: Component to show the Credit Card Offer Screen.
 */

import React from 'react';

const CreditCardOfferScreen = (props) => {
    const { creditCardOfferData } = props;

    return (
        <React.Fragment>
            <div className='heading4 mb-15 mt-15'>Credit Card Offer</div>
            {creditCardOfferData.map((item) => {
                const { name, value } = item;

                return (
                    <div className='frWrapper'>
                        <div className='credit-card-clc-cwr__hl credit-card-clc-cwr__f1'>{name}</div>
                        <div className='credit-card-clc-cwr__hl credit-card-clc-cwr__f2'>{value}</div>
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export default CreditCardOfferScreen;

/**
 * @file Sagas for account ops submit CSV details component
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, toastify, getErrorObjectForSaga, showErrorToastMsg } from '../../utils';
import {
    ACC_BULK_ACCOUNT_VERIFICATION_ENDPOINT, STORE_BALANCE_TRANSFER_UTR_ENDPOINT, STORE_CLOSED_ACCOUNT_BALANCES_ENDPOINT,
} from '../../api/routes';

import {
    VERIFY_BULK_ACCOUNTS_INFO, END_VERIFY_BULK_ACCOUNTS_INFO, UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS,
    UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS, END_UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS,
    END_UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS, SET_SUCCESS_MSG, SET_UI_ERROR_MSG,
} from './constants';

const setSuccessToast = (response) => {
    const { message } = response;

    toastify(message, 'success');
};

const getErrorAction = (containerKey) => ({
    type: SET_UI_ERROR_MSG,
    data: { containerKey },
});

function* verifyBulkAccountsInfoSaga(action) {
    const { containerKey, csvFile, resolve } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            ACC_BULK_ACCOUNT_VERIFICATION_ENDPOINT,
            { csvFile },
        );

        setSuccessToast(response);

        const { description } = response;
        yield put({ type: SET_SUCCESS_MSG, data: { containerKey, description } });

        if (resolve) {
            yield call(resolve);
        }
    } catch (error) {
        showErrorToastMsg(error);

        yield put(getErrorObjectForSaga(
            getErrorAction(containerKey),
            error,
        ));
    } finally {
        yield put({ type: END_VERIFY_BULK_ACCOUNTS_INFO, data: { containerKey } });
    }
}

function* uploadBalanceOfCloseAccountsSaga(action) {
    const { containerKey, csvFile, resolve } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            STORE_CLOSED_ACCOUNT_BALANCES_ENDPOINT,
            { csvFile },
        );

        setSuccessToast(response);

        const { description } = response;
        yield put({ type: SET_SUCCESS_MSG, data: { containerKey, description } });

        if (resolve) {
            yield call(resolve);
        }
    } catch (error) {
        showErrorToastMsg(error);

        yield put(getErrorObjectForSaga(
            getErrorAction(containerKey),
            error,
        ));
    } finally {
        yield put({ type: END_UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS, data: { containerKey } });
    }
}

function* uploadSuccessfulBalanceTransfersSaga(action) {
    const { containerKey, csvFile, resolve } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            STORE_BALANCE_TRANSFER_UTR_ENDPOINT,
            { csvFile },
        );

        setSuccessToast(response);

        const { description } = response;
        yield put({ type: SET_SUCCESS_MSG, data: { containerKey, description } });

        if (resolve) {
            yield call(resolve);
        }
    } catch (error) {
        showErrorToastMsg(error);

        yield put(getErrorObjectForSaga(
            getErrorAction(containerKey),
            error,
        ));
    } finally {
        yield put({ type: END_UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS, data: { containerKey } });
    }
}

export default function* bulkAccountVerificationSaga() {
    yield all(
        [
            yield takeLatest(VERIFY_BULK_ACCOUNTS_INFO, verifyBulkAccountsInfoSaga),
            yield takeLatest(UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS, uploadBalanceOfCloseAccountsSaga),
            yield takeLatest(UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS, uploadSuccessfulBalanceTransfersSaga),
        ],
    );
}

/**
 * @file UserRiskReview actions
 */

import {
    GET_MEDIA_FOR_USER_RISK_REVIEW,
    GET_USER_RISK_REVIEW_DATA,
    GET_FORM_FIELDS,
    SUBMIT_ANNOTATION,
    ERROR_USER_RISK_REVIEW,
    RESET_USER_RISK_REVIEW_DATA,
    GET_USER_RISK_SCREENER_CHECK_DETAILS,
    GET_LINKED_IN_DETAILS,
} from './constants';

export const getUserRiskDataAction = (data) => ({
    type: GET_USER_RISK_REVIEW_DATA,
    data,
});

export const getMediaForUserRiskReviewAction = (data) => ({
    type: GET_MEDIA_FOR_USER_RISK_REVIEW,
    data,
});

export const errUserRiskAction = (data) => ({
    type: ERROR_USER_RISK_REVIEW,
    data,
});

export const getFormFieldsAction = (data) => ({
    type: GET_FORM_FIELDS,
    data,
});

export const submitAnnotationAction = (data) => ({
    type: SUBMIT_ANNOTATION,
    data,
});

export const resetRiskData = () => ({
    type: RESET_USER_RISK_REVIEW_DATA,
});

export const getScreenerCheckDetailsAction = (data) => ({
    type: GET_USER_RISK_SCREENER_CHECK_DETAILS,
    data,
});

export const getLinkedInProfileUrl = (data) => ({
    type: GET_LINKED_IN_DETAILS,
    data,
});

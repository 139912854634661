/**
 * @file Reducer for account ops submit CSV details component
 */

import { fromJS } from 'immutable';

import {
    SET_UPLOAD_CSV_FORM_PARAMS, VERIFY_BULK_ACCOUNTS_INFO, END_VERIFY_BULK_ACCOUNTS_INFO, CONTAINER_KEY_MAP,
    UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS, UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS, END_UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS,
    END_UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS, REDUCER_KEYS, SET_SUCCESS_MSG, SET_UI_ERROR_MSG,
} from './constants';

const {
    BULK_ACCOUNT_VERIFICATION_KEY,
    UPLOAD_BALANCES_KEY,
    BALANCE_TRANSFER_KEY,
} = CONTAINER_KEY_MAP;

const COMMON_STATE = {
    [REDUCER_KEYS.uploadCSVFormParams]: {
        csvFile: null,
    },
    [REDUCER_KEYS.loading]: false,
    [REDUCER_KEYS.error]: '',
    [REDUCER_KEYS.successMsg]: 'Your request has been successfully submitted.',
};

const initialState = fromJS({
    [BULK_ACCOUNT_VERIFICATION_KEY]: COMMON_STATE,
    [UPLOAD_BALANCES_KEY]: COMMON_STATE,
    [BALANCE_TRANSFER_KEY]: COMMON_STATE,
});

function bulkAccountVerificationReducer(state = initialState, action = {}) {
    const { containerKey } = action.data || {};

    switch (action.type) {
        case SET_UPLOAD_CSV_FORM_PARAMS: {
            const { key, value } = action.data;

            return state.setIn([containerKey, REDUCER_KEYS.uploadCSVFormParams, key], value);
        }

        case VERIFY_BULK_ACCOUNTS_INFO:
        case UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS:
        case UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS: {
            return state
                .setIn([containerKey, REDUCER_KEYS.loading], true)
                .setIn([containerKey, REDUCER_KEYS.error], '');
        }

        case END_VERIFY_BULK_ACCOUNTS_INFO:
        case END_UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS:
        case END_UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS: {
            return state.setIn([containerKey, REDUCER_KEYS.loading], false);
        }

        case SET_SUCCESS_MSG: {
            const { description } = action.data;

            return state.setIn([containerKey, REDUCER_KEYS.successMsg], description);
        }

        case SET_UI_ERROR_MSG: {
            const { err } = action.data;

            return state.setIn([containerKey, REDUCER_KEYS.error], err);
        }

        default:
            return state;
    }
}

export default bulkAccountVerificationReducer;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/**
 *
 * WaitlistDetails
 *
 */

import React, { useState, useContext } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import classNames from 'classnames';
import { FormWithSingleInput, Loader } from '../../components';
import { injectReducer, injectSaga, validators } from '../../utils';
import backArrow from '../../assets/images/svgs/back-arrow.svg';

import { getWaitlistInfo as getWaitlistInfoUtil } from '../Tickets/utils';

import { getWaitlistUserInfo, resetWaitlistUserInfo } from './actions';
import {
    makeSelectLoading, makeSelectWaitlistUserInfo, makeSelectHasWaitlistUserInfo,
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import { MetaInfoContext } from '../../context';

import './style.scss';

const WaitlistDetails = () => {
    const dispatch = useDispatch();

    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const waitlistUserInfo = useSelector(makeSelectWaitlistUserInfo(), shallowEqual);
    const hasWaitlistUserInfo = useSelector(makeSelectHasWaitlistUserInfo(), shallowEqual);

    const [mobileNo, setMobileNo] = useState('');
    const [errLabel, setErrLabel] = useState('');

    const metaInfo = useContext(MetaInfoContext);

    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    const handleInputChange = (e) => {
        const charCode = e.which || e.keyCode;
        const { value } = e.target;

        // do not allow to enter any other value than digits
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
            return;
        }

        // do not allow to enter first digit as 0,1,2,3,4,5
        if (mobileNo.length === 0) {
            if ([48, 49, 50, 51, 52, 53].includes(charCode)) {
                e.preventDefault();
                return;
            }
        }

        // do not allow copy/paste of more than 10 digits
        if (value && value.length > 10) {
            e.preventDefault();
            return;
        }

        // do not allow copy/paste of invalid mobile number
        if (['0', '1', '2', '3', '4', '5'].includes(value && value[0])) {
            e.preventDefault();
            return;
        }

        setMobileNo(value);
    };

    const resetWaitlistUserFlow = () => {
        dispatch(resetWaitlistUserInfo({}));
        setMobileNo('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validators.isInputtedMobileNumberValid(mobileNo)) {
            setErrLabel('Please enter a valid mobile number');
            return;
        }

        setErrLabel('');
        dispatch(getWaitlistUserInfo({ phoneNumber: mobileNo }));
    };

    if (hasWaitlistUserInfo) {
        return (
            <div className={classNames({
                'wldtls-cr': bannersVersion === 1,
                'wldtls-cr-v2': bannersVersion === 2,
            })}
            >
                <div className='wldtls-hwr'>
                    <img className='wldtls-backarrow' alt='' src={backArrow} onClick={resetWaitlistUserFlow} />
                    <div className='wldtls-hwr__hd'>Waitlist User Details</div>
                </div>
                {
                    getWaitlistInfoUtil(waitlistUserInfo).map((item) => (
                        <div className='wldtls-cwr' key={item.label}>
                            <div className='wldtls-cwr__cl'>{item.label}</div>
                            <div className='wldtls-cwr__sp'>:</div>
                            <div className='wldtls-cwr__cv'>{item.value}</div>
                        </div>
                    ))
                }
            </div>
        );
    }

    return (
        <div className={classNames({
            'wldtls-cr': bannersVersion === 1,
            'wldtls-cr-v2': bannersVersion === 2,
        })}
        >
            <FormWithSingleInput
                label='Registered Mobile Number'
                labelId='registered-mobile-number'
                input={{
                    value: mobileNo,
                    onChange: handleInputChange,
                    maxLength: 10,
                    type: 'number',
                    onKeyDown: handleInputChange,
                }}
                cta={{
                    primaryLabel: 'Submit',
                    onPrimaryClick: handleSubmit,
                }}
                errLabel={errLabel}
            />
            <Loader visible={loading} />
        </div>
    );
};

const withReducer = injectReducer({ key: 'waitlistDetails', reducer });
const withSaga = injectSaga({ key: 'waitlistDetails', saga });

export default compose(
    withReducer,
    withSaga,
)(WaitlistDetails);

import { createSelector } from 'reselect';

const selectLogs = (state) => state.get('logs');

const makeSelectLoading = () => createSelector(
    selectLogs,
    (substate) => substate.get('loading').toJS(),
);

const makeSelectErr = () => createSelector(
    selectLogs,
    (substate) => substate.get('err').toJS(),
);

const makeSelectLogsList = () => createSelector(
    selectLogs,
    (substate) => substate.get('logsList').toJS(),
);

const makeSelectPrev = () => createSelector(
    selectLogs,
    (substate) => substate.get('prev').toJS(),
);

const makeSelectNext = () => createSelector(
    selectLogs,
    (substate) => substate.get('next').toJS(),
);

const makeSelectLogInfo = () => createSelector(
    selectLogs,
    (substate) => substate.get('logInfo').toJS(),
);

const makeSelectLogFilter = () => createSelector(
    selectLogs,
    (substate) => substate.get('logFilter'),
);

export {
    makeSelectLoading,
    makeSelectErr,
    makeSelectLogsList,
    makeSelectPrev,
    makeSelectNext,
    makeSelectLogInfo,
    makeSelectLogFilter,
};

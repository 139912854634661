/**
 *
 * Wealth Onboarding - Component to show the wealth onboarding info
 * It is getting used for Ticket flow
 * 
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import { Button, Loader } from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import OnboardingInfo from './OnboardingInfo';

import { WEALTH_ONBOARDING_KEY } from './constants';
import { getOnboardingInfo } from './actions';
import { renderOnboardingCurrentStageInfo } from './utils';
import { makeSelectOnboardingDetails } from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const WealthOnboarding = (props) => {
    const dispatch = useDispatch();

    const [showOnboardingInfo, setOnboardingInfo] = useState(false);

    const onboardingDetails = useSelector(makeSelectOnboardingDetails(), shallowEqual);

    const { ticketId, userId, index } = props;

    let loadingOnboardingInfo;
    let onboardingInfo;
    let hasOnboardingInfo;
    let errInLoadingOnboardingInfo;

    if (onboardingDetails[ticketId]) {
        ({ loading: loadingOnboardingInfo, onboardingInfo, hasOnboardingInfo = false,
            err: errInLoadingOnboardingInfo, } = onboardingDetails[ticketId]);
    }

    useEffect(() => {
        if (!hasOnboardingInfo) {
            dispatch(getOnboardingInfo({ ticketId, userId, index }));
        }
    }, []);

    if (showOnboardingInfo) {
        return (
            <OnboardingInfo
                data={onboardingInfo && onboardingInfo.stageDetailedMap}
                toggleHandler={setOnboardingInfo}
                currentStageTroubleshootInfo={onboardingInfo && onboardingInfo.currentStageTroubleshootInfo}
            />
        );
    }

    return (
        <React.Fragment>
            <div className='wonb'>
                {
                    errInLoadingOnboardingInfo ? (
                        <div className='wonb-errlb'>{errInLoadingOnboardingInfo}</div>
                    ) : null
                }
                {
                    hasOnboardingInfo ? (
                        renderOnboardingCurrentStageInfo(onboardingInfo)
                            .map(item => (
                                <div key={item.label} className='wonb-content-wr'>
                                    <div className='wonb-content-wr__label'>{item.label}</div>
                                    <div className='wonb-content-wr__value'>{item.data || '-'}</div>
                                    <Button
                                        secondary
                                        extClasses='wonb-content-wr__cta'
                                        label='View Details'
                                        onClick={() => setOnboardingInfo(true)}
                                    />
                                </div>
                            ))
                    ) : null
                }
            </div>
            <Loader visible={loadingOnboardingInfo} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: WEALTH_ONBOARDING_KEY, reducer });
const withSaga = injectSaga({ key: WEALTH_ONBOARDING_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(WealthOnboarding);

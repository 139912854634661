import { fromJS } from 'immutable';

import {
    GET_USER_REFERRAL_INFO, SET_USER_REFERRAL_INFO, ERR_USER_REFERRAL_INFO, GET_USER_REFERRER_INFO,
    SET_USER_REFERRER_INFO, ERR_USER_REFERRER_INFO, GET_USER_REFEREES, SET_USER_REFEREES,
    ERR_USER_REFEREES, GET_USER_REFEREE_INFO, SET_USER_REFEREE_INFO, ERR_USER_REFEREE_INFO,
    SET_REFERRAL_META_INFO,
} from './constants';

const initialState = fromJS({
    userReferralInfo: {},
    userReferrerInfo: {},
    userRefereesList: {},
    userRefereeInfo: {},
    referralMetaInfo: {},
});

function referralsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_USER_REFERRAL_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['userReferralInfo', ticketId, 'loading'], true)
                .setIn(['userReferralInfo', ticketId, 'err'], '');
        }

        case SET_USER_REFERRAL_INFO: {
            const { ticketId, referralInfo, rewardInfo } = action.data;
            const hasReferralInfo = !!Object.keys(referralInfo).length;
            const hasRewardInfo = !!Object.keys(rewardInfo).length;

            return state
                .setIn(['userReferralInfo', ticketId, 'loading'], false)
                .setIn(['userReferralInfo', ticketId, 'hasReferralInfo'], hasReferralInfo)
                .setIn(['userReferralInfo', ticketId, 'referralInfo'], fromJS(referralInfo))
                .setIn(['userReferralInfo', ticketId, 'hasRewardInfo'], hasRewardInfo)
                .setIn(['userReferralInfo', ticketId, 'rewardInfo'], fromJS(rewardInfo));
        }

        case ERR_USER_REFERRAL_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['userReferralInfo', ticketId, 'loading'], false)
                .setIn(['userReferralInfo', ticketId, 'err'], err)
                .setIn(['userReferralInfo', ticketId, 'hasReferralInfo'], false)
                .setIn(['userReferralInfo', ticketId, 'referralInfo'], fromJS({}))
                .setIn(['userReferralInfo', ticketId, 'hasRewardInfo'], false)
                .setIn(['userReferralInfo', ticketId, 'rewardInfo'], fromJS({}));
        }

        case GET_USER_REFERRER_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['userReferrerInfo', ticketId, 'loading'], true)
                .setIn(['userReferrerInfo', ticketId, 'err'], '');
        }

        case SET_USER_REFERRER_INFO: {
            const { ticketId, referrerInfo, rewardEarnedInfo } = action.data;
            const hasReferrerInfo = !!Object.keys(referrerInfo).length;
            const hasRewardEarnedInfo = !!Object.keys(rewardEarnedInfo).length;

            return state
                .setIn(['userReferrerInfo', ticketId, 'loading'], false)
                .setIn(['userReferrerInfo', ticketId, 'hasReferrerInfo'], hasReferrerInfo)
                .setIn(['userReferrerInfo', ticketId, 'referrerInfo'], fromJS(referrerInfo))
                .setIn(['userReferrerInfo', ticketId, 'hasRewardEarnedInfo'], hasRewardEarnedInfo)
                .setIn(['userReferrerInfo', ticketId, 'rewardEarnedInfo'], fromJS(rewardEarnedInfo));
        }

        case ERR_USER_REFERRER_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['userReferrerInfo', ticketId, 'loading'], false)
                .setIn(['userReferrerInfo', ticketId, 'err'], err)
                .setIn(['userReferrerInfo', ticketId, 'hasReferrerInfo'], false)
                .setIn(['userReferrerInfo', ticketId, 'referrerInfo'], fromJS({}))
                .setIn(['userReferrerInfo', ticketId, 'hasRewardEarnedInfo'], false)
                .setIn(['userReferrerInfo', ticketId, 'rewardEarnedInfo'], fromJS({}));
        }

        case GET_USER_REFEREES: {
            const { ticketId } = action.data;

            return state
                .setIn(['userRefereesList', ticketId, 'loading'], true)
                .setIn(['userRefereesList', ticketId, 'err'], '');
        }

        case SET_USER_REFEREES: {
            const { ticketId, refereeList, prev, next } = action.data;

            return state
                .setIn(['userRefereesList', ticketId, 'loading'], false)
                .setIn(['userRefereesList', ticketId, 'data'], fromJS(refereeList))
                .setIn(['userRefereesList', ticketId, 'prev'], fromJS(prev))
                .setIn(['userRefereesList', ticketId, 'next'], fromJS(next));
        }

        case ERR_USER_REFEREES: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['userRefereesList', ticketId, 'loading'], false)
                .setIn(['userRefereesList', ticketId, 'err'], err)
                .setIn(['userRefereesList', ticketId, 'data'], fromJS([]))
                .setIn(['userRefereesList', ticketId, 'prev'], fromJS({}))
                .setIn(['userRefereesList', ticketId, 'next'], fromJS({}));
        }

        case GET_USER_REFEREE_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['userRefereeInfo', ticketId, 'loading'], true)
                .setIn(['userRefereeInfo', ticketId, 'err'], '')
                .setIn(['userRefereeInfo', ticketId, 'data'], fromJS({}))
                .setIn(['userRefereeInfo', ticketId, 'hasData'], false);
        }

        case SET_USER_REFEREE_INFO: {
            const { ticketId, refereeInfo, rewardInfo } = action.data;
            const data = {
                ...refereeInfo,
                ...rewardInfo,
            };

            return state
                .setIn(['userRefereeInfo', ticketId, 'loading'], false)
                .setIn(['userRefereeInfo', ticketId, 'data'], fromJS(data));
        }

        case ERR_USER_REFEREE_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['userRefereeInfo', ticketId, 'loading'], false)
                .setIn(['userRefereeInfo', ticketId, 'err'], err)
                .setIn(['userRefereeInfo', ticketId, 'data'], fromJS({}));
        }

        case SET_REFERRAL_META_INFO:
            return state.set('referralMetaInfo', fromJS(action.data));

        default:
            return state;
    }
}

export default referralsReducer;

/**
 *
 * ActiveRules
 *
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Loader, Pagination } from '../../../components';

import { getActiveRules } from '../actions';
import { makeSelectActiveRules } from '../selectors';

const ActiveRules = (props) => {
    const dispatch = useDispatch();
    const activeRuleList = useSelector(makeSelectActiveRules(), shallowEqual);

    const { ticketId, userId, index } = props;

    let loading;
    let data;
    let prev;
    let next;

    if (activeRuleList[ticketId]) {
        ({ loading, data, prev, next } = activeRuleList[ticketId]);
    }

    useEffect(() => {
        dispatch(getActiveRules({ ticketId, userId, index }));
    }, []);

    const getPaginatedActiveRules = ({ prev, next }) => {
        dispatch(getActiveRules({ ticketId, userId, index, prev, next }));
    };

    const renderActiveRuleList = () => {
        if (!data) {
            return <div className='fittt-src' />;
        }

        if (data.length === 0) {
            return (
                <div className='fittt-src'>
                    <div className='table-nsr table-nsr--mt0'>No active rules found!</div>
                </div>
            );
        }

        return (
            <div className='fittt-src'>
                <div className='table-sr table-sr--mt0'>
                    <div className='table-sr__label'>All Rules</div>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th'>Rule Title</div>
                        <div className='table-sr__th f25Wrapper'>Rule Description</div>
                        <div className='table-sr__th'>Status</div>
                        <div className='table-sr__th'>Merchant Names</div>
                        <div className='table-sr__th'>Max Subscriptions per user</div>
                    </div>
                    <div className='table-sr__tlc'>
                        {
                            data.map(item => (
                                <div className='table-sr__tlw' key={item.id}>
                                    <div className='table-sr__tl'>{item.title}</div>
                                    <div className='table-sr__tl f25Wrapper'>{item.description}</div>
                                    <div className='table-sr__tl table-sr__tl--cc'>{item.status}</div>
                                    <div className='table-sr__tl table-sr__tl--cc'>{item.actors || '-'}</div>
                                    <div className='table-sr__tl'>{item.maxSubscription}</div>
                                </div>
                            ))
                        }
                    </div>
                    <Pagination prev={prev} next={next} onClickHandler={getPaginatedActiveRules} />
                </div>
            </div>
        );
    };

    return (
        <div className='fittt-cr'>
            {renderActiveRuleList()}
            <Loader visible={loading} />
        </div>
    );
};

export default ActiveRules;

/**
 *
 * TicketSummary
 *
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import { UserDetailsContext } from '../../context';
import { ExpandableCard, FormWithSingleInput, Loader, HorizontalIdNavbar } from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import { INFO_LABEL_MAP } from './constants';
import { getTicketInfo, getTicketNotes, setTicketId } from './actions';
import {
    makeSelectLoading, makeSelectErr, makeSelectTicketInfo, makeSelectTicketNotes,
    makeSelectHasTicketInfo, makeSelectHasTicketNotes, makeSelectTicketId,
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const TicketSummary = () => {
    const userInfo = useContext(UserDetailsContext);
    const {
        basicInfo: {
            accessLevelInfo: {
                isRiskOps,
            },
        },
    } = userInfo;
    

    const dispatch = useDispatch();
    const ticketIdRef = useRef(null);

    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const ticketInfo = useSelector(makeSelectTicketInfo(), shallowEqual);
    const ticketNotes = useSelector(makeSelectTicketNotes(), shallowEqual);
    const hasTicketInfo = useSelector(makeSelectHasTicketInfo(), shallowEqual);
    const hasTicketNotes = useSelector(makeSelectHasTicketNotes(), shallowEqual);
    const ticketId = useSelector(makeSelectTicketId(), shallowEqual);

    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(true);
    // const [ticketId, setTicketId] = useState('');
    const [formErrLabel, setErrLabel] = useState('');

    useEffect(() => {
        if (isSearchCardExpanded) {
            ticketIdRef.current.focus();
        }
    }, [isSearchCardExpanded])

    const handleInputChange = key => event => {
        const value = event.target.value;

        switch (key) {
            case 'ticketId':
                dispatch(setTicketId({ ticketId: value }));
                break;

            default:
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!ticketId) {
            setErrLabel('Please enter a valid ticket id');
            return;
        }

        setErrLabel('');
        dispatch(getTicketInfo({ ticketId }));
        setIsSearchCardExpanded(false);
    };

    return (
        <React.Fragment>
            <HorizontalIdNavbar isFixed isEmptyNavbar />
            <div className='tktsummary-cr'>
                <ExpandableCard
                    extClasses='tktsummary-ec'
                    heading='Search for Ticket Summary'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='tktsummary-sc'>
                        <FormWithSingleInput
                            extClasses={{
                                container: 'tktsummary-fc--m0',
                                label: 'tktsummary-ffl'
                            }}
                            label='Enter Freshdesk Ticket Number'
                            labelId='fd-ticket-no'
                            formType='text'
                            input={{
                                value: ticketId,
                                onChange: handleInputChange('ticketId'),
                                ref: ticketIdRef,
                            }}
                            errLabel={formErrLabel}
                            cta={{
                                primaryLabel: 'Search',
                                onPrimaryClick: handleSubmit,
                            }}
                        />
                    </div>
                </ExpandableCard>
                <div className='tktsummary-src'>
                    {
                        hasTicketInfo &&  INFO_LABEL_MAP.map((item) => (
                            <div className='frWrapper tktsummary-src-wr' key={item.key}>
                                <div className='tktsummary-src__hl'>{item.label}</div>
                                <div className='tktsummary-src__sp'>:</div>
                                <div className='tktsummary-src__cl'>{ticketInfo[item.key] || '-'}</div>
                            </div>
                        ))
                    }
                    { // do not fetch ticket info for risk ops agent
                        (hasTicketInfo && !isRiskOps) ? (
                            <div className='frWrapper tktsummary-src-wr'>
                                <div className='tktsummary-src__hl'>Private/Public Notes</div>
                                <div className='tktsummary-src__sp'>:</div>
                                {
                                    hasTicketNotes ? (
                                        <div className='tktsummary-src-nwr'>
                                            {ticketNotes.privateNotes.map(item => (
                                                <div className='tktsummary-src-nwr__cw' dangerouslySetInnerHTML={{ __html: item }}></div>
                                            ))}
                                            {ticketNotes.publicNotes.map(item => (
                                                <div className='tktsummary-src-nwr__cw' dangerouslySetInnerHTML={{ __html: item }}></div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className='link' onClick={() => dispatch(getTicketNotes({ ticketId }))}>Fetch Notes</div>
                                    )
                                }
                            </div>
                        ) : null
                    }
                    {err ? (<div className='container-err-label'>{err}</div>) : null}
                </div>
            </div>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'ticketSummary', reducer });
const withSaga = injectSaga({ key: 'ticketSummary', saga });

export default compose(
    withReducer,
    withSaga,
)(TicketSummary);

/* eslint-disable no-unused-vars */
/**
 * @file Pre Approved Loans - Component to show the Pre Approved Loans related user info, loan info & upcoming EMI info.
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    KVPairInfo, Loader, Table, Button, Modal, ActionDialog,
} from '../../../components';

import {
    LOAN_ACCOUNT_DETAILS_LABEL_DATA, LAST_TRANSACTIONS_LABEL_DATA,
    UPCOMING_EMI_DETAILS, LOAN_OFFER_DETAILS_LABEL_DATA, customModalStyles,
} from '../constants';
import { getUserInfo, getLoansInfo, getForeclosureAmount } from '../actions';
import { getUserInfoList, getUserLoanInfoList } from '../utils';
import { selectUserInfo, selectLoansInfo } from '../selectors';

import '../style.scss';

const Loans = (props) => {
    const { ticketId, userId, index } = props;

    const dispatch = useDispatch();

    // reducer state variables
    const userInfo = useSelector(selectUserInfo, shallowEqual);
    const loanInfo = useSelector(selectLoansInfo, shallowEqual);
    const [openedActionsCardId, setOpenedActionsCardId] = useState('');

    // variables to control loading status and data
    let loadingUserInfo;
    let userData;
    let hasUserData;
    let errInLoadingUserInfo;

    let loadingLoanInfo;
    let loanData;
    let hasLoanData;
    let errInLoadingLoanInfo;

    if (userInfo[ticketId]) {
        ({
            loading: loadingUserInfo, data: userData, hasData: hasUserData, err: errInLoadingUserInfo,
        } = userInfo[ticketId]);
    }

    if (loanInfo[ticketId]) {
        ({
            loading: loadingLoanInfo, data: loanData, hasData: hasLoanData, err: errInLoadingLoanInfo,
        } = loanInfo[ticketId]);
    }

    const [showLoanInfoModal, setLoanInfoModal] = useState(false);
    const [pastTransactionsTableData, setPastTransactionsTableData] = useState([]);
    const [upcomingEmisTableData, setUpcomingEmisTableData] = useState([]);

    useEffect(() => {
        if (!userInfo[ticketId]?.hasData) {
            dispatch(getUserInfo({ ticketId, userId, index }));
            dispatch(getLoansInfo({ ticketId, userId, index }));
        }
    }, [dispatch]);

    const handleLoanInfoClick = (itemIndex) => {
        setOpenedActionsCardId('');
        if (loanInfo[ticketId].data) {
            const { pastTransactions, upcomingEmis } = itemIndex;

            setPastTransactionsTableData(pastTransactions);
            setUpcomingEmisTableData(upcomingEmis);
        }

        setLoanInfoModal(true);
    };

    const handleModalClose = () => {
        setLoanInfoModal(false);
        setPastTransactionsTableData([]);
        setUpcomingEmisTableData([]);
    };

    const renderActions = (item, idx) => {
        const { loanAccountNumber, loanProgram, vendor } = item;
        return (
            <ActionDialog item={item} openedActionsCardId={openedActionsCardId} setOpenedActionsCardId={setOpenedActionsCardId}>
                <div className='pal-action'>
                    <button
                        onClick={() => {
                            setOpenedActionsCardId('');
                            dispatch(getForeclosureAmount({
                                ticketId, userId, index, loanAccountNumber, loanDetails: { vendor, loanProgram }, idx,
                            }));
                        }}
                        type='button'
                        className='pal-action-label'
                    >
                        Get Foreclosure Amount Now
                    </button>
                    <button
                        onClick={() => handleLoanInfoClick(item)}
                        type='button'
                        className='pal-action-label'
                    >
                        More Details
                    </button>
                </div>
            </ActionDialog>
        );
    };

    return (
        <React.Fragment>
            <div className='pal'>
                {/* User Details */}
                { userInfo[ticketId]?.hasData && (
                    <React.Fragment>
                        <div className='pal-th'>Current Month User Loan Eligibility:</div>
                        <KVPairInfo data={getUserInfoList(userData)} />
                        <div className='pal-th'>Latest Loan Application Details:</div>
                        <KVPairInfo data={getUserLoanInfoList(userData)} />
                        <div className='pal-th'>Loan Offer Details</div>
                        <Table
                            labelData={LOAN_OFFER_DETAILS_LABEL_DATA}
                            contentData={userData.loanOfferDetails}
                            errLabel={errInLoadingUserInfo}
                            extClasses={{
                                container: 'mb-60',
                            }}
                        />
                    </React.Fragment>
                )}

                {/* Loan and EMI Details */}
                { userInfo[ticketId]?.data && (
                    <React.Fragment>
                        <div className='pal-th'>Loan Account Details</div>
                        <Table
                            labelData={LOAN_ACCOUNT_DETAILS_LABEL_DATA}
                            contentData={loanData}
                            extClasses={{
                                container: 'mb-60',
                            }}
                            errLabel={errInLoadingLoanInfo}
                            renderActionableColumn={renderActions}
                        />

                        <Modal
                            visible={showLoanInfoModal}
                            modalStyles={customModalStyles}
                            contentLabel='Loan Info'
                        >
                            {upcomingEmisTableData && (
                                <React.Fragment>
                                    <div className='pal-th'>Upcoming EMI Details</div>
                                    <Table
                                        labelData={UPCOMING_EMI_DETAILS}
                                        contentData={upcomingEmisTableData}
                                        errLabel={errInLoadingLoanInfo}
                                        extClasses={{
                                            container: 'mb-60',
                                        }}
                                    />
                                </React.Fragment>
                            )}

                            {pastTransactionsTableData && (
                                <React.Fragment>
                                    <div className='pal-th'>Last 5 Transactions</div>
                                    <Table
                                        labelData={LAST_TRANSACTIONS_LABEL_DATA}
                                        contentData={pastTransactionsTableData}
                                        errLabel={errInLoadingLoanInfo}
                                        extClasses={{
                                            container: 'mb-60',
                                        }}
                                    />
                                </React.Fragment>
                            )}

                            <Button
                                v2
                                primary
                                label='Close'
                                extClasses='pal-btn'
                                onClick={handleModalClose}
                            />
                        </Modal>
                    </React.Fragment>
                )}

            </div>
            <Loader visible={loadingUserInfo} />
        </React.Fragment>
    );
};

export default Loans;

/**
 * @file Manages the saga generator functions for transaction view V2
 */

import {
    call, put, takeLatest, all,
} from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify, getErrMsg,
} from '../../utils';
import {
    TRANSACTION_RISK_REVIEW_DETAILS_ENDPOINT,
    TRANSACTION_RISK_AGGREGATE_DATA,
    RISK_OPS_ROOT,
} from '../../api/routes';
import { RudderEvent, pushRudderEvent } from '../../rudderEvents';

import {
    GET_TRANSACTION_VIEW_V2, SET_TRANSACTION_VIEW_V2_STREAM, SET_TRANSACTION_VIEW_V2,
    ERR_TRANSACTION_VIEW_V2, SET_TRANSACTION_VIEW_V2_STREAM_COMPLETE, FIRE_TRANSACTION_VIEW_RENDER_MATRIC,
    FIRE_TRANSACTION_MATRIC_EVENT, REQUEST_AGGREGATED_DATA_POINTS, SET_AGGREGATED_DATA_POINTS, GET_ANNOTATIONS, SET_ANNOTATIONS,
    CREATE_TRANSACTIONS_ANNOTATIONS,
} from './constants';
import { dataForTableConverter, formattedAggregatedData, formatAllowedAnnotationsData } from './utils';
import { setTransactionAnnotationModalVisibility } from './actions';

const transactionViewEvent = async (startTime, payload, traceId) => {
    try {
        const {
            agentMail, caseId,
        } = payload;
        await pushRudderEvent(RudderEvent.TransactionView.TransactionViewLoadTime, agentMail, {
            caseId,
            traceId,
            startTime,
            endTime: Date.now(),
            totalLatency: Date.now() - startTime,
        });
    } catch (error) {
        console.error(error);
    }
};

function* fireRenderMatricEvent(action) {
    try {
        const {
            agentMail, caseId, startTime, endTime,
        } = action.data;
        yield call(pushRudderEvent, RudderEvent.TransactionView.TXN_VIEW_RENDERING_TIME, agentMail, {
            caseId,
            startTime,
            endTime,
            totalLatency: endTime - startTime,
        });
    } catch (error) {
        console.error(error);
    }
}

function* fireTransactionViewMatricEventSaga(action) {
    try {
        const {
            agentMail, eventName, payload,
        } = action.data;
        yield call(pushRudderEvent, eventName, agentMail, payload);
    } catch (error) {
        console.error(error);
    }
}

function* getTransactionViewV2Saga(action) {
    const { payload, eventPayload } = action.data;
    const queryString = getQueryStringFromObject(payload);

    try {
        /**
         * isStream is used as a flag to get response in streams or single fetch
         * Stream data is needed to load content as and when the data comes in stream from backend.
         * Single fetch would take some time to aggregate all the data and show in the frontend.
         *
         * true - allow streams
         * false - allow single fetching
         *
         * For testing isStream can be set to false to test single call to API.
         */

        const isStream = true;
        const startTime = Date.now();
        if (!isStream) {
            const response = yield call(
                [clientApiWrapper, clientApiWrapper.get],
                `${TRANSACTION_RISK_REVIEW_DETAILS_ENDPOINT}?${queryString}`,
            );

            const data = dataForTableConverter(response.transactions);

            yield put({ type: SET_TRANSACTION_VIEW_V2, data });
        } else {
            const options = yield call([clientApiWrapper, clientApiWrapper.getOptions], 'GET');

            const streamResponse = yield call(fetch, `${TRANSACTION_RISK_REVIEW_DETAILS_ENDPOINT}?${queryString}`, options);
            if (!streamResponse.ok) {
                const error = yield streamResponse.json();
                if (error) {
                    throw error;
                }

                throw new Error(streamResponse.statusText);
            }

            if (!streamResponse.body) {
                throw new Error('ReadableStream not supported in this browser');
            } else {
                // readStream reader
                const reader = streamResponse.body.getReader();
                // brokenchunk is to store stringified broken array of object
                let brokenChunk = '';

                // continuously reading the data from the readstream whenever its coming from the api
                while (true) {
                    const { done, value } = yield reader.read();

                    // break the loop when stream is completed
                    if (done) {
                        transactionViewEvent(startTime, eventPayload, streamResponse.headers.get('trace-id'));
                        yield put({
                            type: SET_TRANSACTION_VIEW_V2_STREAM_COMPLETE,
                        });
                        break;
                    }

                    const decoder = new TextDecoder();
                    // append borkenchunk with decoded value
                    const result = brokenChunk + decoder.decode(value);
                    // splitting each array of objects from string based on '\t\n'
                    const splittedResult = result.split('\t\n');
                    const parsedArr = [];

                    // try parsing each stringified array of object into object
                    // else store the broken string in brokenChunk
                    // eslint-disable-next-line no-loop-func
                    splittedResult.forEach((item) => {
                        try {
                            const parsed = JSON.parse(item); // parsed string
                            if (item !== '') parsedArr.push(...parsed);
                        } catch (e) {
                            brokenChunk = item; // unparsed string
                        }
                    });

                    yield put({
                        type: SET_TRANSACTION_VIEW_V2_STREAM,
                        data: dataForTableConverter(parsedArr), // converting the response
                    });
                }
            }
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TRANSACTION_VIEW_V2, data: { err: getErrMsg(e) } });
    }
}

function* getAggregatedDataPoints(payload) {
    try {
        const { caseId } = payload.data;
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTION_RISK_AGGREGATE_DATA}?caseId=${caseId}`,
        );

        yield put({ type: SET_AGGREGATED_DATA_POINTS, data: formattedAggregatedData(response) });
    } catch (e) {
        yield put({ type: SET_AGGREGATED_DATA_POINTS, data: null });
    }
}

function* createTransactionsAnnotations(action) {
    try {
        const { mappingList } = action.data;

        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${RISK_OPS_ROOT}/transactions-annotations`,
            { annotationMappingList: mappingList },
        );
        toastify('SuccessFully annotation Created', 'success');
        yield put(setTransactionAnnotationModalVisibility(false));
    } catch (error) {
        const err = getErrLabel(error);
        toastify(err, 'error');
    }
}

function* getAllowedAnnotations(action) {
    try {
        const { annotationRequestPayload, flowType } = action.data;
        const { id, type } = annotationRequestPayload;

        const queryString = getQueryStringFromObject({ name: id, type });

        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_ROOT}/allowed-annotation?${queryString}`,
        );

        yield put({
            type: SET_ANNOTATIONS,
            data: {
                [flowType]: formatAllowedAnnotationsData(response.ui_element_allowed_annotations),
            },
        });
    } catch (error) {
        yield put({ type: SET_ANNOTATIONS, data: null });
        const err = getErrLabel(error);
        toastify(err, 'error');
    }
}

export default function* transactionViewSaga() {
    yield all(
        [
            yield takeLatest(GET_TRANSACTION_VIEW_V2, getTransactionViewV2Saga),
            yield takeLatest(FIRE_TRANSACTION_VIEW_RENDER_MATRIC, fireRenderMatricEvent),
            yield takeLatest(FIRE_TRANSACTION_MATRIC_EVENT, fireTransactionViewMatricEventSaga),
            yield takeLatest(REQUEST_AGGREGATED_DATA_POINTS, getAggregatedDataPoints),
            yield takeLatest(GET_ANNOTATIONS, getAllowedAnnotations),
            yield takeLatest(CREATE_TRANSACTIONS_ANNOTATIONS, createTransactionsAnnotations),
        ],
    );
}

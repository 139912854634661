/* eslint-disable camelcase */
/**
 * @file Redux sagas for UserActions container
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { TICKETS_ROOT, PAYOUT_ROOT, APP_LOGS_ROOT } from '../../api/routes';

import {
    CALLBACK_USER, SET_CALLBACK_USER, ERR_CALLBACK_USER, RAISE_PAY_REQUEST,
    SET_PAY_REQUEST, ERR_PAY_REQUEST, TRIGGER_PUSH_NOTIFICATION_APPLOGS,
    SET_PUSH_NOTIFICATION_APPLOGS, GET_USER_EXPORTED_APP_LOGS, GET_ALLOWED_FC_VALUE, SET_ALLOWED_FC_VALUE,
} from './constants';
import { setUserExportedAppLogsAction } from './actions';

function* callbackUser(action) {
    const { ticketId, phoneNumber } = action.data;

    let callbackUserURL = `${TICKETS_ROOT}/${ticketId}/callbackAdmin`;

    if (phoneNumber) {
        callbackUserURL = `${TICKETS_ROOT}/${ticketId}/callbackAdmin?phoneNumber=${phoneNumber}`;
    }

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            callbackUserURL,
        );

        toastify(response.status, 'success');
        yield put({ type: SET_CALLBACK_USER, data: { ...response, ticketId } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CALLBACK_USER, data: { ticketId } });
    }
}

function* raisePayRequest(action) {
    const {
        ticketId,
        userId,
        payout_type, // new API accepts payout type and payout value, amount deprecated
        payout_value,
        reason,
        resolve,
    } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${PAYOUT_ROOT}/raise`,
            {
                ticketId,
                userId,
                payout_type,
                payout_value,
                reason,
            },
        );

        toastify(response.status, 'success');
        yield put({ type: SET_PAY_REQUEST, data: { ...response, ticketId } });
        yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_PAY_REQUEST, data: { ticketId } });
    }
}

function* triggerPushNotification(action) {
    const { ticketId } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${APP_LOGS_ROOT}/push-notification/${ticketId}`,
        );

        toastify(response.status, 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
    }

    yield put({
        type: SET_PUSH_NOTIFICATION_APPLOGS,
        data: {
            ticketId,
            flag: false,
        },
    });
}

function* getUserExportedAppLogsSaga(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject({ ticketId });

    try {
        const { userExportedAppLogs } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${APP_LOGS_ROOT}/user-exported-app-logs/?${queryString}`,
        );

        yield put(setUserExportedAppLogsAction({ ticketId, userExportedAppLogs }));
    } catch (e) {
        yield put(setUserExportedAppLogsAction({ ticketId, err: e.errMsgForUI }));
    }
}

function* getAllowedFiCoinsPayoutValuesSaga() {
    try {
        const { allowedFiCoinValues } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAYOUT_ROOT}/get-allowed-ficoin-values`,
        );

        yield put({
            type: SET_ALLOWED_FC_VALUE,
            data: allowedFiCoinValues,
        });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
    }
}

export default function* userActionsSaga() {
    yield all(
        [
            yield takeLatest(CALLBACK_USER, callbackUser),
            yield takeLatest(RAISE_PAY_REQUEST, raisePayRequest),
            yield takeLatest(TRIGGER_PUSH_NOTIFICATION_APPLOGS, triggerPushNotification),
            yield takeLatest(GET_USER_EXPORTED_APP_LOGS, getUserExportedAppLogsSaga),
            yield takeLatest(GET_ALLOWED_FC_VALUE, getAllowedFiCoinsPayoutValuesSaga),
        ],
    );
}

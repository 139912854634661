/*
 *
 * LivenessStatus actions
 *
 */

import {
    GET_LIVENESS_STATUS_LIST, UPDATE_LIVENESS_STATUS, SET_MONORAIL_ID,
} from './constants';

export const getLivenessStatusList = data => ({
    type: GET_LIVENESS_STATUS_LIST,
    data
});

export const updateLivenessStatus = data => ({
    type: UPDATE_LIVENESS_STATUS,
    data
});

export const setMonorailId = data => ({
    type: SET_MONORAIL_ID,
    data
});

/**
 *
 * Profile - Component to show all the available SOP .
 *
 */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
    // eslint-disable-next-line no-unused-vars
    ActionDialog, Button, KVPairInfo, LoaderOrError, Modal, Table,
} from '../../../../components';
import { getQueryStringFromObject } from '../../../../utils';

import { SEARCH_PATH } from '../../../AppV2/routes';

import {
    getActivitiesAction, getActivityDetailsAction, getIssueCategoryDetailsAction, getProfileAction, setTicketIdAction,
} from '../../actions';
import { selectTicketId, selectUserActivities, selectUserDetails } from '../../selector';

const Profile = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const ticketId = useSelector(selectTicketId, shallowEqual);
    const userDetails = useSelector(selectUserDetails, shallowEqual);
    const userActivities = useSelector(selectUserActivities, shallowEqual);
    const { ticketId: ticketIdParam } = useParams();
    const [loadingText, setLoadingText] = useState('');
    // const [openedActionsCardId, setOpenedActionsCardId] = useState('');
    const [showActivityDetailsModal, setShowActivityDetailsModal] = useState(false);

    const {
        loading: loadingDetails, err: errDetails, data: dataDetails,
    } = userDetails || {};

    const {
        loading: loadingActivities, err: errActivities, data: dataActivities, issueCategoryDetails, activityDetails,
    } = userActivities || {};

    const {
        loading: loadingIssueCategory, err: errIssueCategory,
    } = issueCategoryDetails || {};

    const {
        loading: loadingActivityDetails, err: errActivityDetails, data: dataActivityDetails,
    } = activityDetails || {};

    const { userProfile } = dataDetails || {};
    const { contactDetails, accountDetails, deviceDetails } = userProfile || {};

    const { activityDetails: activities, prev, screen } = dataActivities || {};

    const loading = loadingDetails || loadingActivities || loadingIssueCategory || loadingActivityDetails;
    const err = errDetails || errActivities || errIssueCategory || errActivityDetails;

    const callAction = (dispatchFunction) => new Promise((resolve, reject) => {
        try {
            dispatchFunction(resolve);
        } catch (e) {
            reject(e);
        }
    });

    useEffect(() => {
        if (ticketIdParam !== `${ticketId}`) {
            dispatch(setTicketIdAction({ path: ['ticketId'], value: ticketIdParam }));
        }
    }, [ticketIdParam]);

    useEffect(() => {
        setLoadingText('Connecting to Ticket...');
        if (ticketId) {
            callAction((resolve) => dispatch(getProfileAction({ screenKey: ['userDetails'], ticketId, resolve })))
                .then(() => setLoadingText('Fetching Recent Activity...'))
                .then(() => callAction((resolve) => dispatch(getActivitiesAction({
                    screenKey: ['userActivities'], ticketId, resolve, clearData: true,
                }))))
                .finally(() => setLoadingText(''));
        }
    }, [ticketId]);

    /* commented out the mutiple actions handling code as it is not required for now */
    // const handleOnActionClick = (action, activity) => {
    //     switch (action.deeplink.screen_name) {
    //         case 'SCREEN_NAME_RECENT_ACTIVITY_VIEW_DETAILS': {
    //             setLoadingText('Fetching Activity Details...');
    //             callAction((resolve) => dispatch(getActivityDetailsAction({
    //                 screenKey: ['userActivities', 'activityDetails'], ticketId, meta: activity?.meta, resolve,
    //             }))).then(() => setShowActivityDetailsModal(true))
    //                 .finally(() => setLoadingText(''));
    //             break;
    //         }
    //         default: {
    //             console.log("🚀 ~ handleOnActionClick ~ action:", action)
    //             break;
    //         }
    //     }
    // }

    const renderActions = (item) => (
        <div className='frccWrapper'>
            <div className='p-5'>
                <Button
                    v2
                    primary
                    label='Select'
                    extClasses='sop-table-cta'
                    onClick={() => {
                        setLoadingText('Fetching Issue Category...');
                        callAction(
                            (resolve) => dispatch(getIssueCategoryDetailsAction({
                                screenKey: ['userActivities', 'issueCategoryDetails'], meta: item?.meta, resolve,
                            })),
                        ).then((resp) => {
                            const { l1, l2, l3 } = resp || {};
                            history.push({
                                pathname: SEARCH_PATH,
                                search: getQueryStringFromObject({
                                    l1, l2, l3, type: 'SEARCH_RESULT_TYPE_SOP',
                                }),
                            });
                        }).finally(() => setLoadingText(''));
                    }}
                />
            </div>
            <div className='p-5'>
                <Button
                    v2
                    secondary
                    label='View Details'
                    extClasses='sop-table-cta'
                    onClick={() => {
                        setLoadingText('Fetching Activity Details...');
                        callAction((resolve) => dispatch(getActivityDetailsAction({
                            screenKey: ['userActivities', 'activityDetails'], ticketId, meta: item?.meta, resolve,
                        }))).then(() => setShowActivityDetailsModal(true))
                            .finally(() => setLoadingText(''));
                    }}
                />
            </div>
            {/* commented out the mutiple actions rendering code as it is not required for now  */}
            {/* {
                item?.actions && Array.isArray(item?.actions) && item?.actions?.length > 0 && (
                    <ActionDialog
                        item={item}
                        key={item?.id || index}
                        extClasses={{
                            container: 'sop-table-cta-dropdown p-20',
                        }}
                        openedActionsCardId={openedActionsCardId}
                        setOpenedActionsCardId={setOpenedActionsCardId}
                    >
                        <div className='fccWrapper'>
                            {
                                item?.actions?.map((action) => (
                                    <button
                                        key={action?.id}
                                        type='button'
                                        className='p-5'
                                        onClick={() => {
                                            setOpenedActionsCardId('');
                                            handleOnActionClick(action, item);
                                        }}

                                    >
                                        {action?.label}
                                    </button>
                                ))
                            }
                        </div>
                    </ActionDialog>
                )
            } */}
        </div>
    );

    const renderUserDataSections = (section) => (
        <div className='frWrapper'>
            <div className='p-20'>
                <div className='sop-box-title sop-box-cell-title'>{section.title}</div>
            </div>
            <div className='sop-box-values sop-box-grid-v2'>
                {
                    Array.isArray(section?.data) && section?.data?.map((item) => (
                        <div className='sop-box-cell'>
                            <div className='sop-box-cell-title'>{item.label}</div>
                            <div className='sop-box-cell-value'>
                                {item.value === null || item.value === undefined || item.value === '' ? '-' : `${item.value}`}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <div>
                <div className='sop-heading'>User Details</div>
                <div className='sop-cc sop-box'>
                    {renderUserDataSections({ title: 'Contact details', data: contactDetails })}
                    <div className='line' />
                    {renderUserDataSections({ title: 'Account details', data: accountDetails })}
                    <div className='line' />
                    {renderUserDataSections({ title: 'Device details', data: deviceDetails })}
                </div>
            </div>

            {
                (activities || screen) && (
                    <div>
                        <div className='sop-heading'>Recent Activity</div>
                        <Table
                            v2
                            infiniteScroll
                            rowDataVersion={2}
                            objKey='header_key'
                            labelData={activities?.columnData}
                            contentData={activities?.rowData}
                            renderActionableColumn={renderActions}
                            infiniteScrollPaginationToken={prev}
                            errLabel={screen === 2 && !activities && 'Current level of authorization is not enough to view this table'}
                            nsrLabel='No Recent Activities Found for the user in last 3 months'
                            paginatedClickHandler={({ token }) => {
                                setLoadingText('Fetching Recent Activity...');
                                callAction((resolve) => dispatch(getActivitiesAction({
                                    screenKey: ['userActivities'], ticketId, prevToken: token, resolve,
                                }))).finally(() => setLoadingText(''));
                            }}
                            extClasses={{
                                container: 'sop-cc sop-table',
                                headerCr: 'sop-table-header',
                                contentCr: 'sop-table-cont',
                                rowCr: 'sop-table-row',
                                infiniteScrlBtn: 'sop-table-cta',
                            }}
                        />
                    </div>
                )
            }
            <Modal
                visible={showActivityDetailsModal && !loadingActivityDetails}
                modalStyles={{
                    content: {
                        maxHeight: 700,
                        width: 800,
                    },
                }}
                contentLabel='Activity Details Modal'
            >
                <div className='recent-activity-modal__title'>Activity Details</div>
                {
                    dataActivityDetails?.map((item) => (
                        <div className='recent-activity-section__container'>
                            <div className='recent-activity-section__title'>{item.title}</div>
                            <KVPairInfo
                                v2
                                data={item.label_values}
                            />
                        </div>
                    ))
                }
                <Button
                    primary
                    extClasses='txns-modal-bwr'
                    label='Close'
                    onClick={() => setShowActivityDetailsModal(!showActivityDetailsModal)}
                />
            </Modal>
            <LoaderOrError loading={loading} errorLabel={err} loadingText={loadingText} />
        </React.Fragment>
    );
};

export default Profile;

// bulk user info by ids
export const GET_BULK_USER_INFO = 'app/BUDBI/GET_BULK_USER_INFO';
export const SET_BULK_USER_INFO = 'app/BUDBI/SET_BULK_USER_INFO';
export const ERR_BULK_USER_INFO = 'app/BUDBI/ERR_BULK_USER_INFO';
export const RESET_BULK_USER_INFO = 'app/BUDBI/RESET_BULK_USER_INFO';

export const LABEL_DATA = [
    {
        id: 1,
        label: 'Actor ID',
        key: 'actorId',
    },
    {
        id: 2,
        label: 'Account ID',
        key: 'accountId',
    },
    {
        id: 3,
        label: 'Phone Number',
        key: 'phoneNumber',
    },
    {
        id: 2,
        label: 'Failure Reason',
        key: 'failureReason',
        extClasses: 'f2Wrapper',
    },
];

export const PRIMARY_ACTION_MAP = {
    uploadCSV: 'Upload CSV',
    enterCSV: 'Enter Comma Separated Values Manually',
};

export const SECONDARY_ACTION_MAP = {
    actorId: 'Actor Id',
    accountId: 'Account Id',
    phoneNumber: 'Phone Number',
    allIds: 'Both Actor & Account Id',
}

export const PRIMARY_ACTION_LIST = [
    PRIMARY_ACTION_MAP.uploadCSV,
    PRIMARY_ACTION_MAP.enterCSV,
];

export const SECONDARY_ACTION_UPLOAD_CSV_LIST = [
    SECONDARY_ACTION_MAP.actorId,
    SECONDARY_ACTION_MAP.accountId,
    SECONDARY_ACTION_MAP.phoneNumber,
    SECONDARY_ACTION_MAP.allIds,
];

export const SECONDARY_ACTION_ENTER_CSV_LIST = [
    SECONDARY_ACTION_MAP.actorId,
    SECONDARY_ACTION_MAP.accountId,
    SECONDARY_ACTION_MAP.phoneNumber,
];

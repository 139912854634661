import React from 'react';

const ProgressBar = ({ number, progressBarStyleClassName = '' }) => {
    const width = `${number}%`;
    const progressBarStyle = {
        width,
        height: '15px',
        borderRadius: '10px',
    };
    const outerDivStyle = {
        width: '100%',
        height: '15px',
        backgroundColor: 'lightgrey',
        borderRadius: '10px',
    };

    return (
        <div style={outerDivStyle}>
            <div style={progressBarStyle} className={progressBarStyleClassName} />
        </div>

    );
};

export default ProgressBar;

/**
 * @file Manages the constants for Scripts component
 */
import { APP_V2_KEY } from '../AppV2/constants';

export const SCRIPTS_KEY = `${APP_V2_KEY}/SCRIPTS`;

// Fetch Script
export const GET_SCRIPT = `${SCRIPTS_KEY}/GET_SCRIPT`;
export const SET_SCRIPT = `${SCRIPTS_KEY}/SET_SCRIPT`;
export const ERR_SCRIPT = `${SCRIPTS_KEY}/ERR_SCRIPT`;

export const renderPostCallSteps = (postCallStep) => (
    [
        {
            title: 'L1 Product category',
            value: postCallStep?.product_category,
        },
        {
            title: 'L2 Product category details',
            value: postCallStep?.product_category_details,
        },
        {
            title: 'L3 Sub category details',
            value: postCallStep?.sub_category,
        },
        {
            title: 'Status',
            value: postCallStep?.status,
        },
        {
            title: 'Group',
            value: postCallStep?.group,
        },
        {
            title: 'Agent',
            value: postCallStep?.agent,
        },
    ]
);

export const SCREEN_KEY = {
    search: 'search',
    script: 'script',
};

export const SUB_SCREEN_KEY = {
    results: 'results',
    meta: 'meta',
};

export const CATEGORY_VARIABLE_MAPPING = {
    l1: 'product_category_detail_meta_list',
    l2: 'sub_category_meta_list',
};

export const SHERLOCK_V2_ENTRY_POINT = 'SHERLOCK_V2_ENTRY_POINT';

/**
 * @file Manages the action functions for transaction case details container
 */

import {
    GET_TRANSACTION_CASE_DETAILS,
    GET_TRANSACTION_CASE_ANNOTATION,
    GET_TRANSACTION_CASE_COMMENTS,
} from './constants';

export const getTransactionCaseDetailsAction = (data) => ({
    type: GET_TRANSACTION_CASE_DETAILS,
    data,
});

export const getTransactionCaseAnnotation = (data) => ({
    type: GET_TRANSACTION_CASE_ANNOTATION,
    data,
});

export const getTransactionCaseComments = (data) => ({
    type: GET_TRANSACTION_CASE_COMMENTS,
    data,
});

/**
 * @file Common utility functions for handling error cases throughout the app
 */

import { isNilOrEmpty } from './helpers';
import toastify from './toastify';

/**
 * This function helps to get the proper error label
 * on the basis whether description is string or object
 * @param {object} err error object consists of description and message from FE server
 * @returns {string} error label
 */
export const getErrLabel = (err) => {
    const { errMsgForUI, message, description } = err;

    if (errMsgForUI && typeof errMsgForUI === 'string') {
        return errMsgForUI;
    }

    if (typeof description === 'string' && !!description) {
        return description;
    }

    return message;
};

/**
 * This function helps to get the proper error label for showing as a toast
 * It checks if the message field exists in the error object and if not sends a generic error message
 * @param {object} err error object consists of description and message from FE server
 * @returns {string} error label for toast
 */
export const getErrForToast = (err) => {
    const { message } = err;

    // 1st preference - message
    let toastMsg = message;

    // 2nd preference - default fall back
    if (!toastMsg) toastMsg = 'Error occurred!';

    return toastMsg;
};

/**
 * This function helps to get the proper error message and shows the trace id as well if it is present
 * @param {object} err error object consists of description and message from FE server
 * @returns {string} formatted error message
 */
export const getErrMsg = (err) => {
    const {
        errMsgForUI, message, description, traceId,
    } = err;

    // 1st preference - errMsgForUI
    let errMsg = errMsgForUI;

    // 2nd preference - description
    if (!errMsg) errMsg = description;

    // 3rd preference - message
    if (!errMsg) errMsg = message;

    // 4th preference - default fall back
    if (!errMsg) errMsg = 'An unexpected error occurred while carrying out the action';

    if (traceId) errMsg = `${errMsg}\n\ntraceId: ${traceId}`;

    return errMsg;
};

/**
 * Common function to build the error object thats required to dispatch the error action inside any saga
 * @param {object} action The action objet that contains the type and data for the given Saga
 * @param {object} errorObj The error object of the catch block
 * @returns {object} The type and data required for dispatch the error action
 */
export const getErrorObjectForSaga = (action, errorObj) => {
    if (isNilOrEmpty(action) || isNilOrEmpty(errorObj)) return {};

    const { type, data } = action;

    if (!type) return {};

    return {
        type,
        data: {
            err: getErrMsg(errorObj),
            ...data,
        },
    };
};

/**
 * Common function to show the error message as a toast on the UI
 * @param {object} errorObj The error object of the catch block
 * @returns {void}
 */
export const showErrorToastMsg = (errorObj) => {
    if (isNilOrEmpty(errorObj)) return;

    const err = getErrForToast(errorObj);

    toastify(err, 'error');
};

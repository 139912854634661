/**
 *
 * ClaimsView
 *
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, DropdownContent, DropdownWrapper, ExpandableCard, FormWithSingleInput, Loader,
} from '../../../components';
import { dateUtils, getDropdownHeight } from '../../../utils';

import {
    getUserRewards, getUserRedeemedOffers, getUserRewardInfo, setSearchParamsForClaimsView as setSearchParams,
    resetSearchParamsForClaimsView as resetSearchParams, getUserRedeemedExchangerOffers, getFiStoreRewards,
} from '../actions';
import {
    makeSelectRewards, makeSelectRedeemedOffers, makeSelectRewardInfo, makeSelectSearchParametersForClaimsView,
    makeSelectRedeemedExchangerOffers, makeSelectFiStoreRewardsList, makeSelectFiStoreRewardAdditionalDetails,
} from '../selectors';
import {
    OFFER_TYPE, OFFER_VENDOR, OFFER_REDEMPTION_STATE, EXPIRY_STATUS, REWARD_TYPE, VISIBILITY_TYPE,
    CLAIMS_VIEW_SEARCH_PARAMS_KEYS, CATEGORY_MAP, EXCHANGER_OFFER_REDEMPTION_STATE, CLAIMS_CATEGORY,
} from '../constants';

import '../style.scss';

import ClaimedOffersView from './ClaimedOffersView';
import ClaimedRewardsView from './ClaimedRewardsView';
import ClaimedExchangerOffersView from './ClaimedExchangerOffersView';
import ClaimedFiStoreRewardsView from './ClaimedFiStoreRewardsView';

const ClaimsView = (props) => {
    const dispatch = useDispatch();

    const searchParameters = useSelector(makeSelectSearchParametersForClaimsView(), shallowEqual);
    const redeemedOffersList = useSelector(makeSelectRedeemedOffers(), shallowEqual);
    const rewardsList = useSelector(makeSelectRewards(), shallowEqual);
    const rewardInfo = useSelector(makeSelectRewardInfo(), shallowEqual);
    const redeemedExchangerOffersList = useSelector(makeSelectRedeemedExchangerOffers(), shallowEqual);
    const fiStoreRewardsList = useSelector(makeSelectFiStoreRewardsList(), shallowEqual);
    const fiStoreRewardAdditionalDetails = useSelector(makeSelectFiStoreRewardAdditionalDetails(), shallowEqual);

    const [isClaimCardExpanded, setIsClaimCardExpanded] = useState(false);
    const [showCategoryDD, setCategoryDD] = useState(false);
    const [showOfferTypeDD, setOfferTypeDD] = useState(false);
    const [showOfferVendorDD, setOfferVendorDD] = useState(false);
    const [showOfferRedemptionStateDD, setOfferRedemptionStateDD] = useState(false);
    const [showExpiryStatusDD, setExpiryStatusDD] = useState(false);
    const [showRewardTypeDD, setRewardTypeDD] = useState(false);
    const [showVisibilityTypeDD, setVisibilityTypeDD] = useState(false);
    const [showExchangerOfferRedemptionStateDD, setExchangerOfferRedemptionStateDD] = useState(false);
    const [offerId, setOfferId] = useState('');
    const [orderId, setOrderId] = useState('');
    const [errLabel, setErrLabel] = useState('');

    const { fromDate: fromInitialDate, toDate: toInitialDate } = dateUtils.getInitialDateRange('02-01-2021', true); // 01 Feb

    const { ticketId, userId, index } = props;

    let fromDate;
    let toDate;
    let category;
    let offerType;
    let offerVendor;
    let offerRedemptionState;
    let expiryStatus;
    let rewardType;
    let visibilityType;
    let exchangerOfferRedemptionState;

    // extract the search parameters for passed ticket id
    if (searchParameters[ticketId]) {
        ({
            fromDate, toDate, category, offerType, offerVendor, offerRedemptionState, expiryStatus,
            rewardType, visibilityType, exchangerOfferRedemptionState,
        } = searchParameters[ticketId]);
    }

    /**
     * initialise from and to date with the date utils value
     * this won't get called again & again
     */
    useEffect(() => {
        if (!fromDate && !toDate) {
            dispatch(setSearchParams({
                ticketId,
                fromDate: fromInitialDate,
                toDate: toInitialDate,
            }));
        }
    }, [fromDate, toDate]);

    let loadingRedeemedOffers;
    let redeemedOffersData;
    let redeemedOffersDataPrev;
    let redeemedOffersDataNext;
    let loadingRewards;
    let rewardsData;
    let rewardsDataPrev;
    let rewardsDataNext;
    let rewardInfoData;
    let hasRewardInfoData;
    let loadingRedeemedExchangerOffers;
    let redeemedExchangerOffersData;
    let redeemedExchangerOffersDataPrev;
    let redeemedExchangerOffersDataNext;

    if (redeemedOffersList[ticketId]) {
        ({
            loading: loadingRedeemedOffers, data: redeemedOffersData, prev: redeemedOffersDataPrev,
            next: redeemedOffersDataNext,
        } = redeemedOffersList[ticketId]);
    }

    if (rewardsList[ticketId]) {
        ({
            loading: loadingRewards, data: rewardsData, prev: rewardsDataPrev,
            next: rewardsDataNext,
        } = rewardsList[ticketId]);
    }

    if (rewardInfo[ticketId]) {
        ({ data: rewardInfoData, hasData: hasRewardInfoData } = rewardInfo[ticketId]);
    }

    if (redeemedExchangerOffersList[ticketId]) {
        ({
            loading: loadingRedeemedExchangerOffers, data: redeemedExchangerOffersData, prev: redeemedExchangerOffersDataPrev,
            next: redeemedExchangerOffersDataNext,
        } = redeemedExchangerOffersList[ticketId]);
    }

    const {
        loading: loadingFiStoreRewardsList, data: fiStoreRewardsListData,
    } = fiStoreRewardsList[ticketId] || {};

    const {
        loading: loadingFiStoreRewardAdditionalDetails, data: fiStoreRewardAdditionalDetailsData,
    } = fiStoreRewardAdditionalDetails[ticketId] || {};

    const getRewardInfo = (item) => {
        dispatch(getUserRewardInfo({
            ticketId,
            userId,
            index,
            rewardOfferId: item.rewardOfferId,
        }));
    };

    const handleDateChange = (key) => (value) => {
        const data = {
            key,
            value,
            fromDate,
            toDate,
        };
        const { fromDate: modifiedFromDate, toDate: modifiedToDate } = dateUtils.getModifiedDate(data);

        dispatch(setSearchParams({
            ticketId,
            fromDate: modifiedFromDate,
            toDate: modifiedToDate,
        }));
    };

    const renderDateBlock = (key) => {
        let label;
        let labelId;
        let value;
        let maxDate;
        let minDate;

        switch (key) {
            case 'fromDate':
                label = 'From Date*';
                labelId = 'from-date';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = fromDate);
                break;

            case 'toDate':
                label = 'To Date*';
                labelId = 'to-date';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = toDate);
                break;

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'rewards-fc',
                    label: 'rewards-dd__lb',
                    inputField: 'rewards-dd__ip',
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: handleDateChange(key),
                    maxDate,
                    minDate,
                }}
                hideErrLabel
            />
        );
    };

    const makePayloadAndCallApi = (params = {}) => {
        const { prev, next } = params;

        let payload = {
            from: fromDate.value,
            to: toDate.value,
        };

        if (category === CATEGORY_MAP.offers) {
            payload = {
                ...payload,
                offerType,
                offerVendor,
                offerRedemptionState,
                expiryStatus,
            };

            dispatch(getUserRedeemedOffers({
                ticketId, userId, index, ...payload, prev, next,
            }));
        } else if (category === CATEGORY_MAP.rewards) {
            payload = {
                ...payload,
                rewardType,
                visibilityType,
                offerId,
            };

            dispatch(getUserRewards({
                ticketId, userId, index, ...payload, prev, next,
            }));
        } else if (category === CATEGORY_MAP.exchangerOffers) {
            payload = {
                ...payload,
                exchangerOfferRedemptionState,
            };

            dispatch(getUserRedeemedExchangerOffers({
                ticketId, userId, index, ...payload, prev, next,
            }));
        } else if (category === CATEGORY_MAP.fiStore) {
            payload = {
                ...payload,
                orderId,
            };

            dispatch(getFiStoreRewards({
                ticketId, userId, index, ...payload, prev, next,
            }));
        }
    };

    const handleClaimsSubmit = (e) => {
        e.preventDefault();

        if (!category || !fromDate.value || !toDate.value) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        makePayloadAndCallApi();

        setErrLabel('');
        setIsClaimCardExpanded(false);
    };

    const getPaginatedClaims = ({ prev, next }) => {
        makePayloadAndCallApi({ prev, next });
    };

    /**
     * set search parameters for given key, value pair
     * and toggle the selected dropdown incase non-empty value is passed
     */
    const handleOptionsChange = (key, value) => {
        dispatch(setSearchParams({
            ticketId,
            key,
            value,
        }));

        if (value) {
            toggleDD(key)();
        }
    };

    const toggleDD = (id) => () => {
        switch (id) {
            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.CATEGORY: {
                setCategoryDD(!showCategoryDD);

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.OFFER_TYPE: {
                setOfferTypeDD(!showOfferTypeDD);

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.OFFER_VENDOR: {
                setOfferVendorDD(!showOfferVendorDD);

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.OFFER_REDEMPTION_STATE: {
                setOfferRedemptionStateDD(!showOfferRedemptionStateDD);

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.EXPIRY_STATUS: {
                setExpiryStatusDD(!showExpiryStatusDD);

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.REWARD_TYPE: {
                setRewardTypeDD(!showRewardTypeDD);

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.VISIBILITY_TYPE: {
                setVisibilityTypeDD(!showVisibilityTypeDD);

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.EXCHANGER_OFFER_REDEMPTION_STATE: {
                setExchangerOfferRedemptionStateDD(!showExchangerOfferRedemptionStateDD);

                break;
            }

            default:
        }
    };

    const renderDD = (id) => {
        let label;
        let value;
        let options;
        let showDD;
        let mandatoryField = false;

        switch (id) {
            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.CATEGORY: {
                label = 'Category*';
                value = category;
                options = CLAIMS_CATEGORY;
                showDD = showCategoryDD;
                mandatoryField = true;

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.OFFER_TYPE: {
                label = 'Offer Type';
                value = offerType;
                options = OFFER_TYPE;
                showDD = showOfferTypeDD;

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.OFFER_VENDOR: {
                label = 'Offer Vendor';
                value = offerVendor;
                options = OFFER_VENDOR;
                showDD = showOfferVendorDD;

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.OFFER_REDEMPTION_STATE: {
                label = 'Offer Redemption State';
                value = offerRedemptionState;
                options = OFFER_REDEMPTION_STATE;
                showDD = showOfferRedemptionStateDD;

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.EXPIRY_STATUS: {
                label = 'Expiry Status';
                value = expiryStatus;
                options = EXPIRY_STATUS;
                showDD = showExpiryStatusDD;

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.REWARD_TYPE: {
                label = 'Reward Type';
                value = rewardType;
                options = REWARD_TYPE;
                showDD = showRewardTypeDD;

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.VISIBILITY_TYPE: {
                label = 'Visibility Type';
                value = visibilityType;
                options = VISIBILITY_TYPE;
                showDD = showVisibilityTypeDD;

                break;
            }

            case CLAIMS_VIEW_SEARCH_PARAMS_KEYS.EXCHANGER_OFFER_REDEMPTION_STATE: {
                label = 'Redemption State';
                value = exchangerOfferRedemptionState;
                options = EXCHANGER_OFFER_REDEMPTION_STATE;
                showDD = showExchangerOfferRedemptionStateDD;

                break;
            }

            default:
                label = '';
                options = [];
        }

        const { length: optionsLength } = options;

        if (optionsLength === 0) {
            return null;
        }

        const optionListDDHeight = getDropdownHeight(optionsLength);

        return (
            <div className='frcWrapper'>
                <div className='rewards-dd__lb'>{label}</div>
                <DropdownWrapper
                    visible={showDD}
                    onOutsideClickHandler={toggleDD(id)}
                    extClasses={{
                        container: 'custom-dd rewards-dd',
                    }}
                >
                    <div className='rewards-dd__vl' onClick={toggleDD(id)} role='presentation'>
                        {value ? value.split('_').join(' ').toLowerCase() : 'Choose...'}
                        { // cross icon to clear selected option
                            value && !mandatoryField ? (
                                <div
                                    className='rewards-dd__clear'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOptionsChange(id, '');
                                    }}
                                    role='presentation'
                                >
                                    x
                                </div>
                            ) : null
                        }
                    </div>
                    <DropdownContent
                        visible
                        extClasses={{
                            container: ['custom-dd__cc rewards-dd__cc'],
                        }}
                        extStyles={{
                            height: showDD ? optionListDDHeight : 0,
                            overflow: showDD ? 'auto' : 'hidden',
                        }}
                    >
                        {
                            options.map((item) => (
                                <div
                                    key={item.label}
                                    className='custom-dd__cl'
                                    onClick={() => handleOptionsChange(id, item.value)}
                                    role='presentation'
                                >
                                    {item.label}
                                </div>
                            ))
                        }
                    </DropdownContent>
                </DropdownWrapper>
            </div>
        );
    };

    return (
        <div className='rewards-cr1'>
            <ExpandableCard
                extClasses='rewards-ec'
                heading='View claims and claim attempts'
                isExpanded={isClaimCardExpanded}
                setIsExpanded={() => setIsClaimCardExpanded(!isClaimCardExpanded)}
            >
                <div className='rewards-sc'>
                    <div className='frwpWrapper rewards-sc__gap'>
                        {isClaimCardExpanded && renderDateBlock('fromDate')}
                        {isClaimCardExpanded && renderDateBlock('toDate')}
                        {renderDD(CLAIMS_VIEW_SEARCH_PARAMS_KEYS.CATEGORY)}
                        {
                            category === CATEGORY_MAP.offers ? (
                                <React.Fragment>
                                    {renderDD(CLAIMS_VIEW_SEARCH_PARAMS_KEYS.OFFER_TYPE)}
                                    {renderDD(CLAIMS_VIEW_SEARCH_PARAMS_KEYS.OFFER_VENDOR)}
                                    {renderDD(CLAIMS_VIEW_SEARCH_PARAMS_KEYS.OFFER_REDEMPTION_STATE)}
                                    {renderDD(CLAIMS_VIEW_SEARCH_PARAMS_KEYS.EXPIRY_STATUS)}
                                </React.Fragment>
                            ) : null
                        }
                        {
                            category === CATEGORY_MAP.rewards ? (
                                <React.Fragment>
                                    {renderDD(CLAIMS_VIEW_SEARCH_PARAMS_KEYS.REWARD_TYPE)}
                                    {renderDD(CLAIMS_VIEW_SEARCH_PARAMS_KEYS.VISIBILITY_TYPE)}
                                    <FormWithSingleInput
                                        hideErrLabel
                                        disableFormSubmitOnEnter
                                        extClasses={{
                                            container: 'rewards-fc',
                                            label: 'rewards-dd__lb',
                                            inputField: 'rewards-dd__ip',
                                        }}
                                        label='Offer Id'
                                        formType='text'
                                        input={{
                                            value: offerId,
                                            onChange: (e) => setOfferId(e.target.value),
                                        }}
                                    />
                                </React.Fragment>
                            ) : null
                        }
                        {
                            category === CATEGORY_MAP.exchangerOffers ? (
                                <React.Fragment>
                                    {renderDD(CLAIMS_VIEW_SEARCH_PARAMS_KEYS.EXCHANGER_OFFER_REDEMPTION_STATE)}
                                </React.Fragment>
                            ) : null
                        }
                        {
                            category === CATEGORY_MAP.fiStore ? (
                                <FormWithSingleInput
                                    hideErrLabel
                                    disableFormSubmitOnEnter
                                    extClasses={{
                                        container: 'rewards-fc',
                                        label: 'rewards-dd__lb',
                                        inputField: 'rewards-dd__ip',
                                    }}
                                    label='Order Id'
                                    formType='text'
                                    input={{
                                        value: orderId,
                                        onChange: (e) => setOrderId(e.target.value),
                                    }}
                                />
                            ) : null
                        }
                    </div>
                    {errLabel ? <div className='err-label rewards-el'>{errLabel}</div> : null}
                    <div className='frcWrapper rewards-sc__3r'>
                        <Button
                            extClasses='rewards-sc__cta'
                            primary
                            label='Search'
                            onClick={handleClaimsSubmit}
                        />
                        <div
                            className='link rewards-sc__clear'
                            onClick={() => {
                                dispatch(resetSearchParams({
                                    ticketId,
                                    fromDate: fromInitialDate,
                                    toDate: toInitialDate,
                                }));
                            }}
                            role='presentation'
                        >
                            Clear All Selection
                        </div>
                    </div>
                </div>
            </ExpandableCard>
            {
                category === CATEGORY_MAP.offers ? (
                    <ClaimedOffersView
                        data={redeemedOffersData}
                        prev={redeemedOffersDataPrev}
                        next={redeemedOffersDataNext}
                        getPaginatedData={getPaginatedClaims}
                    />
                ) : null
            }
            {
                category === CATEGORY_MAP.rewards ? (
                    <ClaimedRewardsView
                        data={rewardsData}
                        prev={rewardsDataPrev}
                        next={rewardsDataNext}
                        rewardInfoData={hasRewardInfoData && rewardInfoData}
                        getPaginatedData={getPaginatedClaims}
                        getRewardInfo={getRewardInfo}
                    />
                ) : null
            }
            {
                category === CATEGORY_MAP.exchangerOffers ? (
                    <ClaimedExchangerOffersView
                        data={redeemedExchangerOffersData}
                        prev={redeemedExchangerOffersDataPrev}
                        next={redeemedExchangerOffersDataNext}
                        getPaginatedData={getPaginatedClaims}
                    />
                ) : null
            }
            {
                category === CATEGORY_MAP.fiStore ? (
                    <ClaimedFiStoreRewardsView
                        ticketId={ticketId}
                        data={fiStoreRewardsListData}
                        additionalData={fiStoreRewardAdditionalDetailsData}
                        getPaginatedData={getPaginatedClaims}
                    />
                ) : null
            }
            <Loader
                visible={
                    loadingRewards || loadingRedeemedOffers || loadingRedeemedExchangerOffers
                    || loadingFiStoreRewardsList || loadingFiStoreRewardAdditionalDetails
                }
            />
        </div>
    );
};

export default ClaimsView;

/**
 *
 * AdminApprover
 *
 */

import React, { memo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, ExpandableCard, FormWithSingleInput, Loader
} from '../../../components';
import { getInitialDateRange } from '../../../utils';

import UserInfo from '../UserInfo';
import {
    getPendingStatusFreelancerList, setFreelancerInfo,
} from '../actions';
import {
    makeSelectLoading, makeSelectErr, makeSelectUserList, makeSelectUserInfo,
} from '../selectors';

import './style.scss';

const AdminApprover = () => {
    const dispatch = useDispatch();
    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const userList = useSelector(makeSelectUserList(), shallowEqual);
    const userInfo = useSelector(makeSelectUserInfo(), shallowEqual);
    const [errLabel, setErrLabel] = useState('');
    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(true);
    const [isSearchInitiated, setIsSearchInitiated] = useState(false);
    const {
        upperLimitDate, lowerLimitDate, fromDate: fromDefaultDate,
        toDate: toDefaultDate,
    } = getInitialDateRange('02-04-2021'); // 04 Feb
    const [fromDate, setFromDate] = useState(fromDefaultDate);
    const [toDate, setToDate] = useState(toDefaultDate);

    const hasUserInfo = !!Object.keys(userInfo).length;

    const getDateRange = ({ toDate, fromDate, prevState }) => {
        let modifiedToDate;
        let modifiedFromDate;
    
        const { fromDate: fromDatePrevState, toDate: toDatePrevState } = prevState;
    
        if (toDate) {
            const newToDate = new Date(toDate);
            newToDate.setHours(23, 59, 59, 999);

            modifiedToDate = {
                ...toDatePrevState,
                value: newToDate
            };
    
            const upperLimitFromDate = new Date(toDate);
            upperLimitFromDate.setHours(0, 0, 0, 0);

            modifiedFromDate = {
                ...fromDatePrevState,
                upperLimit: upperLimitFromDate
            };
        } else if (fromDate) {
            modifiedFromDate = {
                ...fromDatePrevState,
                value: fromDate
            };

            const lowerLimitToDate = new Date(fromDate)
            lowerLimitToDate.setHours(23, 59, 59, 999);

            modifiedToDate = {
                ...toDatePrevState,
                lowerLimit: lowerLimitToDate
            };
        }

        return {
            fromDate: modifiedFromDate,
            toDate: modifiedToDate,
        };
    };

    const handleDateChange = key => value => {
        const data = {
            prevState: { upperLimitDate, lowerLimitDate, fromDate: fromDate, toDate: toDate },
        };

        switch (key) {
            case 'fromDate':
                data.fromDate = value;
                break;

            case 'toDate':
                data.toDate = value;
                break;

            default:
        }

        const { fromDate: newFromDate, toDate: newToDate } = getDateRange(data);
        setFromDate(newFromDate);
        setToDate(newToDate);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!fromDate.value || !toDate.value) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        setErrLabel('');
        setIsSearchInitiated(true);
        dispatch(getPendingStatusFreelancerList({ from: fromDate.value, to: toDate.value }));
        setIsSearchCardExpanded(false);
    };

    const renderDateBlock = (key) => {
        let label;
        let labelId;
        let value;
        let maxDate;
        let minDate;

        switch (key) {
            case 'fromDate':
                label = 'From Date*';
                labelId = 'from-date';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = fromDate);
                break;

            case 'toDate':
                label = 'To Date*';
                labelId = 'to-date';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = toDate);
                break;

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'wladmin-fc',
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: handleDateChange(key),
                    maxDate,
                    minDate,
                }}
            />
        );
    };

    const renderSearchResults = () => {
        if (!isSearchInitiated) return null;

        if (err && err.userList) {
            return <div className='container-err-label'>{err.userList}</div>;
        }

        if (userList.length === 0) {
            return <div className='table-nsr'>No submitted profiles found!</div>
        }

        return (
            <React.Fragment>
                <div className='table-sr'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th'>Profession</div>
                        <div className='table-sr__th'>Work Email</div>
                        <div className='table-sr__th'>Comm Email</div>
                        <div className='table-sr__th'>Current Waitlist Status</div>
                        <div className='table-sr__th'>Action</div>
                    </div>
                    <div className='table-sr__tlc'>
                        {
                            userList.map((item, index) => (
                                <div className='table-sr__tlw' key={index}>
                                    <div className='table-sr__tl  table-sr__tl'>{item.profession}</div>
                                    <div className='table-sr__tl table-sr__tl--lc'>{item.workEmail}</div>
                                    <div className='table-sr__tl table-sr__tl--lc'>{item.commEmail}</div>
                                    <div className='table-sr__tl'>{item.currentStatus}</div>
                                    <div
                                        className='link table-sr__tl'
                                        onClick={() => {
                                            dispatch(setFreelancerInfo({ freelancerInfo: item }));
                                        }}
                                    >
                                        View Details
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {
                !hasUserInfo ? (
                    <div className='wladmin-cr'>
                        <ExpandableCard
                            extClasses='wladmin-ec'
                            heading='Search for Submitted Profiles'
                            isExpanded={isSearchCardExpanded}
                            setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                        >
                            <div className='wladmin-sc'>
                                <div className='frWrapper'>
                                    {renderDateBlock('fromDate')}
                                    {renderDateBlock('toDate')}
                                </div>
                                {errLabel ? <div className='err-label'>{errLabel}</div> : null}
                                <div className='frWrapper wladmin-sc__m20'>
                                    <Button
                                        extClasses='wladmin-sc__cta'
                                        primary
                                        label='Search'
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </div>
                        </ExpandableCard>
                        <div className='wladmin-src'>
                            {renderSearchResults()}
                        </div>
                    </div>
                ) : (
                    <UserInfo
                        from={fromDate.value}
                        to={toDate.value}
                    />
                )
            }
            <Loader visible={loading && loading.userList} />
        </React.Fragment>
    );

};

export default memo(AdminApprover);

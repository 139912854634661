import { getParsedStringFromSnakeCase } from '../../utils/formatter';

import { TICKET_ID_URL_PATTERN } from './constants';

export function getTicketIdFromUrl(url) {
    if (TICKET_ID_URL_PATTERN.test(url)) {
        const match = url.match(TICKET_ID_URL_PATTERN);
        const ticketId = match[1];
        return ticketId; // Returns true if ticketId is present
    }
    return null; // Returns false if the URL doesn't match the patterns
}

/**
 * Function to mask the email address of the customer
 * @param {string} emailAddress Email address of the customer which needs to be masked
 * @returns {string} Returns the masked email address of the customer
 */

export const getMaskedEmailAddress = (emailAddress) => {
    if (!emailAddress) return '-';

    const mask = (str) => {
        const strLen = str.length;

        if (strLen > 2) {
            return str.slice(0, 1) + str.slice(1, -1).replace(/\w/g, '*') + str.slice(-1);
        }

        return str.replace(/([\w.])/g, '*');
    };

    return emailAddress.replace(
        // eslint-disable-next-line no-useless-escape
        /(^[-\w\.]+)@([\w-]+\.)+([\w-]{2,4}$)/g,
        (m, p1, p2, p3) => `${mask(p1)}@${mask(p2)}${p3}`,
    );
};

/**
 * Function to mask a string value with a fixed number of characters to be masked starting at the last
 * @param {string} value String value which needs to be masked
 * @param {number} noOfCharactersToBeMasked No of characters to be masked starting from the last character
 * @returns {string} Returns the masked string value for the given value
 */
export const maskTrailingCharacters = (value, noOfCharactersToBeMasked = 4) => (
    (value && typeof value === 'string' && `${value.slice(0, value.length - noOfCharactersToBeMasked)}${'*'.repeat(noOfCharactersToBeMasked)}`) || '-'
);

/**
 * Function to mask a string value with a fixed number of characters to be masked starting at the beginning
 * @param {string} value String value which needs to be masked
 * @param {number} noOfCharactersToBeMasked No of characters to be masked starting from the first character
 * @returns {string} Returns the masked string value for the given value
 */
export const maskLeadingCharacters = (value, noOfCharactersToBeMasked = 4) => (
    (value && typeof value === 'string' && `${'*'.repeat(noOfCharactersToBeMasked)}${value.slice(noOfCharactersToBeMasked + 1)}`) || '-'
);

/**
 * Function to mask a string value at alternate indexes
 * @param {string} value String value which needs to be masked
 * @returns {string} Returns the masked string value for the given value
 */
export const maskAlternateCharacters = (value) => (
    (value && typeof value === 'string' && `${value.split('').map((letter, index) => ((index % 2 === 0 && '*') || letter)).join('')}`) || '-'
);

/**
 * Function to fully mask a string value
 * @param {string} value String value which needs to be masked
 * @returns {string} Returns the masked string value for the given value
 */
export const maskFullValue = (value) => (
    (value && typeof value === 'string' && `${'*'.repeat(value.length)}`) || '-'
);

/**
 * Function to create a list of label value pair from a list of enums
 * @param {array} enums List of enums
 * @returns {array} Returns a list of label value pair from a list of enums
 */
export const createSelectOptionsFromEnums = (enums) => enums && Array.isArray(enums) && enums.map((option) => (
    {
        label: getParsedStringFromSnakeCase(option, 0, 'capitalize'),
        value: option,
    }
));

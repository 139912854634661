/**
 * @file Manages the utils functions for Instant Indexing component
 */

const getUrlStatusInfoList = (data) => ([
    {
        label: 'URL',
        value: data.url,
    },
    {
        label: 'Type',
        value: data.type,
    },
    {
        label: 'Requested At',
        value: data.lastRequestedAt,
    },
]);

export {
    getUrlStatusInfoList,
}

/**
 *
 * @file RenderView: Renders the page according to activity view.
 *
 */
import React, { memo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { VIEW_ACTIVITIES_DETAILS, VIEW_ACTIVITIES_DETAILS_LIST } from '../constants';

import USSActivityList from '../USSActivityList';
import USSDateView from '../USSDateView';
import USSOrderView from '../USSOrderView';
import { selectUSSActivityType, selectUSSActivityTypeList } from '../selectors';
import { setActivityTypeAction } from '../actions';

const RenderView = (props) => {
    const {
        usStocksView, ticketId, userId, index, searchAction,
    } = props;

    const activityType = useSelector(selectUSSActivityType, shallowEqual);
    const activityTypeList = useSelector(selectUSSActivityTypeList, shallowEqual);

    const dispatch = useDispatch();

    const handleActionChange = (value) => {
        dispatch(setActivityTypeAction(value));
    };

    switch (usStocksView) {
        case VIEW_ACTIVITIES_DETAILS: {
            return (
                <React.Fragment>
                    <USSOrderView
                        ticketId={ticketId}
                        userId={userId}
                        index={index}
                        searchAction={searchAction}
                    />
                    {/* to keep some empty space b/w two search cards */}
                    <div className='uss-src' />
                    <USSDateView
                        showDropdown
                        ticketId={ticketId}
                        userId={userId}
                        index={index}
                        searchAction={searchAction}
                        dropdownValue={activityType}
                        handleDropdownChange={handleActionChange}
                        dropdownOptions={activityTypeList}
                        dropdownLabel='Activity Type'
                    />
                </React.Fragment>
            );
        }

        case VIEW_ACTIVITIES_DETAILS_LIST: {
            return (
                <USSActivityList ticketId={ticketId} />
            );
        }

        default:
            return null;
    }
};

export default memo(RenderView);

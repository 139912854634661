/**
 *
 * @file USSActivityList : Show us stocks activities according to search parameters.
 *
 */

import React, { memo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';
import { Button, Loader, Table } from '../../../components';
import { isNilOrEmpty } from '../../../utils';

import {
    getActivitiesAction, getActivityDetailsAction, resetActivitiesAction, resetSearchParamsAction, setUsStocksViewAction,
} from '../actions';
import { USSSTOCKS_ACTIVITIES, VIEW_ACTIVITIES_DETAILS, VIEW_PORTFOLIO_DETAILS } from '../constants';
import { selectUSSActivities, selectUSSActivityDetail, selectUSSSearchParams, selectUSSLoading } from '../selectors';

import USSActivityInfo from './USSActivityInfo';

const USSActivityList = (props) => {
    const { ticketId, showActivityDetails } = props;

    const dispatch = useDispatch();

    const activities = useSelector(selectUSSActivities, shallowEqual);
    const loadingActivities = useSelector(selectUSSLoading, shallowEqual);
    const searchParams = useSelector(selectUSSSearchParams, shallowEqual);
    const activityDetailInfo = useSelector(selectUSSActivityDetail, shallowEqual);

    const [showActivityInfoModal, setActivityInfoModal] = useState(false);

    const { data, prev: prevToken, next: nextToken } = activities[ticketId];

    const onBackArrowCTA = () => {
        const prevView = showActivityDetails ? VIEW_ACTIVITIES_DETAILS : VIEW_PORTFOLIO_DETAILS;
        dispatch(setUsStocksViewAction(prevView));
        dispatch(resetSearchParamsAction());
        dispatch(resetActivitiesAction({ ticketId }));
    };

    const getActivitiesPaginatedData = (params) => {
        const { prev, next } = params;
        let paramsPayload = {};

        if (searchParams.orderId) {
            paramsPayload = {
                orderId: btoa(searchParams.orderId),
            };
        } else if (searchParams.assetName) {
            paramsPayload = {
                assetName: searchParams.assetName,
            };
        } else if (searchParams.fromDate && searchParams.toDate && searchParams.activityType) {
            paramsPayload = {
                from: searchParams.fromDate.value,
                to: searchParams.toDate.value,
                activity_type: searchParams.activityType,
            };
        } else if (searchParams.fromDate && searchParams.toDate) {
            paramsPayload = {
                from: searchParams.fromDate.value,
                to: searchParams.toDate.value,
            };
        }

        const payload = {
            ticketId,
            prev,
            next,
            ...paramsPayload,
        };

        dispatch(getActivitiesAction(payload));
    };

    const handleSubmit = (orderId) => {
        const payload = { ticketId, orderId };
        dispatch(getActivityDetailsAction(payload));
        setActivityInfoModal(true);
    };

    const renderActions = (item) => (
        <div className='frccWrapper'>
            <Button
                link
                label='View Details'
                onClick={() => { handleSubmit(item.activityId); }}
            />
        </div>
    );

    return (
        <React.Fragment>
            <div>
                <div>
                    <div className='uss-userinfo-hwr'>
                        <button
                            className='uss-backarrow--cr'
                            type='button'
                            onClick={onBackArrowCTA}
                        >
                            <img
                                className='uss-backarrow'
                                alt='back-arrow'
                                src={backArrowIcon}
                            />
                        </button>
                        <div className='uss-userinfo-hwr__hd'>Activities History</div>
                    </div>
                </div>

                {!loadingActivities && data && (
                    <div className='uss-activities-list'>
                        <Loader visible={activities[ticketId].loading} />
                        <Table
                            v2
                            labelData={USSSTOCKS_ACTIVITIES}
                            contentData={data}
                            prevToken={prevToken}
                            nextToken={nextToken}
                            paginatedClickHandler={getActivitiesPaginatedData}
                            renderActionableColumn={renderActions}
                        />
                    </div>
                )}

            </div>

            {!isNilOrEmpty(activityDetailInfo) && activityDetailInfo[ticketId] && activityDetailInfo[ticketId].hasData && (
                <USSActivityInfo
                    visible={showActivityInfoModal}
                    toggleHandler={() => {
                        setActivityInfoModal(false);
                    }}
                    data={activityDetailInfo[ticketId].data}
                />
            )}

            <Loader visible={loadingActivities} />
        </React.Fragment>
    );
};

export default memo(USSActivityList);

/**
 * @file Manages the utility functions for Profile component
 */

const renderProfileInfo = (profileInfo) => [
    {
        label: 'Name',
        data: profileInfo.name,
    },
    {
        label: 'Registered Mobile Number',
        data: profileInfo.accountInfo.phoneNumber,
    },
    {
        label: 'Registered Email ID',
        data: profileInfo.accountInfo.email,
        hideTextTransform: true,
    },
    {
        label: 'Account Status',
        data: profileInfo.accountInfo.fiAccountStatus,
    },
];

const renderAccountInfo = (profileInfo) => [
    {
        label: 'Saving Account Status',
        data: profileInfo.accountInfo.state,
    },
    {
        label: 'Account Number',
        data: profileInfo.accountInfo.lastXDigits,
    },
    {
        label: 'Customer ID',
        data: profileInfo.customerId,
    },
    {
        label: 'Account IFSC Code',
        data: profileInfo.accountInfo.ifscCode,
    },
    {
        label: 'Partner Bank',
        data: profileInfo.accountInfo.partnerBank,
    },
    {
        label: 'Account Type',
        data: profileInfo.accountInfo.type,
    },
    {
        label: 'Balance',
        data: profileInfo.closedAccountBalance,
        hideIfNoValue: true,
    },
    {
        label: 'Account Created Date',
        data: profileInfo.accountInfo.createdDateTs,
    },
    {
        label: 'Account Transaction Limit',
        data: profileInfo.accountInfo.txnLimit,
    },
    {
        label: 'Account Withdrawal Limit',
        data: profileInfo.accountInfo.withdrawalLimit,
    },
    {
        label: 'Sign Status',
        data: profileInfo.accountInfo.signStatus,
    },
];

const renderAddressInfo = (accountInfo) => {
    const addressMap = accountInfo && accountInfo.addressMap;

    if (!addressMap) {
        return [];
    }

    const addressKeys = Object.keys(addressMap);
    const addressList = [];

    addressKeys.forEach((item) => {
        addressList.push({
            label: `${item.toLowerCase()} Address`,
            address: addressMap[item].address_lines.join(' '),
            pincode: addressMap[item].postal_code,
        });
    });

    return addressList;
};

const renderDeviceInfo = (deviceInfo) => [
    {
        label: 'Fi App Version',
        data: deviceInfo.appVersion,
    },
    {
        label: 'Dual SIM',
        data: deviceInfo.isDualSim,
    },
    {
        label: 'Device Manufacturer',
        data: deviceInfo.manufacturer,
    },
    {
        label: 'Device Model',
        data: deviceInfo.model,
    },
    {
        label: 'Device SW version',
        data: deviceInfo.swVersion,
    },
    {
        label: 'Device OS Version',
        data: deviceInfo.osVersion,
    },
    {
        label: 'Discoverability',
        data: deviceInfo.isDiscoverable,
    },
    {
        label: 'Find My Device Enable',
        data: deviceInfo.isFindMyDeviceEnable,
    },
];

const renderAccountClosureInfo = (data) => [
    {
        label: 'Status',
        value: data.status,
    },
    {
        label: 'Reason',
        value: data.reason,
    },
    {
        label: 'Remarks',
        value: data.remarks,
    },
    {
        label: 'Last Updated At',
        value: data.lastUpdatedAt,
    },
    {
        label: 'Updated By',
        value: data.updatedBy,
    },
];

const renderAlternateAccountInfo = (data) => [
    {
        label: 'Has Verified Accounts',
        value: data.hasVerifiedAccounts,
    },
    {
        label: 'Name Match Retries Left',
        value: data.nameMatchRetriesLeft,
    },
    {
        label: 'Account Verification Retries Left',
        value: data.accountVerificationRetriesLeft,
    },
];

export {
    renderProfileInfo,
    renderAddressInfo,
    renderDeviceInfo,
    renderAccountClosureInfo,
    renderAlternateAccountInfo,
    renderAccountInfo,
};

/**
 *
 * Onboarding Meta Info Modal - Component to show the Wealth Onboarding Stage Meta Info
 *
 */

import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button } from '../../../components';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '10%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '30px 25px',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 600,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const OnboardingMetaInfo = (props) => {
    const { visible, data, toggleHandler } = props;

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='Wealth Onboarding Meta Info Modal'
        >
            <div className='wonb-modal-hwr'>
                Wealth Onboarding Meta Info
            </div>
            <div className='wonb-modal-ccr'>
                {
                    Array.isArray(data) ? (
                        data.map(item => (
                            <div className='frWrapper wonb-modal-amiwr'>
                                <div>{item.label}</div>
                                <div className='wonb-modal-amiwr__sp'>:</div>
                                <div>{item.value || 'NA'}</div>
                            </div>
                        ))
                    ) : (
                        JSON.stringify(data, null, 4)
                    )
                }
            </div>
            <Button
                primary
                extClasses='wonb-modal-bwr'
                label='Close'
                onClick={() => toggleHandler(false)}
            />
        </Modal>
    );
};

export default memo(OnboardingMetaInfo);

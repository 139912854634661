/**
 * @file: RadioButtonListV1
 * Renders a list of text as radio buttons and stores the state of the selected option.
 * It allows default or pre-select value.
 */

import classNames from 'classnames';
import React, { memo } from 'react';

import { isNilOrEmpty } from '../../utils';

/**
 * props:
 * @param {object[]} dataList: Array of objects, each having label, value & id in one item. Ex - [{ label: 'label1', value: 'value1', id: '1' }].
 * @param {string} listName: The name of the group of radio buttons. Should be unique for each radio button group.
 * @param {string} selectedValue: current value of the radio button group.
 * @param {function} setSelectedData: The setState function, to set the selected radio button.
 * @param {object} extClasses: Object for external styling classes.
 */
const RadioButtonListV1 = (props) => {
    const {
        dataList, listName, selectedValue, setSelectedData, extClasses = {},
    } = props;

    const containerClasses = classNames('fcWrapper', extClasses && extClasses.container);
    const itemContainerClasses = classNames('frcWrapper', extClasses && extClasses.itemContainer);
    const labelClasses = classNames('ml-15', extClasses && extClasses.label);

    const handleValueChange = (e) => {
        const { value } = e.target;
        setSelectedData(value);
    };

    if (isNilOrEmpty(dataList)) return null;

    return (
        <div
            className={containerClasses}
            onChange={handleValueChange}
        >
            {dataList.map((radioItem) => (
                <div className={itemContainerClasses} key={radioItem.id}>
                    <input
                        type='radio'
                        id={radioItem.id}
                        value={radioItem.value}
                        name={listName}
                        checked={selectedValue === radioItem.value}
                    />
                    <label
                        htmlFor={radioItem.id}
                        className={labelClasses}
                    >
                        {radioItem.label ?? radioItem.value}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default memo(RadioButtonListV1);

/*
 *
 * Wealth Investment Actions
 *
 */

import {
    GET_ONBOARDING_INFO, GET_INV_READINESS_STATUS,
} from './constants';

export const getOnboardingInfo = data => ({
    type: GET_ONBOARDING_INFO,
    data,
});

export const getInvestmentReadinessStatus = data => ({
    type: GET_INV_READINESS_STATUS,
    data,
});

import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
    ERR_EXISTING_MANDATE_DETAILS,
    ERR_UPCOMING_MANDATE_DETAILS,
    GET_EXISTING_MANDATE_DETAILS,
    GET_UPCOMING_MANDATE_DETAILS,
    SET_EXISTING_MANDATE_DETAILS,
    SET_UPCOMING_MANDATE_DETAILS,
} from './constants';
import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { AUTOPAY_EXISTING_MANDATES_ROOT, AUTOPAY_UPCOMING_MANDATES_ROOT } from '../../api/routes';
import { setAuthScreen } from '../Tickets/saga';

function* getExistingMandateInfo(action) {
    const { ticketId, userId, index } = action.data;
    const queryParams = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${AUTOPAY_EXISTING_MANDATES_ROOT}?${queryParams}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_EXISTING_MANDATE_DETAILS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_EXISTING_MANDATE_DETAILS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_EXISTING_MANDATE_DETAILS, data: { ticketId, err } });
    }
}

function* getUpcomingTransactionsInfo(action) {
    const { ticketId, userId, index } = action.data;
    const queryParams = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${AUTOPAY_UPCOMING_MANDATES_ROOT}?${queryParams}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_UPCOMING_MANDATE_DETAILS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_UPCOMING_MANDATE_DETAILS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_UPCOMING_MANDATE_DETAILS, data: { ticketId, err } });
    }
}

export default function* autoPaySaga() {
    yield all(
        [
            yield takeLatest(GET_EXISTING_MANDATE_DETAILS, getExistingMandateInfo),
            yield takeLatest(GET_UPCOMING_MANDATE_DETAILS, getUpcomingTransactionsInfo),
        ],
    );
}

import { createSelector } from 'reselect';

const selectRewards = (state) => state.get('rewards');

const makeSelectActiveRewards = () => createSelector(
    selectRewards,
    (substate) => substate.get('activeRewardsList').toJS(),
);

const makeSelectActiveOffers = () => createSelector(
    selectRewards,
    (substate) => substate.get('activeOffersList').toJS(),
);

const makeSelectRewards = () => createSelector(
    selectRewards,
    (substate) => substate.get('rewardsList').toJS(),
);

const makeSelectRedeemedOffers = () => createSelector(
    selectRewards,
    (substate) => substate.get('redeemedOffersList').toJS(),
);

const makeSelectRewardInfo = () => createSelector(
    selectRewards,
    (substate) => substate.get('rewardInfo').toJS(),
);

const makeSelectFiStoreRewardsList = () => createSelector(
    selectRewards,
    (substate) => substate.get('fiStoreRewardsList').toJS(),
);

const makeSelectFiStoreRewardAdditionalDetails = () => createSelector(
    selectRewards,
    (substate) => substate.get('fiStoreRewardAdditionalDetails').toJS(),
);

const makeSelectSearchParametersForActiveView = () => createSelector(
    (state) => state.getIn(['rewards', 'searchParametersForActiveView']),
    (substate) => substate.toJS(),
);

const makeSelectSearchParametersForClaimsView = () => createSelector(
    (state) => state.getIn(['rewards', 'searchParametersForClaimsView']),
    (substate) => substate.toJS(),
);

const makeSelectActiveExchangerOffers = () => createSelector(
    (state) => state.getIn(['rewards', 'activeExchangerOffersList']),
    (substate) => substate.toJS(),
);

const makeSelectRedeemedExchangerOffers = () => createSelector(
    (state) => state.getIn(['rewards', 'redeemedExchangerOffersList']),
    (substate) => substate.toJS(),
);

const makeSelectActiveViewEntityMeta = () => createSelector(
    (state) => state.getIn(['rewards', 'activeViewEntityMeta']),
    (substate) => substate.toJS(),
);

const makeSelectClaimsViewEntityMeta = () => createSelector(
    (state) => state.getIn(['rewards', 'claimsViewEntityMeta']),
    (substate) => substate.toJS(),
);

const makeSelectTotalRewardsList = () => createSelector(
    selectRewards,
    (substate) => substate.get('totalRewardsList')?.toJS(),
);

const makeSelectEligibleRewards = () => createSelector(
    selectRewards,
    (substate) => substate.get('eligibleRewards')?.toJS(),
);

export {
    makeSelectActiveRewards,
    makeSelectActiveOffers,
    makeSelectRewards,
    makeSelectRedeemedOffers,
    makeSelectRewardInfo,
    makeSelectSearchParametersForActiveView,
    makeSelectSearchParametersForClaimsView,
    makeSelectActiveExchangerOffers,
    makeSelectRedeemedExchangerOffers,
    makeSelectActiveViewEntityMeta,
    makeSelectClaimsViewEntityMeta,
    makeSelectTotalRewardsList,
    makeSelectEligibleRewards,
    makeSelectFiStoreRewardsList,
    makeSelectFiStoreRewardAdditionalDetails,
};

/**
 * Upcoming Transactions
 */

import React, { memo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeSelectExistingMandates } from '../selectors';
import { getExistingMandateDetails } from '../actions';
import { Loader } from '../../../components';

const ExistingMandates = (props) => {
    const { ticketId, userId, index } = props;
    const dispatch = useDispatch();
    const existingMandateDetails = useSelector(makeSelectExistingMandates, shallowEqual);

    useEffect(() => {
        dispatch(getExistingMandateDetails({ ticketId, userId, index }));
    }, []);

    let loadingExistingMandates;
    let errInLoadingExistingMandates;
    let existingMandatesTableData;
    let mandatesDataExists;

    if (existingMandateDetails[ticketId]) {
        ({
            loading: loadingExistingMandates, data: existingMandatesTableData, err: errInLoadingExistingMandates,
        } = existingMandateDetails[ticketId]);
        mandatesDataExists = existingMandateDetails[ticketId] && existingMandateDetails[ticketId].hasData;
    }

    return (
        <React.Fragment>
            {
                !errInLoadingExistingMandates && (mandatesDataExists ? (
                    <div className='txns-container txns-container--ss' style={{ maxWidth: '1000px' }}>
                        <div className='table-sr table-sr--mt0'>
                            <div className='table-sr__label'>{existingMandatesTableData.table_name}</div>
                            <div className='table-sr__thw'>
                                {
                                    existingMandatesTableData.table_headers.map((tableHeader) => (
                                        <div className='table-sr__th mandates-table'>{tableHeader.label}</div>
                                    ))
                                }
                            </div>
                            <div className='table-sr__tlc'>
                                {
                                    existingMandatesTableData.table_rows.map((rowEntry) => (
                                        <div className='table-sr__tlw' key={rowEntry.header_key_to_cell_value_map.id}>
                                            {
                                                existingMandatesTableData.table_headers.map((tableHeader) => (
                                                    <div className='table-sr__tl mandates-table'>
                                                        {rowEntry.header_key_cell_map[tableHeader.header_key].value}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='txns-container txns-container--ss' style={{ maxWidth: '1000px' }}>
                        No Existing Mandates found
                    </div>
                ))
            }
            {
                errInLoadingExistingMandates && (
                    <div className='txns-container txns-container--ss' style={{ maxWidth: '1000px' }}>
                        Error while loading existing Mandates
                    </div>
                )
            }
            <Loader visible={loadingExistingMandates} />
        </React.Fragment>
    );
};

export default memo(ExistingMandates);

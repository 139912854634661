import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { REFERRALS_ROOT } from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_USER_REFERRAL_INFO, SET_USER_REFERRAL_INFO, ERR_USER_REFERRAL_INFO, GET_USER_REFERRER_INFO,
    SET_USER_REFERRER_INFO, ERR_USER_REFERRER_INFO, GET_USER_REFEREES, SET_USER_REFEREES, ERR_USER_REFEREES,
    GET_USER_REFEREE_INFO, SET_USER_REFEREE_INFO, ERR_USER_REFEREE_INFO, GET_REFERRAL_META_INFO,
    SET_REFERRAL_META_INFO,
} from './constants';

function* getUserReferralInfo(action) {
    const { ticketId, userId, index } = action.data;
    const queryString = getQueryStringFromObject({ userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REFERRALS_ROOT}/${ticketId}/user-referral-info?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_USER_REFERRAL_INFO, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_USER_REFERRAL_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_REFERRAL_INFO, data: { ticketId, err: e.errMsgForUI } });
    }
}

function* getUserReferrerInfo(action) {
    const { ticketId, userId, index } = action.data;
    const queryString = getQueryStringFromObject({ userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REFERRALS_ROOT}/${ticketId}/user-referrer-info?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_USER_REFERRER_INFO, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_USER_REFERRER_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_REFERRER_INFO, data: { ticketId, err: e.errMsgForUI } });
    }
}

function* getUserReferees(action) {
    const { ticketId, userId, index, prev, next } = action.data;
    const queryString = getQueryStringFromObject({ userId, prev, next });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REFERRALS_ROOT}/${ticketId}/user-referees?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_USER_REFEREES, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_USER_REFEREES, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_REFEREES, data: { ticketId, err: e.errMsgForUI } });
    }
}

function* getUserRefereeInfo(action) {
    const { ticketId, userId, index, actorId } = action.data;
    let queryString = getQueryStringFromObject({ userId });
    queryString = `${queryString}&actorId=${btoa(actorId)}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REFERRALS_ROOT}/${ticketId}/user-referee-info?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_USER_REFEREE_INFO, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_USER_REFEREE_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_REFEREE_INFO, data: { ticketId, err: e.message } });
    }
}

function* getReferralMetaInfo() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${REFERRALS_ROOT}/meta-info`,
        );

        yield put({ type: SET_REFERRAL_META_INFO, data: response });
    } catch (e) {
        console.log(e);
        yield put({ type: SET_REFERRAL_META_INFO, data: {} });
    }
}

export default function* referralsSaga() {
    yield all(
        [
            yield takeLatest(GET_USER_REFERRAL_INFO, getUserReferralInfo),
            yield takeLatest(GET_USER_REFERRER_INFO, getUserReferrerInfo),
            yield takeLatest(GET_USER_REFEREES, getUserReferees),
            yield takeLatest(GET_USER_REFEREE_INFO, getUserRefereeInfo),
            yield takeLatest(GET_REFERRAL_META_INFO, getReferralMetaInfo),
        ]
    );
}

/**
 *
 * ReferralView
 *
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { ExpandableCard, Loader, Pagination } from '../../../components';

import RefereeInfoModal from '../Modals/RefereeInfo';

import { getUserReferrerInfo, getUserReferees, getUserRefereeInfo } from '../actions';
import { makeSelectUserReferrerInfo, makeSelectUserRefereesList, makeSelectUserRefereeInfo } from '../selectors';
import { getFormattedReferrerInfo } from '../utils';

import '../style.scss';

const ReferralView = (props) => {
    const dispatch = useDispatch();

    const userReferrerInfo = useSelector(makeSelectUserReferrerInfo(), shallowEqual);
    const userRefereesList = useSelector(makeSelectUserRefereesList(), shallowEqual);
    const userRefereeInfo = useSelector(makeSelectUserRefereeInfo(), shallowEqual);

    const [isCardExpanded, setIsCardExpanded] = useState(false);
    const [isRefereeInfoModalOpen, setRefereeInfoModal] = useState(false);

    const { ticketId, userId, index } = props;

    useEffect(() => {
        dispatch(getUserReferrerInfo({ ticketId, userId, index }));

        dispatch(getUserReferees({ ticketId, userId, index }));
    }, [dispatch]);

    let loadingReferrerInfo;
    let errorInLoadingReferrerInfo;
    let hasReferrerInfo;
    let referrerInfo;
    let hasRewardEarnedInfo;
    let rewardEarnedInfo;

    if (userReferrerInfo[ticketId]) {
        ({ loading: loadingReferrerInfo, err: errorInLoadingReferrerInfo, hasReferrerInfo, referrerInfo,
            hasRewardEarnedInfo, rewardEarnedInfo, } = userReferrerInfo[ticketId]);
    }

    let loadingRefereesList;
    let errorInLoadingRefereesList;
    let refereeList;
    let refereeListPrev;
    let refereeListNext;

    if (userRefereesList[ticketId]) {
        ({ loading: loadingRefereesList, err: errorInLoadingRefereesList, data: refereeList, prev: refereeListPrev,
            next: refereeListNext, } = userRefereesList[ticketId]);
    }

    let loadingRefereeInfo;
    let refereeInfo;

    if (userRefereeInfo[ticketId]) {
        ({ loading: loadingRefereeInfo, data: refereeInfo } = userRefereeInfo[ticketId]);
    }

    const getPaginatedRefereeList = ({ prev, next }) => {
        dispatch(getUserReferees({ ticketId, userId, index, prev, next }));
    };

    const getRefereeInfo = (actorId) => {
        setRefereeInfoModal(true);
        dispatch(getUserRefereeInfo({ ticketId, userId, index, actorId }));
    };

    const renderRefereesList = () => {
        if (loadingRefereesList || !refereeList) {
            return null;
        }

        if (errorInLoadingRefereesList) {
            return (
                <div className='referrals-src'>
                    <div className='referrals-ccr-hd1'>{errorInLoadingRefereesList}</div>
                </div>
            );
        }

        if (refereeList.length === 0) {
            return (
                <div className='referrals-src'>
                    <div className='referrals-ccr-hd1'>No user has signed up using the user's referral code yet!</div>
                </div>
            );
        }

        const hasSelectedRefereeInfo = refereeInfo && Object.keys(refereeInfo).length && isRefereeInfoModalOpen;

        return (
            <React.Fragment>
                <div className='referrals-src'>
                    <div className='table-sr table-sr--mt0'>
                        <div className='table-sr__label'>Referral Program Details - Regular Reward</div>
                        <div className='table-sr__thw'>
                            <div className='table-sr__th'>User Referred</div>
                            {/* <div className='table-sr__th'>Finite Code Used</div> */}
                            <div className='table-sr__th'>Action</div>
                        </div>
                        <div className='table-sr__tlc'>
                            {
                                refereeList.map(item => (
                                    <div className='table-sr__tlw' key={item.id}>
                                        <div className='table-sr__tl'>{item.name}</div>
                                        {/* <div className='table-sr__tl'>{item.finiteCodeUsed}</div> */}
                                        <div
                                            className='table-sr__tl link'
                                            onClick={() => {
                                                getRefereeInfo(item.id);
                                            }}
                                        >
                                            View Details
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <Pagination prev={refereeListPrev} next={refereeListNext} onClickHandler={getPaginatedRefereeList} />
                </div>
                <RefereeInfoModal
                    visible={hasSelectedRefereeInfo}
                    data={refereeInfo}
                    toggleHandler={() => {
                        setRefereeInfoModal(false);
                    }}
                />
            </React.Fragment>
        );
    };

    const loading = loadingReferrerInfo
        || loadingRefereesList
        || loadingRefereeInfo;

    return (
        <ExpandableCard
            heading='My Referrals'
            extClasses='referrals-ec'
            isExpanded={isCardExpanded}
            setIsExpanded={() => setIsCardExpanded(!isCardExpanded)}
        >
            <div className='referrals-ccr'>
                {
                    errorInLoadingReferrerInfo ? (
                        <div className='referrals-ccr-hd1'>{errorInLoadingReferrerInfo}</div>
                    ) : null
                }
                {
                    (!errorInLoadingReferrerInfo && !hasReferrerInfo && !hasRewardEarnedInfo) ? (
                        <React.Fragment>
                            <div className='referrals-ccr-hd2'>Referral Stats</div>
                            <div className='referrals-cwr'>
                                <div className='referrals-cwr__label'>Eligible for Referrals</div>
                                <div className='referrals-cwr__value'>No</div>
                            </div>
                        </React.Fragment>
                    ) : null
                }
                {
                    (hasReferrerInfo || hasRewardEarnedInfo) ? (
                        <React.Fragment>
                            <div className='referrals-ccr-hd2'>Referral Stats</div>
                            {
                                getFormattedReferrerInfo({
                                    ...referrerInfo,
                                    ...rewardEarnedInfo,
                                }).map(item => (
                                    <div key={item.label} className='referrals-cwr'>
                                        <div className='referrals-cwr__label'>{item.label}</div>
                                        <div className='referrals-cwr__value'>
                                            {item.value || '-'}
                                        </div>
                                    </div>
                                ))
                            }
                        </React.Fragment>
                    ) : null
                }
                {renderRefereesList()}
            </div>
            <Loader visible={loading} />
        </ExpandableCard>
    );
};

export default ReferralView;

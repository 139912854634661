/* eslint-disable no-unused-vars */
/**
 * @file Loans - Component to show the Pre Approved Loans, LAMF and other loan related menus.
 */

import React from 'react';

import './style.scss';
import { compose } from 'redux';
import { LoansOptions } from '../Tickets/constants';
import PreApprovedLoans from './PreApprovedLoans';
import LoanAgainstMF from './LoanAgainstMF';
import { injectReducer, injectSaga } from '../../utils';
import { LOANS_KEY } from './constants';
import reducer from './reducer';
import saga from './saga';

const Loans = (props) => {
    const {
        ticketId, userId, index, type,
    } = props;

    switch (type) {
        case LoansOptions.PreApprovedLoans:
            return <PreApprovedLoans ticketId={ticketId} userId={userId} index={index} />;

        case LoansOptions.LoanAgainstMF:
            return <LoanAgainstMF ticketId={ticketId} userId={userId} index={index} />;

        default:
            return <React.Fragment>Error</React.Fragment>;
    }
};

const withReducer = injectReducer({ key: LOANS_KEY, reducer });
const withSaga = injectSaga({ key: LOANS_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(Loans);

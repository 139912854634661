/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useMeeting } from '@videosdk.live/react-sdk';
import ParticipantView from './ParticipantView';
import { ComponentLoader } from '../../../components';

const AgentMeetingView = (props) => {
    const { participantId, key } = props;
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const dragItemRef = useRef(null);

    useEffect(() => {
        const updatePosition = () => {
            setPosition({
                x: window.innerWidth - dragItemRef.current.offsetWidth - 160, // 20px padding from the edge
                y: window.innerHeight - dragItemRef.current.offsetHeight - 48, // 20px padding from the edge
            });
        };

        updatePosition(); // Set initial position
        window.addEventListener('resize', updatePosition); // Update position on window resize

        return () => {
            window.removeEventListener('resize', updatePosition); // Clean up the event listener
        };
    }, []);

    const onDragStart = (e) => {
        const { offsetLeft, offsetTop } = dragItemRef.current;
        setOffset({
            x: e.clientX - offsetLeft,
            y: e.clientY - offsetTop,
        });
    };

    const onDrag = (e) => {
        if (e.clientX === 0 && e.clientY === 0) return; // Ignore dragend event
        setPosition({
            x: e.clientX - offset.x,
            y: e.clientY - offset.y,
        });
    };

    const onDragEnd = (e) => {
        setPosition({
            x: e.clientX - offset.x,
            y: e.clientY - offset.y,
        });
    };

    return (
        <div
            tabIndex={-1}
            className='vkyc-agent-video'
            ref={dragItemRef}
            draggable
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            style={{
                position: 'fixed',
                left: `${position.x}px`,
                top: `${position.y}px`,
                width: '100px',
                height: '100px',
                backgroundColor: 'white',
                cursor: 'grab',
            }}
        >
            <ParticipantView
                participantId={participantId}
                key={key}
                height='144px'
                width='256px'
            />
        </div>
    );
};

const MeetingView = (props) => {
    const { participantJoiningStatus, setParticipantLoadingStatus } = props;

    const [joined, setJoined] = useState(null);
    // Get the method which will be used to join the meeting.
    // We will also get the participants list to display all participants
    const { join, participants } = useMeeting({
        // callback for when meeting is joined successfully
        onMeetingJoined: () => {
            setJoined('JOINED');
        },
        // callback for when meeting is left
        onMeetingLeft: () => {
            props?.onMeetingLeave();
        },
        onParticipantJoined: () => {
        },
        onError: (error) => {
            console.error('Error joining the meeting', error);
        },
        onParticipantLeft: (participant) => {
            console.error('Participant left', participant);
        },
    });

    const joinMeeting = () => {
        setJoined('JOINING');
        join();
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            joinMeeting();
        }, 1000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className='container'>
            {joined && joined === 'JOINED' ? (
                <div>
                    <div className='vkyc-customer-video'>
                        <div
                            className='vkyc-customer-video-player'
                        >
                            {!participantJoiningStatus && (
                                <div className='vkyc-customer-video-loader'>
                                    <ComponentLoader visible />
                                    <div className='vkyc-customer-video-loader-text'>Connecting to user</div>
                                </div>
                            )}
                            { [...participants.keys()].length > 1
                    && (
                        <ParticipantView
                            participantId={[...participants.keys()][1]}
                            key={[...participants.keys()][1]}
                            width='100%'
                            height='100%'
                            recordAudio
                            setParticipantLoadingStatus={setParticipantLoadingStatus}
                        />
                    )}
                        </div>
                        <div className='vkyc-customer-consumer-title'>
                            <span className='vkyc-customer-consumer-title-text'>Customer</span>
                        </div>
                    </div>
                    { [...participants.keys()].length > 0
                    && (
                        <AgentMeetingView
                            participantId={[...participants.keys()][0]}
                            key={[...participants.keys()][0]}
                        />
                    )}
                    {/* {[...participants.keys()].map((participantId) => (
                        <ParticipantView
                            participantId={participantId}
                            key={participantId}
                        />
                    ))} */}
                </div>
            ) : joined && joined === 'JOINING' ? (
                <p>Joining the meeting...</p>
            ) : (
                // eslint-disable-next-line react/button-has-type
                <button onClick={joinMeeting} type='button'>Join</button>
            )}
        </div>
    );
};

export default MeetingView;

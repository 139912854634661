/**
 * @file Selectors for SalaryDataOps
 */

import { createSelector } from 'reselect';

import {
    REDUCER_KEYS, SALARY_ACCOUNT_DETAILS_KEY, SALARY_ACCOUNT_EXECUTED_MANDATES,
    SALARY_ACCOUNT_HEALTH_INSURANCE_KEY, SALARY_ACCOUNT_MANDATES, SALARY_ACCOUNT_OPS_KEY,
} from './constants';

export const selectViewSalaryAccountData = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'accountDetails', screenKey, 'salaryData']),
    (subState) => subState?.toJS() || {},
);

export const selectSalaryVerificationRequestsForUser = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'accountDetails', screenKey, 'verificationRequests']),
    (subState) => subState?.toJS() || {},
);

export const selectSalaryEmployerDetailsForUser = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'accountDetails', screenKey, 'employerDetails']),
    (subState) => subState?.toJS() || {},
);

export const selectViewSalaryAccountLoading = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'accountDetails', screenKey, 'loading']),
    (subState) => subState,
);

export const selectViewSalaryAccountErr = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'accountDetails', screenKey, 'err']),
    (subState) => subState,
);

export const selectSalaryActivationStatus = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, SALARY_ACCOUNT_DETAILS_KEY, screenKey, REDUCER_KEYS.ACTIVATION_STATUS]),
    (subState) => subState?.toJS() || {},
);

export const selectUserHealthInsurancePolicyDetails = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, SALARY_ACCOUNT_HEALTH_INSURANCE_KEY, screenKey, REDUCER_KEYS.USER_DETAILS]),
    (subState) => subState?.toJS() || {},
);

export const selectGeneralHealthInsurancePolicyDetails = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, SALARY_ACCOUNT_HEALTH_INSURANCE_KEY, screenKey, REDUCER_KEYS.GENERAL_DETAILS]),
    (subState) => subState?.toJS() || {},
);

export const selectSalaryAccountMandates = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, SALARY_ACCOUNT_MANDATES, screenKey]),
    (subState) => subState?.toJS() || {},
);

export const selectSalaryAccountExecutedMandates = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, SALARY_ACCOUNT_EXECUTED_MANDATES, screenKey]),
    (subState) => subState?.toJS() || {},
);

export const selectSalaryAccountOpsLoading = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, screenKey, 'loading']),
    (subState) => subState,
);

export const selectSalaryAccountOpsLoadingSubscreen = (screenKey, subScreenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, screenKey, subScreenKey, 'loading']),
    (subState) => subState,
);

export const selectSalaryAccountOpsErr = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, screenKey, 'err']),
    (subState) => subState,
);

export const selectSalaryAccountOpsCurView = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, screenKey, 'curView']),
    (subState) => subState,
);

export const selectSalaryAccountEmployerDetails = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'accountDetails', 'employerDetails']),
    (subState) => subState?.toJS(),
);

export const selectSalaryAccountUserIdentifierDetails = (screenKey) => createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, screenKey, 'userIdentifier']),
    (subState) => subState?.toJS(),
);

export const selectSalaryOpsEnums = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'enums']),
    (subState) => subState?.toJS(),
);

export const selectSalaryVerificationQueue = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'verificationQueue']),
    (subState) => subState?.toJS(),
);

export const selectSalaryVerificationCaseDetails = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'verificationQueue', 'caseDetails']),
    (subState) => subState?.toJS(),
);

export const selectSalaryVerificationCaseEmployerDetails = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'verificationQueue', 'caseEmployerDetails']),
    (subState) => subState?.toJS(),
);

export const selectSalaryVerificationCaseTxnHistory = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'verificationQueue', 'caseTxnHistory']),
    (subState) => subState?.toJS(),
);

export const selectSalaryVerificationNewEmployerDetails = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'verificationQueue', 'newEmployerDetails']),
    (subState) => subState?.toJS(),
);

export const selectSalaryVerificationStatus = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'verificationQueue', 'verificationStatus']),
    (subState) => subState?.toJS(),
);
export const selectRaiseVerificationRequestStatus = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'raiseVerificationRequest', 'raiseVerificationStatus']),
    (subState) => subState?.toJS(),
);

export const selectRaiseVerificationRequestStage = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'raiseVerificationRequest', 'raiseVerificationStage']),
    (subState) => subState?.toJS(),
);

export const selectRaiseVerificationRequestCases = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'raiseVerificationRequest', 'raiseVerificationCases']),
    (subState) => subState?.toJS(),
);

export const selectSelectedActor = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'raiseVerificationRequest', 'selectedActor']),
    (subState) => subState?.toJS(),
);

export const selectRaiseVerificationRequestCaseDetails = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'raiseVerificationRequest', 'raiseVerificationCaseDetails']),
    (subState) => subState?.toJS(),
);

export const selectEligibleTxns = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'raiseVerificationRequest', 'raiseVerificationEligibleTxns']),
    (subState) => subState?.toJS(),
);

export const selectTabData = createSelector(
    (state) => state.getIn([SALARY_ACCOUNT_OPS_KEY, 'tabs']),
    (subState) => subState?.toJS() || {},
);

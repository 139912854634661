/*
 *
 * Wealth Onboarding Saga
 *
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { WEALTH_ROOT } from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_ONBOARDING_INFO, SET_ONBOARDING_INFO, ERR_ONBOARDING_INFO,
} from './constants';

function* getOnboardingInfo(action) {
    const { ticketId, userId, index } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId, onboardingType: 1 });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_ROOT}/onboarding?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_ONBOARDING_INFO, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_ONBOARDING_INFO, data: { ticketId } });
        }
    } catch (e) {
        // const err = getErrLabel(e);
        // toastify(err, 'error');
        yield put({ type: ERR_ONBOARDING_INFO, data: { ticketId, err: e.errMsgForUI } });
    }
}

export default function* wealthOnboardingSaga() {
    yield all(
        [
            yield takeLatest(GET_ONBOARDING_INFO, getOnboardingInfo),
        ]
    );
}

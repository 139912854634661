/**
 * @file WealthOps Actions
 */

import {
    DEQUEUE_WEALTH_OPS_CASE, GET_WEALTH_OPS_LIVENESS_VIDEO, GET_WEALTH_OPS_PENDING_REVIEW_COUNT, GET_WEALTH_OPS_QUEUE, SET_WEALTH_OPS_CUR_CASE,
    SET_WEALTH_OPS_CUR_VIEW, SET_WEALTH_OPS_PAYLOAD_TYPE, SET_WEALTH_OPS_PENDING_REVIEW_COUNT, SET_WEALTH_OPS_QUEUE_FILTERS,
} from './constants';

export const getWealthOpsQueueCasesAction = (data) => ({
    type: GET_WEALTH_OPS_QUEUE,
    data,
});

export const dequeueWealthOpsCaseAction = (data) => ({
    type: DEQUEUE_WEALTH_OPS_CASE,
    data,
});

export const setWealthOpsCurViewAction = (data) => ({
    type: SET_WEALTH_OPS_CUR_VIEW,
    data,
});

export const setWealthOpsCurCaseAction = (data) => ({
    type: SET_WEALTH_OPS_CUR_CASE,
    data,
});

export const setWealthOpsPayloadTypeAction = (data) => ({
    type: SET_WEALTH_OPS_PAYLOAD_TYPE,
    data,
});

export const getWealthOpsLivenessVideoAction = (data) => ({
    type: GET_WEALTH_OPS_LIVENESS_VIDEO,
    data,
});

export const setWealthOpsQueueFiltersAction = (data) => ({
    type: SET_WEALTH_OPS_QUEUE_FILTERS,
    data,
});

export const getWealthOpsPendingReviewCountAction = (data) => ({
    type: GET_WEALTH_OPS_PENDING_REVIEW_COUNT,
    data,
});

export const setWealthOpsPendingReviewCountAction = (data) => ({
    type: SET_WEALTH_OPS_PENDING_REVIEW_COUNT,
    data,
});

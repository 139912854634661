import { createSelector } from 'reselect';

// const selectDevActions = (state) => state.get('devActions');

const makeSelectLoading = (containerKey) => createSelector(
    (state) => state.getIn(['devActions', containerKey, 'loading']),
    (subState) => subState || false,
);

const makeSelectErr = (containerKey) => createSelector(
    (state) => state.getIn(['devActions', containerKey, 'err']),
    (subState) => subState || '',
);

const makeSelectActionList = (containerKey) => createSelector(
    (state) => state.getIn(['devActions', containerKey, 'actionList']),
    (subState) => subState?.toJS() || [],
);

const makeSelectParameterList = (containerKey) => createSelector(
    (state) => state.getIn(['devActions', containerKey, 'parameterList']),
    (subState) => subState?.toJS() || [],
);

const makeSelectExecuteInfo = (containerKey) => createSelector(
    (state) => state.getIn(['devActions', containerKey, 'executeInfo']),
    (subState) => subState?.toJS() || [],
);

const makeSelectHasExecuteInfo = (containerKey) => createSelector(
    (state) => state.getIn(['devActions', containerKey, 'hasExecuteInfo']),
    (subState) => subState || false,
);

const makeSelectMonorailInfo = (containerKey) => createSelector(
    (state) => state.getIn(['devActions', containerKey, 'monorailInfo']),
    (subState) => subState?.toJS() || { id: '' },
);

const selectIsParameterListV2 = (containerKey) => createSelector(
    (state) => state.getIn(['devActions', containerKey, 'isNewParamerterList']),
    (substate) => substate,
);

const selectConditionOptionMap = (containerKey) => createSelector(
    (state) => state.getIn(['devActions', containerKey, 'conditionalOptionsMap']),
    (subState) => subState?.toJS(),
);

const selectUserResponseV2 = (containerKey) => createSelector(
    (state) => state.getIn(['devActions', containerKey, 'userResponseV2']),
    (subState) => subState?.toJS(),
);

export {
    makeSelectLoading,
    makeSelectErr,
    makeSelectActionList,
    makeSelectParameterList,
    makeSelectExecuteInfo,
    makeSelectHasExecuteInfo,
    makeSelectMonorailInfo,
    selectIsParameterListV2,
    selectConditionOptionMap,
    selectUserResponseV2,
};

/**
 * @file BankingOps
 * Root component for CX Banking ops views related to salary
 */

import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
    BA_SALARY_ACCOUNT_DETAILS_PATH, BA_SALARY_EXECUTED_MANDATES_PATH, BA_SALARY_HEALTH_INSURANCE_PATH,
    BA_SALARY_MANDATES_PATH, BA_SALARY_VERIFICATION_REQUESTS_PATH,
} from '../../App/routes';

import BankingAccountDetails from '../BankingAccountDetails';
import BankingVerificationRequests from '../BankingVerificationRequests';
import HealthInsuranceDetails from '../HealthInsuranceDetails';
import MandateDetails from '../MandateDetails';
import ExecutedMandateDetails from '../ExecutedMandateDetails';

const BankingOps = (props) => {
    const { ticketId, userId, index } = props;

    return (
        <Switch>
            <Route
                path={BA_SALARY_ACCOUNT_DETAILS_PATH}
                render={() => (
                    <BankingAccountDetails
                        ticketId={ticketId}
                        userId={userId}
                        index={index}
                        screenKey={`${ticketId}-account-details`}
                    />
                )}
            />
            <Route
                path={BA_SALARY_VERIFICATION_REQUESTS_PATH}
                render={() => (
                    <BankingVerificationRequests
                        ticketId={ticketId}
                        userId={userId}
                        index={index}
                        screenKey={`${ticketId}-verification-requests`}
                    />
                )}
            />
            <Route
                path={BA_SALARY_HEALTH_INSURANCE_PATH}
                render={() => (
                    <HealthInsuranceDetails
                        parentProps={props}
                        screenKey={ticketId}
                    />
                )}
            />
            <Route
                path={BA_SALARY_MANDATES_PATH}
                render={() => (
                    <MandateDetails
                        parentProps={props}
                        screenKey={ticketId}
                    />
                )}
            />
            <Route
                path={BA_SALARY_EXECUTED_MANDATES_PATH}
                render={() => (
                    <ExecutedMandateDetails
                        parentProps={props}
                        screenKey={ticketId}
                    />
                )}
            />
        </Switch>
    );
};

export default memo(BankingOps);

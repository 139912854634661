import { GET_MESSAGE_LIST, GET_MESSAGE_INFO, CLOSE_INFO_MODAL } from './constants';

export const getMessageList = data => ({
    type: GET_MESSAGE_LIST,
    data
});

export const getMessageInfo = data => ({
    type: GET_MESSAGE_INFO,
    data
});

export const closeInfoModal = data => ({
    type: CLOSE_INFO_MODAL,
    data
});

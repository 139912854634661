/**
 * @file HeaderFilter
 * Header title component with searching sorting filters
 */

import React from 'react';
import { renderSortIcon, renderSortPriority } from 'react-data-grid';
import pinSvg from '../../../assets/images/svgs/pin-fill.svg';
import unPinSvg from '../../../assets/images/svgs/pin-slash-fill.svg';

const HeaderFilter = (props) => {
    const {
        column, tabIndex, filter = {}, setFilter, onPin,
    } = props;

    const handleOnChange = (evt) => {
        const { value } = evt.target;
        const newFilter = { ...filter };

        if (value === '' || value == null) {
            // remove filter key when value is empty
            delete newFilter[column.key];
        } else {
            // add value and type to filter key
            newFilter[column.key] = { value, type: column.type };
        }
        setFilter(newFilter);
    };

    function inputStopPropagation(event) {
        if (['ArrowLeft', 'ArrowRight', ' '].includes(event.key)) {
            event.stopPropagation();
        }
    }

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flex: 1, overflow: 'hidden' }}>
                <div style={{ overflow: 'clip', textOverflow: 'ellipsis' }}>
                    <span>{column.name}</span>
                </div>
                <div>
                    <input
                        className='rdgrid-f'
                        tabIndex={tabIndex}
                        value={filter[column.key]?.value || filter[column.key] || ''}
                        onChange={handleOnChange}
                        onKeyDown={inputStopPropagation}
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>
            </div>
            <div>
                <button onClick={onPin(column.key)} type='button'>
                    <img style={{ height: 15, width: 15 }} src={!column.frozen ? pinSvg : unPinSvg} alt='pin' />
                </button>
                <span className='rdgrid-arrow-container'>
                    {renderSortIcon(props)}
                    <span>{renderSortPriority(props)}</span>
                </span>
            </div>
        </div>
    );
};

export default HeaderFilter;

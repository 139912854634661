/**
 * @file Component to support the upload file functionality in the native input component
 */

import React, { memo } from 'react';

import { toastify } from '../../utils';

const FileInput = (props) => {
    const {
        id, className, accept, onClick, onChange,
    } = props;

    const onFileClick = (e) => {
        e.target.value = null;

        if (onClick) {
            onClick();
        }
    };

    const onFileChange = (e) => {
        const fileList = e.target.files;
        const file = fileList && fileList[0];

        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        // options to read file in other formats
        // reader.readAsText(file);
        // reader.readAsDataURL(file);

        reader.onload = () => {
            // value of the file is received as an array buffer
            const arrayBuffer = reader.result;

            /**
             * convert array buffer to base64 encoded string
             * direct wrapping with btoa() does not work as expected
             * ref: https://stackoverflow.com/a/42334410
             */
            const contentBase64 = btoa(
                new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''),
            );

            if (onChange) {
                onChange(contentBase64);
            }
        };

        reader.onerror = () => {
            toastify('There is some problem in reading the file, please check & try again', 'error');
        };
    };

    return (
        <input
            type='file'
            id={id}
            className={className}
            accept={accept}
            onClick={onFileClick}
            onChange={onFileChange}
        />
    );
};

export default memo(FileInput);

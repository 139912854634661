import { createSelector } from 'reselect';

const selectFittt = state => state.get('fittt');

const makeSelectActiveRules = () => createSelector(
    selectFittt,
    substate => substate.get('activeRuleList').toJS()
);

const makeSelectUserRules = () => createSelector(
    selectFittt,
    substate => substate.get('userRuleList').toJS()
);

const makeSelectUserRuleInfo = () => createSelector(
    selectFittt,
    substate => substate.get('userRuleInfo').toJS()
);

export {
    makeSelectActiveRules,
    makeSelectUserRules,
    makeSelectUserRuleInfo,
};

/**
 *
 * LogsList
 *
 */

import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Button, Loader } from '../../../components';
import { LOGS_PATH } from '../../App/routes';

import { getLogInfo } from '../actions';
import { makeSelectLoading, makeSelectErr, makeSelectLogInfo } from '../selectors';
import '../style.scss';

const labelMap = {
    'agentEmail': 'agent email',
    'accessLevel': 'access level',
    'ticketId': 'ticket id',
    'action': 'action',
    'object': 'object',
    'status': 'status',
    'extraParams': 'parameters',
    'requestTs': 'timestamp',
    'id': 'id'
};

const LogDetails = () => {
    const { logId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const logInfo = useSelector(makeSelectLogInfo(), shallowEqual);

    useEffect(() => {
        dispatch(getLogInfo({ logId }));
    }, []);

    return (
        <React.Fragment>
            <div className='logs-container'>
                <div className='frcsbWrapper logdetails-hw'>
                    <div className='logdetails-hw__hl'>Log Details</div>
                    <Button secondary onClick={() => history.push(LOGS_PATH)} label='Back to list' />
                </div>
                <div className='logdetails-cc'>
                    {
                        Object.keys(logInfo)
                            .filter(key => key !== 'id')
                            .map(key => (
                                <div className='frcWrapper logdetails-cc-wr' key={key}>
                                    <div className='logdetails-cc__hl'>{labelMap[key]}</div>
                                    <div className='logdetails-cc__sp'>:</div>
                                    {
                                        key === 'extraParams' ? (
                                            <div>
                                                {
                                                    logInfo[key].map(item => (
                                                        <div key={item} className='logdetails-cc__cl'>{item}</div>
                                                    ))
                                                }
                                            </div>
                                        ) : (
                                            <div className='logdetails-cc__cl'>{logInfo[key] || '-'}</div>
                                        )
                                    }
                                </div>
                            ))
                    }
                    {
                        err && err.logInfo ? (
                            <div className='container-err-label'>{err.logInfo}</div>
                        ) : null
                    }
                </div>
            </div>
            <Loader visible={loading && loading.logInfo} />
        </React.Fragment>
    );

};

export default memo(LogDetails);
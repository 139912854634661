import { fromJS } from 'immutable';

import {
    GET_BULK_USER_INFO, SET_BULK_USER_INFO, ERR_BULK_USER_INFO, RESET_BULK_USER_INFO,
} from './constants';

const initialState = fromJS({
    userInfo: {},
});

function bulkUserDetailsByTicketIdsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_BULK_USER_INFO: {
            return state
                .setIn(['userInfo', 'loading'], true);
        }

        case SET_BULK_USER_INFO: {
            const { bulkUserInfo } = action.data;

            return state
                .setIn(['userInfo', 'loading'], false)
                .setIn(['userInfo', 'data'], fromJS(bulkUserInfo))
                .setIn(['userInfo', 'hasData'], true);
        }

        case ERR_BULK_USER_INFO: {
            return state
                .setIn(['userInfo', 'loading'], false)
                .setIn(['userInfo', 'data'], fromJS({}))
                .setIn(['userInfo', 'hasData'], false);
        }

        case RESET_BULK_USER_INFO:
            return state
                .set('userInfo', fromJS({}));

        default:
            return state;
    }
}

export default bulkUserDetailsByTicketIdsReducer;

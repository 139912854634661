/* eslint-disable import/no-cycle */
/**
 *
 * AppHeader
 *
 */

import React, { memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isDevEnviroment } from '../../utils';
import { MetaInfoContext } from '../../context';
import './style.scss';

const AppHeader = (props) => {
    const { children } = props;

    const metaInfo = useContext(MetaInfoContext);

    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    return (
        <div className={
            isDevEnviroment()
                ? classNames({ 'app-header bg-np': bannersVersion === 1 }, { 'app-header-v2 bg-np': bannersVersion === 2 })
                : classNames({ 'app-header bg': bannersVersion === 1 }, { 'app-header-v2 bg': bannersVersion === 2 })
        }
        >
            <Link className='app-header-label' to='/'>
                Sherlock
            </Link>
            {children}
        </div>
    );
};

export default memo(AppHeader);

/*
 *
 * AppLogs constants
 *
 */

export const GET_LOGS_ENUMS = 'app/AppLogs/GET_LOGS_ENUMS';
export const SET_LOGS_ENUMS = 'app/AppLogs/SET_LOGS_ENUMS';
export const GET_LOGS = 'app/AppLogs/GET_LOGS';
export const SET_LOGS = 'app/AppLogs/SET_LOGS';
export const ERR_LOGS = 'app/AppLogs/ERR_LOGS';
export const GET_LOG_INFO = 'app/AppLogs/GET_LOG_INFO';
export const SET_LOG_INFO = 'app/AppLogs/SET_LOG_INFO';
export const ERR_LOG_INFO = 'app/AppLogs/ERR_LOG_INFO';
export const SET_MONORAIL_ID = 'app/AppLogs/SET_MONORAIL_ID';
export const DOWNLOAD_FILE = 'app/AppLogs/DOWNLOAD_FILE';

export const IDENTIFIER_TYPE_LIST_KEY = 'identifierTypeList';

export const IDENTIFIER_TYPES_INFO = {
    PHONE: 'Phone',
    EMAIL: 'Email',
    ACTOR: 'Actor',
};

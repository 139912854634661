/**
 *
 * CustomerDetails
 * Takes in the actorID & fetches the customer details like name, email, kyc status, summary, etc.
 * Used in the 3 Risk ops queue: Liveness, facematch & namematch
 * Also used as a standalone tab for RISK_OPS role
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import {
    Button, ExpandableCard, FormWithSingleInput, Loader, MonorailIdInput, HorizontalIdNavbar,
} from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import { CUSTOMER_DETAILS_KEY } from './constants';
import {
    getCustomerDetailsAction, setMonorailIdForCustomerDetailsAction,
} from './actions';
import {
    selectCustomerDetailsLoading, selectCustomerDetailsErr, selectCustomerDetailsInfo, selectCustomerDetailsMonorailInfo,
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const CustomerDetails = (props) => {
    const { externalData = {} } = props;

    const dispatch = useDispatch();
    const actorIdRef = useRef(null);

    const loading = useSelector(selectCustomerDetailsLoading(), shallowEqual);
    const err = useSelector(selectCustomerDetailsErr(), shallowEqual);
    const customerDetailsInfo = useSelector(selectCustomerDetailsInfo(), shallowEqual);
    const monorailInfo = useSelector(selectCustomerDetailsMonorailInfo(), shallowEqual);

    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(true);
    const [actorId, setActorId] = useState('');
    const [formErrLabel, setFormErrLabel] = useState('');

    const { id: monorailId } = monorailInfo;

    // Set new ticket to true on initial render or when no monorail id present
    const [newTicket, setNewTicket] = useState(!monorailId);
    // Set navbar items to empty array on initial render or when no monorail id present
    const [navItems, setNavItems] = useState((monorailId && [{ id: monorailId }]) || []);

    const fetchCustomerDetails = (reqActorId, reqMonorailId) => {
        setFormErrLabel('');

        /**
         * Send '0' in case monorail ID is not passed since it is a mandatory param
         * This is required when CustomerDetails component is being used as a child in another component
         */
        dispatch(getCustomerDetailsAction({
            monorailId: reqMonorailId || '0',
            actorId: reqActorId,
        }));
        setIsSearchCardExpanded(false);
    };

    useEffect(() => {
        if (monorailId && isSearchCardExpanded) {
            actorIdRef.current.focus();
        }
    }, [monorailId, isSearchCardExpanded]);

    useEffect(() => {
        // If actor ID is already passed through props, fetch customer details
        if (externalData.actorId) {
            setActorId(externalData.actorId);
            fetchCustomerDetails(externalData.actorId, '0');
        }
    }, [dispatch, externalData.actorId]);

    const resetMonorailId = () => {
        setNewTicket(true);
        setNavItems([]);
        dispatch(setMonorailIdForCustomerDetailsAction({ value: '' }));
    };

    const handleMonorailIdSubmit = (value) => {
        setActorId('');
        setIsSearchCardExpanded(true);
        setNewTicket(false);
        setNavItems([{ id: value }]);
        dispatch(setMonorailIdForCustomerDetailsAction({ value }));
    };

    const renderIdNavbar = (
        <HorizontalIdNavbar
            isFixed
            hasSingleItem
            isNewTicketActive={newTicket}
            activeId={monorailId}
            navItems={navItems}
            onItemCloseIconClick={resetMonorailId}
        />
    );

    if (!externalData.actorId && !monorailId) {
        return (
            <React.Fragment>
                {renderIdNavbar}
                <MonorailIdInput submitMonorailId={handleMonorailIdSubmit} />
            </React.Fragment>
        );
    }

    const handleInputChange = (key) => (event) => {
        const { value } = event.target;

        switch (key) {
            case 'actorId':
                setActorId(value);
                break;

            default:
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const modifiedActorId = actorId.trim();

        if (!modifiedActorId) {
            setFormErrLabel('Please fill all the * marked fields');
            return;
        }

        fetchCustomerDetails(modifiedActorId, monorailId);
    };

    const renderCustomerDetailsInfo = () => {
        if (!customerDetailsInfo) {
            return null;
        }

        if (customerDetailsInfo.length === 0) {
            return (<div className='cusdet-src'>No Customer Details found.</div>);
        }

        return (
            <div className='cusdet-src'>
                {JSON.stringify(customerDetailsInfo, null, 4)}
            </div>
        );
    };

    return (
        <React.Fragment>
            {/**
             * Show monorail id tab only when:
             * If monorail id is present. If it is not present, it causes side effect on clicking the cross button
             * If externalData.actorId is not present. If it is present, that means the component is getting used as a child component somewhere else
            */}
            {!externalData.actorId && monorailId && (
                renderIdNavbar
            )}

            <div className='cusdet-cr'>
                <ExpandableCard
                    extClasses='cusdet-ec'
                    heading='Search for Customer Details'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='cusdet-sc'>
                        <FormWithSingleInput
                            hideErrLabel
                            disableFormSubmitOnEnter
                            extClasses={{
                                container: 'cusdet-fc--m0',
                                label: 'cusdet-fc__lb',
                                inputField: 'cusdet-fc__ip',
                            }}
                            label='Actor Id*'
                            labelId='actor-id'
                            input={{
                                value: actorId,
                                onChange: handleInputChange('actorId'),
                                ref: actorIdRef,
                            }}
                            onFormSubmit={handleSubmit}
                        />
                        {formErrLabel ? <div className='err-label cusdet-el'>{formErrLabel}</div> : null}
                        <Button
                            extClasses={`cusdet-sc-cta ${formErrLabel && 'cusdet-sc-cta--err'}`}
                            primary
                            label='Search'
                            onClick={handleSubmit}
                        />
                    </div>
                </ExpandableCard>

                {err ? (<div className='cusdet-crel err-label container-err-label'>{err}</div>) : null}

                {renderCustomerDetailsInfo()}
            </div>

            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: CUSTOMER_DETAILS_KEY, reducer });
const withSaga = injectSaga({ key: CUSTOMER_DETAILS_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(CustomerDetails);

/* eslint-disable jsx-a11y/media-has-caption */
/**
 * @file RenderVideo: Renders a video on the browser
 */

import React, { memo } from 'react';

import DisableContextMenu from '../DisableContextMenu';

const RenderVideo = (props) => {
    const {
        inlineWidth = '480',
        inlineHeight = '540',
        allowDownload = false,
        videoSrc,
    } = props;

    if (!videoSrc) return null;

    let controlsList = '';

    if (!allowDownload) controlsList += 'nodownload';

    return (
        <DisableContextMenu>
            <video
                width={inlineWidth}
                height={inlineHeight}
                controls
                disablePictureInPicture
                controlsList={controlsList}
            >
                <source src={videoSrc} type='video/mp4' />
                Your browser does not support the video tag.
                Please try with another browser.
            </video>
        </DisableContextMenu>
    );
};

export default memo(RenderVideo);

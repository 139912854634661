/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @file Component to show the banners as notifications over the page
 */

import React, { memo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { setBannersVisibility } from '../../../appGlobal/actions';
import { ICONS_URL } from '../../../constants/assets';
import { makeSelectBannersVisibility } from '../selectors';

import './style.scss';

const NotifBanners = (props) => {
    const { title } = props;

    const dispatch = useDispatch();

    const showBanners = useSelector(makeSelectBannersVisibility, shallowEqual);

    const [openBanner, setOpenBanner] = useState(true);

    if (!openBanner) return null;

    return (
        <div className='notification-top-bar'>
            <div
                className='notification-text'
                onClick={() => {
                    dispatch(setBannersVisibility({ showBanners: !showBanners }));
                }}
                // Need to add this to stop propagating to outside click handler of the notification component
                onMouseDown={(e) => e.stopPropagation()}
            >
                {title}
            </div>
            <div className='notification-close' onClick={() => setOpenBanner(false)}>
                <img
                    className=''
                    src={ICONS_URL.WHITE_CLOSE}
                    alt='close-icon'
                    width='15px'
                />
            </div>
        </div>
    );
};

export default memo(NotifBanners);

import { createSelector } from 'reselect';

const selectLivenessStatus = state => state.get('livenessStatus');

const makeSelectLoading = () => createSelector(
    selectLivenessStatus,
    substate => substate.get('loading')
);

const makeSelectStatusList = () => createSelector(
    selectLivenessStatus,
    substate => substate.get('statusList')
);

const makeSelectMonorailInfo = () => createSelector(
    selectLivenessStatus,
    substate => substate.get('monorailInfo').toJS()
);

export {
    makeSelectLoading,
    makeSelectStatusList,
    makeSelectMonorailInfo,
};

/**
 *
 * Single Date Picker for selecting a single date
 *
 */

import React, { memo } from 'react';

import FormWithSingleInput from '../FormWithSingleInput';

// TODO: Add Date UpperLimit props
const SingleDatePicker = (props) => {
    const {
        dateInfo,
        toDate,
        setToDate,
        showTimeInDateInput,
    } = props;

    const {
        toDateInfo, extClasses, initialDateRange,
    } = dateInfo;

    const { label: toLabel, labelId: toLabelId } = toDateInfo;

    const {
        upperLimitDate, lowerLimitDate, toDate: initialToDate,
    } = initialDateRange;

    const getDateRange = ({ toDate: curToDate, prevState }) => {
        const { toDate: toDatePrevState } = prevState;

        const newToDate = new Date(curToDate);

        if (!showTimeInDateInput) newToDate.setHours(23, 59, 59, 999);

        const modifiedToDate = {
            ...toDatePrevState,
            value: newToDate,
        };

        return {
            modifiedToDate,
        };
    };

    const handleDateChange = (key) => (value) => {
        // edge case when user clears date input
        if (!value) {
            setToDate(initialToDate);
            return;
        }

        const data = {
            prevState: {
                upperLimitDate, lowerLimitDate, toDate,
            },
        };

        data[key] = value;

        const { modifiedToDate } = getDateRange(data);

        setToDate(modifiedToDate);
    };
    const { upperLimit: maxToDate, lowerLimit: minToDate, value: toValue } = toDate;

    return (
        <FormWithSingleInput
            disableFormSubmitOnEnter
            extClasses={extClasses}
            label={toLabel}
            labelId={toLabelId}
            formType='dob'
            input={{
                value: toValue,
                onChange: handleDateChange('toDate'),
                maxDate: maxToDate,
                minDate: minToDate,
                showTimeInDateInput,
                filterPassedTime: false,
            }}
            hideErrLabel
        />
    );
};

export default memo(SingleDatePicker);

/*
 *
 * AgentCreateForm actions
 *
 */

import {
    FORM_INITIAL_VALUES, GET_ACCESS_LEVEL,
} from './constants';

export const setInitialValues = (data) => ({
    type: FORM_INITIAL_VALUES,
    data
});

export const getAccessLevel = () => ({
    type: GET_ACCESS_LEVEL,
});

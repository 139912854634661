/**
 * Utility functions to handle the generic parameters list for db states, dev actions & admin actions.
 */

/**
 * This function helps to determine the flag whether the field are empty or not
 * @param {object} item object with type, value as required keys
 * @returns {boolean} flag
 */
const getEmptyValueFlag = (item) => {
    const { type, value } = item;

    if (type === 4) { // TIMESTAMP
        return !!value;
    }

    if (type === 7) { // NAME
        const { first_name: firstName, last_name: lastName } = value;

        return !!(firstName.trim() && lastName.trim());
    }

    if (type === 9 || type === 12) { // MULTI_SELECT_DROPDOWN, FILE_LIST
        return !!value.length;
    }

    if (type === 11) { // FILE
        const { contentBase64, contentType } = value;

        return !!(contentBase64 && contentType);
    }

    if (type === 14) { // BOOLEAN
        return value;
    }

    // STRING
    return !!value.trim();
};

const checkRequiredFieldForOneItem = (item) => {
    const { type, value, isOptional } = item;

    if (isOptional) return true;

    switch (type) {
        case 4: { // TIMESTAMP
            // initial value is string, it gets changed to object on selecting any value from Date Picker
            if (!value) {
                return false;
            }
            break;
        }
        case 7: { // NAME
            const { first_name: firstName, last_name: lastName } = value;

            if (!(firstName.trim() && lastName.trim())) {
                return false;
            }
            break;
        }
        case 9: { // MULTI_SELECT_DROPDOWN
            if (!value.length) {
                return false;
            }
            break;
        }
        case 11: { // FILE
            if (!value) {
                return false;
            }

            const { contentBase64, contentType } = value;

            if (!(contentBase64 && contentType)) {
                return false;
            }
            break;
        }
        case 12: { // FILE_LIST
            if (!value) {
                return false;
            }

            if (!value.length) {
                return false;
            }
            break;
        }

        case 14: { // BOOLEAN
            return true;
        }
        default: {
            if (!(value && value.trim())) { // STRING
                return false;
            }
        }
    }

    // if reached till here that means all conditions are satisfied, so item is valid
    return true;

    /* old logic, can delete after this becomes stable in production for a few weeks
    if (!isOptional) {

        if (type === 4) { // TIMESTAMP
            // initial value is string, it gets changed to object on selecting any value from Date Picker
            if (!value) {
                hasAllRequiredFieldsFilled = false;
            }
        } else if (type === 7) { // NAME
            const { first_name: firstName, last_name: lastName } = value;

            if (!(firstName.trim() && lastName.trim())) {
                hasAllRequiredFieldsFilled = false;
            }
        } else if (type === 9) { // MULTI_SELECT_DROPDOWN
            if (!value.length) {
                hasAllRequiredFieldsFilled = false;
            }
        } else if (type === 11) { // FILE
            if (!value) {
                hasAllRequiredFieldsFilled = false;
            } else {
                const { contentBase64, contentType } = value;

                if (!(contentBase64 && contentType)) {
                    hasAllRequiredFieldsFilled = false;
                }
            }
        } else if (type === 12) { // FILE_LIST
            if (!value) {
                hasAllRequiredFieldsFilled = false;
            } else if (!value.length) {
                hasAllRequiredFieldsFilled = false;
            }
        } else if (!value.trim()) { // STRING
            hasAllRequiredFieldsFilled = false;
        }

    } */
};

/**
 * This function helps to determine the flag whether the required fields are filled or not
 * @param {array} data array of objects
 * @returns {boolean} flag
 */
const getRequiredFieldsFilledFlag = (data) => data
    .filter((item) => !item.isOptional) // filter out optional fields
    .every((item) => checkRequiredFieldForOneItem(item));

/**
 * This function helps to determine the flag whether the string is compatible for encoding or not
 * @param {string} str string to be checked for encoding compatibility
 * @returns {boolean} encoding compatibility flag
 */
const stringEncodingCompatibility = (str) => {
    if (!str) return true;
    // the btoa function only accepts characters with ASCII code between 0 and 255
    return typeof str === 'string' && [...str].every((char) => char.charCodeAt(0) <= 255 && char.charCodeAt(0) >= 0);
};

/**
 * This function helps to determine whether the data is compatible for encoding or not
 * @param {array} data array of objects
 * @returns {boolean} encoding compatibility flag
 */
const getEncodingCompatibilty = (data) => data
    .every((item) => {
        const { type, value, secondaryValue } = item;

        switch (type) {
            case 4:// TIMESTAMP
            case 5:// DROPDOWN
            case 7:// NAME
            case 9:// MULTI_SELECT_DROPDOWN
            case 11:// FILE
            case 12:// FILE_LIST
            case 14:// BOOLEAN
            case 13: { // MULTILINE_TEXT
                // no check in value as the content does not need to be encoded
                return true;
            }
            default: {
                return stringEncodingCompatibility(value) && stringEncodingCompatibility(secondaryValue);
            }
        }
    });

/**
 * This function helps to modify the parameter list with trimming spaces, encoding strings, etc.
 * @param {array} data array of objects
 * @returns {array} modified array of objects
 */
const getModifiedParameterList = (data, requiredEncoding) => {
    const modifiedData = data
        .filter(getEmptyValueFlag)
        .map((item) => {
            const { type, extras } = item;
            let { value, secondaryValue } = item;

            /* old logic, can delete after this becomes stable in production for a few weeks
            if (type === 4) { // TIMESTAMP
                // no change in value as DatePicker values sets as object
            } else if (type === 5) { // DROPDOWN
                // no change in value as dropdown values comes from BE & gets selected on UI
            } else if (type === 7) { // NAME
                value.first_name = value.first_name.trim();
                value.last_name = value.last_name.trim();
            } else if (type === 9) { // MULTI_SELECT_DROPDOWN
                // no change in value as dropdown values comes from BE & gets selected on UI
            } else if (type === 11) { // FILE
                // no change in value as base64 content of file & its type sets as object
            } else if (type === 12) { // FILE_LIST
                // no change in value as base64 content of file & its type sets as array
            } else { // STRING
                value = value.trim();

                if (requiredEncoding) {
                    value = btoa(value);
                }
            }
            */

            switch (type) {
                case 4: { // TIMESTAMP
                    // no change in value as DatePicker values sets as object
                    break;
                }
                case 5: { // DROPDOWN
                    // no change in value as dropdown values comes from BE & gets selected on UI
                    break;
                }
                case 7: { // NAME
                    value.first_name = value.first_name.trim();
                    value.last_name = value.last_name.trim();
                    break;
                }
                case 8: {
                    value = value.trim();
                    secondaryValue = secondaryValue && secondaryValue.trim();

                    if (requiredEncoding) {
                        value = btoa(value);
                        secondaryValue = secondaryValue && btoa(secondaryValue);
                    }
                    break;
                }
                case 9: { // MULTI_SELECT_DROPDOWN
                    // no change in value as dropdown values comes from BE & gets selected on UI
                    break;
                }
                case 11: { // FILE
                    // no change in value as base64 content of file & its type sets as object
                    break;
                }
                case 12: { // FILE_LIST
                    // no change in value as base64 content of file & its type sets as array
                    break;
                }
                case 14: // BOOLEAN
                case 13: { // MULTILINE_TEXT
                    // no change in value as the content does not need to trim whitespace
                    break;
                }

                default: {
                    value = value.trim();

                    if (requiredEncoding) {
                        value = btoa(value);
                    }
                }
            }

            return {
                name: item.name,
                value,
                type: item.type,
                secondaryValue,
                ...((extras) && { extras }),
            };
        });

    return modifiedData;
};

const getModifiedParameterListV2 = (data, requiredEncoding) => {
    const modifiedData = Object.entries(data)
        .filter((responseKV) => Array.isArray(responseKV)
        && responseKV.length > 0 && getEmptyValueFlag(responseKV[1]))
        .map(([name, response]) => {
            const { type, extras } = response;
            let { value } = response;

            switch (type) {
                case 4: { // TIMESTAMP
                    // no change in value as DatePicker values sets as object
                    break;
                }
                case 5: { // DROPDOWN
                    // no change in value as dropdown values comes from BE & gets selected on UI
                    break;
                }
                case 7: { // NAME
                    value.first_name = value.first_name.trim();
                    value.last_name = value.last_name.trim();
                    break;
                }
                case 9: { // MULTI_SELECT_DROPDOWN
                    // no change in value as dropdown values comes from BE & gets selected on UI
                    break;
                }
                case 11: { // FILE
                    // no change in value as base64 content of file & its type sets as object
                    break;
                }
                case 12: { // FILE_LIST
                    // no change in value as base64 content of file & its type sets as array
                    break;
                }
                case 13: { // MULTILINE_TEXT
                    // no change in value as the content does not need to trim whitespace
                    break;
                }
                default: {
                    value = value.trim();

                    if (requiredEncoding) {
                        value = btoa(value);
                    }
                }
            }

            return {
                name,
                value,
                type,
                ...((extras) && { extras }),
            };
        });

    return modifiedData;
};

export {
    getEmptyValueFlag,
    getRequiredFieldsFilledFlag,
    getModifiedParameterList,
    checkRequiredFieldForOneItem,
    getModifiedParameterListV2,
    getEncodingCompatibilty,
};

import React from 'react';
import Check from '../../assets/images/svgs/check.svg';
import Cross from '../../assets/images/svgs/cross.svg';
import './styles.scss';

const CorrectWrongIcon = ({ isCorrect }) => {
    const className = isCorrect ? 'check' : 'cross';
    const componentName = isCorrect ? Check : Cross;
    return (
        <div className={className}>
            <img src={componentName} alt={className} className={`${className}__icon`} />
        </div>
    );
};

export default CorrectWrongIcon;

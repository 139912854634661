/**
 *
 * OnboardingTroubleshoot
 *
 */

import React, { memo, useState } from 'react';
import Modal from 'react-modal';

import { Button } from '../../../components';
import { ONBOARDING_TROUBLESHOOT_MAP, IN_APP_SCREENING_TROUBLESHOOT_MAP } from '../constants';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '12%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '32px',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 1000,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const OnboardingTroubleshoot = (props) => {
    const [showModal, setModal] = useState(false);
    const [selectedModalData, setModalData] = useState([]);

    const viewDetailsHandler = (data) => () => {
        setModalData(data);

        if (data.length === 0) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    const { isInAppScreeningFlow } = props;
    let data;

    if (isInAppScreeningFlow) {
        data = IN_APP_SCREENING_TROUBLESHOOT_MAP;
    } else {
        data = ONBOARDING_TROUBLESHOOT_MAP;
    }

    return (
        <React.Fragment>
            <div className='kob-onbtr'>
                <div className='kob-onbtr__desc'>
                    Below is exhaustive list of all onboarding stages. You need to look at
                    troubleshooting details of a stage if you are seeing a failed state above
                    for that stage. Otherwise, you need to look at the subsequent stage details,
                    as user might be stuck in some retriable error on that stage.
                </div>
                <div className='table-sr table-sr--mt0'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th'>Stage</div>
                        <div className='table-sr__th f15Wrapper'>Description</div>
                        <div className='table-sr__th'>Troubleshooting Details</div>
                    </div>
                    <div className='table-sr__tlc kob-onbtable'>
                        {
                            data.map(item => (
                                <div className='table-sr__tlw table-sr__tlw--aic' key={item.stage}>
                                    <div className='table-sr__tl'>{item.stage}</div>
                                    <div className='table-sr__tl table-sr__tl--nc f15Wrapper'>{item.description}</div>
                                    <div
                                        className='link table-sr__tl'
                                        onClick={viewDetailsHandler(item.data)}
                                    >
                                        View Details
                                </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Modal
                isOpen={showModal}
                style={customStylesForModal}
                contentLabel='Onboarding Troubleshoot Modal'
            >
                <div className='kob-modal-hwr'>
                    {`${isInAppScreeningFlow ? 'In-app Screening' : 'Onboarding'} Troubleshoot Details`}
                </div>
                <div className='table-sr table-sr--mt0'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th'>Error shown on Sherlock</div>
                        <div className='table-sr__th'>Onboarding Screen</div>
                        <div className='table-sr__th f15Wrapper'>What user is saying</div>
                        <div className='table-sr__th f15Wrapper'>How to fix</div>
                    </div>
                    <div className='table-sr__tlc kob-onbtr__tlc'>
                        {
                            selectedModalData.map(item => (
                                <div className='table-sr__tlw table-sr__tlw--aic' key={item.reason}>
                                    <div className='table-sr__tl table-sr__tl--nc'>{item.sherlockError}</div>
                                    <div className='table-sr__tl table-sr__tl--nc'>{item.screen}</div>
                                    <div className='table-sr__tl table-sr__tl--nc f15Wrapper'>
                                        {item.appError}
                                    </div>
                                    <div className='table-sr__tl table-sr__tl--nc f15Wrapper'>
                                        {item.resolution}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Button
                    primary
                    extClasses='kob-modal-bwr'
                    label='Close'
                    onClick={viewDetailsHandler([])}
                />
            </Modal>
        </React.Fragment>
    );
};

export default memo(OnboardingTroubleshoot);

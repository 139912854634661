import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
    Button, ExpandableCard, Loader, FormWithSingleInput, Table, KVPairInfo,
} from '../../../components';

import TncAndStepsToRedeemModal from '../Modals/TncAndStepsToRedeem';

import '../style.scss';
import { getTotalRewards, setTotalRewards } from '../actions';
import { makeSelectTotalRewardsList } from '../selectors';

const getTotalRewardsKVData = (rewardDetails) => {
    if (!rewardDetails) return null;

    return [
        {
            label: 'Reward Count',
            value: rewardDetails?.reward_count,
        },
        {
            label: 'Reward Title',
            value: rewardDetails?.rewardInfo?.title,
        },
        {
            label: 'Action Description',
            value: rewardDetails?.rewardInfo?.description,
        },
    ];
};

const TotalRewards = (props) => {
    const [isTotalCardExpanded, setIsTotalCardExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [rewardOfferID, setRewardOfferId] = useState();
    const [errLabel, setErrLabel] = useState();
    const [showTnCModal, setShowTnCModal] = useState();

    const totalRewardsList = useSelector(makeSelectTotalRewardsList(), shallowEqual);

    const { ticketId, userId, index } = props;

    const dispatch = useDispatch();

    let loadingTotalRewards;
    let totalRewardsData;

    if (totalRewardsList && totalRewardsList[ticketId]) {
        ({
            loading: loadingTotalRewards, data: totalRewardsData,
        } = totalRewardsList[ticketId]);
    }

    const makePayloadAndCallApi = () => {
        setIsLoading(true);
        dispatch(getTotalRewards({
            ticketId, userId, index, rewardOfferID, monthYearDate: selectedDate,
        }));
        setIsLoading(false);
    };

    const handleSubmit = () => {
        if (!selectedDate || !rewardOfferID) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        makePayloadAndCallApi(selectedDate, rewardOfferID);

        setErrLabel('');
    };

    const onDateChange = (e) => {
        setSelectedDate(new Date(e));
    };

    const clearAllSelection = () => {
        setSelectedDate();
        setRewardOfferId('');
        dispatch(setTotalRewards({ ticketId, totalRewardsList: null }));
    };

    const onRewardOfferIdChange = (e) => {
        setRewardOfferId(e.target.value);
    };

    return (
        <div className='rewards-cr1'>
            <ExpandableCard
                extClasses='rewards-ec'
                heading='View Total Rewards Generated'
                isExpanded={isTotalCardExpanded}
                setIsExpanded={() => setIsTotalCardExpanded(!isTotalCardExpanded)}
            >
                <div className='rewards-sc'>
                    <div className='frcsbWrapper rewards-sc__gap'>
                        <div className='frcWrapper'>
                            <div className='rewards-dd__label-large'>Select month and year*</div>
                            <ReactDatePicker
                                dateFormat='MMMM yyyy'
                                showMonthYearPicker
                                selected={selectedDate}
                                onChange={onDateChange}
                            />
                        </div>

                        <FormWithSingleInput
                            hideErrLabel
                            disableFormSubmitOnEnter
                            extClasses={{
                                label: 'rewards-dd__label-large',
                            }}
                            label='Reward Offer ID*'
                            formType='text'
                            input={{
                                value: rewardOfferID,
                                onChange: onRewardOfferIdChange,
                            }}
                        />
                    </div>

                    {errLabel ? <div className='err-label rewards-el'>{errLabel}</div> : null}
                    <div className='frcWrapper rewards-sc__3r'>
                        <Button
                            extClasses='rewards-sc__cta'
                            primary
                            label='Search'
                            onClick={handleSubmit}
                        />
                        <div
                            className='link rewards-sc__clear'
                            onClick={clearAllSelection}
                            role='presentation'
                        >
                            Clear All Selection
                        </div>
                    </div>
                </div>
            </ExpandableCard>
            <Loader visible={isLoading || loadingTotalRewards} />
            {
                totalRewardsData && totalRewardsData.reward_type_utilization_and_capping_table
                && (
                    <React.Fragment>
                        <div className='mt-30'>
                            <KVPairInfo
                                data={getTotalRewardsKVData(totalRewardsData)}
                                extClasses={{
                                    contentLabel: 'rewards-kv__cl',
                                    contentValue: 'rewards-kv__cv',
                                }}
                            />
                            <Button
                                link
                                label='View TnC & Steps to Redeem'
                                onClick={() => {
                                    setShowTnCModal(true);
                                }}
                                className='btn-wrapper--primary-v2'
                            />
                        </div>
                        <TncAndStepsToRedeemModal
                            v2
                            visible={showTnCModal}
                            data={totalRewardsData.rewardInfo}
                            toggleHandler={setShowTnCModal}
                        />
                        <Table
                            objKey='header_key'
                            nsrLabel='FD Credit Card Details'
                            labelData={totalRewardsData?.reward_type_utilization_and_capping_table?.columnData}
                            contentData={totalRewardsData?.reward_type_utilization_and_capping_table?.rowData}
                            header={totalRewardsData?.reward_type_utilization_and_capping_table?.tableName}
                            extClasses={{
                                container: 'mt-30',
                            }}
                        />
                    </React.Fragment>
                )
            }
        </div>
    );
};

export default TotalRewards;

/**
 *
 * PictureGuide - Component to show each picture guide in the required script.
 *
 */
import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as Info } from '../../../assets/images/svgs/Information.svg';
import { ReactComponent as Warn } from '../../../assets/images/svgs/WarningError.svg';
import { checkObjectsEquality } from '../../../utils';
import { UserDetailsContext } from '../../../context';
import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';

import { SHERLOCK_V2_ENTRY_POINT } from '../constants';

const PictureGuide = (props) => {
    const { item, scriptId } = props;
    const container = useRef(null);
    const [activeGuide, setActiveGuide] = useState(item?.guides[0]);
    const [height, setHeight] = useState(null);
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);

    const rudderProperties = {
        ticketId: null,
        callId: null,
        entryPoint: SHERLOCK_V2_ENTRY_POINT,
    };

    useEffect(() => {
        // offsetHeight is the height of the container
        // height of the container / number of guides is the length of height that each guide would take.
        // 20% of the per guide height must be set for the images to be shown
        setHeight((container.current.offsetHeight / item.guides.length) * 0.2);
    }, [item]);
    const handleOnStepClick = (guide, index) => () => {
        const property = {
            index: index + 1,
            scriptId,
            ...guide,
            ...rudderProperties,
        };
        pushRudderEvent(RudderEvent.SherlockV2Scripts.ClickedPictureGuideStep, agentInfo.emailId, property);
        setActiveGuide(guide);
    };

    // Sets the Default selected step when we get the script data.
    useEffect(() => {
        setActiveGuide(item?.guides[0]);
    }, [item]);

    const GuideClasses = (guide) => classNames(
        'frcsbWrapper',
        'scripts-guides-cont',
        { 'scripts-guides-cont__active': checkObjectsEquality(activeGuide, guide) },
    );

    const ImageStyles = {
        // taking height as the maximum of height*6 and 400px
        height: height * 6 > 400 ? (height * 6) : 400,
        objectFit: 'scale-down',
        width: '100%',
    };

    return (
        <div className='scripts-guides'>
            <div className='scripts-cc'>
                <div className='frcsbWrapper scripts-guides-title'>
                    <div>
                        <div>{item && item.title}</div>
                        <div className='scripts-des'>{item && item.description}</div>
                    </div>
                    <div>{(item && item.guides && item.guides.length) || 0} steps</div>
                </div>
                <div className='line' />
                <div className='frfssbWrapper'>
                    <div className='scripts-guides-cc' ref={container}>
                        {
                            item?.guides && item?.guides.map((guide, index) => (
                                <div
                                    className={GuideClasses(guide)}
                                    role='presentation'
                                    onClick={handleOnStepClick(guide, index)}
                                >
                                    {/* eslint-disable-next-line react/no-danger */}
                                    <div className='scripts-guides-text' dangerouslySetInnerHTML={{ __html: guide?.instruction }} />
                                    <div>
                                        {
                                            guide?.tip && (
                                                <div className='scripts-guides-tip'>
                                                    <Info />
                                                    <div className='scripts-guides-tip-text'>
                                                        {guide?.tip}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            guide?.warning && (
                                                <div className='scripts-guides-tip'>
                                                    <Warn />
                                                    <div className='scripts-guides-tip-text'>
                                                        {guide?.warning}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='scripts-guides-img'>
                        <img style={ImageStyles} src={activeGuide?.url} alt='img' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PictureGuide;

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper } from '../../utils';
import { AGENT_ROOT } from '../../api/routes';

import {
    GET_ACCESS_LEVEL, SET_ACCESS_LEVEL,
} from './constants';

function* getAccessLevel() {
    const response = yield call(
        [clientApiWrapper, clientApiWrapper.get],
        `${AGENT_ROOT}/access-level`
    );

    yield put({ type: SET_ACCESS_LEVEL, data: response });
}

export default function* agentSaga() {
    yield all(
        [
            yield takeLatest(GET_ACCESS_LEVEL, getAccessLevel),
        ]
    );
}

/**
 * @file CallFlow component to manage the flow for call history before providing any ticket id
 * 
 * Step-wise call flow (single ticket creation flow) -
 * 1. Get Call List
 * 2. Select a Call record from Call List items
 * 3. Get Ticket and User Info by passing ucid details from selected Call record in CX Header
 * 3.a. if response has ticketIdForCall(attached ticket id for selected Call), then direct it to that ticket landing page
 * 3.b. else if response has isUserExistInEpifi as true(user found in epifi db), then show ticket list for selected Call record(ucid)
 * 3.c. else if response has isUserExistInEpifi as false(user not found in epifi db), then ask agent to enter
 *      registered mobile number/email(user id) and Get User Info by passing ucid & user id
 * 3.c.a. Confirm User Name from the user & perform User Authentication
 * 3.c.b. If the screen returned as 10 after performing User Authentication, then show ticket list for selected Call record & user id
 *
 */

import React, { memo, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { makeSelectCreatedTicketInfoForCall } from '../selectors';

import CallListView from './CallListView';
import TicketListForCallView from './TicketListForCallView';
import NewTicketView from './NewTicketView';

import './style.scss';

const CallFlow = (props) => {
    // reducer state variables
    const createdTicketInfoForCall = useSelector(makeSelectCreatedTicketInfoForCall(), shallowEqual);

    // local state variables
    const [showTicketsForCallPage, setTicketsForCallPage] = useState(false);

    // props variables
    const { setTicketIdAndSubmit, setTicketIdInputPage } = props;

    // destructured variables
    const { id: newTicketId } = createdTicketInfoForCall;

    // render newly created ticket info page
    if (newTicketId) {
        return (
            <NewTicketView
                setTicketIdAndSubmit={(ticketId) => {
                    setTicketIdAndSubmit(ticketId);
                    setTicketsForCallPage(false);
                }}
            />
        );
    }

    // render ticket list for call page
    if (showTicketsForCallPage) {
        return (
            <TicketListForCallView
                setTicketIdAndSubmit={setTicketIdAndSubmit}
                setTicketsForCallPage={setTicketsForCallPage}
            />
        );
    }

    // render call list page
    return (
        <CallListView
            setTicketIdInputPage={setTicketIdInputPage}
            setTicketIdAndSubmit={setTicketIdAndSubmit}
            setTicketsForCallPage={setTicketsForCallPage}
        />
    );
};

export default memo(CallFlow);

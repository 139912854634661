/**
 *
 * Main screen for login either with google SSO or email, password.
 *
 */

// external libraries
import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ICONS_URL } from '../../../constants/assets';
// import signinWithGoogleImg from '../../../assets/images/svgs/signin-with-google.svg';
import { Button, FormWithSingleInput } from '../../../components';
import { isFeatureAccessibleInEnvironments, validators } from '../../../utils';

import { loginWithEmailPwd } from '../actions';
import { FORGOT_PASSWORD, UPDATE_AUTH_STATE } from '../constants';

import '../style.scss';
import { DEVELOPMENT, QA, STAGING } from '../../../utils/constants';

const EmailPassword = (props) => {
    const { loginUrl } = props;
    const dispatch = useDispatch();
    // local state
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailErrLabel, setEmailErrLabel] = useState('');
    const [passwordErrLabel, setPasswordErrLabel] = useState('');
    const [webpageType, setWebpageType] = useState('epifi');
    const [isLoginManually, setIsLoginManually] = useState(false);

    useEffect(() => {
        const curWebpageType = document
            .querySelector('meta[name="webpage-type"]')
            ?.getAttribute('content');
        if (curWebpageType) setWebpageType(curWebpageType);
    }, []);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (emailErrLabel) setEmailErrLabel('');
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (passwordErrLabel) setPasswordErrLabel('');
    };
    const validateInput = () => {
        let isValid = true;

        if (!validators.isInputtedEmailValid(email)) {
            setEmailErrLabel('Please enter a valid email ID');
            isValid = false;
        }

        if (!validators.isAWSPasswordValid(password)) {
            setPasswordErrLabel('Please enter a valid password');
            isValid = false;
        }

        return isValid;
    };

    const handleSignIn = () => {
        const valid = validateInput();
        if (!valid) return;
        dispatch(
            loginWithEmailPwd({
                email,
                password,
            }),
        );
    };

    const signInWithGoogle = () => window.location.replace(loginUrl);

    const forgotPassword = () => {
        dispatch({
            type: UPDATE_AUTH_STATE,
            data: {
                newAuthState: FORGOT_PASSWORD,
            },
        });
    };

    const SignInManualComponent = (
        <React.Fragment>
            {/* <div className='login-or login-item'> OR </div> */}
            <div className='login-header login-item--fit'>
                Sign In to your Account
            </div>
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'login-ff-cr',
                    label: 'login-ff-lb',
                    errLabel: 'login-ff-errlb',
                    inputField: 'login-ff-ip1',
                }}
                label='Email ID'
                labelId='login-email'
                input={{
                    value: email,
                    onChange: handleEmailChange,
                }}
                errLabel={emailErrLabel}
                onFormSubmit={handleSignIn}
            />
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'login-ff-cr',
                    label: 'login-ff-lb',
                    errLabel: 'login-ff-errlb',
                    inputField: 'login-ff-ip1',
                }}
                label='Password'
                labelId='login-password'
                input={{
                    value: password,
                    onChange: handlePasswordChange,
                    type: 'password',
                }}
                errLabel={passwordErrLabel}
                onFormSubmit={handleSignIn}
            />
            <div className='login-btn-ctnr'>
                <Button
                    extClasses='login-btn'
                    primary
                    label='Sign In'
                    onClick={handleSignIn}
                />
                <Button
                    extClasses='login-btn'
                    secondary
                    label='Forgot Password?'
                    onClick={forgotPassword}
                />
            </div>

        </React.Fragment>
    );

    return (
        <div className='login-cr1'>
            { webpageType === 'federal' ? (
                SignInManualComponent
            ) : (
                // webpageType === 'epifi'
                <div className='login-backarrow login-item--fit'>
                    <button onClick={() => signInWithGoogle()} type='button' className='login-btn__center'>
                        <img
                            src={ICONS_URL.SIGN_IN_WITH_GOOGLE}
                            height='50'
                            alt='Sign In With Google'
                        />
                    </button>
                    {isFeatureAccessibleInEnvironments([QA, DEVELOPMENT, STAGING])
                    && (
                        <div>
                            <button
                                onClick={() => {
                                    setIsLoginManually(!isLoginManually);
                                }}
                                className='login-secondary'
                                type='button'
                            >
                                {isLoginManually ? 'Close Manual Login' : 'Login Manually'}
                            </button>
                            {isLoginManually && SignInManualComponent}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default memo(EmailPassword);

/**
 * @file B2BOnboarding
 * Whitelisting users for B2B Onboarding using phone number or email id
 */

import React, { memo, useState } from 'react';

import { Button, LoaderOrError, RadioButtonList } from '../../../components';
import { SALARY_WHITELIST_B2B_ONBOARDING_ENDPOINT } from '../../../api/routes';
import { clientApiWrapper, getErrMsg, showErrorToastMsg, toastify } from '../../../utils';

import {
    DISPLAY_TEXT_MAP, FIELD_TYPES, MODE_TYPES_INFO, MODE_TYPES_LIST,
} from '../constants';
import TextInput from '../TextInput';
import FileInput from '../FileInput';

import './style.scss';

const RenderCurView = (props) => {
    const {
        setLoading, setAPIMessage, setError,
    } = props;

    const [displayText, setDisplayText] = useState('');
    const [isCSV, setIsCSV] = useState(false);
    const [validInput, setValidInput] = useState(false);
    const [modeType, setModeType] = useState('');
    const [input, setInput] = useState('');
    const [employerId, setEmployerId] = useState('');
    const [csv, setCSV] = useState('');
    const [allowBiometric, setAllowBioMetric] = useState(false);
    /* For adding ticket id in payload */
    /* const [ticketId, setTicketId] = useState('');
    const handleTicketIdChange = (e) => {
        setTicketId(e.target.value);
    }; */

    const handleModeChange = (newValue) => {
        setModeType(newValue);
        setInput('');

        // Changing UI based on mode type
        if (newValue === MODE_TYPES_INFO.PHONE_NUMBER.label) {
            setDisplayText(DISPLAY_TEXT_MAP.phoneNumber);
            setIsCSV(false);
        } else if (newValue === MODE_TYPES_INFO.EMAIL_ID.label) {
            setDisplayText(DISPLAY_TEXT_MAP.email);
            setIsCSV(false);
        } else if (newValue === MODE_TYPES_INFO.CSV.label) {
            setDisplayText('');
            setIsCSV(true);
        } else {
            setDisplayText('');
            setIsCSV(false);
        }
    };

    const handleSubmitClick = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                employerId,
            };

            /* For adding ticket id in payload */
            /* if (!ticketId) {
                setError('Please enter ticket id');
                return;
            }

            payload.ticketId = ticketId; */

            // Sending payload according to mode type
            if (modeType === MODE_TYPES_INFO.PHONE_NUMBER.label) {
                payload.phoneNumbers = input;
            } else if (modeType === MODE_TYPES_INFO.EMAIL_ID.label) {
                payload.emailIds = input;
            } else if (modeType === MODE_TYPES_INFO.CSV.label) {
                if (!csv) {
                    setError('Please upload a CSV file');
                    return;
                }
                payload.csvFile = csv;
            } else {
                setError('Please select a mode type');
                return;
            }

            payload.enableBiometricKyc = allowBiometric;
            setLoading(true);
            setError('');
            const response = await clientApiWrapper.post(`${SALARY_WHITELIST_B2B_ONBOARDING_ENDPOINT}`, { payload });

            if (response.status) {
                if (response.status.code === 0) {
                    toastify(response.status?.short_message || 'Successfully Uploaded', 'success');
                } else {
                    setAPIMessage(response.status.short_message || 'Error while uploading');
                    toastify(response.status.short_message || 'Error while uploading', 'error');
                }
            } else {
                toastify('Error while uploading', 'error');
                setAPIMessage('Error while uploading');
            }

            setTimeout(() => {
                setAPIMessage('');
            }, 10000);
        } catch (error) {
            showErrorToastMsg(error);
            setError(getErrMsg(error));
        } finally {
            setLoading(false);
            setInput('');
            setEmployerId('');
            setCSV(null);
            setAllowBioMetric(false);
        }
    };

    const renderAllowBiometricCheck = () => (
        <div className='b2b-fc__ip mb-30'>
            <input
                type='checkbox'
                checked={allowBiometric}
                onChange={() => setAllowBioMetric(!allowBiometric)}
            />
            <span className='ml-15'>Also allow biometric KYC during onboarding</span>
        </div>
    );

    return (
        <div className='fcWrapper b2b-cr'>
            <div className='my-30'>Please ensure that whitelisting is done only post validation of user&apos;s employment</div>
            {/* For adding ticket id in payload */
            /* <FormWithSingleInput
                label='Enter ticket id*'
                labelId='ticket-id'
                input={{
                    value: ticketId,
                    onChange: handleTicketIdChange,
                }}
                extClasses={{
                    container: 'b2b-fc-id mb-30',
                    label: 'b2b-fc-lb',
                }}
                hideErrLabel
            /> */}
            <div className='mb-30'>
                <div className='mb-15'>Select mode for whitelisting user</div>
                <RadioButtonList
                    dataList={MODE_TYPES_LIST}
                    listName='mode-type-list'
                    setSelectedData={handleModeChange}
                    extClasses={{
                        container: 'of-auto b2b-rbcont',
                        itemContainer: '',
                    }}
                />
            </div>
            <TextInput
                input={employerId}
                setInput={setEmployerId}
                fieldType={FIELD_TYPES.INPUT}
                htmlfor='display'
                displayText={DISPLAY_TEXT_MAP.employerId}
                setValidInput={setValidInput}
                extClasses={{
                    container: 'mb-15',
                    label: 'b2b-fc__lb',
                    input: 'b2b-fc__tip',
                }}
            />
            {isCSV ? (
                <React.Fragment>
                    <FileInput
                        csv={csv}
                        setCSV={setCSV}
                        setErr={setError}
                        setValidInput={setValidInput}
                        extClasses={{
                            container: 'b2b-upload-csv-fc mb-30',
                            csv: 'b2b-upload-csv-fc__ip',
                            sample: 'link b2b-upload-csv-sample-link',
                            aside: 'b2b-upload-csv-fc__ip-aside',
                            remove: 'b2b-upload-csv-fc__ip-remv',
                        }}

                    />
                    {renderAllowBiometricCheck()}

                </React.Fragment>
            ) : (
                <React.Fragment>
                    <TextInput
                        input={input}
                        setInput={setInput}
                        modeType={modeType}
                        fieldType={FIELD_TYPES.TEXTAREA}
                        htmlfor='display'
                        displayText={displayText}
                        setValidInput={setValidInput}
                        extClasses={{
                            container: 'mb-15',
                            label: 'b2b-fc__lb',
                            textarea: 'b2b-fc__ip mt-15',
                        }}
                    />
                    {displayText !== '' && (
                        renderAllowBiometricCheck()
                    )}
                </React.Fragment>
            )}
            <Button
                v2
                primary
                disabled={!validInput}
                onClick={handleSubmitClick}
                label='Submit'
                extClasses='b2b-btn frccWrapper'
            />
        </div>
    );
};

const B2BOnboarding = () => {
    const [loading, setLoading] = useState(false);
    const [apiMessage, setAPIMessage] = useState('');
    const [error, setError] = useState('');

    return (
        <div className='salwhitelist-ci'>
            <RenderCurView
                setLoading={setLoading}
                setAPIMessage={setAPIMessage}
                setError={setError}
            />
            {apiMessage && <div className='b2b-res-msg salops-err'>{apiMessage}</div>}
            <LoaderOrError
                loading={loading}
                errorLabel={error}
            />
        </div>
    );
};

export default memo(B2BOnboarding);

/**
 * @file react data grid utils
 */

import { CREDIT, DEBIT } from '../constants';

/**
 * @example
 *  object exmaple
 * data = {foo:{cat:{sound:'meow'}}}
 * key  = foo.cat.sound
 * getObjectValue(data,key) // 'meow'
 *
 * @example
 * array example
 * data = {foo:[{cat:{sound:'meow'}}]}
 * key  = foo.0.cat.sound
 * getObjectValue(data,key) // 'meow'
 *
 * @param {object | array} data - array or object
 * @param {string} key - path to get the value
 */
export const getObjectValue = (data, key = '') => {
    const keyArr = key.split('.');
    let value = data;

    for (let i = 0; i < keyArr.length; i += 1) {
        if (keyArr[i] in value) {
            value = value[keyArr[i]];
        } else {
            return undefined;
        }
    }

    return value;
};

/**
 *  Compare two value and return -1, 0, 1
 * @param {string|number} a
 * @param {string|number} b
 * @returns {0|1|-1}
 */
export function compare(a, b) {
    // convert non numeric values to lowercase string
    const aStr = typeof a !== 'number' ? (a ?? '').toString().toLowerCase() : a;
    const bStr = typeof b !== 'number' ? (b ?? '').toString().toLowerCase() : b;

    if (aStr > bStr) return 1;

    if (aStr < bStr) return -1;

    return 0;
}

/**
 * Multilevel sort
 * @param {Object[]} objects - array to be sorted
 * @param {({columnKey:string,direction:'ASC'|'DESC'} | string)[]} keys - array of column keys and sort directions or array of column keys
 * @param {'ASC'|'DESC'} defaultDirection -default sort direction
 * @returns {Object[]} sorted array
 */
export function multiLevelSort(objects, keys, defaultDirection = 'ASC') {
    if (!objects || !objects.length) {
        return objects;
    }
    //
    const isObjectArrayType = typeof objects[0] === 'object';

    // return the same object when columns keys not found if isObjectArrayType is true
    if ((!keys || !keys.length) && isObjectArrayType) return objects;

    // sorted array using Array sort method
    return objects.sort((a, b) => {
        let res = 0; // default to equal

        if (isObjectArrayType) { // enters if it's an array of object
            for (let i = 0; i < keys.length; i += 1) {
                if (res !== 0) return res;

                const value = keys[i];
                const key = value?.columnKey || value; // setting key
                const direction = (value?.direction || defaultDirection) === 'ASC' ? 1 : -1; // setting direction in number
                const aVal = getObjectValue(a, key);
                const bVal = getObjectValue(b, key);

                if (aVal == null) return 1; // Move items with null firstName to the end

                if (bVal == null) return -1; // Move items with null firstName to the end

                res = compare(aVal, bVal) * direction;
            }
        } else { // enters if it's a none object array example string or number array
            const direction = defaultDirection === 'ASC' ? 1 : -1;

            if (a == null) return 1; // Move items with null firstName to the end

            if (b == null) return -1; // Move items with null firstName to the end

            res = compare(a, b) * direction;
        }
        return res;
    });
}

/**
 * Separate the compare operators from the value
 * @param {string} value
 * @returns {{searchString:string}|{searchString:string,symbol:string}}
 */
export function extractAndRemoveSymbol(value = '') {
    const regex = /^(>=|<=|<|>|=)/;
    const match = value.match(regex);

    if (match) {
        const symbol = match[0];
        const result = value.replace(new RegExp(`^${symbol}`), '');

        return { searchString: result, symbol };
    }

    return { searchString: value };
}

/**
 * compare num1 and num2 based on symbols (compare operators)
 * @param {*} num1
 * @param {*} num2
 * @param {*} symbol
 * @returns {boolean}
 */
export function performOperation(num1, num2, symbol) {
    switch (symbol) {
        case '<':
            return num1 < num2;
        case '>':
            return num1 > num2;
        case '<=':
            return num1 <= num2;
        case '>=':
            return num1 >= num2;
        case '=':
            return num1 === num2;
        default:
            return false;
    }
}

/**
 * check for type as 'date' and value as number
 * @param {*} type
 * @param {*} value
 * @returns {boolean}
 */
const checkDateTypeAndValue = (type, value) => type === 'date' && typeof +value === 'number';

/**
 * multilevel filter
 * @param {Object[]} data
 * @param {*} filter - filter object, column key and filter value pairs
 * @returns {Object[]}
 */
export const multiLevelFilter = (data, filter = {}) => {
    // filtering valid string values
    const values = Object.values(filter).filter((item) => !!item);

    if (values.length) {
        const keys = Object.keys(filter); // get filter keys

        return data.filter((item) => {
            let flag = true;

            // looping all the filter key to check for atmost matches
            for (let i = 0; i <= keys.length; i += 1) {
                const key = keys[i]; // filter key
                const filterData = filter[key]; // filter string
                const sString = (filterData?.value || filterData || '').trim().toLowerCase(); // covert filter string to lower case
                const { searchString, symbol } = extractAndRemoveSymbol(sString); // extracting symbols and filter string if any
                const value = (item[key] || '').toString().toLowerCase(); // convert value to lower case

                if (checkDateTypeAndValue(filterData?.type, value)) { // enters if it's a date type
                    const d = new Date(+value);

                    if (!d.toLocaleString().includes(searchString)) {
                        flag = false;
                    }
                } else if (symbol && searchString) { // enters if it contain symbols and filter string
                    flag = performOperation(parseFloat(value), parseFloat(searchString), symbol);
                } else if (!value.includes(searchString)) {
                    flag = false;
                }

                if (flag === false) break;
            }

            return flag;
        });
    }

    return [...data];
};

/**
 * Get Credit (+) / Debit (-) symbols
 * @param {*} type
 * @returns {string}
 */
export const getCreditDebitSymbol = (type) => {
    if (type === CREDIT) {
        return '+';
    }
    if (type === DEBIT) {
        return '-';
    }
    return '';
};

/**
 * Persist data in local storage
 * @param {string} key
 * @param {object | array} data
 * @returns {void}
 */
export const persistData = (key, data) => localStorage.setItem(key, JSON.stringify(data));

/**
 * Get persisted data from local storage
 * @param {string} key
 * @param {object | array} defaultvalue
 * @returns {object | array}
 */
export const getPersistedData = (key, defaultvalue = {}) => {
    try {
        return JSON.parse(localStorage.getItem(key)) || defaultvalue;
    } catch {
        return defaultvalue;
    }
};

/**
 * Clear persisted data from local storage
 * @param {*} key
 */
export const clearPersistedData = (key) => {
    localStorage.removeItem(key);
};

/**
 * Get Formatted Date for Transaction V2 Table
 * @param {string | number | Date} value
 * @param {'date'| 'month' | 'year' | undefined } flag
 * @returns {string} Formatted Date
 */
export const getDateforTxnTable = (value, flag) => {
    const date = new Date(value);

    if (flag) {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

        if (flag === 'month' || flag === 'year') { // removing day for month and year
            delete options.day;
        }

        if (flag === 'year') { // removing month for year
            delete options.month;
        }

        // eg: (flag = 'date') => 27/06/2023
        // eg: (flag = 'month') => 06/2023
        // eg: (flag = 'year') => 2023
        return date.toLocaleString(undefined, options);
    }

    return date.toLocaleString(); // eg: => 27/06/2023, 11:40:56
};

/**
 *
 * ViewSOP - Component to show all the available SOPs.
 *
 */

import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as GridDuotone } from '../../../assets/images/svgs/grid-duotone.svg';
import { LoaderOrError } from '../../../components';

import { SEARCH_PATH } from '../../AppV2/routes';

import { getL1CategoriesAction } from '../../AppV2/actions';
import { selectL1CategoriesData } from '../../AppV2/selectors';

const ViewSOP = () => {
    const dispatch = useDispatch();
    const scriptsData = useSelector(selectL1CategoriesData, shallowEqual);

    let loading;
    let err;
    let L1List;

    if (scriptsData) {
        ({ loading, err, L1List } = scriptsData);
    }

    useEffect(() => {
        if (!L1List || Object.keys(L1List).length === 0) dispatch(getL1CategoriesAction({ screenKey: ['L1Categories'] }));
    }, []);

    return (
        <div className='scripts-cont__v2'>
            <div>
                <div className='scripts-heading'>View by categories</div>
                <div className='frwpWrapper'>
                    {
                        L1List && Array.isArray(L1List) && L1List?.map((category) => (
                            <Link
                                to={(location) => (
                                    {
                                        pathname: SEARCH_PATH,
                                        search: `?l1=${category?.value}&type=SEARCH_RESULT_TYPE_SOP`,
                                        state: { prevPath: location.pathname },
                                    }
                                )}
                                className='frcWrapper scripts-card'
                                key={category?.label}
                            >
                                <div><GridDuotone /></div>
                                <div className='ml-15'>{category?.label}</div>
                            </Link>
                        ))
                    }
                </div>
            </div>
            <LoaderOrError loading={loading} errorLabel={err} />
        </div>
    );
};

export default ViewSOP;

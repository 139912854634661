/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
/**
 *
 * DevActions Form V2
 *
 */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, FormDropdown, FormWithSingleInput } from '../../../components';
import {
    // injectReducer, injectSaga,
    getRequiredFieldsFilledFlag,
    onFileClick, onFileChange,
} from '../../../utils';
import { SUPPORTED_FILE_TYPES } from '../../../utils/fileUploadEvents';
import { useMemoizedSelector } from '../../../hooks';

import {
    setParameterValueV2,
} from '../actions';
import {
    makeSelectParameterList,
    selectConditionOptionMap, selectUserResponseV2,
} from '../selectors';
import { COPY_REMARKS_TO_REASON_ACTIONS, NO_MAX_DATE_DEV_ACTIONS, fieldType } from '../constants';

import '../style.scss';

const FormV2 = (props) => {
    const {
        containerKey, validateActions, setExecuteInfoModal, selectedAction, setFormV2payload,
    } = props;

    const dispatch = useDispatch();
    const parameterList = useMemoizedSelector(makeSelectParameterList, [containerKey]);
    const ConditionOptionsMap = useMemoizedSelector(selectConditionOptionMap, [containerKey]);
    const [formErrLabel, setFormErrLabel] = useState('');
    const [fileUploadErrLabel, setFileUploadErrLabel] = useState('');

    const UserResponseV2 = useMemoizedSelector(selectUserResponseV2, [containerKey]);

    const reasonItem = parameterList.find((item) => item.type === fieldType.REASON);

    const getConditionalFieldUi = (item, map) => {
        const { value = '' } = UserResponseV2[item.name] || {};
        if (!map && !value) return null;

        if (value && map && map[value]) {
            return (
                <div>
                    {
                        map[value]
                            .map((field) => (
                                <div>
                                    <div>
                                        {
                                            field.type !== fieldType.PAGE_CONTEXT && (
                                                <div className='devactions-sc-owr' key={field.label}>
                                                    <div className='devactions-sc-owr__ol'>{`${field.label}${!field.isOptional ? '*' : ''}`}</div>
                                                    <div className='devactions-sc-owr__ov'>
                                                        {renderParameterValue(
                                                            field,
                                                            { isConditional: true, parentName: item.name, parentValue: value },
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div>{field.type === fieldType.DROPDOWN && getConditionalFieldUi(field, ConditionOptionsMap[field.name])}</div>
                                </div>
                            ))
                    }
                </div>
            );
        }

        return null;
    };

    const showExecuteConfirmationModal = (e) => {
        e.preventDefault();

        const hasAllRequiredFieldsFilled = getRequiredFieldsFilledFlag(parameterList);

        if (!hasAllRequiredFieldsFilled) {
            setFormErrLabel('Please fill all the * marked fields');
            return;
        }

        const error = validateActions && validateActions(parameterList);

        if (error && !error.status) {
            setFormErrLabel(error.message);
            return;
        }

        setFormErrLabel('');
        setExecuteInfoModal(true);
    };

    const handleInput = ({
        item, currentValue, key, conditionalObject,
    }) => {
        const payload = { value: {} };

        switch (item.type) {
            case fieldType.TIMESTAMP: {
                payload.value = {
                    item, value: currentValue, containerKey, conditionalObject,
                };
                break;
            }

            case fieldType.DROPDOWN: {
                // modify the option payload based on a single select option request

                payload.value = {
                    item, value: currentValue, containerKey, conditionalObject,
                };

                break;
            }

            case fieldType.NAME: {
                payload.value = {
                    item, containerKey, value: currentValue, key, conditionalObject,
                };
                break;
            }

            case fieldType.MULTI_SELECT_DROPDOWN: {
                payload.value = {
                    item, value: currentValue, containerKey, conditionalObject,
                };
                break;
            }

            case fieldType.MULTILINE_TEXT:
                payload.value = {
                    item, value: currentValue, containerKey, conditionalObject,
                };
                break;

            case fieldType.STRING: {
                payload.value = {
                    item, value: currentValue, containerKey, conditionalObject,
                };

                // HACK: For particular dev actions, copy text from 'remarks' field to 'reason' field
                if (COPY_REMARKS_TO_REASON_ACTIONS.includes(selectedAction) && item.name === 'Remarks') {
                    dispatch(setParameterValueV2({
                        item: reasonItem, value: currentValue, containerKey, conditionalObject,
                    }));
                }
                break;
            }

            default: {
                payload.value = {
                    item, value: currentValue, containerKey, conditionalObject,
                };
            }
        }

        if (payload.value) dispatch(setParameterValueV2(payload.value));
    };

    const handleFileUpload = ({
        item, conditionalObject,
    }) => {
        const setValue = (value) => {
            dispatch(setParameterValueV2({
                item,
                value,
                containerKey,
                conditionalObject,
            }));
        };

        const fileParams = {
            item,
            setErrLabel: setFileUploadErrLabel,
            setValue,
            conditionalObject,
        };

        onFileChange(fileParams);
    };

    const renderParameterValue = (item, conditionalObject = null) => {
        switch (item.type) {
            case fieldType.TIMESTAMP: {
                let maxDate = new Date();
                let showYearDropdown = false;

                if (NO_MAX_DATE_DEV_ACTIONS.includes(selectedAction)) {
                    maxDate = null;
                    showYearDropdown = true;
                }

                const { value = '' } = UserResponseV2[item.name] || {};

                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'devactions-fc--m0',
                            inputField: 'devactions-fc__ip',
                        }}
                        labelId={item.name}
                        formType='dob'
                        input={{
                            value,
                            disabled: item.isDisabled,
                            onChange: (val) => {
                                handleInput({ item, currentValue: val, conditionalObject });
                            },
                            maxDate,
                            minDate: new Date('10-01-2020'),
                            showYearDropdown,
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }

            case fieldType.DROPDOWN: {
                // modify the option payload based on a single select option request
                const options = item.options?.map((option) => ({
                    label: option,
                    value: option,
                }));

                const { value = '' } = UserResponseV2[item.name] || {};

                // modify the selected option payload based on a single select option request
                const selectedValue = value ? {
                    label: value,
                    value,
                } : null;

                return (
                    <div>
                        <div><FormDropdown
                            options={options}
                            input={{
                                defaultMenuIsOpen: item.isDDOpen,
                                value: selectedValue,
                                disabled: item.isDisabled,
                                onChange: ({ value: selectValue }) => {
                                    handleInput({ item, currentValue: selectValue, conditionalObject });
                                },
                            }}
                            cacheKey='devactions-single'
                            extStyles={{
                                container: 'devactions-dd',
                            }}
                        />
                        </div>
                    </div>
                );
            }

            case fieldType.NAME: {
                const { value = '' } = UserResponseV2[item.name] || {};
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'devactions-fc--m0',
                        }}
                        labelId={item.name}
                        formType='name'
                        input={{
                            value,
                            disabled: item.isDisabled,
                            onChange: (key) => (e) => {
                                handleInput({
                                    item, currentValue: e.target.value, key, conditionalObject,
                                });
                            },
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }

            case fieldType.MULTI_SELECT_DROPDOWN: {
                const { value = '' } = UserResponseV2[item.name] || {};
                return (
                    <FormDropdown
                        isMulti
                        options={item.options}
                        input={{
                            value,
                            disabled: item.isDisabled,
                            onChange: (eventValue) => {
                                handleInput({ item, currentValue: eventValue, conditionalObject });
                            },
                        }}
                        cacheKey='devactions'
                        extStyles={{
                            container: 'devactions-dd',
                        }}
                    />
                );
            }

            case fieldType.MULTILINE_TEXT: {
                const { value = '' } = UserResponseV2[item.name] || {};
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'devactions-fc--m0',
                            inputField: 'devactions-fc__ip devactions-fc__rsv',
                        }}
                        labelId={item.name}
                        formType='textarea'
                        input={{
                            autosize: true,
                            value,
                            disabled: item.isDisabled,
                            onChange: (e) => {
                                handleInput({ item, currentValue: e.target.value, conditionalObject });
                            },
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }

            case fieldType.STRING: {
                const { value: selectedValue = '' } = UserResponseV2[item.name] || {};

                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'devactions-fc--m0',
                            inputField: 'devactions-fc__ip',
                        }}
                        labelId={item.name}
                        input={{
                            value: selectedValue,
                            disabled: item.isDisabled,
                            onChange: (e) => {
                                const { value } = e.target;
                                handleInput({ item, currentValue: value });
                                // HACK: For particular dev actions, copy text from 'remarks' field to 'reason' field
                                if (COPY_REMARKS_TO_REASON_ACTIONS.includes(selectedAction) && item.name === 'Remarks') {
                                    handleInput({ item: reasonItem, currentValue: value, conditionalObject });
                                }
                            },
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }

            case fieldType.FILE:
            case fieldType.FILE_LIST: {
                const setValue = (value) => {
                    handleFileUpload({ item: reasonItem, currentValue: value, conditionalObject });
                };

                const fileParams = {
                    item,
                    setErrLabel: setFileUploadErrLabel,
                    setValue,
                };

                const { type } = item;

                return (
                    <div className='fcWrapper'>
                        <input
                            type='file'
                            className='devactions-fc__ip'
                            accept={SUPPORTED_FILE_TYPES}
                            onClick={onFileClick(fileParams)}
                            onChange={onFileChange(fileParams)}
                            multiple={type === 12}
                        />
                        {
                            fileUploadErrLabel ? (
                                <div className='err-label devactions-fuel'>{fileUploadErrLabel}</div>
                            ) : null
                        }
                    </div>
                );
            }

            default: { // INTEGER, DOUBLE, MONEY, PHONE_NUMBER, REASON
                const { value: selectedValue = '' } = UserResponseV2[item.name] || {};
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'devactions-fc--m0',
                            inputField: 'devactions-fc__ip',
                        }}
                        labelId={item.name}
                        input={{
                            value: selectedValue,
                            disabled: item.isDisabled,
                            onChange: (e) => {
                                handleInput({ item, currentValue: e.target.value, conditionalObject });
                            },
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }
        }
    };
    const validateNestedFields = (fields) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const field of fields) {
            const {
                isOptional, type, name,
            } = field;

            const { value } = UserResponseV2[name] || { [name]: { value: null } };
            if (!isOptional && !value) return false;
            if (type === fieldType.DROPDOWN && ConditionOptionsMap[name] && ConditionOptionsMap[name][value]
                    && !validateNestedFields(ConditionOptionsMap[name][value])) {
                return false;
            }
        }
        return true;
    };

    const sanitizePayload = () => {
        const payload = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const [name, response] of Object.entries(UserResponseV2)) {
            const {
                conditionalObject, value, type, ...rest
            } = response;
            const { parentName, parentValue } = conditionalObject || {};

            if (!conditionalObject || (parentName && parentValue
                 && UserResponseV2[parentName]
                  && UserResponseV2[parentName].value === parentValue)) {
                payload[name] = {
                    value, type, ...rest,
                };
            }
        }

        return payload;
    };

    const checkRequiredFieldV2 = (e) => {
        e.preventDefault();
        if (Array.isArray(parameterList)) {
            // eslint-disable-next-line no-restricted-syntax
            for (const field of parameterList) {
                const {
                    isOptional, type, name,
                } = field;
                const { value } = UserResponseV2[name] || { [name]: { value: null } };
                if (!isOptional && !value) {
                    setFormErrLabel('Please fill all the * marked fields');
                    return;
                }

                if (type === fieldType.DROPDOWN && ConditionOptionsMap[name] && ConditionOptionsMap[name][value]
                    && !validateNestedFields(ConditionOptionsMap[name][value])) {
                    setFormErrLabel('Please fill all the * marked fields');
                    return;
                }
            }
        }
        const error = validateActions && validateActions(parameterList);
        if (error && !error.status) {
            setFormErrLabel(error.message);
            return;
        }

        setFormErrLabel('');
        setExecuteInfoModal(true);
        const payload = sanitizePayload();
        setFormV2payload(payload);
    };

    if (parameterList.length === 0) return null;

    return (
        <React.Fragment>
            <div className='devactions-sc-ocr'>
                <div className='devactions-sc-ocr__hl'>Search Parameters</div>
                {
                    parameterList
                        .map((item) => (
                            <div>
                                {
                                    item.type !== fieldType.PAGE_CONTEXT && (
                                        <div>
                                            <div className='devactions-sc-owr' key={item.label}>
                                                <div className='devactions-sc-owr__ol'>{` ${item.label}${!item.isOptional ? '*' : ''}`}</div>
                                                <div className='devactions-sc-owr__ov'>
                                                    {renderParameterValue(item)}
                                                </div>
                                            </div>
                                            {item.type === fieldType.DROPDOWN && getConditionalFieldUi(item, ConditionOptionsMap[item.name])}
                                        </div>
                                    )
                                }

                            </div>
                        ))
                }
            </div>
            {formErrLabel ? <div className='err-label devactions-el'>{formErrLabel}</div> : null}
            <Button
                v2
                extClasses={`devactions-sc-cta ${formErrLabel && 'devactions-sc-cta--err'}`}
                primary
                label='Submit'
                onClick={checkRequiredFieldV2}
            />
        </React.Fragment>
    );
};

export default FormV2;

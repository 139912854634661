/**
 * @file Kyc details component to list all the kyc attempts
 *  Re-using the VKycAttemptList component from KycOnboarding
 */
import React from 'react';

import { Loader } from '../../../components';

import VKycAttemptList from '../../KycOnboarding/VKycAttemptList';

import { IS_CREDIT_CARD_KYC_SCREEN } from '../constants';
import CardBanner from '../CardBanner';

const KycDetailsScreen = (props) => {
    const { data, ticketId } = props;

    const {
        vkycInfo: kycInfo, loading, hasVKycInfo = false, err,
    } = data;

    if (loading) {
        return <Loader visible={loading} />;
    }

    return (
        <CardBanner title='KYC Details' discardCurrentScreen={IS_CREDIT_CARD_KYC_SCREEN} ticketId={ticketId}>
            {
                hasVKycInfo
                    ? (
                        <VKycAttemptList
                            data={kycInfo && kycInfo.attemptList}
                            customerData={kycInfo && kycInfo.customerInfoList}
                        />
                    )
                    : <div className='error-label1 wspace-pre-wrap'>{err}</div>
            }
        </CardBanner>
    );
};

export default KycDetailsScreen;

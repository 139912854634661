/**
 * @file UpdateTicketIdModal
 * Update the ticket ID for a particular dispute
 */

import React, { memo, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';

import { Button, FormWithSingleInput } from '../../../components';

import { updateDisputesInfoAction } from '../actions';
import { DEFAULT_STYLES_FOR_MODAL } from '../constants';

const customStylesForModal = {
    overlay: {
        ...DEFAULT_STYLES_FOR_MODAL.overlay,
    },
    content: {
        ...DEFAULT_STYLES_FOR_MODAL.content,
        width: 500,
        height: 300,
    },
};

const UpdateTicketIdModal = (props) => {
    const {
        visible, toggleHandler, data, setData,
    } = props;

    const dispatch = useDispatch();

    const [formTicketId, setFormTicketId] = useState('');
    const [errLabel, setErrLabel] = useState('');

    const closeModal = () => {
        setData({});
        toggleHandler(false);
        setErrLabel('');
        setFormTicketId('');
    };

    const handleInputChange = (e) => {
        if (errLabel) setErrLabel('');

        setFormTicketId(e.target.value);
    };

    const handleSubmitClick = () => {
        if (!formTicketId) {
            setErrLabel('Please enter a ticket ID');
            return;
        }

        const payload = {
            disputeId: data.id,
            fieldMaskList: ['FIELD_MASK_TICKET_ID'],
            ticketId: formTicketId,
        };

        const { tableIndex } = data;
        dispatch(updateDisputesInfoAction({ payload, ticketId: data.ticket_id, tableIndex }));

        closeModal();
    };

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='Update Ticket ID'
        >
            <div>
                <FormWithSingleInput
                    label='Enter Ticket ID'
                    labelId='disputes-update-ticket-id'
                    input={{
                        type: 'number',
                        value: formTicketId,
                        onChange: handleInputChange,
                    }}
                    extClasses={{
                        container: 'disputes-fc',
                        inputField: 'disputes-dd1',
                        wrapper: 'disputes-dd__wr',
                        label: 'disputes-dd__lb1',
                        errLabel: 'disputes-el',
                    }}
                    errLabel={errLabel}
                />
            </div>
            <div className='frWrapper'>
                <Button
                    v2
                    extClasses='disputes-modal-cta'
                    primary
                    label='Submit'
                    onClick={handleSubmitClick}
                />
                <Button
                    v2
                    secondary
                    extClasses='disputes-modal-cta'
                    label='Close'
                    onClick={closeModal}
                />
            </div>
        </Modal>
    );
};

export default memo(UpdateTicketIdModal);

/**
 *
 * Liveness Video
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import {
    Button, ExpandableCard, FormWithSingleInput, Loader, MonorailIdInput, HorizontalIdNavbar,
} from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import {
    getLivenessVideoURL, setMonorailId,
} from './actions';
import {
    makeSelectLoading, makeSelectErr, makeSelectVideoUrl, makeSelectMonorailInfo,
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const LivenessVideo = () => {
    const dispatch = useDispatch();
    const actorIdRef = useRef(null);

    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const videoUrl = useSelector(makeSelectVideoUrl(), shallowEqual);
    const monorailInfo = useSelector(makeSelectMonorailInfo(), shallowEqual);

    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(false);
    const [formErrLabel, setFormErrLabel] = useState('');
    const [actorId, setActorId] = useState('');
    const [requestId, setRequestId] = useState('');

    const { id: monorailId } = monorailInfo;

    // set new ticket to true on initial render or when no monorail id present
    const [newTicket, setNewTicket] = useState(!monorailId);
    // set navbar items to empty array on initial render or when no monorail id present
    const [navItems, setNavItems] = useState(monorailId && [{ id: monorailId }] || []);

    useEffect(() => {
        if (monorailId && isSearchCardExpanded) {
            actorIdRef.current.focus();
        }
    }, [monorailId, isSearchCardExpanded]);

    const resetMonorailId = () => {
        setNewTicket(true);
        setNavItems([]);
        dispatch(setMonorailId({ value: '' }));
    };

    const handleMonorailIdSubmit = (value) => {
        setActorId('');
        setRequestId('');
        setIsSearchCardExpanded(true);
        setNewTicket(false);
        setNavItems([{ id: value }]);
        dispatch(setMonorailId({ value }));
    };

    const renderIdNavbar = (
        <HorizontalIdNavbar
            isFixed
            hasSingleItem
            isNewTicketActive={newTicket}
            activeId={monorailId}
            navItems={navItems}
            onItemCloseIconClick={resetMonorailId}
        />
    );

    if (!monorailId) {
        return (
            <React.Fragment>
                {renderIdNavbar}
                <MonorailIdInput submitMonorailId={handleMonorailIdSubmit} />
            </React.Fragment>
        );
    }

    const handleInputChange = key => event => {
        const value = event.target.value;

        switch (key) {
            case 'actorId':
                setActorId(value);
                break;
            
            case 'requestId':
                setRequestId(value);
                break;

            default:
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const modifiedActorId = actorId.trim();
        const modifiedRequestId = requestId.trim();

        if (!modifiedActorId || !modifiedRequestId) {
            setFormErrLabel('Please fill all the * marked fields');
            return;
        }

        setFormErrLabel('');
        dispatch(getLivenessVideoURL({
            monorailId,
            actorId: modifiedActorId,
            requestId: modifiedRequestId,
        }));
        setIsSearchCardExpanded(false);
    };

    return (
        <React.Fragment>
            {renderIdNavbar}
            <div className='lvideo-cr'>
                <ExpandableCard
                    extClasses='lvideo-ec'
                    heading='Search for Liveness Video'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='lvideo-sc'>
                        <FormWithSingleInput
                            hideErrLabel
                            disableFormSubmitOnEnter
                            extClasses={{
                                container: 'lvideo-fc--m0',
                                label: 'lvideo-fc__lb',
                                inputField: 'lvideo-fc__ip',
                            }}
                            label='Actor Id*'
                            labelId='actor-id'
                            input={{
                                value: actorId,
                                onChange: handleInputChange('actorId'),
                                ref: actorIdRef,
                            }}
                            onFormSubmit={handleSubmit}
                        />
                        <FormWithSingleInput
                            hideErrLabel
                            disableFormSubmitOnEnter
                            extClasses={{
                                container: 'lvideo-fc--m0',
                                label: 'lvideo-fc__lb',
                                inputField: 'lvideo-fc__ip',
                            }}
                            label='Request Id*'
                            labelId='request-id'
                            input={{
                                value: requestId,
                                onChange: handleInputChange('requestId')
                            }}
                            onFormSubmit={handleSubmit}
                        />
                        {formErrLabel ? <div className='err-label lvideo-el'>{formErrLabel}</div> : null}
                        <Button
                            extClasses={`lvideo-sc-cta ${formErrLabel && 'lvideo-sc-cta--err'}`}
                            primary
                            label='Search'
                            onClick={handleSubmit}
                        />
                    </div>
                </ExpandableCard>
                {err ? (<div className='container-err-label lvideo-crel'>{err}</div>) : null}
                {
                    (!loading && videoUrl) ? (
                        <div className='lvideo-vc'>
                            <video width="480" height="640" controls disablePictureInPicture controlsList="nodownload">
                                <source src={videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ) : null
                }
            </div>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'livenessVideo', reducer });
const withSaga = injectSaga({ key: 'livenessVideo', saga });

export default compose(
    withReducer,
    withSaga,
)(LivenessVideo);

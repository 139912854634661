import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocationStep from './LocationStep';
import FaceCaptureStep from './FaceCaptureStep';
import PANCardStep from './PANCardStep';
import QuestionStep from './QuestionStep';
import PassportCaptureStep from './PassportCaptureStep';
import { VKYC_STAGES } from '../constants';
import { selectVkycData } from '../selectors';
import { setTitle } from '../actions';
import EmiratesIdCaptureStep from './EmiratesIdCaptureStep';
import FinalScreen from './FinalScreen';

const CurrentStage = ({ stopRecording, setNextStepEnable }) => {
    const dispatch = useDispatch();
    const vkycData = useSelector(selectVkycData);
    const currentStep = vkycData?.meetings?.[vkycData?.data?.meetingId]?.stepNumber;

    const stages = vkycData?.meetings?.[vkycData?.data?.meetingId]?.stages;
    const meetingId = vkycData?.data?.meetingId;
    const answers = vkycData?.meetings?.[meetingId]?.answers;

    React.useEffect(() => {
        dispatch(setTitle({ title: stages?.[currentStep]?.title }));
    }, [currentStep, stages, dispatch]);

    const handleSetNextStepEnable = (enable) => {
        setNextStepEnable(enable);
    };

    if (currentStep >= stages?.length) {
        return (
            <FinalScreen stopRecording={stopRecording} />
        );
    }

    switch (stages?.[currentStep]?.stage_name) {
        case VKYC_STAGES.LIVENESS:
            return (
                <QuestionStep
                    data={stages[currentStep]}
                    answers={answers}
                    meetingId={vkycData?.data?.meetingId}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        case VKYC_STAGES.LOCATION:
            return (
                <LocationStep
                    data={stages[currentStep]}
                    meetingId={vkycData?.data?.meetingId}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        case VKYC_STAGES.FACE_CAPTURE:
            return (
                <FaceCaptureStep
                    data={stages[currentStep]}
                    meetingId={vkycData?.data?.meetingId}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        case VKYC_STAGES.PAN_CAPTURE:
            return (
                <PANCardStep
                    data={stages[currentStep]}
                    meetingId={vkycData?.data?.meetingId}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        case VKYC_STAGES.PASSPORT_CAPTURE:
            return (
                <PassportCaptureStep
                    data={stages[currentStep]}
                    meetingId={vkycData?.data?.meetingId}
                    stopRecording={stopRecording}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        case VKYC_STAGES.EMIRATES_ID_CAPTURE:
            return (
                <EmiratesIdCaptureStep
                    data={stages[currentStep]}
                    meetingId={vkycData?.data?.meetingId}
                    stopRecording={stopRecording}
                    setAllAnsweredYes={handleSetNextStepEnable}
                />
            );
        default:
            return <div>Something went wrong!</div>;
    }
};

export default CurrentStage;

/**
 * @file Manages the cookies related util functions
 */

/**
 * Function to set the cookie in the browser
 *
 * @param {string} key
 * @param {string} value
 * @param {number} expiryInHours
 */
const setCookie = (key, value, expiryInHours) => {
    let expiryStr = '';

    if (expiryInHours) {
		const date = new Date();
		date.setTime(date.getTime() + (expiryInHours * 60 * 60 * 1000));
		expiryStr = `; expires=${date.toUTCString()}`;
	}

    document.cookie = `${key}=${value}${expiryStr}; path=/`;
};

/**
 * Function to read the client-side cookie from the browser
 *
 * @param {string} key 
 * @returns null if cookie is not present, else the cookie value
 */
const readCookie = (key) => {
	const rawKey = `${key}=`;
	const cookieArr = document.cookie.split(';');

	for(let i = 0; i < cookieArr.length; i += 1) {
		let cookie = cookieArr[i];

		/*
		truncate the first letter from cookie string if it's there
		as cookies get stored in this form - 'a=b; c=d; e=f;'
		*/
		while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }

		/*
		check if rawKey is part of cookie string, then return cookie value
		by taking a substring from rawKey's length to whole cookie string length
		e.g. - 'abc=def;' --> 'def'
		*/
		if (cookie.indexOf(rawKey) === 0) {
            return cookie.substring(rawKey.length, cookie.length);
        }
	}

	return null;
};

/**
 * Function to delete the cookie by setting its expiry as -1
 *
 * @param {string} key 
 */
const deleteCookie = (key) => {
    setCookie(key, '', -1);
};

export {
    setCookie,
    readCookie,
    deleteCookie,
};

/*
 *
 * Liveness review saga
 *
 */

import {
    call, put, takeLatest, all, delay,
} from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify,
} from '../../utils';
import { RISK_OPS_ROOT, LIVENESS_ROOT_V2 } from '../../api/routes';

import {
    GET_LIVENESS_CASES, SET_LIVENESS_CASES, ERR_LIVENESS_CASES, DEQUEUE_LIVENESS_CASE,
    VIEW_ALL_LIVENESS_CASES, GET_LIVENESS_REVIEW_VIDEO, SET_LIVENESS_REVIEW_VIDEO, VIEW_SINGLE_LIVENESS_CASE,
    GET_LIVENESS_PENDING_REVIEW_COUNT, SET_LIVENESS_PENDING_REVIEW_COUNT, AUDIT_LOG_MEDIA_DOWNLOAD,
} from './constants';
import { getLivenessPendingReviewCountAction, setCurLivenessCaseAction, setLivenessViewAction } from './actions';

function* getLivenessCasesSaga(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_ROOT}/queue?${queryString}`,
        );
        yield delay(300);

        yield put({ type: SET_LIVENESS_CASES, data: response.elements });
        return response.elements;
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LIVENESS_CASES, data: { err: e.message } });
        yield put({ type: SET_LIVENESS_CASES, data: [] });
        return [];
    }
}

function* dequeueLivenessCaseSaga(action) {
    let { actorId, recordId } = action.data;
    const { queryParams, index, customContext } = action.data;
    const { payloadType } = queryParams;
    const { bannersVersion } = customContext;

    actorId = btoa(actorId);
    recordId = btoa(recordId);
    const queryString = getQueryStringFromObject({ actorId, recordId, payloadType });

    const bannerStyle = {
        1: '.devs-cc',
        2: '.devs-v2-cc',
    };

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.delete],
            `${RISK_OPS_ROOT}/queue?${queryString}`,
        );

        const queueElements = yield call(getLivenessCasesSaga, { data: queryParams });
        yield put(getLivenessPendingReviewCountAction({ ...queryParams }));

        let livenessView;
        let curLivenessCase;
        const queueLen = queueElements.length;

        if (queueLen === 0) { // if queue is empty, show all liveness cases view
            livenessView = VIEW_ALL_LIVENESS_CASES;
            curLivenessCase = {};
        } else { // else show the next case in the queue
            livenessView = VIEW_SINGLE_LIVENESS_CASE;
            /**
             * if index is less than queue length, set the current case at that index
             * else set the current case as the last case in the queue
             */
            curLivenessCase = index < queueLen ? queueElements[index] : queueElements[queueLen - 1];
        }

        yield put(setLivenessViewAction(livenessView));
        yield put(setCurLivenessCaseAction({ ...curLivenessCase, index }));

        /**
         * scroll to the top for the next case
         * need to add 500ms delay otherwise it scrolls to the top & then again back to the bottom
         */
        setTimeout(() => document.querySelector(bannerStyle[bannersVersion]).scrollTo(0, 0), 500);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LIVENESS_CASES, data: { err: e.message } });
    }
}

function* getLivenessReviewVideoURL(action) {
    const { actorId, requestId } = action.data;
    const queryString = `actorId=${btoa(actorId)}&requestId=${btoa(requestId)}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LIVENESS_ROOT_V2}/video-url?${queryString}`,
        );
        yield delay(300);

        yield put({ type: SET_LIVENESS_REVIEW_VIDEO, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LIVENESS_CASES, data: { err: e.message } });
    }
}

function* getPendingReviewCountSaga(action) {
    const { payloadType } = action.data;
    const queryString = getQueryStringFromObject({ payloadType });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_ROOT}/pending-review-count?${queryString}`,
        );

        yield put({ type: SET_LIVENESS_PENDING_REVIEW_COUNT, data: response.count });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LIVENESS_CASES, data: { err: e.message } });
    }
}

export function* auditLogMediaDownloadSaga(action) {
    const { requestBody, resolve, reject } = action.data;
    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${RISK_OPS_ROOT}/media-download`,
            requestBody,
        );

        yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LIVENESS_CASES, data: { err: e.message } });
        yield call(reject);
    }
}

export default function* livenessReviewSaga() {
    yield all(
        [
            yield takeLatest(GET_LIVENESS_CASES, getLivenessCasesSaga),
            yield takeLatest(DEQUEUE_LIVENESS_CASE, dequeueLivenessCaseSaga),
            yield takeLatest(GET_LIVENESS_REVIEW_VIDEO, getLivenessReviewVideoURL),
            yield takeLatest(GET_LIVENESS_PENDING_REVIEW_COUNT, getPendingReviewCountSaga),
            yield takeLatest(AUDIT_LOG_MEDIA_DOWNLOAD, auditLogMediaDownloadSaga),
        ],
    );
}

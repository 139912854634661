/* eslint-disable import/no-cycle */
/**
 *
 * TicketAndUserInfo
 *
 */

import React, { memo } from 'react';

import './style.scss';

const TicketAndUserInfo = ({
    ticketId, source, phoneNumber, name, extClasses = '',
}) => (
    <div className={`ticket-user-info-wrapper frccWrapper ${extClasses}`}>
        <div>
            <span>ID - </span>
            <span>{ticketId}</span>
            <span>&nbsp;&nbsp;||&nbsp;&nbsp;</span>
            <span>{`${source} from ${phoneNumber}`}</span>
            {
                name ? (
                    <React.Fragment>
                        <span>&nbsp;&nbsp;||&nbsp;&nbsp;</span>
                        <span>{name}</span>
                    </React.Fragment>
                ) : null
            }
        </div>
    </div>
);

export default memo(TicketAndUserInfo);

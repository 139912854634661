/**
 * Utility functions to handle the file upload events for db states, dev actions & admin actions.
 */

export const SUPPORTED_FILE_TYPES = '.csv,.txt,.jpeg,.xls,.xlsx';

const ACCEPTED_FORMATS_MAP = {
    'text/plain': 'txt',
    'text/csv': 'csv',
    'image/jpeg': 'jpeg',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
};

/**
 * This function helps to remove the uploaded file related params, if any, when 'Choose File' cta is clicked
 * on input file component.
 * @param {object} param consists of item, dispatch, setParameterValue & setErrLabel
 * @returns it does not return anything
 */
const onFileClick = ({
    item, setErrLabel, setValue,
}) => (e) => {
    e.target.value = null;

    setErrLabel('');

    let paramValue = {
        contentBase64: '',
        contentType: '',
    };

    if (item.type === 12) paramValue = [];

    setValue(paramValue);
};

const getImageInBufferFormat = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    // options to read file in other formats
    // reader.readAsText(file);
    // reader.readAsDataURL(file);

    reader.onload = function () {
        // value of the file is received as an array buffer
        const arrayBuffer = reader.result;

        /**
         * convert array buffer to base64 encoded string
         * direct wrapping with btoa() does not work as expected
         * ref: https://stackoverflow.com/a/42334410
         */
        const contentBase64 = btoa(
            new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''),
        );

        resolve({
            contentBase64,
            contentType: ACCEPTED_FORMATS_MAP[file.type],
        });
    };

    reader.onerror = function () {
        reject(new Error('Something went wrong, please try again!'));
    };
});

/**
 * This function helps to set the uploaded file related params on input file component.
 * @param {object} param consists of item, dispatch, setParameterValue & setErrLabel
 * @returns it does not return anything
 */
const onFileChange = ({
    item, setErrLabel, setValue, setFileName,
}) => async (e) => {
    try {
        const fileList = e.target.files;
        const file = fileList && fileList[0];
        if (setFileName && file && file.name) {
            setFileName(file.name);
        }

        const acceptedFormats = Object.keys(ACCEPTED_FORMATS_MAP);

        if (acceptedFormats.includes(file.type)) {
            const promiseList = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const curFile of fileList) {
                const value = getImageInBufferFormat(curFile);
                promiseList.push(value);
            }

            const valueList = await Promise.all(promiseList);
            setValue(item.type === 11 ? valueList[0] : valueList);
        } else {
            setErrLabel(`File format not supported, only ${SUPPORTED_FILE_TYPES} files are supported`);
        }
    } catch (error) {
        const { message = 'Something went wrong, please try again!' } = error;
        setErrLabel(message);
    }
};

export {
    onFileClick,
    onFileChange,
    getImageInBufferFormat,
};

/**
 * @file ViewKycAndAccountDetails
 * Shows the KYC details and salary account details for a particular user enrolled in salary account program
 */

import React, { memo } from 'react';
import { isEmpty } from 'ramda';

import { KVPairInfo } from '../../../components';

const getKycKVData = (kycInfo) => {
    if (!kycInfo) return null;

    return [
        {
            label: 'KYC Status',
            value: kycInfo.kycStatus,
        },
        {
            label: 'KYC Status Last Updated At',
            value: kycInfo.kycStatusUpdatedAt,
        },
    ];
};

const getSalaryAccountKVData = (salaryInfo) => {
    if (!salaryInfo) return null;

    return [
        {
            label: 'Registration Status',
            value: salaryInfo.registrationStatus,
        },
        {
            label: 'Salary Account Status',
            value: salaryInfo.salaryAccountStatus,
        },
        {
            label: 'Registration Completion Date',
            value: salaryInfo.registrationCompletionDate,
        },
        {
            label: 'First Salary Activation Date',
            value: salaryInfo.firstSalaryActivationDate,
        },
        {
            label: 'Salary Activation Type',
            value: salaryInfo.salaryActivationType,
        },
        {
            label: 'First Salary Activation Type',
            value: salaryInfo.firstSalaryActivationType,
        },
        // Commenting for now, will be used later
        // {
        //     label: 'Last Active Duration Start Date',
        //     value: salaryInfo.lastActiveDurationStartDate,
        // },
        // {
        //     label: 'Last Active Duration End Date',
        //     value: salaryInfo.lastActiveDurationEndDate,
        // },
    ];
};

const ViewKycAndAccountDetails = (props) => {
    const { kycInfo, salaryProgramInfo } = props;

    if (isEmpty(kycInfo) || isEmpty(salaryProgramInfo)) return null;

    return (
        <React.Fragment>
            <KVPairInfo
                title='KYC Details'
                data={getKycKVData(kycInfo)}
                extClasses={{
                    container: 'salops-kv__cr',
                    titleHeading: 'salops-kv__hd',
                    contentLabel: 'salops-kv__cl',
                    contentValue: 'salops-kv__cv',
                }}
            />
            <KVPairInfo
                title='Salary Account Details'
                data={getSalaryAccountKVData(salaryProgramInfo)}
                extClasses={{
                    container: 'salops-kv__cr',
                    titleHeading: 'salops-kv__hd',
                    contentLabel: 'salops-kv__cl',
                    contentValue: 'salops-kv__cv',
                }}
            />
        </React.Fragment>
    );
};

export default memo(ViewKycAndAccountDetails);

/**
 * @file CardBanner: Component to show the back arrow and title with children.
 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import backArrow from '../../../assets/images/svgs/back-arrow.svg';

import { toggleScreenAction } from '../actions';

import './style.scss';

const CardBanner = (props) => {
    const {
        title, discardCurrentScreen, activatePreviousScreen, children, ticketId, containerClass,
    } = props;

    const dispatch = useDispatch();

    /**
     * Toggles the current screen to either discard it or activate the previous screen.
     */
    const toggleScreen = () => {
        const payload = {
            ticketId,
            [discardCurrentScreen]: false,
        };

        if (activatePreviousScreen) {
            payload[activatePreviousScreen] = true;
        }

        dispatch(toggleScreenAction(payload));
    };

    const containerClasses = classNames(
        'credit-card-cr',
        containerClass && 'credit-card-cr--cs',
    );

    return (
        <div className={containerClasses}>
            <div className='credit-card-cb-hwr'>
                <button onClick={toggleScreen} type='button'>
                    <img src={backArrow} alt='back-arrow' className='credit-card-cb-backarrow' />
                </button>
                <div className='credit-card-cb-hl heading1'>{title}</div>
            </div>
            {children}
        </div>
    );
};

CardBanner.propTypes = {
    title: PropTypes.string.isRequired,
    discardCurrentScreen: PropTypes.string.isRequired,
    ticketId: PropTypes.string.isRequired,
    activatePreviousScreen: PropTypes.string,
    children: PropTypes.node,
    containerClass: PropTypes.string,
};

CardBanner.defaultProps = {
    activatePreviousScreen: null,
    children: null,
    containerClass: null,
};

export default memo(CardBanner);

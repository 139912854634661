/**
 *
 * Date range picker for selecting 'from' a particular date 'to' a particular date
 *
 */

import React, { memo } from 'react';

import FormWithSingleInput from '../FormWithSingleInput';

const DATE_KEY_INFO = {
    FROM_DATE: 'fromDate',
    TO_DATE: 'toDate',
};

const DateRangePicker = (props) => {
    const {
        dateInfo,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        showTimeInDateInput,
    } = props;

    const {
        fromDateInfo, toDateInfo, extClasses, initialDateRange,
    } = dateInfo;

    const { label: fromLabel, labelId: fromLabelId } = fromDateInfo;
    const { label: toLabel, labelId: toLabelId } = toDateInfo;

    const { upperLimitDate, lowerLimitDate } = initialDateRange;

    const getDateRange = (data, key) => {
        const { toDate: curToDate, fromDate: curFromDate, prevState } = data;
        let modifiedToDate;
        let modifiedFromDate;

        const { fromDate: fromDatePrevState, toDate: toDatePrevState } = prevState;

        if (key === DATE_KEY_INFO.TO_DATE) {
            // updated the modifiedFromDate value here
            modifiedToDate = {
                ...toDatePrevState,
                value: curToDate,
            };

            // added a check if curToDate is not empty so that modifiedFromDate don't have Invalid upperLimit
            if (curToDate) {
                const newToDate = new Date(curToDate);
                if (!showTimeInDateInput) newToDate.setHours(23, 59, 59, 999);

                const upperLimitFromDate = new Date(curToDate);
                if (!showTimeInDateInput) upperLimitFromDate.setHours(0, 0, 0, 0);

                modifiedFromDate = {
                    ...fromDatePrevState,
                    upperLimit: upperLimitFromDate,
                };
            }
        } else if (key === DATE_KEY_INFO.FROM_DATE) {
            // updated the modifiedFromDate value here
            modifiedFromDate = {
                ...fromDatePrevState,
                value: curFromDate,
            };

            // added a check if curFromDate is not empty so that modifiedToDate don't have Invalid lowerLimit
            if (curFromDate) {
                const lowerLimitToDate = new Date(curFromDate);
                if (!showTimeInDateInput) lowerLimitToDate.setHours(23, 59, 59, 999);

                modifiedToDate = {
                    ...toDatePrevState,
                    lowerLimit: lowerLimitToDate,
                };
            }
        }

        return {
            modifiedFromDate,
            modifiedToDate,
        };
    };

    const handleDateChange = (key) => (value) => {
        const data = {
            prevState: {
                upperLimitDate, lowerLimitDate, fromDate, toDate,
            },
        };

        data[key] = value ?? '';

        const { modifiedFromDate, modifiedToDate } = getDateRange(data, key);

        if (modifiedFromDate) {
            setFromDate(modifiedFromDate);
        }

        if (modifiedToDate) {
            setToDate(modifiedToDate);
        }
    };

    const { upperLimit: maxFromDate, lowerLimit: minFromDate, value: fromValue } = fromDate;
    const { upperLimit: maxToDate, lowerLimit: minToDate, value: toValue } = toDate;

    /**
     * TODO:
     * 1. Add handling of the case where time should be disabled after the current time
     * 2. Add handling for default time to be 12:00 AM
     */
    const filterFromDateTime = (curFromDate) => {
        const toDateValue = new Date(toDate.value);
        const curFromDateTime = new Date(curFromDate);

        if (!toDate.value) return true;

        return toDateValue.getTime() > curFromDateTime.getTime();
    };

    const filterToDateTime = (curToDate) => {
        const fromDateValue = new Date(fromDate.value);
        const curToDateTime = new Date(curToDate);

        if (!fromDate.value) return true;

        return fromDateValue.getTime() < curToDateTime.getTime();
    };

    return (
        <React.Fragment>
            {/* from date input */}
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={extClasses}
                label={fromLabel}
                labelId={fromLabelId}
                formType='dob'
                input={{
                    value: fromValue,
                    onChange: handleDateChange('fromDate'),
                    maxDate: maxFromDate,
                    minDate: minFromDate,
                    showTimeInDateInput,
                    filterPassedTime: filterFromDateTime,
                }}
                hideErrLabel
            />
            {/* to date input */}
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={extClasses}
                label={toLabel}
                labelId={toLabelId}
                formType='dob'
                input={{
                    value: toValue,
                    onChange: handleDateChange('toDate'),
                    maxDate: maxToDate,
                    minDate: minToDate,
                    showTimeInDateInput,
                    filterPassedTime: filterToDateTime,
                }}
                hideErrLabel
            />
        </React.Fragment>
    );
};

export default memo(DateRangePicker);

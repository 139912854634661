/**
 * @file ViewSingleVerificationCase
 * Displays the details of a single case for agents to manually verify salary details for users
 * Agents can see the employment details and remitter name
 * Agents can search and update the remitter name
 * Agents need to fill a form and submit if the employment details match the remitter name
 *
 * Agent can view the employer details for the current case and the following details and submit
 * 1. If the remitter name matches the employer name
 * If yes, then user can simply submit
 * If no, user can search for the required employer details
 * User can mark if the searched employer details is full, partial or no match
 */

import React, { memo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import { Button, FormDropdown, FormWithSingleInput } from '../../../components';

import { getVerificationCaseEmployerDetailsAction, setSalaryAccountOpsCurViewAction, updateSalaryVerificationCaseStatusAction } from '../actions';
import { SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY, VIEW_VERIFICATION_CASES } from '../constants';
import {
    selectSalaryVerificationCaseDetails, selectSalaryOpsEnums, selectSalaryVerificationStatus, selectSalaryVerificationCaseEmployerDetails,
} from '../selectors';

import UpdateEmployerName from '../UpdateEmployerName';
import VerificationInfo from '../VerificationInfo';

const NAME_MATCH_LEVEL_OPTIONS = [
    { label: ' No Match', value: 'NAME_MATCH_LEVEL_NO_MATCH' },
    { label: ' Partial Match', value: 'NAME_MATCH_LEVEL_PARTIAL_MATCH' },
    { label: ' Perfect Match', value: 'NAME_MATCH_LEVEL_PERFECT_MATCH' },
];

const EMPLOYER_MATCH = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

const VERIFICATION_CHECKS_REQUIRED_LIST = [
    'SALARY_TXN_VERIFICATION_STATUS_PENDING_VERIFICATION',
    'SALARY_TXN_VERIFICATION_STATUS_ESCALATED_TO_ENGG_TEAM',
    'SALARY_TXN_VERIFICATION_STATUS_REJECTED',
];

const NAME_MATCH_LEVEL_TO_VERDICT_MAP = {
    NAME_MATCH_LEVEL_NO_MATCH: 'REJECTED',
    NAME_MATCH_LEVEL_PARTIAL_MATCH: 'ESCALATED',
    NAME_MATCH_LEVEL_PERFECT_MATCH: 'ACCEPTED',
};

const VERIFICATION_CHECKS = 'VERIFICATION_CHECKS';
const UPDATE_EMPLOYER_NAME = 'UPDATE_EMPLOYER_NAME';

const ViewSingleVerificationCase = () => {
    const dispatch = useDispatch();

    const caseDetails = useSelector(selectSalaryVerificationCaseDetails, shallowEqual);
    const verificationStatus = useSelector(selectSalaryVerificationStatus, shallowEqual);
    const enums = useSelector(selectSalaryOpsEnums, shallowEqual);
    const employerDetails = useSelector(selectSalaryVerificationCaseEmployerDetails, shallowEqual);

    const nameMatchLevelList = enums.nameMatchLevelList || NAME_MATCH_LEVEL_OPTIONS;
    const { categoryToSubcategoriesFailureReasons } = employerDetails || {};
    const listOfVerificationFailureReasons = Object.keys(categoryToSubcategoriesFailureReasons || {});
    let listOfCategoryVerificationFailureReason;

    if (listOfVerificationFailureReasons.length) {
        listOfCategoryVerificationFailureReason = listOfVerificationFailureReasons.map((item) => ({ label: item, value: item }));
    }

    // local state variables
    const [userEmployerMatch, setUserEmployerMatch] = useState({});
    const [newEmployerDetails, setNewEmployerDetails] = useState({});
    const [nameMatchLevel, setNameMatchLevel] = useState({});
    const [verdict, setVerdict] = useState('');
    const [curView, setCurView] = useState(VERIFICATION_CHECKS);
    const [errLabel, setErrLabel] = useState('');
    const [remarks, setRemarks] = useState('');
    const [categoryVerificationFailureReason, setCategoryVerificationFailureReason] = useState({});
    const [subCategoryVerificationFailureReason, setSubCategoryVerificationFailureReason] = useState({});
    const [listOfSubcategoryVerificationFailureReasons, setListOfSubcategoryVerificationFailureReasons] = useState([]);
    const [mappingAndVerification, setMappingAndVerification] = useState(false);

    const isVerdictRejected = verdict === 'REJECTED';

    const handleBackClick = () => {
        dispatch(setSalaryAccountOpsCurViewAction({ screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY, value: VIEW_VERIFICATION_CASES }));
    };

    const resetCategoryAndSubcategoryVerificationFailureReason = () => {
        setCategoryVerificationFailureReason({});
        setSubCategoryVerificationFailureReason({});
        setListOfSubcategoryVerificationFailureReasons([]);
    };

    const onNameMatchLevelChange = (newNameMatchLevel) => {
        setNameMatchLevel(newNameMatchLevel);
        setVerdict(NAME_MATCH_LEVEL_TO_VERDICT_MAP[newNameMatchLevel.value]);
        resetCategoryAndSubcategoryVerificationFailureReason();
    };

    const onEmployerMatchChange = (newEmployerMatch) => {
        setUserEmployerMatch(newEmployerMatch);

        if (newEmployerMatch.value) {
            setVerdict('ACCEPTED');
        } else {
            setVerdict(NAME_MATCH_LEVEL_TO_VERDICT_MAP[nameMatchLevel.value] || '');
        }

        resetCategoryAndSubcategoryVerificationFailureReason();
    };

    const onEmployerDetailsSubmit = (selectedEmployerDetails) => {
        setNewEmployerDetails(selectedEmployerDetails);
        setCurView(VERIFICATION_CHECKS);
    };

    const onCategoryFailureReasonChange = (newCategoryFailureReason) => {
        setCategoryVerificationFailureReason(newCategoryFailureReason);

        // need to reset subcateogry failure reason value when we change category failure reason
        setSubCategoryVerificationFailureReason({});
    };

    const onSubcategoryFailureReasonChange = (newSubcategoryFailureReason) => {
        setSubCategoryVerificationFailureReason(newSubcategoryFailureReason);
    };

    const handleSubmit = () => {
        let validInputs = true;

        if (isEmpty(userEmployerMatch)) {
            validInputs = false;
        }

        if (userEmployerMatch.value === false && isEmpty(nameMatchLevel)) {
            validInputs = false;
        }

        if (isVerdictRejected && (isEmpty(categoryVerificationFailureReason) || isEmpty(subCategoryVerificationFailureReason))) {
            validInputs = false;
        }

        if (!validInputs) {
            setErrLabel('Please fill all * marked fields');
            return;
        }

        setErrLabel('');

        let reqEmployerId = employerDetails.employerId;

        if (userEmployerMatch.value === false) {
            reqEmployerId = newEmployerDetails.employer_id;
        }

        const { remitterName } = employerDetails;

        // may be needed later, uncomment if needed
        // if (!remitterName) remitterName = employerDetails.piRemitterName;

        dispatch(updateSalaryVerificationCaseStatusAction({
            userEmployerMatch: userEmployerMatch.value,
            nameMatchLevel: nameMatchLevel.value,
            employerId: reqEmployerId,
            remitterName,
            requestId: caseDetails.requestId,
            verificationFailureReasonCategory: categoryVerificationFailureReason.value,
            verificationFailureReasonSubCategory: subCategoryVerificationFailureReason.value,
            verificationRemark: remarks,
            isRemitterNameCompletelyMatchingEmployer: mappingAndVerification,
        }));
    };

    const onBackClick = () => {
        setCurView(VERIFICATION_CHECKS);
    };

    const categoryVerificationFailureReasonSection = (
        <FormDropdown
            label='Choose the category for the reason for rejection*'
            options={listOfCategoryVerificationFailureReason}
            input={{
                value: categoryVerificationFailureReason,
                onChange: onCategoryFailureReasonChange,
                placeholder: 'Choose...',
            }}
            cacheKey='verification-failure-reason-category'
            extStyles={{
                container: 'salops-fc mt-30',
                label: 'salops-kv__cl kvpair-info-cwr__cl',
                inputField: 'salops-fc__ip',
            }}
        />
    );

    const subCategoryVerificationFailureReasonSection = (
        <FormDropdown
            label='Choose the sub category for the reason for rejection*'
            options={listOfSubcategoryVerificationFailureReasons}
            input={{
                value: subCategoryVerificationFailureReason,
                onChange: onSubcategoryFailureReasonChange,
                placeholder: 'Choose...',
            }}
            cacheKey='verification-failure-reason-sub-category'
            extStyles={{
                container: 'salops-fc mt-30',
                label: 'salops-kv__cl kvpair-info-cwr__cl',
                inputField: 'salops-fc__ip',
            }}
        />
    );

    const remarksSection = (
        <FormWithSingleInput
            disableFormSubmitOnEnter
            extClasses={{
                container: 'salops-fs',
                wrapper: 'salops-fs__fw',
                label: 'salops-fs__lb',
                inputField: 'salops-fc__ip',
            }}
            label='Remarks'
            labelId='review-remarks'
            input={{
                value: remarks,
                onChange: (e) => setRemarks(e.target.value),
            }}
        />
    );

    const renderVerificationFailureReasonSection = () => (
        <React.Fragment>
            {categoryVerificationFailureReasonSection}
            {listOfSubcategoryVerificationFailureReasons.length ? subCategoryVerificationFailureReasonSection : null}
            {remarksSection}
        </React.Fragment>
    );

    useEffect(() => {
        // on component load, clear the last search result
        dispatch(getVerificationCaseEmployerDetailsAction({
            txnId: caseDetails.txnId,
            requestId: caseDetails.requestId,
        }));
    }, [dispatch, caseDetails.txnId, caseDetails.requestId]);

    useEffect(() => {
        if (!isEmpty(categoryVerificationFailureReason)) {
            const { sub_categories: subCategories } = categoryToSubcategoriesFailureReasons[categoryVerificationFailureReason.label];
            const data = subCategories?.map((item) => ({ label: item, value: item }));

            setListOfSubcategoryVerificationFailureReasons(data);
        }
    }, [categoryVerificationFailureReason, categoryToSubcategoriesFailureReasons]);

    // if user selects to search employer name
    if (curView === UPDATE_EMPLOYER_NAME) {
        return (
            <UpdateEmployerName
                onEmployerDetailsSubmit={onEmployerDetailsSubmit}
                onBackClick={onBackClick}
            />
        );
    }

    // view employer details and fill verification checks form
    return (
        <React.Fragment>
            <VerificationInfo
                caseDetails={caseDetails}
                employerDetails={employerDetails}
                handleBackClick={handleBackClick}
            />

            {/* Show verification checks only for pending verification & escalated to eng team */}
            {VERIFICATION_CHECKS_REQUIRED_LIST.includes(verificationStatus.value) && (
                <div className='salops-uetb mt-60'>
                    <div className='salops-kv__hd'>Verification Checks</div>

                    <FormDropdown
                        label='Remitter Name Matches Employer Name?*'
                        options={EMPLOYER_MATCH}
                        input={{
                            value: userEmployerMatch,
                            onChange: onEmployerMatchChange,
                            placeholder: 'Choose...',
                        }}
                        cacheKey='salary-user-employer-match'
                        extStyles={{
                            container: 'salops-fc mt-30',
                            label: 'salops-kv__cl kvpair-info-cwr__cl',
                            inputField: 'salops-fc__ip',
                        }}
                    />

                    {/* If employer match is No then show additional form fields */}
                    {userEmployerMatch.value === false && (
                        <React.Fragment>
                            <div className='frcsbWrapper mt-30'>
                                <div className='frcWrapper'>
                                    <div className='salops-kv__cl1 kvpair-info-cwr__cl'>Search And Find Remitter: </div>
                                    <div className='salops-kv__cv1 kvpair-info-cwr__cv mr-30'>{newEmployerDetails.name_by_source || ''}</div>
                                </div>
                                <Button
                                    v2
                                    label='Search Employer Name'
                                    secondary
                                    onClick={() => setCurView(UPDATE_EMPLOYER_NAME)}
                                />
                            </div>
                            <FormDropdown
                                label='Remitter Name Matches A Different Employer Name?*'
                                options={nameMatchLevelList}
                                input={{
                                    value: nameMatchLevel,
                                    onChange: onNameMatchLevelChange,
                                    placeholder: 'Choose...',
                                }}
                                cacheKey='salary-name-match-level'
                                extStyles={{
                                    container: 'salops-fc mt-30',
                                    label: 'salops-kv__cl kvpair-info-cwr__cl',
                                    inputField: 'salops-fc__ip',
                                }}
                            />
                        </React.Fragment>
                    )}

                    <div className={isVerdictRejected ? 'frcsbWrapper mt-30' : 'frcsbWrapper mt-30 mb-15'}>
                        <div className='salops-kv__cl kvpair-info-cwr__cl'>Verdict:</div>
                        <div className='salops-kv__cv kvpair-info-cwr__cv '>{verdict}</div>
                    </div>

                    {isVerdictRejected ? renderVerificationFailureReasonSection() : null}

                    <div className='frcWrapper mb-30'>
                        <input
                            type='checkbox'
                            checked={mappingAndVerification}
                            onChange={() => setMappingAndVerification(!mappingAndVerification)}
                        />
                        <span className='ml-15'>Map this and verify other salary transactions via this Payment Instrument for this employer</span>
                    </div>

                    {errLabel && <div className='err-label label1 sip-elb'>{errLabel}</div>}

                    <Button
                        v2
                        label='Submit'
                        primary
                        onClick={handleSubmit}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default memo(ViewSingleVerificationCase);

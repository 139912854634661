/**
 * @file UserProducts constants
 */

// container key used for reducer and saga
export const USER_RISK_PRODUCTS_KEY = 'userRiskProducts';

// action constants
export const GET_USER_RISK_PRODUCT_LIST_DATA = `app/${USER_RISK_PRODUCTS_KEY}/GET_USER_RISK_PRODUCT_LIST_DATA`;
export const SET_USER_RISK_PRODUCT_LIST_DATA = `app/${USER_RISK_PRODUCTS_KEY}/SET_USER_RISK_PRODUCT_LIST_DATA`;
export const ERROR_USER_RISK_PRODUCT_LIST = `app/${USER_RISK_PRODUCTS_KEY}/ERROR_USER_RISK_PRODUCT_LIST`;

export const GET_USER_RISK_PRODUCT_INFO_DATA = `app/${USER_RISK_PRODUCTS_KEY}/GET_USER_RISK_PRODUCT_INFO_DATA`;
export const SET_USER_RISK_PRODUCT_INFO_DATA = `app/${USER_RISK_PRODUCTS_KEY}/SET_USER_RISK_PRODUCT_INFO_DATA`;
export const RESET_USER_RISK_PRODUCT_INFO_DATA = `app/${USER_RISK_PRODUCTS_KEY}/RESET_USER_RISK_PRODUCT_INFO_DATA`;
export const ERROR_USER_RISK_PRODUCT_INFO = `app/${USER_RISK_PRODUCTS_KEY}/ERROR_USER_RISK_PRODUCT_INFO`;

/**
 * @file Component to show the list of all banners in Sherlock
 */

import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { isEmpty } from 'ramda';

import {
    Button, ExpandableCard, FormDropdown, Loader, Table,
} from '../../../components';

import { CREATE_BANNER_PATH, UPDATE_BANNER_PATH } from '../../App/routes';
import { makeSelectAccessLevelList } from '../../App/selectors';

import { getBannerList, setBannerInfo, setBannerFormFields, resetBannerFormFields } from '../actions';
import { makeSelectBannerList } from '../selectors';
import { BANNER_LIST_LABEL_DATA, BANNER_LIST_FORM_FIELDS, BANNER_STATUS_LIST } from '../constants';

import './style.scss';

const BannerList = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // reducer state variables
    const bannerList = useSelector(makeSelectBannerList, shallowEqual);
    const rolesList = useSelector(makeSelectAccessLevelList, shallowEqual);

    // local state variables
    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(BANNER_STATUS_LIST[0]);
    const [selectedRoles, setSelectedRoles] = useState(null);
    const [formErrLabel, setFormErrLabel] = useState('');

    const {
        loading, data = [], prev, next, err,
    } = bannerList;

    useEffect(() => {
        dispatch(getBannerList({ fetchActiveBanners: true }));
    }, [dispatch]);

    const toggleSearchCard = () => {
        setIsSearchCardExpanded(!isSearchCardExpanded);
    };

    const handleDDChange = (key, value) => {
        switch (key) {
            case BANNER_LIST_FORM_FIELDS.STATUS:
                setSelectedStatus(value);
                break;

            case BANNER_LIST_FORM_FIELDS.ROLES:
                setSelectedRoles(value);
                break;

            default:
        }
    };

    const makePayloadAndCallApi = (params = {}) => {
        const { prev, next } = params;

        const payload = {
            fetchActiveBanners: selectedStatus.value,
            roles: selectedRoles?.map(item => item.value),
            prev,
            next,
        };

        dispatch(getBannerList(payload));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isEmpty(selectedStatus) && isEmpty(selectedRoles)) {
            setFormErrLabel('Please fill either of the above fields');
            return;
        }

        makePayloadAndCallApi();
        setFormErrLabel('');
        toggleSearchCard();
    };

    const getPaginatedBannerList = ({ prev, next }) => {
        makePayloadAndCallApi({ prev, next });
    };

    const onCreateBannerClickHandler = () => {
        dispatch(resetBannerFormFields());

        history.push(CREATE_BANNER_PATH);
    };

    const onUpdateBannerClickHandler = (data) => () => {
        // need to set banner info also to compare the updated info with the existing info
        dispatch(setBannerInfo(data));
        dispatch(setBannerFormFields(data));

        history.push(UPDATE_BANNER_PATH);
    };

    const renderActions = (data) => {
        return (
            <div className='frfscWrapper'>
                <Button
                    link
                    label='Update Banner'
                    onClick={onUpdateBannerClickHandler(data)}
                />
            </div>
        );
    };

    const resetSearchResults = () => {
        // reset all the search filters
        setSelectedStatus(BANNER_STATUS_LIST[1]); // this denotes all banners
        setSelectedRoles(null);
        toggleSearchCard();

        // fetch banners list with no filters
        dispatch(getBannerList({}));
    };

    const renderFormDD = (key) => {
        let label;
        let placeholder;
        let value;
        let options;
        let isMultiSelectDD;

        switch (key) {
            case BANNER_LIST_FORM_FIELDS.STATUS: {
                label = 'Status';
                value = selectedStatus;
                options = BANNER_STATUS_LIST;

                break;
            }

            case BANNER_LIST_FORM_FIELDS.ROLES: {
                label = 'Roles';
                value = selectedRoles;
                options = rolesList;
                isMultiSelectDD = true;

                break;
            }

            default:
                label = '';
                options = [];
        }

        return (
            <React.Fragment>
                <div className='banner-list-fc frcWrapper'>
                    <div className='banner-list-fc__lb'>{label}</div>
                    <FormDropdown
                        isMulti={isMultiSelectDD}
                        options={options}
                        input={{
                            value,
                            onChange: (value) => {
                                handleDDChange(key, value);
                            },
                            placeholder,
                        }}
                        cacheKey='banner-list-form-roles'
                        extStyles={{
                            container: 'banner-list-fc__ip',
                        }}
                    />
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className='banner-list-cr'>
                <div className='banner-list-hcr'>
                    <div className='banner-list-hcr__hd'>Banner Management</div>
                    <Button
                        v2
                        primary
                        onClick={onCreateBannerClickHandler}
                        label='Create Banner'
                        extClasses='banner-list-hcr__cta'
                    />
                </div>
                <ExpandableCard
                    extClasses='banner-list-ec'
                    heading='Search for Banners'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={toggleSearchCard}
                >
                    <div className='banner-list-sc'>
                        <div className='frWrapper'>
                            {renderFormDD(BANNER_LIST_FORM_FIELDS.STATUS)}
                            {renderFormDD(BANNER_LIST_FORM_FIELDS.ROLES)}
                        </div>
                        <div className='err-label banner-list-sc__errlb'>{formErrLabel}</div>
                        <div className='frWrapper banner-list-sc__2r'>
                            <Button
                                v2
                                primary
                                extClasses='banner-list-sc__cta'
                                label='Search'
                                onClick={handleSubmit}
                            />
                            <Button
                                link
                                extClasses='banner-list-sc__cta2'
                                label='Reset Search Results'
                                onClick={resetSearchResults}
                            />
                        </div>
                    </div>
                </ExpandableCard>
                <Table
                    v2
                    header='Banner List'
                    labelData={BANNER_LIST_LABEL_DATA}
                    contentData={data}
                    renderActionableColumn={renderActions}
                    prevToken={prev}
                    nextToken={next}
                    paginatedClickHandler={getPaginatedBannerList}
                    errLabel={err}
                    extClasses={{
                        container: 'banner-list-table-sr',
                    }}
                />
            </div>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(BannerList);

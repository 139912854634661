import {
    GET_PROFILE_INFO, GET_USER_BANNERS, GET_USER_ISSUE_INFO,
} from './constants';

export const getProfileInfo = (data) => ({
    type: GET_PROFILE_INFO,
    data,
});

export const getUserIssueInfoAction = (data) => ({
    type: GET_USER_ISSUE_INFO,
    data,
});

// Get user specific banners
export const getUserBanners = (data) => ({
    type: GET_USER_BANNERS,
    data,
});

/*
 *
 * Deposits constants
 *
 */

export const GET_DEPOSITS_LIST = 'app/Deposits/GET_DEPOSITS_LIST';
export const SET_DEPOSITS_LIST = 'app/Deposits/SET_DEPOSITS_LIST';
export const ERR_DEPOSITS_LIST = 'app/Deposits/ERR_DEPOSITS_LIST';

export const GET_DEPOSIT_REQUEST_LIST = 'app/Deposits/GET_DEPOSIT_REQUEST_LIST';
export const SET_DEPOSIT_REQUEST_LIST = 'app/Deposits/SET_DEPOSIT_REQUEST_LIST';
export const ERR_DEPOSIT_REQUEST_LIST = 'app/Deposits/ERR_DEPOSIT_REQUEST_LIST';

export const GET_DEPOSIT_TXN_LIST = 'app/Deposits/GET_DEPOSIT_TXN_LIST';
export const SET_DEPOSIT_TXN_LIST = 'app/Deposits/SET_DEPOSIT_TXN_LIST';
export const ERR_DEPOSIT_TXN_LIST = 'app/Deposits/ERR_DEPOSIT_TXN_LIST';

export const SET_SEARCH_PARAMS = 'app/Deposits/SET_SEARCH_PARAMS';
export const RESET_SEARCH_PARAMS = 'app/Deposits/RESET_SEARCH_PARAMS';

export const SEARCH_BY_LIST = ['Deposit Number', 'Created Date', 'Maturity Date'];

export const ACCOUNT_TYPE = [
    {
        label: 'Fixed Deposit',
        value: 'FIXED_DEPOSIT'
    },
    {
        label: 'Recurring Deposit',
        value: 'RECURRING_DEPOSIT'
    },
    {
        label: 'Smart Deposit',
        value: 'SMART_DEPOSIT'
    },
];

/**
 *
 * View all facematch cases from the queue
 *
 */

import React, { memo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AppDropdown, DateRangePicker } from '../../../components';
import { getAdditionalFilters } from '../../RiskOps/utils';

import {
    getFacematchCasesAction, setFacematchPayloadTypeAction, setFacematchQueueFiltersAction,
} from '../actions';
import {
    DATE_INFO, DD_CONTENT_CLS, DD_ITEM_CLS, DD_WRAPPER_CLS, QUEUE_PAYLOAD_TYPES, SORTING_OPTIONS,
} from '../constants';
import {
    selectFacematchCasesList, selectFacematchPayloadType, selectFacematchPendingReviewCount, selectFacematchQueueFilters, selectLoading,
} from '../selectors';

const AllFacematchCases = (props) => {
    const { handleReviewClick } = props;

    const dispatch = useDispatch();

    // global state variables
    const facematchCasesList = useSelector(selectFacematchCasesList(), shallowEqual);
    const facematchPayloadType = useSelector(selectFacematchPayloadType(), shallowEqual);
    const loadingFacematchCases = useSelector(selectLoading(), shallowEqual);
    const pendingReviewCount = useSelector(selectFacematchPendingReviewCount(), shallowEqual);
    const { fromDate, toDate, sortOption } = useSelector(selectFacematchQueueFilters(), shallowEqual);

    const setPayloadTypeValue = (newPayload) => {
        dispatch(setFacematchPayloadTypeAction(newPayload));
    };

    const setSortOption = (newOptionValue) => {
        dispatch(setFacematchQueueFiltersAction({ key: 'sortOption', value: newOptionValue }));
    };

    const onSortOptionValueChange = (newOptionValue) => {
        dispatch(getFacematchCasesAction({
            payloadType: facematchPayloadType.value,
            ...getAdditionalFilters(fromDate, toDate, { value: newOptionValue }),
        }));
    };

    const setFromDateValue = (newFromDate) => {
        dispatch(setFacematchQueueFiltersAction({ key: 'fromDate', value: newFromDate }));

        // call API only if fromDate & toDate both are set
        if (!newFromDate.value || !toDate.value) return;

        // call API only if fromDate value has changed
        if (newFromDate.value !== fromDate.value) {
            dispatch(getFacematchCasesAction({
                payloadType: facematchPayloadType.value,
                ...getAdditionalFilters(newFromDate, toDate, sortOption),
            }));
        }
    };

    const setToDateValue = (newToDate) => {
        dispatch(setFacematchQueueFiltersAction({ key: 'toDate', value: newToDate }));

        // call API only if fromDate & toDate both are set
        if (!fromDate.value || !newToDate.value) return;

        // call API only if toDate value has changed
        if (newToDate.value !== toDate.value) {
            dispatch(getFacematchCasesAction({
                payloadType: facematchPayloadType.value,
                ...getAdditionalFilters(fromDate, newToDate, sortOption),
            }));
        }
    };

    return (
        <div className='fmreview-cr'>
            <div className='fmreview-hl1'>Total Facematch Cases - {pendingReviewCount}</div>

            <div className='frcsbWrapper my-30'>
                <div className='fcWrapper'>
                    <DateRangePicker
                        dateInfo={DATE_INFO}
                        fromDate={fromDate}
                        setFromDate={setFromDateValue}
                        toDate={toDate}
                        setToDate={setToDateValue}
                        showTimeInDateInput
                    />
                </div>
                <div className='fcWrapper'>
                    <div className='frcfeWrapper mb-15'>
                        <div className='fmreview-idd__lb'>Select Payload</div>
                        <AppDropdown
                            ddWrapperCls={DD_WRAPPER_CLS}
                            defaultDDLabel='Select Payload Type'
                            ddContentCls={DD_CONTENT_CLS}
                            ddState={facematchPayloadType}
                            setDDState={setPayloadTypeValue}
                            ddList={QUEUE_PAYLOAD_TYPES}
                            ddItemCls={DD_ITEM_CLS}
                        />
                    </div>
                    <div className='frcfeWrapper mb-15'>
                        <div className='fmreview-idd__lb'>Sort By</div>
                        <AppDropdown
                            ddWrapperCls={DD_WRAPPER_CLS}
                            defaultDDLabel='Select Case Sorting'
                            ddContentCls={DD_CONTENT_CLS}
                            ddState={sortOption}
                            setDDState={setSortOption}
                            ddList={SORTING_OPTIONS}
                            ddItemCls={DD_ITEM_CLS}
                            onValueChange={onSortOptionValueChange}
                        />
                    </div>

                </div>
            </div>

            {
                !loadingFacematchCases && (
                    facematchCasesList.length > 0 ? (
                        <div className='table-sr table-sr--mt50'>
                            {/* header row */}
                            <div className='table-sr__thw'>
                                <div className='table-sr__th'>Sr. No.</div>
                                <div className='table-sr__th'>Actor ID</div>
                                <div className='table-sr__th'>Request ID</div>
                                <div className='table-sr__th'>Created At</div>
                                <div className='table-sr__th'>Review</div>
                            </div>
                            {/* table values */}
                            <div className='table-sr__tlc'>
                                {facematchCasesList.map((item, index) => {
                                    const { payload } = item;

                                    return (
                                        <div className='table-sr__tlw' key={item.id}>
                                            <div className='table-sr__tl'>{index + 1}</div>
                                            <div className='table-sr__tl'>{item[payload].actor_id}</div>
                                            <div className='table-sr__tl'>{item[payload].request_id}</div>
                                            <div className='table-sr__tl'>{item[payload].created_at}</div>
                                            <button
                                                className='link table-sr__tl'
                                                type='button'
                                                onClick={handleReviewClick({
                                                    ...item,
                                                    index,
                                                })}
                                            >
                                                Review
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className='fmreview-src'>
                            <div className='table-nsr table-nsr--mt0'>No facematch cases found!</div>
                        </div>
                    )
                )
            }
        </div>
    );
};

export default memo(AllFacematchCases);

import { createSelector } from 'reselect';

const selectFacematchKYCImage = state => state.get('fmkycImage');

const makeSelectLoading = () => createSelector(
    selectFacematchKYCImage,
    substate => substate.get('loading')
);

const makeSelectErr = () => createSelector(
    selectFacematchKYCImage,
    substate => substate.get('err')
);

const makeSelectImageInfo = () => createSelector(
    selectFacematchKYCImage,
    substate => substate.get('imageInfo').toJS()
);

const makeSelectMonorailInfo = () => createSelector(
    selectFacematchKYCImage,
    substate => substate.get('monorailInfo').toJS()
);

export {
    makeSelectLoading,
    makeSelectErr,
    makeSelectImageInfo,
    makeSelectMonorailInfo,
};

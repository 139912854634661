/**
 * @file AppV2/selectors.js Contains selectors for App
 *
 * This file was created due to cyclic dependency error in the App selectors when called in some files.
 */

import { createSelector } from 'reselect';

const selectApp = (state) => state.get('app');

const makeSelectAgentInfo = () => createSelector(
    selectApp,
    (substate) => substate.get('agentInfo').toJS(),
);

export {
    makeSelectAgentInfo,
};

/**
 * SherlockV2Generic - A generic component layout for Sherlock V2
 */
import React, { memo, useState, useEffect, useContext } from 'react';
import {
    Redirect, useLocation, useHistory, matchPath,
} from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';

import Logo from '../../../../assets/images/pngs/logo.png';
import { setAccessLevel as setAccessLevelAction } from '../../../../containers/App/actions';
import { SEARCH_RESULT_TYPES } from '../../../../components/SearchBar/constants';
import { APP_V1_PATH } from '../../../../appGlobal/constants';
import { getBannerList } from '../../../../appGlobal/actions';
import { setAccessLevelInMetaTag } from '../../../../appGlobal/utils';
import { debounce } from '../../../../utils/helpers';
import { getRedirectionUrlFromGuideType, maskPhoneNumber } from '../../../../utils/formatter';
import { injectReducer, injectSaga } from '../../../../utils';
import { AppV2Header, ExpandableSidebar } from '../../../../components';
import { UserDetailsContext } from '../../../../context';
import { RudderEvent, pushRudderEvent } from '../../../../rudderEvents';
import { addMessageEventListener, postMessageEvent } from '../../../../messageEvents';

import { getSearchSopAction } from '../../../Search/actions';
import { selectSearchData } from '../../../Search/selectors';
import { selectCallerId, selectTicketId } from '../../../SOP/selector';

import Routes from '../../Routes/index';
import { ROLE_TO_NAVBAR_MAPPING } from '../../utils/pathsHelper';
import { SEARCH_PATH, VIEW_SOP_TREE_PATH, VIEW_SOP_USER_DETAILS_PATH, VIEW_SOP_USER_TICKETS_PATH } from '../../routes';
import { ACCESS_LEVEL_LIST } from '../../constants';
import NavItem from '../../NavItems';
import '../../style.scss';

import reducer from '../../reducer';
import saga from '../../saga';

const extractSidebarNameFromUrl = (pathname) => {
    const splitContent = pathname.split('/');
    return splitContent[2];
};

const SherlockV2GenericLayout = (props) => {
    const { isSideBarExpanded = true, isSearchBarVisible = true } = props;
    const { basicInfo: agentInfo, selectedAccessLevel } = useContext(UserDetailsContext);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const callerId = useSelector(selectCallerId, shallowEqual);
    const ticketId = useSelector(selectTicketId, shallowEqual);
    const [isExpanded, setIsExpanded] = useState(isSideBarExpanded);
    const [resultType, setResultType] = useState();
    const [headerText, setHeaderText] = useState('');

    useEffect(() => {
        // Tab Open Rudder Event
        pushRudderEvent(RudderEvent.SherlockV2.SherlockV2TabOpen, agentInfo.emailId, {});

        // Track when the tab becomes inactive
        const handleTabInactive = () => {
            if (document.hidden) {
                pushRudderEvent(RudderEvent.SherlockV2.SherlockV2TabInActive, agentInfo.emailId, {});
            }
        };
        document.addEventListener('visibilitychange', handleTabInactive);

        // Track when the tab becomes active again
        const handleTabActive = () => {
            if (!document.hidden) {
                pushRudderEvent(RudderEvent.SherlockV2.SherlockV2TabActive, agentInfo.emailId, {});
            }
        };
        document.addEventListener('visibilitychange', handleTabActive);

        // Track when the tab is closed
        const handleTabClose = () => {
            pushRudderEvent(RudderEvent.SherlockV2.SherlockV2TabClosed, agentInfo.emailId, {});
        };
        window.addEventListener('beforeunload', handleTabClose);

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener('visibilitychange', handleTabInactive);
            document.removeEventListener('visibilitychange', handleTabActive);
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, []);
    const { search: queryString } = useLocation();

    const searchParams = new URLSearchParams(queryString);
    const search = searchParams.get('query') || '';
    const resultTypeParam = searchParams.get('type') || '';

    const searchResults = useSelector(selectSearchData, shallowEqual);

    // Returns the object for the specific filter param in the given options list
    const getFilter = (list, value) => (list.find((item) => (item?.value === value)));

    const getMatch = (path) => matchPath(location.pathname, { path, exact: true });

    useEffect(() => {
        const match = getMatch(VIEW_SOP_TREE_PATH) || getMatch(VIEW_SOP_USER_DETAILS_PATH()) || getMatch(VIEW_SOP_USER_TICKETS_PATH);
        if (match) {
            switch (match.path) {
                case VIEW_SOP_TREE_PATH:
                    setHeaderText(ticketId);
                    break;
                case VIEW_SOP_USER_DETAILS_PATH():
                    setHeaderText(`Ticket ID ${ticketId}`);
                    break;
                case VIEW_SOP_USER_TICKETS_PATH:
                    setHeaderText(maskPhoneNumber(callerId));
                    break;
                default:
                    setHeaderText(extractSidebarNameFromUrl(location.pathname));
                    break;
            }
        } else {
            setHeaderText(extractSidebarNameFromUrl(location.pathname));
        }
    }, [location.pathname, ticketId, callerId]);

    useEffect(() => {
        const beforeUnloadEvent = () => { // Stores the current access level before unloading/refresh in the local Storage
            sessionStorage.setItem('access_level', selectedAccessLevel);
        };
        // Add the event listener to listen to the event of unloading. It triggers the function before unloading.
        window.addEventListener('beforeunload', beforeUnloadEvent);
        return () => window.removeEventListener('beforeunload', beforeUnloadEvent);
    }, [selectedAccessLevel]);

    useEffect(() => {
        if (!selectedAccessLevel || selectedAccessLevel === '') {
            if (sessionStorage.getItem('access_level')) { // Gets the stored access level if present and deletes it from local storage
                setAccessLevel(sessionStorage.getItem('access_level'));
                sessionStorage.removeItem('access_level');
                return () => { };
            }
            if (window.opener && window.opener.location.origin === window.location.origin) {
                postMessageEvent(window.opener, { provideAccessLevel: true }, window.opener.origin);
                const removeMessageListener = addMessageEventListener((event) => {
                    const { accessLevel } = event.data || {};
                    if (accessLevel && accessLevel !== '') {
                        setAccessLevel(accessLevel);
                    } else {
                        history.replace({
                            pathname: APP_V1_PATH,
                            state: {
                                prevPath: `${location.pathname}${location.search}`,
                            },
                        });
                    }
                }, true);
                return removeMessageListener;
            }
            history.replace({
                pathname: APP_V1_PATH,
                state: {
                    prevPath: `${location.pathname}${location.search}`,
                },
            });
        }
        return () => { };
    }, [selectedAccessLevel]);

    useEffect(() => {
        if (resultTypeParam) {
            setResultType(getFilter(SEARCH_RESULT_TYPES, resultTypeParam));
        } else {
            setResultType();
        }
    }, [resultTypeParam]);

    const navHeader = () => (
        <React.Fragment>
            <img className='app__v2-nav-img-logo' src={Logo} alt='Logo' />
            <div className={`app__v2-nav-text ${!isExpanded ? 'app__v2-nav-text__hide' : ''}`}>Sherlock</div>
        </React.Fragment>
    );

    const hasAccess = (accessList) => (
        ACCESS_LEVEL_LIST?.some((accessLevel) => (
            accessList?.some((access) => JSON.stringify(access) === JSON.stringify(accessLevel))
        ))
    );

    if (agentInfo?.accessLevelList && !hasAccess(agentInfo?.accessLevelList)) {
        return <Redirect to={{ pathname: APP_V1_PATH }} />;
    }

    const setAccessLevel = (accessLevel) => {
        // validates the fetched access level
        const validAccessLevel = ACCESS_LEVEL_LIST.find((item) => item?.value === accessLevel);
        if (validAccessLevel) {
            dispatch(setAccessLevelAction({ accessLevel }));
            // need to fetch banners for the selected access level
            dispatch(getBannerList({ roles: [accessLevel] }));

            // set access level in meta tag to read it while making API requests
            setAccessLevelInMetaTag(accessLevel);
        } else {
            history.replace({
                pathname: APP_V1_PATH,
            });
        }
    };
    const handleOnSubmitSearchValue = (value, selectedType, isClear = false) => {
        const newSearchParams = new URLSearchParams();
        if (!(isClear || !value)) newSearchParams.set('query', value);
        if (selectedType) newSearchParams.set('type', selectedType?.value);
        if (newSearchParams.toString() !== queryString) {
            history.replace({
                pathname: SEARCH_PATH,
                search: newSearchParams.toString(),
                state: {
                    noDataFetch: true,
                },
            });
        }
    };

    const handleOnChange = (value, type, isModal) => {
        if (!isModal && !value) {
            searchParams.delete('query');
            history.replace({
                pathname: SEARCH_PATH,
                search: searchParams.toString(),
            });
        }
        dispatch(getSearchSopAction({
            query: value, resultType: type?.value, screenKey: ['tempResults'], paginationParams: { size: 5 },
        }));
    };

    const handleOnTypeChange = (type, query, isPopUp) => {
        if (isPopUp) {
            dispatch(getSearchSopAction({
                query, resultType: type?.value, screenKey: ['tempResults'], paginationParams: { size: 5 },
            }));
        } else {
            setResultType(type);
            if (type) searchParams.set('type', type?.value);
            else searchParams.delete('type');
            history.replace({
                pathname: SEARCH_PATH,
                search: searchParams.toString(),
            });
        }
    };

    const { tempResults } = searchResults || {};
    const { loading: searchResultsLoading, data: tempData } = tempResults || {};
    const searchResultsValue = (
        tempData?.rowData?.map((rows) => ({
            value: rows['SOP Name'][rows['SOP Name']?.value_v2],
            meta: rows?.meta,
            type: rows?.Type?.[rows?.Type?.value_v2] || '',
        })) || []
    );

    const debounceOnChangeHandler = debounce(handleOnChange, 1000);

    const resultOnClick = (value) => {
        const guideType = value?.type?.toLowerCase();
        history.push({
            pathname: getRedirectionUrlFromGuideType(guideType, value?.meta),
            state: { prevPath: location.pathname },
        });
    };

    return (
        <div className='app__v2'>
            <ExpandableSidebar
                left
                data={{
                    header: navHeader,
                    isExpanded,
                    setIsExpanded,
                }}
                extClasses={{
                    container: 'app__v2-nav-cr',
                    hideContainer: 'app__v2-nav-cr__hide',
                    headerContent: 'app__v2-nav-hr',
                    item: 'app__v2-nav-items-cont',
                    arrow: {
                        'app__v2-nav-hr-arrow': isExpanded,
                        'app__v2-nav-hr-arrow__hide': !isExpanded,
                    },
                }}
            >
                {
                    Object.keys(ROLE_TO_NAVBAR_MAPPING?.[selectedAccessLevel] || [])
                        ?.filter((NavbarKey) => NavbarKey !== 'DEFAULT')
                        ?.map((NavbarKey) => (
                            <NavItem
                                item={ROLE_TO_NAVBAR_MAPPING?.[selectedAccessLevel]?.[NavbarKey]}
                                isExpanded={isExpanded}
                                key={ROLE_TO_NAVBAR_MAPPING?.[selectedAccessLevel]?.[NavbarKey]?.path}
                            />
                        ))
                }
            </ExpandableSidebar>
            <div className={isExpanded ? 'app__v2-cr' : 'app__v2-cr-full'}>
                <AppV2Header
                    agentInfo={agentInfo}
                    heading={headerText}
                    searchOptions={{
                        placeholder: 'Search SOPs & Scripts',
                        value: search,
                        setValue: handleOnSubmitSearchValue,
                        resultClickHandler: resultOnClick,
                        onChangeHandler: debounceOnChangeHandler,
                        resultsLoading: searchResultsLoading,
                        resultsValue: searchResultsValue,
                        isSearchBarVisible,
                        resultType,
                        onResultTypeChange: handleOnTypeChange,
                        handleSearchBarClick: () => {
                            if (location.pathname !== SEARCH_PATH) {
                                history.push(SEARCH_PATH);
                            }
                        },
                    }}
                />
                <Routes />
            </div>
        </div>
    );
};

const withReducer = injectReducer({ key: 'app_v2', reducer });
const withSaga = injectSaga({ key: 'app_v2', saga });

export default compose(
    withReducer,
    withSaga,
)(memo(SherlockV2GenericLayout));

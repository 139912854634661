import clientApiWrapper from './clientAPIWrapper';
import dateUtils, { getInitialDateRange } from './date';
import { getErrLabel, getErrMsg, getErrorObjectForSaga, showErrorToastMsg } from './errFormatter';
import { getQueryStringFromObject } from './formatter';
import injectReducer from './injectReducer';
import injectSaga from './injectSaga';
import toastify from './toastify';
import validators from './validators';
import { getDropdownHeight, getSideNavbarItems } from './uiHelpers';
import downloadFile from './downloadFile';
import {
    getEmptyValueFlag, getRequiredFieldsFilledFlag, getModifiedParameterList, checkRequiredFieldForOneItem,
    getModifiedParameterListV2, getEncodingCompatibilty,
} from './parameterListHandler';
import { onFileClick, onFileChange } from './fileUploadEvents';
import { setCookie, readCookie, deleteCookie } from './cookies';
import { isObjEmpty, isNilOrEmpty, checkObjectsEquality } from './helpers';
import { domain1, domain2 } from './constants';
import { getEnvironmentFromURL, isFeatureAccessibleInEnvironments, getStrapiLoginURL } from './environmentHelper';

const isDevEnviroment = () => {
    const url = new URL(window.location.href);
    return !(url.hostname === domain1 || url.hostname === domain2);
};

export {
    checkObjectsEquality,
    clientApiWrapper,
    dateUtils,
    getInitialDateRange,
    getQueryStringFromObject,
    injectReducer,
    injectSaga,
    toastify,
    validators,
    getDropdownHeight,
    downloadFile,
    getEmptyValueFlag,
    getRequiredFieldsFilledFlag,
    getEncodingCompatibilty,
    getModifiedParameterList,
    checkRequiredFieldForOneItem,
    onFileClick,
    onFileChange,
    setCookie,
    readCookie,
    deleteCookie,
    isObjEmpty,
    isNilOrEmpty,
    // errFormatter starts
    getErrMsg,
    getErrLabel,
    getErrorObjectForSaga,
    showErrorToastMsg,
    // errFormatter ends
    isDevEnviroment,
    getSideNavbarItems,
    getEnvironmentFromURL,
    getStrapiLoginURL,
    isFeatureAccessibleInEnvironments,
    getModifiedParameterListV2,
};

/**
 * @file SalaryAccountDetails
 * View the salary account details for a particular user by passing either actor ID, phone number or email ID
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedSelector } from '../../../hooks';
import { Loader } from '../../../components';

import { SALARY_ACCOUNT_DETAILS_KEY, SALARY_ACCOUNT_OPS_KEY, UPDATE_EMPLOYER_NAME, VIEW_SALARY_ACCOUNT_DETAILS } from '../constants';
import {
    selectSalaryAccountOpsCurView, selectSalaryAccountOpsErr, selectSalaryAccountOpsLoading, selectSalaryAccountUserIdentifierDetails,
    selectViewSalaryAccountData,
} from '../selectors';
import { setErrInSalaryOpsAction, setSalaryAccountOpsCurViewAction, updateEmployerDetailsAction } from '../actions';

import UpdateEmployerName from '../UpdateEmployerName';
import ViewSalaryAccountDetails from '../ViewSalaryAccountDetails';

const RenderCurView = ({ curView }) => {
    const dispatch = useDispatch();

    const userIdentifier = useMemoizedSelector(selectSalaryAccountUserIdentifierDetails, [SALARY_ACCOUNT_DETAILS_KEY]);
    const salaryData = useMemoizedSelector(selectViewSalaryAccountData, [SALARY_ACCOUNT_OPS_KEY]);

    const onEmployerDetailsSubmit = (selectedEmployerDetails) => {
        const emailId = salaryData?.employmentInfo?.unmaskedEmailId;

        if (!emailId) {
            setErrInSalaryOpsAction({
                err: 'User\'s Email ID is not set, please go back and search again',
                screenKey: SALARY_ACCOUNT_DETAILS_KEY,
            });
            return;
        }

        dispatch(updateEmployerDetailsAction({
            employerId: selectedEmployerDetails.employer_id,
            emailId,
            userIdentifier,
        }));
    };

    const onBackClick = () => {
        dispatch(setSalaryAccountOpsCurViewAction({ screenKey: SALARY_ACCOUNT_DETAILS_KEY, value: VIEW_SALARY_ACCOUNT_DETAILS }));
    };

    switch (curView) {
        case VIEW_SALARY_ACCOUNT_DETAILS: {
            return (
                <ViewSalaryAccountDetails
                    isSalaryDataOps
                    screenKey={SALARY_ACCOUNT_OPS_KEY}
                />
            );
        }

        case UPDATE_EMPLOYER_NAME: {
            return (
                <UpdateEmployerName
                    onEmployerDetailsSubmit={onEmployerDetailsSubmit}
                    onBackClick={onBackClick}
                />
            );
        }

        default: {
            return null;
        }
    }
};

const SalaryAccountDetails = () => {
    const loading = useMemoizedSelector(selectSalaryAccountOpsLoading, [SALARY_ACCOUNT_DETAILS_KEY]);
    const err = useMemoizedSelector(selectSalaryAccountOpsErr, [SALARY_ACCOUNT_DETAILS_KEY]);
    const curView = useMemoizedSelector(selectSalaryAccountOpsCurView, [SALARY_ACCOUNT_DETAILS_KEY]);

    return (
        <React.Fragment>
            <RenderCurView
                curView={curView}
            />
            {err && <div className='err-label salops-err'>{err}</div>}
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(SalaryAccountDetails);

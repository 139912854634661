// monorail id
export const SET_MONORAIL_ID = 'app/UDBI/SET_MONORAIL_ID';

// user details by identifier
export const GET_USER_DETAILS = 'app/UDBI/GET_USER_DETAILS';
export const SET_USER_DETAILS = 'app/UDBI/SET_USER_DETAILS';
export const ERR_USER_DETAILS = 'app/UDBI/ERR_USER_DETAILS';
export const SET_LOCAL_USER_DETAILS = 'app/UDBI/SET_LOCAL_USER_DETAILS';
export const SET_ACTIVE_ID = 'app/UDBI/SET_ACTIVE_ID';
export const CLOSE_NAV_ITEM = 'app/UDBI/CLOSE_NAV_ITEM';

/**
 * @file KYCOnboarding Contains rudder event names for KYCOnboarding Screen
 */

const KYCMainScreenCopyButtonClick = 'KYCMainScreenCopyButtonClick';
const InAppScreenDetailsCopyButtonClick = 'InAppScreenDetailsCopyButtonClick';
const KYCDetailsCopyButtonClick = 'KYCDetailsCopyButtonClick';
const OnboardingDetailsCopyButtonClick = 'OnboardingDetailsCopyButtonClick';

export {
    KYCMainScreenCopyButtonClick,
    InAppScreenDetailsCopyButtonClick,
    KYCDetailsCopyButtonClick,
    OnboardingDetailsCopyButtonClick,
};

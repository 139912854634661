import {
    all, call, put, takeLatest,
} from 'redux-saga/effects';
import {
    clientApiWrapper, getErrLabel, getErrMsg, getQueryStringFromObject, toastify,
} from '../../utils';
import {
    COMPLETE_SOP_FLOW_ENDPOINT, GET_PROFILE_ENDPOINT, GET_SOP_DETAILS_ENDPOINT, GET_SOP_TREE_ENDPOINT, TICKET_LIST_FOR_CALL_ENDPOINT,
    GET_RECENT_ACTIVITY_ENDPOINT, GET_RECENT_ACTIVITY_DETAILS_ENDPOINT, GET_ISSUE_CATEGORY_DETAILS_ENDPOINT,
} from '../../api/routes';
import {
    COMPLETE_SOP_FLOW, GET_PROFILE, GET_SOP_DETAILS, GET_SOP_NODE, STEP_TYPE, GET_USER_TICKETS,
    GET_RECENT_ACTIVITIES, GET_RECENT_ACTIVITY_DETAILS, GET_ISSUE_CATEGORY_DETAILS,
} from './constants';
import {
    completedSopFlowAction, errInCompletingSopFlowAction, errProfileAction, errSOPDetailsAction,
    errSOPNodeAction, setProfileAction, setSOPDetailsAction, setSOPNodeAction, setUserTicketsAction, errUserTicketsAction,
    setActivitiesAction, errActivitiesAction, setActivityDetailsAction, errActivityDetailsAction, setIssueCategoryDetailsAction,
    errIssueCategoryDetailsAction,
} from './actions';

function* getProfileSaga(action) {
    const { ticketId, resolve } = action.data;
    const queryString = getQueryStringFromObject({ ticketId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_PROFILE_ENDPOINT}?${queryString}`,
        );

        yield put(setProfileAction({ screenKey: ['userDetails'], value: { data: response } }));
        if (resolve) yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errProfileAction({ screenKey: ['userDetails'], err: getErrMsg(e) }));
    }
}

function* getActivitiesSaga(action) {
    const { ticketId, prevToken, resolve } = action.data;
    const toDate = new Date();
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 365);
    const queryString = getQueryStringFromObject({
        ticketId,
        areas: [{ label: 'ALL', value: 'All' }],
        fromDate,
        toDate,
        prevToken,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_RECENT_ACTIVITY_ENDPOINT}?${queryString}`,
        );
        yield put(setActivitiesAction({ screenKey: ['userActivities'], value: { data: response } }));
        if (resolve) yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errActivitiesAction({ screenKey: ['userActivities'], err: getErrMsg(e) }));
    }
}

function* getActivityDetailsSaga(action) {
    const { ticketId, meta, resolve } = action.data;
    const queryString = getQueryStringFromObject({
        ticketId, meta,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_RECENT_ACTIVITY_DETAILS_ENDPOINT}?${queryString}`,
        );
        yield put(setActivityDetailsAction({ screenKey: ['userActivities', 'activityDetails'], value: { data: response?.sections } }));
        if (resolve) yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errActivityDetailsAction({ screenKey: ['userActivities', 'activityDetails'], err: getErrMsg(e) }));
    }
}

function* getIssueCategoryDetailsSaga(action) {
    const { meta, resolve } = action.data;
    const queryString = getQueryStringFromObject({
        meta,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_ISSUE_CATEGORY_DETAILS_ENDPOINT}?${queryString}`,
        );
        yield put(setIssueCategoryDetailsAction({ screenKey: ['userActivities', 'issueCategoryDetails'], value: { data: response } }));
        if (resolve) yield call(resolve, response);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errIssueCategoryDetailsAction({ screenKey: ['userActivities', 'issueCategoryDetails'], err: getErrMsg(e) }));
    }
}

function* getUserTicketsSaga(action) {
    const {
        callerUserId, fetchFromSource, resolve, statusList,
    } = action.data;
    const queryString = getQueryStringFromObject({ callerUserId, fetchFromSource, statusList });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKET_LIST_FOR_CALL_ENDPOINT}?${queryString}`,
        );
        yield put(setUserTicketsAction({ screenKey: ['userTickets'], value: { data: response } }));
        if (resolve) yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errUserTicketsAction({ screenKey: ['userTickets'], err: getErrMsg(e) }));
    }
}

function* getSOPDetailsSaga(action) {
    const { sopId, resolve } = action.data;
    const queryString = getQueryStringFromObject({ sopId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_SOP_DETAILS_ENDPOINT}?${queryString}`,
        );
        yield put(setSOPDetailsAction({ screenKey: 'tree', value: response }));
        const { data } = response;
        const { sourceNode, title } = data || {};
        if (resolve) yield call(resolve, { sourceNode, title });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errSOPDetailsAction({ screenKey: 'tree', err: getErrMsg(e) }));
    }
}

function* getSOPNodeSaga(action) {
    const {
        sopId, nodeId, resolve, ticketId, isSecondRpcCall, rpcsToBeCalled,
    } = action.data;
    const queryString = getQueryStringFromObject({
        sopId, nodeId, ticketId, isSecondRpcCall, rpcsToBeCalled,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_SOP_TREE_ENDPOINT}?${queryString}`,
        );
        yield put(setSOPNodeAction({ value: response }));
        if (resolve) yield call(resolve);
        if (response?.stepType === STEP_TYPE.infoDisplay) {
            const { errorRpcs } = response?.step || {};
            if (errorRpcs && Array.isArray(errorRpcs) && errorRpcs.length > 0) {
                const error = new Error();
                error.errorRpcs = errorRpcs;
                throw error;
            }
        }
    } catch (e) {
        const { errorRpcs } = e || {};
        if (errorRpcs && Array.isArray(errorRpcs)) {
            errorRpcs.forEach((rpcErr) => {
                const { error } = rpcErr;
                const err = getErrLabel(error);
                toastify(err, 'error');
            });
            return;
        }
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errSOPNodeAction({ screenKey: ['tree', 'nodes'], err: getErrMsg(e) }));
    }
}

function* completeSopFlowSaga(action) {
    const {
        sopId, userResponses, resolve, ticketId,
    } = action.data;
    const queryString = getQueryStringFromObject({ sopId, ticketId });
    const payload = {
        userResponses,
    };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${COMPLETE_SOP_FLOW_ENDPOINT}?${queryString}`,
            payload,
        );

        if (response.status) {
            if (response.status.code === 0) {
                yield put(completedSopFlowAction({ screenKey: ['tree'] }));
                toastify(response.status?.short_message || 'Successfully Uploaded', 'success');
                if (resolve) yield call(resolve);
            } else {
                throw new Error(response.status?.short_message);
            }
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errInCompletingSopFlowAction({ screenKey: ['tree'], err: getErrMsg(e) }));
    }
}

export default function* ScriptsSaga() {
    yield all(
        [
            yield takeLatest(GET_PROFILE, getProfileSaga),
            yield takeLatest(GET_RECENT_ACTIVITIES, getActivitiesSaga),
            yield takeLatest(GET_RECENT_ACTIVITY_DETAILS, getActivityDetailsSaga),
            yield takeLatest(GET_ISSUE_CATEGORY_DETAILS, getIssueCategoryDetailsSaga),
            yield takeLatest(GET_USER_TICKETS, getUserTicketsSaga),
            yield takeLatest(GET_SOP_DETAILS, getSOPDetailsSaga),
            yield takeLatest(GET_SOP_NODE, getSOPNodeSaga),
            yield takeLatest(COMPLETE_SOP_FLOW, completeSopFlowSaga),
        ],
    );
}

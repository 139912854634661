/**
 *
 * @file Facematch Review flow
 * User can see the existing cases for facematch
 * User can select a case for review
 * For review, user can view the facematch image and update facematch status for that case
 *
 */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { HorizontalIdNavbar, Loader } from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import {
    selectUSSFMLoading, selectUSSFMErr, selectUSSFMPayloadTypeList, selectUSSFMFacematchView,
} from './selectors';
import {
    getUssFmPayloadTypeListAction, getUssFmReviewItemDetailsAction, setUssFmFacematchViewAction,
} from './actions';
import { VIEW_SINGLE_FACEMATCH_CASE } from './constants';

import reducer from './reducer';
import saga from './saga';

import './style.scss';

import RenderView from './RenderView';

const USStocksOps = () => {
    const dispatch = useDispatch();

    // global state
    const loading = useSelector(selectUSSFMLoading, shallowEqual);
    const err = useSelector(selectUSSFMErr, shallowEqual);
    const payloadTypesList = useSelector(selectUSSFMPayloadTypeList, shallowEqual);
    const facematchView = useSelector(selectUSSFMFacematchView, shallowEqual);

    const [reviewId, setReviewId] = useState();

    useEffect(() => {
        if (!payloadTypesList.length) {
            dispatch(getUssFmPayloadTypeListAction());
        }
    }, [payloadTypesList, dispatch]);

    const handleReviewClick = (id) => {
        setReviewId(id);
        dispatch(getUssFmReviewItemDetailsAction({ reviewId: id }));
        dispatch(setUssFmFacematchViewAction(VIEW_SINGLE_FACEMATCH_CASE));
    };

    return (
        <React.Fragment>
            <HorizontalIdNavbar isFixed isEmptyNavbar />
            <div className='app-ci'>
                <RenderView facematchView={facematchView} handleReviewClick={handleReviewClick} reviewId={reviewId} />
            </div>

            <div className='ussfm-cr'>
                {err && <div className='ussfm-crel err-label'>{err}</div>}
            </div>

            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'ussFacematchReview', reducer });
const withSaga = injectSaga({ key: 'ussFacematchReviewSaga', saga });

export default compose(
    withReducer,
    withSaga,
)(USStocksOps);

/*
 *
 * Pan name match review selectors
 *
 */

import { createSelector } from 'reselect';

export const selectLoading = () => createSelector(
    (state) => state.getIn(['namematchReview', 'loading']),
    (subState) => subState,
);

export const selectErr = () => createSelector(
    (state) => state.getIn(['namematchReview', 'err']),
    (subState) => subState,
);

export const selectNamematchCasesList = () => createSelector(
    (state) => state.getIn(['namematchReview', 'namematchCasesList']),
    (subState) => subState.toJS(),
);

export const selectNamematchView = () => createSelector(
    (state) => state.getIn(['namematchReview', 'namematchView']),
    (subState) => subState,
);

export const selectCurNamematchCase = () => createSelector(
    (state) => state.getIn(['namematchReview', 'curNamematchCase']),
    (subState) => subState.toJS(),
);

export const selectNamematchPayloadType = () => createSelector(
    (state) => state.getIn(['namematchReview', 'namematchPayloadType']),
    (subState) => subState.toJS(),
);

export const selectNamematchPendingReviewCount = () => createSelector(
    (state) => state.getIn(['namematchReview', 'pendingReviewCount']),
    (subState) => subState,
);

export const selectNamematchQueueFilters = () => createSelector(
    (state) => state.getIn(['namematchReview', 'queueFilters']),
    (subState) => subState.toJS(),
);

/**
 *
 * UserActions
 *
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

import {
    BA_CALL_USER_ACTION_TICKET_PATH, BA_PAY_USER_ACTION_TICKET_PATH, BA_EXPORT_HEALTH_DATA_ACTION_TICKET_PATH,
} from '../App/routes';

import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';

import CallUser from './CallUser';
import PayUser from './PayUser';
import ExportHealthData from './ExportHealthData';

import reducer from './reducer';
import saga from './saga';


const UserActions = (props) => {
    const getCommonProps = () => {
        const { ticketId, index, userId, phoneNumber, name } = props;

        return {
            index,
            ticketId,
            userId,
            phoneNumber,
            name
        };
    }

    return (
        <React.Fragment>
            <Switch>
                <Route
                    path={BA_CALL_USER_ACTION_TICKET_PATH}
                    render={() => (<CallUser {...getCommonProps()} />)}
                />
                <Route
                    path={BA_PAY_USER_ACTION_TICKET_PATH}
                    render={() => (<PayUser {...getCommonProps()} />)}
                />
                <Route
                    path={BA_EXPORT_HEALTH_DATA_ACTION_TICKET_PATH}
                    render={() => (<ExportHealthData ticketId={props.ticketId} />)}
                />
            </Switch>
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'userActions', reducer });
const withSaga = injectSaga({ key: 'userActions', saga });

export default compose(
    withReducer,
    withSaga,
)(UserActions);

/**
 * @file Constants for Agent FeedbackDetails
 */

import { getInitialDateRange } from '../../utils';

// action & reducer constants
export const AGENT_FB_DETAILS_KEY = 'agentFeedbackDetails';

export const GET_AGENT_FB_DETAILS = `app/${AGENT_FB_DETAILS_KEY}/GET_DETAILS`;
export const SET_AGENT_FB_DETAILS = `app/${AGENT_FB_DETAILS_KEY}/SET_DETAILS`;
export const ERR_AGENT_FB_DETAILS = `app/${AGENT_FB_DETAILS_KEY}/ERR_DETAILS`;

export const FEEDBACK_DETAILS_LABEL_DATA = [
    {
        id: 1,
        label: 'ID',
        key: 'id',
    },
    {
        id: 2,
        label: 'Feedback Category',
        key: 'feedback_category',
    },
    {
        id: 3,
        label: 'Feedback',
        key: 'feedback_message',
    },
    {
        id: 4,
        label: 'Agent',
        key: 'agent_email',
    },
    {
        id: 5,
        label: 'ID Type',
        key: 'feedbackIdentifierType',
    },
    {
        id: 6,
        label: 'Ticket ID',
        key: 'feedback_identifier_value',
    },
    {
        id: 7,
        label: 'Frequency',
        key: 'frequency',
    },
    {
        id: 8,
        label: 'Urgent',
        key: 'isUrgent',
    },
    {
        id: 9,
        label: 'Highlighted Before',
        key: 'isHighlighted',
    },
];

export const FEEDBACK_CATEGORIES = [
    {
        label: 'FEATURE REQUEST',
        value: '1',
    },
    {
        label: 'REPORT PRODUCT ISSUE',
        value: '2',
    },
    {
        label: 'REPORT SHERLOCK ISSUE',
        value: '3',
    },
    {
        label: 'USER OPINION',
        value: '4',
    },
    {
        label: 'USER RESEARCH',
        value: '5',
    },
];

export const DATE_PICKER_CLS = {
    container: 'af-details-cont-date',
    label: '',
};

export const INITIAL_DATE_RANGE = getInitialDateRange();

// form constants
export const FROM_DATE_INFO = {
    label: 'From Date*',
    labelId: 'agent-feedback-from-date',
};

export const TO_DATE_INFO = {
    label: 'To Date*',
    labelId: 'agent-feedback-to-date',
};

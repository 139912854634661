/**
 *
 * @file SingleFacematchCase : Review a single facematch case for specific review id and update the status of the review item.
 *
 */

import React, { memo, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';
import { Button, DisableContextMenu, FormDropdown, KVPairInfo } from '../../../components';
import { UserDetailsContext } from '../../../context';

import {
    setUssFmFacematchViewAction, setUssFmReviewActionTypeAction, setUssFmReviewAsDoneAction, setUssFmReviewReasonTypeAction,
} from '../actions';
import {
    VIEW_ALL_FACEMATCH_CASES,
} from '../constants';
import {
    selectUSSFMReviewItemDetail, selectUSSFMReviewActionList, selectUSSFMReviewReasonList, selectUSSFMReviewActionType, selectUSSFMReviewReasonType,
} from '../selectors';
import { renderReviewItemDetail } from '../utils';

const SingleFacematchCase = (props) => {
    const { reviewId } = props;

    const dispatch = useDispatch();

    const { basicInfo } = useContext(UserDetailsContext);

    const reviewItemDetails = useSelector(selectUSSFMReviewItemDetail, shallowEqual);
    const reviewActionList = useSelector(selectUSSFMReviewActionList, shallowEqual);
    const reviewReasonList = useSelector(selectUSSFMReviewReasonList, shallowEqual);
    const selectedAction = useSelector(selectUSSFMReviewActionType, shallowEqual);
    const selectedReason = useSelector(selectUSSFMReviewReasonType, shallowEqual);

    const { emailId } = basicInfo;

    const onBackArrowCTA = () => {
        dispatch(setUssFmFacematchViewAction(VIEW_ALL_FACEMATCH_CASES));
    };

    const handleActionChange = (value) => {
        dispatch(setUssFmReviewActionTypeAction(value));
    };

    const handleReasonChange = (value) => {
        dispatch(setUssFmReviewReasonTypeAction(value));
    };

    const setReviewAsDone = () => {
        dispatch(setUssFmReviewAsDoneAction({
            reviewId, reviewAction: selectedAction.value, reviewReason: selectedReason.value, reviewedBy: emailId,
        }));

        dispatch(setUssFmFacematchViewAction(VIEW_ALL_FACEMATCH_CASES));
    };

    return (
        <React.Fragment>
            <div>
                <div className='ussfm-hl--cr'>
                    <button
                        className='ussfm-backarrow--cr'
                        type='button'
                        onClick={onBackArrowCTA}
                    >
                        <img
                            className='ussfm-backarrow'
                            alt='back-arrow'
                            src={backArrowIcon}
                        />
                    </button>
                    <div className='ussfm-hl-main-heading'>Review Facematch Case</div>
                </div>
            </div>

            {reviewItemDetails && (
                <div className='ussfm-cr'>
                    <KVPairInfo data={renderReviewItemDetail(reviewItemDetails)} />
                    <DisableContextMenu>
                        <div className='ussfm-img--cr'>
                            <div className='ussfm-img--itmleft'>
                                <div className='ussfm-hl-image-heading'>Liveness Image</div>
                                {reviewItemDetails.liveness_image.image_data_base64 && (
                                    <img
                                        src={`data:image/jpeg;base64,${reviewItemDetails.liveness_image.image_data_base64}`}
                                        height='400'
                                        alt='video-frame'
                                    />
                                )}
                            </div>
                            <div className='ussfm-img--itmright'>
                                <div className='ussfm-hl-image-heading'>Pan Image</div>
                                {reviewItemDetails.pan_image.image_url && <img src={reviewItemDetails.pan_image.image_url} height='400' alt='kyc' />}
                            </div>
                        </div>
                    </DisableContextMenu>

                    <div className='frcWrapper devactions-sc-rw1'>
                        <FormDropdown
                            label='Action'
                            options={reviewActionList}
                            input={{
                                value: selectedAction,
                                onChange: handleActionChange,
                                placeholder: 'Choose...',
                            }}
                            cacheKey='ussfm-action-dropdown'
                            extStyles={{
                                container: 'ussfm-fcwsd',
                                label: 'ussfm-fcwsd__lb ussfm-action',
                            }}
                        />
                    </div>

                    <div className='frcWrapper devactions-sc-rw1'>
                        <FormDropdown
                            label='Reason'
                            options={reviewReasonList}
                            input={{
                                value: selectedReason,
                                onChange: handleReasonChange,
                                placeholder: 'Choose...',
                            }}
                            cacheKey='devactions-action-dropdown'
                            extStyles={{
                                container: 'devactions-fcwsd',
                                label: 'devactions-fcwsd__lb',
                            }}
                        />
                    </div>

                    <Button
                        v2
                        primary
                        label='Submit'
                        onClick={setReviewAsDone}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default memo(SingleFacematchCase);

/**
 *
 * Account Closure
 *
 */

import React, { memo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Button, Loader } from '../../../components';

import { getAccountClosureInfo } from '../actions';
import { makeSelectAccountClosure } from '../selectors';

import './style.scss';

const AccountClosure = (props) => {
    const dispatch = useDispatch();
    const accountClosureData = useSelector(makeSelectAccountClosure(), shallowEqual);

    const { ticketId, userId, index } = props;

    let loading;
    let data;
    let hasData;

    if (accountClosureData[ticketId]) {
        ({ loading, data, hasData } = accountClosureData[ticketId]);
    }

    return (
        <div className='accountClosure'>
            {
                hasData ? (
                    <div>
                        <div className='accountClosure-rw'>
                            <div className='accountClosure-rw__hd'>Is Account closable?</div>
                            <div>{data.isClosable}</div>
                        </div>
                        {
                            data.reasons.length ? (
                                <div className='accountClosure-rw'>
                                    <div className='accountClosure-rw__hd'>Relevant Details / Pending Items</div>
                                    <div>
                                        {data.reasons.map(item => (
                                            <div className='accountClosure-rw__reasons'>-&nbsp;&nbsp;{item}</div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                ) : (
                    <Button
                        primary
                        label='Check Account Closure Eligibility'
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(getAccountClosureInfo({ ticketId, userId, index }));
                        }}
                        extClasses='accountClosure-cta'
                    />
                )
            }
            <Loader visible={loading} />
        </div>
    );
};

export default memo(AccountClosure);

/**
 * @file Manages the saga generator functions for Agent Management component
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify,
} from '../../utils';
import {
    AGENT_ROOT_V3, ACCESS_LEVEL_LIST_ENDPOINT, AGENT_TYPE_LIST_ENDPOINT, AGENT_STATUS_LIST_ENDPOINT,
    GET_AGENT_LIST_ENDPOINT,
} from '../../api/routes';

import { getAgentInfo } from '../App/saga';

import {
    GET_ACCESS_LEVEL_LIST, SET_ACCESS_LEVEL_LIST, GET_AGENT_TYPE_LIST, SET_AGENT_TYPE_LIST,
    GET_AGENT_STATUS_LIST, SET_AGENT_STATUS_LIST, GET_AGENT_LIST, SET_AGENT_LIST, ERR_AGENT_LIST,
    CREATE_AGENT, UPDATE_AGENT, SUCCESSFUL_AGENT_CREATED_LABEL, FAILURE_AGENT_CREATED_LABEL,
    SUCCESSFUL_AGENT_UPDATED_LABEL, FAILURE_AGENT_UPDATED_LABEL, SET_CREATE_OR_UPDATE_AGENT_LOADER,
} from './constants';

function* getAccessLevelList() {
    const response = yield call(
        [clientApiWrapper, clientApiWrapper.get],
        ACCESS_LEVEL_LIST_ENDPOINT,
    );

    yield put({ type: SET_ACCESS_LEVEL_LIST, data: response });
}

function* getAgentTypeList() {
    const response = yield call(
        [clientApiWrapper, clientApiWrapper.get],
        AGENT_TYPE_LIST_ENDPOINT,
    );

    yield put({ type: SET_AGENT_TYPE_LIST, data: response });
}

function* getAgentStatusList() {
    const response = yield call(
        [clientApiWrapper, clientApiWrapper.get],
        AGENT_STATUS_LIST_ENDPOINT,
    );

    yield put({ type: SET_AGENT_STATUS_LIST, data: response });
}

function* createAgent(action) {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            AGENT_ROOT_V3,
            action.data,
        );

        const { statusList } = response;

        statusList.forEach((status) => {
            const { success, error, label } = status;

            if (success) {
                toastify(`${SUCCESSFUL_AGENT_CREATED_LABEL} in ${label}`, 'success');
            } else {
                toastify(`${FAILURE_AGENT_CREATED_LABEL} in ${label} - ${error}`, 'error');
            }
        });

        yield put({ type: SET_CREATE_OR_UPDATE_AGENT_LOADER, data: { loading: false }});
        yield call(getAgentList, { data: {} });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_CREATE_OR_UPDATE_AGENT_LOADER, data: { loading: false }});
    }
}

function* updateAgent(action) {
    const { refreshAgentInfo } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.put],
            AGENT_ROOT_V3,
            action.data,
        );

        const { statusList } = response;

        statusList.forEach((status) => {
            const { success, error, label } = status;

            if (success) {
                toastify(`${SUCCESSFUL_AGENT_UPDATED_LABEL} in ${label}`, 'success');
            } else {
                toastify(`${FAILURE_AGENT_UPDATED_LABEL} in ${label} - ${error}`, 'error');
            }
        });

        yield put({ type: SET_CREATE_OR_UPDATE_AGENT_LOADER, data: { loading: false }});
        yield call(getAgentList, { data: {} });

        // get the logged-in agent info & send enableAgentManagementV2 flag as true because this API will be called only from v2 flow
        if (refreshAgentInfo) {
            yield call(
                getAgentInfo,
                {
                    data: {
                        enableAgentManagementV2: true,
                    },
                },
            );
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_CREATE_OR_UPDATE_AGENT_LOADER, data: { loading: false }});
    }
}

function* getAgentList(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_AGENT_LIST_ENDPOINT}?${queryString}`
        );

        yield put({ type: SET_AGENT_LIST, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_AGENT_LIST, data: { err: e.message } });
    }
}

export default function* agentManagementSaga() {
    yield all(
        [
            yield takeLatest(GET_ACCESS_LEVEL_LIST, getAccessLevelList),
            yield takeLatest(GET_AGENT_TYPE_LIST, getAgentTypeList),
            yield takeLatest(GET_AGENT_STATUS_LIST, getAgentStatusList),
            yield takeLatest(CREATE_AGENT, createAgent),
            yield takeLatest(UPDATE_AGENT, updateAgent),
            yield takeLatest(GET_AGENT_LIST, getAgentList),
        ]
    );
}

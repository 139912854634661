import {
    BIZ_ADMIN_USER_DETAILS_PATH, BIZ_ADMIN_BULK_USER_DETAILS_PATH,
} from '../App/routes';

export const getSideNavbarItems = () => [
    {
        id: 'get-user-details',
        label: 'Get User Details',
        path: BIZ_ADMIN_USER_DETAILS_PATH,
    },
    {
        id: 'get-bulk-user-details',
        label: 'Get Bulk User Details',
        path: BIZ_ADMIN_BULK_USER_DETAILS_PATH,
    },
];

import { fromJS } from 'immutable';

import {
    SET_MONORAIL_ID, GET_USER_DETAILS, SET_USER_DETAILS, ERR_USER_DETAILS, SET_LOCAL_USER_DETAILS, SET_ACTIVE_ID,
    CLOSE_NAV_ITEM,
} from './constants';

const initialState = fromJS({
    userDetails: {},
    activeId: '',
    navItems: [],
});

function userDetailsByIdReducer(state = initialState, action) {
    switch (action.type) {
        case SET_MONORAIL_ID: {
            const { monorailId } = action.data;
            const userDetails = state.get('userDetails').toJS();
            const userDetailsKeys = Object.keys(userDetails);

            // change the active id incase entered id is already present
            if (userDetailsKeys.includes(monorailId)) {
                return state.set('activeId', monorailId);
            }

            return state
                .set('activeId', monorailId)
                .setIn(['userDetails', monorailId], fromJS({}))
                .update('navItems', arr => arr.push(fromJS({ id: monorailId })));
        }

        case GET_USER_DETAILS: {
            const { monorailId } = action.data;

            return state
                .setIn(['userDetails', monorailId, 'loading'], true);
        }

        case SET_USER_DETAILS: {
            const { monorailId, userInfoById } = action.data;

            return state
                .setIn(['userDetails', monorailId, 'loading'], false)
                .setIn(['userDetails', monorailId, 'data'], fromJS(userInfoById))
                .setIn(['userDetails', monorailId, 'hasData'], true);
        }

        case ERR_USER_DETAILS: {
            const { monorailId } = action.data;

            return state
                .setIn(['userDetails', monorailId, 'loading'], false)
                .setIn(['userDetails', monorailId, 'data'], fromJS({}))
                .setIn(['userDetails', monorailId, 'hasData'], false);
        }

        case SET_LOCAL_USER_DETAILS: {
            const { monorailId, key, value } = action.data;

            return state
                .setIn(['userDetails', monorailId, key], value);
        }

        case SET_ACTIVE_ID: {
            const { id } = action.data;

            return state.set('activeId', id);
        }

        case CLOSE_NAV_ITEM: {
            const { id } = action.data;

            const navItems = state.get('navItems').toJS();
            const modifiedNavItems = navItems.filter(item => id !== item.id);

            return state
                .set('navItems', fromJS(modifiedNavItems))
                .removeIn(['userDetails', id]);
        }

        default:
            return state;
    }
}

export default userDetailsByIdReducer;

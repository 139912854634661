export const getTxnDetails = (data) => [
    {
        label: 'Remitter Bank Name',
        value: data.remitterBankName,
    },
    {
        label: 'Remitter Account Type',
        value: data.remitterAccountType,
    },
    {
        label: 'Remitter Account Number',
        value: data.remitterMaskedAccountNumber,
    },
    {
        label: 'Beneficiary Bank Name',
        value: data.beneficiaryBankName,
    },
    {
        label: 'Beneficiary Account Type',
        value: data.beneficiaryAccountType,
    },
    {
        label: 'Beneficiary Account Number',
        value: data.beneficiaryMaskedAccountNumber,
    },
    {
        label: 'Txn Source',
        value: data.order.provenance,
    },
    {
        label: 'Protocol Status',
        value: data.protocolStatus,
    },
    {
        label: 'From Instr No',
        value: data.actorId.from,
    },
    {
        label: 'To Instr No',
        value: data.actorId.to,
    },
    {
        label: 'Txn Updated At',
        value: data.txn.updatedTs,
    },
    {
        label: 'Txn Disputed At',
        value: data.txn.disputedTs,
    },
    {
        label: 'Partner Executed At',
        value: data.partnerTxnExecutedTs,
    },
    {
        label: 'Status',
        value: data.txn.status,
        cta: true,
    },
    {
        label: 'Remarks',
        value: data.remarks,
    },
    {
        label: 'Credit Particulars',
        value: data.creditParticulars,
    },
    {
        label: 'Debit Particulars',
        value: data.debitParticulars,
    },
];

export const getCreditCardTxnDetails = (data) => [
    {
        label: 'Partner Bank',
        value: data.partnerBank,
    },
    {
        label: 'From Instr No',
        value: data.actorId.from,
    },
    {
        label: 'To Instr No',
        value: data.actorId.to,
    },
    {
        label: 'Txn Updated At',
        value: data.txn.updatedTs,
    },
    {
        label: 'Txn Disputed At',
        value: data.txn.disputedTs,
    },
    {
        label: 'Settlement Status',
        value: data.settlementStatus,
    },
    {
        label: 'Eligible For Fi Coin Reward On CC',
        value: data.eligibleForFiCoinRewards,
    },
    {
        label: 'Type of Coins',
        value: data.typeOfCoins,
    },
    {
        label: 'Total Coins Earned',
        value: data.totalCoinsEarned,
    },
    {
        label: 'Merchant Name From Network',
        value: data.merchantNameFromNetwork,
    },
    {
        label: 'Merchant Name Identified By Fi',
        value: data.merchantName,
    },
    {
        label: 'description',
        value: data.description,
    },
    {
        label: 'Failure Reason',
        value: data.failureReason,
    },
    {
        label: 'Ext Txn Id',
        value: data.vendorExtTxnId,
    },
    {
        label: 'RRN',
        value: data.retrievalReferenceNumber,
    },
];

export const getCreditCardTxnDisputeDetails = (data) => [
    {
        label: 'Transaction ID',
        value: data.transactionId,
    },
    {
        label: 'Account ID',
        value: data.accountId,
    },
    {
        label: 'Dispute Reference Number',
        value: data.disputeReferenceNumber,
    },
    {
        label: 'Dispute State',
        value: data.disputeState,
    },
    {
        label: 'Dispute Amount',
        value: data.disputedAmount,
    },
    {
        label: 'Disputed At',
        value: data.disputedAt,
    },
    {
        label: 'Updated At',
        value: data.updatedAt,
    },
];

export const getTxnDisputeDetails = (data) => [
    {
        label: 'Ticket ID',
        value: data.ticketId,
        style: {
            bold: true,
        },
    },
    {
        label: 'Dispute State',
        value: data.state,
        style: {
            bold: true,
        },
    },
    {
        label: 'Dispute Type',
        value: data.type,
    },
    {
        label: 'Escalation Mode',
        value: data.escalationMode,
        style: {
            bold: true,
        },
    },
    {
        label: 'Escalation Time',
        value: data.escalationTime,
    },
    {
        label: 'Source',
        value: data.source,
    },
    {
        label: 'Channel',
        value: data.channel,
        style: {
            bold: true,
        },
    },
];

export const getUDIRTxnDisputeDetails = (data) => [
    {
        label: 'Dispute State',
        value: data.state,
        style: {
            bold: true,
        },
    },
    {
        label: 'Dispute Type',
        value: data.type,
        style: {
            bold: true,
        },
    },
    {
        label: 'Escalation Mode',
        value: data.escalationMode,
        style: {
            bold: true,
        },
    },
    {
        label: 'Escalation Time',
        value: data.escalationTime,
    },
    {
        label: 'Source',
        value: data.source,
    },
    {
        label: 'Channel',
        value: data.channel,
        style: {
            bold: true,
        },
    },
];

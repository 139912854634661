/**
 * @file CxRiskReview
 * This shows the  queue of risk elements like liveness video based on CX tickets
 * On selecting a particular case, the analyst will be able to see all requests for that actor ID
 * On selecting a particular request, analyst will be able to see the details like liveness video and take action
 */

import React, { memo, useContext } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { compose } from 'redux';

import classNames from 'classnames';
import { HorizontalIdNavbar, Loader } from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import { CX_RISK_REVIEW_KEY, VIEW_ALL_REQUESTS_FOR_TICKET, VIEW_ALL_TICKETS, VIEW_SINGLE_CASE } from './constants';
import { selectCxRiskCurView, selectCxRiskErr, selectCxRiskLoader } from './selectors';

import ViewTickets from './ViewTickets';
import ViewRequestsForActor from './ViewRequestsForActor';
import SingleCase from './SingleCase';

import reducer from './reducer';
import saga from './saga';

import { MetaInfoContext } from '../../context';

import './style.scss';

const RenderViews = () => {
    const curView = useSelector(selectCxRiskCurView, shallowEqual);

    switch (curView) {
        case VIEW_ALL_TICKETS: {
            return <ViewTickets />;
        }

        case VIEW_ALL_REQUESTS_FOR_TICKET: {
            return <ViewRequestsForActor />;
        }

        case VIEW_SINGLE_CASE: {
            return <SingleCase />;
        }

        default:
            return null;
    }
};

const CxRiskReview = () => {
    const err = useSelector(selectCxRiskErr, shallowEqual);
    const loader = useSelector(selectCxRiskLoader, shallowEqual);

    const metaInfo = useContext(MetaInfoContext);

    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    return (
        <div className={classNames({
            // eslint-disable-next-line quote-props
            'cxrisk': bannersVersion === 1,
            'cxrisk-v2': bannersVersion === 2,
        })}
        >
            <HorizontalIdNavbar isFixed isEmptyNavbar />
            <RenderViews />
            <Loader visible={loader} />
            {err && <div className='err-label cxrisk-err'>{err}</div>}
        </div>
    );
};

const withReducer = injectReducer({ key: CX_RISK_REVIEW_KEY, reducer });
const withSaga = injectSaga({ key: CX_RISK_REVIEW_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(memo(CxRiskReview));

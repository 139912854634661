/**
 * @file Manages the selector functions for Tiering component
 */

import { createSelector } from 'reselect';

import { TIERING_KEY } from './constants';

const selectTieringinfo = createSelector(
    (state) => state.getIn([TIERING_KEY, 'tieringInfo']),
    (substate) => substate.toJS(),
);

export {
    selectTieringinfo,
};

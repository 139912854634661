/**
 * @file Dispute Questionnaire
 * Renders the questions and input fields for a user to raise a dispute
 */

import React, { useEffect, memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ExpandableCard, DropdownWrapper } from '../../../components';
import backArrow from '../../../assets/images/svgs/back-arrow.svg';

import { getCreditCardQuestionsInfoAction } from '../actions';
import RenderOptionField from '../RenderOptionField';
import RenderChannelField from '../RenderChannelField';
import RenderQuestions from '../RenderQuestions';

const DisputeQuestionnaire = (props) => {
    const {
        ticketId, userId, channelOption, toggleHandler, creditCardTransaction,
        onDisputeAmountChange,
    } = props;

    const dispatch = useDispatch();

    const [isCSQExpanded, setCSQExpanded] = useState(true);
    const [showChannelOptionDD, setChannelOptionDD] = useState(false);

    const setDisputeAmountValue = (event) => {
        const { value } = event.target;

        onDisputeAmountChange(value);
    };

    useEffect(() => {
        if (!creditCardTransaction) return;

        const payload = {
            ticketId,
            userId,
            questionIndex: '',
            answer: '',
            reset: true,
        };

        dispatch(getCreditCardQuestionsInfoAction(payload));
    }, [creditCardTransaction]);

    return (
        <React.Fragment>
            <div className='txns-rdc-hw'>
                <button
                    type='button'
                    onClick={toggleHandler('dispute-flow')}
                >
                    <img
                        alt='back'
                        className='txns-backarrow'
                        src={backArrow}
                    />
                </button>
                <div className='txns-rdc-hw__hd'>Raise Dispute</div>
            </div>

            <ExpandableCard
                heading='Channel Specific Questionnaire'
                extClasses='txns-exp-card'
                isExpanded={isCSQExpanded}
                setIsExpanded={() => setCSQExpanded(!isCSQExpanded)}
            >
                <div className='frcWrapper txns-rdc-cw'>
                    {creditCardTransaction ? (
                        <React.Fragment>
                            <React.Fragment>
                                <div className='txns-search-label'>
                                    Channel
                                </div>
                                <DropdownWrapper
                                    extClasses={{
                                        container: 'custom-dd txns-search-label--co',
                                    }}
                                >
                                    <button type='button'>
                                        Credit Card
                                    </button>
                                </DropdownWrapper>
                            </React.Fragment>
                            <React.Fragment>
                                <div className='txns-search-label'>
                                    Amount *
                                </div>
                                <div className='fcWrapper ml-15'>
                                    <input
                                        type='number'
                                        onChange={setDisputeAmountValue}
                                    />
                                </div>
                            </React.Fragment>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <RenderChannelField
                                parentProps={props}
                                setChannelOptionDD={setChannelOptionDD}
                            />
                            <RenderOptionField
                                parentProps={props}
                                showChannelOptionDD={showChannelOptionDD}
                                setChannelOptionDD={setChannelOptionDD}
                            />
                        </React.Fragment>
                    )}
                </div>

                {creditCardTransaction || channelOption ? <RenderQuestions parentProps={props} /> : null}
            </ExpandableCard>
        </React.Fragment>
    );
};

export default memo(DisputeQuestionnaire);

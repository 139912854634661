/*
 *
 * Pan name match review reducer
 *
 */

import { fromJS } from 'immutable';

import {
    GET_NAMEMATCH_CASES, SET_NAMEMATCH_CASES, ERR_NAMEMATCH_CASES, VIEW_ALL_NAMEMATCH_CASES,
    SET_NAMEMATCH_VIEW, SET_CUR_NAMEMATCH_CASE, PAYLOAD_DD_OG_STATE, SET_NAMEMATCH_PAYLOAD_TYPE, SET_NAMEMATCH_PENDING_REVIEW_COUNT,
    INITIAL_DATE_RANGE, SORT_DD_OG_STATE, SET_NAMEMATCH_QUEUE_FILTERS,
} from './constants';

const initialState = fromJS({
    namematchCasesList: [],
    curNamematchCase: {},
    namematchView: VIEW_ALL_NAMEMATCH_CASES,
    loading: false,
    err: '',
    namematchPayloadType: PAYLOAD_DD_OG_STATE,
    pendingReviewCount: '',
    queueFilters: {
        fromDate: INITIAL_DATE_RANGE.fromDate,
        toDate: INITIAL_DATE_RANGE.toDate,
        sortOption: SORT_DD_OG_STATE,
    },
});

function namematchReviewReducer(state = initialState, action) {
    switch (action.type) {
        case GET_NAMEMATCH_CASES:
            return state
                .set('loading', true)
                .set('err', '');

        case SET_NAMEMATCH_CASES: {
            const namematchCasesList = action.data;

            return state
                .set('loading', false)
                .set('namematchCasesList', fromJS(namematchCasesList));
        }

        case ERR_NAMEMATCH_CASES: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err);
        }

        case SET_NAMEMATCH_VIEW: {
            return state.set('namematchView', action.data);
        }

        case SET_CUR_NAMEMATCH_CASE: {
            const curNamematchCase = action.data;

            return state.set('curNamematchCase', fromJS(curNamematchCase));
        }

        case SET_NAMEMATCH_PAYLOAD_TYPE: {
            const namematchPayloadType = action.data;

            return state
                .set('namematchPayloadType', fromJS(namematchPayloadType));
        }

        case SET_NAMEMATCH_PENDING_REVIEW_COUNT: {
            return state
                .set('pendingReviewCount', action.data);
        }

        case SET_NAMEMATCH_QUEUE_FILTERS: {
            const { key, value } = action.data;

            return state
                .setIn(['queueFilters', key], fromJS(value));
        }

        default:
            return state;
    }
}

export default namematchReviewReducer;

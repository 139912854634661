/**
 * @file ParameterList: This is the component to render a form with CTA for the parameter list that we get from cx.developer.db_state.ParameterMeta
 */

import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

import {
    getModifiedParameterList, getDropdownHeight, getRequiredFieldsFilledFlag, onFileClick, onFileChange, isNilOrEmpty,
} from '../../utils';
import { SUPPORTED_FILE_TYPES } from '../../utils/fileUploadEvents';

import Button from '../Button';
import DropdownContent from '../DropdownContent';
import DropdownWrapper from '../DropdownWrapper';
import ExpandableCard from '../ExpandableCard';
import FormDropdown from '../FormDropdown';
import FormWithSingleInput from '../FormWithSingleInput';

import './style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 500,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        backgroundColor: '#F5F5F5',
    },
};

const getInitialParameterValue = (item) => {
    const modifiedState = { ...item };

    if (item.type !== 14) {
        modifiedState.value = item.value || '';
    }

    if (item.type === 5) { // DROPDOWN
        modifiedState.isDDOpen = false;
    } else if (item.type === 7) { // NAME
        modifiedState.value = {
            first_name: '',
            last_name: '',
        };
    } else if (item.type === 9) { // MULTI_SELECT_DROPDOWN
        if (!item.disableModifyOptions) {
            modifiedState.options = modifiedState.options.map((subItem) => ({
                label: subItem,
                value: subItem,
            }));
        }
        modifiedState.value = item.value || [];
    } else if (item.type === 14) { // BOOLEAN
        modifiedState.isDDOpen = false;
    }

    return modifiedState;
};

const ParameterValue = (props) => {
    const {
        parameterInfo, showExecuteConfirmationModal, paramListObj, setParamListObj,
    } = props;

    const [fileUploadErrLabel, setFileUploadErrLabel] = useState('');
    const [itemState, setItemState] = useState(parameterInfo);

    const setParameterValue = ({
        value, dd, key, extras = null,
    }) => {
        let newItemState = itemState;

        if (itemState.type === 5) { // DROPDOWN
            newItemState = {
                ...itemState,
                isDDOpen: dd,
                value: value || itemState.value,
                extras, // to send additional parameters info
            };
        } else if (itemState.type === 7) { // NAME
            newItemState = {
                ...itemState,
                value: {
                    ...itemState.value,
                    [key]: value,
                },
            };
        } else if (itemState.type === 14) { // Boolean
            newItemState = {
                ...itemState,
                isDDOpen: dd,
                value: typeof value === 'boolean' ? value : itemState.value,
            };
        } else {
            newItemState = {
                ...itemState,
                value,
            };
        }

        setItemState(newItemState);
        setParamListObj({
            ...paramListObj,
            [itemState.name]: newItemState,
        });
    };

    /**
     * STRING = 1;
     * INTEGER = 2;
     * DOUBLE = 3;
     * TIMESTAMP = 4;
     * DROPDOWN = 5;
     * MONEY = 6;
     * NAME = 7;
     * PHONE_NUMBER = 8;
     * MULTI_SELECT_DROPDOWN = 9;
     * PAGE_CONTEXT = 10;
     * FILE = 11;
     * FILE_LIST = 12;
     * MULTILINE_TEXT = 13;
     * REASON = 100;
     * BOOLEAN = 14;
     */
    switch (itemState.type) {
        case 4: {
            let maxDate = new Date(); // can set latest date as the current day
            let showYearDropdown = false;

            if (itemState.noMaxDate) { // can set a future date
                maxDate = null;
                showYearDropdown = true;
            }

            return (
                <FormWithSingleInput
                    hideErrLabel
                    disableFormSubmitOnEnter
                    extClasses={{
                        container: 'pl-fc--m0',
                        inputField: 'pl-fc__ip',
                    }}
                    labelId={itemState.name}
                    formType='dob'
                    input={{
                        value: itemState.value,
                        disabled: itemState.isDisabled,
                        onChange: (val) => {
                            setParameterValue({ value: val });
                        },
                        maxDate,
                        minDate: new Date(itemState.minDate || '10-01-2020'),
                        showYearDropdown,
                    }}
                    onFormSubmit={showExecuteConfirmationModal}
                />
            );
        }

        case 5: {
            const { length: optionsLength } = itemState.options;
            const optionListDDHeight = getDropdownHeight(optionsLength);

            const onDropDownClick = () => {
                if (!itemState?.isDisabled) {
                    setParameterValue({ dd: true });
                }
            };

            return (
                <DropdownWrapper
                    visible={itemState.isDDOpen}
                    onOutsideClickHandler={() => setParameterValue({ dd: false })}
                    extClasses={{
                        container: `custom-dd pl-dd pl-dd--ml0 ${itemState.className || ''}`,
                    }}
                >
                    <button type='button' className='pl-dd__ph' disabled={itemState?.isDisabled} onClick={onDropDownClick}>
                        {itemState.value || 'Choose...'}
                    </button>
                    <DropdownContent
                        visible
                        extClasses={{
                            container: ['custom-dd__cc pl-dd__cc'],
                        }}
                        extStyles={{
                            height: itemState.isDDOpen ? optionListDDHeight : 0,
                            overflow: itemState.isDDOpen ? 'auto' : 'hidden',
                        }}
                    >
                        {
                            itemState.options.map((ansOpt) => {
                                const value = ansOpt?.value || ansOpt;
                                const key = ansOpt?.id || ansOpt;

                                return (
                                    <button
                                        type='button'
                                        key={key}
                                        className='custom-dd__cl'
                                        onClick={() => {
                                            setParameterValue({ value, dd: false, extras: ansOpt });
                                        }}
                                    >
                                        {value}
                                    </button>
                                );
                            })
                        }
                    </DropdownContent>
                </DropdownWrapper>
            );
        }

        case 14: {
            const { length: optionsLength } = itemState.options;
            const optionListDDHeight = getDropdownHeight(optionsLength);

            const onDropDownClick = () => {
                if (!itemState?.isDisabled) {
                    setParameterValue({ dd: true });
                }
            };

            return (
                <DropdownWrapper
                    visible={itemState.isDDOpen}
                    onOutsideClickHandler={() => setParameterValue({ dd: false })}
                    extClasses={{
                        container: `custom-dd pl-dd pl-dd--ml0 ${itemState.className || ''}`,
                    }}
                >
                    <button type='button' className='pl-dd__ph' disabled={itemState?.isDisabled} onClick={onDropDownClick}>
                        {itemState.options.find((e) => e.value === itemState.value).id || 'Choose...'}
                    </button>
                    <DropdownContent
                        visible
                        extClasses={{
                            container: ['custom-dd__cc pl-dd__cc'],
                        }}
                        extStyles={{
                            height: itemState.isDDOpen ? optionListDDHeight : 0,
                            overflow: itemState.isDDOpen ? 'auto' : 'hidden',
                        }}
                    >
                        {
                            itemState.options.map((ansOpt) => {
                                const value = !!ansOpt?.value;
                                const key = ansOpt?.id;

                                return (
                                    <button
                                        type='button'
                                        key={key}
                                        className='custom-dd__cl'
                                        onClick={() => {
                                            setParameterValue({ value, dd: false, extras: ansOpt });
                                        }}
                                    >
                                        {key}
                                    </button>
                                );
                            })
                        }
                    </DropdownContent>
                </DropdownWrapper>
            );
        }

        case 7: {
            return (
                <FormWithSingleInput
                    hideErrLabel
                    disableFormSubmitOnEnter
                    extClasses={{
                        container: `pl-fc--m0 ${itemState.className || ''}`,
                    }}
                    labelId={itemState.name}
                    formType='name'
                    input={{
                        value: itemState.value,
                        disabled: itemState.isDisabled,
                        onChange: (key) => (e) => {
                            setParameterValue({ key, value: e.target.value });
                        },
                    }}
                    onFormSubmit={showExecuteConfirmationModal}
                />
            );
        }

        case 9: {
            return (
                <FormDropdown
                    isMulti
                    options={itemState.options}
                    input={{
                        value: parameterInfo.value,
                        isDisabled: itemState.isDisabled,
                        onChange: (value) => {
                            setParameterValue({ value });
                        },
                    }}
                    cacheKey='parameter-list-component'
                    extStyles={{
                        container: 'pl-dd',
                    }}
                />
            );
        }
        case 1: {
            return (
                <FormWithSingleInput
                    hideErrLabel
                    disableFormSubmitOnEnter
                    extClasses={{
                        container: 'pl-fc--m0',
                        inputField: 'pl-fc__ip',
                    }}
                    labelId={itemState.name}
                    input={{
                        value: parameterInfo.value,
                        disabled: itemState.isDisabled,
                        onChange: (e) => {
                            const { value } = e.target;
                            setParameterValue({ value });
                        },
                    }}
                    onFormSubmit={showExecuteConfirmationModal}
                />
            );
        }

        case 11:
        case 12: {
            const setValue = (value) => {
                setParameterValue({ value });
            };

            const fileParams = {
                itemState,
                setErrLabel: setFileUploadErrLabel,
                setValue,
            };

            const { type } = itemState;

            return (
                <div className='fcWrapper'>
                    <input
                        type='file'
                        className='pl-fc__ip'
                        accept={SUPPORTED_FILE_TYPES}
                        onClick={onFileClick(fileParams)}
                        onChange={onFileChange(fileParams)}
                        multiple={type === 12}
                    />
                    {
                        fileUploadErrLabel ? (
                            <div className='err-label pl-fuel'>{fileUploadErrLabel}</div>
                        ) : null
                    }
                </div>
            );
        }

        case 13: {
            return (
                <FormWithSingleInput
                    hideErrLabel
                    disableFormSubmitOnEnter
                    extClasses={{
                        container: 'pl-fc--m0',
                        inputField: 'pl-fc__ip pl-fc__rsv',
                    }}
                    labelId={itemState.name}
                    formType='textarea'
                    input={{
                        value: itemState.value,
                        disabled: itemState.isDisabled,
                        onChange: (e) => {
                            setParameterValue({ value: e.target.value });
                        },
                    }}
                    onFormSubmit={showExecuteConfirmationModal}
                />
            );
        }

        default: { // 2, 3, 6, 8, 100
            return (
                <FormWithSingleInput
                    hideErrLabel
                    disableFormSubmitOnEnter
                    extClasses={{
                        container: 'pl-fc--m0',
                        inputField: 'pl-fc__ip',
                    }}
                    labelId={itemState.name}
                    input={{
                        value: itemState.value,
                        disabled: itemState.isDisabled,
                        onChange: (e) => {
                            setParameterValue({ value: e.target.value });
                        },
                    }}
                    onFormSubmit={showExecuteConfirmationModal}
                />
            );
        }
    }
};

const ParameterList = (props) => {
    const {
        config, data, onSubmit, extClasses,
    } = props;
    const {
        warningModal, cardTitle, paramsTitle, defaultIsExpanded, collapseOnSubmit, isCollapsible,
    } = config;
    const { parameterList } = data;

    const [formErrLabel, setFormErrLabel] = useState('');
    const [showExecuteInfoModal, setExecuteInfoModal] = useState(false);
    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(defaultIsExpanded);
    const [paramListObj, setParamListObj] = useState({});

    const expandableCardClasses = classNames('pl-ec', extClasses);

    useEffect(() => {
        // transform the parameters on initial load and store in paramListObj
        if (isNilOrEmpty(parameterList)) return;

        const curParamObj = {};
        parameterList.forEach((paramItem) => {
            const curParamName = paramItem.name;
            curParamObj[curParamName] = getInitialParameterValue(paramItem);
        });

        setParamListObj(curParamObj);
    }, [parameterList]);

    const handleSubmit = () => {
        const currentParameters = Object.values(paramListObj);
        const modifiedParameters = getModifiedParameterList(currentParameters);

        setFormErrLabel('');
        setExecuteInfoModal(false);

        onSubmit(modifiedParameters, currentParameters);

        if (collapseOnSubmit) setIsSearchCardExpanded(!isSearchCardExpanded);
    };

    const showExecuteConfirmationModal = (e) => {
        e.preventDefault();

        const hasAllRequiredFieldsFilled = getRequiredFieldsFilledFlag(Object.values(paramListObj));

        if (!hasAllRequiredFieldsFilled) {
            setFormErrLabel('Please fill all the * marked fields');
            return;
        }

        setFormErrLabel('');

        if (warningModal.required) {
            setExecuteInfoModal(true);
        } else {
            handleSubmit();
        }
    };

    if (isNilOrEmpty(paramListObj)) return null;

    return (
        <React.Fragment>
            <ExpandableCard
                version={2}
                extClasses={expandableCardClasses}
                heading={cardTitle}
                isExpanded={isSearchCardExpanded}
                isCollapsible={isCollapsible}
                setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
            >
                <div className='pl-sc'>
                    <div className='pl-sc-ocr'>
                        <div className='pl-sc-ocr__hl'>{paramsTitle}</div>
                        {parameterList
                            .filter((parameterInfo) => parameterInfo.type !== 10) // filter page context option type
                            .map((parameterInfo, idx) => (
                                <div className='pl-sc-owr' key={parameterInfo.label}>
                                    <div className='pl-sc-owr__ol'>
                                        {`${idx + 1}. ${parameterInfo.label}${!parameterInfo.isOptional ? '*' : ''}`}
                                    </div>
                                    <div className='pl-sc-owr__ov'>
                                        <ParameterValue
                                            parameterInfo={paramListObj[parameterInfo.name]}
                                            showExecuteConfirmationModal={showExecuteConfirmationModal}
                                            paramListObj={paramListObj}
                                            setParamListObj={setParamListObj}
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>

                    {formErrLabel ? <div className='err-label pl-el'>{formErrLabel}</div> : null}

                    <Button
                        v2
                        extClasses={`pl-sc-cta ${formErrLabel && 'pl-sc-cta--err'}`}
                        primary
                        label='Submit'
                        onClick={showExecuteConfirmationModal}
                    />
                </div>
            </ExpandableCard>

            {showExecuteInfoModal && (
                <Modal
                    isOpen
                    style={customStylesForModal}
                    contentLabel='Action Execution Modal'
                >
                    <div className='pl-modal__hl'>
                        {warningModal.message}
                        {/* Are you sure you want to perform {selectedAction}? */}
                    </div>
                    <div className='pl-modal__sbhl'>
                        {warningModal.description}
                    </div>
                    <div className='frcsbWrapper pl-modal__ctas'>
                        <Button
                            v2
                            secondary
                            label='Submit'
                            onClick={handleSubmit}
                        />
                        <Button
                            v2
                            primary
                            label='Cancel'
                            onClick={() => setExecuteInfoModal(false)}
                        />
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};

ParameterList.defaultProps = {
    config: {
        cardTitle: 'Search parameters',
        paramsTitle: 'Fields',
        defaultIsExpanded: false,
        isCollapsible: true,
        collapseOnSubmit: true,
        extClasses: '',
        warningModal: {
            required: false,
            message: 'Are you sure you want to perform this action?',
            description: 'This change is irreversible and affects production data',
        },
    },
};

export default memo(ParameterList);

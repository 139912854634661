/* eslint-disable default-param-last */
/**
 * @file Manages the reducer state & function for Credit Card component
 */

import { fromJS } from 'immutable';

import {
    GET_CURRENT_CREDIT_CARD_DETAILS, SET_CURRENT_CREDIT_CARD_DETAILS, ERR_CURRENT_CREDIT_CARD_DETAILS,
    GET_CREDIT_CARD_TRACKING_INFO, SET_CREDIT_CARD_TRACKING_INFO, GET_CREDIT_CARD_CONTROL_DETAILS,
    SET_CREDIT_CARD_CONTROL_DETAILS, TOGGLE_SCREEN, GET_ALL_CREDIT_CARDS, SET_ALL_CREDIT_CARDS, ERR_ALL_CREDIT_CARDS,
    GET_CREDIT_CARD_USAGE_DETAILS, SET_CREDIT_CARD_USAGE_DETAILS, UPDATE_CREDIT_CARD_USAGE_DETAILS,
    IS_CARD_LIMITS_SCREEN, IS_CARD_SETTINGS_SCREEN, GET_CREDIT_CARD_ONBOARDING_DETAILS, SET_CREDIT_CARD_ONBOARDING_DETAILS,
    GET_CURRENT_LIMIT_USAGE_DETAILS, SET_CURRENT_LIMIT_USAGE_DETAILS, ERR_CURRENT_LIMIT_USAGE_DETAILS,
    GET_CREDIT_CARD_BILLING_INFO, SET_CREDIT_CARD_BILLING_INFO, ERR_CREDIT_CARD_BILLING_INFO, GET_ALL_ACTIVE_LOAN_ACCOUNTS,
    SET_ALL_ACTIVE_LOAN_ACCOUNTS, ERR_ALL_ACTIVE_LOAN_ACCOUNTS, GET_ALL_CLOSED_LOAN_ACCOUNTS, SET_ALL_CLOSED_LOAN_ACCOUNTS,
    ERR_ALL_CLOSED_LOAN_ACCOUNTS, GET_LOAN_ACCOUNT_DETAILS, SET_LOAN_ACCOUNT_DETAILS, IS_LOAN_ACCOUNT_DETAILS_SCREEN,
    GET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS, SET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS, IS_ELIGIBLE_TRANSACTIONS_FOR_EMI_SCREEN,
    GET_LOAN_OFFERS, SET_LOAN_OFFERS, ERR_LOAN_OFFERS, IS_LOAN_OFFERS_SCREEN, ERR_CREDIT_CARD_SCREEN, REDUCER_KEYS,
    INITIATE_BLOCK_CARD, SET_BLOCK_CARD, SET_BLOCK_CARD_CONFIRM_SCREEN, IS_BLOCK_CONFIRM_SCREEN,
    INITIATE_SUSPEND_CARD, SET_SUSPEND_CARD, SET_SUSPEND_CARD_CONFIRM_SCREEN, IS_SUSPEND_CONFIRM_SCREEN,
    SET_FD_CREDIT_CARD_DETAILS, GET_FD_CREDIT_CARD_DETAILS, SET_CREDIT_CARD_ELIGIBILITY_DETAILS,
    SET_CREDIT_CARD_ONBOARDING_DETAILS_V2, IS_CREDIT_CARD_CURRENT_STAGE_SCREEN, IS_CREDIT_CARD_KYC_SCREEN,
    IS_SELECTED_CREDIT_CARD_SCREEN, IS_CREDIT_CARD_NEXT_STAGE_SCREEN, SET_SHOW_ONBOARDING_DETAILS_V2,
    GET_SELECTED_CREDIT_CARD_DETAILS, SET_SELECTED_CREDIT_CARD_DETAILS, GET_CREDIT_CARD_ELIGIBILITY_DETAILS,
    GET_CREDIT_CARD_CURRENT_STAGE_DETAILS, SET_CREDIT_CARD_CURRENT_STAGE_DETAILS,
    GET_CREDIT_CARD_NEXT_STAGE_DETAILS, SET_CREDIT_CARD_NEXT_STAGE_DETAILS, IS_CREDIT_CARD_MILESTONE_REWARDS_SCREEN,
    GET_MILESTONE_REWARDS_DETAILS, SET_MILESTONE_REWARDS_DETAILS, ERR_MILESTONE_REWARDS_DETAILS,

} from './constants';

const initialState = fromJS({
    [REDUCER_KEYS.CREDIT_CARD_LOADER]: false,
    [REDUCER_KEYS.CREDIT_CARD_ERROR]: '',
    [REDUCER_KEYS.CURRENT_CREDIT_CARD_DETAILS]: {},
    [REDUCER_KEYS.CREDIT_CARD_ONBOARDING_DETAILS]: {},
    [REDUCER_KEYS.ALL_CREDIT_CARDS]: {
        [REDUCER_KEYS.CREDIT_CARD_LOADER]: false,
    },
    [REDUCER_KEYS.CREDIT_CARD_TRACKING_INFO]: {},
    [REDUCER_KEYS.CREDIT_CARD_CONTROL_DETAILS]: {
        [IS_CARD_LIMITS_SCREEN]: false,
    },
    [REDUCER_KEYS.CREDIT_CARD_USAGE_DETAILS]: {
        [IS_CARD_SETTINGS_SCREEN]: false,
    },
    [REDUCER_KEYS.CURRENT_LIMIT_USAGE_DETAILS]: {},
    [REDUCER_KEYS.CREDIT_CARD_BILLING_INFO]: {},
    [REDUCER_KEYS.ALL_ACTIVE_LOAN_ACCOUNTS]: {},
    [REDUCER_KEYS.UPCOMING_EMI_INFO]: {},
    [REDUCER_KEYS.ALL_CLOSED_LOAN_ACCOUNTS]: {},
    [REDUCER_KEYS.LOAN_ACCOUNT_DETAILS]: {
        [IS_LOAN_ACCOUNT_DETAILS_SCREEN]: false,
    },
    [REDUCER_KEYS.ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS]: {
        [IS_ELIGIBLE_TRANSACTIONS_FOR_EMI_SCREEN]: false,
    },
    [REDUCER_KEYS.LOAN_OFFERS]: {
        [IS_LOAN_OFFERS_SCREEN]: false,
    },
    [REDUCER_KEYS.BLOCK_CREDIT_CARD]: {
        [IS_BLOCK_CONFIRM_SCREEN]: false,
    },
    [REDUCER_KEYS.SUSPEND_CREDIT_CARD]: {
        [IS_SUSPEND_CONFIRM_SCREEN]: false,
    },
    [REDUCER_KEYS.CREDIT_CARD_ElIGIBILITY_DETAILS]: {},
    [REDUCER_KEYS.CREDIT_CARD_OFFER_DETAILS]: {},
    [REDUCER_KEYS.SHOW_CREDIT_CARD_ONBOARDING_DETAILS_V2]: false,
    [REDUCER_KEYS.CREDIT_CARD_ONBOARDING_DETAILS_V2]: {},
    [REDUCER_KEYS.CREDIT_CARD_KYC_DETAILS]: {
        [IS_CREDIT_CARD_KYC_SCREEN]: false,
    },
    [REDUCER_KEYS.SELECTED_CREDIT_CARD_DETAILS]: {
        [IS_SELECTED_CREDIT_CARD_SCREEN]: false,
    },
    [REDUCER_KEYS.CREDIT_CARD_CURRENT_STAGE_DETAILS]: {
        [IS_CREDIT_CARD_CURRENT_STAGE_SCREEN]: false,
    },
    [REDUCER_KEYS.CREDIT_CARD_NEXT_STAGE_DETAILS]: {
        [IS_CREDIT_CARD_NEXT_STAGE_SCREEN]: false,
    },
    [REDUCER_KEYS.CREDIT_CARD_MILESTONE_REWARDS_DETAILS]: {
        [IS_CREDIT_CARD_MILESTONE_REWARDS_SCREEN]: false,
    },
});

/**
 * This function returns a new state object with the appropriate keys set
 * based on the provided action data and reducer key.
 *
 * @param {object} state - The current state object
 * @param {object} actionData - The data included in the action being dispatched
 * @param {string} reducerKey - The key for the reducer being updated
 * @returns {object} - A new state object with the appropriate keys set
 */
const getApiState = (state, actionData, reducerKey) => {
    const { ticketId } = actionData || {};

    /**
     * If ticketId and reducerKey are both present in actionData,
     * set the appropriate keys for that reducer in the state object
     */
    if (ticketId && reducerKey) {
        return state
            .setIn([REDUCER_KEYS.CREDIT_CARD_LOADER], true)
            .setIn([ticketId, reducerKey, 'err'], '');
    }

    /**
     * If ticketId or reducerKey is missing from actionData,
     * set the appropriate keys for the default reducer in the state object
     */
    return state
        .setIn([REDUCER_KEYS.CREDIT_CARD_LOADER], true)
        .setIn([REDUCER_KEYS.CREDIT_CARD_ERROR], '');
};

/**
 * This function updates the state object with new data and error information
 * based on the provided action data and reducer key.
 *
 * @param {object} state - The current state object
 * @param {object} actionData - The data included in the action being dispatched
 * @param {string} reducerKey - The key for the reducer being updated
 * @param {object} data - The new data to set in the state object
 * @param {boolean} errWithTicketId - Indicates whether the error is associated with a specific ticket ID
 * @returns {object} - A new state object with the appropriate keys set
 */
// To add fallback for updateApiState reducer https://monorail.pointz.in/p/fi-app/issues/detail?id=62270
const updateApiState = (state, actionData, reducerKey, data, errWithTicketId) => {
    const { ticketId } = actionData;

    // If errWithTicketId is true, set the appropriate keys for that ticket ID and reducer in the state object
    if (errWithTicketId) {
        return state
            .setIn([REDUCER_KEYS.CREDIT_CARD_LOADER], false)
            .setIn([ticketId, reducerKey, 'data'], fromJS(data))
            .setIn([ticketId, reducerKey, 'err'], '');
    }

    // If errWithTicketId is false, set the appropriate keys for the default reducer in the state object
    return state
        .setIn([REDUCER_KEYS.CREDIT_CARD_LOADER], false)
        .setIn([ticketId, reducerKey, 'data'], fromJS(data))
        .setIn([REDUCER_KEYS.CREDIT_CARD_ERROR], '');
};

const errApiState = (state, actionData, reducerKey) => {
    const { containerKey: ticketId, err } = actionData;

    return (
        state
            .setIn([REDUCER_KEYS.CREDIT_CARD_LOADER], false)
            .setIn([ticketId, reducerKey, 'err'], err)
    );
};

const creditCardReducer = (state = initialState, action = '') => {
    const actionData = action.data;
    switch (action.type) {
        case GET_CURRENT_CREDIT_CARD_DETAILS: {
            return getApiState(state, actionData, REDUCER_KEYS.CURRENT_CREDIT_CARD_DETAILS);
        }

        case SET_CURRENT_CREDIT_CARD_DETAILS: {
            const { ticketId, currentCreditCardDetails } = actionData;

            const creditCardNumber = currentCreditCardDetails[0]?.cardNumber;

            return updateApiState(state, actionData, REDUCER_KEYS.CURRENT_CREDIT_CARD_DETAILS, currentCreditCardDetails, true)
                .setIn([ticketId, REDUCER_KEYS.CURRENT_CREDIT_CARD_DETAILS, 'creditCardNumber'], creditCardNumber);
        }

        case ERR_CURRENT_CREDIT_CARD_DETAILS: {
            return errApiState(state, actionData, REDUCER_KEYS.CURRENT_CREDIT_CARD_DETAILS);
        }

        case GET_CREDIT_CARD_ONBOARDING_DETAILS: {
            return getApiState(state);
        }

        case SET_CREDIT_CARD_ONBOARDING_DETAILS: {
            const { creditCardOnboardingDetails } = actionData;

            return updateApiState(state, actionData, REDUCER_KEYS.CREDIT_CARD_ONBOARDING_DETAILS, creditCardOnboardingDetails);
        }

        case GET_FD_CREDIT_CARD_DETAILS: {
            return getApiState(state);
        }

        case SET_FD_CREDIT_CARD_DETAILS: {
            // eslint-disable-next-line camelcase
            const { fd_details } = actionData;
            return updateApiState(state, actionData, REDUCER_KEYS.FD_CREDIT_CARD, fd_details);
        }

        case GET_ALL_CREDIT_CARDS: {
            const { ticketId } = actionData;

            return state
                .setIn([ticketId, REDUCER_KEYS.ALL_CREDIT_CARDS, REDUCER_KEYS.CREDIT_CARD_LOADER], true)
                .setIn([ticketId, REDUCER_KEYS.ALL_CREDIT_CARDS, 'err'], '');
        }

        case SET_ALL_CREDIT_CARDS: {
            const { ticketId, cardList } = actionData;

            return state
                .setIn([ticketId, REDUCER_KEYS.ALL_CREDIT_CARDS, REDUCER_KEYS.CREDIT_CARD_LOADER], false)
                .setIn([ticketId, REDUCER_KEYS.ALL_CREDIT_CARDS, 'data'], fromJS(cardList));
        }

        case ERR_ALL_CREDIT_CARDS: {
            const { ticketId, err } = actionData;

            return state
                .setIn([ticketId, REDUCER_KEYS.ALL_CREDIT_CARDS, REDUCER_KEYS.CREDIT_CARD_LOADER], false)
                .setIn([ticketId, REDUCER_KEYS.ALL_CREDIT_CARDS, 'err'], err);
        }

        case GET_CREDIT_CARD_TRACKING_INFO: {
            return getApiState(state);
        }

        case SET_CREDIT_CARD_TRACKING_INFO: {
            const { cardTrackingInfo } = actionData;

            return updateApiState(state, actionData, REDUCER_KEYS.CREDIT_CARD_TRACKING_INFO, cardTrackingInfo);
        }

        case GET_CREDIT_CARD_CONTROL_DETAILS: {
            return getApiState(state);
        }

        case SET_CREDIT_CARD_CONTROL_DETAILS: {
            const { ticketId, isCardLimitsScreen, viewUsageLimits } = actionData;

            return updateApiState(state, actionData, REDUCER_KEYS.CREDIT_CARD_CONTROL_DETAILS, viewUsageLimits)
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_CONTROL_DETAILS, IS_CARD_LIMITS_SCREEN], isCardLimitsScreen);
        }

        case GET_CREDIT_CARD_USAGE_DETAILS: {
            return getApiState(state);
        }

        case SET_CREDIT_CARD_USAGE_DETAILS: {
            const { ticketId, isCardSettingsScreen, viewUsageSettings } = actionData;

            return updateApiState(state, actionData, REDUCER_KEYS.CREDIT_CARD_USAGE_DETAILS, viewUsageSettings)
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_USAGE_DETAILS, IS_CARD_SETTINGS_SCREEN], isCardSettingsScreen);
        }

        case UPDATE_CREDIT_CARD_USAGE_DETAILS: {
            return getApiState(state);
        }

        case TOGGLE_SCREEN: {
            const {
                ticketId, isCardLimitsScreen, isCardSettingsScreen, isLoanAccountDetailsScreen,
                isEligibleTransactionsForEmiScreen, isLoanOffersScreen, isBlockConfirmScreen, isSuspendConfirmScreen,
                isCreditCardKycScreen, isSelectedCreditCardScreen, isCurrentStageScreen, isNextStageScreen, isMilestoneRewardsScreen,
            } = actionData;

            return state
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_CONTROL_DETAILS, IS_CARD_LIMITS_SCREEN], !!isCardLimitsScreen)
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_USAGE_DETAILS, IS_CARD_SETTINGS_SCREEN], !!isCardSettingsScreen)
                .setIn([ticketId, REDUCER_KEYS.LOAN_ACCOUNT_DETAILS, IS_LOAN_ACCOUNT_DETAILS_SCREEN], !!isLoanAccountDetailsScreen)
                .setIn(
                    [ticketId, REDUCER_KEYS.ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS, IS_ELIGIBLE_TRANSACTIONS_FOR_EMI_SCREEN],
                    !!isEligibleTransactionsForEmiScreen,
                )
                .setIn([ticketId, REDUCER_KEYS.LOAN_OFFERS, IS_LOAN_OFFERS_SCREEN], !!isLoanOffersScreen)
                .setIn([ticketId, REDUCER_KEYS.BLOCK_CREDIT_CARD, IS_BLOCK_CONFIRM_SCREEN], !!isBlockConfirmScreen)
                .setIn([ticketId, REDUCER_KEYS.SUSPEND_CREDIT_CARD, IS_SUSPEND_CONFIRM_SCREEN], !!isSuspendConfirmScreen)
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_KYC_DETAILS, IS_CREDIT_CARD_KYC_SCREEN], !!isCreditCardKycScreen)
                .setIn([ticketId, REDUCER_KEYS.SELECTED_CREDIT_CARD_DETAILS, IS_SELECTED_CREDIT_CARD_SCREEN], !!isSelectedCreditCardScreen)
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_CURRENT_STAGE_DETAILS, IS_CREDIT_CARD_CURRENT_STAGE_SCREEN], !!isCurrentStageScreen)
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_NEXT_STAGE_DETAILS, IS_CREDIT_CARD_NEXT_STAGE_SCREEN], !!isNextStageScreen)
                .setIn(
                    [ticketId, REDUCER_KEYS.CREDIT_CARD_MILESTONE_REWARDS_DETAILS, IS_CREDIT_CARD_MILESTONE_REWARDS_SCREEN],
                    !!isMilestoneRewardsScreen,
                );
        }

        case GET_CURRENT_LIMIT_USAGE_DETAILS: {
            return getApiState(state, actionData, REDUCER_KEYS.CURRENT_LIMIT_USAGE_DETAILS);
        }

        case SET_CURRENT_LIMIT_USAGE_DETAILS: {
            const { currentLimitUsageDetails } = actionData;

            return updateApiState(state, actionData, REDUCER_KEYS.CURRENT_LIMIT_USAGE_DETAILS, currentLimitUsageDetails, true);
        }

        case ERR_CURRENT_LIMIT_USAGE_DETAILS: {
            return errApiState(state, actionData, REDUCER_KEYS.CURRENT_LIMIT_USAGE_DETAILS);
        }

        case GET_CREDIT_CARD_BILLING_INFO: {
            return getApiState(state, actionData, REDUCER_KEYS.CREDIT_CARD_BILLING_INFO);
        }

        case SET_CREDIT_CARD_BILLING_INFO: {
            const { creditCardBillingInfo } = actionData;

            return updateApiState(state, actionData, REDUCER_KEYS.CREDIT_CARD_BILLING_INFO, creditCardBillingInfo, true);
        }

        case ERR_CREDIT_CARD_BILLING_INFO: {
            return errApiState(state, actionData, REDUCER_KEYS.CREDIT_CARD_BILLING_INFO);
        }

        case GET_ALL_ACTIVE_LOAN_ACCOUNTS: {
            return getApiState(state, actionData, REDUCER_KEYS.ALL_ACTIVE_LOAN_ACCOUNTS);
        }

        case SET_ALL_ACTIVE_LOAN_ACCOUNTS: {
            const { ticketId, allActiveLoanAccountsData, upcompingEmiData } = actionData;

            return updateApiState(state, actionData, REDUCER_KEYS.ALL_ACTIVE_LOAN_ACCOUNTS, allActiveLoanAccountsData, true)
                .setIn([ticketId, REDUCER_KEYS.UPCOMING_EMI_INFO, 'data'], fromJS(upcompingEmiData));
        }

        case ERR_ALL_ACTIVE_LOAN_ACCOUNTS: {
            return errApiState(state, actionData, REDUCER_KEYS.ALL_ACTIVE_LOAN_ACCOUNTS);
        }

        case GET_ALL_CLOSED_LOAN_ACCOUNTS: {
            return getApiState(state, actionData, REDUCER_KEYS.ALL_CLOSED_LOAN_ACCOUNTS);
        }

        case SET_ALL_CLOSED_LOAN_ACCOUNTS: {
            const { allClosedLoanAccountsData } = actionData;

            return updateApiState(state, actionData, REDUCER_KEYS.ALL_CLOSED_LOAN_ACCOUNTS, allClosedLoanAccountsData, true);
        }

        case ERR_ALL_CLOSED_LOAN_ACCOUNTS: {
            return errApiState(state, actionData, REDUCER_KEYS.ALL_CLOSED_LOAN_ACCOUNTS);
        }

        case GET_LOAN_ACCOUNT_DETAILS: {
            return getApiState(state);
        }

        case SET_LOAN_ACCOUNT_DETAILS: {
            const { ticketId, loanAccountDetailsData, isLoanAccountDetailsScreen } = actionData;

            return updateApiState(state, actionData, REDUCER_KEYS.LOAN_ACCOUNT_DETAILS, loanAccountDetailsData)
                .setIn([ticketId, REDUCER_KEYS.LOAN_ACCOUNT_DETAILS, IS_LOAN_ACCOUNT_DETAILS_SCREEN], isLoanAccountDetailsScreen);
        }

        case GET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS: {
            const { ticketId } = actionData;

            return getApiState(state)
                .setIn([ticketId, REDUCER_KEYS.LOAN_OFFERS, 'err'], '');
        }

        case SET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS: {
            const { ticketId, allEligibleTransactionsForEmisData, isEligibleTransactionsForEmiScreen } = action.data;

            return updateApiState(state, actionData, REDUCER_KEYS.ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS, allEligibleTransactionsForEmisData)
                .setIn(
                    [ticketId, REDUCER_KEYS.ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS, IS_ELIGIBLE_TRANSACTIONS_FOR_EMI_SCREEN],
                    isEligibleTransactionsForEmiScreen,
                );
        }

        case GET_LOAN_OFFERS: {
            return getApiState(state, actionData, REDUCER_KEYS.LOAN_OFFERS);
        }

        case SET_LOAN_OFFERS: {
            const {
                ticketId, loanOffersData, loanTenureValues, isLoanOffersScreen,
            } = action.data;

            return updateApiState(state, actionData, REDUCER_KEYS.LOAN_OFFERS, loanOffersData, true)
                .setIn(
                    [ticketId, REDUCER_KEYS.LOAN_OFFERS, IS_LOAN_OFFERS_SCREEN],
                    isLoanOffersScreen,
                )
                .setIn([ticketId, REDUCER_KEYS.LOAN_OFFERS, 'loanTenureValues'], fromJS(loanTenureValues));
        }

        case ERR_LOAN_OFFERS: {
            return errApiState(state, actionData, REDUCER_KEYS.LOAN_OFFERS);
        }

        case INITIATE_BLOCK_CARD: {
            return getApiState(state);
        }

        case SET_BLOCK_CARD: {
            const { ticketId, isConfirmAction } = action.data;

            if (isConfirmAction) {
                return state
                    .setIn([REDUCER_KEYS.CREDIT_CARD_LOADER], false)
                    .setIn([ticketId, REDUCER_KEYS.BLOCK_CREDIT_CARD, IS_BLOCK_CONFIRM_SCREEN], false);
            }

            return state
                .setIn([REDUCER_KEYS.CREDIT_CARD_LOADER], false)
                .setIn([ticketId, REDUCER_KEYS.BLOCK_CREDIT_CARD, IS_BLOCK_CONFIRM_SCREEN], true);
        }

        case SET_BLOCK_CARD_CONFIRM_SCREEN: {
            const { ticketId, flag } = action.data;

            return state.setIn([ticketId, REDUCER_KEYS.BLOCK_CREDIT_CARD, IS_BLOCK_CONFIRM_SCREEN], flag);
        }

        case INITIATE_SUSPEND_CARD: {
            return getApiState(state);
        }

        case SET_SUSPEND_CARD: {
            const { ticketId, isConfirmAction } = action.data;

            if (isConfirmAction) {
                return state
                    .setIn([REDUCER_KEYS.CREDIT_CARD_LOADER], false)
                    .setIn([ticketId, REDUCER_KEYS.SUSPEND_CREDIT_CARD, IS_SUSPEND_CONFIRM_SCREEN], false);
            }

            return state
                .setIn([REDUCER_KEYS.CREDIT_CARD_LOADER], false)
                .setIn([ticketId, REDUCER_KEYS.SUSPEND_CREDIT_CARD, IS_SUSPEND_CONFIRM_SCREEN], true);
        }

        case SET_SUSPEND_CARD_CONFIRM_SCREEN: {
            const { ticketId, flag } = action.data;

            return state.setIn([ticketId, REDUCER_KEYS.SUSPEND_CREDIT_CARD, IS_SUSPEND_CONFIRM_SCREEN], flag);
        }

        case ERR_CREDIT_CARD_SCREEN: {
            const { err } = actionData;

            return state
                .setIn([REDUCER_KEYS.CREDIT_CARD_LOADER], false)
                .setIn([REDUCER_KEYS.CREDIT_CARD_ERROR], err);
        }

        // v2 reducer action

        case GET_CREDIT_CARD_ELIGIBILITY_DETAILS: {
            return getApiState(state);
        }

        case SET_CREDIT_CARD_ELIGIBILITY_DETAILS: {
            const {
                eligibilityDetails, offerDetails, isCreditCardExist, cardProgramType,
                cardProgramName,
            } = actionData;
            const data = {
                eligibilityDetails,
                offerDetails,
                isCreditCardExist,
                cardProgramType,
                cardProgramName,
            };
            return updateApiState(state, actionData, REDUCER_KEYS.CREDIT_CARD_ElIGIBILITY_DETAILS, data);
        }

        case SET_SHOW_ONBOARDING_DETAILS_V2: {
            const { show, ticketId } = action.data;
            return state.setIn([ticketId, REDUCER_KEYS.SHOW_CREDIT_CARD_ONBOARDING_DETAILS_V2], !!show);
        }

        case SET_CREDIT_CARD_ONBOARDING_DETAILS_V2: {
            const { onboardingDetails, ticketId } = action.data;

            // updateApiState(state, action, REDUCER_KEYS.CREDIT_CARD_ONBOARDING_DETAILS_V2, onboardingDetails);
            return state.setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_ONBOARDING_DETAILS_V2], fromJS(onboardingDetails));
        }

        case GET_SELECTED_CREDIT_CARD_DETAILS: {
            return getApiState(state);
        }

        case SET_SELECTED_CREDIT_CARD_DETAILS: {
            const { ticketId, isSelectedCreditCardScreen, selectedCardDetails } = actionData;

            return updateApiState(state, actionData, REDUCER_KEYS.SELECTED_CREDIT_CARD_DETAILS, selectedCardDetails)
                .setIn([ticketId, REDUCER_KEYS.SELECTED_CREDIT_CARD_DETAILS, IS_SELECTED_CREDIT_CARD_SCREEN], isSelectedCreditCardScreen);
        }

        case GET_CREDIT_CARD_CURRENT_STAGE_DETAILS: {
            return getApiState(state);
        }

        case SET_CREDIT_CARD_CURRENT_STAGE_DETAILS: {
            const { ticketId, isCurrentStageScreen, currentStageDetails } = actionData;

            return updateApiState(state, actionData, REDUCER_KEYS.CREDIT_CARD_CURRENT_STAGE_DETAILS, currentStageDetails)
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_CURRENT_STAGE_DETAILS, IS_CREDIT_CARD_CURRENT_STAGE_SCREEN], isCurrentStageScreen);
        }

        case GET_CREDIT_CARD_NEXT_STAGE_DETAILS: {
            return getApiState(state);
        }

        case SET_CREDIT_CARD_NEXT_STAGE_DETAILS: {
            const { ticketId, isNextStageScreen, nextStageDetails } = actionData;

            return updateApiState(state, actionData, REDUCER_KEYS.CREDIT_CARD_NEXT_STAGE_DETAILS, nextStageDetails)
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_NEXT_STAGE_DETAILS, IS_CREDIT_CARD_NEXT_STAGE_SCREEN], isNextStageScreen);
        }

        case GET_MILESTONE_REWARDS_DETAILS: {
            const { ticketId } = actionData;

            return state
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_MILESTONE_REWARDS_DETAILS, REDUCER_KEYS.CREDIT_CARD_LOADER], true)
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_MILESTONE_REWARDS_DETAILS, 'err'], '')
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_MILESTONE_REWARDS_DETAILS, 'data'], {});
        }

        case SET_MILESTONE_REWARDS_DETAILS: {
            const { ticketId, rewardDetails } = actionData;

            return state
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_MILESTONE_REWARDS_DETAILS, REDUCER_KEYS.CREDIT_CARD_LOADER], false)
                .setIn([ticketId, REDUCER_KEYS.CREDIT_CARD_MILESTONE_REWARDS_DETAILS, 'data'], fromJS(rewardDetails));
        }

        case ERR_MILESTONE_REWARDS_DETAILS: {
            const { containerKey, err } = actionData;

            return state
                .setIn([containerKey, REDUCER_KEYS.CREDIT_CARD_MILESTONE_REWARDS_DETAILS, REDUCER_KEYS.CREDIT_CARD_LOADER], false)
                .setIn([containerKey, REDUCER_KEYS.CREDIT_CARD_MILESTONE_REWARDS_DETAILS, 'err'], err);
        }

        default:
            return state;
    }
};

export default creditCardReducer;

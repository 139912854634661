/**
 * @file Manages the constants for Instant Indexing component
 */

export const INSTANT_INDEXING_KEY = 'instantIndexing';

export const INITIATE_UPDATE_URL = `app/${INSTANT_INDEXING_KEY}/INITIATE_UPDATE_URL`;
export const SET_UPDATE_URL = `app/${INSTANT_INDEXING_KEY}/SET_UPDATE_URL`;
export const END_UPDATE_URL = `app/${INSTANT_INDEXING_KEY}/END_UPDATE_URL`;

export const GET_URL_STATUS = `app/${INSTANT_INDEXING_KEY}/GET_URL_STATUS`;
export const SET_URL_STATUS = `app/${INSTANT_INDEXING_KEY}/SET_URL_STATUS`;
export const END_URL_STATUS = `app/${INSTANT_INDEXING_KEY}/END_URL_STATUS`;

export const PRIMARY_ACTION_MAP = {
    uploadCSV: 'Upload CSV',
    enterCSV: 'Enter Comma Separated Values Manually',
};

export const PRIMARY_ACTION_LIST_SEARCH_PARAM = [
    PRIMARY_ACTION_MAP.uploadCSV,
    PRIMARY_ACTION_MAP.enterCSV,
];

export const UPDATED_URL_LIST_LABEL_DATA = [
    {
        id: 1,
        label: 'URL',
        key: 'url',
    },
    {
        id: 2,
        label: 'Type',
        key: 'type',
    },
    {
        id: 3,
        label: 'Requested At',
        key: 'lastRequestedAt',
    },
];

export const REQUEST_TYPE_LIST_SEARCH_PARAM = [
    {
        label: 'Update',
        value: 'update',
    },
    {
        label: 'Delete',
        value: 'delete',
    },
];

/*
 *
 * LivenessStatus reducer
 *
 */

import { fromJS } from 'immutable';
import {
    GET_LIVENESS_STATUS_LIST, SET_LIVENESS_STATUS_LIST, SET_LIVENESS_STATUS_LOADER,
    UPDATE_LIVENESS_STATUS, SET_MONORAIL_ID, LIVENESS_STATUS_LIST,
} from './constants';

const initialState = fromJS({
    monorailInfo: {
        id: '',
    },
    loading: false,
    statusList: LIVENESS_STATUS_LIST, // keep value incase BE call for fetching status list fails
});

function livenessStatusReducer(state = initialState, action) {
    switch (action.type) {
        case GET_LIVENESS_STATUS_LIST:
        case UPDATE_LIVENESS_STATUS:
            return state
                .set('loading', true);

        case SET_LIVENESS_STATUS_LIST: {
            const { statusList } = action.data;

            return state
                .set('loading', false)
                .set('statusList', fromJS(statusList));
        }

        case SET_LIVENESS_STATUS_LOADER: {
            const { flag = false } = action.data;

            return state
                .set('loading', flag);
        }

        case SET_MONORAIL_ID: {
            const { value } = action.data;

            if (value) {
                return state.setIn(['monorailInfo', 'id'], value);
            }

            // need to persist status list on re-initialising the remaining states
            const newState = initialState.toJS();
            newState.statusList = state.get('statusList').toJS()

            return fromJS(newState);
        }

        default:
            return state;
    }
}

export default livenessStatusReducer;

/*
 *
 * Facematch review selectors
 *
 */

import { createSelector } from 'reselect';

export const selectLoading = () => createSelector(
    (state) => state.getIn(['facematchReview', 'loading']),
    (subState) => subState,
);

export const selectErr = () => createSelector(
    (state) => state.getIn(['facematchReview', 'err']),
    (subState) => subState,
);

export const selectFacematchCasesList = () => createSelector(
    (state) => state.getIn(['facematchReview', 'facematchCasesList']),
    (subState) => subState.toJS(),
);

export const selectFacematchView = () => createSelector(
    (state) => state.getIn(['facematchReview', 'facematchView']),
    (subState) => subState,
);

export const selectVideoFrameImg = () => createSelector(
    (state) => state.getIn(['facematchReview', 'videoFrameImg']),
    (subState) => subState,
);

export const selectKycImg = () => createSelector(
    (state) => state.getIn(['facematchReview', 'kycImg']),
    (subState) => subState,
);

export const selectCurFacematchCase = () => createSelector(
    (state) => state.getIn(['facematchReview', 'curFacematchCase']),
    (subState) => subState.toJS(),
);

export const selectFacematchPayloadType = () => createSelector(
    (state) => state.getIn(['facematchReview', 'facematchPayloadType']),
    (subState) => subState.toJS(),
);

export const selectFacematchPendingReviewCount = () => createSelector(
    (state) => state.getIn(['facematchReview', 'pendingReviewCount']),
    (subState) => subState,
);

export const selectFacematchQueueFilters = () => createSelector(
    (state) => state.getIn(['facematchReview', 'queueFilters']),
    (subState) => subState.toJS(),
);

/**
 * @file UserRiskReview selectors
 */

import { createSelector } from 'reselect';

import { USER_RISK_REVIEW_KEY } from './constants';

export const selectUserRiskReviewLoading = createSelector(
    (state) => state.getIn([USER_RISK_REVIEW_KEY, 'loading']),
    (subState) => subState,
);

export const selectUserRiskReviewError = createSelector(
    (state) => state.getIn([USER_RISK_REVIEW_KEY, 'err']),
    (subState) => subState,
);

export const selectUserRiskReviewSection = (actorId) => createSelector(
    (state) => state.getIn([USER_RISK_REVIEW_KEY, 'reviewSections', actorId]),
    (subState) => subState?.toJS() || [],
);

export const selectIsAFU = createSelector(
    (state) => state.getIn([USER_RISK_REVIEW_KEY, 'isAFU']),
    (subState) => subState,
);

export const selectUserRiskReviewForm = () => createSelector(
    (state) => state.getIn([USER_RISK_REVIEW_KEY, 'userRiskform']),
    (subState) => subState?.toJS(),
);

export const selectScreenerCheckDetails = (requestId) => createSelector(
    (state) => state.getIn([USER_RISK_REVIEW_KEY, 'screenerCheckDetails', requestId]),
    (subState) => subState?.toJS() || {},
);

export const selectLinkedInUrl = createSelector(
    (state) => state.getIn([USER_RISK_REVIEW_KEY, 'linkedInUrl', 'mapping']),
    (subState) => subState?.toJS() || {},
);

export const selectLinkedInProfileLoading = createSelector(
    (state) => state.getIn([USER_RISK_REVIEW_KEY, 'linkedInUrl', 'loading']),
    (subState) => subState,
);

import React from 'react';
import { Button } from '..';
import Arrow from '../../assets/images/svgs/arrow-upright.svg';
import './styles.scss';

const StepsTraversalModal = ({
    title, buttonText, buttonOnclick, backOnClick = () => {}, children, innerChildClass = 'p-20',
}) => (
    <div>
        <div className='frcsbWrapper'>
            <div className='sop-heading'>{title}</div>
            <Button
                link
                label={buttonText}
                onClick={buttonOnclick}
            />
        </div>
        <div className='step-traversal'>
            <div className='sop-cc p-30'>
                {
                    backOnClick
                    && (
                        <Button
                            link
                            label={<img src={Arrow} className='sop-step-back-img' alt='Back Arrow' />}
                            extClasses='mb-15 p-0'
                            onClick={backOnClick}
                        />
                    )
                }

                <div className={innerChildClass}>
                    {children}
                </div>
            </div>
        </div>
    </div>
);

export default StepsTraversalModal;

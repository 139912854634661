import { fromJS } from 'immutable';
import { equals } from 'ramda';

import {
    GET_ACTIVE_REWARDS, SET_ACTIVE_REWARDS, ERR_ACTIVE_REWARDS, GET_ACTIVE_OFFERS,
    SET_ACTIVE_OFFERS, ERR_ACTIVE_OFFERS, GET_USER_REWARDS, SET_USER_REWARDS,
    ERR_USER_REWARDS, GET_USER_REDEEMED_OFFERS, SET_USER_REDEEMED_OFFERS,
    ERR_USER_REDEEMED_OFFERS, GET_USER_REWARD_INFO, SET_USER_REWARD_INFO, ERR_USER_REWARD_INFO,
    SET_SEARCH_PARAMS_FOR_CLAIMS_VIEW, RESET_SEARCH_PARAMS_FOR_CLAIMS_VIEW,
    GET_ACTIVE_EXCHANGER_OFFERS, SET_ACTIVE_EXCHANGER_OFFERS, ERR_ACTIVE_EXCHANGER_OFFERS,
    GET_USER_REDEEMED_EXCHANGER_OFFERS, SET_USER_REDEEMED_EXCHANGER_OFFERS, ERR_USER_REDEEMED_EXCHANGER_OFFERS,
    SET_ACTIVE_VIEW_ENTITY_META, SET_CLAIMS_VIEW_ENTITY_META, SET_SEARCH_PARAMS_FOR_ACTIVE_VIEW,
    RESET_SEARCH_PARAMS_FOR_ACTIVE_VIEW,
    GET_USER_TOTAL_REWARDS,
    SET_USER_TOTAL_REWARDS,
    ERR_USER_TOTAL_REWARDS,
    GET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS,
    SET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS,
    ERR_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS,
    GET_ELIGIBLE_REWARDS,
    SET_ELIGIBLE_REWARDS,
    ERR_ELIGIBLE_REWARDS,
    GET_FI_STORE_REWARDS, SET_FI_STORE_REWARDS, ERR_FI_STORE_REWARDS,
    GET_FI_STORE_REWARD_DETAILS, SET_FI_STORE_REWARD_DETAILS, ERR_FI_STORE_REWARD_DETAILS,
} from './constants';

const initialState = fromJS({
    searchParametersForActiveView: {},
    searchParametersForClaimsView: {},
    activeRewardsList: {},
    activeOffersList: {},
    rewardsList: {},
    redeemedOffersList: {},
    rewardInfo: {},
    activeExchangerOffersList: {},
    redeemedExchangerOffersList: {},
    rewardsViewEntityMeta: {},
    offersViewEntityMeta: {},
    exchangerOffersViewEntityMeta: {},
    activeViewEntityMeta: {},
    claimsViewEntityMeta: {},
    eligibleRewards: {},
    fiStoreRewardsList: {},
    fiStoreRewardAdditionalDetails: {},
});

/**
 * @function setStateHelper used to set state
 * @param {*} state
 * @param {*} key array of keys
 * @param {*} values values to set in the state
 * @returns updated state
 */
function setStateHelper(state, key, values) {
    return state.mergeIn(key, values);
}

/**
 * ES Lint is necessary in below line because the first parameter is always state
 * that parameter needs to have an initial state.
 */
// eslint-disable-next-line default-param-last
function rewardsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SEARCH_PARAMS_FOR_ACTIVE_VIEW: {
            const {
                ticketId, fromDate, toDate, key, value,
            } = action.data;

            // set key-value pair with selected value or clear selected value
            if (key && (value || value === '' || value === null)) {
                return state.setIn(['searchParametersForActiveView', ticketId, key], value);
            }

            if (toDate && fromDate) {
                const values = {
                    fromDate, toDate,
                };
                return state.mergeIn(['searchParametersForActiveView', ticketId], values);
            }

            return state;
        }

        case RESET_SEARCH_PARAMS_FOR_ACTIVE_VIEW: {
            const { ticketId, fromDate, toDate } = action.data;

            return state
                .setIn(['searchParametersForActiveView', ticketId], fromJS({
                    fromDate,
                    toDate,
                    offerRedemptionMode: null,
                    category: null,
                    rewardsApplicableTo: null,
                    cardOfferType: null,
                }))
                .setIn(['activeOffersList', ticketId], fromJS({}))
                .setIn(['activeRewardsList', ticketId], fromJS({}))
                .setIn(['activeExchangerOffersList', ticketId], fromJS({}));
        }

        case SET_SEARCH_PARAMS_FOR_CLAIMS_VIEW: {
            const {
                ticketId, fromDate, toDate, key, value,
            } = action.data;

            // set key-value pair with selected value or clear selected value
            if (key && (value || value === '')) {
                return state.setIn(['searchParametersForClaimsView', ticketId, key], value);
            }

            if (fromDate && toDate) {
                return state
                    .setIn(['searchParametersForClaimsView', ticketId, 'fromDate'], fromJS(fromDate))
                    .setIn(['searchParametersForClaimsView', ticketId, 'toDate'], fromJS(toDate));
            }

            return state;
        }

        case RESET_SEARCH_PARAMS_FOR_CLAIMS_VIEW: {
            const { ticketId, fromDate, toDate } = action.data;

            return state
                .setIn(['searchParametersForClaimsView', ticketId], fromJS({ fromDate, toDate }))
                .setIn(['rewardsList', ticketId], fromJS({}))
                .setIn(['redeemedOffersList', ticketId], fromJS({}))
                .setIn(['rewardInfo', ticketId], fromJS({}))
                .setIn(['fiStoreRewardsList', ticketId], fromJS({}));
        }

        case GET_ACTIVE_REWARDS: {
            const { ticketId } = action.data;

            return state
                .setIn(['activeRewardsList', ticketId, 'loading'], true)
                .setIn(['activeRewardsList', ticketId, 'err'], '');
        }

        case SET_ACTIVE_REWARDS: {
            const {
                ticketId, rewardsList, prev, next,
            } = action.data;

            return state
                .setIn(['activeRewardsList', ticketId, 'loading'], false)
                .setIn(['activeRewardsList', ticketId, 'data'], fromJS(rewardsList))
                .setIn(['activeRewardsList', ticketId, 'prev'], fromJS(prev))
                .setIn(['activeRewardsList', ticketId, 'next'], fromJS(next));
        }

        case ERR_ACTIVE_REWARDS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['activeRewardsList', ticketId, 'loading'], false)
                .setIn(['activeRewardsList', ticketId, 'err'], err)
                .setIn(['activeRewardsList', ticketId, 'data'], fromJS([]))
                .setIn(['activeRewardsList', ticketId, 'prev'], fromJS({}))
                .setIn(['activeRewardsList', ticketId, 'next'], fromJS({}));
        }

        case GET_ACTIVE_OFFERS: {
            const { ticketId } = action.data;

            return state
                .setIn(['activeOffersList', ticketId, 'loading'], true)
                .setIn(['activeOffersList', ticketId, 'err'], '');
        }

        case SET_ACTIVE_OFFERS: {
            const {
                ticketId, offersList, prev, next,
            } = action.data;

            return state
                .setIn(['activeOffersList', ticketId, 'loading'], false)
                .setIn(['activeOffersList', ticketId, 'data'], fromJS(offersList))
                .setIn(['activeOffersList', ticketId, 'prev'], fromJS(prev))
                .setIn(['activeOffersList', ticketId, 'next'], fromJS(next));
        }

        case ERR_ACTIVE_OFFERS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['activeOffersList', ticketId, 'loading'], false)
                .setIn(['activeOffersList', ticketId, 'err'], err)
                .setIn(['activeOffersList', ticketId, 'data'], fromJS([]))
                .setIn(['activeOffersList', ticketId, 'prev'], fromJS({}))
                .setIn(['activeOffersList', ticketId, 'next'], fromJS({}));
        }

        case GET_USER_REWARDS: {
            const { ticketId } = action.data;

            return state
                .setIn(['rewardsList', ticketId, 'loading'], true)
                .setIn(['rewardsList', ticketId, 'err'], '');
        }

        case SET_USER_REWARDS: {
            const {
                ticketId, rewardsList, prev, next,
            } = action.data;

            return state
                .setIn(['rewardsList', ticketId, 'loading'], false)
                .setIn(['rewardsList', ticketId, 'data'], fromJS(rewardsList))
                .setIn(['rewardsList', ticketId, 'prev'], fromJS(prev))
                .setIn(['rewardsList', ticketId, 'next'], fromJS(next));
        }

        case ERR_USER_REWARDS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['rewardsList', ticketId, 'loading'], false)
                .setIn(['rewardsList', ticketId, 'err'], err)
                .setIn(['rewardsList', ticketId, 'data'], fromJS([]))
                .setIn(['rewardsList', ticketId, 'prev'], fromJS({}))
                .setIn(['rewardsList', ticketId, 'next'], fromJS({}));
        }

        case GET_USER_REDEEMED_OFFERS: {
            const { ticketId } = action.data;

            return state
                .setIn(['redeemedOffersList', ticketId, 'loading'], true)
                .setIn(['redeemedOffersList', ticketId, 'err'], '');
        }

        case GET_USER_TOTAL_REWARDS: {
            const { ticketId } = action.data;

            return state
                .setIn(['totalRewardsList', ticketId, 'loading'], true)
                .setIn(['totalRewardsList', ticketId, 'err'], '');
        }

        case SET_USER_TOTAL_REWARDS: {
            const { ticketId, totalRewardsList } = action.data;

            return state
                .setIn(['totalRewardsList', ticketId, 'loading'], false)
                .setIn(['totalRewardsList', ticketId, 'data'], fromJS(totalRewardsList))
                .setIn(['totalRewardsList', ticketId, 'err'], '');
        }

        case ERR_USER_TOTAL_REWARDS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['totalRewardsList', ticketId, 'loading'], false)
                .setIn(['totalRewardsList', ticketId, 'err'], err)
                .setIn(['totalRewardsList', ticketId, 'data'], fromJS({}));
        }

        case SET_USER_REDEEMED_OFFERS: {
            const {
                ticketId, redeemedOffersList, prev, next,
            } = action.data;

            return state
                .setIn(['redeemedOffersList', ticketId, 'loading'], false)
                .setIn(['redeemedOffersList', ticketId, 'data'], fromJS(redeemedOffersList))
                .setIn(['redeemedOffersList', ticketId, 'prev'], fromJS(prev))
                .setIn(['redeemedOffersList', ticketId, 'next'], fromJS(next));
        }

        case ERR_USER_REDEEMED_OFFERS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['redeemedOffersList', ticketId, 'loading'], false)
                .setIn(['redeemedOffersList', ticketId, 'err'], err)
                .setIn(['redeemedOffersList', ticketId, 'data'], fromJS([]))
                .setIn(['redeemedOffersList', ticketId, 'prev'], fromJS({}))
                .setIn(['redeemedOffersList', ticketId, 'next'], fromJS({}));
        }

        case GET_USER_REWARD_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['rewardInfo', ticketId, 'loading'], true)
                .setIn(['rewardInfo', ticketId, 'err'], '')
                .setIn(['rewardInfo', ticketId, 'data'], fromJS({}))
                .setIn(['rewardInfo', ticketId, 'hasData'], false);
        }

        case SET_USER_REWARD_INFO: {
            const { ticketId, rewardInfo } = action.data;

            return state
                .setIn(['rewardInfo', ticketId, 'loading'], false)
                .setIn(['rewardInfo', ticketId, 'hasData'], true)
                .setIn(['rewardInfo', ticketId, 'data'], fromJS(rewardInfo));
        }

        case ERR_USER_REWARD_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['rewardInfo', ticketId, 'loading'], false)
                .setIn(['rewardInfo', ticketId, 'err'], err)
                .setIn(['rewardInfo', ticketId, 'hasData'], false)
                .setIn(['rewardInfo', ticketId, 'data'], fromJS({}));
        }

        case GET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS: {
            const key = ['eligibleRewards', 'dropdownOptions'];
            const values = {
                loading: true,
                err: '',
                data: fromJS({}),
                hasData: false,
            };
            return setStateHelper(state, key, values);
        }

        case SET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS: {
            const { dropdownOptions } = action.data;
            const key = ['eligibleRewards', 'dropdownOptions'];
            const values = {
                loading: false,
                data: fromJS(dropdownOptions),
                hasData: true,
            };
            return setStateHelper(state, key, values);
        }

        case ERR_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS: {
            const { err } = action.data;
            const key = ['eligibleRewards', 'dropdownOptions'];
            const values = {
                loading: false,
                data: fromJS({}),
                hasData: false,
                err,
            };
            return setStateHelper(state, key, values);
        }

        case GET_ELIGIBLE_REWARDS: {
            const { ticketId } = action.data;

            const key = ['eligibleRewards', ticketId];
            const values = {
                loading: true,
                data: fromJS({}),
                hasData: false,
                err: '',
            };
            return setStateHelper(state, key, values);
        }

        case SET_ELIGIBLE_REWARDS: {
            const { ticketId, rewardsTable } = action.data;

            const key = ['eligibleRewards', ticketId];
            const values = {
                loading: false,
                data: fromJS(rewardsTable),
                hasData: true,
                err: '',
            };
            return setStateHelper(state, key, values);
        }

        case ERR_ELIGIBLE_REWARDS: {
            const { ticketId, err } = action.data;

            const key = ['eligibleRewards', ticketId];
            const values = {
                loading: false,
                data: fromJS({}),
                hasData: false,
                err,
            };
            return setStateHelper(state, key, values);
        }

        case GET_ACTIVE_EXCHANGER_OFFERS: {
            const { ticketId } = action.data;

            return state
                .setIn(['activeExchangerOffersList', ticketId, 'loading'], true)
                .setIn(['activeExchangerOffersList', ticketId, 'err'], '');
        }

        case SET_ACTIVE_EXCHANGER_OFFERS: {
            const { ticketId, exchangerOffersList } = action.data;

            return state
                .setIn(['activeExchangerOffersList', ticketId, 'loading'], false)
                .setIn(['activeExchangerOffersList', ticketId, 'data'], fromJS(exchangerOffersList));
        }

        case ERR_ACTIVE_EXCHANGER_OFFERS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['activeExchangerOffersList', ticketId, 'loading'], false)
                .setIn(['activeExchangerOffersList', ticketId, 'err'], err)
                .setIn(['activeExchangerOffersList', ticketId, 'data'], fromJS([]));
        }

        case GET_USER_REDEEMED_EXCHANGER_OFFERS: {
            const { ticketId } = action.data;

            return state
                .setIn(['redeemedExchangerOffersList', ticketId, 'loading'], true)
                .setIn(['redeemedExchangerOffersList', ticketId, 'err'], '');
        }

        case SET_USER_REDEEMED_EXCHANGER_OFFERS: {
            const {
                ticketId, redeemedExchangerOffersList, prev, next,
            } = action.data;

            return state
                .setIn(['redeemedExchangerOffersList', ticketId, 'loading'], false)
                .setIn(['redeemedExchangerOffersList', ticketId, 'data'], fromJS(redeemedExchangerOffersList))
                .setIn(['redeemedExchangerOffersList', ticketId, 'prev'], fromJS(prev))
                .setIn(['redeemedExchangerOffersList', ticketId, 'next'], fromJS(next));
        }

        case ERR_USER_REDEEMED_EXCHANGER_OFFERS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['redeemedExchangerOffersList', ticketId, 'loading'], false)
                .setIn(['redeemedExchangerOffersList', ticketId, 'err'], err)
                .setIn(['redeemedExchangerOffersList', ticketId, 'data'], fromJS([]))
                .setIn(['redeemedExchangerOffersList', ticketId, 'prev'], fromJS({}))
                .setIn(['redeemedExchangerOffersList', ticketId, 'next'], fromJS({}));
        }

        case SET_ACTIVE_VIEW_ENTITY_META: {
            const { meta } = action.data;

            const activeViewEntityMeta = state.get('activeViewEntityMeta').toJS();
            let modifiedActiveViewEntityMeta = activeViewEntityMeta;

            if (equals(activeViewEntityMeta, meta)) {
                modifiedActiveViewEntityMeta = {};
            } else {
                modifiedActiveViewEntityMeta = meta;
            }

            return state
                .set('activeViewEntityMeta', fromJS(modifiedActiveViewEntityMeta));
        }

        case SET_CLAIMS_VIEW_ENTITY_META: {
            const { meta } = action.data;

            const claimsViewEntityMeta = state.get('claimsViewEntityMeta').toJS();
            let modifiedClaimsViewEntityMeta = claimsViewEntityMeta;

            if (equals(claimsViewEntityMeta, meta)) {
                modifiedClaimsViewEntityMeta = {};
            } else {
                modifiedClaimsViewEntityMeta = meta;
            }

            return state
                .set('claimsViewEntityMeta', fromJS(modifiedClaimsViewEntityMeta));
        }

        case GET_FI_STORE_REWARDS: {
            const { ticketId } = action.data;
            const values = {
                loading: true,
                err: '',
                data: {},
            };

            return setStateHelper(state, ['fiStoreRewardsList', ticketId], values);
        }

        case SET_FI_STORE_REWARDS: {
            const { ticketId, fiStoreRewardsTable } = action.data;
            const values = {
                loading: false,
                err: '',
                data: fiStoreRewardsTable,
            };

            return setStateHelper(state, ['fiStoreRewardsList', ticketId], values);
        }

        case ERR_FI_STORE_REWARDS: {
            const { ticketId, err } = action.data;
            const values = {
                loading: false,
                err,
                data: {},
            };

            return setStateHelper(state, ['fiStoreRewardsList', ticketId], values);
        }

        case GET_FI_STORE_REWARD_DETAILS: {
            const { ticketId } = action.data;
            const values = {
                loading: true,
                err: '',
                data: {},
            };

            return setStateHelper(state, ['fiStoreRewardAdditionalDetails', ticketId], values);
        }

        case SET_FI_STORE_REWARD_DETAILS: {
            const { ticketId, fiStoreRewardsAdditionalDetails } = action.data;
            const values = {
                loading: false,
                err: '',
                data: fiStoreRewardsAdditionalDetails,
            };

            return setStateHelper(state, ['fiStoreRewardAdditionalDetails', ticketId], values);
        }

        case ERR_FI_STORE_REWARD_DETAILS: {
            const { ticketId, err } = action.data;
            const values = {
                loading: false,
                err,
                data: {},
            };

            return setStateHelper(state, ['fiStoreRewardAdditionalDetails', ticketId], values);
        }

        default:
            return state;
    }
}

export default rewardsReducer;

/**
 * @file RaiseVerificationRequestTable
 * Renders the verification cases
 */

import React, { memo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, Table } from '../../../../components';
import { useMemoizedSelector } from '../../../../hooks';
import {
    getRaiseVerificationRequestCaseDetailsAction, getRaiseVerificationRequestEligibleTxnsAction,
    getVerificationCaseTxnHistoryAction,
    setRaiseVerificationRequestSelectedActorAction, setSalaryAccountOpsCurViewAction,
} from '../../actions';
import { SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY, VIEW_RAISE_VERIFICATION_SINGLE_CASE } from '../../constants';
import {
    selectRaiseVerificationRequestCases,
    selectRaiseVerificationRequestStage, selectRaiseVerificationRequestStatus, selectSalaryAccountUserIdentifierDetails,
} from '../../selectors';

const QUEUE_LABEL_DATA = [
    {
        label: 'Actor Name',
        key: 'actorName',
    },
    {
        label: 'Actor Phone No.',
        key: 'actorPhoneNumber',
    },
    {
        label: 'Last Verified Txn date',
        key: 'lastVerifiedSalaryTxn',
        hasSortConfig: true,
    },
    {
        label: 'Status',
        key: 'verificationRequestStatus',
    },
];

const RaiseVerificationRequestTable = (props) => {
    const { makePayloadAndGetVerificationCases } = props;

    const dispatch = useDispatch();

    const { data: casesList, prev: prevToken, next: nextToken } = useSelector(selectRaiseVerificationRequestCases, shallowEqual);
    const raiseVerificationStatus = useSelector(selectRaiseVerificationRequestStatus, shallowEqual);
    const raiseVerificationStage = useSelector(selectRaiseVerificationRequestStage, shallowEqual);
    const userIdentifier = useMemoizedSelector(selectSalaryAccountUserIdentifierDetails, [SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY]);

    const [sortOrder, setSortOrder] = useState('');

    const getPaginatedVerificationCases = ({ prev, next }) => {
        makePayloadAndGetVerificationCases(raiseVerificationStatus.value, raiseVerificationStage.value, {
            prev, next, userIdentifier, sortOrder,
        });
    };

    const getSortingDataBasedOnSortingOrder = (value) => {
        setSortOrder(value);
    };

    const handleSeeDetails = (item) => {
        const payload = {
            actorId: item?.actorId,
        };

        dispatch(getRaiseVerificationRequestCaseDetailsAction(payload));
        dispatch(getRaiseVerificationRequestEligibleTxnsAction(payload));
        dispatch(setRaiseVerificationRequestSelectedActorAction(item));
        dispatch(getVerificationCaseTxnHistoryAction({ phoneNumber: item?.unmaskedPhoneNumber }));
        dispatch(setSalaryAccountOpsCurViewAction({
            screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY, value: VIEW_RAISE_VERIFICATION_SINGLE_CASE,
        }));
    };

    const renderActions = (item, index) => (
        <div className='frfscWrapper'>
            <Button
                link
                label='See details'
                onClick={() => handleSeeDetails(item, index)}
            />
        </div>
    );

    useEffect(() => {
        if (!casesList) makePayloadAndGetVerificationCases(raiseVerificationStatus.value, raiseVerificationStage.value);
    }, []);

    useEffect(() => {
        if (sortOrder) {
            makePayloadAndGetVerificationCases(raiseVerificationStatus.value, raiseVerificationStage.value, { sortOrder });
        }
    }, [sortOrder]);

    return (
        <Table
            v2
            labelData={QUEUE_LABEL_DATA}
            contentData={casesList}
            renderActionableColumn={renderActions}
            getSortingDataBasedOnSortingOrder={getSortingDataBasedOnSortingOrder}
            prevToken={prevToken}
            nextToken={nextToken}
            paginatedClickHandler={getPaginatedVerificationCases}
            extClasses={{
                container: 'salops-uetb__cr',
            }}
        />
    );
};

export default memo(RaiseVerificationRequestTable);

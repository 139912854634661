/**
 * @file ClearDropDown for transaction table
 * used for clearing filters, re-orderd columns, resized columns ect...
 */

import React, { useState } from 'react';
import classNames from 'classnames';

import upArrow from '../../../assets/images/svgs/arrow-white.svg';
import { DropdownWrapper, DropdownContent, Button } from '../../../components';

import { clearDropdownOptions } from './constants';

const DROPDOWN_HEIGHT = clearDropdownOptions.length * 36;

const ClearDropDown = (props) => {
    const { onOptionClick, disabled } = props;
    const [open, setOpen] = useState(false);

    const labelImgClass = classNames('rdgrid-cd-label-img', 'transform-transition', {
        'rdgrid-cd-label-img-open': open,
    });

    const label = (
        <span className='rdgrid-cd-label'>
            Clear
            <img
                className={labelImgClass}
                src={upArrow}
                alt='arrow'
            />
        </span>
    );

    const handleonClick = (option) => {
        onOptionClick(option);
        setOpen(false);
    };

    return (
        <DropdownWrapper
            visible={open}
            onOutsideClickHandler={() => setOpen(false)}
        >
            <Button
                v2
                type='button'
                primary
                label={label}
                disabled={disabled}
                onClick={() => {
                    setOpen((pre) => !pre);
                }}
            />
            <DropdownContent
                visible
                extStyles={{
                    height: open && DROPDOWN_HEIGHT,
                }}
                extClasses={{
                    container: [
                        'rdgrid-cd-oc height-transition',
                    ],
                }}
            >
                {clearDropdownOptions
                    .map((option) => (
                        <button
                            key={option.value}
                            type='button'
                            className='disputes-ad-cc-wr__label'
                            onClick={() => handleonClick(option)}
                        >
                            {option.label}
                        </button>
                    ))}
            </DropdownContent>
        </DropdownWrapper>
    );
};

export default ClearDropDown;

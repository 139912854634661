/**
 * @file RenderView
 * Switches between all cases view and single case view
 */

import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedSelector } from '../../../hooks';
import { HorizontalIdNavbar } from '../../../components';

import AllCases from '../AllCases';
import LoaderError from '../LoaderError';

import { setRiskOpsCurCaseAction, setRiskOpsCurViewAction } from '../actions';
import { RISK_OPS_VIEW_ALL_CASES, RISK_OPS_VIEW_SINGLE_CASE } from '../constants';
import { selectRiskOpsCurView } from '../selectors';

const RenderView = (props) => {
    const { data, singleCaseComponent } = props;
    const { containerKey, caseId, actorId } = data;

    const dispatch = useDispatch();
    const curView = useMemoizedSelector(selectRiskOpsCurView, [containerKey]);

    const handleReviewClick = (caseData) => () => {
        dispatch(setRiskOpsCurCaseAction({ containerKey, caseData }));
        dispatch(setRiskOpsCurViewAction({ containerKey, newView: RISK_OPS_VIEW_SINGLE_CASE }));
    };

    useEffect(() => {
        if (caseId) {
            handleReviewClick({
                payload: 'user_review',
                user_review: {
                    actor_id: actorId,
                },
                caseId,
            })();
        }

        return () => {
            dispatch(setRiskOpsCurViewAction({ containerKey, newView: RISK_OPS_VIEW_ALL_CASES }));
        };
    }, [caseId]);

    const renderCurView = () => {
        switch (curView) {
            case RISK_OPS_VIEW_ALL_CASES: {
                return (
                    <AllCases
                        handleReviewClick={handleReviewClick}
                        data={data}
                    />
                );
            }

            case RISK_OPS_VIEW_SINGLE_CASE: {
                return singleCaseComponent;
            }

            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            {!caseId ? <HorizontalIdNavbar isFixed isEmptyNavbar /> : null}
            {renderCurView()}
            {!caseId ? <LoaderError containerKey={containerKey} /> : null}
        </React.Fragment>
    );
};

export default memo(RenderView);

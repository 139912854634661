/**
 *
 * ViewSingleScript - Component to show individual script content.
 *
 */
import React, { useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { ReactComponent as Arrow } from '../../../assets/images/svgs/arrow-chevron-right.svg';
import { Button, LoaderOrError } from '../../../components';
import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';
import { UserDetailsContext } from '../../../context';

import { SCRIPTS_PATH } from '../../AppV2/routes';

import { getScriptAction } from '../actions';
import { selectScriptData } from '../selector';
import { SCREEN_KEY, SHERLOCK_V2_ENTRY_POINT, renderPostCallSteps } from '../constants';
import PictureGuide from '../PictureGuide';

const ViewSingleScript = () => {
    const { scriptId } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);
    const script = useSelector(selectScriptData, shallowEqual);

    const { prevPath } = location.state || {};
    let data;
    let loading;
    let err;
    if (script) {
        ({ loading, err, data } = script);
    }

    useEffect(() => {
        dispatch(getScriptAction({ screenKey: SCREEN_KEY.script, scriptId }));
    }, [scriptId]);

    const rudderProperties = {
        ticketId: null,
        callId: null,
        entryPoint: SHERLOCK_V2_ENTRY_POINT,
    };

    useEffect(() => {
        if (data && data?.title) {
            const properties = {
                id: scriptId,
                title: data?.title,
                ...rudderProperties,
            };
            pushRudderEvent(RudderEvent.SherlockV2Scripts.LandedSherlockV2ScriptsPage, agentInfo.emailId, properties);
        }
    }, [JSON.stringify(data)]);

    const handleOnDone = () => {
        const properties = {
            id: scriptId,
            ...rudderProperties,
        };
        pushRudderEvent(RudderEvent.SherlockV2Scripts.ClickedPictureGuideDoneButton, agentInfo.emailId, properties);
        // If the site is reached by manually putting url or opens in a new tab then goes to scripts page otherwise goes to the last visited page
        if (prevPath && prevPath.includes(SCRIPTS_PATH)) {
            history.goBack();
        } else {
            history.push(SCRIPTS_PATH);
        }
    };

    return (
        <React.Fragment>
            {
                prevPath && prevPath.includes(SCRIPTS_PATH) ? (
                    <button className='frcWrapper ml-15' type='button' onClick={() => history.goBack()}>
                        <Arrow className='scripts-arrow' />
                        <div>Back to all Scripts</div>
                    </button>
                ) : null
            }
            {
                data
                    && (
                        <div className='scripts-cont'>
                            <div className='scripts-title'>{data?.title}</div>
                            <div className='scripts-des pb-20'>{data?.shortDescription}</div>
                            {
                                data && data?.solutions && data?.solutions.map((solution) => (
                                    <React.Fragment key={solution?.postCallStep?.title}>
                                        {
                                            solution?.postCallStep && (
                                                <React.Fragment>
                                                    <div className='scripts-heading'>
                                                        {solution?.postCallStep?.title}
                                                    </div>
                                                    <div className='scripts-pcs scripts-cc'>
                                                        <div className='frWrapper'>
                                                            <div className='p-20'>
                                                                <div className='scripts-pcs-title scripts-pcs-cell-title'>Details</div>
                                                            </div>
                                                            <div className='frwpWrapper scripts-pcs-values'>
                                                                {
                                                                    solution?.postCallStep
                                                                        && renderPostCallSteps(solution?.postCallStep).map((category) => (
                                                                            <div className='scripts-pcs-cell' key={category.title}>
                                                                                <div className='scripts-pcs-cell-title'>{category.title}</div>
                                                                                <div className='scripts-pcs-cell-value'>{category.value || '-'}</div>
                                                                            </div>
                                                                        ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='line' />
                                                        <div className='frWrapper'>
                                                            <div className='p-20'>
                                                                <div className='scripts-pcs-title scripts-pcs-cell-title'>Notes</div>
                                                            </div>
                                                            <div className='scripts-pcs-cell'>
                                                                <div className='scripts-pcs-cell-value'>{solution?.postCallStep?.notes || '-'}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            solution?.postCallStep && (<div className='line' />)
                                        }
                                        {
                                            // eslint-disable-next-line react/no-danger
                                            solution?.text && (<div className='scripts-text' dangerouslySetInnerHTML={{ __html: solution?.text }} />)
                                        }
                                        {
                                            solution?.pictureGuides && (
                                                <PictureGuide item={solution?.pictureGuides} scriptId={scriptId} />
                                            )
                                        }
                                    </React.Fragment>
                                ))
                            }
                            <div className='frcfeWrapper'>
                                <Button v2 primary label='Done' onClick={handleOnDone} />
                            </div>
                        </div>
                    )
            }
            <LoaderOrError loading={loading} errorLabel={err} />
        </React.Fragment>
    );
};

export default ViewSingleScript;

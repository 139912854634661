/**
 *
 * @file USSActivityDetail : Show views according to Portfolio Details or Activity Details.
 *
 */

import React, { memo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getActivitiesAction, getActivityTypeListAction, setSearchParamsAction, setUsStocksViewAction } from '../actions';
import { VIEW_ACTIVITIES_DETAILS, VIEW_ACTIVITIES_DETAILS_LIST } from '../constants';
import { selectUSSActivityTypeList, selectUsStocksView } from '../selectors';

import RenderView from './RenderView';

const USSActivityDetail = (props) => {
    const {
        ticketId, userId, index,
    } = props;

    const dispatch = useDispatch();

    const usStocksView = useSelector(selectUsStocksView, shallowEqual);
    const activityTypeList = useSelector(selectUSSActivityTypeList, shallowEqual);

    useEffect(() => {
        if (usStocksView !== VIEW_ACTIVITIES_DETAILS_LIST) {
            const view = VIEW_ACTIVITIES_DETAILS;
            dispatch(setUsStocksViewAction(view));
        }
    }, [dispatch, usStocksView]);

    useEffect(() => {
        if (!activityTypeList) {
            dispatch(getActivityTypeListAction());
        }
    }, [activityTypeList, dispatch]);

    const searchAction = (params) => {
        const {
            textInput, fromDate, toDate, activityType,
        } = params;

        let payload = {
            ticketId,
            userId,
            index,
        };

        const searchParams = {
            orderId: textInput, fromDate, toDate, activityType,
        };

        if (textInput) {
            payload = {
                ...payload,
                orderId: btoa(textInput),
            };
        }

        if (fromDate || toDate || activityType) {
            payload = {
                ...payload,
                from: fromDate?.value ?? '',
                to: toDate?.value ?? '',
                activityType: activityType?.value ?? '',
            };
        }

        dispatch(setSearchParamsAction(searchParams));
        dispatch(getActivitiesAction(payload));
        dispatch(setUsStocksViewAction(VIEW_ACTIVITIES_DETAILS_LIST));
    };

    return (
        <div className='uss'>
            <RenderView
                usStocksView={usStocksView}
                ticketId={ticketId}
                userId={userId}
                index={index}
                searchAction={searchAction}
            />
        </div>
    );
};

export default memo(USSActivityDetail);

/**
 * @file JSONDiffViewer Modal
 * The Modal lets users view JSON difference
 */

import React, { memo } from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';
import Modal from 'react-modal';

import { Button } from '../../../components';

import { DEFAULT_STYLES_FOR_MODAL } from '../constants';

const customStylesForModal = {
    overlay: {
        ...DEFAULT_STYLES_FOR_MODAL.overlay,
    },
    content: {
        ...DEFAULT_STYLES_FOR_MODAL.content,
        width: 'fit-content',
        height: 'fit-content',
    },
};

const JSONDiffViewer = (props) => {
    const {
        visible,
        toggleHandler,
        oldJson,
        newJson,
    } = props;

    const closeModal = () => {
        toggleHandler(false);
    };

    if (!visible) return null;

    const formatObjectString = (jsonString) => JSON.stringify(JSON.parse(jsonString), null, 2);

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='Old value and new value difference'
        >
            <div className='fcWrapper mb-30'>
                <ReactDiffViewer oldValue={formatObjectString(oldJson)} newValue={formatObjectString(newJson)} splitView />
            </div>

            <Button
                v2
                secondary
                extClasses='disputes-modal-cta'
                label='Close'
                onClick={closeModal}
            />
        </Modal>
    );
};

export default memo(JSONDiffViewer);

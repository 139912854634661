/**
 * @file Manages the action functions for App Global component
 */

import { SET_LOGIN_URL, SET_ACCESS_LEVEL, GET_AGENT_INFO } from '../constants/actions';

import {
    GET_AUTH_LOGOUT, GET_AUTH_STATUS, SET_AUTH_STATUS_LOADER, GET_META_INFO, GET_BANNER_LIST, SET_BANNERS_VISIBILITY,
} from './constants';

export const getAuthStatus = (data) => ({
    type: GET_AUTH_STATUS,
    data,
});

export const getAgentInfo = (data) => ({
    type: GET_AGENT_INFO,
    data,
});

export const getAuthLogout = () => ({
    type: GET_AUTH_LOGOUT,
});

export const setAuthStatusLoader = (data) => ({
    type: SET_AUTH_STATUS_LOADER,
    data,
});

export const setLoginUrl = (data) => ({
    type: SET_LOGIN_URL,
    data,
});

export const setAccessLevel = (data) => ({
    type: SET_ACCESS_LEVEL,
    data,
});

export const getMetaInfo = (data) => ({
    type: GET_META_INFO,
    data,
});

const getBannerList = (data) => ({
    type: GET_BANNER_LIST,
    data,
});

const setBannersVisibility = (data) => ({
    type: SET_BANNERS_VISIBILITY,
    data,
});

export {
    getBannerList,
    setBannersVisibility,
};

import { createSelector } from 'reselect';

const selectLivenessSummary = state => state.get('livenessSummary');

const makeSelectLoading = () => createSelector(
    selectLivenessSummary,
    substate => substate.get('loading')
);

const makeSelectErr = () => createSelector(
    selectLivenessSummary,
    substate => substate.get('err')
);

const makeSelectSummaryInfo = () => createSelector(
    selectLivenessSummary,
    substate => substate.get('summaryInfo')
);

const makeSelectMonorailInfo = () => createSelector(
    selectLivenessSummary,
    substate => substate.get('monorailInfo').toJS()
);

export {
    makeSelectLoading,
    makeSelectErr,
    makeSelectSummaryInfo,
    makeSelectMonorailInfo,
};

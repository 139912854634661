export const CUSTOMER_AUTH_TYPES = {
    phoneNumber: 'CUSTOMER_AUTH_IDENTIFIER_TYPE_PHONE_NUMBER',
    email: 'CUSTOMER_AUTH_IDENTIFIER_TYPE_EMAIL_ID',
    ticket: 'CUSTOMER_AUTH_IDENTIFIER_TYPE_FRESHDESK_TICKET_ID',
};

export const IDENTIFIER_TYPES = [
    {
        label: 'Phone Number',
        value: CUSTOMER_AUTH_TYPES.phoneNumber,
    },
    {
        label: 'Email',
        value: CUSTOMER_AUTH_TYPES.email,
    },
];

export const COUNTRY_CODES = [
    {
        label: '91',
        value: '91',
    },
];

/**
 * @file Actions for account ops submit CSV details component
 */

import {
    SET_UPLOAD_CSV_FORM_PARAMS, UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS, UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS, VERIFY_BULK_ACCOUNTS_INFO,
} from './constants';

export const setUploadCSVFormParamsAction = (data) => ({
    type: SET_UPLOAD_CSV_FORM_PARAMS,
    data,
});

export const verifyBulkAccountsInfoAction = (data) => ({
    type: VERIFY_BULK_ACCOUNTS_INFO,
    data,
});

export const uploadBalanceOfCloseAccountsAction = (data) => ({
    type: UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS,
    data,
});

export const uploadSuccessfulBalanceTransfersAction = (data) => ({
    type: UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS,
    data,
});

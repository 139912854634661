/**
 * @file Constants for UserActions container
 */

// container key
export const USER_ACTIONS_KEY = 'userActions';

// callback user
export const CALLBACK_USER = `app/${USER_ACTIONS_KEY}/CALLBACK_USER`;
export const SET_CALLBACK_USER = `app/${USER_ACTIONS_KEY}/SET_CALLBACK_USER`;
export const ERR_CALLBACK_USER = `app/${USER_ACTIONS_KEY}/ERR_CALLBACK_USER`;
export const SET_CALLBACK_USER_FLOW = `app/${USER_ACTIONS_KEY}/SET_CALLBACK_USER_FLOW`;

// pay user
export const RAISE_PAY_REQUEST = `app/${USER_ACTIONS_KEY}/RAISE_PAY_REQUEST`;
export const SET_PAY_REQUEST = `app/${USER_ACTIONS_KEY}/SET_PAY_REQUEST`;
export const ERR_PAY_REQUEST = `app/${USER_ACTIONS_KEY}/ERR_PAY_REQUEST`;
export const GET_ALLOWED_FC_VALUE = `app/${USER_ACTIONS_KEY}/GET_ALLOWED_FC_VALUE`;
export const SET_ALLOWED_FC_VALUE = `app/${USER_ACTIONS_KEY}/SET_ALLOWED_FC_VALUE`;
export const SET_PAYOUT_TYPE = `app/${USER_ACTIONS_KEY}/SET_PAYOUT_TYPE`;

// trigger push notification
export const TRIGGER_PUSH_NOTIFICATION_APPLOGS = `app/${USER_ACTIONS_KEY}/TRIGGER_PUSH_NOTIFICATION_APPLOGS`;
export const SET_PUSH_NOTIFICATION_APPLOGS = `app/${USER_ACTIONS_KEY}/SET_PUSH_NOTIFICATION_APPLOGS`;

// user exported app logs
export const GET_USER_EXPORTED_APP_LOGS = `app/${USER_ACTIONS_KEY}/GET_USER_EXPORTED_APP_LOGS`;
export const SET_USER_EXPORTED_APP_LOGS = `app/${USER_ACTIONS_KEY}/SET_USER_EXPORTED_APP_LOGS`;

// payout type
export const UA_PAYOUT_TYPE_CASH = 'PAYOUT_TYPE_CASH';
export const UA_PAYOUT_TYPE_FI_COINS = 'PAYOUT_TYPE_FI_COINS';
export const UA_PAY_USER_PAYOUT_TYPES = {
    cash: {
        label: 'CASH',
        value: UA_PAYOUT_TYPE_CASH,
    },
    fiCoins: {
        label: 'FI_COINS',
        value: UA_PAYOUT_TYPE_FI_COINS,
    },
};

export const EXPORTED_APP_LOG_LABEL_DATA = [
    {
        label: 'App Version',
        key: 'appVersion',
    },
    {
        label: 'Exported Time',
        key: 'exportTs',
    },
];

/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */

/*
 * @file Transaction Queue
 * Page to view the details of the Transaction Queue
 */

import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    Table, Loader, HorizontalIdNavbar,
    Button, ParameterList,
} from '../../components';

import { RISK_OPS_TXN_CASE_DETAILS_PATH, RISK_OPS_USER_CASE_DETAILS_PATH } from '../App/routes';

import {
    selectTransactionQueueDetails, selectTransactionQueueLoader,
    selectTransactionQueueParameterList, selectTransactionQueueError,
    selectTransactionQueueFilters,
} from './selectors';
import { PARAMETER_FILTERS_CONFIG, getDefaultParamsValue, TransactionPageConfig } from './constants';
import { getPrioritizedCaseAction, getTransactionQueueAction, getTransactionQueueFilterAction, resetTransactionQueueAction } from './actions';

import './styles.scss';

const TransactionQueue = (props) => {
    const { listType } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const tableData = useSelector(selectTransactionQueueDetails, shallowEqual);
    const loading = useSelector(selectTransactionQueueLoader, shallowEqual);
    const parameterList = useSelector(selectTransactionQueueParameterList, shallowEqual);
    const transactionQueueError = useSelector(selectTransactionQueueError, shallowEqual);
    const transactionQueueFilters = useSelector(selectTransactionQueueFilters, shallowEqual);
    const { savedfilterlist = null, savedFilterValues = null } = transactionQueueFilters[listType];

    const makePayloadAndCallApi = (params = {}) => {
        const {
            prev, next, filters, curParameterList,
        } = params;

        const payload = {
            prevToken: prev,
            nextToken: next,
            filters,
            listType,
            curParameterList,
        };
        dispatch(getTransactionQueueAction(payload));
    };

    const getPaginatedTransactionQueue = ({ prev, next }) => {
        makePayloadAndCallApi({
            prev, next, filters: savedfilterlist, curParameterList: savedfilterlist,
        });
    };

    const handleReviewClick = ({ case_id }) => {
        let ROUTE_PATH = RISK_OPS_TXN_CASE_DETAILS_PATH;

        if (listType === 'userRisk') {
            ROUTE_PATH = RISK_OPS_USER_CASE_DETAILS_PATH;
        }

        history.push(ROUTE_PATH, { caseId: case_id });
    };

    const onFilterSubmit = (curFilterList, curParameterList) => {
        makePayloadAndCallApi({
            filters: curFilterList,
            curParameterList,
        });
    };

    const handleRefreshClick = () => {
        makePayloadAndCallApi({
            filters: savedFilterValues,
            curParameterList: savedfilterlist,
        });
    };

    const renderActions = (item) => (
        <div className='frfscWrapper'>
            <Button
                secondary
                label='Review'
                onClick={() => handleReviewClick(item)}
            />
        </div>
    );

    const goToCaseDetailPage = ({ caseInfo }) => (caseId) => {
        history.push(caseInfo?.routePath, { caseId, caseInfo });
    };

    const handlePrioritizedTaskClick = () => {
        dispatch(getPrioritizedCaseAction({
            goToCaseDetailPage: goToCaseDetailPage({
                caseInfo: {
                    routePath: TransactionPageConfig[listType]?.route,
                    listType,
                },
            }),
            reviewType: listType,
        }));
    };

    useEffect(() => {
        const defaultFilterList = savedFilterValues || getDefaultParamsValue({ listType });
        const getDefaultParamsList = savedfilterlist || parameterList;
        makePayloadAndCallApi({ filters: defaultFilterList, curParameterList: getDefaultParamsList });
        dispatch(getTransactionQueueFilterAction({ listType }));

        return () => {
            dispatch(resetTransactionQueueAction());
        };
    }, [listType]);

    return (
        <React.Fragment>
            <HorizontalIdNavbar isFixed isEmptyNavbar />
            <div className='tq-details app-cc-v1'>
                <ParameterList
                    config={PARAMETER_FILTERS_CONFIG}
                    data={{
                        parameterList: savedfilterlist || parameterList,
                    }}
                    onSubmit={onFilterSubmit}
                />
                <div className='frcsbWrapper mt-30'>
                    <div className='heading1'>File Entries</div>
                    <div className='frcWrapper fgap-col-10'>
                        <Button primary label='Start prioritized task' onClick={handlePrioritizedTaskClick} />
                        <Button v2 secondary label='Refresh' onClick={handleRefreshClick} />
                    </div>
                </div>

                <Table
                    v2
                    enableScrollView
                    labelData={tableData.columnData}
                    contentData={tableData.rowData}
                    renderActionableColumn={renderActions}
                    prevToken={tableData.prevToken}
                    nextToken={tableData.nextToken}
                    paginatedClickHandler={getPaginatedTransactionQueue}
                    errLabel={transactionQueueError}
                />
            </div>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(TransactionQueue);

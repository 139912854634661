import {
    GET_ACTIVE_REWARDS, GET_ACTIVE_OFFERS, GET_USER_REWARDS, GET_USER_REDEEMED_OFFERS,
    GET_USER_REWARD_INFO, SET_SEARCH_PARAMS_FOR_CLAIMS_VIEW, RESET_SEARCH_PARAMS_FOR_CLAIMS_VIEW,
    GET_ACTIVE_EXCHANGER_OFFERS, GET_USER_REDEEMED_EXCHANGER_OFFERS, SET_ENTITY_META,
    SET_ACTIVE_VIEW_ENTITY_META, SET_CLAIMS_VIEW_ENTITY_META, SET_SEARCH_PARAMS_FOR_ACTIVE_VIEW,
    RESET_SEARCH_PARAMS_FOR_ACTIVE_VIEW, GET_USER_TOTAL_REWARDS, SET_USER_TOTAL_REWARDS,
    GET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS, GET_ELIGIBLE_REWARDS, SET_ELIGIBLE_REWARDS,
    GET_FI_STORE_REWARDS, SET_FI_STORE_REWARDS, ERR_FI_STORE_REWARDS,
    GET_FI_STORE_REWARD_DETAILS, SET_FI_STORE_REWARD_DETAILS, ERR_FI_STORE_REWARD_DETAILS,
} from './constants';

export const getActiveRewards = (data) => ({
    type: GET_ACTIVE_REWARDS,
    data,
});

export const getActiveOffers = (data) => ({
    type: GET_ACTIVE_OFFERS,
    data,
});

export const getUserRewards = (data) => ({
    type: GET_USER_REWARDS,
    data,
});

export const getUserRedeemedOffers = (data) => ({
    type: GET_USER_REDEEMED_OFFERS,
    data,
});

export const getTotalRewards = (data) => ({
    type: GET_USER_TOTAL_REWARDS,
    data,
});

export const setTotalRewards = (data) => ({
    type: SET_USER_TOTAL_REWARDS,
    data,
});

export const getUserRewardInfo = (data) => ({
    type: GET_USER_REWARD_INFO,
    data,
});

export const setSearchParamsForClaimsView = (data) => ({
    type: SET_SEARCH_PARAMS_FOR_CLAIMS_VIEW,
    data,
});

export const resetSearchParamsForClaimsView = (data) => ({
    type: RESET_SEARCH_PARAMS_FOR_CLAIMS_VIEW,
    data,
});

export const getActiveExchangerOffers = (data) => ({
    type: GET_ACTIVE_EXCHANGER_OFFERS,
    data,
});

export const getUserRedeemedExchangerOffers = (data) => ({
    type: GET_USER_REDEEMED_EXCHANGER_OFFERS,
    data,
});

export const setEntityMeta = (data) => ({
    type: SET_ENTITY_META,
    data,
});

export const setActiveViewEntityMeta = (data) => ({
    type: SET_ACTIVE_VIEW_ENTITY_META,
    data,
});

export const setClaimsViewEntityMeta = (data) => ({
    type: SET_CLAIMS_VIEW_ENTITY_META,
    data,
});

export const setSearchParamsForActiveView = (data) => ({
    type: SET_SEARCH_PARAMS_FOR_ACTIVE_VIEW,
    data,
});

export const resetSearchParamsForActiveView = (data) => ({
    type: RESET_SEARCH_PARAMS_FOR_ACTIVE_VIEW,
    data,
});

export const getEligibilityRewardsDropdownOptions = (data) => ({
    type: GET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS,
    data,
});

export const getEligibilityRewards = (data) => ({
    type: GET_ELIGIBLE_REWARDS,
    data,
});

export const setEligibilityRewards = (data) => ({
    type: SET_ELIGIBLE_REWARDS,
    data,
});

export const getFiStoreRewards = (data) => ({
    type: GET_FI_STORE_REWARDS,
    data,
});

export const setFiStoreRewards = (data) => ({
    type: SET_FI_STORE_REWARDS,
    data,
});
export const errFiStoreRewards = (data) => ({
    type: ERR_FI_STORE_REWARDS,
    data,
});

export const getFiStoreRewardAdditionalDetails = (data) => ({
    type: GET_FI_STORE_REWARD_DETAILS,
    data,
});

export const setFiStoreRewardAdditionalDetails = (data) => ({
    type: SET_FI_STORE_REWARD_DETAILS,
    data,
});
export const errFiStoreRewardAdditionalDetails = (data) => ({
    type: ERR_FI_STORE_REWARD_DETAILS,
    data,
});

/**
 * @file CallDetailsV1
 * Renders the call recording data in a table format entirely powered by backend
 * Changes to the table like adding / removing rows can be done directly by Backend without needing any client change
 * This is the V1 flow for call recordings that was built post some security related discussions regarding ozonetel
 */

import React, { memo, useEffect, useState } from 'react';

import {
    clientApiWrapper, getErrMsg, showErrorToastMsg, getQueryStringFromObject,
} from '../../../utils';
import { CALL_GET_RECORDINGS_ENDPOINT } from '../../../api/routes';
import { Loader, Table } from '../../../components';

const CallDetailsV1 = (props) => {
    const { parentProps } = props;

    const { ticketId } = parentProps;

    const [loading, setLoading] = useState(false);
    const [errorLabel, setErrorLabel] = useState('');
    const [tableData, setTableData] = useState({});

    const fetchCallRecordings = async (curTicketId) => {
        if (!curTicketId) {
            setErrorLabel('You need have a ticket ID to fetch call recordings');
            return;
        }

        try {
            const payload = {
                ticketId: curTicketId,
            };

            const queryString = getQueryStringFromObject(payload);

            setLoading(true);
            setErrorLabel('');

            const response = await clientApiWrapper.get(`${CALL_GET_RECORDINGS_ENDPOINT}?${queryString}`);

            if (response === 'auth-expired') {
                throw new Error('Auth expired');
            }

            setTableData(response.data.tableData);
        } catch (error) {
            showErrorToastMsg(error);
            const errorMsg = getErrMsg(error);
            setErrorLabel(errorMsg);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCallRecordings(ticketId);
    }, [ticketId]);

    return (
        <div className='calldtls--v1'>
            <Table
                objKey='header_key'
                nsrLabel='No call recordings found the ticket!'
                labelData={tableData?.table_headers}
                contentData={tableData?.table_rows}
                header={tableData?.table_name}
                extClasses={{
                    container: 'mt-15',
                }}
            />
            {errorLabel ? (
                <div className='error-label1 my-30'>{errorLabel}</div>
            ) : null}
            <Loader visible={loading} />
        </div>
    );
};

export default memo(CallDetailsV1);

/**
 * @file RecentActivity/constants.js - Contains saga used for recent activity
 */
import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, toastify } from '../../utils';
import {
    RISK_OPS_ACTIVITY_AREAS, RISK_OPS_ACTIVITY,
} from '../../api/routes';

import {
    GET_RECENT_ACTIVITY, SET_RECENT_ACTIVITY_DETAILS, SET_RECENT_ACTIVITY_AREA, GET_RECENT_ACTIVITY_AREA, SET_LOADER, SET_ERROR, loaderType,
} from './constants';
import { convertMillisecondsToTimestamp } from '../TransactionViewV2/utils';

function* getActorActivityArea() {
    try {
        yield put({ type: SET_LOADER, data: { loading: loaderType.RECENT_ACTIVITY_AREA } });
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            RISK_OPS_ACTIVITY_AREAS,
        );

        yield put({ type: SET_RECENT_ACTIVITY_AREA, data: { recentActivities: response.areas, loading: null } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_RECENT_ACTIVITY_AREA, data: { recentActivities: [], loading: null } });
        // yield put({ type: ERR_RECENT_ACTIVITY, data: { ticketId, err: e.message, authFail: false } });
    }
}

function* getRecentActivityDetails(action) {
    const {
        caseId, fromDate, toDate, selectedAreas, prevToken, nextToken,
    } = action.data;

    const requestPayload = {
        caseId,
        fromDate: convertMillisecondsToTimestamp(fromDate),
        toDate: convertMillisecondsToTimestamp(toDate),
        selectedAreas,
        prevToken,
        nextToken,
    };

    try {
        yield put({ type: SET_LOADER, data: { loading: loaderType.RECENT_ACTIVITY_DETAILS } });

        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            RISK_OPS_ACTIVITY,
            requestPayload,
        );
        yield put({ type: SET_RECENT_ACTIVITY_DETAILS, data: { loading: null, ...response } });
    } catch (e) {
        if (e.description && e.description && e.description.code === 6) {
            yield put({ type: SET_ERROR, data: { loading: null, activityDetails: [], errorScreenVisibility: true } });
        } else {
            const err = getErrLabel(e);
            toastify(err, 'error');
            yield put({ type: SET_RECENT_ACTIVITY_DETAILS, data: { loading: null, activityDetails: [], errorScreenVisibility: false } });
        }
    }
}

export default function* recentActivitySaga() {
    yield all(
        [
            yield takeLatest(GET_RECENT_ACTIVITY_AREA, getActorActivityArea),
            yield takeLatest(GET_RECENT_ACTIVITY, getRecentActivityDetails),
        ],
    );
}

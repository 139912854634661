/**
 * @file TicketListForCallView component to show the ticket list for selected call record & other CTAs to create new ticket at source
 */

import React, { memo, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, Loader, Table, Modal,
} from '../../../components';
import { MetaInfoContext } from '../../../context';
import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';
import { makeSelectAgentInfo } from '../../AppV2/selectors';

import { CALL_TICKET_LIST_LABEL_DATA } from '../constants';
import {
    getTicketListForCall, updateCallInfo,
} from '../actions';
import {
    makeSelectLoading, makeSelectTicketListForCall, makeSelectSelectedCallInfo,
    makeSelectUpdatedCallInfo, makeSelectCreatedTicketInfoForCall,
} from '../selectors';

import CreateNewTicketCTA from './CreateNewTicketCTA';

import './style.scss';

const TicketListForCallView = (props) => {
    const dispatch = useDispatch();

    // reducer state variables
    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const ticketListForCall = useSelector(makeSelectTicketListForCall(), shallowEqual);
    const selectedCallInfo = useSelector(makeSelectSelectedCallInfo(), shallowEqual);
    const updatedCallInfo = useSelector(makeSelectUpdatedCallInfo(), shallowEqual);
    // eslint-disable-next-line no-unused-vars
    const createdTicketInfoForCall = useSelector(makeSelectCreatedTicketInfoForCall(), shallowEqual);
    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);

    const metaInfo = useContext(MetaInfoContext);

    // context api variables
    const {
        tickets: {
            reasonInfo: {
                createNewCtaLanding,
            },
        },
    } = metaInfo;

    // local state variables
    const [showCallInfoModal, setCallInfoModal] = useState(false);
    const [localTicketId, setLocalTicketId] = useState(0);

    // props variables
    const { setTicketIdAndSubmit, setTicketsForCallPage } = props;

    // destructured variables
    const { ticketListForCallView: ticketListForCallViewLoading } = loading;
    const {
        data: ticketListForCallData, prev: ticketListForCallPrev, next: ticketListForCallNext, err: ticketListForCallErr,
    } = ticketListForCall;
    const {
        ucid: selectedCallUcid, callerUserId: selectedCallCallerUserId,
    } = selectedCallInfo;
    const {
        errMsgList: updatedCallErrMsgList, isErrFlow: updatedCallErrFlow,
    } = updatedCallInfo;

    // fetch ticket list for selected call
    useEffect(() => {
        dispatch(getTicketListForCall({ callerUserId: selectedCallCallerUserId }));
    }, [dispatch]);

    const resetTicketsForCallPage = () => {
        setTicketsForCallPage(false);
    };

    // no pagination when we fetch ticket list directly from source
    const getCallTicketListFromSource = () => {
        dispatch(getTicketListForCall({ callerUserId: selectedCallCallerUserId, fetchFromSource: true }));
    };

    const getPaginatedCallTicketList = ({ prev, next }) => {
        dispatch(getTicketListForCall({ callerUserId: selectedCallCallerUserId, prev, next }));
    };

    const attachToCurrentCall = (ticketId) => () => {
        setLocalTicketId(ticketId);
        pushRudderEvent(RudderEvent.SherlockV1Agent.AgentAttachTicketToCurrentCall, agentInfo.emailId, { ticketId, ucid: selectedCallUcid });
        const callUpdateCallInfoAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(updateCallInfo({
                    ucid: selectedCallUcid,
                    ticketId,
                    resolve,
                    reject,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callUpdateCallInfoAction()
            .then((response) => {
                if (response?.updatedCallInfo?.hasErrMsg) { // show err msg list in case of error
                    setCallInfoModal(true);
                } else { // if no err, then directly set & submit ticket id
                    setTicketIdAndSubmit(ticketId);
                }
            })
            .catch((err) => {
                // show err msg list in case of error
                if (err.response?.updatedCallInfo?.hasErrMsg) {
                    setCallInfoModal(true);
                }
            });
    };

    const renderActionsForTicketList = (data) => (
        <div className='frccWrapper'>
            <a className='link-v2' href={data.sourceUrl} target='_blank' rel='noreferrer'>
                View Ticket Summary
            </a>
            <div className='agent-list-action-sr'>|</div>
            <Button
                link
                label='Attach to Current Call'
                onClick={attachToCurrentCall(data.ticketId)}
            />
        </div>
    );

    const renderTicketList = () => (
        <Table
            header='Tickets'
            labelData={CALL_TICKET_LIST_LABEL_DATA}
            contentData={ticketListForCallData}
            renderActionableColumn={renderActionsForTicketList}
            prevToken={ticketListForCallPrev}
            nextToken={ticketListForCallNext}
            paginatedClickHandler={getPaginatedCallTicketList}
            extClasses={{
                container: 'defr-ticket-list-pg-table-sr',
                actionableColumn: 'f2Wrapper',
            }}
            errLabel={ticketListForCallErr}
            nsrLabel='No existing tickets found for the user!'
        />
    );

    // render ticket list for call page
    return (
        <React.Fragment>
            <div className='defr-cr'>
                <div className='frcsbWrapper'>
                    <Button
                        link
                        label='< GO BACK'
                        onClick={resetTicketsForCallPage}
                    />
                    <div className='frcWrapper'>
                        <CreateNewTicketCTA
                            reasonList={createNewCtaLanding}
                        />
                        <Button
                            v2
                            primary
                            label='force refresh data'
                            onClick={getCallTicketListFromSource}
                            extClasses='defr-ticket-list-pg-cta'
                        />
                    </div>
                </div>
                {renderTicketList()}
            </div>
            <Modal
                visible={showCallInfoModal}
                contentLabel='Update Call Info Err List Modal'
            >
                {updatedCallErrMsgList?.map((item) => (
                    <div className='defr-modal-lb'>
                        {`- ${item}`}
                    </div>
                ))}
                <Button
                    v2
                    primary
                    label='Close'
                    onClick={() => {
                        setCallInfoModal(false);

                        if (!updatedCallErrFlow) {
                            setTicketIdAndSubmit(localTicketId);
                        }
                    }}
                    extClasses='defr-modal-cta'
                />
            </Modal>
            <Loader visible={ticketListForCallViewLoading} />
        </React.Fragment>
    );
};

export default memo(TicketListForCallView);

/**
 *
 * SignupView
 *
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { ExpandableCard, Loader } from '../../../components';

import { getUserReferralInfo, getReferralMetaInfo } from '../actions';
import { makeSelectUserReferralInfo, makeSelectReferralMetaInfo } from '../selectors';
import { getFormattedReferralInfo, getFormattedRewardInfo } from '../utils';

import '../style.scss';

const SignupView = (props) => {
    const dispatch = useDispatch();
    const userReferralInfo = useSelector(makeSelectUserReferralInfo(), shallowEqual);
    const referralMetaInfo = useSelector(makeSelectReferralMetaInfo(), shallowEqual);
    const [isCardExpanded, setIsCardExpanded] = useState(false);

    const { ticketId, userId, index } = props;

    let loading;
    let err;
    let hasReferralInfo;
    let referralInfo;
    let hasRewardInfo;
    let rewardInfo;

    if (userReferralInfo[ticketId]) {
        ({ loading, err, hasReferralInfo, referralInfo, hasRewardInfo, rewardInfo } = userReferralInfo[ticketId]);
    }

    useEffect(() => {
        dispatch(getUserReferralInfo({ ticketId, userId, index }));
        dispatch(getReferralMetaInfo());
    }, [dispatch]);

    return (
        <ExpandableCard
            heading='My Signup'
            extClasses='referrals-ec'
            isExpanded={isCardExpanded}
            setIsExpanded={() => setIsCardExpanded(!isCardExpanded)}
        >
            <div className='referrals-ccr'>
                {
                    err ? (
                        <div className='referrals-ccr-hd1'>{err}</div>
                    ) : null
                }
                {
                    (!err && !hasReferralInfo && !hasRewardInfo) ? (
                        <div className='referrals-ccr-hd1'>User has not signed up via referral</div>
                    ) : null
                }
                {
                    (hasReferralInfo || hasRewardInfo) ? (
                        <React.Fragment>
                            <div className='referrals-ccr-hd2'>My Account opening</div>
                            {
                                getFormattedReferralInfo(referralInfo).map(item => (
                                    <div key={item.label} className='referrals-cwr'>
                                        <div className='referrals-cwr__label'>{item.label}</div>
                                        <div className='referrals-cwr__value'>
                                            {item.value || '-'}
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                getFormattedRewardInfo(rewardInfo).map(item => (
                                    <div key={item.label} className='referrals-cwr'>
                                        <div className='referrals-cwr__label'>{item.label}</div>
                                        <div className='referrals-cwr__value'>
                                            {item.value || '-'}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='referrals-ccr-hd3'>
                                <i>{referralMetaInfo.refereeEligibilityLabel}</i>
                            </div>
                        </React.Fragment>
                    ) : null
                }
            </div>
            <Loader visible={loading} />
        </ExpandableCard>
    );
};

export default SignupView;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

/**
*
* Pagination
*
*/

import React, { memo } from 'react';
import classNames from 'classnames';

import Button from '../Button';

import './style.scss';

const Pagination = (props) => {
    // v2 prop to render pagination ctas as per new design
    const {
        prev, next, onClickHandler, v2, enableScrollView = false,
    } = props;
    const hasPrevPage = !!Object.keys(prev).length;
    const hasNextPage = !!Object.keys(next).length;

    let prevActionClass = classNames('pagination-cta', !hasPrevPage && 'pagination-cta--disabled');
    let nextActionClass = classNames('pagination-cta', !hasNextPage && 'pagination-cta--disabled');
    const tableContainerClass = classNames('pagination-container', enableScrollView && 'pagination-sv');

    const onPrevClick = () => {
        if (hasPrevPage) {
            onClickHandler({ prev });
        }
    };

    const onNextClick = () => {
        if (hasNextPage) {
            onClickHandler({ next });
        }
    };

    if (!hasPrevPage && !hasNextPage) {
        return null;
    }

    if (v2) {
        prevActionClass = classNames('pagination-cta pagination-cta--v2');
        nextActionClass = classNames('pagination-cta pagination-cta--v2');

        return (
            <div className={tableContainerClass}>
                <Button
                    link
                    extClasses={prevActionClass}
                    label='< Prev'
                    onClick={onPrevClick}
                    disabled={!hasPrevPage}
                />
                <Button
                    link
                    extClasses={nextActionClass}
                    label='Next >'
                    onClick={onNextClick}
                    disabled={!hasNextPage}
                />
            </div>
        );
    }

    return (
        <div className='pagination-container'>
            <div className={prevActionClass} onClick={onPrevClick}>{'< Prev'}</div>
            <div className={nextActionClass} onClick={onNextClick}>{'Next >'}</div>
        </div>
    );
};

export default memo(Pagination);

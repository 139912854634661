/**
 *
 * NavItems - Component to show each NavItem in the NavBar.
 *
 */
import classNames from 'classnames';
import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';

const NavItem = (props) => {
    const location = useLocation();
    const { item, isExpanded } = props;
    const images = require.context('../../../assets/images', true);
    const curScreen = location.pathname;

    const ItemClasses = classNames(
        'app__v2-nav-item',
        { 'app__v2-nav-item__hide': !isExpanded },
    );

    const ActiveItemClasses = classNames(
        'app__v2-nav-item__active',
        { 'app__v2-nav-item__active__hide': !isExpanded },
    );

    const ImageClasses = classNames(
        'app__v2-nav-img',
        { 'app__v2-nav-img__hide': !isExpanded },
        { 'app__v2-nav-img__active': curScreen.includes(item.id) && !isExpanded },
    );

    return (
        <NavLink
            to={(currLocation) => {
                if (item.id === 'search') {
                    return { ...currLocation, pathname: item.path };
                }
                return { pathname: item.path };
            }}
            className={ItemClasses}
            activeClassName={ActiveItemClasses}
        >
            <img
                src={images(item.img)}
                alt={item.id}
                className={ImageClasses}
            />
            <div className={`app__v2-nav-text ${!isExpanded ? 'app__v2-nav-text__hide' : ''}`}>{item.label}</div>
        </NavLink>
    );
};

export default NavItem;

/**
 * @file Tabs component
 * Render data based on tabs
 * Uses react-tabs library
 * Example - https://reactcommunity.org/react-tabs/
 */

import React, { memo, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import classNames from 'classnames';

/**
 * Keep this commented, keeping it here only for future reference.
 * We have overridden react tabs styles in ./style.scss
 * If we use react-tabs.scss, a white horizontal line appears across the screen while switching tabs
 * import 'react-tabs/style/react-tabs.scss';
 */

import './style.scss';

/**
 * props:
 * @param {object} data The data object to be passed
 * @param {object} extClasses Extending class
 * @param {number} defaultIndex current default tab index
 * @param {Function} onSelectTab on selected current tab
 * Details of data object:
 * @param {string} data.label The name of the tab
 * @param {string} data.id Unique ID for the tab
 * @param {ReactElement} data.component The react element to be rendered for the selected tab
 * @param {object} data.extClasses Extending class
 */
const AppTabs = (props) => {
    const {
        data, extClasses = {}, defaultIndex = 0, onSelectTab,
    } = props;
    const containerClasses = classNames('react-tabs__tab-list', extClasses && extClasses.tabListContainer);

    const [selectedTabIndex, setSelectedTabIndex] = useState(defaultIndex);

    const onSelect = (index, ...args) => {
        onSelectTab(index, ...args);
        setSelectedTabIndex(index);
    };

    if (!data || data.length < 1) return null;

    return (
        <Tabs
            defaultIndex={defaultIndex}
            onSelect={onSelect}
        >
            <TabList className={containerClasses}>
                {data.map((tabData, index) => (
                    <Tab
                        style={{
                            color: tabData?.tabTextColor,
                            borderBottomColor: selectedTabIndex === index ? tabData?.tabTextColor : null,
                        }}
                        key={tabData.id}
                    >
                        {tabData.label}
                    </Tab>
                ))}
            </TabList>

            {data.map((tabData) => (
                <TabPanel key={tabData.id}>{tabData.component}</TabPanel>
            ))}
        </Tabs>
    );
};

export default memo(AppTabs);

/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

/**
 *
 * HorizontalIdNavbar
 * TODO: Remove the ESlint disable rules and wrap the div and images with button tag
 *
 */

import React, { memo, useContext } from 'react';
import classNames from 'classnames';
import { isDevEnviroment } from '../../utils';
import { MetaInfoContext } from '../../context';

import { ICONS_URL } from '../../constants/assets';
// import closeIcon from '../../assets/images/svgs/close.svg';

import BackArrow from '../BackArrow';

import './style.scss';

const HorizontalIdNavbar = (props) => {
    const {
        navItems = [], isFixed, activeId, onNewTicketClick, isNewTicketActive, onItemClick, onItemCloseIconClick,
        hasSingleItem, isEmptyNavbar, onBackHandleClick, hideNav, newTabText, activeIndex, extClasses,
    } = props;

    const metaInfo = useContext(MetaInfoContext);

    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    const containerClasses = classNames(isDevEnviroment() ? {
        'hinb-cr bg-np': bannersVersion === 1,
        'hinb-cr-v2 bg-np': bannersVersion === 2,
        'hinb-cr--fixed bg-np': isFixed && bannersVersion === 1,
        'hinb-cr-v2--fixed bg-np': isFixed && bannersVersion === 2,
        'hinb-cr-disable': hideNav,
    } : {
        'hinb-cr bg': bannersVersion === 1,
        'hinb-cr-v2 bg': bannersVersion === 2,
        'hinb-cr--fixed bg': isFixed && bannersVersion === 1,
        'hinb-cr-v2--fixed bg': isFixed && bannersVersion === 2,
        'hinb-cr-disable': hideNav,
    }, extClasses && extClasses.container);

    // render empty container of fixed height incase ids list is not required
    if (isEmptyNavbar) {
        return (
            <div className={containerClasses}>
                <div className='hinb-empty-cr' />
            </div>
        );
    }

    const hasNavItems = !!navItems.length;
    /**
     * show new ticket tab only if navbar have multiple items or
     * has single item & no item in navbar list
     */
    const showNewTicketTab = !hasSingleItem || (hasSingleItem && !hasNavItems);

    return (
        <React.Fragment>
            <div className={containerClasses}>
                {onBackHandleClick && (
                    <div className='hinb-ba'>
                        <BackArrow variant='white' handleClick={onBackHandleClick} />
                    </div>
                )}
                {
                    navItems.map((item, index) => (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={item.id ? (item.id + index) : (item.value + index)}
                            className={classNames({
                                'hinb-wr__itm': true,
                                'hinb-wr__itm--active': (!isNewTicketActive && activeId === item.id) || index === activeIndex,
                            })}
                            onClick={() => {
                                if (onItemClick) {
                                    onItemClick(item.id ? item.id : index);
                                }
                            }}
                        >
                            <span>{`ID - ${item.id ? item.id : item.value}`}</span>
                            <img
                                className='hinb-close-icon'
                                src={ICONS_URL.CLOSE}
                                alt='close-icon'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onItemCloseIconClick(item.id ? item.id : index);
                                }}
                            />
                        </div>
                    ))
                }
                {
                    showNewTicketTab ? (
                        <div
                            className={classNames({
                                'hinb-wr__itm': true,
                                'hinb-wr__itm--new': true,
                                'hinb-wr__itm--active': isNewTicketActive,
                            })}
                            onClick={onNewTicketClick}
                        >
                            {newTabText || 'New Ticket'}
                        </div>
                    ) : null
                }
            </div>
            {/* {
                isFixed ? (
                    <div className='hinb-empty-cr' />
                ) : null
            } */}
        </React.Fragment>
    );
};

export default memo(HorizontalIdNavbar);

export const GET_CARDS_INFO = 'app/PI/GET_CARDS_INFO';
export const SET_CARDS_INFO = 'app/PI/SET_CARDS_INFO';
export const ERR_CARDS_INFO = 'app/PI/ERR_CARDS_INFO';
export const INITIATE_SUSPEND_CARD = 'app/PI/INITIATE_SUSPEND_CARD';
export const SET_SUSPEND_CARD = 'app/PI/SET_SUSPEND_CARD';
export const ERR_SUSPEND_CARD = 'app/PI/ERR_SUSPEND_CARD';
export const SET_SUSPEND_CARD_CONFIRM_SCREEN = 'app/PI/SET_SUSPEND_CARD_CONFIRM_SCREEN';
export const INITIATE_BLOCK_CARD = 'app/PI/INITIATE_BLOCK_CARD';
export const SET_BLOCK_CARD = 'app/PI/SET_BLOCK_CARD';
export const ERR_BLOCK_CARD = 'app/PI/ERR_BLOCK_CARD';
export const SET_BLOCK_CARD_CONFIRM_SCREEN = 'app/PI/SET_BLOCK_CARD_CONFIRM_SCREEN';
export const GET_CARD_INFO = 'app/PI/GET_CARD_INFO';
export const SET_CARD_INFO = 'app/PI/SET_CARD_INFO';
export const ERR_CARD_INFO = 'app/PI/ERR_CARD_INFO';
export const TOGGLE_SCREEN = 'app/PI/TOGGLE_SCREEN';
export const GET_CARD_TRACKING_INFO = 'app/PI/GET_CARD_TRACKING_INFO';
export const SET_CARD_TRACKING_INFO = 'app/PI/SET_CARD_TRACKING_INFO';
export const ERR_CARD_TRACKING_INFO = 'app/PI/ERR_CARD_TRACKING_INFO';
export const GET_CARD_REQUEST_STATUS = 'app/PI/GET_CARD_REQUEST_STATUS';
export const SET_CARD_REQUEST_STATUS = 'app/PI/SET_CARD_REQUEST_STATUS';
export const ERR_CARD_REQUEST_STATUS = 'app/PI/ERR_CARD_REQUEST_STATUS';
export const SET_CARD_AMC = 'app/PI/SET_CARD_AMC';
export const GET_CARD_AMC = 'app/PI/GET_CARD_AMC';
export const ERR_CARD_AMC = 'app/PI/ERR_CARD_AMC';
export const GET_CARD_LIST = 'app/PI/GET_CARD_LIST';
export const SET_CARD_LIST = 'app/PI/SET_CARD_LIST';
export const ERR_CARD_LIST = 'app/PI/ERR_CARD_LIST';
// card replacement
export const UPDATE_FREE_CARD_REPLACEMENT = 'app/PI/UPDATE_FREE_CARD_REPLACEMENT';
export const SET_FREE_CARD_REPLACEMENT_LOADING_STATE = 'app/PI/SET_FREE_CARD_REPLACEMENT_LOADING_STATE';

export const GET_UPI_INFO = 'app/PI/GET_UPI_INFO';
export const SET_UPI_INFO = 'app/PI/SET_UPI_INFO';
export const ERR_UPI_INFO = 'app/PI/ERR_UPI_INFO';
export const TOGGLE_UPI_STATUS = 'app/PI/TOGGLE_UPI_STATUS';
export const SET_UPI_STATUS = 'app/PI/SET_UPI_STATUS';
export const ERR_UPI_STATUS = 'app/PI/ERR_UPI_STATUS';
export const SET_UPI_TOGGLE_CONFIRM_SCREEN = 'app/PI/SET_UPI_TOGGLE_CONFIRM_SCREEN';

export const GET_FOREX_TXN_INFO = 'app/PI/GET_FOREX_TXN_INFO';
export const SET_FOREX_TXN_INFO = 'app/PI/SET_FOREX_TXN_INFO';
export const ERR_FOREX_TXN_INFO = 'app/PI/ERR_FOREX_TXN_INFO';

export const CARD_CHANNEL_LIST = [
    {
        key: 'atm',
        limitLabel: 'ATM',
        settingLabel: 'ATM Withdrawals',
    },
    {
        key: 'ecommerce',
        limitLabel: 'Ecommerce',
        settingLabel: 'Online Transactions',
    },
    {
        key: 'pos',
        limitLabel: 'POS',
        settingLabel: 'Card Swiping (POS)',
    },
    {
        key: 'nfc',
        limitLabel: 'Contactless',
        settingLabel: 'Contactless Payment',
    },
];

/**
 * @file Manages the selectors for AppV2 component
 */

import { createSelector } from 'reselect';

const getApp = (state) => state.get('app_v2');

export const selectL1CategoriesData = createSelector(
    (state) => getApp(state)?.getIn(['L1Categories']),
    (substate) => substate?.toJS(),
);

export const selectCreatedTicket = createSelector(
    (state) => getApp(state)?.getIn(['createTicketForCall']),
    (substate) => substate?.toJS(),
);

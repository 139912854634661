import React from 'react';

// need to have access level info as we are reading some properties from it
const UserDetailsContext = React.createContext({
    basicInfo: {
        accessLevelInfo: {},
    },
});

export default UserDetailsContext;

import { AGENT_TYPES, FRESHDESK_AGENT_TYPES, TICKET_SCOPES } from './constants';
import { FIELD_TYPES } from '../../components/FormBuilder/constants';

export const agentFormStructure = (formValues = {}, isEdit, accessLevelList) => ([
    {
        name: "email_id",
        type: FIELD_TYPES.INPUT,
        props: {
            label: "Agent Email",
            placeHolder: "Enter Email",
            disabled: isEdit
        }
    },
    {
        name: "phone_number",
        type: FIELD_TYPES.PHONE_NUMBER,
        props: {
            label: "Phone Number",
        }
    },
    {
        name: "name",
        type: FIELD_TYPES.NAME,
        props: {
            label: "Name",
        }
    },
    {
        name: "agent_type",
        type: FIELD_TYPES.DROPDOWN,
        props: {
            label: "Agent Type",
            options: AGENT_TYPES,
        }
    },
    {
        name: "access_level",
        type: FIELD_TYPES.DROPDOWN,
        props: {
            label: "Access Level",
            options: accessLevelList,
        }
    },
    {
        name: "ozonetel_id",
        type: FIELD_TYPES.INPUT,
        props: {
            label: "Ozonetel ID",
            placeHolder: "Enter Ozonetel ID",
        }
    },
    {
        name: "is_freshdesk_creation_required",
        type: FIELD_TYPES.TOGGLE,
        props: {
            label: "Create Freshdesk User"
        },
        hidden: isEdit
    },
    {
        name: "ticket_scope",
        type: FIELD_TYPES.DROPDOWN,
        props: {
            label: "Ticket scope in Freshdesk",
            options: TICKET_SCOPES,
        },
        hidden: isEdit || !formValues.is_freshdesk_creation_required
    },
    {
        name: "freshdesk_agent_type",
        type: FIELD_TYPES.DROPDOWN,
        props: {
            label: "Agent Type in Freshdesk",
            options: FRESHDESK_AGENT_TYPES,
        },
        hidden: isEdit || !formValues.is_freshdesk_creation_required
    },
    // {
    //     name: "agent_status",
    //     type: FIELD_TYPES.DROPDOWN,
    //     props: {
    //         label: "Agent Status",
    //         options: AGENT_STATUS,
    //     }
    // },
]);

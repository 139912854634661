/**
 *
 * Details - Component to handle the fetching of of a particular SOP details and render according them accordingly
 *
 */

import React, { memo, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import { SherlockV2SOPLanded, SherlockV2SOPStepViewed } from '../../../rudderEvents/SherlockV2SOP';
import { useTimer } from '../../../utils/helpers';
import { addMessageEventListener, postMessageEvent } from '../../../messageEvents';
import { LoaderOrError, Modal } from '../../../components';
import { pushRudderEvent } from '../../../rudderEvents';
import { UserDetailsContext } from '../../../context';

import { HOME_PATH } from '../../AppV2/routes';

import { selectSopDetails, selectTicketId } from '../selector';
import { getSOPDetailsAction, getSOPNodeAction, setTicketIdAction } from '../actions';
import { REDIRECTING_TEXT, TIME_IN_MILLISECONDS, WAITING_TEXT } from '../constants';
import TreeTraversal from '../TreeTraversal';

const Details = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { sopId } = useParams();
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);

    const [currentStep, setCurrentStep] = useState(-1);
    const [modalText, setModalText] = useState(WAITING_TEXT);

    const [timer, setTimer] = useTimer((7 * TIME_IN_MILLISECONDS) / 1000);

    const ticketId = useSelector(selectTicketId, shallowEqual);
    const sopDetails = useSelector(selectSopDetails, shallowEqual);

    const {
        loading, err, data: details,
    } = sopDetails || {};
    const { title, shortDescription, sourceNode } = details || {};

    // Function to handle the fetching of next node data
    const getNextNode = (nextNodeId, isSource, extraParams = {}) => {
        const callAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(getSOPNodeAction({
                    screenKey: ['tree', 'nodes'],
                    sopId,
                    nodeId: nextNodeId,
                    resolve,
                    clearData: isSource,
                    ticketId,
                    ...extraParams,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callAction().then(() => {
            // This details does not contain information about the type of the step being shown.
            // This needs to be moved to appropriate step module, if more details specific to step is needed.
            pushRudderEvent(SherlockV2SOPStepViewed, agentInfo.emailId, {
                sopId,
                ticketId,
            });
            setCurrentStep((stepIndex) => {
                if (!isSource) return stepIndex + 1;
                return 0;
            });
        });
    };

    const callAction = () => new Promise((resolve, reject) => {
        try {
            dispatch(getSOPDetailsAction({
                screenKey: ['tree'], sopId, clearData: true, resolve,
            }));
        } catch (e) {
            reject(e);
        }
    });

    // Calls the source node after the SOP details comes in response
    useEffect(() => {
        if (ticketId) {
            callAction().then((args) => {
                getNextNode(args?.sourceNode, true);
                document.title = `SOP: ${args?.title} - Sherlock`;

                pushRudderEvent(SherlockV2SOPLanded, agentInfo.emailId, {
                    ticketId,
                    sopId,
                    sopTitle: args?.title,
                });
            });
        }
        return () => {
            document.title = 'SOP - Sherlock';
        };
    }, [sopId, ticketId]);

    useEffect(() => {
        if (!ticketId) {
            if (sessionStorage.getItem('ticketId')) { // Gets the stored access level if present and deletes it from local storage
                dispatch(setTicketIdAction({ path: ['ticketId'], value: sessionStorage.getItem('ticketId') }));
                sessionStorage.removeItem('ticketId');
            } else {
                setTimer(true);
            }
            return () => {};
        }
        const beforeUnloadEvent = () => { // Stores the current ticketId before unloading/refresh in the local Storage
            sessionStorage.setItem('ticketId', ticketId);
        };
        // Add the event listener to listen to the event of unloading. It triggers the function before unloading.
        const removeEventListener = addMessageEventListener(beforeUnloadEvent, true, 'beforeunload');
        return removeEventListener;
    }, [ticketId]);

    useEffect(() => {
        const eventListener = (event) => {
            const data = event?.data;

            // Proceed with rendering after receiving the message
            if (data?.ticketId) {
                dispatch(setTicketIdAction({ path: ['ticketId'], value: data?.ticketId }));
            }
        };

        const removeEventListener = addMessageEventListener(eventListener, true);
        return removeEventListener;
    }, []);

    useEffect(() => {
        if (timer === 3 && !ticketId) {
            setModalText(REDIRECTING_TEXT);
            setTimeout(() => {
                postMessageEvent(window?.opener, { closeSherlockV2: true }, window?.opener?.location?.origin);
                // Keeping a timeout here so that window close happens first and
                // if it doesn't happen, website redirects to default ticket path.
                setTimeout(() => {
                    history.replace(HOME_PATH);
                }, 1);
            }, (4 * TIME_IN_MILLISECONDS));
        }
    }, [timer]);

    if (!ticketId) {
        return (
            <Modal visible>
                <div className='frcseWrapper'>
                    <div className='loader' />
                    <div>{modalText}... {timer} sec{timer > 1 ? 's' : ''}.</div>
                </div>
            </Modal>
        );
    }

    // Returns a loader if no sop details are present
    if (!details) return (<LoaderOrError loading={loading} errorLabel={err} />);

    return (
        <div className='sop-cont p-30'>
            <div>
                <div className='sop-heading'>User Issue</div>
                <div className='sop-cc sop-box'>
                    <div className='frWrapper sop-box-container'>
                        <div className='p-20'>
                            <div className='sop-box-title sop-box-cell-title'>Ticket ID</div>
                        </div>
                        <div className='frwpWrapper sop-box-values'>
                            <div className='p-20'>
                                <div className='sop-box-cell-value sop-box-cell__danger'>{ticketId}</div>
                            </div>
                        </div>
                    </div>
                    <div className='frWrapper'>
                        <div className='p-20'>
                            <div className='sop-box-title sop-box-cell-title'>Issue</div>
                        </div>
                        <div className='frwpWrapper sop-box-values'>
                            <div className='p-20'>
                                <div className='sop-box-cell-value'>{shortDescription}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TreeTraversal
                title={title}
                getNextNode={getNextNode}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                sourceNode={sourceNode}
                ticketId={ticketId}
            />
            <LoaderOrError loading={loading} errorLabel={err} />
        </div>
    );
};

export default memo(Details);

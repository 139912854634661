/**
 * @file Utils for account ops submit CSV details component
 */

import {
    BALANCE_TRANSFER_UTRS_PATH, BULK_ACCOUNT_VERIFICATION_PATH, UPDATE_BALANCES_PATH,
} from '../App/routes';

import {
    uploadBalanceOfCloseAccountsAction, uploadSuccessfulBalanceTransfersAction, verifyBulkAccountsInfoAction,
} from './actions';
import { CONTAINER_KEY_MAP } from './constants';

const {
    BULK_ACCOUNT_VERIFICATION_KEY,
    UPLOAD_BALANCES_KEY,
    BALANCE_TRANSFER_KEY,
} = CONTAINER_KEY_MAP;

const FILE_NAMES_MAP = {
    [BULK_ACCOUNT_VERIFICATION_KEY]: 'bulk-account-verification-sample',
    [UPLOAD_BALANCES_KEY]: 'upload-balances-sample',
    [BALANCE_TRANSFER_KEY]: 'balance-transfers-utrs-sample',
};

export const URL_TO_CONTAINER_MAP = {
    [BULK_ACCOUNT_VERIFICATION_PATH]: BULK_ACCOUNT_VERIFICATION_KEY,
    [UPDATE_BALANCES_PATH]: UPLOAD_BALANCES_KEY,
    [BALANCE_TRANSFER_UTRS_PATH]: BALANCE_TRANSFER_KEY,
};

export const CONTAINER_INFO_MAP = {
    [BULK_ACCOUNT_VERIFICATION_KEY]: {
        data: {
            inputLabel: 'Upload Account Details',
            sampleCsvLink: `/assets/${FILE_NAMES_MAP[BULK_ACCOUNT_VERIFICATION_KEY]}.csv`,
            downloadedFileName: FILE_NAMES_MAP[BULK_ACCOUNT_VERIFICATION_KEY],
            onSubmitAction: verifyBulkAccountsInfoAction,
            containerKey: BULK_ACCOUNT_VERIFICATION_KEY,
        },
    },
    [UPLOAD_BALANCES_KEY]: {
        data: {
            inputLabel: 'Upload Balances',
            sampleCsvLink: `/assets/${FILE_NAMES_MAP[UPLOAD_BALANCES_KEY]}.csv`,
            downloadedFileName: FILE_NAMES_MAP[UPLOAD_BALANCES_KEY],
            onSubmitAction: uploadBalanceOfCloseAccountsAction,
            containerKey: UPLOAD_BALANCES_KEY,
        },
    },
    [BALANCE_TRANSFER_KEY]: {
        data: {
            inputLabel: 'Upload UTR',
            sampleCsvLink: `/assets/${FILE_NAMES_MAP[BALANCE_TRANSFER_KEY]}.csv`,
            downloadedFileName: FILE_NAMES_MAP[BALANCE_TRANSFER_KEY],
            onSubmitAction: uploadSuccessfulBalanceTransfersAction,
            containerKey: BALANCE_TRANSFER_KEY,
        },
    },
};

/**
 * @file Constants for FreshdeskTicketInfo
 */

// reducer, selector key
export const FRESHDESK_TICKET_INFO_KEY = 'freshdeskTicketInfo';

// actions
const FRESHDESK_TICKET_INFO_ACTION = `app/${FRESHDESK_TICKET_INFO_KEY}/action`;

export const ERR_FRESHDESK_TICKET_INFO = `${FRESHDESK_TICKET_INFO_ACTION}/ERR_FRESHDESK_TICKET_INFO`;

export const GET_FRESHDESK_CATEGORIES = `${FRESHDESK_TICKET_INFO_ACTION}/GET_FRESHDESK_CATEGORIES`;
export const SET_FRESHDESK_CATEGORIES = `${FRESHDESK_TICKET_INFO_ACTION}/SET_FRESHDESK_CATEGORIES`;

export const GET_FRESHDESK_TICKET_INFO = `${FRESHDESK_TICKET_INFO_ACTION}/GET_TICKET_INFO`;
export const SET_FRESHDESK_TICKET_INFO = `${FRESHDESK_TICKET_INFO_ACTION}/SET_TICKET_INFO`;

export const UPDATE_FRESHDESK_TICKET_INFO = `${FRESHDESK_TICKET_INFO_ACTION}/UPDATE_FRESHDESK_TICKET_INFO`;
export const SUCCESSFUL_UPDATE_FRESHDESK_TICKET_INFO = `${FRESHDESK_TICKET_INFO_ACTION}/SUCCESSFUL_UPDATE_FRESHDESK_TICKET_INFO`;

// reducer constants
export const REDUCER = {
    CATEGORY_INFO: 'categoryInfo',
    TICKET_INFO: 'ticketInfo',
    LOADING: 'loading',
    ERR: 'err',
};

export const CATEGORY_KEY = 'categories';
export const CATEGORY_DETAILS_KEY = 'category-details';
export const SUB_CATEGORY_KEY = 'sub-category';

export const FORM_DATA = [
    {
        key: CATEGORY_KEY,
        label: 'Select Category*',
        options: [],
    },
    {
        key: CATEGORY_DETAILS_KEY,
        label: 'Category Details',
        options: [],
    },
    {
        key: SUB_CATEGORY_KEY,
        label: 'Select Sub Category',
        options: [],
    },
];

export const MODAL_STYLES = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 3,
    },
    content: {
        top: '5%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '20px',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 700,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        minHeight: '600px',
    },
};

export const RenderTicketInfoClickItems = {
    GURU_LINK: 'guru_link',
};

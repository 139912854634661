/**
 * @file Component to show the banner list & manage the banners like create, update, etc
 * Landing Page for Banners Component
 */

import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';

import { CREATE_BANNER_PATH, UPDATE_BANNER_PATH, BANNERS_PATH } from '../App/routes';

import CreateOrUpdateBanner from './CreateOrUpdateBanner';
import BannerList from './BannerList';

import { BANNER_KEY } from './constants';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const Banners = () => {
    return (
        <div className='banners-cr'>
            <Switch>
                <Route
                    path={CREATE_BANNER_PATH}
                    component={CreateOrUpdateBanner}
                />
                <Route
                    path={UPDATE_BANNER_PATH}
                    render={() => (<CreateOrUpdateBanner isEdit />)}
                />
                <Route
                    path={BANNERS_PATH}
                    component={BannerList}
                />
            </Switch>
        </div>
    );
};

const withReducer = injectReducer({ key: BANNER_KEY, reducer });
const withSaga = injectSaga({ key: BANNER_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(memo(Banners));

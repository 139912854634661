/**
 * @file UsStocks: Contains different flows that federal agents will use required for US Stocks
 * Most of the flows involve different type of files to be fetched, uploaded and downloaded
 */

import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import { Loader, VerticalNavbar } from '../../components';
import { injectReducer, injectSaga } from '../../utils';
import { FEDERAL_US_STOCKS_PATH } from '../App/routes';

import reducer from './reducer';
import saga from './saga';
import { US_STOCKS_KEY, US_STOCK_SCREENS } from './constants';
import {
    getAdditionalActionsAction, getFileEntriesAction, getNavBarEntitiesAction, getUsStocksTableFiltersAction, setUsStocksFileTypeAction,
    setUsStocksViewAction,
} from './actions';
import {
    selectUSStocksCurView, selectUSStocksError, selectUSStocksFileType, selectUSStocksLoader, selectUSStocksNavBarInfo,
} from './selectors';

import FileEntries from './FileEntries';
import ScreenAction from './ScreenAction';

import './style.scss';

const RenderView = () => {
    const { fileId } = useParams();
    const dispatch = useDispatch();

    const curView = useSelector(selectUSStocksCurView);
    const fileType = useSelector(selectUSStocksFileType);

    // set file type when file ID changes
    useEffect(() => {
        dispatch(setUsStocksFileTypeAction({ fileId }));
    }, [fileId, dispatch]);

    // fetch data from 3 rpcs when fileType changes
    useEffect(() => {
        if (!fileType.value) return;

        dispatch(getFileEntriesAction({ fileType: fileType.value }));

        dispatch(getAdditionalActionsAction({ fileType: fileType.value }));

        dispatch(getUsStocksTableFiltersAction({ fileType: fileType.value }));

        dispatch(setUsStocksViewAction({ curView: US_STOCK_SCREENS.FILE_ENTRIES }));
    }, [fileType.value, dispatch]);

    switch (curView) {
        case US_STOCK_SCREENS.FILE_ENTRIES: {
            return <FileEntries />;
        }

        case US_STOCK_SCREENS.SCREEN_ACTION: {
            return <ScreenAction />;
        }

        default: {
            return null;
        }
    }
};

const UsStocks = () => {
    const dispatch = useDispatch();

    const loading = useSelector(selectUSStocksLoader);
    const err = useSelector(selectUSStocksError);
    const { entities, defaultEntity, hasNavBarInfo } = useSelector(selectUSStocksNavBarInfo);

    useEffect(() => {
        dispatch(getNavBarEntitiesAction());
    }, [dispatch]);

    if (!hasNavBarInfo) return null;

    return (
        <div className='app-cr'>
            <VerticalNavbar
                hideHorizontalNavbar
                navItems={entities}
            />

            <div className='app-cc'>
                <div className='app-ci'>
                    <Switch>
                        <Route
                            path={`${FEDERAL_US_STOCKS_PATH}/:fileId`}
                        >
                            <RenderView />
                        </Route>
                        {/* in case of unmatched url, redirect the user to the default entity path */}
                        <Redirect to={{ pathname: defaultEntity.path }} />
                    </Switch>
                    {err && <div className='err-label2'>{err}</div>}
                </div>
            </div>

            <Loader visible={loading} />
        </div>
    );
};

const withReducer = injectReducer({ key: US_STOCKS_KEY, reducer });
const withSaga = injectSaga({ key: US_STOCKS_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(memo(UsStocks));

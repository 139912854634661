/**
 * @file EscalateToFederal
 * The Modal has 2 functions:
 * 1. Shows the previous history of escalation in a table.
 * 2. Allows the user to submit a new escalation with reason text.
 */

import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button } from '../../../components';

import { DEFAULT_STYLES_FOR_MODAL } from '../constants';

import { AddCorrespondence } from './AddCorrespondence';
import { ViewCorrespondence } from './ViewCorrespondence';

const customStylesForModal = {
    overlay: {
        ...DEFAULT_STYLES_FOR_MODAL.overlay,
    },
    content: {
        ...DEFAULT_STYLES_FOR_MODAL.content,
        width: 900,
        height: 500,
    },
};

const EscalateToFederal = (props) => {
    const {
        visible,
        toggleHandler,
        setData,
    } = props;

    const closeModal = () => {
        setData({});
        toggleHandler();
    };

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
        >
            <ViewCorrespondence
                isEscalation
                parentProps={props}
            />
            <AddCorrespondence
                isEscalation
                parentProps={props}
                closeModal={closeModal}
            />
            <Button
                v2
                secondary
                extClasses='disputes-modal-cta'
                label='Close'
                onClick={closeModal}
            />
        </Modal>
    );
};

export default memo(EscalateToFederal);

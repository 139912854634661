/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

/**
 *
 * Table component to render data in a table format
 *
 */

import React, { memo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames';

import { ICONS_URL } from '../../constants/assets';
import FilterSVG from '../../assets/images/svgs/filter.svg';
import ClearFilterSVG from '../../assets/images/svgs/clear-filter.svg';

import Pagination from '../Pagination';
import PaginationV2 from '../PaginationV2';
import Button from '../Button';

import FilterSelection from './FilterSelection';
import { TABLECELL_DATA_TYPE } from './constants';

import './style.scss';

// TODO: need to add other functionality according to different requirement across the app
const Table = (props) => {
    /**
     * renderActionableColumn {function} - prop to render any extra columns where some action can be performed
     * v2 {boolean} - prop to render the v2 table & pagination as per new design
     * paginationV2 {boolean} - prop to render the v2 pagination as per the new pagination system in Elastic Search.
     * nsrLabel {string} - prop to render the no search results label
     * renderCheckboxColumn {function} - prop to render checkbox to select a particular column
     * enableScrollView {boolean} - prop to render the table with a scrollable view once the table rows overflow.
     * components - prop to add specific components in a table(Ex - row).
     * filterDetails - prop to add filter details for a specific column which uses the label of column to
                       identify the filter value and on filter function of the column for which filtering is to be done.
                       (Ex - Status: { value: statusFilterValue, setValue: setStatusFilterValue, onSubmit: (value) => filterValue(Value) })
     */
    const {
        header, labelData, contentData, prevToken, nextToken, paginatedClickHandler, extClasses = {},
        renderActionableColumn, v2, errLabel, nsrLabel, renderCheckboxColumn, objKey = 'key',
        getSortingDataBasedOnSortingOrder, enableScrollView = false, renderLabelColumn = false,
        detailsColumnLabel, handleDetailsClick, hideActionHeader = false, rowDataVersion = 1,
        components = {}, paginationV2, paginationParams = {}, filterDetails, infiniteScroll = false, infiniteScrollPaginationToken,
    } = props;

    const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);
    const [columnFilteringApplied, setColumnFilteringApplied] = useState(false);

    const containerClasses = classNames('table-sr table-sr--mt0', extClasses && extClasses.container, enableScrollView && 'table-sv');
    const noResultsContainerClasses = classNames('table-nsr table-nsr--mt0', extClasses && extClasses.container);
    const titleClasses = classNames('table-sr__label', extClasses && extClasses.title);
    const headerCrClasses = classNames(
        'table-sr__thw',
        v2 && 'table-sr__thw--v2',
        extClasses && extClasses.headerCr,
        enableScrollView && 'table-sv__thw',
    );
    const contentCrClasses = classNames(
        'table-sr__tlc',
        v2 && 'table-sr__tlc--v2',
        extClasses && extClasses.contentCr,
        enableScrollView && 'table-sv__tlc',
    );

    const rowCrClasses = classNames(
        'table-sr__tlw table-sr__tlw--aic',
        extClasses && extClasses.rowCr,
    );

    const infiniteScrlBtn = classNames(
        extClasses && extClasses.infiniteScrlBtn,
    );

    const errorClass = classNames(
        'error-label1 wspace-pre-wrap',
        noResultsContainerClasses,
    );

    if (errLabel) {
        // using noResultsContainerClasses as we want to show the error or no results label in the same font
        return (
            <div className={errorClass}>
                {errLabel}
            </div>
        );
    }

    // no need to render anything if the data is not defined (API request is not yet initiated)
    // Array type check is need to ensure our contentData.map does not give us any error
    if (!contentData || !Array.isArray(contentData)) {
        return null;
    }

    // show no search results label if the data is not found (after API request)
    if (contentData.length === 0) {
        return (
            <div className={noResultsContainerClasses}>
                {nsrLabel || 'No search results found!'}
            </div>
        );
    }

    const renderSortingSection = (item) => (
        <div className='table__sorting'>
            <span>{item.label}</span>
            <div className='table__arrow-alignment'>
                <img
                    className='table__rotated-chevron'
                    src={ICONS_URL.CHEVRON_BOTTOM}
                    alt='chevron-icon'
                    onClick={() => getSortingDataBasedOnSortingOrder('ASC')}
                />
                <img
                    className='table__chevron'
                    src={ICONS_URL.CHEVRON_BOTTOM}
                    alt='chevron-icon'
                    onClick={() => getSortingDataBasedOnSortingOrder('DESC')}
                />
            </div>
        </div>
    );

    const renderColumnFilterDropDown = (item) => {
        const { label, column_filters: columnFilters } = item || {};
        const { setValue, onSubmit, value } = filterDetails?.[label] || {};

        return (
            <div className='table-filter-dropdown'>
                <div className='frccWrapper frwpWrapper'>
                    <div>{item.label}</div>
                    <img
                        src={FilterSVG}
                        alt='filter-icon'
                        className='table-filter-icon ml-15'
                        onClick={() => setFilterDropdownVisible(true)}
                    />
                    {
                        columnFilteringApplied && value && (
                            <img
                                src={ClearFilterSVG}
                                alt='clear-filter-icon'
                                className='table-filter-icon'
                                onClick={() => {
                                    setFilterDropdownVisible(false);
                                    setColumnFilteringApplied(false);
                                    if (setValue) setValue();
                                    if (onSubmit) onSubmit();
                                }}
                            />
                        )
                    }
                </div>
                {
                    filterDropdownVisible && (
                        <div className='table-filter-dropdown-content'>
                            <FilterSelection
                                filter={columnFilters}
                                value={value}
                                setValue={setValue || (() => {})}
                                extClasses={extClasses && extClasses.filterInput}
                            />
                            <div className='frWrapper p-5'>
                                <div className='table-button p-5'>
                                    <Button
                                        v2
                                        secondary
                                        label='Close'
                                        onClick={() => {
                                            setFilterDropdownVisible(false);
                                        }}
                                    />
                                </div>
                                <div className='table-button p-5'>
                                    <Button
                                        v2
                                        primary
                                        label='Apply'
                                        onClick={() => {
                                            setFilterDropdownVisible(false);
                                            if (onSubmit) onSubmit(value);
                                            setColumnFilteringApplied(true);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    };
    // TODO : https://monorail.pointz.in/p/fi-app/issues/detail?id=57981
    const labelColumn = (prop, columnStyle = {}) => {
        if (prop && rowDataVersion === 2) {
            const { data_type: dataType, value_v2: valueV2, style } = prop || {};
            switch (dataType) {
                case TABLECELL_DATA_TYPE.string:
                    // eslint-disable-next-line react/no-danger
                    return <div style={{ ...columnStyle, ...style }} dangerouslySetInnerHTML={{ __html: prop[valueV2] }} />;
                case TABLECELL_DATA_TYPE.json:
                    return <span className='table-sl__tl'>{prop[valueV2]}</span>;
                case TABLECELL_DATA_TYPE.labelValueMatrix: {
                    const { label_value_matrix: labelValueMatrix } = prop[valueV2];

                    return (
                        <div>
                            {
                                labelValueMatrix && labelValueMatrix?.map((list, index) => {
                                    const { label_value_list: labelValueList } = list;

                                    return (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div className='frWrapper my-15' key={index}>
                                            {
                                                labelValueList && labelValueList?.map((labelValue) => {
                                                    const { label, data_type: innerDataType, value } = labelValue;
                                                    if (innerDataType === 'DATA_TYPE_STRING') {
                                                        return (
                                                            <div className={extClasses?.value} key={label + labelValue[value]}>
                                                                <span className='table-lvm__lb'>{label}</span> {labelValue[value]}
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    );
                }
                default:
                    return prop[valueV2];
            }
        }
        if (prop && typeof prop === 'object') {
            const { value, style = {} } = prop;

            // eslint-disable-next-line react/no-danger
            return <div style={{ ...columnStyle, ...style }} dangerouslySetInnerHTML={{ __html: value }} />;
        }

        // eslint-disable-next-line react/no-danger
        return <div dangerouslySetInnerHTML={{ __html: prop }} />;
    };

    const handleItemDetailsClick = (item) => () => {
        handleDetailsClick(item);
    };

    const renderRow = (item, idx) => (
        <React.Fragment>
            {
                renderCheckboxColumn ? (
                    <div
                        className={classNames('table-sr__tl f05Wrapper', extClasses.checkboxColumn)}
                    >
                        {renderCheckboxColumn(item, idx)}
                    </div>
                ) : null
            }
            {
                labelData.map((subItem) => (
                    <div
                        key={subItem.id || subItem.key || subItem.value || subItem.header_key}
                        className={
                            classNames(
                                'table-sr__tl',
                                enableScrollView && 'table-sv__tl',
                                subItem.extClasses,
                                extClasses[subItem[objKey]] && extClasses[subItem[objKey]].content,
                            )
                        }
                    >
                        {renderLabelColumn
                            ? renderLabelColumn(item[subItem[objKey]], subItem?.style)
                            : labelColumn(item[subItem[objKey]], subItem?.style)}
                    </div>
                ))
            }
            {
                renderActionableColumn ? (
                    <div
                        className={classNames(
                            'table-sr__tl',
                            extClasses.actionableColumn,
                            enableScrollView && 'table-sv__tl',
                        )}
                    >
                        {renderActionableColumn(item, idx)}
                    </div>
                ) : null
            }
            {detailsColumnLabel ? (
                <div
                    onClick={handleItemDetailsClick(item)}
                    role='button'
                    tabIndex='0'
                    className={classNames(
                        'table-sr__tl link',
                        enableScrollView && 'table-sv__tl',
                    )}
                >
                    {detailsColumnLabel}
                </div>
            ) : null}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div className={classNames(enableScrollView && 'table-wrapper')}>
                <div className={containerClasses}>
                    {
                        header ? (
                            <div className={titleClasses}>{header}</div>
                        ) : null
                    }
                    <div className={headerCrClasses}>
                        {
                            renderCheckboxColumn ? (
                                <div className={classNames(
                                    'table-sr__th f05Wrapper',
                                    extClasses.checkboxColumn,
                                    enableScrollView && 'table-sv__th',
                                )}
                                />
                            ) : null
                        }
                        {
                            labelData.map((item) => (
                                <div
                                    key={item.id || item.key || item.value || item.header_key}
                                    className={
                                        classNames(
                                            'table-sr__th',
                                            enableScrollView && 'table-sv__th',
                                            extClasses[item[objKey]] && extClasses[item[objKey]].label,
                                            item.extClasses,
                                        )
                                    }
                                >
                                    {
                                        // eslint-disable-next-line no-nested-ternary
                                        item.hasSortConfig
                                            ? renderSortingSection(item)
                                            : item.has_filters ? renderColumnFilterDropDown(item) : item.label
                                    }
                                </div>
                            ))
                        }
                        {
                            !hideActionHeader && renderActionableColumn ? (
                                <div
                                    className={classNames('table-sr__th', enableScrollView && 'table-sv__th', extClasses.actionableColumn)}
                                >
                                    Actions
                                </div>
                            ) : null
                        }
                        {detailsColumnLabel ? (
                            <div
                                className={classNames('table-sr__th', enableScrollView && 'table-sv__th')}
                            >
                                {detailsColumnLabel}
                            </div>
                        ) : null}
                    </div>
                    <div className={contentCrClasses}>
                        {
                            contentData.map((item, idx) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <React.Fragment key={idx}>
                                    {
                                        !components?.row && (
                                            <div key={item.id || idx + 1} className={rowCrClasses}>
                                                {
                                                    renderRow(item, idx)
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        components?.row && (
                                            <components.row
                                                data={{
                                                    item, idx,
                                                }}
                                                extClasses={rowCrClasses}
                                            >
                                                {
                                                    renderRow(item, idx)
                                                }
                                            </components.row>
                                        )
                                    }
                                </React.Fragment>
                            ))
                        }
                    </div>
                    {
                        infiniteScroll && !!Object.keys(infiniteScrollPaginationToken).length && (
                            <div className='frccWrapper'>
                                <Button
                                    v2
                                    primary
                                    extClasses={infiniteScrlBtn}
                                    label='View More'
                                    onClick={() => paginatedClickHandler({ token: infiniteScrollPaginationToken })}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
            {
                prevToken && nextToken ? (
                    <Pagination
                        v2={v2}
                        prev={prevToken}
                        next={nextToken}
                        onClickHandler={paginatedClickHandler}
                        enableScrollView={enableScrollView}
                    />
                ) : null
            }
            {
                (paginationParams && paginationV2) ? (
                    <PaginationV2
                        paginationParams={paginationParams}
                        onClickHandler={paginatedClickHandler}
                        enableScrollView={enableScrollView}
                    />
                ) : null
            }
        </React.Fragment>
    );
};

export default memo(Table);

/**
 * @file Manages the saga generator functions for Credit Card component
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    CURRENT_CREDIT_CARD_DETAILS_ENDPOINT, CREDIT_CARD_TRACKING_INFO_ENDPOINT, CREDIT_CARD_CONTROL_DETAILS_ENDPOINT,
    ALL_CREDIT_CARDS_ENDPOINT, CREDIT_CARD_USAGE_DETAILS_ENDPOINT, UPDATE_CREDIT_CARD_USAGE_DETAILS_ENDPOINT,
    CREDIT_CARD_ONBOARADING_DETAILS_ENDPOINT, CREDIT_CARD_CURRENT_LIMIT_USAGE_DETAILS_ENDPOINT, CREDIT_CARD_BILLING_INFO_ENDPOINT,
    ALL_ACTIVE_LOAN_ACCOUNTS, ALL_CLOSED_LOAN_ACCOUNTS, LOAN_ACCOUNT_DETAILS, ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS, LOAN_OFFERS,
    BLOCK_CREDIT_CARD_ENDPOINT, SUSPEND_CREDIT_CARD_ENDPOINT, FD_CREDIT_CARD_DETAILS_ENDPOINT,
    CREDIT_CARD_ONBOARADING_DETAILS_V2_ENDPOINT, CREDIT_CARD_ELIGIBILITY_DETAILS_ENDPOINT,
    SELECTED_CREDIT_CARD_DETAILS_ENDPOINT, CREDIT_CARD_CURRENT_STAGE_DETAILS_ENDPOINT, CREDIT_CARD_NEXT_STAGE_DETAILS_ENDPOINT,
    CREDIT_CARD_MILESTONE_REWARDS_DETAILS_ENDPOINT,
} from '../../api/routes';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify, getErrMsg,
} from '../../utils';
import { SENSITIVE_ACTIONS } from '../Tickets/constants';
import { setAuthScreen, discardActionAuthInfo } from '../Tickets/saga';

import { getErrorObjectForSaga, showErrorToastMsg } from '../../utils/errFormatter';

import {
    GET_CURRENT_CREDIT_CARD_DETAILS, SET_CURRENT_CREDIT_CARD_DETAILS, ERR_CURRENT_CREDIT_CARD_DETAILS,
    GET_CREDIT_CARD_TRACKING_INFO, SET_CREDIT_CARD_TRACKING_INFO, GET_CREDIT_CARD_CONTROL_DETAILS,
    SET_CREDIT_CARD_CONTROL_DETAILS, GET_ALL_CREDIT_CARDS, SET_ALL_CREDIT_CARDS, ERR_ALL_CREDIT_CARDS,
    GET_CREDIT_CARD_USAGE_DETAILS, SET_CREDIT_CARD_USAGE_DETAILS, UPDATE_CREDIT_CARD_USAGE_DETAILS,
    GET_CREDIT_CARD_ONBOARDING_DETAILS, SET_CREDIT_CARD_ONBOARDING_DETAILS, GET_CURRENT_LIMIT_USAGE_DETAILS,
    SET_CURRENT_LIMIT_USAGE_DETAILS, ERR_CURRENT_LIMIT_USAGE_DETAILS, GET_CREDIT_CARD_BILLING_INFO,
    SET_CREDIT_CARD_BILLING_INFO, ERR_CREDIT_CARD_BILLING_INFO, GET_ALL_ACTIVE_LOAN_ACCOUNTS,
    SET_ALL_ACTIVE_LOAN_ACCOUNTS, ERR_ALL_ACTIVE_LOAN_ACCOUNTS, GET_ALL_CLOSED_LOAN_ACCOUNTS,
    SET_ALL_CLOSED_LOAN_ACCOUNTS, ERR_ALL_CLOSED_LOAN_ACCOUNTS, GET_LOAN_ACCOUNT_DETAILS,
    SET_LOAN_ACCOUNT_DETAILS, ERR_CREDIT_CARD_SCREEN, GET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS,
    SET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS, GET_LOAN_OFFERS, SET_LOAN_OFFERS, ERR_LOAN_OFFERS,
    INITIATE_BLOCK_CARD, SET_BLOCK_CARD, INITIATE_SUSPEND_CARD, SET_SUSPEND_CARD, GET_FD_CREDIT_CARD_DETAILS,
    SET_FD_CREDIT_CARD_DETAILS, GET_CREDIT_CARD_ELIGIBILITY_DETAILS, SET_CREDIT_CARD_ONBOARDING_DETAILS_V2,
    SET_CREDIT_CARD_ELIGIBILITY_DETAILS,
    GET_CREDIT_CARD_ONBOARDING_DETAILS_V2,
    SET_SELECTED_CREDIT_CARD_DETAILS,
    SET_CREDIT_CARD_CURRENT_STAGE_DETAILS,
    SET_CREDIT_CARD_NEXT_STAGE_DETAILS,
    GET_SELECTED_CREDIT_CARD_DETAILS,
    GET_CREDIT_CARD_NEXT_STAGE_DETAILS,
    GET_CREDIT_CARD_CURRENT_STAGE_DETAILS,
    GET_MILESTONE_REWARDS_DETAILS,
    SET_MILESTONE_REWARDS_DETAILS,
    ERR_MILESTONE_REWARDS_DETAILS,
} from './constants';

const getErrorAction = (actionType, containerKey) => ({
    type: actionType,
    data: {
        containerKey,
    },
});

function* getCurrentCreditCardDetailsSaga(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CURRENT_CREDIT_CARD_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_CURRENT_CREDIT_CARD_DETAILS, data: { ticketId, ...response } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CURRENT_CREDIT_CARD_DETAILS, ticketId), error));
    }
}

function* getCreditCardOnboardingDetailsSaga(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CREDIT_CARD_ONBOARADING_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_CREDIT_CARD_ONBOARDING_DETAILS, data: { ticketId, ...response } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getFDCreditCardDetailsSaga(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${FD_CREDIT_CARD_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_FD_CREDIT_CARD_DETAILS, data: { ticketId, ...response } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getAllCreditCardsSaga(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ALL_CREDIT_CARDS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_ALL_CREDIT_CARDS, data: { ticketId, ...response } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ALL_CREDIT_CARDS, data: { ticketId, err: getErrMsg(e) } });
    }
}

function* getCreditCardTrackingInfoSaga(action) {
    const { ticketId, userId, resolve } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CREDIT_CARD_TRACKING_INFO_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_CREDIT_CARD_TRACKING_INFO, data: { ticketId, ...response } });
        yield call(resolve);
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getCreditCardControlDetailsSaga(action) {
    const { ticketId, isCardLimitsScreen } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CREDIT_CARD_CONTROL_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_CREDIT_CARD_CONTROL_DETAILS, data: { ...response, ticketId, isCardLimitsScreen } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getCreditCardUsageDetailsSaga(action) {
    const { ticketId, isCardSettingsScreen } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CREDIT_CARD_USAGE_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_CREDIT_CARD_USAGE_DETAILS, data: { ...response, ticketId, isCardSettingsScreen } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* updateCreditCardUsageDetailsSaga(action) {
    const { ticketId, requestBody } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${UPDATE_CREDIT_CARD_USAGE_DETAILS_ENDPOINT}?${queryString}`,
            requestBody,
        );

        yield call(getCreditCardUsageDetailsSaga, { data: { isCardSettingsScreen: true, ticketId } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getCurrentLimitUsageDetailsSaga(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CREDIT_CARD_CURRENT_LIMIT_USAGE_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_CURRENT_LIMIT_USAGE_DETAILS, data: { ticketId, ...response } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CURRENT_LIMIT_USAGE_DETAILS, ticketId), error));
    }
}

function* getCreditCardBillingInfoSaga(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CREDIT_CARD_BILLING_INFO_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_CREDIT_CARD_BILLING_INFO, data: { ticketId, ...response } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_BILLING_INFO, ticketId), error));
    }
}

function* getAllActiveLoanAccountsSaga(action) {
    const { ticketId } = action.data;

    const queryString = getQueryStringFromObject({ ticketId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ALL_ACTIVE_LOAN_ACCOUNTS}?${queryString}`,
        );

        yield put({ type: SET_ALL_ACTIVE_LOAN_ACCOUNTS, data: { ticketId, ...response } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_ALL_ACTIVE_LOAN_ACCOUNTS, ticketId), error));
    }
}

function* getAllClosedLoanAccountsSaga(action) {
    const { ticketId } = action.data;

    const queryString = getQueryStringFromObject({ ticketId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ALL_CLOSED_LOAN_ACCOUNTS}?${queryString}`,
        );

        yield put({ type: SET_ALL_CLOSED_LOAN_ACCOUNTS, data: { ticketId, ...response } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_ALL_CLOSED_LOAN_ACCOUNTS, ticketId), error));
    }
}

function* getLoanAccountDetailsSaga(action) {
    const { ticketId, loanAccountId, isLoanAccountDetailsScreen } = action.data;

    const queryString = getQueryStringFromObject({ ticketId, loanAccountId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LOAN_ACCOUNT_DETAILS}?${queryString}`,
        );

        yield put({ type: SET_LOAN_ACCOUNT_DETAILS, data: { ...response, ticketId, isLoanAccountDetailsScreen } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getAllEligibleTransactionsForEmisSaga(action) {
    const { ticketId, isEligibleTransactionsForEmiScreen } = action.data;

    const queryString = getQueryStringFromObject({ ticketId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS}?${queryString}`,
        );

        yield put({ type: SET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS, data: { ...response, ticketId, isEligibleTransactionsForEmiScreen } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getLoanOffersSaga(action) {
    const { ticketId, externalTransactionId, isLoanOffersScreen } = action.data;

    const queryString = getQueryStringFromObject({ ticketId, externalTransactionId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LOAN_OFFERS}?${queryString}`,
        );

        yield put({ type: SET_LOAN_OFFERS, data: { ...response, ticketId, isLoanOffersScreen } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_LOAN_OFFERS, ticketId), error));
    }
}

function* initiateBlockCardSaga(action) {
    const {
        ticketId, userId, index, isConfirmAction,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    const payload = {
        isConfirmAction,
    };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${BLOCK_CREDIT_CARD_ENDPOINT}?${queryString}`,
            payload,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response, type: SENSITIVE_ACTIONS.BLOCKCREDIT } });

        if (!result) {
            yield put({ type: SET_BLOCK_CARD, data: { ...response, isConfirmAction, ticketId } });
            toastify(response.message, 'success');
            yield put({ type: GET_CURRENT_CREDIT_CARD_DETAILS, data: { ticketId } });
        } else if (result === 'confirm-screen') {
            yield put({ type: SET_BLOCK_CARD, data: { isConfirmAction, ticketId } });
            yield call(discardActionAuthInfo, { data: { type: SENSITIVE_ACTIONS.BLOCKCREDIT, index } });
        } else {
            yield put({ type: ERR_CREDIT_CARD_SCREEN, data: { ticketId, err: '' } });
        }
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* initiateSuspendCardSaga(action) {
    const {
        ticketId, userId, index, isConfirmAction,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    const payload = {
        isConfirmAction,
    };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${SUSPEND_CREDIT_CARD_ENDPOINT}?${queryString}`,
            payload,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response, type: SENSITIVE_ACTIONS.SUSPENDCREDIT } });

        if (!result) {
            yield put({ type: SET_SUSPEND_CARD, data: { ticketId, ...response, isConfirmAction } });
            toastify(response.message, 'success');
        } else if (result === 'confirm-screen') {
            yield put({ type: SET_SUSPEND_CARD, data: { ticketId, isConfirmAction } });
            yield call(discardActionAuthInfo, { data: { type: SENSITIVE_ACTIONS.SUSPENDCREDIT, index } });
        } else {
            yield put({ type: ERR_CREDIT_CARD_SCREEN, data: { ticketId, err: '' } });
        }
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getCreditCardEligibilityDetailsSaga(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CREDIT_CARD_ELIGIBILITY_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_CREDIT_CARD_ELIGIBILITY_DETAILS, data: { ticketId, ...response } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getCreditCardOnboardingDetailsV2Saga(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CREDIT_CARD_ONBOARADING_DETAILS_V2_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_CREDIT_CARD_ONBOARDING_DETAILS_V2, data: { ticketId, ...response } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getSelectedCreditCardDetailsSaga(action) {
    const { ticketId, isSelectedCreditCardScreen } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SELECTED_CREDIT_CARD_DETAILS_ENDPOINT}?${queryString}`,
        );
        yield put({
            type: SET_SELECTED_CREDIT_CARD_DETAILS,
            data: { selectedCardDetails: response.programDetails, ticketId, isSelectedCreditCardScreen },
        });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getCreditCardCurrentStageDetailsSaga(action) {
    const { ticketId, isCurrentStageScreen } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CREDIT_CARD_CURRENT_STAGE_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_CREDIT_CARD_CURRENT_STAGE_DETAILS, data: { ...response, ticketId, isCurrentStageScreen } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getCreditCardNextStageDetailsSaga(action) {
    const { ticketId, isNextStageScreen } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CREDIT_CARD_NEXT_STAGE_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_CREDIT_CARD_NEXT_STAGE_DETAILS, data: { ...response, ticketId, isNextStageScreen } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CREDIT_CARD_SCREEN), error));
    }
}

function* getCreditCardMilestoneRewardsDetailsSaga(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CREDIT_CARD_MILESTONE_REWARDS_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_MILESTONE_REWARDS_DETAILS, data: { ...response, ticketId } });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_MILESTONE_REWARDS_DETAILS, ticketId), error));
    }
}

export default function* creditCardSaga() {
    yield all(
        [
            yield takeLatest(GET_CURRENT_CREDIT_CARD_DETAILS, getCurrentCreditCardDetailsSaga),
            yield takeLatest(GET_CREDIT_CARD_ONBOARDING_DETAILS, getCreditCardOnboardingDetailsSaga),
            yield takeLatest(GET_FD_CREDIT_CARD_DETAILS, getFDCreditCardDetailsSaga),
            yield takeLatest(GET_ALL_CREDIT_CARDS, getAllCreditCardsSaga),
            yield takeLatest(GET_CREDIT_CARD_TRACKING_INFO, getCreditCardTrackingInfoSaga),
            yield takeLatest(GET_CREDIT_CARD_CONTROL_DETAILS, getCreditCardControlDetailsSaga),
            yield takeLatest(GET_CREDIT_CARD_USAGE_DETAILS, getCreditCardUsageDetailsSaga),
            yield takeLatest(UPDATE_CREDIT_CARD_USAGE_DETAILS, updateCreditCardUsageDetailsSaga),
            yield takeLatest(GET_CURRENT_LIMIT_USAGE_DETAILS, getCurrentLimitUsageDetailsSaga),
            yield takeLatest(GET_CREDIT_CARD_BILLING_INFO, getCreditCardBillingInfoSaga),
            yield takeLatest(GET_ALL_ACTIVE_LOAN_ACCOUNTS, getAllActiveLoanAccountsSaga),
            yield takeLatest(GET_ALL_CLOSED_LOAN_ACCOUNTS, getAllClosedLoanAccountsSaga),
            yield takeLatest(GET_LOAN_ACCOUNT_DETAILS, getLoanAccountDetailsSaga),
            yield takeLatest(GET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS, getAllEligibleTransactionsForEmisSaga),
            yield takeLatest(GET_LOAN_OFFERS, getLoanOffersSaga),
            yield takeLatest(INITIATE_BLOCK_CARD, initiateBlockCardSaga),
            yield takeLatest(INITIATE_SUSPEND_CARD, initiateSuspendCardSaga),
            // v2 saga
            yield takeLatest(GET_CREDIT_CARD_ELIGIBILITY_DETAILS, getCreditCardEligibilityDetailsSaga),
            yield takeLatest(GET_CREDIT_CARD_ONBOARDING_DETAILS_V2, getCreditCardOnboardingDetailsV2Saga),
            yield takeLatest(GET_SELECTED_CREDIT_CARD_DETAILS, getSelectedCreditCardDetailsSaga),
            yield takeLatest(GET_CREDIT_CARD_CURRENT_STAGE_DETAILS, getCreditCardCurrentStageDetailsSaga),
            yield takeLatest(GET_CREDIT_CARD_NEXT_STAGE_DETAILS, getCreditCardNextStageDetailsSaga),
            yield takeLatest(GET_MILESTONE_REWARDS_DETAILS, getCreditCardMilestoneRewardsDetailsSaga),
        ],
    );
}

// bulk user info by ticket ids
export const GET_BULK_USER_INFO = 'app/BUD/GET_BULK_USER_INFO';
export const SET_BULK_USER_INFO = 'app/BUD/SET_BULK_USER_INFO';
export const ERR_BULK_USER_INFO = 'app/BUD/ERR_BULK_USER_INFO';
export const RESET_BULK_USER_INFO = 'app/BUD/RESET_BULK_USER_INFO';

export const LABEL_DATA = [
    {
        id: 1,
        label: 'Ticket ID',
        key: 'ticketId',
    },
    {
        id: 2,
        label: 'Failure Reason',
        key: 'failureReason',
        extClasses: 'f2Wrapper',
    },
];

export const SEARCH_BY_LIST_MAP = {
    uploadCSV: 'Upload CSV',
    enterCSV: 'Enter Comma Separated Values Manually',
}

export const SEARCH_BY_LIST = [SEARCH_BY_LIST_MAP.uploadCSV, SEARCH_BY_LIST_MAP.enterCSV];

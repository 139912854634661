/**
 * @file Saga for US Stocks flow
 */

import { all, put, takeLatest, call } from 'redux-saga/effects';

import {
    US_STOCKS_ADDITIONAL_ACTIONS_ENDPOINT, US_STOCKS_FILE_ENTRIES_ENDPOINT, US_STOCKS_GENERATE_FILE_ENDPOINT, US_STOCKS_NAV_BAR_ENDPOINT,
    US_STOCKS_TABLE_FILTERS_ENDPOINT, US_STOCKS_ACKNOWLEDGE_FILE_ENTRY_ENDPOINT,
} from '../../api/routes';
import {
    clientApiWrapper, getErrLabel, getErrMsg, getQueryStringFromObject, toastify,
} from '../../utils';
import { FEDERAL_US_STOCKS_PATH } from '../App/routes';

import {
    ERR_ADDITIONAL_ACTIONS, ERR_FILE_ENTRIES, ERR_US_STOCKS_NAV_BAR_ENTITIES, ERR_US_STOCKS_GENERATE_FILE, ERR_US_STOCKS_TABLE_FILTERS,
    GET_FILE_ENTRIES, GET_NAV_BAR_ENTITIES, GET_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY, GET_US_STOCKS_GENERATE_FILE, GET_US_STOCKS_TABLE_FILTERS,
    SET_ADDITIONAL_ACTIONS, SET_FILE_ENTRIES, SET_NAV_BAR_ENTITIES, SET_US_STOCKS_GENERATE_FILE, SET_US_STOCKS_TABLE_FILTERS, US_STOCK_SCREENS,
    GET_ADDITIONAL_ACTIONS, SET_US_STOCKS_VIEW_DATA, SET_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY,
} from './constants';

const addUiPathToEntity = (navItem) => {
    const uiItem = {
        ...navItem,
        path: `${FEDERAL_US_STOCKS_PATH}/${navItem.path}`,
        pathId: navItem.path,
    };

    if (navItem.nested_values) {
        uiItem.nestedLinks = navItem.nested_values.map((nestedItem) => ({
            ...nestedItem,
            path: `${FEDERAL_US_STOCKS_PATH}/${nestedItem.path}`,
            pathId: nestedItem.path,
        }));
    }

    // remove nested_values sent from BE
    delete uiItem.nested_values;

    return uiItem;
};

function* getNavBarEntitiesSaga() {
    try {
        let navBarInfo = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${US_STOCKS_NAV_BAR_ENDPOINT}`,
        );

        navBarInfo = {
            entities: navBarInfo.entities.map(addUiPathToEntity),
            defaultEntity: addUiPathToEntity(navBarInfo.defaultEntity),
        };

        yield put({ type: SET_NAV_BAR_ENTITIES, data: navBarInfo });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_US_STOCKS_NAV_BAR_ENTITIES, data: { err: getErrMsg(e) } });
    }
}

function* getFileEntriesSaga(action) {
    const queryString = getQueryStringFromObject(action.data);
    try {
        const { fileEntries } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${US_STOCKS_FILE_ENTRIES_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_FILE_ENTRIES, data: { fileEntries } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_FILE_ENTRIES, data: { err: getErrMsg(e) } });
    }
}

function* getAdditionalActionsSaga(action) {
    const queryString = getQueryStringFromObject(action.data);
    try {
        const { additionalActions } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${US_STOCKS_ADDITIONAL_ACTIONS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_ADDITIONAL_ACTIONS, data: { additionalActions } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ADDITIONAL_ACTIONS, data: { err: getErrMsg(e) } });
    }
}

function* getUsStocksTableFiltersSaga(action) {
    const queryString = getQueryStringFromObject(action.data);
    try {
        const { parameterList } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${US_STOCKS_TABLE_FILTERS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_US_STOCKS_TABLE_FILTERS, data: { parameterList } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_US_STOCKS_TABLE_FILTERS, data: { err: getErrMsg(e) } });
    }
}

function* getUsStocksGenerateFileSaga(action) {
    const queryString = getQueryStringFromObject(action.data);
    try {
        const { generatedFileData } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${US_STOCKS_GENERATE_FILE_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_US_STOCKS_GENERATE_FILE, data: { generatedFileData } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_US_STOCKS_GENERATE_FILE, data: { err: getErrMsg(e) } });
    }
}

function* getUsStocksAcknowledgeFileEntrySaga(action) {
    const requestBody = action.data;
    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${US_STOCKS_ACKNOWLEDGE_FILE_ENTRY_ENDPOINT}`,
            requestBody,
        );

        yield put({ type: SET_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY });
        yield put({ type: SET_US_STOCKS_VIEW_DATA, data: { curView: US_STOCK_SCREENS.FILE_ENTRIES } });
        toastify('Successfully acknowledged the file entry', 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_US_STOCKS_GENERATE_FILE, data: { err: getErrMsg(e) } });
    }
}

export default function* usStocksSaga() {
    yield all(
        [
            yield takeLatest(GET_NAV_BAR_ENTITIES, getNavBarEntitiesSaga),
            yield takeLatest(GET_FILE_ENTRIES, getFileEntriesSaga),
            yield takeLatest(GET_ADDITIONAL_ACTIONS, getAdditionalActionsSaga),
            yield takeLatest(GET_US_STOCKS_TABLE_FILTERS, getUsStocksTableFiltersSaga),
            yield takeLatest(GET_US_STOCKS_GENERATE_FILE, getUsStocksGenerateFileSaga),
            yield takeLatest(GET_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY, getUsStocksAcknowledgeFileEntrySaga),
        ],
    );
}

/**
 * @file LoanOffersScreen: Component to show the loan offers screen.
 */

import React, { useState, memo } from 'react';

import { TableV1, DropdownWrapper, DropdownContent } from '../../../components';
import { useScrollToPosition } from '../../../hooks';

import CardBanner from '../CardBanner';
import { IS_LOAN_OFFERS_SCREEN, IS_ELIGIBLE_TRANSACTIONS_FOR_EMI_SCREEN } from '../constants';
import { getLoanOfferDetails } from '../utils';

const LoanOffersScreen = (props) => {
    const {
        data, loanTenureValues, ticketId,
    } = props;

    useScrollToPosition();

    const [tenureValue, setTenureValue] = useState(loanTenureValues[0]);
    const [showLoanTenureDD, setShowLoanTenureDD] = useState(false);

    const loanOffersWithMatchingTenure = data.find((loanOffer) => loanOffer.tenure === tenureValue);

    const toggleDropDown = () => {
        setShowLoanTenureDD(!showLoanTenureDD);
    };

    const handleOptionsChange = (value) => () => {
        setTenureValue(value);

        toggleDropDown();
    };

    return (
        <CardBanner
            title='Credit Card'
            discardCurrentScreen={IS_LOAN_OFFERS_SCREEN}
            activatePreviousScreen={IS_ELIGIBLE_TRANSACTIONS_FOR_EMI_SCREEN}
            ticketId={ticketId}
        >
            <div className='heading4'>Emi Preview</div>

            <div className='frcWrapper mt-30 mb-60'>
                <div className='label1'>Tenure of loan:</div>

                <DropdownWrapper
                    visible={showLoanTenureDD}
                    onOutsideClickHandler={toggleDropDown}
                    extClasses={{
                        container: 'custom-dd',
                    }}
                >
                    <div
                        onClick={toggleDropDown}
                        onKeyDown={toggleDropDown}
                        role='button'
                        tabIndex='0'
                    >
                        {tenureValue} months
                    </div>
                    <DropdownContent
                        visible
                        extClasses={{
                            container: ['custom-dd__cc height-transition', showLoanTenureDD && 'custom-dd__cc--ac'],
                        }}
                    >
                        {loanTenureValues.map((value) => (
                            <button
                                type='button'
                                key={value}
                                className='custom-dd__cl'
                                onClick={handleOptionsChange(value)}
                            >
                                {value}
                            </button>
                        ))}
                    </DropdownContent>
                </DropdownWrapper>
            </div>

            <TableV1
                heading=''
                contentData={getLoanOfferDetails(loanOffersWithMatchingTenure)}
            />
        </CardBanner>
    );
};

export default memo(LoanOffersScreen);

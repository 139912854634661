import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { path } from 'ramda';
import { Button, Loader } from '../../../components';
import { selectVkycData, selectRejectedReasons, selectFileLocalStoreStatus, selectVKYCConfig } from '../selectors';
import { getConcludeCall, getEndVKYCCall, getGenerateVKYCReport, muteAudioStatus } from '../actions';
import ReportDetails from '../ReportDetails';
import { Remarks } from '../ReportDetails/Remarks';

const AGENT_REPORT_STAGE = 'AGENT_REPORT_STAGE';

const FinalScreen = ({ stopRecording }) => {
    const dispatch = useDispatch();
    const vkycData = useSelector(selectVkycData, shallowEqual);
    const meetingId = vkycData?.data?.meetingId;
    const reportData = vkycData?.meetings?.[meetingId]?.reports?.report;
    const [isConfirmed, setIsConfirmed] = useState();
    const faceMatchResult = path(['face_match_results'], reportData) || {};
    const permanentAddress = path(['permanent_address'], reportData) || {};
    const rejectedReasons = useSelector(selectRejectedReasons, shallowEqual) || [];
    const [remarkState, setRemarkState] = React.useState({
        visible: false,
        comment: '',
        rejectionReason: '',
    });
    const localStoreStatus = useSelector(selectFileLocalStoreStatus);
    const vkycConsulConfig = useSelector(selectVKYCConfig);

    const videoRecordinUploading = vkycData?.loading;

    useEffect(() => {
        dispatch(getEndVKYCCall({
            meetingId,
            isAccepted: true,
            screenKey: ['meetings', meetingId, 'endCall'],
        }));

        const agentResponses = Object.entries(vkycData?.meetings[meetingId]?.answers || {}).map(([key, value]) => ({
            question: value.question,
            question_type: key,
            string_answer: value?.stringAnswer,
            image_answers: [{
                image_identifier: value?.cameraFront?.imageIdentifier || value?.cameraRear?.imageIdentifier,
            }],
            accepted: value?.accepted,
            remarks: value?.remarks,
        }));

        dispatch(muteAudioStatus(true));

        dispatch(getGenerateVKYCReport({
            questionAnswers: agentResponses,
            meetingId,
            screenKey: ['meetings', meetingId, 'reports'],
        }));
    }, []);

    const concludeCall = () => {
        const s3Url = vkycData?.data?.recordingUploadURL;
        dispatch(getConcludeCall({
            screenKey: ['meetings', meetingId, 'conclude'],
            id: meetingId,
            Data: {
                overall_accepted: !!isConfirmed,
                rejection_reason: remarkState?.rejectionReason,
                remarks: remarkState?.comment,
            },
            meetingId,
            s3Url,
        }));
        setRemarkState((prev) => ({
            ...prev, visible: false,
        }));
    };

    const onApprove = (isCompleted = false) => () => {
        if (localStoreStatus) concludeCall();
        else {
            stopRecording();
            setIsConfirmed(!!isCompleted);
        }
    };

    const onReject = () => {
        if (localStoreStatus) concludeCall();
        else {
            stopRecording();
            setIsConfirmed(false);
        }
    };

    useEffect(() => {
        if (localStoreStatus) concludeCall();
    }, [localStoreStatus]);

    return (
        <div>
            {reportData?.user_details
                && (
                    <ReportDetails
                        reportData={reportData}
                        faceMatchResult={faceMatchResult}
                        permanentAddress={permanentAddress}
                    />
                )}

            {videoRecordinUploading && <Loader visible />}
            {/* <div>Confirm if the customer’s Video KYC is successful?</div> */}
            <div className='vkyc-button-container'>
                <Button
                    v2
                    primary
                    label='Approve'
                    onClick={onApprove(true)}
                />
                <Button
                    v2
                    secondary
                    extClasses='vkyc-button-end'
                    label='Reject'
                    onClick={() => {
                        /**
                         * disable remark screen based on defaultDisabledRemarks flag
                         */
                        if (Array.isArray(vkycConsulConfig?.defaultDisabledRemarks)
                        && vkycConsulConfig?.defaultDisabledRemarks.includes(AGENT_REPORT_STAGE)) {
                            onReject();
                        } else {
                            setRemarkState((prev) => ({
                                ...prev, visible: true, rejectionReason: '', comment: '',
                            }));
                        }
                    }}
                />
            </div>
            <Remarks
                remarkState={remarkState}
                reviewReportAction={onReject}
                rejectedReasons={rejectedReasons}
                setRemarkState={setRemarkState}
            />
        </div>
    );
};

export default FinalScreen;

/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import invariant from 'invariant';
import { isEmpty, isFunction, isString, conformsTo } from 'lodash';

import checkStore from './checkStore';
import { DAEMON, ONCE_TILL_UNMOUNT, PRODUCTION, RESTART_ON_REMOUNT } from './constants';

const allowedModes = [RESTART_ON_REMOUNT, DAEMON, ONCE_TILL_UNMOUNT];

const checkKey = (key) => invariant(
    isString(key) && !isEmpty(key),
    '(app/utils...) injectSaga: Expected "key" to be a non empty string',
);
const checkDescriptor = (descriptor) => {
    const shape = {
        saga: isFunction,
        mode: (mode) => isString(mode) && allowedModes.includes(mode),
    };
    invariant(
        conformsTo(descriptor, shape),
        '(app/utils...) injectSaga: Expected a valid saga descriptor',
    );
};

export function injectSagaFactory(store, isValid) {
    return function injectSaga(key, descriptor = {}, args = undefined) {
        if (!isValid) checkStore(store);
        const newDescriptor = {
            ...descriptor,
            mode: descriptor.mode || RESTART_ON_REMOUNT,
        };

        const { saga, mode } = newDescriptor;
        checkKey(key);
        checkDescriptor(newDescriptor);

        const hasSaga = Reflect.has(store.injectedSagas, key);

        if (
            !hasSaga
            || (hasSaga && mode !== DAEMON && mode !== ONCE_TILL_UNMOUNT)
        ) {
            store.injectedSagas[key] = {
                ...newDescriptor,
                task: store.runSaga(saga, args),
            };
        }
    };
}

export function ejectSagaFactory(store, isValid) {
    return function ejectSaga(key) {
        if (!isValid) checkStore(store);

        checkKey(key);

        if (Reflect.has(store.injectedSagas, key)) {
            const descriptor = store.injectedSagas[key];

            /**
             * Dynamic injection error for saga occurring only in prod build, not dev build
             * Error on console - TypeError: Cannot read properties of undefined (reading 'cancel')
             * Solution link - https://github.com/react-boilerplate/react-boilerplate/issues/2314#issue-350148409
             * Solved by adding - descriptor.mode && descriptor.task
             */
            if (descriptor.mode && descriptor.mode !== DAEMON && descriptor.task) {
                descriptor.task.cancel();
                if (process.env.NODE_ENV === PRODUCTION) {
                    store.injectedSagas[key] = 'done';
                }
            }
        }
    };
}

export default function getInjectors(store) {
    checkStore(store);

    return {
        injectSaga: injectSagaFactory(store, true),
        ejectSaga: ejectSagaFactory(store, true),
    };
}

/**
 * History - Component to show the list for bulk ticket jobs details previously created and also, an option to kill the job in processing
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { isEmpty } from 'ramda';

import {
    Button, ExpandableCard, FormWithSingleInput, Loader, Table, Modal,
} from '../../../components';
import { dateUtils } from '../../../utils';

import { BULK_TICKET_JOB_LIST_LABEL_DATA, BULK_TICKET_JOB_FAILED_TICKET_LIST_LABEL_DATA } from '../constants';
import {
    setSearchParams, getBulkTicketJobList, getBulkTicketJobFailureInfo, killBulkTicketJob,
} from '../actions';
import {
    makeSelectSearchParameters, makeSelectLoading, makeSelectBulkTicketJobList, makeSelectBulkTicketJobFailureInfo,
} from '../selectors';

import './style.scss';

const bulkTicketJobFailureInfoModalStyles = {
    content: {
        backgroundColor: '#F5F5F5',
        maxHeight: 600,
        top: '12%',
        width: 900,
    },
};

const History = () => {
    const dispatch = useDispatch();

    // reducer state variables
    const searchParameters = useSelector(makeSelectSearchParameters(), shallowEqual);
    const isLoading = useSelector(makeSelectLoading(), shallowEqual);
    const bulkTicketJobList = useSelector(makeSelectBulkTicketJobList(), shallowEqual);
    const bulkTicketJobFailureInfo = useSelector(makeSelectBulkTicketJobFailureInfo(), shallowEqual);

    // local state variables
    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(false);
    const [errLabel, setErrLabel] = useState('');
    const [showFailureInfoModal, setFailureInfoModal] = useState(false);

    const { fromDate: fromInitialDate, toDate: toInitialDate } = dateUtils.getInitialDateRange('02-01-2021', true); // 01 Feb

    let fromDate;
    let toDate;

    // extract the search parameters
    if (!isEmpty(searchParameters)) {
        ({ fromDate, toDate } = searchParameters);
    }

    /**
     * initialise from and to date with the date utils value
     * this won't get called again & again
     */
    useEffect(() => {
        if (!fromDate && !toDate) {
            dispatch(setSearchParams({
                fromDate: fromInitialDate,
                toDate: toInitialDate, 
            }));
        }
    }, [fromDate, toDate]);

    const { searchCompleted, err: errInFetchingBulkTicketJobList, data = [], prev, next } = bulkTicketJobList;
    const { hasInfo: hasBulkTicketJobFailureInfo, failureList } = bulkTicketJobFailureInfo;

    const toggleSearchCard = () => {
        setIsSearchCardExpanded(!isSearchCardExpanded);
    };

    const handleDateChange = (key, type) => value => {
        const data = {
            key,
            value,
            fromDate,
            toDate,
        };

        const { fromDate: newFromDate, toDate: newToDate } = dateUtils.getModifiedDate(data);

        dispatch(setSearchParams({
            fromDate: newFromDate,
            toDate: newToDate, 
        }));
    };

    const renderDateBlock = (key) => {
        let label;
        let labelId;
        let value;
        let maxDate;
        let minDate;
        let containerClass;

        switch (key) {
            case 'fromDate':
                label = 'From Date*';
                labelId = 'from-date';
                containerClass = 'btu-history-fc';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = fromDate);
                break;

            case 'toDate':
                label = 'To Date*';
                labelId = 'to-date';
                containerClass = 'btu-history-fc btu-history-fc--var1';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = toDate);
                break;

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: containerClass,
                    label: 'btu-history-fc__lb',
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: handleDateChange(key),
                    maxDate,
                    minDate,
                }}
            />
        );
    };

    const onKillThisJobClickHandler = (data) => () => {
        const callKillBulkTicketJob = () => new Promise((resolve, reject) => {
            try {
                dispatch(killBulkTicketJob({ jobId: data.id, resolve }));
            } catch (e) {
                reject(e);
            }
        });

        // refresh the bulk ticket job list if kill action is successful
        callKillBulkTicketJob().then(() => {
            makePayloadAndCallApi();
        });
    };

    const onViewDetailsClickHandler = (data) => () => {
        dispatch(getBulkTicketJobFailureInfo({ jobId: data.id }));
        setFailureInfoModal(true);
    };

    const makePayloadAndCallApi = (params = {}) => {
        const { prev, next } = params;

        const payload = {
            from: fromDate.value,
            to: toDate.value,
            prev,
            next,
        };

        dispatch(getBulkTicketJobList(payload));
    };

    const handleSearch = (e) => {
        e.preventDefault();

        if (!fromDate.value || !toDate.value) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        makePayloadAndCallApi();

        setErrLabel('');
        setIsSearchCardExpanded(false);
    };

    const getPaginatedBulkTicketJobList = ({ prev, next }) => {
        makePayloadAndCallApi({ prev, next });
    };

    const renderActions = (data) => {
        return (
            <div className='fcWrapper'>
                {
                    data.isKillable ? (
                        <Button
                            link
                            label='Kill this Job'
                            onClick={onKillThisJobClickHandler(data)}
                            extClasses='btu-history-table-sr__cta'
                        />
                    ) : null
                }
                <Button
                    link
                    label='View Details'
                    onClick={onViewDetailsClickHandler(data)}
                />
            </div>
        );
    };

    return (
        <React.Fragment>
            <ExpandableCard
                extClasses='btu-history-ec'
                heading='Search for Bulk Ticket Jobs'
                isExpanded={isSearchCardExpanded}
                setIsExpanded={toggleSearchCard}
            >
                <div className='btu-history-ec__scr'>
                    <div className='frWrapper'>
                        {isSearchCardExpanded && renderDateBlock('fromDate')}
                        {isSearchCardExpanded && renderDateBlock('toDate')}
                    </div>
                    <div className='err-label btu-history-ec__el'>{errLabel}</div>
                    <Button
                        v2
                        primary
                        label='Search'
                        onClick={handleSearch}
                        extClasses='btu-history-ec__cta'
                    />
                </div>
            </ExpandableCard>
            {
                searchCompleted && (
                    <Table
                        v2
                        header='Bulk Ticket Jobs List'
                        labelData={BULK_TICKET_JOB_LIST_LABEL_DATA}
                        contentData={data}
                        renderActionableColumn={renderActions}
                        prevToken={prev}
                        nextToken={next}
                        paginatedClickHandler={getPaginatedBulkTicketJobList}
                        extClasses={{
                            container: 'btu-history-table-sr',
                        }}
                        errLabel={errInFetchingBulkTicketJobList}
                    />
                )
            }
            <Modal
                visible={showFailureInfoModal && hasBulkTicketJobFailureInfo}
                contentLabel='Bulk Ticket Job Failure Info Modal'
                modalStyles={bulkTicketJobFailureInfoModalStyles}
            >
                <Table
                    v2
                    header='Failed Tickets List'
                    labelData={BULK_TICKET_JOB_FAILED_TICKET_LIST_LABEL_DATA}
                    contentData={failureList}
                    errLabel={errInFetchingBulkTicketJobList}
                />
                <Button
                    v2
                    extClasses='btu-history-modal__cta'
                    primary
                    label='Close'
                    onClick={() => setFailureInfoModal(false)}
                />
            </Modal>
            <Loader visible={isLoading} />
        </React.Fragment>
    );
};

export default History;

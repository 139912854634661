/**
 * @file Manages the action functions for Bulk Ticket Update component
 */

import {
    SET_UPLOAD_CSV_FORM_PARAMS, RESET_UPLOAD_CSV_FORM_PARAMS, SET_SEARCH_PARAMS, RESET_SEARCH_PARAMS,
    UPDATE_BULK_TICKETS_INFO, GET_BULK_TICKET_JOB_LIST, GET_BULK_TICKET_JOB_FAILURE_INFO, KILL_BULK_TICKET_JOB,
    RESET_LOADER,
} from './constants';

export const setUploadCSVFormParams = (data) => ({
    type: SET_UPLOAD_CSV_FORM_PARAMS,
    data,
});

export const resetUploadCSVFormParams = () => ({
    type: RESET_UPLOAD_CSV_FORM_PARAMS,
});

export const setSearchParams = (data) => ({
    type: SET_SEARCH_PARAMS,
    data,
});

export const resetSearchParams = () => ({
    type: RESET_SEARCH_PARAMS,
});

export const updateBulkTicketsInfo = (data) => ({
    type: UPDATE_BULK_TICKETS_INFO,
    data,
});

export const getBulkTicketJobList = (data) => ({
    type: GET_BULK_TICKET_JOB_LIST,
    data,
});

export const getBulkTicketJobFailureInfo = (data) => ({
    type: GET_BULK_TICKET_JOB_FAILURE_INFO,
    data,
});

export const killBulkTicketJob = (data) => ({
    type: KILL_BULK_TICKET_JOB,
    data,
});

export const resetLoader = () => ({
    type: RESET_LOADER,
});

/**
 * @file Constants for Wealth US stocks flow
 */

import { getInitialDateRange } from '../../utils';

// key to be used for reducers & selectors for wealth us stocks
export const WEALTH_USSTOCKS_KEY = 'wealthUsStocks';

export const ERR_WEALTH_USSTOCKS = `app/${WEALTH_USSTOCKS_KEY}/ERR_WEALTH_USSTOCKS`;
export const ERR_US_STOCKS = `app/${WEALTH_USSTOCKS_KEY}/ERR_US_STOCKS`;

// portfolio detail list
export const GET_PORTFOLIO_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/GET_PORTFOLIO_DETAILS`;
export const SET_PORTFOLIO_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/SET_PORTFOLIO_DETAILS`;

// stock activities
export const GET_ACTIVITIES = `app/${WEALTH_USSTOCKS_KEY}/GET_ACTIVITIES`;
export const SET_ACTIVITIES = `app/${WEALTH_USSTOCKS_KEY}/SET_ACTIVITIES`;
export const RESET_ACTIVITIES = `app/${WEALTH_USSTOCKS_KEY}/RESET_ACTIVITIES`;

// stock activity details
export const GET_ACTIVITY_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/GET_ACTIVITY_DETAILS`;
export const SET_ACTIVITY_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/SET_ACTIVITY_DETAILS`;

// account basic details
export const GET_ACCOUNT_BASIC_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/GET_ACCOUNT_BASIC_DETAILS`;
export const SET_ACCOUNT_BASIC_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/SET_ACCOUNT_BASIC_DETAILS`;

// account stages details
export const GET_ACCOUNT_STAGES_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/GET_ACCOUNT_STAGES_DETAILS`;
export const SET_ACCOUNT_STAGES_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/SET_ACCOUNT_STAGES_DETAILS`;

// search parameters for across us stocks view
export const SET_SEARCH_PARAMS = `app/${WEALTH_USSTOCKS_KEY}/SET_SEARCH_PARAMS`;
export const RESET_SEARCH_PARAMS = `app/${WEALTH_USSTOCKS_KEY}/RESET_SEARCH_PARAMS`;

export const SET_USSTOCKS_VIEW = `app/${WEALTH_USSTOCKS_KEY}/SET_USSTOCKS_VIEW`;

export const VIEW_PORTFOLIO_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/State/VIEW_PORTFOLIO_DETAILS`;
export const VIEW_PORTFOLIO_DETAILS_LIST = `app/${WEALTH_USSTOCKS_KEY}/State/VIEW_PORTFOLIO_DETAILS_LIST`;
export const VIEW_ACTIVITIES_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/State/VIEW_ACTIVITIES_DETAILS`;
export const VIEW_ACTIVITIES_DETAILS_LIST = `app/${WEALTH_USSTOCKS_KEY}/State/VIEW_ACTIVITIES_DETAILS_LIST`;

// stock remittances
export const GET_REMITTANCES = `app/${WEALTH_USSTOCKS_KEY}/GET_REMITTANCES`;
export const SET_REMITTANCES = `app/${WEALTH_USSTOCKS_KEY}/SET_REMITTANCES`;
export const RESET_REMITTANCES = `app/${WEALTH_USSTOCKS_KEY}/RESET_REMITTANCES`;

// stock remittance order details
export const GET_REMITTANCE_ORDER_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/GET_REMITTANCE_ORDER_DETAILS`;
export const SET_REMITTANCE_ORDER_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/SET_REMITTANCE_ORDER_DETAILS`;

// remittance type list
export const GET_REMITTANCE_TYPE_LIST = `app/${WEALTH_USSTOCKS_KEY}/GET_REMITTANCE_TYPE_LIST`;
export const SET_REMITTANCE_TYPE_LIST = `app/${WEALTH_USSTOCKS_KEY}/SET_REMITTANCE_TYPE_LIST`;

// activity type
export const GET_ACTIVITY_TYPE_LIST = `app/${WEALTH_USSTOCKS_KEY}/GET_ACTIVITY_TYPE_LIST`;
export const SET_ACTIVITY_TYPE_LIST = `app/${WEALTH_USSTOCKS_KEY}/SET_ACTIVITY_TYPE_LIST`;
export const SET_ACTIVITY_TYPE = `app/${WEALTH_USSTOCKS_KEY}/SET_ACTIVITY_TYPE`;

// Account Opening Views
export const SET_ACCOUNT_VIEW = `app/${WEALTH_USSTOCKS_KEY}/SET_ACCOUNT_VIEW`;
export const VIEW_ACCOUNT_BASIC_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/State/VIEW_ACCOUNT_BASIC_DETAILS`;
export const VIEW_ACCOUNT_STAGES_DETAILS = `app/${WEALTH_USSTOCKS_KEY}/State/VIEW_ACCOUNT_STAGES_DETAILS`;

// from date will be current date - 30 days
const days = 30;
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - days);

export const INITIAL_DATE_RANGE = getInitialDateRange(null, true, {
    defaultFromDate,
});

const FROM_DATE_INFO = {
    label: 'From Date',
    labelId: 'from-date',
};

const TO_DATE_INFO = {
    label: 'To Date',
    labelId: 'to-date',
};

const DATE_PICKER_CLS = {
    container: ['uss-fc'],
    label: ['uss-dd__lb'],
    inputField: ['uss-dd__ip'],
};

export const DATE_INFO = {
    fromDateInfo: FROM_DATE_INFO,
    toDateInfo: TO_DATE_INFO,
    initialDateRange: INITIAL_DATE_RANGE,
    extClasses: DATE_PICKER_CLS,
};

export const PORTFOLIO_DETAILS_LABEL_DATA = [
    {
        label: 'Asset Name',
        key: 'name',
    },
    {
        label: 'Asset State',
        key: 'state',
    },
    {
        label: 'Asset Type',
        key: 'type',
    },
    {
        label: 'Auto Invest Details',
        key: 'autoInvestDetails',
    },
    {
        label: 'First Transacted At',
        key: 'firstTxnTs',
    },
    {
        label: 'Last Transacted At',
        key: 'lastTxnTs',
    },
];

export const USSSTOCKS_ACTIVITIES = [
    {
        label: 'Activity ID',
        key: 'activityId',
    },
    {
        label: 'Asset Name',
        key: 'assetName',
    },
    {
        label: 'Created At',
        key: 'createdTs',
    },
    {
        label: 'Updated At',
        key: 'updatedTs',
    },
    {
        label: 'Value of Activity',
        key: 'valueOfActivity',
    },
    {
        label: 'Units',
        key: 'units',
    },
    {
        label: 'Activity Type',
        key: 'activityType',
    },
    {
        label: 'Current Status',
        key: 'status',
    },
    {
        label: 'Fx Rate',
        key: 'fxRate',
    },
    {
        label: 'CBS Number',
        key: 'cbs',
    },
    {
        label: 'Fi external Txn Number',
        key: 'fiExtTxnNumber',
    },
    {
        label: 'GST Paid',
        key: 'gstPaid',
    },
    {
        label: 'TCS Paid',
        key: 'tcsPaid',
    },
];

export const USSTOCKS_ACTIVITY_DETAILS = [
    {
        label: 'Timestamp',
        key: 'ts',
    },
    {
        label: 'Timeline Step',
        key: 'timelineStep',
    },
    {
        label: 'Step Status',
        key: 'stepStatus',
    },
    {
        label: 'Internal Status',
        key: 'internalStatus',
    },
    {
        label: 'Failure Reason',
        key: 'failureReason',
    },
    {
        label: 'ETA',
        key: 'eta',
    },
];

export const ACCOUNT_STAGE_DETAILS = [
    {
        label: 'Stage',
        key: 'currentStage',
    },
    {
        label: 'Status',
        key: 'currentStatus',
    },
    {
        label: 'Updated At',
        key: 'updatedTs',
    },
    {
        label: 'Description',
        key: 'description',
    },
    {
        label: 'Meta Information',
        key: 'metaInformation',
    },
];

export const ACCOUNT_BASIC_DETAILS = [
    {
        label: 'Account iD',
        key: 'account_id',
    },
    {
        label: 'Account Stage',
        key: 'account_stage',
    },
    {
        label: 'Account Status',
        key: 'account_status',
    },
];

export const ACCOUNT_BASIC_DETAILS_INFO = [
    {
        label: 'Current Stage',
        key: 'account_stage',
    },
    {
        label: 'Status',
        key: 'account_status',
    },
];

/**
 *
 * @file USSAccountDetails : Show Account opening page.
 *
 */

import React, { memo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getAccountBasicDetailsAction, getAccountStagesDetailsAction } from '../actions';
import { selectUSSAccountBasicDetails, selectUSSAccountStageDetails, selectUSSAccountView } from '../selectors';

import RenderView from './RenderView';

const USSAccountDetails = (props) => {
    const { ticketId, userId } = props;

    const dispatch = useDispatch();

    const accountStageDetails = useSelector(selectUSSAccountStageDetails, shallowEqual);
    const accountBasicDetails = useSelector(selectUSSAccountBasicDetails, shallowEqual);
    const accountView = useSelector(selectUSSAccountView, shallowEqual);

    useEffect(() => {
        const payload = {
            ticketId,
            userId,
        };

        if (!accountBasicDetails) {
            dispatch(getAccountBasicDetailsAction(payload));
        }

        if (!accountStageDetails) {
            dispatch(getAccountStagesDetailsAction(payload));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, accountBasicDetails, accountStageDetails]);

    return (
        <RenderView accountView={accountView} ticketId={ticketId} />
    );
};

export default memo(USSAccountDetails);

/**
 * @file V2 LivenessReview:
 * User can see the existing cases for liveness
 * User can select a case for review
 * For review, user can view the video and fill details to update liveness status for that case
 * Once review is done, user can dequeue the case
 */

import React, { memo } from 'react';

import { RenderView } from '../RiskOps';

import SingleLivenessCase from './SingleCase';
import {
    LIVENESS_REVIEW_KEY as CONTAINER_KEY, QUEUE_PAYLOAD_TYPES,
} from './constants';

import './style.scss';

const LivenessReview = () => (
    <RenderView
        singleCaseComponent={<SingleLivenessCase containerKey={CONTAINER_KEY} />}
        data={{
            containerKey: CONTAINER_KEY,
            queuePayloadTypes: QUEUE_PAYLOAD_TYPES,
            queueName: 'Liveness',
        }}
    />
);

export default memo(LivenessReview);

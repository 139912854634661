/**
 *
 * FormWithSingleInputV2
 *
 */

import React, { memo } from 'react';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';

import Button from '../Button';

import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';


const InputField = props => {
    const { labelId, formType, input: { value, onChange, pattern, disabled, minLength, maxLength,
        showYearDropdown, yearDropdownItemNumber, maxDate, minDate, ref, onKeyDown, type },
        extClasses } = props;

    const inputFieldClass = classNames('input-label', extClasses);

    switch (formType) {
        case 'name': {
            return (
                ['first_name', 'last_name'].map(item => (
                    <input
                        id={labelId}
                        className='input-label--smaller'
                        type='text'
                        value={value[item]}
                        onChange={onChange && onChange(item)}
                        pattern={pattern}
                        disabled={disabled}
                        minLength={minLength}
                        maxLength={maxLength}
                        placeholder={item.split('_').join(' ')}
                    />
                ))
            );
        }

        case 'txn': {
            return (
                <input
                    id={labelId}
                    className='input-label'
                    type='number'
                    value={value.units}
                    onChange={onChange && onChange('units')}
                    pattern={pattern}
                    disabled={disabled}
                    minLength={minLength}
                />
            );
        }

        case 'dob': {
            return (
                <DatePicker
                    placeholderText='Choose Date'
                    className={inputFieldClass}
                    selected={value}
                    onChange={onChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={disabled}
                    showYearDropdown={showYearDropdown}
                    yearDropdownItemNumber={yearDropdownItemNumber}
                    scrollableYearDropdown
                />
            );
        }

        default: {
            return (
                <input
                    id={labelId}
                    className={inputFieldClass}
                    type={type || 'text'}
                    value={value}
                    onChange={onChange}
                    pattern={pattern}
                    disabled={disabled}
                    minLength={minLength}
                    maxLength={maxLength}
                    ref={ref}
                    onKeyDown={onKeyDown}
                />
            );
        }
    }
};

const FormWithSingleInputV2 = props => {
    const { labelId, label, description, formType, input, disableFormSubmitOnEnter,
        cta, errLabel, statusLabel, extClasses, hideErrLabel, onFormSubmit } = props;
    
    let primaryLabel, onPrimaryClick, primaryDisabled, secondaryLabel, onSecondaryClick,
        secondaryDisabled, tertiaryLabel, onTertiaryClick, tertiaryDisabled;
    
    if (cta) {
        ({ primaryLabel, onPrimaryClick, primaryDisabled, secondaryLabel, onSecondaryClick,
            secondaryDisabled, tertiaryLabel, onTertiaryClick, tertiaryDisabled } = cta);
    }

    const containerClass = classNames('v2-form-container', extClasses && extClasses.container);
    const labelClass = classNames('form-wrapper-label', extClasses && extClasses.label);
    const inputFieldClass = extClasses && extClasses.inputField;
    const errLabelClass = classNames('error-label', extClasses && extClasses.errLabel);

    return (
        <div className={containerClass}>
            <form
                className='form-wrapper'
                onSubmit={(e) => {
                    if (disableFormSubmitOnEnter) {
                        e.preventDefault();
                    }

                    if (onFormSubmit) {
                        onFormSubmit(e);
                    }
                }}
            >
                {
                    label ? (
                        <div className={labelClass}>
                            <label htmlFor={labelId}>{label}</label>
                            {description ? <div>{description}</div> : null}
                        </div>
                    ) : null
                }
                <div className='form-input-container'>
                    <InputField formType={formType} input={input} labelId={labelId} extClasses={inputFieldClass} />
                    {!hideErrLabel ? (<div className={errLabelClass}>{errLabel}</div>) : null}
                </div>
                {
                    cta ? (
                        <React.Fragment>
                            <Button
                                primary
                                label={primaryLabel}
                                disabled={primaryDisabled}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onPrimaryClick(e);
                                }}
                            />
                            {
                                secondaryLabel ? (
                                    <Button
                                        secondary
                                        label={secondaryLabel}
                                        disabled={secondaryDisabled}
                                        extClasses='secondary-btn'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSecondaryClick();
                                        }}
                                    />
                                ) : null
                            }
                            {
                                tertiaryLabel ? (
                                    <Button
                                        secondary
                                        label={tertiaryLabel}
                                        disabled={tertiaryDisabled}
                                        extClasses='secondary-btn'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onTertiaryClick();
                                        }}
                                    />
                                ) : null
                            }
                        </React.Fragment>
                    ) : null
                }
                {
                    statusLabel ? (
                        <div className='status-label'>{statusLabel}</div>
                    ) : null
                }
            </form>
        </div>
    );
};

export default memo(FormWithSingleInputV2);

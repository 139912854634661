import {
    FETCH_AUDIT_CASE,
    SET_AUDIT_REPORT,
    SET_RECORDING_URL,
    REVIEW_REPORT,
    SET_SCREEN,
    SET_CASE_COUNT,
    GET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT,
} from './constants';

export const setAuditCases = () => ({
    type: FETCH_AUDIT_CASE,
});

export const setAuditReport = (data) => ({
    type: SET_AUDIT_REPORT,
    data,
});

export const setRecodingUrl = (data) => ({
    type: SET_RECORDING_URL,
    data,
});

export const reviewReport = (data) => ({
    type: REVIEW_REPORT,
    data,
});

export const setScreen = (data) => ({
    type: SET_SCREEN,
    data,
});

export const setCaseCount = (data) => ({
    type: SET_CASE_COUNT,
    data,
});

export const getVkycAvailableReviewCount = () => ({
    type: GET_VKYC_AUDITOR_AVAILABLE_REVIEW_COUNT,
});

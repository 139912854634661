/**
 * @file SalaryAccountOps
 * Landing Page for Salary Account Ops
 * All routes related to Salary Account Ops flow would be listed here
 */

import React, { memo, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'ramda';

import { VerticalNavbar, HorizontalIdNavbar } from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import {
    SALARY_ACCOUNT_VIEW_DETAILS_PATH,
    SALARY_ACCOUNT_VERIFICATION_QUEUE_PATH,
    SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_PATH,
    SALARY_ACCOUNT_DEV_ACTIONS,
    SALARY_ACCOUNT_OPS_BANKING_PROFILE_PATH,
    SALARY_ACCOUNT_OPS_BANKING_SAVINGS_ACCOUNT_PATH,
} from '../App/routes';
import DevActions from '../DevActions';
import UserDetailsWithPhoneOrEmail from '../UserDetailsWithPhoneOrEmail';

import SalaryAccountDetails from './SalaryAccountDetails';
import SalaryVerificationQueue from './SalaryVerificationQueue';
import RaiseVerificationRequest from './RaiseVerificationRequest';
import BankingOps from './BankingOps';

import reducer from './reducer';
import saga from './saga';
import { getSideNavbarItems } from './utils';
import { SALARY_ACCOUNT_OPS_KEY } from './constants';
import { getSalaryOpsEnumsAction, setIdentifierList, setActiveTabIndex, setNewTabActive } from './actions';
import { selectSalaryOpsEnums, selectTabData } from './selectors';

import './style.scss';

const NAV_BAR_ITEMS = getSideNavbarItems();

const SalaryAccountOps = (props) => {
    const { isBankingView } = props;
    const location = useLocation();
    const dispatch = useDispatch();

    const enums = useSelector(selectSalaryOpsEnums, shallowEqual);

    const { identifierList, activeTabIndex, newTabActive } = useSelector(selectTabData, shallowEqual);

    const closeTab = (index) => {
        if (activeTabIndex === index) { // closing active tab
            dispatch(setIdentifierList({ index }));
            if (identifierList.length - 1 === index) { // last tab removing sends a new tab to be created with its current index
                addNewTab();
            } else { // make the next tab active
                setActiveTab(index);
            }
        }
    };

    const setActiveTab = (index) => {
        if (activeTabIndex !== index) {
            dispatch(setActiveTabIndex({ index }));
            if (newTabActive) dispatch(setNewTabActive({ value: false }));
        }
    };

    const addNewTab = () => {
        dispatch(setActiveTabIndex({ index: -1 }));
        dispatch(setNewTabActive({ value: true }));
    };

    const onIdentifierSubmit = (type, value, subValue) => {
        dispatch(setIdentifierList({ index: identifierList.length, data: { type, value, subValue }, isActive: true }));
        dispatch(setNewTabActive({ value: false }));
    };

    useEffect(() => {
        if (isEmpty(enums)) dispatch(getSalaryOpsEnumsAction());
    }, [dispatch, enums]);

    // If CX agents banking view
    if (isBankingView) {
        const { ticketId, userId, index } = props;
        return (
            <BankingOps
                ticketId={ticketId}
                userId={userId}
                index={index}
            />
        );
    }

    return (
        <div className='app-cr'>
            <HorizontalIdNavbar
                activeId={null}
                navItems={identifierList}
                isEmptyNavbar={!(
                    location.pathname === SALARY_ACCOUNT_OPS_BANKING_PROFILE_PATH
                    || location.pathname === SALARY_ACCOUNT_OPS_BANKING_SAVINGS_ACCOUNT_PATH
                )}
                onNewTicketClick={addNewTab}
                isNewTicketActive={newTabActive}
                onItemClick={setActiveTab}
                onItemCloseIconClick={closeTab}
                activeIndex={activeTabIndex}
                newTabText='New Tab'
                extClasses={{
                    container: 'salwhitelist-tabs',
                }}
            />
            <div className='salops-cr'>
                <VerticalNavbar
                    hideHorizontalNavbar
                    navItems={NAV_BAR_ITEMS}
                />
                <div className='salops-cc'>
                    <div className='salops-ci'>
                        <Switch>
                            <Route
                                path={SALARY_ACCOUNT_VIEW_DETAILS_PATH}
                                component={SalaryAccountDetails}
                            />
                            <Route
                                path={SALARY_ACCOUNT_VERIFICATION_QUEUE_PATH}
                                component={SalaryVerificationQueue}
                            />
                            <Route
                                path={SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_PATH}
                                component={RaiseVerificationRequest}
                            />
                            <Route
                                path={SALARY_ACCOUNT_DEV_ACTIONS}
                            >
                                <DevActions hideNav />
                            </Route>
                            <Route
                                path={SALARY_ACCOUNT_OPS_BANKING_PROFILE_PATH}
                            >
                                <UserDetailsWithPhoneOrEmail
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...props}
                                    isProfile
                                    tabData={{ identifierList, activeTabIndex, newTabActive }}
                                    onSubmit={onIdentifierSubmit}
                                />
                            </Route>
                            <Route
                                path={SALARY_ACCOUNT_OPS_BANKING_SAVINGS_ACCOUNT_PATH}
                            >
                                <UserDetailsWithPhoneOrEmail
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...props}
                                    isOnboarding
                                    tabData={{ identifierList, activeTabIndex, newTabActive }}
                                    onSubmit={onIdentifierSubmit}
                                />
                            </Route>
                            {/* in case of unmatched url, redirect the user to View Salary Account Details page */}
                            <Redirect to={{ pathname: SALARY_ACCOUNT_VIEW_DETAILS_PATH }} />
                        </Switch>
                    </div>

                </div>
            </div>
        </div>
    );
};

const withReducer = injectReducer({ key: SALARY_ACCOUNT_OPS_KEY, reducer });
const withSaga = injectSaga({ key: SALARY_ACCOUNT_OPS_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(memo(SalaryAccountOps));

/**
 * @file Manages the selector functions for Bulk Ticket Update component
 */

import { createSelector } from 'reselect';

import { BULK_TICKET_UPDATE_KEY } from './constants';

const selectUploadCSVFormParameters = (state) => state.getIn([BULK_TICKET_UPDATE_KEY, 'uploadCSVFormParams']);
const selectSearchParameters = (state) => state.getIn([BULK_TICKET_UPDATE_KEY, 'searchParams']);
const selectLoading = (state) => state.getIn([BULK_TICKET_UPDATE_KEY, 'isLoading']);
const selectUpdatedBulkTicketJobInfo = (state) => state.getIn([BULK_TICKET_UPDATE_KEY, 'updatedBulkTicketJobInfo']);
const selectBulkTicketJobList = (state) => state.getIn([BULK_TICKET_UPDATE_KEY, 'bulkTicketJobList']);
const selectBulkTicketJobFailureInfo = (state) => state.getIn([BULK_TICKET_UPDATE_KEY, 'bulkTicketJobFailureInfo']);

const makeSelectUploadCSVFormParameters = () => createSelector(
    selectUploadCSVFormParameters,
    (substate) => substate.toJS(),
);

const makeSelectSearchParameters = () => createSelector(
    selectSearchParameters,
    (substate) => substate.toJS(),
);

const makeSelectLoading = () => createSelector(
    selectLoading,
    (substate) => substate,
);

const makeSelectUpdatedBulkTicketJobInfo = () => createSelector(
    selectUpdatedBulkTicketJobInfo,
    (substate) => substate.toJS(),
);

const makeSelectBulkTicketJobList = () => createSelector(
    selectBulkTicketJobList,
    (substate) => substate.toJS(),
);

const makeSelectBulkTicketJobFailureInfo = () => createSelector(
    selectBulkTicketJobFailureInfo,
    (substate) => substate.toJS(),
);

export {
    makeSelectUploadCSVFormParameters,
    makeSelectSearchParameters,
    makeSelectLoading,
    makeSelectUpdatedBulkTicketJobInfo,
    makeSelectBulkTicketJobList,
    makeSelectBulkTicketJobFailureInfo,
};

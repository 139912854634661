/**
 *
 * @file SingleLivenessCase V2
 * Container for displaying a single liveness case
 *
 */

import React, { memo, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedSelector } from '../../../hooks';
import { MetaInfoContext } from '../../../context';

import { SingleCaseHeading, DequeueCase } from '../../RiskOps';
import { getAdditionalFilters, getIdsFromCase } from '../../RiskOps/utils';
import { dequeueRiskOpsCaseAction, setRiskOpsCurViewAction } from '../../RiskOps/actions';
import { selectRiskOpsCurCase, selectRiskOpsPayloadType, selectRiskOpsQueueFilters } from '../../RiskOps/selectors';
import { RISK_OPS_VIEW_ALL_CASES } from '../../RiskOps/constants';

import LivenessCaseInfo from '../CaseInfo';

const SingleLivenessCase = (props) => {
    const { containerKey } = props;
    const metaInfo = useContext(MetaInfoContext);
    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;
    const dispatch = useDispatch();

    const payloadType = useMemoizedSelector(selectRiskOpsPayloadType, [containerKey]);
    const curCase = useMemoizedSelector(selectRiskOpsCurCase, [containerKey]);
    const { fromDate, toDate, sortOption } = useMemoizedSelector(selectRiskOpsQueueFilters, [containerKey]);

    const { actorId, recordId } = getIdsFromCase(curCase);
    const { index } = curCase;

    const onBackArrowCTA = () => {
        dispatch(setRiskOpsCurViewAction({ containerKey, newView: RISK_OPS_VIEW_ALL_CASES }));
    };

    const dequeueAction = dequeueRiskOpsCaseAction({
        containerKey,
        actorId,
        recordId,
        index,
        queryParams: {
            payloadType: payloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        },
        customContext: {
            bannersVersion,
        },
    });

    return (
        <div className='rops-cr'>
            <SingleCaseHeading
                title='Review Liveness Case'
                onBackArrowCTA={onBackArrowCTA}
            />
            <LivenessCaseInfo containerKey={containerKey} />
            <DequeueCase dequeueAction={dequeueAction} />
        </div>
    );
};

export default memo(SingleLivenessCase);

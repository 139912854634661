/**
 * @file CardSettingsScreen: Component to show the Credit Card Settings Details.
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { Loader, Toggle } from '../../../components';
import { useScrollToPosition } from '../../../hooks';

import { updateCreditCardUsageDetailsAction } from '../actions';
import { CARD_USAGE_SETTINGS_LIST, IS_CARD_SETTINGS_SCREEN } from '../constants';
import CardBanner from '../CardBanner';

import './style.scss';

const CardSettingsScreen = (props) => {
    const {
        viewUsageSettings, ticketId, userId, loader,
    } = props;

    useScrollToPosition();

    const dispatch = useDispatch();

    const onToggleUsageSettingsValue = (item) => () => {
        if (!viewUsageSettings[item.key].enabled) return;

        dispatch(updateCreditCardUsageDetailsAction({
            ticketId,
            userId,
            requestBody: {
                controlType: item.key,
                disableControl: viewUsageSettings[item.key].enabled,
            },
        }));
    };

    return (
        <CardBanner title='Credit Card Usage Settings' discardCurrentScreen={IS_CARD_SETTINGS_SCREEN} ticketId={ticketId} containerClass>
            <div>
                {CARD_USAGE_SETTINGS_LIST.map((item) => (
                    <div className='frWrapper credit-card-csc-wr' key={item.key}>
                        <div className='credit-card-csc__hl'>{item.settingLabel}</div>
                        {viewUsageSettings[item.key] ? (
                            <Toggle
                                value={viewUsageSettings[item.key].enabled}
                                onChange={onToggleUsageSettingsValue(item)}
                                label=''
                            />
                        ) : '-'}
                    </div>
                ))}
            </div>
            <Loader visible={loader} />
        </CardBanner>
    );
};

export default memo(CardSettingsScreen);

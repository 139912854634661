/**
 *
 * Liveness Review flow
 * User can see the existing cases for liveness
 * User can select a case for review
 * For review, user can view the video and fill details to update liveness status for that case
 * Once review is done, user can dequeue the case
 *
 */

import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { HorizontalIdNavbar, Loader } from '../../components';
import { injectReducer, injectSaga } from '../../utils';
import { getAdditionalFilters } from '../RiskOps/utils';

import reducer from './reducer';
import saga from './saga';

import AllLivenessCases from './AllLivenessCases';
import SingleLivenessCase from './SingleLivenessCase';

import { getLivenessCasesAction, getLivenessPendingReviewCountAction, setCurLivenessCaseAction, setLivenessViewAction } from './actions';
import {
    selectErr, selectLivenessPayloadType, selectLivenessView, selectLoading, selectLivenessQueueFilters,
} from './selectors';
import { VIEW_ALL_LIVENESS_CASES, VIEW_SINGLE_LIVENESS_CASE } from './constants';

import './style.scss';

const LivenessReview = () => {
    const dispatch = useDispatch();

    // global state
    const loading = useSelector(selectLoading(), shallowEqual);
    const err = useSelector(selectErr(), shallowEqual);
    const livenessView = useSelector(selectLivenessView(), shallowEqual);
    const livenessPayloadType = useSelector(selectLivenessPayloadType(), shallowEqual);
    const { fromDate, toDate, sortOption } = useSelector(selectLivenessQueueFilters(), shallowEqual);

    /**
     * Get liveness cases in following states:
     * 1. when we have empty liveness cases []
     * 2. when the payload type changes
     * 3. When a dequeue action happens, then we need to call with the current payload type
     */
    useEffect(() => {
        dispatch(getLivenessPendingReviewCountAction({ payloadType: livenessPayloadType.value }));

        dispatch(getLivenessCasesAction({
            payloadType: livenessPayloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        }));
    }, [livenessPayloadType.value, dispatch]);

    const handleReviewClick = (livenessData) => () => {
        dispatch(setCurLivenessCaseAction(livenessData));
        dispatch(setLivenessViewAction(VIEW_SINGLE_LIVENESS_CASE));
    };

    const renderView = () => {
        switch (livenessView) {
            case VIEW_ALL_LIVENESS_CASES: {
                return <AllLivenessCases handleReviewClick={handleReviewClick} />;
            }

            case VIEW_SINGLE_LIVENESS_CASE: {
                return <SingleLivenessCase />;
            }

            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            <HorizontalIdNavbar isFixed isEmptyNavbar />
            {renderView()}

            <div className='lreview-cr'>
                {err && <div className='lreview-crel err-label'>{err}</div>}
            </div>

            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'livenessReview', reducer });
const withSaga = injectSaga({ key: 'livenessReview', saga });

export default compose(
    withReducer,
    withSaga,
)(LivenessReview);

import { createSelector } from 'reselect';

import { SHERLOCK_SCRIPTS_KEY, TICKETS_KEY } from './constants';

const makeSelectLoading = () => createSelector(
    (state) => state.getIn([TICKETS_KEY, 'loading']),
    (substate) => substate.toJS(),
);

const makeSelectTickets = () => createSelector(
    (state) => state.getIn([TICKETS_KEY, 'ticketList']),
    (substate) => substate.toJS(),
);

const makeSelectCallList = () => createSelector(
    (state) => state.getIn([TICKETS_KEY, 'callList']),
    (substate) => substate.toJS(),
);

const makeSelectTicketListForCall = () => createSelector(
    (state) => state.getIn([TICKETS_KEY, 'ticketListForCall']),
    (substate) => substate.toJS(),
);

const makeSelectSelectedCallInfo = () => createSelector(
    (state) => state.getIn([TICKETS_KEY, 'selectedCallInfo']),
    (substate) => substate.toJS(),
);

const makeSelectUpdatedCallInfo = () => createSelector(
    (state) => state.getIn([TICKETS_KEY, 'updatedCallInfo']),
    (substate) => substate.toJS(),
);

const makeSelectCreatedTicketInfoForCall = () => createSelector(
    (state) => state.getIn([TICKETS_KEY, 'createdTicketInfoForCall']),
    (substate) => substate.toJS(),
);

const makeSelectCustomerAuth = (index) => createSelector(
    (state) => state.getIn([TICKETS_KEY, 'ticketList', index, 'customerAuth']),
    (substate) => substate?.toJS(),
);

const makeSelectScriptsSearchResults = () => createSelector(
    (state) => state.getIn([TICKETS_KEY, SHERLOCK_SCRIPTS_KEY, 'searchResults']),
    (substate) => substate?.toJS(),
);

export {
    makeSelectLoading,
    makeSelectTickets,
    makeSelectCallList,
    makeSelectTicketListForCall,
    makeSelectSelectedCallInfo,
    makeSelectUpdatedCallInfo,
    makeSelectCreatedTicketInfoForCall,
    makeSelectCustomerAuth,
    makeSelectScriptsSearchResults,
};

import { GET_BULK_USER_INFO, RESET_BULK_USER_INFO } from './constants';

export const getBulkUserInfo = (data) => ({
    type: GET_BULK_USER_INFO,
    data,
});

export const resetBulkUserInfo = () => ({
    type: RESET_BULK_USER_INFO,
});

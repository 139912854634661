import { createSelector } from 'reselect';

// const selectBUD = state => state.get('bulkUserDetailsByTicketIds');
const selectUserInfo = state => state.getIn(['bulkUserDetailsByTicketIds', 'userInfo']);

const makeSelectBulkUserInfo = () => createSelector(
    selectUserInfo,
    userInfo => userInfo.toJS(),
);

export {
    makeSelectBulkUserInfo,
};

// Container key
export const CONTAINER_KEY = 'Txns';

// transactions
export const GET_TXNS_INFO = 'app/Txns/GET_TXNS_INFO';
export const SET_TXNS_INFO = 'app/Txns/SET_TXNS_INFO';
export const ERR_TXNS_INFO = 'app/Txns/ERR_TXNS_INFO';

// first or last set of transactions
export const GET_FIRST_OR_LAST_SET_TXN_LIST = 'app/Txns/GET_FIRST_OR_LAST_SET_TXN_LIST';
export const SET_FIRST_OR_LAST_SET_TXN_LIST = 'app/Txns/SET_FIRST_OR_LAST_SET_TXN_LIST';
export const ERR_FIRST_OR_LAST_SET_TXN_LIST = 'app/Txns/ERR_FIRST_OR_LAST_SET_TXN_LIST';

// channels
export const GET_CHANNELS_INFO = 'app/Txns/GET_CHANNELS_INFO';
export const SET_CHANNELS_INFO = 'app/Txns/SET_CHANNELS_INFO';
export const ERR_CHANNELS_INFO = 'app/Txns/ERR_CHANNELS_INFO';
// questions
export const GET_QUESTIONS_INFO = 'app/Txns/GET_QUESTIONS_INFO';
export const SET_QUESTIONS_INFO = 'app/Txns/SET_QUESTIONS_INFO';
export const MODIFY_QUESTIONS_INFO = 'app/Txns/MODIFY_QUESTIONS_INFO';
export const ERR_QUESTIONS_INFO = 'app/Txns/ERR_QUESTIONS_INFO';
export const GET_CREDIT_CARD_QUESTIONS_INFO = `app/${CONTAINER_KEY}/GET_CREDIT_CARD_QUESTIONS_INFO`;
// dispute
export const INITIATE_RAISE_DISPUTE = 'app/Txns/INITIATE_RAISE_DISPUTE';
export const SUCCESS_RAISE_DISPUTE = 'app/Txns/SUCCESS_RAISE_DISPUTE';
export const ERR_RAISE_DISPUTE = 'app/Txns/ERR_RAISE_DISPUTE';
// reset state
export const RESET_STATE = 'app/Txns/RESET_STATE';
// dispute info
export const GET_DISPUTE_INFO = 'app/Txns/GET_DISPUTE_INFO';
export const SET_DISPUTE_INFO = 'app/Txns/SET_DISPUTE_INFO';
export const ERR_DISPUTE_INFO = 'app/Txns/ERR_DISPUTE_INFO';
// search parameters
export const SET_SEARCH_PARAMS = 'app/Txns/SET_SEARCH_PARAMS';
export const RESET_SEARCH_PARAMS = 'app/Txns/RESET_SEARCH_PARAMS';

// order status list
export const GET_ORDER_STATUS_LIST = 'app/Txns/GET_ORDER_STATUS_LIST';
export const SET_ORDER_STATUS_LIST = 'app/Txns/SET_ORDER_STATUS_LIST';

// payment protocol list
export const GET_PAYMENT_PROTOCOL_LIST = 'app/Txns/GET_PAYMENT_PROTOCOL_LIST';
export const SET_PAYMENT_PROTOCOL_LIST = 'app/Txns/SET_PAYMENT_PROTOCOL_LIST';

// credit card txn list
export const GET_CREDIT_CARD_TXNS_INFO = `app/${CONTAINER_KEY}/GET_CREDIT_CARD_TXNS_INFO`;

export const GET_FIRST_OR_LAST_SET_CREDIT_CARD_TXN_LIST = `app/${CONTAINER_KEY}/GET_FIRST_OR_LAST_SET_CREDIT_CARD_TXN_LIST`;

export const INITIATE_RAISE_DISPUTE_FOR_CREDIT_CARD_TXN = `app/${CONTAINER_KEY}/INITIATE_RAISE_DISPUTE_FOR_CREDIT_CARD_TXN`;

export const TXNS_VIEW_SEARCH_PARAMS_KEYS = {
    SEARCH_BY: 'searchBy',
};

export const SEARCH_BY_MAP = {
    FI_TXN_ID: 'Fi Transaction ID',
    TXN_AMOUNT: 'Transaction Amount',
    PARTIAL_UTR: 'Partial UTR',
    FULL_UTR: 'Full UTR',
    // PAYMENT_PROTOCOL: 'Payment Protocol',
};

export const CREDIT_CARD_SEARCH_BY_MAP = {
    FI_TXN_ID: 'Fi Transaction ID',
    TXN_AMOUNT: 'Transaction Amount',
};

// key to be used for reducers & selectors for Transactions
export const TRANSACTIONS_KEY = 'transactions';

export const ORDER_STATUS = [
    {
        label: 'Created',
        value: 'CREATED',
    },
    {
        label: 'In Payment',
        value: 'IN_PAYMENT',
    },
    {
        label: 'Paid',
        value: 'PAID',
    },
    {
        label: 'Payment Failed',
        value: 'PAYMENT_FAILED',
    },
    {
        label: 'In Fulfillment',
        value: 'IN_FULFILLMENT',
    },
    {
        label: 'Fulfilled',
        value: 'FULFILLED',
    },
    {
        label: 'Fulfillment Failed',
        value: 'FULFILLMENT_FAILED',
    },
    {
        label: 'In Settlement',
        value: 'IN_SETTLEMENT',
    },
    {
        label: 'Settled',
        value: 'SETTLED',
    },
    {
        label: 'Settlement Failed',
        value: 'SETTLEMENT_FAILED',
    },
    {
        label: 'Manual Intervention',
        value: 'MANUAL_INTERVENTION',
    },
    {
        label: 'Collect In Progress',
        value: 'COLLECT_IN_PROGRESS',
    },
    {
        label: 'Collect Dismissed By Payer',
        value: 'COLLECT_DISMISSED_BY_PAYER',
    },
    {
        label: 'Collect Dismissed By Payee',
        value: 'COLLECT_DISMISSED_BY_PAYEE',
    },
    {
        label: 'Collect Registered',
        value: 'COLLECT_REGISTERED',
    },
    {
        label: 'Collect Failed',
        value: 'COLLECT_FAILED',
    },
    {
        label: 'Expired',
        value: 'EXPIRED',
    },
];

export const OPTIONS_LIST = [
    'AUTHORISED',
    'UNAUTHORISED',
];

export const CARD_ACTION_TYPE = {
    CARD_ACTION_TYPE_PROCESS_DISPUTE: 'CARD_ACTION_TYPE_PROCESS_DISPUTE',
};

export const DISPUTE_TYPE = {
    DISPUTE_TYPE_TRANSACTION: 'DISPUTE_TYPE_TRANSACTION',
};

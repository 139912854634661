/**
 *
 * ConnectedAccounts
 *
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

import { CONNECTED_ACCOUNTS_TICKET_PATH } from '../App/routes';

import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';

import Accounts from './Accounts';

import reducer from './reducer';
import saga from './saga';

const ConnectedAccounts = (props) => {
    const getCommonProps = () => {
        const { ticketId, index, userId, phoneNumber, name } = props;

        return {
            index,
            ticketId,
            userId,
            phoneNumber,
            name,
        };
    }

    return (
        <React.Fragment>
            <Switch>
                <Route
                    path={CONNECTED_ACCOUNTS_TICKET_PATH}
                    render={() => (<Accounts {...getCommonProps()} />)}
                />
            </Switch>
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'connectedAccounts', reducer });
const withSaga = injectSaga({ key: 'connectedAccounts', saga });

export default compose(
    withReducer,
    withSaga,
)(ConnectedAccounts);

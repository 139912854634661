/**
 * @file Manages the action functions for Pre Approved Loans component
 */

import {
    GET_USER_INFO, GET_LOANS_INFO, GET_LAMF_USER_INFO, GET_LAMF_LOANS_INFO, GET_FORECLOSURE_AMOUNT,
    GET_LAMF_LOANS_ADDITIONAL_INFO,
} from './constants';

export const getUserInfo = (data) => ({
    type: GET_USER_INFO,
    data,
});

export const getLoansInfo = (data) => ({
    type: GET_LOANS_INFO,
    data,
});

export const getForeclosureAmount = (data) => ({
    type: GET_FORECLOSURE_AMOUNT,
    data,
});

export const getLAMFUserInfo = (data) => ({
    type: GET_LAMF_USER_INFO,
    data,
});

export const getLAMFLoansInfo = (data) => ({
    type: GET_LAMF_LOANS_INFO,
    data,
});

export const getLAMFLoansAdditionalInfo = (data) => ({
    type: GET_LAMF_LOANS_ADDITIONAL_INFO,
    data,
});

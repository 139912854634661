/**
 * @file Constants for account ops submit CSV details component
 */

// main container key
export const ACCOUNT_OPS_KEY = 'accountOps';

// individual tab keys
export const BULK_ACCOUNT_VERIFICATION_KEY = 'bulkAccountVerification';
export const UPLOAD_BALANCES_KEY = 'uploadBalances';
export const BALANCE_TRANSFER_KEY = 'balanceTransfer';

// ui actions
export const SET_UPLOAD_CSV_FORM_PARAMS = `app/${BULK_ACCOUNT_VERIFICATION_KEY}/SET_UPLOAD_CSV_FORM_PARAMS`;
export const SET_SUCCESS_MSG = `app/${BULK_ACCOUNT_VERIFICATION_KEY}/SET_SUCCESS_MSG`;
export const SET_UI_ERROR_MSG = `app/${BULK_ACCOUNT_VERIFICATION_KEY}/SET_UI_ERROR_MSG`;

// api actions
export const VERIFY_BULK_ACCOUNTS_INFO = `app/${BULK_ACCOUNT_VERIFICATION_KEY}/VERIFY_BULK_ACCOUNTS_INFO`;
export const END_VERIFY_BULK_ACCOUNTS_INFO = `app/${BULK_ACCOUNT_VERIFICATION_KEY}/END_VERIFY_BULK_ACCOUNTS_INFO`;

export const UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS = `app/${UPLOAD_BALANCES_KEY}/UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS`;
export const END_UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS = `app/${UPLOAD_BALANCES_KEY}/END_UPLOAD_BALANCES_OF_CLOSE_ACCOUNTS`;

export const UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS = `app/${BALANCE_TRANSFER_KEY}/UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS`;
export const END_UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS = `app/${BALANCE_TRANSFER_KEY}/END_UPLOAD_SUCCESSFUL_BALANCE_TRANSFERS`;

// ui constants
export const CONTAINER_KEY_MAP = {
    BULK_ACCOUNT_VERIFICATION_KEY,
    UPLOAD_BALANCES_KEY,
    BALANCE_TRANSFER_KEY,
};

export const REDUCER_KEYS = {
    uploadCSVFormParams: 'uploadCSVFormParams',
    loading: 'loading',
    successMsg: 'successMsg',
    error: 'error',
};

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { FEDERAL_ROOT } from '../../api/routes';

import {
    GET_TICKET_LIST, SET_TICKET_LIST, ERR_TICKET_LIST, GET_TICKET_INFO, SET_TICKET_INFO,
    ERR_TICKET_INFO, GET_TICKET_GROUPS, SET_TICKET_GROUPS, ERR_TICKET_GROUPS,
    UPDATE_TICKET_GROUP, SET_UPDATE_TICKET_GROUP_FLAG,
} from './constants';

function* getTicketList(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${FEDERAL_ROOT}/ticket-list?${queryString}`
        );

        yield put({ type: SET_TICKET_LIST, data: response });
    } catch (e) {
        const err = getErrLabel(e);

        if (e.noResults) { // no results found
            yield put({ type: SET_TICKET_LIST, data: { ticketList: [], prev: {}, next: {} } });
        } else {
            toastify(err, 'error');
            yield put({ type: ERR_TICKET_LIST });
        }
    }
}

function* getTicketNotes(action) {
    const { ticket } = action.data;
    const { ticketId } = ticket;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${FEDERAL_ROOT}/ticket-notes/${ticketId}`
        );

        yield put({
            type: SET_TICKET_INFO,
            data: {
                ...ticket,
                ...response,
            }   
        });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TICKET_INFO });
    }
}

function* getAllTicketGroups() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${FEDERAL_ROOT}/ticket-groups`
        );

        yield put({ type: SET_TICKET_GROUPS, data: response });
    } catch (e) {
        yield put({ type: ERR_TICKET_GROUPS });
    }
}

function* updateTicketGroup(action) {
    const { ticket, group, reason, resolve } = action.data;
    const { ticketId } = ticket;

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${FEDERAL_ROOT}/update-group/${ticketId}`,
            { group, reason },
        );

        toastify('Ticket updated successfully!', 'success');
        yield call(resolve);

        if (group) {
            // need to return back to ticket list if user updates group 
            yield put({
                type: GET_TICKET_LIST,
                data: {}
            });
        } else {
            // need to update the ticket info
            yield put({
                type: GET_TICKET_INFO,
                data: { ticket }
            });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_UPDATE_TICKET_GROUP_FLAG, data: { flag: false } });
    }
}

export default function* federalAgentsSaga() {
    yield all(
        [
            yield takeLatest(GET_TICKET_LIST, getTicketList),
            yield takeLatest(GET_TICKET_INFO, getTicketNotes),
            yield takeLatest(GET_TICKET_GROUPS, getAllTicketGroups),
            yield takeLatest(UPDATE_TICKET_GROUP, updateTicketGroup),
        ]
    );
}

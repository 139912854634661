import React, { useState, useEffect, useMemo } from 'react';
import { ExpandableCard } from '../../components';
import DocViewer from './DocViewer';
import { caseConstants } from './constant';

const findFileTypeFromUrl = (stringValue) => {
    if (stringValue) {
        if (stringValue.match(caseConstants.pdfRegex)) {
            return caseConstants.PDF;
        } if (stringValue.match(caseConstants.imageRegex)) {
            return caseConstants.IMAGE;
        }
    }
    return null;
};

const Case = (props) => {
    const { section = [] } = props;
    const [caseData, setCaseData] = useState({});

    const formatContentSection = useMemo(() => {
        const sectionData = section.map((item) => {
            const {
                question_element: QuestionElement,
                response_element: responseElement,
            } = item;

            const question = {};

            if (QuestionElement) {
                const { text_element: { text, style } } = QuestionElement;
                question[caseConstants.QUESTION_TITLE] = text;
                question[caseConstants.STYLE] = style;
            }

            if (responseElement) {
                const { text_elements: textElements, file } = responseElement || { };

                if (textElements) {
                    const { elements = [] } = textElements;
                    question[caseConstants.TYPE] = caseConstants.TEXT;
                    question[caseConstants.RESPONSE] = elements;
                } else if (file) {
                    const { url } = file;
                    question[caseConstants.TYPE] = caseConstants.FILE;
                    question[caseConstants.RESPONSE] = { url, fileType: findFileTypeFromUrl(url) };
                }
            }

            return question;
        });
        return sectionData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section]);

    useEffect(() => {
        if (Array.isArray(section)) setCaseData(formatContentSection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getResponse = (questionType, response) => {
        switch (questionType) {
            case caseConstants.TEXT: {
                return (
                    <p className='user-case-review-question-container-response'>
                        <ul>
                            {
                                response.map((item) => <li key={item.text}>{item.text}</li>)
                            }
                        </ul>

                    </p>
                );
            }

            case caseConstants.FILE: {
                const { fileType, url } = response || { fileType: null, url: '' };

                return (
                    <DocViewer
                        btnClassname='user-case-review-question-container-response-file'
                        type={fileType}
                        url={url}
                    />
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className='case-container'>
            <div className='body'>

                {
                    Array.isArray(caseData) && caseData.map((item) => (
                        <div className='user-case-review-question-container'>
                            <div className='user-case-review-question-container-title'>{item && item.questionTitle}</div>

                            {
                                item && getResponse(item.type, item.response)
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

const ReviewCases = (props) => {
    const { cases = [] } = props;

    const [visibilityMap, setVisibility] = useState({});

    useEffect(() => {
        const map = {};
        cases.forEach((item, index) => {
            map[index] = false;
        });
        setVisibility(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleAccordion = (id) => {
        setVisibility((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const headerData = (head) => {
        const { data_values: dataValues } = head || { data_values: [] };
        return (
            <div className='user-case-review-header'>
                {
                    Array.isArray(dataValues) && dataValues.map((item) => {
                        const { label_element: labelElement, value_element: valueElement } = item;

                        return (
                            <div className='user-case-review-header-col'>
                                { labelElement && <span className='user-case-review-header-title'>{labelElement.text}</span>}
                                { valueElement && <span className='user-case-review-header-title'>{valueElement.text}</span>}
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    return (
        <div className='user-case-review-outer-container'>
            {cases.map((item, index) => (
                <ExpandableCard
                    extClasses='user-case-review-expand-card mb-30'
                    isExpanded={visibilityMap[index]}
                    setIsExpanded={() => toggleAccordion(index)}
                    heading={headerData(item.header)}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                >
                    {
                        visibilityMap[index]
                            && (
                                <Case
                                    section={item.sections}
                                    header={item.header}
                                />
                            )
                    }
                </ExpandableCard>
            ))}
        </div>
    );
};

export default ReviewCases;

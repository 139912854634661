/**
 * @file Manages the utility functions for Review Item Detail component
 */

const renderReviewItemDetail = (data) => ([
    {
        label: 'Name',
        value: data.name,
    },
    {
        label: 'Pan Number',
        value: data.pan_number,
    },
    {
        label: 'DOB',
        value: `${data.dob.day}/${data.dob.month}/${data.dob.year}`,
    },
    {
        label: 'Facematch Score',
        value: data.face_match_score,
    },
    {
        label: 'OCR Score',
        value: data.ocr_score,
    },
]);

export {
    renderReviewItemDetail,
};

/**
 * @file AuthScreen: Component to show the details of the auth screen
 */

import React, { memo } from 'react';

import UserAuth from '../../Tickets/UserAuth';
import { SENSITIVE_ACTIONS } from '../../Tickets/constants';

import { CARD_ACTION_TYPE } from '../constants';

const AuthScreen = (props) => {
    const {
        isSuspendCreditPending, isBlockCreditPending, handleCardAction, index,
    } = props;

    let label;
    let type;
    let nextAction;
    let warningLabel;

    if (isSuspendCreditPending) {
        label = 'Suspend Credit Card';
        type = SENSITIVE_ACTIONS.SUSPENDCREDIT;
        nextAction = () => handleCardAction(CARD_ACTION_TYPE.SUSPEND_CARD);
    } else if (isBlockCreditPending) {
        label = 'Block Credit Card';
        type = SENSITIVE_ACTIONS.BLOCKCREDIT;
        nextAction = () => handleCardAction(CARD_ACTION_TYPE.BLOCK_CARD);
        warningLabel = 'Since required authorisation level could not be passed, card can only be suspended.'
                       + ' You can go back and choose the Suspend Card option.';
    }

    return (
        <UserAuth label={label} type={type} index={index} nextAction={nextAction} warningLabel={warningLabel} />
    );
};

export default memo(AuthScreen);

/**
 * @file SalaryWhitelistB2B
 * Landing Page for Salary Whitelist B2B
 * All routes related to Salary Whitelist B2B flow would be listed here
 */

import React, { memo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import { VerticalNavbar, HorizontalIdNavbar } from '../../components';
import { injectReducer } from '../../utils';

import {
    B2B_ONBOARDING_PATH,
    SALARY_WHITELIST_B2B_BANKING_PROFILE_PATH,
    SALARY_WHITELIST_B2B_BANKING_SAVINGS_ACCOUNT_PATH,
} from '../App/routes';
import UserDetailsWithPhoneOrEmail from '../UserDetailsWithPhoneOrEmail';

import { getSideNavbarItems } from './utils';
import { setIdentifierList, setActiveTabIndex, setNewTabActive } from './actions';
import { selectTabData } from './selectors';
import { SALARY_WHITELIST_B2B_KEY } from './constants';
import B2BOnboarding from './B2BOnboarding';

import reducer from './reducer';
import './style.scss';

/* Commenting for Future Use */
// import saga from './saga';

const NAV_BAR_ITEMS = getSideNavbarItems();

const SalaryWhitelistB2B = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { identifierList, activeTabIndex, newTabActive } = useSelector(selectTabData, shallowEqual);

    const closeTab = (index) => {
        if (activeTabIndex === index) { // closing active tab
            dispatch(setIdentifierList({ index }));
            if (identifierList.length - 1 === index) { // last tab removing sends a new tab to be created with its current index
                addNewTab();
            } else { // make the next tab active
                setActiveTab(index);
            }
        }
    };

    const setActiveTab = (index) => {
        if (activeTabIndex !== index) {
            dispatch(setActiveTabIndex({ index }));
            if (newTabActive) dispatch(setNewTabActive({ value: false }));
        }
    };

    const addNewTab = () => {
        dispatch(setActiveTabIndex({ index: -1 }));
        dispatch(setNewTabActive({ value: true }));
    };

    const onIdentifierSubmit = (type, value, subValue) => {
        dispatch(setIdentifierList({ index: identifierList.length, data: { type, value, subValue }, isActive: true }));
        dispatch(setNewTabActive({ value: false }));
    };

    return (
        <div className='app-cr'>
            <HorizontalIdNavbar
                activeId={null}
                navItems={identifierList}
                isEmptyNavbar={location.pathname === B2B_ONBOARDING_PATH}
                onNewTicketClick={addNewTab}
                isNewTicketActive={newTabActive}
                onItemClick={setActiveTab}
                onItemCloseIconClick={closeTab}
                activeIndex={activeTabIndex}
                newTabText='New Tab'
                extClasses={{
                    container: 'salwhitelist-tabs',
                }}
            />
            <div className='salwhitelist-cr fcWrapper'>
                <VerticalNavbar
                    hideHorizontalNavbar
                    navItems={NAV_BAR_ITEMS}
                />
                <div className='salwhitelist-cc'>
                    <Switch>
                        <Route
                            path={B2B_ONBOARDING_PATH}
                            component={B2BOnboarding}
                        />
                        <Route
                            path={SALARY_WHITELIST_B2B_BANKING_PROFILE_PATH}
                        >
                            <UserDetailsWithPhoneOrEmail
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...props}
                                isProfile
                                tabData={{ identifierList, activeTabIndex, newTabActive }}
                                onSubmit={onIdentifierSubmit}
                            />
                        </Route>
                        <Route
                            path={SALARY_WHITELIST_B2B_BANKING_SAVINGS_ACCOUNT_PATH}
                        >
                            <UserDetailsWithPhoneOrEmail
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...props}
                                isOnboarding
                                tabData={{ identifierList, activeTabIndex, newTabActive }}
                                onSubmit={onIdentifierSubmit}
                            />
                        </Route>
                        {/* in case of unmatched url, redirect the user to View Salary Account Details page */}
                        <Redirect to={{ pathname: B2B_ONBOARDING_PATH }} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

const withReducer = injectReducer({ key: SALARY_WHITELIST_B2B_KEY, reducer });
// const withSaga = injectSaga({ key: SALARY_WHITELIST_B2B_KEY, saga });

export default compose(
    withReducer,
)(memo(SalaryWhitelistB2B));

/**
 * @file RecentActivity/index.js - Contains the whole layout and view for recent activity tab
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import Modal from 'react-modal';
import {
    Button, DropdownContent, DropdownWrapper, ExpandableCard, FormDropdown, FormWithSingleInput, Loader, Table,
} from '../../components';
import {
    clientApiWrapper, getErrMsg, getQueryStringFromObject, injectReducer, injectSaga, showErrorToastMsg,
} from '../../utils';
import { GET_RECENT_ACTIVITY_DROPDOWN_OPTIONS_ENDPOINT } from '../../api/routes';
import { attachEntityToTicket } from '../Tickets/actions';
import { DEFAULT_TICKET_PATH } from '../App/routes';

import { clearRecentActivities, getRecentActivities, getRecentActivityDetails } from './actions';
import { selectRecentActivityData } from './selectors';
import {
    makeSelectQuestionList,
    makeSelectTxnDisputeInfo, makeSelectDisputeInfo,
} from '../Transactions/selectors';
import reducer from './reducer';
import saga from './saga';
import transactionsReducer from '../Transactions/reducer';
import transactionsSaga from '../Transactions/saga';

import './style.scss';
import { EntityType, defaultDropdownOption } from './constants';
import {
    getChannelsInfo,
    getDisputeInfoAction,
    getOrderStatusList,
    getPaymentProtocolList, raiseDisputeForCreditCardTxnAction, raiseTxnDispute, resetState, setDisputeInfo,
    // getQuestionsInfo,
} from '../Transactions/actions';
import DisputeQuestionnaire from '../Transactions/DisputeQuestionnaire';
import { CARD_ACTION_TYPE, DISPUTE_TYPE } from '../Transactions/constants';
import { RudderEvent, pushRudderEvent } from '../../rudderEvents';
import { makeSelectAgentInfo } from '../AppV2/selectors';

// import Transactions from '../Transactions';

const RecentActivity = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { ticketId, userId, index } = props;
    const [formErrLabel, setFormErrLabel] = useState();
    const [isLoading, setIsLoading] = useState({});
    const [dropdownOption, setDropdownOption] = useState();
    const [selectedActivity, setSelectedActivity] = useState({});
    const [disputeActivity, setDisputeActivity] = useState({});
    const [showActivityDetailsModal, setShowActivityDetailsModal] = useState(false);
    const [channel, setChannel] = useState('');
    const [channelOption, setChannelOption] = useState('');
    const [disputeAmount, setDisputeAmount] = useState('');
    const [showDisputeModal, setShowDisputeModal] = useState(false);
    const [showDisputeDetailsModal, setShowDisputeDetailsModal] = useState(false);

    /**
     * Currently the value of dropdown Options is given by backend in an API call but
     * default option is not given hence it is written in constants right now.
     */
    const [selectedAreas, setSelectedAreas] = useState(defaultDropdownOption);
    const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 20)));
    const [toDate, setToDate] = useState(new Date());
    const selectedDisputeInfo = useSelector(makeSelectTxnDisputeInfo, shallowEqual);
    const disputeInfo = useSelector(makeSelectDisputeInfo, shallowEqual);
    const questionList = useSelector(makeSelectQuestionList, shallowEqual);
    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);

    let tableData;
    let nextToken = null;
    let prevToken = null;
    let fetchDataLoading;
    let err;
    let authFail;
    let recentActivityDetails;

    const recentActivityData = useSelector(selectRecentActivityData, shallowEqual);
    if (recentActivityData && recentActivityData[ticketId]) {
        ({
            data: tableData, next: nextToken, prev: prevToken, loading: fetchDataLoading, err, authFail, recentActivityDetails,
        } = recentActivityData[ticketId]);
    }

    const [isRecentActivityCardOpen, setIsRecentActivityCardOpen] = useState(true);

    const clearAllSelection = () => {
        pushRudderEvent(RudderEvent.RecentActivity.ClickedClearAllSherlockRecentActivity, agentInfo.emailId, {
            ticketId,
        });
        setSelectedAreas(null);
        setFromDate();
        setToDate();
        dispatch(clearRecentActivities({
            ticketId, activityDetails: null, prev: null, next: null,
        }));
    };

    const fetchRecentActivity = () => {
        pushRudderEvent(RudderEvent.RecentActivity.SearchedSherlockRecentActivity, agentInfo.emailId, {
            ticketId,
            fromDate,
            toDate,
            selectedAreas,
        });

        if (!fromDate || !toDate || !selectedAreas || selectedAreas.length < 1) {
            setFormErrLabel('Enter all required fields marked with *');
            dispatch(clearRecentActivities({
                ticketId, activityDetails: null, prev: null, next: null,
            }));
            return;
        }
        setFormErrLabel('');
        dispatch(getRecentActivities({
            ticketId, userId, index, fromDate, toDate, selectedAreas, prevToken: null, nextToken: null,
        }));
    };

    const fetchDropdownOptions = async () => {
        try {
            const payload = {
                ticketId,
                userId,
                index,
            };

            const queryString = getQueryStringFromObject(payload);

            setIsLoading(true);
            setFormErrLabel('');

            const response = await clientApiWrapper.get(`${GET_RECENT_ACTIVITY_DROPDOWN_OPTIONS_ENDPOINT}?${queryString}`);

            setDropdownOption(response?.areas);
        } catch (error) {
            showErrorToastMsg(error);
            const errorMsg = getErrMsg(error);
            setFormErrLabel(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChannelChange = (value) => {
        setChannel(value);
        setChannelOption('');
    };

    const handleChannelOptionChange = (value) => {
        setChannelOption(value);
    };

    // Generalizing date change function to have end date once added in future
    const handleDateChange = (key) => (value) => {
        switch (key) {
            case 'fromDate': setFromDate(value);
                break;
            case 'toDate': setToDate(value);
                break;
            default: break;
        }
    };

    useEffect(() => {
        // Rudder Event for Opening Recent Activity
        pushRudderEvent(RudderEvent.RecentActivity.LandedOnSherlockRecentActivity, agentInfo.emailId, { ticketId });

        fetchDropdownOptions();
        dispatch(getChannelsInfo({ ticketId, userId, index }));
        dispatch(getPaymentProtocolList());
        dispatch(getOrderStatusList());
    }, []);

    useEffect(() => {
        fetchRecentActivity();
    }, [userId, index]);

    const renderErrorField = () => {
        if (formErrLabel) {
            return (
                <div className='recent-activity__errlb'>{formErrLabel}</div>
            );
        }
        return <div />;
    };

    const handleMoreOptionsClick = (data) => {
        resetState({ ticketId });
        setSelectedActivity(data);
    };

    const fetchDisputeInfo = (data) => {
        setShowDisputeDetailsModal(!showDisputeDetailsModal);
        setSelectedActivity({});
        const payload = {
            ticketId,
            userId,
            index,
            txnId: data?.internalTransactionIdKey?.value,
        };

        if (isCreditCardTransaction(data)) {
            dispatch(getDisputeInfoAction({ ...payload, creditCardDisputeInfo: true }));
        } else { // must be credit card
            dispatch(getDisputeInfoAction(payload));
        }
    };

    const isCreditCardTransaction = (data) => data?.area && data?.area?.value !== 'Transaction';

    const raiseTxnDisputeMethod = (data) => {
        setSelectedActivity({});

        const callDisputeInfoAction = () => new Promise((resolve, reject) => {
            try {
                const payload = {
                    ticketId,
                    userId,
                    index,
                    txnId: data?.internalTransactionIdKey?.value,
                    reject,
                };

                if (isCreditCardTransaction(data)) {
                    dispatch(getDisputeInfoAction({ ...payload, creditCardDisputeInfo: true }));
                } else {
                    dispatch(getDisputeInfoAction(payload));
                }
                setDisputeActivity(data);
                setTimeout(() => {
                    setShowDisputeModal(true);
                }, 1500);
            } catch (e) {
                reject(e);
            }
        });

        callDisputeInfoAction()
            .catch(() => {
                // resetDisputeDetails();
            });
    };

    const resetDisputeDetails = () => {
        // reset the dispute values here
        setChannelOption('');
        setChannel('');
        setDisputeAmount('');
        setDisputeInfo({
            ticketId,
            disputeInfo: {},
            hasDisputeInfo: false,
        });
    };

    const onDisputeAmountChange = (value) => {
        setDisputeAmount(value);
    };

    const customStylesForModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 1,
        },
        content: {
            top: '5%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: 32,
            transform: 'translateX(-50%) translateX(120px)',
            display: 'flex',
            flexDirection: 'column',
            fontSize: 16,
            border: 0,
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        },
    };

    const customStylesForActivityDetailsModal = {
        ...customStylesForModal,
        content: {
            ...customStylesForModal.content,
            maxHeight: 700,
            width: 700,
        },
    };

    const customStylesForDisputeModal = {
        ...customStylesForModal,
        content: {
            ...customStylesForModal.content,
            maxHeight: 1200,
            width: 900,
        },
    };

    const closeDisputeDetailsModal = () => {
        setShowDisputeDetailsModal(false);
    };

    const renderDateBlock = (key) => {
        let label;
        let labelId;
        let value;
        let maxDate;
        let minDate;

        switch (key) {
            case 'fromDate':
                label = 'From Date*';
                labelId = 'recent-activity_from-date';
                value = fromDate;
                maxDate = new Date();
                minDate = fromDate ? new Date(fromDate.minValue) : null;
                break;

            case 'toDate':
                label = 'To Date';
                labelId = 'recent-activity_to-date';
                value = toDate;
                maxDate = toDate ? new Date(toDate.maxValue) : null;
                minDate = fromDate;
                break;

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'comms-form-container--m0',
                    label: `recent-activity-label ${labelId}`,
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: handleDateChange(key),
                    maxDate,
                    minDate,
                }}
                hideErrLabel
            />
        );
    };

    const getDropdownClasses = (data) => {
        const classesForDropdown = ['txns-ad-cc recent-activity-table-option__height-transition'];
        if (data.meta === selectedActivity.meta) {
            classesForDropdown.push('recent-activity-table-option__active');
        }

        return classesForDropdown;
    };

    const renderActionOptions = (action, data) => {
        switch (action.deeplink.screen_name) {
            case 'SCREEN_NAME_RECENT_ACTIVITY_VIEW_DETAILS': return (
                <div
                    className='txns-ad-cc-wr__label'
                    onClick={() => {
                        dispatch(getRecentActivityDetails({
                            ticketId, userId, meta: data.meta,
                        }));
                        setShowActivityDetailsModal(true);
                        setSelectedActivity({});
                    }}
                    role='presentation'
                >
                    View Details
                </div>
            );
            case 'SCREEN_NAME_RECENT_ACTIVITY_VIEW_DISPUTE_DETAILS': return (
                <div
                    className='txns-ad-cc-wr__label'
                    onClick={fetchDisputeInfo.bind(null, data)}
                    role='presentation'
                >
                    View Dispute Details
                </div>
            );
            case 'SCREEN_NAME_RECENT_ACTIVITY_RAISE_DISPUTE': return (
                <div
                    className='txns-ad-cc-wr__label'
                    onClick={raiseTxnDisputeMethod.bind(null, data)}
                    role='presentation'
                >
                    Raise Dispute
                </div>
            );
            case 'SCREEN_NAME_RECENT_ACTIVITY_MARK_AGAINST_TICKET': return (
                <div
                    className='txns-ad-cc-wr__label'
                    onClick={() => {
                        // Entity_type to be added from backend
                        dispatch(attachEntityToTicket({
                            ticketId, userId, meta: [], type: EntityType.ACTOR_ACTIVITY, resolve: () => {}, metaString: [data.meta],
                        }));
                    }}
                    role='presentation'
                >
                    Mark Against Ticket
                </div>
            );
            default: return '';
        }
    };

    const renderActions = (data) => (
        <div className='frfscWrapper'>
            <DropdownWrapper
                visible={data?.meta && data.meta === selectedActivity?.meta}
                onOutsideClickHandler={() => {
                    setSelectedActivity({});
                }}
                extClasses={{
                    container: 'txns-ad',
                }}
            >
                <div
                    className='txns-ad-label'
                    onClick={handleMoreOptionsClick.bind(null, data)}
                    role='presentation'
                >
                    ...
                </div>
                <DropdownContent
                    visible
                    extClasses={{
                        container: getDropdownClasses(data),
                    }}
                >
                    <div className='txns-ad-cc-wr'>
                        {data.actions.map((action) => renderActionOptions(action, data))}
                    </div>
                </DropdownContent>
            </DropdownWrapper>
        </div>
    );

    const paginatedClickHandler = (item) => {
        const { prev, next } = item;
        dispatch(getRecentActivities({
            ticketId, userId, index, fromDate, toDate, selectedAreas, prevToken: prev, nextToken: next,
        }));
    };

    const renderFormMultiSelectDD = (key) => {
        let label;
        let placeholder;
        let value;
        let options;
        let onChange;

        switch (key) {
            case 'area': {
                label = 'Search by Areas';
                placeholder = 'select multiple areas if required';
                value = selectedAreas;
                options = dropdownOption;
                onChange = (ddValue) => {
                    setSelectedAreas(ddValue);
                };
                break;
            }

            default:
                label = 'Default';
                options = [];
        }

        return (
            <div className='frcWrapper'>
                <div className='recent-activity-label'>{label}</div>
                <FormDropdown
                    isMulti
                    options={options}
                    input={{
                        value,
                        onChange,
                        placeholder,
                    }}
                    cacheKey='agentform-accesslevel'
                    extStyles={{
                        container: 'create-agent-dd',
                    }}
                />
            </div>
        );
    };

    const renderAuthExpiredScreen = () => {
        const listOfAuthFactorsRequired = err?.screenOptions?.authFactorList.map((item) => item.name);

        return (
            <div className='account-statement-error-section'>
                <div className='ticket-modal__heading'>
                    User Authorization for this action is not enough. You need to collect {listOfAuthFactorsRequired?.join()}.
                </div>
                <div className='ticket-modal__subheading'>Press button below to go to the authorization flow</div>
                <Button
                    primary
                    label='Go to authorisation flow'
                    onClick={() => {
                        history.push(`${DEFAULT_TICKET_PATH}${ticketId}`);
                    }}
                />
            </div>
        );
    };

    const isDisputeSubmissionDisabled = () => {
        let reqdQuestionsFilled;

        if (disputeInfo[ticketId]) {
            ({
                reqdQuestionsFilled,
            } = disputeInfo[ticketId]);
        }

        const areRequiredQuestionsAnswered = reqdQuestionsFilled && Boolean(disputeActivity?.area);

        const disputeValidationForCreditCardTxn = !(areRequiredQuestionsAnswered && !Number.isNaN(disputeAmount) && Number(disputeAmount) > 0);

        const disputeValidationForOtherTxn = !areRequiredQuestionsAnswered;

        return isCreditCardTransaction(disputeActivity)
            ? disputeValidationForCreditCardTxn : disputeValidationForOtherTxn;
    };

    const submitDisputeDetails = () => {
        const { data } = questionList[ticketId];

        const modifiedQuestionList = data.map((item) => ({
            question_code: item.code,
            question: item.question,
            answer: item.answer,
        }));

        const commonPayload = {
            ticketId,
            userId,
            index,
            txnId: disputeActivity?.internalTransactionIdKey?.value,
            questionList: modifiedQuestionList,
        };

        if (isCreditCardTransaction(disputeActivity)) {
            dispatch(raiseDisputeForCreditCardTxnAction({
                ...commonPayload,
                cardActionType: CARD_ACTION_TYPE.CARD_ACTION_TYPE_PROCESS_DISPUTE,
                disputeType: DISPUTE_TYPE.DISPUTE_TYPE_TRANSACTION,
                disputeAmount,
            }));
        } else {
            dispatch(raiseTxnDispute({
                ...commonPayload,
                channel,
                channelOption,
            }));
        }
    };

    return (
        <div className='fccWrapper mt-30'>
            {/* <div style={{ display: 'none' }}> */}
            {/*    <Transactions> */}
            {/*        ...props */}
            {/*    </Transactions> */}
            {/* </div> */}
            {(err && authFail) ? renderAuthExpiredScreen()
                : (
                    <React.Fragment>
                        <ExpandableCard
                            extClasses='rewards-ec'
                            heading='Recent Activity Search'
                            isExpanded={isRecentActivityCardOpen}
                            setIsExpanded={() => setIsRecentActivityCardOpen(!isRecentActivityCardOpen)}
                        >
                            <div className='rewards-sc'>
                                <div className='frwpWrapper rewards-sc__gap'>
                                    {renderFormMultiSelectDD('area')}
                                    {renderDateBlock('fromDate')}
                                    {renderDateBlock('toDate')}
                                </div>
                                {renderErrorField()}
                                <div className='frcWrapper rewards-sc__3r'>
                                    <Button
                                        extClasses='rewards-sc__cta'
                                        primary
                                        label='Search'
                                        onClick={() => {
                                            pushRudderEvent(RudderEvent.RecentActivity.ClickedSherlockSearchRecentActivityButton, agentInfo.emailId, {
                                                ticketId,
                                                fromDate,
                                                toDate,
                                                selectedAreas,
                                            });
                                            fetchRecentActivity();
                                        }}
                                    />
                                    <div
                                        className='link rewards-sc__clear'
                                        onClick={clearAllSelection}
                                        role='presentation'
                                    >
                                        Clear All Selection
                                    </div>
                                </div>
                            </div>
                        </ExpandableCard>
                        <Loader visible={isLoading || fetchDataLoading} />

                        <div className='recent-activity-table__container'>
                            {tableData && (
                                <Table
                                    rowDataVersion={2}
                                    objKey='header_key'
                                    labelData={tableData.columnData}
                                    contentData={tableData.rowData}
                                    renderActionableColumn={renderActions}
                                    prevToken={prevToken}
                                    nextToken={nextToken}
                                    paginatedClickHandler={paginatedClickHandler}
                                    extClasses={{
                                        container: 'p-20',
                                    }}
                                />
                            )}
                        </div>
                    </React.Fragment>
                )}
            {
                showActivityDetailsModal && recentActivityDetails ? (
                    <Modal
                        isOpen
                        style={customStylesForActivityDetailsModal}
                        contentLabel='Activity Details Modal'
                        shouldCloseOnOverlayClick
                        onRequestClose={() => setShowActivityDetailsModal(!showActivityDetailsModal)}
                    >
                        <div className='recent-activity-modal__title'>Activity Details</div>
                        {
                            recentActivityDetails?.data?.map((item) => (
                                <div className='recent-activity-section__container'>
                                    <div className='recent-activity-section__title'>{item.title}</div>
                                    {item.label_values.map((labelValue) => (
                                        <div className='txns-modal-cwr' key={labelValue.label}>
                                            <div className='txns-modal-cwr__cl'>{labelValue.label}</div>
                                            <div className='txns-modal-cwr__sp'>:</div>
                                            <div className='txns-modal-cwr__cv'>{labelValue[labelValue.value]}</div>
                                        </div>
                                    ))}
                                </div>
                            ))
                        }
                        <Button
                            primary
                            extClasses='txns-modal-bwr'
                            label='Close'
                            onClick={
                                () => setShowActivityDetailsModal(!showActivityDetailsModal)
                            }
                        />
                        <Loader visible={recentActivityDetails?.loading} />
                    </Modal>
                ) : null
            }
            {
                showDisputeModal ? (
                    <Modal
                        isOpen
                        style={customStylesForDisputeModal}
                        contentLabel='Raise Dispute Modal'
                        shouldCloseOnOverlayClick
                        onRequestClose={() => {
                            setShowDisputeModal(!showDisputeModal);
                            resetDisputeDetails();
                        }}
                    >
                        {
                            disputeInfo[ticketId]?.success ? (
                                <div className='txns-container txns-container--ss'>
                                    <div className='txns-rdc-ss__hd'>{disputeInfo[ticketId].data.message}</div>
                                </div>
                            ) : (
                                <React.Fragment>
                                    <DisputeQuestionnaire
                                        ticketId={ticketId}
                                        userId={userId}
                                        index={index}
                                        channel={channel}
                                        channelOption={channelOption}
                                        selectedTxn={selectedActivity}
                                        handleChannelChange={handleChannelChange}
                                        handleChannelOptionChange={handleChannelOptionChange}
                                        toggleHandler={() => {}}
                                        creditCardTransaction={isCreditCardTransaction(selectedActivity)}
                                        onDisputeAmountChange={onDisputeAmountChange}
                                    />
                                    <Button
                                        primary
                                        disabled={isDisputeSubmissionDisabled(selectedActivity)}
                                        extClasses='txns-search-cta txns-search-cta--dp'
                                        label='Raise Dispute'
                                        onClick={submitDisputeDetails}
                                    />
                                </React.Fragment>
                            )
                        }
                    </Modal>
                ) : null
            }
            {
                showDisputeDetailsModal ? (
                    <Modal
                        isOpen
                        style={customStylesForModal}
                        contentLabel='Dispute Details'
                        onRequestClose={() => {
                            setShowDisputeDetailsModal(!showDisputeDetailsModal);
                        }}
                    >
                        <div className='heading3 mb-15'>
                            View Dispute Details
                        </div>

                        <div className='disputes-modal-wrap'>
                            {
                                selectedDisputeInfo[ticketId].hasInfo
                                    ? (JSON.stringify(selectedDisputeInfo[ticketId], null, 4))
                                    : ('No Dispute raised')
                            }
                        </div>

                        <Button
                            v2
                            secondary
                            extClasses='disputes-modal-cta'
                            label='Close'
                            onClick={closeDisputeDetailsModal}
                        />
                    </Modal>
                ) : null
            }
        </div>
    );
};

const withReducer = injectReducer({ key: 'recentActivity', reducer });
const withSaga = injectSaga({ key: 'recentActivity', saga });

const transactionReducer = injectReducer({ key: 'transactions', reducer: transactionsReducer });
const transactionSaga = injectSaga({ key: 'transactions', saga: transactionsSaga });

export default compose(
    withReducer,
    withSaga,
    transactionReducer,
    transactionSaga,
)(RecentActivity);

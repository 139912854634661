/**
 * @file Eligible Screen: Component to show the Credit Card Eligible Screen.
 */

import React from 'react';

import OnboardingStages from '../OnboardingStages';
import CreditCardOfferScreen from '../CreditCardOfferScreen';

const EligibleScreen = (props) => {
    const { creditCardOnboardingDetailsData } = props;
    const {
        eligibility, creditLimit, offerValidityTimestamp, stageDetails,
    } = creditCardOnboardingDetailsData || {};

    const creditCardOfferData = [
        {
            name: 'Credit limit',
            value: creditLimit,
        },
        {
            name: 'Offer validity',
            value: offerValidityTimestamp,
        },
    ];

    return (
        <React.Fragment>
            <div className='heading4 mb-15'>Credit Card Eligibility</div>
            <div className='frWrapper'>
                <div className='credit-card-clc-cwr__hl credit-card-clc-cwr__f1'>Eligible for CC</div>
                <div className='credit-card-clc-cwr__hl credit-card-clc-cwr__f2'>{eligibility ? 'Yes' : 'No'}</div>
            </div>
            {eligibility ? (
                <React.Fragment>
                    <CreditCardOfferScreen creditCardOfferData={creditCardOfferData} />
                    <OnboardingStages stageDetails={stageDetails} />
                </React.Fragment>
            ) : (
                <div className='label1 mt-15'>
                    The user is not eligible at the moment for the credit card. Please check the knowledge portal
                </div>
            )}
        </React.Fragment>
    );
};

export default EligibleScreen;

/**
 * @file V2 Liveness review constants
 */

import { RISK_OPS_COMMON_STATE } from '../RiskOps/constants';

// container key
export const LIVENESS_REVIEW_KEY = 'livenessReviewV2';

// value constants
const PAYLOAD_TYPE_LIVENESS = 'PAYLOAD_TYPE_LIVENESS';
export const PAYLOAD_TYPE_LIVENESS_SAMPLE = 'PAYLOAD_TYPE_LIVENESS_SAMPLE';
const PAYLOAD_TYPE_AFU_LIVENESS = 'PAYLOAD_TYPE_AFU_LIVENESS';
export const PAYLOAD_TYPE_L2_LIVENESS = 'PAYLOAD_TYPE_QA_LIVENESS';
export const PAYLOAD_TYPE_LIVENESS_PRE_APPROVED_LOAN = 'PAYLOAD_TYPE_LIVENESS_PRE_APPROVED_LOAN_APPLICATION';

export const DEV_ACTION_TYPES = ['MARK_LIVENESS_PASSED', 'RETRY_LIVENESS'];

export const PRE_APPROVED_LOAN_MANUAL_REVIEW_ACTION = 'PRE_APPROVED_LOAN_MANUAL_REVIEW';
export const DEV_ACTIONS_FOR_LIVENESS_PRE_APPROVED_LOAN = [PRE_APPROVED_LOAN_MANUAL_REVIEW_ACTION];

const FEDERAL_LOAN_LIVENESS_REVIEW_ACTION = 'FEDERAL_LOAN_LIVENESS_REVIEW';
export const DEV_ACTIONS_FOR_FEDERAL_LOAN_LIVENESS_REVIEW = [FEDERAL_LOAN_LIVENESS_REVIEW_ACTION];

export const QUEUE_PAYLOAD_TYPES = [
    PAYLOAD_TYPE_LIVENESS,
    PAYLOAD_TYPE_LIVENESS_SAMPLE,
    PAYLOAD_TYPE_AFU_LIVENESS,
    PAYLOAD_TYPE_L2_LIVENESS,
    PAYLOAD_TYPE_LIVENESS_PRE_APPROVED_LOAN,
];

const PAYLOAD_DD_OG_STATE = {
    value: PAYLOAD_TYPE_LIVENESS,
    isVisible: false,
};

export const LIVENESS_SPECIFIC_STATE = {
    payloadType: PAYLOAD_DD_OG_STATE,
    videoUrl: '',
};

export const LIVENESS_REDUCER_STATE = {
    ...RISK_OPS_COMMON_STATE,
    ...LIVENESS_SPECIFIC_STATE,
};

/**
 *
 * Monorail Id Input Component
 *
 */

import React, { memo, useEffect, useRef, useState } from 'react';

import FormWithSingleInput from '../FormWithSingleInput';
import { validators } from '../../utils';

import './style.scss';

const MonorailIdInput = (props) => {
    const monorailIdRef = useRef(null);
    const [monorailId, setMonorailId] = useState('');
    const [errLabel, setErrLabel] = useState('');

    const { submitMonorailId } = props;

    useEffect(() => {
        monorailIdRef.current.focus();
    }, []);

    const handleMonorailIdChange = (e) => {
        setMonorailId(e.target.value);
    };

    const handleMonorailIdSubmit = () => {
        if (!validators.isInputtedNumberValid(monorailId)) {
            setErrLabel('Please enter a valid monorail id');
            return;
        }

        submitMonorailId(monorailId);
    };

    return (
        <div className='monorailcomp-cr'>
            <FormWithSingleInput
                label='Enter monorail id'
                labelId='monorail-id'
                input={{
                    value: monorailId,
                    onChange: handleMonorailIdChange,
                    ref: monorailIdRef,
                }}
                cta={{
                    primaryLabel: 'Submit',
                    onPrimaryClick: handleMonorailIdSubmit,
                }}
                errLabel={errLabel}
                onFormSubmit={handleMonorailIdSubmit}
            />
        </div>
    );
};

export default memo(MonorailIdInput);

/**
 *
 * @file create and update rule details as a Modal in the Risk Rule queue
 *
 */

import React from 'react';
import { Modal, ParameterList } from '../../../components';

import { RULE_FORM_PARAMETER_FILTERS_CONFIG } from '../constants';

import '../style.scss';

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'inherit',
        fontWeight: '200',
        fontSize: 14,
        width: '70%',
        maxWidth: '1500px',
        height: '70%',
        overflow: 'auto',
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        padding: '10px 20px 20px 32px',
    },
};

const RuleInfoModal = ({
    closeCta, visible, submitCta, ruleInfoForm,
}) => {
    if (!visible) return null;

    return (
        <Modal
            visible
            modalStyles={modalStyles}
            contentLabel='Rule Details'
        >

            <div
                className='rr-modal__cl-wrapper'
            >
                <button
                    type='button'
                    className='rr-modal__cl'
                    aria-label='Close'
                    onClick={closeCta}
                >
                    <span aria-hidden='true'>&times;</span>
                </button>
            </div>

            <div className='rr-modal__cr'>
                <ParameterList
                    config={RULE_FORM_PARAMETER_FILTERS_CONFIG}
                    data={ruleInfoForm}
                    onSubmit={submitCta}
                    extClasses='risk-rule-form-container'
                />
            </div>
        </Modal>
    );
};

export default RuleInfoModal;

/**
 * @file UserDetailsWithPhoneOrEmail - Contains the form for user details with phone or email
 */
import React, { useEffect, useState } from 'react';

import { FormDropdown, FormWithSingleInput, Button } from '../../components';
import { validators } from '../../utils';

import UserProfileAndOnboardingDetailsWrapper from '../UserProfileAndOnboardingDetailsWrapper';

import { COUNTRY_CODES, IDENTIFIER_TYPES } from './constants';
import './styles.scss';

const UserDetailsWithPhoneOrEmail = (props) => {
    const { tabData, onSubmit } = props;
    const [type, setType] = useState();
    const [value, setValue] = useState();
    const [subValue, setSubValue] = useState();
    const [errLabel, setErrLabel] = useState('');
    const { activeTabIndex, newTabActive } = tabData || {};

    const submitIdentifierValue = () => {
        onSubmit(type?.value, value, subValue?.value);
        setSubValue();
        setValue('');
        setType();
    };

    const handleIdentifierSubmit = () => {
        setErrLabel('');
        if (type?.value === 'CUSTOMER_AUTH_IDENTIFIER_TYPE_PHONE_NUMBER') {
            if (!validators.isInputtedMobileNumberValid(value)) {
                setErrLabel('Please enter a valid Phone Number');
            } else {
                submitIdentifierValue();
            }
        } else if (type?.value === 'CUSTOMER_AUTH_IDENTIFIER_TYPE_EMAIL_ID') {
            if (!validators.isInputtedEmailValid(value)) {
                setErrLabel('Please enter a valid Email');
            } else {
                submitIdentifierValue();
            }
        }
    };

    useEffect(() => {
        if (type?.value === 'CUSTOMER_AUTH_IDENTIFIER_TYPE_PHONE_NUMBER') {
            setSubValue(COUNTRY_CODES[0]);
        } else {
            setSubValue();
        }
    }, [type]);

    if (!newTabActive) {
        return (
            <div className='p-30 app-cr'>
                <UserProfileAndOnboardingDetailsWrapper
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    componentProps={{
                        ...props,
                        index: activeTabIndex,
                        notTicket: true,
                    }}
                />
            </div>
        );
    }

    return (
        <div className='user-details'>
            <FormDropdown
                label='Identifier Type'
                options={IDENTIFIER_TYPES}
                input={{
                    value: type,
                    onChange: (newValue) => setType(newValue),
                    placeholder: 'Choose...',
                    isClearable: true,
                }}
                cacheKey='identifier-type'
                extStyles={{
                    container: 'user-details-fc1 mb-30',
                    label: 'user-details-fc__lb',
                    inputField: 'user-details-fc__ip',
                }}
            />
            {
                type?.value === 'CUSTOMER_AUTH_IDENTIFIER_TYPE_PHONE_NUMBER' && (
                    <div className='frcWrapper mb-30'>
                        <div className='user-details-fc__lb'>Identifier Value</div>
                        <div className='frcWrapper'>
                            <FormDropdown
                                hideErrLabel
                                options={COUNTRY_CODES}
                                cacheKey='country-code'
                                extStyles={{
                                    container: 'user-details-fc1_1',
                                }}
                                input={{
                                    value: subValue,
                                    onChange: (newValue) => setSubValue(newValue),
                                    isDisabled: true,
                                }}
                            />
                            <FormWithSingleInput
                                hideErrLabel
                                disableFormSubmitOnEnter
                                labelId='identifier-value-phone-number'
                                input={{
                                    value,
                                    onChange: (e) => setValue(e.target.value),
                                }}
                            />
                        </div>
                    </div>
                )
            }
            {
                type?.value === 'CUSTOMER_AUTH_IDENTIFIER_TYPE_EMAIL_ID' && (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'user-details-fc2 mb-30',
                            label: 'user-details-fc__lb',
                            inputField: 'user-details-fc__ip',
                        }}
                        label='Identifier Value'
                        labelId='identifier-value-email'
                        input={{
                            value,
                            onChange: (e) => setValue(e.target.value),
                        }}
                    />
                )
            }
            {errLabel && <div className='err-label label1 user-details-elb'>{errLabel}</div>}
            <Button
                v2
                label='Submit'
                primary
                onClick={() => handleIdentifierSubmit()}
            />
        </div>
    );
};

export default UserDetailsWithPhoneOrEmail;

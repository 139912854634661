/**
 * @file Manages the selector functions for risk rule container
 */

import { createSelector } from 'reselect';

import { RISK_RULE_KEY, REDUCER_KEYS } from './constants';

export const selectRiskRuleQueue = createSelector(
    (state) => state.getIn([RISK_RULE_KEY, REDUCER_KEYS.RULE_FILE_ENTRIES]),
    (substate) => substate?.toJS(),
);

export const selectRiskRuleLoader = createSelector(
    (state) => state.getIn([RISK_RULE_KEY, REDUCER_KEYS.RULE_LOADING]),
    (substate) => substate,
);

export const selectRiskRuleError = createSelector(
    (state) => state.getIn([RISK_RULE_KEY, REDUCER_KEYS.RULE_ERROR]),
    (subState) => subState,
);

export const selectTags = createSelector(
    (state) => state.getIn([RISK_RULE_KEY, REDUCER_KEYS.TAGS]),
    (substate) => substate?.toJS(),
);

export const selectEnums = createSelector(
    (state) => state.getIn([RISK_RULE_KEY, REDUCER_KEYS.ENUMS]),
    (substate) => substate?.toJS(),
);

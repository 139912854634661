/*
 *
 * DBStates reducer
 *
 */

import { fromJS } from 'immutable';

import {
    GET_SERVICE_LIST, SET_SERVICE_LIST, ERR_SERVICE_LIST,
    GET_ENTITY_LIST, SET_ENTITY_LIST, ERR_ENTITY_LIST,
    GET_OPTION_LIST, SET_OPTION_LIST, SET_OPTION_VALUE, ERR_OPTION_LIST,
    GET_DB_INFO, SET_DB_INFO, ERR_DB_INFO, SET_MONORAIL_ID,
} from './constants';

const initialState = fromJS({
    monorailInfo: {
        id: '',
    },
    loading: false,
    err: '',
    serviceList: [],
    entityList: [],
    optionList: [],
    hasDBInfo: false,
    dbInfo: [],
    selectedService: {},
    selectedEntity: {},
});

function dbStatesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_SERVICE_LIST:
        case GET_DB_INFO:
            return state
                .set('loading', true)
                .set('err', '');

        case GET_ENTITY_LIST: {
            const { service } = action.data;

            return state
                .set('loading', true)
                .set('err', '')
                .set('selectedService', fromJS(service))
                .set('selectedEntity', fromJS({}));
        }

        case GET_OPTION_LIST: {
            const { entity } = action.data;

            return state
                .set('loading', true)
                .set('err', '')
                .set('selectedEntity', fromJS(entity));
        }

        case SET_SERVICE_LIST: {
            const { serviceList } = action.data;

            return state
                .set('loading', false)
                .set('serviceList', fromJS(serviceList));
        }

        case ERR_SERVICE_LIST:
            return state.set('loading', false);

        case SET_ENTITY_LIST: {
            const { entityList } = action.data;

            return state
                .set('loading', false)
                .set('entityList', fromJS(entityList))
                .set('optionList', fromJS([]));
        }

        case ERR_ENTITY_LIST:
            return state
                .set('loading', false)
                .set('entityList', fromJS([]))
                .set('optionList', fromJS([]));

        case SET_OPTION_LIST: {
            const { optionsList } = action.data;

            const modifiedOptionList = optionsList.map((item) => {
                const modifiedItem = {
                    ...item,
                    value: '',
                };

                if (item.type === 5) { // DROPDOWN
                    modifiedItem.isDDOpen = false;
                } else if (item.type === 7) { // NAME
                    modifiedItem.value = {
                        first_name: '',
                        last_name: '',
                    };
                } else if (item.type === 8) { // PHONE_NUMBER
                    modifiedItem.value = '';
                    modifiedItem.secondaryValue = '91';
                } else if (item.type === 9) { // MULTI_SELECT_DROPDOWN
                    modifiedItem.options = modifiedItem.options.map((opt) => ({
                        label: opt,
                        value: opt,
                    }));
                    modifiedItem.value = [];
                }

                return modifiedItem;
            });

            return state
                .set('loading', false)
                .set('optionList', fromJS(modifiedOptionList));
        }

        case ERR_OPTION_LIST:
            return state
                .set('loading', false)
                .set('optionList', fromJS([]));

        case SET_OPTION_VALUE: {
            const {
                item, key, value, dd, secondaryValue,
            } = action.data;

            const optionList = state.get('optionList').toJS();
            const itemIdx = optionList.findIndex((lItem) => lItem.name === item.name);

            const modifiedOptionList = [...optionList];

            if (item.type === 5) { // DROPDOWN
                modifiedOptionList[itemIdx] = {
                    ...modifiedOptionList[itemIdx],
                    isDDOpen: dd,
                    value: value || modifiedOptionList[itemIdx].value,
                };
            } else if (item.type === 7) { // NAME
                modifiedOptionList[itemIdx] = {
                    ...modifiedOptionList[itemIdx],
                    value: {
                        ...modifiedOptionList[itemIdx].value,
                        [key]: value,
                    },
                };
            } else if (secondaryValue) {
                modifiedOptionList[itemIdx] = {
                    ...modifiedOptionList[itemIdx],
                    secondaryValue,
                };
            } else {
                modifiedOptionList[itemIdx] = {
                    ...modifiedOptionList[itemIdx],
                    value,
                };
            }

            return state.set('optionList', fromJS(modifiedOptionList));
        }

        case SET_DB_INFO: {
            const { dbInfo } = action.data;

            return state
                .set('loading', false)
                .set('hasDBInfo', true)
                .set('dbInfo', fromJS(dbInfo || []));
        }

        case ERR_DB_INFO: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err)
                .set('hasDBInfo', false)
                .set('dbInfo', fromJS([]));
        }

        case SET_MONORAIL_ID: {
            const { value } = action.data;

            if (value) {
                return state.setIn(['monorailInfo', 'id'], value);
            }

            // need to persist service list on re-initialising the remaining states
            const newState = initialState.toJS();
            newState.serviceList = state.get('serviceList').toJS();

            return fromJS(newState);
        }

        default:
            return state;
    }
}

export default dbStatesReducer;

/*
 *
 * Call Details Actions
 *
 */

import {
    GET_CALL_RECORDING_URL, GET_CALL_TRANSCRIPT,
} from './constants';

export const getCallRecordingURL = data => ({
    type: GET_CALL_RECORDING_URL,
    data
});

export const getCallTranscript = data => ({
    type: GET_CALL_TRANSCRIPT,
    data
});

/**
 *
 * @file UserActions > Call User
 *
 */

import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { FormWithSingleInput, Loader } from '../../../components';
import { validators } from '../../../utils';

import { callbackUser } from '../actions';
import { makeSelectCallbackUser } from '../selectors';
import './style.scss';

const CallUser = (props) => {
    const {
        ticketId,
        phoneNumber,
        // index,
    } = props;

    const dispatch = useDispatch();
    const [mobileNo, setMobileNo] = useState(phoneNumber);
    const [isEditing, setEditing] = useState(false);
    const [errLabel, setErrLabel] = useState('');
    const inputRef = useRef(null);
    const callbackUserInfo = useSelector(makeSelectCallbackUser, shallowEqual);

    let loading;

    if (callbackUserInfo[ticketId]) {
        ({ loading } = callbackUserInfo[ticketId]);
    }

    useEffect(() => {
        if (isEditing) {
            inputRef.current.focus();
        }
    }, [isEditing]);

    const dialPhoneNumber = () => {
        if (isEditing && !validators.isInputtedMobileNumberValid(mobileNo)) {
            setErrLabel('Please enter a valid mobile number');
            return;
        }

        const payload = {
            ticketId,
        };

        if (isEditing) {
            payload.phoneNumber = mobileNo;
        }

        setErrLabel('');
        dispatch(callbackUser(payload));
    };

    const changePhoneNumber = () => {
        setEditing(true);
        setMobileNo('');
    };

    const onInputChange = (e) => {
        const charCode = e.which || e.keyCode;

        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }

        let { value } = e.target;
        const inputResult = /^\d]*$/.test(value);

        if (!inputResult) {
            value = value.replace(/[^a-z0-9\s]/gi, '');
        }

        if (mobileNo.length === 0) {
            if ([48, 49, 50, 51, 52, 53].includes(charCode)) {
                e.preventDefault();
            }
        }

        setMobileNo(value);
    };

    return (
        <React.Fragment>
            <FormWithSingleInput
                label='Enter mobile number'
                labelId='mobile-no'
                input={{
                    value: mobileNo,
                    onChange: onInputChange,
                    disabled: !isEditing,
                    ref: inputRef,
                    maxLength: 10,
                    type: 'number',
                    onKeyDown: onInputChange,
                }}
                cta={{
                    primaryLabel: 'Dial Number',
                    onPrimaryClick: dialPhoneNumber,
                    secondaryLabel: 'Change Number',
                    onSecondaryClick: changePhoneNumber,
                }}
                errLabel={errLabel}
                extClasses={{
                    label: 'calluser-lb',
                    errLabel: 'calluser-errlb',
                }}
            />
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(CallUser);

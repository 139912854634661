/*
 *
 * LivenessVideo constants
 *
 */

export const GET_LIVENESS_VIDEO_URL = 'app/LivenessVideo/GET_LIVENESS_VIDEO_URL';
export const SET_LIVENESS_VIDEO_URL = 'app/LivenessVideo/SET_LIVENESS_VIDEO_URL';
export const ERR_LIVENESS_VIDEO_URL = 'app/LivenessVideo/ERR_LIVENESS_VIDEO_URL';
export const SET_MONORAIL_ID = 'app/LivenessVideo/SET_MONORAIL_ID';

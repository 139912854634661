/**
 * @file Common risk ops sagas
 */
import {
    call, put, takeLatest, all, takeEvery,
} from 'redux-saga/effects';

import { LIVENESS_ROOT, LIVENESS_ROOT_V2, RISK_OPS_ROOT } from '../../api/routes';
import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify, getErrMsg,
} from '../../utils';

import {
    getRiskOpsPendingReviewCountAction, setRiskOpsBase64ImageAction, setRiskOpsCasesAction, setRiskOpsCurCaseAction, setRiskOpsCurViewAction,
    setRiskOpsErrAction, setRiskOpsL2AnnotationDetailsAction, setRiskOpsPendingReviewCountAction, setRiskOpsReviewVideoAction,
} from './actions';
import {
    AUDIT_LOG_MEDIA_DOWNLOAD, GET_RISK_OPS_L2_ANNOTATION_DETAILS, RISK_OPS_VIEW_ALL_CASES, RISK_OPS_VIEW_SINGLE_CASE, GET_RISK_OPS_CASES,
    DEQUEUE_RISK_OPS_CASES, GET_RISK_OPS_REVIEW_VIDEO, GET_RISK_OPS_BASE64_IMAGE, GET_RISK_OPS_PENDING_REVIEW_COUNT, GET_RISK_OPS_REVIEW_BY_ACTOR_ID,
    SUBMIT_RISK_OPS_QA_REVIEW,
} from './constants';

function* getL2AnnotationDetailsSaga(action) {
    const { queryParams, containerKey } = action.data;
    const { actorId, requestId, payloadType } = queryParams;
    const queryString = getQueryStringFromObject({ payloadType });
    const finalQueryString = `${queryString}&actorId=${btoa(actorId)}&requestId=${btoa(requestId)}`;

    try {
        const l2AnnotationDetails = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_ROOT}/l2-annotation-details?${finalQueryString}`,
        );

        yield put(setRiskOpsL2AnnotationDetailsAction({ l2AnnotationDetails, containerKey }));
    } catch (e) {
        const err = getErrLabel(e);
        yield put(setRiskOpsErrAction({ containerKey, err: getErrMsg(e) }));
        toastify(err, 'error');
    }
}

export function* auditLogMediaDownloadSaga(action) {
    const {
        requestBody, resolve, reject, containerKey,
    } = action.data;
    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${RISK_OPS_ROOT}/media-download`,
            requestBody,
        );

        yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setRiskOpsErrAction({ containerKey, err: getErrMsg(e) }));
        yield call(reject);
    }
}

export function* getRiskOpsCasesSaga(action) {
    const { queryParams, containerKey } = action.data;
    const queryString = getQueryStringFromObject(queryParams);

    try {
        const { elements: casesList } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_ROOT}/queue?${queryString}`,
        );

        yield put(setRiskOpsCasesAction({ casesList, containerKey }));

        return casesList;
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setRiskOpsErrAction({ containerKey, err: getErrMsg(e) }));
        yield put(setRiskOpsCasesAction({ containerKey, casesList: [] }));
        return [];
    }
}

export function* getRiskOpsPendingReviewCountSaga(action) {
    const { containerKey, queryParams: { payloadType } } = action.data;
    const queryString = getQueryStringFromObject({ payloadType });

    try {
        const { count: pendingReviewCount } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_ROOT}/pending-review-count?${queryString}`,
        );

        yield put(setRiskOpsPendingReviewCountAction({ containerKey, pendingReviewCount }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setRiskOpsErrAction({ containerKey, err: getErrMsg(e) }));
    }
}

function* refreshRiskOpsQueue(action) {
    const {
        queryParams, index, containerKey, customContext,
    } = action.data;
    const { bannersVersion } = customContext;

    const bannerStyle = {
        1: '.devs-cc',
        2: '.devs-v2-cc',
    };

    try {
        const queueElements = yield call(getRiskOpsCasesSaga, { data: { containerKey, queryParams } });
        yield put(getRiskOpsPendingReviewCountAction({ containerKey, queryParams: { ...queryParams } }));

        let newView;
        let newCase;
        const queueLen = queueElements.length;

        if (queueLen === 0) { // if queue is empty, show all liveness cases view
            newView = RISK_OPS_VIEW_ALL_CASES;
            newCase = {};
        } else { // else show the next case in the queue
            newView = RISK_OPS_VIEW_SINGLE_CASE;
            /**
             * if index is less than queue length, set the current case at that index
             * else set the current case as the last case in the queue
             */
            newCase = index < queueLen ? queueElements[index] : queueElements[queueLen - 1];
        }

        yield put(setRiskOpsCurViewAction({ containerKey, newView }));
        yield put(setRiskOpsCurCaseAction({ containerKey, caseData: { ...newCase, index } }));
        /**
         * scroll to the top for the next case
         * need to add 500ms delay otherwise it scrolls to the top & then again back to the bottom
         */
        setTimeout(() => document.querySelector(bannerStyle[bannersVersion]).scrollTo(0, 0), 500);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setRiskOpsErrAction({ containerKey, err: getErrMsg(e) }));
    }
}

export function* dequeueRiskOpsCaseSaga(action) {
    let { actorId, recordId } = action.data;
    const {
        queryParams, index, containerKey, customContext,
    } = action.data;
    const { payloadType } = queryParams;

    actorId = btoa(actorId);
    recordId = btoa(recordId);
    const queryString = getQueryStringFromObject({ actorId, recordId, payloadType });

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.delete],
            `${RISK_OPS_ROOT}/queue?${queryString}`,
        );

        yield call(refreshRiskOpsQueue, {
            data: {
                queryParams, index, containerKey, customContext,
            },
        });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setRiskOpsErrAction({ containerKey, err: getErrMsg(e) }));
    }
}

function* submitRiskOpsQaReviewSaga(action) {
    const {
        payload, getCasesQueryParams, index, containerKey, customContext,
    } = action.data;
    const { actorId, requestId } = payload;

    const requestBody = {
        ...payload,
        actorId: btoa(actorId),
        requestId: btoa(requestId),
    };

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${RISK_OPS_ROOT}/qa-re-review`,
            requestBody,
        );

        yield call(refreshRiskOpsQueue, {
            data: {
                queryParams: getCasesQueryParams, index, containerKey, customContext,
            },
        });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setRiskOpsErrAction({ containerKey, err: getErrMsg(e) }));
    }
}

export function* getVideoFromActorAndReqIdSaga(action) {
    const queryString = getQueryStringFromObject(action.data, true);

    const { videoUrl } = yield call(
        [clientApiWrapper, clientApiWrapper.get],
        `${LIVENESS_ROOT_V2}/video-url?${queryString}`,
    );

    return videoUrl;
}

export function* getRiskOpsReviewVideoSaga(action) {
    const { containerKey, queryParams: { actorId, requestId } } = action.data;

    try {
        const videoUrl = yield call(getVideoFromActorAndReqIdSaga, { data: { actorId, requestId } });

        yield put(setRiskOpsReviewVideoAction({ containerKey, videoUrl }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');

        yield put(setRiskOpsErrAction({ containerKey, err: getErrMsg(e) }));
    }
}

export function* getBase64ImgFromImgPath(action) {
    const queryString = getQueryStringFromObject(action.data, true);

    const { imageSrc } = yield call(
        [clientApiWrapper, clientApiWrapper.get],
        `${LIVENESS_ROOT}/fm-kyc-image?${queryString}`,
    );
    return imageSrc;
}

export function* getRiskOpsBase64ImageSaga(action) {
    const { containerKey, imageInfo } = action.data;
    const { imageType, imagePath } = imageInfo;

    try {
        // clear the last image set before fetching the new image
        yield put(setRiskOpsBase64ImageAction({ containerKey, imageInfo: { imageType, imageSrc: '' } }));

        const imageSrc = yield call(getBase64ImgFromImgPath, { data: { imagePath } });

        yield put(setRiskOpsBase64ImageAction({ containerKey, imageInfo: { imageType, imageSrc } }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setRiskOpsErrAction({ containerKey, err: getErrMsg(e) }));
    }
}

export function* getRiskOpsRequestsByActorIdSaga(action) {
    const { queryString } = action.data;

    return yield call(
        [clientApiWrapper, clientApiWrapper.get],
        `${RISK_OPS_ROOT}/requests-by-actor-id?${queryString}`,
    );
}

export default function* riskOpsSaga() {
    yield all(
        [
            yield takeLatest(GET_RISK_OPS_L2_ANNOTATION_DETAILS, getL2AnnotationDetailsSaga),
            yield takeLatest(AUDIT_LOG_MEDIA_DOWNLOAD, auditLogMediaDownloadSaga),
            yield takeLatest(GET_RISK_OPS_CASES, getRiskOpsCasesSaga),
            yield takeLatest(GET_RISK_OPS_PENDING_REVIEW_COUNT, getRiskOpsPendingReviewCountSaga),
            yield takeLatest(DEQUEUE_RISK_OPS_CASES, dequeueRiskOpsCaseSaga),
            yield takeLatest(GET_RISK_OPS_REVIEW_VIDEO, getRiskOpsReviewVideoSaga),
            yield takeEvery(GET_RISK_OPS_BASE64_IMAGE, getRiskOpsBase64ImageSaga),
            yield takeLatest(GET_RISK_OPS_REVIEW_BY_ACTOR_ID, getRiskOpsRequestsByActorIdSaga),
            yield takeLatest(SUBMIT_RISK_OPS_QA_REVIEW, submitRiskOpsQaReviewSaga),
        ],
    );
}

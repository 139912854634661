/**
 * @file Saga for SalaryAccountOps
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getErrMsg, getErrorObjectForSaga, getQueryStringFromObject, isNilOrEmpty, showErrorToastMsg, toastify,
} from '../../utils';
import {
    SALARY_ACCOUNT_DETAILS_FOR_AGENTS_ENDPOINT, SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS_ENDPOINT, SALARY_ACCOUNT_EMPLOYER_DETAILS_ENDPOINT,
    SALARY_ACCOUNT_ENUMS_ENDPOINT, SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_ENDPOINT, SALARY_ACCOUNT_RAISE_VERIFICATION_CASE_DETAILS_ENDPOINT,
    SALARY_ACCOUNT_RAISE_VERIFICATION_ELIGIBLE_TXNS_ENDPOINT, SALARY_ACCOUNT_VERIFICATION_EMPLOYER_DETAILS_ENDPOINT,
    SALARY_ACCOUNT_VERIFICATION_REQUESTS_ENDPOINT, SALARY_ACCOUNT_VERIFICATION_REQUESTS_FOR_USER_ENDPOINT,
    SALARY_ACCOUNT_USER_HEALTH_INSURANCE_POLICY_DETAILS_ENDPOINT, SALARY_ACCOUNT_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS_ENDPOINT,
    SALARY_ACCOUNT_ACTIVATION_STATUS_ENDPOINT,
    SALARY_ACCOUNT_VERIFICATION_TXN_DETAILS,
    SALARY_ACCOUNT_VERIFICATION_REQUESTS_COUNT_ENDPOINT,
    SALARY_ACCOUNT_MANDATES_ENDPOINT,
    SALARY_ACCOUNT_EXECUTED_MANDATES_ENDPOINT,
} from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_EMPLOYER_DETAILS_SALARY_ACCOUNT, GET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS, GET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS, GET_SALARY_OPS_ENUMS,
    GET_SALARY_VERIFICATION_CASE_EMPLOYER_DETAILS, SALARY_ACCOUNT_OPS_KEY, UPDATE_EMPLOYER_DETAILS_SALARY_ACCOUNT,
    UPDATE_SALARY_VERIFICATION_CASE_STATUS, VIEW_SALARY_ACCOUNT_DETAILS, VIEW_VERIFICATION_CASES, GET_SALARY_VERIFICATION_CASES,
    GET_SALARY_VERIFICATION_REQUESTS_FOR_USER, GET_SALARY_EMPLOYER_DETAILS_FOR_USER, GET_RAISE_VERIFICATION_REQUEST_CASES,
    GET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS, GET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS, UPDATE_RAISE_VERIFICATION_REQUEST_STATUS,
    VIEW_RAISE_VERIFICATION_CASES, VIEW_RAISE_VERIFICATION_SINGLE_CASE, GET_USER_HEALTH_INSURANCE_POLICY_DETAILS,
    GET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS, GET_SALARY_ACTIVATION_STATUS, GET_SALARY_VERIFICATION_CASE_TXN_HISTORY,
    GET_SALARY_VERIFICATION_CASES_COUNT, GET_SALARY_ACCOUNT_MANDATES, GET_SALARY_ACCOUNT_EXECUTED_MANDATES, GET_SALARY_OPS_EMPLOYER_LIST,
} from './constants';
import {
    setEmployerDetailsAction, setErrInViewSalaryAccountDetailsAction, setSalaryAccountOpsCurViewAction, setSalaryAccountDetailsForDataOpsAction,
    setErrInSalaryOpsAction, successfullyUpdatedEmployerDetailsAction, setSalaryOpsEnumsAction, setVerificationCasesAction,
    setVerificationCaseEmployerDetailsAction, successfullyUpdatedSalaryVerificationCaseStatusAction, setSalaryAccountDetailsForAgentsAction,
    setSalaryVerificationRequestsForUserAction, setSalaryEmployerDetailsForUserAction, setRaiseSalaryVerificationCasesAction,
    setRaiseVerificationRequestCaseDetailsAction, setRaiseVerificationRequestEligibleTxnsAction, updateRaiseVerificationRequestStatusSuccessAction,
    setUserHealthInsurancePolicyDetailsAction, setGeneralHealthInsurancePolicyDetailsAction, setSalaryActivationStatusAction,
    setErrInGeneralHealthInsurancePolicyDetailsAction, setErrInUserHealthInsurancePolicyDetailsAction, setErrInSalaryActivationStatusAction,
    setVerificationCaseTxnHistoryAction, setVerificationCasesCountAction, setSalaryAccountMandatesAction, setErrInViewSalaryAccountMandatesAction,
    setErrInViewSalaryAccountExecutedMandatesAction, setSalaryAccountExecutedMandatesAction, setSalaryOpsEmployerListAction,
    errSalaryOpsEmployerListAction,
} from './actions';

function* getSalaryAccountDetailsForDataOpsSaga(action) {
    const { screenKey, subScreen, userIdentifier } = action.data;
    const queryString = getQueryStringFromObject(userIdentifier, true);

    try {
        const salaryData = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS_ENDPOINT}?${queryString}`,
        );

        yield put(setSalaryAccountDetailsForDataOpsAction({ screenKey, salaryData }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInViewSalaryAccountDetailsAction({ screenKey, subScreen, err: getErrMsg(e) }));
    }
}

function* getSalaryAccountDetailsForAgentsSaga(action) {
    const { screenKey, subScreen, payload } = action.data;
    const { ticketId, userId, index } = payload;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_DETAILS_FOR_AGENTS_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put(setSalaryAccountDetailsForAgentsAction({ screenKey, salaryData: response }));
        } else if (result === 'auth-expired') {
            yield put(setErrInViewSalaryAccountDetailsAction({ screenKey, subScreen, err: 'Auth Expired' }));
        } else {
            yield put(setErrInViewSalaryAccountDetailsAction({ screenKey, subScreen, err: 'CX Auth Error' }));
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInViewSalaryAccountDetailsAction({ screenKey, subScreen, err: getErrMsg(e) }));
    }
}

function* getSalaryAccountMandatesSaga(action) {
    const { screenKey, subScreen, payload } = action.data;
    const {
        ticketId, userId, index, sortOrder, prevToken, nextToken,
    } = payload;
    const queryString = getQueryStringFromObject({
        ticketId, userId, sortOrder, prevToken, nextToken,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_MANDATES_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put(setSalaryAccountMandatesAction({ screenKey, data: response }));
        } else if (result === 'auth-expired') {
            yield put(setErrInViewSalaryAccountMandatesAction({ screenKey, subScreen, err: 'Auth Expired' }));
        } else {
            yield put(setErrInViewSalaryAccountMandatesAction({ screenKey, subScreen, err: 'CX Auth Error' }));
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInViewSalaryAccountMandatesAction({ screenKey, subScreen, err: getErrMsg(e) }));
    }
}

function* getSalaryAccountExecutedMandatesSaga(action) {
    const { screenKey, subScreen, payload } = action.data;
    const {
        ticketId, index, userId, sortOrder, prevToken, nextToken, fromDate, toDate, mandateId,
    } = payload;
    const queryString = getQueryStringFromObject({
        ticketId, userId, sortOrder, prevToken, nextToken, fromDate, toDate, mandateId,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_EXECUTED_MANDATES_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put(setSalaryAccountExecutedMandatesAction({ screenKey, data: response }));
        } else if (result === 'auth-expired') {
            yield put(setErrInViewSalaryAccountExecutedMandatesAction({ screenKey, subScreen, err: 'Auth Expired' }));
        } else {
            yield put(setErrInViewSalaryAccountExecutedMandatesAction({ screenKey, subScreen, err: 'CX Auth Error' }));
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInViewSalaryAccountExecutedMandatesAction({ screenKey, subScreen, err: getErrMsg(e) }));
    }
}

function* getSalaryVerificationRequestsForUserSaga(action) {
    const { screenKey, subScreen, payload } = action.data;
    const { index } = payload;
    const queryString = getQueryStringFromObject(payload);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_VERIFICATION_REQUESTS_FOR_USER_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put(setSalaryVerificationRequestsForUserAction({ screenKey, verificationRequests: response }));
        } else if (result === 'auth-expired') {
            yield put(setErrInViewSalaryAccountDetailsAction({ screenKey, subScreen, err: 'Auth Expired' }));
        } else {
            yield put(setErrInViewSalaryAccountDetailsAction({ screenKey, subScreen, err: 'CX Auth Error' }));
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInViewSalaryAccountDetailsAction({ screenKey, subScreen, err: getErrMsg(e) }));
    }
}

function* getEmployerDetailsSaga(action) {
    const { screenKey, payload: { searchString } } = action.data;
    const queryString = getQueryStringFromObject({ searchString });

    try {
        const { employer_details: employerDetails } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_EMPLOYER_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put(setEmployerDetailsAction(employerDetails));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInSalaryOpsAction({ screenKey, err: getErrMsg(e) }));
    }
}

function* updateEmployerDetailsSaga(action) {
    const {
        payload: { employerId, emailId, userIdentifier }, screenKey,
    } = action.data;

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.put],
            `${SALARY_ACCOUNT_EMPLOYER_DETAILS_ENDPOINT}`,
            { employerId, emailId },
        );

        yield call(getSalaryAccountDetailsForDataOpsSaga, { data: { screenKey: SALARY_ACCOUNT_OPS_KEY, userIdentifier } });
        yield put(setSalaryAccountOpsCurViewAction({ screenKey, value: VIEW_SALARY_ACCOUNT_DETAILS }));

        yield put(successfullyUpdatedEmployerDetailsAction());
        toastify('Updated Successfully', 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInSalaryOpsAction({ screenKey, err: getErrMsg(e) }));
    }
}

function* getSalaryOpsEnumsSaga() {
    try {
        const { enums } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_ENUMS_ENDPOINT}`,
        );

        yield put(setSalaryOpsEnumsAction({ enums }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
    }
}

function* getEmployerListSaga(action) {
    const { searchString } = action.data;
    const queryString = getQueryStringFromObject({ searchString });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_EMPLOYER_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put(setSalaryOpsEmployerListAction(response));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errSalaryOpsEmployerListAction({ err }));
    }
}

function* getVerificationCasesSaga(action) {
    const { screenKey, payload } = action.data;

    const { userIdentifier } = payload;
    let sensitiveParams = '';

    if (!isNilOrEmpty(userIdentifier)) sensitiveParams = getQueryStringFromObject({ userIdentifier }, true);

    delete payload.userIdentifier;
    const nonSensitiveParams = getQueryStringFromObject(payload);

    let queryString = nonSensitiveParams;

    if (sensitiveParams) queryString = `${sensitiveParams}&${nonSensitiveParams}`;

    try {
        const verificationCases = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_VERIFICATION_REQUESTS_ENDPOINT}?${queryString}`,
        );

        yield put(setVerificationCasesAction(verificationCases));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInSalaryOpsAction({ screenKey, err: getErrMsg(e) }));
    }
}

function* getVerificationCasesCountSaga(action) {
    const { screenKey, payload } = action.data;

    const queryString = getQueryStringFromObject(payload);

    try {
        const verificationCases = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_VERIFICATION_REQUESTS_COUNT_ENDPOINT}?${queryString}`,
        );

        yield put(setVerificationCasesCountAction(verificationCases));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInSalaryOpsAction({ screenKey, err: getErrMsg(e) }));
    }
}

function* getEmployerDetailsForATxn(queryString) {
    return yield call(
        [clientApiWrapper, clientApiWrapper.get],
        `${SALARY_ACCOUNT_VERIFICATION_EMPLOYER_DETAILS_ENDPOINT}?${queryString}`,
    );
}

function* getVerificationCaseEmployerDetailsSaga(action) {
    const { screenKey, payload } = action.data;
    const queryString = getQueryStringFromObject(payload);

    try {
        const employerDetails = yield call(getEmployerDetailsForATxn, queryString);

        yield put(setVerificationCaseEmployerDetailsAction(employerDetails));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInSalaryOpsAction({ screenKey, err: getErrMsg(e) }));
    }
}

function* getVerificationCaseTxnHistorySaga(action) {
    const { payload } = action.data;
    const queryString = getQueryStringFromObject(payload);

    try {
        const txnDetails = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_VERIFICATION_TXN_DETAILS}?${queryString}`,
        );

        yield put(setVerificationCaseTxnHistoryAction(txnDetails));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setVerificationCaseTxnHistoryAction({ data: [] }));
    }
}

function* updateSalaryVerificationCaseStatusSaga(action) {
    const { payload, screenKey } = action.data;

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.put],
            `${SALARY_ACCOUNT_VERIFICATION_REQUESTS_ENDPOINT}`,
            payload,
        );

        // clear cases from reducer so that new cases are fetched
        yield put(setVerificationCasesAction({}));
        yield put(setSalaryAccountOpsCurViewAction({ screenKey, value: VIEW_VERIFICATION_CASES }));

        yield put(successfullyUpdatedSalaryVerificationCaseStatusAction());
        toastify('Submitted Successfully', 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInSalaryOpsAction({ screenKey, err: getErrMsg(e) }));
    }
}

function* getSalaryEmployerDetailsForUserSaga(action) {
    const { screenKey, subScreen, payload } = action.data;
    const queryString = getQueryStringFromObject(payload);

    try {
        const employerDetails = yield call(getEmployerDetailsForATxn, queryString);

        yield put(setSalaryEmployerDetailsForUserAction({ screenKey, subScreen, employerDetails }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInViewSalaryAccountDetailsAction({ screenKey, subScreen, err: getErrMsg(e) }));
    }
}

function* getRaiseVerificationRequestCasesSaga(action) {
    const { screenKey, payload } = action.data;
    const { userIdentifier } = payload;

    let sensitiveParams = '';
    if (!isNilOrEmpty(userIdentifier)) sensitiveParams = getQueryStringFromObject({ userIdentifier }, true);

    delete payload.userIdentifier;
    const nonSensitiveParams = getQueryStringFromObject(payload);

    let queryString = nonSensitiveParams;
    if (sensitiveParams) queryString = `${sensitiveParams}&${nonSensitiveParams}`;

    try {
        const raiseVerificationRequestCases = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_ENDPOINT}?${queryString}`,
        );

        yield put(setRaiseSalaryVerificationCasesAction(raiseVerificationRequestCases));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInSalaryOpsAction({ screenKey, err: getErrMsg(e) }));
    }
}

function* getRaiseVerificationRequestCaseDetailsSaga(action) {
    const { screenKey, payload } = action.data;
    const { actorId } = payload;

    try {
        const raiseRequestSelectedUserDetails = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${SALARY_ACCOUNT_RAISE_VERIFICATION_CASE_DETAILS_ENDPOINT}`,
            { actorId },
        );

        yield put(setRaiseVerificationRequestCaseDetailsAction(raiseRequestSelectedUserDetails));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInSalaryOpsAction({ screenKey, err: getErrMsg(e) }));
    }
}

function* getRaiseVerificationRequestEligibleTxnsSaga(action) {
    const { screenKey, payload } = action.data;

    try {
        // clear previous txns so that if there is new case without txn, previous txn details will not be shown
        yield put(setRaiseVerificationRequestEligibleTxnsAction({}));

        const raiseVerificationEligibleTxns = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${SALARY_ACCOUNT_RAISE_VERIFICATION_ELIGIBLE_TXNS_ENDPOINT}`,
            { payload },
        );

        yield put(setRaiseVerificationRequestEligibleTxnsAction(raiseVerificationEligibleTxns));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInSalaryOpsAction({ screenKey, err: getErrMsg(e) }));
    }
}

function* updateRaiseVerificationRequestStatusSaga(action) {
    const { screenKey, payload } = action.data;
    const { actorId, txnId, updatedStatus } = payload;

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.put],
            `${SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_ENDPOINT}`,
            { actorId, txnId, updatedStatus },
        );

        yield put(setSalaryAccountOpsCurViewAction({ screenKey, value: VIEW_RAISE_VERIFICATION_CASES }));

        yield put(updateRaiseVerificationRequestStatusSuccessAction());
        toastify('Updated Successfully', 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setErrInSalaryOpsAction({ screenKey, err: getErrMsg(e) }));
        yield put(setSalaryAccountOpsCurViewAction({ screenKey, value: VIEW_RAISE_VERIFICATION_SINGLE_CASE }));
    }
}

function* getUserHealthInsurancePolicyDetailsSaga(action) {
    const { screenKey, payload } = action.data;
    const { index } = payload;
    const queryString = getQueryStringFromObject({ payload });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_USER_HEALTH_INSURANCE_POLICY_DETAILS_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put(setUserHealthInsurancePolicyDetailsAction({ screenKey, ...response.data }));
        } else if (result === 'auth-expired') {
            yield put(getErrorObjectForSaga(setErrInUserHealthInsurancePolicyDetailsAction({ screenKey }), { errMsgForUI: 'Auth Expired' }));
        } else {
            yield put(getErrorObjectForSaga(setErrInUserHealthInsurancePolicyDetailsAction({ screenKey }), { errMsgForUI: 'CX Auth Error' }));
        }
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(setErrInUserHealthInsurancePolicyDetailsAction({ screenKey }), error));
    }
}

function* getGeneralHealthInsurancePolicyDetailsSaga(action) {
    const { screenKey, payload } = action.data;
    const { index } = payload;
    const queryString = getQueryStringFromObject({ payload });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put(setGeneralHealthInsurancePolicyDetailsAction({ screenKey, ...response.data }));
        } else if (result === 'auth-expired') {
            yield put(getErrorObjectForSaga(setErrInGeneralHealthInsurancePolicyDetailsAction({ screenKey }), { errMsgForUI: 'Auth Expired' }));
        } else {
            yield put(getErrorObjectForSaga(setErrInGeneralHealthInsurancePolicyDetailsAction({ screenKey }), { errMsgForUI: 'CX Auth Error' }));
        }
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(setErrInGeneralHealthInsurancePolicyDetailsAction({ screenKey }), error));
    }
}

function* getSalaryActivationStatusSaga(action) {
    const { screenKey, payload } = action.data;
    const { index } = payload;
    const queryString = getQueryStringFromObject({ payload });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SALARY_ACCOUNT_ACTIVATION_STATUS_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put(setSalaryActivationStatusAction({ screenKey, value: response.data.salary_activation_status }));
        } else if (result === 'auth-expired') {
            yield put(getErrorObjectForSaga(setErrInSalaryActivationStatusAction({ screenKey }), { errMsgForUI: 'Auth Expired' }));
        } else {
            yield put(getErrorObjectForSaga(setErrInSalaryActivationStatusAction({ screenKey }), { errMsgForUI: 'CX Auth Error' }));
        }
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(setErrInSalaryActivationStatusAction({ screenKey }), error));
    }
}

export default function* salaryAccountOpsSaga() {
    yield all(
        [
            yield takeLatest(GET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS, getSalaryAccountDetailsForDataOpsSaga),
            yield takeLatest(GET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS, getSalaryAccountDetailsForAgentsSaga),
            yield takeLatest(GET_SALARY_VERIFICATION_REQUESTS_FOR_USER, getSalaryVerificationRequestsForUserSaga),
            yield takeLatest(GET_EMPLOYER_DETAILS_SALARY_ACCOUNT, getEmployerDetailsSaga),
            yield takeLatest(UPDATE_EMPLOYER_DETAILS_SALARY_ACCOUNT, updateEmployerDetailsSaga),
            yield takeLatest(GET_SALARY_OPS_ENUMS, getSalaryOpsEnumsSaga),
            yield takeLatest(GET_SALARY_VERIFICATION_CASES, getVerificationCasesSaga),
            yield takeLatest(GET_SALARY_VERIFICATION_CASE_EMPLOYER_DETAILS, getVerificationCaseEmployerDetailsSaga),
            yield takeLatest(GET_SALARY_VERIFICATION_CASE_TXN_HISTORY, getVerificationCaseTxnHistorySaga),
            yield takeLatest(UPDATE_SALARY_VERIFICATION_CASE_STATUS, updateSalaryVerificationCaseStatusSaga),
            yield takeLatest(GET_SALARY_EMPLOYER_DETAILS_FOR_USER, getSalaryEmployerDetailsForUserSaga),
            yield takeLatest(GET_RAISE_VERIFICATION_REQUEST_CASES, getRaiseVerificationRequestCasesSaga),
            yield takeLatest(GET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS, getRaiseVerificationRequestCaseDetailsSaga),
            yield takeLatest(GET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS, getRaiseVerificationRequestEligibleTxnsSaga),
            yield takeLatest(UPDATE_RAISE_VERIFICATION_REQUEST_STATUS, updateRaiseVerificationRequestStatusSaga),
            yield takeLatest(GET_USER_HEALTH_INSURANCE_POLICY_DETAILS, getUserHealthInsurancePolicyDetailsSaga),
            yield takeLatest(GET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS, getGeneralHealthInsurancePolicyDetailsSaga),
            yield takeLatest(GET_SALARY_ACTIVATION_STATUS, getSalaryActivationStatusSaga),
            yield takeLatest(GET_SALARY_VERIFICATION_CASES_COUNT, getVerificationCasesCountSaga),
            yield takeLatest(GET_SALARY_ACCOUNT_MANDATES, getSalaryAccountMandatesSaga),
            yield takeLatest(GET_SALARY_ACCOUNT_EXECUTED_MANDATES, getSalaryAccountExecutedMandatesSaga),
            yield takeLatest(GET_SALARY_OPS_EMPLOYER_LIST, getEmployerListSaga),
        ],
    );
}

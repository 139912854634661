import { createSelector } from 'reselect';

const selectDeposits = state => state.get('deposits');

const makeSelectSearchParameters = () => createSelector(
    state => state.getIn(['deposits', 'searchParameters']),
    substate => substate.toJS(),
);

const makeSelectDepositsInfo = () => createSelector(
    state => state.getIn(['deposits', 'depositsInfo']),
    substate => substate.toJS(),
);

export {
    makeSelectSearchParameters,
    makeSelectDepositsInfo,
};

/**
 * @file ColorFormatCell
 */

import React from 'react';
import './style.scss';

const ColorFormatCell = (props) => {
    const {
        column, row, colorCodes, children,
    } = props;

    const value = (row[column.key] || ''); // cell value
    const { colors, variant = 'text' } = colorCodes;
    let colorObj;
    if (typeof colors === 'function') {
        colorObj = colors(row) || {}; // color function to get colors
    } else {
        colorObj = colors[value] || {}; // colur object from colors which matches the value
    }

    let style;

    if (colorObj) { // enters when colorObj is valid
        if (variant === 'contained') {
            style = { color: '#000', backgroundColor: colorObj.value };
        } else {
            style = { color: colorObj.value };
        }
    }

    return <div style={style} className='rdgrid-cf'>{children || value}</div>;
};

export default ColorFormatCell;

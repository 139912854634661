/*
 *
 * DevActions actions
 *
 */

import {
    GET_ACTION_LIST, SET_ACTION_LIST, GET_PARAMETER_LIST, SET_PARAMETER_VALUE, EXECUTE_ACTION,
    SET_MONORAIL_ID, SET_PARAMETER_VALUE_V2,
} from './constants';

export const getActionList = (data) => ({
    type: GET_ACTION_LIST,
    data,
});

export const setActionList = (data) => ({
    type: SET_ACTION_LIST,
    data,
});

export const getParameterList = (data) => ({
    type: GET_PARAMETER_LIST,
    data,
});

export const setParameterValue = (data) => ({
    type: SET_PARAMETER_VALUE,
    data,
});

export const executeAction = (data) => ({
    type: EXECUTE_ACTION,
    data,
});

export const setMonorailId = (data) => ({
    type: SET_MONORAIL_ID,
    data,
});

export const setParameterValueV2 = (data) => ({
    type: SET_PARAMETER_VALUE_V2,
    data,
});

/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';

import riskOps from './containers/RiskOps/reducer';
import devActions from './containers/DevActions/reducer';
import userRiskReview from './containers/UserRiskReview/reducer';
import logsInfoReducer from './containers/LogsInfo/reducer';
import transactionQueue from './containers/TransactionQueue/reducer';
import caseSearch from './containers/CaseSearch/reducer';

export const history = createBrowserHistory();

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(
    injectedReducers,
    injectedFormReducers = {},
) {
    return combineReducers({
        router: connectRouter(history),
        ...injectedReducers,
        form: formReducer.plugin(injectedFormReducers),
        riskOps,
        devActions,
        userRiskReview,
        transactionQueue,
        caseSearch,
        logs: logsInfoReducer,
    });
}

/**
 *
 * History - Component to render all the different traversed step types along with their responses
 *
 */

import React, { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

import UserListSVG from '../../../assets/images/svgs/user-list.svg';
import EditSVG from '../../../assets/images/svgs/edit.svg';
import { SherlockV2SOPClickedGoBackToHistoricalStep } from '../../../rudderEvents/SherlockV2SOP';
import { Button, Modal as CustomModal } from '../../../components';
import { pushRudderEvent } from '../../../rudderEvents';
import { UserDetailsContext } from '../../../context';

import { STEP_TYPE, CONDITION_TYPE } from '../constants';
import { delSOPNodeAction } from '../actions';
import { FinalStepPropTypes } from '../FinalStep';
import { PictureGuideStepPropTypes } from '../PictureGuideStep';
import { QuestionStepPropTypes } from '../QuestionStep';

const History = (props) => {
    const {
        nodes, isFinal, setCurrentStep, getNextNode, sopId, ticketId,
    } = props;
    const dispatch = useDispatch();
    const [editingNode, setEditingNode] = useState(null);
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);

    // Handles the action of editing specific node answer.
    // It sets the current step index to the step just before the editing step and also deletes the nodes till editing node
    const handleEditNodeAction = (nodeData) => () => {
        // This event is to keep track of the go back to any previous node from the notes section.
        pushRudderEvent(SherlockV2SOPClickedGoBackToHistoricalStep, agentInfo.emailId, {
            sopId,
            ticketId,
        });

        const { index, step } = nodeData || {};
        setCurrentStep(() => index - 1);
        dispatch(delSOPNodeAction({ noOfElements: (nodes.length - index) }));
        if (index > 0) {
            const { response: prevStepResponse, step: prevStep } = nodes[index - 1] || {};
            const { nextNode: { mappings, next_step_condition_type: conditionType } } = prevStep || {};
            // Handles getting the node id of the current node using the node previous to current node.
            if (prevStepResponse && conditionType === CONDITION_TYPE.label) {
                getNextNode(mappings[prevStepResponse]);
            } else {
                getNextNode(mappings?.default);
            }
        } else {
            const { nodeId } = step || {};
            getNextNode(nodeId);
        }
        setEditingNode(null);
    };

    // Renders previous steps or notes for final step according to the step type
    const getStepData = (node, index, isNotes) => {
        const { stepType, step } = node || {};
        let { response } = node || {};
        switch (stepType) {
            case STEP_TYPE.question: {
                const { questionText } = step || {};
                if (Array.isArray(response)) response = response.toString();

                return (
                    <div className='my-15'>
                        <div className='sop-step-hist-step'>
                            {isNotes ? (index + 1) : (nodes.length - index)} . {questionText}
                        </div>
                        {
                            response && (
                                <div className='sop-step-hist-res frcWrapper'>
                                    <div className='p-5'>{response}</div>
                                    {
                                        !isNotes && (
                                            <Button
                                                link
                                                label={<img src={EditSVG} alt='Edit' />}
                                                extClasses='p-0'
                                                // Sending the index of current step after subtraction with the last step index as we are traversing the nodes
                                                // in the reverse direction
                                                onClick={() => setEditingNode({ index: nodes.length - 1 - index, step })}
                                            />
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                );
            }
            case STEP_TYPE.infoDisplay:
            case STEP_TYPE.pictureGuide: {
                const { title } = step || {};
                return (
                    <div className='my-15'>
                        <div className='sop-step-hist-step'>
                            {isNotes ? (index + 1) : (nodes.length - index)} . {title}
                        </div>
                    </div>
                );
            }
            default: {
                return '';
            }
        }
    };

    return (
        <div className='p-30'>
            {
                isFinal ? (
                    <div className='frfsWrapper my-30'>
                        <img src={UserListSVG} alt='User List' className='mr-16' />
                        <div>
                            <div className='sop-step-title'>Notes</div>
                            {
                                nodes && Array.isArray(nodes) && nodes.map((node, idx) => {
                                    if (idx !== nodes.length - 1) return getStepData(node, idx, true);
                                    return '';
                                })
                            }
                        </div>
                    </div>

                ) : (
                    <React.Fragment>
                        <div className='sop-step-hist-title'>Previous Steps</div>
                        {
                            nodes && Array.isArray(nodes) && [...nodes].reverse().map((node, idx) => {
                                if (idx !== 0) return getStepData(node, idx);
                                return '';
                            })
                        }
                    </React.Fragment>
                )
            }
            <CustomModal
                visible={!!editingNode}
            >
                <div className='p-20'>Are you sure you want to continue?</div>
                <div style={{ borderBottom: '1px solid #E6E9ED' }} />
                <div className='p-20'>We will restart the flow from this point. Any progress you made after this step will be reset.</div>
                <div className='frcfeWrapper'>
                    <Button
                        v2
                        secondary
                        label='No, go back'
                        extClasses='mr-16'
                        onClick={() => setEditingNode(null)}
                    />
                    <Button
                        v2
                        primary
                        extClasses='ml-15'
                        label='Yes, continue'
                        onClick={handleEditNodeAction(editingNode)}
                    />
                </div>
            </CustomModal>
        </div>
    );
};

History.propTypes = {
    nodes: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape(QuestionStepPropTypes),
            PropTypes.shape(PictureGuideStepPropTypes),
            PropTypes.shape(FinalStepPropTypes),
        ]),
    ).isRequired,
    isFinal: PropTypes.bool,
    setCurrentStep: PropTypes.func.isRequired,
    getNextNode: PropTypes.func.isRequired,
};

History.defaultProps = {
    isFinal: false,
};

export default History;

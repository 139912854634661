import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
    Button, ExpandableCard, Loader, FormWithSingleInput, Table, FormDropdown,
} from '../../../components';

import '../style.scss';
import { getEligibilityRewards, getEligibilityRewardsDropdownOptions, setEligibilityRewards } from '../actions';
import { makeSelectEligibleRewards } from '../selectors';

const EligibleRewards = (props) => {
    const [isCardExpanded, setIsCardExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [toDate, setToDate] = useState();
    const [extTxnId, setExtTxnId] = useState();
    const [fromDate, setFromDate] = useState();
    const [errLabel, setErrLabel] = useState();
    const [selectedDropdown, setSelectedDropdown] = useState();

    const eligibleRewards = useSelector(makeSelectEligibleRewards(), shallowEqual);

    const { ticketId, userId, index } = props;

    const dispatch = useDispatch();

    let loadingEligibleRewards;
    let loadingEligibleRewardsDropdownData;
    let eligibleRewardsData;
    let eligibleRewardsDropdownData;

    if (eligibleRewards && eligibleRewards[ticketId]) {
        ({
            loading: loadingEligibleRewards, data: eligibleRewardsData,
        } = eligibleRewards[ticketId]);
    }

    if (eligibleRewards && eligibleRewards.dropdownOptions) {
        ({
            loading: loadingEligibleRewardsDropdownData, data: eligibleRewardsDropdownData,
        } = eligibleRewards.dropdownOptions);
    }

    useEffect(() => {
        if (!loadingEligibleRewardsDropdownData || !(eligibleRewards?.dropdownOptions)) {
            dispatch(getEligibilityRewardsDropdownOptions({ ticketId }));
        }
    }, []);

    const makePayloadAndCallApi = () => {
        setIsLoading(true);
        dispatch(getEligibilityRewards({
            ticketId, userId, index, extTxnId, toDate, fromDate, rewardsType: selectedDropdown ? selectedDropdown.value : '',
        }));
        setIsLoading(false);
    };

    const handleSubmit = () => {
        if (!(toDate && fromDate) && !(extTxnId)) {
            setErrLabel('Please fill either dates or external transaction id');
            return;
        }
        if (!selectedDropdown) {
            setErrLabel('Please fill reward offer');
            return;
        }

        makePayloadAndCallApi();

        setErrLabel('');
    };

    /**
     * @function onDateChange
     * @param {*} key it can be toDate or fromDate
     * @returns function which takes e as date object input and sets the date in corresponding state.
     */
    const onDateChange = (key) => (e) => {
        switch (key) {
            case 'toDate': setToDate(new Date(e));
                break;
            case 'fromDate': setFromDate(new Date(e));
                break;
            default: return null;
        }
        return null;
    };

    const clearAllSelection = () => {
        setToDate();
        setFromDate();
        setExtTxnId('');
        setSelectedDropdown({});
        dispatch(setEligibilityRewards({ ticketId }));
    };

    const onExtTxnIdChange = (e) => {
        setExtTxnId(e.target.value);
    };

    /**
     * @function renderDD used to render a dropdown
     * @param {*} options must be an array of objects in which each object has label and value
     * @returns Dropdown to be rendered
     */
    const renderDD = (options, isDDOpenDefault, selectedValue, setSelectedValue) => (
        <FormDropdown
            options={options}
            input={{
                defaultMenuIsOpen: isDDOpenDefault,
                value: selectedValue,
                onChange: (newSelectedItem) => {
                    setSelectedValue(newSelectedItem);
                },
            }}
            cacheKey='devactions-single'
            extStyles={{
                container: 'rewards-dd__ip',
            }}
        />
    );

    return (
        <div className='rewards-cr1'>
            <ExpandableCard
                extClasses='rewards-ec'
                heading='Check for eligible Reward Transactions'
                isExpanded={isCardExpanded}
                setIsExpanded={() => setIsCardExpanded(!isCardExpanded)}
            >
                <div className='rewards-sc'>
                    <div className='frcseWrapper rewards-sc__gap form-container rewards-form-container'>
                        <div className='form-wrapper mr-30'>
                            <div className='form-wrapper-label rewards-dd__lb'>From Date</div>
                            <ReactDatePicker
                                dateFormat='dd-MM-yyyy'
                                className='rewards-dd__ip'
                                selected={fromDate}
                                onChange={onDateChange('fromDate')}
                            />
                        </div>
                        <div className='form-wrapper'>
                            <div className='form-wrapper-label rewards-dd__lb'>To Date</div>
                            <ReactDatePicker
                                dateFormat='dd-MM-yyyy'
                                className='rewards-dd__ip'
                                selected={toDate}
                                onChange={onDateChange('toDate')}
                                minDate={fromDate}
                            />
                        </div>
                    </div>
                    <div className='rewards-sc__gap mb-15 mt-15'>
                        <FormWithSingleInput
                            hideErrLabel
                            disableFormSubmitOnEnter
                            extClasses={{
                                container: 'rewards-fc',
                                label: 'rewards-dd__lb',
                                inputField: 'rewards-dd__ip',
                            }}
                            label='External txn id'
                            formType='text'
                            input={{
                                value: extTxnId,
                                onChange: onExtTxnIdChange,
                            }}
                        />
                    </div>
                    <div className='rewards-sc__gap mb-30'>
                        <div className='frcWrapper'>
                            <div className='rewards-dd__lb mr-16'>Reward Offer *</div>
                            {renderDD(eligibleRewardsDropdownData || [], false, selectedDropdown, setSelectedDropdown)}
                        </div>
                    </div>

                    {errLabel ? <div className='err-label rewards-el'>{errLabel}</div> : null}
                    <div className='frcWrapper rewards-sc__3r'>
                        <Button
                            extClasses='rewards-sc__cta'
                            primary
                            label='Search'
                            onClick={handleSubmit}
                        />
                        <div
                            className='link rewards-sc__clear'
                            onClick={clearAllSelection}
                            role='presentation'
                        >
                            Clear All Selection
                        </div>
                    </div>
                </div>
            </ExpandableCard>
            <Loader visible={isLoading || loadingEligibleRewards || loadingEligibleRewardsDropdownData} />
            {
                eligibleRewardsData && eligibleRewardsData?.rowData && Array.isArray(eligibleRewardsData?.rowData)
                && (
                    eligibleRewardsData?.rowData.length > 0
                        ? (
                            <Table
                                objKey='header_key'
                                nsrLabel={eligibleRewardsData?.tableName || 'Eligible Rewards Transaction'}
                                labelData={eligibleRewardsData?.columnData}
                                contentData={eligibleRewardsData?.rowData}
                                header={eligibleRewardsData?.tableName}
                                extClasses={{
                                    container: 'mt-30',
                                }}
                            />
                        ) : <div className='frccWrapper mt-30'>No data available for given parameters!!!</div>
                )
            }
        </div>
    );
};

export default EligibleRewards;

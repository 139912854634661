/**
 * @file Manages the selector functions for Case Search container
 */

import { createSelector } from 'reselect';

import { CASE_SEARCH_KEY, REDUCER_KEYS } from './constants';

export const selectCaseSearchDetails = createSelector(
    (state) => state.getIn([CASE_SEARCH_KEY, REDUCER_KEYS.CASE_SEARCH_FILE_ENTRIES]),
    (substate) => substate?.toJS(),
);

export const selectCaseSearchLoader = createSelector(
    (state) => state.getIn([CASE_SEARCH_KEY, REDUCER_KEYS.CASE_SEARCH_LOADING]),
    (substate) => substate,
);

export const selectCaseSearchError = createSelector(
    (state) => state.getIn([CASE_SEARCH_KEY, REDUCER_KEYS.CASE_SEARCH_ERROR]),
    (subState) => subState,
);

/*
 *
 * AdminActions reducer
 *
 */

import { fromJS } from 'immutable';
import {
    GET_ACTION_LIST, SET_ACTION_LIST, ERR_ACTION_LIST, GET_PARAMETER_LIST,
    SET_PARAMETER_LIST, SET_PARAMETER_VALUE, ERR_PARAMETER_LIST, EXECUTE_ACTION,
    SUCCESS_EXECUTE_ACTION, ERR_EXECUTE_ACTION,
} from './constants';

const initialState = fromJS({
    loading: false,
    err: '',
    actionList: [],
    parameterList: [],
    hasExecuteInfo: false,
    executeInfo: [],
});

function adminActionsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ACTION_LIST:
        case GET_PARAMETER_LIST:
        case EXECUTE_ACTION:
            return state
                .set('loading', true)
                .set('err', '');

        case SET_ACTION_LIST: {
            const { actionList } = action.data;

            return state
                .set('loading', false)
                .set('actionList', fromJS(actionList));
        }

        case ERR_ACTION_LIST:
            return state.set('loading', false);

        case SET_PARAMETER_LIST: {
            const { parameterList } = action.data;

            const modifiedParameterList = parameterList.map(item => {
                const modifiedItem = {
                    ...item,
                    value: '',
                };

                if (item.type === 5) { // DROPDOWN
                    modifiedItem.isDDOpen = false;
                } else if (item.type === 7) { // NAME
                    modifiedItem.value = {
                        first_name: '',
                        last_name: '',
                    };
                } else if (item.type === 9) { // MULTI_SELECT_DROPDOWN
                    modifiedItem.options = modifiedItem.options.map(item => ({
                        label: item,
                        value: item,
                    }));
                    modifiedItem.value = [];
                }

                return modifiedItem;
            });

            return state
                .set('loading', false)
                .set('parameterList', fromJS(modifiedParameterList));
        }

        case ERR_PARAMETER_LIST:
            return state
                .set('loading', false)
                .set('parameterList', fromJS([]));

        case SET_PARAMETER_VALUE: {
            const { item, key, value, dd } = action.data;

            const parameterList = state.get('parameterList').toJS();
            const itemIdx = parameterList.findIndex(lItem => lItem.name === item.name);

            let modifiedParameterList = [...parameterList];

            if (item.type === 5) { // DROPDOWN
                modifiedParameterList[itemIdx] = {
                    ...modifiedParameterList[itemIdx],
                    isDDOpen: dd,
                    value: value || modifiedParameterList[itemIdx].value
                };
            } else if (item.type === 7) { // NAME
                modifiedParameterList[itemIdx] = {
                    ...modifiedParameterList[itemIdx],
                    value: {
                        ...modifiedParameterList[itemIdx].value,
                        [key]: value
                    }
                };
            } else {
                modifiedParameterList[itemIdx] = {
                    ...modifiedParameterList[itemIdx],
                    value,
                };
            }

            return state.set('parameterList', fromJS(modifiedParameterList));
        }

        case SUCCESS_EXECUTE_ACTION: {
            const { executeInfo } = action.data;

            return state
                .set('loading', false)
                .set('hasExecuteInfo', true)
                .set('executeInfo', fromJS(executeInfo || []));
        }

        case ERR_EXECUTE_ACTION: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err)
                .set('hasExecuteInfo', false)
                .set('executeInfo', fromJS([]));
        }

        default:
            return state;
    }
}

export default adminActionsReducer;

/*
 *
 * Pan name match review actions
 *
 */

import {
    GET_NAMEMATCH_CASES, DEQUEUE_NAMEMATCH_CASE, SET_NAMEMATCH_VIEW, SET_CUR_NAMEMATCH_CASE,
    SET_NAMEMATCH_PAYLOAD_TYPE, GET_NAMEMATCH_PENDING_REVIEW_COUNT, SET_NAMEMATCH_QUEUE_FILTERS,
} from './constants';

export const getNamematchCasesAction = (data) => ({
    type: GET_NAMEMATCH_CASES,
    data,
});

export const dequeueNamematchCaseAction = (data) => ({
    type: DEQUEUE_NAMEMATCH_CASE,
    data,
});

export const setNamematchViewAction = (data) => ({
    type: SET_NAMEMATCH_VIEW,
    data,
});

export const setCurNamematchCaseAction = (data) => ({
    type: SET_CUR_NAMEMATCH_CASE,
    data,
});

export const setNamematchPayloadTypeAction = (data) => ({
    type: SET_NAMEMATCH_PAYLOAD_TYPE,
    data,
});

export const getNamematchPendingReviewCountAction = (data) => ({
    type: GET_NAMEMATCH_PENDING_REVIEW_COUNT,
    data,
});

export const setNamematchQueueFiltersAction = (data) => ({
    type: SET_NAMEMATCH_QUEUE_FILTERS,
    data,
});

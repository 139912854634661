export const GET_ACTIVE_REWARDS = 'app/Rewards/GET_ACTIVE_REWARDS';
export const SET_ACTIVE_REWARDS = 'app/Rewards/SET_ACTIVE_REWARDS';
export const ERR_ACTIVE_REWARDS = 'app/Rewards/ERR_ACTIVE_REWARDS';

export const GET_ACTIVE_OFFERS = 'app/Rewards/GET_ACTIVE_OFFERS';
export const SET_ACTIVE_OFFERS = 'app/Rewards/SET_ACTIVE_OFFERS';
export const ERR_ACTIVE_OFFERS = 'app/Rewards/ERR_ACTIVE_OFFERS';

export const GET_USER_REWARDS = 'app/Rewards/GET_USER_REWARDS';
export const SET_USER_REWARDS = 'app/Rewards/SET_USER_REWARDS';
export const ERR_USER_REWARDS = 'app/Rewards/ERR_USER_REWARDS';

export const GET_USER_REDEEMED_OFFERS = 'app/Rewards/GET_USER_REDEEMED_OFFERS';
export const SET_USER_REDEEMED_OFFERS = 'app/Rewards/SET_USER_REDEEMED_OFFERS';
export const ERR_USER_REDEEMED_OFFERS = 'app/Rewards/ERR_USER_REDEEMED_OFFERS';

export const GET_USER_TOTAL_REWARDS = 'app/Rewards/GET_USER_TOTAL_REWARDS';
export const SET_USER_TOTAL_REWARDS = 'app/Rewards/SET_USER_TOTAL_REWARDS';
export const ERR_USER_TOTAL_REWARDS = 'app/Rewards/ERR_USER_TOTAL_REWARDS';

export const GET_USER_REWARD_INFO = 'app/Rewards/GET_USER_REWARD_INFO';
export const SET_USER_REWARD_INFO = 'app/Rewards/SET_USER_REWARD_INFO';
export const ERR_USER_REWARD_INFO = 'app/Rewards/ERR_USER_REWARD_INFO';

export const SET_SEARCH_PARAMS_FOR_CLAIMS_VIEW = 'app/Rewards/SET_SEARCH_PARAMS_FOR_CLAIMS_VIEW';
export const RESET_SEARCH_PARAMS_FOR_CLAIMS_VIEW = 'app/Rewards/RESET_SEARCH_PARAMS_FOR_CLAIMS_VIEW';

// search params for active view
export const SET_SEARCH_PARAMS_FOR_ACTIVE_VIEW = 'app/Rewards/SET_SEARCH_PARAMS_FOR_ACTIVE_VIEW';
export const RESET_SEARCH_PARAMS_FOR_ACTIVE_VIEW = 'app/Rewards/RESET_SEARCH_PARAMS_FOR_ACTIVE_VIEW';

// active exchanger offers for all users
export const GET_ACTIVE_EXCHANGER_OFFERS = 'app/Rewards/GET_ACTIVE_EXCHANGER_OFFERS';
export const SET_ACTIVE_EXCHANGER_OFFERS = 'app/Rewards/SET_ACTIVE_EXCHANGER_OFFERS';
export const ERR_ACTIVE_EXCHANGER_OFFERS = 'app/Rewards/ERR_ACTIVE_EXCHANGER_OFFERS';

// redeemed exchanger offers by user
export const GET_USER_REDEEMED_EXCHANGER_OFFERS = 'app/Rewards/GET_USER_REDEEMED_EXCHANGER_OFFERS';
export const SET_USER_REDEEMED_EXCHANGER_OFFERS = 'app/Rewards/SET_USER_REDEEMED_EXCHANGER_OFFERS';
export const ERR_USER_REDEEMED_EXCHANGER_OFFERS = 'app/Rewards/ERR_USER_REDEEMED_EXCHANGER_OFFERS';

// eligibility rewards dropdown options
export const GET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS = 'app/Rewards/GET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS';
export const SET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS = 'app/Rewards/SET_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS';
export const ERR_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS = 'app/Rewards/ERR_ELIGIBLE_REWARDS_DROPDOWN_OPTIONS';

// eligibility rewards by ext txn id
export const GET_ELIGIBLE_REWARDS = 'app/Rewards/GET_ELIGIBLE_REWARDS';
export const SET_ELIGIBLE_REWARDS = 'app/Rewards/SET_ELIGIBLE_REWARDS';
export const ERR_ELIGIBLE_REWARDS = 'app/Rewards/ERR_ELIGIBLE_REWARDS';

// entity meta
export const SET_ENTITY_META = 'app/Rewards/SET_ENTITY_META';
export const SET_ACTIVE_VIEW_ENTITY_META = 'app/Rewards/SET_ACTIVE_VIEW_ENTITY_META';
export const SET_CLAIMS_VIEW_ENTITY_META = 'app/Rewards/SET_CLAIMS_VIEW_ENTITY_META';

export const GET_FI_STORE_REWARDS = 'app/Rewards/GET_FI_STORE_REWARDS';
export const SET_FI_STORE_REWARDS = 'app/Rewards/SET_FI_STORE_REWARDS';
export const ERR_FI_STORE_REWARDS = 'app/Rewards/ERR_FI_STORE_REWARDS';

export const GET_FI_STORE_REWARD_DETAILS = 'app/Rewards/GET_FI_STORE_REWARD_DETAILS';
export const SET_FI_STORE_REWARD_DETAILS = 'app/Rewards/SET_FI_STORE_REWARD_DETAILS';
export const ERR_FI_STORE_REWARD_DETAILS = 'app/Rewards/ERR_FI_STORE_REWARD_DETAILS';

export const ACTIVE_VIEW_SEARCH_PARAMS_KEYS = {
    CATEGORY: 'category',
    OFFER_REDEMPTION_MODE: 'offerRedemptionMode',
    REWARDS_APPLICABLE_TO: 'rewardsApplicableTo',
    CARD_OFFER_TYPE: 'cardOfferType',
};

export const CLAIMS_VIEW_SEARCH_PARAMS_KEYS = {
    CATEGORY: 'category',
    OFFER_TYPE: 'offerType',
    OFFER_VENDOR: 'offerVendor',
    OFFER_REDEMPTION_STATE: 'offerRedemptionState',
    EXPIRY_STATUS: 'expiryStatus',
    REWARD_TYPE: 'rewardType',
    VISIBILITY_TYPE: 'visibilityType',
    EXCHANGER_OFFER_REDEMPTION_STATE: 'exchangerOfferRedemptionState',
};

export const CATEGORY_MAP = {
    offers: 'Offers',
    rewards: 'Rewards',
    exchangerOffers: 'Exchanger Offers',
    fiStore: 'Fi-Store',
};

export const CATEGORY = [
    {
        label: 'Offers',
        value: 'Offers',
    },
    {
        label: 'Rewards',
        value: 'Rewards',
    },
    {
        label: 'Exchanger Offers',
        value: 'Exchanger Offers',
    },
];
export const CLAIMS_CATEGORY = [
    ...CATEGORY,
    {
        label: 'Fi-Store',
        value: 'Fi-Store',
    },
];

export const OFFER_REDEMPTION_MODE = [
    {
        label: 'Fi Coins',
        value: 'FI_COINS',
    },
    {
        label: 'Fi Card',
        value: 'FI_CARD',
    },
];

export const APPLICATION_TO_OPTIONS = [
    {
        label: 'All',
        value: 'ALL',
    },
    {
        label: 'User',
        value: 'USER',
    },
];

export const CARD_OFFER_TYPE = [
    {
        label: 'EPIFI',
        value: 'CARD_OFFER_TYPE_EPIFI',
    },
    {
        label: 'Partnership',
        value: 'CARD_OFFER_TYPE_PARTNERSHIP',
    },
    {
        label: 'Visa',
        value: 'CARD_OFFER_TYPE_VISA',
    },
];
// TODO: Make Rewards Options BE driven (https://monorail.pointz.in/p/fi-app/issues/detail?id=74177)
export const OFFER_TYPE = [
    {
        label: 'Gift Card',
        value: 'GIFT_CARD',
    },
    {
        label: 'Coupon',
        value: 'COUPON',
    },
    {
        label: 'Subscription',
        value: 'SUBSCRIPTION',
    },
    {
        label: 'Charity',
        value: 'CHARITY',
    },
    {
        label: 'Physical Merchandise',
        value: 'PHYSICAL_MERCHANDISE',
    },
    {
        label: 'Power Up',
        value: 'POWER_UP',
    },
    {
        label: 'Thriwe Benefits Package',
        value: 'THRIWE_BENEFITS_PACKAGE',
    },
    {
        label: 'Vistara Air Miles',
        value: 'VISTARA_AIR_MILES',
    },
    {
        label: 'Cms Coupon',
        value: 'CMS_COUPON',
    },
    {
        label: 'Lounge Access',
        value: 'LOUNGE_ACCESS',
    },
    {
        label: 'External Vendor',
        value: 'EXTERNAL_VENDOR',
    },
    {
        label: 'Club Itc Green Points',
        value: 'CLUB_ITC_GREEN_POINTS',
    },
];

export const OFFER_VENDOR = [
    {
        label: 'LOYLTY',
        value: 'LOYLTY',
    },
    {
        label: 'NAAGIN_SAUCE_OFFLINE',
        value: 'NAAGIN_SAUCE_OFFLINE',
    },
    {
        label: 'MONSOON_HARVEST_OFFLINE',
        value: 'MONSOON_HARVEST_OFFLINE',
    },
    {
        label: 'BLUE_TOKAI_OFFLINE',
        value: 'BLUE_TOKAI_OFFLINE',
    },
    {
        label: 'DHORA_OFFLINE',
        value: 'DHORA_OFFLINE',
    },
    {
        label: 'SOCK_SOHO_OFFLINE',
        value: 'SOCK_SOHO_OFFLINE',
    },
    {
        label: 'TRUE_ELEMENTS_OFFLINE',
        value: 'TRUE_ELEMENTS_OFFLINE',
    },
    {
        label: 'WE_WORK_OFFLINE',
        value: 'WE_WORK_OFFLINE',
    },
    {
        label: 'TEA_TRUNK_OFFLINE',
        value: 'TEA_TRUNK_OFFLINE',
    },
    {
        label: 'LIVING_FOOD_OFFLINE',
        value: 'LIVING_FOOD_OFFLINE',
    },
    {
        label: 'WHOLE_TRUTH_OFFLINE',
        value: 'WHOLE_TRUTH_OFFLINE',
    },
    {
        label: 'GENERIC_OFFLINE_VENDOR',
        value: 'GENERIC_OFFLINE_VENDOR',
    },
    {
        label: 'THRIWE',
        value: 'THRIWE',
    },
    {
        label: 'VISTARA',
        value: 'VISTARA',
    },
    {
        label: 'IN_HOUSE',
        value: 'IN_HOUSE',
    },
    {
        label: 'DREAMFOLKS',
        value: 'DREAMFOLKS',
    },
    {
        label: 'DPANDA',
        value: 'DPANDA',
    },
    {
        label: 'POSHVINE',
        value: 'POSHVINE',
    },
    {
        label: 'ITC',
        value: 'ITC',
    },
];

export const OFFER_REDEMPTION_STATE = [
    {
        label: 'OFFER_REDEMPTION_INITIATED',
        value: 'OFFER_REDEMPTION_INITIATED',
    },
    {
        label: 'PURCHASED_OFFER_FROM_INVENTORY',
        value: 'PURCHASED_OFFER_FROM_INVENTORY',
    },
    {
        label: 'PURCHASE_FROM_INVENTORY_FAILED',
        value: 'PURCHASE_FROM_INVENTORY_FAILED',
    },
    {
        label: 'AMOUNT_DEBITED_FROM_ACCOUNT',
        value: 'AMOUNT_DEBITED_FROM_ACCOUNT',
    },
    {
        label: 'AMOUNT_DEBIT_FAILED',
        value: 'AMOUNT_DEBIT_FAILED',
    },
    {
        label: 'VENDOR_REDEMPTION_SUCCESSFUL',
        value: 'VENDOR_REDEMPTION_SUCCESSFUL',
    },
    {
        label: 'VENDOR_REDEMPTION_FAILED',
        value: 'VENDOR_REDEMPTION_FAILED',
    },
    {
        label: 'VENDOR_REDEMPTION_PENDING_UPDATE',
        value: 'VENDOR_REDEMPTION_PENDING_UPDATE',
    },
    {
        label: 'VENDOR_REDEMPTION_MANUAL_INTERVENTION',
        value: 'VENDOR_REDEMPTION_MANUAL_INTERVENTION',
    },
    {
        label: 'DEBITED_AMOUNT_REVERSED',
        value: 'DEBITED_AMOUNT_REVERSED',
    },
    {
        label: 'PURCHASE_FROM_INVENTORY_RETURNED',
        value: 'PURCHASE_FROM_INVENTORY_RETURNED',
    },
    {
        label: 'OFFER_REDEMPTION_FAILED',
        value: 'OFFER_REDEMPTION_FAILED',
    },
    {
        label: 'OFFER_REDEMPTION_SUCCESSFUL',
        value: 'OFFER_REDEMPTION_SUCCESSFUL',
    },
];

export const EXPIRY_STATUS = [
    {
        label: 'Expired',
        value: 'EXPIRED',
    },
    {
        label: 'Not Expired',
        value: 'NOT_EXPIRED',
    },
];

export const REWARD_TYPE = [
    {
        label: 'No Reward',
        value: 'NO_REWARD',
    },
    {
        label: 'CASH',
        value: 'CASH',
    },
    {
        label: 'Fi Coins',
        value: 'FI_COINS',
    },
    {
        label: 'Lucky Draw',
        value: 'LUCKY_DRAW',
    },
    {
        label: 'Smart Deposit',
        value: 'SMART_DEPOSIT',
    },
    {
        label: 'Gift Hamper',
        value: 'GIFT_HAMPER',
    },
    {
        label: 'Metal Credit Card',
        value: 'METAL_CREDIT_CARD',
    },
    {
        label: 'EGV Basket',
        value: 'EGV_BASKET',
    },
    {
        label: 'Thriwe Benefits Package',
        value: 'THRIWE_BENEFITS_PACKAGE',
    },
];

export const VISIBILITY_TYPE = [
    {
        label: 'Visible',
        value: 'VISIBLE',
    },
    {
        label: 'Hidden',
        value: 'HIDDEN',
    },
];

export const EXCHANGER_OFFER_REDEMPTION_STATE = [
    {
        label: 'CREATED',
        value: 'ORDER_STATE_CREATED',
    },
    {
        label: 'OFFER_AMOUNT_DEBITED',
        value: 'ORDER_STATE_OFFER_AMOUNT_DEBITED',
    },
    {
        label: 'OFFER_AMOUNT_DEBIT_FAILED',
        value: 'ORDER_STATE_OFFER_AMOUNT_DEBIT_FAILED',
    },
    {
        label: 'REWARD_OPTIONS_GENERATED',
        value: 'ORDER_STATE_REWARD_OPTIONS_GENERATED',
    },
    {
        label: 'REWARD_OPTION_CHOSEN',
        value: 'ORDER_STATE_REWARD_OPTION_CHOSEN',
    },
    {
        label: 'REWARD_OPTIONS_GENERATION_FAILED',
        value: 'ORDER_STATE_REWARD_OPTIONS_GENERATION_FAILED',
    },
    {
        label: 'USER_INPUT_NEEDED_FOR_FULFILLMENT',
        value: 'ORDER_STATE_USER_INPUT_NEEDED_FOR_FULFILLMENT',
    },
    {
        label: 'REWARD_FULFILLMENT_INITIATED',
        value: 'ORDER_STATE_REWARD_FULFILLMENT_INITIATED',
    },
    {
        label: 'REWARD_FULFILLED',
        value: 'ORDER_STATE_REWARD_FULFILLED',
    },
    {
        label: 'REVERSED_DEBITED_OFFER_AMOUNT',
        value: 'ORDER_STATE_REVERSED_DEBITED_OFFER_AMOUNT',
    },
    {
        label: 'FULFILLMENT_MANUAL_INTERVENTION',
        value: 'ORDER_STATE_FULFILLMENT_MANUAL_INTERVENTION',
    },
    {
        label: 'REDEMPTION_COUNT_UPDATED',
        value: 'ORDER_STATE_REDEMPTION_COUNT_UPDATED',
    },
    {
        label: 'REDEMPTION_FAILED',
        value: 'ORDER_STATE_REDEMPTION_FAILED',
    },
];

export const ACTIVE_REWARDS_LABEL_DATA = [
    {
        id: 1,
        label: 'Reward Offer Id',
        key: 'id',
    },
    {
        id: 2,
        label: 'Title',
        key: 'title',
    },
    {
        id: 3,
        label: 'Active Since',
        key: 'activeSince',
    },
    {
        id: 4,
        label: 'Active Till',
        key: 'activeTill',
    },
    {
        id: 5,
        label: 'Display Since',
        key: 'displaySince',
    },
    {
        id: 6,
        label: 'Display Till',
        key: 'displayTill',
    },
];

export const ACTIVE_OFFERS_LABEL_DATA = [
    {
        id: 1,
        label: 'Offer Id',
        key: 'id',
        width: 150,
        extClasses: 'rewards-tblv2-col',
    },
    {
        id: 2,
        label: 'Name',
        key: 'name',
        width: 150,
        extClasses: 'rewards-tblv2-col',
    },
    {
        id: 3,
        label: 'Description',
        key: 'desc',
        width: 250,
        extClasses: 'rewards-tblv2-col',
    },
    {
        id: 4,
        label: 'Price',
        key: 'price',
    },
    {
        id: 5,
        label: 'Active Since',
        key: 'activeSince',
    },
    {
        id: 6,
        label: 'Active Till',
        key: 'activeTill',
    },
    {
        id: 7,
        label: 'Display Since',
        key: 'displaySince',
    },
    {
        id: 8,
        label: 'Display Till',
        key: 'displayTill',
    },
];

export const ACTIVE_EXCHANGER_OFFERS_LABEL_DATA = [
    {
        id: 1,
        label: 'Exchanger Offer Id',
        key: 'id',
        width: 150,
        extClasses: 'rewards-tblv2-col',
    },
    {
        id: 2,
        label: 'Offer Title',
        key: 'name',
        width: 200,
        extClasses: 'rewards-tblv2-col',
    },
    {
        id: 3,
        label: 'Offer Description',
        key: 'desc',
        width: 200,
        extClasses: 'rewards-tblv2-col',
    },
    {
        id: 4,
        label: 'Price',
        key: 'price',
        width: 50,
        extClasses: 'rewards-tblv2-col',
    },
    {
        id: 5,
        label: 'Daily Attempts',
        key: 'dailyAttempts',
        maxWidth: 150,
        extClasses: 'rewards-tblv2-col',
    },
    {
        id: 6,
        label: 'Active Since',
        key: 'activeSince',
    },
    {
        id: 7,
        label: 'Active Till',
        key: 'activeTill',
    },
    {
        id: 8,
        label: 'Display Since',
        key: 'displaySince',
    },
    {
        id: 9,
        label: 'Display Till',
        key: 'displayTill',
    },
];

export const CLAIMED_OFFERS_LABEL_DATA = [
    {
        id: 1,
        label: 'Redeemed\nOffer Id',
        key: 'id',
    },
    {
        id: 2,
        label: 'Offer\nId',
        key: 'offerId',
    },
    {
        id: 3,
        label: 'Offer\nType',
        key: 'offerType',
    },
    {
        id: 4,
        label: 'Redemption\nStatus',
        key: 'redemptionState',
    },
    {
        id: 5,
        label: 'Redemption\nInitiated At',
        key: 'createdTs',
    },
    {
        id: 6,
        label: 'Last\nUpdated At',
        key: 'updatedTs',
    },
];

export const CLAIMED_EXCHANGER_OFFERS_LABEL_DATA = [
    {
        id: 1,
        label: 'Exchanger\nOrder Id',
        key: 'id',
    },
    {
        id: 2,
        label: 'Exchanger\nOffer Id',
        key: 'offerId',
    },
    {
        id: 3,
        label: 'User\nChoice',
        key: 'rewardDesc',
    },
    {
        id: 4,
        label: 'Reward\nType',
        key: 'rewardType',
    },
    {
        id: 5,
        label: 'Created\nAt',
        key: 'createdTs',
        extClasses: 'f07Wrapper',
    },
    {
        id: 6,
        label: 'Reward\nAmount',
        key: 'rewardAmount',
    },
    {
        id: 7,
        label: 'Redemption\nStatus',
        key: 'redemptionState',
    },
];

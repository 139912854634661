import { createSelector } from 'reselect';
import { IDENTIFIER_TYPE_LIST_KEY } from './constants';

const selectAppLogs = (state) => state.get('appLogs');

const makeSelectLoading = () => createSelector(
    selectAppLogs,
    (substate) => substate.get('loading').toJS(),
);

const makeSelectErr = () => createSelector(
    selectAppLogs,
    (substate) => substate.get('err').toJS(),
);

const makeSelectLogsList = () => createSelector(
    selectAppLogs,
    (substate) => substate.get('logsList').toJS(),
);

const makeSelectLogInfo = () => createSelector(
    selectAppLogs,
    (substate) => substate.get('logInfo').toJS(),
);

const makeSelectIsSearchInitiated = () => createSelector(
    selectAppLogs,
    (substate) => substate.get('isSearchInitiated'),
);

const makeSelectMonorailInfo = () => createSelector(
    selectAppLogs,
    (substate) => substate.get('monorailInfo').toJS(),
);

const makeSelectAppLogs = () => createSelector(
    selectAppLogs,
    (subState) => subState.get(IDENTIFIER_TYPE_LIST_KEY),
);

export {
    makeSelectLoading,
    makeSelectErr,
    makeSelectLogsList,
    makeSelectLogInfo,
    makeSelectIsSearchInitiated,
    makeSelectMonorailInfo,
    makeSelectAppLogs,
};

/*
 *
 * FacematchKYCImage actions
 *
 */

import {
    GET_FM_KYC_IMAGE, SET_MONORAIL_ID,
} from './constants';

export const getFacematchKYCImage = data => ({
    type: GET_FM_KYC_IMAGE,
    data
});

export const setMonorailId = data => ({
    type: SET_MONORAIL_ID,
    data
});

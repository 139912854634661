/**
 * @file Context Menu for transaction Table
 */

import React, { useEffect, useRef, useState } from 'react';

import { getDateforTxnTable } from './utils';
import './style.scss';

const isValid = (value, type) => {
    if (type === 'date') {
        return !!value;
    }
    return value != null && value !== '';
};

const ContextMenu = (props) => {
    const {
        top, left, row, column, isSelected,
        onChangeFilter, setSelectedRows, onClose, filters,
    } = props;

    const menuRef = useRef(null);

    const [menuRect, setMenuRect] = useState(null);

    const cellValue = row[column.key];
    const { type } = column;

    const enableClearFilter = !!filters[column.key];
    const enableAddToFilter = isValid(cellValue, type);
    const isDate = type === 'date';

    // useEffect to close context menu when clicked outside of the menu
    useEffect(() => {
        function onClick(event) {
            if (event.target instanceof Node && menuRef.current?.contains(event.target)) {
                return;
            }
            onClose();
        }
        if (menuRef.current) {
            window.addEventListener('click', onClick);

            // eslint-disable-next-line consistent-return
        }
        return () => {
            window.removeEventListener('click', onClick);
        };
    }, [menuRef, onClose]);

    // useEffect to get the postion of the Context Menu
    useEffect(() => {
        if (menuRef.current) {
            const menuDim = menuRef.current.getBoundingClientRect();

            let adaptedTop = top;
            let adaptedLeft = left;

            // Adjust top position if popper overflows the viewport from the bottom
            if (top + menuDim.height > window.innerHeight) {
                adaptedTop = top - menuDim.height;
            }

            // Adjust left position if popper overflows the viewport from the right
            if (left + menuDim.width > window.innerWidth) {
                adaptedLeft = left - menuDim.width;
            }

            setMenuRect({ top: adaptedTop, left: adaptedLeft });
        }
    }, [menuRef, top, left]);

    const onSelect = () => {
        setSelectedRows((pre) => {
            const newSet = new Set(pre);
            newSet.add(row.order_id);
            return newSet;
        });
        onClose();
    };

    const onUnselect = () => {
        setSelectedRows((pre) => {
            const newSet = new Set(pre);
            newSet.delete(row.order_id);
            return newSet;
        });
        onClose();
    };

    const onAddToFilter = () => {
        const newFilters = { ...filters };
        const key = column?.key;
        let value = row[key];

        if (type === 'date' && value) {
            value = getDateforTxnTable(value); // converting timestamp to date and time string
        } else {
            value = (value || '').toString();
        }

        if (value) {
            newFilters[key] = { value, type };
            onChangeFilter(newFilters);
        }

        onClose();
    };

    const onAddDateToFilter = (flag) => () => {
        const newFilters = { ...filters };
        const key = column?.key;
        const value = row[key];

        if (value) {
            newFilters[key] = {
                type,
                value: getDateforTxnTable(value, flag), // converting timestamp to date only string
            };
            onChangeFilter(newFilters);
        }

        onClose();
    };

    const onClearFilter = () => {
        const newFilters = { ...filters };
        delete newFilters[column?.key];
        onChangeFilter(newFilters);
        onClose();
    };

    return (
        <div
            ref={menuRef}
            className='rdgrid-cm-wrapper'
            style={{ top: menuRect?.top, left: menuRect?.left }}
        >
            <div className='rdgrid-cm-content'>
                <ul className='menu'>
                    {
                        !isSelected
                            ? (
                                <li role='presentation' onClick={onSelect} className='item'>
                                    <span>Select</span>
                                </li>
                            )
                            : (
                                <li role='presentation' onClick={onUnselect} className='item'>
                                    <span>Unselect</span>
                                </li>
                            )
                    }
                    {
                        enableAddToFilter
                            ? (
                                <React.Fragment>
                                    {
                                        isDate
                                            ? (
                                                <React.Fragment>
                                                    <li role='presentation' onClick={onAddDateToFilter('date')} className='item'>
                                                        <span>Add Date to Filter</span>
                                                    </li>
                                                    <li role='presentation' onClick={onAddDateToFilter('month')} className='item'>
                                                        <span>Add Month to Filter</span>
                                                    </li>
                                                    <li role='presentation' onClick={onAddDateToFilter('year')} className='item'>
                                                        <span>Add Year to Filter</span>
                                                    </li>
                                                </React.Fragment>
                                            )
                                            : null
                                    }
                                    <li role='presentation' onClick={onAddToFilter} className='item'>
                                        <span>Add to Filter</span>
                                    </li>
                                </React.Fragment>

                            )
                            : null
                    }
                    {
                        enableClearFilter
                            ? (
                                <li role='presentation' onClick={onClearFilter} className='item'>
                                    <span>Clear Filter</span>
                                </li>
                            )
                            : null
                    }
                </ul>
            </div>
        </div>
    );
};

export default ContextMenu;

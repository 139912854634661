import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { LOGS_ROOT } from '../../api/routes';

import {
    GET_LOGS, SET_LOGS, ERR_LOGS, GET_LOG_INFO, SET_LOG_INFO, ERR_LOG_INFO,
} from './constants';

function* getLogs(action) {
    const queryString = getQueryStringFromObject(action.data);
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LOGS_ROOT}?${queryString}`,
        );

        yield put({ type: SET_LOGS, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LOGS, data: { err: e.message } });
    }
}

function* getLogInfo(action) {
    const { logId } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LOGS_ROOT}/${logId}`,
        );

        yield put({ type: SET_LOG_INFO, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LOG_INFO, data: { err: e.message } });
    }
}

export default function* logsInfoSaga() {
    yield all(
        [
            yield takeLatest(GET_LOGS, getLogs),
            yield takeLatest(GET_LOG_INFO, getLogInfo),
        ],
    );
}

const getFormattedReferralInfo = (data) => [
    {
        label: 'Referred By',
        value: data.referrerName,
    },
    {
        label: 'Finite Code I used',
        value: data.finiteCodeUsed,
    },
    {
        label: 'Finite Code Channel',
        value: data.finiteCodeChannel,
    },
    {
        label: 'Finite Code Type',
        value: data.finiteCodeType,
    },
    {
        label: 'Qualifying Event',
        value: data.qualifyingEventDescription,
    },
    {
        label: 'Qualifying Event Passed',
        value: data.qualifyingEventPassed,
    },
];

const getFormattedRewardInfo = (data) => [
    {
        label: 'Rewards Earned',
        value: data.rewardEarned,
    },
    {
        label: 'Rewards State',
        value: data.rewardState,
    },
    {
        label: 'Rewards Earned Timestamp',
        value: data.rewardEarnedTs,
    },
    {
        label: 'Rewards Type Earned',
        value: data.rewardType,
    },
    {
        label: 'Total Rewards Earned',
        value: data.totalRewardEarned && data.totalRewardEarned.value,
    },
];

const getFormattedReferrerInfo = (data) => [
    {
        label: 'Eligibility for Referrals',
        value: data.isEligibleForReferral,
    },
    {
        label: 'Referral Finite Codes',
        value: data.finiteCodes,
    },
    {
        label: 'Users claimed Finite Code',
        value: data.totalClaimedFiniteCode,
    },
    {
        label: 'Total Rewards Count',
        value: data.totalRewardsCount,
    },
    {
        label: 'Cash Rewards Count',
        value: data.cashRewardsCount,
    },
    {
        label: 'Cash Rewards Earned',
        value: data.cashRewardEarned,
    },
    {
        label: 'SD Rewards Count',
        value: data.sdRewardsCount,
    },
    {
        label: 'SD Rewards Earned',
        value: data.sdRewardEarned,
    },
    {
        label: 'Fi Coins Rewards Count',
        value: data.fiCoinRewardsCount,
    },
    {
        label: 'Fi Coins Rewards Earned',
        value: data.fiCoinsEarned,
    },
    {
        label: 'Cash Rewards In Processing',
        value: data.cashRewardInProcessing,
    },
    {
        label: 'SD Rewards In Processing',
        value: data.sdRewardInProcessing,
    },
    {
        label: 'Fi Coins Rewards In Processing',
        value: data.fiCoinsInProcessing,
    },
];

const getFormattedRefereeInfo = (data) => [
    {
        label: 'Phone Number',
        value: data.phoneNumber,
    },
    {
        label: 'Onboarding Status of this user',
        value: data.onboardingState,
    },
    {
        label: 'Qualifying Event for this user',
        value: data.qualifyingEventDescription,
    },
    {
        label: 'Qualifying Event Passed for this user',
        value: data.qualifyingEventPassed,
    },
    {
        label: 'Reward Earned for this user',
        value: data.rewardEarned,
    },
    {
        label: 'Reward State for this user',
        value: data.rewardState,
    },
    {
        label: 'Reward Earned Timestamp for this user',
        value: data.rewardEarnedTs,
    },
    {
        label: 'Rewards Type Earned for this user',
        value: data.rewardType,
    },
    {
        label: 'Total Rewards Earned for this user',
        value: data.totalRewardEarned && data.totalRewardEarned.value,
    },
    {
        label: 'Finite Code Claim ID',
        value: data.finiteCodeClaimId,
    },
];

export {
    getFormattedReferralInfo,
    getFormattedRewardInfo,
    getFormattedReferrerInfo,
    getFormattedRefereeInfo,
};

import { toastify } from '../../utils';

/**
 * @param mediaSrc AWS S3 Signed URL of a video file
 * this function captures the S3 URL
 * and cleans it up
 * @returns the file name with the relative path to be passed to RPC
 */
export const extractVideoPathFromURL = (mediaSrc) => {
    const URL_MEDIA = new URL(mediaSrc); // fetch the entire URL
    const URL_PATHNAME = URL_MEDIA.pathname; // extract only pathname
    const URL_PATHNAME_WITHOUT_PARAMS = URL_PATHNAME.replace(/^\/[^/]+\//, '/'); // remove route name
    const DOWNLOADABLE_URL = URL_PATHNAME_WITHOUT_PARAMS.replace(/\.mp4.*$/, '.mp4').substr(1); // remove the initial slash
    return DOWNLOADABLE_URL;
};

export const geSubmitFormConfig = (form) => {
    if (form?.length > 0) {
        const finalForm = [];
        form?.forEach((item) => {
            if (item.type === 9) { // muilt select drop down type
                item.value?.forEach((list) => {
                    finalForm.push({
                        extras: {
                            id: list.value,
                            value: list.label,
                        },
                    });
                });
            } else {
                finalForm.push(item);
            }
        });
        return finalForm;
    }
    return form;
};

export const getLinkedProfilePayload = (emailId) => {
    try {
        const LokiAuthToken = localStorage.getItem('LokiAuthToken');
        if (!localStorage.getItem('outlookURL') && !LokiAuthToken) {
            throw new Error('No Credential Found'
            + ' Please follow given instruction to add outlook credentials to check linkedin profile');
        }

        const outlookData = { outlookURL: localStorage.getItem('outlookURL') };
        const linkedInUrl = new URL(outlookData.outlookURL);

        const baseUrl = `${linkedInUrl.origin}/api/v3/personacards`;

        const RootCorrelationId = linkedInUrl.searchParams.get('RootCorrelationId');

        const CorrelationId = linkedInUrl.searchParams.get('CorrelationId');

        const ClientCorrelationId = linkedInUrl.searchParams.get('ClientCorrelationId');

        const personaType = linkedInUrl.searchParams.get('personaType');

        const smtp = emailId;

        const olsPersonaId = linkedInUrl.searchParams.get('olsPersonaId');

        const viewType = linkedInUrl.searchParams.get('viewType');

        const ConvertGetPost = true;

        const linkedInBaseUrl = `${linkedInUrl.origin}/api/v1/linkedin/profiles/full?`;
        return {
            LokiAuthToken,
            RootCorrelationId,
            CorrelationId,
            ClientCorrelationId,
            personaType,
            smtp,
            olsPersonaId,
            ConvertGetPost,
            viewType,
            linkedInBaseUrl,
            outlookBaseUrl: baseUrl,
        };
    } catch (err) {
        toastify(err?.message || 'No auth Found', 'error');
        return null;
    }
};

export const getActorMailId = (reviewSections) => {
    try {
        const BasicDetails = reviewSections.filter((section) => section.sectionName === 'Basic Details')[0];
        const infoData = BasicDetails.data.info_data.filter((data) => data.label === 'Signup Email');
        return infoData[0].value;
    } catch (err) {
        console.error(err);
        return null;
    }
};

/* eslint-disable no-useless-escape */
/**
 * Returns whether the inputted number is valid or not
 *
 * @param {string} val input string
 * @returns {boolean} Returns whether the inputted number is valid or not
 */
const isInputtedNumberValid = (val) => {
    // Check that id only contains digits and should not be empty
    const regex = /^\d+$/;

    return regex.test(val);
};

/**
 * Returns whether the inputted mobile number is valid or not
 *
 * @param {string} val input string
 * @returns {boolean} Returns whether the inputted mobile number is valid or not
 */
const isInputtedMobileNumberValid = (val) => {
    // Check that id only contains digits and is of 10 length
    const regex = /^[6-9]\d{9}$/;

    return regex.test(val);
};

/**
 * Returns whether the inputted string is valid or not
 *
 * @param {string} val input string
 * @returns {boolean} Returns whether the inputted string is valid or not
 */
const isInputtedStringValid = (val) => {
    // Check that val only contains alphabets, dot(.), apostrophe(') & comma(,)
    const regex = /^[A-Za-z\.\'\, ]+$/;

    return regex.test(val);
};

/**
 * Returns whether the inputted string is valid or not
 *
 * @param {string} val input string
 * @returns {boolean} Returns whether the inputted string is valid or not
 */
const isInputtedStringValidv2 = (val) => {
    // Check that val only contains alphabets, numbers, dot(.), apostrophe(') & comma(,)
    const regex = /^[A-Za-z0-9\.\'\, ]+$/;

    return regex.test(val);
};

/**
 * Returns whether the inputted string is valid or not
 *
 * @param {string} val input string
 * @returns {boolean} Returns whether the inputted string is valid or not
 */
const isInputtedAlphabetStringValid = (val) => {
    // Check that val only contains alphabets
    const regex = /^[A-Za-z]+$/;

    return regex.test(val);
};

/**
 * Returns whether the inputted email is valid or not
 *
 * @param {string} val input string
 * @returns {boolean} Returns whether the inputted string is valid or not
 */
const isInputtedEmailValid = (val) => {
    const regex = /^[-\w\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    return regex.test(val);
};

/**
 * Returns whether the inputted password is valid according to AWS cognito password policy.
 * The password must be minimum 8 characters and maximum 99 characters.
 * The password must contain at least 1 number, 1 special character, 1 uppercase letter, 1 lowercase letter.
 * The special characters are restricted to this - https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
 * Regex taken from stack overflow - https://stackoverflow.com/a/58767981
 *
 * @param {string} password The password to test.
 * @returns {boolean} Returns whether the inputted password is valid or not.
 */
const isAWSPasswordValid = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/;

    return regex.test(password);
};

/**
 * Returns whether the inputted ticket ids is valid or not
 *
 * @param {string} val input string
 * @returns {boolean} Returns whether the inputted ticket ids is valid or not
 */
const isInputtedTicketIdsValid = (val) => {
    // Check that ids only contains digits with comma separated values and should not be empty
    const regex = /^[0-9]+(,[0-9]+)*$/;

    return regex.test(val);
};

/**
 * Returns whether the inputted time string is valid or not
 *
 * @param {string} val input string
 * @returns {boolean} Returns whether the inputted time string is valid or not
 */
const isInputtedTimeStrValid = (val) => {
    // check that time string is in this format - YYYY-MM-DDTHH:MM:SS
    const regex = /^[0-9]{4}-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2})$/;

    return regex.test(val);
};

/**
 * Returns whether the inputted string is a valid PAN (Permanent Account NUmber) or not
 *
 * @param {string} val input string
 * @returns {boolean} Returns whether the inputted string is a valid PAN (Permanent Account NUmber) or not
 */
const isInputtedPANStrValid = (val) => {
    // check that time string is in this format - XXXXX9999X
    const regex = /^[A-Z]{3}[A-Z]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;

    return regex.test(val);
};

export default {
    isInputtedNumberValid,
    isInputtedMobileNumberValid,
    isInputtedStringValid,
    isInputtedStringValidv2,
    isInputtedAlphabetStringValid,
    isInputtedEmailValid,
    isAWSPasswordValid,
    isInputtedTicketIdsValid,
    isInputtedTimeStrValid,
    isInputtedPANStrValid,
};

/**
 *
 * LogsList
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import { isNilOrEmpty } from '../../../utils';
import {
    Button, DropdownContent, DropdownWrapper, ExpandableCard, Loader, Pagination,
} from '../../../components';

import { LOGS_PATH } from '../../App/routes';

import { getLogs, setErrSearchAction } from '../actions';
import {
    makeSelectLoading, makeSelectErr, makeSelectLogsList, makeSelectPrev, makeSelectNext, makeSelectLogFilter,
} from '../selectors';
import {
    ACTION_TYPE, OBJECT_TYPE, STATUS_TYPE,
} from '../constants';
import '../style.scss';

const LogsList = () => {
    const dispatch = useDispatch();

    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const logsList = useSelector(makeSelectLogsList(), shallowEqual);
    const prev = useSelector(makeSelectPrev(), shallowEqual);
    const next = useSelector(makeSelectNext(), shallowEqual);
    const logFilter = useSelector(makeSelectLogFilter(), shallowEqual);

    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(false);
    const [agentEmail, setAgentEmail] = useState('');
    const [ticketId, setTicketId] = useState('');
    const [showActionDropdown, setActionDropdown] = useState(false);
    const [showObjectDropdown, setObjectDropdown] = useState(false);
    const [showStatusDropdown, setStatusDropdown] = useState(false);
    const [actionType, setActionType] = useState('');
    const [objectType, setObjectType] = useState('');
    const [statusType, setStatusType] = useState('');

    useEffect(() => {
        dispatch(getLogs({ logFilter }));
    }, []);

    const getPaginatedLogs = ({ prev: prevItem, next: nextItem }) => {
        dispatch(getLogs(
            {
                agentEmail, ticketId, action: actionType, object: objectType, status: statusType, prev: prevItem, next: nextItem, logFilter,
            },
        ));
    };

    const toggleSearchCard = () => {
        setIsSearchCardExpanded(!isSearchCardExpanded);
    };

    const handleInputChange = (key) => (event) => {
        const { value } = event.target;

        dispatch(setErrSearchAction({ err: '' }));

        switch (key) {
            case 'agentEmail':
                setAgentEmail(value);
                break;

            case 'ticketId':
                setTicketId(value);
                break;

            default:
        }
    };

    const toggleDropdown = (key) => () => {
        switch (key) {
            case 'action':
                setActionDropdown(!showActionDropdown);
                break;

            case 'object':
                setObjectDropdown(!showObjectDropdown);
                break;

            case 'status':
                setStatusDropdown(!showStatusDropdown);
                break;

            default:
        }
    };

    const handleDropdownChange = (key) => (value) => {
        switch (key) {
            case 'action':
                setActionType(value);
                setActionDropdown(false);
                break;

            case 'object':
                setObjectType(value);
                setObjectDropdown(false);
                break;

            case 'status':
                setStatusType(value);
                setStatusDropdown(false);
                break;

            default:
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (isNilOrEmpty(ticketId) && isNilOrEmpty(agentEmail)) {
            dispatch(setErrSearchAction({ err: 'Please enter either Ticket ID or Agent Email' }));
            return;
        }

        dispatch(getLogs({
            agentEmail, ticketId, action: actionType, object: objectType, status: statusType, logFilter,
        }));
        setIsSearchCardExpanded(false);
    };

    const renderSearchResults = () => {
        if (logsList.length === 0) {
            return <div className='table-nsr'>No logs found!</div>;
        }

        return (
            <React.Fragment>
                <div className='table-sr'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th'>Agent Email</div>
                        <div className='table-sr__th'>Access Level</div>
                        <div className='table-sr__th'>Ticket ID</div>
                        <div className='table-sr__th'>Status</div>
                        <div className='table-sr__th'>Action</div>
                        <div className='table-sr__th'>Object</div>
                        <div className='table-sr__th table-sr__th--big'>Timestamp</div>
                        <div className='table-sr__th'>View</div>
                    </div>
                    <div className='table-sr__tlc'>
                        {
                            logsList.map((item) => (
                                <div className='table-sr__tlw' key={item.id}>
                                    <div className='table-sr__tl  table-sr__tl--lc'>{item.agentEmail}</div>
                                    <div className='table-sr__tl'>{item.accessLevel}</div>
                                    <div className='table-sr__tl'>{item.ticketId}</div>
                                    <div className='table-sr__tl'>{item.status}</div>
                                    <div className='table-sr__tl'>{item.action}</div>
                                    <div className='table-sr__tl'>{item.object}</div>
                                    <div className='table-sr__tl table-sr__tl--big'>{item.requestTs}</div>
                                    <Link to={`${LOGS_PATH}${item.id}`} className='link table-sr__tl'>
                                        View Details
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Pagination prev={prev} next={next} onClickHandler={getPaginatedLogs} />
            </React.Fragment>
        );
    };

    const renderDDBlock = (key) => {
        let label;
        let showDropdown;
        let value;
        let dataList;
        let activeClass;

        switch (key) {
            case 'action': {
                label = 'Action';
                showDropdown = showActionDropdown;
                value = actionType;
                dataList = ACTION_TYPE;
                activeClass = '--aai';
                break;
            }

            case 'object': {
                label = 'Object';
                showDropdown = showObjectDropdown;
                value = objectType;
                dataList = OBJECT_TYPE;
                activeClass = '--aai';
                break;
            }

            case 'status': {
                label = 'Status';
                showDropdown = showStatusDropdown;
                value = statusType;
                dataList = STATUS_TYPE;
                activeClass = '--as';
                break;
            }

            default:
        }

        value = value.split('_').join(' ').toLowerCase();

        return (
            <div className='frcWrapper logslist-wrapper'>
                <div className='logslist-label'>
                    <label htmlFor={key}>{label}</label>
                </div>
                <DropdownWrapper
                    visible={showDropdown}
                    onOutsideClickHandler={toggleDropdown(key)}
                >
                    <div
                        onClick={toggleDropdown(key)}
                        className='custom-dd'
                        onKeyDown={() => {}}
                        role='listbox'
                        tabIndex={0}
                    >
                        {value || 'Choose...'}
                    </div>
                    <DropdownContent
                        visible
                        extClasses={{
                            container: ['logslist-custom-dd__cc height-transition', showDropdown && `logslist-custom-dd__cc${activeClass}`],
                        }}
                    >
                        {
                            dataList.map((item) => (
                                <div
                                    key={item.label}
                                    className='custom-dd__cl'
                                    onClick={() => handleDropdownChange(key)(item.value)}
                                    onKeyDown={() => {}}
                                    role='listbox'
                                    tabIndex={0}
                                >
                                    {item.label}
                                </div>
                            ))
                        }
                    </DropdownContent>
                </DropdownWrapper>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className='logs-container'>
                <div className='logslist-heading'>Logs</div>
                <ExpandableCard extClasses='logslist-ec' heading='Search for logs' isExpanded={isSearchCardExpanded} setIsExpanded={toggleSearchCard}>
                    <div className='logslist-sc'>
                        <div className='frWrapper'>
                            <div className='frcWrapper logslist-wrapper'>
                                <div className='logslist-label'>
                                    <label htmlFor='agent_email'>Agent Email*</label>
                                </div>
                                <input
                                    id='agent-email'
                                    type='text'
                                    value={agentEmail}
                                    onChange={handleInputChange('agentEmail')}
                                    placeholder='Enter Agent Email'
                                />
                            </div>
                            <div className='frcWrapper logslist-wrapper'>
                                <div className='logslist-label'>
                                    <label htmlFor='ticket_id'>Ticket ID*</label>
                                </div>
                                <input
                                    id='ticket_id'
                                    type='text'
                                    value={ticketId}
                                    onChange={handleInputChange('ticketId')}
                                    placeholder='Enter Ticket ID'
                                />
                            </div>
                        </div>
                        <div className='frWrapper logslist-sc__3r'>
                            {renderDDBlock('action')}
                            {renderDDBlock('object')}
                        </div>
                        <div className='frWrapper logslist-sc__3r'>
                            {renderDDBlock('status')}
                        </div>
                        {err && err.search ? (
                            <div className='logslist-err'>{err.search}</div>
                        ) : null}
                        <div className='frcWrapper'>
                            <Button
                                extClasses='logslist-sc__cta'
                                primary
                                label='Search'
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </ExpandableCard>
                <div className='logslist-src'>
                    {renderSearchResults()}
                    {
                        err && err.logsList ? (
                            <div className='container-err-label'>{err.logsList}</div>
                        ) : null
                    }
                </div>
            </div>
            <Loader visible={loading && loading.logsList} />
        </React.Fragment>
    );
};

export default memo(LogsList);

/**
 * @file Reducer for FreshdeskTicketInfo
 */

import { fromJS } from 'immutable';

import {
    ERR_FRESHDESK_TICKET_INFO, GET_FRESHDESK_CATEGORIES, GET_FRESHDESK_TICKET_INFO, SET_FRESHDESK_CATEGORIES,
    SET_FRESHDESK_TICKET_INFO, SUCCESSFUL_UPDATE_FRESHDESK_TICKET_INFO, UPDATE_FRESHDESK_TICKET_INFO, REDUCER,
} from './constants';

const initialState = fromJS({
    // API state
    [REDUCER.CATEGORY_INFO]: {},
    [REDUCER.TICKET_INFO]: {},
    // UI state
    [REDUCER.LOADING]: false,
    [REDUCER.ERR]: '',
});

const getApiState = (state) => (
    state
        .setIn(['loading'], true)
        .setIn(['err'], '')
);

const updateApiState = (state) => (
    state
        .setIn(['loading'], false)
        .setIn(['err'], '')
);

const freshdeskTicketInfoReducer = (state = initialState, action = '') => {
    const actionData = action.data;

    switch (action.type) {
        case GET_FRESHDESK_CATEGORIES:
        case GET_FRESHDESK_TICKET_INFO:
        case UPDATE_FRESHDESK_TICKET_INFO: {
            return getApiState(state);
        }

        case SET_FRESHDESK_CATEGORIES: {
            const { categoryInfo } = actionData;

            return updateApiState(state)
                .setIn(['categoryInfo'], fromJS(categoryInfo));
        }

        case SET_FRESHDESK_TICKET_INFO: {
            const { ticketId, ticketInfo } = actionData;

            return updateApiState(state)
                .setIn(['ticketInfo', ticketId], fromJS(ticketInfo));
        }

        case SUCCESSFUL_UPDATE_FRESHDESK_TICKET_INFO: {
            return updateApiState(state);
        }

        case ERR_FRESHDESK_TICKET_INFO: {
            const { errMsg } = actionData;

            return state
                .setIn(['loading'], false)
                .setIn(['err'], errMsg);
        }

        default: {
            return state;
        }
    }
};

export default freshdeskTicketInfoReducer;

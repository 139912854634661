/**
 *
 * Referee Info Modal
 *
 */

import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button } from '../../../components';

import { getFormattedRefereeInfo } from '../utils';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '12%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 900,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        maxHeight: '85%',
    },
};

const RefereeInfo = (props) => {
    const { visible, data, toggleHandler } = props;

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='User Referee Info Modal'
        >
            <div className='referrals-modal-clrwcr'>
                <div className='referrals-modal-hd'>User Referee Details</div>
                {
                    getFormattedRefereeInfo(data).map(item => (
                        <div key={item.label} className='referrals-cwr'>
                            <div className='referrals-cwr__label'>{item.label}</div>
                            <div className='referrals-cwr__value'>
                                {item.value || '-'}
                            </div>
                        </div>
                    ))
                }
            </div>
            <Button
                extClasses='referrals-modal-cta'
                primary
                label='Close'
                onClick={toggleHandler}
            />
        </Modal>
    );
};

export default memo(RefereeInfo);

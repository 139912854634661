/**
 * @file Manages the constants for Pre Approved Loans component
 */

// key to be used for reducers & selectors for pre approved loans
export const LOANS_KEY = 'loans';

export const PRE_APPROVED_LOANS_KEY = 'preApprovedLoans';

// pre approved loans user info
export const GET_USER_INFO = `app/${PRE_APPROVED_LOANS_KEY}/GET_USER_INFO`;
export const SET_USER_INFO = `app/${PRE_APPROVED_LOANS_KEY}/SET_USER_INFO`;
export const ERR_USER_INFO = `app/${PRE_APPROVED_LOANS_KEY}/ERR_USER_INFO`;

// pre approved loans info
export const GET_LOANS_INFO = `app/${PRE_APPROVED_LOANS_KEY}/GET_LOANS_INFO`;
export const SET_LOANS_INFO = `app/${PRE_APPROVED_LOANS_KEY}/SET_LOANS_INFO`;
export const ERR_LOANS_INFO = `app/${PRE_APPROVED_LOANS_KEY}/ERR_LOANS_INFO`;

// Foreclosure Amount
export const GET_FORECLOSURE_AMOUNT = `app/${PRE_APPROVED_LOANS_KEY}/GET_FORECLOSURE_AMOUNT`;
export const SET_FORECLOSURE_AMOUNT = `app/${PRE_APPROVED_LOANS_KEY}/SET_FORECLOSURE_AMOUNT`;
export const ERR_FORECLOSURE_AMOUNT = `app/${PRE_APPROVED_LOANS_KEY}/ERR_FORECLOSURE_AMOUNT`;

// key to be used for reducers & selectors for loans against mf
export const LOAN_AGAINST_MUTUAL_FUNDS_KEY = 'loanAgainstMutualFunds';

// loan against mutual funds user info
export const GET_LAMF_USER_INFO = `app/${LOAN_AGAINST_MUTUAL_FUNDS_KEY}/GET_USER_INFO`;
export const SET_LAMF_USER_INFO = `app/${LOAN_AGAINST_MUTUAL_FUNDS_KEY}/SET_USER_INFO`;
export const ERR_LAMF_USER_INFO = `app/${LOAN_AGAINST_MUTUAL_FUNDS_KEY}/ERR_USER_INFO`;

// loans against mutual funds info
export const GET_LAMF_LOANS_INFO = `app/${LOAN_AGAINST_MUTUAL_FUNDS_KEY}/GET_LOANS_INFO`;
export const SET_LAMF_LOANS_INFO = `app/${LOAN_AGAINST_MUTUAL_FUNDS_KEY}/SET_LOANS_INFO`;
export const ERR_LAMF_LOANS_INFO = `app/${LOAN_AGAINST_MUTUAL_FUNDS_KEY}/ERR_LOANS_INFO`;

// loans against mutual funds info
export const GET_LAMF_LOANS_ADDITIONAL_INFO = `app/${LOAN_AGAINST_MUTUAL_FUNDS_KEY}/GET_LOANS_ADDITIONAL_INFO`;
export const SET_LAMF_LOANS_ADDITIONAL_INFO = `app/${LOAN_AGAINST_MUTUAL_FUNDS_KEY}/SET_LOANS_ADDITIONAL_INFO`;
export const ERR_LAMF_LOANS_ADDITIONAL_INFO = `app/${LOAN_AGAINST_MUTUAL_FUNDS_KEY}/ERR_LOANS_ADDITIONAL_INFO`;

export const LOAN_ACCOUNT_DETAILS_LABEL_DATA = [
    {
        label: 'Account No.',
        key: 'loanAccountNumber',
        extClasses: 'p-5',
    },
    {
        label: 'Open Date',
        key: 'loanOpenDate',
        extClasses: 'p-5',
    },
    {
        label: 'Loan Amt.',
        key: 'loanAmount',
        extClasses: 'p-5',
    },
    {
        label: 'Processing Fee',
        key: 'processingFee',
        extClasses: 'p-5',
    },
    {
        label: 'Broken Period Interest',
        key: 'brokenPeriodInterest',
        extClasses: 'p-5',
    },
    {
        label: 'Interest (%)',
        key: 'interestRate',
        extClasses: 'p-5',
    },
    {
        label: 'Tenure (months)',
        key: 'tenureInMonths',
        extClasses: 'p-5',
    },
    {
        label: 'Outstanding Amt.',
        key: 'outstandingAmount',
        extClasses: 'p-5',
    },
    {
        label: 'GST',
        key: 'gst',
        extClasses: 'p-5',
    },
    {
        label: 'Pre Closure Fee',
        key: 'preClosureFee',
        extClasses: 'p-5',
    },
    {
        label: 'Vendor',
        key: 'vendor',
        extClasses: 'p-5',
    },
    {
        label: 'Loan Program',
        key: 'loanProgram',
        extClasses: 'p-5',
    },
    {
        label: 'Disbursal Bank',
        key: 'bankName',
        extClasses: 'p-5',
    },
    {
        label: 'Bank a/c No.',
        key: 'accountNumber',
        extClasses: 'p-5',
    },
    {
        label: 'Foreclosure Amount',
        key: 'foreclosureAmount',
        extClasses: 'p-5',
    },
];

export const LAST_TRANSACTIONS_LABEL_DATA = [
    {
        label: 'EMI Paid Date',
        key: 'timestamp',
    },
    {
        label: 'Txn Type',
        key: 'transactionType',
    },
    {
        label: 'Status',
        key: 'status',
    },
    {
        label: 'Order Id',
        key: 'orderId',
    },
    {
        label: 'Fi UTR Number',
        key: 'fiUtrNumber',
    },
    {
        label: 'Loan Acct ID',
        key: 'loanAccountNumber',
    },
    {
        label: 'EMI Amount',
        key: 'amount',
    },
    {
        label: 'Mode of Payment',
        key: 'modeOfPayment',
    },
    {
        label: 'Charges',
        key: 'charges',
    },
];

export const LOAN_OFFER_DETAILS_LABEL_DATA = [
    {
        label: 'Min Loan Amount',
        key: 'minLoanAmount',
    },
    {
        label: 'Max Loan Amount',
        key: 'maxLoanAmount',
    },
    {
        label: 'Interest',
        key: 'interest',
    },
    {
        label: 'Min Tenure',
        key: 'minTenure',
    },
    {
        label: 'Max Tenure',
        key: 'maxTenure',
    },
    {
        label: 'Offer Start Date',
        key: 'offerStartDate',
    },
    {
        label: 'Offer End Date',
        key: 'offerEndDate',
    },
    {
        label: 'Agent input - User Feedback',
        key: 'agentInputUserFeedback',
    },
    {
        label: 'Vendor',
        key: 'vendor',
    },
    {
        label: 'Loan Program',
        key: 'loanProgram',
    },
];

export const UPCOMING_EMI_DETAILS = [
    {
        label: 'Loan Acct ID',
        key: 'loanAccountNumber',
    },
    {
        label: 'EMI Amount',
        key: 'amount',
    },
    {
        label: 'Next EMI Date',
        key: 'nextEmiDate',
    },
];

export const customModalStyles = {
    content: {
        width: 1100,
        height: 500,
        overflow: 'scroll',
    },
};

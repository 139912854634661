/**
 * @file Actions for FreshdeskTicketInfo
 */

import { GET_FRESHDESK_CATEGORIES, GET_FRESHDESK_TICKET_INFO, UPDATE_FRESHDESK_TICKET_INFO } from './constants';

// data is not getting used currently, but keeping it for future sue
export const getFreshdeskCategoriesAction = (data) => ({
    type: GET_FRESHDESK_CATEGORIES,
    data,
});

export const getFreshdeskTicketInfoAction = (data) => ({
    type: GET_FRESHDESK_TICKET_INFO,
    data,
});

export const updateFreshdeskTicketInfoAction = (data) => ({
    type: UPDATE_FRESHDESK_TICKET_INFO,
    data,
});

/**
 * @file WealthOps - This component shows the cases pending in the queue for manual verification
 * For each case, the agent may need to verify some or all the following:
 * 1. Liveness video of the user
 * 2. Image of the document proof provided. Check if sensitive information is masked correctly.
 *    If not masked correctly, edit the image and mask the details properly.
 * 3. Check if expiry date of the document is correctly extracted. If there is a mismatch, enter the correct date.
 */

import React, { useEffect, useContext } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { compose } from 'redux';

import classNames from 'classnames';
import { injectReducer, injectSaga } from '../../utils';
import { HorizontalIdNavbar, Loader } from '../../components';

import './style.scss';

import reducer from './reducer';
import saga from './saga';

import AllCases from './AllCases';
import SingleCase from './SingleCase';

import { getAdditionalFilters } from './utils';
import { getWealthOpsPendingReviewCountAction, getWealthOpsQueueCasesAction } from './actions';
import { VIEW_ALL_WEALTH_OPS_CASES, VIEW_SINGLE_WEALTH_OPS_CASE, WEALTH_OPS_KEY } from './constants';
import {
    selectWealthOpsCurView, selectWealthOpsErr, selectWealthOpsLoading, selectWealthOpsPayloadType, selectWealthOpsQueueFilters,
} from './selectors';

import { MetaInfoContext } from '../../context';

const RenderView = ({ curView }) => {
    switch (curView) {
        case VIEW_ALL_WEALTH_OPS_CASES: {
            return <AllCases />;
        }

        case VIEW_SINGLE_WEALTH_OPS_CASE: {
            return <SingleCase />;
        }

        default:
            return null;
    }
};

const WealthOps = () => {
    const dispatch = useDispatch();

    const metaInfo = useContext(MetaInfoContext);

    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    // global state
    const loading = useSelector(selectWealthOpsLoading(), shallowEqual);
    const err = useSelector(selectWealthOpsErr(), shallowEqual);
    const curView = useSelector(selectWealthOpsCurView(), shallowEqual);
    const payloadType = useSelector(selectWealthOpsPayloadType(), shallowEqual);
    const queueFilters = useSelector(selectWealthOpsQueueFilters(), shallowEqual);

    const { limit } = queueFilters;

    useEffect(() => {
        dispatch(getWealthOpsPendingReviewCountAction({
            payloadType: payloadType.value,
        }));
    }, [payloadType.value, dispatch]);

    useEffect(() => {
        dispatch(getWealthOpsQueueCasesAction({
            payloadType: payloadType.value,
            ...getAdditionalFilters(limit.value),
        }));
    }, [payloadType.value, limit.value, dispatch]);

    return (
        <div className={classNames({
            'wops-cr': bannersVersion === 1,
            'wops-cr-v2': bannersVersion === 2,
        })}
        >
            <HorizontalIdNavbar isFixed isEmptyNavbar />
            <RenderView curView={curView} />
            {
                err && <div className='wops-cr__el err-label'>{err}</div>
            }
            <Loader visible={loading} />
        </div>
    );
};

const withReducer = injectReducer({ key: WEALTH_OPS_KEY, reducer });
const withSaga = injectSaga({ key: WEALTH_OPS_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(WealthOps);

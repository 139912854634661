/* eslint-disable default-param-last */

/**
 * @file Manages the reducer state & function for transaction case details container
 */

import { fromJS } from 'immutable';

import {
    GET_TRANSACTION_CASE_DETAILS,
    SET_TRANSACTION_CASE_DETAILS,
    ERR_TRANSACTION_CASE_DETAILS,
    SET_TRANSACTION_CASE_ANNOTATION,
    ERR_TRANSACTION_CASE_ANNOTATION,
    SET_TRANSACTION_CASE_COMMENTS,
    ERR_TRANSACTION_CASE_COMMENTS,
} from './constants';

const initialState = fromJS({
    loading: false,
    err: '',
    transactionCaseDetails: {
        tableSections: [],
        alerts: {},
    },
    listAnnotation: {},
    listComments: {},
});

function transactionCaseDetailReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TRANSACTION_CASE_DETAILS:
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '');

        case SET_TRANSACTION_CASE_DETAILS: {
            const { transactionCaseDetails } = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['transactionCaseDetails'], fromJS(transactionCaseDetails));
        }

        case ERR_TRANSACTION_CASE_DETAILS: {
            const { err } = action.data;

            return state
                .setIn(['transactionCaseDetails'], fromJS({}))
                .setIn(['loading'], false)
                .setIn(['err'], err);
        }

        case SET_TRANSACTION_CASE_ANNOTATION: {
            const { listAnnotation } = action.data;

            return state
                .setIn(['listAnnotation'], fromJS(listAnnotation));
        }

        case ERR_TRANSACTION_CASE_ANNOTATION: {
            return state
                .setIn(['listAnnotation'], fromJS({}));
        }

        case SET_TRANSACTION_CASE_COMMENTS: {
            const { listComments } = action.data;

            return state
                .setIn(['listComments'], fromJS(listComments));
        }

        case ERR_TRANSACTION_CASE_COMMENTS: {
            return state
                .setIn(['listComments'], fromJS({}));
        }

        default:
            return state;
    }
}

export default transactionCaseDetailReducer;

/**
 * @file RenderForm
 * Renders the form input fields for corresponding to fields on freshdesk
 */

import React, { memo, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, FormDropdown } from '../../../components';
import { isNilOrEmpty } from '../../../utils';

import { updateFreshdeskTicketInfoAction } from '../actions';
import {
    CATEGORY_DETAILS_KEY, CATEGORY_KEY, FORM_DATA, SUB_CATEGORY_KEY,
} from '../constants';
import { selectFDTicketInfoCategories } from '../selectors';
import { getCategoryDetails, getSubCategory, getOptionsFromList, getCategoryList } from '../utils';

const RenderForm = (props) => {
    const { ticketId } = props;

    const dispatch = useDispatch();

    const fdCategoryInfo = useSelector(selectFDTicketInfoCategories, shallowEqual);

    const [formData, setFormData] = useState(FORM_DATA);
    const [formState, setFormState] = useState({ errMsg: '' });

    const updateFormOptions = (index, options) => {
        const newFormData = [...formData];
        newFormData[index] = {
            ...newFormData[index],
            options,
        };

        setFormData(newFormData);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cachedUpdateFormOptions = useCallback(updateFormOptions, []);

    // set categories dropdown list when data is fetched for categoriesInfo
    useEffect(() => {
        if (!fdCategoryInfo) return;

        const options = getOptionsFromList(getCategoryList(fdCategoryInfo), 'product_category');
        cachedUpdateFormOptions(0, options);
    }, [fdCategoryInfo, cachedUpdateFormOptions]);

    const handleFormChange = (key, newValue) => {
        let modifiedFormState = {};

        // when category is set
        if (key === CATEGORY_KEY) {
            // update category details options list
            const categoryKey = newValue.value;
            const options = getOptionsFromList(getCategoryDetails(fdCategoryInfo, categoryKey), 'product_category_details');
            updateFormOptions(1, options);

            // reset category details and sub category input
            modifiedFormState = {
                [CATEGORY_DETAILS_KEY]: '',
                [SUB_CATEGORY_KEY]: '',
            };
        }

        // when category details is set
        if (key === CATEGORY_DETAILS_KEY) {
            // update sub category options list
            const categoryKey = formState.categories.value;
            const subCategoryKey = newValue.value;
            const options = getOptionsFromList(getSubCategory(fdCategoryInfo, categoryKey, subCategoryKey), 'subcategory');
            updateFormOptions(2, options);

            // reset sub category input
            modifiedFormState = {
                [SUB_CATEGORY_KEY]: '',
            };
        }

        // update form state
        setFormState({
            ...formState,
            ...modifiedFormState,
            [key]: newValue,
        });
    };

    const handleUpdateDetails = () => {
        if (isNilOrEmpty(formState.categories)) {
            setFormState({
                ...formState,
                errMsg: 'Please fill all mandatory fields',
            });

            return;
        }

        setFormState({
            ...formState,
            errMsg: '',
        });

        dispatch(updateFreshdeskTicketInfoAction({
            ticketId,
            productCategory: formState[CATEGORY_KEY].value,
            productCategoryDetails: formState[CATEGORY_DETAILS_KEY].value,
            subCategory: formState[SUB_CATEGORY_KEY].value,
        }));
    };

    return (
        <div>
            {formData.map((item) => (
                <div className='fcWrapper mb-30' key={item.key}>
                    <div className='mb-15'>{item.label}</div>
                    <FormDropdown
                        options={item.options}
                        input={{
                            value: formState[item.key],
                            onChange: (newValue) => handleFormChange(item.key, newValue),
                            placeholder: item.placeholder || 'Choose...',
                        }}
                        cacheKey={item.key}
                        extStyles={item.extStyles}
                    />
                </div>
            ))}
            <div className='mt-30 error-label'>
                {formState.errMsg}
            </div>
            <Button
                v2
                primary
                extClasses='mt-15'
                label='Update Ticket Details'
                onClick={handleUpdateDetails}
            />
        </div>
    );
};

export default memo(RenderForm);

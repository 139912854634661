/**
 *
 * Pan Name Match Review flow
 * User can see the existing cases for namematch
 * User can select a case for review
 * For review, user can view the 3 names submitted of pan, kyc & profile
 * Once review is done, user can dequeue the case
 *
 */

import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { HorizontalIdNavbar, Loader } from '../../components';
import { injectReducer, injectSaga } from '../../utils';
import { getAdditionalFilters } from '../RiskOps/utils';

import reducer from './reducer';
import saga from './saga';

import AllNamematchCases from './AllNamematchCases';
import SingleNamematchCase from './SingleNamematchCase';

import { getNamematchCasesAction, getNamematchPendingReviewCountAction, setCurNamematchCaseAction, setNamematchViewAction } from './actions';
import {
    selectErr, selectNamematchPayloadType, selectNamematchView, selectLoading, selectNamematchQueueFilters,
} from './selectors';
import { VIEW_ALL_NAMEMATCH_CASES, VIEW_SINGLE_NAMEMATCH_CASE } from './constants';

import './style.scss';

const NamematchReview = () => {
    const dispatch = useDispatch();

    // global state
    const loading = useSelector(selectLoading(), shallowEqual);
    const err = useSelector(selectErr(), shallowEqual);
    const namematchView = useSelector(selectNamematchView(), shallowEqual);
    const namematchPayloadType = useSelector(selectNamematchPayloadType(), shallowEqual);
    const { fromDate, toDate, sortOption } = useSelector(selectNamematchQueueFilters(), shallowEqual);

    /**
     * Get namematch cases in following states:
     * 1. when we have empty namematch cases []
     * 2. when the payload type changes
     * 3. When a dequeue action happens, then we need to call with the current payload type
     */
    useEffect(() => {
        dispatch(getNamematchPendingReviewCountAction({ payloadType: namematchPayloadType.value }));

        dispatch(getNamematchCasesAction({
            payloadType: namematchPayloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        }));
    }, [namematchPayloadType.value, dispatch]);

    const handleReviewClick = (namematchData) => () => {
        dispatch(setCurNamematchCaseAction(namematchData));
        dispatch(setNamematchViewAction(VIEW_SINGLE_NAMEMATCH_CASE));
    };

    const renderView = () => {
        switch (namematchView) {
            case VIEW_ALL_NAMEMATCH_CASES: {
                return <AllNamematchCases handleReviewClick={handleReviewClick} />;
            }

            case VIEW_SINGLE_NAMEMATCH_CASE: {
                return <SingleNamematchCase />;
            }

            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            <HorizontalIdNavbar isFixed isEmptyNavbar />
            {renderView()}

            <div className='namereview-cr'>
                {err && <div className='namereview-crel err-label'>{err}</div>}
            </div>

            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'namematchReview', reducer });
const withSaga = injectSaga({ key: 'namematchReview', saga });

export default compose(
    withReducer,
    withSaga,
)(NamematchReview);

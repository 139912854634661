/*
 *
 * Login constants
 *
 */

// action names
export const LOGIN_WITH_EMAIL_PWD = 'app/LOGIN/EMAIL_PASSWORD';
export const LOGIN_START = 'app/LOGIN/START';
export const LOGIN_ERROR = 'app/LOGIN/ERROR';
export const LOGIN_SUCCESS = 'app/LOGIN/SUCCESS';
export const UPDATE_LOGIN_USER = 'app/LOGIN/UPDATE_USER';
export const UPDATE_AUTH_STATE = 'app/LOGIN/UPDATE_AUTH_STATE';
export const UPDATE_NEW_PASSWORD = 'app/LOGIN/UPDATE_NEW_PASSWORD';
export const VALIDATE_SMS_MFA = 'app/LOGIN/VALIDATE_SMS_MFA';
export const SEND_OTP_CODE_ON_EMAIL = 'app/LOGIN/SEND_OTP_CODE_ON_EMAIL';
export const SET_NEW_PASSWORD_WITH_OTP = 'app/LOGIN/SET_NEW_PASSWORD_WITH_OTP';
export const UPDATE_FORGOT_PASSWORD_STATE = 'app/LOGIN/UPDATE_FORGOT_PASSWORD_STATE';
// auth states
export const SIGN_IN_WITH_EMAIL = 'app/LOGIN/STATE/SIGN_IN';
export const NEW_PASSWORD_REQUIRED = 'app/LOGIN/STATE/NEW_PASSWORD_REQUIRED';
export const SMS_MFA = 'app/LOGIN/STATE/SMS_MFA';
// accepted challenge names
export const ACCEPTED_AUTH_CHALLENGES = [
    {
        name: 'SMS_MFA',
        value: SMS_MFA
    },
    {
        name: 'NEW_PASSWORD_REQUIRED',
        value: NEW_PASSWORD_REQUIRED
    },
];
export const SET_NEW_PASSWORD = 'app/LOGIN/STATE/SET_NEW_PASSWORD';
export const FORGOT_PASSWORD = 'app/LOGIN/STATE/FORGOT_PASSWORD';
// forgot password states
export const REGISTERED_EMAIL = 'app/LOGIN/STATE/REGISTERED_EMAIL';
export const SET_FORGOT_PASSWORD = 'app/LOGIN/STATE/SET_FORGOT_PASSWORD';

/**
*
* Toast
*
*/

import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Toast = ({ content, iconType, dismissAll }) => (
    <div>
        <div className="toast-content">{content}</div>
        <button className="clearAll" type="button" onClick={dismissAll}> Clear All</button>
    </div>
);

Toast.propTypes = {
    content: PropTypes.string.isRequired,
    iconType: PropTypes.string,
    dismissAll: PropTypes.func.isRequired,
};

export default memo(Toast);

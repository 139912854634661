/**
 * @file CallListView component to show the list for for calls and user authentication flow
 */

import React, { memo, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { UserDetailsContext, MetaInfoContext } from '../../../context';
import {
    Button, FormWithSingleInput, Loader, Table, Modal,
} from '../../../components';
import { toastify } from '../../../utils';
import { makeSelectAgentInfo } from '../../AppV2/selectors';

import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';

import { CALL_LIST_LABEL_DATA } from '../constants';
import {
    getCallList, setSelectedCallInfo, getTicketAndUserInfoForCall,
    getUserInfoForCall, userConfirmationForCall, createTicketForCall,
} from '../actions';
import {
    makeSelectLoading, makeSelectCallList, makeSelectSelectedCallInfo,
} from '../selectors';

import CreateNewTicketCTA from './CreateNewTicketCTA';

import './style.scss';

// custom styles for confirmation modal
const customModalStyles = {
    content: {
        padding: 32,
        width: 800,
    },
};

const CallListView = (props) => {
    const dispatch = useDispatch();
    const userInfo = useContext(UserDetailsContext);
    const metaInfo = useContext(MetaInfoContext);

    // context api variables
    const {
        basicInfo: {
            ozonetelId,
        },
    } = userInfo;
    const {
        appConfig: {
            enableSingleTicketCreationFlow,
        },
        tickets: {
            reasonInfo: {
                callListTable,
            },
        },
    } = metaInfo;

    // reducer state variables
    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const callList = useSelector(makeSelectCallList(), shallowEqual);
    const selectedCallInfo = useSelector(makeSelectSelectedCallInfo(), shallowEqual);
    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);

    // local state variables
    const [showUserAuthenticationModal, setUserAuthenticationModal] = useState(false);
    const [showErrorFlowModal, setErrorFlowModal] = useState(false);
    const [registeredUserId, setRegisteredUserId] = useState('');
    const [registeredUserInfo, setRegisteredUserInfo] = useState(null);
    const [showRegisteredEmailIdFlow, setRegisteredEmailIdFlow] = useState(false);
    const [isUserNotRegistered, setIsUserNotRegistered] = useState(false);
    const [formErrLabel, setFormErrLabel] = useState(null);

    // props variables
    const { setTicketIdAndSubmit, setTicketIdInputPage, setTicketsForCallPage } = props;

    // destructured variables
    const { callListView: callListViewLoading } = loading;
    const {
        data: callListData, prev: callListPrev, next: callListNext, err: callListErr,
    } = callList;
    const { ucid: selectedCallUcid } = selectedCallInfo;

    // fetch call list only when the single ticket creation flow is enabled
    useEffect(() => {
        if (enableSingleTicketCreationFlow) {
            dispatch(getCallList({ ozonetelId }));
        }
    }, [dispatch, enableSingleTicketCreationFlow]);

    const getPaginatedCallList = ({ prev, next }) => {
        dispatch(getCallList({ ozonetelId, prev, next }));
    };

    const onUserIdChange = (e) => {
        setRegisteredUserId(e.target.value);
    };

    const getTicketAndUserInfoForCallApiRequest = (data) => {
        const { ucid } = data;

        const callGetTicketAndUserInfoForCallAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(getTicketAndUserInfoForCall({
                    ucid,
                    resolve,
                    reject,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callGetTicketAndUserInfoForCallAction()
            .then((response) => {
                const { ticketIdForCall, isUserExistInEpifi } = response;

                if (ticketIdForCall) { // show ticket landing page if ticket is already attached to selected call record
                    setTicketIdAndSubmit(ticketIdForCall);
                } else if (isUserExistInEpifi) { // show ticket list for selected call record
                    setTicketsForCallPage(true);
                } else { // perform user authentication & then show ticket list for selected call record
                    setUserAuthenticationModal(true);
                }
            })
            .catch(() => {
                setErrorFlowModal(true);
            });
    };

    const onSelectThisCallCTAClick = (data) => () => {
        const { ucid, callerUserId } = data;
        pushRudderEvent(RudderEvent.SherlockV1Agent.AgentUserCallSelectionSherlock, agentInfo.emailId, data);

        dispatch(setSelectedCallInfo({ ucid, callerUserId }));

        getTicketAndUserInfoForCallApiRequest({ ucid });
    };

    const onFindUserCTAClick = () => {
        if (!registeredUserId) {
            setFormErrLabel('Please enter a valid value');
            return;
        }

        const callGetUserInfoForCallAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(getUserInfoForCall({
                    ucid: selectedCallUcid,
                    userId: registeredUserId,
                    resolve,
                    reject,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callGetUserInfoForCallAction()
            .then((response) => {
                // show toast if user name is not found as we need to confirm the name for user authentication
                if (response.name) {
                    setRegisteredUserInfo(response);
                } else {
                    toastify('Not able to find user info', 'error');
                }
            })
            .catch((error) => {
                // If user info is not found, automatically create a ticket for that number
                if (error.statusCode === 404) {
                    dispatch(createTicketForCall({
                        ucid: selectedCallUcid,
                        reason: ['Did not find user details for selected call, creating new ticket'],
                    }));
                }
            });

        setFormErrLabel('');
    };

    const onUserConfirmationCTAClick = () => {
        const callUserConfirmationForCallAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(userConfirmationForCall({
                    ucid: selectedCallUcid,
                    userId: registeredUserId,
                    resolve,
                    reject,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callUserConfirmationForCallAction()
            .then((response) => {
                // we need to show ticket list page if screen gets returned as 10(PAST_TICKET_DETAILS_FOR_USER_SCREEN)
                if (response.screen === 10) {
                    dispatch(setSelectedCallInfo({ ucid: selectedCallUcid, callerUserId: registeredUserId }));
                    setTicketsForCallPage(true);
                }
            });
    };

    const renderActionsForCallList = (data) => (
        <div className='fccWrapper'>
            <Button
                link
                label='Select this Call'
                onClick={onSelectThisCallCTAClick(data)}
            />
            {/* show create new ticket CTA only if ticket ID is 0 & callHandleStatus is answered */}
            {data.ticketId === '0' && data.callHandleStatus === 'answered' && (
                <React.Fragment>
                    <hr className='defr-sep' />
                    <CreateNewTicketCTA
                        isLinkButtonType
                        reasonList={callListTable}
                        selectedRowData={data}
                    />
                </React.Fragment>
            )}
        </div>
    );

    const renderCallList = () => (
        <Table
            header='Call History'
            labelData={CALL_LIST_LABEL_DATA}
            contentData={callListData}
            renderActionableColumn={renderActionsForCallList}
            prevToken={callListPrev}
            nextToken={callListNext}
            paginatedClickHandler={getPaginatedCallList}
            extClasses={{
                actionableColumn: 'f15Wrapper',
            }}
            errLabel={callListErr}
            nsrLabel='No calls found for the agent!'
        />
    );

    const renderUserIdForm = () => {
        if (isUserNotRegistered) {
            return (
                <div className='user-not-registered-wrapper'>
                    <span className='label'>
                        User cannot be identified using mobile number or email id.
                        Please ask the user to call from their registered number.
                    </span>
                </div>
            );
        }

        const resetRegisteredUserFields = () => {
            setRegisteredUserId('');
            setRegisteredUserInfo(null);
        };

        let label = 'Ask user their registered ';
        let labelId = 'call-registered-';
        let onWrongUserCTAClick;

        if (!showRegisteredEmailIdFlow) {
            label += 'mobile number';
            labelId += 'mobile-number';

            // set user id as null & enable email id flow
            onWrongUserCTAClick = () => {
                resetRegisteredUserFields();
                setRegisteredEmailIdFlow(true);
            };
        } else {
            label += 'email id';
            labelId += 'email-id';

            // set user id as null & enable user not registered flow
            onWrongUserCTAClick = () => {
                resetRegisteredUserFields();
                setRegisteredEmailIdFlow(false);
                setIsUserNotRegistered(true);
            };
        }

        return (
            <React.Fragment>
                <FormWithSingleInput
                    label={label}
                    labelId={labelId}
                    input={{
                        value: registeredUserId,
                        onChange: onUserIdChange,
                    }}
                    cta={{
                        primaryLabel: 'Find User',
                        onPrimaryClick: onFindUserCTAClick,
                    }}
                    extClasses={{
                        container: 'defr-fc',
                        label: 'defr-fc__lb',
                        inputField: 'defr-fc__ip',
                    }}
                    errLabel={formErrLabel}
                />
                {
                    registeredUserInfo ? (
                        <div className='defr-ua-modal-ucwr'>
                            <span className='defr-ua-modal-ucwr__lb'>Confirm User Name:</span>
                            <span className='defr-ua-modal-ucwr__name'>{registeredUserInfo.name}</span>
                            <Button
                                primary
                                label='Confirm'
                                onClick={onUserConfirmationCTAClick}
                                extClasses='defr-ua-modal-ucwr__p-cta'
                            />
                            <Button
                                secondary
                                label='Still wrong user'
                                onClick={onWrongUserCTAClick}
                            />
                        </div>
                    ) : null
                }
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className='defr-cr'>
                <Button
                    v2
                    primary
                    label='refresh call history'
                    onClick={() => {
                        dispatch(getCallList({ ozonetelId }));
                    }}
                    extClasses='defr-call-pg-ref-cta'
                />
                {renderCallList()}
                <Button
                    v2
                    primary
                    label="i know user's ticket id"
                    onClick={() => {
                        setTicketIdInputPage(true);
                    }}
                    extClasses='defr-call-pg-cta'
                />
            </div>
            <Modal
                visible={showErrorFlowModal}
                modalStyles={customModalStyles}
                contentLabel='Call Flow Error Modal'
            >
                <div className='defr-ef-modal-hd'>
                    Fetching ticket details for this user failed.
                    You can `Retry` or `Create a New Ticket`
                </div>
                <div className='defr-ef-modal-cta-wr'>
                    <Button
                        v2
                        primary
                        label='Retry'
                        onClick={() => {
                            setErrorFlowModal(false);
                            getTicketAndUserInfoForCallApiRequest({ ucid: selectedCallUcid });
                        }}
                        extClasses='defr-ef-modal-cta'
                    />
                    <CreateNewTicketCTA
                        reasonList={callListTable}
                    />
                </div>
            </Modal>
            <Modal
                visible={showUserAuthenticationModal}
                modalStyles={customModalStyles}
                contentLabel='User Authentication Modal'
            >
                <div className='defr-ua-modal-hd'>User Confirmation</div>
                {renderUserIdForm()}
                <Button
                    v2
                    primary
                    label='Close'
                    onClick={() => {
                        // close the modal & reset the user authentication flow
                        setUserAuthenticationModal(false);
                        setRegisteredUserId('');
                        setRegisteredEmailIdFlow(false);
                        setIsUserNotRegistered(false);

                        // refresh the call list if the user is not registered
                        if (isUserNotRegistered) {
                            dispatch(getCallList({ ozonetelId }));
                        }
                    }}
                    extClasses='defr-ua-modal-cta'
                />
            </Modal>
            <Loader visible={callListViewLoading} />
        </React.Fragment>
    );
};

export default memo(CallListView);

import {
    BULK_USER_DETAILS_PATH, WAITLIST_USER_DETAILS_PATH, BULK_TICKET_UPDATE_UPLOAD_CSV_PATH,
    BULK_TICKET_UPDATE_HISTORY_PATH, BANNERS_PATH, AGENT_FEEDBACK_DETAILS, ALL_DISPUTES, LOG_FILTER,
    ISSUE_CONFIG_HISTORY_PATH, ISSUE_CONFIG_MANAGE_PATH,
} from '../App/routes';

export const getSideNavbarItems = ({
    isAdmin, isAdminRestricted, isSuperAdmin,
}) => [
    {
        id: 'get-bulk-user-details',
        label: 'Get Bulk User Details',
        path: BULK_USER_DETAILS_PATH,
    },
    {
        id: 'get-waitlist-user-details',
        label: 'Get Waitlist User Details',
        path: WAITLIST_USER_DETAILS_PATH,
        hidden: !(isAdmin || isAdminRestricted),
    },
    {
        id: 'bulk-ticket-update',
        label: 'Bulk Ticket Update',
        hidden: !(isAdmin || isSuperAdmin),
        nestedLinks: [
            {
                id: 'btu-upload-csv',
                label: 'Upload CSV',
                path: BULK_TICKET_UPDATE_UPLOAD_CSV_PATH,
            },
            {
                id: 'btu-history',
                label: 'History',
                path: BULK_TICKET_UPDATE_HISTORY_PATH,
            },
        ],
    },
    {
        id: 'banners',
        label: 'Banners',
        path: BANNERS_PATH,
    },
    {
        id: 'agent-feedbacks',
        label: 'Agent Feedbacks',
        path: AGENT_FEEDBACK_DETAILS,
    },
    {
        id: 'dipsutes',
        label: 'Disputes',
        path: ALL_DISPUTES,
    },
    {
        id: 'log-filter',
        label: 'Log Filter',
        path: LOG_FILTER,
        hidden: !isSuperAdmin,
    },
    {
        id: 'issue-config',
        label: 'Issue Config',
        hidden: !(isAdmin || isSuperAdmin),
        nestedLinks: [
            {
                id: 'ic-manage',
                label: 'Manage Issue Config',
                path: ISSUE_CONFIG_MANAGE_PATH,
            },
            {
                id: 'ic-history',
                label: 'Issue Config History',
                path: ISSUE_CONFIG_HISTORY_PATH,
            },
        ],
    },
];

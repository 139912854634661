/*
 *
 * Kyc Onboarding constants
 *
 */

export const GET_KYC_INFO = 'app/KO/GET_KYC_INFO';
export const SET_KYC_INFO = 'app/KO/SET_KYC_INFO';
export const ERR_KYC_INFO = 'app/KO/ERR_KYC_INFO';

export const GET_ONBOARDING_INFO = 'app/KO/GET_ONBOARDING_INFO';
export const SET_ONBOARDING_INFO = 'app/KO/SET_ONBOARDING_INFO';
export const ERR_ONBOARDING_INFO = 'app/KO/ERR_ONBOARDING_INFO';

export const GET_REOOBE_INFO = 'app/KO/GET_REOOBE_INFO';
export const SET_REOOBE_INFO = 'app/KO/SET_REOOBE_INFO';
export const ERR_REOOBE_INFO = 'app/KO/ERR_REOOBE_INFO';

export const GET_VKYC_INFO = 'app/KO/GET_VKYC_INFO';
export const SET_VKYC_INFO = 'app/KO/SET_VKYC_INFO';
export const ERR_VKYC_INFO = 'app/KO/ERR_VKYC_INFO';

export const RETRY_LIVENESS = 'app/KO/RETRY_LIVENESS';
export const UPDATE_RETRY_LIVENESS = 'app/KO/UPDATE_RETRY_LIVENESS';

export const ONBOARDING_TROUBLESHOOT_MAP = [
    {
        stage: 'TNC_CONSENT',
        description: 'After email screen, user consents to TnC',
        data: [
            {
                sherlockError: 'FAILURE',
                screen: 'T&C',
                reason: 'T&C services are down and consent is not recorded',
                appError: 'Seeing failure on T&C consent screen',
                resolution: 'Less likely. But if it does happen, retry in some time.',
            },
        ],
    },
    {
        stage: 'INITIATE_CKYC',
        description: 'To fetch ckyc records based on PAN',
        data: [
            {
                sherlockError: 'FAILURE',
                screen: 'PAN & DOB',
                reason: 'Invalid PAN',
                appError: 'Seeing Invalid PAN error',
                resolution: 'Check if PAN is a personal PAN, not expired',
            },
            {
                sherlockError: 'FAILURE',
                screen: 'PAN & DOB',
                reason: 'PAN Validation call fails',
                appError: 'Seeing PAN validation failed error',
                resolution: 'This is a transient error. Retry in sometime',
            },
            {
                sherlockError: 'FAILURE',
                screen: 'PAN & DOB',
                reason: 'User is a minor',
                appError: 'User sees screen \'Cannot open your account digitally\'',
                resolution: 'End of flow, no way forward',
            },
        ],
    },
    {
        stage: 'DEDUPE_CHECK',
        description: 'Happens on screen where user enters PAN and Date of birth',
        data: [
            {
                sherlockError: 'CUSTOMER_DOEST_NOT_EXISTS',
                screen: 'PAN & DOB',
                reason: 'The given customer does not have a prior account with vendor',
                appError: 'Error screen with specific messages about their dedupe status',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'FAILED',
                screen: 'PAN & DOB',
                reason: 'Dedupe call has failed on vendor',
                appError: 'Error screen with specific messages about their dedupe status',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS',
                screen: 'PAN & DOB',
                reason: 'Customer has a prior account with vendor',
                appError: 'User sees screen \'Cannot open your account digitally\'',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_DETAILS_MISMATCH',
                screen: 'PAN & DOB',
                reason: 'Customer is duplicate customer, but the dob and/or phone number details does not match with the federal account',
                appError: 'Error screen with specific messages about their dedupe status',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_NRI',
                screen: 'PAN & DOB',
                reason: 'Customer exists but is NRI',
                appError: 'Error screen with specific messages about their dedupe status',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_MINOR',
                screen: 'PAN & DOB',
                reason: 'Customer exists but is Minor',
                appError: 'Error screen with specific messages about their dedupe status',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_PARTIAL_KYC',
                screen: 'PAN & DOB',
                reason: 'Customer exists but KYC restricted from opening some accounts',
                appError: 'Error screen with specific messages about their dedupe status',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_MULTIPLE_CUSTOMER_ID',
                screen: 'PAN & DOB',
                reason: 'Multiple customer exists in federal',
                appError: 'Error screen with specific messages about their dedupe status',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_PHONE_MISMATCH',
                screen: 'PAN & DOB',
                reason: 'Phone number mismatch - Customer is dedupe customer, but the phone number does not match with the federal account',
                appError: 'Error screen with specific messages about their dedupe status',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_DOB_MISMATCH',
                screen: 'PAN & DOB',
                reason: 'DOB mismatch - Customer is dedupe customer, but the dob does not match with the federal account',
                appError: 'Error screen with specific messages about their dedupe status',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_PHONE_NUMBER_LINKED_TO_MULTIPLE_ACCOUNTS',
                screen: 'PAN & DOB',
                reason: 'Phone number is already linked to multiple accounts',
                appError: 'Error screen with specific messages about their dedupe status',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'FAILURE',
                screen: 'PAN & DOB',
                reason: 'User says they don\'t have a Federal account',
                appError: 'User sees a screen \'You already have an existing account\' but user says they do not have a Federal bank account',
                resolution: 'Flag it internally, this needs follow up with Federal',
            },
        ],
    },
    {
        stage: 'MOTHER_FATHER_NAME',
        description: 'Post mobile otp, email & PAN entering',
        data: [
            {
                sherlockError: 'FAILURE',
                screen: 'Father, Mother Name',
                reason: 'Name validation fails',
                appError: 'User sees an error on screen \'Please enter a valid name\' for Parent\'s name',
                resolution: 'Fix the errors shown on UI and continue',
            },
            {
                sherlockError: 'FAILURE',
                screen: 'Father, Mother Name',
                reason: 'Nominee addition fails due to name/address checks',
                appError: 'Unable to add nominee details',
                resolution: 'Fix the errors shown on UI and continue',
            },
        ],
    },
    {
        stage: 'KYC_AND_LIVENESS_COMPLETION',
        description: 'Once ckyc/ekyc and liveness is completed. Refer KYC history to understand details around KYC',
        data: [
            {
                sherlockError: 'CKYC_SEARCH_ERROR = 4',
                screen: 'CKYC',
                reason: 'CKYC search call fails',
                appError: 'CKYC search call fails',
                resolution: 'This is a transient error. Retry in sometime',
            },
            {
                sherlockError: 'CKYC_SEARCH_MAX_RETRIES = 5',
                screen: 'CKYC',
                reason: 'CKYC search call fails after all retries',
                appError: 'CKYC search call fails after all retries',
                // eslint-disable-next-line max-len
                resolution: 'We have exhausted all retries to search for a CKYC record. Possibly CKYC services are down. Ask user to retry in sometime',
            },
            {
                sherlockError: 'CKYC_SEARCH_NOT_FOUND = 3',
                screen: 'CKYC',
                reason: 'CKYC record not found for user',
                appError: 'CKYC record not found for user',
                resolution: 'User doesn\'t have CKYC record. They will have to go through EKYC',
            },
            {
                sherlockError: 'CKYC_DOWNLOAD_ERROR = 7',
                screen: 'CKYC',
                reason: 'CKYC download call fails',
                appError: 'CKYC download call fails',
                resolution: 'This is a transient error. Retry in sometime',
            },
            {
                sherlockError: 'CKYC_DOWNLOAD_MAX_RETRIES = 8',
                screen: 'CKYC',
                reason: 'CKYC download call fails after all retries',
                appError: 'CKYC download call fails after all retries',
                // eslint-disable-next-line max-len
                resolution: 'We have exhausted all retries to search for a CKYC record. Possibly CKYC services are down. Ask user to retry in sometime',
            },
            {
                sherlockError: 'STATUS_LIVENESS_VIDEO_NOT_RECEIVED = 1',
                screen: 'Liveness',
                reason: 'User denied permissions',
                appError: 'User says they denied audio, video permissions',
                resolution: 'Give permissions to move ahead',
            },
            {
                sherlockError: 'STATUS_LIVENESS_FAILED = 3',
                screen: 'Liveness',
                reason: 'User didn\'t read OTP correctly',
                appError: 'User tried the liveness flow but is seeing failure on app',
                resolution: 'Spell out OTP digit by digit in a quiet background',
            },
            {
                sherlockError: 'STATUS_LIVENESS_FAILED = 3',
                screen: 'Liveness',
                reason: 'User\'s background was unclear',
                appError: 'User tried the liveness flow but is seeing failure on app',
                resolution: 'Make sure background is preferably a plain color one',
            },
            {
                sherlockError: 'STATUS_LIVENESS_FAILED = 3',
                screen: 'Liveness',
                reason: 'User\'s lighting was too dark',
                appError: 'User tried the liveness flow but is seeing failure on app',
                resolution: 'Make sure video is recorded in good light',
            },
            {
                sherlockError: 'STATUS_LIVENESS_FAILED = 3',
                screen: 'Liveness',
                reason: 'User\'s face was not detected',
                appError: 'User tried the liveness flow but is seeing failure on app',
                resolution: 'Either user was too close to or too far from the camera',
            },
            {
                sherlockError: 'STATUS_LIVENESS_EXPIRED = 7',
                screen: 'Liveness',
                reason: 'User hit maximum retries',
                appError: 'User tried the liveness flow but is seeing failure on app',
                // eslint-disable-next-line max-len
                resolution: 'User exhausted number of retries. Requires a reset via Sherlock and instructions during the call, escalate internally. User starts from the same liveness step',
            },
            {
                sherlockError: 'STATUS_LIVENESS_FAILED = 3',
                screen: 'Liveness',
                reason: 'Liveness call failed',
                appError: 'User tried the liveness flow but is seeing failure on app',
                // eslint-disable-next-line max-len
                resolution: 'User exhausted number of retries. Requires a reset via Sherlock and instructions during the call, escalate internally. User starts from the same liveness step',
            },
            {
                sherlockError: 'STATUS_LIVENESS_PENDING = 5',
                screen: 'Liveness',
                reason: 'Liveness call taking longer',
                appError: 'User is seeing loading screen after liveness',
                resolution: 'Vendor response taking longer than expected, ask user to wait for sometime',
            },
            {
                sherlockError: 'STATUS_FACE_MATCH_FAILED = 4',
                screen: 'Facematch',
                reason: 'Facematch failed with KYC image but liveness succeeded',
                appError: 'User sees error \'Cannot open account digitally\'',
                resolution: 'KYC image quality was poor, End of flow for user',
            },
            {
                sherlockError: 'STATUS_FACE_MATCH_FAILED = 4',
                screen: 'Facematch',
                reason: 'Facematch failed with KYC image and liveness also failed',
                appError: 'User sees error \'Cannot open account digitally\'',
                // eslint-disable-next-line max-len
                resolution: 'User exhausted number of retries. Requires a reset via Sherlock and instructions during the call, escalate internally. User starts from the same liveness step',
            },
            {
                sherlockError: 'EKYC_INIT = 9',
                screen: 'Aadhaar',
                reason: 'Aadhaar services are down',
                appError: 'User seeing error in Aadhaar screen',
                resolution: 'Retry in sometime',
            },
            {
                sherlockError: 'EKYC_FAILED = 10',
                screen: 'Aadhaar',
                reason: 'Aadhaar number is invalid',
                appError: 'User seeing error in Aadhaar screen',
                resolution: 'User needs to enter a valid Aadhaar number',
            },
            {
                sherlockError: 'EKYC_FAILED = 10',
                screen: 'Aadhaar',
                reason: 'Aadhaar number is blocked by user on UIDAI site',
                appError: 'User seeing error in Aadhaar screen',
                // eslint-disable-next-line max-len
                resolution: 'User might have blocked OTP based/biometric verification on UIDAI site. They need to visit UIDAI site and unblock it so it can be used in our flow',
            },
            {
                sherlockError: 'EKYC_FAILED = 10',
                screen: 'Aadhaar',
                reason: 'Aadhaar number is suspended/unusable',
                appError: 'User seeing error in Aadhaar screen',
                resolution: 'User must contact UIDAI site to make their Aadhaar existing and valid',
            },
            {
                sherlockError: 'EKYC_FAILED = 10',
                screen: 'Aadhaar',
                reason: 'Mobile number not linked to Aadhaar',
                appError: 'User seeing error in Aadhaar screen',
                // eslint-disable-next-line max-len
                resolution: 'User must visit their local Aadhaar center and manually fill a form to add their current mobile number to be linked to their Aadhaar',
            },
            {
                sherlockError: 'EKYC_FAILED = 10',
                screen: 'Aadhaar',
                reason: 'User tried too many OTP send requests',
                appError: 'User seeing error in Aadhaar screen',
                resolution: 'Retry in sometime',
            },
            {
                sherlockError: 'EKYC_FAILED = 10',
                screen: 'Aadhaar',
                reason: 'User entered incorrect OTP',
                appError: 'User seeing error in Aadhaar screen',
                // eslint-disable-next-line max-len
                resolution: 'Backspace delete the OTP entered and reenter the right OTP. If it still fails, it might be invalid. So resend and enter a fresh OTP',
            },
            {
                sherlockError: 'EKYC_FAILED = 10',
                screen: 'Aadhaar',
                reason: 'OTP not received',
                appError: 'User seeing error in Aadhaar screen',
                // eslint-disable-next-line max-len
                resolution: 'Make sure network signal is good. If it is good but still not receiving, check if the mobile number on Aadhaar is latest and active',
            },
            {
                sherlockError: 'DIFFERENT STATUS POSSIBLE',
                screen: 'Aadhaar',
                reason: 'Account cannot be opened digitally',
                appError: 'Account cannot be opened digitally',
                // eslint-disable-next-line max-len
                resolution: 'Name match failed - no way forward except delete user and ask them to retry, escalate internally. Facematch failed - retry possible, escalate internally',
            },
        ],
    },
    {
        stage: 'PAN_NAME_CHECK',
        description: 'Ensure PAN name and KYC name match',
        data: [
            {
                sherlockError: 'MANUAL_INTERVENTION',
                screen: 'Aadhaar',
                reason: '',
                appError: '\'Your name on PAN Card and KYC records did not match\' screen',
                resolution: 'User\'s name on PAN and KYC didn\'t match. Manual verification required. Connect with Risk Ops team.',
            },
        ],
    },
    {
        stage: 'KYC_DEDUPE_CHECK',
        description: 'This stage calls dedupe for the user with KYC details',
        data: [
            {
                sherlockError: 'CUSTOMER_DOEST_NOT_EXISTS',
                screen: 'Aadhaar',
                reason: 'The given customer does not have a prior account with vendor',
                // eslint-disable-next-line max-len
                appError: '\'Sorry! You have an existing Federal account. Please complete your KYC with the bank before you proceed forward.\' error screen',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'FAILED',
                screen: 'Aadhaar',
                reason: 'Dedupe call has failed on vendor',
                // eslint-disable-next-line max-len
                appError: '\'Sorry! You have an existing Federal account. Please complete your KYC with the bank before you proceed forward.\' error screen',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS',
                screen: 'Aadhaar',
                reason: 'Customer has a prior account with vendor',
                // eslint-disable-next-line max-len
                appError: '\'Sorry! You have an existing Federal account. Please complete your KYC with the bank before you proceed forward.\' error screen',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_DETAILS_MISMATCH',
                screen: 'Aadhaar',
                reason: 'Customer is duplicate customer, but the dob and/or phone number details does not match with the federal account',
                // eslint-disable-next-line max-len
                appError: '\'Sorry! You have an existing Federal account. Please complete your KYC with the bank before you proceed forward.\' error screen',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_NRI',
                screen: 'Aadhaar',
                reason: 'Customer exists but is NRI',
                // eslint-disable-next-line max-len
                appError: '\'Sorry! You have an existing Federal account. Please complete your KYC with the bank before you proceed forward.\' error screen',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_MINOR',
                screen: 'Aadhaar',
                reason: 'Customer exists but is Minor',
                // eslint-disable-next-line max-len
                appError: '\'Sorry! You have an existing Federal account. Please complete your KYC with the bank before you proceed forward.\' error screen',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_PARTIAL_KYC',
                screen: 'Aadhaar',
                reason: 'Customer exists but KYC restricted from opening some accounts',
                // eslint-disable-next-line max-len
                appError: '\'Sorry! You have an existing Federal account. Please complete your KYC with the bank before you proceed forward.\' error screen',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_MULTIPLE_CUSTOMER_ID',
                screen: 'Aadhaar',
                reason: 'Multiple customer exists in federal',
                // eslint-disable-next-line max-len
                appError: '\'Sorry! You have an existing Federal account. Please complete your KYC with the bank before you proceed forward.\' error screen',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_PHONE_MISMATCH',
                screen: 'Aadhaar',
                reason: 'Phone number mismatch - Customer is dedupe customer, but the phone number does not match with the federal account',
                // eslint-disable-next-line max-len
                appError: '\'Sorry! You have an existing Federal account. Please complete your KYC with the bank before you proceed forward.\' error screen',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_DOB_MISMATCH',
                screen: 'Aadhaar',
                reason: 'DOB mismatch - Customer is dedupe customer, but the dob does not match with the federal account',
                // eslint-disable-next-line max-len
                appError: '\'Sorry! You have an existing Federal account. Please complete your KYC with the bank before you proceed forward.\' error screen',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
            {
                sherlockError: 'CUSTOMER_EXISTS_PHONE_NUMBER_LINKED_TO_MULTIPLE_ACCOUNTS',
                screen: 'Aadhaar',
                reason: 'Phone number is already linked to multiple accounts',
                // eslint-disable-next-line max-len
                appError: '\'Sorry! You have an existing Federal account. Please complete your KYC with the bank before you proceed forward.\' error screen',
                resolution: 'Get KYC details updated at Federal\'s end',
            },
        ],
    },
    {
        stage: 'ENSURE_KYC_AVAILABILITY',
        description: 'KYC data is expired after some days and is deleted. this stage asks for KYC data again from the user and re-do KYC.',
        data: [
            {
                sherlockError: 'INITIATED',
                screen: 'Aadhaar',
                reason: 'NA',
                appError: '',
                resolution: 'No fix required',
            },
            {
                sherlockError: 'INPROGRESS',
                screen: 'Aadhaar',
                reason: 'KYC is being re-done again as it got expired',
                appError: '',
                resolution: 'KYC is being done again. Refer to KYC_AND_LIVENESS_COMPLETION fixes',
            },
        ],
    },
    {
        stage: 'CONFIRM_CARD_MAILING_ADDRESS',
        description: 'User confirms the card mailing address',
        data: [
            {
                sherlockError: 'FAILURE',
                screen: 'Card shipping address',
                reason: 'Address validation checks fail',
                appError: 'User seeing error in shipping address screen',
                resolution: 'Fix the errors shown on UI and continue',
            },
            {
                sherlockError: 'FAILURE',
                screen: 'Open bank account',
                reason: 'User denied permissions',
                appError: 'User seeing error after swiping to confirm open bank account',
                resolution: 'Give permissions to move ahead',
            },
        ],
    },
    {
        stage: 'UPI_CONSENT',
        description: 'Implicit consent',
        data: [
            {
                sherlockError: 'FAILURE',
                screen: 'Open bank account',
                reason: 'UPI consent recording failed',
                appError: '',
                resolution: 'Rare issue. They will be brought back to open bank account screen and they can move ahead',
            },
        ],
    },
    {
        stage: 'DEVICE_REGISTRATION',
        description: 'Register the mobile device of the user, an SMS is triggered in the background',
        data: [
            {
                sherlockError: 'FAILURE',
                screen: 'Open bank account',
                reason: 'Outgoing SMS not sent',
                appError: 'User is on the loading screen saying Registering device',
                // eslint-disable-next-line max-len
                resolution: 'Make sure network signal is good. Wait for retries to complete. If not working, close app come back and retry in sometime',
            },
            {
                sherlockError: 'FAILURE',
                screen: 'Open bank account',
                reason: 'Device registration check didn\'t happen',
                appError: 'User is on the loading screen saying Registering device',
                // eslint-disable-next-line max-len
                resolution: 'Possible that outgoing SMS either wasn\'t sent from user\'s phone or wasn\'t received by Federal. Retry is the only solution',
            },
            {
                sherlockError: 'FAILURE',
                screen: 'Open bank account',
                reason: 'Device registration check failed',
                appError: 'User is on the loading screen saying Registering device',
                // eslint-disable-next-line max-len
                resolution: 'Possible that outgoing SMS either wasn\'t sent from user\'s phone or wasn\'t received by Federal. Retry is the only solution. If dual SIM, choose the right SIM',
            },
        ],
    },
    {
        stage: 'CUSTOMER_CREATION',
        description: 'Create a customer in partner bank\'s end, step in the background',
        data: [
            {
                sherlockError: 'FAILURE',
                screen: 'Open bank account',
                reason: 'Customer creation failed due to data quality issues',
                appError: 'User is seeing screen saying account cannot be opened digitally',
                resolution: 'Flag issue internally, might need bug fixes. And then a retry can be initiated',
            },
            {
                sherlockError: 'INPROGRESS',
                screen: 'Open bank account',
                reason: 'Customer creation stuck',
                appError: 'User is seeing loading screen saying customer creation in progress',
                resolution: 'Flag issue internally, might need bug fixes. And then a retry can be initiated',
            },
        ],
    },
    {
        stage: 'ACCOUNT_CREATION',
        description: 'Creation of user\'s saving account',
        data: [
            {
                sherlockError: 'INPROGRESS',
                screen: 'Open bank account',
                reason: 'Account creation stuck',
                appError: 'User is seeing loading screen saying account creation in progress',
                resolution: 'Flag issue internally, might need bug fixes. And then a retry can be initiated',
            },
        ],
    },
    {
        stage: 'CARD_CREATION',
        description: 'Create the debit card for the user',
        data: [
            {
                sherlockError: 'INPROGRESS',
                screen: 'Open bank account',
                reason: 'Card creation stuck',
                appError: 'User is seeing loading screen saying card creation in progress',
                resolution: 'Flag issue internally, might need bug fixes. And then a retry can be initiated',
            },
        ],
    },
    {
        stage: 'UPI_SETUP',
        description: 'UPI registration, step in the background',
        data: [
            {
                sherlockError: 'INPROGRESS',
                screen: 'Open bank account',
                reason: 'UPI ID creation stuck',
                appError: 'User is seeing loading screen saying card creation in progress',
                resolution: 'Flag issue internally, might need bug fixes. And then a retry can be initiated',
            },
        ],
    },
    {
        stage: 'SHIPPING_ADDRESS_UPDATE',
        description: 'Updating the card shipping address at partner bank end',
        data: [
            {
                sherlockError: 'INPROGRESS',
                screen: 'Open bank account',
                reason: 'UPI ID creation stuck',
                appError: 'User is seeing loading screen saying updating shipping address',
                resolution: 'Flag issue internally, might need bug fixes. And then a retry can be initiated',
            },
        ],
    },
    {
        stage: 'DEBIT_CARD_PIN_SETUP',
        description: 'User sets the ATM PIN',
        data: [
            {
                sherlockError: 'FAILURE',
                screen: 'Card PIN set',
                reason: 'User comes to app after a long time & PIN set fails',
                appError: 'User comes to app after a long time & PIN set fails',
                resolution: 'Ask them to retry. OTP might be needed. If still doesn\'t work, flag issue internally',
            },
            {
                sherlockError: 'INPROGRESS',
                screen: 'Card PIN set',
                reason: 'OTP not received',
                appError: 'OTP not received',
                resolution: 'Make sure network signal is good. If not received even then, flag issue internally',
            },
        ],
    },
];

export const IN_APP_SCREENING_TROUBLESHOOT_MAP = [
    {
        stage: 'REFERRAL_FINITE_CODE',
        description: 'This stage allows a user to enter a finite code',
        data: [
            {
                sherlockError: 'FAILURE',
                screen: 'FINITE CODE',
                reason: 'Entered fi.nite code was incorrect',
                appError: 'Invalid fi.nite code error message on the Fi.nite code screen',
                resolution: 'User can either skip the fi.nite code step or enter a correct fi.nite code',
            },
            {
                sherlockError: 'FAILURE',
                screen: 'T&C',
                reason: 'Service downtime leading to incomplete validation',
                appError: 'Fi.nite code was entered and moved to the next screen without any error. Referral reward not received',
                // eslint-disable-next-line max-len
                resolution: 'Less likely. Will have to verify and assign reward to such user later. User will still be able to continue with onboarding.',
            },
        ],
    },
    {
        stage: 'CHECK_CREDIT_REPORT_PRESENCE',
        description: 'This stage checks the presence of credit report of user',
        data: [
            {
                sherlockError: 'FAILURE',
                screen: 'EMPLOYMENT_VERIFICATION',
                reason: 'External API to check credit report presence failed',
                appError: 'User moved to employment verification screen after the a loading screen saying \'Finding the shortest path\'',
                resolution: 'Proceed ahead with employment verification. If failed in that, user will be put on hold.',
            },
        ],
    },
    {
        stage: 'MANDATE_CONSENT_CREDIT_REPORT',
        description: 'User is mandated to give consent to download and verify the credit report. If there is no consent, user cannot go further.',
        data: [
            {
                sherlockError: 'INPROGRESS',
                screen: 'MANDATE_CONSENT_CREDIT_REPORT',
                // eslint-disable-next-line max-len
                reason: 'User\'s credit report was found but consent was not given to download the report. Additionally, employment verification failed for the user.',
                appError: 'User stuck on a screen asking to verify with credit report as only CTA',
                resolution: 'Give the consent to read credit report to expedite the onboarding process. Else user will be put on hold.',
            },
        ],
    },
    {
        stage: 'MANUAL_SCREENING',
        description: 'User needs to go through manual employment verification',
        data: [
            {
                sherlockError: 'INPROGRESS',
                screen: 'MANUAL_SCREENING',
                reason: 'User is put on hold and will require a manual intervention to be accepted or rejected',
                appError: 'User stuck on screen without any CTA explaining that further verification will be done',
                resolution: 'Can be taken by the team on a case by case basis. Forward the issue to marketing team.',
            },
            {
                sherlockError: 'FAILURE',
                screen: 'REJECT',
                reason: 'User has been rejected from onboarding flow',
                appError: 'User is communicated that they cannot continue right now',
                // eslint-disable-next-line max-len
                resolution: 'No immediate resolution as the user has been rejected. Status can possibly change with change in credit report after ~ 90 days.',
            },
        ],
    },
    {
        stage: 'APP_SCREENING',
        description: 'App screener parent stage. This stage is first evaluated before moving onto individual stages.',
        data: [
            {
                sherlockError: 'FAILURE',
                screen: 'REJECT',
                reason: 'User has been rejected from onboarding flow',
                appError: 'User is communicated that they cannot continue right now',
                // eslint-disable-next-line max-len
                resolution: 'No immediate resolution as the user has been rejected. Status can possibly change with change in credit report after ~ 90 days.',
            },
            {
                sherlockError: 'UNSPECIFIED',
                screen: 'MANDATE_CONSENT_CREDIT_REPORT',
                reason: 'User has failed employment verification and might not have given credit report consent',
                appError: 'User stuck on a screen explaining a further verification will be done',
                resolution: 'Give the consent to read credit report to expedite the onboarding process. Else user will be put on hold.',
            },
            {
                sherlockError: 'UNSPECIFIED',
                screen: 'MANUAL_SCREENING',
                reason: 'User has failed employment verification and might not have given credit report consent',
                appError: 'User stuck on a screen explaining a further verification will be done',
                resolution: 'Can be taken by the team on a case by case basis. Forward the issue to marketing team.',
            },
        ],
    },
];

export const REOOBE_TROUBLESHOOT_LABEL_DATA = [
    {
        id: 1,
        label: 'Current Stage',
        key: 'stage',
    },
    {
        id: 2,
        label: 'Status',
        key: 'state',
    },
    {
        id: 3,
        label: 'Diagnosis Code',
        key: 'diagnosisCode',
    },
    {
        id: 4,
        label: 'Diagnosis Report',
        key: 'diagnosisReport',
    },
    {
        id: 5,
        label: 'Troubleshoot',
        key: 'debugInfo',
    },
];

export const VKYC_CUSTOMER_INFO_LABEL_DATA = [
    {
        id: 1,
        label: 'Weblink',
        key: 'weblink',
    },
    {
        id: 2,
        label: 'KYC Date',
        key: 'kycDate',
    },
    {
        id: 3,
        label: 'Created At',
        key: 'createdTs',
    },
    {
        id: 2,
        label: 'Updated At',
        key: 'updatedTs',
    },
];

export const accountInformationGuidelines = {
    dormant: {
        resolutionSteps: [
            'Inform the user about the dormant status and reason for the same.',
            'Share KYC update form and customer request form with the user and ask the user to reply with filled forms.',
            'Once forms are received, flip the ticket to CNX team with necessary details and forms.',
            'TAT: account will be active within 4 working days.',
        ],
        reason: 'No user initiated or non-bank  transactions (p2p or p2m) in the last 2 years.',
        // eslint-disable-next-line max-len
        note: 'In the event of errors in the filled forms, kindly ask the user to make necessary corrections and resubmit to avoid delays in the account reactivation process.',
    },
    inactive: {
        reason: 'No user initiated or non-bank transactions (p2p or p2m) in the last 1 year.',
        resolutionSteps: [
            'Inform the user that their account is in inactive status and the reason for the same.',
            'Ask the user to make a successful outgoing transaction.',
        ],
    },
};

export const LABEL_VALUE_DATA_TYPE = {
    string: 'DATA_TYPE_STRING',
    table: 'DATA_TYPE_TABLE',
};

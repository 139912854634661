import {
    GET_ACCOUNT_CLOSURE_INFO, GET_CHEQUEBOOK_REQUEST_DETAILS, GET_ACCOUNT_STATEMENT_FORM_DETAILS,
    GET_AGENT_PROMPTS_ACTION, GET_SEND_NOTIFICATION_BY_PROMPTS, SET_SEND_NOTIFICATION_BY_PROMPTS,
} from './constants';

export const getAccountClosureInfo = (data) => ({
    type: GET_ACCOUNT_CLOSURE_INFO,
    data,
});

export const getUserChequebookDetails = (data) => ({
    type: GET_CHEQUEBOOK_REQUEST_DETAILS,
    data,
});

export const getAccountStatementFormDetails = (data) => ({
    type: GET_ACCOUNT_STATEMENT_FORM_DETAILS,
    data,
});

export const getAgentPrompts = (data) => ({
    type: GET_AGENT_PROMPTS_ACTION,
    data,
});

export const sendNotificationByPrompt = (data) => ({
    type: GET_SEND_NOTIFICATION_BY_PROMPTS,
    data,
});

export const setSendNotificationByPrompt = (data) => ({
    type: SET_SEND_NOTIFICATION_BY_PROMPTS,
    data,
});

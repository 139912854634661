/* eslint-disable react/jsx-props-no-spreading */
/**
 * @file Customer Auth component
 * A wrapper component which is used to perform customer authentication
 * to get authorized to a less to high sensitivity page or information
 */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { hasAccessToThePage } from '../../../utils/helpers';
import { Button } from '../../../components';

import { getInitialAuthFactor, setAuthUpgradeInfo, setCustomerAuth } from '../actions';
import { makeSelectCustomerAuth } from '../selectors';
import AuthUpgradeScreen from '../AuthUpgradeScreen';
import UserAuth from '../UserAuth';

const screen = {
    AUTHUPGRADE: 'AUTHUPGRADE',
    CUSTOMERAUTH: 'CUSTOMERAUTH',
};

const CustomerAuth = (props) => {
    const {
        index, ticketId, userId, activeTicket, children,
    } = props;

    const [authScreen, setAuthScreen] = useState(null);

    const { pathname } = useLocation();

    const { isActive, sensitivityLevel, authPathname } = useSelector(makeSelectCustomerAuth(index), shallowEqual);

    const dispatch = useDispatch();

    // reset customer auth and authScreen
    const closeAuth = () => {
        setAuthScreen(null);
        dispatch(setAuthUpgradeInfo({ isAuthUpgradeInProgress: false, index }));
        dispatch(setCustomerAuth({
            index, isActive: false, sensitivityLevel: null, authPathname: '',
        }));
    };

    // useEffect to close auth page if there is a mis-match in pathname
    useEffect(() => {
        if (pathname !== authPathname) {
            closeAuth();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, authPathname]);

    useEffect(() => {
        if (isActive) {
            // enters when sensitivityLevel is valid and authScreen is not valid
            // authScreen is used to avoid unnecessary call to inner if statement
            if (sensitivityLevel && !authScreen) {
                // checking for page or details sensitivity
                if (!hasAccessToThePage(activeTicket, sensitivityLevel)) {
                    setAuthScreen(screen.AUTHUPGRADE);
                } else {
                    closeAuth();
                }
            }
        } else {
            // resetting authScreen if isActive is false
            setAuthScreen(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTicket, authScreen, isActive, sensitivityLevel]);

    const handleOnAuthUpgrade = () => {
        dispatch(setAuthUpgradeInfo({ isAuthUpgradeInProgress: true, index }));
        dispatch(getInitialAuthFactor({ ticketId, userId, index }));
        setAuthScreen(screen.CUSTOMERAUTH);
    };

    const onAuthSuccess = () => {
        closeAuth();
    };

    if (authScreen) {
        return (
            <React.Fragment>
                {
                    authScreen === screen.AUTHUPGRADE
                        ? <AuthUpgradeScreen {...props} onClick={handleOnAuthUpgrade} />
                        : null
                }
                {
                    authScreen === screen.CUSTOMERAUTH
                        ? (
                            <UserAuth
                                disableTicketUserInfo
                                index={index}
                                handleRedirect={onAuthSuccess}
                            />
                        )
                        : null
                }
                <div className='frccWrapper mt-30'>
                    <Button warning label='Go back' onClick={closeAuth} />
                </div>
            </React.Fragment>
        );
    }

    return children;
};

export default CustomerAuth;

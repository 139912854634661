/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 *
 * MutualFundOrdersList - Component to show the invested mutual fund orders list by the current user
 *
 */

import React, { useState } from 'react';
import classNames from 'classnames';

import { Pagination } from '../../../components';

import MutualFundOrderInfoModal from './MutualFundOrderInfo';

const MutualFundOrdersList = (props) => {
    const [showMutualFundOrderInfoModal, setMutualFundOrderInfoModal] = useState(false);

    const {
        data, prev, next, getPaginatedData, handleViewDetails, mutualFundOrderInfoData, hasMutualFundOrderInfoData,
    } = props;

    const containerClass = classNames(
        'mfs-src1',
    );

    if (!data) return <div className={containerClass} />;

    if (data.length === 0) {
        return (
            <div className={containerClass}>
                <div className='table-nsr table-nsr--mt0'>
                    No search results found!
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className={containerClass}>
                <div className='table-sr table-sr--mt0'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th'>Created{'\n'}At</div>
                        <div className='table-sr__th'>Updated{'\n'}At</div>
                        <div className='table-sr__th'>Amount</div>
                        <div className='table-sr__th'>Rta Confirmed Amount</div>
                        <div className='table-sr__th'>Units</div>
                        <div className='table-sr__th'>Folio{'\n'}No</div>
                        <div className='table-sr__th'>Txn{'\n'}Type</div>
                        <div className='table-sr__th'>Current{'\n'}Status</div>
                        <div className='table-sr__th'>UTR{'\n'}No</div>
                        <div className='table-sr__th'>Order{'\n'}Id</div>
                        <div className='table-sr__th'>Vendor{'\n'}Order Id</div>
                        <div className='table-sr__th'>ETA</div>
                        <div className='table-sr__th'>Action</div>
                    </div>
                    <div className='table-sr__tlc'>
                        {
                            data.map((item) => (
                                <div className='table-sr__tlw' key={item.orderId}>
                                    <div className='table-sr__tl'>{item.createdTs}</div>
                                    <div className='table-sr__tl'>{item.updatedTs}</div>
                                    <div className='table-sr__tl'>{item.amount}</div>
                                    <div className='table-sr__tl'>{item.rtaConfirmedAmount}</div>
                                    <div className='table-sr__tl'>{item.units}</div>
                                    <div className='table-sr__tl'>{item.folioNo}</div>
                                    <div className='table-sr__tl'>{item.txnType}</div>
                                    <div className='table-sr__tl'>{item.status}</div>
                                    <div className='table-sr__tl'>{item.utrNo}</div>
                                    <div className='table-sr__tl'>{item.externalOrderId}</div>
                                    <div className='table-sr__tl'>{item.vendorOrderId}</div>
                                    <div className='table-sr__tl'>{item.eta}</div>
                                    <div
                                        className='table-sr__tl link'
                                        onClick={() => {
                                            setMutualFundOrderInfoModal(true);
                                            handleViewDetails(item.orderId);
                                        }}
                                    >
                                        View Details
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Pagination prev={prev} next={next} onClickHandler={getPaginatedData} />
            </div>
            <MutualFundOrderInfoModal
                visible={showMutualFundOrderInfoModal && hasMutualFundOrderInfoData}
                data={mutualFundOrderInfoData}
                toggleHandler={() => {
                    setMutualFundOrderInfoModal(false);
                }}
            />
        </React.Fragment>
    );
};

export default MutualFundOrdersList;

/*
 *
 * @file Actions for reviewing facematch items.
 *
 */

import {
    GET_USS_FM_REVIEW_ACTION, GET_USS_FM_REVIEW_ITEMS, GET_USS_FM_REVIEW_ITEM_DETAILS,
    GET_USS_FM_REVIEW_REASON, SET_USS_FM_FACEMATCH_PAYLOAD_TYPE, SET_USS_FM_FACEMATCH_VIEW,
    SET_USS_FM_REVIEW_ACTION_TYPE, SET_USS_FM_REVIEW_REASON_TYPE, GET_USS_FM_PAYLOAD_TYPE_LIST, GET_USS_FM_REVIEW_AS_DONE,
} from './constants';

export const getUssFmPayloadTypeListAction = (data) => ({
    type: GET_USS_FM_PAYLOAD_TYPE_LIST,
    data,
});

export const setUssFmFacematchPayloadTypeAction = (data) => ({
    type: SET_USS_FM_FACEMATCH_PAYLOAD_TYPE,
    data,
});

export const getUssFmReviewActionListAction = (data) => ({
    type: GET_USS_FM_REVIEW_ACTION,
    data,
});

export const setUssFmReviewActionTypeAction = (data) => ({
    type: SET_USS_FM_REVIEW_ACTION_TYPE,
    data,
});

export const getUssFmReviewReasonListAction = (data) => ({
    type: GET_USS_FM_REVIEW_REASON,
    data,
});

export const setUssFmReviewReasonTypeAction = (data) => ({
    type: SET_USS_FM_REVIEW_REASON_TYPE,
    data,
});

export const getUssFmReviewItemsAction = (data) => ({
    type: GET_USS_FM_REVIEW_ITEMS,
    data,
});

export const getUssFmReviewItemDetailsAction = (data) => ({
    type: GET_USS_FM_REVIEW_ITEM_DETAILS,
    data,
});

export const setUssFmReviewAsDoneAction = (data) => ({
    type: GET_USS_FM_REVIEW_AS_DONE,
    data,
});

export const setUssFmFacematchViewAction = (data) => ({
    type: SET_USS_FM_FACEMATCH_VIEW,
    data,
});

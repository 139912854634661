/**
 *
 * Constants for CX dispute flow
 *
 */

import { getInitialDateRange } from '../../utils';

export const DISPUTES = 'disputes';

// actions
export const GET_CX_DISPUTES_INFO = 'app/Disputes/GET_DISPUTES_INFO';
export const SET_CX_DISPUTES_INFO = 'app/Disputes/SET_DISPUTES_INFO';
export const ERR_CX_DISPUTES_INFO = 'app/Disputes/ERR_DISPUTES_INFO';

export const UPDATE_CX_DISPUTES_INFO = 'app/Disputes/UPDATE_CX_DISPUTES_INFO';
export const UPDATE_SUCCESS_CX_DISPUTES = 'app/Disputes/UPDATE_SUCCESS_CX_DISPUTES';

export const GET_CX_DISPUTES_INFO_FOR_ACTOR = `app/${DISPUTES}/GET_CX_DISPUTES_INFO_FOR_ACTOR`;
export const SET_CX_DISPUTES_INFO_FOR_ACTOR = `app/${DISPUTES}/SET_CX_DISPUTES_INFO_FOR_ACTOR`;
export const ERR_CX_DISPUTES_INFO_FOR_ACTOR = `app/${DISPUTES}/ERR_CX_DISPUTES_INFO_FOR_ACTOR`;

export const DATE_RANGE_KEY = 'cx-disputes-date-range';

export const REDUCER_KEYS = {
    DISPUTES_INFO_FOR_ACTOR: 'disputesInfoForActor',
    ESCALATION_MODE_OPTIONS: 'escalationModeOptions',
    LOADING: 'loading',
    ERR: 'err',
};

// class values
export const DD_WRAPPER_CLS = {
    container: 'custom-dd disputes-idd',
};

export const DD_CONTENT_CLS = {
    container: ['disputes-idd__cc'],
};

export const DD_ITEM_CLS = 'custom-dd__cl disputes-idd__cl';

export const DATE_PICKER_CLS = {
    container: 'disputes-fc',
    label: 'disputes-dd__lb1',
};

export const DATE_PICKER_CLS_FOR_VIEW_CORRESPONDENCE = {
    container: 'disputes-fc mb-60',
    label: 'disputes-dd__lb2',
};

export const DD_OG_STATE = {
    value: '',
    isVisible: false,
};

// from date will be current date - 60 days
const days = 60;
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - days);

export const INITIAL_DATE_RANGE = getInitialDateRange(null, true, {
    defaultFromDate,
});

// form constants
export const FROM_DATE_INFO = {
    label: 'From Date*',
    labelId: 'cx-disputes-from-date',
};

export const TO_DATE_INFO = {
    label: 'To Date*',
    labelId: 'cx-disputes-to-date',
};

export const dateInfo = {
    fromDateInfo: FROM_DATE_INFO,
    toDateInfo: TO_DATE_INFO,
    initialDateRange: INITIAL_DATE_RANGE,
    extClasses: DATE_PICKER_CLS,
};

export const dateInfoForViewCorrespondence = {
    fromDateInfo: FROM_DATE_INFO,
    toDateInfo: TO_DATE_INFO,
    initialDateRange: INITIAL_DATE_RANGE,
    extClasses: DATE_PICKER_CLS_FOR_VIEW_CORRESPONDENCE,
};

export const CHANNEL_OPTIONS = [
    { value: 'UPI', label: 'UPI' },
    { value: 'IMPS', label: 'IMPS' },
    { value: 'NEFT', label: 'NEFT' },
    { value: 'RTGS', label: 'RTGS' },
    { value: 'DEBIT_CARD_ATM', label: 'DEBIT_CARD_ATM' },
    { value: 'DEBIT_CARD_POS', label: 'DEBIT_CARD_POS' },
    { value: 'DEBIT_CARD_ECOM', label: 'DEBIT_CARD_ECOM' },
    { value: 'CHEQUE', label: 'CHEQUE' },
    { value: 'INTRA_BANK', label: 'INTRA_BANK' },
    { value: 'CARD', label: 'CARD' },
];

export const DISPUTE_TYPE_OPTIONS = [
    { value: 'AUTHORISED', label: 'AUTHORISED' },
    { value: 'UNAUTHORISED', label: 'UNAUTHORISED' },
    { value: 'WRONG_BENEFICIARY', label: 'WRONG_BENEFICIARY' },
    { value: 'WRONG_ACCOUNT_NUMBER', label: 'WRONG_ACCOUNT_NUMBER' },
    { value: 'WRONG_AMOUNT', label: 'WRONG_AMOUNT' },
];

export const DISPUTE_STATE_OPTIONS = [
    { value: 'CREATED', label: 'CREATED' },
    { value: 'PROCESSED', label: 'PROCESSED' },
    { value: 'RESOLVED', label: 'RESOLVED' },
    { value: 'RESOLVED_ACCEPTED', label: 'RESOLVED_ACCEPTED' },
    { value: 'RESOLVED_REJECTED', label: 'RESOLVED_REJECTED' },
    { value: 'ESCALATED', label: 'ESCALATED' },
    { value: 'MANUAL_INTERVENTION', label: 'MANUAL_INTERVENTION' },
];

export const DISPUTE_ESCALATION_MODE_OPTIONS = [
    { value: 'FRESHDESK', label: 'Freshdesk' },
    { value: 'ESCALATION_MODE_UDIR', label: 'UDIR' },
    { value: 'ESCALATION_MODE_FEDERAL_BANK', label: 'Federal Bank' },
];

export const DISPUTE_ESCALATION_MODE = {
    FRESHDESK: 'FRESHDESK',
    UDIR: 'ESCALATION_MODE_UDIR',
    FEDERAL: 'ESCALATION_MODE_FEDERAL_BANK',
};

export const DEFAULT_STYLES_FOR_MODAL = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '10%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        fontSize: 16,
        width: 500,
        height: 300,
        border: 0,
        backgroundColor: '#F5F5F5',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

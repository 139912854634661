/**
 * @file Manages the selector functions for Transaction case container
 */

import { createSelector } from 'reselect';

import { TRANSACTION_CASE_DETAILS_KEY } from './constants';

export const selectTransactionCaseDetails = createSelector(
    (state) => state.getIn([TRANSACTION_CASE_DETAILS_KEY, 'transactionCaseDetails']),
    (substate) => substate?.toJS(),
);

export const selectTransactionCaseLoader = createSelector(
    (state) => state.getIn([TRANSACTION_CASE_DETAILS_KEY, 'loading']),
    (substate) => substate,
);

export const selectTransactionCaseError = createSelector(
    (state) => state.getIn([TRANSACTION_CASE_DETAILS_KEY, 'err']),
    (substate) => substate,
);

export const selectTransactionCaseAnnotation = createSelector(
    (state) => state.getIn([TRANSACTION_CASE_DETAILS_KEY, 'listAnnotation']),
    (substate) => substate?.toJS(),
);

export const selectTransactionCaseComments = createSelector(
    (state) => state.getIn([TRANSACTION_CASE_DETAILS_KEY, 'listComments']),
    (substate) => substate?.toJS(),
);

/**
 * @file Manages the constants for Bulk Ticket Update component
 */

export const SET_UPLOAD_CSV_FORM_PARAMS = 'app/BTU/SET_UPLOAD_CSV_FORM_PARAMS';
export const RESET_UPLOAD_CSV_FORM_PARAMS = 'app/BTU/RESET_UPLOAD_CSV_FORM_PARAMS';

export const SET_SEARCH_PARAMS = 'app/BTU/SET_SEARCH_PARAMS';
export const RESET_SEARCH_PARAMS = 'app/BTU/RESET_SEARCH_PARAMS';

export const UPDATE_BULK_TICKETS_INFO = 'app/BTU/UPDATE_BULK_TICKETS_INFO';
export const SET_UPDATE_BULK_TICKETS_INFO = 'app/BTU/SET_UPDATE_BULK_TICKETS_INFO';
export const ERR_UPDATE_BULK_TICKETS_INFO = 'app/BTU/ERR_UPDATE_BULK_TICKETS_INFO';

export const GET_BULK_TICKET_JOB_LIST = 'app/BTU/GET_BULK_TICKET_JOB_LIST';
export const SET_BULK_TICKET_JOB_LIST = 'app/BTU/SET_BULK_TICKET_JOB_LIST';
export const ERR_BULK_TICKET_JOB_LIST = 'app/BTU/ERR_BULK_TICKET_JOB_LIST';

export const GET_BULK_TICKET_JOB_FAILURE_INFO = 'app/BTU/GET_BULK_TICKET_JOB_FAILURE_INFO';
export const SET_BULK_TICKET_JOB_FAILURE_INFO = 'app/BTU/SET_BULK_TICKET_JOB_FAILURE_INFO';
export const ERR_BULK_TICKET_JOB_FAILURE_INFO = 'app/BTU/ERR_BULK_TICKET_JOB_FAILURE_INFO';

export const KILL_BULK_TICKET_JOB = 'app/BTU/KILL_BULK_TICKET_JOB';
export const ERR_KILL_BULK_TICKET_JOB = 'app/BTU/ERR_KILL_BULK_TICKET_JOB';

export const RESET_LOADER = 'app/BTU/RESET_LOADER';

export const BULK_TICKET_JOB_LIST_LABEL_DATA = [
    {
        id: 1,
        label: 'Job\nStatus',
        key: 'status',
    },
    {
        id: 2,
        label: 'Job\nDescription',
        key: 'description',
    },
    {
        id: 3,
        label: 'Total\nTicket Count',
        key: 'totalTicketCount',
    },
    {
        id: 4,
        label: 'Processed\nCount',
        key: 'processedTicketCount',
    },
    {
        id: 5,
        label: 'Success\nCount',
        key: 'successfulTicketCount',
    },
    {
        id: 6,
        label: 'Failure\nCount',
        key: 'failedTicketCount',
    },
    {
        id: 7,
        label: 'Created\nAt',
        key: 'createdTs',
    },
    {
        id: 8,
        label: 'Updated\nAt',
        key: 'updatedTs',
    },
    {
        id: 9,
        label: 'Is\nKilled',
        key: 'isKilled',
    },
    {
        id: 10,
        label: 'Started\nBy',
        key: 'startedByEmail',
    },
    {
        id: 11,
        label: 'Checked\nBy',
        key: 'checkerEmail',
    },
];

export const BULK_TICKET_JOB_FAILED_TICKET_LIST_LABEL_DATA = [
    {
        id: 1,
        label: 'Ticket ID',
        key: 'ticketId',
    },
    {
        id: 2,
        label: 'Reason',
        key: 'reason',
    },
    {
        id: 3,
        label: 'Created At',
        key: 'createdTs',
    },
    {
        id: 4,
        label: 'Updated At',
        key: 'updatedTs',
    },
];

export const BULK_TICKET_UPDATE_KEY = 'bulkTicketUpdate';

/**
 *
 * Deposits
 *
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import {
    Button, DropdownContent, DropdownWrapper, ExpandableCard, FormWithSingleInput,
    Loader,
} from '../../components';
import { dateUtils, injectSaga, injectReducer } from '../../utils';

import { attachEntityToTicket } from '../Tickets/actions';
import DepositList from './DepositList';
import DetailsModal from './DetailsModal';
import TxnsModal from './TxnsModal';
import { SEARCH_BY_LIST, ACCOUNT_TYPE } from './constants';
import {
    setSearchParams, resetSearchParams, getDepositsList, getDepositRequestList, getDepositTxnList,
} from './actions';
import {
    makeSelectSearchParameters, makeSelectDepositsInfo,
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const Deposits = (props) => {
    const dispatch = useDispatch();

    const searchParameters = useSelector(makeSelectSearchParameters(), shallowEqual);
    const depositsInfo = useSelector(makeSelectDepositsInfo(), shallowEqual);

    const [showSearchByDD, setSearchByDD] = useState(false);
    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(false);
    const [showDepositsDropdown, setDepositsDropdown] = useState(false);
    const [errLabel, setErrLabel] = useState('');
    const [selectedDeposit, setSelectedDeposit] = useState({});
    const [openedActionsDepositId, setOpenedActionsDepositId] = useState('');
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [isTxnsModalOpen, setIsTxnsModalOpen] = useState(false);

    const { fromDate, toDate } = dateUtils.getInitialDateRange('02-01-2021', true); // 01 Feb

    const { ticketId, userId, index } = props;

    let searchBy;
    let depositNumber;
    let accountType;
    let fromCreatedDate;
    let toCreatedDate;
    let fromMaturityDate;
    let toMaturityDate;

    // extract the search parameters for passed ticket id
    if (searchParameters[ticketId]) {
        ({
            searchBy, depositNumber, accountType, fromCreatedDate,
            toCreatedDate, fromMaturityDate, toMaturityDate,
        } = searchParameters[ticketId]);
    }

    /**
     * initialise from and to date with the date utils value
     * this won't get called again & again
     */
    useEffect(() => {
        if (!fromCreatedDate && !toCreatedDate) {
            dispatch(setSearchParams({
                ticketId,
                fromCreatedDate: fromDate,
                toCreatedDate: toDate, 
            }));
        }

        // no date limits for maturity date
        if (!fromMaturityDate && !toMaturityDate) {
            const initialMaturityDate = {
                lowerLimit: '',
                upperLimit: '',
                value: '',
            };

            dispatch(setSearchParams({
                ticketId,
                fromMaturityDate: initialMaturityDate,
                toMaturityDate: initialMaturityDate,
            }));
        }
    }, [fromCreatedDate, toCreatedDate, fromMaturityDate, toMaturityDate]);

    let loading;
    let depositList;
    let hasDepositList;
    let depositRequestList;
    let hasDepositRequestList;
    let depositTxnList;

    // extract the deposits related details for passed ticket id
    if (depositsInfo[ticketId]) {
        ({ loading, depositList, hasDepositList = false, depositRequestList, hasDepositRequestList = false,
            depositTxnList, } = depositsInfo[ticketId]);
    }

    const toggleSearchCard = () => {
        setIsSearchCardExpanded(!isSearchCardExpanded);
    };

    const handleInputChange = (key) => (event) => {
        dispatch(setSearchParams({
            ticketId,
            key,
            value: event.target.value,
        }));
    };

    const toggleDetailsModal = (value) => {
        setOpenedActionsDepositId('');
        setIsDetailsModalOpen(value);
    };

    const toggleTxnsModal = (value) => {
        setOpenedActionsDepositId('');

        if (value) {
            dispatch(getDepositTxnList({ ticketId, userId, index, depositId: selectedDeposit.depositId, setIsTxnsModalOpen }));
        } else {
            setIsTxnsModalOpen(value);
        }
    };

    const getPaginatedDepositTxnList = ({ prev, next }) => {
        dispatch(getDepositTxnList({ ticketId, userId, index, depositId: selectedDeposit.depositId, prev, next }));
    };

    const handleDateChange = (key, type) => value => {
        let fromDate;
        let toDate;

        switch (type) {
            case 'createdDate':
                fromDate = fromCreatedDate;
                toDate = toCreatedDate;
                break;

            case 'maturityDate':
                fromDate = fromMaturityDate;
                toDate = toMaturityDate;
                break;

            default:
        }

        const data = {
            key,
            value,
            fromDate,
            toDate,
        };

        const { fromDate: newFromDate, toDate: newToDate } = dateUtils.getModifiedDate(data);

        switch (type) {
            case 'createdDate':
                dispatch(setSearchParams({
                    ticketId,
                    fromCreatedDate: newFromDate,
                    toCreatedDate: newToDate, 
                }));
                break;

            case 'maturityDate':
                dispatch(setSearchParams({
                    ticketId,
                    fromMaturityDate: newFromDate,
                    toMaturityDate: newToDate, 
                }));
                break;

            default:
        }
    };

    const toggleDD = (key) => () => {
        switch (key) {
            case 'searchBy': {
                setSearchByDD(!showSearchByDD);

                break;
            }

            case 'accountType': {
                setDepositsDropdown(!showDepositsDropdown);

                break;
            }

            default:
        }
    };

    const handleDDChange = (key, value) => {
        dispatch(setSearchParams({
            ticketId,
            key,
            value,
        }));

        toggleDD(key)();
    };

    const attachEntityAgainstTicket = (meta, resolve) => {
        dispatch(attachEntityToTicket({
            ticketId,
            userId,
            index,
            type: 'SAVINGS',
            meta: [meta], // only single deposit can be marked
            resolve,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        let showErrLabel = false;

        if (!accountType) {
            showErrLabel = true;
        } else if (searchBy === 'Deposit Number') {
            if (!depositNumber) {
                showErrLabel = true;
            } else if (depositNumber.length !== 4) {
                setErrLabel('Please enter last 4 chars of deposit number');
                return;
            }
        } else if (searchBy === 'Created Date') {
            if (!fromCreatedDate.value || !toCreatedDate.value) {
                showErrLabel = true;
            }
        } else if (searchBy === 'Maturity Date') {
            if (!fromMaturityDate.value || !toMaturityDate.value) {
                showErrLabel = true;
            }
        };

        if (showErrLabel) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        const data = {
            index,
            ticketId,
            userId,
            accountType,
            searchBy,
        };

        if (searchBy === 'Deposit Number') {
            data.depositNumber = depositNumber;
        } else if (searchBy === 'Created Date') {
            data.fromCreatedDate = fromCreatedDate.value;
            data.toCreatedDate = toCreatedDate.value;

            dispatch(getDepositRequestList(data));
        } else if (searchBy === 'Maturity Date') {
            data.fromMaturityDate = fromMaturityDate.value;
            data.toMaturityDate = toMaturityDate.value;
        };

        setErrLabel('');
        setIsSearchCardExpanded(false);
        dispatch(getDepositsList(data));
    };

    const renderDateBlock = (key, type) => {
        let label;
        let labelId;
        let modifiedKey;
        let value;
        let maxDate;
        let minDate;

        switch (key) {
            case 'fromCreatedDate':
                label = 'From Created Date*';
                labelId = 'from-created-date';
                modifiedKey = 'fromDate';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = fromCreatedDate);
                break;

            case 'toCreatedDate':
                label = 'To Created Date*';
                labelId = 'to-created-date';
                modifiedKey = 'toDate';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = toCreatedDate);
                break;

            case 'fromMaturityDate':
                label = 'From Maturity Date*';
                labelId = 'from-maturity-date';
                modifiedKey = 'fromDate';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = fromMaturityDate);
                break;

            case 'toMaturityDate':
                label = 'To Maturity Date*';
                labelId = 'to-maturity-date';
                modifiedKey = 'toDate';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = toMaturityDate);
                break;

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'deposits-fc',
                    label: 'deposits-other-label'
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: handleDateChange(modifiedKey, type),
                    maxDate,
                    minDate,
                }}
            />
        );
    };

    const renderAccountTypeBlock = () => (
        <div className='fcWrapper'>
            <div className='frcWrapper'>
                <div className='deposits-other-label'>Account Type*</div>
                <DropdownWrapper
                    visible={showDepositsDropdown}
                    onOutsideClickHandler={toggleDD('accountType')}
                    extClasses={{
                        container: 'custom-dd'
                    }}
                >
                    <div onClick={toggleDD('accountType')}>
                        {accountType ? accountType.split('_').join(' ').toLowerCase() : 'Choose...'}
                    </div>
                    <DropdownContent
                        visible
                        extClasses = {{
                            container: ['custom-dd__cc height-transition', showDepositsDropdown && 'deposits-custom-dd__cc--ac']
                        }}
                    >
                        {
                            ACCOUNT_TYPE.map(item => (
                                <div key={item.label} className='custom-dd__cl' onClick={() => handleDDChange('accountType', item.value)}>{item.label}</div>
                            ))
                        }
                    </DropdownContent>
                </DropdownWrapper>
            </div>
        </div>
    );

    const renderSearchByBlock = () => {
        return (
            <div className='frcWrapper'>
                <div className='deposits-other-label'>Search By</div>
                <DropdownWrapper
                    visible={showSearchByDD}
                    onOutsideClickHandler={toggleDD('searchBy')}
                    extClasses={{
                        container: 'custom-dd'
                    }}
                >
                    <div onClick={toggleDD('searchBy')}>
                        {searchBy ? searchBy : 'Choose...'}
                    </div>
                    <DropdownContent
                        visible
                        extClasses = {{
                            container: ['custom-dd__cc height-transition', showSearchByDD && 'deposits-custom-dd__cc--ac']
                        }}
                    >
                        {
                            SEARCH_BY_LIST.map(item => (
                                <div key={item} className='custom-dd__cl' onClick={() => handleDDChange('searchBy', item)}>{item}</div>
                            ))
                        }
                    </DropdownContent>
                </DropdownWrapper>
            </div>
        );
    };

    const renderSearchCTA = () => (
        <React.Fragment>
            {errLabel ? <div className='err-label deposits-errlb'>{errLabel}</div> : null}
            <div className={`frcWrapper deposits-sc ${errLabel && 'deposits-sc--err'}`}>
                <Button
                    extClasses='deposits-sc__cta'
                    primary
                    label='Search'
                    onClick={handleSubmit}
                />
                <div
                    className='link deposits-sc__clear'
                    onClick={() => dispatch(resetSearchParams({ ticketId }))}
                >
                    Clear All Selection
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div className='deposits-cr'>
                <ExpandableCard
                    extClasses='deposits-ec'
                    heading='Search for deposits'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={toggleSearchCard}
                >
                    <div className='deposits-sbwr'>
                        {renderSearchByBlock()}
                        {
                            searchBy === 'Deposit Number' ? (
                                <FormWithSingleInput
                                    disableFormSubmitOnEnter
                                    extClasses={{
                                        container: 'deposits-fc',
                                        label: 'deposits-other-label'
                                    }}
                                    label={'Last 4 chars of deposit number*'}
                                    labelId='deposit-number'
                                    formType='text'
                                    input={{
                                        value: depositNumber,
                                        onChange: handleInputChange('depositNumber'),
                                        minLength: 4,
                                        maxLength: 4,
                                    }}
                                />
                            ) : null
                        }
                        {
                            searchBy === 'Created Date' ? (
                                <div className='frWrapper'>
                                    {renderDateBlock('fromCreatedDate', 'createdDate')}
                                    {renderDateBlock('toCreatedDate', 'createdDate')}
                                </div>
                            ) : null
                        }
                        {
                            searchBy === 'Maturity Date' ? (
                                <div className='frWrapper'>
                                    {renderDateBlock('fromMaturityDate', 'maturityDate')}
                                    {renderDateBlock('toMaturityDate', 'maturityDate')}
                                </div>
                            ) : null
                        }
                        { searchBy ? renderAccountTypeBlock() : null }
                        { searchBy ? renderSearchCTA() : null }
                    </div>
                </ExpandableCard>
                <DepositList
                    hasDepositList={hasDepositList}
                    depositList={depositList}
                    toggleDetailsModal={toggleDetailsModal}
                    toggleTxnsModal={toggleTxnsModal}
                    setSelectedDeposit={setSelectedDeposit}
                    openedActionsDepositId={openedActionsDepositId}
                    setOpenedActionsDepositId={setOpenedActionsDepositId}
                    attachEntityAgainstTicket={attachEntityAgainstTicket}
                />
                <DepositList
                    isDepositRequestList
                    hasDepositList={hasDepositRequestList}
                    depositList={depositRequestList}
                    toggleDetailsModal={toggleDetailsModal}
                    toggleTxnsModal={toggleTxnsModal}
                    setSelectedDeposit={setSelectedDeposit}
                    openedActionsDepositId={openedActionsDepositId}
                    setOpenedActionsDepositId={setOpenedActionsDepositId}
                />
            </div>
            {
                isDetailsModalOpen ? (
                    <DetailsModal selectedDeposit={selectedDeposit} toggleModal={toggleDetailsModal} />
                ) : null
            }
            {
                isTxnsModalOpen ? (
                    <TxnsModal
                        depositTxnList={depositTxnList}
                        toggleModal={toggleTxnsModal}
                        getPaginatedDepositTxnList={getPaginatedDepositTxnList}
                    />
                ) : null
            }
            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'deposits', reducer });
const withSaga = injectSaga({ key: 'deposits', saga });

export default compose(
    withReducer,
    withSaga,
)(Deposits);

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { WEALTH_ROOT, WEALTH_MF_FILTER_STATUS_ENDPOINT } from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_MUTUAL_FUND_AMC, SET_MUTUAL_FUND_AMC, ERR_MUTUAL_FUND_AMC, GET_MUTUAL_FUND_TXN_TYPE,
    SET_MUTUAL_FUND_TXN_TYPE, ERR_MUTUAL_FUND_TXN_TYPE, GET_MUTUAL_FUNDS, SET_MUTUAL_FUNDS,
    ERR_MUTUAL_FUNDS, GET_MUTUAL_FUND_ORDERS, SET_MUTUAL_FUND_ORDERS, ERR_MUTUAL_FUND_ORDERS,
    GET_MUTUAL_FUND_ORDER_INFO, SET_MUTUAL_FUND_ORDER_INFO, ERR_MUTUAL_FUND_ORDER_INFO, SET_SEARCH_PARAMS,
    GET_MUTUAL_FUND_ORDER_FILTER_STATUS, SET_MUTUAL_FUND_ORDER_FILTER_STATUS, ERR_MUTUAL_FUND_ORDER_FILTER_STATUS,
} from './constants';

function* getMFAmcList() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_ROOT}/mf-amc`,
        );

        yield put({ type: SET_MUTUAL_FUND_AMC, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_MUTUAL_FUND_AMC, data: { err: e.message } });
    }
}

function* getMFTransactionTypeList() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_ROOT}/mf-txn-type`,
        );

        yield put({ type: SET_MUTUAL_FUND_TXN_TYPE, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_MUTUAL_FUND_TXN_TYPE, data: { err: e.message } });
    }
}

function* getMFOrderFilterStatusListSaga() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            WEALTH_MF_FILTER_STATUS_ENDPOINT,
        );

        yield put({ type: SET_MUTUAL_FUND_ORDER_FILTER_STATUS, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
    }
}

function* getMutualFunds(action) {
    const { ticketId, index } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_ROOT}/mfs?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_MUTUAL_FUNDS, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_MUTUAL_FUNDS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_MUTUAL_FUNDS, data: { ticketId, err: e.message } });
    }
}

function* getMutualFundOrders(action) {
    const { ticketId, index } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_ROOT}/mf-orders?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_MUTUAL_FUND_ORDERS, data: { ticketId, ...response }});

            // set submitted flag true in search parameters
            yield put({
                type: SET_SEARCH_PARAMS,
                data: {
                    ticketId,
                    mutualFundOrdersView: true,
                    key: 'isSubmitted',
                    value: true,
                },
            });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_MUTUAL_FUND_ORDERS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_MUTUAL_FUND_ORDERS, data: { ticketId, err: e.message } });
    }
}

function* getMutualFundOrderInfo(action) {
    const { ticketId, userId, orderId, index } = action.data;
    let queryString = getQueryStringFromObject({ ticketId, userId });
    queryString = `${queryString}&orderId=${btoa(orderId)}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_ROOT}/mf-order-info?${queryString}`
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_MUTUAL_FUND_ORDER_INFO, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_MUTUAL_FUND_ORDER_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_MUTUAL_FUND_ORDER_INFO, data: { ticketId, err: e.message } });
    }
}

export default function* mutualFundsSaga() {
    yield all(
        [
            yield takeLatest(GET_MUTUAL_FUND_AMC, getMFAmcList),
            yield takeLatest(GET_MUTUAL_FUND_TXN_TYPE, getMFTransactionTypeList),
            yield takeLatest(GET_MUTUAL_FUND_ORDER_FILTER_STATUS, getMFOrderFilterStatusListSaga),
            yield takeLatest(GET_MUTUAL_FUNDS, getMutualFunds),
            yield takeLatest(GET_MUTUAL_FUND_ORDERS, getMutualFundOrders),
            yield takeLatest(GET_MUTUAL_FUND_ORDER_INFO, getMutualFundOrderInfo),
        ]
    );
}

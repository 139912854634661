/**
 * @file ExpandableSideBar component
 * Render any React Element passed to it as props using 'children'.
 * Can expand and collapse
 * Maintains the existing state even when the side bar state is changed
 */

import React, { memo } from 'react';
import classNames from 'classnames';

import './style.scss';
import { ICONS_URL } from '../../constants/assets';

/**
 * props:
 * children {ReactNode} - The React children that would be rendered
 *
 * data {object} - Data values for the component
 * isExpandedInitially {boolean} - If the sidebar needs to be expanded initially. By default it is not expanded
 * header {string} - Title of the sidebar
 *
 * extClasses {object} - External classes to override default class behavior
 *
 * Note:
 * For sidebar to work properly, set position:relative to the container that it is written inside.
 * This is needed since expandable sidebar has position: absolute
 *
 * Future Work:
 * Currently the sidebar works only for right aligned position
 * A new prop 'alignment' should be passed when we require a left aligned sidebar
 */
const ExpandableSidebar = (props) => {
    const {
        children, left = false, data = {}, extClasses = {},
    } = props;

    const { header = null, isExpanded, setIsExpanded } = data;

    const containerClasses = classNames({
        'sbar-cr': !left,
        'sbar-cr-left': left,
    }, extClasses && extClasses.container);
    const hideContainerClasses = classNames({
        'sbar-cr__hide': !left,
        'sbar-cr__hide-left': left,
    }, extClasses && extClasses.hideContainer);
    const headerContainerClasses = classNames('sbar-header-cr frcWrapper', extClasses && extClasses.headerContainer);
    const headerContentClasses = classNames('sbar-header-content', extClasses && extClasses.headerContent);
    const itemClasses = classNames('sbar-items', extClasses && extClasses.item);
    const arrowClass = classNames({
        'sbar-arrow': !isExpanded,
        'sbar-arrow--expanded': isExpanded,
    }, extClasses && extClasses.arrow);

    const onHeaderClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div
            className={isExpanded ? containerClasses : hideContainerClasses}
        >
            <button
                className={`${left ? 'sbar-header-cr-left ' : ''}${headerContainerClasses}`}
                onClick={onHeaderClick}
                type='button'
            >
                <img
                    className={arrowClass}
                    src={ICONS_URL.ARROW}
                    alt='arrow'
                />
                <div className={headerContentClasses}>
                    {header()}
                </div>
            </button>
            <div className={itemClasses}>
                {children}
            </div>
        </div>
    );
};

export default memo(ExpandableSidebar);

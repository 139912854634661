/**
 * @file Manages the saga generator functions for Case Search container
 */

import {
    call, put, takeLatest, all,
} from 'redux-saga/effects';

import {
    TRANSACTION_RISK_RELATED_CASE_ENDPOINT,
} from '../../api/routes';
import {
    clientApiWrapper, getQueryStringFromObject, showErrorToastMsg, getErrorObjectForSaga,
} from '../../utils';

import {
    GET_CASE_SEARCH,
    SET_CASE_SEARCH,
    ERR_CASE_SEARCH,
} from './constants';

const getErrorAction = (actionType, containerKey) => ({
    type: actionType,
    data: { containerKey },
});

function* getCaseSearchSaga(action) {
    const {
        prevToken = null, nextToken = null, actorId = null, caseId = null,
    } = action.data || {};

    const queryString = getQueryStringFromObject({
        prevToken, nextToken, actorId, caseId,
    } || {});

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTION_RISK_RELATED_CASE_ENDPOINT}?${queryString}`,
        );

        yield call(action.callback);
        yield put({ type: SET_CASE_SEARCH, data: response });
    } catch (error) {
        showErrorToastMsg(error);
        yield put(getErrorObjectForSaga(getErrorAction(ERR_CASE_SEARCH), error));
    }
}

export default function* caseSearchSaga() {
    yield all([
        yield takeLatest(GET_CASE_SEARCH, getCaseSearchSaga),
    ]);
}

/**
 * @file ViewTickets
 * View all open and pending tickets assigned to risk ops group
 */

import React, { memo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, Table } from '../../../components';

import { getRiskOpsCxTicketsAction, setCxRiskReviewCurTicketDetailsAction, setCxRiskReviewCurViewAction } from '../actions';
import { CX_TICKET_LIST_LABEL_DATA, TICKET_FILTERS, VIEW_ALL_REQUESTS_FOR_TICKET } from '../constants';
import { selectCxRiskTickets } from '../selectors';

const ViewTickets = () => {
    const dispatch = useDispatch();

    const cxRiskTickets = useSelector(selectCxRiskTickets, shallowEqual);
    const { data: ticketList, prev: prevToken, next: nextToken } = cxRiskTickets;

    useEffect(() => {
        if (!ticketList) dispatch(getRiskOpsCxTicketsAction({ ticketFilters: TICKET_FILTERS }));
    }, [dispatch]);

    const getPaginatedCallTicketList = ({ prev, next }) => {
        dispatch(getRiskOpsCxTicketsAction({ ticketFilters: TICKET_FILTERS, prev, next }));
    };

    const handleTicketDetails = (ticketDetails) => {
        dispatch(setCxRiskReviewCurTicketDetailsAction(ticketDetails));
        dispatch(setCxRiskReviewCurViewAction(VIEW_ALL_REQUESTS_FOR_TICKET));
    };

    const renderActionableColumn = (data) => (
        <div className='frccWrapper'>
            <a className='link-v2' href={data.sourceUrl} target='_blank' rel='noreferrer'>
                Open Ticket in Freshdesk
            </a>
            <div className='cxrisk-action-sr'>|</div>
            <Button
                v2
                link
                label='View Requests'
                disabled={!data.actorId}
                onClick={() => handleTicketDetails(data)}
            />
        </div>
    );

    return (
        <React.Fragment>
            <div className='frcsbWrapper mb-30'>
                <div className='cxrisk-hd'>CX Risk Tickets</div>
                <Button
                    v2
                    primary
                    label='Refresh'
                    onClick={getPaginatedCallTicketList}
                />
            </div>
            <Table
                v2
                header=''
                labelData={CX_TICKET_LIST_LABEL_DATA}
                contentData={ticketList}
                renderActionableColumn={renderActionableColumn}
                prevToken={prevToken}
                nextToken={nextToken}
                paginatedClickHandler={getPaginatedCallTicketList}
                extClasses={{
                    container: 'cxrisk-table-cr',
                    title: 'mb-30',
                    actionableColumn: 'f2Wrapper',
                }}
                nsrLabel='No existing tickets found!'
            />
        </React.Fragment>

    );
};

export default memo(ViewTickets);

/**
 *
 * Render results for CX disputes
 *
 */

import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { equals } from 'ramda';

import {
    Button, DropdownContent, DropdownWrapper, Loader, Pagination,
} from '../../../components';
import { useMemoizedSelector } from '../../../hooks';
import { clientApiWrapper, getErrMsg, showErrorToastMsg, toastify } from '../../../utils';
import { DISPUTE_MARK_AGAINST_TICKET_ENDPOINT } from '../../../api/routes';

import { getDisputesInfoAction } from '../actions';
import { selectDisputesInfo, selectDisputesInfoForActor } from '../selectors';

import ViewDetailsModal from '../Modals/ViewDetails';
import ViewCorrespondenceModal from '../Modals/ViewCorrespondence';
import ViewDocumentsModal from '../Modals/ViewDocuments';
import UpdateDisputeStatusModal from '../Modals/UpdateDisputeStatus';
import UpdateTicketIdModal from '../Modals/UpdateTicketId';
import AddCorrespondenceModal from '../Modals/AddCorrespondence';
import AttachDocumentModal from '../Modals/AttachDocument';
import EscalateToFederalModal from '../Modals/EscalateToFederal';
import { DISPUTE_ESCALATION_MODE } from '../constants';

const DROPDOWN_OPTIONS_INFO = {
    VIEW_DETAILS: {
        ctaLabel: 'View Details',
        id: 'view-details',
        order: 1,
        visibleTo: [DISPUTE_ESCALATION_MODE.FRESHDESK, DISPUTE_ESCALATION_MODE.FEDERAL, DISPUTE_ESCALATION_MODE.UDIR],
    },
    VIEW_CORRESPONDENCE: {
        ctaLabel: 'View Correspondence',
        id: 'view-correspondence',
        order: 2,
        visibleTo: [DISPUTE_ESCALATION_MODE.FEDERAL],
    },
    VIEW_DOCUMENTS: {
        ctaLabel: 'View Documents',
        id: 'view-documents',
        order: 3,
        visibleTo: [DISPUTE_ESCALATION_MODE.FEDERAL],
    },
    UPDATE_DISPUTE_STATUS: {
        ctaLabel: 'Update Dispute Status',
        id: 'update-dispute-status',
        order: 4,
        visibleTo: [DISPUTE_ESCALATION_MODE.FRESHDESK, DISPUTE_ESCALATION_MODE.FEDERAL, DISPUTE_ESCALATION_MODE.UDIR],
    },
    UPDATE_TICKET_ID: {
        ctaLabel: 'Update Ticket ID',
        id: 'update-ticket-id',
        order: 5,
        visibleTo: [DISPUTE_ESCALATION_MODE.FRESHDESK],
    },
    ADD_CORRESPONDENCE: {
        ctaLabel: 'Add Correspondence',
        id: 'add-correspondence',
        order: 6,
        visibleTo: [DISPUTE_ESCALATION_MODE.FEDERAL],
    },
    ATTACH_DOCUMENT: {
        ctaLabel: 'Attach Document',
        id: 'attach-document',
        order: 7,
        visibleTo: [DISPUTE_ESCALATION_MODE.FEDERAL],
    },
    ESCALATE_TO_FEDERAL: {
        ctaLabel: 'Escalate To Federal',
        id: 'escalate-to-federal',
        order: 8,
        visibleTo: [DISPUTE_ESCALATION_MODE.FEDERAL],
    },
};

const DROPDOWN_OPTIONS_ARRAY = Object.entries(DROPDOWN_OPTIONS_INFO)
    .map(([name, obj]) => ({ name, ...obj }))
    .sort((itemA, itemB) => itemA - itemB);

const OPTIONS_LENGTH = DROPDOWN_OPTIONS_ARRAY.length;
const DROPDOWN_HEIGHT = OPTIONS_LENGTH * 36; // 36px is the heigh of 1 item

const SearchResults = (props) => {
    const { ticketId, index } = props;

    const dispatch = useDispatch();

    // global state
    const disputesInfoForActor = useMemoizedSelector(selectDisputesInfoForActor, [ticketId]);
    const disputesInfo = useMemoizedSelector(selectDisputesInfo, [ticketId]);

    // local state
    const [selectedDisputeDetails, setSelectedDisputeDetails] = useState({});
    const [openedActionsCardId, setOpenedActionsCardId] = useState('');
    const [selectedDispute, setSelectedDispute] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorLabel, setErrorLabel] = useState('');

    // modals
    const [activeModalId, setActiveModalId] = useState('');

    let disputesData;
    let disputesPrev;
    let disputesNext;
    let filters;

    if (disputesInfo) {
        ({
            disputesData, prev: disputesPrev, next: disputesNext, filters,
        } = disputesInfo);
    }

    if (disputesInfoForActor) {
        ({
            disputesData, prev: disputesPrev, next: disputesNext, filters,
        } = disputesInfoForActor);
    }

    const getPaginatedDisputes = ({ prev, next }) => {
        dispatch(getDisputesInfoAction({
            ticketId,
            index,
            payload: filters,
            prev,
            next,
        }));
    };

    const onChangeCheckbox = (disputeInfo) => () => {
        // uncheck if user clicks the same item again
        if (selectedDispute && equals(selectedDispute.id, disputeInfo.id)) {
            setSelectedDispute(null);
        } else { // else, check the item
            setSelectedDispute(disputeInfo);
        }
    };

    const handleMarkAgainstTicket = async () => {
        try {
            const payload = {
                disputeId: selectedDispute.id,
                ticketId,
            };

            setLoading(true);
            setErrorLabel('');

            const response = await clientApiWrapper.post(DISPUTE_MARK_AGAINST_TICKET_ENDPOINT, { payload });
            const { message } = response;

            toastify(message, 'success');
        } catch (error) {
            showErrorToastMsg(error);
            const errorMsg = getErrMsg(error);
            setErrorLabel(errorMsg);
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => {
        setActiveModalId('');
    };

    const handleOptionClick = (data) => {
        const { optionInfo, itemInfo } = data;

        setActiveModalId(optionInfo.id);
        setSelectedDisputeDetails(itemInfo);
        setOpenedActionsCardId('');
    };

    if (!disputesData) return null;

    if (disputesData.length === 0) {
        return (
            <div className='disputes-src'>
                <div className='table-nsr table-nsr--mt0'>No search results found!</div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className='disputes-src'>
                <div className='table-sr table-sr--mt0'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th--checkbox'>-</div>
                        <div className='table-sr__th'>Internal Dispute ID</div>
                        <div className='table-sr__th'>Case No.</div>
                        <div className='table-sr__th'>Created At</div>
                        <div className='table-sr__th'>Updated At</div>
                        <div className='table-sr__th'>Freshdesk Ticket ID</div>
                        <div className='table-sr__th'>Dispute State</div>
                        <div className='table-sr__th'>Source</div>
                        <div className='table-sr__th'>Channel</div>
                        <div className='table-sr__th'>Escalation Mode</div>
                        <div className='table-sr__th table-sr__th--actions' />
                    </div>
                    <div className='table-sr__tlc'>
                        {disputesData.map((item, tableIndex) => (
                            <div className='table-sr__tlw pos__rel' key={item.id}>
                                <div className='table-sr__tl f05Wrapper'>
                                    <input
                                        type='checkbox'
                                        checked={equals(selectedDispute?.id, item.id)}
                                        onChange={onChangeCheckbox(item)}
                                    />
                                </div>
                                <div className='table-sr__tl'>{item.id}</div>
                                <div className='table-sr__tl'>{item?.escalation_info?.federal_bank_escalation_info?.dispute_case_number ?? ''}</div>
                                <div className='table-sr__tl'>{item.created_at}</div>
                                <div className='table-sr__tl'>{item.updated_at}</div>
                                <div className='table-sr__tl'>{item.ticket_id}</div>
                                <div className='table-sr__tl'>{item.dispute_state}</div>
                                <div className='table-sr__tl'>{item.source}</div>
                                <div className='table-sr__tl'>{item.channel}</div>
                                <div className='table-sr__tl'>{item.escalation_mode}</div>
                                <div className='table-sr__tl table-sr__tl--actions'>
                                    <DropdownWrapper
                                        visible={openedActionsCardId === item.id}
                                        onOutsideClickHandler={() => setOpenedActionsCardId('')}
                                    >
                                        <button
                                            type='button'
                                            className='disputes-ad-label'
                                            onClick={() => {
                                                setOpenedActionsCardId(item.id);
                                            }}
                                        >
                                            ...
                                        </button>
                                        <DropdownContent
                                            visible
                                            extStyles={{
                                                height: openedActionsCardId === item.id && DROPDOWN_HEIGHT,
                                            }}
                                            extClasses={{
                                                container: [
                                                    'disputes-ad-cc height-transition',
                                                ],
                                            }}
                                        >
                                            {DROPDOWN_OPTIONS_ARRAY.filter((option) => option.visibleTo.includes(item.escalation_mode))
                                                .map((optionInfo) => (
                                                    <button
                                                        key={optionInfo.id}
                                                        type='button'
                                                        className='disputes-ad-cc-wr__label'
                                                        onClick={() => {
                                                            handleOptionClick({
                                                                itemInfo: {
                                                                    ...item,
                                                                    tableIndex,
                                                                },
                                                                optionInfo,
                                                            });
                                                        }}
                                                    >
                                                        {optionInfo.ctaLabel}
                                                    </button>
                                                ))}
                                        </DropdownContent>
                                    </DropdownWrapper>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Pagination prev={disputesPrev} next={disputesNext} onClickHandler={getPaginatedDisputes} />
                <Button
                    v2
                    primary
                    extClasses='deposits-aecta'
                    disabled={!selectedDispute}
                    label='Mark against Ticket'
                    onClick={handleMarkAgainstTicket}
                />
            </div>
            {/* modals */}
            <ViewDetailsModal
                visible={activeModalId === DROPDOWN_OPTIONS_INFO.VIEW_DETAILS.id}
                toggleHandler={closeModal}
                data={selectedDisputeDetails}
                setData={setSelectedDisputeDetails}
                parentProps={props}
            />
            <ViewCorrespondenceModal
                visible={activeModalId === DROPDOWN_OPTIONS_INFO.VIEW_CORRESPONDENCE.id}
                toggleHandler={closeModal}
                data={selectedDisputeDetails}
                setData={setSelectedDisputeDetails}
                parentProps={props}
            />
            <ViewDocumentsModal
                visible={activeModalId === DROPDOWN_OPTIONS_INFO.VIEW_DOCUMENTS.id}
                toggleHandler={closeModal}
                data={selectedDisputeDetails}
                setData={setSelectedDisputeDetails}
                parentProps={props}
            />
            <UpdateDisputeStatusModal
                visible={activeModalId === DROPDOWN_OPTIONS_INFO.UPDATE_DISPUTE_STATUS.id}
                toggleHandler={closeModal}
                data={selectedDisputeDetails}
                setData={setSelectedDisputeDetails}
                parentProps={props}
            />
            <UpdateTicketIdModal
                visible={activeModalId === DROPDOWN_OPTIONS_INFO.UPDATE_TICKET_ID.id}
                toggleHandler={closeModal}
                data={selectedDisputeDetails}
                setData={setSelectedDisputeDetails}
                parentProps={props}
            />
            <AddCorrespondenceModal
                visible={activeModalId === DROPDOWN_OPTIONS_INFO.ADD_CORRESPONDENCE.id}
                toggleHandler={closeModal}
                data={selectedDisputeDetails}
                setData={setSelectedDisputeDetails}
                parentProps={props}
            />
            <AttachDocumentModal
                visible={activeModalId === DROPDOWN_OPTIONS_INFO.ATTACH_DOCUMENT.id}
                toggleHandler={closeModal}
                data={selectedDisputeDetails}
                setData={setSelectedDisputeDetails}
                parentProps={props}
            />
            <EscalateToFederalModal
                visible={activeModalId === DROPDOWN_OPTIONS_INFO.ESCALATE_TO_FEDERAL.id}
                toggleHandler={closeModal}
                data={selectedDisputeDetails}
                setData={setSelectedDisputeDetails}
                parentProps={props}
            />

            {errorLabel ? (
                <div className='error-label1 my-60'>{errorLabel}</div>
            ) : null}

            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(SearchResults);

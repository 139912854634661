import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button, Pagination } from '../../../components';

import '../style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content : {
        top: '10%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 1000,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        maxHeight: 700,
    }
};

const TxnsModal = ({ depositTxnList, toggleModal, getPaginatedDepositTxnList }) => (
    <Modal
        isOpen
        style={customStylesForModal}
        contentLabel='View Deposit Transactions Modal'
    >
        <div className='deposits-modal-hwr'>View Transactions</div>
        <div className='table-sr deposits-modal-sr'>
            <div className='table-sr__thw'>
                <div className='table-sr__th f15Wrapper'>From Inst. No</div>
                <div className='table-sr__th f15Wrapper'>To Inst. No</div>
                <div className='table-sr__th'>Txn Mode</div>
                <div className='table-sr__th'>Fi Txn Id</div>
                <div className='table-sr__th f15Wrapper'>UTR</div>
                <div className='table-sr__th'>Partner Bank</div>
                <div className='table-sr__th'>Status</div>
                <div className='table-sr__th'>Created At</div>
            </div>
            <div className='table-sr__tlc'>
                {
                    depositTxnList.data.map(item => (
                        <div className='table-sr__tlw deposits-modal-tlw' key={item.id}>
                            <div className='table-sr__tl f15Wrapper'>{item.pi.from.value}</div>
                            <div className='table-sr__tl f15Wrapper'>{item.pi.to.value}</div>
                            <div className='table-sr__tl'>{item.paymentProtocol}</div>
                            <div className='table-sr__tl'>{item.orderId}</div>
                            <div className='table-sr__tl f15Wrapper'>{item.utr}</div>
                            <div className='table-sr__tl'>{item.partnerBank}</div>
                            <div className='table-sr__tl'>{item.status}</div>
                            <div className='table-sr__tl'>{item.createdTs}</div>
                        </div>
                    ))
                }
            </div>
        </div>
        <Pagination prev={depositTxnList.prev} next={depositTxnList.next} onClickHandler={getPaginatedDepositTxnList} />
        <Button primary extClasses='deposits-modal-bwr' label='Close' onClick={() => toggleModal(false)} />
    </Modal>
);

export default memo(TxnsModal);

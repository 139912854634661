export const SEARCH_RESULT_TYPES = [
    {
        label: 'SOP',
        value: 'SEARCH_RESULT_TYPE_SOP',
    },
    {
        label: 'Script',
        value: 'SEARCH_RESULT_TYPE_SCRIPT',
    },
];

export const SEARCH_RESULT_DEFAULT = {
    label: 'All',
    value: 'SEARCH_RESULT_TYPE_UNSPECIFIED',
};

/* eslint-disable func-names */
/**
 * @file Constants for Transaction view V2
*/

import { DAY, WEEK, MONTH } from '../../components/d3/constants';

import { getInitialDateRange } from '../../utils';

// eslint-disable-next-line import/no-cycle
import { getFromDateBasedOnPeriod } from './utils';

// action & reducer constants
export const TRANSACTION_VIEW_V2_KEY = 'transactionViewV2';

export const GET_TRANSACTION_VIEW_V2 = `app/${TRANSACTION_VIEW_V2_KEY}/GET_TRANSACTION_VIEW`;
export const SET_TRANSACTION_VIEW_V2 = `app/${TRANSACTION_VIEW_V2_KEY}/SET_TRANSACTION_VIEW`;
export const SET_TRANSACTION_VIEW_V2_STREAM = `app/${TRANSACTION_VIEW_V2_KEY}/SET_TRANSACTION_VIEW_STREAM`;
export const SET_TRANSACTION_VIEW_V2_STREAM_COMPLETE = `app/${TRANSACTION_VIEW_V2_KEY}/SET_TRANSACTION_VIEW_STREAM_COMPLETE`;
export const ERR_TRANSACTION_VIEW_V2 = `app/${TRANSACTION_VIEW_V2_KEY}/ERR_TRANSACTION_VIEW`;
export const SET_TRANSACTION_VIEW_V2_PERIOD = `app/${TRANSACTION_VIEW_V2_KEY}/SET_TRANSACTION_VIEW_V2_PERIOD`;
export const FIRE_TRANSACTION_VIEW_RENDER_MATRIC = `app/${TRANSACTION_VIEW_V2_KEY}/FIRE_TRANSACTION_VIEW_RENDER_MATRIC`;
export const TRANSACTION_VIEW_SET_FILTER_FIELD = `app/${TRANSACTION_VIEW_V2_KEY}/TRANSACTION_VIEW_SET_FILTER_FIELD`;
export const RESET_TRANSACTION_VIEW = `app/${TRANSACTION_VIEW_V2_KEY}/RESET_TRANSACTION_VIEW`;
export const FIRE_TRANSACTION_MATRIC_EVENT = `app/${TRANSACTION_VIEW_V2_KEY}/FIRE_TRANSACTION_MATRIC_EVENT`;
export const REQUEST_AGGREGATED_DATA_POINTS = `app/${TRANSACTION_VIEW_V2_KEY}/REQUEST_AGGREGATED_DATA_POINTS`;
export const SET_AGGREGATED_DATA_POINTS = `app/${TRANSACTION_VIEW_V2_KEY}/SET_AGGREGATED_DATA_POINTS`;
export const GET_ANNOTATIONS = `app/${TRANSACTION_VIEW_V2_KEY}/GET_ANNOTATIONS`;
export const SET_ANNOTATIONS = `app/${TRANSACTION_VIEW_V2_KEY}/SET_ANNOTATIONS`;
export const CREATE_TRANSACTIONS_ANNOTATIONS = `app/${TRANSACTION_VIEW_V2_KEY}/CREATE_TRANSACTIONS_ANNOTATIONS`;
export const SET_TXN_ANNOTATION_MODAL_VISIBILITY = `app/${TRANSACTION_VIEW_V2_KEY}/SET_TXN_ANNOTATION_MODAL_VISIBILITY`;

export const CREDIT = 'CREDIT';
export const DEBIT = 'DEBIT';
export const AMOUNT = 'amount';
export const TRANSACTIONCOUNT = 'txnCount';
export const OTHERACTORCOUNT = 'otherActorCount';
export const SUCCESS = 'Success';
export const FAILED = 'Failed';
export const P2M = 'P2M';
export const M2P = 'M2P';

export const THREE_MONTHS = 'THREE_MONTHS';
export const LAST_30_DAYS = 'LAST_30_DAYS';
export const LAST_60_DAYS = 'LAST_60_DAYS';
export const ONE_YEAR = 'ONE_YEAR';

// period map object
export const periodMap = {
    [THREE_MONTHS]: {
        days: 90,
        startDate: 1,
    },
    [LAST_30_DAYS]: {
        days: 30,
    },
    [LAST_60_DAYS]: {
        days: 60,
    },
    [ONE_YEAR]: {
        days: 365,
    },
};

export const PeriodOptions = [
    {
        label: '3 Months',
        value: THREE_MONTHS,
        days: 90,
    },
    {
        label: 'Last 30 Days',
        value: LAST_30_DAYS,
        days: 30,
    },
    {
        label: 'Last 60 Days',
        value: LAST_60_DAYS,
        days: 60,
    },
    {
        label: '1 Year',
        value: ONE_YEAR,
        days: 365,
    },
    {
        label: 'Custom',
        value: null,
        days: null,
    },
];

// colors
export const COLORS = {
    lightGreen: '#b6d7a8',
    lightRed: '#f4cccc',
    green: '#00B899',
    red: '#f56060',
    blue: '#3C78D8',
    black: '#000',
    white: '#fff',
};

export const AggregateOptions = [
    {
        label: 'Day',
        value: DAY,
    },
    {
        label: 'Week',
        value: WEEK,
    },
    {
        label: 'Month',
        value: MONTH,
    },
];

export const YValueOptions = [
    {
        label: 'Amount',
        value: AMOUNT,
    },
    {
        label: 'Transaction Count',
        value: TRANSACTIONCOUNT,
    },
];

export const cdTypeOptions = [
    {
        label: 'Debit',
        value: DEBIT,
    },
    {
        label: 'Credit',
        value: CREDIT,
    },
];

export const defaultAggergation = AggregateOptions.find((item) => item.value === MONTH);

export const defaultYValue = YValueOptions.find((item) => item.value === AMOUNT);

export const defaultCdType = cdTypeOptions.find((item) => item.value === DEBIT);

export const DATE_RANGE_KEY = 'transaction-view-v2-date-range';

export const defaultPeriod = PeriodOptions.find((item) => item.value === THREE_MONTHS);

const defaultFromDate = getFromDateBasedOnPeriod(defaultPeriod.value);

export const INITIAL_DATE_RANGE = getInitialDateRange(null, true, {
    defaultFromDate,
});

// form constants
export const FROM_DATE_INFO = {
    label: 'From Date*',
    labelId: 'transaction-view-v2-from-date',
};

export const TO_DATE_INFO = {
    label: 'To Date*',
    labelId: 'transaction-view-v2-to-date',
};

export const dateInfo = {
    fromDateInfo: FROM_DATE_INFO,
    toDateInfo: TO_DATE_INFO,
    initialDateRange: INITIAL_DATE_RANGE,
};

export const BARCHART_DIMENSIONS = {
    width: 1100,
    height: 400,
    marginTop: 20,
    marginRight: 0,
    marginBottom: 30,
    marginLeft: 40,
    barPadding: 5,
};

export const customFilterPeriod = {
    label: 'Custom',
    value: null,
    days: null,
};

export const TRANSACTIONS_ALLOWED_ANNOTATION = {
    id: 'list-transaction',
    type: 'UI_ELEMENT_TYPE_SECTION',
};

export const ANNOTATION_MAP = {
    TYPE: {
        LIST_TRANSACTION: 'LIST_TRANSACTION',
        TRANSACTION_PAGE_BOTTOM: 'TRANSACTION_PAGE_BOTTOM',
    },
    PAYLOAD_MAP: {
        LIST_TRANSACTION: {
            id: 'list-transaction',
            type: 'UI_ELEMENT_TYPE_SECTION',
        },
        TRANSACTION_PAGE_BOTTOM: {
            id: 'transactions-page-bottom',
            type: 'UI_ELEMENT_TYPE_SECTION',
        },
    },
};

export const TRANSACTION_VIEW_EVENT_THRESHOLD = {
    START: 1.4,
    END: 7,
    VIEW_TIME: 1000,
    START_INIT: -1,
    END_INIT: -1,
    TOP_POSITION: 0,
};

const eventInitValue = {
    start: TRANSACTION_VIEW_EVENT_THRESHOLD.START_INIT,
    end: TRANSACTION_VIEW_EVENT_THRESHOLD.END_INIT,
    delay: 0,
};

export function TransactionViewMetricsEvent() {
    this.events = {
        CHART_CREDIT_VS_DEBIT: {
            id: 'chart-credit-vs-debit',
            name: 'CHART_CREDIT_VS_DEBIT',
            ...eventInitValue,
        },
        CHART_CATEGORY: {
            id: 'chart-categories',
            name: 'CHART_CATEGORY',
            ...eventInitValue,
        },
        CHART_SENDERS: {
            id: 'chart-senders',
            name: 'CHART_SENDERS',
            ...eventInitValue,
        },
    };
}

TransactionViewMetricsEvent.prototype.resetEvent = function (eventName) {
    this.events[eventName] = {
        ...this.events[eventName],
        delay: 0,
        ...eventInitValue,
    };
};

TransactionViewMetricsEvent.prototype.setEvent = function (eventName, event) {
    this.events[eventName] = {
        ...event,
    };
};

export const staticColorMapping = {
    TEAL_GREEN: 'CREDIT',
    ORANGE: 'DEBIT',
};

/**
 * @file RecentActivity/constants.js - Contains saga used for recent activity
 */
import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import {
    GET_RECENT_ACTIVITY_DETAILS_ENDPOINT,
    GET_RECENT_ACTIVITY_ENDPOINT,
} from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_RECENT_ACTIVITY, SET_RECENT_ACTIVITY, ERR_RECENT_ACTIVITY, SET_RECENT_ACTIVITY_DETAILS,
    ERR_RECENT_ACTIVITY_DETAILS, GET_RECENT_ACTIVITY_DETAILS,
} from './constants';

function* getRecentActivities(action) {
    const {
        ticketId, userId, index, fromDate, toDate, selectedAreas, prevToken, nextToken,
    } = action.data;
    const queryString = getQueryStringFromObject({
        ticketId, userId, fromDate, toDate, areas: selectedAreas, prevToken, nextToken,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_RECENT_ACTIVITY_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_RECENT_ACTIVITY, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_RECENT_ACTIVITY, data: { ticketId, err: response } });
        } else {
            yield put({ type: ERR_RECENT_ACTIVITY, data: { ticketId, err: response, authFail: true } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_RECENT_ACTIVITY, data: { ticketId, err: e.message, authFail: false } });
    }
}

function* getRecentActivityDetails(action) {
    const {
        ticketId, userId, index, meta,
    } = action.data;
    const queryString = getQueryStringFromObject({
        ticketId, userId, meta,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_RECENT_ACTIVITY_DETAILS_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_RECENT_ACTIVITY_DETAILS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_RECENT_ACTIVITY_DETAILS, data: { ticketId, err: response } });
        } else {
            yield put({ type: ERR_RECENT_ACTIVITY_DETAILS, data: { ticketId, err: response, authFail: true } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_RECENT_ACTIVITY_DETAILS, data: { ticketId, err: e.message, authFail: false } });
    }
}

export default function* recentActivitySaga() {
    yield all(
        [
            yield takeLatest(GET_RECENT_ACTIVITY, getRecentActivities),
            yield takeLatest(GET_RECENT_ACTIVITY_DETAILS, getRecentActivityDetails),
        ],
    );
}

/*
 *
 * AdminActions constants
 *
 */

export const GET_ACTION_LIST = 'app/AdminActions/GET_ACTION_LIST';
export const SET_ACTION_LIST = 'app/AdminActions/SET_ACTION_LIST';
export const ERR_ACTION_LIST = 'app/AdminActions/ERR_ACTION_LIST';

export const GET_PARAMETER_LIST = 'app/AdminActions/GET_PARAMETER_LIST';
export const SET_PARAMETER_LIST = 'app/AdminActions/SET_PARAMETER_LIST';
export const SET_PARAMETER_VALUE = 'app/AdminActions/SET_PARAMETER_VALUE';
export const ERR_PARAMETER_LIST = 'app/AdminActions/ERR_PARAMETER_LIST';

export const EXECUTE_ACTION = 'app/AdminActions/EXECUTE_ACTION';
export const SUCCESS_EXECUTE_ACTION = 'app/AdminActions/SUCCESS_EXECUTE_ACTION';
export const ERR_EXECUTE_ACTION = 'app/AdminActions/ERR_EXECUTE_ACTION';


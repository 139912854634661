/**
 * Wealth P2P - Component to show the Wealth P2P related investor info & their investment list.
 * It is getting used for Ticket Wealth flow
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import { Button, KVPairInfo, Loader, Table } from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import InvestmentTxnFailureInfoModal from './Modals/InvestmentTxnFailureInfo';

import { WEALTH_P2P_KEY, INVESTMENT_LIST_LABEL_DATA, INVESTOR_INFO_LABEL_DATA } from './constants';
import { getInvestorInfo, getInvestmentList } from './actions';
import { renderInvestorInfo, renderInvestmentTxnFailureInfo } from './utils';
import { makeSelectInvestorInfo, makeSelectInvestmentList } from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const WealthP2P = (props) => {
    const dispatch = useDispatch();

    // reducer state variables
    const investorInfo = useSelector(makeSelectInvestorInfo, shallowEqual);
    const investmentList = useSelector(makeSelectInvestmentList, shallowEqual);

    // local state variables
    const [showInvestmentTxnFailureInfoModal, setInvestmentTxnFailureInfoModal] = useState(false);
    const [selectedInvestmentTxnFailureInfo, setSelectedInvestmentTxnFailureInfo] = useState({});

    const { ticketId, userId, index } = props;

    let loadingInvestorInfo;
    let investorData;
    let hasInvestorData;
    let errInLoadingInvestorInfo;
    let loadingInvestmentList;
    let investmentListData;
    let hasInvestmentListData;
    let investmentListPrevToken;
    let investmentListNextToken;
    let errInLoadingInvestmentList;
    let schemeLevelDetails;

    if (investorInfo[ticketId]) {
        ({
            loading: loadingInvestorInfo, data: investorData, hasData: hasInvestorData, err: errInLoadingInvestorInfo,
        } = investorInfo[ticketId]);

        schemeLevelDetails = investorData?.schemeLevelDetails;
    }

    if (investmentList[ticketId]) {
        ({
            // eslint-disable-next-line no-unused-vars
            loading: loadingInvestmentList, data: investmentListData, hasData: hasInvestmentListData, prev: investmentListPrevToken,
            next: investmentListNextToken, err: errInLoadingInvestmentList,
        } = investmentList[ticketId]);
    }

    useEffect(() => {
        if (!hasInvestorData) {
            dispatch(getInvestorInfo({ ticketId, userId, index }));
        }
    }, []);

    const getPaginatedInvestmentList = ({ prev, next }) => {
        dispatch(getInvestmentList({
            ticketId,
            userId,
            index,
            prev,
            next,
        }));
    };

    const investmentTxnFailureInfoModalToggleHandler = (data) => () => {
        setSelectedInvestmentTxnFailureInfo(data);
        setInvestmentTxnFailureInfoModal(!showInvestmentTxnFailureInfoModal);
    };

    const renderActions = (data) => (
        <div className='frfscWrapper'>
            <Button
                link
                label='View Details'
                onClick={investmentTxnFailureInfoModalToggleHandler(data.failureInfo)}
            />
        </div>
    );

    return (
        <React.Fragment>
            <div className='wp2p'>
                {
                    errInLoadingInvestorInfo ? (
                        <div className='wp2p-errlb'>{errInLoadingInvestorInfo}</div>
                    ) : null
                }
                {
                    hasInvestorData ? (
                        <KVPairInfo data={renderInvestorInfo(investorData)} />
                    ) : null
                }
                <div className='mb-60'>
                    <Table
                        labelData={INVESTOR_INFO_LABEL_DATA}
                        contentData={schemeLevelDetails}
                    />
                </div>
                <Table
                    labelData={INVESTMENT_LIST_LABEL_DATA}
                    contentData={investmentListData}
                    renderActionableColumn={renderActions}
                    prevToken={investmentListPrevToken}
                    nextToken={investmentListNextToken}
                    paginatedClickHandler={getPaginatedInvestmentList}
                    errLabel={errInLoadingInvestmentList}
                />
            </div>
            <InvestmentTxnFailureInfoModal
                visible={showInvestmentTxnFailureInfoModal}
                data={renderInvestmentTxnFailureInfo(selectedInvestmentTxnFailureInfo)}
                toggleHandler={investmentTxnFailureInfoModalToggleHandler({})}
            />
            <Loader visible={loadingInvestorInfo || loadingInvestmentList} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: WEALTH_P2P_KEY, reducer });
const withSaga = injectSaga({ key: WEALTH_P2P_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(WealthP2P);

/**
 * @file Eligible Screen: Component to show the Credit Card Eligible Screen.
 */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { hasAccessToThePage } from '../../../../utils/helpers';
import { useMemoizedSelector } from '../../../../hooks';
import { Button, Toggle } from '../../../../components';

import { getVKycInfo } from '../../../KycOnboarding/actions';
import { setCustomerAuth } from '../../../Tickets/actions';
import { SCREEN } from '../../../Tickets/constants';

import TableDetails from '../../TableDetails';
import { selectCreditCardOnboardingDetailsV2, selectShowOnBoardingDetailsV2 } from '../../selectors';
import {
    getCreditCardCurrentStageDetails,
    getCreditCardNextStageDetails,
    getOnboardingDetailsV2Action,
    getSelectedCreditCardDetails, setShowOnboardingDetailsV2Action, toggleScreenAction,
} from '../../actions';
import {
    SCREEN_NAME_CREDIT_CARD_CURRENT_STAGE_DETAILS, SCREEN_NAME_CREDIT_CARD_DETAILS,
    SCREEN_NAME_CREDIT_CARD_KYC_DETAILS, SCREEN_NAME_CREDIT_CARD_NEXT_STAGE_DETAILS,
} from '../../constants';

import './style.scss';

const EligibleScreenV2 = (props) => {
    const {
        eligibilityDetails, offerDetails, activeTicket, ticketId, userId, index,
    } = props;

    const dispatch = useDispatch();

    const { pathname } = useLocation();

    const showOnboardingDetails = useMemoizedSelector(selectShowOnBoardingDetailsV2, [ticketId]);
    const onboardingDetails = useMemoizedSelector(selectCreditCardOnboardingDetailsV2, [ticketId]);

    useEffect(() => {
        if (showOnboardingDetails) {
            dispatch(getOnboardingDetailsV2Action({ ticketId, userId }));
        }
    }, [dispatch, showOnboardingDetails, ticketId, userId]);

    const handleOnBoardToggle = () => {
        if (hasAccessToThePage(activeTicket, SCREEN.MODERATELY_SENSITIVE_SCREEN)) {
            dispatch(setShowOnboardingDetailsV2Action({ ticketId, show: !showOnboardingDetails }));
        } else {
            dispatch(setCustomerAuth({
                index,
                isActive: true,
                sensitivityLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                authPathname: pathname,
            }));
        }
    };

    const handleReviewClick = (item) => {
        const { screen_name: screenName } = item;
        if (screenName === SCREEN_NAME_CREDIT_CARD_KYC_DETAILS) {
            dispatch(getVKycInfo({ ticketId, userId, index }));
            dispatch(toggleScreenAction({ ticketId, isCreditCardKycScreen: true }));
        } else if (screenName === SCREEN_NAME_CREDIT_CARD_DETAILS) {
            dispatch(getSelectedCreditCardDetails({ ticketId, isSelectedCreditCardScreen: true }));
        } else if (screenName === SCREEN_NAME_CREDIT_CARD_CURRENT_STAGE_DETAILS) {
            dispatch(getCreditCardCurrentStageDetails({ ticketId, isCurrentStageScreen: true }));
        } else if (screenName === SCREEN_NAME_CREDIT_CARD_NEXT_STAGE_DETAILS) {
            dispatch(getCreditCardNextStageDetails({ ticketId, isNextStageScreen: true }));
        }
    };

    const renderActionableColumn = (item, idx) => {
        const { label, deeplink, is_disabled: disabled } = onboardingDetails.actions[idx];

        if (!deeplink) {
            return null;
        }

        return (
            <div className='frfscWrapper' key={idx}>
                <Button
                    secondary
                    label={label}
                    disabled={disabled}
                    onClick={() => handleReviewClick(deeplink, idx)}
                />
            </div>
        );
    };

    const isEligible = !!(offerDetails?.rowData?.length);

    return (
        <React.Fragment>
            {/* eligibility table */}
            <TableDetails data={eligibilityDetails} heading={eligibilityDetails?.tableName || 'Credit Card Eligibility'} />

            {isEligible ? (
                <React.Fragment>
                    <TableDetails data={offerDetails} heading={offerDetails?.tableName || 'Offers'} />
                    <div className='cc-es2-heading-container mt-30'>
                        <div className='cc-es2-heading'>{showOnboardingDetails ? 'Hide' : 'Show'} Onboarding Details</div>
                        <Toggle
                            value={showOnboardingDetails}
                            onChange={handleOnBoardToggle}
                        />
                    </div>
                    {
                        showOnboardingDetails
                            ? (
                                <TableDetails
                                    hideActionHeader
                                    data={onboardingDetails}
                                    renderActionableColumn={renderActionableColumn}
                                    lsrLabel='Onboarding not started yet!'
                                />
                            )
                            : null
                    }
                </React.Fragment>
            ) : (
                <div className='label1 mt-15'>
                    The user is not eligible at the moment for the credit card. Please check the knowledge portal
                </div>
            )}
        </React.Fragment>
    );
};

export default EligibleScreenV2;

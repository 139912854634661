// key to be used for reducers & selectors for wealth mutual funds
export const WEALTH_MUTUAL_FUNDS_KEY = 'wealthMutualFunds';

// mutual fund amc list
export const GET_MUTUAL_FUND_AMC = `app/${WEALTH_MUTUAL_FUNDS_KEY}/GET_MUTUAL_FUND_AMC`;
export const SET_MUTUAL_FUND_AMC = `app/${WEALTH_MUTUAL_FUNDS_KEY}/SET_MUTUAL_FUND_AMC`;
export const ERR_MUTUAL_FUND_AMC = `app/${WEALTH_MUTUAL_FUNDS_KEY}/ERR_MUTUAL_FUND_AMC`;

// mutual fund transaction type list
export const GET_MUTUAL_FUND_TXN_TYPE = `app/${WEALTH_MUTUAL_FUNDS_KEY}/GET_MUTUAL_FUND_TXN_TYPE`;
export const SET_MUTUAL_FUND_TXN_TYPE = `app/${WEALTH_MUTUAL_FUNDS_KEY}/SET_MUTUAL_FUND_TXN_TYPE`;
export const ERR_MUTUAL_FUND_TXN_TYPE = `app/${WEALTH_MUTUAL_FUNDS_KEY}/ERR_MUTUAL_FUND_TXN_TYPE`;

// mutual fund order filter status list
export const GET_MUTUAL_FUND_ORDER_FILTER_STATUS = `app/${WEALTH_MUTUAL_FUNDS_KEY}/GET_MUTUAL_FUND_ORDER_FILTER_STATUS`;
export const SET_MUTUAL_FUND_ORDER_FILTER_STATUS = `app/${WEALTH_MUTUAL_FUNDS_KEY}/SET_MUTUAL_FUND_ORDER_FILTER_STATUS`;
export const ERR_MUTUAL_FUND_ORDER_FILTER_STATUS = `app/${WEALTH_MUTUAL_FUNDS_KEY}/ERR_MUTUAL_FUND_ORDER_FILTER_STATUS`;

// mutual funds list
export const GET_MUTUAL_FUNDS = `app/${WEALTH_MUTUAL_FUNDS_KEY}/GET_MUTUAL_FUNDS`;
export const SET_MUTUAL_FUNDS = `app/${WEALTH_MUTUAL_FUNDS_KEY}/SET_MUTUAL_FUNDS`;
export const ERR_MUTUAL_FUNDS = `app/${WEALTH_MUTUAL_FUNDS_KEY}/ERR_MUTUAL_FUNDS`;

// mutual fund orders list
export const GET_MUTUAL_FUND_ORDERS = `app/${WEALTH_MUTUAL_FUNDS_KEY}/GET_MUTUAL_FUND_ORDERS`;
export const SET_MUTUAL_FUND_ORDERS = `app/${WEALTH_MUTUAL_FUNDS_KEY}/SET_MUTUAL_FUND_ORDERS`;
export const ERR_MUTUAL_FUND_ORDERS = `app/${WEALTH_MUTUAL_FUNDS_KEY}/ERR_MUTUAL_FUND_ORDERS`;

// mutual fund order details
export const GET_MUTUAL_FUND_ORDER_INFO = `app/${WEALTH_MUTUAL_FUNDS_KEY}/GET_MUTUAL_FUND_ORDER_INFO`;
export const SET_MUTUAL_FUND_ORDER_INFO = `app/${WEALTH_MUTUAL_FUNDS_KEY}/SET_MUTUAL_FUND_ORDER_INFO`;
export const ERR_MUTUAL_FUND_ORDER_INFO = `app/${WEALTH_MUTUAL_FUNDS_KEY}/ERR_MUTUAL_FUND_ORDER_INFO`;

// search parameters for across mutual funds view
export const SET_SEARCH_PARAMS = `app/${WEALTH_MUTUAL_FUNDS_KEY}/SET_SEARCH_PARAMS`;
export const RESET_SEARCH_PARAMS = `app/${WEALTH_MUTUAL_FUNDS_KEY}/RESET_SEARCH_PARAMS`;

export const SEARCH_PARAMS_KEYS = {
    AMC: 'amc',
    TXN_TYPE: 'txnType',
    ORDER_FILTER_STATUS: 'orderFilterStatus',
};

export const MUTUAL_FUND_ORDER_INFO_LABEL_DATA = [
    {
        id: 1,
        label: 'Timestamp',
        key: 'ts',
    },
    {
        id: 2,
        label: 'Timeline Step',
        key: 'timelineStep',
    },
    {
        id: 3,
        label: 'Step Status',
        key: 'stepStatus',
    },
    {
        id: 4,
        label: 'Internal Status',
        key: 'internalStatus',
    },
    {
        id: 5,
        label: 'Failure Reason',
        key: 'failureReason',
    },
];

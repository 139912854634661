/**
 *
 * UserCallback
 *
 */

import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, FormWithSingleInput, Loader } from '../../../components';
import { validators } from '../../../utils';

import TicketAndUserInfo from '../TicketAndUserInfo';
import { getInitialAuthFactor, callbackUser, setCallbackUserFlow } from '../actions';

import './style.scss';

const UserCallback = props => {
    const dispatch = useDispatch();
    const [enableCallBtn, setCallBtn] = useState(false);
    const [mobileNo, setMobileNo] = useState('');
    const [errLabel, setErrLabel] = useState('');
    const inputRef = useRef(null);

    const { index, ticket, ticketAndUserInfo, toggleCloseTicketModal } = props;
    const { ticketId, callbackInfo, loading, shouldFetchAuthInfoOnCallbackFlow,
        basicInfo: { isUserExistInSystem = false }, } = ticket;

    let primaryBtnLabel = enableCallBtn ? 'Initiate another call' : 'Initiate Call';
    let isPrimaryBtnDisabled = false;

    if (!enableCallBtn && callbackInfo.success) {
        primaryBtnLabel = 'Call Initiated';
        isPrimaryBtnDisabled = true;
    }

    useEffect(() => {
        // auto focus for manual dial option
        if (!isUserExistInSystem) {
            inputRef && inputRef.current.focus();
        }
    }, []);

    const setCallbackFlow = () => {
        if (shouldFetchAuthInfoOnCallbackFlow) {
            dispatch(getInitialAuthFactor({ ticketId, index }));
            setTimeout(() => {
                dispatch(setCallbackUserFlow({ flag: false, index }));
            }, 400);
        } else {
            dispatch(setCallbackUserFlow({ flag: false, index }));
        }
    };

    const dialPhoneNumber = () => {
        if (!validators.isInputtedMobileNumberValid(mobileNo)) {
            setErrLabel('Please enter a valid mobile number');
            return;
        }

        setErrLabel('');
        dispatch(callbackUser({ ticketId, index, phoneNumber: mobileNo }));
    };

    const onInputChange = (e) => {
        const charCode = e.which || e.keyCode;
        const value = e.target.value;

        // do not allow to enter any other value than digits
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
            return;
        }

        // do not allow to enter first digit as 0,1,2,3,4,5
        if (mobileNo.length === 0) {
            if ([48, 49, 50, 51, 52, 53].includes(charCode)) {
                e.preventDefault();
                return;
            }
        }

        // do not allow copy/paste of more than 10 digits
        if (value && value.length > 10) {
            e.preventDefault();
            return;
        }

        // do not allow copy/paste of invalid mobile number
        if (['0', '1', '2', '3', '4', '5'].includes(value && value[0])) {
            e.preventDefault();
            return;
        }

        setMobileNo(value);
    };

    return (
        <React.Fragment>
            <TicketAndUserInfo {...ticketAndUserInfo} />
            <div className='user-cb-container'>
                <div className='user-cb-heading'>
                    As per status, we need to do a call back to
                    the user to update them about their issue
                </div>
                <div className='user-cb-pcta'>
                    <div>
                        {
                            (callbackInfo.success || isUserExistInSystem) ? (
                                <Button
                                    primary={!callbackInfo.success}
                                    secondary={callbackInfo.success}
                                    label={primaryBtnLabel}
                                    disabled={isPrimaryBtnDisabled}
                                    onClick={() => {
                                        dispatch(callbackUser({ ticketId, index, phoneNumber: mobileNo }));
                                        setCallBtn(false);
                                    }}
                                />
                            ) : (
                                <FormWithSingleInput
                                    label='Enter mobile number'
                                    labelId='mobile-no'
                                    input={{
                                        value: mobileNo,
                                        onChange: onInputChange,
                                        ref: inputRef,
                                        maxLength: 10,
                                        type: 'number',
                                        onKeyDown: onInputChange,
                                    }}
                                    cta={{
                                        primaryLabel: 'Dial Number',
                                        onPrimaryClick: dialPhoneNumber,
                                    }}
                                    errLabel={errLabel}
                                    extClasses={{
                                        label: 'calluser-lb',
                                        errLabel: 'calluser-errlb',
                                    }}
                                />
                            )
                        }
                        {
                            callbackInfo.success ? (
                                <div
                                    className='link user-cb-link'
                                    onClick={() => setCallBtn(true)}
                                >
                                    User did not respond?
                                </div>
                            ) : null
                        }
                    </div>
                    {
                        callbackInfo.success ? (
                            <Button
                                primary
                                label='Call Completed'
                                onClick={toggleCloseTicketModal(index, true)}
                            />
                        ) : null
                    }
                </div>
                <div className='user-cb-subheading'>
                    User themselves has called back and
                    information needs to be accessed?
                </div>
                <Button
                    extClasses='user-cb-scta'
                    secondary
                    label='Go to authorisation flow'
                    onClick={setCallbackFlow}
                />
            </div>
            <Loader visible={loading.callbackUser} />
        </React.Fragment>
    );
};

export default memo(UserCallback);

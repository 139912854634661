/**
 *
 * MutualFundsView - Component to allow search for the mutual funds by the current user
 *
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, DropdownContent, DropdownWrapper, ExpandableCard, FormWithSingleInput, Loader,
} from '../../../components';
import { dateUtils, getDropdownHeight } from '../../../utils';

import {
    setSearchParams, resetSearchParams, getMFAmcList, getMutualFunds,
} from '../actions';
import {
    makeSelectSearchParameters, makeSelectMFAmcList, makeSelectMutualFunds,
} from '../selectors';
import { SEARCH_PARAMS_KEYS } from '../constants';

import '../style.scss';

import MutualFundsList from './MutualFundsList';

const MutualFundsView = (props) => {
    const dispatch = useDispatch();

    const searchParameters = useSelector(makeSelectSearchParameters(), shallowEqual);
    const mfAmcList = useSelector(makeSelectMFAmcList(), shallowEqual);
    const mutualFundsList = useSelector(makeSelectMutualFunds(), shallowEqual);

    const [isSearchCardExpanded, setSearchCardExpanded] = useState(false);
    const [showAmcDD, setAmcDD] = useState(false);
    const [errLabel, setErrLabel] = useState('');

    const { fromDate: fromInitialDate, toDate: toInitialDate } = dateUtils.getInitialDateRange('01-01-2021', true); // 01 Jan 2022

    const { ticketId, userId, index } = props;

    let fromDate;
    let toDate;
    let amc;
    let isSubmitted;

    // extract the search parameters for mutual funds view
    if (searchParameters[ticketId] && searchParameters[ticketId].mutualFundsView) {
        ({
            fromDate, toDate, amc,
        } = searchParameters[ticketId].mutualFundsView);
    }

    /*
    extract the search parameters for mutual fund orders view
    this is required to show the mutual fund orders list on clicking 'View Details'
    */
    if (searchParameters[ticketId] && searchParameters[ticketId].mutualFundOrdersView) {
        ({ isSubmitted } = searchParameters[ticketId].mutualFundOrdersView);
    }

    useEffect(() => {
        if (!mfAmcList.length) {
            dispatch(getMFAmcList());
        }
    }, [mfAmcList.length]);

    /**
     * initialise from and to date with the date utils value
     * this won't get called again & again
     */
    useEffect(() => {
        if (!fromDate && !toDate) {
            dispatch(setSearchParams({
                ticketId,
                mutualFundsView: true,
                fromDate: fromInitialDate,
                toDate: toInitialDate, 
            }));
        }
    }, [fromDate, toDate]);

    let loadingMutualFunds;
    let mutualFundsData;
    let mutualFundsDataPrev;
    let mutualFundsDataNext;

    if (mutualFundsList[ticketId]) {
        ({
            loading: loadingMutualFunds, data: mutualFundsData, prev: mutualFundsDataPrev,
            next: mutualFundsDataNext,
        } = mutualFundsList[ticketId]);
    }

    // set mutual fund id, name & isSubmitted flag on clicking 'View Details'
    const handleViewDetails = (id, name) => {
        // set mutual fund id in search parameters
        dispatch(setSearchParams({
            ticketId,
            mutualFundOrdersView: true,
            key: 'mfId',
            value: id,
        }));

        // set mutual fund name in search parameters
        dispatch(setSearchParams({
            ticketId,
            mutualFundOrdersView: true,
            key: 'mfName',
            value: name,
        }));

        // set submitted flag true in search parameters
        dispatch(setSearchParams({
            ticketId,
            mutualFundOrdersView: true,
            key: 'isSubmitted',
            value: true,
        }));
    };

    const handleDateChange = key => value => {
        const data = {
            key,
            value,
            fromDate,
            toDate,
        };
        const { fromDate: modifiedFromDate, toDate: modifiedToDate } = dateUtils.getModifiedDate(data);;

        dispatch(setSearchParams({
            ticketId,
            mutualFundsView: true,
            fromDate: modifiedFromDate,
            toDate: modifiedToDate, 
        }));
    };

    const renderDateBlock = (key) => {
        let label;
        let labelId;
        let value;
        let maxDate;
        let minDate;

        switch (key) {
            case 'fromDate':
                label = 'From Date';
                labelId = 'from-date';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = fromDate);
                break;

            case 'toDate':
                label = 'To Date';
                labelId = 'to-date';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = toDate);
                break;

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'mfs-fc',
                    label: 'mfs-dd__lb',
                    inputField: 'mfs-dd__ip',
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: handleDateChange(key),
                    maxDate,
                    minDate,
                }}
                hideErrLabel
            />
        );
    };

    const makePayloadAndCallApi = (params = {}) => {
        const { prev, next } = params;

        let payload = {
            from: fromDate.value || '',
            to: toDate.value || '',
            amc,
        };

        dispatch(getMutualFunds({
            ticketId,
            userId,
            index,
            ...payload,
            prev,
            next,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // fromData and toDate are not required value
        /*
        if (!fromDate.value || !toDate.value) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }
        */

        makePayloadAndCallApi();

        // setErrLabel('');
        setSearchCardExpanded(false);
    };

    const getPaginatedList = ({ prev, next }) => {
        makePayloadAndCallApi({ prev, next });
    };

    /**
     * set search parameters for given key, value pair
     * and toggle the selected dropdown incase non-empty value is passed
     */
    const handleOptionsChange = (key, value) => {
        dispatch(setSearchParams({
            ticketId,
            mutualFundsView: true,
            key,
            value,
        }));

        if (value) {
            toggleDD(key)();
        }
    };

    const toggleDD = (id) => () => {
        switch (id) {
            case SEARCH_PARAMS_KEYS.AMC: {
                setAmcDD(!showAmcDD);

                break;
            }

            default:
        }
    };

    const renderDD = (id) => {
        let label;
        let value;
        let options;
        let showDD;
        let mandatoryField = false;

        switch (id) {
            case SEARCH_PARAMS_KEYS.AMC: {
                label = 'AMC Name';
                value = amc;
                options = mfAmcList;
                showDD = showAmcDD;
                mandatoryField = false;

                break;
            }

            default:
                label = '';
                options = [];
        }

        const { length: optionsLength } = options;

        if (optionsLength === 0) {
            return null;
        }

        const optionListDDHeight = getDropdownHeight(optionsLength);

        return (
            <div className='frcWrapper'>
                <div className='mfs-dd__lb'>{label}</div>
                <DropdownWrapper
                    visible={showDD}
                    onOutsideClickHandler={toggleDD(id)}
                    extClasses={{
                        container: 'custom-dd mfs-dd',
                    }}
                >
                    <div className='mfs-dd__vl' onClick={toggleDD(id)}>
                        {value ? value.split('_').join(' ').toLowerCase() : 'Choose...'}
                        { // cross icon to clear selected option
                            value && !mandatoryField ? (
                                <div
                                    className='mfs-dd__clear'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOptionsChange(id, '')
                                    }}
                                >
                                    x
                                </div>
                            ) : null
                        }
                    </div>
                    <DropdownContent
                        visible
                        extClasses = {{
                            container: ['custom-dd__cc mfs-dd__cc'],
                        }}
                        extStyles = {{
                            height: showDD ? optionListDDHeight : 0,
                            overflow: showDD ? 'auto' : 'hidden',
                        }}
                    >
                        {
                            options.map(item => (
                                <div
                                    key={item}
                                    className='custom-dd__cl'
                                    onClick={() => handleOptionsChange(id, item)}
                                >
                                    {item}
                                </div>
                            ))
                        }
                    </DropdownContent>
                </DropdownWrapper>
            </div>
        );
    };

    /*
    do not show the mutual fund search parameters & mutual funds list
    when any mutual fund is selected by clicking on 'View Details' or search by order id
    */
    if (isSubmitted) {
        return null;
    }

    return (
        <React.Fragment>
            <div className='mfs-cr'>
                <ExpandableCard
                    extClasses='mfs-ec'
                    heading='View user investment details - Search by other parameters'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='mfs-sc'>
                        <div className='frwpWrapper mfs-sc__gap'>
                            {isSearchCardExpanded && renderDateBlock('fromDate')}
                            {isSearchCardExpanded && renderDateBlock('toDate')}
                            {renderDD(SEARCH_PARAMS_KEYS.AMC)}
                        </div>
                        {/* {errLabel ? <div className='err-label mfs-el'>{errLabel}</div> : null} */}
                        <div className='frcWrapper mfs-sc__3r'>
                            <Button
                                extClasses='mfs-sc__cta'
                                primary
                                label='Search'
                                onClick={handleSubmit}
                            />
                            <div
                                className='link mfs-sc__clear'
                                onClick={() => {
                                    dispatch(resetSearchParams({
                                        ticketId,
                                        mutualFundsView: true,
                                        fromDate: fromInitialDate,
                                        toDate: toInitialDate,
                                    }));
                                }}
                            >
                                Clear All Selection
                            </div>
                        </div>
                    </div>
                </ExpandableCard>
                <MutualFundsList
                    data={mutualFundsData}
                    prev={mutualFundsDataPrev}
                    next={mutualFundsDataNext}
                    getPaginatedData={getPaginatedList}
                    handleViewDetails={handleViewDetails}
                />
            </div>
            <Loader visible={loadingMutualFunds} />
        </React.Fragment>
    );
};

export default MutualFundsView;

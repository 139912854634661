/*
 *
 * CustomerDetails Saga
 *
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { RISK_OPS_ROOT } from '../../api/routes';

import {
    GET_CUSTOMER_DETAILS, SET_CUSTOMER_DETAILS, ERR_CUSTOMER_DETAILS,
} from './constants';

function* getCustomerDetailsSaga(action) {
    const { monorailId, actorId } = action.data;
    let queryString = getQueryStringFromObject({ monorailId });
    queryString = `${queryString}&actorId=${btoa(actorId)}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_ROOT}/customer-details?${queryString}`,
        );

        yield put({ type: SET_CUSTOMER_DETAILS, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CUSTOMER_DETAILS, data: { err: e.message } });
    }
}

export default function* customerDetailsSaga() {
    yield all(
        [
            yield takeLatest(GET_CUSTOMER_DETAILS, getCustomerDetailsSaga),
        ],
    );
}

/**
 * @file LoanAccountDetailsScreen: Component to show loan account details screen.
 */

import React, { memo } from 'react';

import { TableV1 } from '../../../components';
import { useScrollToPosition } from '../../../hooks';

import CardBanner from '../CardBanner';
import { getLoanAccountDetails } from '../utils';
import { IS_LOAN_ACCOUNT_DETAILS_SCREEN } from '../constants';

const LoanAccountDetailsScreen = (props) => {
    const { data, ticketId } = props;

    useScrollToPosition();

    return (
        <CardBanner title='Credit Card' discardCurrentScreen={IS_LOAN_ACCOUNT_DETAILS_SCREEN} ticketId={ticketId}>
            <TableV1
                heading={`EMI loan ID ${data?.loanAccountId} details`}
                contentData={getLoanAccountDetails(data)}
            />
        </CardBanner>
    );
};

export default memo(LoanAccountDetailsScreen);

/**
 *
 * Show a warning message modal before user selects to dequeue a case in the 3 Risk ops queue: liveness, facematch & namematch
 * If submitted the action will remove the case from the respective queue
 *
 */

import React from 'react';
import { Button, Modal } from '../../components';

import './style.scss';

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 500,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        backgroundColor: '#F5F5F5',
    },
};

const DequeueCaseModal = ({ closeCta, submitCta, visible }) => {
    if (!visible) return null;

    return (
        <Modal
            visible
            modalStyles={modalStyles}
            contentLabel='Dequeue Current Case'
        >
            <div className='rops-modal__hl'>
                Are you sure you want to dequeue this case?
            </div>
            <div className='rops-modal__sbhl'>
                This change is irreversible and affects production data
            </div>
            <div className='frcsbWrapper rops-modal__cta'>
                <Button
                    v2
                    secondary
                    label='Submit'
                    onClick={submitCta}
                />
                <Button
                    v2
                    primary
                    label='Cancel'
                    onClick={() => closeCta(false)}
                />
            </div>
        </Modal>
    );
};

export default DequeueCaseModal;

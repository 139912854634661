/**
 * @file Actions for SalaryAccountOps
 */

import {
    GET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS, SET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS, ERR_VIEW_SALARY_ACCOUNT_DETAILS, SET_SALARY_OPS_CUR_VIEW,
    GET_EMPLOYER_DETAILS_SALARY_ACCOUNT, SET_EMPLOYER_DETAILS_SALARY_ACCOUNT, UPDATE_EMPLOYER_DETAILS_SALARY_ACCOUNT, ERR_SALARY_OPS,
    SET_SALARY_ACCOUNT_DETAILS_USER_IDENTIFIER, SUCCESSFULLY_UPDATED_EMPLOYER_DETAILS, GET_SALARY_OPS_ENUMS, SET_SALARY_OPS_ENUMS,
    GET_SALARY_VERIFICATION_CASES, SET_SALARY_VERIFICATION_CASES, SET_SALARY_VERIFICATION_CASE_DETAILS, SET_SALARY_VERIFICATION_NEW_EMPLOYER_DETAILS,
    SET_SALARY_VERIFICATION_CASE_EMPLOYER_DETAILS, GET_SALARY_VERIFICATION_CASE_EMPLOYER_DETAILS, SALARY_ACCOUNT_DETAILS_KEY,
    SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY, UPDATE_SALARY_VERIFICATION_CASE_STATUS, SUCCESSFULLY_UPDATED_SALARY_VERIFICATION_CASE_STATUS,
    SET_SALARY_VERIFICATION_SEARCH_FILTER, GET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS, SET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS,
    GET_SALARY_VERIFICATION_REQUESTS_FOR_USER, SET_SALARY_VERIFICATION_REQUESTS_FOR_USER, GET_SALARY_EMPLOYER_DETAILS_FOR_USER,
    SET_SALARY_EMPLOYER_DETAILS_FOR_USER, SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY, SET_RAISE_VERIFICATION_REQUEST_FILTER,
    GET_RAISE_VERIFICATION_REQUEST_CASES, SET_RAISE_VERIFICATION_REQUEST_CASES, SET_RAISE_VERIFICATION_SELECTED_ACTOR,
    GET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS, SET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS, GET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS,
    SET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS, UPDATE_RAISE_VERIFICATION_REQUEST_STATUS, UPDATE_RAISE_VERIFICATION_REQUEST_STATUS_SUCCESS,
    GET_USER_HEALTH_INSURANCE_POLICY_DETAILS, GET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS, GET_SALARY_ACTIVATION_STATUS,
    SALARY_ACCOUNT_HEALTH_INSURANCE_KEY, REDUCER_KEYS, SET_USER_HEALTH_INSURANCE_POLICY_DETAILS, SET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS,
    SET_SALARY_ACTIVATION_STATUS, ERROR_USER_HEALTH_INSURANCE_DETAILS, ERROR_GENERAL_HEALTH_INSURANCE_DETAILS, ERROR_SALARY_ACTIVATION_STATUS,
    GET_SALARY_VERIFICATION_CASE_TXN_HISTORY, SET_SALARY_VERIFICATION_CASE_TXN_HISTORY, GET_SALARY_VERIFICATION_CASES_COUNT,
    SET_SALARY_VERIFICATION_CASES_COUNT, GET_SALARY_ACCOUNT_MANDATES, SET_SALARY_ACCOUNT_MANDATES, ERR_SALARY_ACCOUNT_MANDATES,
    GET_SALARY_ACCOUNT_EXECUTED_MANDATES, SET_SALARY_ACCOUNT_EXECUTED_MANDATES, ERR_SALARY_ACCOUNT_EXECUTED_MANDATES,
    GET_SALARY_OPS_EMPLOYER_LIST, SET_SALARY_OPS_EMPLOYER_LIST, ERR_SALARY_OPS_EMPLOYER_LIST, SET_SALARY_VERIFICATION_CASES_EMPLOYER_TYPE,
    SET_SALARY_VERIFICATION_CASES_EMPLOYER_NAME, SET_IDENTIFIER_LIST, SET_ACTIVE_TAB_INDEX, SET_NEW_TAB_ACTIVE,
} from './constants';

export const getSalaryAccountDetailsForDataOpsAction = (data) => ({
    type: GET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS,
    data: {
        ...data,
        subScreen: 'accountDetails',
    },
});

export const setSalaryAccountDetailsForDataOpsAction = (data) => ({
    type: SET_SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS,
    data: {
        ...data,
        subScreen: 'accountDetails',
    },
});

export const getSalaryAccountDetailsForAgentsAction = (data) => ({
    type: GET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS,
    data: {
        ...data,
        subScreen: 'accountDetails',
    },
});

export const setSalaryAccountDetailsForAgentsAction = (data) => ({
    type: SET_SALARY_ACCOUNT_DETAILS_FOR_AGENTS,
    data: {
        ...data,
        subScreen: 'accountDetails',
    },
});

export const getSalaryAccountMandatesAction = (data) => ({
    type: GET_SALARY_ACCOUNT_MANDATES,
    data: {
        ...data,
        subScreen: 'mandates',
    },
});

export const setSalaryAccountMandatesAction = (data) => ({
    type: SET_SALARY_ACCOUNT_MANDATES,
    data: {
        ...data,
        subScreen: 'mandates',
    },
});

export const setErrInViewSalaryAccountMandatesAction = (data) => ({
    type: ERR_SALARY_ACCOUNT_MANDATES,
    data,
});

export const getSalaryAccountExecutedMandatesAction = (data) => ({
    type: GET_SALARY_ACCOUNT_EXECUTED_MANDATES,
    data: {
        ...data,
        subScreen: 'executedMandates',
    },
});

export const setSalaryAccountExecutedMandatesAction = (data) => ({
    type: SET_SALARY_ACCOUNT_EXECUTED_MANDATES,
    data: {
        ...data,
        subScreen: 'executedMandates',
    },
});

export const setErrInViewSalaryAccountExecutedMandatesAction = (data) => ({
    type: ERR_SALARY_ACCOUNT_EXECUTED_MANDATES,
    data,
});

export const getSalaryVerificationRequestsForUserAction = (data) => ({
    type: GET_SALARY_VERIFICATION_REQUESTS_FOR_USER,
    data: {
        ...data,
        subScreen: 'verificationRequests',
    },
});

export const setSalaryVerificationRequestsForUserAction = (data) => ({
    type: SET_SALARY_VERIFICATION_REQUESTS_FOR_USER,
    data: {
        ...data,
        subScreen: 'verificationRequests',
    },
});

export const getSalaryEmployerDetailsForUserAction = (data) => ({
    type: GET_SALARY_EMPLOYER_DETAILS_FOR_USER,
    data: {
        ...data,
        subScreen: 'employerDetails',
    },
});

export const setSalaryEmployerDetailsForUserAction = (data) => ({
    type: SET_SALARY_EMPLOYER_DETAILS_FOR_USER,
    data: {
        ...data,
        subScreen: 'employerDetails',
    },
});

export const setErrInViewSalaryAccountDetailsAction = (data) => ({
    type: ERR_VIEW_SALARY_ACCOUNT_DETAILS,
    data,
});

export const setErrInSalaryOpsAction = (data) => ({
    type: ERR_SALARY_OPS,
    data,
});

export const setSalaryAccountOpsCurViewAction = (data) => ({
    type: SET_SALARY_OPS_CUR_VIEW,
    data: {
        ...data,
        key: 'curView',
    },
});

export const getEmployerDetailsAction = (data) => ({
    type: GET_EMPLOYER_DETAILS_SALARY_ACCOUNT,
    data: {
        screenKey: SALARY_ACCOUNT_DETAILS_KEY,
        key: 'employerDetails',
        payload: data,
        resetValue: null,
    },
});

export const setEmployerDetailsAction = (data) => ({
    type: SET_EMPLOYER_DETAILS_SALARY_ACCOUNT,
    data: {
        screenKey: SALARY_ACCOUNT_DETAILS_KEY,
        key: 'employerDetails',
        value: data,
    },
});

export const updateEmployerDetailsAction = (data) => ({
    type: UPDATE_EMPLOYER_DETAILS_SALARY_ACCOUNT,
    data: {
        screenKey: SALARY_ACCOUNT_DETAILS_KEY,
        payload: data,
    },
});

export const successfullyUpdatedEmployerDetailsAction = () => ({
    type: SUCCESSFULLY_UPDATED_EMPLOYER_DETAILS,
    data: {
        screenKey: SALARY_ACCOUNT_DETAILS_KEY,
    },
});

export const setSalaryAccountDetailsUserIdentifierAction = (data) => ({
    type: SET_SALARY_ACCOUNT_DETAILS_USER_IDENTIFIER,
    data: {
        screenKey: data.screenKey,
        key: 'userIdentifier',
        value: data.value,
    },
});

export const getSalaryOpsEnumsAction = (data) => ({
    type: GET_SALARY_OPS_ENUMS,
    data,
});

export const setSalaryOpsEnumsAction = (data) => ({
    type: SET_SALARY_OPS_ENUMS,
    data,
});

export const getSalaryOpsEmployerListAction = (data) => ({
    type: GET_SALARY_OPS_EMPLOYER_LIST,
    data,
});

export const setSalaryOpsEmployerListAction = (data) => ({
    type: SET_SALARY_OPS_EMPLOYER_LIST,
    data,
});

export const errSalaryOpsEmployerListAction = (data) => ({
    type: ERR_SALARY_OPS_EMPLOYER_LIST,
    data,
});

export const getVerificationCasesAction = (data) => ({
    type: GET_SALARY_VERIFICATION_CASES,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
        key: 'verificationCases',
        payload: data,
        resetValue: {},
    },
});

export const setVerificationCasesEmployerTypeAction = (data) => ({
    type: SET_SALARY_VERIFICATION_CASES_EMPLOYER_TYPE,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
        key: 'employerType',
        value: data,
    },
});

export const setVerificationCasesEmployerNameAction = (data) => ({
    type: SET_SALARY_VERIFICATION_CASES_EMPLOYER_NAME,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
        key: 'employerName',
        value: data,
    },
});
export const setVerificationCasesAction = (data) => ({
    type: SET_SALARY_VERIFICATION_CASES,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
        key: 'verificationCases',
        value: data,
    },
});

export const getVerificationCasesCountAction = (data) => ({
    type: GET_SALARY_VERIFICATION_CASES_COUNT,
    data: {
        setterArray: [SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY, 'verificationCasesCount'],
        payload: data,
        resetValue: {},
    },
});

export const setVerificationCasesCountAction = (data) => ({
    type: SET_SALARY_VERIFICATION_CASES_COUNT,
    data: {
        setterArray: [SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY, 'verificationCasesCount'],
        value: data,
    },
});

export const setVerificationCaseDetailsAction = (data) => ({
    type: SET_SALARY_VERIFICATION_CASE_DETAILS,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
        key: 'caseDetails',
        value: data,
    },
});

export const setVerificationNewEmployerDetailsAction = (data) => ({
    type: SET_SALARY_VERIFICATION_NEW_EMPLOYER_DETAILS,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
        key: 'newEmployerDetails',
        value: data,
    },
});

export const getVerificationCaseEmployerDetailsAction = (data) => ({
    type: GET_SALARY_VERIFICATION_CASE_EMPLOYER_DETAILS,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
        key: 'caseEmployerDetails',
        payload: data,
        resetValue: null,
    },
});

export const setVerificationCaseEmployerDetailsAction = (data) => ({
    type: SET_SALARY_VERIFICATION_CASE_EMPLOYER_DETAILS,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
        key: 'caseEmployerDetails',
        value: data,
    },
});

export const getVerificationCaseTxnHistoryAction = (data) => ({
    type: GET_SALARY_VERIFICATION_CASE_TXN_HISTORY,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
        key: 'caseTxnHistory',
        payload: data,
        resetValue: null,
    },
});

export const setVerificationCaseTxnHistoryAction = (data) => ({
    type: SET_SALARY_VERIFICATION_CASE_TXN_HISTORY,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
        key: 'caseTxnHistory',
        value: data,
    },
});

export const updateSalaryVerificationCaseStatusAction = (data) => ({
    type: UPDATE_SALARY_VERIFICATION_CASE_STATUS,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
        payload: data,
    },
});

export const successfullyUpdatedSalaryVerificationCaseStatusAction = () => ({
    type: SUCCESSFULLY_UPDATED_SALARY_VERIFICATION_CASE_STATUS,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
    },
});

export const setSalaryFilterVerificationStatusAction = (data) => ({
    type: SET_SALARY_VERIFICATION_SEARCH_FILTER,
    data: {
        screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY,
        key: 'verificationStatus',
        value: data,
    },
});

export const setRaiseSalaryVerificationStatusAction = (data) => ({
    type: SET_RAISE_VERIFICATION_REQUEST_FILTER,
    data: {
        screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
        key: 'raiseVerificationStatus',
        value: data,
    },
});

export const setRaiseSalaryVerificationStageAction = (data) => ({
    type: SET_RAISE_VERIFICATION_REQUEST_FILTER,
    data: {
        screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
        key: 'raiseVerificationStage',
        value: data,
    },
});

export const getRaiseSalaryVerificationCasesAction = (data) => ({
    type: GET_RAISE_VERIFICATION_REQUEST_CASES,
    data: {
        screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
        key: 'raiseVerificationCases',
        payload: data,
        resetValue: {},
    },
});

export const setRaiseSalaryVerificationCasesAction = (data) => ({
    type: SET_RAISE_VERIFICATION_REQUEST_CASES,
    data: {
        screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
        key: 'raiseVerificationCases',
        value: data,
    },
});

export const setRaiseVerificationRequestSelectedActorAction = (data) => ({
    type: SET_RAISE_VERIFICATION_SELECTED_ACTOR,
    data: {
        screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
        key: 'selectedActor',
        value: data,
    },
});

export const getRaiseVerificationRequestCaseDetailsAction = (data) => ({
    type: GET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS,
    data: {
        screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
        key: 'raiseVerificationCaseDetails',
        payload: data,
    },
});

export const setRaiseVerificationRequestCaseDetailsAction = (data) => ({
    type: SET_RAISE_VERIFICATION_REQUEST_CASE_DETAILS,
    data: {
        screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
        key: 'raiseVerificationCaseDetails',
        value: data,
    },
});

export const getRaiseVerificationRequestEligibleTxnsAction = (data) => ({
    type: GET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS,
    data: {
        screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
        key: 'raiseVerificationEligibleTxns',
        payload: data,
    },
});

export const setRaiseVerificationRequestEligibleTxnsAction = (data) => ({
    type: SET_RAISE_VERIFICATION_REQUEST_ELIGIBLE_TXNS,
    data: {
        screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
        key: 'raiseVerificationEligibleTxns',
        value: data,
    },
});

export const updateRaiseVerificationRequestStatusAction = (data) => ({
    type: UPDATE_RAISE_VERIFICATION_REQUEST_STATUS,
    data: {
        screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
        payload: data,
    },
});

export const updateRaiseVerificationRequestStatusSuccessAction = () => ({
    type: UPDATE_RAISE_VERIFICATION_REQUEST_STATUS_SUCCESS,
    data: {
        screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
    },
});

export const getSalaryActivationStatusAction = (data) => ({
    type: GET_SALARY_ACTIVATION_STATUS,
    data: {
        ...data,
        setterArray: [SALARY_ACCOUNT_DETAILS_KEY, data.screenKey, REDUCER_KEYS.ACTIVATION_STATUS],
    },
});

export const setSalaryActivationStatusAction = (data) => ({
    type: SET_SALARY_ACTIVATION_STATUS,
    data: {
        ...data,
        setterArray: [SALARY_ACCOUNT_DETAILS_KEY, data.screenKey, REDUCER_KEYS.ACTIVATION_STATUS],
    },
});

export const setErrInSalaryActivationStatusAction = (data) => ({
    type: ERROR_SALARY_ACTIVATION_STATUS,
    data: {
        setterArray: [SALARY_ACCOUNT_DETAILS_KEY, data.screenKey, REDUCER_KEYS.ACTIVATION_STATUS],
    },
});

export const getUserHealthInsurancePolicyDetailsAction = (data) => ({
    type: GET_USER_HEALTH_INSURANCE_POLICY_DETAILS,
    data: {
        ...data,
        resetValue: {},
        setterArray: [SALARY_ACCOUNT_HEALTH_INSURANCE_KEY, data.screenKey, REDUCER_KEYS.USER_DETAILS],
    },
});

export const setUserHealthInsurancePolicyDetailsAction = (data) => ({
    type: SET_USER_HEALTH_INSURANCE_POLICY_DETAILS,
    data: {
        value: data,
        setterArray: [SALARY_ACCOUNT_HEALTH_INSURANCE_KEY, data.screenKey, REDUCER_KEYS.USER_DETAILS],
    },
});

export const setErrInUserHealthInsurancePolicyDetailsAction = (data) => ({
    type: ERROR_USER_HEALTH_INSURANCE_DETAILS,
    data: {
        setterArray: [SALARY_ACCOUNT_HEALTH_INSURANCE_KEY, data.screenKey, REDUCER_KEYS.USER_DETAILS],
    },
});

export const getGeneralHealthInsurancePolicyDetailsAction = (data) => ({
    type: GET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS,
    data: {
        ...data,
        resetValue: {},
        setterArray: [SALARY_ACCOUNT_HEALTH_INSURANCE_KEY, data.screenKey, REDUCER_KEYS.GENERAL_DETAILS],
    },
});

export const setGeneralHealthInsurancePolicyDetailsAction = (data) => ({
    type: SET_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS,
    data: {
        value: data,
        setterArray: [SALARY_ACCOUNT_HEALTH_INSURANCE_KEY, data.screenKey, REDUCER_KEYS.GENERAL_DETAILS],
    },
});

export const setErrInGeneralHealthInsurancePolicyDetailsAction = (data) => ({
    type: ERROR_GENERAL_HEALTH_INSURANCE_DETAILS,
    data: {
        setterArray: [SALARY_ACCOUNT_HEALTH_INSURANCE_KEY, data.screenKey, REDUCER_KEYS.GENERAL_DETAILS],
    },
});

export const setIdentifierList = (data) => ({
    type: SET_IDENTIFIER_LIST,
    data,
});

export const setActiveTabIndex = (data) => ({
    type: SET_ACTIVE_TAB_INDEX,
    data,
});

export const setNewTabActive = (data) => ({
    type: SET_NEW_TAB_ACTIVE,
    data,
});

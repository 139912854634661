import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { PROFILE_ROOT } from '../../api/routes';

import {
    GET_BULK_USER_INFO, SET_BULK_USER_INFO, ERR_BULK_USER_INFO,
} from './constants';

function* getBulkUserInfoByTicketIds(action) {
    const { actorIds, accountIds, phoneNumbers, reason } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${PROFILE_ROOT}/bulk-user-info/ids`,
            {
                actorIds,
                accountIds,
                phoneNumbers,
                reason,
            },
        );

        yield put({ type: SET_BULK_USER_INFO, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_BULK_USER_INFO });
    }
}

export default function* bulkUserDetailsByTicketIdsSaga() {
    yield all(
        [
            yield takeLatest(GET_BULK_USER_INFO, getBulkUserInfoByTicketIds),
        ]
    );
}

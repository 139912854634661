/**
 * @file LivenessAndFacematchReview:
 * This shows the combined merged queue of liveness and facematch cases in a single queue
 * On selecting a particular case, the analyst will be able to review either liveness or facematch or both of them
 */

import React, { memo } from 'react';

import { RenderView } from '../RiskOps';

import { LIVENESS_AND_FACEMATCH_REVIEW_KEY as CONTAINER_KEY, QUEUE_PAYLOAD_TYPES } from './constants';
import SingleCase from './SingleCase';

const LivenessAndFacematchReview = () => (
    <RenderView
        singleCaseComponent={<SingleCase />}
        data={{
            containerKey: CONTAINER_KEY,
            queuePayloadTypes: QUEUE_PAYLOAD_TYPES,
            queueName: 'Liveness & Facematch',
        }}
    />

);

export default memo(LivenessAndFacematchReview);

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, toastify } from '../../utils';
import { TICKETS_ROOT } from '../../api/routes';

import {
    GET_RELATED_TICKETS, SET_RELATED_TICKETS, ERR_RELATED_TICKETS,
    MERGE_RELATED_TICKETS, SUCCESS_MERGE_RELATED_TICKETS,
} from './constants';

function* getRelatedTickets(action) {
    const { ticketId } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKETS_ROOT}/${ticketId}/related`,
        );

        yield put({ type: SET_RELATED_TICKETS, data: { ...response, ticketId }});
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_RELATED_TICKETS, data: { ticketId }});
    }
}

function* mergeRelatedTickets(action) {
    const { ticketId, primaryTicket, primaryReason, secondaryTicket, secondaryReason,
        resolve, } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${TICKETS_ROOT}/${ticketId}/related`,
            {
                primaryTicket,
                primaryReason,
                secondaryTicket,
                secondaryReason,
            }
        );

        toastify(response.description, 'success');
        yield put({ type: SUCCESS_MERGE_RELATED_TICKETS, data: { ticketId }});
        yield call(resolve);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_RELATED_TICKETS, data: { ticketId }});
    }
}

export default function* userActionsSaga() {
    yield all(
        [
            yield takeLatest(GET_RELATED_TICKETS, getRelatedTickets),
            yield takeLatest(MERGE_RELATED_TICKETS, mergeRelatedTickets),
        ]
    );
}

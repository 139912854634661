/**
 * @file Manage the sagas for Home component
 */
import {
    all, call, put, takeLatest,
} from 'redux-saga/effects';

import { CALL_LIST_ENDPOINT } from '../../api/routes';
import {
    clientApiWrapper, getErrLabel, getErrMsg, getQueryStringFromObject, toastify,
} from '../../utils';

import { GET_AGENT_CALLS } from './constants';
import { errCallsAction, setCallsAction } from './actions';

function* getCallListSaga(action) {
    const { ozonetelId, prev, next } = action.data;
    const queryString = getQueryStringFromObject({ ozonetelId, prev, next });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CALL_LIST_ENDPOINT}?${queryString}`,
        );
        yield put(setCallsAction({ key: 'data', value: response?.callDetailsTable }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errCallsAction({ err: getErrMsg(e) }));
    }
}

export default function* HomeSaga() {
    yield all(
        [
            yield takeLatest(GET_AGENT_CALLS, getCallListSaga),
        ],
    );
}

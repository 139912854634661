/*
 *
 * @file Functions to retrieve details about US stocks CX agent view.
 *
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import {
    WEALTH_US_STOCKS_ACTIVITY_TYPE,
    WEALTH_US_STOCKS_ENDPOINT,
    WEALTH_US_STOCKS_REMITTANCES,
    WEALTH_US_STOCKS_REMITTANCE_ORDER_DETAILS,
    WEALTH_US_STOCKS_REMITTANCE_TYPE,
} from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    ERR_WEALTH_USSTOCKS, GET_ACCOUNT_BASIC_DETAILS, GET_ACCOUNT_STAGES_DETAILS, GET_ACTIVITIES, GET_ACTIVITY_DETAILS, GET_ACTIVITY_TYPE_LIST,
    GET_PORTFOLIO_DETAILS, SET_ACCOUNT_BASIC_DETAILS, SET_ACCOUNT_STAGES_DETAILS, SET_ACTIVITIES, SET_ACTIVITY_DETAILS,
    SET_ACTIVITY_TYPE, SET_ACTIVITY_TYPE_LIST, SET_PORTFOLIO_DETAILS, ERR_US_STOCKS, SET_REMITTANCES, SET_REMITTANCE_ORDER_DETAILS,
    GET_REMITTANCES, GET_REMITTANCE_ORDER_DETAILS, SET_REMITTANCE_TYPE_LIST, GET_REMITTANCE_TYPE_LIST,
} from './constants';

function* getPortfolioDetails(action) {
    const { ticketId, userId } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });
    const containerKey = 'investedAssetsList';

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_US_STOCKS_ENDPOINT}/portfolio-details?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { ...response } });

        if (!result) {
            yield put({ type: SET_PORTFOLIO_DETAILS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: SET_PORTFOLIO_DETAILS, data: {} });
            yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId, err } });
    }
}

function* getActivities(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);
    const containerKey = 'activities';

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_US_STOCKS_ENDPOINT}/activities?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { ...response } });

        if (!result) {
            yield put({ type: SET_ACTIVITIES, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: SET_ACTIVITIES, data: {} });
            yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId, err } });
    }
}

function* getActivityDetails(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);
    const containerKey = 'activityDetail';

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_US_STOCKS_ENDPOINT}/activity-details?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { ...response } });

        if (!result) {
            yield put({ type: SET_ACTIVITY_DETAILS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: SET_ACTIVITY_DETAILS, data: {} });
            yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId, err } });
    }
}

function* getActivityTypeListSaga() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_US_STOCKS_ACTIVITY_TYPE}`,
        );

        yield put({ type: SET_ACTIVITY_TYPE_LIST, data: response });
        yield put({ type: SET_ACTIVITY_TYPE, data: response.list[0] });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_ACTIVITY_TYPE_LIST, data: {} });
        yield put({ type: ERR_WEALTH_USSTOCKS, data: { err } });
    }
}

function* getRemittanceTypeListSaga() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_US_STOCKS_REMITTANCE_TYPE}`,
        );

        yield put({ type: SET_REMITTANCE_TYPE_LIST, data: response });
        // yield put({ type: SET_ACTIVITY_TYPE, data: response.list[0] });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_REMITTANCE_TYPE_LIST, data: {} });
        yield put({ type: ERR_WEALTH_USSTOCKS, data: { err } });
    }
}

function* getAccountBasicDetails(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);
    const containerKey = 'accountBasicDetails';

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_US_STOCKS_ENDPOINT}/account-details?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { ...response } });

        if (!result) {
            const accountDetails = [];
            accountDetails.push(response.accountDetails);
            yield put({ type: SET_ACCOUNT_BASIC_DETAILS, data: { ticketId, accountDetails } });
        } else if (result === 'auth-expired') {
            yield put({ type: SET_ACCOUNT_BASIC_DETAILS, data: {} });
            yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId, err } });
    }
}

function* getAccountStagesDetails(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);
    const containerKey = 'accountStageDetails';

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_US_STOCKS_ENDPOINT}/account-stage-details?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { ...response } });

        if (!result) {
            yield put({ type: SET_ACCOUNT_STAGES_DETAILS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: SET_ACCOUNT_STAGES_DETAILS, data: {} });
            yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId, err } });
    }
}

function* getRemittances(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);
    const containerKey = 'remittances';

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_US_STOCKS_REMITTANCES}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { ...response } });

        if (!result) {
            yield put({ type: SET_REMITTANCES, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: SET_REMITTANCES, data: {} });
            yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId, err } });
    }
}

function* getRemittanceOrderDetails(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject(action.data);
    const containerKey = 'remittances';

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_US_STOCKS_REMITTANCE_ORDER_DETAILS}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { ...response } });

        if (!result) {
            yield put({ type: SET_REMITTANCE_ORDER_DETAILS, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: SET_REMITTANCE_ORDER_DETAILS, data: {} });
            yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_US_STOCKS, data: { containerKey, ticketId, err } });
    }
}

export default function* usstocksSaga() {
    yield all([
        yield takeLatest(GET_PORTFOLIO_DETAILS, getPortfolioDetails),
        yield takeLatest(GET_ACTIVITIES, getActivities),
        yield takeLatest(GET_ACTIVITY_DETAILS, getActivityDetails),
        yield takeLatest(GET_ACTIVITY_TYPE_LIST, getActivityTypeListSaga),
        yield takeLatest(GET_ACCOUNT_BASIC_DETAILS, getAccountBasicDetails),
        yield takeLatest(GET_ACCOUNT_STAGES_DETAILS, getAccountStagesDetails),
        yield takeLatest(GET_REMITTANCES, getRemittances),
        yield takeLatest(GET_REMITTANCE_ORDER_DETAILS, getRemittanceOrderDetails),
        yield takeLatest(GET_REMITTANCE_TYPE_LIST, getRemittanceTypeListSaga),
    ]);
}

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { DEFAULT_TICKET_PATH } from '../../App/routes';
import { SEND_ACCOUNT_STATEMENT } from '../../../api/routes';

import { clientApiWrapper, getErrMsg, showErrorToastMsg, toastify } from '../../../utils';

import { makeSelectProfileDetails } from '../../Profile/selectors';

import {
    Button, DropdownContent, DropdownWrapper, ExpandableCard, FormWithSingleInput, Loader,
} from '../../../components';

import { getAccountStatementFormDetails } from '../actions';
import { makeSelectAccountStatementFormDetails } from '../selectors';

import './styles.scss';

const AccountStatement = (props) => {
    const {
        ticketId, index,
    } = props;

    const profileDetails = useSelector(makeSelectProfileDetails, shallowEqual);
    const accountStatementData = useSelector(makeSelectAccountStatementFormDetails(), shallowEqual);
    const {
        loading, data, hasData, err,
    } = accountStatementData[ticketId] ?? {};

    const [isAccountStatemnentCardExpanded, setIsAccountStatemnentCardExpanded] = useState(true);
    const [accountType, setAccountType] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [reason, setReason] = useState('');
    const [errLabel, setErrLabel] = useState('');
    const [showStatementType, setShowStatementType] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (!hasData) {
            dispatch(getAccountStatementFormDetails({ ticketId, index }));
        }
    }, []);

    useEffect(() => {
        if (hasData) {
            const { endTime, startTime, accountTypes } = data ?? {};
            const inititalStartDate = new Date(startTime.defaultDisplayValue);
            const inititalEndDate = new Date(endTime.defaultDisplayValue);
            const initialActivityType = accountTypes?.[0];

            setStartDate(inititalStartDate);
            setEndDate(inititalEndDate);
            setAccountType(initialActivityType);
        }
    }, [hasData]);

    const dispatch = useDispatch();

    const toggleAccountStatementCard = () => {
        setIsAccountStatemnentCardExpanded(!isAccountStatemnentCardExpanded);
    };

    const toggleDD = () => {
        setShowStatementType(!showStatementType);
    };

    const handleDDChange = (value) => {
        setAccountType(value);
    };

    const handleDateChange = (key) => (value) => {
        switch (key) {
            case 'startDate':
                setStartDate(value);
                break;

            case 'endDate':
                setEndDate(value);
                break;

            default:
        }
    };

    const handleSubmit = async () => {
        if (!accountType || !startDate || !endDate || !reason) {
            setErrLabel('Please fill all the * marked fields');
        }

        if (accountType && startDate && endDate && reason) {
            try {
                const payload = {
                    ticketId,
                    accountType: data.accountTypeToDetailsMap?.[accountType]?.account_type,
                    startDate,
                    endDate,
                    reason,
                };

                const response = await clientApiWrapper.post(`${SEND_ACCOUNT_STATEMENT}`, { payload });

                if (response) {
                    setStartDate();
                    setEndDate();
                    setReason('');
                    setAccountType();
                    const { message } = response;

                    toastify(message, 'success');
                }
            } catch (error) {
                showErrorToastMsg(error);
                setErrLabel(getErrMsg(error));
            }
        }
    };

    const renderAuthExpiredScreen = () => {
        const listOfAuthFactorsRequired = err?.screenOptions?.authFactorList.map((item) => item.name);

        return (
            <div className='account-statement-error-section'>
                <div className='ticket-modal__heading'>
                    User Authorisation for this action is not enough. You need to collect {listOfAuthFactorsRequired?.join()}.
                </div>
                <div className='ticket-modal__subheading'>Press button below to go to the authorisation flow</div>
                <Button
                    primary
                    label='Go to authorisation flow'
                    onClick={() => {
                        history.push(`${DEFAULT_TICKET_PATH}${ticketId}`);
                    }}
                />
            </div>
        );
    };

    const renderStatementType = () => (
        <div className='frcWrapper account-statement-dd'>
            <div className='account-statement-other-label'>Statement Type</div>
            <DropdownWrapper
                visible={showStatementType}
                onOutsideClickHandler={() => toggleDD()}
                extClasses={{
                    container: 'custom-dd',
                }}
            >
                <div
                    onClick={() => toggleDD()}
                    role='presentation'
                >
                    {accountType || 'Choose...'}
                </div>

                <DropdownContent
                    visible
                    extClasses={{
                        container: ['custom-dd__cc height-transition', showStatementType && 'account-statement-custom-dd__cc--ac'],
                    }}
                >
                    {
                        data.accountTypes.map((item) => (
                            <div key={item} className='custom-dd__cl' onClick={() => handleDDChange(item)} role='presentation'>{item}</div>
                        ))
                    }
                </DropdownContent>
            </DropdownWrapper>
        </div>
    );

    const renderDateBlock = (key) => {
        let label;
        let labelId;
        let value;
        let maxDate;
        let minDate;

        switch (key) {
            case 'startDate':
                label = 'Start Date*';
                labelId = 'start-date';
                value = startDate;
                maxDate = startDate ? new Date(startDate.maxValue) : null;
                minDate = startDate ? new Date(startDate.minValue) : null;
                break;

            case 'endDate':
                label = 'End Date*';
                labelId = 'end-date';
                value = endDate;
                maxDate = endDate ? new Date(endDate.maxValue) : null;
                minDate = endDate ? new Date(endDate.minValue) : null;
                break;

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'account-statement-fc--m0',
                    label: 'account-statement-fc__lb',
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: handleDateChange(key),
                    maxDate,
                    minDate,
                }}
            />
        );
    };

    const renderAccounInfo = (key) => {
        let label;
        let value;

        switch (key) {
            case 'accountNumber':
                label = 'Account Number';
                value = data.accountTypeToDetailsMap?.[accountType]?.account_number_last_four_digits ?? '-';
                break;

            case 'registeredMailID':
                label = 'Registered Mail ID';
                value = profileDetails[ticketId].profileInfo.accountInfo.email;
                break;

            default:
        }

        return (
            <div className='account-statement-info--container'>
                <div className='account-statement-info--label'>{label}</div>
                <div className='account-statement-info--value'>{value}</div>
            </div>
        );
    };

    if (loading) {
        return <Loader visible={loading} />;
    }

    if (err && Object.keys(err).length && !hasData) {
        return <div className='fcWrapper account-statement-error-text'>{renderAuthExpiredScreen()}</div>;
    }

    if (hasData) {
        return (
            <div className='fcWrapper'>
                <div className='account-statement-cr'>
                    <ExpandableCard
                        extClasses='account-statement-ec'
                        heading='Account Statement'
                        isExpanded={setIsAccountStatemnentCardExpanded}
                        setIsExpanded={toggleAccountStatementCard}
                    >
                        <div className='account-statement-field-container'>
                            {renderStatementType()}
                            <div className=''>
                                {renderDateBlock('startDate')}
                                {renderDateBlock('endDate')}
                            </div>
                            <FormWithSingleInput
                                disableFormSubmitOnEnter
                                extClasses={{
                                    label: 'account-statement-other-label',
                                }}
                                label='Reason*'
                                labelId='reason'
                                formType='text'
                                input={{
                                    value: reason,
                                    onChange: (e) => setReason(e.target.value),
                                }}
                            />

                            <div>
                                {renderAccounInfo('accountNumber')}
                                {renderAccounInfo('registeredMailID')}
                            </div>

                            {errLabel ? <div className='err-label'>{errLabel}</div> : null}
                            <div className='account-statement-button'>
                                <Button
                                    v2
                                    primary
                                    label='Send Statement'
                                    onClick={handleSubmit}
                                    extClasses='account-statement-ec__cta'
                                />
                            </div>
                        </div>
                    </ExpandableCard>
                </div>
            </div>
        );
    }

    return null;
};

export default AccountStatement;

/**
 * @file RecentActivity/constants.js - Contains constants used for recent activity
 */
export const RECENT_ACTIVITY_ROOT_REDUX = 'riskActivityReducer';

export const RECENT_ACTIVITY_DATA = 'recentActivityData';

export const GET_RECENT_ACTIVITY = 'risk/RecentActivity/GET_RECENT_ACTIVITY';
export const SET_RECENT_ACTIVITY = 'risk/RecentActivity/SET_RECENT_ACTIVITY';
export const ERR_RECENT_ACTIVITY = 'risk/RecentActivity/ERR_RECENT_ACTIVITY';

export const GET_RECENT_ACTIVITY_AREA = 'risk/RecentActivity/GET_RECENT_ACTIVITY_AREA';
export const SET_RECENT_ACTIVITY_AREA = 'risk/RecentActivity/SET_RECENT_ACTIVITY_AREA';

export const GET_RECENT_ACTIVITY_DETAILS = 'risk/RecentActivity/GET_RECENT_ACTIVITY_DETAILS';
export const SET_RECENT_ACTIVITY_DETAILS = 'risk/RecentActivity/SET_RECENT_ACTIVITY_DETAILS';
export const ERR_RECENT_ACTIVITY_DETAILS = 'risk/RecentActivity/ERR_RECENT_ACTIVITY_DETAILS';
export const SET_LOADER = 'risk/RecentActivity/SET_LOADER';
export const SET_ERROR = 'risk/RecentActivity/SET_ERROR';

export const defaultDropdownOption = [];

export const EntityType = {
    ENTITY_TYPE_UNSPECIFIED: 'ENTITY_TYPE_UNSPECIFIED',
    TRANSACTION: 'TRANSACTION',
    SAVINGS: 'SAVINGS',
    REWARDS: 'REWARDS',
    ACTOR_ACTIVITY: 'ACTOR_ACTIVITY',
};

export const loaderType = {
    RECENT_ACTIVITY_AREA: 'RECENT_ACTIVITY_AREA',
    RECENT_ACTIVITY_DETAILS: 'RECENT_ACTIVITY_DETAILS',
};

export const RECENT_ACTIVITY_DETAILS_KEY = 'recentActivityDetails';

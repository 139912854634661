import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    getInitiateCall, getOnboardingStages, setVKYCStep, muteAudioStatus, setCurrentStep,
} from '../actions';
import { Button } from '../../../components';
import { selectVkycData, selectVkycAvailableCallsCount } from '../selectors';
import { FLOW_PROGRESS } from '../constants';

const CallQueueScreen = (props) => {
    const { initiateRecordingSetup, stopRecording } = props;
    const dispatch = useDispatch();
    const vkycData = useSelector(selectVkycData, shallowEqual);
    const availableCallCount = useSelector(selectVkycAvailableCallsCount);

    const setAudioMuteFlag = (flag) => {
        dispatch(muteAudioStatus(flag));
    };

    useEffect(() => {
        if (vkycData?.data?.meetingId) {
            if (vkycData?.muteAudioFlag) setAudioMuteFlag(false);
            dispatch(setVKYCStep({ step: 1 }));
            dispatch(setCurrentStep({ progress: FLOW_PROGRESS.IN_PROGRESS }));
            dispatch(getOnboardingStages({ meetingId: vkycData?.data?.meetingId, screenKey: ['meetings', vkycData?.data?.meetingId] }));
        } else if (!vkycData?.devicePermissionStatus?.status) {
            stopRecording();
        }
    }, [vkycData?.data, vkycData?.devicePermissionStatus?.status]);

    useEffect(() => {
        if (vkycData?.devicePermissionStatus?.status) {
            dispatch(getInitiateCall({ screenKey: ['calls'], flowState: FLOW_PROGRESS.IN_PROGRESS }));
        }
    }, [vkycData?.devicePermissionStatus?.status]);

    const onInitiateCall = () => {
        initiateRecordingSetup();
    };

    // eslint-disable-next-line no-unused-vars
    const [calls, setCalls] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(null);

    // To-Do: Implement WebSocket connection by @joshi008
    useEffect(() => {
        const WS_URL = 'ws://localhost:8122';
        const ws = new WebSocket(WS_URL);

        ws.onopen = () => {
            ws.send(JSON.stringify({ type: 'getAvailableCalls', payload: {/* requestPayload */} }));
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'error') {
                setError(data.message);
            } else if (data.type === 'end') {
                ws.close();
            } else {
                setCalls((prevCalls) => [...prevCalls, data]);
            }
        };

        ws.onerror = (error2) => {
            console.error('WebSocket error:', error2);
            setError('WebSocket error');
        };

        ws.onclose = () => {
            console.error('WebSocket connection closed');
        };

        return () => {
            ws.close();
        };
    }, []);

    return (
        <div className='vkyc-call-queue-screen'>
            {/* <div className='vkyc-call-queue-message-container'>
                <img src={InformationSVG} alt='' className='vkyc-call-queue-message-icon' />
                <div>As soon as customers initiate Video KYC on the app, they will be assigned to you.</div>
            </div> */}
            <Button
                primary
                v2
                label='Initiate Call'
                onClick={onInitiateCall}
                disabled={vkycData?.loading || vkycData?.data?.meetingId || availableCallCount === 0}
            />
        </div>
    );
};

export default CallQueueScreen;

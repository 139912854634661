/**
 * @file Manages the saga generator functions for transaction view container
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify, getErrMsg,
} from '../../utils';
import {
    TRANSACTION_RISK_VIEW_ENDPOINT,
    TRANSACTION_RISK_VIEW_FILTER_ENDPOINT,
} from '../../api/routes';

import {
    GET_TRANSACTION_VIEW, SET_TRANSACTION_VIEW, ERR_TRANSACTION_VIEW,
    GET_TRANSACTION_VIEW_FILTER, SET_TRANSACTION_VIEW_FILTER, ERR_TRANSACTION_VIEW_FILTER,
} from './constants';

function* getTransactionViewSaga(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTION_RISK_VIEW_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_TRANSACTION_VIEW, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TRANSACTION_VIEW, data: { err: getErrMsg(e) } });
    }
}

function* getTransactionViewFilterSaga(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTION_RISK_VIEW_FILTER_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_TRANSACTION_VIEW_FILTER, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TRANSACTION_VIEW_FILTER, data: { err: getErrMsg(e) } });
    }
}

export default function* transactionViewSaga() {
    yield all(
        [
            yield takeLatest(GET_TRANSACTION_VIEW, getTransactionViewSaga),
            yield takeLatest(GET_TRANSACTION_VIEW_FILTER, getTransactionViewFilterSaga),
        ],
    );
}

/**
 *
 * Tickets - Main entry point component to handle all the routes related logic for Tickets Section
 *
 */
import React from 'react';

import './style.scss';

const Tickets = () => (
    <div className='tickets'>
        <div className='tickets-cr frccWrapper'>Coming Soon</div>
    </div>
);

export default Tickets;

/*
 *
 * Tickets actions
 *
 */

import {
    GET_CALL_LIST, SET_SELECTED_CALL_INFO, GET_TICKET_AND_USER_INFO_FOR_CALL,
    GET_USER_INFO_FOR_CALL, USER_CONFIRMATION_FOR_CALL, GET_TICKET_LIST_FOR_CALL,
    UPDATE_CALL_INFO, CREATE_TICKET_FOR_CALL,
    SET_TICKET_ID, SUBMIT_TICKET_ID, ADD_NEW_TICKET, SET_ACTIVE_TICKET, CLOSE_TICKET,
    SET_REGISTERED_ID_FLOW, SET_USER_ID, SUBMIT_USER_ID, SET_USER_NOT_REGISTERED,
    GET_INITIAL_AUTH_FACTOR, SET_AUTH_FACTOR_VALUE, VERIFY_AUTH_FACTOR, SET_ASYNC_AUTH_INFO,
    CALLBACK_USER, SET_CALLBACK_USER_FLOW, SET_AUTH_EXPIRED_SCREEN,
    DISCARD_ACTION_AUTH_INFO, ERR_TICKET_INFO, GET_WAITLIST_USER, ATTACH_ENTITY_TICKET,
    GET_OTP_ATTEMPTS, GET_ACCOUNT_FREEZE_INFO, SET_AGENT_FEEDBACK, SET_AUTH_UPGRADE_INFO, SET_CUSTOMER_AUTH, GET_SHERLOCK_SCRIPTS_SEARCH_RESULTS,
} from './constants';

export const getCallList = (data) => ({
    type: GET_CALL_LIST,
    data,
});

export const setSelectedCallInfo = (data) => ({
    type: SET_SELECTED_CALL_INFO,
    data,
});

export const getTicketAndUserInfoForCall = (data) => ({
    type: GET_TICKET_AND_USER_INFO_FOR_CALL,
    data,
});

export const getUserInfoForCall = (data) => ({
    type: GET_USER_INFO_FOR_CALL,
    data,
});

export const userConfirmationForCall = (data) => ({
    type: USER_CONFIRMATION_FOR_CALL,
    data,
});

export const getTicketListForCall = (data) => ({
    type: GET_TICKET_LIST_FOR_CALL,
    data,
});

export const updateCallInfo = (data) => ({
    type: UPDATE_CALL_INFO,
    data,
});

export const createTicketForCall = (data) => ({
    type: CREATE_TICKET_FOR_CALL,
    data,
});

export const setTicketId = (data) => ({
    type: SET_TICKET_ID,
    data,
});

export const setErrTicketInfo = (data) => ({
    type: ERR_TICKET_INFO,
    data,
});

export const submitTicketId = (data) => ({
    type: SUBMIT_TICKET_ID,
    data,
});

export const addNewTicket = (data) => ({
    type: ADD_NEW_TICKET,
    data,
});

export const closeTicket = (data) => ({
    type: CLOSE_TICKET,
    data,
});

export const setActiveTicket = (data) => ({
    type: SET_ACTIVE_TICKET,
    data,
});

export const setRegisteredIdFlow = (data) => ({
    type: SET_REGISTERED_ID_FLOW,
    data,
});

export const setUserId = (data) => ({
    type: SET_USER_ID,
    data,
});

export const submitUserId = (data) => ({
    type: SUBMIT_USER_ID,
    data,
});

export const setUserNotRegistered = (data) => ({
    type: SET_USER_NOT_REGISTERED,
    data,
});

export const getInitialAuthFactor = (data) => ({
    type: GET_INITIAL_AUTH_FACTOR,
    data,
});

export const setAuthFactorValue = (data) => ({
    type: SET_AUTH_FACTOR_VALUE,
    data,
});

export const verifyAuthFactor = (data) => ({
    type: VERIFY_AUTH_FACTOR,
    data,
});

export const setAsyncAuthInfo = (data) => ({
    type: SET_ASYNC_AUTH_INFO,
    data,
});

export const callbackUser = (data) => ({
    type: CALLBACK_USER,
    data,
});

export const setCallbackUserFlow = (data) => ({
    type: SET_CALLBACK_USER_FLOW,
    data,
});

export const setAuthExpiredScreen = (data) => ({
    type: SET_AUTH_EXPIRED_SCREEN,
    data,
});

export const discardActionAuthInfo = (data) => ({
    type: DISCARD_ACTION_AUTH_INFO,
    data,
});

export const getWaitlistUserDetails = (data) => ({
    type: GET_WAITLIST_USER,
    data,
});

export const attachEntityToTicket = (data) => ({
    type: ATTACH_ENTITY_TICKET,
    data,
});

export const getOTPAttempts = (data) => ({
    type: GET_OTP_ATTEMPTS,
    data,
});

export const getAccountFreezeInfo = () => ({
    type: GET_ACCOUNT_FREEZE_INFO,
});

export const setAgentFeedback = (data) => ({
    type: SET_AGENT_FEEDBACK,
    data,
});

// sets isAuthUpgradeInProgress to true
export const setAuthUpgradeInfo = (data) => ({
    type: SET_AUTH_UPGRADE_INFO,
    data,
});

// set to initiate or close customer auth
export const setCustomerAuth = (data) => ({
    type: SET_CUSTOMER_AUTH,
    data,
});

export const getSherlockScriptsSearchResults = (data) => ({
    type: GET_SHERLOCK_SCRIPTS_SEARCH_RESULTS,
    data,
});

/**
 *
 * AgentManagement - Component to show the agent list & manage the agents like create, update, etc
 *
 */

import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

import { MetaInfoContext } from '../../context';
import { injectReducer, injectSaga } from '../../utils';
import { MaintenanceMode } from '../../components';

import {
    AGENT_PATH, CREATE_AGENT_PATH, EDIT_AGENT_PATH, VIEW_AGENT_PATH,
} from '../App/routes';

import AgentListing from '../AgentListing';
import AgentCreateForm from '../AgentCreateForm';
import ViewAgent from '../ViewAgent';
import CreateOrUpdateAgent from './CreateOrUpdateAgent';
import AgentInfo from './AgentInfo';
import AgentList from './AgentList';

import { AGENT_MANAGEMENT_KEY } from './constants';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const AgentManagement = () => {
    const metaInfo = useContext(MetaInfoContext);

    const {
        appConfig: {
            enableAgentManagementV2, enableAgentManagementMaintenanceMode,
        },
    } = metaInfo;

    // show Maintenance Mode component if the enableAgentManagementMaintenanceMode flag is enabled
    if (enableAgentManagementMaintenanceMode) {
        return (
            <div className='agent-mngmt-cr'>
                <MaintenanceMode />
            </div>
        );
    }

    // show old Agent Management UI if the enableAgentManagementV2 flag is disabled
    if (!enableAgentManagementV2) {
        return (
            <Switch>
                <Route
                    path={CREATE_AGENT_PATH}
                    render={(props) => <AgentCreateForm {...props} />}
                />
                <Route
                    path={EDIT_AGENT_PATH}
                    render={(props) => <AgentCreateForm {...props} isEdit />}
                />
                <Route
                    path={VIEW_AGENT_PATH}
                    component={ViewAgent}
                />
                <Route
                    path={AGENT_PATH}
                    render={(props) => <AgentListing {...props} />}
                />
            </Switch>
        );
    }

    return (
        <div className='agent-mngmt-cr'>
            <Switch>
                <Route
                    path={CREATE_AGENT_PATH}
                    component={CreateOrUpdateAgent}
                />
                <Route
                    path={EDIT_AGENT_PATH}
                    render={() => (<CreateOrUpdateAgent isEdit />)}
                />
                <Route
                    path={VIEW_AGENT_PATH}
                    component={AgentInfo}
                />
                <Route
                    path={AGENT_PATH}
                    component={AgentList}
                />
            </Switch>
        </div>
    );
};

const withReducer = injectReducer({ key: AGENT_MANAGEMENT_KEY, reducer });
const withSaga = injectSaga({ key: AGENT_MANAGEMENT_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(AgentManagement);

/**
 *
 * MergeTickets
 *
 */

import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Modal from 'react-modal';

import { Button, Loader } from '../../components';
import { injectReducer, injectSaga, validators } from '../../utils';

import { getRelatedTickets, mergeRelatedTickets } from './actions';
import { makeSelectRelatedTickets } from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content : {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 500,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    }
};

const MergeTickets = (props) => {
    const dispatch = useDispatch();
    const [primaryReason, setPrimaryReason] = useState('');
    const [secondaryReason, setSecondaryReason] = useState('');
    const [errLabel, setErrLabel] = useState('');
    const [selectedTicketId, setTicketId] = useState('');
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const relatedTickets = useSelector(makeSelectRelatedTickets(), shallowEqual);

    const { ticketId } = props;

    let loading;
    let data;

    if (relatedTickets[ticketId]) {
        ({ loading, data } = relatedTickets[ticketId]);
    }

    useEffect(() => {
        dispatch(getRelatedTickets({ ticketId }));
    }, []);

    const setSelectedTicketId = (id) => {
        if (selectedTicketId === id) {
            setTicketId('');
        } else {
            setTicketId(id);
        }
    };

    const handleInputChange = (key) => (e) => {
        const value = e.target.value;

        switch (key) {
            case 'primary':
                setPrimaryReason(value);
                break;

            case 'secondary':
                setSecondaryReason(value);
                break;

            default:
        }
    };

    const onSubmitCTA = () => {
        if (
            !validators.isInputtedStringValid(primaryReason)
            || !validators.isInputtedStringValid(secondaryReason)
        ) {
            setErrLabel('Please enter a valid note');
            return;
        }

        setErrLabel('');
        setConfirmationModal(true);
    };

    const onConfirmCTA = () => {
        setConfirmationModal(false);

        const callMergeRelatedTicketsAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(mergeRelatedTickets({
                    ticketId,
                    primaryTicket: ticketId,
                    primaryReason,
                    secondaryTicket: selectedTicketId,
                    secondaryReason,
                    resolve
                }));
            } catch (e) {
                reject(e);
            }
        });

        callMergeRelatedTicketsAction().then(() => {
            setPrimaryReason('');
            setSecondaryReason('');
            dispatch(getRelatedTickets({ ticketId }));
        });
    };

    return (
        <React.Fragment>
            <div className='mergetkt'>
                {
                    data && data.length ? (
                        <React.Fragment>
                            <div className='table-sr table-sr--mt0'>
                                <div className='table-sr__thw'>
                                    <div className='table-sr__th f05Wrapper'>Ticket ID</div>
                                    <div className='table-sr__th'>Subject</div>
                                    <div className='table-sr__th'>Status</div>
                                    <div className='table-sr__th'>Product Category</div>
                                    <div className='table-sr__th'>Product Category Details</div>
                                    <div className='table-sr__th table-sr__th--actions'>Select</div>
                                </div>
                                <div className='table-sr__tlc'>
                                    {
                                        data.map(item => (
                                            <div className='table-sr__tlw' key={item.id}>
                                                <div className='table-sr__tl f05Wrapper'>{item.id}</div>
                                                <div className='table-sr__tl'>{item.subject}</div>
                                                <div className='table-sr__tl'>{item.status}</div>
                                                <div className='table-sr__tl'>{item.productCategory}</div>
                                                <div className='table-sr__tl'>{item.productCategoryDetails}</div>
                                                <div className='table-sr__tl table-sr__tl--actions'>
                                                    <input
                                                        type='checkbox'
                                                        checked={selectedTicketId === item.id}
                                                        onChange={() => setSelectedTicketId(item.id)}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className='mergetkt-notecr'>
                                <div className='form-container fcWrapper'>
                                    <label
                                        className='form-wrapper-label mergetkt-notecr__lb'
                                        htmlFor='primary-tkt-note'
                                    >
                                        Enter Primary Ticket Note*
                                    </label>
                                    <textarea
                                        id='primary-tkt-note'
                                        className='mergetkt-notecr__ta'
                                        value={primaryReason}
                                        onChange={handleInputChange('primary')}
                                        rows={4}
                                        cols={70}
                                    />
                                </div>
                                <div className='form-container fcWrapper'>
                                    <label
                                        className='form-wrapper-label mergetkt-notecr__lb'
                                        htmlFor='secondary-tkt-note'
                                    >
                                        Enter Secondary Ticket Note*
                                    </label>
                                    <textarea
                                        id='secondary-tkt-note'
                                        className='mergetkt-notecr__ta'
                                        value={secondaryReason}
                                        onChange={handleInputChange('secondary')}
                                        rows={4}
                                        cols={70}
                                    />
                                </div>
                            </div>
                            <div className='err-label mergetkt-errlb'>{errLabel}</div>
                            <Button
                                primary
                                extClasses='mergetkt-cta'
                                label='Merge Ticket'
                                onClick={onSubmitCTA}
                                disabled={!selectedTicketId || !primaryReason || !secondaryReason}
                            />
                        </React.Fragment>  
                    ) : (
                        <div className='table-nsr'>No related tickets found!</div>
                    )
                }
            </div>
            {
                showConfirmationModal ? (
                    <Modal
                        isOpen
                        style={customStylesForModal}
                        contentLabel='Merge Tickets Confiramtion Modal'
                    >
                        <div className='payuser-modal__hl'>
                            Are you sure you want to perform this action?
                        </div>
                        <div className='frcsbWrapper payuser-modal__ctas'>
                            <Button
                                primary
                                label='Confirm'
                                onClick={onConfirmCTA}
                            />
                            <Button
                                secondary
                                label='Cancel'
                                onClick={() => setConfirmationModal(false)}
                            />
                        </div>
                    </Modal>
                ) : null
            }
            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'mergeTickets', reducer });
const withSaga = injectSaga({ key: 'mergeTickets', saga });

export default compose(
    withReducer,
    withSaga,
)(MergeTickets);

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
/**
 * UpdateURL - Component to update or delete the URL by hitting Google Instant Indexing APIs
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { isEmpty } from 'ramda';

import {
    Button, CSVReader, DropdownContent, DropdownWrapper, FormWithSingleInput, Loader, Modal, Table,
} from '../../../components';
import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';

import {
    PRIMARY_ACTION_MAP, PRIMARY_ACTION_LIST_SEARCH_PARAM, UPDATED_URL_LIST_LABEL_DATA, REQUEST_TYPE_LIST_SEARCH_PARAM,
} from '../constants';
import { updateUrl } from '../actions';
import { makeSelectLoading, makeSelectUpdatedUrlInfo } from '../selectors';

import './style.scss';

const UpdateURL = () => {
    const dispatch = useDispatch();

    // reducer state variables
    const isLoading = useSelector(makeSelectLoading(), shallowEqual);
    const updatedUrlInfo = useSelector(makeSelectUpdatedUrlInfo(), shallowEqual);

    // local state variables
    const [showPrimaryActionDD, setPrimaryActionDD] = useState(true);
    const [primaryAction, setPrimaryAction] = useState('');
    const [csvData, setCSVData] = useState('');
    const [urls, setUrls] = useState('');
    const [urlsArr, setUrlsArr] = useState([]);
    const [urlsCount, setUrlsCount] = useState(0);
    const [requestType, setRequestType] = useState('');
    const [showRequestTypeDD, setRequestTypeDD] = useState(false);
    const [showUrlsModal, setUrlsModal] = useState(false);
    const [showUpdatedUrlInfo, setUpdatedUrlInfo] = useState(false);
    const [errLabel, setErrLabel] = useState('');

    const { data, hasInfo } = updatedUrlInfo;

    const resetCSVFilledData = () => {
        setUrls('');
        setUrlsArr([]);
        setUrlsCount(0);
        setRequestType('');
    };

    useEffect(() => {
        if (csvData) {
            const csvUrlsArr = [];

            // parse csv data & set urls
            csvData.forEach((item, idx) => {
                if (idx !== 0) {
                    const itemData = item[0];

                    if (itemData) {
                        csvUrlsArr.push(itemData);
                    }
                }
            });

            setUrls(csvUrlsArr.join(','));
            setUrlsArr(csvUrlsArr);
            setUrlsCount(csvUrlsArr.length);
        } else {
            resetCSVFilledData();
        }
    }, [csvData]);

    const toggleDD = (key) => () => {
        switch (key) {
            case 'primaryAction': {
                setPrimaryActionDD(!showPrimaryActionDD);

                break;
            }

            case 'requestType': {
                setRequestTypeDD(!showRequestTypeDD);

                break;
            }

            default:
        }
    };

    const onInputChange = (key) => (e) => {
        const { value } = e.target;

        switch (key) {
            case 'urls':
                setUrls(value);
                setUrlsArr(value.split(','));
                break;

            default:
        }
    };

    const onSubmitCTA = (e) => {
        e.preventDefault();

        // check if all the fields are filled
        if (isEmpty(urls) || isEmpty(requestType)) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        setErrLabel('');

        const callUpdateUrl = () => new Promise((resolve, reject) => {
            try {
                dispatch(updateUrl({
                    urls: urlsArr,
                    requestType,
                    resolve,
                }));
            } catch (error) {
                reject(error);
            }
        });

        callUpdateUrl().then(() => {
            setUpdatedUrlInfo(true);
        });
    };

    const handleDDChange = (key, value) => {
        switch (key) {
            case 'primaryAction': {
                setPrimaryAction(value);
                break;
            }

            case 'requestType': {
                setRequestType(value);
                break;
            }

            default:
        }

        toggleDD(key)();
    };

    const onBackArrowCTA = () => {
        resetCSVFilledData();
        setPrimaryActionDD(true);
        setPrimaryAction('');
        setUpdatedUrlInfo(false);
    };

    const renderPrimaryActionForm = () => (
        <div className='frcWrapper ii-update-url-fc'>
            <div className='ii-update-url-fc__lb'>Primary Action</div>
            <DropdownWrapper
                visible={showPrimaryActionDD}
                onOutsideClickHandler={toggleDD('primaryAction')}
                extClasses={{
                    container: 'custom-dd ii-update-url-dd',
                }}
            >
                <div onClick={toggleDD('primaryAction')}>
                    {primaryAction || 'Choose...'}
                </div>
                <DropdownContent
                    visible
                    extClasses={{
                        container: ['custom-dd__cc ii-update-url-dd__cc'],
                    }}
                    extStyles={{
                        height: showPrimaryActionDD ? 98 : 0,
                        overflow: showPrimaryActionDD ? 'auto' : 'hidden',
                    }}
                >
                    {
                        PRIMARY_ACTION_LIST_SEARCH_PARAM.map((item) => (
                            <div
                                key={item}
                                className='custom-dd__cl'
                                onClick={() => handleDDChange('primaryAction', item)}
                            >
                                {item}
                            </div>
                        ))
                    }
                </DropdownContent>
            </DropdownWrapper>
        </div>
    );

    const renderUploadCSVForm = () => {
        if (primaryAction === PRIMARY_ACTION_MAP.uploadCSV) {
            const label = 'Upload URLs*';
            const sampleFilePath = '/assets/urls-sample.csv';

            return (
                <React.Fragment>
                    <div className='frfsWrapper ii-update-url-fc'>
                        <div className='ii-update-url-fc__lb'>{label}</div>
                        <CSVReader setCSVData={setCSVData} isV2Button />
                        <a
                            className='link-v2 ii-update-url-link'
                            href={sampleFilePath}
                            download='sample'
                        >
                            Download Sample Format
                        </a>
                    </div>
                    {
                        urls ? (
                            <div className='frcWrapper ii-update-url-fc'>
                                <div className='ii-update-url-fc__lb'>Successfully Uploaded{'\n'}URLs Count</div>
                                <div className='ii-update-url-label'>{urlsCount}</div>
                                <Button
                                    link
                                    label='View Uploaded Data'
                                    onClick={() => setUrlsModal(true)}
                                    extClasses='ii-update-url-link'
                                />
                            </div>
                        ) : null
                    }
                </React.Fragment>
            );
        }

        return null;
    };

    const renderEnterCSVForm = () => {
        if (primaryAction === PRIMARY_ACTION_MAP.enterCSV) {
            const label = 'Enter URLs*';
            let value;

            return (
                <FormWithSingleInput
                    disableFormSubmitOnEnter
                    label={label}
                    labelId='ii-update-url'
                    input={{
                        value,
                        onChange: onInputChange('urls'),
                        rows: 4,
                        cols: 50,
                    }}
                    extClasses={{
                        container: 'ii-update-url-fc',
                        label: 'ii-update-url-fc__lb',
                        inputField: 'ii-update-url-fc__ip',
                    }}
                />
            );
        }

        return null;
    };

    const renderRequestTypeForm = () => {
        if (primaryAction) {
            return (
                <div className='frcWrapper ii-update-url-fc'>
                    <div className='ii-update-url-fc__lb'>Request Type*</div>
                    <DropdownWrapper
                        visible={showRequestTypeDD}
                        onOutsideClickHandler={toggleDD('requestType')}
                        extClasses={{
                            container: 'custom-dd  ii-update-url-dd',
                        }}
                    >
                        <div onClick={toggleDD('requestType')}>
                            {requestType || 'Choose...'}
                        </div>
                        <DropdownContent
                            visible
                            extClasses={{
                                container: ['custom-dd__cc  ii-update-url-dd__cc'],
                            }}
                            extStyles={{
                                height: showRequestTypeDD ? 98 : 0,
                                overflow: showRequestTypeDD ? 'auto' : 'hidden',
                            }}
                        >
                            {
                                REQUEST_TYPE_LIST_SEARCH_PARAM.map((item) => (
                                    <div
                                        key={item.label}
                                        className='custom-dd__cl'
                                        onClick={() => handleDDChange('requestType', item.value)}
                                    >
                                        {item.label}
                                    </div>
                                ))
                            }
                        </DropdownContent>
                    </DropdownWrapper>
                </div>
            );
        }

        return null;
    };

    const renderSubmitCTA = () => {
        if (primaryAction) {
            return (
                <Button
                    v2
                    primary
                    label='Submit'
                    onClick={onSubmitCTA}
                    extClasses='ii-update-url-cta'
                />
            );
        }

        return null;
    };

    const renderModal = (
        <Modal
            visible={showUrlsModal}
            contentLabel='Uploaded Data Modal'
        >
            <div className='ii-update-url-modal__hl'>Uploaded Data</div>
            {
                urls.split(',').map((item) => (
                    <div className='ii-update-url-modal__hl'>{`- ${item}`}</div>
                ))
            }
            <Button
                v2
                primary
                extClasses='ii-update-url-modal__cta'
                label='Close'
                onClick={() => setUrlsModal(false)}
            />
        </Modal>
    );

    const renderUpdatedUrlDetails = () => (
        <div className='ii-update-url'>
            <div className='ii-update-url-urlinfo-hwr'>
                <img className='ii-update-url-backarrow' alt='back arrow' src={backArrowIcon} onClick={onBackArrowCTA} />
                <div className='ii-update-url-urlinfo-hwr__hd'>Updated URL Details</div>
            </div>
            {
                !isEmpty(data) ? (
                    <Table
                        // header='Failed Id List'
                        labelData={UPDATED_URL_LIST_LABEL_DATA}
                        contentData={data}
                        extClasses={{
                            container: 'ii-update-url-table-sr',
                            headerCr: 'ii-update-url-table-sr__thw',
                            contentCr: 'ii-update-url-table-sr__tlc',
                        }}
                    />
                ) : null
            }
        </div>
    );

    const renderFormInputs = () => (
        <React.Fragment>
            <div className='ii-update-url'>
                {renderPrimaryActionForm()}
                {renderUploadCSVForm()}
                {renderEnterCSVForm()}
                {renderRequestTypeForm()}
                <div className='err-label ii-update-url-el'>{errLabel}</div>
                {renderSubmitCTA()}
            </div>
            {renderModal}
            <Loader visible={isLoading} />
        </React.Fragment>
    );

    return (
        showUpdatedUrlInfo && hasInfo ? (
            renderUpdatedUrlDetails()
        ) : (
            renderFormInputs()
        )
    );
};

export default UpdateURL;

/* eslint-disable react-hooks/exhaustive-deps */

/*
 * @file Risk Rule Management
 * Page to view, create, update the details of the risk rule
 */

import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import {
    Table, Loader,
    Button, ParameterList,
} from '../../components';
import { injectReducer, injectSaga } from '../../utils';
import { UserDetailsContext } from '../../context';

import RuleInfoModal from './modals/RuleInfoModal';
import {
    selectRiskRuleQueue, selectRiskRuleLoader,
    selectRiskRuleError, selectTags, selectEnums,
} from './selectors';
import {
    RISK_RULE_KEY, PARAMETER_FILTERS_CONFIG, RULE_LABEL_DATA, getRuleForm,
    DEFAULT_FILTER_DATA, FILTER_BY_VALUE,
} from './constants';
import {
    createRuleAction, getRuleQueueAction, resetRuleAction, updateRuleAction, getTags,
} from './actions';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const RiskRule = () => {
    const dispatch = useDispatch();

    const userInfo = useContext(UserDetailsContext);
    const { basicInfo: { accessLevelInfo } } = userInfo;
    const { isRiskOpsAdmin } = accessLevelInfo;

    const tableData = useSelector(selectRiskRuleQueue, shallowEqual);
    const loading = useSelector(selectRiskRuleLoader, shallowEqual);
    const queueError = useSelector(selectRiskRuleError, shallowEqual);

    const hasEditRuleAccess = useMemo(() => isRiskOpsAdmin, [accessLevelInfo]);

    const [filterList, setFiltersList] = useState({});
    const [showRuleDetailModal, setShowRuleDetailModal] = useState(false);
    const [formDetails, setFormDetails] = useState(null);
    const [ruleInfoForm, setRuleInfoForm] = useState(getRuleForm());

    const tags = useSelector(selectTags, shallowEqual);
    const protoEnums = useSelector(selectEnums, shallowEqual);

    const makePayloadAndCallApi = (params = {}) => {
        const {
            filters,
        } = params;

        const payload = {
            filters,
        };
        dispatch(getRuleQueueAction(payload));
    };

    const handleCreateClick = () => {
        const updatedFormData = { serverDriverOptionsMap: { tags, ...protoEnums } };
        setRuleInfoForm(getRuleForm(updatedFormData));
        setShowRuleDetailModal(true);
    };

    const handleUpdateRuleClick = (formData) => () => {
        const selectedTagValue = (formData.tags || []).map((item) => ({
            label: item,
            value: item,
        }));
        const updatedFormData = { ...formData, tags: selectedTagValue, serverDriverOptionsMap: { tags, ...protoEnums } };
        setRuleInfoForm(getRuleForm(updatedFormData, true));
        setFormDetails(formData);
        setShowRuleDetailModal(true);
    };

    const onFilterSubmit = (curFilterList) => {
        const appliedFilters = {};
        const filters = {};

        if (curFilterList?.length) {
            curFilterList?.forEach((item) => {
                if (item.value && item.name) {
                    appliedFilters[item.name] = item.value;
                }
            });
        }

        const filterValueKey = FILTER_BY_VALUE[appliedFilters?.filter_by];

        if (appliedFilters?.filter_by && appliedFilters[filterValueKey]) {
            filters.filter_by = appliedFilters?.filter_by;
            filters[filterValueKey] = appliedFilters[filterValueKey];
        }

        setFiltersList(filters);
        makePayloadAndCallApi({
            filters,
        });
    };

    const handleRefreshClick = () => {
        makePayloadAndCallApi({
            filters: filterList,
        });
    };

    const handleCloseModal = () => {
        setShowRuleDetailModal(false);
        setFormDetails(null);
    };

    const onSubmitForm = (ruleInfo) => {
        const payload = {};
        ruleInfo?.forEach((item) => {
            payload[item.name] = item.value;
        });

        const callExecuteAction = () => new Promise((resolve, reject) => {
            try {
                if (formDetails?.id) {
                    payload.id = formDetails?.id;

                    dispatch(updateRuleAction({
                        filters: filterList,
                        payload,
                        resolve,
                    }));
                } else {
                    dispatch(createRuleAction({
                        filters: filterList,
                        payload,
                        resolve,
                    }));
                }
            } catch (e) {
                reject(e);
            }
        });

        callExecuteAction().then(() => {
            handleCloseModal();
        });
    };

    const renderActions = (data) => (
        <div className='frfscWrapper'>
            <Button
                link
                label='Edit'
                extClasses='rr-row-action-btn'
                onClick={handleUpdateRuleClick(data)}
            />
        </div>
    );

    useEffect(() => {
        makePayloadAndCallApi();
        dispatch(getTags());
        return () => {
            dispatch(resetRuleAction());
        };
    }, []);

    return (
        <div className='risk-rule-wrapper'>
            <div className='rr'>
                <ParameterList
                    config={PARAMETER_FILTERS_CONFIG}
                    data={DEFAULT_FILTER_DATA}
                    onSubmit={onFilterSubmit}
                />
                <div className='frcsbWrapper mt-30'>
                    <div className='heading1'>File Entries</div>
                    <div className='frcsbWrapper fgap-col-10'>
                        {hasEditRuleAccess ? <Button v2 primary label='Create Rule' onClick={handleCreateClick} /> : null}
                        <Button v2 secondary label='Refresh' onClick={handleRefreshClick} />
                    </div>
                </div>
            </div>
            <div className='risk-rule'>
                <Table
                    v2
                    enableScrollView
                    labelData={RULE_LABEL_DATA}
                    renderActionableColumn={hasEditRuleAccess ? renderActions : null}
                    contentData={tableData?.rowData || []}
                    errLabel={queueError}
                />
            </div>
            <RuleInfoModal
                visible={showRuleDetailModal}
                closeCta={handleCloseModal}
                submitCta={onSubmitForm}
                ruleInfoForm={ruleInfoForm}
            />

            <Loader visible={loading} />
        </div>
    );
};

const withReducer = injectReducer({ key: RISK_RULE_KEY, reducer });
const withSaga = injectSaga({ key: RISK_RULE_KEY, saga });

export default compose(withReducer, withSaga)(RiskRule);

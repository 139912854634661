/**
 * @file Risk popup Modal
 * Modal to view risk screener check details
 * A details can be failed or incorrect data like name, phone number, email etc..
 */

import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';

import {
    Button, ExpandableCard, KVPairInfo, Loader, Table,
} from '../../../components';
import { isNilOrEmpty } from '../../../utils';
import { useMemoizedSelector } from '../../../hooks';

import { getScreenerCheckDetailsAction } from '../actions';
import { selectScreenerCheckDetails } from '../selectors';
import '../style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '32px 32px 24px',
        transform: 'translate(-50%,-50%)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        maxHeight: '80%',
        minWidth: 'max-content',
        width: '1000px',
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const RiskPopupModal = (props) => {
    const { resultId, onClose } = props;

    const sectionData = useMemoizedSelector(selectScreenerCheckDetails, [resultId]);

    const {
        ui_options: uiOptions, data, loading, error,
    } = sectionData || {};
    const { header = '', position = '' } = uiOptions || {};
    const { info_data: infoData = null, table = null } = data || {};

    const heading = position ? header : `${position}. ${header}`;

    const isEmpty = isNilOrEmpty(infoData) && isNilOrEmpty(table);

    const dispatch = useDispatch();

    const handleOnClose = () => {
        onClose();
    };

    useEffect(() => {
        // resultId is to get the screener check details
        if (resultId) {
            dispatch(getScreenerCheckDetailsAction({ resultId }));
        }
    }, [dispatch, resultId]);

    return (
        <Modal
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            isOpen={!!resultId}
            style={customStylesForModal}
            onRequestClose={handleOnClose}
        >
            <div className='urr-rpopup-container'>
                <div className='body'>
                    {
                        !isEmpty
                            ? (
                                <ExpandableCard
                                    isExpanded
                                    version={2}
                                    heading={heading}
                                    isCollapsible={false}
                                    extClasses='urr-expandablecard'
                                    extHeadingClasses='p-20'
                                    extChildElementsClass='p-0-20-20'
                                >
                                    {!isNilOrEmpty(infoData) && (
                                        <KVPairInfo
                                            data={infoData}
                                            extClasses={{
                                                container: 'urr-kv-cr urr-kv-mh m-0',
                                                contentLabel: 'urr-kv-cl',
                                                contentValue: 'urr-kv-cv',
                                                contentWr: 'urr-kv-cw',
                                            }}
                                        />
                                    )}
                                    {!isNilOrEmpty(table) && (
                                        <Table
                                            v2
                                            labelData={table?.columnData}
                                            contentData={table?.rowData || []}
                                            extClasses={{
                                                headerCr: 'urr-table-header',
                                                contentCr: 'urr-rpopup-table-content',
                                            }}
                                        />
                                    )}
                                </ExpandableCard>
                            )
                            : null
                    }

                    {
                        (!loading) && (error || isEmpty)
                            ? <div className='err-label1'>{error || 'No Records Found'}</div>
                            : null
                    }

                    <Loader visible={!!loading} />
                </div>
                <div className='footer'>
                    <Button
                        v2
                        primary
                        label='Close'
                        onClick={handleOnClose}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default RiskPopupModal;

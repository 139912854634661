/**
 * @file Manages the reducer state & function for Agent Management component
 */

import { fromJS } from 'immutable';
import {
    SET_ACCESS_LEVEL_LIST, SET_AGENT_TYPE_LIST, SET_AGENT_STATUS_LIST, GET_AGENT_LIST, SET_AGENT_LIST,
    ERR_AGENT_LIST, SET_AGENT_INFO, SET_AGENT_FORM_FIELDS, RESET_AGENT_FORM_FIELDS, AGENT_FORM_FIELDS,
    NAME_FIELD_ARR, CREATE_AGENT, UPDATE_AGENT, SET_CREATE_OR_UPDATE_AGENT_LOADER,
} from './constants';

const initialState = fromJS({
    accessLevelList: [],
    agentTypeList: [],
    agentStatusList: [],
    agentList: {
        loading: false,
        err: '',
    },
    agentInfo: {},
    agentFormFields: {
        [AGENT_FORM_FIELDS.EMAIL_ID]: '',
        [AGENT_FORM_FIELDS.PHONE_NUMBER]: '',
        [AGENT_FORM_FIELDS.FULL_NAME]: {
            firstName: '',
            middleName: '',
            lastName: '',
        },
        [AGENT_FORM_FIELDS.AGENT_TYPE]: '',
        [AGENT_FORM_FIELDS.AGENT_STATUS]: '',
        [AGENT_FORM_FIELDS.ACCESS_LEVEL]: [],
        [AGENT_FORM_FIELDS.OZONETEL_ID]: '',
    },
    createOrUpdateAgent: {
        loading: false,
    },
});

function agentManagementReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ACCESS_LEVEL_LIST: {
            const { accessLevelList } = action.data;

            return state.set('accessLevelList', fromJS(accessLevelList));
        }

        case SET_AGENT_TYPE_LIST: {
            const { agentTypeList } = action.data;

            return state.set('agentTypeList', fromJS(agentTypeList));
        }

        case SET_AGENT_STATUS_LIST: {
            const { agentStatusList } = action.data;

            return state.set('agentStatusList', fromJS(agentStatusList));
        }

        case GET_AGENT_LIST:
            return state
                .setIn(['agentList', 'loading'], true)
                .setIn(['agentList', 'err'], '');

        case SET_AGENT_LIST: {
            const { agentList: { data, prev, next } } = action.data;

            return state
                .setIn(['agentList', 'loading'], false)
                .setIn(['agentList', 'data'], fromJS(data))
                .setIn(['agentList', 'prev'], fromJS(prev))
                .setIn(['agentList', 'next'], fromJS(next));
        }

        case ERR_AGENT_LIST: {
            const { err } = action.data;

            return state
                .setIn(['agentList', 'loading'], false)
                .setIn(['agentList', 'err'], err)
                .setIn(['agentList', 'data'], fromJS([]))
                .setIn(['agentList', 'prev'], fromJS({}))
                .setIn(['agentList', 'next'], fromJS({}));;
        }

        case SET_AGENT_INFO: {
            return state.set('agentInfo', fromJS(action.data));
        }

        case SET_AGENT_FORM_FIELDS: {
            const { key, value } = action.data;

            // need to set agent form fields with the whole action data
            if (!key) {
                return state.set('agentFormFields', fromJS(action.data));
            }
            
            const agentFormFields = state.get('agentFormFields').toJS();
            const modifiedAgentFormFields = { ...agentFormFields };

            if (NAME_FIELD_ARR.includes(key)) {
                modifiedAgentFormFields[AGENT_FORM_FIELDS.FULL_NAME] = {
                    ...modifiedAgentFormFields[AGENT_FORM_FIELDS.FULL_NAME],
                    [key]: value,
                };
            } else {
                modifiedAgentFormFields[key] = value;
            }

            return state.set('agentFormFields', fromJS(modifiedAgentFormFields));
        }

        case RESET_AGENT_FORM_FIELDS: {
            const { agentFormFields } = initialState.toJS();

            return state.set('agentFormFields', fromJS(agentFormFields));
        }

        case CREATE_AGENT:
        case UPDATE_AGENT:
            return state.setIn(['createOrUpdateAgent', 'loading'], true);

        case SET_CREATE_OR_UPDATE_AGENT_LOADER: {
            const { loading } = action.data;

            return state.setIn(['createOrUpdateAgent', 'loading'], loading);
        }

        default:
            return state;
    }
}

export default agentManagementReducer;

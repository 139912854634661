/**
 * @file Manages the saga generator functions for Issue config
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    GET_FILTER_OPTIONS_ENDPOINT, GET_ISSUE_CONFIG_ENDPOINT, GET_ISSUE_CONFIG_HISTORY_ENDPOINT, UPDATE_ISSUE_CONFIG_ENDPOINT,
} from '../../api/routes';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify,
} from '../../utils';

import {
    ERR_FILTER_OPTIONS, ERR_ISSUE_CONFIG, ERR_ISSUE_CONFIG_HISTORY, ERR_UPDATE_ISSUE_CONFIG, GET_FILTER_OPTIONS, GET_ISSUE_CONFIG,
    GET_ISSUE_CONFIG_HISTORY, SET_FILTER_OPTIONS, SET_ISSUE_CONFIG, SET_ISSUE_CONFIG_HISTORY, SET_UPDATE_ISSUE_CONFIG, UPDATE_ISSUE_CONFIG,
} from './constants';

function* getIssueConfig(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_ISSUE_CONFIG_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_ISSUE_CONFIG, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ISSUE_CONFIG, data: { err: e.message } });
    }
}

function* getFilterOptions(action) {
    const queryString = getQueryStringFromObject(action.data || {});

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_FILTER_OPTIONS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_FILTER_OPTIONS, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_FILTER_OPTIONS, data: err });
    }
}

function* getIssueConfigHistory(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_ISSUE_CONFIG_HISTORY_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_ISSUE_CONFIG_HISTORY, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ISSUE_CONFIG_HISTORY, data: { err: e.message } });
    }
}

function* updateIssueConfig(action) {
    const {
        ticketId, userId, fileContent,
    } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.put],
            `${UPDATE_ISSUE_CONFIG_ENDPOINT}?${queryString}`,
            { fileContent },
        );
        toastify(response?.message, 'success');

        yield put({ type: SET_UPDATE_ISSUE_CONFIG, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_UPDATE_ISSUE_CONFIG, data: { err: e.message } });
    }
}

export default function* issueConfigSaga() {
    yield all(
        [
            yield takeLatest(GET_ISSUE_CONFIG, getIssueConfig),
            yield takeLatest(GET_FILTER_OPTIONS, getFilterOptions),
            yield takeLatest(GET_ISSUE_CONFIG_HISTORY, getIssueConfigHistory),
            yield takeLatest(UPDATE_ISSUE_CONFIG, updateIssueConfig),
        ],
    );
}

/**
 *
 * AgentListing
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import ShowTable from '../../components/ShowTable';
import Button from '../../components/Button';
import Loader from '../../components/Loader';

import toastify from '../../utils/toastify';
import injectReducer from '../../utils/injectReducer';
import { getAgentList, makeRequest, removeAgent } from '../../api/requestFunctions';
import { CREATE_AGENT_PATH, AGENT_PATH } from '../App/routes';

import makeSelectAgentListing from './selectors';
import reducer from './reducer';
import { AGENT_LIST_HEADERS } from './constants';

import './style.scss';


class AgentListing extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			agentList: [],
			isLoading: false,
			error: false,
			isRemovingAgent: false,
		};
	}

	componentDidMount = () => {
		this.setState({ isLoading: true }, this.loadAgentList());
	}

	loadAgentList = () => {
		makeRequest(getAgentList(), {
			onSuccess: (response) => {
				this.setState({
					isLoading: false,
					agentList: response.filter(item => item.agent_status === 'ACTIVE') });
			},
			onError: (error) => {
				console.log(error);
				this.setState({ isLoading: false, error: error });
				toastify('Failed to load Agent List', 'error');
			}
		})
	}

	deleteAgent = (email) => {
		const { agentList } = this.state;

		this.setState({ isRemovingAgent: true });

		makeRequest(removeAgent(email), {
			onSuccess: () => {
				this.setState(prevState => ({
					isRemovingAgent: false,
					agentList: prevState.agentList.filter(item => item.email_id !== email)
				}), () => {
					toastify('Agent Removed Successfully', 'success');
				});
			},
			onError: (error) => {
				this.setState({ isRemovingAgent: false });

				toastify('Failed to remove agent', 'error');
			}
		});
	}

	renderActions = (row) => {
		const { history } = this.props;

		return (
			<div className='action-list'>
				<Link className='action-item' to={`${AGENT_PATH}${row.email_id}`}>View</Link>
				<div className='action-separator'>|</div>
				<div className='action-item' onClick={() => history.push(`${AGENT_PATH}${row.email_id}/edit`)}>Edit</div>
				<div className='action-separator'>|</div>
				<div className='action-item' onClick={() => this.deleteAgent(row.email_id)}>Remove</div>
			</div>
		);
	}

	render() {
		const { isLoading, error, agentList, isRemovingAgent } = this.state;
		const { history } = this.props;

		return (
			<React.Fragment>
				<div className='agent-listing-wrapper'>
					<div className='header-bar'>
						<h2>{'Agent List'}</h2>
						<Button primary onClick={() => history.push(CREATE_AGENT_PATH)} label='Create New' />
					</div>
					{isLoading && 'Loading...'}
					{error && 'Failed to load Agent List'}
					{!isLoading && !error && agentList && agentList.length === 0 && 'No Data'}
					{agentList && agentList.length > 0 && <ShowTable
						headers={AGENT_LIST_HEADERS}
						data={agentList}
						appendToRow={this.renderActions}
					/>}
				</div>
				<Loader visible={isLoading || isRemovingAgent} />
			</React.Fragment>
		);
	}
}

AgentListing.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
	agentlisting: makeSelectAgentListing(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'agentListing', reducer });

export default compose(
	withReducer,
	withConnect,
)(AgentListing);

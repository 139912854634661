/**
 * @file Manages the reducer state & function for Pre Approved Loans component
 */

import { fromJS } from 'immutable';

import {
    GET_USER_INFO, SET_USER_INFO, ERR_USER_INFO,
    GET_LOANS_INFO, SET_LOANS_INFO, ERR_LOANS_INFO, GET_LAMF_USER_INFO, SET_LAMF_USER_INFO,
    ERR_LAMF_USER_INFO, GET_LAMF_LOANS_INFO, SET_LAMF_LOANS_INFO, ERR_LAMF_LOANS_INFO, PRE_APPROVED_LOANS_KEY,
    LOAN_AGAINST_MUTUAL_FUNDS_KEY, GET_FORECLOSURE_AMOUNT, SET_FORECLOSURE_AMOUNT, ERR_FORECLOSURE_AMOUNT,
    GET_LAMF_LOANS_ADDITIONAL_INFO, SET_LAMF_LOANS_ADDITIONAL_INFO, ERR_LAMF_LOANS_ADDITIONAL_INFO,
} from './constants';

const initialState = fromJS({
    [PRE_APPROVED_LOANS_KEY]: {
        userInfo: {},
        loanInfo: {},
    },
    [LOAN_AGAINST_MUTUAL_FUNDS_KEY]: {
        userInfo: {},
        loanInfo: {},
        additionalDetails: {},
    },
});

// eslint-disable-next-line default-param-last
function preApprovedLoansReducer(state = initialState, action) {
    switch (action.type) {
        case GET_USER_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn([PRE_APPROVED_LOANS_KEY, 'userInfo', ticketId, 'loading'], true)
                .setIn([PRE_APPROVED_LOANS_KEY, 'userInfo', ticketId, 'err'], '');
        }

        case SET_USER_INFO: {
            const { ticketId, userInfo } = action.data;

            return state
                .setIn([PRE_APPROVED_LOANS_KEY, 'userInfo', ticketId, 'loading'], false)
                .setIn([PRE_APPROVED_LOANS_KEY, 'userInfo', ticketId, 'hasData'], true)
                .setIn([PRE_APPROVED_LOANS_KEY, 'userInfo', ticketId, 'data'], fromJS(userInfo));
        }

        case ERR_USER_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn([PRE_APPROVED_LOANS_KEY, 'userInfo', ticketId, 'loading'], false)
                .setIn([PRE_APPROVED_LOANS_KEY, 'userInfo', ticketId, 'hasData'], false)
                .setIn([PRE_APPROVED_LOANS_KEY, 'userInfo', ticketId, 'data'], fromJS({}))
                .setIn([PRE_APPROVED_LOANS_KEY, 'userInfo', ticketId, 'err'], err);
        }

        case GET_LOANS_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'loading'], true)
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'err'], '');
        }

        case SET_LOANS_INFO: {
            const {
                ticketId, loanDetails,
            } = action.data;

            return state
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'loading'], false)
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'hasData'], true)
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'data'], fromJS(loanDetails))
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'err'], '');
        }

        case ERR_LOANS_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'loading'], false)
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'hasData'], false)
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'err'], err);
        }

        case GET_FORECLOSURE_AMOUNT: {
            const { ticketId, idx } = action.data;

            return state
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'loading'], true)
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'data', idx, 'foreclosureAmountErr'], '');
        }

        case SET_FORECLOSURE_AMOUNT: {
            const {
                ticketId, foreclosureAmount, idx,
            } = action.data;

            return state
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'loading'], false)
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'data', idx, 'foreclosureAmount'], foreclosureAmount)
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'data', idx, 'foreclosureAmountErr'], '');
        }

        case ERR_FORECLOSURE_AMOUNT: {
            const { ticketId, err, idx } = action.data;

            return state
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'loading'], false)
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'data', idx, 'foreclosureAmount'], '-')
                .setIn([PRE_APPROVED_LOANS_KEY, 'loanInfo', ticketId, 'data', idx, 'foreclosureAmountErr'], err);
        }

        case GET_LAMF_USER_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'userInfo', ticketId, 'loading'], true)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'userInfo', ticketId, 'err'], '')
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'userInfo', ticketId, 'data'], fromJS({}));
        }

        case SET_LAMF_USER_INFO: {
            const { ticketId, components } = action.data;

            return state
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'userInfo', ticketId, 'loading'], false)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'userInfo', ticketId, 'hasData'], true)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'userInfo', ticketId, 'data'], fromJS(components));
        }

        case ERR_LAMF_USER_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'userInfo', ticketId, 'loading'], false)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'userInfo', ticketId, 'hasData'], false)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'userInfo', ticketId, 'data'], fromJS({}))
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'userInfo', ticketId, 'err'], err);
        }

        case GET_LAMF_LOANS_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'loanInfo', ticketId, 'loading'], true)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'loanInfo', ticketId, 'err'], '')
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'loanInfo', ticketId, 'data'], fromJS({}));
        }

        case SET_LAMF_LOANS_INFO: {
            const {
                ticketId, components,
            } = action.data;

            return state
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'loanInfo', ticketId, 'loading'], false)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'loanInfo', ticketId, 'hasData'], true)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'loanInfo', ticketId, 'data'], fromJS(components))
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'loanInfo', ticketId, 'err'], '');
        }

        case ERR_LAMF_LOANS_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'loanInfo', ticketId, 'loading'], false)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'loanInfo', ticketId, 'hasData'], false)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'loanInfo', ticketId, 'data'], fromJS({}))
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'loanInfo', ticketId, 'err'], err);
        }

        case GET_LAMF_LOANS_ADDITIONAL_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'additionalDetails', ticketId, 'loading'], true)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'additionalDetails', ticketId, 'err'], '')
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'additionalDetails', ticketId, 'data'], fromJS({}));
        }

        case SET_LAMF_LOANS_ADDITIONAL_INFO: {
            const {
                ticketId, components,
            } = action.data;

            return state
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'additionalDetails', ticketId, 'loading'], false)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'additionalDetails', ticketId, 'hasData'], true)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'additionalDetails', ticketId, 'data'], fromJS(components))
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'additionalDetails', ticketId, 'err'], '');
        }

        case ERR_LAMF_LOANS_ADDITIONAL_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'additionalDetails', ticketId, 'loading'], false)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'additionalDetails', ticketId, 'hasData'], false)
                .setIn([LOAN_AGAINST_MUTUAL_FUNDS_KEY, 'additionalDetails', ticketId, 'err'], err);
        }

        default:
            return state;
    }
}

export default preApprovedLoansReducer;

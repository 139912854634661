import { toastify } from '../../../utils';

export const isOutlookCredentialsAdded = () => {
    const outlookData = localStorage.getItem('outlookURL');
    const LokiAuthToken = localStorage.getItem('LokiAuthToken');
    if (outlookData && LokiAuthToken) return true;
    return false;
};

/**
 * outlookAuthScript : This is custom script which used to extract outlook credentials require to fetch linkedin profile of logged in user.
 */

// eslint-disable-next-line no-script-url
export const outlookAuthScript = `javascript:(function () {
    const originalOpen = XMLHttpRequest.prototype.open;
    const originalSend = XMLHttpRequest.prototype.send;

    XMLHttpRequest.prototype.open = function (...args) {
        originalOpen.apply(this, args);

        this.addEventListener('readystatechange', function () {
            if (this.readyState === 4) {
                if (Array.isArray(args) && args[0] === 'POST' && typeof (args[1]) === 'string' && args[1].includes('/api/v3/personacards')) {
                    navigator.clipboard.writeText(JSON.stringify({
                        outlookURL: args[1],
                        LokiAuthToken: window.sessionStorage.LokiAuthToken,
                    }));

                    navigator.clipboard.readText().then((outlookCred) => {
                        const { outlookURL, LokiAuthToken } = JSON.parse(outlookCred);
                        localStorage.setItem('outlookURL', outlookURL);
                        localStorage.setItem('LokiAuthToken', LokiAuthToken);
                        alert('done');
                        window.open('', '_self').close();
                    }).catch(() => {
                        alert('Unable to fetch Credentials, please try again add repeat the steps');
                        window.open('', '_self').close();
                    });
                }
            }
        });
    };
    XMLHttpRequest.prototype.send = function (body) {
        originalSend.call(this, body);
    };
    alert('Script has been added successfully');
})()`;

export const setCredentials = async (refreshCallback) => {
    try {
        const outlookCred = await navigator.clipboard.readText();
        const { outlookURL, LokiAuthToken } = JSON.parse(outlookCred);
        localStorage.setItem('outlookURL', outlookURL);
        localStorage.setItem('LokiAuthToken', LokiAuthToken);
        toastify('Credentials has been added successfully', 'success');
        refreshCallback();
    } catch {
        toastify('unable to get credential, please try again', 'error');
    }
};

export const removeCredential = (refreshCallback) => {
    localStorage.removeItem('outlookURL');
    localStorage.removeItem('LokiAuthToken');
    if (typeof (refreshCallback) === 'function') refreshCallback();
};

/**
 * @file Manages the constants for Tiering component
 */

import { getInitialDateRange } from '../../utils';
import { getDateRange } from './utils';

// key to be used for reducers & selectors for tiering
export const TIERING_KEY = 'tiering';

// tiering details of user
export const GET_TIERING_DETAILS = `app/${TIERING_KEY}/GET_TIERING_DETAILS`;
export const SET_TIERING_DETAILS = `app/${TIERING_KEY}/SET_TIERING_DETAILS`;
export const ERR_TIERING_DETAILS = `app/${TIERING_KEY}/ERR_TIERING_DETAILS`;

export const SET_TIERING_GRACE = `app/${TIERING_KEY}/SET_TIERING_GRACE`;
export const ERR_TIERING_GRACE = `app/${TIERING_KEY}/ERR_TIERING_GRACE`;

export const SET_TIERING_COOLOFF = `app/${TIERING_KEY}/SET_TIERING_COOLOFF`;
export const ERR_TIERING_COOLOFF = `app/${TIERING_KEY}/ERR_TIERING_COOLOFF`;

export const GET_TIERING_SENSITIVE_DETAILS = `app/${TIERING_KEY}/GET_TIERING_SENSITIVE_DETAILS`;
export const SET_TIERING_SENSITIVE_DETAILS = `app/${TIERING_KEY}/SET_TIERING_SENSITIVE_DETAILS`;
export const ERR_TIERING_SENSITIVE_DETAILS = `app/${TIERING_KEY}/ERR_TIERING_SENSITIVE_DETAILS`;

export const TIERING_TABLE_LABEL_DATA = [
    {
        label: 'Id',
        key: 'id',
    },
    {
        label: 'From Plan',
        key: 'fromTierPlanName',
    },
    {
        label: 'To Plan',
        key: 'toTierPlanName',
    },
    {
        label: 'Movement Type',
        key: 'movementType',
    },
    {
        label: 'Plan Start Date',
        key: 'planStart',
    },
    {
        label: 'Plan End Date',
        key: 'planEnd',
    },
];

export const INITIAL_DATE_RANGE = getInitialDateRange();
export const DATE_RANGE = getDateRange();

// form constants
export const EXTEND_TO_DATE_INFO = {
    label: 'Extend To Date*',
    labelId: 'tiering-extend-to-date',
};

export const customTieringModalStyles = {
    content: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 16,
        height: 400,
    },
};

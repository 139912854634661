/**
 * @file Selectors for US Stocks flow
 */

import { createSelector } from 'reselect';

import { US_STOCKS_KEY } from './constants';

// API data selectors
export const selectUSStocksNavBarInfo = createSelector(
    (state) => state.getIn([US_STOCKS_KEY, 'navBar']),
    (subState) => subState.toJS(),
);

export const selectUSStocksFileEntries = createSelector(
    (state) => state.getIn([US_STOCKS_KEY, 'fileEntries']),
    (subState) => subState.toJS(),
);

export const selectUSStocksAdditionalActions = createSelector(
    (state) => state.getIn([US_STOCKS_KEY, 'additionalActions']),
    (subState) => subState.toJS(),
);

export const selectUSStocksTableFilters = createSelector(
    (state) => state.getIn([US_STOCKS_KEY, 'parameterList']),
    (subState) => subState.toJS(),
);

export const selectUSStocksGeneratedFileData = createSelector(
    (state) => state.getIn([US_STOCKS_KEY, 'generatedFileData']),
    (subState) => subState.toJS(),
);

// UI selectors
export const selectUSStocksLoader = createSelector(
    (state) => state.getIn([US_STOCKS_KEY, 'loading']),
    (subState) => subState,
);

export const selectUSStocksError = createSelector(
    (state) => state.getIn([US_STOCKS_KEY, 'err']),
    (subState) => subState,
);

export const selectUSStocksScreenActionData = createSelector(
    (state) => state.getIn([US_STOCKS_KEY, 'screenActionData']),
    (subState) => subState.toJS(),
);

export const selectUSStocksCurView = createSelector(
    (state) => state.getIn([US_STOCKS_KEY, 'curView']),
    (subState) => subState,
);

export const selectUSStocksFileType = createSelector(
    (state) => state.getIn([US_STOCKS_KEY, 'fileType']),
    (subState) => subState,
);

/**
 * @file TableDetails component for Credit Card.
 */

import React, { memo } from 'react';

import { Table } from '../../../components';

const TableDetails = (props) => {
    const {
        heading, data, renderActionableColumn,
        nsrLabel, enableScrollView, hideActionHeader,
    } = props;

    return (
        <React.Fragment>
            {
                heading
                    ? <div className='heading4 mb-15 mt-15'>{heading}</div>
                    : null
            }
            <Table
                v2
                enableScrollView={enableScrollView}
                labelData={data?.columnData}
                contentData={data?.rowData || []}
                hideActionHeader={hideActionHeader}
                renderActionableColumn={data?.actions?.length ? renderActionableColumn : null}
                header=''
                nsrLabel={nsrLabel}
            />
        </React.Fragment>
    );
};

export default memo(TableDetails);

/**
 *
 * TicketList
 *
 */

import React, { memo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Pagination } from '../../../components';

import { getTicketList, getTicketNotes } from '../actions';
import { makeSelectTicketList } from '../selectors';

import '../style.scss';

const TicketList = () => {
    const dispatch = useDispatch();

    const ticketList = useSelector(makeSelectTicketList(), shallowEqual);

    const getPaginatedTicketList = ({ prev, next }) => {
        dispatch(getTicketList({ prev, next }));
    };

    const { data, prev, next } = ticketList;

    if (data.length === 0) {
        return (
            <div className='fdags-nosr'>No results found!</div>
        );
    }

    return (
        <div className='fdags'>
            <div className='table-sr table-sr--mt0 fdags-table'>
                <div className='table-sr__thw fdags-table__thw'>
                    <div className='table-sr__th f05Wrapper'>Ticket ID</div>
                    <div className='table-sr__th'>Subject</div>
                    <div className='table-sr__th f05Wrapper'>Type</div>
                    <div className='table-sr__th'>Status</div>
                    <div className='table-sr__th f05Wrapper'>Priority</div>
                    <div className='table-sr__th'>Product Category</div>
                    <div className='table-sr__th'>Product Category Details</div>
                    <div className='table-sr__th f05Wrapper'>Creation Date</div>
                    <div className='table-sr__th'>Actions</div>
                </div>
                <div className='table-sr__tlc fdags-table__tlc'>
                    {
                        data.map(item => (
                            <div className='table-sr__tlw table-sr__tlw--aic' key={item.ticketId}>
                                <div className='table-sr__tl f05Wrapper'>{item.ticketId}</div>
                                <div className='table-sr__tl'>{item.subject}</div>
                                <div className='table-sr__tl f05Wrapper'>{item.type}</div>
                                <div className='table-sr__tl'>{item.status}</div>
                                <div className='table-sr__tl f05Wrapper'>{item.priority}</div>
                                <div className='table-sr__tl table-sr__tl--cc'>{item.productCategory}</div>
                                <div className='table-sr__tl table-sr__tl--cc'>{item.productCategoryDetails}</div>
                                <div className='table-sr__tl f05Wrapper'>{item.createdTs}</div>
                                <div className='table-sr__th'>
                                    <div className='link' onClick={() => dispatch(getTicketNotes({ ticket: item }))}>
                                        View Details
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <Pagination prev={prev} next={next} onClickHandler={getPaginatedTicketList} />
        </div>
    );
};

export default memo(TicketList);

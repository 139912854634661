/**
 * @file RecentActivity/constants.js - Contains constants used for recent activity
 */
export const RECENT_ACTIVITY_ROOT_REDUX = 'recentActivity';

export const RECENT_ACTIVITY_DATA = 'recentActivityData';

export const GET_RECENT_ACTIVITY = 'app/RecentActivity/GET_RECENT_ACTIVITY';
export const SET_RECENT_ACTIVITY = 'app/RecentActivity/SET_RECENT_ACTIVITY';
export const ERR_RECENT_ACTIVITY = 'app/RecentActivity/ERR_RECENT_ACTIVITY';

export const GET_RECENT_ACTIVITY_DETAILS = 'app/RecentActivity/GET_RECENT_ACTIVITY_DETAILS';
export const SET_RECENT_ACTIVITY_DETAILS = 'app/RecentActivity/SET_RECENT_ACTIVITY_DETAILS';
export const ERR_RECENT_ACTIVITY_DETAILS = 'app/RecentActivity/ERR_RECENT_ACTIVITY_DETAILS';

export const defaultDropdownOption = [{
    label: 'ALL',
    value: 'All',
}];

export const EntityType = {
    ENTITY_TYPE_UNSPECIFIED: 'ENTITY_TYPE_UNSPECIFIED',
    TRANSACTION: 'TRANSACTION',
    SAVINGS: 'SAVINGS',
    REWARDS: 'REWARDS',
    ACTOR_ACTIVITY: 'ACTOR_ACTIVITY',
};

export const RECENT_ACTIVITY_DETAILS_KEY = 'recentActivityDetails';

/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @file Renders one section of a risk component
 * Each risk section can have:
 * 1. Information in key-value format
 * 2. Any media units like images, videos, etc.
 * 3. Actions like dev-actions
 */

import React, {
    memo, useMemo, useContext, useEffect, useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, ExpandableCard, KVPairInfo, ParameterList, Table,
} from '../../../components';
import { isNilOrEmpty } from '../../../utils';
import { useMemoizedSelector } from '../../../hooks';
import { UserDetailsContext } from '../../../context';

import { selectRiskOpsCurCase } from '../../RiskOps/selectors';
import { getDataFromLivenessCase, getPreFilledValuesObj } from '../../RiskOps/utils';

import DevActions from '../../DevActions';

import { selectUserRiskReviewForm, selectLinkedInUrl, selectLinkedInProfileLoading } from '../selectors';
import { getFormFieldsAction, submitAnnotationAction, getLinkedInProfileUrl } from '../actions';
import {
    USER_RISK_REVIEW_KEY as CONTAINER_KEY,
    MEDIA_TYPE_IMAGE,
    PARAMETER_FILTERS_CONFIG,
    SCREEN_NAME_RISK_SCREENER_ADDITIONAL_DETAILS_POPUP,
} from '../constants';
import RenderMediaV2 from '../RenderMediaV2';
import RenderMedia from '../RenderMedia';
import RiskPopupModal from '../RiskPopupModal';
import '../style.scss';
import { getLinkedProfilePayload } from '../utils';

const LinkedInUrlContainer = (props) => {
    const { actorMailId } = props;
    const dispatch = useDispatch();
    const linkedInUrlMapping = useSelector(selectLinkedInUrl, shallowEqual) || {};
    const linkedProfileLoading = useSelector(selectLinkedInProfileLoading, shallowEqual) || false;
    const [isLinkedinUrlRequested, setLinkedinUrlStatus] = useState(false);

    useEffect(() => {
        if (isLinkedinUrlRequested && linkedInUrlMapping[actorMailId]) window.open(linkedInUrlMapping[actorMailId]);
    }, [linkedInUrlMapping, linkedProfileLoading]);

    const loadLinkedInUrl = () => {
        const requestPayload = getLinkedProfilePayload(actorMailId);
        if (requestPayload && !(linkedInUrlMapping[actorMailId] || '').includes('linkedin')) dispatch(getLinkedInProfileUrl(requestPayload));
        else if (isLinkedinUrlRequested && linkedInUrlMapping[actorMailId]) window.open(linkedInUrlMapping[actorMailId]);
        setLinkedinUrlStatus(true);
    };

    return (
        <div>
            <div className='linked-in-url'>
                {
                    !linkedProfileLoading ? (
                        <span
                            tabIndex={-1}
                            role='button'
                            onKeyDown={() => null}
                            onClick={loadLinkedInUrl}
                        >
                            Check User linkedIn profile
                        </span>
                    ) : <span className='linked-in-url-loading'>Loading Linkedin profile...</span>
                }
            </div>
        </div>
    );
};

const RiskSection = (props) => {
    const { sectionData, actorMailId } = props;

    const { sectionName, ui_options: uiOptions, data } = sectionData;
    const { position, is_collapsible: isCollapsible } = uiOptions;
    const {
        dev_actions: devActions, info_data: infoData, media_unit: mediaUnits, table,
    } = data;

    const isLivenessOrFacematchDetails = !isNilOrEmpty(devActions) && !isNilOrEmpty(mediaUnits) && !isNilOrEmpty(infoData);

    const dispatch = useDispatch();

    // reducer state variables
    const { basicInfo } = useContext(UserDetailsContext);
    const userRiskUIForm = useMemoizedSelector(selectUserRiskReviewForm, [CONTAINER_KEY]);
    const curCase = useMemoizedSelector(selectRiskOpsCurCase, [CONTAINER_KEY]);
    const { actorId, caseId } = getDataFromLivenessCase(curCase);

    const { emailId } = basicInfo;

    // local state variables
    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(true);
    const [preFilledValues, setPreFilledValues] = useState(null);
    const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
    // request id to get screener check details
    const [screenerResultId, setScreenerResultId] = useState('');

    const mediaUnitsWithId = useMemo(() => {
        if (!mediaUnits || (mediaUnits && mediaUnits.length === 0)) return [];

        const sectionMediaType = mediaUnits[0].media_type;

        if (sectionMediaType === MEDIA_TYPE_IMAGE) {
            // The even index images in the array are KYC images, while the odd index images are livenesses.
            // We must only display the first index image for the KYC View and the remaining all-odd index images for Liveness.
            return mediaUnits.filter((media, index) => (index % 2 !== 0)).map((mediaUnitInfo) => ({
                items: [
                    {
                        id: uuidv4(),
                        title: 'KYC Image',
                        ...mediaUnits[0],
                    },
                    {
                        id: uuidv4(),
                        title: 'Liveness Image',
                        ...mediaUnitInfo,
                    },
                ],
                ...mediaUnitInfo,
                tabTextColor: mediaUnitInfo?.background_color,
            }));
        }

        return mediaUnits.map((mediaInfo) => ({
            ...mediaInfo,
            id: uuidv4(),
            tabTextColor: mediaInfo?.background_color,
        }));
    }, [mediaUnits]);

    const requestId = useMemo(() => {
        // requestId Pre-filled from selected media unit or the last item of the request
        const requestIdIndex = selectedMediaIndex !== null ? selectedMediaIndex : mediaUnitsWithId.length - 1;
        if (requestIdIndex !== null && mediaUnitsWithId.length > 0) {
            return mediaUnitsWithId[requestIdIndex]?.request_id;
        }
        return null;
    }, [mediaUnitsWithId, selectedMediaIndex]);

    // User allowed annotaion Form when the requested id changes, the form values must be updated.
    const currentReviewForm = useMemo(() => {
        if (devActions?.length) {
            const form = userRiskUIForm?.[devActions[0]];
            const resultForm = {
                ...form,
                parameterList: form?.parameterList?.map((list) => {
                    if (list.name === 'request_id') {
                        list.value = requestId;
                    }
                    return list;
                }),
            };

            return resultForm;
        }

        return null;
    }, [requestId, userRiskUIForm]);

    useEffect(() => {
        const inputObj = {
            actorId, requestId, emailId, disableRequestId: requestId,
        };
        setPreFilledValues(getPreFilledValuesObj(inputObj));
    }, [dispatch, curCase.id, mediaUnits, requestId]);

    useEffect(() => {
        if (requestId && caseId && !userRiskUIForm) {
            dispatch(getFormFieldsAction({
                requestId,
                actorId,
                emailId,
            }));
        }
    }, [requestId]);

    const handleReviewClick = (item) => {
        const { screen_name: screenName, screen_data: screenData } = item;
        const { checkId } = screenData.data;
        // check if the screen name is screen check details modal
        if (screenName === SCREEN_NAME_RISK_SCREENER_ADDITIONAL_DETAILS_POPUP) {
            setScreenerResultId(checkId); // set result id
        }
    };

    const handleModalClose = () => {
        setScreenerResultId('');
    };

    const renderActionableColumn = (item, idx) => {
        const { label, deeplink, is_disabled: disabled } = table.actions[idx];
        return (
            <div className='frfscWrapper' key={idx}>
                <Button
                    secondary
                    label={label}
                    disabled={disabled}
                    onClick={() => handleReviewClick(deeplink, idx)}
                />
            </div>
        );
    };

    const onSubmitAnnotation = (payload) => {
        // while submitting an annotation some of the annotations feild only for viewing purposes not need to send it to the backend.
        const submitForm = payload?.filter((field) => !field?.name?.match(/request_id|actor_id|reviewed_by/g));
        dispatch(submitAnnotationAction({
            submitForm, requestId, emailId, caseId, type: devActions?.[0],
        }));
    };

    return (
        <React.Fragment>
            <ExpandableCard
                version={2}
                heading={`${position}. ${sectionName}`}
                isExpanded={isSearchCardExpanded}
                setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                isCollapsible={isCollapsible}
                extClasses='mb-30 urr-expandablecard'
                extHeadingClasses='p-20'
                extChildElementsClass='p-0-20-20'
            >

                {(!isNilOrEmpty(infoData) && !isLivenessOrFacematchDetails) && (
                    <KVPairInfo
                        data={infoData}
                        extClasses={{
                            container: 'urr-kv-cr m-0',
                            contentLabel: 'urr-kv-cl',
                            contentValue: 'urr-kv-cv',
                            contentWr: 'urr-kv-cw',
                        }}
                    />
                )}
                {!isNilOrEmpty(table) && (
                    <div>
                        {
                            sectionName === 'Social Media Details' && <LinkedInUrlContainer actorMailId={actorMailId} />
                        }
                        <Table
                            v2
                            enableScrollView
                            labelData={table?.columnData}
                            contentData={table?.rowData || []}
                            extClasses={{
                                headerCr: 'urr-table-header',
                                contentCr: 'urr-table-content',
                            }}
                            renderActionableColumn={table?.actions?.length ? renderActionableColumn : null}
                        />
                    </div>
                )}
                {
                    isLivenessOrFacematchDetails
                && (
                    <RenderMediaV2
                        onSelectMedia={(index) => setSelectedMediaIndex(index)}
                        mediaUnits={mediaUnitsWithId}
                        currentReviewForm={currentReviewForm}
                        onSubmit={onSubmitAnnotation}
                        infoData={infoData}
                    />
                )
                }
                {
                    !isLivenessOrFacematchDetails
                && (
                    <RenderMedia
                        onSelectMedia={(index) => setSelectedMediaIndex(index)}
                        mediaUnits={mediaUnitsWithId}
                    />
                )
                }

                {!caseId && !isNilOrEmpty(devActions) && !isLivenessOrFacematchDetails && (
                    <DevActions
                        actionTypes={devActions}
                        containerKey={`risk-section-${sectionData.index}`}
                        extClasses={{
                            container: 'm-0 urr-dev-action-cr',
                        }}
                        preFilledValues={preFilledValues}
                        autoActionSelection={false}
                    />
                )}

                {(caseId && !isNilOrEmpty(devActions) && currentReviewForm) && !isLivenessOrFacematchDetails ? (
                    <ParameterList
                        config={PARAMETER_FILTERS_CONFIG}
                        data={currentReviewForm}
                        onSubmit={onSubmitAnnotation}
                    />
                ) : ''}
            </ExpandableCard>
            {
                screenerResultId
                    ? <RiskPopupModal resultId={screenerResultId} onClose={handleModalClose} />
                    : null
            }
        </React.Fragment>
    );
};

export default memo(RiskSection);

/**
 * @file TableV1 Screen: This file contains the TableV1 Screen component, which displays a vertical table
 * You can refer to the credit card eligible screen table or the credit card preview EMI table for more information."
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const TableV1 = (props) => {
    const {
        heading, contentData, errLabel, nsrLabel,
    } = props;

    if (errLabel) {
        return <div className='table-nsr error-label1 wspace-pre-wrap'>{errLabel}</div>;
    }

    // no need to render anything if the data is not defined (API request is not yet initiated)
    if (!contentData) {
        return null;
    }

    // show no search results label if the data is not found (after API request)
    if (contentData.length === 0) {
        return (
            <div className='table-nsr table-nsr--mt0'>
                {nsrLabel || 'No search results found!'}
            </div>
        );
    }

    return (
        <React.Fragment>
            {heading ? <div className='heading3 mb-15 mt-30'>{heading}</div> : null}
            {contentData.map((item) => {
                const { label, value } = item;

                return (
                    <div className='frWrapper'>
                        <div className='tableV1-column_one heading4 text-center'>{label}</div>
                        <div className='tableV1-column_two heading5 text-center'>{value}</div>
                    </div>
                );
            })}
        </React.Fragment>
    );
};

TableV1.propTypes = {
    heading: PropTypes.string,
    contentData: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
    ).isRequired,
    errLabel: PropTypes.string,
    nsrLabel: PropTypes.string,
};

TableV1.defaultProps = {
    heading: '',
    errLabel: '',
    nsrLabel: '',
};

export default memo(TableV1);

/**
 *
 * Login using email & password or google SSO.
 *
 */

import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { compose } from 'redux';

import { Loader } from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import { makeSelectLoading, makeSelectErr, makeSelectAuthState } from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';
import { LOGIN_SUCCESS, NEW_PASSWORD_REQUIRED, SIGN_IN_WITH_EMAIL, SMS_MFA, FORGOT_PASSWORD } from './constants';
import EmailPassword from './EmailPassword';
import NewPassword from './NewPassword';
import { makeSelectLoginUrl } from '../App/selectors';
import SmsMfa from './SmsMfa';
import ForgotPassword from './ForgotPassword';

const Login = () => {
    const dispatch = useDispatch();
    // global state
    const loginUrl = useSelector(makeSelectLoginUrl(), shallowEqual);
    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const authState = useSelector(makeSelectAuthState(), shallowEqual);

    // on load, clear loader and errors
    useEffect(() => {
        dispatch({ type: LOGIN_SUCCESS });
    }, []);

    if (!loginUrl) {
        return null;
    }

    return (
        <React.Fragment>
            <div className='login login-shadow-effect'>
                { authState === SIGN_IN_WITH_EMAIL &&
                    <EmailPassword loginUrl={loginUrl} />
                }
                { authState === NEW_PASSWORD_REQUIRED &&
                    <NewPassword />
                }
                { authState === SMS_MFA &&
                    <SmsMfa />
                }
                { authState === FORGOT_PASSWORD &&
                    <ForgotPassword />
                }
                { err ? (<div className='err-label login-err'>{err}</div>) : null }
            </div>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'login', reducer });
const withSaga = injectSaga({ key: 'login', saga });

export default compose(
    withReducer,
    withSaga,
)(Login);

/**
 * ManageIssueConfig - Component to get all issue configs for specific L1,L2,L3.
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, FormDropdown, Loader, Table,
} from '../../../components';

import { makeSelectIssueConfig, makeSelectIssueConfigFilterOptions } from '../selectors';

import '../style.scss';
import { clearFileDownloadURL, getIssueConfig, getIssueConfigFilters, updateIssueConfig } from '../actions';
import UploadCSV from './UploadCSV';

const extractFilterOptions = (filterOptions) => {
    if (!filterOptions || filterOptions.length === 0) return filterOptions;
    return filterOptions.map((option) => ({
        ...option,
        label: option?.value,
    }));
};

const ManageIssueConfig = () => {
    const dispatch = useDispatch();

    // reducer state variables
    const issueConfigSelector = useSelector(makeSelectIssueConfig(), shallowEqual);
    const issueConfigFilterOptionsSelector = useSelector(makeSelectIssueConfigFilterOptions(), shallowEqual);

    const [L1Details, setL1Details] = useState({});
    const [L2Details, setL2Details] = useState({});
    const [L3Details, setL3Details] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(null);

    // local state variables
    const [errLabel, setErrLabel] = useState('');
    let prevToken; let nextToken;

    const {
        data: issueConfigData, loading: issueConfigLoading, err: issueConfigError,
    } = issueConfigSelector;

    const {
        data: filterData, loading: filterDataLoading, err: filterDataError,
    } = issueConfigFilterOptionsSelector;

    const handleSubmit = (e) => {
        e.preventDefault();

        setErrLabel('');
        dispatch(getIssueConfig({
            format: 'table',
            category: L1Details?.selectedValue?.value || '',
            categoryDetail: L2Details?.selectedValue?.value || '',
            subCategory: L3Details?.selectedValue?.value || '',
            prevToken,
            nextToken,
        }));
    };

    const handleL1Change = (newValue) => {
        setL1Details({
            ...L1Details,
            selectedValue: newValue,
        });
        setL2Details({ options: extractFilterOptions(newValue?.children), selectedValue: null });
        setL3Details({ options: [], selectedValue: null });
    };
    const handleL2Change = (newValue) => {
        setL2Details({
            ...L2Details,
            selectedValue: newValue,
        });
        setL3Details({ options: extractFilterOptions(newValue?.children), selectedValue: null });
    };
    const handleL3Change = (newValue) => {
        setL3Details({
            ...L3Details,
            selectedValue: newValue,
        });
    };

    useEffect(() => {
        if (!filterData) dispatch(getIssueConfigFilters());
    }, []);

    useEffect(() => {
        if (filterData?.issueCategory && (!L1Details.options || Object.keys(L1Details.options).length === 0)) {
            setL1Details({ options: extractFilterOptions(filterData.issueCategory) });
        }
    }, [filterData]);

    useEffect(() => {
        if (issueConfigData?.issueConfigFile) {
            openLinkInNewTab(issueConfigData?.issueConfigFile);
            dispatch(clearFileDownloadURL());
        }
    }, [issueConfigData]);

    const paginatedClickHandler = (item) => {
        const { prev, next } = item;
        dispatch(getIssueConfig({
            prevToken: prev, nextToken: next,
        }));
    };

    const openLinkInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const downloadFilteredData = () => {
        dispatch(getIssueConfig({
            format: 'file',
            category: L1Details?.selectedValue?.value || '',
            categoryDetail: L2Details?.selectedValue?.value || '',
            subCategory: L3Details?.selectedValue?.value || '',
            prevToken,
            nextToken,
        }));
    };
    const downloadAllData = () => {
        dispatch(getIssueConfig({
            format: 'file',
            prevToken,
            nextToken,
        }));
    };

    return (
        <React.Fragment>
            <div className=''>
                <div className='frcWrapper'>
                    <FormDropdown
                        label='L1 : '
                        options={L1Details.options || []}
                        isLoading={L1Details.loading}
                        input={{
                            value: L1Details.selectedValue,
                            onChange: handleL1Change,
                            placeholder: 'Choose',
                            isClearable: false,
                        }}
                        cacheKey='salary-identifier-type'
                        extStyles={{
                            container: 'issue-config-dd-container',
                            label: 'issue-config-dd-lb',
                            inputField: 'issue-config-dd-input',
                        }}
                    />
                    <FormDropdown
                        label='L2 :'
                        options={L2Details.options || []}
                        isLoading={L2Details.loading}
                        input={{
                            value: L2Details.selectedValue,
                            onChange: handleL2Change,
                            placeholder: 'Choose',
                            isClearable: true,
                        }}
                        cacheKey='salary-identifier-type'
                        extStyles={{
                            container: 'issue-config-dd-container',
                            label: 'issue-config-dd-lb',
                            inputField: 'issue-config-dd-input',
                        }}
                    />
                    <FormDropdown
                        label='L3 :'
                        options={L3Details.options || []}
                        isLoading={L3Details.loading}
                        input={{
                            value: L3Details.selectedValue,
                            onChange: handleL3Change,
                            placeholder: 'Choose',
                            isClearable: true,
                        }}
                        cacheKey='salary-identifier-type'
                        extStyles={{
                            container: 'issue-config-dd-container',
                            label: 'issue-config-dd-lb',
                            inputField: 'issue-config-dd-input',
                        }}
                    />

                    <div className='frcWrapper'>
                        <div className='fcWrapper'>
                            <div
                                className='link btu-upload-csv-sample-link'
                                href='/assets/bulk-ticket-update-sample.csv'
                                onClick={downloadFilteredData}
                                role='presentation'
                            >
                                Download filtered data
                            </div>
                            <div
                                className='link btu-upload-csv-sample-link'
                                href='/assets/bulk-ticket-update-sample.csv'
                                onClick={downloadAllData}
                                role='presentation'
                            >
                                Download all data
                            </div>
                        </div>
                    </div>
                </div>

                <div className='err-label btu-upload-csv-el issue-config-err-label'>{errLabel || filterDataError || issueConfigError}</div>
                <Button
                    v2
                    primary
                    label='Submit'
                    onClick={handleSubmit}
                    extClasses='mt-30 mb-30'
                />

                <div className='mb-30'>
                    {issueConfigData?.issueConfigTable && (
                        <Table
                            v2
                            objKey='header_key'
                            rowDataVersion={2}
                            labelData={issueConfigData?.issueConfigTable?.columnData}
                            contentData={issueConfigData?.issueConfigTable?.rowData}
                            prevToken={prevToken}
                            nextToken={nextToken}
                            paginatedClickHandler={paginatedClickHandler}
                            extClasses={{
                                container: 'p-20',
                            }}
                        />
                    )}
                </div>

                <Button
                    v2
                    primary
                    label='Upload Config'
                    onClick={() => {
                        setIsModalVisible(true);
                    }}
                    extClasses='mt-30 mb-30'
                />
                <UploadCSV
                    visible={isModalVisible}
                    toggleHandler={setIsModalVisible}
                    onSubmit={(fileData) => {
                        dispatch(updateIssueConfig({ fileContent: fileData }));
                    }}
                />

            </div>
            <Loader visible={filterDataLoading || issueConfigLoading} />
        </React.Fragment>
    );
};

export default ManageIssueConfig;

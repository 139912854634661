/**
 * @file Agent contains rudder event names for Sherlock V1 Agent level
 */

const AgentUserCallSelectionSherlock = 'AgentUserCallSelectionSherlock';
const AgentClickCreateANewTicket = 'AgentClickCreateANewTicket';
const AgentAttachTicketToCurrentCall = 'AgentAttachTicketToCurrentCall';
const AgentClickTicketIdSubmit = 'AgentClickTicketIdSubmit';
const AgentClickSherlockSidebarOption = 'AgentClickSherlockSidebarOption';
const SherlockV1AgentTicketTabActive = 'SherlockV1AgentTabActive';
const SherlockV1AgentTicketTabInActive = 'SherlockV1AgentTabInActive';
const SherlockV1AgentTicketTabOpen = 'SherlockV1AgentTabOpen';
const SherlockV1AgentTicketTabClosed = 'SherlockV1AgentTabClosed';

export {
    AgentUserCallSelectionSherlock,
    AgentClickCreateANewTicket,
    AgentAttachTicketToCurrentCall,
    AgentClickTicketIdSubmit,
    AgentClickSherlockSidebarOption,
    SherlockV1AgentTicketTabActive,
    SherlockV1AgentTicketTabInActive,
    SherlockV1AgentTicketTabOpen,
    SherlockV1AgentTicketTabClosed,
};

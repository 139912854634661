import { fromJS } from 'immutable';

import {
    SET_MUTUAL_FUND_AMC, SET_MUTUAL_FUND_TXN_TYPE, GET_MUTUAL_FUNDS, SET_MUTUAL_FUNDS,
    ERR_MUTUAL_FUNDS, GET_MUTUAL_FUND_ORDERS, SET_MUTUAL_FUND_ORDERS, ERR_MUTUAL_FUND_ORDERS,
    GET_MUTUAL_FUND_ORDER_INFO, SET_MUTUAL_FUND_ORDER_INFO, ERR_MUTUAL_FUND_ORDER_INFO,
    SET_SEARCH_PARAMS, RESET_SEARCH_PARAMS, SET_MUTUAL_FUND_ORDER_FILTER_STATUS,
} from './constants';

const initialState = fromJS({
    searchParameters: {},
    mfAmcList: [],
    mfTxnTypeList: [],
    mfOrderFilterStatusList: [],
    mutualFundsList: {},
    mutualFundOrdersList: {},
    mutualFundOrderInfo: {},
});

function mutualFundsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SEARCH_PARAMS: {
            const {
                ticketId, mutualFundsView, mutualFundOrdersView, fromDate, toDate, key, value,
            } = action.data;

            let view;

            if (mutualFundsView) {
                view = 'mutualFundsView';
            } else if (mutualFundOrdersView) {
                view = 'mutualFundOrdersView';
            }

            // set key-value pair with selected value or clear selected value
            if (key && (value || value === '')) {
                return state.setIn(['searchParameters', ticketId, view, key], value);
            }

            if (fromDate && toDate) {
                return state
                    .setIn(['searchParameters', ticketId, view, 'fromDate'], fromJS(fromDate))
                    .setIn(['searchParameters', ticketId, view, 'toDate'], fromJS(toDate));
            }

            return state;
        }

        case RESET_SEARCH_PARAMS: {
            const { ticketId, mutualFundsView, mutualFundOrdersView, fromDate, toDate } = action.data;

            let view;

            if (mutualFundsView) {
                view = 'mutualFundsView';

                // reset all search parameters for mutual funds view & reset the mutual funds list too
                return state
                    .setIn(['searchParameters', ticketId, view], fromJS({ fromDate, toDate }))
                    .setIn(['mutualFundsList', ticketId], fromJS({}));
            } else if (mutualFundOrdersView) {
                view = 'mutualFundOrdersView';

                /*
                reset isSubmitted flag, mutual fund id & name search parameters for mutual fund orders view
                & reset the mutual fund orders list & order info
                */
                return state
                    .setIn(['searchParameters', ticketId, view, 'isSubmitted'], false)
                    .setIn(['searchParameters', ticketId, view, 'mfId'], '')
                    .setIn(['searchParameters', ticketId, view, 'mfName'], '')
                    .setIn(['mutualFundOrdersList', ticketId], fromJS({}))
                    .setIn(['mutualFundOrderInfo', ticketId], fromJS({}));
            }

            return state;
        }

        case SET_MUTUAL_FUND_AMC: {
            const { amcList } = action.data;

            return state.set('mfAmcList', fromJS(amcList));
        }

        case SET_MUTUAL_FUND_TXN_TYPE: {
            const { txnTypeList } = action.data;

            return state.set('mfTxnTypeList', fromJS(txnTypeList));
        }

        case SET_MUTUAL_FUND_ORDER_FILTER_STATUS: {
            const { orderFilterStatusList } = action.data;

            return state.setIn(['mfOrderFilterStatusList'], fromJS(orderFilterStatusList));
        }

        case GET_MUTUAL_FUNDS: {
            const { ticketId } = action.data;

            return state
                .setIn(['mutualFundsList', ticketId, 'loading'], true)
                .setIn(['mutualFundsList', ticketId, 'err'], '');
        }

        case SET_MUTUAL_FUNDS: {
            const { ticketId, mutualFundsList } = action.data;
            const { data, prev, next } = mutualFundsList;

            return state
                .setIn(['mutualFundsList', ticketId, 'loading'], false)
                .setIn(['mutualFundsList', ticketId, 'data'], fromJS(data))
                .setIn(['mutualFundsList', ticketId, 'prev'], fromJS(prev))
                .setIn(['mutualFundsList', ticketId, 'next'], fromJS(next));
        }

        case ERR_MUTUAL_FUNDS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['mutualFundsList', ticketId, 'loading'], false)
                .setIn(['mutualFundsList', ticketId, 'err'], err)
                .setIn(['mutualFundsList', ticketId, 'data'], fromJS([]))
                .setIn(['mutualFundsList', ticketId, 'prev'], fromJS({}))
                .setIn(['mutualFundsList', ticketId, 'next'], fromJS({}));
        }

        case GET_MUTUAL_FUND_ORDERS: {
            const { ticketId } = action.data;

            return state
                .setIn(['mutualFundOrdersList', ticketId, 'loading'], true)
                .setIn(['mutualFundOrdersList', ticketId, 'err'], '');
        }

        case SET_MUTUAL_FUND_ORDERS: {
            const { ticketId, mutualFundOrdersList } = action.data;
            const { data, fundName, prev, next } = mutualFundOrdersList;

            return state
                .setIn(['mutualFundOrdersList', ticketId, 'loading'], false)
                .setIn(['mutualFundOrdersList', ticketId, 'data'], fromJS(data))
                .setIn(['mutualFundOrdersList', ticketId, 'fundName'], fromJS(fundName))
                .setIn(['mutualFundOrdersList', ticketId, 'prev'], fromJS(prev))
                .setIn(['mutualFundOrdersList', ticketId, 'next'], fromJS(next));
        }

        case ERR_MUTUAL_FUND_ORDERS: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['mutualFundOrdersList', ticketId, 'loading'], false)
                .setIn(['mutualFundOrdersList', ticketId, 'err'], err)
                .setIn(['mutualFundOrdersList', ticketId, 'data'], fromJS([]))
                .setIn(['mutualFundOrdersList', ticketId, 'prev'], fromJS({}))
                .setIn(['mutualFundOrdersList', ticketId, 'next'], fromJS({}));
        }

        case GET_MUTUAL_FUND_ORDER_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['mutualFundOrderInfo', ticketId, 'loading'], true)
                .setIn(['mutualFundOrderInfo', ticketId, 'err'], '')
                .setIn(['mutualFundOrderInfo', ticketId, 'data'], fromJS({}))
                .setIn(['mutualFundOrderInfo', ticketId, 'hasData'], false);
        }

        case SET_MUTUAL_FUND_ORDER_INFO: {
            const { ticketId, mutualFundOrderInfo } = action.data;

            return state
                .setIn(['mutualFundOrderInfo', ticketId, 'loading'], false)
                .setIn(['mutualFundOrderInfo', ticketId, 'hasData'], true)
                .setIn(['mutualFundOrderInfo', ticketId, 'data'], fromJS(mutualFundOrderInfo));
        }

        case ERR_MUTUAL_FUND_ORDER_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['mutualFundOrderInfo', ticketId, 'loading'], false)
                .setIn(['mutualFundOrderInfo', ticketId, 'err'], err)
                .setIn(['mutualFundOrderInfo', ticketId, 'hasData'], false)
                .setIn(['mutualFundOrderInfo', ticketId, 'data'], fromJS({}));
        }

        default:
            return state;
    }
}

export default mutualFundsReducer;

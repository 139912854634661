/*
 *
 * Call Details Reducer
 *
 */

import { fromJS } from 'immutable';

import {
    GET_CALL_RECORDING_URL, SET_CALL_RECORDING_URL, ERR_CALL_RECORDING_URL,
    GET_CALL_TRANSCRIPT, SET_CALL_TRANSCRIPT, ERR_CALL_TRANSCRIPT,
} from './constants';

const initialState = fromJS({
    recordingInfo: {},
    transcriptInfo: {},
});

function callDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CALL_RECORDING_URL: {
            const { ticketId } = action.data;

            return state
                .setIn(['recordingInfo', ticketId, 'loading'], true)
                .setIn(['recordingInfo', ticketId, 'err'], '');
        }

        case SET_CALL_RECORDING_URL: {
            const { ticketId, recordingURL } = action.data;

            return state
                .setIn(['recordingInfo', ticketId, 'loading'], false)
                .setIn(['recordingInfo', ticketId, 'recordingURL'], recordingURL);
        }

        case ERR_CALL_RECORDING_URL: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['recordingInfo', ticketId, 'loading'], false)
                .setIn(['recordingInfo', ticketId, 'recordingURL'], '')
                .setIn(['recordingInfo', ticketId, 'err'], err);
        }

        case GET_CALL_TRANSCRIPT: {
            const { ticketId } = action.data;

            return state
                .setIn(['transcriptInfo', ticketId, 'loading'], true)
                .setIn(['transcriptInfo', ticketId, 'err'], '');
        }

        case SET_CALL_TRANSCRIPT: {
            const { ticketId, transcriptData } = action.data;

            return state
                .setIn(['transcriptInfo', ticketId, 'loading'], false)
                .setIn(['transcriptInfo', ticketId, 'transcriptData'], fromJS(JSON.parse(transcriptData)));
        }

        case ERR_CALL_TRANSCRIPT: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['transcriptInfo', ticketId, 'loading'], false)
                .setIn(['transcriptInfo', ticketId, 'transcriptData'], '')
                .setIn(['transcriptInfo', ticketId, 'err'], err);
        }

        default:
            return state;
    }
}

export default callDetailsReducer;

/**
 * IdentifierHeader
 * Component to display the identifier & phone number of the user
 * It's getting used in UserDetailsById component
 */

import React, { memo } from 'react';

import './style.scss';

const IdentifierHeader = (props) => {
    const { label, id, name } = props;

    return (
        <div className='id-header-wr'>
            <span>{`${label} - ${id}`}</span>
            {
                name ? (
                    <React.Fragment>
                        <span>&nbsp;•&nbsp;</span>
                        <span>{name}</span>
                    </React.Fragment>
                ) : null
            }
        </div>
    );
};

export default memo(IdentifierHeader);

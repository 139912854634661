/**
 *
 * @file Transaction Annotation Modal
 *
 */

import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modal, Button } from '../../../components';
import { setTransactionAnnotationModalVisibility } from '../actions';
import { selectTxnAnnotationStatus } from '../selectors';
import { ANNOTATION_MAP } from '../constants';
import FormUi from './form';
import './style.scss';

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'inherit',
        fontWeight: '200',
        fontSize: 14,
        width: '30%',
        maxWidth: '1500px',
        overflow: 'auto',
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        padding: '0',
    },
};

const ModalUI = (props) => {
    const { selectedRows, isVisible, toggleModal } = props;

    return (
        <Modal
            visible={isVisible}
            modalStyles={modalStyles}
            contentLabel='Rule Details'
        >
            <div className='annotations-modal'>
                <div className='annotations-modal-headers'>
                    <span className='annotations-modal-heading'>Annotate Transaction</span>
                    <button
                        type='button'
                        className='annotations-modal__cl'
                        aria-label='Close'
                        onClick={toggleModal}
                    >
                        <span aria-hidden='true'>&times;</span>
                    </button>

                </div>

                <div
                    className='annotations-modal__cl-wrapper'
                />
                <div className='annotations-modal-body'>
                    <div className='annotations-modal-container'>
                        <FormUi
                            selectedRows={selectedRows}
                            flowType={ANNOTATION_MAP.TYPE.LIST_TRANSACTION}
                            extClass='annotations-modal-form-container'
                        />
                    </div>
                </div>
            </div>
        </Modal>

    );
};

const TransactionAnnotationModal = ({
    selectedRows = [],
}) => {
    const txnAnnotationVisibility = useSelector(selectTxnAnnotationStatus, shallowEqual);
    const dispatch = useDispatch();

    const toggleModal = () => {
        dispatch(setTransactionAnnotationModalVisibility(!txnAnnotationVisibility));
    };

    return (
        <React.Fragment>
            {
                txnAnnotationVisibility && <ModalUI selectedRows={selectedRows} isVisible={txnAnnotationVisibility} toggleModal={toggleModal} />
            }
            {
                selectedRows.length > 0 && (
                    <Button
                        v2
                        type='button'
                        primary
                        label='annotate'
                        onClick={toggleModal}
                        extClasses='annotations-modal-toggle-button'
                    />
                )
            }
        </React.Fragment>
    );
};

export default TransactionAnnotationModal;

/* eslint-disable no-nested-ternary */
/**
 * @file WealthKYCDocketFilterInput
 * Shows a form to take in the type of filter and value to filter out the KYC Docket Queue results
 */
//

import React, { memo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Button, FormDropdown, FormWithSingleInput } from '../../../components';
import { validators } from '../../../utils';

import { getAdditionalFilters } from '../utils';
import { setWealthOpsQueueFiltersAction, getWealthOpsQueueCasesAction } from '../actions';
import { selectWealthOpsQueueFilters, selectWealthOpsPayloadType } from '../selectors';

import './style.scss';

const FILTER_TYPES = [
    {
        label: 'KRA Status',
        value: 'kra_status',
    },
    {
        label: 'PAN Number',
        value: 'pan',
    },
];

const KRA_STATUS_TYPES = [
    {
        label: 'KRA_STATUS_SUBMITTED',
        value: 'KRA_STATUS_SUBMITTED',
    },
    {
        label: 'KRA_STATUS_NOT_CHECKED_WITH_RESPECTIVE_KRA',
        value: 'KRA_STATUS_NOT_CHECKED_WITH_RESPECTIVE_KRA',
    },
    {
        label: 'KRA_STATUS_KRA_VERIFIED',
        value: 'KRA_STATUS_KRA_VERIFIED',
    },
    {
        label: 'KRA_STATUS_HOLD',
        value: 'KRA_STATUS_HOLD',
    },
    {
        label: 'KRA_STATUS_REJECTED',
        value: 'KRA_STATUS_REJECTED',
    },
    {
        label: 'KRA_STATUS_NOT_AVAILABLE',
        value: 'KRA_STATUS_NOT_AVAILABLE',
    },
    {
        label: 'KRA_STATUS_DEACTIVATED',
        value: 'KRA_STATUS_DEACTIVATED',
    },
    {
        label: 'KRA_STATUS_EXISTING_KYC_SUBMITTED',
        value: 'KRA_STATUS_EXISTING_KYC_SUBMITTED',
    },
    {
        label: 'KRA_STATUS_EXISTING_KYC_VERIFIED',
        value: 'KRA_STATUS_EXISTING_KYC_VERIFIED',
    },
    {
        label: 'KRA_STATUS_EXISTING_KYC_HOLD',
        value: 'KRA_STATUS_EXISTING_KYC_HOLD',
    },
    {
        label: 'KRA_STATUS_EXISTING_KYC_REJECTED',
        value: 'KRA_STATUS_EXISTING_KYC_REJECTED',
    },
    {
        label: 'KRA_STATUS_KYC_REGISTERED_WITH_CVLMF',
        value: 'KRA_STATUS_KYC_REGISTERED_WITH_CVLMF',
    },
    {
        label: 'KRA_STATUS_NOT_CHECKED_WITH_MULTIPLE_KRA',
        value: 'KRA_STATUS_NOT_CHECKED_WITH_MULTIPLE_KRA',
    },
    {
        label: 'KRA_STATUS_INVALID_PAN_NO_FORMAT',
        value: 'KRA_STATUS_INVALID_PAN_NO_FORMAT',
    },
];

const WealthKYCDocketFilterInput = () => {
    const dispatch = useDispatch();

    const payloadType = useSelector(selectWealthOpsPayloadType(), shallowEqual);
    const queueFilters = useSelector(selectWealthOpsQueueFilters(), shallowEqual);
    const [errLabel, setErrLabel] = useState('');

    const { limit, kycFilterType, kycFilterValue } = queueFilters;

    const validateInputs = () => {
        const { value: identifierTypeValue } = kycFilterType;

        if (!identifierTypeValue) {
            setErrLabel('Please select a filter type');
            return true;
        }

        if (!kycFilterValue) {
            setErrLabel('Please enter a filter value');
            return true;
        }

        if (identifierTypeValue === 'pan' && !validators.isInputtedPANStrValid(kycFilterValue)) {
            setErrLabel('Please enter a valid PAN number');
            return true;
        }

        return false;
    };

    const onChangeFilterType = (newValue) => {
        dispatch(setWealthOpsQueueFiltersAction({ key: 'kycFilterType', value: newValue }));
        dispatch(setWealthOpsQueueFiltersAction({ key: 'kycFilterValue', value: '' }));

        // clear filter type if cross button is clicked
        if (!newValue) dispatch(setWealthOpsQueueFiltersAction({ key: 'kycFilterType', value: '' }));
    };

    const onChangeFilterValue = (newValue) => {
        dispatch(setWealthOpsQueueFiltersAction({ key: 'kycFilterValue', value: newValue }));

        // clear filter value if cross button is clicked
        if (!newValue) dispatch(setWealthOpsQueueFiltersAction({ key: 'kycFilterValue', value: '' }));
    };

    const handleSearchDetails = (e) => {
        e.preventDefault();

        if (validateInputs()) return;

        setErrLabel('');

        dispatch(getWealthOpsQueueCasesAction({
            payloadType: payloadType.value,
            casesList: [],
            ...getAdditionalFilters(limit.value, kycFilterType.value, kycFilterValue.value || kycFilterValue),
        }));
    };

    const clearFilters = (e) => {
        e.preventDefault();

        setErrLabel('');

        dispatch(getWealthOpsQueueCasesAction({
            payloadType: payloadType.value,
            casesList: [],
            ...getAdditionalFilters(limit.value, '', ''),
        }));

        dispatch(setWealthOpsQueueFiltersAction({ key: 'kycFilterType', value: '' }));
        dispatch(setWealthOpsQueueFiltersAction({ key: 'kycFilterValue', value: '' }));
    };

    return (
        <div className='kycdoc-ip-cr'>
            <FormDropdown
                label='Filter Type'
                options={FILTER_TYPES}
                input={{
                    value: kycFilterType,
                    onChange: onChangeFilterType,
                    placeholder: 'Choose...',
                    isClearable: true,
                }}
                cacheKey='kyc-docket-identifier-type'
                extStyles={{
                    container: 'kycdoc-ip-fc1 mb-15',
                    label: 'kycdoc-ip-fc__lb',
                    inputField: 'kycdoc-ip-fc__ip',
                }}
            />
            {
                kycFilterType && kycFilterType.value === 'kra_status' ? (
                    <FormDropdown
                        label='Filter Value'
                        options={KRA_STATUS_TYPES}
                        input={{
                            value: kycFilterValue,
                            onChange: onChangeFilterValue,
                            placeholder: 'Choose...',
                            isClearable: true,
                        }}
                        cacheKey='kyc-docket-identifier-type'
                        extStyles={{
                            container: 'kycdoc-ip-fc1 mb-15',
                            label: 'kycdoc-ip-fc__lb',
                            inputField: 'kycdoc-ip-fc__ip',
                        }}
                    />
                ) : (
                    <FormWithSingleInput
                        disableFormSubmitOnEnter
                        onFormSubmit={handleSearchDetails}
                        extClasses={{
                            container: 'kycdoc-ip-fc2 mb-15',
                            label: 'kycdoc-ip-fc__lb',
                            inputField: 'kycdoc-ip-fc__ip',
                        }}
                        label='Filter Value'
                        labelId='kyc-docket-identifier-value'
                        formType='text'
                        input={{
                            value: kycFilterValue,
                            onChange: (e) => onChangeFilterValue(e.target.value),
                        }}
                    />
                )
            }
            {errLabel && <div className='err-label label1 kycdoc-ip-elb'>{errLabel}</div>}
            <div className='frfssbWrapper'>
                <Button
                    v2
                    label='Search'
                    primary
                    onClick={handleSearchDetails}
                />
                <Button
                    v2
                    label='Clear Filters'
                    secondary
                    onClick={clearFilters}
                />
            </div>
        </div>
    );
};

export default memo(WealthKYCDocketFilterInput);

/**
 * @file User Products
 * page to view User products such as liquid loans, credit cards, savings cards ect..
 * with their basic details, kyc details and other details for review
 */

import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';
import { FormDropdown, Loader } from '../../components';

import RiskSection from '../UserRiskReview/RiskSection';

import { getUserProductInfoAction, getUserProductListAction, resetUserProductInfoData } from './actions';
import {
    selectProduct, selectUserRiskProductInfo, selectUserRiskProductInfoError,
    selectUserRiskProductInfoLoading, selectUserRiskProductList, selectUserRiskProductListError,
} from './selectors';
import { USER_RISK_PRODUCTS_KEY } from './constants';
import reducer from './reducer';
import saga from './saga';
import './style.scss';

const UserProducts = (props) => {
    const { caseId, actorId } = props;

    const productList = useSelector(selectUserRiskProductList, shallowEqual);
    const error = useSelector(selectUserRiskProductListError, shallowEqual);
    const productInfo = useSelector(selectUserRiskProductInfo, shallowEqual);
    const product = useSelector(selectProduct, shallowEqual);
    const productInfoloading = useSelector(selectUserRiskProductInfoLoading, shallowEqual);
    const productInfoerror = useSelector(selectUserRiskProductInfoError, shallowEqual);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserProductListAction({ caseId, actorId }));

        return () => {
            dispatch(resetUserProductInfoData());
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChange = (data) => {
        if (data) {
            dispatch(getUserProductInfoAction({ caseId, actorId, product: data }));
        }
    };

    return (
        <React.Fragment>
            <div className='uprod-cr'>
                <div className='frccWrapper mb-30'>
                    <FormDropdown
                        label='Products'
                        options={productList}
                        input={{
                            value: product || null,
                            onChange: handleOnChange,
                            placeholder: 'Choose...',
                        }}
                        cacheKey='user-product-list-dropdown'
                        extStyles={{
                            container: 'frccWrapper',
                            label: 'mr-16',
                            inputField: 'uprod-ddi',
                        }}
                    />
                </div>
                {productInfoerror || error ? <div className='container-err-label err-label1'>{error || productInfoerror}</div> : null}
                {
                    productInfo?.map((item) => (
                        <RiskSection sectionData={item} key={item.id} />
                    ))
                }
            </div>
            <Loader visible={productInfoloading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: USER_RISK_PRODUCTS_KEY, reducer });
const withSaga = injectSaga({ key: USER_RISK_PRODUCTS_KEY, saga });

export default compose(withReducer, withSaga)(UserProducts);

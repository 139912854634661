/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

/**
 *
 * @file AllFacematchCases : View all facematch cases for US Stocks
 * Shows all the facematch cases in Table according to the payload type selected.
 *
 */
import React, { memo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, FormDropdown, Table } from '../../../components';

import {
    getUssFmReviewActionListAction, getUssFmReviewItemsAction, getUssFmReviewReasonListAction,
    setUssFmFacematchPayloadTypeAction,
} from '../actions';
import { REVIEW_LABEL_DATA } from '../constants';
import {
    selectUSSFMLoading, selectUSSFMPayloadTypeList, selectUSSFMReviewItemList,
    selectUSSFMFacematchPayloadType, selectUSSFMReviewActionList, selectUSSFMReviewReasonList,
} from '../selectors';

const AllFacematchCases = (props) => {
    const { handleReviewClick } = props;

    const dispatch = useDispatch();

    // global state variables
    const payloadTypes = useSelector(selectUSSFMPayloadTypeList, shallowEqual);
    const loadingFacematchCases = useSelector(selectUSSFMLoading, shallowEqual);
    const reviewItems = useSelector(selectUSSFMReviewItemList, shallowEqual);
    const facematchPayloadType = useSelector(selectUSSFMFacematchPayloadType, shallowEqual);
    const reviewActionList = useSelector(selectUSSFMReviewActionList, shallowEqual);
    const reviewReasonList = useSelector(selectUSSFMReviewReasonList, shallowEqual);

    useEffect(() => {
        if (facematchPayloadType.value) {
            dispatch(getUssFmReviewItemsAction({ payloadType: facematchPayloadType.value }));
        }
    }, [facematchPayloadType.value, dispatch]);

    useEffect(() => {
        if (!reviewActionList.length) {
            dispatch(getUssFmReviewActionListAction());
        }

        if (!reviewReasonList.length) {
            dispatch(getUssFmReviewReasonListAction());
        }
    }, [dispatch, reviewActionList, reviewReasonList]);

    const { data, prev: prevToken, next: nextToken } = reviewItems;

    const setPayloadTypeValue = (newPayload) => {
        dispatch(setUssFmFacematchPayloadTypeAction(newPayload));
    };

    const getPaginatedData = (params) => {
        const { prev, next } = params;
        const payload = {
            payloadType: facematchPayloadType.value,
            prev,
            next,
        };

        dispatch(getUssFmReviewItemsAction(payload));
    };

    const renderActions = (item) => (
        <div className='frccWrapper'>
            <Button
                link
                label='Review'
                onClick={() => {
                    handleReviewClick(item.id);
                }}
            />
        </div>
    );

    return (
        <div>
            <div className='frcsbWrapper my-30'>
                <div className='fcWrapper'>
                    <div className='frcfeWrapper mb-15' />
                </div>
                <div className='fcWrapper'>
                    <div className='frcfeWrapper mb-15'>
                        <div className='ussfm-idd__lb'>Payload</div>
                        <FormDropdown
                            options={payloadTypes}
                            input={{
                                value: facematchPayloadType,
                                onChange: setPayloadTypeValue,
                                placeholder: 'Choose...',
                            }}
                            cacheKey='ussfm-action-dropdown'
                            extStyles={{
                                container: 'ussfm-fcwsd ussfm-payload',
                                label: 'ussfm-fcwsd__lb',
                            }}
                        />
                    </div>
                </div>
            </div>

            {!loadingFacematchCases && data && (
                <Table
                    v2
                    labelData={REVIEW_LABEL_DATA}
                    contentData={data}
                    prevToken={prevToken}
                    nextToken={nextToken}
                    paginatedClickHandler={getPaginatedData}
                    renderActionableColumn={renderActions}
                />
            )}
        </div>
    );
};

export default memo(AllFacematchCases);

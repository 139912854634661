/**
 * @file Manages the saga generator functions for transaction case details container
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, toastify, getErrMsg,
    getQueryStringFromObject,
} from '../../utils';
import {
    TRANSACTION_RISK_VIEW_ENDPOINT,
    TRANSACTION_RISK_LIST_ANNOTATION_ENDPOINT,
    TRANSACTION_RISK_LIST_COMMENTS_ENDPOINT,
} from '../../api/routes';

import {
    GET_TRANSACTION_CASE_DETAILS,
    SET_TRANSACTION_CASE_DETAILS,
    ERR_TRANSACTION_CASE_DETAILS,
    GET_TRANSACTION_CASE_ANNOTATION,
    SET_TRANSACTION_CASE_ANNOTATION,
    ERR_TRANSACTION_CASE_ANNOTATION,
    GET_TRANSACTION_CASE_COMMENTS,
    SET_TRANSACTION_CASE_COMMENTS,
    ERR_TRANSACTION_CASE_COMMENTS,
} from './constants';

function* getTransactionCaseDetailSaga(action) {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTION_RISK_VIEW_ENDPOINT}/${action.data.caseId}`,
        );

        yield put({ type: SET_TRANSACTION_CASE_DETAILS, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({
            type: ERR_TRANSACTION_CASE_DETAILS,
            data: { err: getErrMsg(e) },
        });
    }
}

function* getTransactionCaseAnnotationSaga(action) {
    try {
        const queryReq = {
            actorId: action.data.actorId,
        };
        const queryString = getQueryStringFromObject(queryReq);

        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTION_RISK_LIST_ANNOTATION_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_TRANSACTION_CASE_ANNOTATION, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({
            type: ERR_TRANSACTION_CASE_ANNOTATION,
            data: { err: getErrMsg(e) },
        });
    }
}

function* getTransactionCommentsSaga(action) {
    try {
        const queryReq = {
            actorId: action.data.actorId,
        };
        const queryString = getQueryStringFromObject(queryReq);

        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TRANSACTION_RISK_LIST_COMMENTS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_TRANSACTION_CASE_COMMENTS, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({
            type: ERR_TRANSACTION_CASE_COMMENTS,
            data: { err: getErrMsg(e) },
        });
    }
}

export default function* transactionCaseDetailSaga() {
    yield all([
        yield takeLatest(GET_TRANSACTION_CASE_DETAILS, getTransactionCaseDetailSaga),
        yield takeLatest(GET_TRANSACTION_CASE_ANNOTATION, getTransactionCaseAnnotationSaga),
        yield takeLatest(GET_TRANSACTION_CASE_COMMENTS, getTransactionCommentsSaga),
    ]);
}

/**
 * @file UserRiskReview reducer
 */

import { fromJS } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import {
    ERROR_USER_RISK_REVIEW, GET_MEDIA_FOR_USER_RISK_REVIEW, GET_USER_RISK_REVIEW_DATA,
    HIDE_LOADER_USER_RISK_REVIEW, RESET_USER_RISK_REVIEW_DATA, SET_FORM_FIELDS, SET_USER_RISK_REVIEW_DATA,
    SET_USER_RISK_SCREENER_CHECK_DETAILS, ERR_USER_RISK_SCREENER_CHECK_DETAILS, GET_USER_RISK_SCREENER_CHECK_DETAILS,
    SET_LINKED_IN_URL,
} from './constants';

const initialState = fromJS({
    loading: false,
    err: '',
    reviewSections: {},
    isAFU: null,
    userRiskform: null,
    screenerCheckDetails: {},
    linkedInUrl: {
        mapping: {},
        loading: false,
    },
});

function userRiskReviewReducer(state = initialState, action = '') {
    switch (action.type) {
        case GET_USER_RISK_REVIEW_DATA:
        case GET_MEDIA_FOR_USER_RISK_REVIEW: {
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '');
        }

        case SET_USER_RISK_REVIEW_DATA: {
            const { reviewSections, actorId, isAFU } = action.data;
            const sectionList = [];

            /** For AFU history reviewSections is an Array
             *  For UserRisk reviewSections is an Object
             */
            if (!Array.isArray(reviewSections)) {
                Object.entries(reviewSections).forEach(([sectionKey, sectionInfo], index) => {
                    const sectionName = sectionInfo?.ui_options?.header || sectionKey.replace(/_/g, ' '); // replace _ with space
                    if (sectionInfo) {
                        sectionList.push({
                            ...sectionInfo,
                            sectionName,
                            id: uuidv4(),
                            index: index + 1,
                        });
                    }
                });

                // sort sections based on position
                sectionList.sort((section1, section2) => (
                    section1.ui_options.position - section2.ui_options.position));
            } else {
                reviewSections.forEach((item, index) => {
                    sectionList.push({
                        ...item,
                        sectionName: item.ui_options.header,
                        id: uuidv4(),
                        index: index + 1,
                    });
                });
            }

            return state.setIn(['reviewSections', actorId], fromJS(sectionList))
                .setIn(['isAFU'], isAFU);
        }

        case ERROR_USER_RISK_REVIEW: {
            const { err } = action.data;

            return state.setIn(['err'], err);
        }

        case HIDE_LOADER_USER_RISK_REVIEW: {
            return state.setIn(['loading'], false);
        }

        case SET_FORM_FIELDS: {
            return state.setIn(['userRiskform'], fromJS(action.data));
        }

        case GET_USER_RISK_SCREENER_CHECK_DETAILS: {
            const { resultId } = action.data;
            return state.setIn(['screenerCheckDetails', resultId], fromJS({ loading: true }));
        }

        case SET_USER_RISK_SCREENER_CHECK_DETAILS: {
            const { resultId, data } = action.data;
            return state.setIn(['screenerCheckDetails', resultId], fromJS(data));
        }

        case ERR_USER_RISK_SCREENER_CHECK_DETAILS: {
            const { resultId, error } = action.data;
            return state.setIn(['screenerCheckDetails', resultId], fromJS({ error }));
        }

        case RESET_USER_RISK_REVIEW_DATA: {
            return initialState;
        }

        case SET_LINKED_IN_URL: {
            const { mail, linkedInUrl, loading } = action.data;
            const currentMap = state.getIn(['linkedInUrl', 'mapping']).toJS();
            if (mail) currentMap[mail] = linkedInUrl;
            return state
                .setIn(['linkedInUrl', 'mapping'], fromJS(currentMap))
                .setIn(['linkedInUrl', 'loading'], loading);
        }

        default:
            return state;
    }
}

export default userRiskReviewReducer;

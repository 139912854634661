/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import {
    BA_DEBIT_INSTRUMENT_TICKET_PATH, BA_UPI_INSTRUMENT_TICKET_PATH,
} from '../App/routes';
import DebitCard from './DebitCard';
import UPI from './UPI';

import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';

import {
    getUPIInfo as getUPIInfoApi, toggleUPIStatus as toggleUPIStatusApi,
} from './actions';
import reducer from './reducer';
import saga from './saga';
import { makeSelectCardsList, makeSelectUPIInfo } from './selectors';

class PaymentInstruments extends React.PureComponent {
    getCommonProps = () => {
        const {
            pendingAuth, ticketId, index, userId, isChatFlow, activeTicket,
        } = this.props;

        return {
            index,
            ticketId,
            userId,
            pendingAuth,
            isChatFlow,
            activeTicket,
        };
    };

    render() {
        const {
            ticketId, cardsList, upiInfo, getUPIInfo,
            toggleUPIStatus,
        } = this.props;

        return (
            <Switch>
                <Route
                    path={BA_DEBIT_INSTRUMENT_TICKET_PATH}
                    render={
                        (props) => (
                            <DebitCard
                                {...props}
                                {...this.getCommonProps()}
                                {...cardsList[ticketId]}
                            />
                        )
                    }
                />
                <Route
                    path={BA_UPI_INSTRUMENT_TICKET_PATH}
                    render={
                        (props) => (
                            <UPI
                                {...props}
                                {...this.getCommonProps()}
                                {...upiInfo[ticketId]}
                                getUPIInfo={getUPIInfo}
                                toggleUPIStatus={toggleUPIStatus}
                            />
                        )
                    }
                />
            </Switch>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    cardsList: makeSelectCardsList(),
    upiInfo: makeSelectUPIInfo(),
});

const mapDispatchToProps = (dispatch) => ({
    getUPIInfo: (data) => dispatch(getUPIInfoApi(data)),
    toggleUPIStatus: (data) => dispatch(toggleUPIStatusApi(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'paymentInstruments', reducer });
const withSaga = injectSaga({ key: 'paymentInstruments', saga });

export default compose(
    withReducer,
    withSaga,
    withConnect,
)(PaymentInstruments);

import React from 'react';

import { Button, Modal } from '../../../components';

const customStylesForModal = {
    content: {
        top: '15%',
        padding: 32,
        width: 900,
    },
};

const TncAndStepsToRedeem = (props) => {
    const {
        visible, data = {}, toggleHandler, v2 = false,
    } = props;

    if (!visible) return null;

    let { tnc, stepsToRedeem } = data;

    const { unlockSteps } = data;

    let tncLabel;
    let stepsToRedeemLabel;

    if (v2) {
        tncLabel = tnc?.label;
        stepsToRedeemLabel = stepsToRedeem?.label;
        tnc = tnc?.value;
        stepsToRedeem = stepsToRedeem?.value;
    }

    return (
        <Modal
            visible={visible}
            modalStyles={customStylesForModal}
            contentLabel='TnC & Steps to Redeem Modal'
        >
            <div className='rewards-modal-cr'>
                {
                    tnc?.length ? (
                        <React.Fragment>
                            <div className='rewards-modal-hd'>{tncLabel || 'Terms and Conditions'}</div>
                            <div className='rewards-modal-ctwr'>
                                {tnc.map((si) => (
                                    <div className='rewards-modal-ct'>• {si}</div>
                                ))}
                            </div>
                        </React.Fragment>
                    ) : null
                }
                {
                    stepsToRedeem?.length ? (
                        <React.Fragment>
                            <div className='rewards-modal-hd'>{stepsToRedeemLabel || 'Steps to Redeem'}</div>
                            <div className='rewards-modal-ctwr'>
                                {stepsToRedeem.map((si) => (
                                    <div className='rewards-modal-ct'>• {si}</div>
                                ))}
                            </div>
                        </React.Fragment>
                    ) : null
                }
                {
                    unlockSteps?.value ? (
                        <React.Fragment>
                            <div className='rewards-modal-hd'>{unlockSteps?.label || 'Unlock Steps'}</div>
                            <div className='rewards-modal-ctwr'>
                                {unlockSteps?.value.map((si) => (
                                    <div className='rewards-modal-ct'>• {si}</div>
                                ))}
                            </div>
                        </React.Fragment>
                    ) : null
                }
            </div>
            <Button
                v2
                primary
                extClasses='rewards-modal-cta'
                label='Close'
                onClick={() => toggleHandler(false)}
            />
        </Modal>
    );
};

export default TncAndStepsToRedeem;

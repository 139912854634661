import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { PROFILE_ROOT } from '../../api/routes';

import {
    GET_USER_DETAILS, SET_USER_DETAILS, ERR_USER_DETAILS,
} from './constants';

function* getUserDetailsById(action) {
    const { monorailId, accountNumber, reason } = action.data;
    const queryString = getQueryStringFromObject({ monorailId, accountNumber, reason: btoa(reason) });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PROFILE_ROOT}/user-info?${queryString}`,
        );

        yield put({ type: SET_USER_DETAILS, data: { monorailId, ...response }});
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_DETAILS, data: { monorailId } });
    }
}

export default function* userDetailsByIdSaga() {
    yield all(
        [
            yield takeLatest(GET_USER_DETAILS, getUserDetailsById),
        ]
    );
}

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { CONNECTED_ACCOUNTS_ROOT } from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_ACCOUNTS_LIST, SET_ACCOUNTS_LIST, ERR_ACCOUNTS_LIST,
} from './constants';

function* getAccountsList(action) {
    const { ticketId, userId, index } = action.data;
    const queryParams = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CONNECTED_ACCOUNTS_ROOT}?${queryParams}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_ACCOUNTS_LIST, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_ACCOUNTS_LIST, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ACCOUNTS_LIST, data: { ticketId }});
    }
}

export default function* connectedAccountsSaga() {
    yield all(
        [
            yield takeLatest(GET_ACCOUNTS_LIST, getAccountsList),
        ]
    );
}

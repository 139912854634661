/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @file RiskRecentActivity/index.js - Contains the whole layout and view for risk recent activity tab
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    Button, ExpandableCard, FormDropdown, FormWithSingleInput, Table, ComponentLoader,
} from '../../components';

import { clearRecentActivities, getRecentActivities, getRecentActivityAreas } from './actions';
import { selectRecentActivityData } from './selectors';

import './style.scss';
import { defaultDropdownOption, loaderType } from './constants';

const RecentActivity = (props) => {
    const { caseId } = props;

    const dispatch = useDispatch();
    const [formErrLabel, setFormErrLabel] = useState();
    const [selectedAreas, setSelectedAreas] = useState(defaultDropdownOption);
    const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 20)));
    const [toDate, setToDate] = useState(new Date());

    let tableData;
    let nextToken = null;
    let prevToken = null;

    const recentActivityData = useSelector(selectRecentActivityData, shallowEqual);

    const { activityAreas, loading, errorScreenVisibility } = recentActivityData;

    if (recentActivityData) {
        ({
            data: tableData, next: nextToken, prev: prevToken,
        } = recentActivityData || {});
    }

    const [isRecentActivityCardOpen, setIsRecentActivityCardOpen] = useState(true);

    const clearAllSelection = () => {
        setSelectedAreas(null);
        setFromDate();
        setToDate();
        dispatch(clearRecentActivities({
            activityAreas: [],
        }));
    };

    const fetchRecentActivity = () => {
        if (!fromDate || !toDate || !selectedAreas || selectedAreas.length < 1) {
            setFormErrLabel('Enter all required fields marked with *');
            dispatch(clearRecentActivities({
                activityAreas: [],
            }));
            return;
        }
        setFormErrLabel('');
        dispatch(getRecentActivities({
            caseId,
            fromDate: fromDate.getTime(),
            toDate: toDate.getTime(),
            selectedAreas: selectedAreas.map((item) => item.label),
            prevToken: null,
            nextToken: null,
        }));
    };

    // Generalizing date change function to have end date once added in future
    const handleDateChange = (key) => (value) => {
        switch (key) {
            case 'fromDate': setFromDate(value);
                break;
            case 'toDate': setToDate(value);
                break;
            default: break;
        }
    };

    useEffect(() => {
        dispatch(getRecentActivityAreas());
    }, []);

    const renderErrorField = () => {
        if (formErrLabel) {
            return (
                <div className='recent-activity__errlb'>{formErrLabel}</div>
            );
        }
        return <div />;
    };

    const renderDateBlock = (key) => {
        let label;
        let labelId;
        let value;
        let maxDate;
        let minDate;

        switch (key) {
            case 'fromDate':
                label = 'From Date*';
                labelId = 'recent-activity_from-date';
                value = fromDate;
                maxDate = new Date();
                minDate = fromDate ? new Date(fromDate.minValue) : null;
                break;

            case 'toDate':
                label = 'To Date';
                labelId = 'recent-activity_to-date';
                value = toDate;
                maxDate = toDate ? new Date(toDate.maxValue) : null;
                minDate = fromDate;
                break;

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'comms-form-container--m0',
                    label: `recent-activity-label ${labelId}`,
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: handleDateChange(key),
                    maxDate,
                    minDate,
                }}
                hideErrLabel
            />
        );
    };

    const paginatedClickHandler = (item) => {
        const { prev, next } = item;
        dispatch(getRecentActivities({
            caseId,
            fromDate,
            toDate,
            selectedAreas: selectedAreas.map((area) => area.label),
            prevToken: prev,
            nextToken: next,
        }));
    };

    const renderFormMultiSelectDD = (key) => {
        let label;
        let placeholder;
        let value;
        let onChange;

        switch (key) {
            case 'area': {
                label = 'Search by Areas';
                placeholder = 'select multiple areas if required';
                value = selectedAreas;
                onChange = (ddValue) => {
                    setSelectedAreas(ddValue);
                };
                break;
            }

            default:
                label = 'Default';
        }

        return (
            <div className='frcWrapper risk-recent-activity-field-container'>
                <div className='recent-activity-label'>{label}</div>
                <FormDropdown
                    isMulti
                    options={(activityAreas || []).map((item) => ({ value: item, label: item }))}
                    input={{
                        value,
                        onChange,
                        placeholder,
                    }}
                    cacheKey='agentform-accesslevel'
                    extStyles={{
                        container: 'create-agent-dd',
                    }}
                />
            </div>
        );
    };

    const renderAuthExpiredScreen = () => (
        <div className='account-statement-error-section'>
            <div className='ticket-modal__heading'>
                Recent Activity View is not available for this case
            </div>
        </div>
    );

    return (
        <div className='risk-recent-activity-outer-wrapper mt-30'>
            {errorScreenVisibility ? renderAuthExpiredScreen()
                : (
                    <React.Fragment>
                        <div className='risk-recent-activity-search-panel'>
                            <ComponentLoader visible={loading === loaderType.RECENT_ACTIVITY_AREA} text='loading activity area...' />
                            <ExpandableCard
                                extClasses='risk-recent-activity'
                                heading='Recent Activity Search'
                                isExpanded={isRecentActivityCardOpen}
                                setIsExpanded={() => setIsRecentActivityCardOpen(!isRecentActivityCardOpen)}
                            >
                                <div className='risk-recent-activity-sc'>
                                    <div>{renderFormMultiSelectDD('area')}</div>
                                    <div className='frwpWrapper risk-recent-activity-sc__gap'>
                                        {renderDateBlock('fromDate')}
                                        {renderDateBlock('toDate')}
                                    </div>
                                    {renderErrorField()}
                                    <div className='frcWrapper risk-recent-activity-sc__3r'>
                                        <Button
                                            extClasses='risk-recent-activity-sc__cta'
                                            primary
                                            label='Search'
                                            onClick={fetchRecentActivity}
                                        />
                                        <div
                                            className='link risk-recent-activity-sc__clear'
                                            onClick={clearAllSelection}
                                            role='presentation'
                                        >
                                            Clear All Selection
                                        </div>
                                    </div>
                                </div>
                            </ExpandableCard>
                        </div>
                        <div className='risk-recent-activity-table__container'>
                            <ComponentLoader
                                visible={loading === loaderType.RECENT_ACTIVITY_DETAILS}
                                text='loading recent activies'
                            />
                            {tableData && (
                                <Table
                                    rowDataVersion={2}
                                    objKey='header_key'
                                    labelData={tableData.columnData}
                                    contentData={tableData.rowData}
                                    prevToken={prevToken}
                                    nextToken={nextToken}
                                    paginatedClickHandler={paginatedClickHandler}
                                    extClasses={{
                                        container: 'risk-activity-src2',
                                    }}
                                />
                            )}
                        </div>
                    </React.Fragment>
                )}
        </div>
    );
};

export default RecentActivity;

/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */
/**
 * @file Manages the reducer state & function for transaction queue container
 */

import { fromJS } from 'immutable';

import {
    GET_TRANSACTION_QUEUE, SET_TRANSACTION_QUEUE, ERR_TRANSACTION_QUEUE,
    GET_TRANSACTION_QUEUE_FILTER, SET_TRANSACTION_QUEUE_FILTER, ERR_TRANSACTION_QUEUE_FILTER,
    RESET_TRANSACTION_QUEUE, getDefaultParamsValue, GET_PRIORITIZED_CASE, SET_PRIORITIZED_CASE,
    ERR_PRIORITIZED_CASE,
} from './constants';

const initialState = fromJS({
    fileEntries: {
        columnData: [],
        rowData: [],
        prevToken: '',
        nextToken: '',
    },
    filters: {
        riskTxnQueue: {},
        userRisk: {},
        escalations: {},
    },
    loading: false,
    parameterList: [],
    err: '',
});

// Prefilling default filter config
const getParameterListPrefill = (parameterList = [], filterConfig) => {
    if (parameterList && parameterList.length > 0) {
        parameterList.forEach((item) => {
            const getDefaultValue = filterConfig.find((list) => list.id === item.name);
            // config defaultvalue
            if (getDefaultValue?.value) item.value = getDefaultValue.value;
            if (getDefaultValue?.minDate) item.minDate = getDefaultValue.minDate;
            if (getDefaultValue?.isDisabled) item.isDisabled = true;
        });
    }

    return parameterList;
};

function transactionQueueReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TRANSACTION_QUEUE: {
            const { listType, curParameterList = null, filters = null } = action.data;

            const filterConfig = {
                savedfilterlist: curParameterList?.length ? curParameterList : null,
                savedFilterValues: filters?.length ? filters : null,
            };

            return state
                .setIn(['loading'], true)
                .setIn(['filters', listType], filterConfig)
                .setIn(['err'], '');
        }

        case SET_TRANSACTION_QUEUE: {
            const { fileEntries } = action.data;

            return state
                .setIn(['fileEntries'], fromJS(fileEntries))
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }

        case ERR_TRANSACTION_QUEUE: {
            const { err } = action.data;

            return state
                .setIn(['fileEntries'], fromJS({}))
                .setIn(['loading'], false)
                .setIn(['err'], err);
        }

        case GET_TRANSACTION_QUEUE_FILTER:
            return state
                .setIn(['err'], '');

        case SET_TRANSACTION_QUEUE_FILTER: {
            const { parameterList, listType } = action.data;

            return state
                .setIn(['parameterList'], fromJS(getParameterListPrefill(parameterList, getDefaultParamsValue({ listType }))))
                .setIn(['err'], '');
        }

        case ERR_TRANSACTION_QUEUE_FILTER: {
            return state
                .setIn(['parameterList'], fromJS([]))
                .setIn(['loading'], false);
        }

        case GET_PRIORITIZED_CASE:
            return state
                .setIn(['loading'], true);

        case SET_PRIORITIZED_CASE:
        case ERR_PRIORITIZED_CASE:
            return state
                .setIn(['loading'], false);

        case RESET_TRANSACTION_QUEUE: {
            return initialState
                .setIn(['filters'], state.get('filters'));
        }

        default:
            return state;
    }
}

export default transactionQueueReducer;

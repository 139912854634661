/**
 * @file Liveness and facematch SingleCase
 * Review a single liveness and facematch case and update the status
 */

import React, { memo, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedSelector } from '../../../hooks';
import { ExpandableCard } from '../../../components';
import { MetaInfoContext } from '../../../context';

import { SingleCaseHeading, DequeueCase } from '../../RiskOps';
import { dequeueRiskOpsCaseAction, setRiskOpsCurViewAction } from '../../RiskOps/actions';
import { selectRiskOpsCurCase, selectRiskOpsPayloadType, selectRiskOpsQueueFilters } from '../../RiskOps/selectors';
import { getAdditionalFilters, getIdsFromCase } from '../../RiskOps/utils';
import { RISK_OPS_VIEW_ALL_CASES } from '../../RiskOps/constants';
import FacematchCaseInfo from '../../FacematchReviewV2/CaseInfo';
import LivenessCaseInfo from '../../LivenessReviewV2/CaseInfo';

import { LIVENESS_AND_FACEMATCH_REVIEW_KEY as CONTAINER_KEY } from '../constants';

const getReviewInfo = (curCase) => {
    const { payload } = curCase;
    const { review_type: reviewType } = curCase[payload];
    const hasFacematchReview = ['REVIEW_TYPE_FACEMATCH_ONLY', 'REVIEW_TYPE_LIVENESS_AND_FACEMATCH'].includes(reviewType);
    const hasLivenessReview = ['REVIEW_TYPE_LIVENESS_ONLY', 'REVIEW_TYPE_LIVENESS_AND_FACEMATCH'].includes(reviewType);

    return {
        reviewType, hasFacematchReview, hasLivenessReview,
    };
};

const SingleCase = () => {
    const metaInfo = useContext(MetaInfoContext);
    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;
    const dispatch = useDispatch();

    const payloadType = useMemoizedSelector(selectRiskOpsPayloadType, [CONTAINER_KEY]);
    const curCase = useMemoizedSelector(selectRiskOpsCurCase, [CONTAINER_KEY]);
    const { fromDate, toDate, sortOption } = useMemoizedSelector(selectRiskOpsQueueFilters, [CONTAINER_KEY]);

    let defaultLivenessCardState = true;
    let defaultFacematchCardState = false;

    const { reviewType, hasFacematchReview, hasLivenessReview } = getReviewInfo(curCase);

    if (reviewType === 'REVIEW_TYPE_FACEMATCH_ONLY') {
        defaultLivenessCardState = false;
        defaultFacematchCardState = true;
    }

    const [isLivenessCardExpanded, setLivenessCardExpanded] = useState(defaultLivenessCardState);
    const [isFacematchCardExpanded, setFacematchCardExpanded] = useState(defaultFacematchCardState);

    const { actorId, recordId } = getIdsFromCase(curCase);
    const { index } = curCase;

    const onBackArrowCTA = () => {
        dispatch(setRiskOpsCurViewAction({ containerKey: CONTAINER_KEY, newView: RISK_OPS_VIEW_ALL_CASES }));
    };

    const dequeueAction = dequeueRiskOpsCaseAction({
        containerKey: CONTAINER_KEY,
        actorId,
        recordId,
        index,
        queryParams: {
            payloadType: payloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        },
        customContext: {
            bannersVersion,
        },
    });

    return (
        <div className='rops-cr'>
            <SingleCaseHeading
                title='Review Liveness & Facematch Case'
                onBackArrowCTA={onBackArrowCTA}
            />
            {hasLivenessReview && (
                <ExpandableCard
                    extClasses='rops-ec my-30 p-30'
                    extHeadingClasses='p-0'
                    heading='Liveness Review'
                    isExpanded={isLivenessCardExpanded}
                    setIsExpanded={() => {
                        setLivenessCardExpanded(!isLivenessCardExpanded);
                        setFacematchCardExpanded(false);
                    }}
                >
                    <LivenessCaseInfo
                        containerKey={CONTAINER_KEY}
                        dequeueOnSubmit={false}
                    />
                </ExpandableCard>
            )}

            {hasFacematchReview && (
                <ExpandableCard
                    extClasses='rops-ec my-30 p-30'
                    extHeadingClasses='p-0'
                    heading='Facematch Review'
                    isExpanded={isFacematchCardExpanded}
                    setIsExpanded={() => {
                        setFacematchCardExpanded(!isFacematchCardExpanded);
                        setLivenessCardExpanded(false);
                    }}
                >
                    <FacematchCaseInfo
                        containerKey={CONTAINER_KEY}
                        dequeueOnSubmit={false}
                    />
                </ExpandableCard>
            )}

            <DequeueCase dequeueAction={dequeueAction} />
        </div>
    );
};

export default memo(SingleCase);

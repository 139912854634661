/*
 *
 * Wealth Onboarding Reducer
 *
 */

import { fromJS } from 'immutable';

import {
    GET_ONBOARDING_INFO, SET_ONBOARDING_INFO, ERR_ONBOARDING_INFO,
} from './constants';

const initialState = fromJS({
    onboarding: {},
});

function wealthOnboardingReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ONBOARDING_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['onboarding', ticketId, 'loading'], true)
                .setIn(['onboarding', ticketId, 'err'], '');
        }

        case SET_ONBOARDING_INFO: {
            const { ticketId, onboardingInfo } = action.data;

            return state
                .setIn(['onboarding', ticketId, 'loading'], false)
                .setIn(['onboarding', ticketId, 'hasOnboardingInfo'], true)
                .setIn(['onboarding', ticketId, 'onboardingInfo'], fromJS(onboardingInfo));
        }

        case ERR_ONBOARDING_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['onboarding', ticketId, 'loading'], false)
                .setIn(['onboarding', ticketId, 'hasOnboardingInfo'], false)
                .setIn(['onboarding', ticketId, 'onboardingInfo'], fromJS({}))
                .setIn(['onboarding', ticketId, 'err'], err);
        }

        default:
            return state;
    }
}

export default wealthOnboardingReducer;

import { createSelector } from 'reselect';

const selectUDBI = state => state.get('userDetailsById');
const selectUserDetails = state => state.getIn(['userDetailsById', 'userDetails']);
const selectActiveId = state => state.getIn(['userDetailsById', 'activeId']);
const selectNavItems = state => state.getIn(['userDetailsById', 'navItems']);

const makeSelectUserDetails = () => createSelector(
    selectUserDetails,
    userDetails => userDetails.toJS(),
);

const makeSelectActiveId = () => createSelector(
    selectActiveId,
    activeId => activeId,
);

// const makeSelectHorizontalIdNavbarItems = () => createSelector(
//     selectUserDetails,
//     userDetails => {
//         const rawUserDetails = userDetails.toJS();
//         const navbarItems = [];

//         const userDetailsKeys = Object.keys(rawUserDetails);
//         userDetailsKeys.forEach(item => {
//             navbarItems.push({
//                 id: item,
//                 isActive: rawUserDetails[item].isActive,
//             });
//         });

//         return navbarItems;
//     },
// );

const makeSelectHorizontalIdNavbarItems = () => createSelector(
    selectNavItems,
    navItems => navItems.toJS(),
);

export {
    makeSelectUserDetails,
    makeSelectActiveId,
    makeSelectHorizontalIdNavbarItems,
};

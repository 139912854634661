/* eslint-disable import/no-extraneous-dependencies, jsx-a11y/label-has-associated-control, react/forbid-prop-types */

/**
*
* FormDropdown
*
*/

import React, { memo, useMemo } from 'react';
import Select, { NonceProvider } from 'react-select';
import classNames from 'classnames';

// import { TokensContext } from '../../context';

import './style.scss';

// change height from 34 to 36 to avoid scroling issue
const HEIGHT = 36;
const HEIGHT_WITH_BORDER = 36;

const FormDropdown = (props) => {
    // currently nonce is not used because we are setting style-src 'unsafe-inline', but should be used in the future
    // const { cspNonce } = useContext(TokensContext);
    const {
        options, label, isLoading, isMulti, cacheKey, components, extStyles, neverEmpty, extCustomStyles = {}, input: {
            value, onChange, placeholder, autoFocus, isClearable, isDisabled = false, defaultMenuIsOpen = false,
            inputValue, onInputChange, onFocus, defaultValue,
        },
    } = props;

    if (!(extCustomStyles && extCustomStyles?.option)) extCustomStyles.option = () => ({});

    const memoizedCustomStyles = useMemo(() => ({
        control: (baseStyles) => ({
            ...baseStyles,
            borderColor: '#AFB0B9',
            borderRadius: 6,
            boxShadow: 'none',
            minHeight: HEIGHT,
            height: HEIGHT_WITH_BORDER,
            '&:hover': {
                borderColor: '#AFB0B9',
            },
            ...extCustomStyles?.control,
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            color: '#8D8D8D',
            fontSize: 16,
            ...extCustomStyles?.placeholder,
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            color: '#000000',
            fontSize: 16,
            ...extCustomStyles?.singleValue,
        }),
        indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            height: HEIGHT,
            minHeight: HEIGHT,
            ...extCustomStyles?.indicatorsContainer,
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            cursor: 'pointer',
            height: HEIGHT,
            minHeight: HEIGHT,
            padding: '0px 8px',
            overflow: 'auto',
            ...extCustomStyles?.valueContainer,
        }),
        input: (baseStyles) => ({
            ...baseStyles,
            margin: 0,
            marginLeft: 2,
            paddingTop: 0,
            paddingBottom: 0,
            ...extCustomStyles?.input,
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isSelected ? '#00b899 !important' : 'transparent',

            '&:hover': {
                backgroundColor: 'rgba(0, 184, 153, 0.1)',
                cursor: 'pointer',
            },
            ...extCustomStyles?.option(state),
        }),
    }), [extCustomStyles]);

    const containerStyles = classNames('form-group dropdown-wrapper', extStyles && extStyles.container);
    const labelStyles = classNames(extStyles && extStyles.label);
    const inputStyles = classNames('form-dropdown', extStyles && extStyles.inputField);

    return (
        <div className={containerStyles}>
            <label className={labelStyles}>{label}</label>
            <NonceProvider
                // nonce={cspNonce}
                cacheKey={cacheKey}
            >
                <Select
                    isMulti={isMulti}
                    styles={memoizedCustomStyles}
                    options={options}
                    onChange={onChange}
                    value={neverEmpty ? (value || defaultValue) : value}
                    inputValue={inputValue}
                    onInputChange={onInputChange}
                    className={inputStyles}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    defaultMenuIsOpen={autoFocus || defaultMenuIsOpen}
                    isClearable={isClearable}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    onFocus={onFocus}
                    components={components}
                    defaultValue={defaultValue}
                />
            </NonceProvider>
        </div>
    );
};

export default memo(FormDropdown);

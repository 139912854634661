/**
 * @file - Toggle Component
 */

import React, { memo } from 'react';

import './style.scss';

const Toggle = (props) => {
    const { label, value, onChange } = props;

    return (
        <div className='form-group toggle'>
            <label>{label}</label>
            <div className='switch' onClick={onChange}>
                <input type='checkbox' checked={value} readOnly />
                <span className='slider round' />
            </div>
        </div>
    );
};

export default memo(Toggle);

/**
 * @file ViewSalaryAccountDetails
 * View the salary account details for a specific user
 * This component gets used at 2 places:
 * 1. SalaryDataOps - Agents can search using Email ID or phone number and they can update employer details
 * 2. CX - Agents can only view salary account details for a particular ticket
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedSelector } from '../../../hooks';
import { Loader } from '../../../components';

import { selectViewSalaryAccountData, selectViewSalaryAccountErr, selectViewSalaryAccountLoading } from '../selectors';
import { getSalaryAccountDetailsForDataOpsAction } from '../actions';
import { SALARY_ACCOUNT_DETAILS_KEY, SALARY_ACCOUNT_OPS_KEY } from '../constants';

import SalaryIdentifierInput from '../SalaryIdentifierInput';
import ViewEmploymentDetails from '../ViewEmploymentDetails';
import ViewKycAndAccountDetails from '../ViewKycAndAccountDetails';

const ViewSalaryAccountDetails = (props) => {
    const { screenKey, isSalaryDataOps } = props;

    const dispatch = useDispatch();

    const loading = useMemoizedSelector(selectViewSalaryAccountLoading, [screenKey]);
    const err = useMemoizedSelector(selectViewSalaryAccountErr, [screenKey]);
    const salaryData = useMemoizedSelector(selectViewSalaryAccountData, [screenKey]);

    const handleSubmit = (identifier) => {
        dispatch(getSalaryAccountDetailsForDataOpsAction({
            screenKey: SALARY_ACCOUNT_OPS_KEY,
            userIdentifier: identifier,
        }));
    };

    return (
        <React.Fragment>
            <div className='heading1 mb-60'>Salary Account Details</div>
            <SalaryIdentifierInput
                isSalaryDataOps={isSalaryDataOps}
                screenKey={SALARY_ACCOUNT_DETAILS_KEY}
                onSubmit={handleSubmit}
            />
            <ViewEmploymentDetails
                allowEmployerNameUpdate={isSalaryDataOps}
                employmentInfo={salaryData?.employmentInfo}
            />
            <ViewKycAndAccountDetails
                kycInfo={salaryData?.kycInfo}
                salaryProgramInfo={salaryData?.salaryProgramInfo}
            />
            {err && <div className='err-label salops-err'>{err}</div>}
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(ViewSalaryAccountDetails);

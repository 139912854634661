/**
 * @file MilestoneRewardsScreen: Component to show credit card milestone rewards
 */

import React, { memo } from 'react';

import CardBanner from '../CardBanner';

import '../style.scss';
import { Loader, Table } from '../../../components';
import { getDisplayLableValuePairsForMilestoneRewardsSection } from '../constants';

const TableDetailScreen = (props) => {
    const {
        data = {}, err, loading, ticketId, currentScreen,
    } = props;

    return (
        <CardBanner discardCurrentScreen={currentScreen} ticketId={ticketId}>
            {data && Array.isArray(data) && data?.map((detailsDatum) => (
                <div className='mb-30'>
                    <div className='credit-card-rewards-heading'>{detailsDatum?.title}</div>
                    <div className='mb-30'>
                        {detailsDatum && getDisplayLableValuePairsForMilestoneRewardsSection(detailsDatum).map((lbPair) => (
                            <div className='credit-card-rewards-container'>
                                <div className='credit-card-rewards-lb'>{lbPair?.label}</div>
                                <div className='credit-card-rewards--val'>{lbPair?.value || '-'}</div>
                            </div>
                        ))}
                    </div>
                    <Table
                        objKey='header_key'
                        labelData={detailsDatum?.renewalFeesTable?.columnData}
                        contentData={detailsDatum?.renewalFeesTable?.rowData}
                        header={detailsDatum?.renewalFeesTable?.tableName}
                        extClasses={{
                            container: 'mt-15',
                        }}
                        rowDataVersion={2}
                    />

                    <div className='credit-card-rewards-table-lb mt-30'>{detailsDatum?.milestoneRewardsTable?.tableName}</div>
                    {detailsDatum?.milestoneRewardsTable && detailsDatum?.milestoneRewardsTable?.columnData?.length > 0
                        ? (
                            <Table
                                objKey='header_key'
                                labelData={detailsDatum?.milestoneRewardsTable?.columnData}
                                contentData={detailsDatum?.milestoneRewardsTable?.rowData}
                                rowDataVersion={2}
                            />

                        )
                        : <div>{detailsDatum?.milestone_reward_info}</div>}

                </div>
            ))}
            <Loader visible={loading} />
            {err ? <div className='error-label1 wspace-pre-wrap'>{err}</div> : null}
        </CardBanner>
    );
};

export default memo(TableDetailScreen);

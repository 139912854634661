/**
 * @file Manages the action functions for Wealth P2P component
 */

import {
    GET_INVESTOR_INFO, GET_INVESTMENT_LIST,
} from './constants';

export const getInvestorInfo = data => ({
    type: GET_INVESTOR_INFO,
    data,
});

export const getInvestmentList = data => ({
    type: GET_INVESTMENT_LIST,
    data,
});

/* eslint-disable default-param-last */
/**
 * @file Manages the reducer state & function for Wealth USStocks component
 */

import { fromJS } from 'immutable';

import {
    ERR_WEALTH_USSTOCKS, GET_ACCOUNT_BASIC_DETAILS, GET_ACCOUNT_STAGES_DETAILS, GET_ACTIVITIES, GET_ACTIVITY_DETAILS, GET_PORTFOLIO_DETAILS,
    RESET_ACTIVITIES, RESET_SEARCH_PARAMS, SET_ACCOUNT_BASIC_DETAILS, SET_ACCOUNT_STAGES_DETAILS, SET_ACCOUNT_VIEW, SET_ACTIVITIES,
    SET_ACTIVITY_DETAILS, SET_ACTIVITY_TYPE, SET_ACTIVITY_TYPE_LIST, SET_PORTFOLIO_DETAILS, SET_SEARCH_PARAMS, SET_USSTOCKS_VIEW,
    VIEW_ACCOUNT_BASIC_DETAILS, ERR_US_STOCKS, GET_REMITTANCES, SET_REMITTANCES, RESET_REMITTANCES, GET_REMITTANCE_ORDER_DETAILS,
    SET_REMITTANCE_ORDER_DETAILS,
    SET_REMITTANCE_TYPE_LIST,
} from './constants';

const initialState = fromJS({
    portfolioDetails: {},
    accountView: VIEW_ACCOUNT_BASIC_DETAILS,
});

function usStocksReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PORTFOLIO_DETAILS: {
            const { ticketId } = action.data;

            return state
                .setIn(['investedAssetsList', ticketId, 'loading'], true)
                .setIn(['investedAssetsList', ticketId, 'err'], '');
        }

        case SET_PORTFOLIO_DETAILS: {
            const { ticketId, investedAssetsList } = action.data ?? {};

            if (!investedAssetsList) {
                return state;
            }

            const { data } = investedAssetsList ?? {};

            return state
                .setIn(['investedAssetsList', ticketId, 'loading'], false)
                .setIn(['investedAssetsList', ticketId, 'data'], fromJS(data));
        }

        case GET_ACTIVITIES: {
            const { ticketId } = action.data;

            return state
                .setIn(['activities', ticketId, 'loading'], true)
                .setIn(['activities', ticketId, 'err'], '');
        }

        case SET_ACTIVITIES: {
            const { ticketId, usStocksActivitiesList } = action.data;

            // removing this check causes Redux saga to crash hence if you are removing it, do it with caution. because
            // usStocksActivitiesList is undefined when api gives 500 error
            if (!usStocksActivitiesList) return state;

            const { data, prev, next } = usStocksActivitiesList || {};

            if (!usStocksActivitiesList) {
                return state;
            }

            return state
                .setIn(['activities', ticketId, 'loading'], false)
                .setIn(['activities', ticketId, 'data'], fromJS(data))
                .setIn(['activities', ticketId, 'prev'], fromJS(prev))
                .setIn(['activities', ticketId, 'next'], fromJS(next));
        }

        case RESET_ACTIVITIES: {
            const { ticketId } = action.data;

            return state
                .setIn(['activities', ticketId, 'loading'], false)
                .setIn(['activities', ticketId, 'data'], fromJS({}))
                .setIn(['activities', ticketId, 'prev'], fromJS({}))
                .setIn(['activities', ticketId, 'next'], fromJS({}));
        }

        case GET_ACTIVITY_DETAILS: {
            const { ticketId } = action.data;

            return state
                .setIn(['activityDetail', ticketId, 'loading'], true)
                .setIn(['activityDetail', ticketId, 'err'], '')
                .setIn(['activityDetail', ticketId, 'data'], fromJS({}))
                .setIn(['activityDetail', ticketId, 'hasData'], false);
        }

        case SET_ACTIVITY_DETAILS: {
            const { ticketId, usStockActivityDetail } = action.data;

            if (!usStockActivityDetail) {
                return state;
            }

            return state
                .setIn(['activityDetail', ticketId, 'loading'], false)
                .setIn(['activityDetail', ticketId, 'hasData'], true)
                .setIn(['activityDetail', ticketId, 'data'], fromJS(usStockActivityDetail));
        }

        case GET_REMITTANCES: {
            const { ticketId } = action.data;

            return state
                .setIn(['remittances', ticketId, 'loading'], true)
                .setIn(['remittances', ticketId, 'err'], '');
        }

        case SET_REMITTANCES: {
            const {
                ticketId, remittances, prev, next,
            } = action.data;

            if (!remittances) return {};

            return state
                .setIn(['remittances', ticketId, 'loading'], false)
                .setIn(['remittances', ticketId, 'data'], fromJS(remittances))
                .setIn(['remittances', ticketId, 'prev'], fromJS(prev))
                .setIn(['remittances', ticketId, 'next'], fromJS(next));
        }

        case RESET_REMITTANCES: {
            const { ticketId } = action.data;

            return state
                .setIn(['remittances', ticketId, 'loading'], false)
                .setIn(['remittances', ticketId, 'data'], fromJS({}))
                .setIn(['remittances', ticketId, 'prev'], fromJS({}))
                .setIn(['remittances', ticketId, 'next'], fromJS({}));
        }

        case GET_REMITTANCE_ORDER_DETAILS: {
            const { ticketId } = action.data;

            return state
                .setIn(['remittances', ticketId, 'order-details', 'loading'], true)
                .setIn(['remittances', ticketId, 'order-details', 'err'], '')
                .setIn(['remittances', ticketId, 'order-details', 'data'], fromJS({}))
                .setIn(['remittances', ticketId, 'order-details', 'hasData'], false);
        }

        case SET_REMITTANCE_ORDER_DETAILS: {
            const { ticketId, remittancesOrderDetails } = action.data;

            if (!remittancesOrderDetails) {
                return {};
            }

            return state
                .setIn(['remittances', ticketId, 'order-details', 'loading'], false)
                .setIn(['remittances', ticketId, 'order-details', 'hasData'], true)
                .setIn(['remittances', ticketId, 'order-details', 'data'], fromJS(remittancesOrderDetails));
        }

        case GET_ACCOUNT_BASIC_DETAILS: {
            const { ticketId } = action.data;

            return state
                .setIn(['accountBasicDetails', ticketId, 'loading'], true)
                .setIn(['accountBasicDetails', ticketId, 'err'], '');
        }

        case SET_ACCOUNT_BASIC_DETAILS: {
            const { ticketId, accountDetails } = action.data;

            if (!accountDetails) {
                return state;
            }

            return state
                .setIn(['accountBasicDetails', ticketId, 'loading'], false)
                .setIn(['accountBasicDetails', ticketId, 'data'], fromJS(accountDetails));
        }

        case GET_ACCOUNT_STAGES_DETAILS: {
            const { ticketId } = action.data;

            return state
                .setIn(['accountStageDetails', ticketId, 'loading'], true)
                .setIn(['accountStageDetails', ticketId, 'err'], '');
        }

        case SET_ACCOUNT_STAGES_DETAILS: {
            const { ticketId, usStockAccountStageList } = action.data;

            if (!usStockAccountStageList) {
                return state;
            }

            const { data } = usStockAccountStageList;

            return state
                .setIn(['accountStageDetails', ticketId, 'loading'], false)
                .setIn(['accountStageDetails', ticketId, 'data'], fromJS(data));
        }

        case SET_USSTOCKS_VIEW: {
            return state.set('usStocksView', action.data);
        }

        case SET_ACCOUNT_VIEW: {
            return state.set('accountView', action.data);
        }

        case SET_SEARCH_PARAMS: {
            return state.set('searchParams', fromJS(action.data));
        }

        case RESET_SEARCH_PARAMS: {
            return state.set('searchParams', fromJS({}));
        }

        case SET_ACTIVITY_TYPE_LIST: {
            const { list } = action.data;

            return state.set('activityTypes', fromJS(list));
        }

        case SET_ACTIVITY_TYPE: {
            const activityType = action.data;

            return state.set('activityType', fromJS(activityType));
        }

        case SET_REMITTANCE_TYPE_LIST: {
            const { list } = action.data;

            return state.set('remittanceTypes', fromJS(list));
        }

        case ERR_US_STOCKS: {
            const { containerKey, ticketId, err } = action.data;

            return state
                .setIn([containerKey, ticketId, 'loading'], false)
                .setIn([containerKey, ticketId, 'err'], err);
        }

        case ERR_WEALTH_USSTOCKS: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err);
        }

        default:
            return state;
    }
}

export default usStocksReducer;

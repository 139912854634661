/**
 * @file TextInput
 * Whitelisting users for B2B Onboarding using phone number or email id using input field(Max 10)
 */

import React, { memo } from 'react';

import { isNilOrEmpty, validators } from '../../../utils';
import { FIELD_TYPES, MODE_TYPES_INFO } from '../constants';

const TextInput = (props) => {
    const {
        input, setInput, modeType,
        htmlfor, displayText,
        setValidInput, fieldType,
        extClasses = {},
    } = props;

    const validateInput = (value) => {
        const inputArray = value.split(',');
        let isValid = true;
        const array = [];

        if (modeType === MODE_TYPES_INFO.PHONE_NUMBER.label) {
            inputArray.forEach((element) => {
                if (validators.isInputtedMobileNumberValid(element)) {
                    array.push(element);
                } else {
                    isValid = false;
                }
            });
        } else if (modeType === MODE_TYPES_INFO.EMAIL_ID.label) {
            inputArray.forEach((element) => {
                if (validators.isInputtedEmailValid(element)) {
                    array.push(element);
                } else {
                    isValid = false;
                }
            });
        }

        if (!isValid) return false;
        return true;
    };

    const handleInput = (e) => {
        setInput(e.target.value);
        const validate = validateInput(e.target.value);

        if (validate) {
            setValidInput(true);
        } else {
            setValidInput(false);
        }
    };

    if (isNilOrEmpty(displayText)) {
        return null;
    }

    return (
        <div>
            <div className={extClasses.container}>
                <label
                    htmlFor={htmlfor}
                    className={extClasses.label}
                >
                    {displayText}
                </label>
                {
                    fieldType === FIELD_TYPES.INPUT ? (
                        <input
                            className={extClasses?.input}
                            type='text'
                            value={input}
                            onChange={handleInput}
                        />
                    ) : (
                        <textarea
                            id={modeType}
                            className={extClasses?.textarea}
                            value={input}
                            onChange={handleInput}
                            maxLength={modeType === MODE_TYPES_INFO.PHONE_NUMBER.label ? 109 : null}
                            rows={1}
                        />
                    )
                }
            </div>
        </div>
    );
};

export default memo(TextInput);

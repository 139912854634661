/**
 * @file WealthOps constants
 */

// key to be used for reducers & selectors for wealth ops
export const WEALTH_OPS_KEY = 'wealthOps';

// action constants
export const GET_WEALTH_OPS_QUEUE = 'app/WealthOps/GET_WEALTH_OPS_QUEUE';
export const SET_WEALTH_OPS_QUEUE = 'app/WealthOps/SET_WEALTH_OPS_QUEUE';
export const ERR_WEALTH_OPS = 'app/WealthOps/ERR_WEALTH_OPS';
export const DEQUEUE_WEALTH_OPS_CASE = 'app/WealthOps/DEQUEUE_WEALTH_OPS_CASE';
export const SET_WEALTH_OPS_CUR_VIEW = 'app/WealthOps/SET_WEALTH_OPS_CUR_VIEW';
export const SET_WEALTH_OPS_CUR_CASE = 'app/WealthOps/SET_WEALTH_OPS_CUR_CASE';
export const SET_WEALTH_OPS_PAYLOAD_TYPE = 'app/WealthOps/SET_WEALTH_OPS_PAYLOAD_TYPE';
export const GET_WEALTH_OPS_LIVENESS_VIDEO = 'app/WealthOps/GET_WEALTH_OPS_LIVENESS_VIDEO';
export const SET_WEALTH_OPS_LIVENESS_VIDEO = 'app/WealthOps/SET_WEALTH_OPS_LIVENESS_VIDEO';
export const SET_WEALTH_OPS_QUEUE_FILTERS = 'app/WealthOps/SET_WEALTH_OPS_QUEUE_FILTERS';
export const GET_WEALTH_OPS_PENDING_REVIEW_COUNT = `app/${WEALTH_OPS_KEY}/GET_PENDING_REVIEW_COUNT`;
export const SET_WEALTH_OPS_PENDING_REVIEW_COUNT = `app/${WEALTH_OPS_KEY}/SET_PENDING_REVIEW_COUNT`;

// view constants
export const VIEW_ALL_WEALTH_OPS_CASES = 'app/WealthOps/View/ALL_CASES';
export const VIEW_SINGLE_WEALTH_OPS_CASE = 'app/WealthOps/View/SINGLE_CASE';

// value constants
export const PAYLOAD_TYPE_WEALTH_DATA = 'PAYLOAD_TYPE_WEALTH_DATA';
export const PAYLOAD_TYPE_KYC_DOCKET = 'PAYLOAD_TYPE_KYC_DOCKET';
export const QUEUE_PAYLOAD_TYPES = [PAYLOAD_TYPE_WEALTH_DATA, PAYLOAD_TYPE_KYC_DOCKET];
export const LIMIT_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 50, 70, 100];

export const PAYLOAD_DD_OG_STATE = {
    value: PAYLOAD_TYPE_WEALTH_DATA,
    isVisible: false,
};

export const LIMIT_DD_OG_STATE = {
    value: 2,
    isVisible: false,
};

export const LIVENESS_DEV_ACTION_TYPES = ['MARK_WEALTH_ONBOARDING_LIVENESS_PASSED'];
export const DOCUMENT_REDACTION_DEV_ACTION_TYPES = ['MARK_WEALTH_ONBOARDING_REDACTION_PASSED'];
export const DOCUMENT_EXPIRY_DEV_ACTION_TYPES = ['MARK_WEALTH_ONBOARDING_EXPIRY_PASSED'];

// liveness review table labels
export const WEALTH_OPS_LIVENESS_TABLE = [
    {
        id: 1,
        label: 'Actor ID',
        key: 'actor_id',
    },
    {
        id: 2,
        label: 'OTP',
        key: 'otp',
    },
    {
        id: 3,
        label: 'OTP Score',
        key: 'otp_score',
    },
    {
        id: 4,
        label: 'Liveness Score',
        key: 'liveness_score',
    },
    {
        id: 5,
        label: 'Manual Review ID',
        key: 'manual_review_id',
    },
];

// document review table labels
export const WEALTH_OPS_DOCUMENT_TABLE = [
    {
        label: 'Manual Review ID',
        key: 'manual_review_id',
        view: 'common',
    },
    {
        label: 'Confidence Score',
        key: 'confidence_score',
        view: 'common',
    },
    {
        label: 'Threshold Score',
        key: 'threshold_score',
        view: 'common',
    },
    {
        label: 'Vendor Review Flag',
        key: 'vendor_review_flag',
        view: 'common',
    },
    {
        label: 'Expiry Year',
        key: 'year',
        view: 'onlyExpiryReview',
    },
    {
        label: 'Expiry Month',
        key: 'money',
        view: 'onlyExpiryReview',
    },
    {
        label: 'Expiry Day',
        key: 'day',
        view: 'onlyExpiryReview',
    },
];

// All cases table labels
export const WEALTH_OPS_ALL_CASE_TABLE_LABEL_DATA = [
    {
        label: 'Sr. No',
        key: 'sr_no',
    },
    {
        label: 'Actor ID',
        key: 'actor_id',
    },
];

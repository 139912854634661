/**
 *
 * Generic app dropdown component
 *
 */

import React, { memo } from 'react';

import { getDropdownHeight } from '../../utils';

import DropdownContent from '../DropdownContent';
import DropdownWrapper from '../DropdownWrapper';

import './style.scss';

const AppDropdown = (props) => {
    const {
        ddWrapperCls,
        defaultDDLabel,
        ddContentCls,
        ddState,
        setDDState,
        ddList,
        ddItemCls,
        onValueChange,
    } = props;

    const setDDVisibility = (isVisible) => () => {
        setDDState({
            value: ddState.value,
            isVisible,
        });
    };

    const setDDValue = (value) => {
        setDDState({
            value,
            isVisible: false,
        });
    };

    const onAnsChange = (ansOpt) => () => {
        // fire event only if value is different that current one
        if (onValueChange && ansOpt !== ddState.value) onValueChange(ansOpt);
        setDDValue(ansOpt);
    };

    return (
        <DropdownWrapper
            visible={ddState.isVisible}
            onOutsideClickHandler={setDDVisibility(false)}
            extClasses={ddWrapperCls}
        >
            <button
                className='app-dd--btn'
                type='button'
                onClick={setDDVisibility(true)}
            >
                {ddState.value || defaultDDLabel}
            </button>
            <DropdownContent
                visible
                extClasses={ddContentCls}
                extStyles={{
                    height: ddState.isVisible
                        ? getDropdownHeight(ddList.length)
                        : 0,
                    overflow: ddState.isVisible ? 'auto' : 'hidden',
                }}
            >
                {ddList.map((ansOpt) => (
                    <button
                        type='button'
                        key={ansOpt}
                        className={ddItemCls}
                        onClick={onAnsChange(ansOpt)}
                    >
                        {ansOpt}
                    </button>
                ))}
            </DropdownContent>
        </DropdownWrapper>
    );
};

export default memo(AppDropdown);

import { createSelector } from 'reselect';

/**
 * Direct selector to the viewAgent state domain
 */
export const selectViewAgentDomain = () => (state) => state.get('viewAgent');

/**
 * Other specific selectors
 */


/**
 * Default selector used by ViewAgent
 */

const makeSelectViewAgent = () => createSelector(
  selectViewAgentDomain(),
  (substate) => substate.toJS()
);

export default makeSelectViewAgent;

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { COMMUNICATIONS_ROOT } from '../../api/routes';

import {
    GET_MESSAGE_LIST, SET_MESSAGE_LIST, ERR_MESSAGE_LIST, GET_MESSAGE_INFO, SET_MESSAGE_INFO,
    ERR_MESSAGE_INFO
} from './constants';
import { setAuthScreen } from '../Tickets/saga';

function* getMessageList(action) {
    const { ticketId, userId, index, from, to, prev, next } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId, from, to, medium: ['SMS'], prev, next });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${COMMUNICATIONS_ROOT}/message-list?${queryString}`
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_MESSAGE_LIST, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_MESSAGE_LIST, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_MESSAGE_LIST, data: { ticketId, err: e.message } });
    }
}

function* getMessageInfo(action) {
    const { ticketId, userId, index, id } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId, id });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${COMMUNICATIONS_ROOT}/message-info?${queryString}`
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_MESSAGE_INFO, data: { ticketId, id, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_MESSAGE_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_MESSAGE_INFO, data: { ticketId, err: e.message } });
    }
}

export default function* transactionsSaga() {
    yield all(
        [
            yield takeLatest(GET_MESSAGE_LIST, getMessageList),
            yield takeLatest(GET_MESSAGE_INFO, getMessageInfo),
        ]
    );
}

/**
 * @file Manages the saga generator functions for Instant Indexing component
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, toastify,
} from '../../utils';
import {
    INSTANT_INDEXING_ENDPOINT, NOTIFICATION_STATUS_ENDPOINT,
} from '../../api/routes';

import {
    INITIATE_UPDATE_URL, SET_UPDATE_URL, END_UPDATE_URL, GET_URL_STATUS, SET_URL_STATUS, END_URL_STATUS,
} from './constants';

function* updateUrl(action) {
    // action.data consists of url, requestType & Promise resolve fn
    const { urls, requestType, resolve } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            INSTANT_INDEXING_ENDPOINT,
            {
                urls,
                requestType,
            },
        );

        yield put({ type: SET_UPDATE_URL, data: response });
        toastify('Instant Indexing request sent successfully', 'success');

        if (resolve) {
            yield call(resolve);
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: END_UPDATE_URL });
    }
}

function* getUrlStatus(action) {
    // action.data consists of url & Promise resolve fn
    const { url, resolve } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${NOTIFICATION_STATUS_ENDPOINT}?url=${encodeURIComponent(url)}`,
        );

        yield put({ type: SET_URL_STATUS, data: response });

        if (resolve) {
            yield call(resolve);
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: END_URL_STATUS });
    }
}

export default function* instantIndexingSaga() {
    yield all(
        [
            yield takeLatest(INITIATE_UPDATE_URL, updateUrl),
            yield takeLatest(GET_URL_STATUS, getUrlStatus),
        ]
    );
}

/*
 *
 * FederalAgents constants
 *
 */
// get federal escalation ticket list
export const GET_TICKET_LIST = 'app/FA/GET_TICKET_LIST';
export const SET_TICKET_LIST = 'app/FA/SET_TICKET_LIST';
export const ERR_TICKET_LIST = 'app/FA/ERR_TICKET_LIST';

// get ticket notes
export const GET_TICKET_INFO = 'app/FA/GET_TICKET_INFO';
export const SET_TICKET_INFO = 'app/FA/SET_TICKET_INFO';
export const ERR_TICKET_INFO = 'app/FA/ERR_TICKET_INFO';

// get ticket groups
export const GET_TICKET_GROUPS = 'app/FA/GET_TICKET_GROUPS';
export const SET_TICKET_GROUPS = 'app/FA/SET_TICKET_GROUPS';
export const ERR_TICKET_GROUPS = 'app/FA/ERR_TICKET_GROUPS';

// update ticket group
export const UPDATE_TICKET_GROUP = 'app/FA/UPDATE_TICKET_GROUP';
export const SET_UPDATE_TICKET_GROUP_FLAG = 'app/FA/SET_UPDATE_TICKET_GROUP_FLAG';

export const TICKET_GROUPS = [
    'NON_SFTP_ESCALATIONS',
    'SFTP_PENDING_GROUP',
    'FEDERAL_UPDATES',
    'CALLBACK',
    'EPIFI_ESCALATION',
    /**
     * we don't want to show this group to federal agents
     * as every ticket would be of this group only
     */ 
    // 'FEDERAL_ESCALATIONS', 
    'L1_SUPPORT',
    'ESCALATED_CASES_CLOSURE',
    'L2_SUPPORT',
    'SFTP_ESCALATIONS',
    'L1_SUPPORT_WAITLIST'
];

export const getDetailedInfo = (data) => [
    {
        label: 'Name',
        value: data.name
    },
    {
        label: 'Status',
        value: data.status
    },
    {
        label: 'Term',
        value: data.term
    },
    {
        label: 'Interest Payout',
        value: data.interestPayout || '-'
    },
    {
        label: 'Auto Renewable',
        value: data.isAutoRenew ? 'Yes' : 'No'
    },
    {
        label: 'Nominee Registered',
        value: data.hasNominee ? 'Yes' : 'No'
    },
    {
        label: 'Partner Bank',
        value: data.partnerBank
    },
    {
        label: `Detailed Status${'\n'}(Status, Timestamp, Error)`,
        isArray: true,
        value: data.statusList,
        valueFields: ['status', 'createdTs', 'error'],
    },
];

/**
 * @file MandateDetails
 * Shows the Mandate Details of salary account
 */

import React, { memo, useEffect, useMemo } from 'react';
import { isEmpty } from 'ramda';
import { useDispatch } from 'react-redux';

import { LoaderOrError, TableV2 } from '../../../components';
import { useMemoizedSelector } from '../../../hooks';
import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';

import { selectSalaryAccountMandates } from '../selectors';
import { getSalaryAccountMandatesAction } from '../actions';
import { getTicketDetails } from '../utils';

import './style.scss';

const MandateDetails = (props) => {
    const { screenKey, parentProps } = props;
    const { ticketId, userId, index } = parentProps;

    const dispatch = useDispatch();

    let nextToken = null;
    let prevToken = null;
    let mandatesTable;
    let loading;
    let err;

    const mandateDetails = useMemoizedSelector(selectSalaryAccountMandates, [screenKey]);

    if (mandateDetails) {
        ({ loading, err } = mandateDetails);
        const { data, hasData } = mandateDetails;
        if (hasData) ({ mandatesTable, prev: prevToken, next: nextToken } = data);
    }

    const memoizedParentProps = useMemo(() => ({
        ticketId, userId, index,
    }), [ticketId, userId, index]);

    useEffect(() => {
        dispatch(getSalaryAccountMandatesAction({
            screenKey,
            payload: getTicketDetails(memoizedParentProps),
        }));
    }, [dispatch, screenKey, memoizedParentProps]);

    const paginatedClickHandler = (tokens) => {
        const { prev, next } = tokens;
        dispatch(getSalaryAccountMandatesAction({
            screenKey,
            payload: {
                ...getTicketDetails(memoizedParentProps),
                prevToken: prev,
                nextToken: next,
            },
        }));
    };

    if (isEmpty(mandatesTable)) return null;

    return (
        <React.Fragment>
            <div>
                <TableV2
                    columns={mandatesTable?.columnData}
                    rows={mandatesTable?.rowData}
                    prevToken={prevToken}
                    nextToken={nextToken}
                    paginatedClickHandler={paginatedClickHandler}
                    header={mandatesTable?.tableName}
                    loading={loading}
                    rowV2
                />
                <div className='mnda-note frcfeWrapper'>
                    Scroll for more
                    <img
                        className='mnda-note-img'
                        alt='back-arrow'
                        src={backArrowIcon}
                    />
                </div>
            </div>
            <LoaderOrError loading={loading} errLabel={err} />
        </React.Fragment>
    );
};

export default memo(MandateDetails);

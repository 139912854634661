// profile
export const GET_PROFILE_INFO = 'app/Profile/GET_PROFILE_INFO';
export const SET_PROFILE_INFO = 'app/Profile/SET_PROFILE_INFO';
export const ERR_PROFILE_INFO = 'app/Profile/ERR_PROFILE_INFO';
export const GET_USER_ISSUE_INFO = 'app/Profile/GET_USER_ISSUE_INFO';
export const SET_USER_ISSUE_INFO = 'app/Profile/SET_USER_ISSUE_INFO';
export const ERR_USER_ISSUE_INFO = 'app/Profile/ERR_USER_ISSUE_INFO';
export const GET_USER_BANNERS = 'app/Profile/GET_USER_BANNERS';
export const SET_USER_BANNERS = 'app/Profile/SET_USER_BANNERS';
export const ERR_USER_BANNERS = 'app/Profile/ERR_USER_BANNERS';

export const ALTERNATE_ACCOUNT_VERIFICATION_LABEL_DATA = [
    {
        id: 1,
        label: 'Status',
        key: 'status',
    },
    {
        id: 2,
        label: 'Failure Reason',
        key: 'failureReason',
    },
    {
        id: 3,
        label: 'Last Updated At',
        key: 'lastUpdatedAt',
    },
];

/**
 * @file Constants for Transaction risk container
 */

export const TRANSACTION_RISK_DEV_ACTION_TYPES = ['PERFORM_RISK_REVIEW_ACTION'];

export const TXN_LIST_VIEW = 'txnListView';
export const TXN_DETAIL_VIEW = 'txnDetailView';
export const USER_LIST_VIEW = 'userListView';
export const USER_DETAIL_VIEW = 'userDetailView';
export const CASE_DETAIL_VIEW = 'caseDetailView';

/* eslint-disable react/jsx-no-comment-textnodes */
/**
 *
 * Header - Component to show the header of the App with Logo, Access Level dropdown & Profile dropdown
 *
 */

import React, { memo, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import classNames from 'classnames';
import { AppHeader } from '../../../components';
import { isNilOrEmpty, isDevEnviroment } from '../../../utils';

import { DEFAULT_TICKET_PATH } from '../routes';
import { makeSelectAccessLevel, makeSelectBannerList } from '../selectors';

import NotificationBanners from '../NotificationBanners';
import AccessLevelDropdown from '../AccessLevelDropdown';
import ProfileDropdown from '../ProfileDropdown';
import NotifBanners from '../NotifBanners';

import { MetaInfoContext } from '../../../context';

import './style.scss';

const Header = (props) => {
    const location = useLocation();

    // reducer state variables
    const selectedAccessLevel = useSelector(makeSelectAccessLevel(), shallowEqual);
    const { data } = useSelector(makeSelectBannerList, shallowEqual);

    const { setAccessLevel } = props;

    const isDefaultTicketRoute = location.pathname.includes(DEFAULT_TICKET_PATH);

    const metaInfo = useContext(MetaInfoContext);

    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    return (
        <React.Fragment>
            {
                bannersVersion === 2 && !isNilOrEmpty(data) && data.map((item, index) => (
                    index < 2 && <NotifBanners key={item.id} body={item.body} title={item.title} />
                ))
            }

            {(!selectedAccessLevel || !isDefaultTicketRoute) ? (
            // app header with the new design
                <AppHeader>
                    <div className='frcWrapper'>
                        <NotificationBanners whiteIcon />
                        <AccessLevelDropdown
                            extClasses={{
                                label: 'app-header-access-level',
                                arrow: 'app-header-arrow',
                            }}
                            setAccessLevel={setAccessLevel}
                        />
                        <ProfileDropdown
                            extClasses={{
                                label: 'app-header-profile',
                                arrow: 'app-header-arrow',
                            }}
                        />
                    </div>
                </AppHeader>
            ) : (
            // app header with the old design
                <div className={classNames(isDevEnviroment() ? {
                    // eslint-disable-next-line quote-props
                    'header bg-np': bannersVersion === 1,
                    'header-v2 bg-np': bannersVersion === 2,
                } : {
                    // eslint-disable-next-line quote-props
                    'header bg': bannersVersion === 1,
                    'header-v2 bg': bannersVersion === 2,
                })}
                >
                    <div>
                        <Link to='/' className='htitle'>Sherlock</Link>
                    </div>
                    <div className='frcWrapper'>
                        <NotificationBanners />
                        <AccessLevelDropdown setAccessLevel={setAccessLevel} />
                        <ProfileDropdown />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default memo(Header);

/**
 * @file Manages the selector functions for Banners component
 */

import { createSelector } from 'reselect';

import { BANNER_KEY } from './constants';

const makeSelectBannerInfo = createSelector(
    (state) => state.getIn([BANNER_KEY, 'bannerInfo']),
    substate => substate.toJS(),
);

const makeSelectBannerFormFields = createSelector(
    (state) => state.getIn([BANNER_KEY, 'bannerFormFields']),
    substate => substate.toJS(),
);

const makeSelectCreateOrUpdateBannerInfo = createSelector(
    (state) => state.getIn([BANNER_KEY, 'createOrUpdateBanner']),
    substate => substate.toJS(),
);

const makeSelectBannerList = createSelector(
    (state) => state.getIn([BANNER_KEY, 'bannerList']),
    substate => substate.toJS(),
);

export {
    makeSelectBannerInfo,
    makeSelectBannerFormFields,
    makeSelectCreateOrUpdateBannerInfo,
    makeSelectBannerList,
};

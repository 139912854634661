/**
 * @file UserDetailsWrapperForDeveloper - Contains a wrapper component for the UserDetailsWithPhoneOrEmail component
 */
import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { HorizontalIdNavbar } from '../../../components';

import UserDetailsWithPhoneOrEmail from '../../UserDetailsWithPhoneOrEmail';

import { setIdentifierList, setActiveTabIndex, setNewTabActive } from '../actions';
import { selectTabData } from '../selectors';

const UserDetailsWrapperForDeveloper = (props) => {
    const dispatch = useDispatch();
    const { identifierList, activeTabIndex, newTabActive } = useSelector(selectTabData, shallowEqual);

    const closeTab = (index) => {
        if (activeTabIndex === index) { // closing active tab
            dispatch(setIdentifierList({ index }));
            if (identifierList.length - 1 === index) { // last tab removing sends a new tab to be created with its current index
                addNewTab();
            } else { // make the next tab active
                setActiveTab(index);
            }
        }
    };

    const setActiveTab = (index) => {
        if (activeTabIndex !== index) {
            dispatch(setActiveTabIndex({ index }));
            if (newTabActive) dispatch(setNewTabActive({ value: false }));
        }
    };

    const addNewTab = () => {
        dispatch(setActiveTabIndex({ index: -1 }));
        dispatch(setNewTabActive({ value: true }));
    };

    const onIdentifierSubmit = (type, value, subValue) => {
        dispatch(setIdentifierList({ index: identifierList.length, data: { type, value, subValue }, isActive: true }));
        dispatch(setNewTabActive({ value: false }));
    };

    return (
        <React.Fragment>
            <HorizontalIdNavbar
                activeId={null}
                navItems={identifierList}
                onNewTicketClick={addNewTab}
                isNewTicketActive={newTabActive}
                onItemClick={setActiveTab}
                onItemCloseIconClick={closeTab}
                activeIndex={activeTabIndex}
                newTabText='New Tab'
                extClasses={{
                    container: 'devs-v2-tabs',
                }}
            />
            <UserDetailsWithPhoneOrEmail
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                tabData={{ identifierList, activeTabIndex, newTabActive }}
                onSubmit={onIdentifierSubmit}
            />
        </React.Fragment>
    );
};

export default UserDetailsWrapperForDeveloper;

/*
 *
 * WaitlistDetails actions
 *
 */

import { GET_WAITLIST_USER_INFO, RESET_WAITLIST_USER_INFO } from './constants';

export const getWaitlistUserInfo = data => ({
    type: GET_WAITLIST_USER_INFO,
    data,
});

export const resetWaitlistUserInfo = data => ({
    type: RESET_WAITLIST_USER_INFO,
    data,
});

/* eslint-disable */
/**
 *
 * TicketInfo
 *
 */

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    TICKET_PATH,
    BA_PROFILE_TICKET_PATH,
    BA_KYC_ONBOARDING_TICKET_PATH,
    BA_ACCOUNT_TICKET_PATH,
    BA_TXN_TICKET_PATH,
    BA_INSTRUMENT_TICKET_PATH,
    BA_COMMS_TICKET_PATH,
    BA_USER_ACTIONS_TICKET_PATH,
    BA_REWARDS_TICKET_PATH,
    BA_MERGE_TICKETS_PATH,
    BA_CALL_DETAILS_TICKET_PATH,
    BA_FIT_RULES_TICKET_PATH,
    BA_USER_REQUESTS_TICKET_PATH,
    BA_ADMIN_ACTIONS_TICKET_PATH,
    BA_REFERRALS_TICKET_PATH,
    BA_DISPUTES_TICKET_PATH,
    CONNECTED_ACCOUNTS_TICKET_PATH,
    WL_ONBOARDING_TICKET_PATH,
    WL_INVESTMENT_TICKET_PATH,
    WL_MUTUAL_FUNDS_TICKET_PATH,
    WL_P2P_TICKET_PATH,
    BA_SALARY_PROGRAM_PATH,
    BA_PRE_APPROVED_LOANS_PATH,
    WL_USSTOCKS_TICKET_PATH,
    BA_TIERING_PATH,
    BA_CREDIT_CARD_TICKET_PATH,
    BA_CREDIT_CARD_TXN_TICKET_PATH,
    BA_RECENT_ACTIVITY_PATH,
    BA_LOAN_AGAINST_MF_PATH,
    BA_AUTOPAY_TICKET_PATH,
} from '../../App/routes';

import Profile from '../../Profile';
import KycOnboarding from '../../KycOnboarding';
import Deposits from '../../Deposits';
import Transactions from '../../Transactions';
import PaymentInstruments from '../../PaymentInstruments';
import Communications from '../../Communications';
import UserActions from '../../UserActions';
import Rewards from '../../Rewards';
import MergeTickets from '../../MergeTickets';
import CallDetails from '../../CallDetails';
import Fittt from '../../Fittt';
import UserRequests from '../../UserRequests';
import AdminActions from '../../AdminActions';
import Referrals from '../../Referrals';
import Disputes from '../../Disputes';
import ConnectedAccounts from '../../ConnectedAccounts';
import WealthOnboarding from '../../WealthOnboarding';
import WealthInvestment from '../../WealthInvestment';
import WealthMutualFunds from '../../WealthMutualFunds';
import WealthP2P from '../../WealthP2P';
import WealthUSStocks from '../../WealthUSStocks';
import SalaryAccountOps from '../../SalaryAccountOps';
import Loans from '../../Loans';
import CreditCard from '../../CreditCard';
import Tiering from '../../Tiering';

import UserConfirmation from '../UserConfirmation';
import TicketAndUserInfo from '../TicketAndUserInfo';
import UserCallback from '../UserCallback';
import UserAuth from '../UserAuth';
import RecentActivity from '../../RecentActivity';
import { hasAccessToThePage } from '../../../utils/helpers';
import { LoansOptions, SCREEN } from '../constants';
import AuthUpgradeScreen from '../AuthUpgradeScreen';
import CustomerAuth from '../CustomerAuth';
import ExistingMandates from "../../AutoPay/ExistingMandates";
import UpcomingTransactions from "../../AutoPay/UpcomingTransactions";
import AutoPay from "../../AutoPay";
import { SearchBar } from '../../../components';
import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';

import { makeSelectAgentInfo } from '../../AppV2/selectors';

import ScriptsSearchBar from '../ScriptsSearchBar';
import UserProfileAndOnboardingDetailsWrapper from "../../UserProfileAndOnboardingDetailsWrapper";


const mapStateToProps = (state) => ({
    agentInfo: makeSelectAgentInfo()(state),
});

class TicketInfo extends React.PureComponent {
    componentDidMount() {
        const { ticket: { hasBasicInfo }, index, match, submitTicketId, agentInfo } = this.props;
        const { ticketId } = match.params;

        // Rudder Event for tab open
        pushRudderEvent(RudderEvent.SherlockV1Agent.SherlockV1AgentTicketTabOpen, agentInfo.emailId, { ticketId: ticketId });

        // Rudder Event for tab inactive
        this.handleTabInactive = () => {
            if (document.hidden) {
                pushRudderEvent(RudderEvent.SherlockV1Agent.SherlockV1AgentTicketTabInActive, agentInfo.emailId, { ticketId: ticketId });
            }
        };
        document.addEventListener('visibilitychange', this.handleTabInactive);

        // Rudder event for tab active
        this.handleTabActive = () => {
            if (!document.hidden) {
                pushRudderEvent(RudderEvent.SherlockV1Agent.SherlockV1AgentTicketTabActive, agentInfo.emailId, { ticketId: ticketId });
            }
        };
        document.addEventListener('visibilitychange', this.handleTabActive);

        // Rudder event for tab closure
        this.handleTabClose = (event) => {
            pushRudderEvent(RudderEvent.SherlockV1Agent.SherlockV1AgentTicketTabClosed, agentInfo.emailId, { ticketId: ticketId });
        };
        window.addEventListener('beforeunload', this.handleTabClose);


        if (!hasBasicInfo) {
            submitTicketId(ticketId, index)();
        }
    }

    componentWillUnmount() {
        // Cleanup event listeners
        document.removeEventListener('visibilitychange', this.handleTabInactive);
        document.removeEventListener('visibilitychange', this.handleTabActive);
        window.removeEventListener('beforeunload', this.handleTabClose);
    }

    static getTicketAndUserInfo = (ticket) => {
        const { ticketId, basicInfo: { source, phoneNumber, ticketPhoneNumber, name },
            hasConfirmedUserInfo, userInfo } = ticket;
        const ticketAndUserInfo = {
            ticketId,
            source,
            phoneNumber: ticketPhoneNumber || phoneNumber,
            name: hasConfirmedUserInfo ? userInfo.name : name
        };

        return ticketAndUserInfo;
    }

    getCommonProps = () => {
        const { ticket, index, toggleCloseTicketModal } = this.props;
        const { ticketId, userId, basicInfo: { name, phoneNumber, ticketPhoneNumber, isChatFlow }, pendingAuth } = ticket;

        return {
            index,
            ticketId,
            userId,
            phoneNumber: phoneNumber || ticketPhoneNumber,
            pendingAuth,
            toggleCloseTicketModal,
            isChatFlow,
            name,
            activeTicket: ticket,
        };
    }

    getAccountFreezeInfoProps = () => {
        const { ticket } = this.props;
        const { accountFreezeInfo } = ticket;

        return {
            accountFreezeInfo,
        };
    }

    renderDefaultRoute = (ticket, index) => {
        const { hasAuthInfo, hasBasicInfo, skipUserConfirmation } = ticket;

        if (hasAuthInfo) {
            return (
                <UserAuth index={index} />
            );
        }

        if (!skipUserConfirmation && hasBasicInfo) {
            const { basicInfo: { callbackUser }, isCallbackUserFlow } = ticket;
            const { toggleCloseTicketModal } = this.props;

            if (isCallbackUserFlow && callbackUser) {
                return (
                    <UserCallback
                        ticket={ticket}
                        index={index}
                        ticketAndUserInfo={TicketInfo.getTicketAndUserInfo(ticket)}
                        toggleCloseTicketModal={toggleCloseTicketModal}
                    />
                )
            }

            return (
                <UserConfirmation
                    ticket={ticket}
                    index={index}
                    ticketAndUserInfo={TicketInfo.getTicketAndUserInfo(ticket)}
                />
            );
        }
    }

    renderAuthUpgradeIfRequired = (component, informationLevel) => {
        return hasAccessToThePage(this.getCommonProps().activeTicket, informationLevel) ? (
            component
        ) : <AuthUpgradeScreen
            informationLevel
            {...this.getCommonProps()}
        />;
    }

    render() {
        const { ticket, index, match, location } = this.props;

        this.state = {
            searchValue: ''
        }
        const { hasBasicInfo } = ticket;
        const isTicketMainUrl = match.url === location.pathname;

        // incase of no ticket info, redirect to ticket id path '/ticket/:ticketId'
        if (!hasBasicInfo && !isTicketMainUrl) {
            return <Route render={() => <Redirect to={{ pathname: match.url }} />} />;
        }

        return (
            <React.Fragment>
                {
                    !isTicketMainUrl ? (
                        <div>
                            <ScriptsSearchBar />
                            <TicketAndUserInfo {...TicketInfo.getTicketAndUserInfo(ticket)} />
                        </div>
                    ) : null
                }
                <CustomerAuth {...this.getCommonProps()} >
                    <Switch>
                        <Route
                            path={BA_PROFILE_TICKET_PATH}
                            render={
                                props => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <React.Fragment>
                                            <UserProfileAndOnboardingDetailsWrapper
                                                isProfile
                                                componentProps={{
                                                    ...props,
                                                    ...this.getCommonProps(),
                                                }}
                                            />
                                        </React.Fragment>
                                    ), SCREEN.INSENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_KYC_ONBOARDING_TICKET_PATH}
                            render={
                                props => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <UserProfileAndOnboardingDetailsWrapper
                                            isOnboarding
                                            componentProps={{
                                                ...props,
                                                ...this.getCommonProps(),
                                                ...this.getAccountFreezeInfoProps(),
                                            }}
                                        />
                                    ), SCREEN.INSENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_ACCOUNT_TICKET_PATH}
                            render={
                                props => {
                                    return this.renderAuthUpgradeIfRequired((<Deposits
                                        {...props}
                                        {...this.getCommonProps()}
                                    />), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_TXN_TICKET_PATH}
                            render={
                                props => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <React.Fragment>
                                            <Transactions
                                                {...props}
                                                {...this.getCommonProps()}
                                            />
                                        </React.Fragment>
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_CREDIT_CARD_TXN_TICKET_PATH}
                            render={
                                props => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <React.Fragment>
                                            <Transactions
                                                {...props}
                                                {...this.getCommonProps()}
                                                creditCardTransaction
                                            />
                                        </React.Fragment>
                                    ), SCREEN.LESS_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_CREDIT_CARD_TICKET_PATH}
                            render={
                                props => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <CreditCard
                                            {...props}
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.INSENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_INSTRUMENT_TICKET_PATH}
                            render={
                                props => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <PaymentInstruments
                                            {...props}
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.INSENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_AUTOPAY_TICKET_PATH}
                            render={
                                props => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <React.Fragment>
                                            <AutoPay
                                                {...props}
                                                {...this.getCommonProps()}
                                            />
                                        </React.Fragment>
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_CREDIT_CARD_TXN_TICKET_PATH}
                            render={
                                props => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <React.Fragment>
                                            <Transactions
                                                {...props}
                                                {...this.getCommonProps()}
                                                creditCardTransaction
                                            />
                                        </React.Fragment>
                                    ), SCREEN.LESS_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_COMMS_TICKET_PATH}
                            render={
                                props => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <Communications
                                            {...props}
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.INSENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_RECENT_ACTIVITY_PATH}
                            render={
                                props => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <RecentActivity
                                            {...props}
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_USER_ACTIONS_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <UserActions
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_REWARDS_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <Rewards
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_MERGE_TICKETS_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <MergeTickets
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.INSENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_CALL_DETAILS_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <CallDetails
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_FIT_RULES_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <Fittt
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.LESS_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_USER_REQUESTS_TICKET_PATH}
                            render={
                                () => {
                                    return (<UserRequests
                                        renderAuthUpgradeIfRequired={this.renderAuthUpgradeIfRequired}
                                        {...this.getCommonProps()}
                                    />
                                    )
                                }
                            }
                        />
                        <Route
                            path={BA_ADMIN_ACTIONS_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <AdminActions
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_REFERRALS_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <Referrals
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.LESS_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_DISPUTES_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <Disputes
                                            {...this.getCommonProps()}
                                            isDisputeInfoForActor
                                        />
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={CONNECTED_ACCOUNTS_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <ConnectedAccounts
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.INSENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={WL_ONBOARDING_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <WealthOnboarding
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.INSENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={WL_INVESTMENT_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <WealthInvestment
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.INSENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={WL_MUTUAL_FUNDS_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <WealthMutualFunds
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={WL_P2P_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <WealthP2P
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={WL_USSTOCKS_TICKET_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <WealthUSStocks
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_SALARY_PROGRAM_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <SalaryAccountOps
                                            isBankingView
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.LESS_SENSITIVE_SCREEN);// move to child
                                }
                            }
                        />
                        <Route
                            path={BA_PRE_APPROVED_LOANS_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <Loans
                                            isBankingView
                                            type={LoansOptions.PreApprovedLoans}
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_LOAN_AGAINST_MF_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((
                                        <Loans
                                            isBankingView
                                            type={LoansOptions.LoanAgainstMF}
                                            {...this.getCommonProps()}
                                        />
                                    ), SCREEN.MODERATELY_SENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route
                            path={BA_TIERING_PATH}
                            render={
                                () => {
                                    return this.renderAuthUpgradeIfRequired((<Tiering
                                        isBankingView
                                        {...this.getCommonProps()}
                                    />), SCREEN.INSENSITIVE_SCREEN);
                                }
                            }
                        />
                        <Route exact path={TICKET_PATH} render={() => this.renderDefaultRoute(ticket, index)} />
                    </Switch>
                </CustomerAuth>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(TicketInfo);
/**
 * @file Common risk ops reducer for all risk ops queue
 */

import { fromJS } from 'immutable';
import { CX_RISK_REVIEW_KEY } from '../CxRiskReview/constants';

import { FACEMATCH_REDUCER_STATE, FACEMATCH_REVIEW_KEY } from '../FacematchReviewV2/constants';
import { LIVENESS_AND_FACEMATCH_REDUCER_STATE, LIVENESS_AND_FACEMATCH_REVIEW_KEY } from '../LivenessAndFacematchReview/constants';
import { LIVENESS_REDUCER_STATE, LIVENESS_REVIEW_KEY } from '../LivenessReviewV2/constants';
import { LOAN_LIVENESS_REDUCER_STATE, LOAN_LIVENESS_REVIEW_KEY } from '../LoanLivenessReviewer/constants';
import { USER_RISK_REVIEW_KEY, USER_RISK_REVIEW_REDUCER_STATE } from '../UserRiskReview/constants';

import {
    GET_RISK_OPS_CASES, SET_RISK_OPS_CASES, SET_RISK_OPS_CUR_VIEW, SET_RISK_OPS_PAYLOAD_TYPE, SET_RISK_OPS_CUR_CASE,
    SET_RISK_OPS_PENDING_REVIEW_COUNT, SET_RISK_OPS_QUEUE_FILTERS, SET_RISK_OPS_ERROR, SET_RISK_OPS_L2_ANNOTATION_DETAILS,
    GET_RISK_OPS_REVIEW_VIDEO, SET_RISK_OPS_REVIEW_VIDEO, GET_RISK_OPS_BASE64_IMAGE, SET_RISK_OPS_BASE64_IMAGE, SUBMIT_RISK_OPS_QA_REVIEW,
} from './constants';

const initialState = fromJS({
    [LIVENESS_REVIEW_KEY]: LIVENESS_REDUCER_STATE,
    [FACEMATCH_REVIEW_KEY]: FACEMATCH_REDUCER_STATE,
    [LIVENESS_AND_FACEMATCH_REVIEW_KEY]: LIVENESS_AND_FACEMATCH_REDUCER_STATE,
    [CX_RISK_REVIEW_KEY]: LIVENESS_REDUCER_STATE,
    [USER_RISK_REVIEW_KEY]: USER_RISK_REVIEW_REDUCER_STATE,
    [LOAN_LIVENESS_REVIEW_KEY]: LOAN_LIVENESS_REDUCER_STATE,
});

function riskOpsReducer(state = initialState, action = '') {
    switch (action.type) {
        case GET_RISK_OPS_CASES:
        case GET_RISK_OPS_BASE64_IMAGE:
        case SUBMIT_RISK_OPS_QA_REVIEW: {
            const { containerKey } = action.data;

            return state
                .setIn([containerKey, 'loading'], true)
                .setIn([containerKey, 'err'], '');
        }

        case SET_RISK_OPS_CASES: {
            const { casesList, containerKey } = action.data;

            return state
                .setIn([containerKey, 'loading'], false)
                .setIn([containerKey, 'casesList'], fromJS(casesList));
        }

        case SET_RISK_OPS_ERROR: {
            const { containerKey, err } = action.data;

            return state
                .setIn([containerKey, 'loading'], false)
                .setIn([containerKey, 'err'], err);
        }

        case SET_RISK_OPS_CUR_VIEW: {
            const { containerKey, newView } = action.data;

            return state
                .setIn([containerKey, 'curView'], newView);
        }

        case SET_RISK_OPS_PAYLOAD_TYPE: {
            const { containerKey, newPayload } = action.data;

            return state
                .setIn([containerKey, 'payloadType'], fromJS(newPayload));
        }

        case SET_RISK_OPS_CUR_CASE: {
            const { containerKey, caseData } = action.data;

            return state
                .setIn([containerKey, 'curCase'], fromJS(caseData));
        }

        case SET_RISK_OPS_PENDING_REVIEW_COUNT: {
            const { containerKey, pendingReviewCount } = action.data;

            return state
                .setIn([containerKey, 'pendingReviewCount'], fromJS(pendingReviewCount));
        }

        case SET_RISK_OPS_QUEUE_FILTERS: {
            const { containerKey, queueFilters } = action.data;
            const { key, value } = queueFilters;

            return state
                .setIn([containerKey, 'queueFilters', key], fromJS(value));
        }

        case SET_RISK_OPS_L2_ANNOTATION_DETAILS: {
            const { l2AnnotationDetails, containerKey } = action.data;

            return state
                .setIn([containerKey, 'l2AnnotationDetails'], fromJS(l2AnnotationDetails));
        }

        case GET_RISK_OPS_REVIEW_VIDEO: {
            const { containerKey } = action.data;

            return state
                .setIn([containerKey, 'loading'], true)
                .setIn([containerKey, 'videoUrl'], '')
                .setIn([containerKey, 'err'], '');
        }

        case SET_RISK_OPS_REVIEW_VIDEO: {
            const { containerKey, videoUrl } = action.data;

            return state
                .setIn([containerKey, 'loading'], false)
                .setIn([containerKey, 'videoUrl'], videoUrl);
        }

        case SET_RISK_OPS_BASE64_IMAGE: {
            const { containerKey, imageInfo } = action.data;
            const { imageType, imageSrc } = imageInfo;

            let modifiedState = state.setIn([containerKey, imageType], imageSrc);

            /**
             * 2 API calls are fired for facematch image, set loader as false only for 2nd one. If this is not done then loader is not seen at all
             * Also set loader to false only when imageSrc data is received. There is a case when imageSrc is empty and that action is fired in
             * the saga to clear the older image before fetching new one.
             */
            if (imageType === 'kycImg' && imageSrc) modifiedState = modifiedState.setIn([containerKey, 'loading'], false);

            return modifiedState;
        }

        default:
            return state;
    }
}

export default riskOpsReducer;

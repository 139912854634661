/**
 * @file Wealth US Stocks
 * Root component to show the invested us stocks related information by the current user
 */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';

import {
    WL_USSTOCKS_ACCOUNT_OPENING,
    WL_USSTOCKS_PORTFOLIO_DETAILS,
    WL_USSTOCKS_ACTIVITY_DETAILS,
    WL_USSTOCKS_REMITTANCE_DETAILS,
} from '../App/routes';

import { WEALTH_USSTOCKS_KEY } from './constants';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

import USSAccountDetails from './USSAccountDetails';
import USSActivityDetails from './USSActivityDetails';
import USSPortfolioDetails from './USSPortfolioDetails';
import USSRemittanceDetails from './USSRemittanceDetails';

const WealthUSStocks = (props) => {
    const { ticketId, userId, index } = props;
    return (
        <Switch>
            <Route
                path={WL_USSTOCKS_ACCOUNT_OPENING}
                render={
                    () => <USSAccountDetails ticketId={ticketId} userId={userId} />
                }
            />
            <Route
                path={WL_USSTOCKS_ACTIVITY_DETAILS}
                render={
                    () => <USSActivityDetails ticketId={ticketId} userId={userId} index={index} />
                }
            />
            <Route
                path={WL_USSTOCKS_PORTFOLIO_DETAILS}
                render={
                    () => <USSPortfolioDetails ticketId={ticketId} userId={userId} index={index} />
                }
            />
            <Route
                path={WL_USSTOCKS_REMITTANCE_DETAILS}
                render={
                    () => <USSRemittanceDetails ticketId={ticketId} userId={userId} index={index} />
                }
            />
            {/* in case of unmatched url, redirect the user to null */}
            <Route
                path='/'
                render={
                    () => null
                }
            />
        </Switch>
    );
};

const withReducer = injectReducer({ key: WEALTH_USSTOCKS_KEY, reducer });
const withSaga = injectSaga({ key: WEALTH_USSTOCKS_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(WealthUSStocks);

/*
 *
 * FederalAgents actions
 *
 */

import {
    GET_TICKET_LIST, GET_TICKET_INFO, SET_TICKET_INFO, GET_TICKET_GROUPS, UPDATE_TICKET_GROUP,
} from './constants';

export const getTicketList = data => ({
    type: GET_TICKET_LIST,
    data
});

export const getTicketNotes = data => ({
    type: GET_TICKET_INFO,
    data
});

export const setTicketNotes = data => ({
    type: SET_TICKET_INFO,
    data
});

export const getAllTicketGroups = data => ({
    type: GET_TICKET_GROUPS,
    data
});

export const updateTicketGroup = data => ({
    type: UPDATE_TICKET_GROUP,
    data
});

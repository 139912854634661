// auth status dispatch
export const GET_AUTH_STATUS = 'app/GET_AUTH_STATUS';
export const SET_AUTH_STATUS = 'app/SET_AUTH_STATUS';
export const SET_AUTH_STATUS_LOADER = 'app/SET_AUTH_STATUS_LOADER';

// agent info dispatch
export const GET_AGENT_INFO = 'app/GET_AGENT_INFO';
export const SET_AGENT_INFO = 'app/SET_AGENT_INFO';
export const ERR_AGENT_INFO = 'app/ERR_AGENT_INFO';

export const SET_SECURITY_TOKEN = 'app/SET_SECURITY_TOKEN';

export const GET_AUTH_LOGOUT = 'app/GET_AUTH_LOGOUT';

export const GET_META_INFO = 'app/GET_META_INFO';
export const SET_META_INFO = 'app/SET_META_INFO';

export const GET_ACCESS_LEVEL_LIST = 'app/GET_ACCESS_LEVEL_LIST';
export const SET_ACCESS_LEVEL_LIST = 'app/SET_ACCESS_LEVEL_LIST';

export const GET_BANNER_LIST = 'app/GET_BANNER_LIST';
export const SET_BANNER_LIST = 'app/SET_BANNER_LIST';
export const ERR_BANNER_LIST = 'app/ERR_BANNER_LIST';

export const SET_BANNERS_VISIBILITY = 'app/SET_BANNERS_VISIBILITY';

/* ***Global Routes*** */

export const ROOT_PATH = '/';

// login route
export const LOGIN_PATH = `${ROOT_PATH}login`;

// AppV1 route
export const APP_V1_PATH = ROOT_PATH;

// AppV2 route
export const APP_V2_PATH = `${ROOT_PATH}v2`;

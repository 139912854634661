/**
 * @file RenderOptionField
 * Renders the option field in raise dispute flow
 */

import React, { memo, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { DropdownContent, DropdownWrapper } from '../../../components';
import { MetaInfoContext } from '../../../context';

import { getQuestionsInfo } from '../actions';
import { OPTIONS_LIST } from '../constants';
import { makeSelectChannelList } from '../selectors';

const RenderOptionField = (props) => {
    const { parentProps, showChannelOptionDD, setChannelOptionDD } = props;

    const dispatch = useDispatch();

    const metaInfo = useContext(MetaInfoContext);

    const channelList = useSelector(makeSelectChannelList, shallowEqual);

    const {
        ticketId, userId, index, channel, channelOption, handleChannelOptionChange, selectedTxn,
    } = parentProps;

    const {
        componentVersions: {
            txnDispute: disputesVersion,
        },
    } = metaInfo;

    let optionsList = [];
    const { data: channelData } = channelList[ticketId];

    if (channel) {
        optionsList = channelData.find((item) => item.channel === channel).options;
    } else {
        // In v2, use default options list
        optionsList = OPTIONS_LIST;
    }

    const setOptionsValue = (newOptionValue) => {
        handleChannelOptionChange(newOptionValue);
        setChannelOptionDD(false);

        let payload = {
            ticketId,
            userId,
            index,
            id: channel,
            answer: newOptionValue,
            reset: true,
        };

        if (disputesVersion === 2) {
            payload = {
                ...payload,
                txnId: selectedTxn.txn.intId,
                disputeType: newOptionValue,
            };
        }

        dispatch(getQuestionsInfo(payload));
    };

    /**
     * If its disputes v1 flow and channel is not selected, do not show channel dropdown
     * In disputes v2 flow, show options dropdown directly
     */
    if (disputesVersion === 1 && !channel) return null;

    return (
        <React.Fragment>
            <div className='txns-search-label'>Option</div>
            <DropdownWrapper
                visible={showChannelOptionDD}
                onOutsideClickHandler={() => setChannelOptionDD(!showChannelOptionDD)}
                extClasses={{
                    container: 'custom-dd',
                }}
            >
                <button
                    type='button'
                    className='txns-dd__cta'
                    onClick={() => setChannelOptionDD(!showChannelOptionDD)}
                >
                    {channelOption || 'Choose...'}
                </button>
                <DropdownContent
                    visible
                    extClasses={{
                        container: ['custom-dd__cc height-transition', showChannelOptionDD && 'custom-dd__cc--ac'],
                    }}
                >
                    {optionsList.map((item) => (
                        <button
                            type='button'
                            key={item}
                            className='custom-dd__cl'
                            onClick={() => setOptionsValue(item)}
                        >
                            {item}
                        </button>
                    ))}
                </DropdownContent>
            </DropdownWrapper>
        </React.Fragment>
    );
};

export default memo(RenderOptionField);

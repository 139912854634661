/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router/immutable';

import createReducer, { history } from './reducers';

const sagaMiddleware = createSagaMiddleware();

function configureStore(initialState = {}) {
    // Create the store with two middlewares
    // 1. sagaMiddleware: Makes redux-sagas work
    // 2. routerMiddleware: Syncs the location/URL path to the state

    const middlewares = [sagaMiddleware, routerMiddleware(history)];

    const enhancers = [applyMiddleware(...middlewares)];

    let composeEnhancers = compose;

    if (process.env.REACT_APP_ENV !== 'prod' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
    }

    const store = createStore(
        createReducer(),
        fromJS(initialState),
        composeEnhancers(...enhancers),
    );

    // Extensions
    store.runSaga = sagaMiddleware.run;
    store.injectedReducers = {}; // Reducer registry
    store.injectedSagas = {}; // Saga registry

    // Make reducers hot reloadable, see http://mxs.is/googmo
    /* istanbul ignore next */
    // if (module.hot) {
    //   module.hot.accept('./reducers', () => {
    //     store.replaceReducer(createReducer(store.injectedReducers));
    //   });
    // }

    return store;
}

const initialState = {};

const store = configureStore(initialState);

export default store;

// // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
// /* istanbul ignore next */
// if (process.env.NODE_ENV === 'production' && typeof window === 'object') {
// /* eslint-disable no-underscore-dangle */
//     if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) { composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}); }

// // NOTE: Uncomment the code below to restore support for Redux Saga
// // Dev Tools once it supports redux-saga version 1.x.x
// // if (window.__SAGA_MONITOR_EXTENSION__)
// //   reduxSagaMonitorOptions = {
// //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
// //   };
// /* eslint-enable */
// }

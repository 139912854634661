/*
 *
 * ViewAgent constants
 *
 */

import { INFO_TYPES } from '../../components/ShowInfo/constants';

export const DEFAULT_ACTION = 'app/ViewAgent/DEFAULT_ACTION';

export const FormatAgentInfo = (agentInfo) => ([
    {
        type: INFO_TYPES.ROW,
        label: 'Name',
        value: agentInfo.fullName,
    },
    {
        type: INFO_TYPES.ROW,
        label: 'Email',
        value: agentInfo.emailId,
    },
    {
        type: INFO_TYPES.ROW,
        label: 'Phone Number',
        value: agentInfo.phoneNumber,
    },
    {
        type: INFO_TYPES.ROW,
        label: 'Type',
        value: agentInfo.agentType,
    },
    {
        type: INFO_TYPES.ROW,
        label: 'Access Level',
        value: agentInfo.accessLevel,
    },
    {
        type: INFO_TYPES.ROW,
        label: 'Status',
        value: agentInfo.agentStatus,
    },
    {
        type: INFO_TYPES.ROW,
        label: 'Ozonetel ID',
        value: agentInfo.ozonetel_id || '-',
    },
]);

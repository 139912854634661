/**
 * @file Manages the constants for Home component
 */
import { APP_V2_KEY } from '../AppV2/constants';

export const HOME_KEY = `${APP_V2_KEY}/HOME`;

export const GET_AGENT_CALLS = `${HOME_KEY}/GET_AGENT_CALLS`;
export const SET_AGENT_CALLS = `${HOME_KEY}/SET_AGENT_CALLS`;
export const ERR_AGENT_CALLS = `${HOME_KEY}/ERR_AGENT_CALLS`;

/*
 *
 * Facematch review reducer
 *
 */

import { fromJS } from 'immutable';

import {
    GET_FACEMATCH_CASES, SET_FACEMATCH_CASES, ERR_FACEMATCH_CASES, VIEW_ALL_FACEMATCH_CASES,
    SET_FACEMATCH_VIEW, GET_BASE64_IMAGE, SET_BASE64_IMAGE, SET_CUR_FACEMATCH_CASE, PAYLOAD_DD_OG_STATE,
    SET_FACEMATCH_PAYLOAD_TYPE, SET_FACEMATCH_PENDING_REVIEW_COUNT, INITIAL_DATE_RANGE, SORT_DD_OG_STATE,
    SET_FACEMATCH_QUEUE_FILTERS,
    SET_FACEMATCH_L2_ANNOTATION_DETAILS,
} from './constants';

const initialState = fromJS({
    facematchCasesList: [],
    curFacematchCase: {},
    facematchView: VIEW_ALL_FACEMATCH_CASES,
    videoFrameImg: '',
    kycImg: '',
    loading: false,
    err: '',
    facematchPayloadType: PAYLOAD_DD_OG_STATE,
    pendingReviewCount: '',
    l2AnnotationDetails: null,
    queueFilters: {
        fromDate: INITIAL_DATE_RANGE.fromDate,
        toDate: INITIAL_DATE_RANGE.toDate,
        sortOption: SORT_DD_OG_STATE,
    },
});

function facematchReviewReducer(state = initialState, action) {
    switch (action.type) {
        case GET_FACEMATCH_CASES:
        case GET_BASE64_IMAGE:
            return state
                .set('loading', true)
                .set('err', '');

        case SET_FACEMATCH_CASES: {
            const facematchCasesList = action.data;

            return state
                .set('loading', false)
                .set('facematchCasesList', fromJS(facematchCasesList));
        }

        case ERR_FACEMATCH_CASES: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err);
        }

        case SET_FACEMATCH_VIEW: {
            return state.set('facematchView', action.data);
        }

        case SET_BASE64_IMAGE: {
            const { imageType, imageSrc } = action.data;

            return state
                .set('loading', false)
                .set(imageType, imageSrc);
        }

        case SET_CUR_FACEMATCH_CASE: {
            const curFacematchCase = action.data;

            return state.set('curFacematchCase', fromJS(curFacematchCase));
        }

        case SET_FACEMATCH_PAYLOAD_TYPE: {
            const facematchPayloadType = action.data;

            return state
                .set('facematchPayloadType', fromJS(facematchPayloadType));
        }

        case SET_FACEMATCH_PENDING_REVIEW_COUNT: {
            return state
                .set('pendingReviewCount', action.data);
        }

        case SET_FACEMATCH_QUEUE_FILTERS: {
            const { key, value } = action.data;

            return state
                .setIn(['queueFilters', key], fromJS(value));
        }

        case SET_FACEMATCH_L2_ANNOTATION_DETAILS: {
            const l2AnnotationDetails = action.data;

            return state
                .set('l2AnnotationDetails', fromJS(l2AnnotationDetails));
        }

        default:
            return state;
    }
}

export default facematchReviewReducer;

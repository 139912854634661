/**
 *
 * Allows users to reset their password by sending a code to their registered email address.
 *
 */

import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// styling
import '../style.scss';
import { makeSelectForgotPwdState } from '../selectors';
import { REGISTERED_EMAIL, SET_FORGOT_PASSWORD } from '../constants';

import RegisteredEmail from './RegisteredEmail';
import ConfirmNewPassword from './ConfirmNewPassword';

const POSSIBLE_FORGOT_PWD_STATES = [REGISTERED_EMAIL, SET_FORGOT_PASSWORD];

const ForgotPassword = () => {
    // global state
    const forgotPwdState = useSelector(makeSelectForgotPwdState(), shallowEqual);

    // main component
    return (
        <div className='login-cr2'>
            { POSSIBLE_FORGOT_PWD_STATES.includes(forgotPwdState) && (
                <div className='login-header login-item--fit'>
                    Reset Your Password
                </div>
            )}
            { forgotPwdState === REGISTERED_EMAIL && 
                <RegisteredEmail />
            }
            { forgotPwdState === SET_FORGOT_PASSWORD && 
                <ConfirmNewPassword />
            }
        </div>
    );
};

export default memo(ForgotPassword);

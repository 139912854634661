export const getTrackingLink = (courierPartner) => {
    switch (courierPartner) {
        case 'DELHIVERY':
            return 'https://www.delhivery.com/';
        case 'BlueDart':
            return 'https://www.bluedart.com/web/guest';
        case 'SpeedPost':
            return 'https://www.indiapost.gov.in/vas/Pages/IndiaPostHome.aspx';
        default:
            return '-';
    }
};

export const getCardTrackingLabels = (data) => {
    // common fields in api v1 & v2
    const trackingInfo = [
        {
            label: 'Card Generation Date',
            value: data.generatedTs,
            isLink: false,
        },
        {
            label: 'AWB',
            value: data.awb,
            isLink: false,
        },
        {
            label: 'Courier Partner',
            value: data.courierPartner,
            isLink: false,
        },
        {
            label: 'Tracking Link',
            value: getTrackingLink(data.courierPartner),
            isLink: true,
        },
        {
            label: 'Delivery Status',
            value: data.deliveryStatus,
            isLink: false,
        },
    ];

    let formattedData;

    if (data.hasOwnProperty('isDispatched')) { // v2 api fields
        formattedData = trackingInfo.concat([
            {
                label: 'Card Activated',
                value: data.isActivated,
            },
            {
                label: 'Card Dispatched',
                value: data.isDispatched,
            },
        ]);
    } else { // v1 api fields
        formattedData = trackingInfo.concat([
            {
                label: 'Card Dispatch Date',
                value: data.dispatchedDate,
            },
            {
                label: 'Card Return Date',
                value: data.returnedDate,
            },
            {
                label: 'Remarks',
                value: data.remarks,
            },
            {
                label: 'Card Activation Status',
                value: data.activationStatus,
            },
        ]);
    }

    return formattedData;
};

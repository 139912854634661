/**
 * @file Reducer for UserActions container
 */

import { fromJS } from 'immutable';

import {
    CALLBACK_USER, SET_CALLBACK_USER, ERR_CALLBACK_USER, RAISE_PAY_REQUEST, SET_PAY_REQUEST,
    ERR_PAY_REQUEST, TRIGGER_PUSH_NOTIFICATION_APPLOGS, SET_PUSH_NOTIFICATION_APPLOGS,
    GET_USER_EXPORTED_APP_LOGS, SET_USER_EXPORTED_APP_LOGS, SET_ALLOWED_FC_VALUE, SET_PAYOUT_TYPE, GET_ALLOWED_FC_VALUE,
} from './constants';

const initialState = fromJS({
    callbackUser: {},
    payUser: {},
    exportHealthData: {},
    payoutType: {},
    allowedFiCoinValues: [],
});

function userActionsReducer(state = initialState, action = '') {
    switch (action.type) {
        case CALLBACK_USER: {
            const { ticketId } = action.data;

            return state
                .setIn(['callbackUser', ticketId, 'loading'], true);
        }

        case SET_CALLBACK_USER: {
            const { ticketId, status } = action.data;

            return state
                .setIn(['callbackUser', ticketId, 'loading'], false)
                .setIn(['callbackUser', ticketId, 'status'], status);
        }

        case ERR_CALLBACK_USER: {
            const { ticketId } = action.data;

            return state
                .setIn(['callbackUser', ticketId, 'loading'], false);
        }

        case RAISE_PAY_REQUEST: {
            const { ticketId } = action.data;

            return state
                .setIn(['payUser', ticketId, 'loading'], true);
        }

        case SET_PAY_REQUEST: {
            const { ticketId, status } = action.data;

            return state
                .setIn(['payUser', ticketId, 'loading'], false)
                .setIn(['payUser', ticketId, 'status'], status);
        }

        case ERR_PAY_REQUEST: {
            const { ticketId } = action.data;

            return state
                .setIn(['payUser', ticketId, 'loading'], false);
        }

        case TRIGGER_PUSH_NOTIFICATION_APPLOGS:
        case GET_USER_EXPORTED_APP_LOGS: {
            const { ticketId } = action.data;

            return state
                .setIn(['exportHealthData', ticketId, 'loading'], true);
        }

        case SET_PUSH_NOTIFICATION_APPLOGS: {
            const { ticketId, flag } = action.data;

            return state
                .setIn(['exportHealthData', ticketId, 'loading'], flag);
        }

        case SET_USER_EXPORTED_APP_LOGS: {
            const { ticketId, userExportedAppLogs, err } = action.data;

            return state
                .setIn(['exportHealthData', ticketId, 'loading'], false)
                .setIn(['exportHealthData', ticketId, 'userExportedAppLogs'], userExportedAppLogs)
                .setIn(['exportHealthData', ticketId, 'err'], err);
        }

        case GET_ALLOWED_FC_VALUE: {
            return state
                .setIn(['loading'], true);
        }

        case SET_ALLOWED_FC_VALUE: {
            const allowedFiCoinValues = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['allowedFiCoinValues'], fromJS(allowedFiCoinValues));
        }

        case SET_PAYOUT_TYPE: {
            const payoutType = action.data;

            return state
                .set('payoutType', fromJS(payoutType));
        }

        default:
            return state;
    }
}

export default userActionsReducer;

/**
 * @file messageEvents/index.js - Contains all the event listener functions that can be imported by other files to facilitate communication between two tabs
 */

import { getErrMsg } from '../utils';

const isWindow = (object) => (object && object.constructor.name === 'Window') || false;

/**
 *
 * @param {Function} onMessageAuthenticated event function
 * @param {boolean | undefined} onV2 if the event is for v2 then it must be turned true
 * @param {String | undefined} eventListener this can be any event listener type given in https://developer.mozilla.org/en-US/docs/Web/Events
 * @returns a remove event listener function which can be returned in useEffect
 *
 */
const addMessageEventListener = (onMessageAuthenticated = () => {}, onV2 = false, eventListener = 'message') => {
    try {
        const isNotMessageEvent = eventListener !== 'message';
        const onMessageReceive = (event) => {
            if (isNotMessageEvent || (
                // Checks if the origin of the event target and the current origin is same or not
                event.origin === window.location.origin
                // Checks if the origin of the event source and the current origin is same or not
                && event.source.location.origin === window.location.origin
                // Checks if the event message type is object or not
                && typeof event.data === 'object'
                // Checks if the event message type is array or not
                && !Array.isArray(event.data)
            )) {
                // Checks if the event source pathname starts with v2 or not if the event is triggered on V1 if its a message event
                if (isNotMessageEvent || onV2 || event.source.location.pathname.startsWith('/v2')) onMessageAuthenticated(event);
            }
        };
        window.addEventListener(eventListener, onMessageReceive);
        return () => window.removeEventListener(eventListener, onMessageReceive);
    } catch (error) {
        const errorMsg = getErrMsg(error);
        console.error('addMessageEventListener ~ errorMsg:', errorMsg);
        return () => {};
    }
};

/**
 *
 * @param {Window} windowObject window object on which the message has to be sent
 * @param {Object | string} message the message to be sent to the window object
 * @param {String} targetOrigin the target window's location which is used foe authentication
 *
 */
const postMessageEvent = (windowObject, message, targetOrigin) => {
    if (isWindow(windowObject)) {
        windowObject.postMessage(message, targetOrigin);
    }
};

export {
    postMessageEvent,
    addMessageEventListener,
};

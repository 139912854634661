/**
 * @file Manages the selector functions for Wealth P2P component
 */

import { createSelector } from 'reselect';

import { WEALTH_P2P_KEY } from './constants';

const makeSelectInvestorInfo = createSelector(
    (state) => state.getIn([WEALTH_P2P_KEY, 'investorInfo']),
    (substate) => substate.toJS(),
);

const makeSelectInvestmentList = createSelector(
    (state) => state.getIn([WEALTH_P2P_KEY, 'investmentList']),
    (substate) => substate.toJS(),
);

export {
    makeSelectInvestorInfo,
    makeSelectInvestmentList,
};

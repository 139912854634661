/* eslint-disable default-param-last */
/**
 * @file Manages the reducer state & function for Agent Feedback Details component
 */

import { fromJS } from 'immutable';

import {
    GET_AGENT_FB_DETAILS, SET_AGENT_FB_DETAILS, ERR_AGENT_FB_DETAILS,
} from './constants';

const initialState = fromJS({
    agentFeedbackDetails: {
        loading: false,
        err: '',
        data: [],
        prevToken: '',
        nextToken: '',
    },
});

function agentFeedbackDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_AGENT_FB_DETAILS:
            return state
                .setIn(['agentFeedbackDetails', 'loading'], true)
                .setIn(['agentFeedbackDetails', 'err'], '');

        case SET_AGENT_FB_DETAILS: {
            const { agentFeedbackDetails, prev, next } = action.data;

            return state
                .setIn(['agentFeedbackDetails', 'loading'], false)
                .setIn(['agentFeedbackDetails', 'data'], fromJS(agentFeedbackDetails))
                .setIn(['agentFeedbackDetails', 'prevToken'], fromJS(prev))
                .setIn(['agentFeedbackDetails', 'nextToken'], fromJS(next));
        }

        case ERR_AGENT_FB_DETAILS: {
            const { err } = action.data;

            return state
                .setIn(['agentFeedbackDetails', 'loading'], false)
                .setIn(['agentFeedbackDetails', 'err'], err);
        }

        default:
            return state;
    }
}

export default agentFeedbackDetailsReducer;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
/**
 * BulkUserDetailsByIds
 * Component to fetch the bulk user details by actor ids, account ids, phone numbers or csv of same data
 * It's getting used for Biz Admin flow
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'ramda';
import Modal from 'react-modal';

import {
    Button, FormWithSingleInput, Loader, Table, CSVReader, DropdownWrapper, DropdownContent, HorizontalIdNavbar,
} from '../../components';
import {
    injectReducer, injectSaga, validators,
} from '../../utils';
import backArrowIcon from '../../assets/images/svgs/back-arrow.svg';

import {
    LABEL_DATA, PRIMARY_ACTION_MAP, PRIMARY_ACTION_LIST, SECONDARY_ACTION_UPLOAD_CSV_LIST,
    SECONDARY_ACTION_ENTER_CSV_LIST, SECONDARY_ACTION_MAP,
} from './constants';
import { getBulkUserInfo, resetBulkUserInfo } from './actions';
import { makeSelectBulkUserInfo } from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '32px 20px',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 600,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const BulkUserDetailsByIds = () => {
    const dispatch = useDispatch();

    const userInfo = useSelector(makeSelectBulkUserInfo(), shallowEqual);

    const [showPrimaryActionDD, setPrimaryActionDD] = useState(true);
    const [primaryAction, setPrimaryAction] = useState('');
    const [showSecondaryActionDD, setSecondaryActionDD] = useState(false);
    const [secondaryAction, setSecondaryAction] = useState('');
    const [csvData, setCSVData] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [fileData, setFileData] = useState('');
    const [actorIds, setActorIds] = useState('');
    const [accountIds, setAccountIds] = useState('');
    const [phoneNumbers, setPhoneNumbers] = useState('');
    const [actorIdsCount, setActorIdsCount] = useState(0);
    const [accountIdsCount, setAccountIdsCount] = useState(0);
    const [phoneNumbersCount, setPhoneNumbersCount] = useState(0);
    const [reason, setReason] = useState('');
    const [errLabel, setErrLabel] = useState('');
    const [showIdsModal, setIdsModal] = useState(false);

    const resetCSVFilledData = () => {
        setActorIds('');
        setAccountIds('');
        setPhoneNumbers('');
        setActorIdsCount(0);
        setAccountIdsCount(0);
        setPhoneNumbersCount(0);
    };

    useEffect(() => {
        if (csvData) {
            const actorIdsArr = [];
            const accountIdsArr = [];
            const phoneNumbersArr = [];

            // parse csv data & set actor/account ids
            csvData.forEach((item, idx) => {
                if (idx !== 0) {
                    const firstColData = item[0];
                    const secondColData = item[1];

                    /**
                     * 1. if secondary action is selected as 'Actor Id', push csv data in actorIdsArr
                     * 2. if secondary action is selected as 'Account Id', push csv data in accountIdsArr
                     * 3. if secondary action is selected as 'Phone Number', push csv data in phoneNumbersArr
                     * 4. if secondary action is selected as 'Both Actor Id and Account Id', push first column csv data in actorIdsArr
                     * and second column data in accountIdsArr
                     */
                    if (secondaryAction === SECONDARY_ACTION_MAP.actorId) {
                        actorIdsArr.push(firstColData);
                    } else if (secondaryAction === SECONDARY_ACTION_MAP.accountId) {
                        accountIdsArr.push(firstColData);
                    } else if (secondaryAction === SECONDARY_ACTION_MAP.phoneNumber) {
                        phoneNumbersArr.push(firstColData);
                    } else if (secondaryAction === SECONDARY_ACTION_MAP.allIds) {
                        actorIdsArr.push(firstColData);
                        accountIdsArr.push(secondColData);
                    }
                }
            });

            setActorIds(actorIdsArr.join(','));
            setAccountIds(accountIdsArr.join(','));
            setPhoneNumbers(phoneNumbersArr.join(','));
            setActorIdsCount(actorIdsArr.length);
            setAccountIdsCount(accountIdsArr.length);
            setPhoneNumbersCount(phoneNumbersArr.length);
        } else {
            resetCSVFilledData();
        }
    }, [csvData]);

    const { data, hasData, loading } = userInfo;

    const toggleDD = (key) => () => {
        switch (key) {
            case 'primaryAction': {
                setPrimaryActionDD(!showPrimaryActionDD);

                break;
            }

            case 'secondaryAction': {
                setSecondaryActionDD(!showSecondaryActionDD);

                break;
            }

            default:
        }
    };

    const handleDDChange = (key, value) => {
        switch (key) {
            // reset secondary action & open its dropdown whenever primary action gets changed
            case 'primaryAction': {
                setPrimaryAction(value);
                setSecondaryAction('');
                toggleDD('secondaryAction')();

                break;
            }

            // reset all actorIds, accountIds & phoneNumbers data whenever secondary action gets changed
            case 'secondaryAction': {
                setSecondaryAction(value);
                resetCSVFilledData();
                setCSVData('');
                setFileData('');

                break;
            }

            default:
        }

        toggleDD(key)();
    };

    const onInputChange = (key) => (e) => {
        const { value } = e.target;

        switch (key) {
            case 'ids': {
                if (secondaryAction === SECONDARY_ACTION_MAP.actorId) {
                    setActorIds(value);
                } else if (secondaryAction === SECONDARY_ACTION_MAP.accountId) {
                    setAccountIds(value);
                } else if (secondaryAction === SECONDARY_ACTION_MAP.phoneNumber) {
                    setPhoneNumbers(value);
                }

                break;
            }

            case 'reason':
                setReason(value);
                break;

            default:
        }
    };

    const onSubmitCTA = () => {
        if ((isEmpty(actorIds) && isEmpty(accountIds) && isEmpty(phoneNumbers)) || isEmpty(reason)) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        // using this regex to replace any space in the actor ids/account ids/phone numbers list
        const formattedActorIds = actorIds && actorIds.replace(/\s+/g, '');
        const formattedAccountIds = accountIds && accountIds.replace(/\s+/g, '');
        const formattedPhoneNumbers = phoneNumbers && phoneNumbers.replace(/\s+/g, '');

        if (!validators.isInputtedStringValid(reason)) {
            setErrLabel('Please enter a valid reason');
            return;
        }

        setErrLabel('');
        dispatch(getBulkUserInfo({
            actorIds: formattedActorIds,
            accountIds: formattedAccountIds,
            phoneNumbers: formattedPhoneNumbers,
            reason,
        }));
    };

    const onBackArrowCTA = () => {
        dispatch(resetBulkUserInfo());
    };

    const renderUserDetails = () => {
        const { fileUrl, failedIdList } = data;
        const filename = `bulk-user-info_${new Date().toISOString()}.csv`;

        return (
            <React.Fragment>
                <div className='budbi-userinfo-hwr'>
                    <img className='budbi-backarrow' alt='back arrow' src={backArrowIcon} onClick={onBackArrowCTA} />
                    <div className='budbi-userinfo-hwr__hd'>Bulk User Details</div>
                </div>
                <a className='link budbi-link' href={fileUrl} download={filename} target='_blank' rel='noreferrer'>
                    Download Bulk User Info CSV
                </a>
                {
                    !isEmpty(failedIdList) ? (
                        <div className='budbi-table'>
                            <Table
                                header='Failed Id List'
                                labelData={LABEL_DATA}
                                contentData={failedIdList}
                                extClasses={{
                                    container: 'budbi-table-sr',
                                    headerCr: 'budbi-table-sr__thw',
                                    contentCr: 'budbi-table-sr__tlc',
                                }}
                            />
                        </div>
                    ) : null
                }
            </React.Fragment>
        );
    };

    const renderUserDetailsPrimaryActionForm = () => (
        <div className='frcWrapper budbi-fc'>
            <div className='budbi-label budbi-fc__lb'>Primary Action</div>
            <DropdownWrapper
                visible={showPrimaryActionDD}
                onOutsideClickHandler={toggleDD('primaryAction')}
                extClasses={{
                    container: 'custom-dd budbi-dd',
                }}
            >
                <div onClick={toggleDD('primaryAction')}>
                    {primaryAction || 'Choose...'}
                </div>
                <DropdownContent
                    visible
                    extClasses={{
                        container: ['custom-dd__cc budbi-dd__cc'],
                    }}
                    extStyles={{
                        height: showPrimaryActionDD ? 98 : 0,
                        overflow: showPrimaryActionDD ? 'auto' : 'hidden',
                    }}
                >
                    {
                        PRIMARY_ACTION_LIST.map((item) => (
                            <div
                                key={item}
                                className='custom-dd__cl'
                                onClick={() => handleDDChange('primaryAction', item)}
                            >
                                {item}
                            </div>
                        ))
                    }
                </DropdownContent>
            </DropdownWrapper>
        </div>
    );

    const renderUserDetailsSecondaryActionForm = () => {
        if (!primaryAction) {
            return null;
        }

        let listDDHeight; // dropdown height as it has different elements
        let SECONDARY_ACTION_LIST;

        if (primaryAction === PRIMARY_ACTION_MAP.uploadCSV) {
            SECONDARY_ACTION_LIST = SECONDARY_ACTION_UPLOAD_CSV_LIST;
            listDDHeight = 176; // dropdown height for 4 elements(39px * 4 + 20px top/bottom padding)
        } else {
            SECONDARY_ACTION_LIST = SECONDARY_ACTION_ENTER_CSV_LIST;
            listDDHeight = 137; // dropdown height for 3 elements(39px * 3 + 20px top/bottom padding)
        }

        return (
            <div className='frcWrapper budbi-fc'>
                <div className='budbi-label budbi-fc__lb'>Secondary Action</div>
                <DropdownWrapper
                    visible={showSecondaryActionDD}
                    onOutsideClickHandler={toggleDD('secondaryAction')}
                    extClasses={{
                        container: 'custom-dd budbi-dd',
                    }}
                >
                    <div onClick={toggleDD('secondaryAction')}>
                        {secondaryAction || 'Choose...'}
                    </div>
                    <DropdownContent
                        visible
                        extClasses={{
                            container: ['custom-dd__cc budbi-dd__cc'],
                        }}
                        extStyles={{
                            height: showSecondaryActionDD ? listDDHeight : 0,
                            overflow: showSecondaryActionDD ? 'auto' : 'hidden',
                        }}
                    >
                        {
                            SECONDARY_ACTION_LIST.map((item) => (
                                <div
                                    key={item}
                                    className='custom-dd__cl'
                                    onClick={() => handleDDChange('secondaryAction', item)}
                                >
                                    {item}
                                </div>
                            ))
                        }
                    </DropdownContent>
                </DropdownWrapper>
            </div>
        );
    };

    const renderUploadCSVForm = () => {
        if (primaryAction === PRIMARY_ACTION_MAP.uploadCSV && secondaryAction) {
            let label = 'Upload IDs*';
            let sampleFilePath = '/assets/allids-sample.csv';

            if (secondaryAction === SECONDARY_ACTION_MAP.actorId) {
                label = 'Upload Actor IDs*';
                sampleFilePath = '/assets/actorids-sample.csv';
            } else if (secondaryAction === SECONDARY_ACTION_MAP.accountId) {
                label = 'Upload Account IDs*';
                sampleFilePath = '/assets/accountids-sample.csv';
            } else if (secondaryAction === SECONDARY_ACTION_MAP.phoneNumber) {
                label = 'Upload Phone Numbers*';
                sampleFilePath = '/assets/phonenumbers-sample.csv';
            }

            return (
                <React.Fragment>
                    <div className='frfsWrapper budbi-fc'>
                        <div className='budbi-label budbi-fc__lb'>{label}</div>
                        <div className='budbi-csv-cr'>
                            <CSVReader setCSVData={setCSVData} setFileData={setFileData} />
                        </div>
                        <a
                            className='link budbi-download-link'
                            href={sampleFilePath}
                            download='sample'
                        >
                            Download Sample Format
                        </a>
                    </div>
                    {
                        (actorIds || accountIds || phoneNumbers) ? (
                            <div className='frcWrapper budbi-fc'>
                                <div className='budbi-label budbi-fc__lb'>Successfully Uploaded{'\n'}IDs Count</div>
                                <div className='budbi-label'>
                                    {actorIdsCount + accountIdsCount + phoneNumbersCount}
                                </div>
                                <div className='link budbi-view-link' onClick={() => setIdsModal(true)}>View Uploaded Data</div>
                            </div>
                        ) : null
                    }
                </React.Fragment>
            );
        }

        return null;
    };

    const renderEnterCSVForm = () => {
        if (primaryAction === PRIMARY_ACTION_MAP.enterCSV && secondaryAction) {
            let label = 'Enter IDs*';
            let value;

            if (secondaryAction === SECONDARY_ACTION_MAP.actorId) {
                label = 'Enter Actor IDs*';
                value = actorIds;
            } else if (secondaryAction === SECONDARY_ACTION_MAP.accountId) {
                label = 'Enter Account IDs*';
                value = accountIds;
            } else if (secondaryAction === SECONDARY_ACTION_MAP.phoneNumber) {
                label = 'Enter Phone Numbers*';
                value = phoneNumbers;
            }

            return (
                <FormWithSingleInput
                    disableFormSubmitOnEnter
                    label={label}
                    labelId='ids'
                    input={{
                        value,
                        onChange: onInputChange('ids'),
                    }}
                    extClasses={{
                        container: 'budbi-fc',
                        label: 'budbi-fc__lb',
                        inputField: 'budbi-fc__ip',
                    }}
                />
            );
        }

        return null;
    };

    const renderModal = (
        <Modal
            isOpen={showIdsModal}
            style={customStylesForModal}
            contentLabel='Uploaded Data Modal'
        >
            <div className='budbi-modal__hl'>Uploaded Data</div>
            {
                actorIds ? (
                    <div className='budbi-modal__hl'>
                        {`Actor IDs - ${actorIds}`}
                    </div>
                ) : null
            }
            {
                accountIds ? (
                    <div className='budbi-modal__hl'>
                        {`Account IDs - ${accountIds}`}
                    </div>
                ) : null
            }
            {
                phoneNumbers ? (
                    <div className='budbi-modal__hl'>
                        {`Phone Numbers - ${phoneNumbers}`}
                    </div>
                ) : null
            }
            <Button
                extClasses='budbi-modal__cta'
                secondary
                label='Close'
                onClick={() => setIdsModal(false)}
            />
        </Modal>
    );

    const renderUserDetailsForm = () => (
        <React.Fragment>
            {renderUserDetailsPrimaryActionForm()}
            {renderUserDetailsSecondaryActionForm()}
            {renderUploadCSVForm()}
            {renderEnterCSVForm()}
            {
                primaryAction && secondaryAction ? (
                    <React.Fragment>
                        <div className='form-container budbi-fc'>
                            <label
                                className='form-wrapper-label budbi-fc__lb'
                                htmlFor='budbi-reason'
                            >
                                Enter Reason*
                            </label>
                            <textarea
                                id='budbi-reason'
                                className='budbi-fc__ip'
                                value={reason}
                                onChange={onInputChange('reason')}
                                rows={4}
                                cols={50}
                            />
                        </div>
                        <div className='err-label budbi-errlb'>{errLabel}</div>
                        <Button
                            primary
                            label='View Details'
                            onClick={onSubmitCTA}
                            extClasses='budbi-cta'
                        />
                    </React.Fragment>
                ) : null
            }
            <Loader visible={loading} />
            {renderModal}
        </React.Fragment>
    );

    return (
        <div className='bud'>
            <HorizontalIdNavbar isFixed isEmptyNavbar />
            {
                hasData ? (
                    renderUserDetails()
                ) : (
                    renderUserDetailsForm()
                )
            }
        </div>
    );
};

const withReducer = injectReducer({ key: 'bulkUserDetailsByIds', reducer });
const withSaga = injectSaga({ key: 'bulkUserDetailsByIds', saga });

export default compose(
    withReducer,
    withSaga,
)(BulkUserDetailsByIds);

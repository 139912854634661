export const caseConstants = {
    pdfRegex: /\.pdf/g,
    imageRegex: /^[^\s]+\.(jpg|jpeg|png|gif|bmp|web)$/g,
    IMAGE: 'IMAGE',
    PDF: 'PDF',
    QUESTION_TITLE: 'questionTitle',
    STYLE: 'style',
    TYPE: 'type',
    TEXT: 'text',
    FILE: 'file',
    RESPONSE: 'response',
};

/**
 * PDF.js: library to show pdf to users
 * As per Risk use case, user should not be allowed to download the file.
 * ref: https://github.com/mozilla/pdf.js?utm_source=cdnjs&utm_medium=cdnjs_link&utm_campaign=cdnjs_library
 */

export const PDFJSParams = {
    src: '/PDF_JS_PROXY/ajax/libs/pdf.js/2.6.347/pdf.min.js',
    async: true,
    integrity: 'sha512-Z8CqofpIcnJN80feS2uccz+pXWgZzeKxDsDNMD/dJ6997/LSRY+W4NmEt9acwR+Gt9OHN0kkI1CTianCwoqcjQ==',
    crossOrigin: 'anonymous',
    referrerpolicy: 'no-referrer',
    id: 'pdf.js',
    permissionType: 'Content-Security-Policy',
    // eslint-disable-next-line comma-dangle, max-len
    permissionContent: "default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

/**
 *
 * @file USSAccountStageDetails : Show account stages details page.
 *
 */

import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';
import { Button, Loader, Table } from '../../../components';
import { isNilOrEmpty } from '../../../utils';

import { setAccountViewAction } from '../actions';
import { ACCOUNT_BASIC_DETAILS_INFO, ACCOUNT_STAGE_DETAILS, VIEW_ACCOUNT_BASIC_DETAILS } from '../constants';
import { selectUSSAccountBasicDetails, selectUSSAccountStageDetails, selectUSSLoading } from '../selectors';

import USSAccountDetailInfo from './USSAccountDetailInfo';

const USSAccountStageDetails = (props) => {
    const { ticketId } = props;

    const dispatch = useDispatch();

    const accountStageDetails = useSelector(selectUSSAccountStageDetails, shallowEqual);
    const accountBasicDetails = useSelector(selectUSSAccountBasicDetails, shallowEqual);
    const loading = useSelector(selectUSSLoading, shallowEqual);

    const [showAccountInfoModal, setAccountInfoModal] = useState(false);

    const { data: accountBasicDetail } = accountBasicDetails ? accountBasicDetails[ticketId] : [];

    const onBackArrowCTA = () => {
        dispatch(setAccountViewAction(VIEW_ACCOUNT_BASIC_DETAILS));
    };

    const handleSubmit = () => {
        setAccountInfoModal(true);
    };
    const renderActions = () => (
        <div className='frccWrapper'>
            <Button
                link
                label='View Details'
                onClick={handleSubmit}
            />
        </div>
    );

    return (
        <React.Fragment>
            <div>
                <div className='ussfm-hl--cr'>
                    <button
                        className='ussfm-backarrow--cr'
                        type='button'
                        onClick={onBackArrowCTA}
                    >
                        <img
                            className='ussfm-backarrow'
                            alt='back-arrow'
                            src={backArrowIcon}
                        />
                    </button>
                    <div className='ussfm-hl-main-heading'>Account Opening History</div>
                </div>
            </div>
            {!isNilOrEmpty(accountBasicDetail) ? (
                <Table
                    v2
                    labelData={ACCOUNT_BASIC_DETAILS_INFO}
                    contentData={accountBasicDetail}
                    renderActionableColumn={renderActions}
                />
            ) : null }

            <div className='p-30'>
                <Table
                    v2
                    labelData={ACCOUNT_STAGE_DETAILS}
                    contentData={accountStageDetails[ticketId].data}
                />
            </div>

            <USSAccountDetailInfo
                visible={showAccountInfoModal}
                toggleHandler={() => {
                    setAccountInfoModal(false);
                }}
                accountBasicDetail={accountBasicDetail[0]}
            />

            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default USSAccountStageDetails;

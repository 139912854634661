/**
 * BulkTicketUpdate - Component to update the bulk ticket details by uploading csv & check their history
 * It's getting used for Admin Tools flow
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';

import { BULK_TICKET_UPDATE_UPLOAD_CSV_PATH, BULK_TICKET_UPDATE_HISTORY_PATH } from '../App/routes';

import UploadCSV from './UploadCSV';
import History from './History';

import { BULK_TICKET_UPDATE_KEY } from './constants';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const BulkTicketUpdate = () => (
    <div className='btu'>
        <Switch>
            <Route
                path={BULK_TICKET_UPDATE_UPLOAD_CSV_PATH}
                component={UploadCSV}
            />
            <Route
                path={BULK_TICKET_UPDATE_HISTORY_PATH}
                component={History}
            />
        </Switch>
    </div>
);

const withReducer = injectReducer({ key: BULK_TICKET_UPDATE_KEY, reducer });
const withSaga = injectSaga({ key: BULK_TICKET_UPDATE_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(BulkTicketUpdate);

/**
 * documentation for linkedin scrapper: https://docs.google.com/document/d/1WVOhBeQ65N10464WwT79cEckGaCkE9O5eGomxKpm9Nk
 */
import React, { useState, useReducer } from 'react';
import './style.scss';
import { isOutlookCredentialsAdded, outlookAuthScript, setCredentials, removeCredential } from './utils';
import ExpandableCard from '../../../components/ExpandableCard';
import CopyToClipboard from '../../../components/CopyToClipboard';
import { ReactComponent as Info } from '../../../assets/images/svgs/Information.svg';

const OutlookCredentialsBanner = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [, forceRerender] = useReducer((x) => x + 1, 0);
    const [isInfoSectionVisible, setInfoSectionVisibility] = useState(false);

    const toggleExpand = () => {
        setIsExpanded((pre) => !pre);
    };

    const toggleInfoSection = () => {
        setInfoSectionVisibility((prev) => !prev);
    };

    const copyScript = () => {
        navigator.clipboard.writeText(outlookAuthScript);
    };

    if (!(window.location.pathname || '').includes('case-details')) return null;

    return (
        <div className='outlook-info'>
            <span tabIndex={0} onKeyDown={() => null} role='button' onClick={toggleInfoSection}> <Info /></span>

            {
                isInfoSectionVisible && (
                    !isOutlookCredentialsAdded() ? (
                        <ExpandableCard
                            extClasses='linked-in-auth-panel-expand-card mb-30'
                            isExpanded={isExpanded}
                            setIsExpanded={toggleExpand}
                            heading='LinkedIn profile Credential has not been added'
                        >
                            <div className='linked-in-auth-panel-container'>
                                <div>
                                    <div>
                                        <ul className='linked-in-auth-panel-list'>
                                            <li>
                                                <div>One time setup upon token expiry</div>
                                                <ul>
                                                    <li>Prerequisite: Ensure you are logged in to outlook and your
                                                        linkedin should be attached to outlook
                                                    </li>
                                                    <li>click
                                                        <span
                                                            role='button'
                                                            onKeyDown={() => null}
                                                            tabIndex={0}
                                                            className='linked-in-auth-panel-link'
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(outlookAuthScript);
                                                                window.open('https://outlook.live.com/');
                                                            }}
                                                        > here&nbsp;
                                                        </span> to outlook homepage.
                                                    </li>
                                                    <li>
                                                        Click on the bookmark “LinkedIn fetcher” which was
                                                        created during the first time setup.
                                                        You will see a popup saying “Script added successfully”.
                                                    </li>
                                                    <li>Open the “People” tab as seen in the left bar in Outlook homepage.
                                                        Please add a contact with email id if no contact is available.
                                                    </li>
                                                    <li>You will a popup that says “Done”</li>
                                                    <li>Click
                                                        <span
                                                            role='button'
                                                            onKeyDown={() => null}
                                                            tabIndex={0}
                                                            className='linked-in-auth-panel-link'
                                                            onClick={() => {
                                                                setCredentials(forceRerender);
                                                            }}
                                                        >
                                                            here&nbsp;
                                                        </span>
                                                        to complete the process.
                                                        You will see a message that says credential added.
                                                        Token expiry
                                                    </li>
                                                    <li>If the LinkedIn token expires,
                                                        you will see a message that says token or credential expired.
                                                    </li>
                                                    <li>If the above happens, do the one time setup as described above.</li>
                                                </ul>
                                            </li>

                                            <li>
                                                First time setup (once in lifetime)
                                                <ul>
                                                    <li>
                                                        <div className='linked-in-auth-panel-copy-section'>chrome://bookmarks/  <CopyToClipboard text='chrome://bookmarks/' /></div> &nbsp;
                                                        copy and paste + enter on browser address bar.
                                                    </li>
                                                    <li>Click on “Add new bookmark” from the menu on the top right corner.</li>
                                                    <li>Enter any preferred name (eg: “LinkedIn fetcher”) and paste
                                                        <span
                                                            role='button'
                                                            onKeyDown={() => null}
                                                            tabIndex={0}
                                                            className='linked-in-auth-panel-link'
                                                            onClick={copyScript}
                                                        > this&nbsp;
                                                        </span>
                                                        in the URL field.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </ExpandableCard>
                    )
                        : (
                            <div className='linked-in-auth-panel-notification-container'>
                                LinkedIn Credentials added successfully
                                <span
                                    className='linked-in-auth-panel-notification-container-remove-btn'
                                    role='button'
                                    onKeyDown={() => null}
                                    tabIndex={0}
                                    onClick={() => removeCredential(forceRerender)}
                                >Reset Credentials
                                </span>
                            </div>
                        )
                )
            }

        </div>
    );
};

export default OutlookCredentialsBanner;

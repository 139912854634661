/*
 *
 * @file Functions to retrieve details about Facematch reviews.
 *
 */

import {
    call, put, takeLatest, all,
} from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify,
} from '../../utils';
import { US_STOCKS_OPS_ROOT } from '../../api/routes';

import {
    ERR_USS_FM,
    GET_USS_FM_PAYLOAD_TYPE_LIST, GET_USS_FM_REVIEW_ACTION, GET_USS_FM_REVIEW_AS_DONE,
    GET_USS_FM_REVIEW_ITEMS, GET_USS_FM_REVIEW_ITEM_DETAILS, GET_USS_FM_REVIEW_REASON,
    SET_USS_FM_FACEMATCH_PAYLOAD_TYPE,
    SET_USS_FM_PAYLOAD_TYPE_LIST, SET_USS_FM_REVIEW_ACTION, SET_USS_FM_REVIEW_ACTION_TYPE, SET_USS_FM_REVIEW_AS_DONE,
    SET_USS_FM_REVIEW_ITEMS, SET_USS_FM_REVIEW_ITEM_DETAILS, SET_USS_FM_REVIEW_REASON, SET_USS_FM_REVIEW_REASON_TYPE, SUCCESS_MESSAGE,
} from './constants';

function* getPayloadTypeListSaga() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${US_STOCKS_OPS_ROOT}/enums?name=payload-type`,
        );

        yield put({ type: SET_USS_FM_PAYLOAD_TYPE_LIST, data: response });
        yield put({ type: SET_USS_FM_FACEMATCH_PAYLOAD_TYPE, data: response.list[0] });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_USS_FM_PAYLOAD_TYPE_LIST, data: {} });
        yield put({ type: ERR_USS_FM, data: { err: e.message } });
    }
}

function* getReviewActionListSaga() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${US_STOCKS_OPS_ROOT}/enums?name=review-action`,
        );

        yield put({ type: SET_USS_FM_REVIEW_ACTION, data: response });
        yield put({ type: SET_USS_FM_REVIEW_ACTION_TYPE, data: response.list[0] });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_USS_FM_REVIEW_ACTION, data: {} });
        yield put({ type: ERR_USS_FM, data: { err: e.message } });
    }
}

function* getReviewReasonListSaga() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${US_STOCKS_OPS_ROOT}/enums?name=review-reason`,
        );

        yield put({ type: SET_USS_FM_REVIEW_REASON, data: response });
        yield put({ type: SET_USS_FM_REVIEW_REASON_TYPE, data: response.list[0] });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_USS_FM_REVIEW_REASON, data: {} });
        yield put({ type: ERR_USS_FM, data: { err: e.message } });
    }
}

function* getReviewItemsSaga(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${US_STOCKS_OPS_ROOT}/review?${queryString}`,
        );

        yield put({ type: SET_USS_FM_REVIEW_ITEMS, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_USS_FM_REVIEW_ITEMS, data: {} });
        yield put({ type: ERR_USS_FM, data: { err: e.message } });
    }
}

function* getReviewItemDetailsSaga(action) {
    const { reviewId } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${US_STOCKS_OPS_ROOT}/review/${reviewId}`,
        );

        yield put({ type: SET_USS_FM_REVIEW_ITEM_DETAILS, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_USS_FM_REVIEW_ITEM_DETAILS, data: {} });
        yield put({ type: ERR_USS_FM, data: { err: e.message } });
    }
}

function* setReviewAsDoneSaga(action) {
    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${US_STOCKS_OPS_ROOT}/review`,
            action.data,
        );

        yield put({ type: SET_USS_FM_REVIEW_AS_DONE, data: {} });

        toastify(SUCCESS_MESSAGE, 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_USS_FM_REVIEW_AS_DONE, data: {} });
        yield put({ type: ERR_USS_FM, data: { err: e.message } });
    }
}

export default function* ussFacematchReviewSaga() {
    yield all(
        [
            yield takeLatest(GET_USS_FM_PAYLOAD_TYPE_LIST, getPayloadTypeListSaga),
            yield takeLatest(GET_USS_FM_REVIEW_ACTION, getReviewActionListSaga),
            yield takeLatest(GET_USS_FM_REVIEW_REASON, getReviewReasonListSaga),
            yield takeLatest(GET_USS_FM_REVIEW_ITEMS, getReviewItemsSaga),
            yield takeLatest(GET_USS_FM_REVIEW_ITEM_DETAILS, getReviewItemDetailsSaga),
            yield takeLatest(GET_USS_FM_REVIEW_AS_DONE, setReviewAsDoneSaga),
        ],
    );
}

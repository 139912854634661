/*
 *
 * Call Details Constants
 *
 */

export const GET_CALL_RECORDING_URL = 'app/CD/GET_CALL_RECORDING_URL';
export const SET_CALL_RECORDING_URL = 'app/CD/SET_CALL_RECORDING_URL';
export const ERR_CALL_RECORDING_URL = 'app/CD/ERR_CALL_RECORDING_URL';

export const GET_CALL_TRANSCRIPT = 'app/CD/GET_CALL_TRANSCRIPT';
export const SET_CALL_TRANSCRIPT = 'app/CD/SET_CALL_TRANSCRIPT';
export const ERR_CALL_TRANSCRIPT = 'app/CD/ERR_CALL_TRANSCRIPT';

/**
 * @file Utils for SalaryAccountOps
 */

import {
    SALARY_ACCOUNT_VIEW_DETAILS_PATH,
    SALARY_ACCOUNT_VERIFICATION_QUEUE_PATH,
    SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_PATH,
    SALARY_ACCOUNT_DEV_ACTIONS,
    SALARY_ACCOUNT_OPS_BANKING_PROFILE_PATH,
    SALARY_ACCOUNT_OPS_BANKING_SAVINGS_ACCOUNT_PATH,
} from '../App/routes';

export const getSideNavbarItems = () => [
    {
        id: 'salary-account-ops',
        label: 'Salary Account Ops',
        nestedLinks: [
            {
                id: 'salary-account-details',
                label: 'Account Details',
                path: SALARY_ACCOUNT_VIEW_DETAILS_PATH,
            },
            {
                id: 'salary-verification-queue',
                label: 'Verification Queue',
                path: SALARY_ACCOUNT_VERIFICATION_QUEUE_PATH,
            },
            {
                id: 'raise-verification-request',
                label: 'Raise a request',
                path: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_PATH,
            },
            {
                id: 'raise-dev-actions',
                label: 'Dev Actions',
                path: SALARY_ACCOUNT_DEV_ACTIONS,
            },
        ],
    },
    {
        id: 'banking',
        label: 'Banking',
        nestedLinks: [
            {
                id: 'profile',
                label: 'Profile',
                path: SALARY_ACCOUNT_OPS_BANKING_PROFILE_PATH,
            },
            {
                id: 'savings-account',
                label: 'Saving Accounts',
                path: SALARY_ACCOUNT_OPS_BANKING_SAVINGS_ACCOUNT_PATH,
            },
        ],
    },
];

export const getTicketDetails = (ticketDetails) => ({
    ticketId: ticketDetails.ticketId,
    userId: ticketDetails.userId,
    index: ticketDetails.index,
});

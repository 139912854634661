/**
 * @file Manages the utility functions for User Requests component
 */

const getUserInfoList = (data) => ([
    {
        label: 'Status',
        value: {
            text: data.status,
        },
    },
    {
        label: 'Ordered At',
        value: {
            text: data.orderedAt,
        },
    },
    {
        label: 'Charges',
        value: {
            text: data.charges,
        },
    },
    {
        label: 'Chequebook Leaves',
        value: {
            text: data.chequeLeavesCount,
        },
    },
    {
        label: 'Courier Service Provider',
        value: {
            text: data.courierServiceProvider,
        },
    },
    {
        label: 'Tracking ID',
        value: {
            text: data.trackingId,
        },
    },
    {
        label: 'Tracking Link',
        value: {
            text: data.trackingUrl,
            link: data.trackingUrl,
        },
    },
]);

export {
    getUserInfoList,
};

/**
 * @file Pre Approved Loans - Component to show the Pre Approved Loans related user info, loan info & upcoming EMI info.
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    KVPairInfo, Table, Button, Modal, LoaderOrError,
} from '../../../components';

import { customModalStyles } from '../constants';
import { getLAMFUserInfo, getLAMFLoansInfo, getLAMFLoansAdditionalInfo } from '../actions';
import { selectLAMFUserInfo, selectLAMFLoansInfo, selectLAMFLoansAdditionalInfo } from '../selectors';

import '../style.scss';

const Loans = (props) => {
    const { ticketId, userId, index } = props;

    const dispatch = useDispatch();

    // reducer state variables
    const userInfo = useSelector(selectLAMFUserInfo, shallowEqual);
    const loanInfo = useSelector(selectLAMFLoansInfo, shallowEqual);
    const loanAdditionalInfo = useSelector(selectLAMFLoansAdditionalInfo, shallowEqual);

    // variables to control loading status and data
    let loadingUserInfo;
    let userData;
    let hasUserData;
    let errInLoadingUserInfo;

    let loadingLoanInfo;
    let loanData;
    let hasLoanData;
    let errInLoadingLoanInfo;

    let loadingLoanAdditionalData;
    let loanAdditionalData;
    let hasLoanAdditionalData;
    let errInLoadingLoanAdditionalData;

    if (userInfo[ticketId]) {
        ({
            loading: loadingUserInfo, data: userData, hasData: hasUserData, err: errInLoadingUserInfo,
        } = userInfo[ticketId]);
    }

    if (loanInfo[ticketId]) {
        ({
            loading: loadingLoanInfo, data: loanData, hasData: hasLoanData, err: errInLoadingLoanInfo,
        } = loanInfo[ticketId]);
    }

    if (loanAdditionalInfo[ticketId]) {
        ({
            loading: loadingLoanAdditionalData, data: loanAdditionalData, hasData: hasLoanAdditionalData, err: errInLoadingLoanAdditionalData,
        } = loanAdditionalInfo[ticketId]);
    }

    const [showLoanInfoModal, setLoanInfoModal] = useState(false);

    useEffect(() => {
        if (!userInfo[ticketId]?.hasData) {
            dispatch(getLAMFUserInfo({
                ticketId, userId, index, requestType: 'LAMF',
            }));
        }
        if (!loanInfo[ticketId]?.hasData) {
            dispatch(getLAMFLoansInfo({
                ticketId, userId, index, requestType: 'LAMF',
            }));
        }
    }, [dispatch]);

    const handleLoanInfoClick = (meta) => {
        const callRpc = () => new Promise((resolve, reject) => {
            try {
                dispatch(getLAMFLoansAdditionalInfo({
                    ticketId,
                    meta,
                    resolve,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callRpc().then(() => {
            setLoanInfoModal(true);
        });
    };

    const handleModalClose = () => {
        setLoanInfoModal(false);
    };

    // eslint-disable-next-line react/no-unstable-nested-components
    const renderActions = (action) => (item) => {
        const { meta } = item;
        return (
            <div className='frfscWrapper'>
                <Button
                    v2
                    disabled={action.disabled}
                    label={action.label}
                    onClick={() => handleLoanInfoClick(meta)}
                />
            </div>
        );
    };

    const renderComponent = (component) => {
        const { table, key_value_component: section, details } = component;

        switch (details) {
            case 'key_value_component': {
                return (
                    <React.Fragment>
                        <div className='pal-th'>{section?.title}</div>
                        <KVPairInfo data={section?.label_values} v2 />
                    </React.Fragment>
                );
            }
            case 'table': {
                return (
                    <React.Fragment>
                        <div className='pal-th'>{table?.tableName}</div>
                        <Table
                            rowDataVersion={2}
                            renderActionableColumn={
                                (table?.actions && Array.isArray(table?.actions) && table?.actions.length === 1)
                                    ? renderActions(table?.actions[0]) : null
                            }
                            labelData={table?.columnData}
                            contentData={table?.rowData}
                            errLabel={errInLoadingUserInfo}
                            extClasses={{
                                container: 'mb-60',
                            }}
                        />
                    </React.Fragment>
                );
            }
            default: {
                return '';
            }
        }
    };

    return (
        <React.Fragment>
            <div className='pal'>
                {/* User Details */}
                {
                    hasUserData && Array.isArray(userData) && userData.length > 0 && (
                        <React.Fragment>
                            {
                                userData.map(renderComponent)
                            }
                        </React.Fragment>
                    )
                }

                {/* Loan and EMI Details */}
                {
                    hasLoanData && Array.isArray(loanData) && loanData.length > 0 && (
                        <React.Fragment>
                            {
                                loanData.map(renderComponent)
                            }
                        </React.Fragment>
                    )
                }
                <Modal
                    visible={showLoanInfoModal}
                    modalStyles={customModalStyles}
                    contentLabel='Loan Info'
                >
                    {
                        hasLoanAdditionalData && Array.isArray(loanAdditionalData) && loanAdditionalData.length > 0 && (
                            <React.Fragment>
                                {
                                    loanAdditionalData.map(renderComponent)
                                }
                            </React.Fragment>
                        )
                    }
                    <Button
                        v2
                        primary
                        label='Close'
                        extClasses='pal-btn'
                        onClick={handleModalClose}
                    />
                </Modal>
            </div>
            <LoaderOrError
                loading={loadingUserInfo || loadingLoanInfo || loadingLoanAdditionalData}
                errLabel={errInLoadingUserInfo || errInLoadingLoanInfo || errInLoadingLoanAdditionalData}
            />
        </React.Fragment>
    );
};

export default Loans;

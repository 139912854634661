/**
 *
 * SearchBar - Component to show the SearchBar which opens up a modal upon clicking
 *
 */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import SearchIcon from '../../assets/images/svgs/search.svg';
import CrossIcon from '../../assets/images/svgs/cross.svg';
import SearchesIcon from '../../assets/images/svgs/searches.svg';
import RedirectIcon from '../../assets/images/svgs/arrow-upright.svg';

import FormDropdown from '../FormDropdown';
import Modal from '../Modal';
import OutsideAlerter from '../OutsideAlerter';
import Loader from '../Loader';

import { SEARCH_RESULT_DEFAULT, SEARCH_RESULT_TYPES } from './constants';
import './style.scss';

const getSherlockResultTypeValue = (type) => type?.replace('SHERLOCK_SEARCH_RESULT_TYPE_', '').toLowerCase();

const SearchBar = (props) => {
    const {
        handleOnSubmit, handleOnModalClear, handleOnResultsClear, placeholder, value = '', searchResults = [], recentSearches = [],
        mostViewed = [], extClasses = {}, handleOnChange = () => { }, isLoading = false, resultOnClick = () => { }, showAllResultsOnClick = () => { },
        handleSearchBarClick = () => { }, isDisabled = false, resultType, onResultTypeChange, isV1 = false,
    } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [input, setInput] = useState(value);
    const [selectedType, setSelectedType] = useState(resultType);

    const searchContainerClasses = classNames('search', extClasses && extClasses?.container);
    const inputClasses = classNames('search-input', extClasses && extClasses?.input);
    const modalContainerClasses = classNames('search-modal', extClasses && extClasses?.modalContainer);

    const handleOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (handleOnSubmit) handleOnSubmit(input, selectedType);
            setIsModalOpen(false);
        }
        if (e.key === 'Escape') {
            handleOnBlur();
        }
    };

    const handleOnCross = (isModal) => () => {
        setInput('');
        handleOnChange('', selectedType, isModal);
        if (handleOnResultsClear) handleOnResultsClear();
        if (isModal) {
            if (handleOnModalClear) handleOnModalClear();
        }
    };

    const handleOnBlur = () => {
        setIsModalOpen(false);
        setInput('');
        setSelectedType();
    };

    const renderSearch = (isModal) => (
        <div className='frcWrapper'>
            <div className={isModal ? modalContainerClasses : searchContainerClasses}>
                <img
                    className='search-icon'
                    src={SearchIcon}
                    alt='search-icon'
                />
                <input
                    autoFocus={isModal}
                    className={inputClasses}
                    type='text'
                    placeholder={isModal ? 'Start typing to search' : placeholder}
                    value={isModal ? input : value}
                    onClick={!isModal ? (() => {
                        setIsModalOpen(true);
                        handleSearchBarClick();
                    }) : null}
                    onChange={isModal ? ((e) => {
                        handleOnChange(e.target.value, selectedType, isModal);
                        setInput(e.target.value);
                    }) : () => setIsModalOpen(true)}
                    onKeyDown={isModal ? handleOnKeyDown : null}
                    disabled={isDisabled}
                />
                {
                    ((isModal && input) || (!isModal && value)) && (
                        <img
                            className='search-cross-icon'
                            src={CrossIcon}
                            alt='cross-icon'
                            onClick={handleOnCross(isModal)}
                            role='presentation'
                        />
                    )
                }
            </div>
            {
                // isV1 is used to check if the search bar is used in V1 or V2
                // and based on that the result type dropdown is shown
                (!isV1 || isModal) && (
                    <FormDropdown
                        options={SEARCH_RESULT_TYPES}
                        neverEmpty
                        input={{
                            value: isModal ? selectedType : resultType,
                            onChange: (type) => {
                                if (isModal) setSelectedType(type);
                                onResultTypeChange(type, input, isModal);
                            },
                            placeholder: 'Choose Search Type...',
                            isClearable: true,
                            defaultValue: SEARCH_RESULT_DEFAULT,
                        }}
                        cacheKey='result-type'
                        extStyles={{
                            container: 'search-type',
                        }}
                        extCustomStyles={{
                            control: {
                                borderRadius: '18px',
                            },
                        }}
                    />
                )
            }
        </div>
    );

    useEffect(() => {
        if (selectedType !== resultType) setSelectedType(resultType);
    }, [resultType, isModalOpen]);

    useEffect(() => {
        if (isModalOpen && value) handleOnChange(input, resultType);
    }, [isModalOpen]);

    return (
        <React.Fragment>
            {renderSearch(false)}
            <Modal
                visible={isModalOpen}
                contentLabel=''
                modalStyles={{
                    overlay: {
                        backdropFilter: 'blur(5px)',
                    },
                    content: {
                        padding: '0px',
                        borderRadius: '18px',
                    },
                }}
            >
                <OutsideAlerter
                    visible={isModalOpen}
                    onClickHandler={handleOnBlur}
                >
                    <Loader visible={isLoading} parentNode='.search-box' />
                    {renderSearch(true)}
                    {
                        searchResults.length === 0 ? (
                            <div className='search-box'>
                                <div className='search-default'>
                                    <div className='search-title'>Recently Searched</div>
                                    {
                                        recentSearches.length !== 0 ? recentSearches.map((item) => (
                                            <div className='frWrapper' key={item.value}>
                                                <img src={SearchIcon} alt='searches-icon' />
                                                <div>{item.value}</div>
                                            </div>
                                        )) : <div className='search-default-nsr'>No recent search history</div>
                                    }
                                </div>
                                <div className='search-default'>
                                    <div className='search-title'>Most Viewed</div>
                                    {
                                        mostViewed.length !== 0 ? recentSearches.map((item) => (
                                            <div className='frWrapper' key={item.value}>
                                                <img src={SearchesIcon} alt='searches-icon' />
                                                <div>{item.value}</div>
                                            </div>
                                        )) : <div className='search-default-nsr'>Not available</div>
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className='search-box search-results'>
                                <div className='search-title'>Search Results</div>
                                {
                                    searchResults.map((item) => (
                                        <div
                                            className='search-results-single frcWrapper'
                                            onClick={() => {
                                                resultOnClick(item);
                                                setIsModalOpen(false);
                                            }}
                                            role='presentation'
                                            key={item.meta}
                                        >
                                            <img src={SearchesIcon} alt='searches-icon' className='search-results-icon' />
                                            <div className='search-results-value'>
                                                <div>{item.value}</div>
                                                {item?.type && (
                                                    <div
                                                        className={
                                                            `search-results-type search-results-type-${getSherlockResultTypeValue(item?.type || '')}`
                                                        }
                                                    >
                                                        {getSherlockResultTypeValue(item?.type || '')}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                }
                                <div
                                    className='search-results-all frcfsWrapper'
                                    onClick={() => {
                                        showAllResultsOnClick(input);
                                        handleOnSubmit(input, selectedType);
                                        setIsModalOpen(false);
                                    }}
                                    role='presentation'
                                >
                                    <img src={RedirectIcon} alt='searches-icon' className='search-results-icon' />
                                    <div>Show All Results</div>
                                </div>
                            </div>
                        )
                    }
                </OutsideAlerter>
            </Modal>
        </React.Fragment>
    );
};

export default SearchBar;

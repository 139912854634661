import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { CUSTOMER_BANNER_LIST_ENDPOINT, PROFILE_ROOT, USER_ISSUE_INFO_ROOT } from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_PROFILE_INFO, SET_PROFILE_INFO, ERR_PROFILE_INFO, GET_USER_BANNERS, ERR_USER_BANNERS,
    SET_USER_BANNERS, SET_USER_ISSUE_INFO, ERR_USER_ISSUE_INFO, GET_USER_ISSUE_INFO,
} from './constants';

function* getProfileInfo(action) {
    const {
        userId, index, forceBalanceRefresh, authType, authValue, authSubValue,
    } = action.data;

    const queryString = getQueryStringFromObject({
        authType, authValue, userId, forceBalanceRefresh, authSubValue,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PROFILE_ROOT}/?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_PROFILE_INFO, data: { authValue, authSubValue, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_PROFILE_INFO, data: { authValue, authSubValue } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_PROFILE_INFO, data: { authValue, authSubValue, err: e.message } });
    }
}

function* getUserIssueInfoSaga(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject({ ticketId });

    try {
        let { user_issue_data: userIssueData } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${USER_ISSUE_INFO_ROOT}/?${queryString}`,
        );

        if (!userIssueData || userIssueData.length < 1) userIssueData = null;

        yield put({ type: SET_USER_ISSUE_INFO, data: { ticketId, userIssueData } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_ISSUE_INFO, data: { ticketId, err: e.message } });
    }
}

// Get user specific banners from API call
function* getUserBannersSaga(action) {
    const { authType, authValue, authSubValue } = action.data;
    const queryString = getQueryStringFromObject({ authType, authValue, authSubValue });

    try {
        let { banners } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CUSTOMER_BANNER_LIST_ENDPOINT}/?${queryString}`,
        );

        if (!banners || banners.length < 1) banners = null;

        yield put({ type: SET_USER_BANNERS, data: { authValue, authSubValue, banners } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_BANNERS, data: { authValue, authSubValue, err: e.message } });
    }
}

export default function* profileSaga() {
    yield all(
        [
            yield takeLatest(GET_PROFILE_INFO, getProfileInfo),
            yield takeLatest(GET_USER_ISSUE_INFO, getUserIssueInfoSaga),
            yield takeLatest(GET_USER_BANNERS, getUserBannersSaga),
        ],
    );
}

/**
 *
 * Table component to render data which is highly customizable like sortable, resizable and sortable in a table format
 *
 */

import React, { memo } from 'react';
import DataGrid, { SelectColumn, SelectCellFormatter, useRowSelection } from 'react-data-grid';
import classNames from 'classnames';

import Pagination from '../Pagination';

import './style.scss';

const SelectFormater = (selectProps) => {
    const [, onRowSelectionChange] = useRowSelection();
    const { tabIndex, row } = selectProps;

    return (
        <SelectCellFormatter
            aria-label='Select'
            tabIndex={tabIndex}
            value={row?.entityMeta}
            onChange={(checked, isShiftClick) => {
                onRowSelectionChange({
                    type: 'ROW', row, checked, isShiftClick,
                });
            }}
        />
    );
};

const TableV2 = (props) => {
    const {
        v2, columns, rows, headerRowHeight, selectedRows, setSelectedRows, sortable, resizable, columnWidth, columnMinWidth, prevToken, nextToken,
        paginatedClickHandler, enableScrollView, handleFill, header, extClasses, handleOnResize, onColumnsReorder, onSortColumnsChange,
        handleOnCellContextMenu, sortColumns, rowClass, rowKeyGetter, loading, renderCheckbox = false, renderers, extraColumns = [],
        renderActionableColumn, enableVirtualization = true, rowV2 = false,
    } = props;

    const titleClasses = classNames('tableV2-sr__title', extClasses && extClasses.title);
    const containerClasses = classNames('tableV2-cr', extClasses && extClasses.container);
    const contentClasses = classNames(extClasses && extClasses.content);

    const actionColumn = {
        key: 'row-action',
        name: '',
        width: 120,
        renderCell(cellProps) {
            return renderActionableColumn(cellProps.row);
        },
    };

    const handleColumnData = (columnData) => {
        let modifiedColumnData = columnData.map((column) => ({
            sortable: column?.is_sortable,
            visible: column?.is_visible,
            key: column?.key,
            name: column?.label,
            cellClass: column?.extClasses,
            width: column?.width,
            maxWidth: column?.maxWidth,
        }));
        if (renderCheckbox) {
            modifiedColumnData = [{ ...SelectColumn, renderCell: SelectFormater, renderHeaderCell: undefined }].concat(modifiedColumnData);
        }
        if (renderActionableColumn) {
            modifiedColumnData = modifiedColumnData.concat(actionColumn);
        }
        return modifiedColumnData.concat(extraColumns);
    };

    const handleRowData = (rowData) => {
        let modifiedRowData = rowData;
        if (rowV2) {
            modifiedRowData = rowData.map((row) => {
                const newRow = {};
                Object.keys(row).forEach((obj) => {
                    newRow[obj] = row[obj]?.value;
                });
                return newRow;
            });
        }
        return modifiedRowData;
    };

    return (
        <React.Fragment>
            {
                !loading && (
                    <div className={containerClasses}>
                        {
                            header ? (
                                <div className={titleClasses}>{header}</div>
                            ) : null
                        }
                        {
                            rows && Array.isArray(rows) && rows.length !== 0 ? (
                                <DataGrid
                                    className={contentClasses}
                                    columns={handleColumnData(columns)}
                                    rows={handleRowData(rows)}
                                    sortColumns={sortColumns}
                                    headerRowHeight={headerRowHeight || 70}
                                    rowKeyGetter={rowKeyGetter}
                                    selectedRows={selectedRows}
                                    onFill={handleFill}
                                    rowClass={rowClass}
                                    onSelectedRowsChange={setSelectedRows}
                                    renderers={renderers}
                                    defaultColumnOptions={{
                                        sortable: sortable || false,
                                        resizable: resizable || false,
                                        minWidth: columnMinWidth || 75,
                                        width: columnWidth,
                                    }}
                                    onColumnResize={handleOnResize}
                                    onColumnsReorder={onColumnsReorder}
                                    onSortColumnsChange={onSortColumnsChange}
                                    onCellContextMenu={handleOnCellContextMenu}
                                    enableVirtualization={enableVirtualization}
                                />
                            ) : <div className='tableV2-nsr'>No results found</div>
                        }
                    </div>
                )
            }
            {
                prevToken && nextToken ? (
                    <Pagination
                        v2={v2}
                        prev={prevToken}
                        next={nextToken}
                        onClickHandler={paginatedClickHandler}
                        enableScrollView={enableScrollView}
                    />
                ) : null
            }
        </React.Fragment>
    );
};
export default memo(TableV2);

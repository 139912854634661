/**
 * @file Constants for Credit Card
 */

// main container key
export const CREDIT_CARD_KEY = 'creditCard';

// reducer constants
export const REDUCER_KEYS = {
    CREDIT_CARD_LOADER: 'loading',
    CREDIT_CARD_ERROR: 'creditCardError',
    CURRENT_CREDIT_CARD_DETAILS: 'cardDetails',
    ALL_CREDIT_CARDS: 'allCreditCards',
    CREDIT_CARD_TRACKING_INFO: 'trackingInfo',
    CREDIT_CARD_CONTROL_DETAILS: 'controlDetails',
    CREDIT_CARD_USAGE_DETAILS: 'usageDetails',
    CREDIT_CARD_ONBOARDING_DETAILS: 'onboardingDetails',
    CURRENT_LIMIT_USAGE_DETAILS: 'currentLimitUsageDetails',
    CREDIT_CARD_BILLING_INFO: 'billingInfo',
    ALL_ACTIVE_LOAN_ACCOUNTS: 'allActiveLoanAccounts',
    UPCOMING_EMI_INFO: 'upcomingEmiInfo',
    ALL_CLOSED_LOAN_ACCOUNTS: 'allClosedLoanAccounts',
    LOAN_ACCOUNT_DETAILS: 'loanAccountDetails',
    ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS: 'allEligibleTransactionsForEmis',
    LOAN_OFFERS: 'loanOffers',
    BLOCK_CREDIT_CARD: 'blockCreditCard',
    SUSPEND_CREDIT_CARD: 'suspendCreditCard',
    FD_CREDIT_CARD: 'fcCreditCardDetails',
    // v2 constants
    CREDIT_CARD_ElIGIBILITY_DETAILS: 'eligibilityDetails',
    SHOW_CREDIT_CARD_ONBOARDING_DETAILS_V2: 'showOnboardingDetailsV2',
    CREDIT_CARD_ONBOARDING_DETAILS_V2: 'onboardingDetailsV2',
    CREDIT_CARD_KYC_DETAILS: 'kycDetails',
    SELECTED_CREDIT_CARD_DETAILS: 'selectedCardDetails',
    CREDIT_CARD_CURRENT_STAGE_DETAILS: 'currentStageDetails',
    CREDIT_CARD_NEXT_STAGE_DETAILS: 'nextStageDetails',
    CREDIT_CARD_MILESTONE_REWARDS_DETAILS: 'milestonRewardDetails',
};

export const ERR_CREDIT_CARD_SCREEN = `app/${CREDIT_CARD_KEY}/ERR_CREDIT_CARD_SCREEN`;

export const GET_CREDIT_CARD_ONBOARDING_DETAILS = `app/${CREDIT_CARD_KEY}/GET_CREDIT_CARD_ONBOARDING_DETAILS`;
export const SET_CREDIT_CARD_ONBOARDING_DETAILS = `app/${CREDIT_CARD_KEY}/SET_CREDIT_CARD_ONBOARDING_DETAILS`;
export const ERR_CREDIT_CARD_ONBOARDING_DETAILS = `app/${CREDIT_CARD_KEY}/ERR_CREDIT_CARD_ONBOARDING_DETAILS`;

export const GET_CURRENT_CREDIT_CARD_DETAILS = `app/${CREDIT_CARD_KEY}/GET_CURRENT_CREDIT_CARD_DETAILS`;
export const SET_CURRENT_CREDIT_CARD_DETAILS = `app/${CREDIT_CARD_KEY}/SET_CURRENT_CREDIT_CARD_DETAILS`;
export const ERR_CURRENT_CREDIT_CARD_DETAILS = `app/${CREDIT_CARD_KEY}/ERR_CURRENT_CREDIT_CARD_DETAILS`;

export const GET_ALL_CREDIT_CARDS = `app/${CREDIT_CARD_KEY}/GET_ALL_CREDIT_CARDS`;
export const SET_ALL_CREDIT_CARDS = `app/${CREDIT_CARD_KEY}/SET_ALL_CREDIT_CARDS`;
export const ERR_ALL_CREDIT_CARDS = `app/${CREDIT_CARD_KEY}/ERR_ALL_CREDIT_CARDS`;

export const GET_CREDIT_CARD_TRACKING_INFO = `app/${CREDIT_CARD_KEY}/GET_CREDIT_CARD_TRACKING_INFO`;
export const SET_CREDIT_CARD_TRACKING_INFO = `app/${CREDIT_CARD_KEY}/SET_CREDIT_CARD_TRACKING_INFO`;
export const ERR_CREDIT_CARD_TRACKING_INFO = `app/${CREDIT_CARD_KEY}/ERR_CREDIT_CARD_TRACKING_INFO`;

export const GET_CREDIT_CARD_CONTROL_DETAILS = `app/${CREDIT_CARD_KEY}/GET_CREDIT_CARD_CONTROL_DETAILS`;
export const SET_CREDIT_CARD_CONTROL_DETAILS = `app/${CREDIT_CARD_KEY}/SET_CREDIT_CARD_CONTROL_DETAILS`;
export const ERR_CREDIT_CARD_CONTROL_DETAILS = `app/${CREDIT_CARD_KEY}/ERR_CREDIT_CARD_CONTROL_DETAILS`;

export const GET_CREDIT_CARD_USAGE_DETAILS = `app/${CREDIT_CARD_KEY}/GET_CREDIT_CARD_USAGE_DETAILS`;
export const SET_CREDIT_CARD_USAGE_DETAILS = `app/${CREDIT_CARD_KEY}/SET_CREDIT_CARD_USAGE_DETAILS`;
export const ERR_CREDIT_CARD_USAGE_DETAILS = `app/${CREDIT_CARD_KEY}/ERR_CREDIT_CARD_USAGE_DETAILS`;

export const UPDATE_CREDIT_CARD_USAGE_DETAILS = `app/${CREDIT_CARD_KEY}/UPDATE_CREDIT_CARD_USAGE_DETAILS`;
export const ERR_IN_UPDATE_CREDIT_CARD_USAGE_DETAILS = `app/${CREDIT_CARD_KEY}/ERR_IN_UPDATE_CREDIT_CARD_USAGE_DETAILS`;

export const GET_CURRENT_LIMIT_USAGE_DETAILS = `app/${CREDIT_CARD_KEY}/GET_CURRENT_LIMIT_USAGE_DETAILS`;
export const SET_CURRENT_LIMIT_USAGE_DETAILS = `app/${CREDIT_CARD_KEY}/SET_CURRENT_LIMIT_USAGE_DETAILS`;
export const ERR_CURRENT_LIMIT_USAGE_DETAILS = `app/${CREDIT_CARD_KEY}/ERR_CURRENT_LIMIT_USAGE_DETAILS`;

export const GET_CREDIT_CARD_BILLING_INFO = `app/${CREDIT_CARD_KEY}/GET_CREDIT_CARD_BILLING_INFO`;
export const SET_CREDIT_CARD_BILLING_INFO = `app/${CREDIT_CARD_KEY}/SET_CREDIT_CARD_BILLING_INFO`;
export const ERR_CREDIT_CARD_BILLING_INFO = `app/${CREDIT_CARD_KEY}/ERR_CREDIT_CARD_BILLING_INFO`;

export const GET_ALL_ACTIVE_LOAN_ACCOUNTS = `app/${CREDIT_CARD_KEY}/GET_ALL_ACTIVE_LOAN_ACCOUNTS`;
export const SET_ALL_ACTIVE_LOAN_ACCOUNTS = `app/${CREDIT_CARD_KEY}/SET_ALL_ACTIVE_LOAN_ACCOUNTS`;
export const ERR_ALL_ACTIVE_LOAN_ACCOUNTS = `app/${CREDIT_CARD_KEY}/ERR_ALL_ACTIVE_LOAN_ACCOUNTS`;

export const GET_ALL_CLOSED_LOAN_ACCOUNTS = `app/${CREDIT_CARD_KEY}/GET_ALL_CLOSED_LOAN_ACCOUNTS`;
export const SET_ALL_CLOSED_LOAN_ACCOUNTS = `app/${CREDIT_CARD_KEY}/SET_ALL_CLOSED_LOAN_ACCOUNTS`;
export const ERR_ALL_CLOSED_LOAN_ACCOUNTS = `app/${CREDIT_CARD_KEY}/ERR_ALL_CLOSED_LOAN_ACCOUNTS`;

export const GET_FD_CREDIT_CARD_DETAILS = `app/${CREDIT_CARD_KEY}/GET_FD_CREDIT_CARD_DETAILS`;
export const SET_FD_CREDIT_CARD_DETAILS = `app/${CREDIT_CARD_KEY}/SET_FD_CREDIT_CARD_DETAILS`;
export const ERR_FD_CREDIT_CARD_DETAILS = `app/${CREDIT_CARD_KEY}/ERR_FD_CREDIT_CARD_DETAILS`;

export const GET_LOAN_ACCOUNT_DETAILS = `app/${CREDIT_CARD_KEY}/GET_LOAN_ACCOUNT_DETAILS`;
export const SET_LOAN_ACCOUNT_DETAILS = `app/${CREDIT_CARD_KEY}/SET_LOAN_ACCOUNT_DETAILS`;
export const ERR_LOAN_ACCOUNT_DETAILS = `app/${CREDIT_CARD_KEY}/ERR_LOAN_ACCOUNT_DETAILS`;
export const INITIATE_BLOCK_CARD = `app/${CREDIT_CARD_KEY}/INITIATE_BLOCK_CARD`;
export const SET_BLOCK_CARD = `app/${CREDIT_CARD_KEY}/SET_BLOCK_CARD`;
export const ERR_BLOCK_CARD = `app/${CREDIT_CARD_KEY}/ERR_BLOCK_CARD`;

export const INITIATE_SUSPEND_CARD = `app/${CREDIT_CARD_KEY}/INITIATE_SUSPEND_CARD`;
export const SET_SUSPEND_CARD = `app/${CREDIT_CARD_KEY}/SET_SUSPEND_CARD`;
export const ERR_SUSPEND_CARD = `app/${CREDIT_CARD_KEY}/ERR_SUSPEND_CARD`;

export const SET_BLOCK_CARD_CONFIRM_SCREEN = `app/${CREDIT_CARD_KEY}/SET_BLOCK_CARD_CONFIRM_SCREEN`;
export const SET_SUSPEND_CARD_CONFIRM_SCREEN = `app/${CREDIT_CARD_KEY}/SET_SUSPEND_CARD_CONFIRM_SCREEN`;

export const GET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS = `app/${CREDIT_CARD_KEY}/GET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS`;
export const SET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS = `app/${CREDIT_CARD_KEY}/SET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS`;
export const ERR_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS = `app/${CREDIT_CARD_KEY}/ERR_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS`;

export const GET_LOAN_OFFERS = `app/${CREDIT_CARD_KEY}/GET_LOAN_OFFERS`;
export const SET_LOAN_OFFERS = `app/${CREDIT_CARD_KEY}/SET_LOAN_OFFERS`;
export const ERR_LOAN_OFFERS = `app/${CREDIT_CARD_KEY}/ERR_LOAN_OFFERS`;

export const TOGGLE_SCREEN = `app/${CREDIT_CARD_KEY}/TOGGLE_SCREEN`;

export const GET_CREDIT_CARD_ELIGIBILITY_DETAILS = `app/${CREDIT_CARD_KEY}/GET_CREDIT_CARD_ELIGIBILITY_DETAILS`;
export const SET_CREDIT_CARD_ELIGIBILITY_DETAILS = `app/${CREDIT_CARD_KEY}/SET_CREDIT_CARD_ELIGIBILITY_DETAILS`;
export const ERR_CREDIT_CARD_ELIGIBILITY_DETAILS = `app/${CREDIT_CARD_KEY}/ERR_CREDIT_CARD_ELIGIBILITY_DETAILS`;

export const SET_SHOW_ONBOARDING_DETAILS_V2 = `app/${CREDIT_CARD_KEY}/SET_SHOW_ONBOARDING_DETAILS_V2`;

export const GET_CREDIT_CARD_ONBOARDING_DETAILS_V2 = `app/${CREDIT_CARD_KEY}/GET_CREDIT_CARD_ONBOARDING_DETAILS_V2`;
export const SET_CREDIT_CARD_ONBOARDING_DETAILS_V2 = `app/${CREDIT_CARD_KEY}/SET_CREDIT_CARD_ONBOARDING_DETAILS_V2`;
export const ERR_CREDIT_CARD_ONBOARDING_DETAILS_V2 = `app/${CREDIT_CARD_KEY}/ERR_CREDIT_CARD_ONBOARDING_DETAILS_V2`;

export const GET_MILESTONE_REWARDS_DETAILS = `app/${CREDIT_CARD_KEY}/GET_MILESTONE_REWARDS_DETAILS`;
export const SET_MILESTONE_REWARDS_DETAILS = `app/${CREDIT_CARD_KEY}/SET_MILESTONE_REWARDS_DETAILS`;
export const ERR_MILESTONE_REWARDS_DETAILS = `app/${CREDIT_CARD_KEY}/ERR_MILESTONE_REWARDS_DETAILS`;

export const GET_SELECTED_CREDIT_CARD_DETAILS = `app/${CREDIT_CARD_KEY}/GET_SELECTED_CREDIT_CARD_DETAILS`;
export const SET_SELECTED_CREDIT_CARD_DETAILS = `app/${CREDIT_CARD_KEY}/SET_SELECTED_CREDIT_CARD_DETAILS`;

export const GET_CREDIT_CARD_CURRENT_STAGE_DETAILS = `app/${CREDIT_CARD_KEY}/GET_CREDIT_CARD_CURRENT_STAGE_DETAILS`;
export const SET_CREDIT_CARD_CURRENT_STAGE_DETAILS = `app/${CREDIT_CARD_KEY}/SET_CREDIT_CARD_CURRENT_STAGE_DETAILS`;

export const GET_CREDIT_CARD_NEXT_STAGE_DETAILS = `app/${CREDIT_CARD_KEY}/GET_CREDIT_CARD_NEXT_STAGE_DETAILS`;
export const SET_CREDIT_CARD_NEXT_STAGE_DETAILS = `app/${CREDIT_CARD_KEY}/SET_CREDIT_CARD_NEXT_STAGE_DETAILS`;

export const CURRENT_CREDIT_CARD_DETAILS_LABEL_DATA = [
    {
        id: 1,
        label: 'Bank',
        key: 'bankName',
    },
    {
        id: 2,
        label: 'Card Number',
        key: 'cardNumber',
    },
    {
        id: 3,
        label: 'Kit Number',
        key: 'kitNumber',
    },
    {
        id: 4,
        label: 'Card Category',
        key: 'cardCategory',
    },
    {
        id: 5,
        label: 'Digital Card Status',
        key: 'digitalCardStatus',
    },
    {
        id: 6,
        label: 'Physical card status',
        key: 'cardPinStatus',
    },
    {
        id: 7,
        label: 'created At',
        key: 'createdTs',
    },
    {
        id: 8,
        label: 'updated At',
        key: 'updateTs',
    },
    {
        id: 9,
        label: 'Payment Cycle',
        key: 'paymentCycle',
    },
    {
        id: 10,
        label: 'Card Type',
        key: 'cardType',
    },
    {
        id: 11,
        label: 'Card Form',
        key: 'cardForm',
    },
];

export const CURRENT_LIMIT_USAGE_DETAILS_LABEL_DATA = [
    {
        id: 1,
        label: 'Total Limit',
        key: 'totalLimit',
    },
    {
        id: 2,
        label: 'Available Limit',
        key: 'availableLimit',
    },
    {
        id: 3,
        label: 'Used Limit',
        key: 'usedLimit',
    },
    {
        id: 4,
        label: 'Billed',
        key: 'billedAmount',
    },
    {
        id: 5,
        label: 'Unbilled',
        key: 'unbilledAmount',
    },
];

export const CREDIT_CARD_BILLING_INFO_LABEL_DATA = [
    {
        id: 1,
        label: 'Bill generation Date',
        key: 'billGenerationDate',
    },
    {
        id: 2,
        label: 'Due Date',
        key: 'dueDate',
    },
    {
        id: 3,
        label: 'Total Due',
        key: 'totalDue',
    },
    {
        id: 4,
        label: 'Min. Due',
        key: 'minDue',
    },
    {
        id: 5,
        label: 'Total Fi Coins Earned',
        key: 'totalRewardCoins',
    },
];

export const CARD_USAGE_LIMITS_LIST = [
    {
        key: 'card_control_type_atm',
        limitLabel: 'ATM',
    },
    {
        key: 'card_control_type_ecom',
        limitLabel: 'Ecommerce',
    },
    {
        key: 'card_control_type_pos',
        limitLabel: 'POS',
    },
];

export const CARD_USAGE_SETTINGS_LIST = [
    {
        key: 'card_control_type_atm',
        settingLabel: 'ATM Withdrawals',
    },
    {
        key: 'card_control_type_ecom',
        settingLabel: 'Online Transactions',
    },
    {
        key: 'card_control_type_pos',
        settingLabel: 'Card Swiping (POS)',
    },
    {
        key: 'card_control_type_contactless',
        settingLabel: 'Contactless Payments',
    },
    {
        key: 'card_control_type_international',
        settingLabel: 'International Usage',
    },
];

export const CREDIT_CARD_ONBOARDING_STAGES_DATA = [
    {
        id: 1,
        label: 'Stage',
        key: 'stage',
    },
    {
        id: 2,
        label: 'Description',
        key: 'onboardingStageName',
    },
    {
        id: 3,
        label: 'Timestamp',
        key: 'startTimestamp',
    },
    {
        id: 4,
        label: 'Status',
        key: 'status',
    },
];

export const ALL_ACTIVE_LOAN_ACCOUNTS_LABEL_DATA = [
    {
        id: 1,
        label: 'Transaction Amount',
        key: 'transactionAmount',
    },
    {
        id: 2,
        label: 'EMI Conversion Date',
        key: 'emiConversionDate',
    },
    {
        id: 3,
        label: 'Merchant',
        key: 'merchant',
    },
    {
        id: 4,
        label: 'Vendor Loan ID',
        key: 'vendorLoanId',
    },
    {
        id: 5,
        label: 'Status',
        key: 'loanAccountStatus',
    },
    {
        id: 6,
        label: 'Tenure',
        key: 'tenure',
    },
];

export const ALL_CLOSED_LOAN_ACCOUNTS_LABEL_DATA = [
    {
        id: 1,
        label: 'Transaction Amount',
        key: 'transactionAmount',
    },
    {
        id: 2,
        label: 'Last EMI Statement Date',
        key: 'lastEmiStatementDate',
    },
    {
        id: 3,
        label: 'EMI Closed On',
        key: 'emiClosureDate',
    },
    {
        id: 4,
        label: 'Merchant',
        key: 'merchant',
    },
    {
        id: 5,
        label: 'Vendor Loan ID',
        key: 'vendorLoanId',
    },
    {
        id: 6,
        label: 'Status',
        key: 'loanAccountStatus',
    },
    {
        id: 7,
        label: 'Tenure',
        key: 'tenure',
    },
];

export const ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS_LABEL_DATA = [
    {
        id: 1,
        label: 'Merchant',
        key: 'merchant',
    },
    {
        id: 2,
        label: 'Transaction Date',
        key: 'transactionDate',
    },
    {
        id: 3,
        label: 'Transaction Amount',
        key: 'transactionAmount',
    },
    {
        id: 4,
        label: 'Transaction ID',
        key: 'externalTransactionId',
    },
];

export const IS_CARD_LIMITS_SCREEN = 'isCardLimitsScreen';
export const IS_CARD_SETTINGS_SCREEN = 'isCardSettingsScreen';
export const IS_LOAN_ACCOUNT_DETAILS_SCREEN = 'isLoanAccountDetailsScreen';
export const IS_ELIGIBLE_TRANSACTIONS_FOR_EMI_SCREEN = 'isEligibleTransactionsForEmiScreen';
export const IS_LOAN_OFFERS_SCREEN = 'isLoanOffersScreen';
export const IS_BLOCK_CONFIRM_SCREEN = 'isBlockConfirmScreen';
export const IS_SUSPEND_CONFIRM_SCREEN = 'isSuspendConfirmScreen';
export const IS_CREDIT_CARD_KYC_SCREEN = 'isCreditCardKycScreen';
export const IS_SELECTED_CREDIT_CARD_SCREEN = 'isSelectedCreditCardScreen';
export const IS_CREDIT_CARD_CURRENT_STAGE_SCREEN = 'isCurrentStageScreen';
export const IS_CREDIT_CARD_NEXT_STAGE_SCREEN = 'isNextStageScreen';
export const IS_CREDIT_CARD_MILESTONE_REWARDS_SCREEN = 'isMilestoneRewardsScreen';

// screens from onboarding details table deeplink
export const SCREEN_NAME_CREDIT_CARD_KYC_DETAILS = 'SCREEN_NAME_CREDIT_CARD_KYC_DETAILS';
export const SCREEN_NAME_CREDIT_CARD_DETAILS = 'SCREEN_NAME_CREDIT_CARD_DETAILS';
export const SCREEN_NAME_CREDIT_CARD_CURRENT_STAGE_DETAILS = 'SCREEN_NAME_CREDIT_CARD_CURRENT_STAGE_DETAILS';
export const SCREEN_NAME_CREDIT_CARD_NEXT_STAGE_DETAILS = 'SCREEN_NAME_CREDIT_CARD_NEXT_STAGE_DETAILS';

export const CARD_ACTION_TYPE = {
    BLOCK_CARD: 'BLOCK_CARD',
    SUSPEND_CARD: 'SUSPEND_CARD',
};

export const getDisplayLableValuePairsForMilestoneRewardsSection = (data) => [
    {
        label: "User's DPD (day past due) Status:",
        value: data?.dpd_status,
    },
    {
        label: 'User is active',
        value: data?.is_user_active,
    },
    {
        label: 'Retain User',
        value: data?.retain_user,
    },
    {
        label: 'Recommendation',
        value: data?.recommendation,
    },
];

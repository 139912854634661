/*
 *
 * AppLogs reducer
 *
 */

import { fromJS } from 'immutable';
import {
    GET_LOGS, SET_LOGS, ERR_LOGS, GET_LOG_INFO, SET_LOG_INFO, ERR_LOG_INFO,
    SET_MONORAIL_ID, DOWNLOAD_FILE, SET_LOGS_ENUMS, IDENTIFIER_TYPE_LIST_KEY,
} from './constants';

const initialState = fromJS({
    monorailInfo: {
        id: '',
    },
    loading: {
        logsList: false,
        logInfo: false,
    },
    err: {
        logsList: '',
        logInfo: '',
    },
    isSearchInitiated: false,
    logsList: [],
    logInfo: {},
    identifierTypeList: [],
});

function appLogsReducer(state = initialState, action = '') {
    switch (action.type) {
        case SET_LOGS_ENUMS: {
            const { identifierTypeList } = action.data;
            return state.setIn([IDENTIFIER_TYPE_LIST_KEY], identifierTypeList);
        }

        case GET_LOGS:
            return state
                .setIn(['loading', 'logsList'], true)
                .setIn(['err', 'logsList'], '');

        case SET_LOGS: {
            const { appLogList } = action.data;

            return state
                .setIn(['loading', 'logsList'], false)
                .set('isSearchInitiated', true)
                .set('logsList', fromJS(appLogList));
        }

        case ERR_LOGS: {
            const { err } = action.data;

            return state
                .setIn(['loading', 'logsList'], false)
                .setIn(['err', 'logsList'], err)
                .set('logsList', fromJS([]))
                .set('logInfo', fromJS({}));
        }

        case GET_LOG_INFO: {
            const { next, isDownloadFn } = action.data;

            if (isDownloadFn || next) {
                return state
                    .setIn(['loading', 'logInfo'], true)
                    .setIn(['err', 'logInfo'], '');
            }

            return state
                .setIn(['loading', 'logInfo'], true)
                .setIn(['err', 'logInfo'], '')
                .set('logInfo', fromJS({}));
        }

        case SET_LOG_INFO: {
            const {
                logs, calledWithNextToken, prev, next, hasNext,
            } = action.data;
            let modifiedLogs;

            if (calledWithNextToken) {
                const oldLogs = state.getIn(['logInfo', 'data']);
                modifiedLogs = `${oldLogs}${logs}`;
            } else {
                modifiedLogs = logs;
            }

            return state
                .setIn(['loading', 'logInfo'], false)
                .setIn(['logInfo', 'data'], modifiedLogs)
                .setIn(['logInfo', 'prev'], fromJS(prev))
                .setIn(['logInfo', 'next'], fromJS(next))
                .setIn(['logInfo', 'hasNext'], hasNext);
        }

        case ERR_LOG_INFO: {
            const { err } = action.data;

            return state
                .setIn(['loading', 'logInfo'], false)
                .setIn(['err', 'logInfo'], err)
                .set('logInfo', fromJS({}));
        }

        case DOWNLOAD_FILE: {
            return state
                .setIn(['loading', 'logInfo'], false)
                .setIn(['err', 'logInfo'], '');
        }

        case SET_MONORAIL_ID: {
            const { value } = action.data;

            if (value) {
                return state.setIn(['monorailInfo', 'id'], value);
            }

            return initialState;
        }

        default:
            return state;
    }
}

export default appLogsReducer;

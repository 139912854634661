/**
 * @file ViewSingleRaiseVerificationRequestCase
 * Parent component to case details like employment details, last verified transaction details etc. for the selected single case
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { setSalaryAccountOpsCurViewAction } from '../../actions';
import { SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY, VIEW_RAISE_VERIFICATION_CASES } from '../../constants';

import RaiseVerificationRequestCaseDetails from '../RaiseVerificationRequestCaseDetails';

const ViewSingleRaiseVerificationRequestCase = () => {
    const dispatch = useDispatch();

    const handleBackClick = () => {
        dispatch(setSalaryAccountOpsCurViewAction({
            screenKey: SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
            value: VIEW_RAISE_VERIFICATION_CASES,
        }));
    };

    return (
        <RaiseVerificationRequestCaseDetails
            handleBackClick={handleBackClick}
        />
    );
};

export default memo(ViewSingleRaiseVerificationRequestCase);

/**
 * @file Manages the reducer state & functions for Search component
 */

import { fromJS } from 'immutable';
import {
    ERR_SEARCH_SOP, ERR_SEARCH_SOP_META, GET_SEARCH_SOP, GET_SEARCH_SOP_META, SET_SEARCH_SOP, SET_SEARCH_SOP_META,
} from './constants';

const initialState = fromJS({
    meta: {
        originalArgs: {},
        loading: false,
        err: '',
        categoryMap: null,
        totalResults: 0,
    },
    tempResults: {
        originalArgs: {},
        loading: false,
        err: '',
        from: '0',
        size: '5',
        data: null,
    },
    results: {
        originalArgs: {},
        loading: false,
        err: '',
        from: '0',
        size: '10',
        data: null,
    },
});

function searchReducer(state = initialState, action = {}) {
    switch (action.type) {
        // Getter changes
        case GET_SEARCH_SOP:
        case GET_SEARCH_SOP_META: {
            const { screenKey, clearData } = action.data;
            let key;
            if (screenKey) {
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                const values = {
                    loading: true,
                    err: '',
                };
                if (clearData) values.data = null;
                return state.mergeIn(key, values);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '')
                .setIn(['data'], null);
        }
        // Setter merge changes
        case SET_SEARCH_SOP:
        case SET_SEARCH_SOP_META: {
            const { screenKey, key: keyArg, value } = action.data;
            let key;
            if (screenKey) {
                let values = {
                    loading: false,
                    err: '',
                };
                if (value) {
                    values = {
                        ...values,
                        ...value,
                    };
                }
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                return state.mergeIn(key, values);
            }

            return state
                .setIn([keyArg], value)
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }

        // Err Changes
        case ERR_SEARCH_SOP:
        case ERR_SEARCH_SOP_META: {
            const { screenKey, err } = action.data;
            let key;
            if (screenKey) {
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                const values = {
                    loading: false,
                    err,
                };
                return state.mergeIn(key, values);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['err'], err)
                .setIn(['data'], null);
        }

        default:
            return state;
    }
}

export default searchReducer;

/**
 * @file Utils for SalaryWhitelistB2B
 */

import {
    B2B_ONBOARDING_PATH, SALARY_WHITELIST_B2B_BANKING_PROFILE_PATH, SALARY_WHITELIST_B2B_BANKING_SAVINGS_ACCOUNT_PATH,
} from '../App/routes';

export const getSideNavbarItems = () => [
    {
        id: 'b2b-onboarding',
        label: 'B2B Onboarding',
        path: B2B_ONBOARDING_PATH,
    },
    {
        id: 'banking',
        label: 'Banking',
        nestedLinks: [
            {
                id: 'profile',
                label: 'Profile',
                path: SALARY_WHITELIST_B2B_BANKING_PROFILE_PATH,
            },
            {
                id: 'savings-account',
                label: 'Saving Accounts',
                path: SALARY_WHITELIST_B2B_BANKING_SAVINGS_ACCOUNT_PATH,
            },
        ],
    },
];

/**
 * @file Landing Page for Account Ops users
 * All routes related to Account Ops flow would be listed here
 */

import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { VerticalNavbar } from '../../components';

import { SIDE_NAVBAR_INFO } from './utils';

import './style.scss';

const AccountOps = () => {
    const { navItems, defaultRedirectPath } = SIDE_NAVBAR_INFO;

    return (
        <div className='accops-cr'>
            <VerticalNavbar hideHorizontalNavbar navItems={navItems} />
            <div className='accops-cc'>
                <Switch>
                    {navItems.map((navItem) => (
                        <Route
                            key={navItem.path}
                            path={navItem.path}
                            component={navItem.component}
                        />
                    ))}
                    <Redirect to={{ pathname: defaultRedirectPath }} />
                </Switch>
            </div>
        </div>
    );
};

export default memo(AccountOps);

/**
 *
 * @file USSRemittanceDetail : Show users investment details by remittance id or date.
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getRemittanceTypeListAction, getRemittances } from '../actions';
import { selectRemittance, selectRemittanceTypes } from '../selectors';
import USSOrderView from '../USSOrderView';
import USSDateView from '../USSDateView';
import { Button, Loader, Table } from '../../../components';
import RemittanceOrderDetailsModal from './RemittanceOrderDetailsModal';

const USSRemittanceDetail = (props) => {
    const {
        ticketId, userId, index,
    } = props;

    const dispatch = useDispatch();

    const [selectedRemittanceType, setSelectedRemittanceType] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRemittanceId, setSelectedRemittanceId] = useState();

    const remittanceData = useSelector(selectRemittance, shallowEqual);
    const remittanceTypes = useSelector(selectRemittanceTypes, shallowEqual);

    useEffect(() => {
        dispatch(getRemittanceTypeListAction());
    }, [dispatch]);

    useEffect(() => {
        if (remittanceTypes) {
            setSelectedRemittanceType(remittanceTypes[0]);
        }
    }, [remittanceTypes]);

    const handleDropdownChange = (value) => {
        setSelectedRemittanceType(value);
    };

    const searchAction = (params) => {
        const {
            textInput, fromDate, toDate, dropdownValue,
        } = params;

        let payload = {
            ticketId,
            userId,
            index,
        };

        if (textInput) {
            payload = {
                ...payload,
                remittanceId: textInput,
            };
        }

        if (fromDate || toDate || dropdownValue) {
            payload = {
                ...payload,
                from: fromDate?.value ?? '',
                to: toDate?.value ?? '',
                remittanceType: dropdownValue?.value ?? '',
            };
        }

        dispatch(getRemittances(payload));
    };

    const handleViewDetails = (item) => {
        setSelectedRemittanceId(item.remittance_id.value || '');
        setIsModalOpen(true);
    };

    const renderActions = (item) => (
        <div className='frfscWrapper'>
            <Button
                link
                label='View Details'
                onClick={() => handleViewDetails(item)}
            />
        </div>
    );

    return (
        <div className='uss'>
            <USSOrderView
                ticketId={ticketId}
                userId={userId}
                index={index}
                searchAction={searchAction}
                heading='View user investment details - Search by Remittance ID'
                inputLabel='Remittance Id'
            />
            {/* to keep some empty space b/w two search cards */}
            <div className='uss-src' />
            <USSDateView
                showDropdown
                ticketId={ticketId}
                userId={userId}
                index={index}
                searchAction={searchAction}
                heading='View user investment details - Search by Remittance ID'
                dropdownLabel='Remittance Type'
                dropdownValue={selectedRemittanceType}
                handleDropdownChange={handleDropdownChange}
                dropdownOptions={remittanceTypes}
            />
            <div className='uss-src mb-30' />
            <div className='mb-30'>
                {remittanceData
                && remittanceData[ticketId] && remittanceData[ticketId].data && remittanceData[ticketId].data.map((remittanceDatum) => (
                    <Table
                        objKey='header_key'
                        header={remittanceDatum.tableName}
                        key={(remittanceDatum.rowData && remittanceDatum.rowData[0] && remittanceDatum.rowData[0].meta) || ''}
                        labelData={remittanceDatum.columnData}
                        contentData={remittanceDatum.rowData}
                        renderActionableColumn={renderActions}
                        prevToken={remittanceData.prev}
                        nextToken={remittanceData.next}
                        paginatedClickHandler={() => {}}
                        extClasses={{
                            container: 'p-20',
                        }}
                    />
                ))}
            </div>
            {isModalOpen && (
                <RemittanceOrderDetailsModal
                    visible={isModalOpen}
                    ticketId={ticketId}
                    userId={userId}
                    index={index}
                    remittanceId={selectedRemittanceId}
                    toggleHandler={(changedResponse) => setIsModalOpen(changedResponse)}
                />
            )}
            <Loader visible={remittanceData
                && remittanceData[ticketId]
                && remittanceData[ticketId].loading}
            />
        </div>
    );
};

export default memo(USSRemittanceDetail);

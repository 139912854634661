import { createSelector } from 'reselect';
import { AUTOPAY_KEY } from './constants';

const selectAutoPay = (state) => state.get(AUTOPAY_KEY);

const makeSelectExistingMandates = createSelector(
    selectAutoPay,
    (substate) => substate.get('existing_mandate_details').toJS(),
);

const makeSelectUpcomingTransactions = createSelector(
    selectAutoPay,
    (substate) => substate.get('upcoming_transaction_details').toJS(),
);

export {
    makeSelectExistingMandates,
    makeSelectUpcomingTransactions,
};

/**
*
* PhoneNumberField
*
*/

import React from 'react';
import PropTypes from 'prop-types'
import './style.scss';

class PhoneNumberField extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { label, input: { name, value: { country_code, national_number }, onChange } } = this.props;
    return (
      <div className="form-group phone-number">
        <label>{label}</label>
        {// TODO: Change to dropdown
        }
        <div className="input-wrapper">
          <input 
            id={name+"-country-code"} 
            type="text" 
            className="form-control country-code" 
            placeholder={"Code"} 
            value={country_code}
            onChange={(e) => onChange({ country_code: e.target.value, national_number: national_number })}
          />
          <input 
            id={name+"-national-number"}
            type="text"
            className="form-control national-number" 
            placeholder={"Phone Number"} 
            value={national_number} 
            onChange={(e) => onChange({ country_code: country_code, national_number: e.target.value })}
          />
        </div>
      </div>
    );
  }
}

PhoneNumberField.propTypes = {

};

export default PhoneNumberField;

/**
 * @file UserRiskReview reducer
 */

import { fromJS } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import {
    ERROR_USER_RISK_PRODUCT_INFO, ERROR_USER_RISK_PRODUCT_LIST, GET_USER_RISK_PRODUCT_INFO_DATA,
    GET_USER_RISK_PRODUCT_LIST_DATA,
    RESET_USER_RISK_PRODUCT_INFO_DATA, SET_USER_RISK_PRODUCT_INFO_DATA, SET_USER_RISK_PRODUCT_LIST_DATA,
} from './constants';

const initialState = fromJS({
    productListError: '',
    userProductList: [],
    product: null,

    loading: false,
    productInfoError: '',
    userProductInfo: [],
});

function userRiskProductReducer(state = initialState, action = '') {
    switch (action.type) {
        case GET_USER_RISK_PRODUCT_LIST_DATA: {
            return state.setIn(['productListError'], '');
        }

        case SET_USER_RISK_PRODUCT_LIST_DATA: {
            const { data } = action;

            return state
                .setIn(['userProductList'], fromJS(data));
        }

        case ERROR_USER_RISK_PRODUCT_LIST: {
            const { err } = action.data;

            return state.setIn(['productListError'], err);
        }

        case GET_USER_RISK_PRODUCT_INFO_DATA: {
            const { product } = action.data;

            return state
                .setIn(['loading'], true)
                .setIn(['product'], fromJS(product))
                .setIn(['productInfoError'], '');
        }

        case SET_USER_RISK_PRODUCT_INFO_DATA: {
            const { data } = action;
            const sectionList = [];

            data.forEach((item, index) => {
                sectionList.push({
                    ...item,
                    sectionName: item.ui_options.header,
                    id: uuidv4(),
                    index: index + 1,
                });
            });

            return state.setIn(['userProductInfo'], fromJS(sectionList))
                .setIn(['loading'], false);
        }

        case ERROR_USER_RISK_PRODUCT_INFO: {
            const { err } = action.data;

            return state.setIn(['productInfoError'], err).setIn(['loading'], false);
        }

        case RESET_USER_RISK_PRODUCT_INFO_DATA: {
            return initialState;
        }

        default:
            return state;
    }
}

export default userRiskProductReducer;

/**
 * @file Manages the constants for AppV2 component
 */
import {
    HOME_PATH, SCRIPTS_PATH, SOP_PATH, TICKETS_PATH,
} from './routes';

export const navItems = [
    {
        label: 'Home',
        img: './svgs/home.svg',
        id: 'home',
        path: HOME_PATH,
        subLinks: [],
    },
    {
        label: 'My Tickets',
        img: './svgs/tickets.svg',
        id: 'tickets',
        path: TICKETS_PATH,
        subLinks: [],
    },
    {
        label: 'Scripts',
        img: './svgs/scripts.svg',
        id: 'scripts',
        path: SCRIPTS_PATH,
        subLinks: [],
    },
    {
        label: 'SOP',
        img: './svgs/sop.svg',
        id: 'sop',
        path: SOP_PATH,
        subLinks: [],
    },
];

// key to be used for reducers & selectors for main appv2
export const APP_V2_KEY = 'app_v2';

export const ACCESS_LEVEL_LIST = [
    {
        label: 'SUPER_ADMIN',
        value: 'SUPER_ADMIN',
    },
    {
        label: 'ADMIN',
        value: 'ADMIN',
    },
    {
        label: 'ADMIN_RESTRICTED',
        value: 'ADMIN_RESTRICTED',
    },
    {
        label: 'AGENT',
        value: 'AGENT',
    },
    {
        label: 'VKYC_CALL_AGENT',
        value: 'VKYC_CALL_AGENT',
    },
    {
        label: 'VKYC_CALL_AUDITOR',
        value: 'VKYC_CALL_AUDITOR',
    },
];

export const SOP_KEY = `${APP_V2_KEY}/SEARCH`;
export const GET_L1_CATEGORIES = `${APP_V2_KEY}/GET_L1_CATEGORIES`;
export const SET_L1_CATEGORIES = `${APP_V2_KEY}/SET_L1_CATEGORIES`;
export const ERR_L1_CATEGORIES = `${APP_V2_KEY}/ERR_L1_CATEGORIES`;

export const CREATE_TICKET_FOR_CALL = `${APP_V2_KEY}/CREATE_TICKET_FOR_CALL`;
export const SET_CREATED_TICKET_FOR_CALL = `${APP_V2_KEY}/SET_CREATED_TICKET_FOR_CALL`;
export const ERR_CREATED_TICKET_FOR_CALL = `${APP_V2_KEY}/ERR_CREATED_TICKET_FOR_CALL`;

import { createSelector } from 'reselect';

const selectTicketSummary = state => state.get('ticketSummary');

const makeSelectLoading = () => createSelector(
    selectTicketSummary,
    substate => substate.get('loading')
);

const makeSelectErr = () => createSelector(
    selectTicketSummary,
    substate => substate.get('err')
);

const makeSelectTicketInfo = () => createSelector(
    selectTicketSummary,
    substate => substate.get('ticketInfo').toJS()
);

const makeSelectTicketNotes = () => createSelector(
    selectTicketSummary,
    substate => substate.get('ticketNotes').toJS()
);

const makeSelectHasTicketInfo = () => createSelector(
    selectTicketSummary,
    substate => substate.get('hasTicketInfo')
);

const makeSelectHasTicketNotes = () => createSelector(
    selectTicketSummary,
    substate => substate.get('hasTicketNotes')
);

const makeSelectTicketId = () => createSelector(
    selectTicketSummary,
    substate => substate.get('ticketId'),
);

export {
    makeSelectLoading,
    makeSelectErr,
    makeSelectTicketInfo,
    makeSelectTicketNotes,
    makeSelectHasTicketInfo,
    makeSelectHasTicketNotes,
    makeSelectTicketId,
};

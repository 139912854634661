/**
 * @file Manages the reducer state & functions for AppV2 component
 */

import { fromJS } from 'immutable';
import {
    CREATE_TICKET_FOR_CALL, SET_CREATED_TICKET_FOR_CALL, ERR_CREATED_TICKET_FOR_CALL,
    ERR_L1_CATEGORIES, GET_L1_CATEGORIES, SET_L1_CATEGORIES,
} from './constants';

const initialState = fromJS({
    L1Categories: {
        loading: false,
        err: '',
        L1List: {},
    },
    createTicketForCall: {
        loading: false,
        err: '',
        data: null,
    },
});

function appV2Reducer(state = initialState, action = {}) {
    switch (action.type) {
        // Getter changes
        case CREATE_TICKET_FOR_CALL:
        case GET_L1_CATEGORIES: {
            const { screenKey, clearData } = action.data;
            let key;
            if (screenKey) {
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                const values = {
                    loading: true,
                    err: '',
                };
                if (clearData) values.data = null;
                return state.mergeIn(key, values);
            }
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '')
                .setIn(['data'], null);
        }
        // Setter merge changes
        case SET_CREATED_TICKET_FOR_CALL:
        case SET_L1_CATEGORIES: {
            const { screenKey, key: keyArg, value } = action.data;
            let key;
            if (screenKey) {
                let values = {
                    loading: false,
                    err: '',
                };
                if (value) {
                    values = {
                        ...values,
                        ...value,
                    };
                }
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                return state.mergeIn(key, values);
            }

            return state
                .setIn([keyArg], value)
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }

        // Err Changes
        case ERR_CREATED_TICKET_FOR_CALL:
        case ERR_L1_CATEGORIES: {
            const { screenKey, err } = action.data;
            let key;
            if (screenKey) {
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                const values = {
                    loading: false,
                    err,
                };
                return state.mergeIn(key, values);
            }
            return state
                .setIn(['loading'], false)
                .setIn(['err'], err)
                .setIn(['data'], null);
        }

        default:
            return state;
    }
}

export default appV2Reducer;

/* eslint-disable default-param-last */
import { fromJS } from 'immutable';

import {
    GET_ACCOUNT_CLOSURE_INFO, SET_ACCOUNT_CLOSURE_INFO, ERR_ACCOUNT_CLOSURE_INFO,
    GET_CHEQUEBOOK_REQUEST_DETAILS, SET_CHEQUEBOOK_REQUEST_DETAILS, ERR_CHEQUEBOOK_REQUEST_DETAILS, CHEQUEBOOK_KEY,
    GET_ACCOUNT_STATEMENT_FORM_DETAILS, ERR_ACCOUNT_STATEMENT_FORM_DETAILS, SET_ACCOUNT_STATEMENT_FORM_DETAILS,
    GET_AGENT_PROMPTS_ACTION, SET_AGENT_PROMPTS_ACTION, SET_SEND_NOTIFICATION_BY_PROMPTS, GET_SEND_NOTIFICATION_BY_PROMPTS,
    ERR_AGENT_PROMPTS_ACTION, ERR_SEND_NOTIFICATION_BY_PROMPTS,
} from './constants';

const initialState = fromJS({
    accountClosure: {},
    chequebookReqInfo: {},
    accountStatement: {},
});

function userActionsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ACCOUNT_CLOSURE_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['accountClosure', ticketId, 'loading'], true);
        }

        case SET_ACCOUNT_CLOSURE_INFO: {
            const { ticketId, accountInfo } = action.data;

            return state
                .setIn(['accountClosure', ticketId, 'loading'], false)
                .setIn(['accountClosure', ticketId, 'data'], fromJS(accountInfo))
                .setIn(['accountClosure', ticketId, 'hasData'], true);
        }

        case ERR_ACCOUNT_CLOSURE_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['accountClosure', ticketId, 'loading'], false)
                .setIn(['accountClosure', ticketId, 'data'], fromJS({}))
                .setIn(['accountClosure', ticketId, 'hasData'], false);
        }

        case GET_CHEQUEBOOK_REQUEST_DETAILS: {
            const { ticketId } = action.data;

            return state
                .setIn([CHEQUEBOOK_KEY, ticketId, 'loading'], true);
        }

        case SET_CHEQUEBOOK_REQUEST_DETAILS: {
            const { ticketId, chequebookReqInfo } = action.data;

            return state
                .setIn([CHEQUEBOOK_KEY, ticketId, 'loading'], false)
                .setIn([CHEQUEBOOK_KEY, ticketId, 'data'], fromJS(chequebookReqInfo))
                .setIn([CHEQUEBOOK_KEY, ticketId, 'hasData'], true);
        }

        case ERR_CHEQUEBOOK_REQUEST_DETAILS: {
            const { ticketId } = action.data;

            return state
                .setIn([CHEQUEBOOK_KEY, ticketId, 'loading'], false)
                .setIn([CHEQUEBOOK_KEY, ticketId, 'data'], fromJS({}))
                .setIn([CHEQUEBOOK_KEY, ticketId, 'hasData'], false);
        }

        case GET_ACCOUNT_STATEMENT_FORM_DETAILS: {
            const { ticketId } = action.data;

            return state
                .setIn(['accountStatement', ticketId, 'loading'], true)
                .setIn(['accountStatement', ticketId, 'err'], fromJS({}));
        }

        case SET_ACCOUNT_STATEMENT_FORM_DETAILS: {
            const { ticketId, accountStatementInfo } = action.data;

            // error handling if accountStatementInfo is null saga does not crash
            if (!accountStatementInfo) {
                return state;
            }

            return state
                .setIn(['accountStatement', ticketId, 'loading'], false)
                .setIn(['accountStatement', ticketId, 'data'], fromJS(accountStatementInfo))
                .setIn(['accountStatement', ticketId, 'hasData'], true)
                .setIn(['agentList', ticketId, 'err'], fromJS({}));
        }

        case ERR_ACCOUNT_STATEMENT_FORM_DETAILS: {
            const { ticketId, errInfo } = action.data;

            return state
                .setIn(['accountStatement', ticketId, 'loading'], false)
                .setIn(['accountStatement', ticketId, 'data'], fromJS({}))
                .setIn(['accountStatement', ticketId, 'hasData'], false)
                .setIn(['accountStatement', ticketId, 'err'], fromJS(errInfo));
        }

        case GET_AGENT_PROMPTS_ACTION: {
            const { ticketId } = action.data;

            const key = ['userNotifications', ticketId, 'prompts'];
            const values = {
                loading: true,
                data: fromJS({}),
                hasData: false,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case SET_AGENT_PROMPTS_ACTION: {
            const { ticketId, prompts } = action.data;

            const key = ['userNotifications', ticketId, 'prompts'];
            const values = {
                loading: false,
                data: fromJS(prompts),
                hasData: true,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case ERR_AGENT_PROMPTS_ACTION: {
            const { ticketId, err } = action.data;

            const key = ['userNotifications', ticketId, 'prompts'];
            const values = {
                loading: false,
                data: fromJS({}),
                hasData: false,
                err,
            };
            return state.mergeIn(key, values);
        }

        case GET_SEND_NOTIFICATION_BY_PROMPTS: {
            const { ticketId } = action.data;

            const key = ['userNotifications', ticketId, 'notification'];
            const values = {
                loading: true,
                data: fromJS({}),
                hasData: false,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case SET_SEND_NOTIFICATION_BY_PROMPTS: {
            const { ticketId, notification } = action.data;

            const key = ['userNotifications', ticketId, 'notification'];
            const values = {
                loading: false,
                data: fromJS(notification),
                hasData: true,
                err: '',
            };
            return state.mergeIn(key, values);
        }

        case ERR_SEND_NOTIFICATION_BY_PROMPTS: {
            const { ticketId, err } = action.data;

            const key = ['userNotifications', ticketId, 'notification'];
            const values = {
                loading: false,
                data: fromJS({}),
                hasData: false,
                err,
            };
            return state.mergeIn(key, values);
        }

        default:
            return state;
    }
}

export default userActionsReducer;

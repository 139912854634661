/**
 * @file Constants for SalaryWhitelistB2B
 */
export const SALARY_WHITELIST_B2B_KEY = 'salary-whitelist-b2b';

export const SET_IDENTIFIER_LIST = `app/${SALARY_WHITELIST_B2B_KEY}/SET_IDENTIFIER_LIST`;
export const SET_ACTIVE_TAB_INDEX = `app/${SALARY_WHITELIST_B2B_KEY}/SET_ACTIVE_TAB_INDEX`;
export const SET_NEW_TAB_ACTIVE = `app/${SALARY_WHITELIST_B2B_KEY}/SET_NEW_TAB_ACTIVE`;

export const MODE_TYPES_INFO = {
    PHONE_NUMBER: {
        id: 'Phone Number',
        value: 'Phone Number',
        label: 'Phone Number',
        order: 1,
    },
    EMAIL_ID: {
        id: 'Email ID',
        value: 'Email ID',
        label: 'Email ID',
        order: 2,
    },
    CSV: {
        id: 'CSV Upload',
        value: 'CSV Upload',
        label: 'CSV Upload',
        order: 3,
    },
};

export const FIELD_TYPES = {
    INPUT: 'Input',
    TEXTAREA: 'Textarea',
};

export const MODE_TYPES_LIST = Object.values(MODE_TYPES_INFO).sort((a, b) => (a.order - b.order));

export const DISPLAY_TEXT_MAP = {
    phoneNumber: 'Enter comma separated phone numbers. Only 10 digit phone numbers are allowed. Max 10 values.',
    email: 'Enter comma separated email ids',
    employerId: 'Enter Employer ID (Works Only with Phone numbers)',
};

/*
 * LogsFilter constants
 */

export const SET_LOG_FILTER = 'app/LogFilter/SET_LOG_FILTER';

export const FILTER_BY_LIST_MAP = {
    ticketId: 'TICKET ID',
    agent: 'AGENT',
    accessLevel: 'ACCESS LEVEL',
    timestamp: 'TIMESTAMP',
};

export const FILTER_BY_LIST = [FILTER_BY_LIST_MAP.ticketId, FILTER_BY_LIST_MAP.agent, FILTER_BY_LIST_MAP.accessLevel, FILTER_BY_LIST_MAP.timestamp];

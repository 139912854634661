/*
 *
 * Wealth Onboarding Constants
 *
 */
// wealth onboarding info
export const GET_ONBOARDING_INFO = 'app/WO/GET_ONBOARDING_INFO';
export const SET_ONBOARDING_INFO = 'app/WO/SET_ONBOARDING_INFO';
export const ERR_ONBOARDING_INFO = 'app/WO/ERR_ONBOARDING_INFO';

// key to be used for reducers & selectors for wealth onboarding
export const WEALTH_ONBOARDING_KEY = 'wealthOnboarding';

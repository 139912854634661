/**
 *  @file Legend
 */

import React from 'react';
import './style.scss';

const Legend = ({ data, color, maxWidth }) => (
    <div className='d3-legend' style={{ maxWidth }}>
        {data.map((item) => (
            <div key={item} className='wrapper'>
                <div className='shape' style={{ backgroundColor: color(item) }} />
                <div className='text' style={{ color: color(item) }}>{item}</div>
            </div>
        ))}
    </div>
);

export default Legend;

/*
 *
 * CustomerDetails selectors
 *
 */

import { createSelector } from 'reselect';

import { CUSTOMER_DETAILS_KEY } from './constants';

const selectCustomerDetailsLoading = () => createSelector(
    (state) => state.getIn([CUSTOMER_DETAILS_KEY, 'loading']),
    (subState) => subState,
);

const selectCustomerDetailsErr = () => createSelector(
    (state) => state.getIn([CUSTOMER_DETAILS_KEY, 'err']),
    (subState) => subState,
);

const selectCustomerDetailsInfo = () => createSelector(
    (state) => state.getIn([CUSTOMER_DETAILS_KEY, 'customerDetails']),
    (subState) => subState,
);

const selectCustomerDetailsMonorailInfo = () => createSelector(
    (state) => state.getIn([CUSTOMER_DETAILS_KEY, 'monorailInfo']),
    (subState) => subState.toJS(),
);

export {
    selectCustomerDetailsLoading,
    selectCustomerDetailsErr,
    selectCustomerDetailsInfo,
    selectCustomerDetailsMonorailInfo,
};

/**
 *
 * UserInfo
 *
 */

import React, { memo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Modal from 'react-modal';
import copy from 'copy-text-to-clipboard';
import classNames from 'classnames';

import { Button, Loader } from '../../../components';
import backArrow from '../../../assets/images/svgs/back-arrow.svg';

import {
    setFreelancerInfo, updateFreelancerWLStatus,
} from '../actions';
import {
    makeSelectLoading, makeSelectUserInfo,
} from '../selectors';
import { getFormattedFreelacerInfo } from '../utils';

import './style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content : {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 500,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    }
};

const UserInfo = (props) => {
    const dispatch = useDispatch();
    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const userInfo = useSelector(makeSelectUserInfo(), shallowEqual);
    const [actionLabel, setActionLabel] = useState('');
    const [reason, setReason] = useState('');
    const [updateStatusPayload, setUpdateStatusPayload] = useState({});
    const [openConfirmActionModal, setConfirmActionModal] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const { ticketId, setTicketId, from, to } = props;

    const handleInputChange = (key) => (e) => {
        const value = e.target.value;

        switch (key) {
            case 'reason':
                setReason(value);
                break;

            default:
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!reason) return;

        setConfirmActionModal(false);

        dispatch(updateFreelancerWLStatus({
            ticketId, // waitlist approver
            from, // waitlist admin approver
            to, // waitlist admin approver
            phoneNumber: userInfo.phoneNumber,
            reason,
            ...updateStatusPayload
        }));
    };

    const onUpdateStatusBtnClick = (data) => {
        const { accepted, rejected, onhold, closeModal } = data;

        if (closeModal) {
            setActionLabel('');
            setUpdateStatusPayload({});
            setConfirmActionModal(false);

            return;
        }

        if (accepted) {
            setActionLabel('accept');
        } else if (rejected) {
            setActionLabel('reject');
        } else if (onhold) {
            setActionLabel('put on-hold');
        }

        setUpdateStatusPayload({
            accepted,
            rejected,
            onhold,
        });

        setConfirmActionModal(true);
    };

    const resetUserState = () => {
        // valid for waitlist approver
        if (setTicketId) {
            setTicketId('');
        }

        dispatch(setFreelancerInfo({ freelancerInfo: {} }));
    };

    const acceptForWLCta = (
        <Button
            primary
            disabled={!reason}
            label='Accept for Waitlist'
            onClick={(e) => {
                e.preventDefault();
                onUpdateStatusBtnClick({ accepted: true });
            }}
        />
    );

    const rejectForWLCta = (
        <Button
            primary
            disabled={!reason}
            label='Reject for Waitlist'
            onClick={(e) => {
                e.preventDefault();
                onUpdateStatusBtnClick({ rejected: true });
            }}
        />
    );

    const putOnholdCta = (
        <Button
            secondary
            disabled={!reason}
            label='Put on-hold'
            extClasses='secondary-btn'
            onClick={(e) => {
                e.preventDefault();
                onUpdateStatusBtnClick({ onhold: true });
            }}
        />
    );

    const copyLinkClass = classNames(
        'wlui-cwr__cpl',
        {
            'link': !isCopied
        }
    );

    const onCopyLinkClick = (item) => () => {
        setIsCopied(true);
        copy(item.value);

        setTimeout(() => {
            setIsCopied(false);
        }, 5000);
    };

    return (
        <React.Fragment>
            <div className='wlui-cr'>
                <div className='wlui-hw'>
                    <img className='wlui-backarrow' src={backArrow} onClick={resetUserState} />
                    <div className='wlui-hw__hd'>Waitlist User Details</div>
                </div>
                {
                    getFormattedFreelacerInfo(userInfo)
                        .filter(item => !!item.value)
                        .map(item => (
                            <div className='wlui-cwr' key={item.label}>
                                <div className='wlui-cwr__cl'>{item.label}</div>
                                <div className='wlui-cwr__sp'>:</div>
                                <div className='wlui-cwr__cv'>{item.value}</div>
                                {
                                    item.copy ? (
                                        <div className={copyLinkClass} onClick={onCopyLinkClick(item)}>
                                            {!isCopied ? item.copy : 'Copied'}
                                        </div>
                                    ) : null
                                }
                            </div>
                        ))
                }
                {
                    !userInfo.isAcceptedOrRejected ? (
                        <React.Fragment>
                            <div className='form-container wlui-rfc'>
                                <label
                                    className='form-wrapper-label wlui-rfc__lb'
                                    htmlFor='wl-reason'
                                >
                                    Enter Reason*
                                </label>
                                <textarea
                                    id='wl-reason'
                                    className='wlui-rfc__ta'
                                    value={reason}
                                    onChange={handleInputChange('reason')}
                                    rows={4}
                                    cols={70}
                                />
                            </div>
                            <div className='wlui-ctas'>
                                {acceptForWLCta}
                                {rejectForWLCta}
                                {!userInfo.isOnhold ? putOnholdCta : null}
                            </div>
                        </React.Fragment>
                    ) : null
                }

            </div>
            <Modal
                isOpen={openConfirmActionModal}
                style={customStylesForModal}
                contentLabel='Confirm Action Modal'
            >
                <div className='wlui-modal__hl'>
                    Are you sure you want to {actionLabel} the profile?
                </div>
                <div className='frcsbWrapper wlui-modal__ctas'>
                    <Button
                        primary
                        label='Confirm'
                        onClick={handleSubmit}
                    />
                    <Button
                        secondary
                        label='Cancel'
                        onClick={() => onUpdateStatusBtnClick({ closeModal: true })}
                    />
                </div>
            </Modal>
            <Loader visible={loading && loading.userInfo} />
        </React.Fragment>
    );

};

export default memo(UserInfo);

/**
 * @file Constants for Transaction view container
 */

import { getInitialDateRange } from '../../utils';

// action & reducer constants
export const TRANSACTION_VIEW_KEY = 'transactionView';

export const GET_TRANSACTION_VIEW = `app/${TRANSACTION_VIEW_KEY}/GET_TRANSACTION_VIEW`;
export const SET_TRANSACTION_VIEW = `app/${TRANSACTION_VIEW_KEY}/SET_TRANSACTION_VIEW`;
export const ERR_TRANSACTION_VIEW = `app/${TRANSACTION_VIEW_KEY}/ERR_TRANSACTION_VIEW`;

export const GET_TRANSACTION_VIEW_FILTER = `app/${TRANSACTION_VIEW_KEY}/GET_TRANSACTION_VIEW_FILTER`;
export const SET_TRANSACTION_VIEW_FILTER = `app/${TRANSACTION_VIEW_KEY}/SET_TRANSACTION_VIEW_FILTER`;
export const ERR_TRANSACTION_VIEW_FILTER = `app/${TRANSACTION_VIEW_KEY}/ERR_TRANSACTION_VIEW_FILTER`;

// UI constants
export const PARAMETER_FILTERS_CONFIG = {
    cardTitle: 'Filters',
    paramsTitle: 'Fields',
    defaultIsExpanded: false,
    collapseOnSubmit: true,
    warningModal: {
        required: false,
        message: 'Are you sure you want to perform this action?',
        description: 'This change is irreversible and affects production data',
    },
};

export const SORTING_OPTIONS = [
    'TRANSACTION_TABLE_FIELD_MASK_ACTOR_FROM',
    'TRANSACTION_TABLE_FIELD_MASK_ACTOR_TO',
    'TRANSACTION_TABLE_FIELD_MASK_AMOUNT',
    'TRANSACTION_TABLE_FIELD_MASK_ORDER_WORKFLOW',
    'TRANSACTION_TABLE_FIELD_MASK_STATUS',
    'TRANSACTION_TABLE_FIELD_MASK_CREDIT_DEBIT',
    'TRANSACTION_TABLE_FIELD_MASK_TIMESTAMP',
    'TRANSACTION_TABLE_FIELD_MASK_PROVENANCE',
];

export const SORT_DD_OG_STATE = {
    value: 'TRANSACTION_TABLE_FIELD_MASK_ACTOR_FROM',
    isVisible: false,
};

// Default filter values for transaction risk
export const PARAMETER_DEFAULT_FILTER_CONFIG = [
    {
        id: 'from_time',
        value: getInitialDateRange('01-01-2010').lowerLimitDate,
        minDate: '01-01-2010',
    },
    {
        id: 'to_time',
        minDate: '01-01-2010',
    },
    {
        id: 'order_status',
        value: [{
            label: 'TRANSACTION_REVIEW_ORDER_STATUS_FILTER_SUCCESS',
            value: 'TRANSACTION_REVIEW_ORDER_STATUS_FILTER_SUCCESS',
        }],
    },
    {
        id: 'accounting_entry',
        value: 'DEBIT',
    },
];

// Default filter values for transaction risk
export const PARAMETER_DEFAULT_VALUE_CONFIG = [
    {
        name: 'from_time',
        value: getInitialDateRange('01-01-2010').lowerLimitDate,
        type: 4,
    },
    {
        name: 'order_status',
        value: [
            {
                label: 'TRANSACTION_REVIEW_ORDER_STATUS_FILTER_SUCCESS',
                value: 'TRANSACTION_REVIEW_ORDER_STATUS_FILTER_SUCCESS',
            },
        ],
        type: 9,
    },
    {
        name: 'accounting_entry',
        value: 'DEBIT',
        type: 5,
    },
];

/**
 * @file Loan Liveness Reviewer: Contains the entry point of Federal liveness flow.
 * Renders the vertical nav bar and different routes for this flow
 */

import React, { memo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { VerticalNavbar } from '../../components';

import { FEDERAL_LENDING_PATH } from '../App/routes';
import SingleLivenessCase from '../LivenessReviewV2/SingleCase';

import { RenderView } from '../RiskOps';

import {
    LOAN_LIVENESS_REVIEW_KEY as CONTAINER_KEY, QUEUE_PAYLOAD_TYPES, PAL_LIVENESS_SIDEBAR_CONSTANTS,
} from './constants';

const LoanLivenessReviewer = () => (
    <div className='app-cr'>
        <VerticalNavbar
            navItems={PAL_LIVENESS_SIDEBAR_CONSTANTS}
        />

        <div className='app-cc'>
            <div className='app-ci'>
                <Switch>
                    <Route
                        path={`${FEDERAL_LENDING_PATH}/loan-liveness-reviewer`}
                    >
                        <RenderView
                            singleCaseComponent={<SingleLivenessCase containerKey={CONTAINER_KEY} />}
                            data={{
                                containerKey: CONTAINER_KEY,
                                queuePayloadTypes: QUEUE_PAYLOAD_TYPES,
                                queueName: 'Loan Liveness',
                            }}
                        />
                    </Route>
                    {/* in case of unmatched url, redirect the user to the loan liveness reviewer path */}
                    <Redirect to={{ pathname: `${FEDERAL_LENDING_PATH}/loan-liveness-reviewer` }} />
                </Switch>
            </div>
        </div>
    </div>
);
export default memo(LoanLivenessReviewer);

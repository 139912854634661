const renderKycInfo = (kycInfo) => [
    {
        label: 'KYC Type',
        data: kycInfo.type,
    },
    // The below feature is currently not needed in addition of Periodic KYC Updation feature
    // {
    //     label: 'KYC Status',
    //     data: kycInfo.status,
    //     cta: true,
    // },
];

const renderKycStatus = (kycInfo) => [
    {
        label: 'KYC Level',
        data: kycInfo.level,
    },
    {
        label: 're-KYC Status',
        data: kycInfo.reKycStatus,
        cta: true,
    },
];

const renderVKycSummaryInfo = (data) => [
    {
        label: 'VKYC Status',
        data: data.status,
        cta: true,
    },
];

const renderVKycCallType = (data) => [
    {
        label: 'Video KYC Call Type',
        data: data.callInfo && data.callInfo.type,
        cta: true,
    },
];

const renderDetailedVKycInfo = (data) => [
    {
        label: 'Status',
        value: data.status,
    },
    {
        label: 'Sub Status',
        value: data.subStatus,
    },
    {
        label: 'Created At',
        value: data.createdTs,
    },
    {
        label: 'Updated At',
        value: data.updatedTs,
    },
];

const renderOnboardingInfo = (onboardingInfo) => [
    {
        label: "User's Savings Account Onboarding Status",
        data: onboardingInfo.onboardingStatus,
    },
];

const renderInAppScreeningInfo = (onboardingInfo) => [
    {
        label: 'In-app Screening',
        data: onboardingInfo.inAppScreenerStatus,
    },
];

const renderUserFiOnboardingInfo = (onboardingInfo) => [
    {
        label: "User's Fi Onboarding Status",
        data: onboardingInfo.fiOnboardingStatus,
    },
];

const renderCurrentStageOnboardingTroubleshootInfo = (onboardingInfo) => [
    {
        label: 'Current Stage',
        value: onboardingInfo.stage,
    },
    {
        label: 'Status',
        value: onboardingInfo.state,
    },
    /*
    {
        label: 'Updated At',
        value: onboardingInfo.updatedTs,
    },
    */
    {
        label: 'RCA L1',
        value: onboardingInfo.level1,
    },
    {
        label: 'RCA L2',
        value: onboardingInfo.level2,
    },
    {
        label: 'RCA L3',
        value: onboardingInfo.level3,
    },
    {
        label: 'Troubleshooting Advice',
        value: onboardingInfo.advice,
    },
    {
        label: 'Additional Details',
        value: onboardingInfo.debugInfo,
        format: 'json',
    },
];

const renderCurrentStageReOnboardingTroubleshootInfo = (data) => [
    {
        label: 'Current Stage',
        value: data.stage,
    },
    {
        label: 'Status',
        value: data.state,
    },
    {
        label: 'Diagnosis Code',
        value: data.diagnosisCode,
    },
    {
        label: 'Diagnosis Report',
        value: data.diagnosisReport,
    },
    {
        label: 'Troubleshooting Advice',
        value: data.debugInfo,
    },
];

const labelValuesConcatenation = (labelValues) => labelValues.map((labelValue) => ({
    label: labelValue.label,
    value: (labelValue.value && Array.isArray(labelValue.value)) ? labelValue.value.join(', ') : '',
}));

export {
    renderKycInfo,
    renderKycStatus,
    renderVKycSummaryInfo,
    renderVKycCallType,
    renderDetailedVKycInfo,
    renderOnboardingInfo,
    renderInAppScreeningInfo,
    renderUserFiOnboardingInfo,
    renderCurrentStageOnboardingTroubleshootInfo,
    renderCurrentStageReOnboardingTroubleshootInfo,
    labelValuesConcatenation,
};

/**
 *
 * UserConfirmation
 *
 */

import React, { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';

import { Button, FormWithSingleInput, Loader } from '../../../components';

import { DEFAULT_TICKET_PATH } from '../../App/routes';
import TicketAndUserInfo from '../TicketAndUserInfo';
import OTPAttempt from '../OTPAttempt';

import {
    setRegisteredIdFlow, setTicketId, setUserId, setUserNotRegistered, submitUserId,
    getInitialAuthFactor, getWaitlistUserDetails, getOTPAttempts,
} from '../actions';

import './style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 16,
        width: 500,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const UserConfirmation = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isUserNotRegisteredModalOpen, toggleUserNotRegisteredModal] = useState(false);
    const [registeredUserFlow, setRegisteredUserFlow] = useState('');

    const { ticket, index, ticketAndUserInfo } = props;
    const {
        ticketId, loading, basicInfo: {
            source, ticketPhoneNumber, isUserExistInEpifi, isUserExistInSystem,
        }, errLabel, isRegisteredPhoneNumber, isRegisteredEmailId, isUserNotRegistered, userId, userInfo,
        hasUserInfo, waitlistUserInfo, hasWaitlistUserInfo, hasOTPAttemptInfo,
        otpAttemptList, isCallFlow,
    } = ticket;
    const loadingState = loading.waitlistUser || loading.otpAttempts;

    const resetTicketFlow = () => {
        dispatch(setTicketId({ ticketId: '', index }));
        history.push(DEFAULT_TICKET_PATH);
    };

    const fetchOTPAttemptList = (phoneNumber) => {
        const callOTPAttemptsAction = () => new Promise((resolve, reject) => {
            dispatch(getOTPAttempts({
                ticketId,
                index,
                phoneNumber,
                resolve,
                reject,
            }));
        });

        callOTPAttemptsAction()
            .then(() => {
                setRegisteredUserFlow('otpAttempt');
            })
            .catch(() => {
                // default ticket phone number flow by clicking on 'Yes, Registered Number'
                if (!phoneNumber) {
                    toggleModal();
                }
            });
    };

    /*
    const resetWaitlistUserFlow = () => {
        dispatch(setRegisteredIdFlow({ index, type: '' }));
        setRegisteredUserFlow('');
    };
    */

    // eslint-disable-next-line no-shadow
    const onUserIdChange = (index) => (event) => {
        dispatch(setUserId({ userId: event.target.value, index }));
    };

    // eslint-disable-next-line no-shadow
    const onUserIdSubmit = (index) => () => {
        if (registeredUserFlow === 'waitlist') {
            dispatch(getWaitlistUserDetails({
                ticketId, index, userId, showWaitlistUserInfo: false,
            }));
        } else {
            const callUserDetailsAction = () => new Promise((resolve, reject) => {
                dispatch(submitUserId({
                    ticketId, userId, index, resolve, reject,
                }));
            });

            callUserDetailsAction()
                .catch(() => {
                    /**
                     * call this only iff
                     * 1. when no user found for submitted user id
                     * 2. ticket phone number user is not there in system
                     * 3. registered phone number flow
                     */
                    if (!isUserExistInSystem && isRegisteredPhoneNumber) {
                        fetchOTPAttemptList(userId);
                    }
                });
        }
    };

    // eslint-disable-next-line no-shadow
    const onUserConfirmation = (userId) => {
        dispatch(getInitialAuthFactor({
            ticketId, userId, index, isInsensitiveAuth: true,
        }));
    };

    const setRegisteredUser = (type, flow = '') => {
        dispatch(setRegisteredIdFlow({ index, type }));
        setRegisteredUserFlow(flow);
    };

    const toggleModal = () => {
        toggleUserNotRegisteredModal(!isUserNotRegisteredModalOpen);
    };

    const onPrimaryCTAClick = () => {
        if (!isUserExistInSystem) {
            fetchOTPAttemptList();
        } else if (!isUserExistInEpifi) {
            toggleModal();
        } else {
            onUserConfirmation();
        }
    };

    if (isUserNotRegistered) {
        return (
            <React.Fragment>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <TicketAndUserInfo {...ticketAndUserInfo} />
                <div className='user-not-registered-wrapper'>
                    <span className='label'>
                        User cannot be identified using mobile number or email id.
                        Please ask the user to call from their registered number.
                    </span>
                    <Button primary label='Close & Go back' onClick={resetTicketFlow} />
                </div>
            </React.Fragment>
        );
    }

    if (hasOTPAttemptInfo && registeredUserFlow === 'otpAttempt') {
        return (
            <React.Fragment>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <TicketAndUserInfo {...ticketAndUserInfo} />
                <OTPAttempt
                    data={otpAttemptList}
                    toggleHandler={setRegisteredUserFlow}
                />
            </React.Fragment>
        );
    }

    /* waitlist flow is not getting used
    if (hasWaitlistUserInfo && showWaitlistUserInfo) {
        return (
            <React.Fragment>
                <TicketAndUserInfo {...ticketAndUserInfo} />
                <div className='wlwr'>
                    <div className='wlwr-hw'>
                        <img className='wlwr-backarrow' src={backArrow} onClick={resetWaitlistUserFlow} />
                        <div className='wlwr-hw__hd'>Waitlist User Details</div>
                    </div>
                    {
                        getWaitlistInfo(waitlistUserInfo).map(item => (
                            <div className='wlwr-cwr' key={item.label}>
                                <div className='wlwr-cwr__cl'>{item.label}</div>
                                <div className='wlwr-cwr__sp'>:</div>
                                <div className='wlwr-cwr__cv'>{item.value}</div>
                            </div>
                        ))
                    }
                </div>
            </React.Fragment>
        );
    }
    */

    if (isRegisteredPhoneNumber || isRegisteredEmailId) {
        let label = 'Ask user their registered ';
        let labelId = 'registered-';
        let pattern;
        let secondaryLabel;
        let onWrongUserClick;
        let onUserConfirmationClick = () => { onUserConfirmation(userId); };
        let hasRegisteredUserInfo = hasUserInfo;
        let registeredUserInfo = userInfo;

        const primaryLabel = errLabel ? 'Retry' : 'Find User';

        if (isRegisteredPhoneNumber) {
            label += 'phone number';
            labelId += 'phone-number';

            if (registeredUserFlow !== 'waitlist') {
                secondaryLabel = errLabel && 'Find using email id';

                onWrongUserClick = () => { setRegisteredUser('emailId', registeredUserFlow); };
            } else {
                onWrongUserClick = () => { dispatch(setUserNotRegistered({ index })); };
            }
        } else {
            label += 'email id';
            labelId += 'email-id';

            onWrongUserClick = () => { dispatch(setUserNotRegistered({ index })); };
        }

        if (registeredUserFlow === 'waitlist') {
            hasRegisteredUserInfo = hasWaitlistUserInfo;
            registeredUserInfo = waitlistUserInfo;

            onUserConfirmationClick = () => {
                dispatch(getWaitlistUserDetails({
                    ticketId, index, userId, showWaitlistUserInfo: true,
                }));
            };
        }

        return (
            <React.Fragment>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <TicketAndUserInfo {...ticketAndUserInfo} />
                <FormWithSingleInput
                    label={label}
                    labelId={labelId}
                    input={{
                        value: userId,
                        pattern,
                        onChange: onUserIdChange(index),
                        disabled: hasRegisteredUserInfo,
                    }}
                    cta={{
                        primaryLabel,
                        primaryDisabled: hasRegisteredUserInfo,
                        onPrimaryClick: onUserIdSubmit(index),
                        secondaryLabel,
                        onSecondaryClick: () => { setRegisteredUser('emailId', registeredUserFlow); },
                    }}
                    errLabel={errLabel}
                />
                {
                    hasRegisteredUserInfo ? (
                        <div className='user-confirm-wrapper'>
                            <span className='label'>Confirm User Name:</span>
                            <span className='name'>{registeredUserInfo.name}</span>
                            <Button primary label='Confirm' onClick={onUserConfirmationClick} extClasses='primary-cta' />
                            <Button secondary label='Still wrong user' onClick={onWrongUserClick} />
                        </div>
                    ) : null
                }
                <Loader visible={loadingState} />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className='user-wrapper'>
                {
                    isCallFlow ? (
                        <div className='user-auth-label'>User Authentication</div>
                    ) : null
                }
                <div className='frcsbWrapper user-info-wrapper'>
                    <div>
                        <span className='label'>Ticket ID:</span>
                        <span className='name'>{`${ticketId} || ${source} from ${ticketPhoneNumber}`}</span>
                    </div>
                    <div className='link' role='presentation' onClick={resetTicketFlow}>Wrong ticket id entered?</div>
                </div>
                <div>
                    <span className='user-info-label'>
                        {`Is your account registered with the same number you are calling from? Number ending in ${ticketPhoneNumber}?`}
                    </span>
                    <div className='frcsbWrapper user-ctas-container'>
                        <div className='fcWrapper user-ctas-wrapper'>
                            <div className='label'>User is calling from their registered number</div>
                            <Button primary label='Yes, Registered Number' onClick={onPrimaryCTAClick} />
                        </div>
                        <div className='fcWrapper user-ctas-wrapper'>
                            <div className='label'>User is calling from a different number</div>
                            <Button secondary label='Another Account' onClick={() => { setRegisteredUser('phoneNumber'); }} />
                        </div>
                    </div>
                </div>
                {/* <div className='frcsbWrapper user-ctas-container user-ctas-container--wl'>
                    <div className='fcWrapper user-ctas-wrapper'>
                        <Button
                            primary
                            disabled={!isUserExistInWL}
                            label='Check Waitlist Details'
                            onClick={() => { dispatch(getWaitlistUserDetails({ ticketId, index, userId, showWaitlistUserInfo: true })); }}
                        />
                    </div>
                    {
                        !isUserExistInWL ? (
                            <div
                                className='link'
                                onClick={() => { setRegisteredUser('phoneNumber', 'waitlist'); }}
                            >
                                User registered in waitlist using different number
                            </div>
                        ) : null
                    }
                </div> */}
            </div>
            <Modal
                isOpen={isUserNotRegisteredModalOpen}
                style={customStylesForModal}
                contentLabel='User Not Registered Modal'
            >
                <div>{`No user registered for mobile number ${ticketPhoneNumber}.`}</div>
                <div className='modal-action-label'>
                    Ask the user for the mobile number using which they had created their Fi account
                </div>
                <Button primary label='Close' onClick={toggleModal} />
            </Modal>
            <Loader visible={loadingState} />
        </React.Fragment>
    );
};

export default memo(UserConfirmation);

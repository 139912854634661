/**
 * @file Manages the action functions for Instant Indexing component
 */

import {
    INITIATE_UPDATE_URL, GET_URL_STATUS,
} from './constants';

export const updateUrl = (data) => ({
    type: INITIATE_UPDATE_URL,
    data,
});

export const getUrlStatus = (data) => ({
    type: GET_URL_STATUS,
    data,
});

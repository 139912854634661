/**
 * @file SingleCase
 * View case details like liveness for a particular request
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import SingleCaseHeading from '../../RiskOps/SingleCaseHeading';
import LivenessCaseInfo from '../../LivenessReviewV2/CaseInfo';

import { setCxRiskReviewCurViewAction } from '../actions';
import { CX_RISK_REVIEW_KEY, VIEW_ALL_REQUESTS_FOR_TICKET, VIEW_ALL_TICKETS } from '../constants';

const SingleCase = () => {
    const dispatch = useDispatch();

    const handBackArrow = () => {
        dispatch(setCxRiskReviewCurViewAction(VIEW_ALL_REQUESTS_FOR_TICKET));
    };

    const extraActions = [setCxRiskReviewCurViewAction(VIEW_ALL_TICKETS)];

    return (
        <div className='cxrisk-cr1'>
            <SingleCaseHeading
                title='Liveness Review'
                onBackArrowCTA={handBackArrow}
            />
            <LivenessCaseInfo
                containerKey={CX_RISK_REVIEW_KEY}
                dequeueOnSubmit={false}
                extraActions={extraActions}
            />
        </div>
    );
};

export default memo(SingleCase);

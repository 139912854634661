/**
 * @file Maintenance Mode component to block the user to do any action while performing any maintenance operation
 */

import React, { memo } from 'react';

import './style.scss';

const MaintenanceMode = () => (
    <div className='maintenance-mode-cr'>
        <div className='maintenance-mode-lb1'>
            The screen you are trying to access is under maintenance
        </div>
        <div className='maintenance-mode-lb2'>
            Please try again after sometime
        </div>
    </div>
);

export default memo(MaintenanceMode);

/**
 *
 * Federal Agents
 *
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import { Loader } from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import TicketList from './TicketList';
import TicketInfo from './TicketInfo';
import { getTicketList, getAllTicketGroups } from './actions';
import { makeSelectLoading, makeSelectTicketInfo } from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const FederalAgents = () => {
    const dispatch = useDispatch();

    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const ticketInfo = useSelector(makeSelectTicketInfo(), shallowEqual);

    useEffect(() => {
        dispatch(getTicketList({}));
        dispatch(getAllTicketGroups());
    }, []);

    const hasTicketInfo = Object.keys(ticketInfo).length;

    if (hasTicketInfo) {
        return (
            <React.Fragment>
                <TicketInfo />
                <Loader visible={loading} />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <TicketList />
            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'federal', reducer });
const withSaga = injectSaga({ key: 'federal', saga });

export default compose(
    withReducer,
    withSaga,
)(FederalAgents);

/**
 * @file ViewEmploymentDetails
 * Shows the employment details for a particular user enrolled in salary account program
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { Button, KVPairInfo } from '../../../components';

import { setSalaryAccountOpsCurViewAction } from '../actions';
import { SALARY_ACCOUNT_DETAILS_KEY, UPDATE_EMPLOYER_NAME } from '../constants';

const getKVData = (employmentInfo) => ([
    {
        label: 'Legal Name',
        value: employmentInfo.name,
    },
    {
        label: 'Phone Number',
        value: employmentInfo.phoneNumber,
    },
    {
        label: 'Email ID',
        value: employmentInfo.emailId,
    },
    {
        label: 'Employment Type',
        value: employmentInfo.employmentType,
    },
    {
        label: 'Employer Name',
        value: employmentInfo.employerName,
    },
    {
        label: 'Employer ID',
        value: employmentInfo.employerId,
    },
    {
        label: 'Employment Confirmation Complete',
        value: employmentInfo.employmentConfirmationComplete,
    },
    {
        label: 'Employment Data Last Updated At',
        value: employmentInfo.employmentDataUpdatedAt,
    },
]);

const ViewEmploymentDetails = (props) => {
    const dispatch = useDispatch();

    const { employmentInfo, allowEmployerNameUpdate } = props;

    if (!employmentInfo) return null;

    const handleUpdateEmployerName = () => {
        dispatch(setSalaryAccountOpsCurViewAction({ screenKey: SALARY_ACCOUNT_DETAILS_KEY, value: UPDATE_EMPLOYER_NAME }));
    };

    return (
        <React.Fragment>
            <KVPairInfo
                title='User Employment Details'
                data={getKVData(employmentInfo)}
                extClasses={{
                    container: 'salops-kv__cr',
                    titleHeading: 'salops-kv__hd',
                    contentLabel: 'salops-kv__cl',
                    contentValue: 'salops-kv__cv',
                }}
            />
            {allowEmployerNameUpdate && (
                <Button
                    v2
                    label='Update Employer Name'
                    primary
                    onClick={handleUpdateEmployerName}
                />
            )}
        </React.Fragment>
    );
};

export default memo(ViewEmploymentDetails);

/**
 *
 * Profile
 *
 */

import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { getKeyFromValueAndSubValue } from '../../utils/formatter';
import { copyTextWithStyle } from '../../utils/uiHelpers';
import { Loader, Button } from '../../components';
import { isNilOrEmpty } from '../../utils';

import { makeSelectAgentInfo } from '../AppV2/selectors';
import { RudderEvent, pushRudderEvent } from '../../rudderEvents';
import { MetaInfoContext } from '../../context';

import { makeSelectOnboardingDetails } from '../KycOnboarding/selectors';
import { getOnboardingInfo } from '../KycOnboarding/actions';
import { CUSTOMER_AUTH_TYPES } from '../UserDetailsWithPhoneOrEmail/constants';

import UserBanners from './UserBanners';

import { renderProfileInfo, renderDeviceInfo } from './utils';
import { getProfileInfo } from './actions';
import { makeSelectProfileDetails } from './selectors';

import './style.scss';

const ProfileContentId = 'profile-content';

const Profile = (props) => {
    const dispatch = useDispatch();
    const {
        ticketId, userId, index, notTicket, tabData,
    } = props;

    const profileDetails = useSelector(makeSelectProfileDetails, shallowEqual);
    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);
    const onboardingDetails = useSelector(makeSelectOnboardingDetails(), shallowEqual);
    const metaInfo = useContext(MetaInfoContext);
    const { identifierList, activeTabIndex } = tabData || {};

    let currentTabData = identifierList && identifierList[activeTabIndex];

    if (!currentTabData && !notTicket) {
        currentTabData = {
            type: CUSTOMER_AUTH_TYPES.ticket,
            value: ticketId,
        };
    }

    const {
        componentVersions: {
            userSpecificBanners,
        },
    } = metaInfo;

    let loadingProfile;
    let profileInfo;
    let hasProfileInfo;
    let loadingOnboarding;
    let onboardingInfo;
    let hasOnboardingInfo;
    let err;

    const currentKey = getKeyFromValueAndSubValue(currentTabData?.value, currentTabData?.subValue);

    if (profileDetails && profileDetails[currentKey]) {
        ({
            loading: loadingProfile = true, profileInfo, hasProfileInfo = false, err,
        } = profileDetails[currentKey]);
    }
    if (onboardingDetails && onboardingDetails[currentKey]) {
        ({
            loading: loadingOnboarding, onboardingInfo, hasOnboardingInfo = false,
        } = onboardingDetails[currentKey]);
    }

    useEffect(() => {
        if (!hasProfileInfo || !hasOnboardingInfo) {
            const data = {
                userId, index, authType: currentTabData?.type, authValue: currentTabData?.value, authSubValue: currentTabData?.subValue,
            };

            if (!hasProfileInfo) {
                dispatch(getProfileInfo(data));
            }

            if (!hasOnboardingInfo) {
                dispatch(getOnboardingInfo(data));
            }
        }
    }, []);

    const deviceInfo = useMemo(() => {
        if (hasProfileInfo) {
            return renderDeviceInfo(profileInfo.deviceInfo);
        }
        return null;
    }, [hasProfileInfo, profileInfo?.deviceInfo]);
    const copyButtonClickHandler = () => {
        const properties = {
            ticketId,
        };
        pushRudderEvent(
            RudderEvent.Profile.SherlockProfileCopyButtonClick,
            agentInfo.emailId,
            properties,
        );
        copyTextWithStyle(ProfileContentId);
    };

    return (
        <React.Fragment>
            <div className='profile'>
                {userSpecificBanners && (
                    <UserBanners
                        authData={{ authType: currentTabData?.type, authValue: currentTabData?.value, authSubValue: currentTabData?.subValue }}
                    />
                )}
                <div className='frcfeWrapper'>
                    <Button
                        v2
                        secondary
                        onClick={copyButtonClickHandler}
                        label='Copy Data'
                    />
                </div>
                <div id={ProfileContentId}>
                    {
                        hasProfileInfo ? (
                            <React.Fragment>
                                {
                                    renderProfileInfo(profileInfo).map((item) => {
                                    // if item data is empty and hideIfNoValue flag is true, do not render the item
                                        if (isNilOrEmpty(item.data) && item.hideIfNoValue) return null;

                                        return (
                                            <div key={item.label} className='profile-wrapper'>
                                                <div className='profile-wrapper__label'>{item.label}</div>
                                                {/* eslint-disable max-len */}
                                                <div className={`profile-wrapper__value ${item.hideTextTransform ? 'profile-wrapper__value--v1' : ''}`}>
                                                    {item.data || '-'}
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </React.Fragment>
                        ) : null
                    }
                    {
                        hasOnboardingInfo ? (
                            <div className='profile-sec'>
                                <h4 className='profile-sec__label'>User&apos;s Onboarding Details</h4>
                                <div key={onboardingInfo.currentProduct} className='kob-wrapper'>
                                    <div className='kob-wrapper__label'>Products selected by user during onboarding</div>
                                    <div className='kob-wrapper__value'>{onboardingInfo.currentProduct || '-'}</div>
                                </div>
                                {/* The following implementation requires changes in backend which would be worked on later. Once those are complete then the following code can be uncommented */}
                                {/* <div key={onboardingInfo.onboardedProduct} className='kob-wrapper'>
                                    <div className='kob-wrapper__label'>Products for which onboarding is complete</div>
                                    <div className='kob-wrapper__value'>{onboardingInfo.onboardedProduct || '-'}</div>
                                </div> */}
                            </div>
                        )
                            : null
                    }
                    {
                        hasProfileInfo ? (
                            <div className='profile-sec'>
                                <h4 className='profile-sec__label'>User&apos;s Device and Mobile Operator Details</h4>
                                {
                                    Array.isArray(deviceInfo) && deviceInfo.map((item) => (
                                        <div key={item.label} className='profile-wrapper'>
                                            <div className='profile-wrapper__label'>{item.label}</div>
                                            <div className={`profile-wrapper__value ${item.hideTextTransform ? 'profile-wrapper__value--v1' : ''}`}>
                                                {item.data || '-'}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ) : null
                    }
                    {
                        !hasProfileInfo && err ? (
                            <div className='profile-err'>{`* ${err}`}</div>
                        ) : null
                    }
                </div>
            </div>
            <Loader visible={loadingOnboarding || loadingProfile} />
        </React.Fragment>
    );
};

export default Profile;

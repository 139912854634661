/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */

/**
 * @file Manages the reducer state & function for transaction view container
 */

import { fromJS } from 'immutable';

import {
    GET_TRANSACTION_VIEW, SET_TRANSACTION_VIEW, ERR_TRANSACTION_VIEW,
    GET_TRANSACTION_VIEW_FILTER, SET_TRANSACTION_VIEW_FILTER, ERR_TRANSACTION_VIEW_FILTER,
    PARAMETER_DEFAULT_FILTER_CONFIG,
} from './constants';

const initialState = fromJS({
    fileEntries: {
        columnData: [],
        rowData: [],
        prevToken: '',
        nextToken: '',
    },
    loading: false,
    parameterList: [],
    err: '',
});

// Prefilling default filter config
const getParameterListPrefill = (parameterList = []) => {
    if (parameterList && parameterList.length > 0) {
        parameterList.forEach((item) => {
            const getDefaultValue = PARAMETER_DEFAULT_FILTER_CONFIG.find((list) => list.id === item.name);
            // config defaultvalue
            if (getDefaultValue?.value) item.value = getDefaultValue.value;
            if (getDefaultValue?.minDate) item.minDate = getDefaultValue.minDate;
        });
    }
    return parameterList;
};

function transactionViewReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TRANSACTION_VIEW:
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '');

        case SET_TRANSACTION_VIEW: {
            const { fileEntries } = action.data;

            return state
                .setIn(['fileEntries'], fromJS(fileEntries))
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }

        case ERR_TRANSACTION_VIEW: {
            const { err } = action.data;

            return state
                .setIn(['fileEntries'], fromJS({}))
                .setIn(['loading'], false)
                .setIn(['err'], err);
        }

        case GET_TRANSACTION_VIEW_FILTER:
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '');

        case SET_TRANSACTION_VIEW_FILTER: {
            const { parameterList } = action.data;

            return state
                .setIn(['parameterList'], fromJS(getParameterListPrefill(parameterList)))
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }

        case ERR_TRANSACTION_VIEW_FILTER: {
            return state
                .setIn(['parameterList'], fromJS([]))
                .setIn(['loading'], false);
        }

        default:
            return state;
    }
}

export default transactionViewReducer;

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { DB_STATES_ROOT } from '../../api/routes';

import {
    GET_SERVICE_LIST, SET_SERVICE_LIST, ERR_SERVICE_LIST,
    GET_ENTITY_LIST, SET_ENTITY_LIST, ERR_ENTITY_LIST,
    GET_OPTION_LIST, SET_OPTION_LIST, ERR_OPTION_LIST,
    GET_DB_INFO, SET_DB_INFO, ERR_DB_INFO,
} from './constants';

function* getServiceList() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${DB_STATES_ROOT}/services`,
        );

        yield put({ type: SET_SERVICE_LIST, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_SERVICE_LIST, data: { err: e.message } });
    }
}

function* getEntityList(action) {
    const { service, monorailId } = action.data;
    const { value: serviceValue } = service;
    const queryString = getQueryStringFromObject({ monorailId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${DB_STATES_ROOT}/services/${serviceValue}/entities?${queryString}`,
        );

        yield put({ type: SET_ENTITY_LIST, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ENTITY_LIST, data: { err: e.message } });
    }
}

function* getOptionList(action) {
    const { service, entity, monorailId } = action.data;
    const { value: serviceValue } = service;
    const { value: entityValue } = entity;
    const queryString = getQueryStringFromObject({ monorailId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${DB_STATES_ROOT}/services/${serviceValue}/entities/${entityValue}/options?${queryString}`,
        );

        yield put({ type: SET_OPTION_LIST, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_OPTION_LIST, data: { err: e.message } });
    }
}

function* getDBInfo(action) {
    const {
        service, entity, options, monorailId,
    } = action.data;
    const { value: serviceValue } = service;
    const { value: entityValue } = entity;

    const queryString = getQueryStringFromObject({
        service: serviceValue,
        entity: entityValue,
        options,
        monorailId,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${DB_STATES_ROOT}/info?${queryString}`,
        );

        yield put({ type: SET_DB_INFO, data: response });
    } catch (e) {
        // const err = getErrLabel(e);
        // show error message as toast
        toastify(e.message, 'error');
        // show error description on UI
        yield put({ type: ERR_DB_INFO, data: { err: e.description } });
    }
}

export default function* ticketSummarySaga() {
    yield all(
        [
            yield takeLatest(GET_SERVICE_LIST, getServiceList),
            yield takeLatest(GET_ENTITY_LIST, getEntityList),
            yield takeLatest(GET_OPTION_LIST, getOptionList),
            yield takeLatest(GET_DB_INFO, getDBInfo),
        ],
    );
}

/**
 * @file Common risk ops constants
 */

import { getInitialDateRange } from '../../utils/date';

export const RISK_OPS_KEY = 'riskOps';

// filter dropdown options
export const OLDEST_CASES_FIRST = 'OLDEST_CASES_FIRST';
export const LATEST_CASES_FIRST = 'LATEST_CASES_FIRST';

// common actions
export const GET_RISK_OPS_L2_ANNOTATION_DETAILS = `app/${RISK_OPS_KEY}/GET_L2_ANNOTATION_DETAILS`;
export const AUDIT_LOG_MEDIA_DOWNLOAD = `app/${RISK_OPS_KEY}/AUDIT_LOG_MEDIA_DOWNLOAD`;
export const GET_RISK_OPS_CASES = `app/${RISK_OPS_KEY}/GET_CASES`;
export const SET_RISK_OPS_CASES = `app/${RISK_OPS_KEY}/SET_CASES`;
export const DEQUEUE_RISK_OPS_CASES = `app/${RISK_OPS_KEY}/DEQUEUE_CASE`;
export const SET_RISK_OPS_CUR_VIEW = `app/${RISK_OPS_KEY}/SET_CUR_VIEW`;
export const SET_RISK_OPS_PAYLOAD_TYPE = `app/${RISK_OPS_KEY}/SET_PAYLOAD_TYPE`;
export const SET_RISK_OPS_CUR_CASE = `app/${RISK_OPS_KEY}/SET_CUR_CASE`;
export const GET_RISK_OPS_PENDING_REVIEW_COUNT = `app/${RISK_OPS_KEY}/GET_PENDING_REVIEW_COUNT`;
export const SET_RISK_OPS_PENDING_REVIEW_COUNT = `app/${RISK_OPS_KEY}/SET_PENDING_REVIEW_COUNT`;
export const SET_RISK_OPS_QUEUE_FILTERS = `app/${RISK_OPS_KEY}/SET_QUEUE_FILTERS`;
export const SET_RISK_OPS_ERROR = `app/${RISK_OPS_KEY}/ERROR`;
export const SET_RISK_OPS_L2_ANNOTATION_DETAILS = `app/${RISK_OPS_KEY}/SET_L2_ANNOTATION_DETAILS`;
export const GET_RISK_OPS_REVIEW_VIDEO = `app/${RISK_OPS_KEY}/GET_REVIEW_VIDEO`;
export const SET_RISK_OPS_REVIEW_VIDEO = `app/${RISK_OPS_KEY}/SET_REVIEW_VIDEO`;
export const GET_RISK_OPS_BASE64_IMAGE = `app/${RISK_OPS_KEY}/GET_BASE64_IMAGE`;
export const SET_RISK_OPS_BASE64_IMAGE = `app/${RISK_OPS_KEY}/SET_BASE64_IMAGE`;
export const GET_RISK_OPS_REVIEW_BY_ACTOR_ID = `app/${RISK_OPS_KEY}/GET_REVIEW_BY_ACTOR_ID`;
export const SUBMIT_RISK_OPS_QA_REVIEW = `app/${RISK_OPS_KEY}/SUBMIT_QA_REVIEW`;

// common state
export const RISK_OPS_VIEW_ALL_CASES = `app/${RISK_OPS_KEY}/State/VIEW_ALL_CASES`;
export const RISK_OPS_VIEW_SINGLE_CASE = `app/${RISK_OPS_KEY}/State/VIEW_SINGLE_CASE`;

export const INITIAL_DATE_RANGE = getInitialDateRange();

export const AWS_S3_PROXY_PREFIX = '/AWS_S3_Proxy';

export const AWS_S3_UPLOAD_PROXY = '/AWS_S3_UPLOAD_Proxy';

const FROM_DATE_INFO = {
    label: 'From Date',
    labelId: 'risk-ops-from-date',
};

const TO_DATE_INFO = {
    label: 'To Date',
    labelId: 'risk-ops-to-date',
};

const DATE_PICKER_CLS = {
    container: ['rops-dp'],
    inputField: ['rops-dp__ip'],
};

export const DATE_INFO = {
    fromDateInfo: FROM_DATE_INFO,
    toDateInfo: TO_DATE_INFO,
    initialDateRange: INITIAL_DATE_RANGE,
    extClasses: DATE_PICKER_CLS,
};

export const SORTING_OPTIONS = [OLDEST_CASES_FIRST, LATEST_CASES_FIRST];

export const SORT_DD_OG_STATE = {
    value: LATEST_CASES_FIRST,
    isVisible: false,
};

// class constants
export const DD_ITEM_CLS = 'custom-dd__cl rops-idd__cl';

export const RISK_OPS_COMMON_STATE = {
    casesList: [],
    curCase: {},
    curView: RISK_OPS_VIEW_ALL_CASES,
    loading: false,
    err: '',
    pendingReviewCount: '',
    l2AnnotationDetails: null,
    queueFilters: {
        fromDate: INITIAL_DATE_RANGE.fromDate,
        toDate: INITIAL_DATE_RANGE.toDate,
        sortOption: SORT_DD_OG_STATE,
    },
};

export const L2_LIVENESS_VERDICT_OPTIONS = [
    {
        label: 'VERDICT_PASS',
        value: 'VERDICT_PASS',
    },
    {
        label: 'VERDICT_FAIL',
        value: 'VERDICT_FAIL',
    },
];

export const L2_FACEMATCH_VERDICT_OPTIONS = [
    {
        label: 'FACE_MATCH_VERDICT_PASS',
        value: 'FACE_MATCH_VERDICT_PASS',
    },
    {
        label: 'FACE_MATCH_VERDICT_FAIL',
        value: 'FACE_MATCH_VERDICT_FAIL',
    },
];

export const L2_ERROR_TYPE_OPTIONS = [
    {
        label: 'RE_REVIEW_ERROR_TYPE_NORMAL',
        value: 'RE_REVIEW_ERROR_TYPE_NORMAL',
    },
    {
        label: 'RE_REVIEW_ERROR_TYPE_CRITICAL',
        value: 'RE_REVIEW_ERROR_TYPE_CRITICAL',
    },
];

export const SUB_CATEGORY_OPTIONS = [
    {
        label: 'OTHER_LIVENESS_ATTEMPT_ISSUE',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_OTHER_LIVENESS_ATTEMPT_ISSUE',
    },
    {
        label: 'QUEUED_VIDEO_ISSUE',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_QUEUED_VIDEO_ISSUE',
    },
    {
        label: 'WRONG_VERDICT_AFTER_OUTCALL',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_WRONG_VERDICT_AFTER_OUTCALL',
    },
    {
        label: 'OUTCALL_NOT_MADE',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_OUTCALL_NOT_MADE',
    },
    {
        label: 'CUSTOMER_PROFILE_REVIEW_ISSUE',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_CUSTOMER_PROFILE_REVIEW_ISSUE',
    },
    {
        label: 'FACEMATCH_WRONG_VERDICT',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_FACEMATCH_WRONG_VERDICT',
    },
    {
        label: 'MISSED_VIDEO_ISSUES',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_MISSED_VIDEO_ISSUES',
    },
    {
        label: 'MISSED_POTENTIAL_FRAUD',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_MISSED_POTENTIAL_FRAUD',
    },
    {
        label: 'MISSED_REMARKS',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_MISSED_REMARKS',
    },
    {
        label: 'WRONG_VIDEO_ISSUE',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_WRONG_VIDEO_ISSUE',
    },
    {
        label: 'WRONG_POTENTIAL_FRAUD',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_WRONG_POTENTIAL_FRAUD',
    },
    {
        label: 'WRONG_LIVE_PERSON',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_WRONG_LIVE_PERSON',
    },
    {
        label: 'INVALID_COMBO',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_INVALID_COMBO',
    },
    {
        label: 'WRONG_REMARKS',
        value: 'RE_REVIEW_ERROR_SUBCATEGORY_WRONG_REMARKS',
    },
];

/**
 *
 * Scripts - Main entry point component to handle all the routes related logic for Scripts Section
 *
 */

import React, { memo } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';

import { injectReducer, injectSaga } from '../../utils';

import { SCRIPTS_PATH, VIEW_SCRIPT_PATH } from '../AppV2/routes';
import ViewSingleScript from './ViewSingleScript';
import ViewScripts from './ViewScripts';
import reducer from './reducer';
import saga from './saga';
import './style.scss';

const Scripts = () => (
    <div className='scripts'>
        <div className='scripts-cr'>
            <Switch>
                <Route path={VIEW_SCRIPT_PATH} component={ViewSingleScript} />
                <Route path={SCRIPTS_PATH} component={ViewScripts} />
            </Switch>
        </div>
    </div>
);

const withReducer = injectReducer({ key: 'scripts', reducer });
const withSaga = injectSaga({ key: 'scripts', saga });

export default compose(
    withReducer,
    withSaga,
)(memo(Scripts));

/**
 *
 * Rewards Landing Page
 *
 */

import React from 'react';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';

import MarkAgainstTicketCTA from './MarkAgainstTicketCTA';
import ActiveView from './ActiveView';
import ClaimsView from './ClaimsView';
import TotalRewards from './TotalRewards';
import EligibleRewards from './EligibleRewards';

import reducer from './reducer';
import saga from './saga';

import './style.scss';

const Rewards = (props) => {
    const { ticketId, userId, index } = props;

    const renderSubmitCTA = () => (
        <MarkAgainstTicketCTA
            ticketId={ticketId}
            userId={userId}
            index={index}
        />
    );

    return (
        <div className='rewards'>
            {renderSubmitCTA()}
            <ActiveView
                ticketId={ticketId}
                userId={userId}
                index={index}
            />
            <ClaimsView
                ticketId={ticketId}
                userId={userId}
                index={index}
            />
            <TotalRewards
                ticketId={ticketId}
                userId={userId}
                index={index}
            />
            <EligibleRewards
                ticketId={ticketId}
                userId={userId}
                index={index}
            />
            {renderSubmitCTA()}
        </div>
    );
};

const withReducer = injectReducer({ key: 'rewards', reducer });
const withSaga = injectSaga({ key: 'rewards', saga });

export default compose(
    withReducer,
    withSaga,
)(Rewards);

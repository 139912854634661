/*
 *
 * Wealth Investment Constants
 *
 */
// investment onboarding info
export const GET_ONBOARDING_INFO = 'app/WI/GET_ONBOARDING_INFO';
export const SET_ONBOARDING_INFO = 'app/WI/SET_ONBOARDING_INFO';
export const ERR_ONBOARDING_INFO = 'app/WI/ERR_ONBOARDING_INFO';

// investment readiness status info
export const GET_INV_READINESS_STATUS = 'app/WI/GET_INV_READINESS_STATUS';
export const SET_INV_READINESS_STATUS = 'app/WI/SET_INV_READINESS_STATUS';
export const ERR_INV_READINESS_STATUS = 'app/WI/ERR_INV_READINESS_STATUS';

// key to be used for reducers & selectors for wealth investment
export const WEALTH_INVESTMENT_KEY = 'wealthInvestment';

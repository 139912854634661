/*
 *
 * FacematchKYCImage constants
 *
 */

export const GET_FM_KYC_IMAGE = 'app/FMKYC/GET_FM_KYC_IMAGE';
export const SET_FM_KYC_IMAGE = 'app/FMKYC/SET_FM_KYC_IMAGE';
export const ERR_FM_KYC_IMAGE = 'app/FMKYC/ERR_FM_KYC_IMAGE';

export const SET_MONORAIL_ID = 'app/FMKYC/SET_MONORAIL_ID';

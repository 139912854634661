/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

/**
 *
 * @file USSAccountBasicDetails : Show account basic details page.
 *
 */

import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, Loader, Table } from '../../../components';
import { isNilOrEmpty } from '../../../utils';

import { setAccountViewAction } from '../actions';
import { ACCOUNT_BASIC_DETAILS, VIEW_ACCOUNT_STAGES_DETAILS } from '../constants';
import { selectUSSAccountBasicDetails, selectUSSLoading } from '../selectors';

const USSAccountBasicDetails = (props) => {
    const { ticketId } = props;

    const dispatch = useDispatch();

    const accountBasicDetails = useSelector(selectUSSAccountBasicDetails, shallowEqual);
    const loading = useSelector(selectUSSLoading, shallowEqual);

    const { data: accountBasicDetail } = accountBasicDetails ? accountBasicDetails[ticketId] : [];

    const handleSubmit = () => {
        dispatch(setAccountViewAction(VIEW_ACCOUNT_STAGES_DETAILS));
    };

    const renderActions = () => (
        <div className='frccWrapper'>
            <Button
                link
                label='View Details'
                onClick={handleSubmit}
            />
        </div>
    );

    if (isNilOrEmpty(accountBasicDetail)) return null;

    return (
        <div>
            <Table
                v2
                labelData={ACCOUNT_BASIC_DETAILS}
                contentData={accountBasicDetail}
                renderActionableColumn={renderActions}
            />

            <Loader visible={loading} />
        </div>
    );
};

export default USSAccountBasicDetails;

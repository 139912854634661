import { createSelector } from 'reselect';
import { Map } from 'immutable';

export const selectAgentCreateFormDomain = () => (state) => state.get('agentCreateForm');
const selectFormDomain = () => (state) => state.get('form');

const selectAccessLevel = state => state.getIn(['agentCreateForm', 'accessLevelList']);

const makeSelectAgentCreateForm = () => createSelector(
    selectAgentCreateFormDomain(),
    (substate) => substate.toJS()
);

const makeSelectAgentCreateFormInitialValues = () => createSelector(
    selectAgentCreateFormDomain(),
    (substate) => substate.get('formInitialValues'),
);

const makeSelectAgentCreateFormValues = () => createSelector(
    selectFormDomain(),
    (substate) => substate.getIn(["AgentCreateForm", 'values'], Map()),
);

const makeSelectAccessLevel = () => createSelector(
    selectAccessLevel,
    accessLevel => accessLevel.toJS(),
);

export {
    makeSelectAgentCreateForm,
    makeSelectAgentCreateFormValues,
    makeSelectAgentCreateFormInitialValues,
    makeSelectAccessLevel,
};

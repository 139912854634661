/**
 * @file RecentActivity contains rudder event names for Recent Activity
 */

const LandedOnSherlockRecentActivity = 'LandedOnSherlockRecentActivity';
const ClickedSherlockSearchRecentActivityButton = 'ClickedSherlockSearchRecentActivityButton';
const SearchedSherlockRecentActivity = 'SearchedSherlockRecentActivity';
const ClickedClearAllSherlockRecentActivity = 'ClickedClearAllSherlockRecentActivity';

export {
    LandedOnSherlockRecentActivity,
    ClickedSherlockSearchRecentActivityButton,
    SearchedSherlockRecentActivity,
    ClickedClearAllSherlockRecentActivity,
};

import { US_STOCKS_ROOT } from '../../api/routes';

export const ROOT_PATH = '/';

// login route
export const LOGIN_PATH = `${ROOT_PATH}login`;

/* federal agents flow routes */
export const FEDERAL_PATH = `${ROOT_PATH}federal`;
export const FEDERAL_AGENT_PATH = `${FEDERAL_PATH}/agent`;
export const FEDERAL_US_STOCKS_PATH = `${FEDERAL_PATH}/us-stocks`;
export const FEDERAL_LENDING_PATH = `${FEDERAL_PATH}/lending`;
/* end federal agents flow routes */

/* agent management routes */
export const AGENT_PATH = `${ROOT_PATH}agent-management/`;
export const CREATE_AGENT_PATH = `${AGENT_PATH}create`;
export const VIEW_AGENT_PATH = `${AGENT_PATH}:agentEmail`;
export const EDIT_AGENT_PATH = `${AGENT_PATH}:agentEmail/edit`;
/* end agent management routes */

/* audit logs flow routes */
export const LOGS_PATH = `${ROOT_PATH}logs/`;
export const VIEW_LOG_PATH = `${LOGS_PATH}:logId`;
/* end audit logs flow routes */

// payout requests flow route
export const PAYOUT_REQUESTS_PATH = `${ROOT_PATH}payouts`;

// waitlist approval flow route
export const WAITLIST_APPROVAL_PATH = `${ROOT_PATH}waitlist-approval`;

// check waitlist details
export const CHECK_WAITLIST_DETAILS_PATH = `${ROOT_PATH}check-waitlist-details`;

/* ticket flow routes */
export const DEFAULT_TICKET_PATH = `${ROOT_PATH}ticket/`;
export const TICKET_PATH = `${DEFAULT_TICKET_PATH}:ticketId`;

/* banking ticket flow routes */
export const BANKING_TICKET_PATH = `${TICKET_PATH}/banking`;
export const BA_PROFILE_TICKET_PATH = `${BANKING_TICKET_PATH}/profile`;
export const BA_KYC_ONBOARDING_TICKET_PATH = `${BANKING_TICKET_PATH}/kyc-onboarding`;
export const BA_ACCOUNT_TICKET_PATH = `${BANKING_TICKET_PATH}/deposits`;
export const BA_TXN_TICKET_PATH = `${BANKING_TICKET_PATH}/txn`;
export const BA_AUTOPAY_TICKET_PATH = `${BANKING_TICKET_PATH}/autopay`;
export const BA_AUTOPAY_UPCOMING_TICKET_PATH = `${BA_AUTOPAY_TICKET_PATH}/upcoming`;
export const BA_AUTOPAY_EXISTING_TICKET_PATH = `${BA_AUTOPAY_TICKET_PATH}/existing`;
export const BA_CREDIT_CARD_TXN_TICKET_PATH = `${BANKING_TICKET_PATH}/credit-card-txn`;
export const BA_INSTRUMENT_TICKET_PATH = `${BANKING_TICKET_PATH}/instrument`;
export const BA_DEBIT_INSTRUMENT_TICKET_PATH = `${BA_INSTRUMENT_TICKET_PATH}/debit`;
export const BA_UPI_INSTRUMENT_TICKET_PATH = `${BA_INSTRUMENT_TICKET_PATH}/upi`;
export const BA_RULE_TICKET_PATH = `${BANKING_TICKET_PATH}/rule`;
export const BA_REWARDS_TICKET_PATH = `${BANKING_TICKET_PATH}/rewards`;
export const BA_COMMS_TICKET_PATH = `${BANKING_TICKET_PATH}/comms`;
export const BA_RECENT_ACTIVITY_PATH = `${BANKING_TICKET_PATH}/recent-activity`;
export const BA_USER_ACTIONS_TICKET_PATH = `${BANKING_TICKET_PATH}/user-actions`;
export const BA_CALL_USER_ACTION_TICKET_PATH = `${BA_USER_ACTIONS_TICKET_PATH}/call`;
export const BA_PAY_USER_ACTION_TICKET_PATH = `${BA_USER_ACTIONS_TICKET_PATH}/pay`;
export const BA_EXPORT_HEALTH_DATA_ACTION_TICKET_PATH = `${BA_USER_ACTIONS_TICKET_PATH}/export-health-data`;
export const BA_MERGE_TICKETS_PATH = `${BANKING_TICKET_PATH}/merge-tickets`;
export const BA_CALL_DETAILS_TICKET_PATH = `${BANKING_TICKET_PATH}/call-recordings`;
export const BA_FIT_RULES_TICKET_PATH = `${BANKING_TICKET_PATH}/fit-rules`;
export const BA_USER_REQUESTS_TICKET_PATH = `${BANKING_TICKET_PATH}/user-requests`;
export const BA_ACCOUNT_STATEMENT_USER_REQUEST_TICKET_PATH = `${BA_USER_REQUESTS_TICKET_PATH}/account-statement`;
export const BA_ACCOUNT_CLOSURE_USER_REQUEST_TICKET_PATH = `${BA_USER_REQUESTS_TICKET_PATH}/account-closure`;
export const BA_CHEQUEBOOK_USER_REQUEST_TICKET_PATH = `${BA_USER_REQUESTS_TICKET_PATH}/chequebook-request`;
export const BA_CHEQUEBOOK_USER_REQUEST_SEND_NOTIFICATIONS_PATH = `${BA_USER_REQUESTS_TICKET_PATH}/send-notifications`;
export const BA_ADMIN_ACTIONS_TICKET_PATH = `${BANKING_TICKET_PATH}/admin-actions`;
export const BA_REFERRALS_TICKET_PATH = `${BANKING_TICKET_PATH}/referrals`;
export const BA_DISPUTES_TICKET_PATH = `${BANKING_TICKET_PATH}/disputes`;
export const BA_SALARY_PROGRAM_PATH = `${BANKING_TICKET_PATH}/salary`;
export const BA_PRE_APPROVED_LOANS_PATH = `${BANKING_TICKET_PATH}/loans/pre-approved-loans`;
export const BA_LOAN_AGAINST_MF_PATH = `${BANKING_TICKET_PATH}/loans/loan-against-mf`;
export const BA_TIERING_PATH = `${BANKING_TICKET_PATH}/tiering`;
export const BA_SALARY_ACCOUNT_DETAILS_PATH = `${BA_SALARY_PROGRAM_PATH}/account-details`;
export const BA_SALARY_VERIFICATION_REQUESTS_PATH = `${BA_SALARY_PROGRAM_PATH}/verification-requests`;
export const BA_SALARY_HEALTH_INSURANCE_PATH = `${BA_SALARY_PROGRAM_PATH}/health-insurance`;
export const BA_SALARY_MANDATES_PATH = `${BA_SALARY_PROGRAM_PATH}/mandates`;
export const BA_SALARY_EXECUTED_MANDATES_PATH = `${BA_SALARY_PROGRAM_PATH}/executed-mandates`;
export const BA_CREDIT_CARD_TICKET_PATH = `${BANKING_TICKET_PATH}/credit`;
/* end banking ticket flow routes */

/* scripts urls start */
export const SCRIPTS_BASE_URL = '/scripts/admin';
export const SCRIPTS_LOGIN_URL = `${SCRIPTS_BASE_URL}/strapi-plugin-sso/cognito`;
/* scripts urls end */

/* connected accounts ticket flow routes */
export const CONNECTED_ACCOUNTS_TICKET_PATH = `${TICKET_PATH}/connected-accounts`;
export const CA_ACCOUNTS_TICKET_PATH = `${CONNECTED_ACCOUNTS_TICKET_PATH}/accounts`;
/* end connected accounts ticket flow routes */

/* wealth ticket flow routes */
export const WEALTH_TICKET_PATH = `${TICKET_PATH}/wealth`;
export const WL_ONBOARDING_TICKET_PATH = `${WEALTH_TICKET_PATH}/onboarding`;
export const WL_INVESTMENT_TICKET_PATH = `${WEALTH_TICKET_PATH}/investment`;
export const WL_MUTUAL_FUNDS_TICKET_PATH = `${WEALTH_TICKET_PATH}/mutual-funds`;
export const WL_P2P_TICKET_PATH = `${WEALTH_TICKET_PATH}/p2p`;
export const WL_USSTOCKS_TICKET_PATH = `${WEALTH_TICKET_PATH}/us-stocks`;
export const WL_USSTOCKS_ACCOUNT_OPENING = `${WL_USSTOCKS_TICKET_PATH}/account-opening`;
export const WL_USSTOCKS_PORTFOLIO_DETAILS = `${WL_USSTOCKS_TICKET_PATH}/portfolio-details`;
export const WL_USSTOCKS_REMITTANCE_DETAILS = `${WL_USSTOCKS_TICKET_PATH}/remittance-details`;
export const WL_USSTOCKS_ACTIVITY_DETAILS = `${WL_USSTOCKS_TICKET_PATH}/activity-details`;
/* end wealth ticket flow routes */
/* end ticket flow routes */

/* developer flow routes */
// developer
export const DEVELOPER_PATH = `${ROOT_PATH}developer/`;

// app logs
export const APP_LOGS_PATH = `${DEVELOPER_PATH}app-logs`;
export const VIEW_APP_LOG_PATH = `${APP_LOGS_PATH}/view-log`;

// db states
export const DB_STATES_PATH = `${DEVELOPER_PATH}db-states`;

// ticket summary
export const TICKET_SUMMARY_PATH = `${DEVELOPER_PATH}ticket-summary`;

// dev actions
export const DEV_ACTIONS_PATH = `${DEVELOPER_PATH}actions`;

// liveness summary
export const LIVENESS_SUMMARY_PATH = `${DEVELOPER_PATH}liveness-summary`;

// customer details - shows customer information like name, email, kyc status, summary, etc.
export const CUSTOMER_DETAILS_PATH = `${DEVELOPER_PATH}customer-details`;

// liveness video
export const LIVENESS_VIDEO_PATH = `${DEVELOPER_PATH}liveness-video`;

// facematch kyc image
export const FM_KYC_IMAGE_PATH = `${DEVELOPER_PATH}fm-kyc-image`;

// facematch image - view existing queue of facematch cases and review
export const FACEMATCH_REVIEW_PATH = `${DEVELOPER_PATH}facematch-review`;

// pan namematch review - compare name details of kyc, pan and profile
export const NAMEMATCH_REVIEW_PATH = `${DEVELOPER_PATH}namematch-review`;

// liveness and facematch review - combined review of liveness and facematch in a single queue
export const LIVENESS_AND_FACEMATCH_REVIEW_PATH = `${DEVELOPER_PATH}liveness-and-facematch-review`;

// cx risk review - review of risk elements like liveness w.r.t CX tickets
export const CX_RISK_REVIEW_PATH = `${DEVELOPER_PATH}cx-risk-review`;

// user risk review -  review of risk elements at a user level, it could contain liveness, facematch, kyc info, etc.
export const USER_RISK_REVIEW_PATH = `${DEVELOPER_PATH}user-risk-review`;

// risk case queue review -  review of transaction case risk elements at a user level
export const RISK_CASE_TXN_PATH = `${DEVELOPER_PATH}risk-case-transaction`;

// risk escalation case review -  review of escalation case risk elements at a user level
export const ESCALATION_REVIEW_PATH = `${DEVELOPER_PATH}escalation-review`;

// risk case queue review -  review of user case risk elements at a user level
export const RISK_CASE_USER_PATH = `${DEVELOPER_PATH}risk-case-user`;

// risk case search -  review of case risk elements at a user level and case level
export const RISK_CASE_SEARCH_PATH = `${DEVELOPER_PATH}risk-case-search`;

// risk rule queue review -  view the list of risk rules at a user level
export const RISK_RULE_PATH = `${DEVELOPER_PATH}risk-rule`;

// wealth ops - view existing queue of wealth cases and review
export const WEALTH_OPS_PATH = `${DEVELOPER_PATH}wealth-ops`;

// us-stocks ops - review of facematch under usStocksOps role
export const US_STOCKS_DEVELOPER_PATH = `${DEVELOPER_PATH}${US_STOCKS_ROOT}`;
export const US_STOCKS_FACEMATCH_REVIEW_PATH = `${US_STOCKS_DEVELOPER_PATH}/facematch-review`;

// liveness status
export const LIVENESS_STATUS_PATH = `${DEVELOPER_PATH}liveness-status`;

// liveness check - view existing queue of liveness cases and review
export const LIVENESS_REVIEW_PATH = `${DEVELOPER_PATH}liveness-review`;

// fit admin
export const FIT_RULES_PATH = `${DEVELOPER_PATH}fit-rules`;

// Banking paths
export const DEVELOPER_BANKING_PATH = `${DEVELOPER_PATH}banking`;
export const DEVELOPER_BANKING_PROFILE_PATH = `${DEVELOPER_BANKING_PATH}/profile`;
export const DEVELOPER_BANKING_SAVINGS_ACCOUNT_PATH = `${DEVELOPER_BANKING_PATH}/savings-account`;
/* end developer flow routes */

/* biz admin flow routes */
export const BIZ_ADMIN_PATH = `${ROOT_PATH}biz-admin`;
export const BIZ_ADMIN_USER_DETAILS_PATH = `${BIZ_ADMIN_PATH}/user-details`;
export const BIZ_ADMIN_BULK_USER_DETAILS_PATH = `${BIZ_ADMIN_PATH}/bulk-user-details`;
/* end admin flow routes */

/* admin tools flow routes */
export const ADMIN_TOOLS_PATH = `${ROOT_PATH}admin-tools`;
export const BULK_USER_DETAILS_PATH = `${ADMIN_TOOLS_PATH}/bulk-user-details`;
export const WAITLIST_USER_DETAILS_PATH = `${ADMIN_TOOLS_PATH}/waitlist-user-details`;
export const BULK_TICKET_UPDATE_PATH = `${ADMIN_TOOLS_PATH}/bulk-ticket-update`;
export const BULK_TICKET_UPDATE_UPLOAD_CSV_PATH = `${BULK_TICKET_UPDATE_PATH}/upload-csv`;
export const BULK_TICKET_UPDATE_HISTORY_PATH = `${BULK_TICKET_UPDATE_PATH}/history`;
export const BANNERS_PATH = `${ADMIN_TOOLS_PATH}/banners`;
export const CREATE_BANNER_PATH = `${BANNERS_PATH}/create`;
export const UPDATE_BANNER_PATH = `${BANNERS_PATH}/update`;
export const AGENT_FEEDBACK_DETAILS = `${ADMIN_TOOLS_PATH}/agent-feedback-details`;
export const ALL_DISPUTES = `${ADMIN_TOOLS_PATH}/disputes`;
export const LOG_FILTER = `${ADMIN_TOOLS_PATH}/log-filter`;
export const ISSUE_CONFIG_PATH = `${ADMIN_TOOLS_PATH}/issue-config`;
export const ISSUE_CONFIG_MANAGE_PATH = `${ISSUE_CONFIG_PATH}/manage`;
export const ISSUE_CONFIG_HISTORY_PATH = `${ISSUE_CONFIG_PATH}/history`;
/* end admin tools flow routes */

/* web developer flow routes */
export const WEB_DEVELOPER_PATH = `${ROOT_PATH}web-developer`;
export const INSTANT_INDEXING_PATH = `${WEB_DEVELOPER_PATH}/instant-indexing`;
export const INSTANT_INDEXING_UPDATE_URL_PATH = `${INSTANT_INDEXING_PATH}/update-url`;
export const INSTANT_INDEXING_URL_STATUS_PATH = `${INSTANT_INDEXING_PATH}/url-status`;
/* end web developer flow routes */

/* account ops flow routes */
export const ACCOUNT_OPS_PATH = `${ROOT_PATH}account-ops`;
export const BULK_ACCOUNT_VERIFICATION_PATH = `${ACCOUNT_OPS_PATH}/bulk-account-verification`;
export const UPDATE_BALANCES_PATH = `${ACCOUNT_OPS_PATH}/update-balances`;
export const BALANCE_TRANSFER_UTRS_PATH = `${ACCOUNT_OPS_PATH}/balance-transfer-utrs`;
/* end account ops flow routes */

/* salary account flow routes */
export const SALARY_ACCOUNT_OPS_PATH = `${ROOT_PATH}salary-account`;
export const SALARY_ACCOUNT_VIEW_DETAILS_PATH = `${SALARY_ACCOUNT_OPS_PATH}/account-details`;
export const SALARY_ACCOUNT_VERIFICATION_QUEUE_PATH = `${SALARY_ACCOUNT_OPS_PATH}/verification-queue`;
export const SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_PATH = `${SALARY_ACCOUNT_OPS_PATH}/raise-verification-request`;
export const SALARY_ACCOUNT_DEV_ACTIONS = `${SALARY_ACCOUNT_OPS_PATH}/dev-actions`;
export const SALARY_ACCOUNT_OPS_BANKING_PATH = `${SALARY_ACCOUNT_OPS_PATH}/banking`;
export const SALARY_ACCOUNT_OPS_BANKING_PROFILE_PATH = `${SALARY_ACCOUNT_OPS_BANKING_PATH}/profile`;
export const SALARY_ACCOUNT_OPS_BANKING_SAVINGS_ACCOUNT_PATH = `${SALARY_ACCOUNT_OPS_BANKING_PATH}/savings-account`;
/* end salary account flow routes */

/* risk transaction and user */
export const RISK_OPS_RISK_PATH = `${ROOT_PATH}risk`;

// risk user
export const RISK_OPS_USER_PATH = `${RISK_OPS_RISK_PATH}/user`;
export const RISK_OPS_USER_VIEW_PATH = `${RISK_OPS_USER_PATH}/view`;
export const RISK_OPS_USER_AFU_HISTORY_PATH = `${RISK_OPS_USER_PATH}/afu-history`;
export const RISK_OPS_USER_PRODUCTS_PATH = `${RISK_OPS_USER_PATH}/products`;
export const RISK_OPS_USER_CASE_DETAILS_PATH = `${RISK_OPS_USER_PATH}/case-details`;
export const RISK_OPS_USER_CASE_SUBMIT_ACTIONS_PATH = `${RISK_OPS_USER_PATH}/submit-actions`;
export const RISK_OPS_USER_USER_RESPONSE = `${RISK_OPS_USER_PATH}/user-response`;
export const RISK_OPS_USER_RISK_ACTIVITY = `${RISK_OPS_USER_PATH}/risk-activity`;

// risk transaction
export const RISK_OPS_TXN_PATH = `${RISK_OPS_RISK_PATH}/transaction`;
export const RISK_OPS_TXN_HISTORY_PATH = `${RISK_OPS_TXN_PATH}/history`;
export const RISK_OPS_TXN_USER_VIEW_PATH = `${RISK_OPS_TXN_PATH}/user-view`;
export const RISK_OPS_TXN_CASE_DETAILS_PATH = `${RISK_OPS_TXN_PATH}/case-details`;
export const RISK_OPS_TXN_VIEW_PATH = `${RISK_OPS_TXN_PATH}/view`;
export const RISK_OPS_TXN_ANNOTATION_PATH = `${RISK_OPS_TXN_PATH}/annotation`;
export const RISK_OPS_TXN_USER_RESPONSE = `${RISK_OPS_TXN_PATH}/user-response`;
export const RISK_OPS_TXN_RISK_ACTIVITY = `${RISK_OPS_TXN_PATH}/risk-activity`;
/* end risk transaction  and user */

/* risk cases */
export const RISK_OPS_CASES_PATH = `${RISK_OPS_RISK_PATH}/cases`;
export const RISK_OPS_CASES_CASE_DETAILS_PATH = `${RISK_OPS_CASES_PATH}/case-details`;
export const RISK_OPS_CASES_TXN_VIEW_PATH = `${RISK_OPS_CASES_PATH}/transction-view`;
export const RISK_OPS_CASES_USER_VIEW_PATH = `${RISK_OPS_CASES_PATH}/user-view`;
export const RISK_OPS_CASES_USER_RESPONSE = `${RISK_OPS_CASES_PATH}/user-response`;
export const RISK_OPS_CASES_RISK_ACTIVITY = `${RISK_OPS_CASES_PATH}/risk-activity`;
/* end risk cases */

/* salary whitelist B2B */
export const SALARY_WHITELIST_B2B_PATH = `${ROOT_PATH}salary-whitelist-b2b`;

/* B2B Onboarding */
export const B2B_ONBOARDING_PATH = `${SALARY_WHITELIST_B2B_PATH}/b2b-onboarding`;
export const SALARY_WHITELIST_B2B_BANKING_PATH = `${SALARY_WHITELIST_B2B_PATH}/banking`;
export const SALARY_WHITELIST_B2B_BANKING_PROFILE_PATH = `${SALARY_WHITELIST_B2B_BANKING_PATH}/profile`;
export const SALARY_WHITELIST_B2B_BANKING_SAVINGS_ACCOUNT_PATH = `${SALARY_WHITELIST_B2B_BANKING_PATH}/savings-account`;

/* sherlock v2 */
export const SHERLOCK_V2 = `${ROOT_PATH}v2`;

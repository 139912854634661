/**
 * @file Manages the selector functions for Credit Card component
 */

import { createSelector } from 'reselect';

import { CREDIT_CARD_KEY, REDUCER_KEYS } from './constants';

const selectCreditCardLoader = createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, REDUCER_KEYS.CREDIT_CARD_LOADER]),
    (subState) => subState,
);

const selectCreditCardError = createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, REDUCER_KEYS.CREDIT_CARD_ERROR]),
    (subState) => subState,
);

const selectCreditCardOnboardingDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CREDIT_CARD_ONBOARDING_DETAILS]),
    (substate) => substate?.toJS(),
);

const selectFDCreditCardDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.FD_CREDIT_CARD]),
    (substate) => substate?.toJS(),
);

const selectCurrentCreditCardDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CURRENT_CREDIT_CARD_DETAILS]),
    (substate) => substate?.toJS(),
);

const selectAllCreditCardDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.ALL_CREDIT_CARDS]),
    (substate) => substate?.toJS(),
);

const selectCreditCardTrackingInfo = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CREDIT_CARD_TRACKING_INFO]),
    (substate) => substate?.toJS(),
);

const selectCreditCardControlDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CREDIT_CARD_CONTROL_DETAILS]),
    (substate) => substate?.toJS(),
);

const selectCreditCardUsageDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CREDIT_CARD_USAGE_DETAILS]),
    (substate) => substate?.toJS(),
);

const selectCurrentLimitUsageDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CURRENT_LIMIT_USAGE_DETAILS]),
    (substate) => substate?.toJS(),
);

const selectCreditCardBillingInfo = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CREDIT_CARD_BILLING_INFO]),
    (substate) => substate?.toJS(),
);

const selectAllActiveLoanAccounts = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.ALL_ACTIVE_LOAN_ACCOUNTS]),
    (substate) => substate?.toJS(),
);

const selectUpcomingEmiInfo = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.UPCOMING_EMI_INFO]),
    (substate) => substate?.toJS(),
);

const selectAllClosedLoanAccounts = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.ALL_CLOSED_LOAN_ACCOUNTS]),
    (substate) => substate?.toJS(),
);

const selectLoanAccountDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.LOAN_ACCOUNT_DETAILS]),
    (substate) => substate?.toJS(),
);

const selectAllEligibleTransactionsForEmis = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS]),
    (substate) => substate?.toJS(),
);

const selectLoanOffers = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.LOAN_OFFERS]),
    (substate) => substate?.toJS(),
);

const selectBlockCardDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.BLOCK_CREDIT_CARD]),
    (substate) => substate?.toJS(),
);

const selectSuspendCardDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.SUSPEND_CREDIT_CARD]),
    (substate) => substate?.toJS(),
);

// v2 selectors

const selectCreditCardEligibilityDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CREDIT_CARD_ElIGIBILITY_DETAILS]),
    (substate) => substate?.toJS(),
);

const selectShowOnBoardingDetailsV2 = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.SHOW_CREDIT_CARD_ONBOARDING_DETAILS_V2]),
    (substate) => substate,
);

const selectCreditCardOnboardingDetailsV2 = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CREDIT_CARD_ONBOARDING_DETAILS_V2]),
    (substate) => substate?.toJS(),
);

const selectCreditCardKycDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CREDIT_CARD_KYC_DETAILS]),
    (substate) => substate?.toJS(),
);

const selectSelectedCreditCardDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.SELECTED_CREDIT_CARD_DETAILS]),
    (substate) => substate?.toJS(),
);

const selectCreditCardCurrentStageDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CREDIT_CARD_CURRENT_STAGE_DETAILS]),
    (substate) => substate?.toJS(),
);

const selectCreditCardNextStageDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CREDIT_CARD_NEXT_STAGE_DETAILS]),
    (substate) => substate?.toJS(),
);

const selectCreditCardMilestoneRewardsDetails = (ticketId) => createSelector(
    (state) => state.getIn([CREDIT_CARD_KEY, ticketId, REDUCER_KEYS.CREDIT_CARD_MILESTONE_REWARDS_DETAILS]),
    (substate) => substate?.toJS(),
);

export {
    selectCreditCardLoader,
    selectCreditCardError,
    selectCurrentCreditCardDetails,
    selectCreditCardTrackingInfo,
    selectCreditCardControlDetails,
    selectAllCreditCardDetails,
    selectCreditCardUsageDetails,
    selectCreditCardOnboardingDetails,
    selectCurrentLimitUsageDetails,
    selectCreditCardBillingInfo,
    selectAllActiveLoanAccounts,
    selectUpcomingEmiInfo,
    selectAllClosedLoanAccounts,
    selectLoanAccountDetails,
    selectAllEligibleTransactionsForEmis,
    selectLoanOffers,
    selectBlockCardDetails,
    selectSuspendCardDetails,
    selectFDCreditCardDetails,
    selectCreditCardEligibilityDetails,
    selectCreditCardOnboardingDetailsV2,
    selectShowOnBoardingDetailsV2,
    selectCreditCardKycDetails,
    selectSelectedCreditCardDetails,
    selectCreditCardCurrentStageDetails,
    selectCreditCardNextStageDetails,
    selectCreditCardMilestoneRewardsDetails,
};

/**
 * @file Manages the action functions for Scripts component
 */

import {
    ERR_SCRIPT, GET_SCRIPT,
    SET_SCRIPT,
} from './constants';

export const getScriptAction = (data) => ({
    type: GET_SCRIPT,
    data,
});

export const setScriptAction = (data) => ({
    type: SET_SCRIPT,
    data,
});

export const errScriptAction = (data) => ({
    type: ERR_SCRIPT,
    data,
});

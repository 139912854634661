/**
 * InstantIndexing - Component to allow instant indexing from Google by updating or deleting url
 * It's getting used for Web Developer flow
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';

import { INSTANT_INDEXING_UPDATE_URL_PATH, INSTANT_INDEXING_URL_STATUS_PATH } from '../App/routes';

import UpdateUrl from './UpdateUrl';
import UrlStatus from './UrlStatus';

import { INSTANT_INDEXING_KEY } from './constants';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const InstantIndexing = () => (
    <div className='ins-indx'>
        <Switch>
            <Route
                path={INSTANT_INDEXING_UPDATE_URL_PATH}
                component={UpdateUrl}
            />
            <Route
                path={INSTANT_INDEXING_URL_STATUS_PATH}
                component={UrlStatus}
            />
            <Redirect to={{ pathname: INSTANT_INDEXING_UPDATE_URL_PATH }} />
        </Switch>
    </div>
);

const withReducer = injectReducer({ key: INSTANT_INDEXING_KEY, reducer });
const withSaga = injectSaga({ key: INSTANT_INDEXING_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(InstantIndexing);

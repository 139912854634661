export const GET_ACTIVE_RULES = 'app/Fittt/GET_ACTIVE_RULES';
export const SET_ACTIVE_RULES = 'app/Fittt/SET_ACTIVE_RULES';
export const ERR_ACTIVE_RULES = 'app/Fittt/ERR_ACTIVE_RULES';

export const GET_USER_RULES = 'app/Fittt/GET_USER_RULES';
export const SET_USER_RULES = 'app/Fittt/SET_USER_RULES';
export const ERR_USER_RULES = 'app/Fittt/ERR_USER_RULES';

export const GET_USER_RULE_INFO = 'app/Fittt/GET_USER_RULE_INFO';
export const SET_USER_RULE_INFO = 'app/Fittt/SET_USER_RULE_INFO';
export const ERR_USER_RULE_INFO = 'app/Fittt/ERR_USER_RULE_INFO';

import { createSelector } from 'reselect';

const selectLogin = state => state.get('login');

const makeSelectLoading = () => createSelector(
    selectLogin,
    substate => substate.get('loading')
);

const makeSelectErr = () => createSelector(
    selectLogin,
    substate => substate.get('err')
);

const makeSelectAuthState = () => createSelector(
    selectLogin,
    substate => substate.get('authState')
);

const makeSelectUserInfo = () => createSelector(
    selectLogin,
    substate => substate.get('userInfo').toJS()
);

const makeSelectForgotPwdState = () => createSelector(
    state => state.getIn(['login', 'forgotPwdState']),
    substate => substate
);

export {
    makeSelectLoading,
    makeSelectErr,
    makeSelectAuthState,
    makeSelectUserInfo,
    makeSelectForgotPwdState,
};

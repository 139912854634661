import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setTitle } from '../../actions';
import CorrectWrongIcon from '../../../../components/CorrectWrongIcon';

const LocationStep = (props) => {
    const { data, setAllAnsweredYes } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTitle({ title: 'VKYC - Location' }));
    });

    useEffect(() => {
        setAllAnsweredYes(data?.stages?.[0]?.location?.location_details.reduce(
            (accumulator, currentValue) => accumulator && currentValue.is_valid,
            true,
        ));
    }, [data]);

    return (
        <div className='vkyc-location-container'>
            <div>
                {data?.stages?.[0]?.location?.location_details?.map((locationKVPair) => (
                    <div className='mb-30 frcsbWrapper'>
                        <div>
                            <div className='sop-step-no'>{locationKVPair?.label}</div>
                            <div className='sop-step-title'>{locationKVPair?.expected_answer}</div>
                        </div>
                        <div>
                            <CorrectWrongIcon isCorrect={locationKVPair?.is_valid} />
                        </div>
                    </div>
                ))}
            </div>
            <div className='vkyc-location-lat-long_container'>
                <div className='vkyc-location-lat-long_kv-container'>
                    <div className='vkyc-location-lat-long_key'>Latitude</div>
                    <div className='vkyc-location-lat-long_value'>{data?.stages?.[0]?.location?.location?.coordinates?.latitude}</div>
                </div>
                <div>
                    <div className='vkyc-location-lat-long_key'>Longitude</div>
                    <div className='vkyc-location-lat-long_value'>{data?.stages?.[0]?.location?.location?.coordinates?.longitude}</div>
                </div>
                <div>
                    <div className='vkyc-location-lat-long_key'>Capture Timestamp</div>
                    <div className='vkyc-location-lat-long_value'>{
                        data?.stages?.[0]?.location?.location?.recorded_at?.seconds
                        && data?.stages?.[0]?.location?.location?.recorded_at?.seconds !== 0
                            ? (new Date()).toLocaleString()
                            : data?.stages?.[0]?.location?.location?.recorded_at?.seconds
                    }
                    </div>
                </div>
            </div>

        </div>
    );
};

export default LocationStep;

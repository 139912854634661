/**
 * ManageIssueConfig - Component to get all issue configs for specific L1,L2,L3.
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, FormDropdown, Loader, Table,
} from '../../../components';

import { makeSelectIssueConfigHistory, makeSelectIssueConfigFilterOptions } from '../selectors';

import '../style.scss';
import { getIssueConfigFilters, getIssueConfigHistory } from '../actions';
import JSONDiffViewer from './JSONDiffViewer';

const extractFilterOptions = (filterOptions) => {
    if (!filterOptions || filterOptions.length === 0) return filterOptions;
    return filterOptions.map((option) => ({
        ...option,
        label: option?.value,
    }));
};

const IssueConfigHistory = () => {
    const dispatch = useDispatch();

    // reducer state variables
    const issueConfigSelector = useSelector(makeSelectIssueConfigHistory(), shallowEqual);
    const issueConfigFilterOptionsSelector = useSelector(makeSelectIssueConfigFilterOptions(), shallowEqual);

    const [L1Details, setL1Details] = useState({});
    const [L2Details, setL2Details] = useState({});
    const [L3Details, setL3Details] = useState({});
    const [selectedConfig, setSelectedConfig] = useState();
    const [jsonViewerDetails, setJsonViewerDetails] = useState({});
    const [isJsonViewVisible, setIsJsonViewVisible] = useState(false);

    // local state variables
    const [errLabel, setErrLabel] = useState('');

    let prevToken; let nextToken; let issueConfigHistoryTable;

    const {
        data: issueConfigData, loading: issueConfigLoading, err: issueConfigError,
    } = issueConfigSelector;

    const {
        data: filterData, loading: filterDataLoading, err: filterDataError,
    } = issueConfigFilterOptionsSelector;

    // eslint-disable-next-line prefer-const
    ({ prev: prevToken, next: nextToken, issueConfigHistoryTable } = issueConfigData || {});

    const handleSubmit = (e) => {
        e.preventDefault();

        setErrLabel('');
        dispatch(getIssueConfigHistory({
            category: L1Details?.selectedValue?.value || '',
            categoryDetail: L2Details?.selectedValue?.value || '',
            subCategory: L3Details?.selectedValue?.value || '',
            configType: selectedConfig?.value || '',
            prevToken,
            nextToken,
        }));
    };

    const handleL1Change = (newValue) => {
        setL1Details({
            ...L1Details,
            selectedValue: newValue,
        });
        setL2Details({ options: extractFilterOptions(newValue?.children), selectedValue: null });
        setL3Details({ options: [], selectedValue: null });
    };
    const handleL2Change = (newValue) => {
        setL2Details({
            ...L2Details,
            selectedValue: newValue,
        });
        setL3Details({ options: extractFilterOptions(newValue?.children), selectedValue: null });
    };
    const handleL3Change = (newValue) => {
        setL3Details({
            ...L3Details,
            selectedValue: newValue,
        });
    };

    const paginatedClickHandler = (item) => {
        const { prev, next } = item;
        dispatch(getIssueConfigHistory({
            prevToken: prev, nextToken: next,
        }));
    };

    const renderActions = (item) => (
        <div className='frccWrapper'>
            <Button
                link
                label='View Diff'
                onClick={() => {
                    setJsonViewerDetails({
                        oldValue: item['Old Value']?.json_value,
                        newValue: item['New Value']?.json_value,
                    });
                    setIsJsonViewVisible(true);
                }}
            />
        </div>
    );

    useEffect(() => {
        if (filterData?.issueCategory && (!L1Details.options || Object.keys(L1Details.options).length === 0)) {
            setL1Details({ options: extractFilterOptions(filterData.issueCategory) });
        }
    }, [filterData]);

    useEffect(() => {
        if (!filterData) dispatch(getIssueConfigFilters());
    }, []);

    return (
        <React.Fragment>
            <div className=''>
                <div className='frcWrapper'>
                    <FormDropdown
                        label='L1 : '
                        options={L1Details.options || []}
                        isLoading={L1Details.loading}
                        input={{
                            value: L1Details.selectedValue,
                            onChange: handleL1Change,
                            placeholder: 'Choose',
                            isClearable: false,
                        }}
                        cacheKey='salary-identifier-type'
                        extStyles={{
                            container: 'issue-config-dd-container',
                            label: 'issue-config-dd-lb',
                            inputField: 'issue-config-dd-input',
                        }}
                    />
                    <FormDropdown
                        label='L2 :'
                        options={L2Details.options || []}
                        isLoading={L2Details.loading}
                        input={{
                            value: L2Details.selectedValue,
                            onChange: handleL2Change,
                            placeholder: 'Choose',
                            isClearable: true,
                        }}
                        cacheKey='salary-identifier-type'
                        extStyles={{
                            container: 'issue-config-dd-container',
                            label: 'issue-config-dd-lb',
                            inputField: 'issue-config-dd-input',
                        }}
                    />
                    <FormDropdown
                        label='L3 :'
                        options={L3Details.options || []}
                        isLoading={L3Details.loading}
                        input={{
                            value: L3Details.selectedValue,
                            onChange: handleL3Change,
                            placeholder: 'Choose',
                            isClearable: true,
                        }}
                        cacheKey='salary-identifier-type'
                        extStyles={{
                            container: 'issue-config-dd-container',
                            label: 'issue-config-dd-lb',
                            inputField: 'issue-config-dd-input',
                        }}
                    />
                    <FormDropdown
                        label='Config:'
                        options={filterData?.configType || []}
                        isLoading={filterDataLoading}
                        input={{
                            value: selectedConfig,
                            onChange: (val) => {
                                setSelectedConfig(val);
                            },
                            placeholder: 'Choose',
                            isClearable: true,
                        }}
                        cacheKey='salary-identifier-type'
                        extStyles={{
                            container: 'issue-config-dd-container',
                            label: 'issue-config-dd-lb',
                            inputField: 'issue-config-dd-input',
                        }}
                    />
                </div>

                <div className='err-label btu-upload-csv-el issue-config-err-label'>{errLabel || filterDataError || issueConfigError}</div>
                <Button
                    v2
                    primary
                    label='Submit'
                    onClick={handleSubmit}
                    extClasses='mt-30 mb-30'
                />

                <div className='mb-30'>
                    {issueConfigHistoryTable && (
                        <Table
                            v2
                            objKey='header_key'
                            rowDataVersion={2}
                            labelData={issueConfigHistoryTable.columnData}
                            contentData={issueConfigHistoryTable.rowData}
                            prevToken={prevToken}
                            nextToken={nextToken}
                            paginatedClickHandler={paginatedClickHandler}
                            extClasses={{
                                container: 'p-20',
                            }}
                            renderActionableColumn={renderActions}
                        />
                    )}
                </div>

                <JSONDiffViewer
                    visible={isJsonViewVisible}
                    toggleHandler={setIsJsonViewVisible}
                    oldJson={jsonViewerDetails?.oldValue}
                    newJson={jsonViewerDetails?.newValue}
                />
            </div>
            <Loader visible={filterDataLoading || issueConfigLoading} />
        </React.Fragment>
    );
};

export default IssueConfigHistory;

/**
 * @file CardLimitsScreen: Component to show the Credit Card Limit Details.
 */

import React, { memo } from 'react';

import { useScrollToPosition } from '../../../hooks';

import { CARD_USAGE_LIMITS_LIST, IS_CARD_LIMITS_SCREEN } from '../constants';
import CardBanner from '../CardBanner';

import './style.scss';

const CardLimitsScreen = (props) => {
    const { viewUsageLimits, ticketId } = props;

    useScrollToPosition();

    return (
        <CardBanner title='Credit Card Usage Limits' discardCurrentScreen={IS_CARD_LIMITS_SCREEN} ticketId={ticketId} containerClass>
            <div className='credit-card-clc-cwr'>
                <div className='frWrapper'>
                    <div className='credit-card-clc-cwr__hl credit-card-clc-cwr__f1'>Channel</div>
                    <div className='credit-card-clc-cwr__hl credit-card-clc-cwr__f2'>Domestic</div>
                    <div className='credit-card-clc-cwr__hl credit-card-clc-cwr__f2'>International</div>
                </div>
                <div className='frWrapper'>
                    <div className='credit-card-clc-cwr__f1' />
                    <div className='credit-card-clc-cwr__f1'>Current</div>
                    <div className='credit-card-clc-cwr__f1'>Max Allowed</div>
                    <div className='credit-card-clc-cwr__f1'>Current</div>
                    <div className='credit-card-clc-cwr__f1'>Max Allowed</div>
                </div>
                {
                    CARD_USAGE_LIMITS_LIST.map((item) => (
                        <div className='frWrapper' key={item.key}>
                            <div className='credit-card-clc-cwr__hl credit-card-clc-cwr__f1'>{item.limitLabel}</div>
                            <div className='credit-card-clc-cwr__f1'>{viewUsageLimits[item.key].domestic.currentAllowedAmount}</div>
                            <div className='credit-card-clc-cwr__f1'>{viewUsageLimits[item.key].domestic.maxAllowedAmount}</div>
                            <div className='credit-card-clc-cwr__f1'>{viewUsageLimits[item.key].international.currentAllowedAmount}</div>
                            <div className='credit-card-clc-cwr__f1'>{viewUsageLimits[item.key].international.maxAllowedAmount}</div>
                        </div>
                    ))
                }
            </div>
        </CardBanner>
    );
};

export default memo(CardLimitsScreen);

/**
 * MarkAgainstTicketCTA - CTA to submit the request for marking some data to a ticket
 */

import React, { memo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { isEmpty } from 'ramda';

import { Button } from '../../../components';

import { attachEntityToTicket } from '../../Tickets/actions';

import { setActiveViewEntityMeta, setClaimsViewEntityMeta } from '../actions';
import { makeSelectActiveViewEntityMeta, makeSelectClaimsViewEntityMeta } from '../selectors';

const MarkAgainstTicketCTA = (props) => {
    const dispatch = useDispatch();

    // global state variables
    const activeViewEntityMeta = useSelector(makeSelectActiveViewEntityMeta(), shallowEqual);
    const claimsViewEntityMeta = useSelector(makeSelectClaimsViewEntityMeta(), shallowEqual);

    const { ticketId, userId, index } = props;

    const hasEntityMeta = !isEmpty(activeViewEntityMeta) || !isEmpty(claimsViewEntityMeta);

    const onClickHandler = (e) => {
        e.preventDefault();

        if (!hasEntityMeta) {
            return;
        }

        const entityMeta = [];

        if (!isEmpty(activeViewEntityMeta)) {
            entityMeta.push(activeViewEntityMeta);
        }

        if (!isEmpty(claimsViewEntityMeta)) {
            entityMeta.push(claimsViewEntityMeta);
        }

        const callAttachEntityAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(attachEntityToTicket({
                    ticketId,
                    userId,
                    index,
                    type: 'REWARDS',
                    meta: entityMeta,
                    resolve,
                }));
            } catch (error) {
                reject(error);
            }
        });

        // reset the active & claims view entity meta after successful request
        callAttachEntityAction().then(() => {
            dispatch(setActiveViewEntityMeta({ meta: {} }));
            dispatch(setClaimsViewEntityMeta({ meta: {} }));
        });
    };

    return (
        <div className='rewards-mat-cta'>
            <Button
                v2
                primary
                label='mark against ticket'
                onClick={onClickHandler}
                disabled={!hasEntityMeta}
            />
        </div>
    );
};

export default memo(MarkAgainstTicketCTA);

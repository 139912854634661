/**
 *
 * This component disable right click on a browser for all its children elements
 *
 */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

const DisableContextMenu = ({ children }) => {
    const disableMenu = (e) => {
        e.preventDefault();
    };

    return (
        <div
            onMouseDown={disableMenu}
            onContextMenu={disableMenu}
        >
            {children}
        </div>
    );
};

export default DisableContextMenu;

/**
 * @file Manages the action functions for transaction view V2
 */

import {
    GET_TRANSACTION_VIEW_V2, SET_TRANSACTION_VIEW_V2_PERIOD, FIRE_TRANSACTION_VIEW_RENDER_MATRIC,
    TRANSACTION_VIEW_SET_FILTER_FIELD, RESET_TRANSACTION_VIEW, REQUEST_AGGREGATED_DATA_POINTS, GET_ANNOTATIONS,
    CREATE_TRANSACTIONS_ANNOTATIONS, SET_TXN_ANNOTATION_MODAL_VISIBILITY, FIRE_TRANSACTION_MATRIC_EVENT,
} from './constants';

export const getTransactionViewV2Action = (data) => ({
    type: GET_TRANSACTION_VIEW_V2,
    data,
});

export const setTransactionViewV2PeriodAction = (data) => ({
    type: SET_TRANSACTION_VIEW_V2_PERIOD,
    data,
});

export const fireRenderEvent = (data) => ({
    type: FIRE_TRANSACTION_VIEW_RENDER_MATRIC,
    data,
});

export const setFilterField = (data) => ({
    type: TRANSACTION_VIEW_SET_FILTER_FIELD,
    data,
});

export const resetTransactionViewV2 = () => ({
    type: RESET_TRANSACTION_VIEW,
});

export const fireTransactionEvent = (data) => ({ type: FIRE_TRANSACTION_MATRIC_EVENT, data });

export const getAggregateDataPointsTV2 = (data) => ({
    type: REQUEST_AGGREGATED_DATA_POINTS,
    data,
});

export const getAllowedAnnotations = (data) => ({
    type: GET_ANNOTATIONS,
    data,
});

export const createTransactionsAnnotations = (data) => ({
    type: CREATE_TRANSACTIONS_ANNOTATIONS,
    data,
});

export const setTransactionAnnotationModalVisibility = (data) => ({
    type: SET_TXN_ANNOTATION_MODAL_VISIBILITY,
    data,
});

/**
 *
 * LogsInfo
 *
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
    LOGS_PATH, VIEW_LOG_PATH,
} from '../App/routes';

import LogsList from './LogsList';
import LogDetails from './LogDetails';

class LogsInfo extends React.PureComponent {
    render() {
        return (
            <Switch>
                <Route
                    path={VIEW_LOG_PATH}
                    component={LogDetails}
                />
                <Route
                    path={LOGS_PATH}
                    component={LogsList}
                />
            </Switch>
        );
    }
}

export default (LogsInfo);

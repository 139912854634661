/**
 * @file Manages the selector functions for Instant Indexing component
 */

import { createSelector } from 'reselect';

import { INSTANT_INDEXING_KEY } from './constants';

const makeSelectLoading = () => createSelector(
    (state) => state.getIn([INSTANT_INDEXING_KEY, 'isLoading']),
    (substate) => substate,
);

const makeSelectUpdatedUrlInfo = () => createSelector(
    (state) => state.getIn([INSTANT_INDEXING_KEY, 'updatedUrlInfo']),
    (substate) => substate.toJS(),
);

const makeSelectUrlStatusInfo = () => createSelector(
    (state) => state.getIn([INSTANT_INDEXING_KEY, 'urlStatusInfo']),
    (substate) => substate.toJS(),
);

export {
    makeSelectLoading,
    makeSelectUpdatedUrlInfo,
    makeSelectUrlStatusInfo,
};

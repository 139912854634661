/**
 *
 * @file RenderView: Renders the page according to activity view.
 *
 */
import React, { memo } from 'react';

import { VIEW_PORTFOLIO_DETAILS, VIEW_PORTFOLIO_DETAILS_LIST } from '../constants';

import USSActivityList from '../USSActivityList';
import USSPortfolioDetailSearch from './USSPortfolioDetailSearch';

const RenderView = (props) => {
    const {
        usStocksView, ticketId, userId, index, portfolioDetailList,
    } = props;

    switch (usStocksView) {
        case VIEW_PORTFOLIO_DETAILS: {
            return (
                <USSPortfolioDetailSearch
                    ticketId={ticketId}
                    userId={userId}
                    index={index}
                    portfolioDetailList={portfolioDetailList}
                />
            );
        }

        case VIEW_PORTFOLIO_DETAILS_LIST: {
            return (
                <div className='uss'>
                    <USSActivityList ticketId={ticketId} />
                </div>
            );
        }

        default:
            return null;
    }
};

export default memo(RenderView);

/**
 * @file V2 Facematch Review flow
 * User can see the existing cases for facematch
 * User can select a case for review
 * For review, user can view the facematch image and fill details to update facematch status for that case
 * Once review is done, user can dequeue the case
 */

import React, { memo } from 'react';

import { RenderView } from '../RiskOps';

import SingleCase from './SingleCase';
import { FACEMATCH_REVIEW_KEY as CONTAINER_KEY, QUEUE_PAYLOAD_TYPES } from './constants';

import './style.scss';

const FacematchReviewV2 = () => (
    <RenderView
        singleCaseComponent={<SingleCase />}
        data={{
            containerKey: CONTAINER_KEY,
            queuePayloadTypes: QUEUE_PAYLOAD_TYPES,
            queueName: 'Facematch',
        }}
    />
);

export default memo(FacematchReviewV2);

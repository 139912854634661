/*
 *
 * PAN name match review constants
 *
 */
import { getInitialDateRange } from '../../utils';
import { LATEST_CASES_FIRST, OLDEST_CASES_FIRST } from '../RiskOps/constants';

// reducer & saga key
export const NAMEMATCH_REVIEW_KEY = 'namematchReview';

// action constants
export const GET_NAMEMATCH_CASES = 'app/NamematchReview/GET_NAMEMATCH_CASES';
export const SET_NAMEMATCH_CASES = 'app/NamematchReview/SET_NAMEMATCH_CASES';
export const ERR_NAMEMATCH_CASES = 'app/NamematchReview/ERR_NAMEMATCH_CASES';
export const DEQUEUE_NAMEMATCH_CASE = 'app/NamematchReview/DEQUEUE_NAMEMATCH_CASE';
export const SET_NAMEMATCH_VIEW = 'app/NamematchReview/SET_NAMEMATCH_VIEW';
export const SET_CUR_NAMEMATCH_CASE = 'app/NamematchReview/SET_CUR_NAMEMATCH_CASE';
export const SET_NAMEMATCH_PAYLOAD_TYPE = 'app/NamematchReview/SET_NAMEMATCH_PAYLOAD_TYPE';
export const GET_NAMEMATCH_PENDING_REVIEW_COUNT = 'app/NamematchReview/GET_NAMEMATCH_PENDING_REVIEW_COUNT';
export const SET_NAMEMATCH_PENDING_REVIEW_COUNT = 'app/NamematchReview/SET_NAMEMATCH_PENDING_REVIEW_COUNT';
export const SET_NAMEMATCH_QUEUE_FILTERS = 'app/NamematchReview/SET_NAMEMATCH_QUEUE_FILTERS';

// state constants
export const VIEW_ALL_NAMEMATCH_CASES = 'app/NamematchReview/State/VIEW_ALL_NAMEMATCH_CASES';
export const VIEW_SINGLE_NAMEMATCH_CASE = 'app/NamematchReview/State/VIEW_SINGLE_NAMEMATCH_CASE';
export const DEV_ACTION_TYPES = ['UPDATE_PAN_NAME_REVIEW'];

// value constants
export const PAYLOAD_TYPE_PAN_NAME_MATCH = 'PAYLOAD_TYPE_PAN_NAME_MATCH';
export const PAYLOAD_TYPE_PAN_NAME_SAMPLE = 'PAYLOAD_TYPE_PAN_NAME_SAMPLE';

export const QUEUE_PAYLOAD_TYPES = [PAYLOAD_TYPE_PAN_NAME_MATCH, PAYLOAD_TYPE_PAN_NAME_SAMPLE];

export const INITIAL_DATE_RANGE = getInitialDateRange();

const FROM_DATE_INFO = {
    label: 'From Date',
    labelId: 'namematch-review-from-date',
};

const TO_DATE_INFO = {
    label: 'To Date',
    labelId: 'namematch-review-to-date',
};

const DATE_PICKER_CLS = {
    container: ['namereview-fc'],
    inputField: ['namereview-fc__ip'],
};

export const DATE_INFO = {
    fromDateInfo: FROM_DATE_INFO,
    toDateInfo: TO_DATE_INFO,
    initialDateRange: INITIAL_DATE_RANGE,
    extClasses: DATE_PICKER_CLS,
};

export const PAYLOAD_DD_OG_STATE = {
    value: PAYLOAD_TYPE_PAN_NAME_MATCH,
    isVisible: false,
};

export const SORTING_OPTIONS = [OLDEST_CASES_FIRST, LATEST_CASES_FIRST];

export const SORT_DD_OG_STATE = {
    value: LATEST_CASES_FIRST,
    isVisible: false,
};

// class constants
export const DD_WRAPPER_CLS = {
    container: 'custom-dd namereview-idd',
};

export const DD_CONTENT_CLS = {
    container: ['namereview-idd__cc'],
};

export const DD_ITEM_CLS = 'custom-dd__cl namereview-idd__cl';

// table labels
export const LABEL_DATA = [
    {
        id: 1,
        label: 'Type',
        key: 'type',
    },
    {
        id: 2,
        label: 'First Name',
        key: 'first_name',
    },
    {
        id: 3,
        label: 'Middle Name',
        key: 'middle_name',
    },
    {
        id: 4,
        label: 'Last Name',
        key: 'last_name',
    },
    {
        id: 5,
        label: 'Honorific',
        key: 'honorific',
    },
];

/**
 * CreateNewTicketCTA - CTA to submit the request to create a new ticket in freshdesk
 */

import React, { memo, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import copy from 'copy-text-to-clipboard';

import CopyIcon from '../../../assets/images/icons/copy.png';
import CopiedIcon from '../../../assets/images/icons/green-tick.png';

import { Button, Modal, RadioButtonList } from '../../../components';
import { MetaInfoContext } from '../../../context';
import { setTicketIdAction } from '../../SOP/actions';
import { VIEW_SOP_USER_DETAILS_PATH } from '../../AppV2/routes';
import { createTicketForCallAction } from '../../AppV2/actions';

// custom styles for reason modal
const customModalStyles = {
    content: {
        padding: 32,
        width: 800,
        maxHeight: 500,
    },
};

const CreateNewTicketModal = (props) => {
    const {
        reasonList, setLoadingText, ucid, onResetReasonState, reasonModalVisibility, createdTicket,
    } = props;
    const history = useHistory();

    const metaInfo = useContext(MetaInfoContext);
    const {
        tickets: {
            reasonInfo: {
                enableReasonFlow,
            },
        },
    } = metaInfo;

    const dispatch = useDispatch();

    const {
        id: newTicketId, sourceUrl: newTicketSourceUrl,
    } = createdTicket || {};

    // local state variables
    const [ticketCreatedModal, setTicketCreatedModal] = useState(false);
    const [reasonErr, setReasonErr] = useState(false);
    const [reasonText, setReasonText] = useState('');
    const [isCopied, setIsCopied] = useState(false);

    const resetReasonState = () => {
        setReasonErr(false);
        setReasonText('');
        // eslint-disable-next-line no-unused-expressions
        onResetReasonState && onResetReasonState();
    };

    const callAction = (loaderText, params = {}) => new Promise((resolve, reject) => {
        try {
            setLoadingText(loaderText);
            dispatch(createTicketForCallAction({
                ...params,
                resolve,
            }));
        } catch (e) {
            reject(e);
        }
    });

    const onCopyLinkClick = (item) => () => {
        setIsCopied(true);
        copy(`${item}`); // need to pass it as string, else it won't copy

        setTimeout(() => {
            setIsCopied(false);
        }, 5000);
    };

    const handleReasonSubmit = () => {
        if (!reasonText && enableReasonFlow) {
            setReasonErr(true);
            return;
        }
        callAction('Creating ticket...', {
            ucid, reason: [reasonText], screenKey: ['createTicketForCall'], clearData: true,
        }).then(() => {
            setLoadingText('');
            setTicketCreatedModal(true);
        });
        resetReasonState();
    };

    useEffect(() => {
        if (!enableReasonFlow && reasonModalVisibility) {
            handleReasonSubmit();
        }
    }, [reasonModalVisibility]);

    return (
        <React.Fragment>
            {
                enableReasonFlow && (
                    <Modal
                        visible={reasonModalVisibility}
                        modalStyles={customModalStyles}
                        contentLabel='Please enter reason for creating new ticket'
                    >
                        <div className='defr-ua-modal-hd'>Please enter reason for creating new ticket</div>

                        <RadioButtonList
                            dataList={reasonList}
                            listName='new-ticket-reason-list'
                            setSelectedData={setReasonText}
                            extClasses={{
                                container: 'mb-60 of-auto',
                            }}
                        />

                        {reasonErr && <div className='err-label mb-15'>Please select an option from the above list</div>}

                        <div className='frccWrapper'>
                            <Button
                                v2
                                primary
                                label='Submit'
                                onClick={handleReasonSubmit}
                            />
                            <Button
                                v2
                                secondary
                                label='Cancel'
                                onClick={resetReasonState}
                                extClasses='ml-30'
                            />
                        </div>

                    </Modal>
                )
            }
            <Modal
                visible={ticketCreatedModal && createdTicket}
                contentLabel='Create Ticket Err Modal'
            >
                <div className='fccWrapper flex-justify-center'>
                    <div className='frcWrapper p-20'>
                        <div className='defr-new-ticket-pg-lb'>
                            {`New ticket has been created: ID - ${newTicketId}`}
                        </div>
                        <div role='presentation' onClick={onCopyLinkClick(newTicketId)}>
                            <img
                                className='defr-new-ticket-pg-icon'
                                src={!isCopied ? CopyIcon : CopiedIcon}
                                alt={!isCopied ? 'copy' : 'copied'}
                            />
                        </div>
                    </div>
                    <div className='frcWrapper p-20'>
                        <Button
                            v2
                            primary
                            label='Proceed'
                            onClick={() => {
                                dispatch(setTicketIdAction({ path: ['ticketId'], value: newTicketId }));
                                history.push({
                                    pathname: VIEW_SOP_USER_DETAILS_PATH(
                                        { ticketId: newTicketId },
                                    ),
                                });
                            }}
                            extClasses='defr-new-ticket-pg-primary-cta'
                        />
                        <a
                            href={newTicketSourceUrl}
                            target='_blank'
                            rel='noreferrer nofollow'
                            className='link-v2 defr-new-ticket-pg-link-cta'
                        >
                            View Ticket Summary
                        </a>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default memo(CreateNewTicketModal);

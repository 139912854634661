import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fireTransactionEvent } from './actions';
import { selectApp } from './selectors';
import { persistData, getPersistedData } from './TransactionTable/utils';
import { RudderEvent } from '../../rudderEvents';

export function useTransactionViewEvent() {
    const dispatch = useDispatch();
    const { agentInfo } = useSelector(selectApp, shallowEqual);

    const fireEvent = (eventName, payload) => {
        const { caseId, type } = payload;
        if (!caseId || !type) return null;

        const tvEventMatrices = getPersistedData('tv-matrices') || {};

        const traceId = tvEventMatrices['tv-trace-id'];

        if (!tvEventMatrices.caseId || (tvEventMatrices.caseId !== caseId)) {
            const eventTraceId = window.crypto.randomUUID();
            persistData('tv-matrices', {
                'tv-trace-id': eventTraceId,
                caseId,
            });
            tvEventMatrices['event-trace-id'] = eventTraceId;
        }

        dispatch(fireTransactionEvent({ eventName, agentMail: agentInfo.emailId, payload: { ...payload, 'event-trace-id ': traceId } }));
        return '';
    };

    return {
        fireTransactionViewEvents: fireEvent,
        events: RudderEvent.TransactionView,
    };
}

/* eslint-disable default-param-last */
/**
 *
 * Reducer for CX dispute flow
 *
 */

import { fromJS } from 'immutable';

import {
    GET_CX_DISPUTES_INFO, SET_CX_DISPUTES_INFO, ERR_CX_DISPUTES_INFO, UPDATE_CX_DISPUTES_INFO, UPDATE_SUCCESS_CX_DISPUTES,
    GET_CX_DISPUTES_INFO_FOR_ACTOR, SET_CX_DISPUTES_INFO_FOR_ACTOR, ERR_CX_DISPUTES_INFO_FOR_ACTOR, REDUCER_KEYS,
} from './constants';

const initialState = fromJS({
    disputesInfo: {},
    [REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR]: {},
    disputeDocument: {},
});

function disputesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CX_DISPUTES_INFO: {
            const { ticketId, payload: filters } = action.data;

            return state
                .setIn(['disputesInfo', ticketId, 'loading'], true)
                .setIn(['disputesInfo', ticketId, 'err'], '')
                .setIn(['disputesInfo', ticketId, 'filters'], fromJS(filters));
        }

        case SET_CX_DISPUTES_INFO: {
            const {
                ticketId, disputesData, prev, next,
            } = action.data;

            return state
                .setIn(['disputesInfo', ticketId, 'loading'], false)
                .setIn(['disputesInfo', ticketId, 'disputesData'], fromJS(disputesData))
                .setIn(['disputesInfo', ticketId, 'prev'], fromJS(prev))
                .setIn(['disputesInfo', ticketId, 'next'], fromJS(next));
        }

        case ERR_CX_DISPUTES_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['disputesInfo', ticketId, 'loading'], false)
                .setIn(['disputesInfo', ticketId, 'err'], err)
                .setIn(['disputesInfo', ticketId, 'disputesData'], fromJS([]))
                .setIn(['disputesInfo', ticketId, 'prev'], fromJS({}))
                .setIn(['disputesInfo', ticketId, 'next'], fromJS({}));
        }

        case UPDATE_CX_DISPUTES_INFO: {
            const { ticketId, payload, tableIndex } = action.data;
            const { disputeState, ticketId: formTicketId } = payload;

            // update dispute state or ticket ID based on what is passed in the payload
            const key = disputeState ? 'dispute_state' : 'ticket_id';
            const value = disputeState || formTicketId;

            return state
                .setIn(['disputesInfo', ticketId, 'loading'], true)
                .setIn(['disputesInfo', ticketId, 'err'], '')
                .setIn(['disputesInfo', ticketId, 'disputesData', tableIndex, key], value);
        }

        case UPDATE_SUCCESS_CX_DISPUTES: {
            const { ticketId } = action.data;

            return state
                .setIn(['disputesInfo', ticketId, 'loading'], false)
                .setIn(['disputesInfo', ticketId, 'err'], '');
        }

        case GET_CX_DISPUTES_INFO_FOR_ACTOR: {
            const { ticketId, payload: filters } = action.data;

            return state
                .setIn([REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId, 'loading'], true)
                .setIn([REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId, 'err'], '')
                .setIn([REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId, 'filters'], fromJS(filters));
        }

        case SET_CX_DISPUTES_INFO_FOR_ACTOR: {
            const {
                ticketId, disputesData, prev, next,
            } = action.data;

            return state

                .setIn([REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId, 'loading'], false)
                .setIn([REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId, 'disputesData'], fromJS(disputesData))
                .setIn([REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId, 'prev'], fromJS(prev))
                .setIn([REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId, 'next'], fromJS(next));
        }

        case ERR_CX_DISPUTES_INFO_FOR_ACTOR: {
            const { ticketId, err } = action.data;

            return state
                .setIn([REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId, 'loading'], false)
                .setIn([REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId, 'err'], err)
                .setIn([REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId, 'disputesData'], fromJS([]))
                .setIn([REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId, 'prev'], fromJS({}))
                .setIn([REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId, 'next'], fromJS({}));
        }

        default:
            return state;
    }
}

export default disputesReducer;

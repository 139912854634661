/**
 * @file WealthOps Reducer
 */

import { fromJS } from 'immutable';

import {
    GET_WEALTH_OPS_QUEUE, SET_WEALTH_OPS_QUEUE, ERR_WEALTH_OPS, VIEW_ALL_WEALTH_OPS_CASES, SET_WEALTH_OPS_PAYLOAD_TYPE,
    PAYLOAD_DD_OG_STATE, SET_WEALTH_OPS_CUR_VIEW, SET_WEALTH_OPS_CUR_CASE, SET_WEALTH_OPS_QUEUE_FILTERS,
    GET_WEALTH_OPS_LIVENESS_VIDEO, SET_WEALTH_OPS_LIVENESS_VIDEO, LIMIT_DD_OG_STATE, SET_WEALTH_OPS_PENDING_REVIEW_COUNT,
} from './constants';

const initialState = fromJS({
    casesList: [],
    curCase: {},
    livenessVideoUrl: '',
    curView: VIEW_ALL_WEALTH_OPS_CASES,
    payloadType: PAYLOAD_DD_OG_STATE,
    loading: false,
    pendingReviewCount: '',
    err: '',
    queueFilters: {
        limit: LIMIT_DD_OG_STATE,
        kycFilterType: '',
        kycFilterValue: '',
    },
});

function wealthOpsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_WEALTH_OPS_QUEUE: {
            const { casesList } = action.data;

            let modifiedState = state
                .set('loading', true)
                .set('err', '');

            if (casesList) modifiedState = modifiedState.set('casesList', fromJS(casesList));

            return modifiedState;
        }

        case SET_WEALTH_OPS_QUEUE: {
            const casesList = action.data;

            return state
                .set('loading', false)
                .set('casesList', fromJS(casesList));
        }

        case ERR_WEALTH_OPS: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err);
        }

        case SET_WEALTH_OPS_CUR_VIEW: {
            return state.set('curView', action.data);
        }

        case SET_WEALTH_OPS_CUR_CASE: {
            return state.set('curCase', fromJS(action.data));
        }

        case SET_WEALTH_OPS_PAYLOAD_TYPE: {
            return state
                .set('payloadType', fromJS(action.data));
        }

        case GET_WEALTH_OPS_LIVENESS_VIDEO: {
            return state
                .set('loading', true)
                .set('livenessVideoUrl', '')
                .set('err', '');
        }

        case SET_WEALTH_OPS_LIVENESS_VIDEO: {
            const { videoUrl } = action.data;

            return state
                .set('loading', false)
                .set('livenessVideoUrl', videoUrl);
        }

        case SET_WEALTH_OPS_QUEUE_FILTERS: {
            const { key, value } = action.data;

            return state
                .setIn(['queueFilters', key], fromJS(value));
        }

        case SET_WEALTH_OPS_PENDING_REVIEW_COUNT: {
            const { pendingReviewCount } = action.data;

            return state
                .setIn(['pendingReviewCount'], fromJS(pendingReviewCount));
        }

        default:
            return state;
    }
}

export default wealthOpsReducer;

/**
 *
 * Current Stage Onboarding Troubleshoot - Component to show the Wealth Current Stage Onboarding Troubleshoot Info
 *
 */

import React, { memo, useState } from 'react';
import Modal from 'react-modal';

import { Button } from '../../../components';
import { renderOnboardingCurrentStageTroubleshootInfo } from '../utils';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '12%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '32px',
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 800,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const CurrentStageOnboardingTroubleshoot = (props) => {
    const [showModal, setModal] = useState(false);
    const { data } = props;

    return (
        <React.Fragment>
            <div>
                <div className='table-sr table-sr--mt0 table-sr--mb20'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th f15Wrapper'>Current Stage</div>
                        <div className='table-sr__th f15Wrapper'>Status</div>
                        {/* <div className='table-sr__th f15Wrapper'>Updated At</div> */}
                        <div className='table-sr__th'>Troubleshoot</div>
                    </div>
                    <div className='table-sr__tlc wonb-onbtable'>
                        <div className='table-sr__tlw table-sr__tlw--aic' >
                            <div className='table-sr__tl f15Wrapper'>{data.stage}</div>
                            <div className='table-sr__tl table-sr__tl--nc f15Wrapper'>{data.state}</div>
                            {/* <div className='table-sr__tl table-sr__tl--nc f15Wrapper'>{data.updatedTs}</div> */}
                            <div
                                className='link table-sr__tl'
                                onClick={() => setModal(true)}
                            >
                                View Details
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={showModal}
                style={customStylesForModal}
                contentLabel='Current Stage Wealth Onboarding Troubleshoot Modal'
            >
                <div className='wonb-modal-hwr'>
                    Current Stage Wealth Onboarding Troubleshoot Details
                </div>
                <div className='wonb-modal-curr-stage'>
                    {
                        renderOnboardingCurrentStageTroubleshootInfo(data).map(item => (
                            <div className='wonb-modal-cwr wonb-modal-cwr--curr-stage' key={item.label}>
                                <div className='wonb-modal-cwr__cl wonb-modal-cwr__cl--v2'>{item.label}</div>
                                <div className='wonb-modal-cwr__sp'>:</div>
                                <div className='wonb-modal-cwr__cv'>
                                    {item.format === 'json' ? JSON.stringify(item.value, null, 4) : item.value}
                                </div>
                            </div>
                        ))
                    }
                </div>
                <Button
                    primary
                    extClasses='wonb-modal-bwr'
                    label='Close'
                    onClick={() => setModal(false)}
                />
            </Modal>
        </React.Fragment>
    );
};

export default memo(CurrentStageOnboardingTroubleshoot);

/*
 *
 * Waitlist constants
 *
 */

// freelancer
export const GET_FREELANCER_INFO = 'app/Waitlist/GET_FREELANCER_INFO';
export const SET_FREELANCER_INFO = 'app/Waitlist/SET_FREELANCER_INFO';
export const ERR_FREELANCER_INFO = 'app/Waitlist/ERR_FREELANCER_INFO';

export const UPDATE_FL_WL_STATUS = 'app/Waitlist/UPDATE_FL_WL_STATUS';
export const ERR_UPDATE_FL_WL_STATUS = 'app/Waitlist/ERR_UPDATE_FL_WL_STATUS';

export const GET_PENDING_STATUS_FL_LIST = 'app/Waitlist/GET_PENDING_STATUS_FL_LIST';
export const SET_PENDING_STATUS_FL_LIST = 'app/Waitlist/SET_PENDING_STATUS_FL_LIST';
export const ERR_PENDING_STATUS_FL_LIST = 'app/Waitlist/ERR_PENDING_STATUS_FL_LIST';

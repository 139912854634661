import {
    GET_USER_REFERRAL_INFO, GET_USER_REFERRER_INFO, GET_USER_REFEREES, GET_USER_REFEREE_INFO, GET_REFERRAL_META_INFO,
} from './constants';

export const getUserReferralInfo = (data) => ({
    type: GET_USER_REFERRAL_INFO,
    data,
});

export const getUserReferrerInfo = (data) => ({
    type: GET_USER_REFERRER_INFO,
    data,
});

export const getUserReferees = (data) => ({
    type: GET_USER_REFEREES,
    data,
});

export const getUserRefereeInfo = (data) => ({
    type: GET_USER_REFEREE_INFO,
    data,
});

export const getReferralMetaInfo = () => ({
    type: GET_REFERRAL_META_INFO,
});

/**
 * @file Manages the saga generator functions for Bulk Ticket Update component
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify,
} from '../../utils';
import {
    BULK_TICKET_JOB_ENDPOINT, BULK_TICKET_JOB_LIST_ENDPOINT, BULK_TICKET_JOB_FAILURE_INFO_ENDPOINT,
    BULK_TICKET_JOB_KILL_ENDPOINT,
} from '../../api/routes';

import {
    UPDATE_BULK_TICKETS_INFO, SET_UPDATE_BULK_TICKETS_INFO, ERR_UPDATE_BULK_TICKETS_INFO,
    GET_BULK_TICKET_JOB_LIST, SET_BULK_TICKET_JOB_LIST, ERR_BULK_TICKET_JOB_LIST,
    GET_BULK_TICKET_JOB_FAILURE_INFO, SET_BULK_TICKET_JOB_FAILURE_INFO, ERR_BULK_TICKET_JOB_FAILURE_INFO,
    KILL_BULK_TICKET_JOB, RESET_LOADER,
} from './constants';

function* updateBulkTicketsInfo(action) {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.put],
            BULK_TICKET_JOB_ENDPOINT,
            action.data,
        );

        yield put({ type: SET_UPDATE_BULK_TICKETS_INFO, data: response });
        // yield put({ type: RESET_UPLOAD_CSV_FORM_PARAMS });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_UPDATE_BULK_TICKETS_INFO });
    }
}

function* getBulkTicketJobList(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${BULK_TICKET_JOB_LIST_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_BULK_TICKET_JOB_LIST, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_BULK_TICKET_JOB_LIST, data: { err: e.message } });
    }
}

function* getBulkTicketJobFailureInfo(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${BULK_TICKET_JOB_FAILURE_INFO_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_BULK_TICKET_JOB_FAILURE_INFO, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_BULK_TICKET_JOB_FAILURE_INFO });
    }
}

function* killBulkTicketJob(action) {
    const { jobId, resolve } = action.data;
    const queryString = getQueryStringFromObject({ jobId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.delete],
            `${BULK_TICKET_JOB_KILL_ENDPOINT}?${queryString}`,
        );

        toastify(response.message, 'success');

        if (resolve) {
            yield call(resolve);
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
    }

    yield put({ type: RESET_LOADER });
}

export default function* bulkTicketUpdateSaga() {
    yield all(
        [
            yield takeLatest(UPDATE_BULK_TICKETS_INFO, updateBulkTicketsInfo),
            yield takeLatest(GET_BULK_TICKET_JOB_LIST, getBulkTicketJobList),
            yield takeLatest(GET_BULK_TICKET_JOB_FAILURE_INFO, getBulkTicketJobFailureInfo),
            yield takeLatest(KILL_BULK_TICKET_JOB, killBulkTicketJob),
        ]
    );
}

/**
 *
 * @file InputField - Component to render a specific type of input field based on the field data type
 *
 */

import React, { memo } from 'react';
import classNames from 'classnames';

import FormDropdown from '../../FormDropdown';
import FormWithSingleInput from '../../FormWithSingleInput';
import { COLUMN_FILTER_TYPES } from '../constants';

const FilterSelection = (props) => {
    const {
        filter, value, setValue, extClasses = {},
    } = props;

    const {
        filter_type: dataType, options_config: optionsConfig,
    } = filter || {};

    switch (dataType) {
        case COLUMN_FILTER_TYPES.text: {
            return (
                <FormWithSingleInput
                    disableFormSubmitOnEnter
                    input={{
                        value,
                        onChange: (e) => setValue(e.target.value),
                        placeholder: 'Enter...',
                    }}
                    hideErrLabel
                    extClasses={{
                        container: 'p-5 table-filter-dropdown-input table-filter-dropdown-input-cont',
                        wrapper: 'table-filter-dropdown-input-cont',
                        inputField: 'table-filter-dropdown-input-cont',
                        ...extClasses,
                    }}
                />
            );
        }
        case COLUMN_FILTER_TYPES.date:
        case COLUMN_FILTER_TYPES.dateAndTime: {
            return (
                <div className='frcWrapper table-filter-dropdown-input'>
                    <FormWithSingleInput
                        disableFormSubmitOnEnter
                        formType='dob'
                        input={{
                            showTimeInDateInput: dataType === COLUMN_FILTER_TYPES.dateAndTime,
                            value: value?.min,
                            onChange: (newValue) => setValue({
                                ...value,
                                min: newValue,
                            }),
                            placeholder: 'Select...',
                        }}
                        hideErrLabel
                        extClasses={{
                            container: 'p-5 table-filter-dropdown-input-cont',
                            wrapper: 'table-filter-dropdown-input-cont',
                            inputField: 'table-filter-dropdown-input-cont',
                            ...extClasses,
                        }}
                    />
                    -
                    <FormWithSingleInput
                        disableFormSubmitOnEnter
                        formType='dob'
                        input={{
                            showTimeInDateInput: dataType === COLUMN_FILTER_TYPES.dateAndTime,
                            value: value?.max,
                            onChange: (newValue) => setValue({
                                ...value,
                                max: newValue,
                            }),
                            placeholder: 'Select...',
                        }}
                        hideErrLabel
                        extClasses={{
                            container: 'p-5 table-filter-dropdown-input-cont',
                            wrapper: 'table-filter-dropdown-input-cont',
                            inputField: 'table-filter-dropdown-input-cont',
                            ...extClasses,
                        }}
                    />
                </div>
            );
        }
        case COLUMN_FILTER_TYPES.number: {
            return (
                <div className='frcWrapper table-filter-dropdown-input'>
                    <div className='p-5'>
                        <input
                            type='number'
                            className={classNames('table-filter-dropdown-input-cont', extClasses)}
                            value={value?.min}
                            onChange={(e) => setValue({
                                ...value,
                                min: e.target.value,
                            })}
                            placeholder='Enter...'
                        />
                    </div>
                    -
                    <div className='p-5'>
                        <input
                            type='number'
                            className={classNames('p-5', 'table-filter-dropdown-input-cont', extClasses)}
                            value={value?.max}
                            onChange={(e) => setValue({
                                ...value,
                                max: e.target.value,
                            })}
                            placeholder='Enter...'
                        />
                    </div>
                </div>
            );
        }
        case COLUMN_FILTER_TYPES.select: {
            return (
                <FormDropdown
                    extStyles={{
                        container: 'p-5 table-filter-dropdown-input',
                        inputField: 'table-filter-dropdown-input',
                        ...extClasses,
                    }}
                    extCustomStyles={{
                        control: {
                            maxWidth: '500px',
                            minWidth: '100%',
                            width: 'max-content',
                        },
                    }}
                    options={filter[optionsConfig]?.select_options}
                    input={{
                        value,
                        defaultMenuIsOpen: true,
                        onChange: (newValue) => setValue(newValue),
                        placeholder: 'Choose...',
                    }}
                    cacheKey='enum-field-value-selection'
                />
            );
        }
        case COLUMN_FILTER_TYPES.multiSelect: {
            return (
                <FormDropdown
                    isMulti
                    options={filter[optionsConfig]?.select_options}
                    extStyles={{
                        container: 'p-5 table-filter-dropdown-input',
                        inputField: 'table-filter-dropdown-input',
                        ...extClasses,
                    }}
                    extCustomStyles={{
                        control: {
                            maxWidth: '500px',
                            minWidth: '100%',
                            width: 'max-content',
                        },
                    }}
                    input={{
                        value,
                        defaultMenuIsOpen: true,
                        onChange: (newValue) => setValue(newValue),
                        placeholder: 'Choose...',
                    }}
                    cacheKey='enum-field-value-selection'
                />
            );
        }
        default: {
            return <div className='sop-text-info'>Field data type is not supported</div>;
        }
    }
};

export default memo(FilterSelection);

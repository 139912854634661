/**
 *
 * RewardsView - Component to show the active rewards for all users
 *
 */

import React, { useState } from 'react';

import { Button, Table } from '../../../components';

import MarkAgainstTicketCheckbox from '../MarkAgainstTicketCheckbox';
import TncAndStepsToRedeemModal from '../Modals/TncAndStepsToRedeem';

import { ACTIVE_REWARDS_LABEL_DATA } from '../constants';

const RewardsView = (props) => {
    // local state variables
    const [selectedRewardDetails, setSelectedRewardDetails] = useState({});
    const [showRewardDetailsModal, setRewardDetailsModal] = useState(false);

    const {
        data, prev, next, getPaginatedData,
    } = props;

    const renderActions = (item) => (
        <div className='frccWrapper'>
            <Button
                link
                label='View TnC & Steps to Redeem'
                onClick={() => {
                    setSelectedRewardDetails(item);
                    setRewardDetailsModal(true);
                }}
            />
        </div>
    );

    const renderCheckboxColumn = (item) => {
        const { entityMeta: itemEntityMeta } = item;

        return <MarkAgainstTicketCheckbox isActiveView entityMeta={itemEntityMeta} />;
    };

    return (
        <React.Fragment>
            <Table
                labelData={ACTIVE_REWARDS_LABEL_DATA}
                contentData={data}
                renderCheckboxColumn={renderCheckboxColumn}
                renderActionableColumn={renderActions}
                prevToken={prev}
                nextToken={next}
                paginatedClickHandler={getPaginatedData}
                extClasses={{
                    container: 'rewards-src2',
                    actionableColumn: 'f15Wrapper',
                }}
            />
            <TncAndStepsToRedeemModal
                v2
                visible={showRewardDetailsModal}
                data={selectedRewardDetails}
                toggleHandler={setRewardDetailsModal}
            />
        </React.Fragment>
    );
};

export default RewardsView;

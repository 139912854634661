import { fromJS } from 'immutable';

import {
    GET_MESSAGE_LIST, SET_MESSAGE_LIST, ERR_MESSAGE_LIST, GET_MESSAGE_INFO, SET_MESSAGE_INFO,
    ERR_MESSAGE_INFO, CLOSE_INFO_MODAL
} from './constants';

const initialState = fromJS({
    messageList: {},
});

function communicationsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MESSAGE_LIST:
        case GET_MESSAGE_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['messageList', ticketId, 'loading'], true)
                .setIn(['messageList', ticketId, 'listErr'], '')
                .setIn(['messageList', ticketId, 'infoErr'], '');
        }

        case SET_MESSAGE_LIST: {
            const { ticketId, messageList, prev, next } = action.data;

            return state
                .setIn(['messageList', ticketId, 'loading'], false)
                .setIn(['messageList', ticketId, 'data'], fromJS(messageList))
                .setIn(['messageList', ticketId, 'prev'], fromJS(prev))
                .setIn(['messageList', ticketId, 'next'], fromJS(next));
        }
        
        case ERR_MESSAGE_LIST: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['messageList', ticketId, 'loading'], false)
                .setIn(['messageList', ticketId, 'listErr'], err)
                .setIn(['messageList', ticketId, 'data'], fromJS([]))
                .setIn(['messageList', ticketId, 'prev'], fromJS({}))
                .setIn(['messageList', ticketId, 'next'], fromJS({}));
        }

        case SET_MESSAGE_INFO: {
            const { ticketId, smsVendorInfo, messageInfo } = action.data;

            return state
                .setIn(['messageList', ticketId, 'loading'], false)
                .setIn(['messageList', ticketId, 'selectedMsgData'], fromJS({ smsVendorInfo, messageInfo }))
                .setIn(['messageList', ticketId, 'showInfoModal'], true);
        }
        
        case ERR_MESSAGE_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['messageList', ticketId, 'loading'], false)
                .setIn(['messageList', ticketId, 'infoErr'], err);
        }

        case CLOSE_INFO_MODAL: {
            const { ticketId } = action.data;

            return state.setIn(['messageList', ticketId, 'showInfoModal'], false);
        }

        default:
            return state;
    }
}

export default communicationsReducer;

import {
    INSTANT_INDEXING_UPDATE_URL_PATH, INSTANT_INDEXING_URL_STATUS_PATH,
} from '../App/routes';

export const getSideNavbarItems = () => [
    {
        id: 'instant-indexing',
        label: 'Instant Indexing',
        nestedLinks: [
            {
                id: 'ii-update-url',
                label: 'Update URL',
                path: INSTANT_INDEXING_UPDATE_URL_PATH,
            },
            {
                id: 'ii-url-status',
                label: 'URL Status',
                path: INSTANT_INDEXING_URL_STATUS_PATH,
            },
        ],
    },
];

/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/**
 * @file UserRiskReview saga
 */

import {
    all, call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';

import { RISK_OPS_AFU_HISTORY_ENDPOINT, RISK_OPS_ROOT, RISK_OPS_SCREENER_CHECK_DETAILS_ENDPOINT, RISK_OPS_USER_RISK_REVIEW_ENDPOINT } from '../../api/routes';
import {
    clientApiWrapper, getErrLabel, getErrMsg, getQueryStringFromObject, toastify,
} from '../../utils';
import { getBase64ImgFromImgPath, getVideoFromActorAndReqIdSaga } from '../RiskOps/saga';

import {
    ERROR_USER_RISK_REVIEW, GET_MEDIA_FOR_USER_RISK_REVIEW, GET_USER_RISK_REVIEW_DATA,
    HIDE_LOADER_USER_RISK_REVIEW, MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO, SET_USER_RISK_REVIEW_DATA,
    GET_FORM_FIELDS, MARK_ACTION_FORM, SET_FORM_FIELDS, SUBMIT_ANNOTATION, ERR_SUBMIT_ANNOTATION,
    MARK_LIVENESS_PASSED, MARK_FACEMATCH_PASSED, REVIEW_ENTITY_TYPE_LIVENESS, REVIEW_ENTITY_TYPE_FACEMATCH,
    GET_USER_RISK_SCREENER_CHECK_DETAILS,
    SET_USER_RISK_SCREENER_CHECK_DETAILS,
    ERR_USER_RISK_SCREENER_CHECK_DETAILS,
    GET_LINKED_IN_DETAILS, SET_LINKED_IN_URL,
} from './constants';
import { geSubmitFormConfig } from './utils';

function* getUserRiskDataSaga(action) {
    const {
        actorId, reviewReasons, caseId, isAFU,
    } = action.data;

    const ENDPOINT = isAFU ? RISK_OPS_AFU_HISTORY_ENDPOINT : RISK_OPS_USER_RISK_REVIEW_ENDPOINT;
    const queryReq = {
        caseId,
    };

    if (!caseId && actorId) {
        queryReq.actorId = actorId;
    }

    if (Array.isArray(reviewReasons) && reviewReasons.length > 0) {
        queryReq.reviewReasons = reviewReasons.join(',');
    }

    const queryString = getQueryStringFromObject(queryReq, true);

    try {
        const { reviewSections } = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_USER_RISK_REVIEW_DATA, data: { reviewSections, actorId, isAFU } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERROR_USER_RISK_REVIEW, data: { err: getErrMsg(e) } });
    } finally {
        yield put({ type: HIDE_LOADER_USER_RISK_REVIEW });
    }
}

function* getMediaForUserRiskReviewSaga(action) {
    const { mediaInfo, setMediaSrc } = action.data;

    try {
        const {
            media_type: mediaType,
            image_path: imagePath,
            base64_kyc_image_data: base64Img,
            actor_id: actorId,
            request_id: requestId,
        } = mediaInfo;

        let mediaSrc = '';

        // if media type is video & there is no actor id or request id, return
        if (mediaType === MEDIA_TYPE_VIDEO) {
            if (!actorId || !requestId) return;

            mediaSrc = yield call(getVideoFromActorAndReqIdSaga, { data: { actorId, requestId } });
        }

        // if media type is image and there is no base64 string and also there is no image path, return
        if (mediaType === MEDIA_TYPE_IMAGE) {
            if (!base64Img && !imagePath) return;

            mediaSrc = yield call(getBase64ImgFromImgPath, { data: { imagePath } });
        }

        setMediaSrc(mediaSrc);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERROR_USER_RISK_REVIEW, data: { err: getErrMsg(e) } });
    } finally {
        yield put({ type: HIDE_LOADER_USER_RISK_REVIEW });
    }
}

function* getScreenerCheckDetailsSaga(action) {
    const { resultId } = action.data;

    const queryReq = {
        result_id: resultId,
    };

    const queryString = getQueryStringFromObject(queryReq);
    try {
        const caseDetails = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_SCREENER_CHECK_DETAILS_ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_USER_RISK_SCREENER_CHECK_DETAILS, data: { data: caseDetails, resultId } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_RISK_SCREENER_CHECK_DETAILS, data: { resultId, error: getErrMsg(e) } });
    } finally {
        yield put({ type: HIDE_LOADER_USER_RISK_REVIEW });
    }
}

function* getFormFieldsSaga(action) {
    const { actorId, requestId, emailId } = action.data;
    const actionForm = {};

    for (let i = 0; i < MARK_ACTION_FORM?.length; i += 1) {
        const form = MARK_ACTION_FORM[i];

        for (let j = 0; j < form.parameterList?.length; j += 1) {
            const param = form.parameterList[j];

            if (param.name === 'actor_id') {
                param.value = actorId;
                param.isDisabled = true;
            } else if (param.name === 'request_id') {
                param.value = requestId;
                param.isDisabled = true;
            } else if (param.name === 'reviewed_by') {
                param.value = emailId;
                param.isDisabled = true;
            }

            if (param?.annotationType) {
                let queryString = null;
                if (form.id === MARK_LIVENESS_PASSED) {
                    queryString = getQueryStringFromObject({
                        entityType: REVIEW_ENTITY_TYPE_LIVENESS,
                        annotationType: {
                            liveness_annotation_type: param?.annotationType,
                        },
                    });
                } else if (form.id === MARK_FACEMATCH_PASSED) {
                    queryString = getQueryStringFromObject({
                        entityType: REVIEW_ENTITY_TYPE_FACEMATCH,
                        annotationType: {
                            face_match_annotation_type: param?.annotationType,
                        },
                    });
                }

                const options = yield call(
                    [clientApiWrapper, clientApiWrapper.get],
                    `${RISK_OPS_ROOT}/get-allowed-annotation?${queryString}`,
                );

                if (param.type === 9) {
                    param.options = options?.map((item) => ({
                        label: item?.value,
                        value: item?.id,
                    }));
                } else {
                    param.options = options;
                }
            }
        }

        actionForm[MARK_ACTION_FORM[i].id] = MARK_ACTION_FORM[i];
    }

    yield put({
        type: SET_FORM_FIELDS,
        data: actionForm,
    });
}

function* submitAnnotationSaga(action) {
    const {
        submitForm, requestId, emailId, caseId, type,
    } = action.data;

    const finalSubmitionForm = geSubmitFormConfig(submitForm);

    try {
        for (let i = 0; i < finalSubmitionForm.length; i += 1) {
            const form = finalSubmitionForm[i];

            const commentType = {};
            let entityType = null;

            if (type === MARK_LIVENESS_PASSED) {
                entityType = REVIEW_ENTITY_TYPE_LIVENESS;
                commentType.liveness_comment_type = 'LIVENESS_COMMENT_TYPE_ALL';
            } else if (type === MARK_FACEMATCH_PASSED) {
                entityType = REVIEW_ENTITY_TYPE_FACEMATCH;
                commentType.facematch_comment_type = 'FACEMATCH_COMMENT_TYPE_REMARKS';
            }

            if (form?.extras) {
                yield call(
                    [clientApiWrapper, clientApiWrapper.post],
                    `${RISK_OPS_ROOT}/create-annotation`,
                    {
                        caseId,
                        entityType,
                        entityId: requestId,
                        allowedAnnotationId: form?.extras?.id,
                        addedByEmail: emailId,
                        value: form?.extras?.value,
                    },
                );
            } else {
                yield call(
                    [clientApiWrapper, clientApiWrapper.post],
                    `${RISK_OPS_ROOT}/create-comments`,
                    {
                        entityType,
                        entityId: requestId,
                        comment: form?.value,
                        addedByEmail: emailId,
                        commentType,
                        caseId,
                    },
                );
            }
        }
        toastify('Action has been performed successfully', 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_SUBMIT_ANNOTATION, data: { err: getErrMsg(e) } });
    }
}

function* getLinkedInDetailsSaga(action) {
    try {
        yield put({ type: SET_LINKED_IN_URL, data: { loading: true } });
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            '/api/v1/risk-ops/linkedin',
            action.data,
        );
        const { smtp } = action.data;
        yield put({ type: SET_LINKED_IN_URL, data: { mail: smtp, linkedInUrl: response.url, loading: false } });
    } catch (error) {
        yield put({ type: SET_LINKED_IN_URL, data: {} });
        toastify(error.description || error.message || 'Profile Not found', 'error');
    }
}

export default function* wealthOpsSaga() {
    yield all(
        [
            yield takeLatest(GET_USER_RISK_REVIEW_DATA, getUserRiskDataSaga),
            yield takeEvery(GET_MEDIA_FOR_USER_RISK_REVIEW, getMediaForUserRiskReviewSaga),
            yield takeEvery(GET_FORM_FIELDS, getFormFieldsSaga),
            yield takeEvery(SUBMIT_ANNOTATION, submitAnnotationSaga),
            yield takeLatest(GET_USER_RISK_SCREENER_CHECK_DETAILS, getScreenerCheckDetailsSaga),
            yield takeLatest(GET_LINKED_IN_DETAILS, getLinkedInDetailsSaga),
        ],
    );
}

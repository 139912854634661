/* eslint-disable react-hooks/exhaustive-deps */

/**
 *@file Transaction Risk page
 * routing of the Transaction Risk Screens
 */

import React, { useMemo, useEffect, useState, useContext } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
    Redirect, Route, Switch, useLocation, useHistory,
} from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'redux';

import { VerticalNavbar, HorizontalIdNavbar } from '../../components';
import { MetaInfoContext } from '../../context';
import { isDevEnviroment, getInitialDateRange, injectReducer, injectSaga } from '../../utils';

import {
    selectTransactionCaseDetails,
} from '../TransactionCaseDetails/selectors';
// eslint-disable-next-line import/no-cycle
import { makeSelectAccessLevel } from '../App/selectors';
import { selectTransactionViewV2Details, selectApp } from '../TransactionViewV2/selectors';
import {
    RISK_OPS_TXN_CASE_DETAILS_PATH, RISK_OPS_TXN_VIEW_PATH,
    RISK_OPS_TXN_ANNOTATION_PATH, RISK_OPS_TXN_PATH, RISK_OPS_TXN_HISTORY_PATH,
    RISK_OPS_USER_PATH, RISK_OPS_USER_CASE_DETAILS_PATH, RISK_OPS_USER_VIEW_PATH,
    RISK_OPS_USER_CASE_SUBMIT_ACTIONS_PATH,
    RISK_OPS_CASES_CASE_DETAILS_PATH,
    RISK_OPS_CASES_PATH,
    RISK_OPS_USER_AFU_HISTORY_PATH,
    RISK_OPS_USER_PRODUCTS_PATH,
    RISK_OPS_TXN_USER_VIEW_PATH,
    RISK_OPS_CASES_USER_VIEW_PATH,
    RISK_OPS_CASES_TXN_VIEW_PATH,
    RISK_OPS_CASES_USER_RESPONSE,
    RISK_OPS_TXN_USER_RESPONSE,
    RISK_OPS_USER_USER_RESPONSE,
    RISK_OPS_USER_RISK_ACTIVITY,
    RISK_OPS_TXN_RISK_ACTIVITY,
    RISK_OPS_CASES_RISK_ACTIVITY,
} from '../App/routes';

import TransactionView from '../TransactionView';
import TransactionCaseDetails from '../TransactionCaseDetails';
import TransactionQueue from '../TransactionQueue';
import UserRiskReview from '../UserRiskReview';
import TransactionViewV2 from '../TransactionViewV2';
import UserProducts from '../UserProducts';
import UserResponse from '../UserResponse';

// eslint-disable-next-line import/no-cycle
import RiskSubmitAction from './RiskSubmitAction';
// eslint-disable-next-line import/no-cycle
import {
    getMenuStructure,
} from './utils';
import {
    TXN_LIST_VIEW, TXN_DETAIL_VIEW, USER_DETAIL_VIEW, USER_LIST_VIEW, CASE_DETAIL_VIEW,
} from './constants';
import './style.scss';

import { getFromDateBasedOnPeriod, getStartOrEndTime, convertMillisecondsToTimestamp } from '../TransactionViewV2/utils';
import { defaultPeriod, TRANSACTION_VIEW_V2_KEY } from '../TransactionViewV2/constants';
import { getTransactionViewV2Action, setFilterField, resetTransactionViewV2 } from '../TransactionViewV2/actions';
import reducer from '../TransactionViewV2/reducer';
import saga from '../TransactionViewV2/saga';
import riskActivityReducer from '../RiskRecentActivity/reducer';
import riskActivitySaga from '../RiskRecentActivity/saga';
import RiskRecentActivity from '../RiskRecentActivity';
import transactionsReducer from '../Transactions/reducer';
import transactionsSaga from '../Transactions/saga';
import OutlookCredentialsBanner from './OutlookCredentialsBanner';

const TransactionRisk = () => {
    const location = useLocation();

    const history = useHistory();

    const transactionCaseDetails = useSelector(selectTransactionCaseDetails, shallowEqual);
    const selectedAccessLevel = useSelector(makeSelectAccessLevel(), shallowEqual);

    const transactionData = useSelector(selectTransactionViewV2Details, shallowEqual);
    const actorId = useMemo(() => transactionCaseDetails?.caseDetails?.find((item) => item?.actor_id)?.value, [transactionCaseDetails]);

    const [caseId, setCaseId] = useState(location?.state?.caseId);
    const [caseInfo, setCaseInfo] = useState(location?.state?.caseInfo);
    const { agentInfo } = useSelector(selectApp, shallowEqual);

    const handleItemCloseIconClick = () => {
        history.goBack();
    };

    const metaInfo = useContext(MetaInfoContext);

    const dispatch = useDispatch();

    const {
        componentVersions: {
            banners: bannersVersion,
        },
        riskOps: {
            transactionViewVersion,
        },
    } = metaInfo;

    const getNavItems = useMemo(() => {
        let menuType = TXN_LIST_VIEW;
        const locationPath = location?.pathname;

        if (locationPath?.includes(RISK_OPS_TXN_PATH)) {
            menuType = caseId ? TXN_DETAIL_VIEW : TXN_LIST_VIEW;
        } else if (locationPath?.includes(RISK_OPS_USER_PATH)) {
            menuType = caseId ? USER_DETAIL_VIEW : USER_LIST_VIEW;
        } else if (locationPath?.includes(RISK_OPS_CASES_PATH)) {
            menuType = caseId ? CASE_DETAIL_VIEW : null;
        }

        return getMenuStructure(metaInfo, menuType, selectedAccessLevel);
    }, [caseId, metaInfo, selectedAccessLevel]);

    useEffect(() => {
        if (location?.state?.caseId) {
            setCaseId(location?.state?.caseId);
            setCaseInfo(location?.state?.caseInfo);
        } else if (location?.pathname === RISK_OPS_TXN_PATH || location?.pathname === (RISK_OPS_USER_PATH)) {
            setCaseId(null);
            setCaseInfo(null);
        }
    }, [location?.pathname]);

    const getTransactionDetails = () => {
        const defaultFromDate = getFromDateBasedOnPeriod(defaultPeriod.value);

        const { fromDate, toDate } = getInitialDateRange(null, true, {
            defaultFromDate,
        });

        const fromTime = getStartOrEndTime(fromDate.value);
        // set hours to end of the day in milliseonds
        const toTime = getStartOrEndTime(toDate.value, 'end');

        dispatch(getTransactionViewV2Action({
            payload: {
                actor_id: actorId,
                from_time: convertMillisecondsToTimestamp(fromTime),
                to_time: convertMillisecondsToTimestamp(toTime),
            },
            eventPayload: {
                agentMail: agentInfo.emailId,
                caseId,
            },
        }));

        dispatch(setFilterField({
            fromDate,
            toDate,
        }));
    };

    useEffect(() => {
        if (actorId && Array.isArray(transactionData) && transactionData.length === 0) getTransactionDetails();
        return () => {
            dispatch(resetTransactionViewV2());
        };
    }, [actorId]);

    return (
        <div className={classNames(
            { 'app-cr': bannersVersion === 1, 'devs-v2-cr': bannersVersion === 2 },
            isDevEnviroment() ? 'bg-np' : 'bg',
        )}
        >
            <VerticalNavbar
                isReplaceLink
                enableExpandableNav
                navItems={getNavItems}
            />
            <div className={classNames({
                'app-cc': bannersVersion === 1,
                'app-cc-v3': bannersVersion === 2,
                'layout-container--mt-92 layout-container--clr-wild-sand': true,
            })}
            >
                {(caseId) ? (
                    <HorizontalIdNavbar
                        isFixed
                        hasSingleItem
                        isNewTicketActive={false}
                        activeId={caseId}
                        navItems={[{ id: caseId }]}
                        onItemCloseIconClick={handleItemCloseIconClick}
                        onBackHandleClick={handleItemCloseIconClick}
                    />
                ) : null}
                <div className='transaction-risk-body'>
                    <OutlookCredentialsBanner />
                    <div>
                        <Switch>
                            <Route
                                path={RISK_OPS_TXN_PATH}
                                exact
                                render={() => <TransactionQueue listType='riskTxnQueue' />}
                            />

                            <Route
                                path={RISK_OPS_TXN_HISTORY_PATH}
                                exact
                                render={() => <TransactionQueue listType='history' />}
                            />

                            <Route
                                path={RISK_OPS_USER_PATH}
                                exact
                                render={() => <TransactionQueue listType='userRisk' />}
                            />
                            {/** transction queue transction view */}
                            <Route
                                exact
                                path={RISK_OPS_TXN_VIEW_PATH}
                                render={() => (transactionViewVersion === 1
                                    ? <TransactionView caseId={caseId} />
                                    : <TransactionViewV2 actorId={actorId} />)}
                            />
                            {/** case search transction view */}
                            <Route
                                exact
                                path={RISK_OPS_CASES_TXN_VIEW_PATH}
                                render={() => (transactionViewVersion === 1
                                    ? <TransactionView caseId={caseId} />
                                    : <TransactionViewV2 actorId={actorId} />)}
                            />

                            <Route
                                exact
                                path={RISK_OPS_TXN_ANNOTATION_PATH}
                                render={() => <RiskSubmitAction caseId={caseId} caseInfo={caseInfo} updateCaseId={setCaseId} />}
                            />

                            <Route
                                exact
                                path={RISK_OPS_TXN_CASE_DETAILS_PATH}
                                render={() => <TransactionCaseDetails caseId={caseId} />}
                            />

                            <Route
                                exact
                                path={RISK_OPS_USER_CASE_DETAILS_PATH}
                                render={() => <TransactionCaseDetails caseId={caseId} />}
                            />
                            <Route
                                exact
                                path={RISK_OPS_CASES_CASE_DETAILS_PATH}
                                render={() => <TransactionCaseDetails caseId={caseId} />}
                            />
                            {/** key queue user view */}
                            <Route
                                exact
                                path={RISK_OPS_USER_VIEW_PATH}
                                render={() => (
                                    <UserRiskReview
                                        caseId={caseId}
                                        actorId={actorId}
                                    />
                                )}
                            />
                            {/** Transaction queue user view */}
                            <Route
                                exact
                                path={RISK_OPS_TXN_USER_VIEW_PATH}
                                render={() => (
                                    <UserRiskReview
                                        caseId={caseId}
                                        actorId={actorId}
                                    />
                                )}
                            />
                            {/** Case search user view  */}
                            <Route
                                exact
                                path={RISK_OPS_CASES_USER_VIEW_PATH}
                                render={() => (
                                    <UserRiskReview
                                        caseId={caseId}
                                        actorId={actorId}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path={RISK_OPS_CASES_USER_RESPONSE}
                                render={() => (
                                    <UserResponse />
                                )}
                            />

                            <Route
                                exact
                                path={RISK_OPS_TXN_USER_RESPONSE}
                                render={() => (
                                    <UserResponse />
                                )}
                            />

                            <Route
                                exact
                                path={RISK_OPS_USER_USER_RESPONSE}
                                render={() => (
                                    <UserResponse />
                                )}
                            />

                            {/** AFU History is using UserRiskReview Component.
                     * In RiskReview Component isAFU flag is used to differentiate User View and AFU History */}
                            <Route
                                exact
                                path={RISK_OPS_USER_AFU_HISTORY_PATH}
                                render={() => (
                                    <UserRiskReview
                                        caseId={caseId}
                                        actorId={actorId}
                                    />
                                )}
                            />
                            {/** User Products */}
                            <Route
                                exact
                                path={RISK_OPS_USER_PRODUCTS_PATH}
                                render={() => (
                                    <UserProducts
                                        caseId={caseId}
                                        actorId={actorId}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path={RISK_OPS_USER_CASE_SUBMIT_ACTIONS_PATH}
                                render={() => (
                                    <RiskSubmitAction
                                        caseId={caseId}
                                        caseInfo={caseInfo}
                                        updateCaseId={setCaseId}
                                        autoActionSelection={false}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path={RISK_OPS_USER_RISK_ACTIVITY}
                                render={() => <RiskRecentActivity caseId={caseId} />}
                            />

                            <Route
                                exact
                                path={RISK_OPS_TXN_RISK_ACTIVITY}
                                render={() => <RiskRecentActivity caseId={caseId} />}
                            />

                            <Route
                                exact
                                path={RISK_OPS_CASES_RISK_ACTIVITY}
                                render={() => <RiskRecentActivity caseId={caseId} />}
                            />

                            {/* in case of unmatched url, redirect the user transaction case detail page */}
                            <Redirect to={{ pathname: RISK_OPS_TXN_PATH }} />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
};

const withReducer = injectReducer({ key: TRANSACTION_VIEW_V2_KEY, reducer });
const withSaga = injectSaga({ key: TRANSACTION_VIEW_V2_KEY, saga });

const withRiskActivityReducer = injectReducer({ key: 'riskActivityReducer', reducer: riskActivityReducer });
const withRiskActivitySaga = injectSaga({ key: 'riskActivityReducer', saga: riskActivitySaga });

const transactionReducer = injectReducer({ key: 'transactions', reducer: transactionsReducer });
const transactionSaga = injectSaga({ key: 'transactions', saga: transactionsSaga });

export default compose(withReducer, withSaga, withRiskActivityReducer, withRiskActivitySaga, transactionReducer, transactionSaga)(TransactionRisk);

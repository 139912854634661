/**
 *
 * ExchangerOffersView - Component to show the active exchanger offers for all users
 *
 */

import React, { useState } from 'react';

import { Button, TableV2 } from '../../../components';

import MarkAgainstTicketCheckbox from '../MarkAgainstTicketCheckbox';
import TncAndStepsToRedeemModal from '../Modals/TncAndStepsToRedeem';

import { ACTIVE_EXCHANGER_OFFERS_LABEL_DATA } from '../constants';

const ExchangerOffersView = (props) => {
    // local state variables
    const [selectedExchangerOfferDetails, setSelectedExchangerOfferDetails] = useState({});
    const [showExchangerOfferDetailsModal, setExchangerOfferDetailsModal] = useState(false);

    const {
        data, prev, next, getPaginatedData, loading,
    } = props;

    const renderActions = (item) => (
        <div className='frccWrapper' style={{ height: '100%' }}>
            <Button
                link
                label='View TnC Details'
                onClick={() => {
                    setSelectedExchangerOfferDetails(item);
                    setExchangerOfferDetailsModal(true);
                }}
            />
        </div>
    );

    const renderCheckboxColumn = (checkboxProps) => {
        const {
            checked: itemEntityMeta, onChange, tabIndex, disabled,
        } = checkboxProps;

        return (
            <MarkAgainstTicketCheckbox
                isActiveView
                entityMeta={itemEntityMeta}
                onChange={onChange}
                tabIndex={tabIndex}
                disabled={disabled}
            />
        );
    };

    return (
        <React.Fragment>
            <TableV2
                renderCheckbox
                columns={ACTIVE_EXCHANGER_OFFERS_LABEL_DATA}
                rows={data}
                rowClass={() => 'rewards-tblv2-row'}
                prevToken={prev}
                nextToken={next}
                paginatedClickHandler={getPaginatedData}
                renderActionableColumn={renderActions}
                loading={loading}
                renderers={{
                    renderCheckbox: renderCheckboxColumn,
                }}
                enableVirtualization={false}
                extClasses={{
                    container: 'rewards-src4',
                    content: 'rewards-tblv2',
                }}
            />
            <TncAndStepsToRedeemModal
                visible={showExchangerOfferDetailsModal}
                data={selectedExchangerOfferDetails}
                toggleHandler={setExchangerOfferDetailsModal}
            />
        </React.Fragment>
    );
};

export default ExchangerOffersView;

/**
 *
 * ClaimedExchangerOffersView - Component to show the claimed exchanger offers list by current user
 *
 */

import React, { useState } from 'react';

import { Button, Modal, Table } from '../../../components';

import MarkAgainstTicketCheckbox from '../MarkAgainstTicketCheckbox';

import { CLAIMED_EXCHANGER_OFFERS_LABEL_DATA } from '../constants';

const modalStyles = {
    content: {
        maxHeight: 650,
        top: '10%',
    },
};

const ClaimedExchangerOffersView = (props) => {
    // local state variables
    const [additionalDetails, setAdditionalDetails] = useState({});
    const [showAdditionalDetailsModal, setAdditionalDetailsModal] = useState(false);

    const {
        data, prev, next, getPaginatedData,
    } = props;

    const renderActions = (item) => {
        return (
            <div className='frccWrapper'>
                <Button
                    link
                    label='View Details'
                    onClick={() => {
                        setAdditionalDetails(item.additionalInfo);
                        setAdditionalDetailsModal(true);
                    }}
                />
            </div>
        );
    };

    const renderCheckboxColumn = (item) => {
        const { entityMeta: itemEntityMeta } = item;

        return <MarkAgainstTicketCheckbox isClaimsView entityMeta={itemEntityMeta} />;
    };

    return (
        <React.Fragment>
            <Table
                labelData={CLAIMED_EXCHANGER_OFFERS_LABEL_DATA}
                contentData={data}
                renderCheckboxColumn={renderCheckboxColumn}
                renderActionableColumn={renderActions}
                prevToken={prev}
                nextToken={next}
                paginatedClickHandler={getPaginatedData}
                extClasses={{
                    container: 'rewards-src3',
                }}
            />
            <Modal
                visible={showAdditionalDetailsModal}
                modalStyles={modalStyles}
                contentLabel='Claimed Exchanger Offers Additional Details Modal'
            >
                <div className='rewards-modal-wrap'>
                    {JSON.stringify(additionalDetails, null, 4)}
                </div>
                <Button
                    extClasses='rewards-modal-cta'
                    primary
                    label='Close'
                    onClick={() => {
                        setAdditionalDetails({});
                        setAdditionalDetailsModal(false);
                    }}
                />
            </Modal>
        </React.Fragment>
    );
};

export default ClaimedExchangerOffersView;

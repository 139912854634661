/*
 *
 * Login actions
 *
 */

import {
    LOGIN_WITH_EMAIL_PWD,
    UPDATE_NEW_PASSWORD,
    VALIDATE_SMS_MFA,
    SEND_OTP_CODE_ON_EMAIL,
    SET_NEW_PASSWORD_WITH_OTP,
} from './constants';

export const loginWithEmailPwd = data => ({
    type: LOGIN_WITH_EMAIL_PWD,
    data
});

export const updateNewPassword = data => ({
    type: UPDATE_NEW_PASSWORD,
    data
});

export const validateSmsMfa = data => ({
    type: VALIDATE_SMS_MFA,
    data
});

export const sendOtpCodeOnEmail = data => ({
    type: SEND_OTP_CODE_ON_EMAIL,
    data
});

export const setNewPasswordWithOtpCode = data => ({
    type: SET_NEW_PASSWORD_WITH_OTP,
    data
});

import React, { useCallback } from 'react';
import { Table } from '../../../components';
import CorrectWrongIcon from '../../../components/CorrectWrongIcon';
import ImageUtils, { AutoScaleImageViewer } from '../../../utils/imageUtils';
import ProgressBar from '../../../components/ProgressBar';
import { matchScoreFormatting } from '../utils/serverCodeFormatter';

const ReportDetails = (props) => {
    const { reportData, faceMatchResult, permanentAddress } = props;

    const userImageDetails = reportData?.user_image_details;

    const getUserDetailsUrl = useCallback((imageKey) => userImageDetails?.[imageKey]?.presigned_read_url, [userImageDetails]);

    const capturedUserImageUrl = getUserDetailsUrl('captured_user_image_url');

    return (
        <div className='vkyc-auditor-'>
            <div className='vkyc-auditor-details-table-container'>
                {reportData?.user_details
                && (
                    <Table
                        rowDataVersion={2}
                        objKey='header_key'
                        labelData={reportData?.user_details?.columnData}
                        contentData={reportData?.user_details?.rowData}
                        // renderActionableColumn={renderActions}
                        prevToken={null}
                        nextToken={null}
                        paginatedClickHandler={() => {}}
                        extClasses={{
                            container: 'p-20 vkyc-auditor-details-table',
                        }}
                    />
                )}

                <div className='vkyc-final-screen-row'>
                    <div className='vkyc-image-comparison-container'>
                        <div className='vkyc-image-comparison-title'>Face Match with PAN</div>
                        <div className='vkyc-image-comparison-img-container'>
                            <AutoScaleImageViewer
                                url={getUserDetailsUrl('pan_user_image_url')}
                                baseContainerClass='vkyc-image-placeholder mr-30'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                landscapeImageClass='vkyc-image-horizontal'
                            />
                            <div className='vkyc-image-placeholder'>
                                <ImageUtils
                                    url={capturedUserImageUrl}
                                    containerClass='vkyc-auditor-image'
                                />
                            </div>
                        </div>
                        <div className='vkyc-image-comparison-match-score'>
                            <ProgressBar
                                number={faceMatchResult?.pan_face_match_result?.match_score_percent || 0}
                                progressBarStyleClassName={
                                    faceMatchResult?.pan_face_match_result?.match_score_percent
                                        ? 'vkyc-match-score-progress--green' : 'vkyc-match-score-progress--red'
                                }
                            />
                            {matchScoreFormatting(faceMatchResult?.pan_face_match_result?.match_score_percent)}%
                        </div>
                    </div>
                    <div className='vkyc-image-comparison-container'>
                        <div className='vkyc-image-comparison-title'>Face Match with Passport</div>
                        <div className='vkyc-image-comparison-img-container'>
                            <AutoScaleImageViewer
                                url={getUserDetailsUrl('passport_user_image_url')}
                                baseContainerClass='vkyc-image-placeholder mr-30'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                landscapeImageClass='vkyc-image-horizontal'
                            />
                            <div className='vkyc-image-report-placeholder'>
                                <ImageUtils
                                    url={capturedUserImageUrl}
                                    containerClass='vkyc-auditor-image'
                                />
                            </div>
                        </div>
                        <div className='vkyc-image-comparison-match-score'>
                            <ProgressBar
                                number={faceMatchResult?.passport_face_match_result?.match_score_percent || 0}
                                progressBarStyleClassName={
                                    faceMatchResult?.passport_face_match_result?.match_score_percent
                                        ? 'vkyc-match-score-progress--green' : 'vkyc-match-score-progress--red'
                                }
                            />
                            {matchScoreFormatting(faceMatchResult?.passport_face_match_result?.match_score_percent)}%
                        </div>
                    </div>
                </div>
                <div className='vkyc-final-screen-row'>
                    <div className='vkyc-image-comparison-container-landscape'>
                        <div className='vkyc-image-comparison-title'>Face Match with Emirates Id</div>
                        <div className='vkyc-image-comparison-img-container'>
                            <AutoScaleImageViewer
                                url={getUserDetailsUrl('emirates_id_user_image_url')}
                                baseContainerClass='vkyc-image-placeholder'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                landscapeImageClass='vkyc-image-horizontal'
                            />
                            <div className='vkyc-image-text-placeholder'>
                                <ImageUtils
                                    url={capturedUserImageUrl}
                                    containerClass='vkyc-auditor-image'
                                />
                            </div>
                        </div>
                        <div className='vkyc-image-comparison-match-score'>
                            <ProgressBar
                                number={faceMatchResult?.emirates_id_face_match_result?.match_score_percent || 0}
                                progressBarStyleClassName={
                                    faceMatchResult?.emirates_id_face_match_result?.match_score_percent
                                        ? 'vkyc-match-score-progress--green' : 'vkyc-match-score-progress--red'
                                }
                            />
                            {matchScoreFormatting(faceMatchResult?.emirates_id_face_match_result?.match_score_percent)}%
                        </div>
                    </div>
                    <div className='vkyc-image-comparison-container'>
                        <div className='vkyc-image-comparison-title'>Location Check</div>
                        {reportData?.location_check_report?.formatted_location_details?.map((locationDetail) => (
                            <div className='vkyc-qna-container'>
                                <div className='frcsbWrapper'>
                                    <div className=''>
                                        <div className='sop-step-title'>{locationDetail?.label}</div>
                                        <div className='sop-step-des'>{locationDetail?.expected_answer}</div>
                                    </div>
                                    <div className=''>
                                        <CorrectWrongIcon isCorrect={locationDetail?.is_valid} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='vkyc-final-screen-row'>
                    <div className='vkyc-image-comparison-container'>
                        <div className='vkyc-image-comparison-title'>Address Match with Passport</div>
                        <div className='vkyc-image-comparison-img-container'>
                            <AutoScaleImageViewer
                                url={getUserDetailsUrl('passport_rear_image_url')}
                                baseContainerClass='vkyc-image-placeholder mr-30'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                landscapeImageClass='vkyc-image-horizontal'
                            />
                            <div className='vkyc-image-placeholder vkyc-match-text'>
                                {permanentAddress}
                            </div>
                        </div>
                        <div className='vkyc-image-match-score-text'> </div>
                    </div>
                    <div className='vkyc-image-comparison-container'>
                        <div className='vkyc-image-comparison-title'>Liveness Check</div>
                        {reportData?.question_answers?.filter((qna) => qna?.string_answer).map((qna) => (
                            <div className='vkyc-qna-container'>
                                <div className='frcsbWrapper'>
                                    <div className=''>
                                        <div className='vkyc-auditor-liveness-details-label'>{qna?.question}</div>
                                        <div className='vkyc-auditor-liveness-details-value'>{qna?.string_answer}</div>
                                    </div>
                                    <div className=''>
                                        <CorrectWrongIcon isCorrect />
                                        {/* {answers[index] && <CorrectWrongIcon isCorrect={answers[index] === QUESTION_ANSWER_VALUE.YES} />} */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
                <div className='vkyc-final-screen-row'>
                    <div className='vkyc-image-comparison-container'>
                        <div className='vkyc-image-comparison-title'> Emirates Id</div>
                        <div className='vkyc-image-comparison-img-container'>
                            <AutoScaleImageViewer
                                url={getUserDetailsUrl('emirates_id_front_image_url')}
                                baseContainerClass='vkyc-image-placeholder mr-30'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                landscapeImageClass='vkyc-image-horizontal'
                            />
                            <AutoScaleImageViewer
                                url={getUserDetailsUrl('emirates_id_back_image_url')}
                                baseContainerClass='vkyc-image-placeholder mr-30'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                landscapeImageClass='vkyc-image-horizontal'
                            />
                        </div>
                        <div className='vkyc-image-comparison-match-score' />
                    </div>
                </div>
                <div className='vkyc-final-screen-row'>
                    <div className='vkyc-image-comparison-container vkyc-w-100'>
                        <div className='vkyc-image-comparison-title'>Browser & IP Details</div>
                        <div className='vkyc-qna-container'>
                            <div className='frcsbWrapper'>
                                <div className=''>
                                    <div className='vkyc-auditor-liveness-details-label'>ISP</div>
                                    <div className='vkyc-auditor-liveness-details-v'>
                                        {reportData?.browser_and_ip_details?.internet_service_provider}
                                    </div>
                                </div>
                                <div className=''>
                                    <CorrectWrongIcon isCorrect />
                                </div>
                            </div>
                        </div>
                        <div className='vkyc-qna-container'>
                            <div className='frcsbWrapper'>
                                <div className=''>
                                    <div className='vkyc-auditor-liveness-details-label'>IP Country Code</div>
                                    <div className='vkyc-auditor-liveness-details-value'>{reportData?.browser_and_ip_details?.ip_country_code}</div>
                                </div>
                                <div className=''>
                                    <CorrectWrongIcon isCorrect />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ReportDetails;

/**
 * @file BankingVerificationRequests
 * Shows the transaction verification requests for the particular ticket raised by the user
 * On click check, shows the employer details and transaction details for that row
 */

import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedSelector } from '../../../hooks';
import { isNilOrEmpty } from '../../../utils';
import { Loader } from '../../../components';

import { getSalaryEmployerDetailsForUserAction, getSalaryVerificationRequestsForUserAction } from '../actions';
import {
    selectSalaryEmployerDetailsForUser, selectSalaryVerificationRequestsForUser, selectViewSalaryAccountErr, selectViewSalaryAccountLoading,
} from '../selectors';
import { getTicketDetails } from '../utils';

import VerificationCasesTable from '../VerificationCasesTable';
import VerificationInfo from '../VerificationInfo';

const ALL_CASES = 'ALL_CASES';
const SINGLE_CASE = 'SINGLE_CASE';

const VerificationCases = (props) => {
    const { ticketInfo, setCurView, setCaseDetails } = props;
    const { screenKey } = ticketInfo;

    const dispatch = useDispatch();

    const { data: casesList, prev: prevToken, next: nextToken } = useMemoizedSelector(selectSalaryVerificationRequestsForUser, [screenKey]);

    const makePayloadAndGetVerificationCases = (params = {}) => {
        const payload = {
            ...params,
            ...getTicketDetails(ticketInfo),
        };

        dispatch(getSalaryVerificationRequestsForUserAction({
            screenKey,
            payload,
        }));
    };

    useEffect(() => {
        // fetch verification cases only if not present already
        if (isNilOrEmpty(casesList)) makePayloadAndGetVerificationCases();
    }, []);

    const getPaginatedVerificationCases = ({ prev, next }) => {
        makePayloadAndGetVerificationCases({ prev, next });
    };

    const handleVerify = (item) => {
        // item, index
        setCaseDetails(item);
        setCurView(SINGLE_CASE);
    };

    return (
        <React.Fragment>
            <div className='heading1 mb-60'>Salary Verification Requests</div>
            <VerificationCasesTable
                casesList={casesList}
                prevToken={prevToken}
                nextToken={nextToken}
                getPaginatedVerificationCases={getPaginatedVerificationCases}
                handleVerify={handleVerify}
            />
        </React.Fragment>
    );
};

const SingleCase = (props) => {
    const { ticketInfo, caseDetails, setCurView } = props;
    const { screenKey } = ticketInfo;

    const dispatch = useDispatch();

    const employerDetails = useMemoizedSelector(selectSalaryEmployerDetailsForUser, [screenKey]);

    const handleBackClick = () => {
        setCurView(ALL_CASES);
    };

    useEffect(() => {
        // fetch employer details only if not present already
        if (isNilOrEmpty(employerDetails)) {
            dispatch(getSalaryEmployerDetailsForUserAction({
                screenKey,
                payload: {
                    txnId: caseDetails.txnId,
                    requestId: caseDetails.requestId,
                },
            }));
        }
    }, []);

    return (
        <VerificationInfo
            caseDetails={caseDetails}
            employerDetails={employerDetails}
            handleBackClick={handleBackClick}
        />
    );
};

const RenderViews = (props) => {
    const { ticketInfo } = props;

    const [curView, setCurView] = useState(ALL_CASES);
    const [caseDetails, setCaseDetails] = useState(ALL_CASES);

    switch (curView) {
        case ALL_CASES:
            return (
                <VerificationCases
                    ticketInfo={ticketInfo}
                    setCurView={setCurView}
                    setCaseDetails={setCaseDetails}
                />
            );

        case SINGLE_CASE:
            return (
                <SingleCase
                    ticketInfo={ticketInfo}
                    caseDetails={caseDetails}
                    setCurView={setCurView}
                />
            );

        default:
            return null;
    }
};

const BankingVerificationRequests = (props) => {
    const { screenKey } = props;

    const loading = useMemoizedSelector(selectViewSalaryAccountLoading, [screenKey]);
    const err = useMemoizedSelector(selectViewSalaryAccountErr, [screenKey]);

    return (
        <div className='salops-ci'>
            <RenderViews ticketInfo={props} />
            {err && <div className='err-label salops-err'>{err}</div>}
            <Loader visible={loading} />
        </div>
    );
};

export default memo(BankingVerificationRequests);

/*
 *
 * Waitlist actions
 *
 */

import {
    GET_FREELANCER_INFO, SET_FREELANCER_INFO, UPDATE_FL_WL_STATUS,
    GET_PENDING_STATUS_FL_LIST,
} from './constants';

export const getFreelancerInfo = data => ({
    type: GET_FREELANCER_INFO,
    data
});

export const setFreelancerInfo = data => ({
    type: SET_FREELANCER_INFO,
    data
});

export const updateFreelancerWLStatus = data => ({
    type: UPDATE_FL_WL_STATUS,
    data
});

export const getPendingStatusFreelancerList = data => ({
    type: GET_PENDING_STATUS_FL_LIST,
    data
});

/**
 *
 * ActiveView - Component to show the active offers, or rewards, or exchanger offers
 *
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, ExpandableCard, FormDropdown, FormWithSingleInput, Loader,
} from '../../../components';
import { dateUtils } from '../../../utils';

import {
    getActiveOffers, getActiveRewards, getActiveExchangerOffers, setSearchParamsForActiveView as setSearchParams,
    resetSearchParamsForActiveView as resetSearchParams,
} from '../actions';
import {
    makeSelectSearchParametersForActiveView, makeSelectActiveOffers, makeSelectActiveRewards,
    makeSelectActiveExchangerOffers,
} from '../selectors';
import {
    ACTIVE_VIEW_SEARCH_PARAMS_KEYS, APPLICATION_TO_OPTIONS, CARD_OFFER_TYPE, CATEGORY, CATEGORY_MAP, OFFER_REDEMPTION_MODE,
} from '../constants';

import '../style.scss';

import OffersView from './OffersView';
import RewardsView from './RewardsView';
import ExchangerOffersView from './ExchangerOffersView';

const ActiveView = (props) => {
    const dispatch = useDispatch();

    // global state variables
    const searchParameters = useSelector(makeSelectSearchParametersForActiveView(), shallowEqual);
    const offersList = useSelector(makeSelectActiveOffers(), shallowEqual);
    const rewardsList = useSelector(makeSelectActiveRewards(), shallowEqual);
    const exchangerOffersList = useSelector(makeSelectActiveExchangerOffers(), shallowEqual);

    // local state variables
    const [isActiveCardExpanded, setIsActiveCardExpanded] = useState(false);
    const [errLabel, setErrLabel] = useState('');

    const { ticketId, userId, index } = props;

    const { fromDate: fromInitialDate, toDate: toInitialDate } = dateUtils.getInitialDateRange('02-01-2021', true); // 01 Feb

    let category;
    let offerRedemptionMode;
    let rewardsApplicableTo;
    let cardOfferType;
    let fromDate;
    let toDate;
    let loadingOffers;
    let offersData;
    let offersDataPrev;
    let offersDataNext;
    let loadingRewards;
    let rewardsData;
    let rewardsDataPrev;
    let rewardsDataNext;
    let loadingExchangerOffers;
    let exchangerOffersData;
    let exchangerOffersDataPrev;
    let exchangerOffersDataNext;

    // extract the search parameters for passed ticket id
    if (searchParameters[ticketId]) {
        ({
            fromDate, toDate, category, offerRedemptionMode, rewardsApplicableTo, cardOfferType,
        } = searchParameters[ticketId]);
    }

    useEffect(() => {
        handleResetSearchParams();
    }, []);

    /**
     * initialise from and to date with the date utils value
     * this won't get called again & again
     */
    useEffect(() => {
        if (!fromDate && !toDate) {
            dispatch(setSearchParams({
                ticketId,
                fromDate: fromInitialDate,
                toDate: toInitialDate,
            }));
        }
    }, [fromDate, toDate]);

    if (offersList[ticketId]) {
        ({
            loading: loadingOffers, data: offersData, prev: offersDataPrev,
            next: offersDataNext,
        } = offersList[ticketId]);
    }

    if (rewardsList[ticketId]) {
        ({
            loading: loadingRewards, data: rewardsData, prev: rewardsDataPrev,
            next: rewardsDataNext,
        } = rewardsList[ticketId]);
    }

    if (exchangerOffersList[ticketId]) {
        ({
            loading: loadingExchangerOffers, data: exchangerOffersData, prev: exchangerOffersDataPrev,
            next: exchangerOffersDataNext,
        } = exchangerOffersList[ticketId]);
    }

    const makePayloadAndCallApi = (params = {}) => {
        const { prev, next } = params;

        let payload = {
            fromDate: fromDate.value,
            toDate: toDate.value,
        };

        switch (category?.value) {
            case CATEGORY_MAP.offers: {
                payload = {
                    ...payload,
                    redemptionMode: offerRedemptionMode?.value,
                    cardOfferType: offerRedemptionMode?.value === OFFER_REDEMPTION_MODE[1]?.value && cardOfferType?.value,
                };

                dispatch(getActiveOffers({
                    ticketId, userId, index, ...payload, prev, next,
                }));
                break;
            }
            case CATEGORY_MAP.rewards: {
                if (rewardsApplicableTo?.value === 'USER') payload = {};
                payload = {
                    ...payload,
                    rewardsApplicableToUser: rewardsApplicableTo?.value === (APPLICATION_TO_OPTIONS.length > 0
                        && APPLICATION_TO_OPTIONS[APPLICATION_TO_OPTIONS.length - 1].value),
                };
                dispatch(getActiveRewards({
                    ticketId, userId, index, ...payload, prev, next,
                }));
                break;
            }
            case CATEGORY_MAP.exchangerOffers: {
                dispatch(getActiveExchangerOffers({
                    ticketId, userId, index, ...payload, prev, next,
                }));
                break;
            }
            default: break;
        }
    };

    const handleClaimsSubmit = (e) => {
        e.preventDefault();

        if (!category || (!(category?.value === CATEGORY_MAP.rewards && rewardsApplicableTo?.value === 'USER') && !toDate?.value)) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        if (category === CATEGORY_MAP.offers && !offerRedemptionMode) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        makePayloadAndCallApi();

        setErrLabel('');
        setIsActiveCardExpanded(false);
    };

    const getPaginatedClaims = ({ prev, next }) => {
        makePayloadAndCallApi({ prev, next });
    };

    /**
     * set search parameters for given key, value pair
     * and toggle the selected dropdown incase non-empty value is passed
     */
    const handleOptionsChange = (key) => (value) => {
        dispatch(setSearchParams({
            ticketId,
            key,
            value,
        }));
    };

    const handleDateChange = (key) => (value) => {
        const data = {
            key,
            value,
            fromDate,
            toDate,
        };
        const { fromDate: modifiedFromDate, toDate: modifiedToDate } = dateUtils.getModifiedDate(data);

        dispatch(setSearchParams({
            ticketId,
            fromDate: modifiedFromDate,
            toDate: modifiedToDate,
        }));
    };

    const renderDateBlock = (key) => {
        let label;
        let labelId;
        let value;
        let maxDate;
        let minDate;

        switch (key) {
            case 'fromDate':
                label = 'Active Since';
                labelId = 'since';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = fromDate || {});
                break;

            case 'toDate':
                label = 'Active Till*';
                labelId = 'till';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = toDate || {});
                break;

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'rewards-fc',
                    label: 'rewards-dd__lb',
                    inputField: 'rewards-dd__ip',
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: handleDateChange(key),
                    maxDate,
                    minDate,
                }}
                hideErrLabel
            />
        );
    };

    const renderDD = (id) => {
        let label;
        let value;
        let options;

        switch (id) {
            case ACTIVE_VIEW_SEARCH_PARAMS_KEYS.CATEGORY: {
                label = 'Category*';
                value = category;
                options = CATEGORY;

                break;
            }

            case ACTIVE_VIEW_SEARCH_PARAMS_KEYS.OFFER_REDEMPTION_MODE: {
                label = 'Offer Redemption Mode*';
                value = offerRedemptionMode;
                options = OFFER_REDEMPTION_MODE;

                break;
            }

            case ACTIVE_VIEW_SEARCH_PARAMS_KEYS.REWARDS_APPLICABLE_TO: {
                label = 'Applicable To*';
                value = rewardsApplicableTo;
                options = APPLICATION_TO_OPTIONS;

                break;
            }

            case ACTIVE_VIEW_SEARCH_PARAMS_KEYS.CARD_OFFER_TYPE: {
                label = 'Card Offer Type';
                value = cardOfferType;
                options = CARD_OFFER_TYPE;

                break;
            }

            default:
                label = '';
                options = [];
        }

        const { length: optionsLength } = options;

        if (optionsLength === 0) {
            return null;
        }

        return (
            <FormDropdown
                label={label}
                options={options}
                input={{
                    value,
                    onChange: handleOptionsChange(id),
                    placeholder: 'Choose...',
                }}
                cacheKey={`${id.toLowerCase()}-type`}
                extStyles={{
                    container: 'rewards-dd__fcc',
                    label: 'rewards-dd__lb rewards-dd__lb-d',
                    inputField: 'rewards-dd__ip',
                }}
            />
        );
    };

    const handleResetSearchParams = () => dispatch(resetSearchParams({ ticketId, fromDate: fromInitialDate, toDate: toInitialDate }));

    return (
        <div className='rewards-cr1'>
            <ExpandableCard
                extClasses='rewards-ec'
                heading='View current offers/rewards/exchanger offers'
                isExpanded={isActiveCardExpanded}
                setIsExpanded={() => setIsActiveCardExpanded(!isActiveCardExpanded)}
            >
                <div className='rewards-sc'>
                    <div className='frwpWrapper rewards-sc__gap'>
                        {renderDD(ACTIVE_VIEW_SEARCH_PARAMS_KEYS.CATEGORY)}
                        {
                            category?.value === CATEGORY_MAP.offers ? (
                                <React.Fragment>
                                    {renderDD(ACTIVE_VIEW_SEARCH_PARAMS_KEYS.OFFER_REDEMPTION_MODE)}
                                    {
                                        offerRedemptionMode?.value === OFFER_REDEMPTION_MODE[1]?.value ? (
                                            <React.Fragment>
                                                {renderDD(ACTIVE_VIEW_SEARCH_PARAMS_KEYS.CARD_OFFER_TYPE)}
                                            </React.Fragment>
                                        ) : null
                                    }
                                </React.Fragment>
                            ) : category?.value === CATEGORY_MAP.rewards
                            && (
                                <React.Fragment>
                                    {renderDD(ACTIVE_VIEW_SEARCH_PARAMS_KEYS.REWARDS_APPLICABLE_TO)}
                                </React.Fragment>
                            )
                        }
                        {
                            !(category?.value === CATEGORY_MAP.rewards && rewardsApplicableTo?.value === 'USER') ? (
                                <React.Fragment>
                                    {renderDateBlock('fromDate')}
                                    {renderDateBlock('toDate')}
                                </React.Fragment>
                            ) : null
                        }
                    </div>
                    {errLabel ? <div className='err-label rewards-el'>{errLabel}</div> : null}
                    <div className='frcWrapper rewards-sc__3r'>
                        <Button
                            extClasses='rewards-sc__cta'
                            primary
                            label='Search'
                            onClick={handleClaimsSubmit}
                        />
                        <div
                            className='link rewards-sc__clear'
                            onClick={handleResetSearchParams}
                            role='presentation'
                        >
                            Clear All Selection
                        </div>
                    </div>
                </div>
            </ExpandableCard>
            {
                category?.value === CATEGORY_MAP.offers ? (
                    <OffersView
                        data={offersData}
                        prev={offersDataPrev}
                        next={offersDataNext}
                        loading={loadingOffers}
                        getPaginatedData={getPaginatedClaims}
                    />
                ) : null
            }
            {
                category?.value === CATEGORY_MAP.rewards ? (
                    <RewardsView
                        data={rewardsData}
                        prev={rewardsDataPrev}
                        next={rewardsDataNext}
                        getPaginatedData={getPaginatedClaims}
                    />
                ) : null
            }
            {
                category?.value === CATEGORY_MAP.exchangerOffers ? (
                    <ExchangerOffersView
                        data={exchangerOffersData}
                        prev={exchangerOffersDataPrev}
                        next={exchangerOffersDataNext}
                        getPaginatedData={getPaginatedClaims}
                        loading={loadingExchangerOffers}
                    />
                ) : null
            }
            <Loader visible={loadingOffers || loadingRewards || loadingExchangerOffers} />
        </div>
    );
};

export default ActiveView;

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const domain1 = 'sherlock.epifi.in';
export const domain2 = 'federal.epifi.in';

// Domains
export const DEVELOPMENT_DOMAIN = 'localhost';
export const PRODUCTION_DOMAIN = 'sherlock.epifi.in';
export const QA_DOMAIN = 'sherlock.qa.pointz.in';
export const STAGING_DOMAIN = 'sherlock.staging.pointz.in';
export const UAT_DOMAIN = 'sherlock.uat.pointz.in';
export const FEDERAL_DOMAIN = 'federal.epifi.in';

// Environments
export const PRODUCTION = 'production';
export const DEVELOPMENT = 'development';
export const QA = 'qa';
export const STAGING = 'staging';
export const UAT = 'uat';

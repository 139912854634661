/**
 * @file Constants for Case Search container
 */

// action & reducer constants
export const CASE_SEARCH_KEY = 'caseSearch';

export const REDUCER_KEYS = {
    CASE_SEARCH_FILE_ENTRIES: 'fileEntries',
    CASE_SEARCH_LOADING: 'loading',
    CASE_SEARCH_ERROR: 'err',
    CASE_SEARCH_COLUMN_DATA: 'columnData',
    CASE_SEARCH_ROW_DATA: 'rowData',
    CASE_SEARCH_PREV_TOKEN: 'prevToken',
    CASE_SEARCH_NEXT_TOKEN: 'nextToken',
};

export const GET_CASE_SEARCH = `app/${CASE_SEARCH_KEY}/GET_CASE_SEARCH`;
export const SET_CASE_SEARCH = `app/${CASE_SEARCH_KEY}/SET_CASE_SEARCH`;
export const ERR_CASE_SEARCH = `app/${CASE_SEARCH_KEY}/ERR_CASE_SEARCH`;
export const RESET_CASE_SEARCH = `app/${CASE_SEARCH_KEY}/RESET_CASE_SEARCH`;

export const ID_TYPES_INFO = {
    ACTOR_ID: {
        id: 'actorId',
        value: 'actorId',
        label: 'Actor ID',
        order: 1,
    },
    CASE_ID: {
        id: 'caseId',
        value: 'caseId',
        label: 'Case ID',
        order: 2,
    },
};

export const ID_TYPES_LIST = Object.values(ID_TYPES_INFO).sort((a, b) => (a.order - b.order));

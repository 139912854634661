/**
 * IssueConfig - Component to update the issue config by uploading csv & check their history
 * Used for Admin Tools flow
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';

import { ISSUE_CONFIG_MANAGE_PATH, ISSUE_CONFIG_HISTORY_PATH } from '../App/routes';

import ManageIssueConfig from './ManageIssueConfig';
import IssueConfigHistory from './IssueConfigHistory';

import { ISSUE_CONFIG_KEY } from './constants';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const IssueConfig = () => (
    <div className='btu'>
        <Switch>
            <Route
                path={ISSUE_CONFIG_MANAGE_PATH}
                component={ManageIssueConfig}
            />
            <Route
                path={ISSUE_CONFIG_HISTORY_PATH}
                component={IssueConfigHistory}
            />
        </Switch>
    </div>
);

const withReducer = injectReducer({ key: ISSUE_CONFIG_KEY, reducer });
const withSaga = injectSaga({ key: ISSUE_CONFIG_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(IssueConfig);

import { getStrapiLoginURL } from '../../../utils';
import { BA_SALARY_EXECUTED_MANDATES_PATH, BA_SALARY_MANDATES_PATH, DEFAULT_TICKET_PATH } from '../../App/routes';
import { SCREEN } from '../constants';

export const menuStructure = ({
    ticketId,
    basicInfo,
    recentActivityVersion,
    sendNotificationsVersion,
    autoPayVersion,
    scriptsSearchVersion,
}) => {
    const {
        accessLevelInfo: {
            isAgent, isAdminRestricted,
        },
    } = basicInfo;

    const TICKET_PATH = `${DEFAULT_TICKET_PATH}${ticketId}`;
    const BANKING_TICKET_PATH = `${TICKET_PATH}/banking`;
    const CONNECTED_ACCOUNTS_TICKET_PATH = `${TICKET_PATH}/connected-accounts`;
    const WEALTH_TICKET_PATH = `${TICKET_PATH}/wealth`;
    const WEALTH_USSTOCKS_TICKET_PATH = `${WEALTH_TICKET_PATH}/us-stocks`;

    return (
        [
            {
                id: 'ba-scripts',
                label: 'Scripts',
                path: getStrapiLoginURL(),
                informationLevel: SCREEN.INSENSITIVE_SCREEN,
                hidden: !scriptsSearchVersion || isAgent,
                isExternal: true,
            },
            {
                id: 'banking',
                label: 'Banking',
                subLinks: [
                    {
                        id: 'ba-profile',
                        label: 'Profile',
                        path: `${BANKING_TICKET_PATH}/profile`,
                        informationLevel: SCREEN.INSENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-kyc-onboarding',
                        label: 'Savings Account',
                        path: `${BANKING_TICKET_PATH}/kyc-onboarding`,
                        informationLevel: SCREEN.INSENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-deposits',
                        label: 'Deposits',
                        path: `${BANKING_TICKET_PATH}/deposits`,
                        // disable: isAuthorizedForLessSensitiveScreen
                        informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-txns',
                        label: 'Transactions',
                        subLinks: [
                            {
                                id: 'ba-txns',
                                label: 'Transactions',
                                path: `${BANKING_TICKET_PATH}/txn`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-credit-card-txns',
                                label: 'Credit Card Transactions',
                                path: `${BANKING_TICKET_PATH}/credit-card-txn`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                        ],
                    },
                    {
                        id: 'ba-autopay',
                        label: 'Autopay',
                        hidden: autoPayVersion !== 1,
                        subLinks: [
                            {
                                id: 'ba-autopay-existing',
                                label: 'Existing Mandates',
                                path: `${BANKING_TICKET_PATH}/autopay/existing`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-autopay-upcoming',
                                label: 'Upcoming Transactions',
                                path: `${BANKING_TICKET_PATH}/autopay/upcoming`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                        ],
                    },
                    {
                        id: 'ba-credit',
                        label: 'Credit Card',
                        path: `${BANKING_TICKET_PATH}/credit`,
                        informationLevel: SCREEN.INSENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-instruments',
                        label: 'Instruments',
                        subLinks: [
                            {
                                id: 'ba-debit-instrument',
                                label: 'Debit',
                                path: `${BANKING_TICKET_PATH}/instrument/debit`,
                                informationLevel: SCREEN.INSENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-upi-instrument',
                                label: 'UPI',
                                path: `${BANKING_TICKET_PATH}/instrument/upi`,
                                informationLevel: SCREEN.INSENSITIVE_SCREEN,
                            },
                        ],
                    },
                    {
                        id: 'ba-comms',
                        label: 'Communications',
                        path: `${BANKING_TICKET_PATH}/comms`,
                        informationLevel: SCREEN.INSENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-recent-activity',
                        label: 'Recent Activity',
                        path: `${BANKING_TICKET_PATH}/recent-activity`,
                        hidden: recentActivityVersion !== 1,
                        informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-user-actions',
                        label: 'User Actions',
                        subLinks: [
                            {
                                id: 'ba-call-user-action',
                                label: 'Call User',
                                path: `${BANKING_TICKET_PATH}/user-actions/call`,
                                hidden: isAgent,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-pay-user-action',
                                label: 'Pay User',
                                path: `${BANKING_TICKET_PATH}/user-actions/pay`,
                                hidden: isAdminRestricted,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-export-health-data-user-action',
                                label: 'Export Health Data',
                                path: `${BANKING_TICKET_PATH}/user-actions/export-health-data`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                        ],
                    },
                    {
                        id: 'ba-rewards',
                        label: 'Rewards',
                        path: `${BANKING_TICKET_PATH}/rewards`,
                        informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-merge-tickets',
                        label: 'Merge Tickets',
                        path: `${BANKING_TICKET_PATH}/merge-tickets`,
                        informationLevel: SCREEN.INSENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-call-recordings',
                        label: 'Call Recordings',
                        path: `${BANKING_TICKET_PATH}/call-recordings`,
                        informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-fit-rules',
                        label: 'FIT Rules',
                        path: `${BANKING_TICKET_PATH}/fit-rules`,
                        informationLevel: SCREEN.LESS_SENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-user-requests',
                        label: 'User Requests',
                        subLinks: [
                            {
                                id: 'ba-account-statment-request',
                                label: 'Account Statement',
                                path: `${BANKING_TICKET_PATH}/user-requests/account-statement`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-account-closure-user-request',
                                label: 'Account Closure',
                                path: `${BANKING_TICKET_PATH}/user-requests/account-closure`,
                                informationLevel: SCREEN.INSENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-chequebook-request',
                                label: 'Chequebook Request',
                                path: `${BANKING_TICKET_PATH}/user-requests/chequebook-request`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-send-notifications',
                                label: 'Send Notifications',
                                hidden: sendNotificationsVersion !== 1,
                                path: `${BANKING_TICKET_PATH}/user-requests/send-notifications`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                        ],
                    },
                    {
                        id: 'ba-admin-actions',
                        label: 'Admin Actions',
                        path: `${BANKING_TICKET_PATH}/admin-actions`,
                        informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-referrals',
                        label: 'Referrals',
                        path: `${BANKING_TICKET_PATH}/referrals`,
                        informationLevel: SCREEN.LESS_SENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-disputes',
                        label: 'Disputes',
                        path: `${BANKING_TICKET_PATH}/disputes`,
                        informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                    },
                    {
                        id: 'ba-salary-program',
                        label: 'Salary Program',
                        subLinks: [
                            {
                                id: 'ba-salary-account-details',
                                label: 'Account Details',
                                path: `${BANKING_TICKET_PATH}/salary/account-details`,
                                informationLevel: SCREEN.LESS_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-salary-verification-requests',
                                label: 'Verification Requests',
                                path: `${BANKING_TICKET_PATH}/salary/verification-requests`,
                                informationLevel: SCREEN.LESS_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-salary-health-insurance',
                                label: 'Health Insurance Benefits',
                                path: `${BANKING_TICKET_PATH}/salary/health-insurance`,
                                informationLevel: SCREEN.LESS_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-salary-enach-mandates',
                                label: 'eNACH Mandates Details',
                                path: `${BA_SALARY_MANDATES_PATH}`,
                                informationLevel: SCREEN.LESS_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-salary-executed-mandates',
                                label: 'Executed Mandates Details',
                                path: `${BA_SALARY_EXECUTED_MANDATES_PATH}`,
                                informationLevel: SCREEN.LESS_SENSITIVE_SCREEN,
                            },
                        ],
                    },
                    {
                        id: 'ba-loans',
                        label: 'Loans',
                        subLinks: [
                            {
                                id: 'ba-preapprovedloans',
                                label: 'Pre Approved Loans',
                                path: `${BANKING_TICKET_PATH}/loans/pre-approved-loans`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'ba-lamf',
                                label: 'Loan Against MF',
                                path: `${BANKING_TICKET_PATH}/loans/loan-against-mf`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                        ],
                    },
                    {
                        id: 'ba-tiering',
                        label: 'Tiering Plans',
                        path: `${BANKING_TICKET_PATH}/tiering`,
                        informationLevel: SCREEN.INSENSITIVE_SCREEN,
                    },
                ],
            },
            {
                id: 'connected-accounts',
                label: 'Connected Accounts',
                subLinks: [
                    {
                        id: 'ca-accounts',
                        label: 'Accounts',
                        path: `${CONNECTED_ACCOUNTS_TICKET_PATH}/accounts`,
                        informationLevel: SCREEN.INSENSITIVE_SCREEN,
                    },
                ],
            },
            {
                id: 'wealth',
                label: 'Wealth',
                subLinks: [
                    {
                        id: 'wl-onboarding',
                        label: 'Onboarding',
                        path: `${WEALTH_TICKET_PATH}/onboarding`,
                        informationLevel: SCREEN.INSENSITIVE_SCREEN,
                    },
                    {
                        id: 'wl-investment',
                        label: 'Investment',
                        path: `${WEALTH_TICKET_PATH}/investment`,
                        informationLevel: SCREEN.INSENSITIVE_SCREEN,
                    },
                    {
                        id: 'wl-mutual-funds',
                        label: 'Mutual Funds',
                        path: `${WEALTH_TICKET_PATH}/mutual-funds`,
                        informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                    },
                    {
                        id: 'wl-p2p',
                        label: 'Jump/P2P',
                        path: `${WEALTH_TICKET_PATH}/p2p`,
                        informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                    },
                    {
                        id: 'wl-uss',
                        label: 'US Stocks',
                        subLinks: [
                            {
                                id: 'wl-uss-account-opening',
                                label: 'USS Account Opening',
                                path: `${WEALTH_USSTOCKS_TICKET_PATH}/account-opening`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'wl-uss-portfolio-details',
                                label: 'USS Portfolio Details',
                                path: `${WEALTH_USSTOCKS_TICKET_PATH}/portfolio-details`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                            {
                                id: 'wl-uss-remittance-details',
                                label: 'USS Remittance Details',
                                path: `${WEALTH_USSTOCKS_TICKET_PATH}/remittance-details`,
                            },
                            {
                                id: 'wl-uss-activity-details',
                                label: 'USS Activity Details',
                                path: `${WEALTH_USSTOCKS_TICKET_PATH}/activity-details`,
                                informationLevel: SCREEN.MODERATELY_SENSITIVE_SCREEN,
                            },
                        ],
                    },
                ],
            },
        ]
    );
};

/**
 * @file Manages the actions for AppV2 component
 */

import {
    ERR_L1_CATEGORIES, GET_L1_CATEGORIES, SET_L1_CATEGORIES, CREATE_TICKET_FOR_CALL,
} from './constants';

export const getL1CategoriesAction = (data) => ({
    type: GET_L1_CATEGORIES,
    data,
});

export const setL1CategoriesAction = (data) => ({
    type: SET_L1_CATEGORIES,
    data,
});

export const errL1CategoriesAction = (data) => ({
    type: ERR_L1_CATEGORIES,
    data,
});

export const createTicketForCallAction = (data) => ({
    type: CREATE_TICKET_FOR_CALL,
    data,
});

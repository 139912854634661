import {
    GET_CHANNELS_INFO, GET_QUESTIONS_INFO, GET_TXNS_INFO, INITIATE_RAISE_DISPUTE,
    MODIFY_QUESTIONS_INFO, RESET_STATE, GET_DISPUTE_INFO, SET_DISPUTE_INFO,
    GET_FIRST_OR_LAST_SET_TXN_LIST, SET_SEARCH_PARAMS, RESET_SEARCH_PARAMS,
    GET_ORDER_STATUS_LIST, GET_PAYMENT_PROTOCOL_LIST, GET_CREDIT_CARD_TXNS_INFO,
    GET_FIRST_OR_LAST_SET_CREDIT_CARD_TXN_LIST, GET_CREDIT_CARD_QUESTIONS_INFO, INITIATE_RAISE_DISPUTE_FOR_CREDIT_CARD_TXN,
} from './constants';

export const getTxnsInfo = (data) => ({
    type: GET_TXNS_INFO,
    data,
});

export const getCreditCardTxnsInfoAction = (data) => ({
    type: GET_CREDIT_CARD_TXNS_INFO,
    data,
});

export const getFirstOrLastSetTxnList = (data) => ({
    type: GET_FIRST_OR_LAST_SET_TXN_LIST,
    data,
});

export const getFirstOrLastSetCreditCardTxnsListAction = (data) => ({
    type: GET_FIRST_OR_LAST_SET_CREDIT_CARD_TXN_LIST,
    data,
});

export const getChannelsInfo = (data) => ({
    type: GET_CHANNELS_INFO,
    data,
});

export const getQuestionsInfo = (data) => ({
    type: GET_QUESTIONS_INFO,
    data,
});

export const modifyQuestionsInfo = (data) => ({
    type: MODIFY_QUESTIONS_INFO,
    data,
});

export const raiseTxnDispute = (data) => ({
    type: INITIATE_RAISE_DISPUTE,
    data,
});

export const resetState = (data) => ({
    type: RESET_STATE,
    data,
});

export const getDisputeInfoAction = (data) => ({
    type: GET_DISPUTE_INFO,
    data,
});

export const setDisputeInfo = (data) => ({
    type: SET_DISPUTE_INFO,
    data,
});

export const setSearchParams = (data) => ({
    type: SET_SEARCH_PARAMS,
    data,
});

export const resetSearchParams = (data) => ({
    type: RESET_SEARCH_PARAMS,
    data,
});

export const getOrderStatusList = () => ({
    type: GET_ORDER_STATUS_LIST,
});

export const getPaymentProtocolList = () => ({
    type: GET_PAYMENT_PROTOCOL_LIST,
});

export const getCreditCardQuestionsInfoAction = (data) => ({
    type: GET_CREDIT_CARD_QUESTIONS_INFO,
    data,
});

export const raiseDisputeForCreditCardTxnAction = (data) => ({
    type: INITIATE_RAISE_DISPUTE_FOR_CREDIT_CARD_TXN,
    data,
});

import React, { memo, useContext } from 'react';
import classNames from 'classnames';
import { MetaInfoContext } from '../../context';

import './style.scss';

const DropdownContent = (props) => {
    const {
        children, visible, extClasses, extStyles,
    } = props;

    const metaInfo = useContext(MetaInfoContext);

    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    if (!visible) {
        return null;
    }

    const containerClass = classNames(
        { 'dropdown-content-container': bannersVersion === 1 },
        { 'dropdown-content-container-v2': bannersVersion === 2 },
        extClasses && extClasses.container,
    );

    return (
        <div className={containerClass} style={extStyles}>
            {children}
        </div>
    );
};

export default memo(DropdownContent);

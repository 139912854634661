import { createSelector } from 'reselect';

import { CHEQUEBOOK_KEY, NOTIFICATION_KEY } from './constants';

const selectAccountClosure = (state) => state.getIn(['userRequests', 'accountClosure']);

const makeSelectAccountClosure = () => createSelector(
    selectAccountClosure,
    (accountClosure) => accountClosure.toJS(),
);

const makeSelectAccountStatementFormDetails = () => createSelector(
    (state) => state.getIn(['userRequests', 'accountStatement']),
    (accountStatement) => accountStatement.toJS(),
);

const makeSelectChequebookRequests = createSelector(
    (state) => state.getIn(['userRequests', CHEQUEBOOK_KEY]),
    (substate) => substate?.toJS(),
);

const makeSelectPromptNotification = createSelector(
    (state) => state.getIn(['userRequests', NOTIFICATION_KEY]),
    (substate) => substate?.toJS(),
);

export {
    makeSelectAccountClosure,
    makeSelectChequebookRequests,
    makeSelectAccountStatementFormDetails,
    makeSelectPromptNotification,
};

/**
 * @file Component to show the banners as notifications in the app header
 */

import React, { memo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { ICONS_URL } from '../../../constants/assets';
import { setBannersVisibility } from '../../../appGlobal/actions';
import { DropdownContent, DropdownWrapper } from '../../../components';
import { isNilOrEmpty } from '../../../utils';
// import NotificationBellIcon from '../../../assets/images/svgs/notification-bell.svg';
// import WhiteNotificationBellIcon from '../../../assets/images/svgs/notification-bell--white.svg';

import { makeSelectBannerList, makeSelectBannersVisibility } from '../selectors';

import './style.scss';

const NotificationBanners = (props) => {
    const dispatch = useDispatch();

    // reducer state variables
    const { data } = useSelector(makeSelectBannerList, shallowEqual);
    const showBanners = useSelector(makeSelectBannersVisibility, shallowEqual);

    // passed props
    const { whiteIcon } = props;

    const toggleDropdown = () => {
        dispatch(setBannersVisibility({ showBanners: !showBanners }));
    };

    // do not render anything if the data is not available
    if (isNilOrEmpty(data)) {
        return null;
    }

    return (
        <DropdownWrapper
            visible={showBanners}
            onOutsideClickHandler={toggleDropdown}
            extClasses={{
                container: 'notification-banners',
            }}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div className='icon-wrapper' onClick={toggleDropdown}>
                <img
                    className='icon-wrapper__image-icon'
                    src={whiteIcon ? ICONS_URL.NOTIFICATION_BELL_WHITE : ICONS_URL.NOTIFICATION_BELL}
                    alt='bell-icon'
                />
                <div className='icon-wrapper__notification-icon'>{data.length}</div>
            </div>
            <DropdownContent
                visible={showBanners}
                extClasses={{
                    container: 'content-container',
                }}
            >
                <div className='user-action-wrapper'>
                    {
                        data.map((item) => (
                            <div
                                key={item.id}
                                className='label'
                            >
                                <div className='label__title'>{item.title}</div>
                                <div className='label__body'>{item.body}</div>
                            </div>
                        ))
                    }
                </div>
            </DropdownContent>
        </DropdownWrapper>
    );
};

export default memo(NotificationBanners);

/**
 * @file Manages the Money or currency related util functions
 */

/** Convert GRPC Money object into amount
 * @param {object} money - GRPC money object conatain units,nanos,currency
 * @returns {number} amount
 */
export const convertMoneytoAmount = (money) => Number(money?.units || null) + (Number(money?.nanos || null) * 1e-9);

/**
 * Get currency amount format
 * @param {number | string} value unformatted amount
 * @param {number} precession default - 2
 * @param {*} locales default - en-IN
 * @returns {string} formatted amount eg. 1,22,000.00 , 1,34,567.57
 */
export const getCurrencyAmountFormat = (value, precession = 2, locales = 'en-IN') => Number(value || null).toLocaleString(locales, {
    // fixed to precession values eg. precession - 2 => 12.24, 13.00
    minimumFractionDigits: precession,
    maximumFractionDigits: precession,
});

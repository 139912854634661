import React, {
    useEffect, useRef, useImperativeHandle, forwardRef,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import RecordRTC, { MediaStreamRecorder } from 'recordrtc/RecordRTC';
import { toastify } from '../../utils';

const ScreenRecorder = forwardRef((props, ref) => {
    const {
        upload, setStopFlag, participantAudio, setPermissionStatus, audioMuteFlag,
    } = props;
    const videoElementRef = useRef();
    const recorderRef = useRef();
    const streamRef = useRef();
    const displayStreamRef = useRef();
    const audioStreamRef = useRef();

    const mergeAudioStreams = (desktopStream, voiceStream, participantAudioStream) => {
        const context = new AudioContext();
        const destination = context.createMediaStreamDestination();

        if (desktopStream.getAudioTracks().length > 0) {
            const source1 = context.createMediaStreamSource(desktopStream);
            const desktopGain = context.createGain();
            desktopGain.gain.value = 0.7;
            source1.connect(desktopGain).connect(destination);
        }

        if (voiceStream.getAudioTracks().length > 0) {
            const source2 = context.createMediaStreamSource(voiceStream);
            const voiceGain = context.createGain();
            voiceGain.gain.value = 0.7;
            source2.connect(voiceGain).connect(destination);
        }

        if (participantAudioStream) {
            const source3 = context.createMediaStreamSource(participantAudioStream);
            const participantGain = context.createGain();
            participantGain.gain.value = 0.7;
            source3.connect(participantGain).connect(destination);
        }

        return destination.stream.getAudioTracks();
    };

    const initiateResourceAccess = async () => {
        try {
            /**
             *  These config are supported by chrome 91 and above. In firefox, only audio and video are supported.
             *   displaySurface: 'browser'  Capture the entire screen or monitor.
             *   audio: 'true' Capture audio.
             *   preferCurrentTab: 'true' Capture the currently active tab.
             *   selfBrowserSurface: 'include' Capture the browser window.
             *   systemAudio: 'include' Capture system audio.
             *   surfaceSwitching: 'include' Capture the entire screen or monitor.
             *   monitorTypeSurfaces: 'include' Capture the entire screen or monitor.
             */

            const displayMediaOptions = {
                video: {
                    displaySurface: 'monitor',
                },
                audio: true,
                // preferCurrentTab: true,
                // selfBrowserSurface: 'include',
                // systemAudio: 'include',
                // surfaceSwitching: 'exclude',
                // monitorTypeSurfaces: 'include',
            };
            const displayStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
            audioStreamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });

            if (displayStream.getAudioTracks().length === 0) {
                console.warn('No audio track found in display stream');
            }

            displayStreamRef.current = displayStream;
            setPermissionStatus(true);
        } catch (error) {
            if (typeof (setPermissionStatus) === 'function') setPermissionStatus(false, error);
            toastify('Failed to start recording. Please grant the necessary permissions.', 'error');
            setStopFlag(false);
        }
    };

    const startRecording = async () => {
        try {
            const combinedStream = new MediaStream([
                ...displayStreamRef.current.getVideoTracks(),
                ...mergeAudioStreams(displayStreamRef.current, audioStreamRef.current, participantAudio),
            ]);

            videoElementRef.current.srcObject = combinedStream;

            const recorder = new RecordRTC(combinedStream, { type: 'video', mimeType: 'video/mp4', recorderType: MediaStreamRecorder });
            addStreamStopListener(combinedStream, stopRecordingCallback);
            recorder.startRecording();
            recorderRef.current = recorder;
        } catch (error) {
            toastify(new Error(error).message, 'error');
            setStopFlag(false);
        }
    };

    const stopRecording = () => {
        if (recorderRef.current) {
            recorderRef.current.stopRecording(() => {
                const blob = recorderRef.current.getBlob();
                videoElementRef.current.srcObject = null;
                if (typeof upload === 'function') upload(blob);
            });
            streamRef.current?.getTracks().forEach((track) => track.stop());
            streamRef.current?.getAudioTracks().forEach((track) => track.stop());
        }
        setStopFlag(false);
    };

    const muteStreamAudio = () => {
        streamRef.current?.getAudioTracks().forEach((track) => {
            // eslint-disable-next-line no-param-reassign
            track.enabled = false;
        });
    };

    const stopRecordingCallback = () => {
        stopRecording();
    };

    const addStreamStopListener = (stream, callback) => {
        stream.addEventListener('ended', callback, false);
        stream.addEventListener('inactive', callback, false);
        stream.getTracks().forEach((track) => {
            track.addEventListener('ended', callback, false);
            track.addEventListener('inactive', callback, false);
        });
        streamRef.current = stream;
    };

    useImperativeHandle(ref, () => ({
        startRecording: initiateResourceAccess,
        stopRecording,
    }));

    useEffect(() => {
        if (participantAudio) {
            startRecording();
        }
    }, [participantAudio]);

    useEffect(() => {
        if (props.stopFlag) {
            stopRecording();
        }
    }, [props.stopFlag]);

    useEffect(() => {
        if (audioMuteFlag) {
            muteStreamAudio();
        }
    }, [audioMuteFlag]);

    // eslint-disable-next-line jsx-a11y/media-has-caption
    return <video ref={videoElementRef} style={{ display: 'none' }} />;
});

export default ScreenRecorder;

/* eslint-disable camelcase */
export const nameFormatter = (name) => {
    const { first_name, middle_name, last_name } = name;
    return `${first_name} ${middle_name ? `${middle_name} ` : ''}${last_name}`;
};

export const dobFormatter = (dob) => {
    const { year, month, day } = dob;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    return `${formattedDay}/${formattedMonth}/${year}`;
};

export const matchScoreFormatting = (score) => {
    if (score && !Number.isNaN(score)) return score.toFixed(1);
    return 0;
};

/**
 *
 * ReKycDetails
 *
 */

import React, { memo } from 'react';

import backArrow from '../../../assets/images/svgs/back-arrow.svg';

const ReKycDetails = (props) => {
    const { data = [], toggleHandler } = props;

    return (
        <div className='kob'>
            {
                toggleHandler
                    ? (
                        <div className='wlui-hw'>
                            <img role='presentation' className='wlui-backarrow' src={backArrow} alt='back' onClick={() => toggleHandler(false)} />
                            <div className='wlui-hw__hd'>Re-KYC Details</div>
                        </div>
                    )
                    : null
            }
            {
                data && Array.isArray(data) && data.map((labelValuesPair) => (
                    <div key={labelValuesPair?.label} className='kob-wrapper--v3'>
                        <div className='kob-wrapper__label--v2'>{labelValuesPair?.label || '-'} :</div>
                        <div className='kob-wrapper__value--v3'>
                            {
                                Array.isArray(labelValuesPair?.value) && labelValuesPair?.value?.length !== 0
                                    // eslint-disable-next-line react/no-danger
                                    ? <div className='kob-modal-link' dangerouslySetInnerHTML={{ __html: labelValuesPair?.value[0] }} /> : '-'
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default memo(ReKycDetails);

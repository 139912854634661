/**
 *
 * VKycAttemptList
 *
 */

import React, { memo, useState } from 'react';

import { Table } from '../../../components';
import backArrow from '../../../assets/images/svgs/back-arrow.svg';

import VKycAttemptCallInfoModal from '../Modals/VKycAttemptCallInfo';

import { VKYC_CUSTOMER_INFO_LABEL_DATA } from '../constants';

const VKycAttemptList = (props) => {
    const [isVKycAttemptCallInfoModalOpen, setIsVKycAttemptCallInfoModalOpen] = useState(false);
    const [selectedAttemptDetails, setSelectedAttemptDetails] = useState([]);

    const { data, toggleHandler, customerData } = props;

    return (
        <React.Fragment>
            <div className='kob'>
                {
                    toggleHandler
                        ? (
                            <div className='wlui-hw'>
                                <img role='presentation' className='wlui-backarrow' src={backArrow} alt='back' onClick={() => toggleHandler(false)} />
                                <div className='wlui-hw__hd'>Attempt Results</div>
                            </div>
                        )
                        : null
                }
                <div className='table-sr'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th'>Attempt Source</div>
                        <div className='table-sr__th f15Wrapper'>Status</div>
                        <div className='table-sr__th f15Wrapper'>Substatus</div>
                        <div className='table-sr__th'>Created At</div>
                        <div className='table-sr__th'>Updated At</div>
                        <div className='table-sr__th'>Actions</div>
                    </div>
                    <div className='table-sr__tlc'>
                        {
                            data.map((item, idx) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className='table-sr__tlw' key={idx}>
                                    <div className='table-sr__tl'>{item.attemptInfo.source}</div>
                                    <div className='table-sr__tl f15Wrapper'>{item.attemptInfo.status}</div>
                                    <div className='table-sr__tl f15Wrapper'>{item.attemptInfo.subStatus}</div>
                                    <div className='table-sr__tl'>{item.attemptInfo.createdTs}</div>
                                    <div className='table-sr__tl'>{item.attemptInfo.updatedTs}</div>
                                    <div
                                        className='link table-sr__tl'
                                        role='presentation'
                                        onClick={() => {
                                            setIsVKycAttemptCallInfoModalOpen(true);
                                            setSelectedAttemptDetails(item.callInfoList);
                                        }}
                                    >
                                        View Details
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Table
                    header='Customer Details'
                    labelData={VKYC_CUSTOMER_INFO_LABEL_DATA}
                    contentData={customerData}
                    extClasses={{
                        container: 'kob-vkyc-table-sr',
                    }}
                />
            </div>
            <VKycAttemptCallInfoModal
                visible={isVKycAttemptCallInfoModalOpen}
                data={selectedAttemptDetails}
                toggleHandler={setIsVKycAttemptCallInfoModalOpen}
            />
        </React.Fragment>
    );
};

export default memo(VKycAttemptList);

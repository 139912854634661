/*
 *
 * TicketSummary actions
 *
 */

import { GET_TICKET_INFO, GET_TICKET_NOTES, SET_TICKET_ID } from './constants';

export const getTicketInfo = data => ({
    type: GET_TICKET_INFO,
    data
});

export const getTicketNotes = data => ({
    type: GET_TICKET_NOTES,
    data
});

export const setTicketId = data => ({
    type: SET_TICKET_ID,
    data,
});

import { createSelector } from 'reselect';

const selectCallDetails = state => state.get('callDetails');

const makeSelectRecordingInfo = () => createSelector(
    selectCallDetails,
    substate => substate.get('recordingInfo').toJS()
);

const makeSelectTranscriptInfo = () => createSelector(
    selectCallDetails,
    substate => substate.get('transcriptInfo').toJS()
);

export {
    makeSelectRecordingInfo,
    makeSelectTranscriptInfo,
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
/**
 *
 * DevActions
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import classNames from 'classnames';

import {
    Button, ExpandableCard, FormDropdown, FormWithSingleInput, Loader,
    MonorailIdInput, HorizontalIdNavbar,
} from '../../components';
import {
    // injectReducer, injectSaga,
    getRequiredFieldsFilledFlag, getModifiedParameterList,
    onFileClick, onFileChange, getModifiedParameterListV2,
} from '../../utils';
import { SUPPORTED_FILE_TYPES } from '../../utils/fileUploadEvents';
import { useMemoizedSelector } from '../../hooks';

import {
    getActionList, getParameterList, setParameterValue, executeAction, setMonorailId, setActionList,
} from './actions';
import {
    makeSelectLoading, makeSelectErr, makeSelectActionList, makeSelectParameterList,
    makeSelectExecuteInfo, makeSelectHasExecuteInfo, makeSelectMonorailInfo, selectIsParameterListV2,
} from './selectors';
import { COPY_REMARKS_TO_REASON_ACTIONS, NO_MAX_DATE_DEV_ACTIONS } from './constants';
import FormV2 from './V2';
import './style.scss';
import { useTransactionViewEvent } from '../TransactionViewV2/useTransactionViewEvent';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 500,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        backgroundColor: '#F5F5F5',
        wordBreak: 'break-word',
    },
};

const DevActions = (props) => {
    const {
        actionTypes, preFilledValues, extraActions, extClasses, containerKey, actionConfig, validateActions, onSuccessSubmit, hideNav = false,
        autoActionSelection = true,
    } = props;

    const dispatch = useDispatch();

    const loading = useMemoizedSelector(makeSelectLoading, [containerKey]);
    const err = useMemoizedSelector(makeSelectErr, [containerKey]);
    const actionList = useMemoizedSelector(makeSelectActionList, [containerKey]);
    const parameterList = useMemoizedSelector(makeSelectParameterList, [containerKey]);
    const executeInfo = useMemoizedSelector(makeSelectExecuteInfo, [containerKey]);
    const hasExecuteInfo = useMemoizedSelector(makeSelectHasExecuteInfo, [containerKey]);
    const monorailInfo = useMemoizedSelector(makeSelectMonorailInfo, [containerKey]);

    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(true);
    const [formErrLabel, setFormErrLabel] = useState('');
    const [selectedAction, setSelectedAction] = useState('');
    const [selectedActionObj, setSelectedActionObj] = useState('');
    const [showActionDD, setActionDD] = useState(false);
    const [showExecuteInfoModal, setExecuteInfoModal] = useState(false);
    const [fileUploadErrLabel, setFileUploadErrLabel] = useState('');
    const transactionViewEvent = useTransactionViewEvent();
    // not using local state and reading from reducer
    // const [monorailId, setLocalMonorailId] = useState('');

    const { id: monorailId } = monorailInfo;

    // set new ticket to true on initial render or when no monorail id present
    const [newTicket, setNewTicket] = useState(!monorailId);
    // set navbar items to empty array on initial render or when no monorail id present
    const [navItems, setNavItems] = useState((monorailId && [{ id: monorailId }]) || []);
    const reasonItem = parameterList.find((item) => item.type === 100);
    const isNewParamerterList = useMemoizedSelector(selectIsParameterListV2, [containerKey]);
    const [formV2payload, setFormV2payload] = useState({});

    useEffect(() => {
        // if action is already passed through props
        if (actionTypes) {
            // need to change array of strings to array of objects so that they can be rendered properly
            const formattedActionList = actionTypes.map((item) => ({ value: item, label: item }));
            dispatch(setActionList({ actionList: formattedActionList, containerKey }));
            setSelectedAction(actionTypes[0]);
            dispatch(getParameterList({
                action: actionTypes[0], monorailId: '', preFilledValues, containerKey,
            }));
        }
    }, [dispatch, actionTypes, preFilledValues, containerKey]);

    useEffect(() => {
        if (actionConfig && !(actionList && actionList.length)) {
            dispatch(getActionList({ actionConfig, containerKey }));
        }
    }, [dispatch, containerKey, actionConfig]);

    useEffect(() => {
        if (actionConfig && actionList && actionList.length > 0) {
            if (autoActionSelection) setSelectedAction(actionList[0]?.value);
            dispatch(getParameterList({
                action: actionList[0]?.value, monorailId: '', preFilledValues, containerKey, actionConfig,
            }));
        }
    }, [dispatch, containerKey, actionConfig, actionList]);

    useEffect(() => {
        setSelectedActionObj({
            label: selectedAction,
            value: selectedAction,
        });
    }, [selectedAction]);

    const containerClasses = classNames('devactions-cr', extClasses && extClasses.container);

    const resetMonorailId = () => {
        setNewTicket(true);
        setNavItems([]);
        dispatch(setMonorailId({ value: '', containerKey }));
        // setLocalMonorailId('');
    };

    const handleMonorailIdSubmit = (value) => {
        // common steps
        setIsSearchCardExpanded(true);
        setNewTicket(false);
        setNavItems([{ id: value }]);
        dispatch(setMonorailId({ value, containerKey }));
        // setLocalMonorailId(value);

        setSelectedAction('');
        setActionDD(true);
        dispatch(getActionList({ monorailId: value, containerKey }));
    };

    const renderIdNavbar = (
        <HorizontalIdNavbar
            isFixed
            hasSingleItem
            isNewTicketActive={newTicket}
            activeId={monorailId}
            navItems={navItems}
            onItemCloseIconClick={resetMonorailId}
            hideNav={hideNav}
        />
    );

    if (!actionTypes && !monorailId && !actionConfig) {
        return (
            <React.Fragment>
                {renderIdNavbar}
                <MonorailIdInput submitMonorailId={handleMonorailIdSubmit} />
            </React.Fragment>
        );
    }

    const handleActionChange = (data) => {
        setSelectedAction(data.value);

        setActionDD(false);
        setFormErrLabel('');

        dispatch(getParameterList({
            action: data.value, monorailId, preFilledValues, containerKey, actionConfig,
        }));
    };

    const showExecuteConfirmationModal = (e) => {
        e.preventDefault();

        const hasAllRequiredFieldsFilled = getRequiredFieldsFilledFlag(parameterList);

        if (!hasAllRequiredFieldsFilled) {
            setFormErrLabel('Please fill all the * marked fields');
            return;
        }

        const error = validateActions && validateActions(parameterList);

        if (error && !error.status) {
            setFormErrLabel(error.message);
            return;
        }

        setFormErrLabel('');
        setExecuteInfoModal(true);
    };

    const handleSubmit = () => {
        const modifiedParameters = !isNewParamerterList ? getModifiedParameterList(parameterList) : getModifiedParameterListV2(formV2payload);

        setFormErrLabel('');
        setExecuteInfoModal(false);

        const callExecuteAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(executeAction({
                    action: selectedAction,
                    parameters: modifiedParameters,
                    monorailId,
                    resolve,
                    containerKey,
                    actionConfig,
                }));
                transactionViewEvent.fireTransactionViewEvents(
                    transactionViewEvent.events.DEV_ACTION.SUBMIT_ACTION,
                    {
                        caseId: actionConfig?.caseId,
                        actionTaken: selectedAction,
                    },
                );
            } catch (e) {
                reject(e);
            }
        });

        callExecuteAction().then(() => {
            if (extraActions) {
                extraActions.forEach((action) => dispatch(action));
            }

            if (onSuccessSubmit) {
                onSuccessSubmit();
            }
        });
    };

    const renderParameterValue = (item) => {
        /**
         * STRING = 1;
         * INTEGER = 2;
         * DOUBLE = 3;
         * TIMESTAMP = 4;
         * DROPDOWN = 5;
         * MONEY = 6;
         * NAME = 7;
         * PHONE_NUMBER = 8;
         * MULTI_SELECT_DROPDOWN = 9;
         * PAGE_CONTEXT = 10;
         * FILE = 11;
         * FILE_LIST = 12;
         * MULTILINE_TEXT = 13;
         * REASON = 100;
         */
        switch (item.type) {
            case 4: {
                let maxDate = new Date();
                let showYearDropdown = false;

                if (NO_MAX_DATE_DEV_ACTIONS.includes(selectedAction)) {
                    maxDate = null;
                    showYearDropdown = true;
                }

                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'devactions-fc--m0',
                            inputField: 'devactions-fc__ip',
                        }}
                        labelId={item.name}
                        formType='dob'
                        input={{
                            value: item.value,
                            disabled: item.isDisabled,
                            onChange: (val) => {
                                dispatch(setParameterValue({ item, value: val, containerKey }));
                            },
                            maxDate,
                            minDate: new Date('10-01-2020'),
                            showYearDropdown,
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }

            case 5: {
                // modify the option payload based on a single select option request
                const options = item.options?.map((option) => ({
                    label: option,
                    value: option,
                }));

                // modify the selected option payload based on a single select option request
                const selectedValue = item?.value ? {
                    label: item.value,
                    value: item.value,
                } : null;

                return (
                    <FormDropdown
                        options={options}
                        input={{
                            defaultMenuIsOpen: item.isDDOpen,
                            value: selectedValue,
                            disabled: item.isDisabled,
                            onChange: ({ value }) => {
                                dispatch(setParameterValue({ item, value, containerKey }));
                            },
                        }}
                        cacheKey='devactions-single'
                        extStyles={{
                            container: 'devactions-dd',
                        }}
                    />
                );
            }

            case 7: {
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'devactions-fc--m0',
                        }}
                        labelId={item.name}
                        formType='name'
                        input={{
                            value: item.value,
                            disabled: item.isDisabled,
                            onChange: (key) => (e) => {
                                dispatch(setParameterValue({
                                    item, key, value: e.target.value, containerKey,
                                }));
                            },
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }

            case 9: {
                return (
                    <FormDropdown
                        isMulti
                        options={item.options}
                        input={{
                            value: item.value,
                            disabled: item.isDisabled,
                            onChange: (value) => {
                                dispatch(setParameterValue({ item, value, containerKey }));
                            },
                        }}
                        cacheKey='devactions'
                        extStyles={{
                            container: 'devactions-dd',
                        }}
                    />
                );
            }

            case 13: {
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'devactions-fc--m0',
                            inputField: 'devactions-fc__ip devactions-fc__rsv',
                        }}
                        labelId={item.name}
                        formType='textarea'
                        input={{
                            autosize: true,
                            value: item.value,
                            disabled: item.isDisabled,
                            onChange: (e) => {
                                dispatch(setParameterValue({
                                    item, value: e.target.value, containerKey,
                                }));
                            },
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }

            case 1: {
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'devactions-fc--m0',
                            inputField: 'devactions-fc__ip',
                        }}
                        labelId={item.name}
                        input={{
                            value: item.value,
                            disabled: item.isDisabled,
                            onChange: (e) => {
                                const { value } = e.target;
                                dispatch(setParameterValue({ item, value, containerKey }));

                                // HACK: For particular dev actions, copy text from 'remarks' field to 'reason' field
                                if (COPY_REMARKS_TO_REASON_ACTIONS.includes(selectedAction) && item.name === 'Remarks') {
                                    dispatch(setParameterValue({ item: reasonItem, value, containerKey }));
                                }
                            },
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }

            case 11:
            case 12: {
                const setValue = (value) => {
                    dispatch(setParameterValue({
                        item,
                        value,
                        containerKey,
                    }));
                };

                const fileParams = {
                    item,
                    setErrLabel: setFileUploadErrLabel,
                    setValue,
                };

                const { type } = item;

                return (
                    <div className='fcWrapper'>
                        <input
                            type='file'
                            className='devactions-fc__ip'
                            accept={SUPPORTED_FILE_TYPES}
                            onClick={onFileClick(fileParams)}
                            onChange={onFileChange(fileParams)}
                            multiple={type === 12}
                        />
                        {
                            fileUploadErrLabel ? (
                                <div className='err-label devactions-fuel'>{fileUploadErrLabel}</div>
                            ) : null
                        }
                    </div>
                );
            }

            default: { // 2, 3, 6, 8, 100
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'devactions-fc--m0',
                            inputField: 'devactions-fc__ip',
                        }}
                        labelId={item.name}
                        input={{
                            value: item.value,
                            disabled: item.isDisabled,
                            onChange: (e) => {
                                dispatch(setParameterValue({ item, value: e.target.value, containerKey }));
                            },
                        }}
                        onFormSubmit={showExecuteConfirmationModal}
                    />
                );
            }
        }
    };

    const renderParameterList = () => {
        if (parameterList.length === 0) return null;

        return (
            <div className='devactions-sc-ocr'>
                <div className='devactions-sc-ocr__hl'>Search Parameters</div>
                {
                    parameterList
                        .filter((item) => item.type !== 10) // filter page context option type
                        .map((item, idx) => (
                            <div className='devactions-sc-owr' key={item.label}>
                                <div className='devactions-sc-owr__ol'>{`${idx + 1}. ${item.label}${!item.isOptional ? '*' : ''}`}</div>
                                <div className='devactions-sc-owr__ov'>
                                    {renderParameterValue(item)}
                                </div>
                            </div>
                        ))
                }
            </div>
        );
    };

    const renderExecuteInfo = () => {
        // if action types or actionConfig is passed, do not show execute info
        if (!hasExecuteInfo || actionTypes || actionConfig) {
            return null;
        }

        if (executeInfo.length === 0) {
            return (<div className='devactions-src'>No execute info found for the above search parameters.</div>);
        }

        return (
            <div className='devactions-src'>
                {JSON.stringify(executeInfo, null, 4)}
            </div>
        );
    };

    /**
     * Not needed since we are now using multi select dropdown library
     * const { length: actionListLength } = actionList;
     * const actionListDDHeight = getDropdownHeight(actionListLength);
     */

    return (
        <React.Fragment>
            {/* show monorail id tab only if present. When not present causes side effect on clicking the cross button */}
            {/* show monorail id only if action types is not passed */}
            {!actionTypes && monorailId && (
                renderIdNavbar
            )}

            <div className={containerClasses}>
                <ExpandableCard
                    // remove position: absolute if devactions is used in another component
                    extClasses={actionTypes ? 'devactions-ec1' : 'devactions-ec'}
                    heading='Select Actions to perform'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='devactions-sc'>
                        <div className='frcWrapper devactions-sc-rw1'>
                            <FormDropdown
                                label='Action'
                                options={actionList}
                                input={{
                                    value: selectedAction ? selectedActionObj : null,
                                    onChange: handleActionChange,
                                    placeholder: 'Choose...',
                                    autoFocus: showActionDD,
                                }}
                                cacheKey='devactions-action-dropdown'
                                extStyles={{
                                    container: 'devactions-fcwsd',
                                    label: 'devactions-fcwsd__lb',
                                }}
                            />
                        </div>
                        {
                            selectedAction ? (
                                <div>
                                    {
                                        isNewParamerterList
                                            ? (
                                                <FormV2
                                                    parameterList={parameterList}
                                                    containerKey={containerKey}
                                                    validateActions={validateActions}
                                                    setExecuteInfoModal={setExecuteInfoModal}
                                                    selectedAction={selectedAction}
                                                    setFormV2payload={setFormV2payload}
                                                />
                                            ) : (
                                                <div>
                                                    {
                                                        renderParameterList()
                                                    }
                                                    {formErrLabel ? <div className='err-label devactions-el'>{formErrLabel}</div> : null}
                                                    <Button
                                                        v2
                                                        extClasses={`devactions-sc-cta ${formErrLabel && 'devactions-sc-cta--err'}`}
                                                        primary
                                                        label='Submit'
                                                        onClick={showExecuteConfirmationModal}
                                                    />
                                                </div>
                                            )
                                    }
                                </div>
                            ) : null
                        }
                    </div>
                </ExpandableCard>
                {renderExecuteInfo()}
                {err ? (<div className='container-err-label devactions-crel err-label'>{err}</div>) : null}
            </div>
            {
                showExecuteInfoModal ? (
                    <Modal
                        isOpen
                        style={customStylesForModal}
                        contentLabel='Action Execution Modal'
                    >
                        <div className='devactions-modal__hl'>
                            Are you sure you want to perform {selectedAction}?
                        </div>
                        <div className='devactions-modal__sbhl'>
                            This change is irreversible and affects production data
                        </div>
                        <div className='frcsbWrapper devactions-modal__ctas'>
                            <Button
                                v2
                                secondary
                                label='Submit'
                                onClick={handleSubmit}
                            />
                            <Button
                                v2
                                primary
                                label='Cancel'
                                onClick={() => setExecuteInfoModal(false)}
                            />
                        </div>
                    </Modal>
                ) : null
            }
            {/* show loader only if action types is not passed */}
            <Loader visible={!actionTypes && loading} />
        </React.Fragment>
    );
};

export default memo(DevActions);

/**
 * @file Redux actions for UserActions container
 */

import {
    CALLBACK_USER, GET_USER_EXPORTED_APP_LOGS, RAISE_PAY_REQUEST, SET_USER_EXPORTED_APP_LOGS,
    TRIGGER_PUSH_NOTIFICATION_APPLOGS, GET_ALLOWED_FC_VALUE, SET_PAYOUT_TYPE, SET_ALLOWED_FC_VALUE,
} from './constants';

export const callbackUser = (data) => ({
    type: CALLBACK_USER,
    data,
});

export const raisePayRequest = (data) => ({
    type: RAISE_PAY_REQUEST,
    data,
});

export const triggerPushNotification = (data) => ({
    type: TRIGGER_PUSH_NOTIFICATION_APPLOGS,
    data,
});

export const getUserExportedAppLogsAction = (data) => ({
    type: GET_USER_EXPORTED_APP_LOGS,
    data,
});

export const setUserExportedAppLogsAction = (data) => ({
    type: SET_USER_EXPORTED_APP_LOGS,
    data,
});

export const getAllowedFiCoinsValueAction = (data) => ({
    type: GET_ALLOWED_FC_VALUE,
    data,
});

export const setAllowedFiCoinsValueAction = (data) => ({
    type: SET_ALLOWED_FC_VALUE,
    data,
});

export const setPayoutTypeAction = (data) => ({
    type: SET_PAYOUT_TYPE,
    data,
});

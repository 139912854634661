import {
    GET_CARDS_INFO, GET_UPI_INFO, TOGGLE_UPI_STATUS, SET_UPI_TOGGLE_CONFIRM_SCREEN, INITIATE_SUSPEND_CARD,
    INITIATE_BLOCK_CARD, SET_SUSPEND_CARD_CONFIRM_SCREEN, SET_BLOCK_CARD_CONFIRM_SCREEN, GET_CARD_INFO,
    TOGGLE_SCREEN, GET_CARD_TRACKING_INFO, GET_CARD_LIST, UPDATE_FREE_CARD_REPLACEMENT, GET_FOREX_TXN_INFO,
    GET_CARD_REQUEST_STATUS, SET_CARD_REQUEST_STATUS, ERR_CARD_REQUEST_STATUS, GET_CARD_AMC, SET_CARD_AMC, ERR_CARD_AMC,
} from './constants';

export const getCardsInfo = (data) => ({
    type: GET_CARDS_INFO,
    data,
});

export const initiateSuspendCard = (data) => ({
    type: INITIATE_SUSPEND_CARD,
    data,
});

export const setSuspendConfirmScreen = (data) => ({
    type: SET_SUSPEND_CARD_CONFIRM_SCREEN,
    data,
});

export const initiateBlockCard = (data) => ({
    type: INITIATE_BLOCK_CARD,
    data,
});

export const setBlockConfirmScreen = (data) => ({
    type: SET_BLOCK_CARD_CONFIRM_SCREEN,
    data,
});

export const toggleScreen = (data) => ({
    type: TOGGLE_SCREEN,
    data,
});

export const getCardInfo = (data) => ({
    type: GET_CARD_INFO,
    data,
});

export const getCardTrackingInfo = (data) => ({
    type: GET_CARD_TRACKING_INFO,
    data,
});

export const getCardRequestStatus = (data) => ({
    type: GET_CARD_REQUEST_STATUS,
    data,
});
export const setCardRequestStatus = (data) => ({
    type: SET_CARD_REQUEST_STATUS,
    data,
});
export const errCardRequestStatus = (data) => ({
    type: ERR_CARD_REQUEST_STATUS,
    data,
});

export const getCardAMC = (data) => ({
    type: GET_CARD_AMC,
    data,
});
export const setCardAMC = (data) => ({
    type: SET_CARD_AMC,
    data,
});
export const errCardAMC = (data) => ({
    type: ERR_CARD_AMC,
    data,
});

export const getUPIInfo = (data) => ({
    type: GET_UPI_INFO,
    data,
});

export const toggleUPIStatus = (data) => ({
    type: TOGGLE_UPI_STATUS,
    data,
});

export const setUPIToggleConfirmScreen = (data) => ({
    type: SET_UPI_TOGGLE_CONFIRM_SCREEN,
    data,
});

export const getCardList = (data) => ({
    type: GET_CARD_LIST,
    data,
});

export const updateFreeCardReplacement = (data) => ({
    type: UPDATE_FREE_CARD_REPLACEMENT,
    data,
});

export const getForexTxnInfo = (data) => ({
    type: GET_FOREX_TXN_INFO,
    data,
});

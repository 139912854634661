/**
 * @file WealthOps Selectors
 */

import { createSelector } from 'reselect';

import { WEALTH_OPS_KEY } from './constants';

export const makeSelectWealthOpsQueueCases = () => createSelector(
    (state) => state.getIn([WEALTH_OPS_KEY, 'casesList']),
    (subState) => {
        const casesList = subState.toJS();
        const uiTableList = [];

        casesList.forEach((item, index) => {
            const curRowObj = {
                id: item.id,
                sr_no: index + 1,
            };

            // Add certain items for PAYLOAD_TYPE_WEALTH_DATA
            if (item.wealth_data) {
                curRowObj.actor_id = item.wealth_data.actor_id;
                curRowObj.to_verify = item.wealth_data.item_types
                    .map((subItem) => subItem.replace('ITEM_TYPE_', ''))
                    .join('\n');
            }

            // Add certain items for PAYLOAD_TYPE_KYC_DOCKET
            if (item.kyc_docket_data) {
                const kycDocketData = item.kyc_docket_data;
                curRowObj.actor_id = kycDocketData.actor_id;
                curRowObj.pan = kycDocketData.pan;
                const kraFormPayload = kycDocketData.kra_form_payload;
                const { year, month, day } = kraFormPayload.upload_date;
                const uploadDate = `${day}/${month}/${year}`;
                curRowObj.upload_date = uploadDate;
                curRowObj.kra_status = kraFormPayload.kra_status;
            }

            curRowObj.ogData = item;

            uiTableList.push(curRowObj);
        });

        return uiTableList;
    },
);

export const selectWealthOpsCurCase = () => createSelector(
    (state) => state.getIn([WEALTH_OPS_KEY, 'curCase']),
    (subState) => subState.toJS(),
);

export const selectWealthOpsCurView = () => createSelector(
    (state) => state.getIn([WEALTH_OPS_KEY, 'curView']),
    (subState) => subState,
);

export const selectWealthOpsPayloadType = () => createSelector(
    (state) => state.getIn([WEALTH_OPS_KEY, 'payloadType']),
    (subState) => subState.toJS(),
);

export const selectWealthOpsLivenessVideoUrl = () => createSelector(
    (state) => state.getIn([WEALTH_OPS_KEY, 'livenessVideoUrl']),
    (subState) => subState,
);

export const selectWealthOpsLoading = () => createSelector(
    (state) => state.getIn([WEALTH_OPS_KEY, 'loading']),
    (subState) => subState,
);

export const selectWealthOpsErr = () => createSelector(
    (state) => state.getIn([WEALTH_OPS_KEY, 'err']),
    (subState) => subState,
);

export const selectWealthOpsQueueFilters = () => createSelector(
    (state) => state.getIn([WEALTH_OPS_KEY, 'queueFilters']),
    (subState) => subState.toJS(),
);

export const selectWealthOpsPendingReviewCount = createSelector(
    (state) => state.getIn([WEALTH_OPS_KEY, 'pendingReviewCount']),
    (subState) => subState,
);

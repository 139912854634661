/**
 *
 * AccessLevelDropdown - Dropdown to select or toggle the access level once user selects any access level in the App entry step
 *
 */

import React, { memo, useContext, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';

import { MetaInfoContext } from '../../../context';
import {
    Button, DropdownContent, DropdownWrapper, Modal,
} from '../../../components';
import { ICONS_URL } from '../../../constants/assets';
// import arrowIcon from '../../assets/images/svgs/chevron-bottom.svg';

import { makeSelectAgentInfo, makeSelectAccessLevel } from '../selectors';

import './style.scss';

// custom styles for confirmation modal
const customModalStyles = {
    content: {
        padding: '48px 80px 40px',
        width: 700,
    },
};

const AccessLevelDropdown = (props) => {
    const metaInfo = useContext(MetaInfoContext);

    // reducer state variables
    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);
    const selectedAccessLevel = useSelector(makeSelectAccessLevel(), shallowEqual);

    // local state variables
    const [isDDOpen, setDDOpen] = useState(false);
    const [accessLevelDD, setAccessLevelDD] = useState('');
    const [showConfirmationModal, setConfirmationModal] = useState(false);

    const { extClasses = {}, setAccessLevel } = props;
    const { appConfig: { enableAgentManagementV2 } } = metaInfo;

    const { accessLevelList } = agentInfo;

    const labelClass = classNames('main-label', extClasses.label);
    const imgClass = classNames(
        {
            'transform-transition': true,
            'arrow-icon': !isDDOpen,
            'collapsed-arrow-icon': isDDOpen,
        },
        extClasses.arrow,
    );

    const toggleDropdown = () => {
        setDDOpen(!isDDOpen);
    };

    const onDropdownClickHandler = (value) => () => {
        if (selectedAccessLevel !== value) {
            setAccessLevelDD(value);
            setConfirmationModal(true);
        }

        toggleDropdown();
    };

    const onModalProceedCTAClickHandler = () => {
        setAccessLevel(accessLevelDD);
        setConfirmationModal(false);
    };

    const onModalCancelCTAClickHandler = () => {
        setConfirmationModal(false);
    };

    // do not render anything if Agent Management v2 flow is not enabled
    if (!enableAgentManagementV2) {
        return null;
    }

    // do not render anything if the access level is not selected or only 1 role is available
    if (!selectedAccessLevel || accessLevelList?.length === 1) {
        return null;
    }

    return (
        <React.Fragment>
            <DropdownWrapper
                visible={selectedAccessLevel && isDDOpen}
                onOutsideClickHandler={toggleDropdown}
                extClasses={{
                    container: 'accesslevel-dd',
                }}
            >
                {
                    selectedAccessLevel ? (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <div onClick={toggleDropdown}>
                            <span className={labelClass}>{selectedAccessLevel}</span>
                            <img className={imgClass} src={ICONS_URL.CHEVRON_BOTTOM} alt='arrow-icon' />
                        </div>
                    ) : null
                }
                <DropdownContent
                    visible={isDDOpen}
                    extClasses={{
                        container: 'content-container',
                    }}
                >
                    <div className='user-action-wrapper'>
                        {
                            accessLevelList.map((item) => (
                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                                <div
                                    key={item.label}
                                    className='label'
                                    onClick={onDropdownClickHandler(item.value)}
                                >
                                    {item.value}
                                </div>
                            ))
                        }
                    </div>
                </DropdownContent>
            </DropdownWrapper>
            <Modal
                visible={showConfirmationModal}
                modalStyles={customModalStyles}
                contentLabel='Access Level Change Confirmation Modal'
            >
                <div className='accesslevel-dd-modal__lb1'>
                    You are trying to change your role
                </div>
                <div className='accesslevel-dd-modal__lb2'>
                    You may lose the data that you already accessed on the dashboard. Do you still want to proceed?
                </div>
                <div className='frcsbWrapper'>
                    <Button
                        v2
                        primary
                        label='Proceed'
                        extClasses='accesslevel-dd-modal__cta'
                        onClick={onModalProceedCTAClickHandler}
                    />
                    <Button
                        v2
                        secondary
                        label='Cancel'
                        extClasses='accesslevel-dd-modal__cta'
                        onClick={onModalCancelCTAClickHandler}
                    />
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default memo(AccessLevelDropdown);

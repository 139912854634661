/**
 *
 * AgentCreateForm
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { reduxForm, Form } from 'redux-form/immutable';
import { pathOr } from 'ramda';

import FormBuilder from '../../components/FormBuilder';
import { Button } from '../../components';
import { validators } from '../../utils';
import { UserDetailsContext } from '../../context';

import { createAgent, makeRequest, getAgentInfo, updateAgent } from '../../api/requestFunctions';
import toastify from '../../utils/toastify';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import { AGENT_PATH } from '../App/routes';
import { getAgentInfo as getAgentInfoApi } from '../App/actions';

import { setInitialValues, getAccessLevel as getAccessLevelApi } from './actions';
import {
    makeSelectAgentCreateForm, makeSelectAgentCreateFormValues, makeSelectAgentCreateFormInitialValues, makeSelectAccessLevel,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import { agentFormStructure } from './utils';

import './style.scss';

export class AgentCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            status: [],
        }
    }

    componentDidMount = () => {
        const { isEdit, setInitialValue, getAccessLevel } = this.props;

        // get access level list of casbin mappings
        getAccessLevel();

        if (isEdit) {
            this.loadAgentInfo();
        } else {
            setInitialValue({});
        }
    }

    loadAgentInfo = () => {
        const { match, setInitialValue } = this.props;
        const agentEmail = match.params.agentEmail;

        makeRequest(getAgentInfo(agentEmail), {
            onSuccess: (response) => {
                setInitialValue(response);
            },
            onError: (error) => {
                console.log(error);
            }
        })
    }

    onSubmit = (agentData, userInfo) => () => {
        const { isEdit, match, getAgentInfoFromApp } = this.props;
        const agentEmail = match.params.agentEmail;

        let requestData = agentData.toJS();
        requestData = {
            ...requestData,
            agent_status: requestData && requestData.agent_status ? requestData.agent_status.value : null,
            access_level: requestData && requestData.access_level ? requestData.access_level.value : null,
            agent_type: requestData && requestData.agent_type ? requestData.agent_type.value : null,
            ticket_scope: requestData && requestData.ticket_scope ? requestData.ticket_scope.value : null,
            freshdesk_agent_type: requestData && requestData.freshdesk_agent_type ? requestData.freshdesk_agent_type.value : null,
        };

        const { email_id, phone_number, name, access_level, agent_type, ozonetel_id } = requestData;

        if (!validators.isInputtedEmailValid(email_id)) {
            toastify('Please enter a valid email id', 'error');
            return;
        }

        if (
            !phone_number
            || !validators.isInputtedNumberValid(phone_number.country_code)
            || !validators.isInputtedMobileNumberValid(phone_number.national_number)
        ) {
            toastify('Please enter a valid phone number', 'error');
            return;
        }

        if (
            !name
            || !name.first_name
            || !name.last_name
            || !validators.isInputtedAlphabetStringValid(name.first_name)
            || !validators.isInputtedAlphabetStringValid(name.last_name)
            || (name.middle_name && !validators.isInputtedAlphabetStringValid(name.middle_name))
        ) {
            toastify('Please enter a valid name', 'error');
            return;
        }

        if (!agent_type) {
            toastify('Please select agent type', 'error');
            return;
        }

        if (!access_level) {
            toastify('Please select access level', 'error');
            return;
        }

        if (ozonetel_id && !validators.isInputtedStringValid(ozonetel_id)) {
            toastify('Please enter a valid ozonetel id', 'error');
            return;
        }

        const apiRequest = isEdit
            ? updateAgent(agentEmail, requestData)
            : createAgent(requestData);
        const successToastLabel = `Agent ${isEdit ? 'Updated' : 'Created'} Successfully`;
        const failureToastLabel = `Failed to ${isEdit ? 'update' : 'create'} Agent`;

        makeRequest(apiRequest, {
            onSuccess: (response) => {
                const success = pathOr(false, ['success'], response);

                if (success) {
                    toastify(successToastLabel, 'success');

                    // need to fetch agent info again incase user is making changes in self info
                    if (isEdit && userInfo.emailId === agentEmail) {
                        getAgentInfoFromApp({ email: agentEmail });
                    }
                } else {
                    toastify(failureToastLabel, 'error');
                }

                this.setState({ submitted: true, status: pathOr([], ['status'], response) });
            },
            onError: (error) => {
                toastify(failureToastLabel, 'error');
                this.setState({ submitted: true, status: pathOr([], ['response', 'status'], error) });
            }
        });
    }

    renderStatus = (status) => {
        return (<div className='status-container'>
            <div className='heading'>Status:</div>
            {status.map((row) => (<div className='status-row'>
                <div className='label'>{row.label}:</div>
                <div className={`message ${row.success ? 'success' : 'error'}`}>
                    {row.success ? 'success' : row.error}
                </div>
            </div>))}
        </div>)

    }

    render() {
        const { isEdit, history, formValues, handleSubmit, accessLevelList } = this.props;
        const { submitted, status } = this.state;
        const label = isEdit ? 'Update Agent' : 'Create Agent';

        return (
            <UserDetailsContext.Consumer>
                {({ basicInfo }) => (
                    <div className='agent-create-form'>
                        <div className='header-bar'>
                            <h2>{label}</h2>
                            <Button
                                secondary
                                label='Back to list'
                                onClick={() => history.push(AGENT_PATH)}
                            />
                        </div>
                        <Form onSubmit={handleSubmit(this.onSubmit(formValues, basicInfo))}>
                            <FormBuilder formStructure={agentFormStructure(formValues.toJS(), isEdit, accessLevelList)} />
                            {
                                submitted && status.length > 0 && this.renderStatus(status)
                            }
                            <Button
                                primary
                                label={label}
                                extClasses='cta--primary'
                                onClick={handleSubmit(this.onSubmit(formValues, basicInfo))}
                            />
                        </Form>
                    </div>
                )}
            </UserDetailsContext.Consumer>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    agentcreateform: makeSelectAgentCreateForm(),
    formValues: makeSelectAgentCreateFormValues(),
    initialValues: makeSelectAgentCreateFormInitialValues(),
    accessLevelList: makeSelectAccessLevel(),
});

const mapDispatchToProps = dispatch => ({
    setInitialValue: data => dispatch(setInitialValues(data)),
    getAgentInfoFromApp: data => dispatch(getAgentInfoApi(data)),
    getAccessLevel: data => dispatch(getAccessLevelApi(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'agentCreateForm', reducer });
const withSaga = injectSaga({ key: 'agentCreateForm', saga });

export default compose(
    withReducer,
    withSaga,
    withConnect,
    reduxForm({
        form: 'AgentCreateForm',
        enableReinitialize: true
    })
)(AgentCreateForm);

/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * Forex Transaction
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { FormWithSingleInput, Loader, Table, Toggle } from '../../../../components';
import { getForexTxnInfo } from '../../actions';
import { getSelectForexTxnInfo } from '../../selectors';

import './styles.scss';
import { setCustomerAuth } from '../../../Tickets/actions';
import { SCREEN } from '../../../Tickets/constants';
import { hasAccessToThePage } from '../../../../utils/helpers';

const FOREX_TABLE_LABEL_DATA = [
    {
        label: 'International Txn Amt',
        key: 'txnAmount',
    },
    {
        label: 'Transaction Date',
        key: 'txnTime',
    },
    {
        label: 'Tiering',
        key: 'userTier',
    },
    {
        label: 'Forex Applied',
        key: 'forexMarkup',
    },
    {
        label: 'Forex Refund',
        key: 'refundAmount',
    },
    {
        label: 'Refund Status',
        key: 'refundStatus',
    },
    {
        label: 'Refund Date',
        key: 'refundTime',
    },
];

const DebitCard = (props) => {
    const { ticketId, activeTicket, index } = props;
    const [startDate, setStartDate] = useState(null);
    const [showForexTxn, setShowForexTxn] = useState(false);
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const forexTxnInfo = useSelector(getSelectForexTxnInfo(), shallowEqual);

    const { data, loading } = forexTxnInfo[ticketId] ?? {};

    useEffect(() => {
        if (showForexTxn) {
            // checking for sensitivity
            if (hasAccessToThePage(activeTicket, SCREEN.LESS_SENSITIVE_SCREEN)) {
                dispatch(getForexTxnInfo({ ticketId, startDate }));
            } else {
                // enabling customer auth screen
                dispatch(setCustomerAuth({
                    index,
                    isActive: true,
                    sensitivityLevel: SCREEN.LESS_SENSITIVE_SCREEN,
                    authPathname: pathname,
                }));
            }
        }
    }, [showForexTxn, startDate]);

    const getPaginatedForexTransactionList = ({ prev, next }) => {
        dispatch(getForexTxnInfo({
            ticketId, prev, next,
        }));
    };

    const renderForexTransactionResults = () => {
        if (!data) {
            return null;
        }

        const { refunds, next: nextToken, prev: prevToken } = data ?? {};

        if (refunds?.length === 0) {
            return <div className='table-nsr'>No Transaction found!</div>;
        }

        return (
            <div className='forex-txn-table-container'>
                <Table
                    v2
                    labelData={FOREX_TABLE_LABEL_DATA}
                    contentData={refunds}
                    prevToken={prevToken}
                    nextToken={nextToken}
                    paginatedClickHandler={getPaginatedForexTransactionList}
                />
            </div>
        );
    };

    const handleDateChange = () => (value) => {
        setStartDate(value);
    };

    const renderDateBlock = () => (
        <div className='forex-txn-fc'>
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'forex-txn-fc--m0',
                    label: 'forex-txn-fc__lb',
                }}
                label='From Date'
                labelId='from-date'
                formType='dob'
                input={{
                    value: startDate,
                    onChange: handleDateChange(),
                    maxDate: new Date(),
                }}
            />
        </div>
    );

    return (
        <div className='forex-txn'>
            <div className='forex-txn-heading-container'>
                <div className='forex-txn-heading'>{showForexTxn ? 'Hide' : 'Show'} Forex Txns</div>
                <Toggle
                    value={showForexTxn}
                    onChange={() => setShowForexTxn(!showForexTxn)}
                />
            </div>
            {showForexTxn
            && (
                <React.Fragment>
                    {renderDateBlock()}
                    {renderForexTransactionResults()}
                </React.Fragment>
            )}

            <Loader visible={loading} />
        </div>
    );
};

export default memo(DebitCard);

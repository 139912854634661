/*
 *
 * Deposits reducer
 *
 */

import { fromJS } from 'immutable';
import {
    SET_SEARCH_PARAMS, RESET_SEARCH_PARAMS, GET_DEPOSITS_LIST, SET_DEPOSITS_LIST, ERR_DEPOSITS_LIST,
    GET_DEPOSIT_REQUEST_LIST, SET_DEPOSIT_REQUEST_LIST, ERR_DEPOSIT_REQUEST_LIST,
    GET_DEPOSIT_TXN_LIST, SET_DEPOSIT_TXN_LIST, ERR_DEPOSIT_TXN_LIST,
} from './constants';
import {
    ATTACH_ENTITY_TICKET, SET_ATTACH_ENTITY_TICKET, ERR_ATTACH_ENTITY_TICKET
} from '../Tickets/constants';

const initialState = fromJS({
    searchParameters: {},
    depositsInfo: {},
});

function depositsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SEARCH_PARAMS: {
            const {
                ticketId, key, value, fromCreatedDate, toCreatedDate, fromMaturityDate, toMaturityDate,
            } = action.data;

            if (key && value) {
                return state.setIn(['searchParameters', ticketId, key], value);
            }

            if (fromCreatedDate && toCreatedDate) {
                return state
                    .setIn(['searchParameters', ticketId, 'fromCreatedDate'], fromJS(fromCreatedDate))
                    .setIn(['searchParameters', ticketId, 'toCreatedDate'], fromJS(toCreatedDate));
            }

            if (fromMaturityDate && toMaturityDate) {
                return state
                    .setIn(['searchParameters', ticketId, 'fromMaturityDate'], fromJS(fromMaturityDate))
                    .setIn(['searchParameters', ticketId, 'toMaturityDate'], fromJS(toMaturityDate));
            }

            return state;
        }

        case RESET_SEARCH_PARAMS: {
            const { ticketId } = action.data;

            return state
                .setIn(['searchParameters', ticketId], fromJS({}))
                .setIn(['depositsInfo', ticketId], fromJS({}));
        }

        case GET_DEPOSITS_LIST:
        case GET_DEPOSIT_REQUEST_LIST:
        case GET_DEPOSIT_TXN_LIST: {
            const { ticketId } = action.data;

            return state
                .setIn(['depositsInfo', ticketId, 'loading'], true);
        }

        case SET_DEPOSITS_LIST: {
            const { ticketId, depositList } = action.data;

            return state
                .setIn(['depositsInfo', ticketId, 'loading'], false)
                .setIn(['depositsInfo', ticketId, 'hasDepositList'], true)
                .setIn(['depositsInfo', ticketId, 'depositList'], fromJS(depositList));
        }

        case ERR_DEPOSITS_LIST:
        case ERR_DEPOSIT_REQUEST_LIST:
        case ERR_DEPOSIT_TXN_LIST: {
            const { ticketId } = action.data;

            return state
                .setIn(['depositsInfo', ticketId, 'loading'], false);
        }

        case SET_DEPOSIT_REQUEST_LIST: {
            const { ticketId, depositRequestList } = action.data;

            return state
                .setIn(['depositsInfo', ticketId, 'loading'], false)
                .setIn(['depositsInfo', ticketId, 'hasDepositRequestList'], true)
                .setIn(['depositsInfo', ticketId, 'depositRequestList'], fromJS(depositRequestList));
        }

        case SET_DEPOSIT_TXN_LIST: {
            const { ticketId, depositTxnList, prev, next } = action.data;

            return state
                .setIn(['depositsInfo', ticketId, 'loading'], false)
                .setIn(['depositsInfo', ticketId, 'depositTxnList', 'data'], fromJS(depositTxnList))
                .setIn(['depositsInfo', ticketId, 'depositTxnList', 'prev'], fromJS(prev))
                .setIn(['depositsInfo', ticketId, 'depositTxnList', 'next'], fromJS(next));
        }

        case ATTACH_ENTITY_TICKET: {
            const { ticketId } = action.data;

            return state
                .setIn(['depositsInfo', ticketId, 'loading'], true);
        }

        case SET_ATTACH_ENTITY_TICKET:
        case ERR_ATTACH_ENTITY_TICKET: {
            const { ticketId } = action.data;

            return state
                .setIn(['depositsInfo', ticketId, 'loading'], false);
        }

        default:
            return state;
    }
}

export default depositsReducer;

/**
 * @file Selectors for SalaryWhitelistB2B
 */
import { createSelector } from 'reselect';
import { SALARY_WHITELIST_B2B_KEY } from './constants';

export const selectTabData = createSelector(
    (state) => state.getIn([SALARY_WHITELIST_B2B_KEY, 'tabs']),
    (subState) => subState?.toJS() || {},
);

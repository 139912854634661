/**
 * @file AvailableTransactionTable
 * Shows all the transactions that are available for verification request
 */

import React, { memo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, Table } from '../../../../components';
import { useMemoizedSelector } from '../../../../hooks';
import { getRaiseVerificationRequestEligibleTxnsAction } from '../../actions';

import {
    RAISE_VERIFICATION_REQUEST_STATUS, SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY,
} from '../../constants';
import { selectEligibleTxns, selectSalaryAccountUserIdentifierDetails, selectSelectedActor } from '../../selectors';

const QUEUE_LABEL_DATA = [
    {
        label: 'Transaction ID',
        key: 'txnId',
    },
    {
        label: 'Remitter Name',
        key: 'remitterName',
    },
    {
        label: 'Fallback Remitter Name',
        key: 'piRemitterName',
    },
    {
        label: 'Payment Protocol',
        key: 'paymentProtocol',
    },
    {
        label: 'Transparticular',
        key: 'particulars',
    },
    {
        label: 'Transaction Date',
        key: 'txnTime',
    },
    {
        label: 'Percentage Difference From Last Salary',
        key: 'percentageDiffFromLastSalaryString',
    },
    {
        label: 'Is amount divisible by 1000',
        key: 'isAmountDivisibleBy1000',
    },
];

const isTransactionsExists = (txn) => txn && Array.isArray(txn) && txn.length > 0;

const AvailableTransactionTable = (props) => {
    const { handleRaiseRequest, handleActivateSalaryBenefits } = props;

    const dispatch = useDispatch();

    const eligibleTxnInfo = useSelector(selectEligibleTxns, shallowEqual);
    const { data: eligibleTxns, prev: prevToken, next: nextToken } = eligibleTxnInfo;

    // Addition of probability against remitter name and fallback remitter name
    const [modifiedEligibleTxns, setModifiedEligibleTxns] = useState([]);
    useEffect(() => {
        if (isTransactionsExists(eligibleTxns)) {
            const updatedEligibleTxns = eligibleTxns.map((txn) => {
                const modifiedTxn = { ...txn };
                modifiedTxn.remitterName = `${txn?.remitterName ? txn?.remitterName : ''} ${txn?.probabilityOfRemitterNameBeingMerchant
                    ? `<b>(${txn?.probabilityOfRemitterNameBeingMerchant})</b>`
                    : ''
                }`;
                modifiedTxn.piRemitterName = `${txn?.piRemitterName ? txn?.piRemitterName : ''} ${txn?.probabilityOfPIRemitterNameBeingMerchant
                    ? `<b>(${txn?.probabilityOfPIRemitterNameBeingMerchant})</b>`
                    : ''
                }`;
                return modifiedTxn;
            });

            setModifiedEligibleTxns(updatedEligibleTxns);
        }
    }, [eligibleTxns]);

    const userIdentifier = useMemoizedSelector(selectSalaryAccountUserIdentifierDetails, [SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY]);
    const selectedActor = useSelector(selectSelectedActor, shallowEqual);

    const [sortOrder, setSortOrder] = useState('');

    const statusComplete = RAISE_VERIFICATION_REQUEST_STATUS.complete.value;

    const makePayloadAndGetVerificationTxns = (params = {}) => {
        const payload = {
            actorId: selectedActor?.actorId,
            ...params,
        };
        dispatch(getRaiseVerificationRequestEligibleTxnsAction(payload));
    };

    const getPaginatedVerificationRequestTxns = ({ prev, next }) => {
        makePayloadAndGetVerificationTxns({
            prev, next, userIdentifier, sortOrder,
        });
    };

    const getSortingDataBasedOnSortingOrder = (value) => {
        setSortOrder(value);
    };

    const renderActions = (item) => (
        <div className='fccWrapper'>
            <Button
                link
                label='Raise a request'
                onClick={() => handleRaiseRequest(item?.txnId, statusComplete)}
            />
            <div>-</div>
            <Button
                link
                label='Activate Salary benefits'
                onClick={() => handleActivateSalaryBenefits(item?.txnId)}
            />
        </div>
    );

    return (
        modifiedEligibleTxns && modifiedEligibleTxns.length > 0 ? (
            <React.Fragment>
                <div className='heading1 mb-30'>TRANSACTIONS AVAILABLE FOR VERIFICATION</div>
                <Table
                    v2
                    labelData={QUEUE_LABEL_DATA}
                    contentData={modifiedEligibleTxns}
                    renderActionableColumn={renderActions}
                    getSortingDataBasedOnSortingOrder={getSortingDataBasedOnSortingOrder}
                    prevToken={prevToken}
                    nextToken={nextToken}
                    paginatedClickHandler={getPaginatedVerificationRequestTxns}
                    extClasses={{
                        container: 'salops-uetb__cr',
                    }}
                />
            </React.Fragment>
        ) : null
    );
};

export default memo(AvailableTransactionTable);

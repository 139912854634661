/**
 * @file CxRiskReview Sagas
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getErrMsg, getQueryStringFromObject, toastify,
} from '../../utils';
import {
    TICKET_LIST_FOR_SHERLOCK_ENDPOINT,
} from '../../api/routes';

import { setCxRiskReviewErrAction, setCxRiskReviewRequestsForActorAction, setRiskOpsCxTicketsAction } from './actions';
import { GET_CX_RISK_REVIEW_REQUESTS_FOR_ACTOR, GET_CX_RISK_REVIEW_TICKETS } from './constants';
import { getRiskOpsRequestsByActorIdSaga } from '../RiskOps/saga';

function* getRiskOpsCxTicketsSaga(action) {
    const queryStr = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKET_LIST_FOR_SHERLOCK_ENDPOINT}?${queryStr}`,
        );

        yield put(setRiskOpsCxTicketsAction(response));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setCxRiskReviewErrAction(getErrMsg(e)));
    }
}

function* getCxRiskReviewRequestsForActorSaga(action) {
    const { actorId, payloadType } = action.data;
    try {
        let queryString = getQueryStringFromObject({ payloadType });
        queryString = `actorId=${btoa(actorId)}&${queryString}`;

        const { review_elements: reviewElements } = yield call(getRiskOpsRequestsByActorIdSaga, { data: { queryString } });

        yield put(setCxRiskReviewRequestsForActorAction({ actorId, reviewElements }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setCxRiskReviewErrAction(getErrMsg(e)));
    }
}

export default function* cxRiskReviewSaga() {
    yield all(
        [
            yield takeLatest(GET_CX_RISK_REVIEW_TICKETS, getRiskOpsCxTicketsSaga),
            yield takeLatest(GET_CX_RISK_REVIEW_REQUESTS_FOR_ACTOR, getCxRiskReviewRequestsForActorSaga),
        ],
    );
}

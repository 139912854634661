/*
 *
 * @file Facematch review selectors
 *
 */

import { createSelector } from 'reselect';

import { USS_FACEMATCH_REVIEW } from './constants';

export const selectUSSFMLoading = createSelector(
    (state) => state.getIn([USS_FACEMATCH_REVIEW, 'loading']),
    (substate) => substate,
);

export const selectUSSFMErr = createSelector(
    (state) => state.getIn([USS_FACEMATCH_REVIEW, 'err']),
    (substate) => substate,
);

export const selectUSSFMPayloadTypeList = createSelector(
    (state) => state.getIn([USS_FACEMATCH_REVIEW, 'payloadTypes']),
    (substate) => substate.toJS(),
);

export const selectUSSFMFacematchPayloadType = createSelector(
    (state) => state.getIn([USS_FACEMATCH_REVIEW, 'facematchPayloadType']),
    (substate) => substate.toJS(),
);

export const selectUSSFMReviewActionList = createSelector(
    (state) => state.getIn([USS_FACEMATCH_REVIEW, 'reviewActions']),
    (substate) => substate.toJS(),
);

export const selectUSSFMReviewActionType = createSelector(
    (state) => state.getIn([USS_FACEMATCH_REVIEW, 'reviewActionType']),
    (substate) => substate.toJS(),
);

export const selectUSSFMReviewReasonList = createSelector(
    (state) => state.getIn([USS_FACEMATCH_REVIEW, 'reviewReasons']),
    (substate) => substate.toJS(),
);

export const selectUSSFMReviewReasonType = createSelector(
    (state) => state.getIn([USS_FACEMATCH_REVIEW, 'reviewReasonType']),
    (substate) => substate.toJS(),
);

export const selectUSSFMReviewItemList = createSelector(
    (state) => state.getIn([USS_FACEMATCH_REVIEW, 'reviewItemList']),
    (substate) => substate.toJS(),
);

export const selectUSSFMFacematchView = createSelector(
    (state) => state.getIn([USS_FACEMATCH_REVIEW, 'facematchView']),
    (subState) => subState,
);

export const selectUSSFMReviewItemDetail = createSelector(
    (state) => state.getIn([USS_FACEMATCH_REVIEW, 'reviewItemDetails']),
    (subState) => subState,
);

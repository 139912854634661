import {
    ADD_SOP_NODE_RESPONSE, COMPLETED_SOP_FLOW, COMPLETE_SOP_FLOW, DEL_SOP_NODE, ERR_IN_COMPLETING_SOP_FLOW, ERR_PROFILE,
    ERR_SOP_DETAILS, ERR_SOP_NODE, GET_PROFILE, GET_SOP_DETAILS, GET_SOP_NODE, SET_PROFILE, SET_SOP_DETAILS, SET_SOP_NODE,
    SET_TICKET_ID, SET_CALLER_ID, GET_USER_TICKETS, SET_USER_TICKETS, ERR_USER_TICKETS,
    GET_RECENT_ACTIVITIES, SET_RECENT_ACTIVITIES, ERR_RECENT_ACTIVITIES,
    GET_ISSUE_CATEGORY_DETAILS, SET_ISSUE_CATEGORY_DETAILS, ERR_ISSUE_CATEGORY_DETAILS,
    GET_RECENT_ACTIVITY_DETAILS, SET_RECENT_ACTIVITY_DETAILS, ERR_RECENT_ACTIVITY_DETAILS,
} from './constants';

export const getProfileAction = (data) => ({
    type: GET_PROFILE,
    data,
});

export const setProfileAction = (data) => ({
    type: SET_PROFILE,
    data,
});

export const errProfileAction = (data) => ({
    type: ERR_PROFILE,
    data,
});

export const getActivitiesAction = (data) => ({
    type: GET_RECENT_ACTIVITIES,
    data,
});

export const setActivitiesAction = (data) => ({
    type: SET_RECENT_ACTIVITIES,
    data,
});

export const errActivitiesAction = (data) => ({
    type: ERR_RECENT_ACTIVITIES,
    data,
});

export const getActivityDetailsAction = (data) => ({
    type: GET_RECENT_ACTIVITY_DETAILS,
    data,
});

export const setActivityDetailsAction = (data) => ({
    type: SET_RECENT_ACTIVITY_DETAILS,
    data,
});

export const errActivityDetailsAction = (data) => ({
    type: ERR_RECENT_ACTIVITY_DETAILS,
    data,
});

export const getIssueCategoryDetailsAction = (data) => ({
    type: GET_ISSUE_CATEGORY_DETAILS,
    data,
});

export const setIssueCategoryDetailsAction = (data) => ({
    type: SET_ISSUE_CATEGORY_DETAILS,
    data,
});

export const errIssueCategoryDetailsAction = (data) => ({
    type: ERR_ISSUE_CATEGORY_DETAILS,
    data,
});

export const getUserTicketsAction = (data) => ({
    type: GET_USER_TICKETS,
    data,
});

export const setUserTicketsAction = (data) => ({
    type: SET_USER_TICKETS,
    data,
});

export const errUserTicketsAction = (data) => ({
    type: ERR_USER_TICKETS,
    data,
});

export const getSOPDetailsAction = (data) => ({
    type: GET_SOP_DETAILS,
    data,
});

export const setSOPDetailsAction = (data) => ({
    type: SET_SOP_DETAILS,
    data,
});

export const errSOPDetailsAction = (data) => ({
    type: ERR_SOP_DETAILS,
    data,
});

export const setCallerIdAction = (data) => ({
    type: SET_CALLER_ID,
    data,
});

export const setTicketIdAction = (data) => ({
    type: SET_TICKET_ID,
    data,
});

export const getSOPNodeAction = (data) => ({
    type: GET_SOP_NODE,
    data,
});

export const setSOPNodeAction = (data) => ({
    type: SET_SOP_NODE,
    data,
});

export const addSOPNodeResponseAction = (data) => ({
    type: ADD_SOP_NODE_RESPONSE,
    data,
});

export const delSOPNodeAction = (data) => ({
    type: DEL_SOP_NODE,
    data,
});

export const errSOPNodeAction = (data) => ({
    type: ERR_SOP_NODE,
    data,
});

export const completeSopFlowAction = (data) => ({
    type: COMPLETE_SOP_FLOW,
    data,
});

export const completedSopFlowAction = (data) => ({
    type: COMPLETED_SOP_FLOW,
    data,
});

export const errInCompletingSopFlowAction = (data) => ({
    type: ERR_IN_COMPLETING_SOP_FLOW,
    data,
});

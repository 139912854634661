/**
 *
 * TableModal - Modal to render a table in a pop up
 *
 */

import React, { memo } from 'react';

import { Button, Modal, Table } from '../../../components';

const TableModal = (props) => {
    const {
        children, visible, data, toggleHandler, error,
    } = props;

    if (!data) return null;

    return (
        <Modal
            visible={visible}
            modalStyles={{
                content: {
                    width: '900px',
                    left: '45%',
                },
            }}
        >
            <Table
                objKey='header_key'
                rowDataVersion={2}
                labelData={data?.columnData}
                contentData={data?.rowData}
                header={data?.tableName}
                errLabel={error}
            />
            {children && <div className='p-20'>{children}</div>}
            <Button
                primary
                extClasses='debitcard-modal-bwr'
                label='Close'
                onClick={() => toggleHandler(null)}
            />
        </Modal>
    );
};

export default memo(TableModal);

/**
 *
 * WaitlistApproval
 *
 */

import React, { useContext } from 'react';
import { compose } from 'redux';

import { UserDetailsContext } from '../../context';
import { injectReducer, injectSaga } from '../../utils';

import AdminApprover from './AdminApprover';
import Approver from './Approver';
import reducer from './reducer';
import saga from './saga';

const WaitlistApproval = () => {
    const userInfo = useContext(UserDetailsContext);
    const {
        basicInfo: {
            accessLevelInfo: {
                isWaitlistApprover, isWaitlistAdminApprover,
            },
        },
    } = userInfo;

    if (isWaitlistApprover) {
        return <Approver />;
    }

    return <AdminApprover />;
};

const withReducer = injectReducer({ key: 'waitlist', reducer });
const withSaga = injectSaga({ key: 'waitlist', saga });

export default compose(
    withReducer,
    withSaga,
)(WaitlistApproval);

/**
 * @file Manages the reducer state & function for App Global component
 */

import { fromJS } from 'immutable';
import { isEmpty } from 'ramda';

import { SET_LOGIN_URL, RESET_AGENT_INFO, SET_ACCESS_LEVEL } from '../constants/actions';
import { META_INFO_INITIAL_VALUES } from '../context';

import {
    GET_AUTH_STATUS, SET_AUTH_STATUS, SET_AGENT_INFO, SET_AUTH_STATUS_LOADER,
    ERR_AGENT_INFO, SET_SECURITY_TOKEN, SET_META_INFO,
    SET_ACCESS_LEVEL_LIST, SET_BANNER_LIST, ERR_BANNER_LIST, SET_BANNERS_VISIBILITY,
} from './constants';
import { getFormattedAccessLevel } from './utils';

const initialState = fromJS({
    loading: {
        authStatus: true,
        agentInfo: false,
    },
    err: {
        agentInfo: '',
        selectedAccessLevel: '',
    },
    hasAgentInfo: false,
    // need to have access level info as we are reading some properties from it
    agentInfo: {
        accessLevelInfo: {},
    },
    cspNonce: '',
    loginUrl: '',
    selectedAccessLevel: '',
    metaInfo: META_INFO_INITIAL_VALUES,
    accessLevelList: [],
    bannerList: {},
    showBanners: true,
});

function appReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_AUTH_STATUS:
            return state.setIn(['loading', 'authStatus'], true);

        case SET_AUTH_STATUS: {
            const { cspNonce } = action.data;

            return state
                .setIn(['loading', 'authStatus'], false)
                .setIn(['loading', 'agentInfo'], true)
                .set('cspNonce', cspNonce);
        }

        case SET_META_INFO: {
            const { metaInfo } = action.data;

            // do not change the state incase meta info is empty from server
            if (isEmpty(metaInfo)) {
                return state;
            }

            return state.set('metaInfo', fromJS(metaInfo));
        }

        case SET_AGENT_INFO: {
            const { agentInfo } = action.data;
            const { selectedAccessLevel, metaInfo } = state.toJS();

            const { appConfig: { enableAgentManagementV2 } } = metaInfo;

            let modifiedSelectedAccessLevel = selectedAccessLevel;
            let errInSelectedAccessLevel = '';

            // if the selected role is not available in the new roles, reset the flow - only happens when refreshing the agent info
            if (enableAgentManagementV2) {
                if (selectedAccessLevel && !agentInfo.accessLevelStr.split(', ').includes(selectedAccessLevel)) {
                    modifiedSelectedAccessLevel = '';
                    errInSelectedAccessLevel = 'The role that you have previously selected is no longer available, please select any other role';
                }
            }

            const accessLevelInfo = getFormattedAccessLevel(modifiedSelectedAccessLevel);
            const modifiedAgentInfo = {
                ...agentInfo,
                accessLevelInfo,
            };

            return state
                .setIn(['loading', 'agentInfo'], false)
                .set('hasAgentInfo', true)
                .set('agentInfo', fromJS(modifiedAgentInfo))
                .set('selectedAccessLevel', modifiedSelectedAccessLevel)
                .setIn(['err', 'selectedAccessLevel'], errInSelectedAccessLevel);
        }

        case RESET_AGENT_INFO: {
            return state
                .setIn(['loading', 'agentInfo'], false)
                .set('hasAgentInfo', false)
                .set('agentInfo', fromJS({ accessLevelInfo: {} }))
                .set('selectedAccessLevel', '')
                .setIn(['err', 'selectedAccessLevel'], '');
        }

        case ERR_AGENT_INFO: {
            const { description } = action.data;

            return state
                .setIn(['loading', 'agentInfo'], false)
                .setIn(['err', 'agentInfo'], description)
                .set('hasAgentInfo', false)
                .set('agentInfo', fromJS(initialState.toJS().agentInfo));
        }

        case SET_AUTH_STATUS_LOADER: {
            const { flag } = action.data;

            return state.setIn(['loading', 'authStatus'], flag);
        }

        case SET_LOGIN_URL: {
            const { loginUrl } = action.data;

            return state.set('loginUrl', loginUrl);
        }

        case SET_SECURITY_TOKEN: {
            const { cspNonce } = action.data;

            return state.set('cspNonce', cspNonce);
        }

        case SET_ACCESS_LEVEL: {
            const { agentInfo } = state.toJS();
            const { accessLevel } = action.data;

            const accessLevelInfo = getFormattedAccessLevel(accessLevel);
            const modifiedAgentInfo = {
                ...agentInfo,
                accessLevelInfo,
            };

            return state
                .set('selectedAccessLevel', accessLevel)
                .set('agentInfo', fromJS(modifiedAgentInfo));
        }

        case SET_ACCESS_LEVEL_LIST: {
            const { accessLevelList } = action.data;

            return state.set('accessLevelList', fromJS(accessLevelList));
        }

        case SET_BANNER_LIST: {
            const { bannerList: { data, prev, next } } = action.data;

            return state
                .setIn(['bannerList', 'data'], fromJS(data))
                .setIn(['bannerList', 'prev'], fromJS(prev))
                .setIn(['bannerList', 'next'], fromJS(next));
        }

        case ERR_BANNER_LIST:
            return state
                .setIn(['bannerList', 'data'], fromJS([]))
                .setIn(['bannerList', 'prev'], fromJS({}))
                .setIn(['bannerList', 'next'], fromJS({}));

        case SET_BANNERS_VISIBILITY: {
            const { showBanners } = action.data;

            return state.set('showBanners', showBanners);
        }

        default:
            return state;
    }
}

export default appReducer;

/**
 * @file Routes for SherlockV2
 */
export const ROOT_V2_PATH = '/v2';

// Nav Menu Flow Routes
export const HOME_PATH = `${ROOT_V2_PATH}/home`;
export const SEARCH_PATH = `${ROOT_V2_PATH}/search`;
export const SCRIPTS_PATH = `${ROOT_V2_PATH}/scripts`;
export const SOP_PATH = `${ROOT_V2_PATH}/sops`;
export const TICKETS_PATH = `${ROOT_V2_PATH}/tickets`;
export const VKYC_PATH = `${ROOT_V2_PATH}/vkyc`;
export const VKYC_AUDITOR_PATH = `${ROOT_V2_PATH}/vkyc-auditor`;

// Scripts Flow Routes
export const VIEW_SCRIPT_PATH = `${SCRIPTS_PATH}/:scriptId`;
export const SEARCH_SCRIPTS_PATH = `${SCRIPTS_PATH}/search`;

// SOP Flow Routes
export const VIEW_SOP_USER_PATH = `${SOP_PATH}/profile`;
export const VIEW_SOP_USER_TICKETS_PATH = `${VIEW_SOP_USER_PATH}/tickets`;
export const VIEW_SOP_USER_DETAILS_PATH = (params = {}) => `${VIEW_SOP_USER_TICKETS_PATH}/${params?.ticketId || ':ticketId'}`;
export const VIEW_SOP_TREE_PATH = `${SOP_PATH}/:sopId`;

/**
 * UserDetailsById
 * Component to fetch the user details by some identifier. e.g. account number, customer id, etc
 * It's getting used for Biz Admin flow
 */

import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import {
    Button, FormWithSingleInput, Loader, MonorailIdInput, HorizontalIdNavbar,
} from '../../components';
import {
    injectReducer, injectSaga, validators,
} from '../../utils';
import backArrow from '../../assets/images/svgs/back-arrow.svg';

import IdentifierHeader from './IdentifierHeader';
import { getUserInfo } from './utils';
import {
    setMonorailId, getUserDetails, setLocalUserDetails, setActiveId, closeNavItem,
} from './actions';
import {
    makeSelectUserDetails, makeSelectActiveId, makeSelectHorizontalIdNavbarItems,
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const UserDetailsById = () => {
    const dispatch = useDispatch();

    const userDetails = useSelector(makeSelectUserDetails(), shallowEqual);
    const monorailId = useSelector(makeSelectActiveId(), shallowEqual);
    const navItems = useSelector(makeSelectHorizontalIdNavbarItems(), shallowEqual);

    const [errLabel, setErrLabel] = useState('');
    const [newTicket, setNewTicket] = useState(true);

    let data;
    let hasData;
    let accountNumber;
    let reason;
    let loading;

    if (monorailId) {
        ({
            data, hasData, accountNumber, reason, loading,
        } = userDetails[monorailId]);
    }

    const onInputChange = (key) => (event) => {
        const { value } = event.target;

        dispatch(setLocalUserDetails({
            monorailId,
            key,
            value,
        }));
    };

    const onMonorailIdSubmit = (value) => {
        setNewTicket(false);
        dispatch(setMonorailId({ monorailId: value }));
    };

    // mark new tab as active & remove the other tab from active state
    const onNewTicketClick = () => {
        setNewTicket(true);
        dispatch(setActiveId({ id: '' }));
    };

    // mark the selected tab as active & remove the new tab from active state
    const onItemClick = (id) => {
        setNewTicket(false);
        dispatch(setActiveId({ id }));
    };

    // remove the selected tab data
    const onItemCloseIconClick = (id) => {
        // if we are closing the active nav item, then change the active id to new ticket
        if (monorailId === id) {
            setNewTicket(true);
            dispatch(setActiveId({ id: '' }));
        }

        dispatch(closeNavItem({ id }));
    };

    const onSubmitCTA = () => {
        if (!accountNumber || !reason) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        if (!validators.isInputtedStringValid(reason)) {
            setErrLabel('Please enter a valid reason');
            return;
        }

        setErrLabel('');
        dispatch(getUserDetails({ monorailId, accountNumber, reason }));
    };

    const renderUserDetails = () => {
        // eslint-disable-next-line no-shadow
        const { accountNumber, name } = data;
        let label;
        let id;

        if (accountNumber) {
            label = 'Account Number';
            id = accountNumber;
        }

        return (
            <React.Fragment>
                <IdentifierHeader
                    label={label}
                    id={id}
                    name={name}
                />
                <div className='udbi udbi--mt0'>
                    <div className='udbi-hwr'>
                        <img className='udbi-backarrow' src={backArrow} alt='Back Arrow' />
                        <div className='udbi-hwr__hd'>User Details</div>
                    </div>
                    {
                        getUserInfo(data).map((item) => (
                            <div className='udbi-cwr' key={item.label}>
                                <div className='udbi-cwr__cl'>{item.label}</div>
                                <div className='udbi-cwr__sp'>:</div>
                                <div className='udbi-cwr__cv'>{item.value}</div>
                            </div>
                        ))
                    }
                </div>
            </React.Fragment>
        );
    };

    const renderUserDetailsForm = () => (
        newTicket ? (
            <MonorailIdInput submitMonorailId={onMonorailIdSubmit} />
        ) : (
            <div className='udbi'>
                <FormWithSingleInput
                    label='Search By'
                    labelId='search-by'
                    input={{
                        value: 'Account Number',
                        disabled: true,
                    }}
                    extClasses={{
                        label: 'udbi-lb',
                    }}
                />
                <FormWithSingleInput
                    disableFormSubmitOnEnter
                    label='Enter Account Number*'
                    labelId='account-number'
                    input={{
                        value: accountNumber,
                        onChange: onInputChange('accountNumber'),
                    }}
                    extClasses={{
                        label: 'udbi-lb',
                    }}
                />
                <div className='form-container udbi-fc'>
                    <label
                        className='form-wrapper-label udbi-lb udbi-fwr-lb'
                        htmlFor='account-reason'
                    >
                        Enter Reason*
                    </label>
                    <textarea
                        id='account-reason'
                        className='udbi-ilb'
                        value={reason}
                        onChange={onInputChange('reason')}
                        rows={4}
                        cols={50}
                    />
                </div>
                <div className='err-label udbi-errlb'>{errLabel}</div>
                <Button
                    primary
                    label='View Details'
                    onClick={onSubmitCTA}
                    extClasses='udbi-cta'
                />
                <Loader visible={loading} />
            </div>
        )
    );

    return (
        <React.Fragment>
            <HorizontalIdNavbar
                isFixed
                isNewTicketActive={newTicket}
                activeId={monorailId}
                navItems={navItems}
                onItemClick={onItemClick}
                onItemCloseIconClick={onItemCloseIconClick}
                onNewTicketClick={onNewTicketClick}
            />
            {
                hasData ? (
                    renderUserDetails()
                ) : (
                    renderUserDetailsForm()
                )
            }
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'userDetailsById', reducer });
const withSaga = injectSaga({ key: 'userDetailsById', saga });

export default compose(
    withReducer,
    withSaga,
)(UserDetailsById);

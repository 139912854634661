/**
 *
 * DBStates
 *
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import {
    Button, ExpandableCard, FormDropdown, FormWithSingleInput, Loader,
    MonorailIdInput, HorizontalIdNavbar,
} from '../../components';
import {
    injectReducer, injectSaga, getRequiredFieldsFilledFlag, getModifiedParameterList,
    getEncodingCompatibilty,
} from '../../utils';

import {
    getServiceList, getEntityList, getOptionList, setOptionValue, getDBInfo,
    setMonorailId,
} from './actions';
import {
    makeSelectLoading, makeSelectErr, makeSelectServiceList, makeSelectEntityList,
    makeSelectOptionList, makeSelectDBInfo, makeSelectHasDBInfo, makeSelectMonorailInfo,
    makeSelectService, makeSelectEntity,
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const DBStates = () => {
    const dispatch = useDispatch();

    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const serviceList = useSelector(makeSelectServiceList(), shallowEqual);
    const entityList = useSelector(makeSelectEntityList(), shallowEqual);
    const optionList = useSelector(makeSelectOptionList(), shallowEqual);
    const dbInfo = useSelector(makeSelectDBInfo(), shallowEqual);
    const hasDBInfo = useSelector(makeSelectHasDBInfo(), shallowEqual);
    const monorailInfo = useSelector(makeSelectMonorailInfo(), shallowEqual);
    const selectedService = useSelector(makeSelectService(), shallowEqual);
    const selectedEntity = useSelector(makeSelectEntity(), shallowEqual);

    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(true);
    const [formErrLabel, setFormErrLabel] = useState('');
    // const [selectedService, setSelectedService] = useState('');
    const [showServiceDD, setServiceDD] = useState(false);
    // const [selectedEntity, setSelectedEntity] = useState('');
    const [showEntityDD, setEntityDD] = useState(false);
    const [reRenderEntityDD, setReRenderEntityDD] = useState(false);

    const { id: monorailId } = monorailInfo;

    // set new ticket to true on initial render or when no monorail id present
    const [newTicket, setNewTicket] = useState(!monorailId);
    // set navbar items to empty array on initial render or when no monorail id present
    const [navItems, setNavItems] = useState((monorailId && [{ id: monorailId }]) || []);

    useEffect(() => {
        if (serviceList.length === 0) {
            dispatch(getServiceList());
        }
    }, [dispatch, serviceList.length]);

    const resetMonorailId = () => {
        setNewTicket(true);
        setNavItems([]);
        dispatch(setMonorailId({ value: '' }));
    };

    const handleMonorailIdSubmit = (value) => {
        // setSelectedService('');
        // setSelectedEntity('');
        setIsSearchCardExpanded(true);
        setNewTicket(false);
        setNavItems([{ id: value }]);
        dispatch(setMonorailId({ value }));
        setServiceDD(true);
    };

    const renderIdNavbar = (
        <HorizontalIdNavbar
            isFixed
            hasSingleItem
            isNewTicketActive={newTicket}
            activeId={monorailId}
            navItems={navItems}
            onItemCloseIconClick={resetMonorailId}
        />
    );

    if (!monorailId) {
        return (
            <React.Fragment>
                {renderIdNavbar}
                <MonorailIdInput submitMonorailId={handleMonorailIdSubmit} />
            </React.Fragment>
        );
    }

    const handleServiceChange = (value) => {
        // setSelectedService(value);
        setServiceDD(false);
        // setSelectedEntity('');
        setEntityDD(true);
        setReRenderEntityDD(false);

        dispatch(getEntityList({ service: value, monorailId }));

        // HACK: to re-render the entity dropdown & open its options list.
        // Put a 400ms interval to accommodate some time that it will take to get the list from BE
        setTimeout(() => setReRenderEntityDD(true), 400);
    };

    const handleEntityChange = (value) => {
        // setSelectedEntity(value);
        setEntityDD(false);
        setFormErrLabel('');

        dispatch(getOptionList({ service: selectedService, entity: value, monorailId }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const hasAllRequiredFieldsFilled = getRequiredFieldsFilledFlag(optionList);

        if (!hasAllRequiredFieldsFilled) {
            setFormErrLabel('Please fill all the * marked fields');
            return;
        }

        const hasEncodingCompatibilty = getEncodingCompatibilty(optionList);

        if (!hasEncodingCompatibilty) {
            setFormErrLabel('Filled fields contains characters which are not supported');
            return;
        }

        const modifiedOptions = getModifiedParameterList(optionList, true);

        setFormErrLabel('');
        dispatch(getDBInfo({
            service: selectedService,
            entity: selectedEntity,
            options: modifiedOptions,
            monorailId,
        }));
        setIsSearchCardExpanded(false);
    };

    const renderOptionValue = (item) => {
        /**
         * STRING = 1;
         * INTEGER = 2;
         * DOUBLE = 3;
         * TIMESTAMP = 4;
         * DROPDOWN = 5;
         * MONEY = 6;
         * NAME = 7;
         * PHONE_NUMBER = 8;
         * MULTI_SELECT_DROPDOWN = 9;
         * PAGE_CONTEXT = 10;
         */
        switch (item.type) {
            case 4: {
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'dbstates-fc--m0',
                            inputField: 'dbstates-fc__ip',
                        }}
                        labelId={item.label}
                        formType='dob'
                        input={{
                            value: item.value,
                            onChange: (val) => {
                                dispatch(setOptionValue({ item, value: val }));
                            },
                            maxDate: new Date(),
                            minDate: new Date('10-01-2020'),
                        }}
                        onFormSubmit={handleSubmit}
                    />
                );
            }

            case 5: {
                // modify the option payload based on a single select option request
                const options = item.options?.map((option) => ({
                    label: option,
                    value: option,
                }));

                // modify the selected option payload based on a single select option request
                const selectedValue = item?.value ? {
                    label: item.value,
                    value: item.value,
                } : null;

                return (
                    <FormDropdown
                        options={options}
                        input={{
                            defaultMenuIsOpen: item.isDDOpen,
                            value: selectedValue,
                            disabled: item.isDisabled,
                            onChange: ({ value }) => {
                                dispatch(setOptionValue({ item, value, dd: false }));
                            },
                        }}
                        cacheKey='devactions-single'
                        extStyles={{
                            container: 'devactions-dd',
                        }}
                    />
                );
            }

            case 7: {
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'dbstates-fc--m0',
                        }}
                        labelId={item.label}
                        formType='name'
                        input={{
                            value: item.value,
                            onChange: (key) => (e) => {
                                dispatch(setOptionValue({ item, key, value: e.target.value }));
                            },
                        }}
                        onFormSubmit={handleSubmit}
                    />
                );
            }

            case 8: {
                return (
                    <div className='frcWrapper dbstates-fc__ip-ph'>
                        <FormWithSingleInput
                            hideErrLabel
                            disableFormSubmitOnEnter
                            extClasses={{
                                container: 'dbstates-fc--m0',
                                inputField: 'dbstates-fc__ip-ph-cc',
                            }}
                            labelId={`${item.name}-country-code`}
                            input={{
                                value: item.secondaryValue,
                                onChange: (e) => {
                                    dispatch(setOptionValue({ item, secondaryValue: e.target.value }));
                                },
                            }}
                            onFormSubmit={handleSubmit}
                        />
                        <FormWithSingleInput
                            hideErrLabel
                            disableFormSubmitOnEnter
                            extClasses={{
                                container: 'dbstates-fc--m0 dbstates-fc__ip-ph-no',
                                inputField: 'dbstates-fc__ip-ph-no',
                            }}
                            labelId={item.name}
                            input={{
                                value: item.value,
                                onChange: (e) => {
                                    dispatch(setOptionValue({ item, value: e.target.value }));
                                },
                            }}
                            onFormSubmit={handleSubmit}
                        />
                    </div>
                );
            }

            case 9: {
                return (
                    <FormDropdown
                        isMulti
                        options={item.options}
                        input={{
                            value: item.value,
                            onChange: (value) => {
                                dispatch(setOptionValue({ item, value }));
                            },
                        }}
                        cacheKey='dbstates'
                        extStyles={{
                            container: 'dbstates-idd',
                        }}
                    />
                );
            }

            default: { // 1, 2, 3, 6, 8
                return (
                    <FormWithSingleInput
                        hideErrLabel
                        disableFormSubmitOnEnter
                        extClasses={{
                            container: 'dbstates-fc--m0',
                            inputField: 'dbstates-fc__ip',
                        }}
                        labelId={item.name}
                        input={{
                            value: item.value,
                            onChange: (e) => {
                                dispatch(setOptionValue({ item, value: e.target.value }));
                            },
                        }}
                        onFormSubmit={handleSubmit}
                    />
                );
            }
        }
    };

    const renderOptionList = () => {
        if (optionList.length === 0) return null;

        return (
            <div className='dbstates-sc-ocr'>
                <div className='dbstates-sc-ocr__hl'>Search Parameters</div>
                {
                    optionList
                        .filter((item) => item.type !== 10) // filter page context option type
                        .map((item, idx) => (
                            <div className='dbstates-sc-owr' key={item.label}>
                                <div className='dbstates-sc-owr__ol'>
                                    {`${idx + 1}. ${item.label}${!item.isOptional ? '*' : ''}`}
                                </div>
                                <div className='dbstates-sc-owr__ov'>
                                    {renderOptionValue(item)}
                                </div>
                            </div>
                        ))
                }
            </div>
        );
    };

    const renderDBInfo = () => {
        if (!hasDBInfo) {
            return null;
        }

        if (dbInfo.length === 0) {
            return (
                <div className='dbstates-src'>
                    No DB info found for the above search parameters.
                </div>
            );
        }

        return (
            <div className='dbstates-src'>
                {JSON.stringify(dbInfo, null, 4)}
            </div>
        );
    };

    return (
        <React.Fragment>
            {renderIdNavbar}
            <div className='dbstates-cr'>
                <ExpandableCard
                    extClasses='dbstates-ec'
                    heading='Search for Database States'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='dbstates-sc'>
                        <div className='frcWrapper dbstates-sc-rw1'>
                            <FormDropdown
                                label='Service'
                                options={serviceList}
                                input={{
                                    value: selectedService.value ? selectedService : null,
                                    onChange: handleServiceChange,
                                    placeholder: 'Choose...',
                                    autoFocus: showServiceDD,
                                }}
                                cacheKey='dbstates-service-dropdown'
                                extStyles={{
                                    container: 'dbstates-fc',
                                    label: 'dbstates-fc__lb',
                                }}
                            />
                            {
                                selectedService.value && reRenderEntityDD ? (
                                    <FormDropdown
                                        label='Entity'
                                        options={entityList}
                                        input={{
                                            value: selectedEntity.value ? selectedEntity : null,
                                            onChange: handleEntityChange,
                                            placeholder: 'Choose...',
                                            autoFocus: showEntityDD,
                                        }}
                                        cacheKey='dbstates-entity-dropdown'
                                        extStyles={{
                                            container: 'dbstates-fc--ml-30',
                                            label: 'dbstates-fc__lb',
                                        }}
                                    />
                                ) : null
                            }
                        </div>
                        {
                            (selectedService.value && selectedEntity.value) ? (
                                <React.Fragment>
                                    {renderOptionList()}
                                    {formErrLabel ? <div className='err-label dbstates-el'>{formErrLabel}</div> : null}
                                    <Button
                                        extClasses={`dbstates-sc-cta ${formErrLabel && 'dbstates-sc-cta--err'}`}
                                        primary
                                        label='Search'
                                        onClick={handleSubmit}
                                    />
                                </React.Fragment>
                            ) : null
                        }
                    </div>
                </ExpandableCard>
                {renderDBInfo()}
                {err ? (<div className='container-err-label dbstates-crel'>{err}</div>) : null}
            </div>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'dbStates', reducer });
const withSaga = injectSaga({ key: 'dbStates', saga });

export default compose(
    withReducer,
    withSaga,
)(DBStates);

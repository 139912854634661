/**
 *
 * View list of wealth ops cases
 * Show a table of cases with details about what the user needs to verify like liveness review, document redaction review, document expiry review, etc.
 * User can click a particular row and view further information
 *
 */

import React, { memo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { AppDropdown, Button, Table } from '../../../components';
import { isObjEmpty } from '../../../utils/helpers';

import {
    makeSelectWealthOpsQueueCases, selectWealthOpsPayloadType, selectWealthOpsPendingReviewCount, selectWealthOpsQueueFilters,
} from '../selectors';
import {
    VIEW_SINGLE_WEALTH_OPS_CASE, QUEUE_PAYLOAD_TYPES, LIMIT_OPTIONS, WEALTH_OPS_ALL_CASE_TABLE_LABEL_DATA,
    PAYLOAD_TYPE_WEALTH_DATA, PAYLOAD_TYPE_KYC_DOCKET,
} from '../constants';
import {
    setWealthOpsCurCaseAction, setWealthOpsCurViewAction, setWealthOpsPayloadTypeAction, setWealthOpsQueueFiltersAction,
} from '../actions';
import { useMemoizedSelector } from '../../../hooks';
import WealthKYCDocketFilterInput from '../WealthKYCDocketFilterInput';

const getTableLabelData = (payloadType) => {
    let tableLabelData = WEALTH_OPS_ALL_CASE_TABLE_LABEL_DATA;

    if (payloadType.value === PAYLOAD_TYPE_WEALTH_DATA) {
        tableLabelData = tableLabelData.concat([
            {
                label: 'To Verify',
                key: 'to_verify',
                extClasses: 'wspace-pre-wrap',
            },
            {
                label: 'Record ID',
                key: 'id',
            },
        ]);
    }

    if (payloadType.value === PAYLOAD_TYPE_KYC_DOCKET) {
        tableLabelData = tableLabelData.concat([
            {
                label: 'Upload Date',
                key: 'upload_date',
            },
            {
                label: 'KRA Status',
                key: 'kra_status',
            },
            {
                label: 'PAN Number',
                key: 'pan',
            },
        ]);
    }

    return tableLabelData;
};

const AllCases = () => {
    const dispatch = useDispatch();

    // global state
    const payloadType = useSelector(selectWealthOpsPayloadType(), shallowEqual);
    const queueCases = useMemoizedSelector(makeSelectWealthOpsQueueCases);
    const queueFilters = useSelector(selectWealthOpsQueueFilters(), shallowEqual);
    const pendingReviewCount = useSelector(selectWealthOpsPendingReviewCount, shallowEqual);

    const { limit } = queueFilters;
    const tableLabelData = getTableLabelData(payloadType);

    const handleReviewClick = (curCase) => () => {
        dispatch(setWealthOpsCurCaseAction(curCase));

        if (!isObjEmpty(curCase)) dispatch(setWealthOpsCurViewAction(VIEW_SINGLE_WEALTH_OPS_CASE));
    };

    const setPayloadType = (newPayload) => {
        dispatch(setWealthOpsPayloadTypeAction(newPayload));
    };

    const setLimit = (newLimit) => {
        dispatch(setWealthOpsQueueFiltersAction({ key: 'limit', value: newLimit }));
    };

    const renderActions = (item, index) => (
        <div className='frfscWrapper'>
            <Button
                link
                label='Review'
                onClick={handleReviewClick({
                    ...item.ogData,
                    index,
                })}
            />
        </div>
    );

    return (
        <React.Fragment>
            <div className='wops-hl1 mb-15'>Total Wealth Ops Cases - {pendingReviewCount}</div>

            <div className='frfssbWrapper'>
                <div className='frcWrapper'>
                    <div className='wops-idd__lb'>Queue Size</div>
                    <AppDropdown
                        ddState={limit}
                        setDDState={setLimit}
                        ddList={LIMIT_OPTIONS}
                        defaultDDLabel='Select Queue Size'
                        ddItemCls='custom-dd__cl wops-idd__con'
                        ddWrapperCls={{
                            container: 'custom-dd wops-idd1',
                        }}
                        ddContentCls={{
                            container: ['wops-idd__cr1'],
                        }}
                    />
                </div>
                <div className='wops-filter-cont'>
                    <div className='frcsbWrapper '>
                        <div className='wops-idd__lb'>Select Payload</div>
                        <AppDropdown
                            ddState={payloadType}
                            setDDState={setPayloadType}
                            ddList={QUEUE_PAYLOAD_TYPES}
                            defaultDDLabel='Select Payload Type'
                            ddItemCls='custom-dd__cl wops-idd__con'
                            ddWrapperCls={{
                                container: 'custom-dd wops-idd',
                            }}
                            ddContentCls={{
                                container: ['wops-idd__cr'],
                            }}
                        />
                    </div>
                    {
                        payloadType.value === 'PAYLOAD_TYPE_KYC_DOCKET' ? (
                            <WealthKYCDocketFilterInput />
                        ) : (
                            null
                        )
                    }
                </div>
            </div>

            <Table
                v2
                header=''
                labelData={tableLabelData}
                contentData={queueCases}
                renderActionableColumn={renderActions}
                extClasses={{
                    container: 'wops-src',
                }}
            />
        </React.Fragment>
    );
};

export default memo(AllCases);

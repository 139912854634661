/**
 * @file RenderChannelField
 * Renders the channel field in the raise dispute flow
 */

import React, { memo, useContext, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { DropdownContent, DropdownWrapper, Loader } from '../../../components';
import { MetaInfoContext } from '../../../context';

import { makeSelectChannelList } from '../selectors';

const RenderChannelField = (props) => {
    const { parentProps, setChannelOptionDD } = props;

    const {
        ticketId, channel, handleChannelChange,
    } = parentProps;

    const metaInfo = useContext(MetaInfoContext);

    const channelList = useSelector(makeSelectChannelList, shallowEqual);

    const [showChannelDD, setChannelDD] = useState(false);

    const { data: channelData, loading: channelLoading } = channelList[ticketId];

    const {
        componentVersions: {
            txnDispute: disputesVersion,
        },
    } = metaInfo;

    // if disputes version is more than 1, then we do not need to show channel dropdown
    if (disputesVersion > 1) return null;

    return (
        <React.Fragment>
            <div className='txns-search-label'>Channel</div>
            <DropdownWrapper
                visible={showChannelDD}
                onOutsideClickHandler={() => setChannelDD(!showChannelDD)}
                extClasses={{
                    container: 'custom-dd txns-search-label--co',
                }}
            >
                <button
                    type='button'
                    className='txns-dd__cta'
                    onClick={() => setChannelDD(!showChannelDD)}
                >
                    {channel || 'Choose...'}
                </button>
                <DropdownContent
                    visible
                    extClasses={{
                        container: ['custom-dd__cc height-transition', showChannelDD && 'custom-dd__cc--ac'],
                    }}
                >
                    {channelData.map((item) => (
                        <button
                            type='button'
                            key={item.channel}
                            className='custom-dd__cl'
                            onClick={() => {
                                handleChannelChange(item.channel);
                                setChannelDD(false);
                                setChannelOptionDD(true);
                            }}
                        >
                            {item.channel}
                        </button>
                    ))}
                </DropdownContent>
            </DropdownWrapper>
            <Loader visible={channelLoading} />
        </React.Fragment>
    );
};

export default memo(RenderChannelField);

import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { ExpandableCard } from '../../components';
import ReviewCases from './cases';
import './style.scss';
import { selectTransactionCaseDetails } from '../TransactionCaseDetails/selectors';
import { toastify, clientApiWrapper, getErrLabel } from '../../utils';
import { USER_REPONSE_CASE_MANAGEMENT } from '../../api/routes';
import { PDFJSParams } from './constant';

const UserQuestionnaire = () => {
    const [isExpanded, setExpandFlag] = useState({
        main: false,
    });

    const [formData, setFormData] = useState([]);

    const transactionCaseDetails = useSelector(selectTransactionCaseDetails, shallowEqual);

    const actorId = useMemo(() => transactionCaseDetails?.caseDetails?.find((item) => item?.actor_id)?.value, [transactionCaseDetails]);

    const getUserResposne = async () => {
        try {
            const response = await clientApiWrapper.get(`${USER_REPONSE_CASE_MANAGEMENT}?actorId=${encodeURIComponent(actorId)}`);

            const { forms } = response.response;
            setFormData(forms);
        } catch (error) {
            const err = getErrLabel(error);
            toastify(err, 'error');
        }
    };

    const loadPdFLoader = () => {
        const permissionMetaTag = document.createElement('meta');
        permissionMetaTag.httpEquiv = PDFJSParams.permissionType;
        permissionMetaTag.content = PDFJSParams.permissionContent;
        const script = document.createElement('script');
        script.src = PDFJSParams.src;
        script.async = PDFJSParams.async;
        script.integrity = PDFJSParams.integrity;
        script.crossOrigin = PDFJSParams.crossOrigin;
        script.referrerpolicy = PDFJSParams.referrerpolicy;
        script.id = PDFJSParams.id;
        document.head.appendChild(permissionMetaTag);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    };

    useEffect(() => {
        /**
         * dynamically adding pdf.js library
         *  load pdf.js library if already not loaded.
         */
        if (!document.getElementById('pdf.js')) loadPdFLoader();
    }, []);

    useEffect(() => {
        getUserResposne();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleAccordion = () => {
        setExpandFlag((prev) => !prev);
    };

    return (
        <div
            className='user-escalation-review-container'
        >
            <ExpandableCard
                extClasses='user-case-review-expand-card-parent mb-30'
                isExpanded={isExpanded}
                setIsExpanded={toggleAccordion}
                heading='Questionnaire Response'
            >
                {
                    isExpanded
                    && (
                        <ReviewCases
                            cases={formData}
                        />
                    )
                }
            </ExpandableCard>
        </div>
    );
};

export default UserQuestionnaire;

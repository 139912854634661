/**
 * 
 * Step 1 of forgot password flow: Ask user for registered email to sent OTP on their email
 * 
 */

import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import '../../style.scss';
import { Button, FormWithSingleInputV2 } from '../../../../components';
import { validators } from '../../../../utils';
import { sendOtpCodeOnEmail } from '../../actions';

const RegisteredEmail = () => {
    const dispatch = useDispatch();
    // local state
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');

    const handleInputChange = (e, setValue, curError, setError) => {
        setValue(e.target.value);
        if (curError) setError('');
    };

    const validateEmailInput = () => {
        let isValid = true;

        if (!validators.isInputtedEmailValid(email)) {
            setEmailErr('Please enter a valid email ID');
            isValid = false;
        }

        return isValid;
    };

    const handleRegisteredEmail = () => {
        const valid = validateEmailInput();

        if (!valid) return;

        dispatch(sendOtpCodeOnEmail({
            email,
        }));
    }

    return (
        <React.Fragment>
            <FormWithSingleInputV2
                disableFormSubmitOnEnter
                label='Registered Email ID'
                labelId='login-email'
                input={{
                    value: email,
                    onChange: (e) => {
                        handleInputChange(e, setEmail, emailErr, setEmailErr);
                    },
                }}
                errLabel={emailErr}
                onFormSubmit={handleRegisteredEmail}
            />
            <Button
                extClasses='login-item'
                primary
                label={'Request OTP Code'}
                onClick={handleRegisteredEmail}
            />
    </React.Fragment>
    );
};

export default memo(RegisteredEmail);

/**
 *
 * ReOnboardingInfo - Component to show the re-oobe current stage troubleshoot info & attempt list
 *
 */

import React, { memo } from 'react';

import backArrow from '../../../assets/images/svgs/back-arrow.svg';

import CurrentStageOnboardingTroubleshoot from '../CurrentStageOnboardingTroubleshoot';

const ReOnboardingInfo = (props) => {
    const { data = {}, toggleHandler, } = props;
    const { attemptList = [], currentStageTroubleshootInfo = {} } = data;

    return (
        <div className='kob'>
            <div className='kob-hw'>
                <img className='kob-backarrow' src={backArrow} onClick={() => toggleHandler(false)} />
                <div className='kob-hw__hd'>
                    Re-Onboarding Details
                </div>
            </div>

            <CurrentStageOnboardingTroubleshoot isReoobeFlow data={currentStageTroubleshootInfo} />

            <div className='table-sr'>
                <div className='table-sr__label'>Re-Onboarding Attempt List</div>
                <div className='table-sr__thw'>
                    <div className='table-sr__th'>Status</div>
                    <div className='table-sr__th f15Wrapper'>Identifier Changed</div>
                    <div className='table-sr__th f25Wrapper'>
                        Stage Details
                        <br />
                        (Stage - Status)
                    </div>
                    <div className='table-sr__th'>Created At</div>
                    <div className='table-sr__th'>Deleted At</div>
                    <div className='table-sr__th'>Failure Reason</div>
                </div>
                <div className='table-sr__tlc'>
                    {
                        attemptList.map(item => (
                            <div className='table-sr__tlw kob-modal-tlw' key={item.afuId}>
                                <div className='table-sr__tl'>{item.status}</div>
                                <div className='table-sr__tl f15Wrapper'>{item.authFactors.join(', ')}</div>
                                <div className='table-sr__tl f25Wrapper'>
                                    {
                                        item.stageInfoMap.map(idmItem => (
                                            <div className='kob-modal-siwr2'>{idmItem}</div>
                                        ))
                                    }
                                </div>
                                <div className='table-sr__tl'>{item.createdTs}</div>
                                <div className='table-sr__tl'>{item.deletedTs}</div>
                                <div className='table-sr__tl'>{item.failureReason}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default memo(ReOnboardingInfo);

/**
 * @file CxRiskReview reducer
 */

import { fromJS } from 'immutable';

import {
    GET_CX_RISK_REVIEW_REQUESTS_FOR_ACTOR, GET_CX_RISK_REVIEW_TICKETS, SET_CX_RISK_REVIEW_CUR_TICKET_DETAILS, SET_CX_RISK_REVIEW_CUR_VIEW,
    SET_CX_RISK_REVIEW_ERR, SET_CX_RISK_REVIEW_REQUESTS_FOR_ACTOR, SET_CX_RISK_REVIEW_TICKETS, VIEW_ALL_TICKETS,
} from './constants';

const initialState = fromJS({
    cxRiskTickets: {},
    loading: false,
    err: '',
    curView: VIEW_ALL_TICKETS,
    curTicketDetails: {},
    requestsList: {},
});

function cxRiskReviewReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CX_RISK_REVIEW_TICKETS:
        case GET_CX_RISK_REVIEW_REQUESTS_FOR_ACTOR: {
            return state
                .setIn(['loading'], true)
                .setIn(['err'], '');
        }

        case SET_CX_RISK_REVIEW_TICKETS: {
            const { ticketList } = action.data;
            const { data, prev, next } = ticketList;

            return state
                .setIn(['loading'], false)
                .setIn(['cxRiskTickets', 'data'], fromJS(data))
                .setIn(['cxRiskTickets', 'prev'], fromJS(prev))
                .setIn(['cxRiskTickets', 'next'], fromJS(next))
                .setIn(['err'], '');
        }

        case SET_CX_RISK_REVIEW_ERR: {
            const err = action.data;

            return state
                .setIn(['loading'], false)
                .setIn(['err'], fromJS(err));
        }

        case SET_CX_RISK_REVIEW_CUR_VIEW: {
            const curView = action.data;

            return state
                .setIn(['curView'], curView);
        }

        case SET_CX_RISK_REVIEW_CUR_TICKET_DETAILS: {
            const curTicketDetails = action.data;

            return state
                .setIn(['curTicketDetails'], fromJS(curTicketDetails));
        }

        case SET_CX_RISK_REVIEW_REQUESTS_FOR_ACTOR: {
            const { actorId, reviewElements } = action.data;

            return state
                .setIn(['requestsList', actorId], fromJS(reviewElements))
                .setIn(['loading'], false)
                .setIn(['err'], '');
        }

        default:
            return state;
    }
}

export default cxRiskReviewReducer;

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, toastify } from '../../utils';
import { TICKET_SUMMARY_ROOT } from '../../api/routes';

import {
    GET_TICKET_INFO, SET_TICKET_INFO, ERR_TICKET_INFO,
    GET_TICKET_NOTES, SET_TICKET_NOTES, ERR_TICKET_NOTES,
} from './constants';

function* getTicketInfo(action) {
    const { ticketId } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKET_SUMMARY_ROOT}/${ticketId}`
        );

        yield put({ type: SET_TICKET_INFO, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TICKET_INFO, data: { err: e.message } });
    }
}

function* getTicketNotes(action) {
    const { ticketId } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${TICKET_SUMMARY_ROOT}/${ticketId}/notes`
        );

        yield put({ type: SET_TICKET_NOTES, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_TICKET_NOTES });
    }
}

export default function* ticketSummarySaga() {
    yield all(
        [
            yield takeLatest(GET_TICKET_INFO, getTicketInfo),
            yield takeLatest(GET_TICKET_NOTES, getTicketNotes),
        ]
    );
}

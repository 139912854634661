/**
 * @file Manages the saga generator functions for Agent Feedback Details component
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify, getErrMsg,
} from '../../utils';
import { SHERLOCK_FEEDBACK_ROOT } from '../../api/routes';

import {
    GET_AGENT_FB_DETAILS, SET_AGENT_FB_DETAILS, ERR_AGENT_FB_DETAILS,
} from './constants';

function* getAgentFeedbackDetailsSaga(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${SHERLOCK_FEEDBACK_ROOT}/agent?${queryString}`,
        );

        yield put({ type: SET_AGENT_FB_DETAILS, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_AGENT_FB_DETAILS, data: { err: getErrMsg(e) } });
    }
}

export default function* agentFeedbackDetailsSaga() {
    yield all(
        [
            yield takeLatest(GET_AGENT_FB_DETAILS, getAgentFeedbackDetailsSaga),
        ],
    );
}

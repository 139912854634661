/**
 *
 * WealthMutualFunds - Component to show the invested mutual funds related information by the current user
 *
 */

import React from 'react';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';

import MutualFundOrdersView from './MutualFundOrdersView';
import MutualFundsView from './MutualFundsView';

import { WEALTH_MUTUAL_FUNDS_KEY } from './constants';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const WealthMutualFunds = (props) => {
    const { ticketId, userId, index } = props;

    return (
        <div className='mfs'>
            <MutualFundOrdersView
                ticketId={ticketId}
                userId={userId}
                index={index}
            />
            <MutualFundsView
                ticketId={ticketId}
                userId={userId}
                index={index}
            />
        </div>
    );
};

const withReducer = injectReducer({ key: WEALTH_MUTUAL_FUNDS_KEY, reducer });
const withSaga = injectSaga({ key: WEALTH_MUTUAL_FUNDS_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(WealthMutualFunds);

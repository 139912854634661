import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, toastify } from '../../utils';
import { LIVENESS_ROOT } from '../../api/routes';

import {
    GET_LIVENESS_STATUS_LIST, SET_LIVENESS_STATUS_LIST, SET_LIVENESS_STATUS_LOADER,
    UPDATE_LIVENESS_STATUS,
} from './constants';

function* getLivenessStatusList() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LIVENESS_ROOT}/status-list`
        );

        yield put({ type: SET_LIVENESS_STATUS_LIST, data: response });
    } catch (e) {
        yield put({ type: SET_LIVENESS_STATUS_LOADER, data: {} });
    }
}

function* updateLivenessStatus(action) {
    const { monorailId, actorId, imagePath, livenessStatus: status, reason, resolve } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.put],
            `${LIVENESS_ROOT}/status`,
            {
                monorailId,
                actorId,
                imagePath,
                status,
                reason,
            },
        );

        yield call(resolve);

        toastify(response.status, 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
    } finally {
        yield put({ type: SET_LIVENESS_STATUS_LOADER, data: {} });
    }
}

export default function* livenessStatusSaga() {
    yield all(
        [
            yield takeLatest(GET_LIVENESS_STATUS_LIST, getLivenessStatusList),
            yield takeLatest(UPDATE_LIVENESS_STATUS, updateLivenessStatus),
        ]
    );
}

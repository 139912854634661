/*
 *
 * AgentListing constants
 *
 */

export const DEFAULT_ACTION = 'app/AgentListing/DEFAULT_ACTION';

export const AGENT_LIST_HEADERS = [
    { label: "Email Id", key: 'email_id' },
    { label: "Agent Type", key: "agent_type" },
    { label: "Access Level", key: "access_level" },
];

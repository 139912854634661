import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { ACCOUNTS_ROOT, ALFRED_ROUTE, GET_AGENT_PROMPTS, SEND_NOTIFICATION_BY_PROMPTS } from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_ACCOUNT_CLOSURE_INFO, SET_ACCOUNT_CLOSURE_INFO, ERR_ACCOUNT_CLOSURE_INFO,
    GET_CHEQUEBOOK_REQUEST_DETAILS, SET_CHEQUEBOOK_REQUEST_DETAILS, ERR_CHEQUEBOOK_REQUEST_DETAILS,
    GET_ACCOUNT_STATEMENT_FORM_DETAILS, ERR_ACCOUNT_STATEMENT_FORM_DETAILS, SET_ACCOUNT_STATEMENT_FORM_DETAILS,
    SET_AGENT_PROMPTS_ACTION, GET_AGENT_PROMPTS_ACTION, SET_SEND_NOTIFICATION_BY_PROMPTS,
    GET_SEND_NOTIFICATION_BY_PROMPTS, ERR_SEND_NOTIFICATION_BY_PROMPTS, ERR_AGENT_PROMPTS_ACTION,
} from './constants';

function* getAccountClosureInfo(action) {
    const { ticketId, userId, index } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ACCOUNTS_ROOT}/${ticketId}/account-closure?userId=${userId}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_ACCOUNT_CLOSURE_INFO, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_ACCOUNT_CLOSURE_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ACCOUNT_CLOSURE_INFO, data: { ticketId } });
    }
}

function* getChequebookDetails(action) {
    const { ticketId, index } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ALFRED_ROUTE}/${ticketId}/chequebook-request?ticketId=${ticketId}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_CHEQUEBOOK_REQUEST_DETAILS, data: { ticketId, chequebookReqInfo: response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_CHEQUEBOOK_REQUEST_DETAILS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CHEQUEBOOK_REQUEST_DETAILS, data: { ticketId } });
    }
}

function* getAccountStatementFormDetails(action) {
    const { ticketId, index } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ACCOUNTS_ROOT}/${ticketId}/account-statement?ticketId=${ticketId}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_ACCOUNT_STATEMENT_FORM_DETAILS, data: { ticketId, accountStatementInfo: response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_ACCOUNT_STATEMENT_FORM_DETAILS, data: { ticketId } });
        } else {
            yield put({ type: ERR_ACCOUNT_STATEMENT_FORM_DETAILS, data: { ticketId, errInfo: response } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ACCOUNT_STATEMENT_FORM_DETAILS, data: { ticketId } });
    }
}

function* getAgentPrompts(action) {
    const { ticketId, index } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_AGENT_PROMPTS}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_AGENT_PROMPTS_ACTION, data: { ticketId, prompts: response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_AGENT_PROMPTS_ACTION, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_AGENT_PROMPTS_ACTION, data: { ticketId, err } });
    }
}

function* sendNotificationByPrompts(action) {
    const { ticketId, index } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${SEND_NOTIFICATION_BY_PROMPTS}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_SEND_NOTIFICATION_BY_PROMPTS, data: { ticketId, notification: response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_SEND_NOTIFICATION_BY_PROMPTS, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_SEND_NOTIFICATION_BY_PROMPTS, data: { ticketId, err } });
    }
}

export default function* userRequestsSaga() {
    yield all(
        [
            yield takeLatest(GET_ACCOUNT_CLOSURE_INFO, getAccountClosureInfo),
            yield takeLatest(GET_CHEQUEBOOK_REQUEST_DETAILS, getChequebookDetails),
            yield takeLatest(GET_ACCOUNT_STATEMENT_FORM_DETAILS, getAccountStatementFormDetails),
            yield takeLatest(GET_AGENT_PROMPTS_ACTION, getAgentPrompts),
            yield takeLatest(GET_SEND_NOTIFICATION_BY_PROMPTS, sendNotificationByPrompts),
        ],
    );
}

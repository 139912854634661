import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components';
import { getInitialAuthFactor, setAuthUpgradeInfo } from '../actions';
import { DEFAULT_TICKET_PATH } from '../../App/routes';

const AuthUpgradeScreen = (props) => {
    const {
        index, ticketId, userId, activeTicket, onClick,
    } = props;
    const { isAuthorizedForSensitiveScreen, isAuthorizedForModeratelySensitiveScreen, isAuthorizedForLessSensitiveScreen } = activeTicket;

    const dispatch = useDispatch();
    const history = useHistory();

    const isAuthAlreadyDone = isAuthorizedForSensitiveScreen || isAuthorizedForModeratelySensitiveScreen || isAuthorizedForLessSensitiveScreen;

    const waitingTime = '15 minutes';

    const onAuthUpdateClick = () => {
        if (onClick) {
            onClick();
        } else {
            dispatch(setAuthUpgradeInfo({ isAuthUpgradeInProgress: true, index }));
            dispatch(getInitialAuthFactor({ ticketId, userId, index }));
            history.push(`${DEFAULT_TICKET_PATH}${ticketId}`);
        }
    };

    return (
        <div className='account-statement-error-section'>
            {
                !isAuthAlreadyDone ? <div className='ticket-modal__subheading'>Current level of authorization is not enough to view this page.</div>
                    : (
                        <div className='ticket-modal__subheading'>
                            You have done authorization recently. Please wait {waitingTime} and try authenticating again
                        </div>
                    )
            }
            {
                !isAuthAlreadyDone
                && (
                    <Button
                        primary
                        label='Go to authorisation flow'
                        onClick={onAuthUpdateClick}
                    />
                )
            }
        </div>
    );
};

export default memo(AuthUpgradeScreen);

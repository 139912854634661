/**
 * @file ViewRaiseVerificationRequestCases
 * All the cases for which verfication request can be raised.
 */

import React, { memo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { FormDropdown } from '../../../../components';
import { useMemoizedSelector } from '../../../../hooks';

import { getRaiseSalaryVerificationCasesAction, setRaiseSalaryVerificationStageAction, setRaiseSalaryVerificationStatusAction } from '../../actions';
import { RAISE_VERIFICATION_REQUEST_STATUS, SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY } from '../../constants';
import SalaryIdentifierInput from '../../SalaryIdentifierInput';
import {
    selectRaiseVerificationRequestStage,
    selectRaiseVerificationRequestStatus, selectSalaryAccountUserIdentifierDetails, selectSalaryOpsEnums,
} from '../../selectors';

import RaiseVerificationRequestTable from '../RaiseVerificationRequestTable';

const FILTER_OPTIONS = [
    {
        label: RAISE_VERIFICATION_REQUEST_STATUS.new.label,
        value: RAISE_VERIFICATION_REQUEST_STATUS.new.value,
    },
    {
        label: RAISE_VERIFICATION_REQUEST_STATUS.pending.label,
        value: RAISE_VERIFICATION_REQUEST_STATUS.pending.value,
    },
];

const ViewRaiseVerificationRequestCases = () => {
    const dispatch = useDispatch();

    const enums = useSelector(selectSalaryOpsEnums, shallowEqual);
    const { raiseVerificationStatusList, raiseVerificationStageList } = enums;
    const filteredRaiseVerificationStatusList = raiseVerificationStatusList
        ?.filter((status) => status.value !== RAISE_VERIFICATION_REQUEST_STATUS.complete.value);

    const raiseVerificationStatus = useSelector(selectRaiseVerificationRequestStatus, shallowEqual);
    const raiseVerificationStage = useSelector(selectRaiseVerificationRequestStage, shallowEqual);
    const userIdentifier = useMemoizedSelector(selectSalaryAccountUserIdentifierDetails, [SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY]);

    const makePayloadAndGetVerificationCases = (verificationStatusValue, verificationStageValue, params = {}) => {
        const payload = {
            raiseVerificationStatus: verificationStatusValue,
            raiseVerificationStage: verificationStageValue,
            ...params,
        };
        dispatch(getRaiseSalaryVerificationCasesAction(payload));
    };

    const handleVerificationStatusChange = (newFilterValue) => {
        dispatch(setRaiseSalaryVerificationStatusAction(newFilterValue));
        makePayloadAndGetVerificationCases(newFilterValue.value, raiseVerificationStage.value, { userIdentifier });
    };

    const handleVerificationStageChange = (newFilterValue) => {
        dispatch(setRaiseSalaryVerificationStageAction(newFilterValue));
        makePayloadAndGetVerificationCases(raiseVerificationStatus.value, newFilterValue.value, { userIdentifier });
    };

    const handleSubmit = (identifier) => {
        makePayloadAndGetVerificationCases(raiseVerificationStatus.value, raiseVerificationStage.value, { userIdentifier: identifier });
    };

    return (
        <React.Fragment>
            <div className='heading1 mb-60'>Raise a salary verification request</div>
            <div className='frfssbWrapper mb-30'>
                <SalaryIdentifierInput
                    isSalaryDataOps
                    isVerificationQueue
                    screenKey={SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_KEY}
                    onSubmit={handleSubmit}
                />
                <div>
                    <FormDropdown
                        label='Filter By Status'
                        options={filteredRaiseVerificationStatusList || FILTER_OPTIONS}
                        input={{
                            value: raiseVerificationStatus,
                            onChange: handleVerificationStatusChange,
                            placeholder: 'Choose...',
                        }}
                        cacheKey='filter-status'
                        extStyles={{
                            container: 'sip-fc1 mb-15',
                            label: 'sip-fc__lb',
                            inputField: 'sip-fc__ip',
                        }}
                    />
                    <FormDropdown
                        label='Filter By Stage'
                        options={raiseVerificationStageList}
                        input={{
                            value: raiseVerificationStage,
                            onChange: handleVerificationStageChange,
                            placeholder: 'Choose...',
                        }}
                        cacheKey='filter-stage'
                        extStyles={{
                            container: 'sip-fc1',
                            label: 'sip-fc__lb',
                            inputField: 'sip-fc__ip',
                        }}
                    />
                </div>
            </div>
            <RaiseVerificationRequestTable
                v2
                makePayloadAndGetVerificationCases={makePayloadAndGetVerificationCases}
            />
        </React.Fragment>
    );
};

export default memo(ViewRaiseVerificationRequestCases);

/* eslint-disable default-param-last */
/* eslint-disable dot-notation */
/*
 *
 * DevActions reducer
 *
 */

import { fromJS } from 'immutable';
import {
    GET_ACTION_LIST, SET_ACTION_LIST, ERR_ACTION_LIST, GET_PARAMETER_LIST,
    SET_PARAMETER_LIST, SET_PARAMETER_VALUE, ERR_PARAMETER_LIST, EXECUTE_ACTION,
    SUCCESS_EXECUTE_ACTION, ERR_EXECUTE_ACTION, SET_MONORAIL_ID, SET_PARAMETER_VALUE_V2,
} from './constants';

const initialState = fromJS({});

const formatDropDownOption = (field, mapProps) => {
    const { name, options } = field;
    const labelMap = {};
    let map = {};
    Object.entries(options).forEach(([key, value]) => {
        const modifiedFieldValue = value.fields.map((item) => {
            if (item.type === 5) {
                const { options: childOption, conditionalMap: childMap, ...rest } = formatDropDownOption(item, { ...mapProps });
                map = { ...map, ...childMap };

                return { ...rest, options: childOption };
            }
            return { ...item };
        });
        if (typeof (mapProps) === 'object') {
            labelMap[key] = modifiedFieldValue;
        }
    });

    return { ...field, options: Object.keys(options), conditionalMap: { ...map, [name]: labelMap } };
};

function devActionsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ACTION_LIST:
        case GET_PARAMETER_LIST:
        case EXECUTE_ACTION: {
            const { containerKey } = action.data;

            return state
                .setIn([containerKey, 'loading'], true)
                .setIn([containerKey, 'err'], '');
        }

        case SET_ACTION_LIST: {
            const { actionList, containerKey } = action.data;

            return state
                .setIn([containerKey, 'loading'], false)
                .setIn([containerKey, 'actionList'], fromJS(actionList));
        }

        case ERR_ACTION_LIST: {
            const { containerKey } = action.data;

            return state.setIn([containerKey, 'loading'], false);
        }

        case SET_PARAMETER_LIST: {
            const { parameterList, containerKey, version } = action.data;

            let conditionalOptionsMap = {};
            const modifiedParameterList = parameterList.map((item) => {
                const modifiedItem = {
                    ...item,
                    value: item.value || '',
                };

                if (item.type === 5) { // DROPDOWN
                    modifiedItem.isDDOpen = false;

                    if (version === 2) {
                        const { options, conditionalMap } = formatDropDownOption(item, {});
                        conditionalOptionsMap = { ...conditionalOptionsMap, ...conditionalMap };
                        modifiedItem['options'] = options;
                    }
                } else if (item.type === 7) { // NAME
                    modifiedItem.value = {
                        first_name: '',
                        last_name: '',
                    };
                } else if (item.type === 9) { // MULTI_SELECT_DROPDOWN
                    modifiedItem.options = modifiedItem.options.map((subItem) => ({
                        label: subItem,
                        value: subItem,
                    }));
                    modifiedItem.value = [];
                }

                return modifiedItem;
            });

            return state
                .setIn([containerKey, 'loading'], false)
                .setIn([containerKey, 'parameterList'], fromJS(modifiedParameterList))
                .setIn([containerKey, 'conditionalOptionsMap'], fromJS(conditionalOptionsMap))
                .setIn([containerKey, 'userResponseV2'], fromJS({}))
                .setIn([containerKey, 'isNewParamerterList'], version === 2);
        }

        case SET_PARAMETER_VALUE: {
            const {
                item, key, value, dd, containerKey,
            } = action.data;

            const parameterList = state.getIn([containerKey, 'parameterList']).toJS();
            const itemIdx = parameterList.findIndex((lItem) => lItem.name === item.name);

            const modifiedParameterList = [...parameterList];

            if (item.type === 5) { // DROPDOWN
                modifiedParameterList[itemIdx] = {
                    ...modifiedParameterList[itemIdx],
                    isDDOpen: dd,
                    value: value || modifiedParameterList[itemIdx].value,
                };
            } else if (item.type === 7) { // NAME
                modifiedParameterList[itemIdx] = {
                    ...modifiedParameterList[itemIdx],
                    value: {
                        ...modifiedParameterList[itemIdx].value,
                        [key]: value,
                    },
                };
            } else {
                modifiedParameterList[itemIdx] = {
                    ...modifiedParameterList[itemIdx],
                    value,
                };
            }

            return state.setIn([containerKey, 'parameterList'], fromJS(modifiedParameterList));
        }

        case ERR_PARAMETER_LIST: {
            const { containerKey } = action.data;

            return state
                .setIn([containerKey, 'loading'], false)
                .setIn([containerKey, 'parameterList'], fromJS([]));
        }

        case SET_PARAMETER_VALUE_V2: {
            const {
                item, key, value = '', containerKey, conditionalObject,
            } = action.data;
            const { name, type } = item;

            const currentUserRespone = state.getIn([containerKey, 'userResponseV2']).toJS();

            if (item.type === 5) { // DROPDOWN
                const { dd } = action.payload || {};
                return state.setIn([containerKey, 'userResponseV2'], fromJS({
                    ...currentUserRespone,
                    [name]: {
                        type, value, isDDOpen: dd, conditionalObject,
                    },
                }));
            } if (item.type === 7) { // NAME
                const currentValue = currentUserRespone[name] && currentUserRespone[name][key] ? currentUserRespone[name][key] : {};
                const multiSelectPayload = {
                    ...currentValue,
                    [key]: value,
                };
                return state.setIn([containerKey, 'userResponseV2'], fromJS({
                    ...currentUserRespone,
                    [name]: { type, value: multiSelectPayload, conditionalObject },
                }));
            }
            return state.setIn([containerKey, 'userResponseV2'], fromJS({ ...currentUserRespone, [name]: { type, value, conditionalObject } }));
        }

        case SUCCESS_EXECUTE_ACTION: {
            const { executeInfo, containerKey } = action.data;

            return state
                .setIn([containerKey, 'loading'], false)
                .setIn([containerKey, 'hasExecuteInfo'], true)
                .setIn([containerKey, 'executeInfo'], fromJS(executeInfo || []));
        }

        case ERR_EXECUTE_ACTION: {
            const { err, containerKey } = action.data;

            return state
                .setIn([containerKey, 'loading'], false)
                .setIn([containerKey, 'err'], err)
                .setIn([containerKey, 'hasExecuteInfo'], false)
                .setIn([containerKey, 'executeInfo'], fromJS([]));
        }

        case SET_MONORAIL_ID: {
            const { value, containerKey } = action.data;

            if (value) {
                return state.setIn([containerKey, 'monorailInfo', 'id'], value);
            }

            // return initialState;
            return state;
        }

        default:
            return state;
    }
}

export default devActionsReducer;

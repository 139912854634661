/**
 *
 * TableModal - Modal to Account Closure Requests Table
 *
 */

import React, { memo } from 'react';

import { Button, Modal, Table } from '../../../components';

const TableModal = (props) => {
    const {
        visible, data, toggleHandler, error,
    } = props;

    if (!data) return null;

    return (
        <Modal
            visible={visible}
            modalStyles={{
                content: {
                    width: '900px',
                    left: '45%',
                },
            }}
        >
            <Table
                objKey='header_key'
                rowDataVersion={2}
                labelData={data?.columnData}
                contentData={data?.rowData}
                errLabel={error}
            />
            <Button
                primary
                extClasses='kob-modal-bwr'
                label='Close'
                onClick={() => toggleHandler(null)}
            />
        </Modal>
    );
};

export default memo(TableModal);

export const getCardTrackingLabels = (data) => {
    const trackingInfo = [
        {
            label: 'Card Generation Date',
            value: data.generatedTs || '-',
        },
        {
            label: 'AWB',
            value: data.awb || '-',
        },
        {
            label: 'Courier Partner',
            value: data.courierPartner || '-',
        },
        {
            label: 'Card Activated',
            value: data.cardActivated ? 'Yes' : 'No',
        },
        {
            label: 'Card Dispatched',
            value: data.cardDispatched ? 'Yes' : 'No',
        },
    ];

    return trackingInfo;
};

export const getUpcomingEmiBill = (data) => {
    const upcomingEmiBillData = [
        {
            label: 'Total Active EMIs',
            value: data?.totalActiveEmis,
        },
        {
            label: 'EMI due in next statement',
            value: data?.emiDueInNextStatement,
        },
    ];

    return upcomingEmiBillData;
};

export const getLoanAccountDetails = (data) => {
    const loanAccountDetailsData = [
        {
            label: 'Merchant',
            value: data?.merchant,
        },
        {
            label: 'Interest p.a',
            value: data?.interestRate,
        },
        {
            label: 'Fees',
            value: data?.fees,
        },
        {
            label: 'Total Amount to be paid in EMIs',
            value: data?.emiAmount,
        },
        {
            label: 'Created On',
            value: data?.emiConversionDate,
        },
        {
            label: 'Next EMI Due On',
            value: data?.nextBillDate,
        },
        {
            label: 'Vendor Loan ID',
            value: data?.vendorLoanId,
        },
        {
            label: 'Closed On',
            value: data?.emiClosureDate,
        },
        {
            label: 'Tenure',
            value: data?.tenure,
        },
        {
            label: 'Installment Amount',
            value: data?.installmentAmount,
        },
    ];

    return loanAccountDetailsData;
};

export const getLoanOfferDetails = (data) => {
    const loanOffersData = [
        {
            label: 'Amount',
            value: data?.amount,
        },
        {
            label: 'Interest Rate',
            value: data?.interestRate,
        },
        {
            label: 'Tenure',
            value: data?.tenure,
        },
        {
            label: 'EMI Amount',
            value: data?.emiAmount,
        },
        {
            label: 'Principle',
            value: data?.principle,
        },
        {
            label: 'Interest',
            value: data?.interest,
        },
        {
            label: 'Processing Fees',
            value: data?.processingFee,
        },
        {
            label: 'Taxes',
            value: data?.taxes,
        },
        {
            label: 'Toal Amount',
            value: data?.totalAmount,
        },
        {
            label: 'Transaction Date',
            value: data?.transactionDate,
        },
    ];

    return loanOffersData;
};

/**
 * @file HealthInsuranceDetails
 * Component that fetches the user and general insurance details for the given ticket and renders on screen
 */

import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedSelector } from '../../../hooks';
import { KVPairInfo, Loader, Table } from '../../../components';

import { getGeneralHealthInsurancePolicyDetailsAction, getUserHealthInsurancePolicyDetailsAction } from '../actions';
import { selectGeneralHealthInsurancePolicyDetails, selectUserHealthInsurancePolicyDetails } from '../selectors';
import { getTicketDetails } from '../utils';

const HealthInsuranceDetails = (props) => {
    const { screenKey, parentProps } = props;

    const dispatch = useDispatch();

    const userHealthInsurancePolicyDetails = useMemoizedSelector(selectUserHealthInsurancePolicyDetails, [screenKey]);
    const generalHealthInsurancePolicyDetails = useMemoizedSelector(selectGeneralHealthInsurancePolicyDetails, [screenKey]);

    const { ticketId, userId, index } = parentProps;

    const memoizedParentProps = useMemo(() => ({
        ticketId, userId, index,
    }), [ticketId, userId, index]);

    useEffect(() => {
        dispatch(getUserHealthInsurancePolicyDetailsAction({
            screenKey,
            payload: getTicketDetails(memoizedParentProps),
        }));

        dispatch(getGeneralHealthInsurancePolicyDetailsAction({
            screenKey,
            payload: getTicketDetails(memoizedParentProps),
        }));
    }, [dispatch, screenKey, memoizedParentProps]);

    // extract user health insurance details
    const { value: userDetails, loading: userLoading, err: userErr } = userHealthInsurancePolicyDetails || {};
    const { policy_details: policyDetails, note_info: userNoteInfo } = userDetails || {};

    // extract general health insurance details
    const { value: ghDetails, loading: ghLoading, err: ghErr } = generalHealthInsurancePolicyDetails || {};
    const { info_sections: infoSections, note_info: generalNoteInfo } = ghDetails || {};

    return (
        <div className='salops-ci'>
            <div className='heading1 mb-60'>Health Insurance Details</div>
            {/* user health insurance details */}
            <Table
                v2
                objKey='header_key'
                nsrLabel='No user health insurance details found!'
                labelData={policyDetails?.table_headers}
                contentData={policyDetails?.table_rows}
                header={policyDetails?.table_name}
                extClasses={{
                    container: 'mt-15',
                }}
            />
            <div
                className='mt-15  text-m'
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: userNoteInfo }}
            />
            {userErr ? (
                <div className='err-label1'>
                    Could not load the user health insurance details
                    {'\n'}
                    {userErr}
                </div>
            ) : null}
            {/* general health insurance details */}
            <KVPairInfo
                title='General Health Insurance Details'
                data={infoSections}
                valuesHaveHtml
                extClasses={{
                    container: 'my-60',
                    titleHeading: 'salops-kv__hd2',
                }}
            />
            <div
                className='mt-15 text-m'
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: generalNoteInfo }}
            />
            {ghErr ? (
                <div className='err-label1'>
                    Could not load the general health insurance details
                    {'\n'}
                    {ghErr}
                </div>
            ) : null}
            <Loader visible={ghLoading || userLoading} />
        </div>
    );
};

export default memo(HealthInsuranceDetails);

/* eslint-disable default-param-last */
import { fromJS } from 'immutable';

import { getKeyFromValueAndSubValue } from '../../utils/formatter';

import {
    GET_PROFILE_INFO,
    SET_PROFILE_INFO,
    ERR_PROFILE_INFO,
    GET_USER_BANNERS,
    SET_USER_BANNERS,
    ERR_USER_BANNERS,
    GET_USER_ISSUE_INFO,
    SET_USER_ISSUE_INFO,
    ERR_USER_ISSUE_INFO,
} from './constants';

const initialState = fromJS({
    profile: {},
    userIssueInfo: {},
    userBanners: {},
    agentFeedback: {},
});

function profileReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PROFILE_INFO: {
            const { authValue, authSubValue } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['profile', key, 'loading'], true)
                .setIn(['profile', key, 'err'], '');
        }

        case SET_PROFILE_INFO: {
            const { authValue, authSubValue, profileInfo } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['profile', key, 'loading'], false)
                .setIn(['profile', key, 'hasProfileInfo'], true)
                .setIn(['profile', key, 'profileInfo'], fromJS(profileInfo));
        }

        case ERR_PROFILE_INFO: {
            const { authValue, authSubValue, err } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['profile', key, 'loading'], false)
                .setIn(['profile', key, 'hasProfileInfo'], false)
                .setIn(['profile', key, 'profileInfo'], fromJS({}))
                .setIn(['profile', key, 'err'], err);
        }

        case GET_USER_ISSUE_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['userIssueInfo', ticketId, 'loading'], true)
                .setIn(['userIssueInfo', ticketId, 'err'], '');
        }

        case SET_USER_ISSUE_INFO: {
            const { ticketId, userIssueData } = action.data;

            let hasUserIssueData = false;

            if (userIssueData) hasUserIssueData = true;

            return state
                .setIn(['userIssueInfo', ticketId, 'loading'], false)
                .setIn(['userIssueInfo', ticketId, 'hasUserIssueData'], hasUserIssueData)
                .setIn(['userIssueInfo', ticketId, 'userIssueData'], fromJS(userIssueData));
        }

        case ERR_USER_ISSUE_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['userIssueInfo', ticketId, 'loading'], false)
                .setIn(['userIssueInfo', ticketId, 'hasUserIssueData'], false)
                .setIn(['userIssueInfo', ticketId, 'userIssueData'], fromJS(null))
                .setIn(['userIssueInfo', ticketId, 'err'], err);
        }

        case GET_USER_BANNERS: {
            const { authValue, authSubValue } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['userBanners', key, 'loading'], true)
                .setIn(['userBanners', key, 'err'], '');
        }

        case SET_USER_BANNERS: {
            const { authValue, authSubValue, banners } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            let hasData = false;

            if (banners) hasData = true;

            return state
                .setIn(['userBanners', key, 'loading'], false)
                .setIn(['userBanners', key, 'hasData'], hasData)
                .setIn(['userBanners', key, 'banners'], fromJS(banners));
        }

        case ERR_USER_BANNERS: {
            const { authValue, authSubValue, err } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['userBanners', key, 'loading'], false)
                .setIn(['userBanners', key, 'hasData'], false)
                .setIn(['userBanners', key, 'banners'], fromJS(null))
                .setIn(['userBanners', key, 'err'], err);
        }

        default:
            return state;
    }
}

export default profileReducer;

/**
 * @file LoaderOrError
 * Common component that renders an error or loader or both
 */

import React, { memo } from 'react';
import classNames from 'classnames';

import Loader from '../Loader';

const LoaderOrError = (props) => {
    const {
        loading, errorLabel, parentNode, extClasses, loadingText,
    } = props;

    const errorLabelClasses = classNames('error-label1 my-30', extClasses && extClasses.errorLabel);

    return (
        <React.Fragment>
            {errorLabel ? (
                <div className={errorLabelClasses}>{errorLabel}</div>
            ) : null}

            {/* if loading is not passed as a prop, loader will not be visible hence not using any conditional check */}
            <Loader visible={loading} parentNode={parentNode} loadingText={loadingText} />
        </React.Fragment>
    );
};

export default memo(LoaderOrError);

/**
 * Upcoming Transactions
 */

import React, { memo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../components';
import { makeSelectUpcomingTransactions } from '../selectors';
import { getUpcomingTransactionDetails } from '../actions';

const UpcomingTransactions = (props) => {
    const { ticketId, userId, index } = props;
    const dispatch = useDispatch();
    const upcomingTransactionDetails = useSelector(makeSelectUpcomingTransactions, shallowEqual);

    useEffect(() => {
        dispatch(getUpcomingTransactionDetails({ ticketId, userId, index }));
    }, []);

    let loadingUpcomingTransactions;
    let errInLoadingUpcomingTransactions;
    let upcomingTransactionsTableData;
    let upcomingTransactionExists;

    if (upcomingTransactionDetails[ticketId]) {
        ({
            loading: loadingUpcomingTransactions, data: upcomingTransactionsTableData, err: errInLoadingUpcomingTransactions,
        } = upcomingTransactionDetails[ticketId]);
        upcomingTransactionExists = upcomingTransactionDetails[ticketId] && upcomingTransactionDetails[ticketId].hasData;
    }

    return (
        <React.Fragment>
            {
                !errInLoadingUpcomingTransactions && (upcomingTransactionExists ? (
                    <div className='txns-container txns-container--ss' style={{ maxWidth: '1000px' }}>
                        <div className='table-sr table-sr--mt0'>
                            <div className='table-sr__label'>{upcomingTransactionsTableData.table_name}</div>
                            <div className='table-sr__thw'>
                                {
                                    upcomingTransactionsTableData.table_headers.map((tableHeader) => (
                                        <div className='table-sr__th mandates-table'>{tableHeader.label}</div>
                                    ))
                                }
                            </div>
                            <div className='table-sr__tlc'>
                                {
                                    upcomingTransactionsTableData.table_rows.map((rowEntry) => (
                                        <div className='table-sr__tlw' key={rowEntry.header_key_to_cell_value_map.id}>
                                            {
                                                upcomingTransactionsTableData.table_headers.map((tableHeader) => (
                                                    <div className='table-sr__tl mandates-table'>
                                                        {rowEntry.header_key_to_cell_value_map[tableHeader.header_key]}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='txns-container txns-container--ss' style={{ maxWidth: '1000px' }}>
                        No Existing Mandates found
                    </div>
                ))
            }
            {
                errInLoadingUpcomingTransactions && (
                    <div className='txns-container txns-container--ss' style={{ maxWidth: '1000px' }}>
                        Error while loading Upcoming Transactions
                    </div>
                )
            }
            <Loader visible={loadingUpcomingTransactions} />
        </React.Fragment>
    );
};

export default memo(UpcomingTransactions);

/**
 *
 * Facematch KYC Image
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';

import {
    Button, ExpandableCard, FormWithSingleInput, Loader, MonorailIdInput, HorizontalIdNavbar,
} from '../../components';
import { injectReducer, injectSaga, } from '../../utils';

import {
    getFacematchKYCImage, setMonorailId,
} from './actions';
import {
    makeSelectLoading, makeSelectErr, makeSelectImageInfo, makeSelectMonorailInfo,
} from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const FacematchKYCImage = () => {
    const dispatch = useDispatch();
    const imagePathRef = useRef(null);

    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const imageInfo = useSelector(makeSelectImageInfo(), shallowEqual);
    const monorailInfo = useSelector(makeSelectMonorailInfo(), shallowEqual);

    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(false);
    const [formErrLabel, setFormErrLabel] = useState('');
    const [imagePath, setImagePath] = useState('');

    const { id: monorailId } = monorailInfo;

    // set new ticket to true on initial render or when no monorail id present
    const [newTicket, setNewTicket] = useState(!monorailId);
    // set navbar items to empty array on initial render or when no monorail id present
    const [navItems, setNavItems] = useState(monorailId && [{ id: monorailId }] || []);

    useEffect(() => {
        if (monorailId && isSearchCardExpanded) {
            imagePathRef.current.focus();
        }
    }, [monorailId, isSearchCardExpanded])

    const resetMonorailId = () => {
        setNewTicket(true);
        setNavItems([]);
        dispatch(setMonorailId({ value: '' }));
    };

    const handleMonorailIdSubmit = (value) => {
        setImagePath('');
        setIsSearchCardExpanded(true);
        setNewTicket(false);
        setNavItems([{ id: value }]);
        dispatch(setMonorailId({ value }));
    };

    const renderIdNavbar = (
        <HorizontalIdNavbar
            isFixed
            hasSingleItem
            isNewTicketActive={newTicket}
            activeId={monorailId}
            navItems={navItems}
            onItemCloseIconClick={resetMonorailId}
        />
    );

    if (!monorailId) {
        return (
            <React.Fragment>
                {renderIdNavbar}
                <MonorailIdInput submitMonorailId={handleMonorailIdSubmit} />
            </React.Fragment>
        );
    }

    const handleInputChange = key => event => {
        const value = event.target.value;

        switch (key) {
            case 'imagePath':
                setImagePath(value);
                break;

            default:
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const modifiedImagePath = imagePath.trim();

        if (!modifiedImagePath) {
            setFormErrLabel('Please fill all the * marked fields');
            return;
        }

        setFormErrLabel('');
        dispatch(getFacematchKYCImage({
            monorailId,
            imagePath: modifiedImagePath,
        }));
        setIsSearchCardExpanded(false);
    };

    return (
        <React.Fragment>
            {renderIdNavbar}
            <div className='fmkyc-cr'>
                <ExpandableCard
                    extClasses='fmkyc-ec'
                    heading='Search for Facematch or KYC Image'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='fmkyc-sc'>
                        <FormWithSingleInput
                            hideErrLabel
                            disableFormSubmitOnEnter
                            extClasses={{
                                container: 'fmkyc-fc--m0',
                                label: 'fmkyc-fc__lb',
                                inputField: 'fmkyc-fc__ip',
                            }}
                            label='Image Path*'
                            labelId='image-path'
                            input={{
                                value: imagePath,
                                onChange: handleInputChange('imagePath'),
                                ref: imagePathRef,
                            }}
                            onFormSubmit={handleSubmit}
                        />
                        {formErrLabel ? <div className='err-label fmkyc-el'>{formErrLabel}</div> : null}
                        <Button
                            extClasses={`fmkyc-sc-cta ${formErrLabel && 'fmkyc-sc-cta--err'}`}
                            primary
                            label='Search'
                            onClick={handleSubmit}
                        />
                    </div>
                </ExpandableCard>
                {err ? (<div className='container-err-label fmkyc-crel'>{err}</div>) : null}
                {
                    (!loading && imageInfo.url) ? (
                        <div className='fmkyc-ic'>
                            <img src={imageInfo.url} height='600' alt='person-image' />
                        </div>
                    ) : null
                }
            </div>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'fmkycImage', reducer });
const withSaga = injectSaga({ key: 'fmkycImage', saga });

export default compose(
    withReducer,
    withSaga,
)(FacematchKYCImage);

/*
 *
 * DBStates actions
 *
 */

import {
    GET_SERVICE_LIST, GET_ENTITY_LIST, GET_OPTION_LIST, GET_DB_INFO, SET_OPTION_VALUE,
    SET_MONORAIL_ID,
} from './constants';

export const getServiceList = data => ({
    type: GET_SERVICE_LIST,
    data
});

export const getEntityList = data => ({
    type: GET_ENTITY_LIST,
    data
});

export const getOptionList = data => ({
    type: GET_OPTION_LIST,
    data
});

export const setOptionValue = data => ({
    type: SET_OPTION_VALUE,
    data
});

export const getDBInfo = data => ({
    type: GET_DB_INFO,
    data
});

export const setMonorailId = data => ({
    type: SET_MONORAIL_ID,
    data
});

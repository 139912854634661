/**
 * @file Manages the action functions for Credit Card component
 */

import {
    GET_CURRENT_CREDIT_CARD_DETAILS, GET_CREDIT_CARD_TRACKING_INFO, GET_CREDIT_CARD_CONTROL_DETAILS,
    GET_ALL_CREDIT_CARDS, GET_CREDIT_CARD_USAGE_DETAILS, UPDATE_CREDIT_CARD_USAGE_DETAILS, TOGGLE_SCREEN,
    GET_CREDIT_CARD_ONBOARDING_DETAILS, GET_CURRENT_LIMIT_USAGE_DETAILS, GET_CREDIT_CARD_BILLING_INFO,
    GET_ALL_ACTIVE_LOAN_ACCOUNTS, GET_ALL_CLOSED_LOAN_ACCOUNTS, GET_LOAN_ACCOUNT_DETAILS,
    GET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS, GET_LOAN_OFFERS, INITIATE_BLOCK_CARD, SET_BLOCK_CARD_CONFIRM_SCREEN,
    INITIATE_SUSPEND_CARD, SET_SUSPEND_CARD_CONFIRM_SCREEN, GET_FD_CREDIT_CARD_DETAILS,
    GET_CREDIT_CARD_ELIGIBILITY_DETAILS, GET_CREDIT_CARD_ONBOARDING_DETAILS_V2, GET_SELECTED_CREDIT_CARD_DETAILS,
    GET_CREDIT_CARD_CURRENT_STAGE_DETAILS, GET_CREDIT_CARD_NEXT_STAGE_DETAILS, SET_SHOW_ONBOARDING_DETAILS_V2, GET_MILESTONE_REWARDS_DETAILS,
} from './constants';

export const getCurrentCreditCardDetailsAction = (data) => ({
    type: GET_CURRENT_CREDIT_CARD_DETAILS,
    data,
});

export const getCreditCardOnboardingDetailsAction = (data) => ({
    type: GET_CREDIT_CARD_ONBOARDING_DETAILS,
    data,
});

export const getAllCreditCardDetailsAction = (data) => ({
    type: GET_ALL_CREDIT_CARDS,
    data,
});

export const getCreditCardTrackingInfoAction = (data) => ({
    type: GET_CREDIT_CARD_TRACKING_INFO,
    data,
});

export const getCreditCardControlDetailsAction = (data) => ({
    type: GET_CREDIT_CARD_CONTROL_DETAILS,
    data,
});

export const getCreditCardUsageDetailsAction = (data) => ({
    type: GET_CREDIT_CARD_USAGE_DETAILS,
    data,
});

export const updateCreditCardUsageDetailsAction = (data) => ({
    type: UPDATE_CREDIT_CARD_USAGE_DETAILS,
    data,
});

export const initiateBlockCardAction = (data) => ({
    type: INITIATE_BLOCK_CARD,
    data,
});

export const setBlockConfirmScreenAction = (data) => ({
    type: SET_BLOCK_CARD_CONFIRM_SCREEN,
    data,
});

export const initiateSuspendCardAction = (data) => ({
    type: INITIATE_SUSPEND_CARD,
    data,
});

export const setSuspendConfirmScreenAction = (data) => ({
    type: SET_SUSPEND_CARD_CONFIRM_SCREEN,
    data,
});

export const toggleScreenAction = (data) => ({
    type: TOGGLE_SCREEN,
    data,
});

export const getCurrentLimitUsageDetailsAction = (data) => ({
    type: GET_CURRENT_LIMIT_USAGE_DETAILS,
    data,
});

export const getCreditCardBillingInfoAction = (data) => ({
    type: GET_CREDIT_CARD_BILLING_INFO,
    data,
});

export const getAllActiveLoanAccountsAction = (data) => ({
    type: GET_ALL_ACTIVE_LOAN_ACCOUNTS,
    data,
});

export const getAllClosedLoanAccountsAction = (data) => ({
    type: GET_ALL_CLOSED_LOAN_ACCOUNTS,
    data,
});

export const getLoanAccountDetailsAction = (data) => ({
    type: GET_LOAN_ACCOUNT_DETAILS,
    data,
});

export const getAllEligibleTransactionsForEmisAction = (data) => ({
    type: GET_ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS,
    data,
});

export const getLoanOffersAction = (data) => ({
    type: GET_LOAN_OFFERS,
    data,
});

export const getFDCreditCardDetails = (data) => ({
    type: GET_FD_CREDIT_CARD_DETAILS,
    data,
});

// v2 actions
export const getEligibilityDetailsAction = (data) => ({
    type: GET_CREDIT_CARD_ELIGIBILITY_DETAILS,
    data,
});

export const setShowOnboardingDetailsV2Action = (data) => ({
    type: SET_SHOW_ONBOARDING_DETAILS_V2,
    data,
});

export const getOnboardingDetailsV2Action = (data) => ({
    type: GET_CREDIT_CARD_ONBOARDING_DETAILS_V2,
    data,
});

export const getSelectedCreditCardDetails = (data) => ({
    type: GET_SELECTED_CREDIT_CARD_DETAILS,
    data,
});

export const getCreditCardCurrentStageDetails = (data) => ({
    type: GET_CREDIT_CARD_CURRENT_STAGE_DETAILS,
    data,
});

export const getCreditCardNextStageDetails = (data) => ({
    type: GET_CREDIT_CARD_NEXT_STAGE_DETAILS,
    data,
});

export const getCreditCardMilestonRewardsDetails = (data) => ({
    type: GET_MILESTONE_REWARDS_DETAILS,
    data,
});

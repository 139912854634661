/*
 *
 * Kyc Onboarding actions
 *
 */

import {
    GET_KYC_INFO, GET_ONBOARDING_INFO, GET_REOOBE_INFO, GET_VKYC_INFO, RETRY_LIVENESS,
} from './constants';

export const getKycInfo = data => ({
    type: GET_KYC_INFO,
    data
});

export const getOnboardingInfo = data => ({
    type: GET_ONBOARDING_INFO,
    data
});

export const getReoobeInfo = data => ({
    type: GET_REOOBE_INFO,
    data
});

export const getVKycInfo = data => ({
    type: GET_VKYC_INFO,
    data
});

export const retryLiveness = data => ({
    type: RETRY_LIVENESS,
    data
});

/*
 *
 * CustomerDetails Constants
 *
 */

// key for reducer, selectors
export const CUSTOMER_DETAILS_KEY = 'customerDetails';

// action constants
export const GET_CUSTOMER_DETAILS = `app/${CUSTOMER_DETAILS_KEY}/GET_CUSTOMER_DETAILS`;
export const SET_CUSTOMER_DETAILS = `app/${CUSTOMER_DETAILS_KEY}/SET_CUSTOMER_DETAILS`;
export const ERR_CUSTOMER_DETAILS = `app/${CUSTOMER_DETAILS_KEY}/ERR_CUSTOMER_DETAILS`;
export const SET_MONORAIL_ID_FOR_CUSTOMER_DETAILS = `app/${CUSTOMER_DETAILS_KEY}/SET_MONORAIL_ID_FOR_CUSTOMER_DETAILS`;

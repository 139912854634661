import { APP_V2_KEY } from '../AppV2/constants';

export const SOP_KEY = `${APP_V2_KEY}/SOP`;

export const GET_PROFILE = `${SOP_KEY}/GET_PROFILE`;
export const SET_PROFILE = `${SOP_KEY}/SET_PROFILE`;
export const ERR_PROFILE = `${SOP_KEY}/ERR_PROFILE`;

export const GET_RECENT_ACTIVITIES = `${SOP_KEY}/GET_RECENT_ACTIVITIES`;
export const SET_RECENT_ACTIVITIES = `${SOP_KEY}/SET_RECENT_ACTIVITIES`;
export const ERR_RECENT_ACTIVITIES = `${SOP_KEY}/ERR_RECENT_ACTIVITIES`;

export const GET_RECENT_ACTIVITY_DETAILS = `${SOP_KEY}/GET_RECENT_ACTIVITY_DETAILS`;
export const SET_RECENT_ACTIVITY_DETAILS = `${SOP_KEY}/SET_RECENT_ACTIVITY_DETAILS`;
export const ERR_RECENT_ACTIVITY_DETAILS = `${SOP_KEY}/ERR_RECENT_ACTIVITY_DETAILS`;

export const GET_ISSUE_CATEGORY_DETAILS = `${SOP_KEY}/GET_ISSUE_CATEGORY_DETAILS`;
export const SET_ISSUE_CATEGORY_DETAILS = `${SOP_KEY}/SET_ISSUE_CATEGORY_DETAILS`;
export const ERR_ISSUE_CATEGORY_DETAILS = `${SOP_KEY}/ERR_ISSUE_CATEGORY_DETAILS`;

export const GET_USER_TICKETS = `${SOP_KEY}/GET_USER_TICKETS`;
export const SET_USER_TICKETS = `${SOP_KEY}/SET_USER_TICKETS`;
export const ERR_USER_TICKETS = `${SOP_KEY}/ERR_USER_TICKETS`;

export const GET_SOP_DETAILS = `${SOP_KEY}/GET_SOP_DETAILS`;
export const SET_SOP_DETAILS = `${SOP_KEY}/SET_SOP_DETAILS`;
export const ERR_SOP_DETAILS = `${SOP_KEY}/ERR_SOP_DETAILS`;

export const SET_TICKET_ID = `${SOP_KEY}/SET_TICKET_ID`;
export const SET_CALLER_ID = `${SOP_KEY}/SET_CALLER_ID`;

export const GET_SOP_NODE = `${SOP_KEY}/GET_SOP_NODE`;
export const SET_SOP_NODE = `${SOP_KEY}/SET_SOP_NODE`;
export const DEL_SOP_NODE = `${SOP_KEY}/DEL_SOP_NODE`;
export const ERR_SOP_NODE = `${SOP_KEY}/ERR_SOP_NODE`;
export const ADD_SOP_NODE_RESPONSE = `${SOP_KEY}/ADD_SOP_NODE_RESPONSE`;

export const COMPLETE_SOP_FLOW = `${SOP_KEY}/COMPLETE_SOP_FLOW`;
export const COMPLETED_SOP_FLOW = `${SOP_KEY}/COMPLETED_SOP_FLOW`;
export const ERR_IN_COMPLETING_SOP_FLOW = `${SOP_KEY}/ERR_IN_COMPLETING_SOP_FLOW`;

export const STEP_TYPE = {
    question: 'STEP_COMPONENT_TYPE_QUESTION',
    pictureGuide: 'STEP_COMPONENT_TYPE_PICTURE_GUIDE',
    finalStep: 'STEP_COMPONENT_TYPE_FINAL_STEP',
    infoDisplay: 'STEP_COMPONENT_TYPE_INFO_DISPLAY',
    fileUpload: 'STEP_COMPONENT_TYPE_FILE_UPLOAD',
    userAction: 'STEP_COMPONENT_TYPE_USER_ACTION',
};

export const CONDITION_TYPE = {
    simple: 'NEXT_STEP_CONDITION_TYPE_SIMPLE',
    label: 'NEXT_STEP_CONDITION_TYPE_LABEL',
    complex: 'NEXT_STEP_CONDITION_TYPE_COMPLEX',
};

export const ANSWER_TYPE = {
    radioButton: 'ANSWER_TYPE_RADIO_BUTTON',
    checkbox: 'ANSWER_TYPE_CHECKBOX',
    dropdown: 'ANSWER_TYPE_DROPDOWN',
    freeform: 'ANSWER_TYPE_FREEFORM',
};

export const renderDetails = (details) => (
    [
        {
            title: 'L1',
            value: details?.L1,
        },
        {
            title: 'L2',
            value: details?.L2,
        },
        {
            title: 'L3',
            value: details?.L3,
        },
        {
            title: 'Owner',
            value: details?.agent,
        },
        {
            title: 'Group',
            value: details?.group?.label,
        },
    ]
);

export const SCREEN_KEY = {
    search: 'search',
    script: 'sop',
};

export const SUB_SCREEN_KEY = {
    results: 'results',
    meta: 'meta',
};

export const TIME_IN_MILLISECONDS = 1000;

export const CATEGORY_VARIABLE_MAPPING = {
    l1: 'product_category_detail_meta_list',
    l2: 'sub_category_meta_list',
};

export const TICKET_ID_URL_PATTERN = /\/v2\/sop\/ticket\/([^/]+)/;

export const WAITING_TEXT = 'Waiting for ticket id from Sherlock V1';
export const REDIRECTING_TEXT = 'No Ticket ID Found! Select Ticket ID. Redirecting in';

export const BOOLEAN_OPTIONS = [
    {
        label: 'true',
        value: true,
    },
    {
        label: 'false',
        value: false,
    },
];

export const MASKING_TYPE = {
    email: 'MASKING_TYPE_EMAIL',
    trailing: 'MASKING_TYPE_TRAILING',
    leading: 'MASKING_TYPE_LEADING',
    full: 'MASKING_TYPE_FULL',
    alternate: 'MASKING_TYPE_ALTERNATE',
};

export const SUPPORTED_FIELD_DATA_TYPES = {
    string: 'string',
    decimal: 'number',
    integer: 'integer',
    date: 'google.type.Date',
    dateAndTime: 'date-time',
    bool: 'boolean',
    labelValuePairV2: 'types.webui.LabelValueV2',
    list: 'array',
};

export const LABEL_VALUE_ARRAY_DATA_TYPE = 'types.webui.LabelValue';
export const TABLE_DATA_TYPE = 'types.webui.Table';

export const SUPPORTED_ARRAY_FIELD_DATA_TYPES = {
    string: 'string',
    labelValuePairV2: 'types.webui.LabelValueV2',
};

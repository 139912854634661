/**
 * @file SalaryIdentifierInput
 * Shows a form to take in the type of identifier and the identifier to get salary account details
 */

import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, FormDropdown, FormWithSingleInput } from '../../../components';
import { useMemoizedSelector } from '../../../hooks';
import { validators, isNilOrEmpty } from '../../../utils';

import { setSalaryAccountDetailsUserIdentifierAction } from '../actions';
import { selectSalaryAccountUserIdentifierDetails } from '../selectors';

import './style.scss';

const IDENTIFIER_TYPES = [
    {
        label: 'EMAIL ID',
        value: 'emailId',
    },
    {
        label: 'PHONE NUMBER',
        value: 'phoneNumber',
    },
];

const VERIFICATION_QUEUE_IDENTIFIER_TYPES = IDENTIFIER_TYPES.concat([
    {
        label: 'ACTOR ID',
        value: 'actorId',
    },
]);

const SalaryIdentifierInput = (props) => {
    const {
        isSalaryDataOps, onSubmit, isVerificationQueue, screenKey,
    } = props;

    const dispatch = useDispatch();

    const userIdentifier = useMemoizedSelector(selectSalaryAccountUserIdentifierDetails, [screenKey]);

    let identifierOptions = IDENTIFIER_TYPES;

    if (isVerificationQueue) {
        identifierOptions = VERIFICATION_QUEUE_IDENTIFIER_TYPES;
    }

    let initialIdentifierType = '';
    let initialIdentifierValue = '';

    // extract user identifier type and identifier value from object
    if (!isNilOrEmpty(userIdentifier)) {
        const [identifierTypeValue] = Object.keys(userIdentifier);
        initialIdentifierType = identifierOptions.find((item) => item.value === identifierTypeValue);
        initialIdentifierValue = userIdentifier[identifierTypeValue];
    }

    const [identifierType, setIdentifierType] = useState(initialIdentifierType);
    const [identifierValue, setIdentifierValue] = useState(initialIdentifierValue);
    const [errLabel, setErrLabel] = useState('');

    if (!isSalaryDataOps) {
        return null;
    }

    const validateInputs = () => {
        if (!identifierType) {
            // if no identifier type is selected and its the verification queue then allow this as a valid state
            if (isVerificationQueue) {
                return false;
            }

            // if its not the verification queue then this is an invalid input state
            setErrLabel('Please enter an user identifier type');
            return true;
        }

        const { value: identifierTypeValue } = identifierType;

        if (identifierTypeValue === 'emailId' && !validators.isInputtedEmailValid(identifierValue)) {
            setErrLabel('Please enter a valid email ID');
            return true;
        }

        if (identifierTypeValue === 'phoneNumber' && !validators.isInputtedMobileNumberValid(identifierValue)) {
            setErrLabel('Please enter a valid mobile number');
            return true;
        }

        if (identifierTypeValue === 'actorId' && !identifierValue) {
            setErrLabel('Please enter a valid actor ID');
            return true;
        }

        return false;
    };

    const onChangeIdentifierType = (newValue) => {
        setIdentifierType(newValue);

        // clear identifier value if identifier type is cleared
        if (!newValue) setIdentifierValue('');
    };

    const handleSearchDetails = (e) => {
        e.preventDefault();

        if (validateInputs()) return;

        setErrLabel('');

        let identifier = {};

        if (identifierType) {
            const { value: identifierTypeValue } = identifierType;
            identifier[identifierTypeValue] = identifierValue;
        } else {
            identifier = null;
        }

        dispatch(setSalaryAccountDetailsUserIdentifierAction({
            screenKey,
            value: identifier,
        }));

        onSubmit(identifier);
    };

    const containerClass = isVerificationQueue ? 'sip-cr1' : 'sip-cr';

    return (
        <div className={containerClass}>
            <FormDropdown
                label='User Identifier Type'
                options={identifierOptions}
                input={{
                    value: identifierType,
                    onChange: onChangeIdentifierType,
                    placeholder: 'Choose...',
                    isClearable: true,
                }}
                cacheKey='salary-identifier-type'
                extStyles={{
                    container: 'sip-fc1 mb-15',
                    label: 'sip-fc__lb',
                    inputField: 'sip-fc__ip',
                }}
            />
            <FormWithSingleInput
                disableFormSubmitOnEnter
                onFormSubmit={handleSearchDetails}
                extClasses={{
                    container: 'sip-fc2 mb-15',
                    label: 'sip-fc__lb',
                    inputField: 'sip-fc__ip',
                }}
                label='User Identifier Value'
                labelId='salary-identifier-value'
                formType='text'
                input={{
                    value: identifierValue,
                    onChange: (e) => setIdentifierValue(e.target.value),
                }}
            />
            {errLabel && <div className='err-label label1 sip-elb'>{errLabel}</div>}
            <Button
                v2
                label='Search'
                primary
                onClick={handleSearchDetails}
            />
        </div>
    );
};

export default memo(SalaryIdentifierInput);

/**
 * @file PAL Liveness Reviewer Constants
 */

import { FEDERAL_LENDING_PATH } from '../App/routes';
import { RISK_OPS_COMMON_STATE } from '../RiskOps/constants';

export const PAL_LIVENESS_SIDEBAR_CONSTANTS = [
    {
        id: '9c1d649d-6277-47ec-a840-e5913f435261',
        path: `${FEDERAL_LENDING_PATH}/loan-liveness-reviewer`,
        label: 'Loan Liveness Reviewer',
        pathId: '-',
    },
];

export const LOAN_LIVENESS_REVIEW_KEY = 'loanLivenessReview';

export const PAYLOAD_TYPE_PAL_APPLICATION_LIVENESS_FEDERAL_REVIEW = 'PAYLOAD_TYPE_PAL_APPLICATION_LIVENESS_FEDERAL_REVIEW';

export const QUEUE_PAYLOAD_TYPES = [
    PAYLOAD_TYPE_PAL_APPLICATION_LIVENESS_FEDERAL_REVIEW,
];

const PAYLOAD_DD_OG_STATE = {
    value: PAYLOAD_TYPE_PAL_APPLICATION_LIVENESS_FEDERAL_REVIEW,
    isVisible: false,
};

export const PAL_FEDERAL_REVIEWER_STATE = {
    value: PAYLOAD_TYPE_PAL_APPLICATION_LIVENESS_FEDERAL_REVIEW,
    isVisible: false,
};

export const LOAN_LIVENESS_SPECIFIC_STATE = {
    payloadType: PAYLOAD_DD_OG_STATE,
    videoUrl: '',
};

export const LOAN_LIVENESS_REDUCER_STATE = {
    ...RISK_OPS_COMMON_STATE,
    ...LOAN_LIVENESS_SPECIFIC_STATE,
};

export const PAL_DOWNLOAD_ENDPOINT = '/api/v1/risk-ops/download-file';

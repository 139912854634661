/**
 * @file DequeueCase
 * Dequeue a case for a particular queue
 */

import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '../../../components';

import DequeueCaseModal from '../DequeueCaseModal';

const DequeueCase = (props) => {
    const { dequeueAction } = props;
    const dispatch = useDispatch();

    // local state variables
    const [showDequeueCaseModal, setDequeueCaseModalVisibility] = useState(false);

    const handleDequeueCase = () => {
        dispatch(dequeueAction);
        setDequeueCaseModalVisibility(false);
    };

    return (
        <React.Fragment>
            <div className='rops-cr1'>
                <div className='rops-hl2'>If the above action does not suit your need, you can dequeue this case</div>
                <Button
                    v2
                    secondary
                    label='Remove User From Queue'
                    onClick={() => setDequeueCaseModalVisibility(true)}
                />
            </div>

            <DequeueCaseModal
                visible={showDequeueCaseModal}
                submitCta={handleDequeueCase}
                closeCta={setDequeueCaseModalVisibility}
            />
        </React.Fragment>
    );
};

export default memo(DequeueCase);

/**
 * @file UserProducts saga
 */

import {
    all, call, put, takeLatest,
} from 'redux-saga/effects';

import {
    RISK_OPS_USER_PRODUCTS_INFO_ENDPOINT,
    RISK_OPS_USER_PRODUCTS_LIST_ENDPOINT,
} from '../../api/routes';
import {
    clientApiWrapper, getErrLabel, getErrMsg, getQueryStringFromObject, toastify,
} from '../../utils';

import {
    ERROR_USER_RISK_PRODUCT_INFO, ERROR_USER_RISK_PRODUCT_LIST, GET_USER_RISK_PRODUCT_INFO_DATA,
    GET_USER_RISK_PRODUCT_LIST_DATA, SET_USER_RISK_PRODUCT_INFO_DATA, SET_USER_RISK_PRODUCT_LIST_DATA,
} from './constants';

function* getUserProductListSaga(action) {
    const {
        actorId, caseId,
    } = action.data;

    const ENDPOINT = RISK_OPS_USER_PRODUCTS_LIST_ENDPOINT;
    const queryReq = {
        caseId,
    };

    if (!caseId && actorId) {
        queryReq.actorId = actorId;
    }

    const queryString = getQueryStringFromObject(queryReq, true);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_USER_RISK_PRODUCT_LIST_DATA, data: response.products });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERROR_USER_RISK_PRODUCT_LIST, data: { err: getErrMsg(e) } });
    }
}

function* getUserProductInfoDataSaga(action) {
    const {
        actorId, caseId, product,
    } = action.data;

    const ENDPOINT = RISK_OPS_USER_PRODUCTS_INFO_ENDPOINT;
    const queryReq = {
        caseId,
        product: product?.value,
    };

    if (!caseId && actorId) {
        queryReq.actorId = actorId;
    }

    const queryString = getQueryStringFromObject(queryReq, true);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ENDPOINT}?${queryString}`,
        );

        yield put({ type: SET_USER_RISK_PRODUCT_INFO_DATA, data: response.reviewSections });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERROR_USER_RISK_PRODUCT_INFO, data: { err: getErrMsg(e) } });
    }
}

export default function* UserProductsSaga() {
    yield all(
        [
            yield takeLatest(GET_USER_RISK_PRODUCT_LIST_DATA, getUserProductListSaga),
            yield takeLatest(GET_USER_RISK_PRODUCT_INFO_DATA, getUserProductInfoDataSaga),
        ],
    );
}

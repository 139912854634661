/**
 * @file History cards component
 * Component that lists date and time card
 */
import classNames from 'classnames';
import React from 'react';

const Card = ({
    onClick, data, selected, index,
}) => {
    const cardClassnames = classNames({
        'urr-rmV2-hc-card': true,
        'urr-rmV2-hc-card-selected': selected,
    });
    const [date, time] = React.useMemo(() => data?.created_at?.split(' ') || ['', ''], [data.created_at]);

    const handleOnClick = () => {
        onClick(index, data);
    };

    return (
        <button
            className={cardClassnames}
            type='button'
            style={{ color: `${data.background_color || '#000'}` }}
            onClick={handleOnClick}
        >
            <div>{date}</div>
            <div>{time}</div>
        </button>
    );
};

const HistoryCards = (props) => {
    const {
        data, onClick, selectedIndex = -1, className,
    } = props;

    const containerClass = classNames('urr-rmV2-hc-container', className);

    return (
        <div className={containerClass}>
            {data.map((item, index) => (
                <Card
                    key={item.request_id}
                    selected={selectedIndex === index}
                    data={item}
                    index={index}
                    onClick={onClick}
                />
            ))}
        </div>
    );
};

export default HistoryCards;

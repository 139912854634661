/**
 * @file SingleCaseHeading
 * Displays the title of a single case with the back arrow button
 */

import React, { memo } from 'react';

import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';

const SingleCaseHeading = (props) => {
    const { title, onBackArrowCTA } = props;

    return (
        <div className='rops-hl__cr'>
            <button className='rops-barw__cr' type='button' onClick={onBackArrowCTA}>
                <img className='rops-barw' alt='back-arrow' src={backArrowIcon} />
            </button>
            <div className='rops-hl1'>{title}</div>
        </div>
    );
};

export default memo(SingleCaseHeading);

import { createSelector } from 'reselect';

const getSOP = (state) => state.get('sop');

export const selectCallerId = createSelector(
    (state) => getSOP(state)?.getIn(['callerId']),
    (substate) => substate,
);

export const selectTicketId = createSelector(
    (state) => getSOP(state)?.getIn(['ticketId']),
    (substate) => substate,
);

export const selectUserDetails = createSelector(
    (state) => getSOP(state)?.getIn(['userDetails']),
    (substate) => substate?.toJS(),
);

export const selectUserActivities = createSelector(
    (state) => getSOP(state)?.getIn(['userActivities']),
    (substate) => substate?.toJS(),
);

export const selectUserTickets = createSelector(
    (state) => getSOP(state)?.getIn(['userTickets']),
    (substate) => substate?.toJS(),
);

export const selectSopDetails = createSelector(
    (state) => getSOP(state)?.getIn(['tree']),
    (substate) => substate?.toJS(),
);

export const selectSOPNode = createSelector(
    (state) => getSOP(state)?.getIn(['tree', 'nodes']),
    (substate) => substate?.toJS(),
);

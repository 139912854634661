/**
 * @file Manages the constants for Search component
 */

import { APP_V2_KEY } from '../AppV2/constants';

export const SEARCH_KEY = `${APP_V2_KEY}/SEARCH`;
export const GET_SEARCH_SOP = `${SEARCH_KEY}/GET_SEARCH_SOP`;
export const SET_SEARCH_SOP = `${SEARCH_KEY}/SET_SEARCH_SOP`;
export const ERR_SEARCH_SOP = `${SEARCH_KEY}/ERR_SEARCH_SOP`;

export const GET_SEARCH_SOP_META = `${SEARCH_KEY}/GET_SEARCH_SOP_META`;
export const SET_SEARCH_SOP_META = `${SEARCH_KEY}/SET_SEARCH_SOP_META`;
export const ERR_SEARCH_SOP_META = `${SEARCH_KEY}/ERR_SEARCH_SOP_META`;

export const CATEGORY_VARIABLE_MAPPING = {
    l1: 'product_category_detail_meta_list',
    l2: 'sub_category_meta_list',
};

export const TIME_IN_MILLISECONDS = 1000;

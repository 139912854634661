import { createSelector } from 'reselect';

const selectAdminActions = state => state.get('adminActions');
const selectLoading = state => state.getIn(['adminActions', 'loading']);
const selectErr = state => state.getIn(['adminActions', 'err']);
const selectActionList = state => state.getIn(['adminActions', 'actionList']);
const selectParameterList = state => state.getIn(['adminActions', 'parameterList']);
const selectExecuteInfo = state => state.getIn(['adminActions', 'executeInfo']);
const selectHasExecuteInfo = state => state.getIn(['adminActions', 'hasExecuteInfo']);

const makeSelectLoading = () => createSelector(
    selectLoading,
    substate => substate
);

const makeSelectErr = () => createSelector(
    selectErr,
    substate => substate
);

const makeSelectActionList = () => createSelector(
    selectActionList,
    substate => substate.toJS()
);

const makeSelectParameterList = () => createSelector(
    selectParameterList,
    substate => substate.toJS()
);

const makeSelectExecuteInfo = () => createSelector(
    selectExecuteInfo,
    substate => substate.toJS()
);

const makeSelectHasExecuteInfo = () => createSelector(
    selectHasExecuteInfo,
    substate => substate
);

export {
    makeSelectLoading,
    makeSelectErr,
    makeSelectActionList,
    makeSelectParameterList,
    makeSelectExecuteInfo,
    makeSelectHasExecuteInfo,
};

/**
 *
 * Actions for CX dispute flow
 *
 */

import {
    GET_CX_DISPUTES_INFO, UPDATE_CX_DISPUTES_INFO, GET_CX_DISPUTES_INFO_FOR_ACTOR,
} from './constants';

export const getDisputesInfoAction = (data) => ({
    type: GET_CX_DISPUTES_INFO,
    data,
});

export const updateDisputesInfoAction = (data) => ({
    type: UPDATE_CX_DISPUTES_INFO,
    data,
});

export const getDisputesInfoActionForUser = (data) => ({
    type: GET_CX_DISPUTES_INFO_FOR_ACTOR,
    data,
});

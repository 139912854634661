/*
 *
 * WaitlistDetails constants
 *
 */

export const GET_WAITLIST_USER_INFO = 'app/WD/GET_WAITLIST_USER_INFO';
export const SET_WAITLIST_USER_INFO = 'app/WD/SET_WAITLIST_USER_INFO';
export const ERR_WAITLIST_USER_INFO = 'app/WD/ERR_WAITLIST_USER_INFO';
export const RESET_WAITLIST_USER_INFO = 'app/WD/RESET_WAITLIST_USER_INFO';

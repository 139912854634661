import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { imageNodeProxyRoute } from './downloadFile';
import Button from '../components/Button';

export const loadImage = (src) => new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
});

const ImageCropUtils = (props, isNodeRouteRequired = true) => {
    const { url, styles, containerClass } = props;
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    return (
        <div className={containerClass}>
            <Cropper
                image={(isNodeRouteRequired && imageNodeProxyRoute(url)) || url}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                style={styles}
                maxZoom={5}
            />
        </div>
    );
};

export const AutoScaleImageViewer = ({
    url,
    captureOnClick,
    baseContainerClass = '',
    baseImageClass = '',
    landscapeContainerClass = '',
    landscapeImageClass = '',

}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isPortraitImage, setAsPortrait] = useState(true);

    const imageUrlSrc = imageNodeProxyRoute(url) || url;

    useEffect(() => {
        setIsLoading(true);
        loadImage(imageUrlSrc).then((img) => {
            const isHorizontal = img.width > img.height;
            setAsPortrait(!isHorizontal);
            setIsLoading(false);
        }).catch((err) => {
            console.error('Error loading image:', err);
            setIsLoading(false);
        });
    }, [imageUrlSrc]);

    return (
        <div className={` ${baseContainerClass} 
        ${!isPortraitImage && landscapeContainerClass}`}
        >
            {
                !isLoading ? (
                    <ImageCropUtils
                        url={imageUrlSrc}
                        containerClass={
                            `${baseImageClass} ${!isPortraitImage && landscapeImageClass}`
                        }
                    />
                ) : ''
            }
            { captureOnClick && (
                <Button
                    v2
                    secondary
                    label={imageUrlSrc ? 'Retake' : 'Capture'}
                    extClasses={`vkyc-image-btn__capture ${!isPortraitImage && 'vkyc-image-btn__capture-horizontal'}`}
                    onClick={captureOnClick}
                />
            )}

        </div>
    );
};

export default ImageCropUtils;

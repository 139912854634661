/**
* @file CommentListView: component used for viewing the set of comments
*/

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const CommentListView = (props) => {
    const {
        comments,
        renderCommentBody,
        caseId,
    } = props;

    const getCaseString = (curCase) => {
        const isCurrentCase = Number(caseId) === Number(curCase);
        const classes = classNames('m-0', 'clv-cc', {
            'clv-ccc': isCurrentCase,
        });

        return (
            <p className={classes}>
                {
                    isCurrentCase
                        ? 'Current Case'
                        : `Case Id : ${curCase}`
                }
            </p>
        );
    };

    return (
        <div className='clv'>
            {comments?.map((item) => (
                <div className='clv-ct'>
                    <div className='clv-cm'>
                        <div className='clv-ch'>
                            <span className='clv-cbd' />
                            <div className='clv-ci'>
                                {getCaseString(item.caseId)}
                                <span className='clv-ca'>{item.author}</span>
                                <p className='m-0'>
                                    {item.title}
                                </p>
                            </div>
                        </div>
                        <div className='clv-cb'>
                            {renderCommentBody ? renderCommentBody(item) : item.comment}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

CommentListView.propTypes = {
    comments: PropTypes.arrayOf( // list of the comments
        PropTypes.shape({
            comment: PropTypes.string.isRequired, // comment body
            author: PropTypes.string.isRequired, // author of the comment
            title: PropTypes.string, // title of the comment
        }),
    ).isRequired || [],
    renderCommentBody: PropTypes.func || null,
    caseId: PropTypes.string,
};

CommentListView.defaultProps = {
    comments: [],
    renderCommentBody: null,
    caseId: '',
};

export default memo(CommentListView);

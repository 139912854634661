import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { FITTT_ROOT } from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_ACTIVE_RULES, SET_ACTIVE_RULES, ERR_ACTIVE_RULES, GET_USER_RULES, SET_USER_RULES,
    ERR_USER_RULES, GET_USER_RULE_INFO, SET_USER_RULE_INFO, ERR_USER_RULE_INFO,
} from './constants';

function* getActiveRules(action) {
    const { ticketId, userId, index, prev, next } = action.data;
    const queryString = getQueryStringFromObject({ userId, prev, next });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${FITTT_ROOT}/${ticketId}/rules?${queryString}`
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_ACTIVE_RULES, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_ACTIVE_RULES, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ACTIVE_RULES, data: { ticketId, err: e.message } });
    }
}

function* getUserRules(action) {
    const { ticketId, userId, index, prev, next } = action.data;
    const queryString = getQueryStringFromObject({ userId, prev, next });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${FITTT_ROOT}/${ticketId}/user-rules?${queryString}`
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_USER_RULES, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_USER_RULES, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_RULES, data: { ticketId, err: e.message } });
    }
}

function* getUserRuleInfo(action) {
    const { ticketId, userId, index, subscriptionId, prev, next } = action.data;
    const queryString = getQueryStringFromObject({ userId, subscriptionId, prev, next });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${FITTT_ROOT}/${ticketId}/user-rule-info?${queryString}`
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_USER_RULE_INFO, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_USER_RULE_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_USER_RULE_INFO, data: { ticketId, err: e.message } });
    }
}

export default function* fitttSaga() {
    yield all(
        [
            yield takeLatest(GET_ACTIVE_RULES, getActiveRules),
            yield takeLatest(GET_USER_RULES, getUserRules),
            yield takeLatest(GET_USER_RULE_INFO, getUserRuleInfo),
        ]
    );
}

/**
 * @file Component to submit a CSV for different flows
 * This comes under the account ops role
 * Each tab has the same functionality of uploading a CSV and making an API call
 * The data for each tab differs and each tab make a request to a different RPC
 */

import React, { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';

// components
import UploadCSV from './UploadCSV';

// logic
import { ACCOUNT_OPS_KEY } from './constants';
import { CONTAINER_INFO_MAP, URL_TO_CONTAINER_MAP } from './utils';

// state management & styles
import reducer from './reducer';
import saga from './saga';
import './style.scss';

const SubmitAccountInfo = () => {
    const { pathname } = useLocation();

    const [containerData, setContainerData] = useState({});

    useEffect(() => {
        const containerKey = URL_TO_CONTAINER_MAP[pathname];
        const curContainerData = CONTAINER_INFO_MAP[containerKey].data;

        setContainerData(curContainerData);
    }, [pathname]);

    return (
        <div className='sai'>
            <UploadCSV data={containerData} />
        </div>
    );
};

const withReducer = injectReducer({ key: ACCOUNT_OPS_KEY, reducer });
const withSaga = injectSaga({ key: ACCOUNT_OPS_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(memo(SubmitAccountInfo));

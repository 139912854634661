const getFormattedFreelacerInfo = (data) => [
    {
        label: 'Profession',
        value: data.profession,
    },
    {
        label: 'Work URL',
        value: data.workUrl,
        copy: 'Copy Link', 
    },
    {
        label: 'Work Email',
        value: data.workEmail,
    },
    {
        label: 'Comm Email',
        value: data.commEmail,
    },
    {
        label: 'Current Waitlist Status',
        value: data.currentStatus,
    },
    {
        label: 'Reason',
        value: data.reason,
    },
];

export {
    getFormattedFreelacerInfo,
};

/**
 *
 * AppLogsList
 *
 */

import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    Button, ExpandableCard, FormDropdown, FormWithSingleInput, Loader, Table,
} from '../../../components';
import { dateUtils, getInitialDateRange, getQueryStringFromObject, validators } from '../../../utils';
import { VIEW_APP_LOG_PATH } from '../../App/routes';

import { getLogs, getLogsEnumsAction } from '../actions';
import {
    makeSelectLoading, makeSelectErr, makeSelectLogsList, makeSelectIsSearchInitiated, makeSelectAppLogs,
} from '../selectors';
import '../style.scss';
import { IDENTIFIER_TYPES_INFO } from '../constants';

const AppLogsList = (props) => {
    const dispatch = useDispatch();
    const identifierValueRef = useRef(null);

    const loading = useSelector(makeSelectLoading(), shallowEqual);
    const err = useSelector(makeSelectErr(), shallowEqual);
    const logsList = useSelector(makeSelectLogsList(), shallowEqual);
    const isSearchInitiated = useSelector(makeSelectIsSearchInitiated(), shallowEqual);
    const identifierTypeList = useSelector(makeSelectAppLogs(), shallowEqual);

    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(logsList.length === 0);
    const [identifierValue, setIdentifierValue] = useState('');
    const [identifierType, setIdentifierType] = useState();
    const {
        fromDate, toDate,
    } = getInitialDateRange('10-01-2020'); // 01 Oct 2020
    const [fromExportDate, setFromExportDate] = useState(fromDate);
    const [toExportDate, setToExportDate] = useState(toDate);
    const [errLabel, setErrLabel] = useState('');
    const { monorailId } = props;

    useEffect(() => {
        if (identifierType) {
            identifierValueRef.current.focus();
        }
    }, [identifierType]);

    useEffect(() => {
        dispatch(getLogsEnumsAction());
    }, [dispatch]);

    const renderActions = (data) => {
        const queryString = getQueryStringFromObject({ logId: btoa(data.logKey) });

        return (
            <div className='frfscWrapper'>
                <Link
                    to={`${VIEW_APP_LOG_PATH}?${queryString}`}
                    className='link'
                >
                    View Logs
                </Link>
            </div>
        );
    };

    const handleInputChange = (key) => (e) => {
        switch (key) {
            case 'identifierValue':
                setIdentifierValue(e.target.value);
                break;
            case 'identifierType':
                setIdentifierType(e);
                break;
            default:
        }
    };

    // To validate identifier value input
    const validateInput = () => {
        if (identifierType?.label === IDENTIFIER_TYPES_INFO.EMAIL) return validators.isInputtedEmailValid(identifierValue);
        if (identifierType?.label === IDENTIFIER_TYPES_INFO.PHONE) return validators.isInputtedMobileNumberValid(identifierValue);
        if (identifierType?.label === IDENTIFIER_TYPES_INFO.ACTOR) return true;
        return false;
    };

    const handleDateChange = (key) => (value) => {
        const data = {
            key,
            value,
            fromDate: fromExportDate,
            toDate: toExportDate,
        };
        const { fromDate: newFromExportDate, toDate: newToExportDate } = dateUtils.getModifiedDate(data);
        setFromExportDate(newFromExportDate);
        setToExportDate(newToExportDate);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrLabel('');

        const modifiedIdentifierValue = identifierValue.trim();

        if (!modifiedIdentifierValue || !identifierType || !fromExportDate.value || !toExportDate.value) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        if (!validateInput()) {
            setErrLabel('Please enter valid identifier value');
            return;
        }

        setErrLabel('');
        dispatch(getLogs({
            identifierValue: modifiedIdentifierValue,
            identifierType: identifierType.value,
            from: fromExportDate.value,
            to: toExportDate.value,
            monorailId,
        }));
        setIsSearchCardExpanded(false);
    };

    const renderSearchResults = () => {
        if (!isSearchInitiated) return null;

        return (
            <Table
                header={logsList?.tableName}
                labelData={logsList?.columnData}
                contentData={logsList?.rowData}
                renderActionableColumn={renderActions}
                errLabel={err?.logsList}
                nsrLabel='No logs found!'
            />
        );
    };

    const renderDateBlock = (key) => {
        let label;
        let labelId;
        let value;
        let maxDate;
        let minDate;

        switch (key) {
            case 'fromDate':
                label = 'From Export Date*';
                labelId = 'from-export-date';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = fromExportDate);
                break;

            case 'toDate':
                label = 'To Export Date*';
                labelId = 'to-export-date';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = toExportDate);
                break;

            default:
        }

        return (
            <FormWithSingleInput
                hideErrLabel
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'applogslist-fc--m0',
                    label: 'applogslist-fc__lb',
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: handleDateChange(key),
                    maxDate,
                    minDate,
                }}
                onFormSubmit={handleSubmit}
            />
        );
    };

    return (
        <React.Fragment>
            <div className='applogs-container'>
                <ExpandableCard
                    extClasses='applogslist-ec'
                    heading='Search for App Logs'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='applogslist-sc'>
                        <div className='frWrapper'>
                            <FormDropdown
                                label='Identifier Type*'
                                options={identifierTypeList}
                                input={{
                                    autoFocus: true,
                                    value: identifierType,
                                    onChange: handleInputChange('identifierType'),
                                    placeholder: 'Choose...',
                                    isClearable: true,
                                }}
                                cacheKey='identifier-type'
                                extStyles={{
                                    container: 'applogslist-fc--m0',
                                    label: 'applogslist-fc__lb applogslist-fc__lb-dd',
                                    inputField: 'applogslist-fc__ip',
                                }}
                            />
                            <FormWithSingleInput
                                hideErrLabel
                                disableFormSubmitOnEnter
                                extClasses={{
                                    container: 'applogslist-fc--m0',
                                    label: 'applogslist-fc__lb',
                                }}
                                label='Identifier Value*'
                                labelId='identifier-value'
                                formType='text'
                                input={{
                                    value: identifierValue,
                                    onChange: handleInputChange('identifierValue'),
                                    ref: identifierValueRef,
                                }}
                                onFormSubmit={handleSubmit}
                            />
                        </div>
                        <div className='frWrapper'>
                            {renderDateBlock('fromDate')}
                            {renderDateBlock('toDate')}
                        </div>
                        {errLabel ? <div className='err-label applogslist-el'>{errLabel}</div> : null}
                        <div className='frWrapper applogslist-sc__3r'>
                            <Button
                                extClasses='applogslist-sc__cta'
                                primary
                                label='Search'
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </ExpandableCard>
                <div className='applogslist-src'>
                    {renderSearchResults()}
                </div>
            </div>
            <Loader visible={loading && loading.logsList} />
        </React.Fragment>
    );
};

export default memo(AppLogsList);

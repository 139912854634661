/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
/**
 *
 * UserRequests
 *
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

import {
    BA_ACCOUNT_CLOSURE_USER_REQUEST_TICKET_PATH, BA_ACCOUNT_STATEMENT_USER_REQUEST_TICKET_PATH,
    BA_CHEQUEBOOK_USER_REQUEST_SEND_NOTIFICATIONS_PATH, BA_CHEQUEBOOK_USER_REQUEST_TICKET_PATH,
} from '../App/routes';

import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';

import AccountStatement from './AccountStatement';
import AccountClosure from './AccountClosure';
import ChequebookRequest from './ChequebookRequest';
import { SCREEN } from '../Tickets/constants';

import reducer from './reducer';
import saga from './saga';
import PromptNotification from './PromptNotification';

const UserRequests = (props) => {
    const { renderAuthUpgradeIfRequired } = props;

    const getCommonProps = () => {
        const {
            ticketId, index, userId, phoneNumber, name,
        } = props;

        return {
            index,
            ticketId,
            userId,
            phoneNumber,
            name,
        };
    };

    return (
        <Switch>
            <Route
                path={BA_ACCOUNT_STATEMENT_USER_REQUEST_TICKET_PATH}
                render={
                    () => renderAuthUpgradeIfRequired(
                        (
                            <AccountStatement {...getCommonProps()} />
                        ), SCREEN.MODERATELY_SENSITIVE_SCREEN,
                    )
                }
            />
            <Route
                path={BA_ACCOUNT_CLOSURE_USER_REQUEST_TICKET_PATH}
                render={
                    () => renderAuthUpgradeIfRequired(
                        (
                            <AccountClosure {...getCommonProps()} />
                        ), SCREEN.INSENSITIVE_SCREEN,
                    )
                }
            />
            <Route
                path={BA_CHEQUEBOOK_USER_REQUEST_TICKET_PATH}
                render={
                    () => renderAuthUpgradeIfRequired(
                        (
                            <ChequebookRequest {...getCommonProps()} />
                        ), SCREEN.MODERATELY_SENSITIVE_SCREEN,
                    )
                }
            />
            <Route
                path={BA_CHEQUEBOOK_USER_REQUEST_SEND_NOTIFICATIONS_PATH}
                render={
                    () => renderAuthUpgradeIfRequired(
                        (
                            <PromptNotification {...getCommonProps()} />
                        ), SCREEN.LESS_SENSITIVE_SCREEN,
                    )
                }
            />

        </Switch>
    );
};

const withReducer = injectReducer({ key: 'userRequests', reducer });
const withSaga = injectSaga({ key: 'userRequests', saga });

export default compose(
    withReducer,
    withSaga,
)(UserRequests);

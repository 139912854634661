/**
 * UploadCSV - Component to update the bulk ticket details by uploading csv in specified format
 */

import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, FormWithSingleInput, Loader,
} from '../../../components';
import { validators, toastify } from '../../../utils';

import { setUploadCSVFormParams, updateBulkTicketsInfo } from '../actions';
import { makeSelectUploadCSVFormParameters, makeSelectLoading, makeSelectUpdatedBulkTicketJobInfo } from '../selectors';

import './style.scss';

const UploadCSV = () => {
    const dispatch = useDispatch();

    // reducer state variables
    const uploadCSVFormParameters = useSelector(makeSelectUploadCSVFormParameters(), shallowEqual);
    const isLoading = useSelector(makeSelectLoading(), shallowEqual);
    const updatedBulkTicketJobInfo = useSelector(makeSelectUpdatedBulkTicketJobInfo(), shallowEqual);

    // local state variables
    const [errLabel, setErrLabel] = useState('');

    const { description, emailId, csvFile } = uploadCSVFormParameters;

    const onInputChange = (key) => (e) => {
        const { value } = e.target;

        dispatch(setUploadCSVFormParams({ key, value }));
    };

    const onFileClick = (e) => {
        e.target.value = null;

        // remove the previously selected file
        dispatch(setUploadCSVFormParams({ key: 'csvFile', value: null }));
    };

    const onFileChange = (e) => {
        const fileList = e.target.files;
        const file = fileList && fileList[0];

        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        // options to read file in other formats
        // reader.readAsText(file);
        // reader.readAsDataURL(file);

        reader.onload = function () {
            // value of the file is received as an array buffer
            const arrayBuffer = reader.result;

            /**
             * convert array buffer to base64 encoded string
             * direct wrapping with btoa() does not work as expected
             * ref: https://stackoverflow.com/a/42334410
             */
            const contentBase64 = btoa(
                new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''),
            );

            dispatch(setUploadCSVFormParams({ key: 'csvFile', value: contentBase64 }));
        };

        reader.onerror = function () {
            toastify('There is some problem in reading the file, please check & try again', 'error');
        };
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // check if all the fields are filled
        if (!description || !emailId || !csvFile) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        // check if valid email id is inputted
        if (!validators.isInputtedEmailValid(emailId)) {
            setErrLabel('Please enter a valid email id');
            return;
        }

        setErrLabel('');
        dispatch(updateBulkTicketsInfo({
            description,
            emailId,
            csvFile,
        }));
    };

    return (
        <React.Fragment>
            <div className='btu-upload-csv'>
                <FormWithSingleInput
                    disableFormSubmitOnEnter
                    label='Enter Description*'
                    labelId='btu-upload-csv-description'
                    formType='textarea'
                    input={{
                        value: description,
                        onChange: onInputChange('description'),
                        rows: 4,
                        cols: 50,
                    }}
                    extClasses={{
                        container: 'btu-upload-csv-fc',
                        label: 'btu-upload-csv-fc__lb',
                        inputField: 'btu-upload-csv-fc__ip',
                    }}
                />
                <FormWithSingleInput
                    disableFormSubmitOnEnter
                    label="Enter Checker's Email ID*"
                    description='Email ID of whoever approved it'
                    labelId='btu-upload-csv-checker-email'
                    input={{
                        value: emailId,
                        onChange: onInputChange('emailId'),
                    }}
                    extClasses={{
                        container: 'btu-upload-csv-fc',
                        label: 'btu-upload-csv-fc__lb',
                        description: 'btu-upload-csv-fc__desc',
                        inputField: 'btu-upload-csv-fc__ip',
                    }}
                />
                <div className='frcWrapper btu-upload-csv-fc'>
                    <div className='btu-upload-csv-fc__lb'>
                        Upload Ticket Details*
                    </div>
                    <div className='fcWrapper'>
                        <input
                            type='file'
                            className='btu-upload-csv-fc__ip'
                            accept='.csv'
                            onClick={onFileClick}
                            onChange={onFileChange}
                        />
                        <a
                            className='link btu-upload-csv-sample-link'
                            href='/assets/bulk-ticket-update-sample.csv'
                            download='bulk-ticket-update-sample'
                        >
                            Download Sample Format
                        </a>
                    </div>
                </div>
                <div className='err-label btu-upload-csv-el'>{errLabel}</div>
                <Button
                    v2
                    primary
                    label='Submit'
                    onClick={handleSubmit}
                    extClasses='btu-upload-csv-cta'
                />
                {
                    updatedBulkTicketJobInfo.id ? (
                        <div className='btu-upload-csv-job-id'>
                            {`
                                Your bulk ticket job has been queued successfully.
                                Please note job id ${updatedBulkTicketJobInfo.id} for future reference.
                            `}
                        </div>
                    ) : null
                }
            </div>
            <Loader visible={isLoading} />
        </React.Fragment>
    );
};

export default UploadCSV;

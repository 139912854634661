import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { LIVENESS_ROOT } from '../../api/routes';

import {
    GET_FM_KYC_IMAGE, SET_FM_KYC_IMAGE, ERR_FM_KYC_IMAGE,
} from './constants';

function* getFacematchKYCImage(action) {
    const { monorailId, imagePath } = action.data;
    let queryString = getQueryStringFromObject({ monorailId });
    queryString = `${queryString}&imagePath=${btoa(imagePath)}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LIVENESS_ROOT}/fm-kyc-image?${queryString}`
        );

        yield put({ type: SET_FM_KYC_IMAGE, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_FM_KYC_IMAGE, data: { err: e.message } });
    }
}

export default function* facematchKYCImageSaga() {
    yield all(
        [
            yield takeLatest(GET_FM_KYC_IMAGE, getFacematchKYCImage),
        ]
    );
}

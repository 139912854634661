/*
* @file AgentFeedbackDetails
* Page to view the details of the Feedback submitted by the agents for the Admins
*/

import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import {
    Button, DateRangePicker, FormDropdown, Table, Loader,
} from '../../components';
import {
    injectReducer, injectSaga,
} from '../../utils';

import { getAgentFeedbackDetails } from './actions';
import { selectAgentFeedbackDetails } from './selectors';
import {
    FROM_DATE_INFO, INITIAL_DATE_RANGE, TO_DATE_INFO, DATE_PICKER_CLS, FEEDBACK_DETAILS_LABEL_DATA, FEEDBACK_CATEGORIES, AGENT_FB_DETAILS_KEY,
} from './constants';
import reducer from './reducer';
import saga from './saga';
import './styles.scss';

const AgentFeedbackDetails = () => {
    const dispatch = useDispatch();

    const agentFeedbackDetails = useSelector(selectAgentFeedbackDetails, shallowEqual);

    const [fromDate, setFromDate] = useState(INITIAL_DATE_RANGE.fromDate);
    const [toDate, setToDate] = useState(INITIAL_DATE_RANGE.toDate);
    const [feedbackCategory, setFeedbackCategory] = useState({});

    const {
        loading, err, data, prevToken, nextToken,
    } = agentFeedbackDetails;

    const handleFeedbackCategoryChange = (value) => {
        setFeedbackCategory(value);
    };

    const handleFilter = () => {
        const payload = {
            filters: {
                from_date: fromDate,
                to_date: toDate,
                feedback_category: parseInt(feedbackCategory.value, 10),
            },
            prevToken,
            nextToken,
        };

        dispatch(getAgentFeedbackDetails(payload));
    };

    const makePayloadAndCallApi = (params = {}) => {
        const { prev, next } = params;

        const payload = {
            filters: {
                from_date: fromDate,
                to_date: toDate,
                feedback_category: parseInt(feedbackCategory.value, 10),
            },
            prevToken: prev,
            nextToken: next,
        };

        dispatch(getAgentFeedbackDetails(payload));
    };

    const getPaginatedAgentFeedback = ({ prev, next }) => {
        makePayloadAndCallApi({ prev, next });
    };

    const dateInfo = {
        fromDateInfo: FROM_DATE_INFO,
        toDateInfo: TO_DATE_INFO,
        initialDateRange: INITIAL_DATE_RANGE,
        extClasses: DATE_PICKER_CLS,
    };

    return (
        <div className='af-details'>
            <div className='heading2 my-30'>Agent Feedback Details</div>
            <div className='af-details-cont'>
                <DateRangePicker
                    key='agent-feedback-from-date'
                    dateInfo={dateInfo}
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                />

                <FormDropdown
                    label='Feedback Category*'
                    options={FEEDBACK_CATEGORIES}
                    input={{
                        value: feedbackCategory,
                        onChange: handleFeedbackCategoryChange,
                        placeholder: 'Choose...',
                        isClearable: true,
                    }}
                    cacheKey='feedback-category'
                    extStyles={{
                        container: 'frcsbWrapper af-details-input',
                    }}
                />

                <Button
                    v2
                    label='Filter'
                    primary
                    extClasses='af-btn'
                    onClick={handleFilter}
                />
            </div>

            <Table
                v2
                header='Agent Feedback Details Table'
                labelData={FEEDBACK_DETAILS_LABEL_DATA}
                contentData={data}
                prevToken={prevToken}
                nextToken={nextToken}
                paginatedClickHandler={getPaginatedAgentFeedback}
                errLabel={err}
                extClasses={{
                    container: 'af-details-table',
                }}
            />

            <Loader visible={loading} />
        </div>
    );
};

const withReducer = injectReducer({ key: AGENT_FB_DETAILS_KEY, reducer });
const withSaga = injectSaga({ key: AGENT_FB_DETAILS_KEY, saga });

export default compose(
    withReducer,
    withSaga,
)(AgentFeedbackDetails);

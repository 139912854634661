/**
 * @file Selectors for account ops submit CSV details component
 */

import { createSelector } from 'reselect';

import { ACCOUNT_OPS_KEY, REDUCER_KEYS } from './constants';

export const selectUploadCSVFormParameters = (containerKey) => createSelector(
    (state) => state.getIn([ACCOUNT_OPS_KEY, containerKey, REDUCER_KEYS.uploadCSVFormParams]),
    (subState) => subState?.toJS() || {},
);

export const selectLoading = (containerKey) => createSelector(
    (state) => state.getIn([ACCOUNT_OPS_KEY, containerKey, REDUCER_KEYS.loading]),
    (subState) => subState,
);

export const selectError = (containerKey) => createSelector(
    (state) => state.getIn([ACCOUNT_OPS_KEY, containerKey, REDUCER_KEYS.error]),
    (subState) => subState,
);

export const selectSuccessMsg = (containerKey) => createSelector(
    (state) => state.getIn([ACCOUNT_OPS_KEY, containerKey, REDUCER_KEYS.successMsg]),
    (subState) => subState,
);

/*
 *
 * Kyc Onboarding reducer
 *
 */

import { fromJS } from 'immutable';

import { getKeyFromValueAndSubValue } from '../../utils/formatter';

import {
    GET_KYC_INFO, SET_KYC_INFO, ERR_KYC_INFO, GET_ONBOARDING_INFO, SET_ONBOARDING_INFO,
    ERR_ONBOARDING_INFO, GET_REOOBE_INFO, SET_REOOBE_INFO, ERR_REOOBE_INFO, GET_VKYC_INFO,
    SET_VKYC_INFO, ERR_VKYC_INFO, RETRY_LIVENESS, UPDATE_RETRY_LIVENESS,
} from './constants';

const initialState = fromJS({
    kyc: {},
    onboarding: {},
    vkyc: {},
});

function kycOnboardingReducer(state = initialState, action = '') {
    switch (action.type) {
        case GET_KYC_INFO: {
            const { authValue, authSubValue } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['kyc', key, 'loading'], true)
                .setIn(['kyc', key, 'err'], '');
        }

        case SET_KYC_INFO: {
            const { authValue, authSubValue, kycInfo } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['kyc', key, 'loading'], false)
                .setIn(['kyc', key, 'hasKycInfo'], true)
                .setIn(['kyc', key, 'kycInfo'], fromJS(kycInfo));
        }

        case ERR_KYC_INFO: {
            const { authValue, authSubValue, err } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['kyc', key, 'loading'], false)
                .setIn(['kyc', key, 'hasKycInfo'], false)
                .setIn(['kyc', key, 'kycInfo'], fromJS({}))
                .setIn(['kyc', key, 'err'], err);
        }

        case GET_ONBOARDING_INFO:
        case GET_REOOBE_INFO: {
            const { authValue, authSubValue } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['onboarding', key, 'loading'], true)
                .setIn(['onboarding', key, 'err'], '');
        }

        case SET_ONBOARDING_INFO: {
            const { authValue, authSubValue, onboardingInfo } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['onboarding', key, 'loading'], false)
                .setIn(['onboarding', key, 'hasOnboardingInfo'], true)
                .setIn(['onboarding', key, 'onboardingInfo'], fromJS(onboardingInfo));
        }

        case ERR_ONBOARDING_INFO: {
            const { authValue, authSubValue, err } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['onboarding', key, 'loading'], false)
                .setIn(['onboarding', key, 'hasOnboardingInfo'], false)
                .setIn(['onboarding', key, 'onboardingInfo'], fromJS({}))
                .setIn(['onboarding', key, 'err'], err);
        }

        case SET_REOOBE_INFO: {
            const { authValue, authSubValue, reoobeInfo } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['onboarding', key, 'loading'], false)
                .setIn(['onboarding', key, 'hasReoobeInfo'], true)
                .setIn(['onboarding', key, 'reoobeInfo'], fromJS(reoobeInfo));
        }

        case ERR_REOOBE_INFO: {
            const { authValue, authSubValue, err } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['onboarding', key, 'loading'], false)
                .setIn(['onboarding', key, 'hasReoobeInfo'], false)
                .setIn(['onboarding', key, 'reoobeInfo'], fromJS({}))
                .setIn(['onboarding', key, 'err'], err);
        }

        case GET_VKYC_INFO: {
            const { authValue, authSubValue } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['vkyc', key, 'loading'], true)
                .setIn(['vkyc', key, 'err'], '');
        }

        case SET_VKYC_INFO: {
            const { authValue, authSubValue, vkycInfo } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['vkyc', key, 'loading'], false)
                .setIn(['vkyc', key, 'hasVKycInfo'], true)
                .setIn(['vkyc', key, 'vkycInfo'], fromJS(vkycInfo));
        }

        case ERR_VKYC_INFO: {
            const { authValue, authSubValue, err } = action.data;
            const key = getKeyFromValueAndSubValue(authValue, authSubValue);

            return state
                .setIn(['vkyc', key, 'loading'], false)
                .setIn(['vkyc', key, 'hasVKycInfo'], false)
                .setIn(['vkyc', key, 'vkycInfo'], fromJS({}))
                .setIn(['vkyc', key, 'err'], err);
        }

        case RETRY_LIVENESS: {
            const { ticketId } = action.data;

            return state.setIn(['kyc', ticketId, 'loading'], true);
        }

        case UPDATE_RETRY_LIVENESS: {
            const { ticketId } = action.data;

            return state.setIn(['kyc', ticketId, 'loading'], false);
        }

        default:
            return state;
    }
}

export default kycOnboardingReducer;

export const TransactionViewEventList = {
    TransactionViewLoadTime: 'TXN_VIEW_TOTAL_LOAD_TIME',
    TXN_VIEW_RENDERING_TIME: 'TXN_VIEW_RENDERING_TIME',
    TRANSACTION_QUEUE_LANDING: 'TRANSACTION_QUEUE',
    TV_REVIEW_BTN: 'TV_REVIEW_BTN',
    CASE_SUBMIT_ACTION: 'CASE_SUBMIT_ACTION',
    TRANSACTION_VIEW: {
        PAGE_LOADING: 'PAGE_LOADING',
        VIEW_TIME: 'VIEW_TIME',
    },
    USER_REVIEW: {
        PAGE_LANDING: 'PAGE_LANDING',
        PAGE_EXIT: 'PAGE_EXIT',
    },
    DEV_ACTION: {
        SUBMIT_ACTION: 'SUBMIT_ACTION',
    },
    CHARTS: {
        CHART_CREDIT_VS_DEBIT: 'CHART_CREDIT_VS_DEBIT',
        CHART_CATEGORY: 'CHART_CATEGORY',
        CHART_SENDERS: 'CHART_SENDERS',
    },
};

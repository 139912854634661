import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
    Button, ExpandableCard, FormWithSingleInput, Loader,
} from '../../components';
import { getInitialDateRange, injectReducer, injectSaga } from '../../utils';

import MessageList from './MessageList';
import { getMessageList as getMessageListApi } from './actions';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

class Communications extends React.Component {
    constructor(props) {
        super(props);

        const { upperLimitDate, lowerLimitDate, fromDate: fromInitialDate,
            toDate: toInitialDate } = getInitialDateRange('01-01-2021'); // 01 Jan

        this.state = {
            isSearchCardExpanded: false,
            errLabel: '',
            medium: [],
            upperLimitDate,
            lowerLimitDate,
            fromDate: fromInitialDate,
            toDate: toInitialDate,
        };
    }

    setErrLabel = (value) => {
        this.setState({
            errLabel: value
        });
    }

    toggleSearchCard = () => {
        this.setState(prevState => ({
            isSearchCardExpanded: !prevState.isSearchCardExpanded
        }));
    }

    getDateRange = ({ toDate, fromDate, prevState }) => {
        let modifiedToDate;
        let modifiedFromDate;

        const { fromDate: fromDatePrevState, toDate: toDatePrevState } = prevState;

        if (toDate) {
            const newToDate = new Date(toDate);
            newToDate.setHours(23, 59, 59, 999);

            modifiedToDate = {
                ...toDatePrevState,
                value: newToDate
            };

            const upperLimitFromDate = new Date(toDate);
            upperLimitFromDate.setHours(0, 0, 0, 0);

            modifiedFromDate = {
                ...fromDatePrevState,
                upperLimit: upperLimitFromDate
            };
        } else if (fromDate) {
            modifiedFromDate = {
                ...fromDatePrevState,
                value: fromDate
            };

            const lowerLimitToDate = new Date(fromDate)
            lowerLimitToDate.setHours(23, 59, 59, 999);

            modifiedToDate = {
                ...toDatePrevState,
                lowerLimit: lowerLimitToDate
            };
        }

        return {
            fromDate: modifiedFromDate,
            toDate: modifiedToDate,
        };
    }

    handleMediumChange = value => {
        this.setState({
            medium: value
        });
    }

    handleDateChange = key => value => {
        const { upperLimitDate, lowerLimitDate, fromDate, toDate } = this.state;
        const data = {
            prevState: {
                upperLimitDate,
                lowerLimitDate,
                fromDate,
                toDate,
            },
        };

        switch (key) {
            case 'fromDate':
                data.fromDate = value;
                break;

            case 'toDate':
                data.toDate = value;
                break;

            default:
        }

        const { fromDate: newFromDate, toDate: newToDate } = this.getDateRange(data);
        this.setState({
            fromDate: newFromDate,
            toDate: newToDate,
        });
    }

    handleSubmit = ({ prev, next }) => {
        const { fromDate, toDate } = this.state;

        if (!fromDate.value || !toDate.value) {
            this.setErrLabel('Please fill all the * marked fields');
            return;
        }

        const { ticketId, index, userId, getMessageList } = this.props;

        const data = {
            ticketId,
            index,
            userId,
            from: fromDate.value,
            to: toDate.value,
            prev,
            next,
        };

        this.setState({
            isSearchCardExpanded: false,
            errLabel: ''
        });

        getMessageList(data);
    }

    renderDateBlock = (key) => {
        let label;
        let labelId;
        let value;
        let maxDate;
        let minDate;

        const { fromDate, toDate } = this.state;

        switch (key) {
            case 'fromDate':
                label = 'From Date*';
                labelId = 'comms_from-date';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = fromDate);
                break;

            case 'toDate':
                label = 'To Date*';
                labelId = 'comms_to-date';
                ({ upperLimit: maxDate, lowerLimit: minDate, value } = toDate);
                break;

            default:
        }

        return (
            <FormWithSingleInput
                disableFormSubmitOnEnter
                extClasses={{
                    container: 'comms-form-container--m0',
                    label: 'txns-other-label'
                }}
                label={label}
                labelId={labelId}
                formType='dob'
                input={{
                    value,
                    onChange: this.handleDateChange(key),
                    maxDate,
                    minDate,
                }}
                hideErrLabel
            />
        );
    }

    renderSearchCard = () => {
        const { isSearchCardExpanded, errLabel } = this.state;

        return (
            <ExpandableCard heading='Search for Messages' isExpanded={isSearchCardExpanded} setIsExpanded={this.toggleSearchCard}>
                <div className='comms-sc'>
                    <div className='frWrapper'>
                        {this.renderDateBlock('fromDate')}
                        {this.renderDateBlock('toDate')}
                    </div>
                    {/*
                        <FormDropdown
                            isMulti
                            options={COMMS_MEDIUM}
                            label='Medium'
                            input={{
                                onChange: this.handleMediumChange
                            }}
                        />
                    */}
                    {errLabel ? <div className='err-label comms-sc__errlb'>{errLabel}</div> : null}
                    <Button
                        primary
                        extClasses={`comms-sc__cta ${errLabel && 'comms-sc__cta--err'}`}
                        label='Search'
                        onClick={this.handleSubmit}
                    />
                </div>
            </ExpandableCard>
        );
    }

    renderSearchResults = () => {
        const { ticketId, userId, index, phoneNumber } = this.props;

        return (
            <MessageList
                ticketId={ticketId}
                userId={userId}
                index={index}
                phoneNumber={phoneNumber}
                handleSubmit={this.handleSubmit}
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className='comms-container'>
                    {this.renderSearchCard()}
                    {this.renderSearchResults()}
                </div>
                <Loader visible={false} />
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getMessageList: (data) => dispatch(getMessageListApi(data)),
});

const withConnect = connect(null, mapDispatchToProps);

const withReducer = injectReducer({ key: 'communications', reducer });
const withSaga = injectSaga({ key: 'communications', saga });

export default compose(
    withReducer,
    withSaga,
    withConnect
)(Communications);

/**
 *
 * Sagas for CX dispute flow
 *
 */

import {
    call, put, takeLatest, all,
} from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify,
} from '../../utils';
import {
    DISPUTE_ACTOR_DETAILS_ENDPOINT, DISPUTE_DETAILS_ENDPOINT, DISPUTE_ROOT,
} from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    ERR_CX_DISPUTES_INFO, GET_CX_DISPUTES_INFO, SET_CX_DISPUTES_INFO,
    UPDATE_CX_DISPUTES_INFO, UPDATE_SUCCESS_CX_DISPUTES, GET_CX_DISPUTES_INFO_FOR_ACTOR,
    SET_CX_DISPUTES_INFO_FOR_ACTOR, ERR_CX_DISPUTES_INFO_FOR_ACTOR,
} from './constants';

function* getDisputesInfoSaga(action) {
    const {
        ticketId, index, payload, prev, next,
    } = action.data;

    const queryString = getQueryStringFromObject({ payload, prev, next });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${DISPUTE_ROOT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_CX_DISPUTES_INFO, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_CX_DISPUTES_INFO, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CX_DISPUTES_INFO, data: { ticketId, err: e.errMsgForUI } });
    }
}

function* updateDisputesInfoSaga(action) {
    const {
        ticketId, payload,
    } = action.data;

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.put],
            `${DISPUTE_DETAILS_ENDPOINT}`,
            payload,
        );

        yield put({ type: UPDATE_SUCCESS_CX_DISPUTES, data: { ticketId } });

        toastify('Successfully updated dispute info', 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
    }
}

function* getDisputesInfoForActorSaga(action) {
    const {
        ticketId, index, payload, prev, next,
    } = action.data;

    const queryString = getQueryStringFromObject({
        ticketId, payload, prev, next,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${DISPUTE_ACTOR_DETAILS_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_CX_DISPUTES_INFO_FOR_ACTOR, data: { ticketId, ...response } });
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_CX_DISPUTES_INFO_FOR_ACTOR, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_CX_DISPUTES_INFO_FOR_ACTOR, data: { ticketId, err: getErrLabel(e) } });
    }
}

export default function* disputesSaga() {
    yield all(
        [
            yield takeLatest(GET_CX_DISPUTES_INFO, getDisputesInfoSaga),
            yield takeLatest(UPDATE_CX_DISPUTES_INFO, updateDisputesInfoSaga),
            yield takeLatest(GET_CX_DISPUTES_INFO_FOR_ACTOR, getDisputesInfoForActorSaga),
        ],
    );
}

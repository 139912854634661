import { createSelector } from 'reselect';
import { SCREEN_KEY } from './constants';

const getScripts = (state) => state.get('scripts');

export const selectScripts = createSelector(
    getScripts,
    (substate) => substate?.toJS(),
);

export const selectScriptData = createSelector(
    (state) => getScripts(state).getIn([SCREEN_KEY.script]),
    (substate) => substate?.toJS(),
);

export const selectScriptSearchData = createSelector(
    (state) => state.getIn(['scripts', 'search']),
    (substate) => substate?.toJS(),
);

/**
 *
 * Review a single namematch case and update the status
 *
 */

import React, { memo, useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import backArrowIcon from '../../../assets/images/svgs/back-arrow.svg';
import { Button, Table } from '../../../components';
import { isObjEmpty } from '../../../utils/helpers';
import { MetaInfoContext, UserDetailsContext } from '../../../context';

import DevActions from '../../DevActions';
import { CustomerDetailsModal, DequeueCaseModal, LivenessSummaryModal } from '../../RiskOps';

import { dequeueNamematchCaseAction, setNamematchViewAction } from '../actions';
import { DEV_ACTION_TYPES, LABEL_DATA, VIEW_ALL_NAMEMATCH_CASES } from '../constants';
import { selectCurNamematchCase, selectNamematchPayloadType, selectNamematchQueueFilters } from '../selectors';
import { getAdditionalFilters } from '../../RiskOps/utils';

const getIdsFromCase = (curFacematchCase) => {
    const { payload, id: recordId } = curFacematchCase;
    const { actor_id: actorId } = curFacematchCase[payload];
    return { actorId, recordId };
};

const getPreFilledValuesObj = (curFacematchCase, emailId) => {
    const { actorId } = getIdsFromCase(curFacematchCase);

    return {
        mergeKey: 'label',
        paramList: [
            { label: 'Actor ID', value: actorId, isDisabled: true },
            { label: 'Reviewed By (ex: yourname@epifi.com)', value: emailId, isDisabled: true },
        ],
    };
};

const getTableContent = (curFacematchCase) => {
    const { payload } = curFacematchCase;
    const nameInfo = curFacematchCase[payload];
    const kycInfo = { type: 'KYC Details', ...nameInfo.kyc_name };
    const panInfo = { type: 'PAN Details', ...nameInfo.pan_name };
    return [kycInfo, panInfo];
};

const SingleNamematchCase = () => {
    const { basicInfo } = useContext(UserDetailsContext);
    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = useContext(MetaInfoContext);
    const dispatch = useDispatch();

    // global state
    const namematchPayloadType = useSelector(selectNamematchPayloadType(), shallowEqual);
    const curNamematchCase = useSelector(selectCurNamematchCase(), shallowEqual);
    const { actorId, recordId } = getIdsFromCase(curNamematchCase);
    const { index } = curNamematchCase;
    const { emailId } = basicInfo;

    const { fromDate, toDate, sortOption } = useSelector(selectNamematchQueueFilters(), shallowEqual);

    // local state
    const [showDequeueCaseModal, setDequeueCaseModalVisibility] = useState(false);
    const [preFilledValues, setPreFilledValues] = useState(null);
    const [tableContent, setTableContent] = useState(null);
    const [showLivenessSummaryModal, setLivenessSummaryModalVisibility] = useState(false);
    const [showCustomerDetailsModal, setCustomerDetailsModalVisibility] = useState(false);

    useEffect(() => {
        setPreFilledValues(getPreFilledValuesObj(curNamematchCase, emailId));
        setTableContent(getTableContent(curNamematchCase));
    }, [dispatch, curNamematchCase.id]);

    // if no namematch case is set, return null
    if (isObjEmpty(curNamematchCase)) {
        return null;
    }

    const onBackArrowCTA = () => {
        dispatch(setNamematchViewAction(VIEW_ALL_NAMEMATCH_CASES));
    };

    const dequeueAction = dequeueNamematchCaseAction({
        actorId,
        recordId,
        index,
        queryParams: {
            payloadType: namematchPayloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        },
        customContext: {
            bannersVersion,
        },
    });

    const extraActions = [dequeueAction];

    const handleDequeueCase = () => {
        dispatch(dequeueAction);
        setDequeueCaseModalVisibility(false);
    };

    return (
        <React.Fragment>
            <div className='namereview-cr'>
                <div className='namereview-hl--cr'>
                    <button
                        className='namereview-backarrow--cr'
                        type='button'
                        onClick={onBackArrowCTA}
                    >
                        <img
                            className='namereview-backarrow'
                            alt='back-arrow'
                            src={backArrowIcon}
                        />
                    </button>
                    <div className='namereview-hl1'>Review Namematch Case</div>
                </div>

                <div className='namereview-table--cr'>
                    <Table labelData={LABEL_DATA} contentData={tableContent} />
                </div>

                <div className='frwpWrapper'>
                    <Button
                        secondary
                        label='View Liveness Summary Details'
                        onClick={() => setLivenessSummaryModalVisibility(true)}
                    />
                    <Button
                        secondary
                        label='View Customer Details'
                        extClasses='ml-30'
                        onClick={() => setCustomerDetailsModalVisibility(true)}
                    />
                </div>

                {preFilledValues && (
                    <DevActions
                        containerKey='namematch-v1'
                        actionTypes={DEV_ACTION_TYPES}
                        preFilledValues={preFilledValues}
                        extraActions={extraActions}
                    />
                )}

                <div className='namereview-cr1'>
                    <div className='namereview-hl3'>
                        If the above action does not suit your need, you can dequeue
                        this case
                    </div>
                    <Button
                        secondary
                        label='Remove User From Queue'
                        onClick={() => setDequeueCaseModalVisibility(true)}
                    />
                </div>
            </div>

            <DequeueCaseModal
                visible={showDequeueCaseModal}
                submitCta={handleDequeueCase}
                closeCta={setDequeueCaseModalVisibility}
            />

            <LivenessSummaryModal
                visible={showLivenessSummaryModal}
                actorId={actorId}
                closeCta={setLivenessSummaryModalVisibility}
            />

            <CustomerDetailsModal
                visible={showCustomerDetailsModal}
                actorId={actorId}
                closeCta={setCustomerDetailsModalVisibility}
            />
        </React.Fragment>
    );
};

export default memo(SingleNamematchCase);

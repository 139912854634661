/**
 *
 * @file UserActions > Pay User
 *
 */

import React, { memo, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Modal from 'react-modal';

import {
    Button, FormWithSingleInput, Loader, FormDropdown,
} from '../../../components';
import { validators } from '../../../utils';

import { UA_PAYOUT_TYPE_CASH, UA_PAYOUT_TYPE_FI_COINS, UA_PAY_USER_PAYOUT_TYPES } from '../constants';
import { raisePayRequest, getAllowedFiCoinsValueAction, setPayoutTypeAction } from '../actions';
import { makeSelectPayUser, makeSelectGetAllowedFiCoinValues, makeSelectPayoutType } from '../selectors';

import './style.scss';
import { MetaInfoContext, UserDetailsContext } from '../../../context';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 500,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const PayUser = (props) => {
    const {
        ticketId, userId, name, phoneNumber,
    } = props;

    const metaInfo = useContext(MetaInfoContext);
    const {
        payouts: {
            payUser: {
                cashPayoutRoles,
                fiCoinsPayoutRoles,
            },
        },
    } = metaInfo;
    const userInfo = useContext(UserDetailsContext);
    const { selectedAccessLevel } = userInfo;

    const dispatch = useDispatch();
    const [amount, setAmount] = useState('');
    const [reason, setReason] = useState('');
    const [errLabel, setErrLabel] = useState('');
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const [payoutValue, setPayoutValue] = useState({});
    const [payoutTypeOptions, setPayoutTypeOptions] = useState([]);

    const payUserInfo = useSelector(makeSelectPayUser, shallowEqual);
    const payoutType = useSelector(makeSelectPayoutType, shallowEqual);
    const allowedFiCoinValues = useSelector(makeSelectGetAllowedFiCoinValues, shallowEqual);

    useEffect(() => {
        const reqPayoutTypes = [];

        if (cashPayoutRoles.includes(selectedAccessLevel)) {
            reqPayoutTypes.push(UA_PAY_USER_PAYOUT_TYPES.cash);
        }

        if (fiCoinsPayoutRoles.includes(selectedAccessLevel)) {
            reqPayoutTypes.push(UA_PAY_USER_PAYOUT_TYPES.fiCoins);
            dispatch(getAllowedFiCoinsValueAction());
        }

        setPayoutTypeOptions(reqPayoutTypes);

        // if at least 1 value is present, set payout type
        if (reqPayoutTypes.length > 0) dispatch(setPayoutTypeAction(reqPayoutTypes[0]));
    }, [dispatch, selectedAccessLevel]);

    let loading;

    if (payUserInfo[ticketId]) {
        ({ loading } = payUserInfo[ticketId]);
    }

    const onAmountChange = (e) => {
        const charCode = e.which || e.keyCode;

        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }

        let { value } = e.target;
        const inputResult = /^\d]*$/.test(value);

        if (!inputResult) {
            value = value.replace(/[^a-z0-9\s]/gi, '');
        }

        setPayoutValue(value);
    };

    const onReasonChange = (e) => {
        setReason(e.target.value);
    };

    const onSubmitCTA = () => {
        if (!reason || !payoutValue) {
            setErrLabel('Please fill all the * marked fields');
            return;
        }

        if (!validators.isInputtedStringValid(reason)) {
            setErrLabel('Please enter only alphabets as the reason');
            return;
        }

        setErrLabel('');
        setConfirmationModal(true);
    };

    const onConfirmCTA = () => {
        setConfirmationModal(false);

        const callRaisePayRequestAction = () => new Promise((resolve, reject) => {
            const curPayoutValue = payoutValue.value ?? payoutValue;

            try {
                dispatch(raisePayRequest({
                    ticketId,
                    userId,
                    // new API accepts payout type and payout value, amount deprecated
                    payout_type: payoutType.value,
                    payout_value: curPayoutValue,
                    reason,
                    resolve,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callRaisePayRequestAction().then(() => {
            setAmount('');
            setReason('');
            setPayoutValue({});
        });
    };

    const setPayoutType = (newPayload) => {
        dispatch(setPayoutTypeAction(newPayload));

        if (newPayload.value === UA_PAYOUT_TYPE_FI_COINS) {
            dispatch(getAllowedFiCoinsValueAction());
        }
    };

    const handleFiCoinValueChange = (data) => {
        setPayoutValue(data);
    };

    return (
        <div className='payuser'>
            <FormDropdown
                label='Select Payout Type'
                options={payoutTypeOptions}
                input={{
                    value: payoutType,
                    onChange: setPayoutType,
                    placeholder: 'Choose...',
                    isClearable: false,
                }}
                cacheKey='payuser-payout-type'
                extStyles={{
                    container: 'payuser-fc1 mb-15',
                    label: 'payuser-fc__lb',
                    inputField: 'payuser-fc__ip',
                }}
            />
            {payoutType.value === UA_PAYOUT_TYPE_CASH ? (
                <FormWithSingleInput
                    disableFormSubmitOnEnter
                    label='Enter Amount in Cash*'
                    labelId='pay-amount'
                    input={{
                        value: payoutValue,
                        onChange: onAmountChange,
                        type: 'number',
                        onKeyDown: onAmountChange,
                        maxLength: 4,
                    }}
                    extClasses={{
                        label: 'payuser-lb',
                        errLabel: 'payuser-errlb',
                    }}
                />
            ) : (
                <FormDropdown
                    label='Select Fi Coins*'
                    options={allowedFiCoinValues}
                    input={{
                        value: payoutValue,
                        onChange: handleFiCoinValueChange,
                        placeholder: 'Choose...',
                        isClearable: true,
                    }}
                    cacheKey='payuser-payout-value'
                    extStyles={{
                        container: 'payuser-fc1 mb-15',
                        label: 'payuser-fc__lb',
                        inputField: 'payuser-fc__ip',
                    }}
                />
            )}
            <div className='form-container payuser-fc'>
                <label
                    className='form-wrapper-label payuser-lb payuser-fwr-lb'
                    htmlFor='pay-reason'
                >
                    Enter Reason*
                </label>
                <textarea
                    id='pay-reason'
                    className='payuser-ilb'
                    value={reason}
                    onChange={onReasonChange}
                    rows={4}
                    cols={50}
                />
            </div>
            <div className='err-label payuser-errlb'>{errLabel}</div>
            <Button
                primary
                label='Send Amount'
                onClick={onSubmitCTA}
            />
            {showConfirmationModal && (
                <Modal
                    isOpen
                    style={customStylesForModal}
                    contentLabel='Payout Confiramtion Modal'
                >
                    <div className='payuser-modal__hl'>
                        Are you sure you want to perform this action?
                    </div>
                    <div className='payuser-modal__sbhl'>
                        {`This will send amount ${amount} INR to ${name} with mobile number ${phoneNumber}`}
                    </div>
                    <div className='frcsbWrapper payuser-modal__ctas'>
                        <Button
                            primary
                            label='Confirm Payout'
                            onClick={onConfirmCTA}
                        />
                        <Button
                            secondary
                            label='Cancel'
                            onClick={() => setConfirmationModal(false)}
                        />
                    </div>
                </Modal>
            )}
            <Loader visible={loading} />
        </div>
    );
};

export default memo(PayUser);

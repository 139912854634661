/**
 *
 * @file RenderView: Renders the page according to facematch view.
 *
 */
import React, { memo } from 'react';

import AllFacematchCases from '../AllFacematchCases';
import SingleFacematchCase from '../SingleFacematchCase';
import { VIEW_ALL_FACEMATCH_CASES, VIEW_SINGLE_FACEMATCH_CASE } from '../constants';

const RenderView = (props) => {
    const { facematchView, handleReviewClick, reviewId } = props;

    switch (facematchView) {
        case VIEW_ALL_FACEMATCH_CASES: {
            return <AllFacematchCases handleReviewClick={handleReviewClick} />;
        }

        case VIEW_SINGLE_FACEMATCH_CASE: {
            return <SingleFacematchCase reviewId={reviewId} />;
        }

        default:
            return null;
    }
};

export default memo(RenderView);

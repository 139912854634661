/**
 * @file NewTicketView component to show the newly created ticket page
 */

import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import copy from 'copy-text-to-clipboard';

import { Button, Modal } from '../../../components';
import CopyIcon from '../../../assets/images/icons/copy.png';
import CopiedIcon from '../../../assets/images/icons/green-tick.png';

import { makeSelectCreatedTicketInfoForCall } from '../selectors';

const NewTicketView = (props) => {
    const dispatch = useDispatch();

    // reducer state variables
    const createdTicketInfoForCall = useSelector(makeSelectCreatedTicketInfoForCall(), shallowEqual);

    // local state variables
    const [showNewTicketErrInfoModal, setNewTicketErrInfoModal] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    // props variables
    const { setTicketIdAndSubmit } = props;

    // destructured variables
    const {
        id: newTicketId, sourceUrl: newTicketSourceUrl, errMsgList: newTicketErrMsgList, hasErrMsg: hasNewTicketErrMsg,
    } = createdTicketInfoForCall;

    // set err info modal if the err msg list is there while creating new ticket
    useEffect(() => {
        if (hasNewTicketErrMsg) {
            setNewTicketErrInfoModal(true);
        }
    }, [dispatch, hasNewTicketErrMsg]);

    const onCopyLinkClick = (item) => () => {
        setIsCopied(true);
        copy(`${item}`); // need to pass it as string, else it won't copy

        setTimeout(() => {
            setIsCopied(false);
        }, 5000);
    };

    return (
        <React.Fragment>
            <div className='defr-cr frcsbWrapper'>
                <div className='frcWrapper'>
                    <div className='defr-new-ticket-pg-lb'>
                        {`New ticket has been created: ID - ${newTicketId}`}
                    </div>
                    <div onClick={onCopyLinkClick(newTicketId)}>
                        <img
                            className='defr-new-ticket-pg-icon'
                            src={!isCopied ? CopyIcon : CopiedIcon}
                            alt={!isCopied ? 'copy' : 'copied'}
                        />
                    </div>
                </div>
                <div className='frcWrapper'>
                    <Button
                        v2
                        primary
                        label='proceed'
                        onClick={() => setTicketIdAndSubmit(newTicketId)}
                        extClasses='defr-new-ticket-pg-primary-cta'
                    />
                    <a
                        href={newTicketSourceUrl}
                        target='_blank'
                        rel='noreferrer nofollow'
                        className='link-v2 defr-new-ticket-pg-link-cta'
                    >
                        View Ticket Summary
                    </a>
                </div>
            </div>
            <Modal
                visible={showNewTicketErrInfoModal}
                contentLabel='Create Ticket Err Modal'
            >
                {newTicketErrMsgList?.map(item => (
                    <div className='defr-modal-lb'>
                        {`- ${item}`}
                    </div>
                ))}
                <Button
                    v2
                    primary
                    label='Close'
                    onClick={() => {
                        setNewTicketErrInfoModal(false);
                    }}
                    extClasses='defr-modal-cta'
                />
            </Modal>
        </React.Fragment>
    );
};

export default memo(NewTicketView);

/**
*
* ShowTable
*
*/

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const ShowTable = props => {
    const { headers, appendToRow, data } = props;

    const renderHeaders = () => (
        <thead>
            <tr>
                {
                    headers.map((header) => {
                        return <th key={header.key} className={`table-header ${header.key}`}>{header.label}</th>
                    })
                }
                {appendToRow && <th>Actions</th>}
            </tr>
        </thead>
    );

    const renderBody = () => (
        <tbody>
            {
                data.map((row, index) => (
                    <tr key={row.id} className={'table-row'}>
                        {
                            headers.map((header) => (
                                <td key={header.key} className={`table-data ${header.key}`}>
                                    {row[header.key] || ' - '}
                                </td>
                            ))
                        }
                        {
                            appendToRow && <td>{appendToRow(row, index)}</td>
                        }
                    </tr>
                ))
            }
        </tbody>
    );

    return (
        <div className='table-container'>
            <table>
                {renderHeaders()}
                {renderBody()}
            </table>
        </div>
    );
};

ShowTable.propTypes = {
    headers: PropTypes.array,
    data: PropTypes.array,
    appendToRow: PropTypes.func,
};

export default memo(ShowTable);

/**
 *
 * @file USSAccountDeatilInfo : Show account basic detail modal.
 *
 */

import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button, KVPairInfo } from '../../../components';

import { renderAccountDetailInfo } from '../utils';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '10%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 900,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        maxHeight: '85%',
    },
};

const USSAccountDetailInfo = (props) => {
    const { visible, toggleHandler, accountBasicDetail } = props;

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
        >
            <div className='uss-modal-mfoicr'>
                <div className='ussfm-hl-main-heading'>Current Stage Troubleshooting Detail</div>
                <div className='p-30'>
                    <KVPairInfo data={renderAccountDetailInfo(accountBasicDetail)} />
                </div>
            </div>
            <Button
                primary
                onClick={toggleHandler}
                extClasses='uss-modal-cta'
                label='Close'
            />
        </Modal>
    );
};

export default memo(USSAccountDetailInfo);

/*
 *
 * Wealth Onboarding Selectors
 *
 */

import { createSelector } from 'reselect';

import { WEALTH_ONBOARDING_KEY } from './constants';

// const selectWealthOnboarding = state => state.get(WEALTH_ONBOARDING_KEY);
const selectOnboardingDetails = state => state.getIn([WEALTH_ONBOARDING_KEY, 'onboarding']);

const makeSelectOnboardingDetails = () => createSelector(
    selectOnboardingDetails,
    onboardingDetails => onboardingDetails.toJS(),
);

export {
    makeSelectOnboardingDetails,
};

import { createSelector } from 'reselect';

import { WEALTH_MUTUAL_FUNDS_KEY } from './constants';

const makeSelectMFAmcList = () => createSelector(
    state => state.getIn([WEALTH_MUTUAL_FUNDS_KEY, 'mfAmcList']),
    substate => substate.toJS(),
);

const makeSelectMFTxnTypeList = () => createSelector(
    state => state.getIn([WEALTH_MUTUAL_FUNDS_KEY, 'mfTxnTypeList']),
    substate => substate.toJS(),
);

const selectMFOrderFilterStatusList = createSelector(
    state => state.getIn([WEALTH_MUTUAL_FUNDS_KEY, 'mfOrderFilterStatusList']),
    substate => substate.toJS(),
);

const makeSelectSearchParameters = () => createSelector(
    state => state.getIn([WEALTH_MUTUAL_FUNDS_KEY, 'searchParameters']),
    substate => substate.toJS(),
);

const makeSelectMutualFunds = () => createSelector(
    state => state.getIn([WEALTH_MUTUAL_FUNDS_KEY, 'mutualFundsList']),
    substate => substate.toJS(),
);

const makeSelectMutualFundOrders = () => createSelector(
    state => state.getIn([WEALTH_MUTUAL_FUNDS_KEY, 'mutualFundOrdersList']),
    substate => substate.toJS(),
);

const makeSelectMutualFundOrderInfo = () => createSelector(
    state => state.getIn([WEALTH_MUTUAL_FUNDS_KEY, 'mutualFundOrderInfo']),
    substate => substate.toJS(),
);

export {
    makeSelectMFAmcList,
    makeSelectMFTxnTypeList,
    selectMFOrderFilterStatusList,
    makeSelectSearchParameters,
    makeSelectMutualFunds,
    makeSelectMutualFundOrders,
    makeSelectMutualFundOrderInfo,
};

/**
 *  @file D3 constants
 */

export const STACKED = 'STACKED';
export const GROUPED = 'GROUPED';
export const MONTH = 'MONTH';
export const WEEK = 'WEEK';
export const DAY = 'DAY';

export const GraphTypeOptions = [
    {
        label: 'Stacked',
        value: STACKED,
    },
    {
        label: 'Grouped',
        value: GROUPED,
    },
];

/**
 * @file Manages the reducer state & function for Wealth P2P component
 */

import { fromJS } from 'immutable';

import {
    GET_INVESTOR_INFO, SET_INVESTOR_INFO, ERR_INVESTOR_INFO,
    GET_INVESTMENT_LIST, SET_INVESTMENT_LIST, ERR_INVESTMENT_LIST,
} from './constants';

const initialState = fromJS({
    investorInfo: {},
    investmentList: {},
});

function wealthP2PReducer(state = initialState, action) {
    switch (action.type) {
        case GET_INVESTOR_INFO: {
            const { ticketId } = action.data;

            return state
                .setIn(['investorInfo', ticketId, 'loading'], true)
                .setIn(['investorInfo', ticketId, 'err'], '');
        }

        case SET_INVESTOR_INFO: {
            const { ticketId, investorInfo } = action.data;

            return state
                .setIn(['investorInfo', ticketId, 'loading'], false)
                .setIn(['investorInfo', ticketId, 'hasData'], true)
                .setIn(['investorInfo', ticketId, 'data'], fromJS(investorInfo));
        }

        case ERR_INVESTOR_INFO: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['investorInfo', ticketId, 'loading'], false)
                .setIn(['investorInfo', ticketId, 'hasData'], false)
                .setIn(['investorInfo', ticketId, 'data'], fromJS({}))
                .setIn(['investorInfo', ticketId, 'err'], err);
        }

        case GET_INVESTMENT_LIST: {
            const { ticketId } = action.data;

            return state
                .setIn(['investmentList', ticketId, 'loading'], false);
        }

        case SET_INVESTMENT_LIST: {
            const {
                ticketId, investmentList,
            } = action.data;
            const { data, prev, next } = investmentList;

            return state
                .setIn(['investmentList', ticketId, 'hasData'], true)
                .setIn(['investmentList', ticketId, 'data'], fromJS(data))
                .setIn(['investmentList', ticketId, 'prev'], fromJS(prev))
                .setIn(['investmentList', ticketId, 'next'], fromJS(next))
                .setIn(['investmentList', ticketId, 'err'], '');
        }

        case ERR_INVESTMENT_LIST: {
            const { ticketId, err } = action.data;

            return state
                .setIn(['investmentList', ticketId, 'hasData'], false)
                .setIn(['investmentList', ticketId, 'data'], fromJS([]))
                .setIn(['investmentList', ticketId, 'prev'], fromJS({}))
                .setIn(['investmentList', ticketId, 'next'], fromJS({}))
                .setIn(['investmentList', ticketId, 'err'], err);
        }

        default:
            return state;
    }
}

export default wealthP2PReducer;

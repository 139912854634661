/**
 * @file rudderEvents/index.js Contains all the rudder event names and functions that can be imported by other files to facilitate rudder events
 */

import { PUSH_RUDDER_EVENT } from '../api/routes';

import { getQueryStringFromObject, getErrMsg, clientApiWrapper } from '../utils';

import SherlockProfileCopyButtonClick from './Profile';
import ClickedGuruCardLink from './FreshDeskInfo';
import {
    KYCMainScreenCopyButtonClick,
    InAppScreenDetailsCopyButtonClick,
    KYCDetailsCopyButtonClick,
    OnboardingDetailsCopyButtonClick,
} from './KYCOnboarding';
import { TransactionViewEventList } from './TransactionView';
import {
    ClickedSherlockSearchBar,
    SearchedSherlockScriptSearchBar,
    ClickedSherlockIndividualScriptSearchBar,
    ClickedSherlockSearchBarAllScripts,
} from './SherlockV1Search';
import {
    LandedSherlockV2ScriptsSearchPage,
    LandedSherlockV2ScriptsPage,
    ClickedPictureGuideStep,
    ClickedPictureGuideDoneButton,
} from './SherlockV2Scripts';
import {
    LandedOnSherlockRecentActivity,
    ClickedSherlockSearchRecentActivityButton,
    SearchedSherlockRecentActivity,
    ClickedClearAllSherlockRecentActivity,
} from './RecentActivity';
import {
    AgentUserCallSelectionSherlock,
    AgentClickCreateANewTicket,
    AgentAttachTicketToCurrentCall,
    AgentClickTicketIdSubmit,
    AgentClickSherlockSidebarOption,
    SherlockV1AgentTicketTabActive,
    SherlockV1AgentTicketTabInActive,
    SherlockV1AgentTicketTabOpen,
    SherlockV1AgentTicketTabClosed,
} from './Agent';
import {
    SherlockV2TabActive,
    SherlockV2TabInActive,
    SherlockV2TabOpen,
    SherlockV2TabClosed,
} from './SherlockV2';

const pushRudderEvent = async (event, userEmail, properties = {}) => {
    try {
        const payload = { event, actorId: userEmail, properties: JSON.stringify(properties) };

        const queryString = getQueryStringFromObject(payload);

        await clientApiWrapper.get(`${PUSH_RUDDER_EVENT}?${queryString}`);
    } catch (error) {
        const errorMsg = getErrMsg(error);
        console.error('pushRudderEvent ~ errorMsg:', errorMsg);
    }
};

const RudderEvent = {
    TransactionView: TransactionViewEventList,
    Profile: {
        SherlockProfileCopyButtonClick,
    },
    FreshDeskInfo: {
        ClickedGuruCardLink,
    },
    KYCOnboarding: {
        KYCMainScreenCopyButtonClick,
        InAppScreenDetailsCopyButtonClick,
        KYCDetailsCopyButtonClick,
        OnboardingDetailsCopyButtonClick,
    },
    SherlockV1Scripts: {
        ClickedSherlockSearchBar,
        SearchedSherlockScriptSearchBar,
        ClickedSherlockIndividualScriptSearchBar,
        ClickedSherlockSearchBarAllScripts,
    },
    SherlockV2Scripts: {
        LandedSherlockV2ScriptsSearchPage,
        LandedSherlockV2ScriptsPage,
        ClickedPictureGuideStep,
        ClickedPictureGuideDoneButton,
    },
    RecentActivity: {
        LandedOnSherlockRecentActivity,
        ClickedSherlockSearchRecentActivityButton,
        SearchedSherlockRecentActivity,
        ClickedClearAllSherlockRecentActivity,
    },
    SherlockV1Agent: {
        AgentUserCallSelectionSherlock,
        AgentClickCreateANewTicket,
        AgentAttachTicketToCurrentCall,
        AgentClickTicketIdSubmit,
        AgentClickSherlockSidebarOption,
        SherlockV1AgentTicketTabActive,
        SherlockV1AgentTicketTabInActive,
        SherlockV1AgentTicketTabOpen,
        SherlockV1AgentTicketTabClosed,
    },
    SherlockV2: {
        SherlockV2TabActive,
        SherlockV2TabInActive,
        SherlockV2TabOpen,
        SherlockV2TabClosed,
    },
};

export { pushRudderEvent, RudderEvent };

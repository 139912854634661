import request from '../utils/request';
import { 
    AGENT_ROOT,
    AGENT_ROOT_V2,
} from './routes';

// TODO: remove this file altogether once all api calls logic moved to sagas

export const makeRequest = async (req, callbacks = {}) => {
    try {
        let response = await req;
        if(callbacks.onSuccess) {
            callbacks.onSuccess(response);
        }
    } catch (error) {
        if(callbacks.onError) {
            callbacks.onError(error);
        }
    }
};

const getHeaders = () => {
    const csrfTokenMetaTag = document.querySelector('meta[name="csrf-token"]')
    let csrfToken;

    if (csrfTokenMetaTag) {
        csrfToken = csrfTokenMetaTag.getAttribute('content');

        return {
            'csrf-token': csrfToken,
        };
    }

    return {};
};

// AGENTS
export const getAgentList = () => request(`${AGENT_ROOT}`, { method: 'GET' });
export const createAgent = (data) => request(AGENT_ROOT, { method: 'POST', headers: getHeaders(), data });
export const getAgentInfo = (email) => request(`${AGENT_ROOT}/${email}`, { method: 'GET' });
export const getAgentInfoV2 = (email) => request(`${AGENT_ROOT_V2}/${email}`, { method: 'GET' });
export const removeAgent = (email) => request(`${AGENT_ROOT}/${email}`, { method: 'DELETE', headers: getHeaders() });
export const updateAgent = (email, data) => request(`${AGENT_ROOT}/${email}`, { method: 'POST', headers: getHeaders(), data });

/**
 *
 * AgentList - Component to show the list of all agents in Sherlock
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    Button, ExpandableCard, FormWithSingleInput, Loader, Table,
} from '../../../components';

import { CREATE_AGENT_PATH, AGENT_PATH } from '../../App/routes';

import {
    getAgentList, setAgentInfo, setAgentFormFields, resetAgentFormFields,
} from '../actions';
import { makeSelectAgentList } from '../selectors';
import { AGENT_LIST_LABEL_DATA } from '../constants';

import './style.scss';

const AgentList = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // reducer state variables
    const agentList = useSelector(makeSelectAgentList(), shallowEqual);

    // local state variables
    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(false);
    const [emailId, setEmailId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [formErrLabel, setFormErrLabel] = useState('');

    const { loading, data = [], prev, next } = agentList;

    useEffect(() => {
        if (data.length === 0) {
            dispatch(getAgentList({}));
        }
    }, [dispatch, data.length]);

    const getPaginatedAgentList = ({ prev, next }) => {
        dispatch(getAgentList({ prev, next }));
    };

    const toggleSearchCard = () => {
        setIsSearchCardExpanded(!isSearchCardExpanded);
    };

    const handleInputChange = (key) => (e) => {
        const value = e.target.value;

        switch (key) {
            case 'emailId':
                setEmailId(value);
                break;

            case 'firstName':
                setFirstName(value);
                break;

            default:
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!emailId && !firstName) {
            setFormErrLabel('Please fill either of the above fields');
            return;
        }

        dispatch(getAgentList({ emailId, firstName }));
        setFormErrLabel('');
        toggleSearchCard();
    };

    const onCreateAgentClickHandler = () => {
        dispatch(resetAgentFormFields());

        history.push(CREATE_AGENT_PATH);
    };

    const onViewAgentInfoClickHandler = (data) => () => {
        dispatch(setAgentInfo(data));

        history.push(`${AGENT_PATH}${data.emailId}`);
    };

    const onEditAgentInfoClickHandler = (data) => () => {
        // need to set agent info also to compare the updated info with the existing info
        dispatch(setAgentInfo(data));
        dispatch(setAgentFormFields(data));

        history.push(`${AGENT_PATH}${data.emailId}/edit`);
    };

    const renderActions = (data) => {
        return (
            <div className='frfscWrapper'>
                <Button
                    link
                    label='View Agent Info'
                    onClick={onViewAgentInfoClickHandler(data)}
                />
                <div className='agent-list-action-sr'>|</div>
                <Button
                    link
                    label='Edit Agent Info'
                    onClick={onEditAgentInfoClickHandler(data)}
                />
            </div>
        );
    };

    const renderFormInput = (key) => {
        let label;
        let labelId;
        let value;

        switch (key) {
            case 'emailId': {
                label = 'Email Id';
                labelId = 'agent-list-view-email-id';
                value = emailId;

                break;
            }

            case 'firstName': {
                label = 'First Name';
                labelId = 'agent-list-view-first-name';
                value = firstName;

                break;
            }

            default:
        }

        return (
            <FormWithSingleInput
                onFormSubmit={handleSubmit}
                extClasses={{
                    container: 'agent-list-fc',
                    label: 'agent-list-fc__lb',
                    inputField: 'agent-list-fc__ip',
                }}
                label={label}
                labelId={labelId}
                formType='text'
                input={{
                    value,
                    onChange: handleInputChange(key),
                }}
                hideErrLabel
            />
        );
    };

    const resetSearchResults = () => {
        dispatch(getAgentList({}));
        toggleSearchCard();
    };

    return (
        <React.Fragment>
            <div className='agent-list-cr'>
                <div className='agent-list-hcr'>
                    <div className='agent-list-hcr__hd'>Agent Management</div>
                    <Button
                        v2
                        primary
                        onClick={onCreateAgentClickHandler}
                        label='Create Agent'
                        extClasses='agent-list-hcr__cta'
                    />
                </div>
                <ExpandableCard
                    extClasses='agent-list-ec'
                    heading='Search for Agents'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={toggleSearchCard}
                >
                    <div className='agent-list-sc'>
                        <div className='frWrapper'>
                            {renderFormInput('emailId')}
                            {renderFormInput('firstName')}
                        </div>
                        <div className='err-label agent-list-sc__errlb'>{formErrLabel}</div>
                        <div className='frWrapper agent-list-sc__2r'>
                            <Button
                                v2
                                primary
                                extClasses='agent-list-sc__cta'
                                label='Search'
                                onClick={handleSubmit}
                            />
                            <Button
                                link
                                extClasses='agent-list-sc__cta2'
                                label='Reset Search Results'
                                onClick={resetSearchResults}
                            />
                        </div>
                    </div>
                </ExpandableCard>
                <Table
                    v2
                    header='Agent List'
                    labelData={AGENT_LIST_LABEL_DATA}
                    contentData={data}
                    renderActionableColumn={renderActions}
                    prevToken={prev}
                    nextToken={next}
                    paginatedClickHandler={getPaginatedAgentList}
                    extClasses={{
                        container: 'agent-list-table-sr',
                    }}
                />
            </div>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default memo(AgentList);

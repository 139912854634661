/**
 * @file Selectors for UserActions container
 */

import { createSelector } from 'reselect';

import { USER_ACTIONS_KEY as CONTAINER_KEY } from './constants';

const makeSelectCallbackUser = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'callbackUser']),
    (subState) => subState.toJS(),
);

const makeSelectPayUser = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'payUser']),
    (subState) => subState.toJS(),
);

const makeSelectExportHealthData = (ticketId) => createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'exportHealthData', ticketId]),
    (subState) => subState?.toJS(),
);

const makeSelectPayoutType = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'payoutType']),
    (subState) => subState?.toJS(),
);

const makeSelectGetAllowedFiCoinValues = createSelector(
    (state) => state.getIn([CONTAINER_KEY, 'allowedFiCoinValues']),
    (subState) => subState?.toJS(),
);

export {
    makeSelectCallbackUser,
    makeSelectPayUser,
    makeSelectExportHealthData,
    makeSelectPayoutType,
    makeSelectGetAllowedFiCoinValues,
};

/**
 *
 * PIN Set Details Modal
 *
 */

import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button } from '../../../components';

import './style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '25%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 1000,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    }
};

const PINSetDetailsModal = (props) => {
    const { visible, data, toggleHandler } = props;

    return (
        <Modal
            isOpen={visible}
            style={customStylesForModal}
            contentLabel='PIN Set Details Modal'
        >
            <div className='upi-modal-hwr'>PIN Set Details</div>
            <div className='table-sr table-sr--mt0'>
                <div className='table-sr__thw'>
                    <div className='table-sr__th'>User Action</div>
                    <div className='table-sr__th'>Status</div>
                    <div className='table-sr__th'>Created At</div>
                    <div className='table-sr__th'>Updated At</div>
                    <div className='table-sr__th f2Wrapper'>Description</div>
                </div>
                <div className='table-sr__tlc'>
                    {
                        data.pinInfo && data.pinInfo.map(frItem => (
                            <div className='table-sr__tlw table-sr__tlw--aic'>
                                <div className='table-sr__tl'>{frItem.userAction}</div>
                                <div className='table-sr__tl'>{frItem.status}</div>
                                <div className='table-sr__tl'>{frItem.createdTs}</div>
                                <div className='table-sr__tl'>{frItem.updatedTs}</div>
                                <div className='table-sr__tl table-sr__tl--pwr f2Wrapper'>{frItem.detailedStatus}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <Button
                primary
                extClasses='upi-modal-bwr'
                label='Close'
                onClick={() => toggleHandler(false)}
            />
        </Modal>
    );
};

export default memo(PINSetDetailsModal);

/**
 * @file UpdateDisputeStatus
 * Update the dispute status for a particular dispute
 */

import React, { memo, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';

import { AppDropdown, Button } from '../../../components';

import { updateDisputesInfoAction } from '../actions';
import {
    DISPUTE_STATE_OPTIONS, DD_OG_STATE, DD_WRAPPER_CLS,
    DD_CONTENT_CLS, DD_ITEM_CLS, DEFAULT_STYLES_FOR_MODAL,
} from '../constants';

const customStylesForModal = {
    overlay: {
        ...DEFAULT_STYLES_FOR_MODAL.overlay,
    },
    content: {
        ...DEFAULT_STYLES_FOR_MODAL.content,
        width: 500,
        height: 400,
    },
};

const DISPUTE_STATES = DISPUTE_STATE_OPTIONS.map((item) => item.value);

const UpdateDisputeStatus = (props) => {
    const {
        visible, toggleHandler, data, setData,
    } = props;

    const dispatch = useDispatch();

    const [disputeState, setDisputeState] = useState(DD_OG_STATE);
    const [errLabel, setErrLabel] = useState('');

    const closeModal = () => {
        setData({});
        toggleHandler(false);
        setErrLabel('');
        setDisputeState(DD_OG_STATE);
    };

    const handleDDChange = () => {
        if (errLabel) setErrLabel('');
    };

    const handleSubmitClick = () => {
        const newDisputeState = disputeState.value;
        if (!newDisputeState) {
            setErrLabel('Please select a dispute state');
            return;
        }

        const payload = {
            disputeId: data.id,
            fieldMaskList: ['FIELD_MASK_DISPUTE_STATE'],
            disputeState: newDisputeState,
        };

        const { tableIndex } = data;
        dispatch(updateDisputesInfoAction({ payload, ticketId: data.ticket_id, tableIndex }));

        closeModal();
    };

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='Update Ticket ID'
        >
            <div className='fccWrapper'>
                <div className='fcWrapper disputes-idd'>
                    <div className='heading3'>Update Dispute State</div>

                    <AppDropdown
                        ddWrapperCls={DD_WRAPPER_CLS}
                        defaultDDLabel='Select New Dispute State'
                        ddContentCls={DD_CONTENT_CLS}
                        ddState={disputeState}
                        setDDState={setDisputeState}
                        ddList={DISPUTE_STATES}
                        ddItemCls={DD_ITEM_CLS}
                        onValueChange={handleDDChange}
                    />

                    {errLabel && <div className='err-label'>{errLabel}</div>}
                </div>

                <div className='frWrapper'>
                    <Button
                        v2
                        extClasses='disputes-modal-cta1'
                        primary
                        label='Submit'
                        onClick={handleSubmitClick}
                    />
                    <Button
                        v2
                        secondary
                        extClasses='disputes-modal-cta1'
                        label='Close'
                        onClick={closeModal}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default memo(UpdateDisputeStatus);

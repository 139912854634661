/**
 *
 * Facematch Review flow
 * User can see the existing cases for facematch
 * User can select a case for review
 * For review, user can view the facematch image and fill details to update facematch status for that case
 * Once review is done, user can dequeue the case
 *
 */

import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { HorizontalIdNavbar, Loader } from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import { getAdditionalFilters } from '../RiskOps/utils';

import reducer from './reducer';
import saga from './saga';

import AllFacematchCases from './AllFacematchCases';
import SingleFacematchCase from './SingleFacematchCase';

import { getFacematchCasesAction, getFacematchPendingReviewCountAction, setCurFacematchCaseAction, setFacematchViewAction } from './actions';
import { VIEW_ALL_FACEMATCH_CASES, VIEW_SINGLE_FACEMATCH_CASE } from './constants';
import {
    selectErr, selectFacematchPayloadType, selectFacematchQueueFilters, selectFacematchView, selectLoading,
} from './selectors';

import './style.scss';

const FacematchReview = () => {
    const dispatch = useDispatch();

    // global state
    const loading = useSelector(selectLoading(), shallowEqual);
    const err = useSelector(selectErr(), shallowEqual);
    const facematchView = useSelector(selectFacematchView(), shallowEqual);
    const facematchPayloadType = useSelector(selectFacematchPayloadType(), shallowEqual);
    const { fromDate, toDate, sortOption } = useSelector(selectFacematchQueueFilters(), shallowEqual);

    /**
     * Get facematch cases in following states:
     * 1. when we have empty facematch cases []
     * 2. when the payload type changes
     * 3. When a dequeue action happens, then we need to call with the current payload type
     */
    useEffect(() => {
        dispatch(getFacematchPendingReviewCountAction({ payloadType: facematchPayloadType.value }));

        dispatch(getFacematchCasesAction({
            payloadType: facematchPayloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        }));
    }, [facematchPayloadType.value, dispatch]);

    const handleReviewClick = (facematchData) => () => {
        dispatch(setCurFacematchCaseAction(facematchData));
        dispatch(setFacematchViewAction(VIEW_SINGLE_FACEMATCH_CASE));
    };

    const renderView = () => {
        switch (facematchView) {
            case VIEW_ALL_FACEMATCH_CASES: {
                return <AllFacematchCases handleReviewClick={handleReviewClick} />;
            }

            case VIEW_SINGLE_FACEMATCH_CASE: {
                return <SingleFacematchCase />;
            }

            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            <HorizontalIdNavbar isFixed isEmptyNavbar />
            {renderView()}

            <div className='fmreview-cr'>
                {err && <div className='fmreview-crel err-label'>{err}</div>}
            </div>

            <Loader visible={loading} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'facematchReview', reducer });
const withSaga = injectSaga({ key: 'facematchReview', saga });

export default compose(
    withReducer,
    withSaga,
)(FacematchReview);

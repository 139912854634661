/**
 * @file Manages the action functions for transaction queue container
 */

import { GET_TRANSACTION_QUEUE, GET_TRANSACTION_QUEUE_FILTER, RESET_TRANSACTION_QUEUE, GET_PRIORITIZED_CASE } from './constants';

export const getTransactionQueueAction = (data) => ({
    type: GET_TRANSACTION_QUEUE,
    data,
});

export const getTransactionQueueFilterAction = (data) => ({
    type: GET_TRANSACTION_QUEUE_FILTER,
    data,
});

export const resetTransactionQueueAction = (data) => ({
    type: RESET_TRANSACTION_QUEUE,
    data,
});

export const getPrioritizedCaseAction = (data) => ({
    type: GET_PRIORITIZED_CASE,
    data,
});

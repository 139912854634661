import {
    GET_ACTIVE_RULES, GET_USER_RULES, GET_USER_RULE_INFO,
} from './constants';

export const getActiveRules = data => ({
    type: GET_ACTIVE_RULES,
    data
});

export const getUserRules = data => ({
    type: GET_USER_RULES,
    data
});

export const getUserRuleInfo = data => ({
    type: GET_USER_RULE_INFO,
    data
});

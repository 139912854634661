/**
 *
 * UserRuleInfoModal
 *
 */

import React, { memo } from 'react';
import Modal from 'react-modal';

import { Button, Pagination } from '../../../components';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '10%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 32,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: 900,
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
        maxHeight: '85%',
    },
};

const UserRuleInfoModal = (props) => {
    const { visible, data, selectedUserRule, pagination = {}, toggleHandler } = props;
    const { prev, next, onClickHandler } = pagination;

    if (!visible) return null;

    return (
        <Modal
            isOpen
            style={customStylesForModal}
            contentLabel='User Rule Info Modal'
        >
            <div className='fittt-modal-cr'>
                <div className='fittt-modal-lb'>
                    <span className='fittt-modal-lb--sbd'>Rule Description</span> - {selectedUserRule.descriptionStr}
                </div>
                {
                    selectedUserRule.accountNo ? (
                        <div className='fittt-modal-lb'>
                            <span className='fittt-modal-lb--sbd'>SD Number</span> - {selectedUserRule.accountNo}
                        </div>
                    ) : null
                }
                {
                    data.length ? (
                        <div className='table-sr table-sr--mt20'>
                            <div className='table-sr__label'>Rule Execution History</div>
                            <div className='table-sr__thw'>
                                <div className='table-sr__th'>Amount</div>
                                <div className='table-sr__th'>Timestamp</div>
                                <div className='table-sr__th'>Status</div>
                                <div className='table-sr__th'>Rule Type</div>
                            </div>
                            <div className='table-sr__tlc fittt-modal-table-sr__tlc'>
                                {
                                    data.map(item => (
                                        <div className='table-sr__tlw'>
                                            <div className='table-sr__tl'>{item.amount}</div>
                                            <div className='table-sr__tl'>{item.lastExecutedTs}</div>
                                            <div className='table-sr__tl'>{item.status}</div>
                                            <div className='table-sr__tl'>{item.type}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ) : (
                        <div className='fittt-src'>
                            <div className='table-nsr table-nsr--mt0'>No rule execution history found!</div>
                        </div>
                    )
                }
                <Pagination prev={prev} next={next} onClickHandler={onClickHandler} />
            </div>
            <Button
                extClasses='fittt-modal-cta'
                primary
                label='Close'
                onClick={toggleHandler}
            />
        </Modal>
    );
};

export default memo(UserRuleInfoModal);

/**
 *
 * Selectors for CX dispute flow
 *
 */

import { createSelector } from 'reselect';

import { DISPUTES, REDUCER_KEYS } from './constants';

export const selectDisputesInfo = (ticketId) => createSelector(
    (state) => state.getIn(['disputes', 'disputesInfo', ticketId]),
    (subState) => subState?.toJS() || {},
);

export const selectDisputesInfoForActor = (ticketId) => createSelector(
    (state) => state.getIn([DISPUTES, REDUCER_KEYS.DISPUTES_INFO_FOR_ACTOR, ticketId]),
    (subState) => subState?.toJS(),
);

import { createSelector } from 'reselect';

const selectUserInfo = state => state.getIn(['bulkUserDetailsByIds', 'userInfo']);

const makeSelectBulkUserInfo = () => createSelector(
    selectUserInfo,
    userInfo => userInfo.toJS(),
);

export {
    makeSelectBulkUserInfo,
};

/**
 *
 * Show liveness summary details as a Modal in the 3 Risk ops queue: liveness, facematch & namematch
 * Takes in the actorID of the current case & fetches the liveness details like annotation, threshold score, etc.
 *
 */

import React from 'react';
import { Button, Modal } from '../../components';

import LivenessSummary from '../LivenessSummary';

import './style.scss';

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: '15%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        transform: 'translateX(-50%) translateX(120px)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: '70%',
        maxWidth: '1500px',
        height: '70%',
        overflow: 'auto',
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const LivenessSummaryModal = ({ actorId, closeCta, visible }) => {
    if (!visible) return null;

    return (
        <Modal
            visible
            modalStyles={modalStyles}
            contentLabel='Liveness Summary Details'
        >
            <div className='rops-modal__cr'>
                <LivenessSummary externalData={{ actorId }} />
            </div>

            <div className='frcsbWrapper rops-modal__cta'>
                <Button
                    v2
                    primary
                    label='Close'
                    onClick={() => closeCta(false)}
                />
            </div>
        </Modal>
    );
};

export default LivenessSummaryModal;

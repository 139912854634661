import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { PAYOUT_ROOT } from '../../api/routes';

import {
    GET_RAISED_PAYOUT_REQUESTS, SET_RAISED_PAYOUT_REQUESTS, ERR_RAISED_PAYOUT_REQUESTS,
    GET_ALL_PAYOUT_REQUESTS, SET_ALL_PAYOUT_REQUESTS, ERR_ALL_PAYOUT_REQUESTS,
    UPDATE_PAYOUT_REQUEST, SET_UPDATE_PAYOUT_REQUEST,
} from './constants';

function* getRaisedPayoutRequests(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAYOUT_ROOT}/requests?${queryString}`
        );

        yield put({ type: SET_RAISED_PAYOUT_REQUESTS, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_RAISED_PAYOUT_REQUESTS, data: { err: e.message } });
    }
}

function* getAllPayoutRequests(action) {
    const { state } = action.data;
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PAYOUT_ROOT}/all?${queryString}`
        );

        yield put({ type: SET_ALL_PAYOUT_REQUESTS, data: { ...response, state }});
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_ALL_PAYOUT_REQUESTS, data: { err: e.message } });
    }
}

function* updatePayoutRequest(action) {
    const { requestId, state, reason, resolve } = action.data;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${PAYOUT_ROOT}/update`,
            {
                requestId,
                state,
                reason,
            }
        );

        if (resolve) {
            yield call(resolve);
        }

        toastify(response.status, 'success');
        yield put({ type: SET_UPDATE_PAYOUT_REQUEST });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: SET_UPDATE_PAYOUT_REQUEST });
    }
}

export default function* payoutRequestsSaga() {
    yield all(
        [
            yield takeLatest(GET_RAISED_PAYOUT_REQUESTS, getRaisedPayoutRequests),
            yield takeEvery(GET_ALL_PAYOUT_REQUESTS, getAllPayoutRequests),
            yield takeLatest(UPDATE_PAYOUT_REQUEST, updatePayoutRequest),
        ]
    );
}

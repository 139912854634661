/**
 *
 * AppV2Header - Component to show the header of the AppV2 with Location, SearchBar and Profile
 *
 */
import React from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as NotificationIcon } from '../../assets/images/svgs/bell.svg';
import { ReactComponent as AvatarIcon } from '../../assets/images/svgs/avatar.svg';
import SearchBar from '../SearchBar';
import './style.scss';
import { getAuthLogout } from '../../appGlobal/actions';

const AppV2Header = (props) => {
    const {
        agentInfo, heading, searchOptions,
    } = props;

    const {
        placeholder, value, setValue,
        showAllClickHandler, resultClickHandler, onChangeHandler,
        resultsLoading, resultsValue, handleSearchBarClick, isDisabled,
        isSearchBarVisible = true, resultType, onResultTypeChange,
    } = searchOptions || {};

    const dispatch = useDispatch();

    return (
        <div className='heading frcsbWrapper'>
            <h1 className='heading-title'>{heading}</h1>
            <div className='heading-other frcfeWrapper'>
                {isSearchBarVisible
                && (
                    <SearchBar
                        value={value}
                        handleOnSubmit={setValue}
                        placeholder={placeholder}
                        handleOnChange={onChangeHandler}
                        searchResults={resultsValue}
                        isLoading={resultsLoading}
                        resultOnClick={resultClickHandler}
                        showAllResultsOnClick={showAllClickHandler}
                        handleSearchBarClick={handleSearchBarClick}
                        isDisabled={isDisabled}
                        resultType={resultType}
                        onResultTypeChange={onResultTypeChange}
                    />
                )}

                <NotificationIcon />
                <div className='heading-user frcWrapper'>
                    <AvatarIcon className='heading-avatar' />
                    <div>{agentInfo?.firstName}</div>
                </div>
                <div className='heading-logout-wrapper'>
                    <div role='presentation' className='heading-logout-button' onClick={() => dispatch(getAuthLogout())}>
                        Log Out
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppV2Header;

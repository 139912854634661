/* eslint-disable import/no-cycle */
/**
 * AdminTools
 * Landing Page for Admin Tools
 * All routes related to Admin Tools flow would be listed here
 */

import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import classNames from 'classnames';
import { MetaInfoContext, UserDetailsContext } from '../../context';
import { VerticalNavbar } from '../../components';
import {
    BULK_USER_DETAILS_PATH, WAITLIST_USER_DETAILS_PATH, BULK_TICKET_UPDATE_PATH, BANNERS_PATH,
    AGENT_FEEDBACK_DETAILS, ALL_DISPUTES, LOG_FILTER, ISSUE_CONFIG_PATH,
} from '../App/routes';

import BulkUserDetailsByTicketIds from '../BulkUserDetailsByTicketIds';
import WaitlistDetails from '../WaitlistDetails';
import BulkTicketUpdate from '../BulkTicketUpdate';
import Banners from '../Banners';
import AgentFeedbackDetails from '../AgentFeedbackDetails';
import Disputes from '../Disputes';
import LogFilter from '../LogFilter';
import IssueConfig from '../IssueConfig';

import { getSideNavbarItems } from './utils';

import './style.scss';

const AdminTools = () => {
    const userInfo = useContext(UserDetailsContext);
    const {
        basicInfo: {
            accessLevelInfo: {
                isAdmin, isAdminRestricted, isSuperAdmin,
            },
        },
    } = userInfo;
    const showWaitlistDetails = isAdmin || isAdminRestricted; // access to admin & admin restricted

    const metaInfo = useContext(MetaInfoContext);

    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    const renderRoutes = () => (
        <Switch>
            <Route
                path={BULK_USER_DETAILS_PATH}
                component={BulkUserDetailsByTicketIds}
            />
            <Route
                path={WAITLIST_USER_DETAILS_PATH}
                render={() => {
                    if (!showWaitlistDetails) {
                        return <Redirect to={{ pathname: BULK_USER_DETAILS_PATH }} />;
                    }

                    return <WaitlistDetails />;
                }}
            />
            <Route
                path={BULK_TICKET_UPDATE_PATH}
                component={BulkTicketUpdate}
            />
            <Route
                path={ISSUE_CONFIG_PATH}
                component={IssueConfig}
            />
            <Route
                path={BANNERS_PATH}
                component={Banners}
            />
            <Route
                path={AGENT_FEEDBACK_DETAILS}
                component={AgentFeedbackDetails}
            />
            <Route
                path={ALL_DISPUTES}
                component={Disputes}
            />
            <Route
                path={LOG_FILTER}
                render={() => {
                    if (!isSuperAdmin) {
                        return <Redirect to={{ pathname: BULK_USER_DETAILS_PATH }} />;
                    }

                    return <LogFilter />;
                }}
            />
            {/* in case of unmatched url, redirect the user to Bulk User Details page */}
            <Redirect to={{ pathname: BULK_USER_DETAILS_PATH }} />
        </Switch>
    );

    return (
        <div className={classNames({
            'adtools-cr': bannersVersion === 1,
            'adtools-cr-v2': bannersVersion === 2,
        })}
        >
            <VerticalNavbar
                hideHorizontalNavbar
                initialActiveNavItem='get-bulk-user-details'
                navItems={getSideNavbarItems({
                    isAdmin, isAdminRestricted, isSuperAdmin,
                })}
            />
            <div className={classNames({
                'adtools-cc': bannersVersion === 1,
                'adtools-cc-v2': bannersVersion === 2,
            })}
            >
                {renderRoutes()}
            </div>
        </div>
    );
};

export default AdminTools;

const LABEL_DATA = [
    {
        id: 1,
        label: 'Bank',
        key: 'bank',
    },
    {
        id: 2,
        label: 'Account\nType',
        key: 'accountType',
    },
    {
        id: 3,
        label: 'Account No.',
        key: 'accountNo',
    },
    {
        id: 4,
        label: 'Account\nStatus',
        key: 'accountStatus',
    },
    {
        id: 5,
        label: 'Account\nSub Status',
        key: 'accountSubStatus',
    },
    {
        id: 6,
        label: 'Connected\nAt',
        key: 'connectedAt',
        extClasses: 'f15Wrapper',
    },
    {
        id: 7,
        label: 'Last Synced\nAt',
        key: 'lastSyncedAt',
        extClasses: 'f15Wrapper'
    },
];

export {
    LABEL_DATA,
};

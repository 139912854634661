import React, { useEffect, useContext, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { SCRIPTS_PATH, SEARCH_PATH, SOP_PATH } from '../../../containersV2/AppV2/routes';
import { debounce } from '../../../utils/helpers';
import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';
import { SearchBar } from '../../../components';
import { MetaInfoContext } from '../../../context';
import { addMessageEventListener, postMessageEvent } from '../../../messageEvents';
import { TIME_IN_MILLISECONDS } from '../../../containersV2/SOP/constants';

import { makeSelectAgentInfo } from '../../AppV2/selectors';

import { getSherlockScriptsSearchResults } from '../actions';
import { makeSelectScriptsSearchResults, makeSelectSelectedCallInfo, makeSelectTickets } from '../selectors';
import { SHERLOCK_V1_ENTRY_POINT } from '../constants';

const ScriptsSearchBar = () => {
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch();

    const agentInfo = useSelector(makeSelectAgentInfo(), shallowEqual);
    const searchResults = useSelector(makeSelectScriptsSearchResults(), shallowEqual);
    const ticketList = useSelector(makeSelectTickets(), shallowEqual);
    const selectedCallInfo = useSelector(makeSelectSelectedCallInfo(), shallowEqual);
    const activeIndex = ticketList.findIndex((item) => item.isActive);
    const activeTicket = ticketList[activeIndex];

    const rudderProperties = {
        ticketId: activeTicket?.ticketId,
        callId: selectedCallInfo?.ucid || null,
        entryPoint: SHERLOCK_V1_ENTRY_POINT,
    };

    const { loading: searchResultsLoading, data } = searchResults;
    // eslint-disable-next-line max-len
    const searchResultsValue = (data?.data?.rowData?.map((rows) => ({
        value: rows['SOP Name'][rows['SOP Name']?.value_v2],
        meta: rows?.meta,
        type: rows?.Type?.[rows?.Type?.value_v2],
    })) || []);

    const handleOnChange = (value, selectedType) => {
        dispatch(getSherlockScriptsSearchResults({
            text: value, resultType: selectedType?.value, agentEmail: agentInfo.emailId, rudderProperties, paginationParams: { size: 5 },
        }));
    };
    const debounceOnChangeHandler = debounce(handleOnChange, 1000);

    const postTicketIdToNewTab = (childWindow) => {
        const message = {
            ticketId: activeTicket?.ticketId,
        };
        if (childWindow) {
            childWindow.addEventListener('load', () => {
                setTimeout(() => {
                    postMessageEvent(childWindow, message, window?.opener?.location?.origin);
                }, [TIME_IN_MILLISECONDS * 3]);
            });
        }
    };

    const resultOnClick = (value) => {
        const resultClickRudderProperties = {
            id: value?.meta,
            ...rudderProperties,
        };
        pushRudderEvent(RudderEvent.SherlockV1Scripts.ClickedSherlockIndividualScriptSearchBar, agentInfo.emailId, resultClickRudderProperties);
        let externalUrl;
        if (value?.type?.toLowerCase() === 'sop') {
            externalUrl = `${SOP_PATH}/${value.meta}`;
        } else {
            externalUrl = `${SCRIPTS_PATH}/${value.meta}`;
        }
        const childWindow = window.open(externalUrl, '_blank');
        postTicketIdToNewTab(childWindow);
    };
    const handleOnSubmit = (value, selectedType) => {
        const properties = {
            queryText: value,
            ...rudderProperties,
        };
        pushRudderEvent(RudderEvent.SherlockV1Scripts.ClickedSherlockSearchBarAllScripts, agentInfo.emailId, properties);
        const childWindow = window.open(`${SEARCH_PATH}?query=${value}${(selectedType?.value && `&type=${selectedType?.value}`) || ''}`, '_blank');
        postTicketIdToNewTab(childWindow);
    };

    const handleOnTypeChange = (type, query) => {
        dispatch(getSherlockScriptsSearchResults({
            text: query, resultType: type?.value, agentEmail: agentInfo.emailId, rudderProperties, paginationParams: { size: 5 },
        }));
    };

    // Flag to turn off the search entry point
    const metaInfo = useContext(MetaInfoContext);
    const {
        componentVersions: {
            scriptsSearchVersion,
        },
    } = metaInfo;

    const handleSearchBarClick = () => {
        pushRudderEvent(RudderEvent.SherlockV1Scripts.ClickedSherlockSearchBar, agentInfo.emailId, rudderProperties);
    };

    useEffect(() => {
        const removeMessageListener = addMessageEventListener((event) => {
            const { closeSherlockV2 } = event.data || {};
            if (closeSherlockV2) {
                // Closes the event source window if all the conditions are satisfied.
                event.source.close();
            }
        });
        return removeMessageListener;
    }, []);

    if (!scriptsSearchVersion) return null;

    return (
        <div className='frccWrapper'>
            <SearchBar
                isV1
                value={searchValue}
                handleOnSubmit={(searchValueInternal, selectedType) => {
                    handleOnSubmit(searchValueInternal, selectedType);
                    setSearchValue(searchValueInternal);
                }}
                placeholder='Search SOPs & Scripts...'
                handleOnChange={debounceOnChangeHandler}
                searchResults={searchResultsValue}
                isLoading={searchResultsLoading}
                resultOnClick={resultOnClick}
                handleSearchBarClick={handleSearchBarClick}
                onResultTypeChange={handleOnTypeChange}
            />
        </div>
    );
};

export default ScriptsSearchBar;

import { createSelector } from 'reselect';

const selectWaitlist = state => state.get('waitlist');

const makeSelectLoading = () => createSelector(
    selectWaitlist,
    substate => substate.get('loading').toJS()
);

const makeSelectErr = () => createSelector(
    selectWaitlist,
    substate => substate.get('err').toJS()
);

const makeSelectUserList = () => createSelector(
    selectWaitlist,
    substate => substate.get('userList').toJS()
);

const makeSelectUserInfo = () => createSelector(
    selectWaitlist,
    substate => substate.get('userInfo').toJS()
);

export {
    makeSelectLoading,
    makeSelectErr,
    makeSelectUserList,
    makeSelectUserInfo,
};

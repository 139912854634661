/**
 * @file Contains helper function that help w.r.t to UI
 */

import { isNilOrEmpty } from './helpers';
import toastify from './toastify';

const getDropdownHeight = (listLength) => {
    let height;

    /**
     * 39 - height for single element in dropdown
     * 20 - top & bottom padding
     * 10 - extra space to show user that someting is there
     */
    if (listLength > 5) {
        height = 39 * 5 + 20 + 10;
    } else {
        height = 39 * listLength + 20;
    }

    return height;
};

const getSideNavbarItems = (navInfo) => {
    if (isNilOrEmpty(navInfo)) return [];

    const {
        ROOT_PATH: rootPath,
        ITEMS: navItems,
        DEFAULT_REDIRECT_PATH_INDEX = 0, // if not passed, takes first item as default redirect item
    } = navInfo;

    if (!rootPath) {
        throw new Error('The navInfo object does not have a ROOT_PATH property. Check your config.');
    }

    if (!navItems) {
        throw new Error('The navInfo object does not have a ITEMS property. Check your config.');
    }

    const modifiedNavItems = navItems.map((item) => {
        if (isNilOrEmpty(item)) {
            throw new Error('The item object is nil or empty. Check the objects inside ITEMS property.');
        }

        const { label, component, route } = item;

        if (!label || !component) {
            throw new Error('The item object does not have a label or component. Check the objects inside ITEMS property.');
        }

        const id = label
            .split(' ') // Ex - ["Bulk", "Account", "Verification"]
            .join('-') // Ex - Bulk-Account-Verification
            .toLowerCase(); // Ex - bulk-account-verification

        let path = route;

        // if route is not passed, build it using rootPath and id
        if (!path) {
            path = `${rootPath}/${id}`;
        }

        return {
            label,
            component,
            path,
            id,
        };
    });

    const defaultRedirectItem = modifiedNavItems[DEFAULT_REDIRECT_PATH_INDEX];

    if (isNilOrEmpty(defaultRedirectItem)) {
        throw new Error('The DEFAULT_REDIRECT_PATH_INDEX is not within the range of items. It should be less than the length of ITEMS array');
    }

    return {
        navItems: modifiedNavItems,
        defaultRedirectPath: defaultRedirectItem.path,
    };
};

// Calculate text area box content height
const calcTextAreaHeight = (value) => {
    const numberOfLineBreaks = (value.match(/\n/g) || []).length;
    // min-height + lines x line-height + padding + border
    const newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
    return newHeight;
};

/**
 * @param {string} id The id of the element to copy
 * @returns Does not return anything
 *
 * The function is used to copy the text inside any element along with its styles.
 * It uses a deprecated function document.execCommand, hence use this function
 * only if necessary keeping in mind that some browsers may give error for this function.
 */
const copyTextWithStyle = (id) => {
    try {
        const doc = document;
        const text = doc.getElementById(id);
        let range;
        let selection;

        // Check if createTextRange method is available (Internet Explorer)
        if (doc.body.createTextRange) {
            range = doc.body.createTextRange();
            // Expands range to the entire element
            range.moveToElement(text);
            // Select the range
            range.select();
        } else if (window.getSelection) { // Use the Selection API (modern browsers)
            selection = window.getSelection();

            range = doc.createRange();
            // Select the contents of the element using the range
            range.selectNodeContents(text);

            // Remove any existing ranges from the selection
            selection.removeAllRanges();
            // Add the new range to the selection
            selection.addRange(range);
        }
        /**
         * document.execCommand('copy') is a deprecated method used in the code to copy selected
         * content to the clipboard. It should be used with caution as it is no longer recommended
         * for new projects or critical functionality due to security concerns. Instead, it is
         * recommended to utilize the newer Clipboard API, such as navigator.clipboard.writeText(),
         * for improved cross-browser compatibility and security.
         *
         * The navigator.clipboard.writeText() does not allow to automatically copy all selected text
         * with styles. When navigator clipboard is used, it either allows to just copy text without styles.
         * Or we can use Clipboard API along with Blob to copy html, but it does not copy styles with html.
         * Hence it does not fit with our functionality to copy text with styles as it is.
         */
        document.execCommand('copy');
        toastify('Copied data to clipboard', 'success');
    } catch (error) {
        toastify('Error occurred while copying', 'error');
    }
    // Clear the selection range
    window.getSelection().removeAllRanges();
};

export {
    getDropdownHeight,
    getSideNavbarItems,
    calcTextAreaHeight,
    copyTextWithStyle,
};

/**
 * @file CxRiskReview actions
 */

import {
    GET_CX_RISK_REVIEW_REQUESTS_FOR_ACTOR, GET_CX_RISK_REVIEW_TICKETS, SET_CX_RISK_REVIEW_CUR_VIEW, SET_CX_RISK_REVIEW_ERR,
    SET_CX_RISK_REVIEW_REQUESTS_FOR_ACTOR, SET_CX_RISK_REVIEW_TICKETS, SET_CX_RISK_REVIEW_CUR_TICKET_DETAILS,
} from './constants';

export const getRiskOpsCxTicketsAction = (data) => ({
    type: GET_CX_RISK_REVIEW_TICKETS,
    data,
});

export const setRiskOpsCxTicketsAction = (data) => ({
    type: SET_CX_RISK_REVIEW_TICKETS,
    data,
});

export const setCxRiskReviewErrAction = (data) => ({
    type: SET_CX_RISK_REVIEW_ERR,
    data,
});

export const setCxRiskReviewCurViewAction = (data) => ({
    type: SET_CX_RISK_REVIEW_CUR_VIEW,
    data,
});

export const setCxRiskReviewCurTicketDetailsAction = (data) => ({
    type: SET_CX_RISK_REVIEW_CUR_TICKET_DETAILS,
    data,
});

export const getCxRiskReviewRequestsForActorAction = (data) => ({
    type: GET_CX_RISK_REVIEW_REQUESTS_FOR_ACTOR,
    data,
});

export const setCxRiskReviewRequestsForActorAction = (data) => ({
    type: SET_CX_RISK_REVIEW_REQUESTS_FOR_ACTOR,
    data,
});

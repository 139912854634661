/*
 *
 * Deposits actions
 *
 */

import {
    SET_SEARCH_PARAMS, RESET_SEARCH_PARAMS, GET_DEPOSITS_LIST, GET_DEPOSIT_REQUEST_LIST, GET_DEPOSIT_TXN_LIST,
} from './constants';

export const setSearchParams = data => ({
    type: SET_SEARCH_PARAMS,
    data,
});

export const resetSearchParams = data => ({
    type: RESET_SEARCH_PARAMS,
    data,
});

export const getDepositsList = data => ({
    type: GET_DEPOSITS_LIST,
    data,
});

export const getDepositRequestList = data => ({
    type: GET_DEPOSIT_REQUEST_LIST,
    data,
});

export const getDepositTxnList = data => ({
    type: GET_DEPOSIT_TXN_LIST,
    data,
});

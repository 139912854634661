/**
 * @file Manages the saga generator functions for Wealth P2P component
 */

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { WEALTH_P2P_INVESTOR_INFO_ENDPOINT, WEALTH_P2P_INVESTMENT_LIST_ENDPOINT } from '../../api/routes';

import { setAuthScreen } from '../Tickets/saga';

import {
    GET_INVESTOR_INFO, SET_INVESTOR_INFO, ERR_INVESTOR_INFO,
    GET_INVESTMENT_LIST, SET_INVESTMENT_LIST, ERR_INVESTMENT_LIST,
} from './constants';

function* getInvestorInfo(action) {
    const { ticketId, userId, index } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_P2P_INVESTOR_INFO_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_INVESTOR_INFO, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_INVESTOR_INFO, data: { ticketId } });
        }

        const { investorInfo: { isEligibleFlag } } = response;

        if (isEligibleFlag) {
            yield call(getInvestmentList, { data: action.data });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_INVESTOR_INFO, data: { ticketId, err } });
    }
}

function* getInvestmentList(action) {
    const { ticketId, userId, index } = action.data;
    const queryString = getQueryStringFromObject({ ticketId, userId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${WEALTH_P2P_INVESTMENT_LIST_ENDPOINT}?${queryString}`,
        );

        const result = yield call(setAuthScreen, { data: { index, ...response } });

        if (!result) {
            yield put({ type: SET_INVESTMENT_LIST, data: { ticketId, ...response }});
        } else if (result === 'auth-expired') {
            yield put({ type: ERR_INVESTMENT_LIST, data: { ticketId } });
        }
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_INVESTMENT_LIST, data: { ticketId, err } });
    }
}

export default function* wealthInvestmentSaga() {
    yield all(
        [
            yield takeLatest(GET_INVESTOR_INFO, getInvestorInfo),
            yield takeLatest(GET_INVESTMENT_LIST, getInvestmentList),
        ]
    );
}

import { createSelector } from 'reselect';

const selectPI = (state) => state.get('paymentInstruments');

const makeSelectCardsList = () => createSelector(
    selectPI,
    (substate) => substate.get('cardsList').toJS(),
);

const makeSelectUPIInfo = () => createSelector(
    selectPI,
    (substate) => substate.get('upiInfo').toJS(),
);

const getSelectForexTxnInfo = () => createSelector(
    (state) => state.getIn(['paymentInstruments', 'forexTxnsInfo']),
    (substate) => substate?.toJS(),
);

export {
    makeSelectCardsList,
    makeSelectUPIInfo,
    getSelectForexTxnInfo,
};

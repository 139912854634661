/**
 * @file Common risk ops actions
 */

import {
    AUDIT_LOG_MEDIA_DOWNLOAD, GET_RISK_OPS_L2_ANNOTATION_DETAILS, GET_RISK_OPS_CASES, SET_RISK_OPS_CASES, DEQUEUE_RISK_OPS_CASES,
    SET_RISK_OPS_CUR_VIEW, SET_RISK_OPS_PAYLOAD_TYPE, SET_RISK_OPS_CUR_CASE, GET_RISK_OPS_PENDING_REVIEW_COUNT, SET_RISK_OPS_PENDING_REVIEW_COUNT,
    SET_RISK_OPS_QUEUE_FILTERS, SET_RISK_OPS_ERROR, SET_RISK_OPS_L2_ANNOTATION_DETAILS, GET_RISK_OPS_REVIEW_VIDEO, SET_RISK_OPS_REVIEW_VIDEO,
    GET_RISK_OPS_BASE64_IMAGE, SET_RISK_OPS_BASE64_IMAGE, SUBMIT_RISK_OPS_QA_REVIEW,
} from './constants';

export const getRiskOpsL2AnnotationDetailsAction = (data) => ({
    type: GET_RISK_OPS_L2_ANNOTATION_DETAILS,
    data,
});

export const setRiskOpsL2AnnotationDetailsAction = (data) => ({
    type: SET_RISK_OPS_L2_ANNOTATION_DETAILS,
    data,
});

export const getRiskOpsCasesAction = (data) => ({
    type: GET_RISK_OPS_CASES,
    data,
});

export const setRiskOpsCasesAction = (data) => ({
    type: SET_RISK_OPS_CASES,
    data,
});

export const dequeueRiskOpsCaseAction = (data) => ({
    type: DEQUEUE_RISK_OPS_CASES,
    data,
});

export const setRiskOpsCurViewAction = (data) => ({
    type: SET_RISK_OPS_CUR_VIEW,
    data,
});

export const setRiskOpsPayloadTypeAction = (data) => ({
    type: SET_RISK_OPS_PAYLOAD_TYPE,
    data,
});

export const setRiskOpsCurCaseAction = (data) => ({
    type: SET_RISK_OPS_CUR_CASE,
    data,
});

export const getRiskOpsPendingReviewCountAction = (data) => ({
    type: GET_RISK_OPS_PENDING_REVIEW_COUNT,
    data,
});

export const setRiskOpsPendingReviewCountAction = (data) => ({
    type: SET_RISK_OPS_PENDING_REVIEW_COUNT,
    data,
});

export const setRiskOpsQueueFiltersAction = (data) => ({
    type: SET_RISK_OPS_QUEUE_FILTERS,
    data,
});

export const setRiskOpsErrAction = (data) => ({
    type: SET_RISK_OPS_ERROR,
    data,
});

export const getRiskOpsReviewVideoAction = (data) => ({
    type: GET_RISK_OPS_REVIEW_VIDEO,
    data,
});

export const setRiskOpsReviewVideoAction = (data) => ({
    type: SET_RISK_OPS_REVIEW_VIDEO,
    data,
});

export const getRiskOpsBase64ImageAction = (data) => ({
    type: GET_RISK_OPS_BASE64_IMAGE,
    data,
});

export const setRiskOpsBase64ImageAction = (data) => ({
    type: SET_RISK_OPS_BASE64_IMAGE,
    data,
});

export const auditLogMediaDownloadAction = (data) => ({
    type: AUDIT_LOG_MEDIA_DOWNLOAD,
    data,
});

export const submitRiskOpsQaReviewAction = (data) => ({
    type: SUBMIT_RISK_OPS_QA_REVIEW,
    data,
});

// Create a new file store
const fileStore = new Map();

// Function to store a file in the file store
function storeFile(id, file) {
    fileStore.set(id, file);
}

// Function to fetch a file from the file store using its id
function fetchFile(id) {
    return fileStore.get(id);
}

export { storeFile, fetchFile, fileStore };

/**
 * @file ViewVerificationCases
 * Displays all the pending cases for agents to manually verify salary details for users
 * Agents can filter the queue based on verification status
 */

import React, { useState, memo, useEffect, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { FormDropdown } from '../../../components';
import { useDebounce, useMemoizedSelector } from '../../../hooks';
import { MetaInfoContext } from '../../../context';

import {
    getSalaryOpsEmployerListAction, getVerificationCasesAction, getVerificationCasesCountAction, setSalaryAccountOpsCurViewAction,
    setSalaryFilterVerificationStatusAction, setSalaryOpsEmployerListAction, setVerificationCaseDetailsAction, setVerificationCasesEmployerNameAction,
    setVerificationCasesEmployerTypeAction,
} from '../actions';
import { SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY, VIEW_SINGLE_VERIFICATION_CASE } from '../constants';
import SalaryIdentifierInput from '../SalaryIdentifierInput';
import {
    selectSalaryAccountUserIdentifierDetails, selectSalaryOpsEnums, selectSalaryVerificationQueue,
} from '../selectors';
import VerificationCasesTable from '../VerificationCasesTable';

const FILTER_OPTIONS = [
    {
        label: 'Pending Verification',
        value: 'SALARY_TXN_VERIFICATION_STATUS_PENDING_VERIFICATION',
    },
    {
        label: 'Accepted',
        value: 'SALARY_TXN_VERIFICATION_STATUS_ACCEPTED',
    },
    {
        label: 'Rejected',
        value: 'SALARY_TXN_VERIFICATION_STATUS_REJECTED',
    },
    {
        label: 'Escalated To Engg Team',
        value: 'SALARY_TXN_VERIFICATION_STATUS_ESCALATED_TO_ENGG_TEAM',
    },
    {
        label: 'Awaiting Employment Update',
        value: 'SALARY_TXN_VERIFICATION_STATUS_AWAITING_EMPLOYMENT_UPDATE',
    },
];

const ViewVerificationCases = () => {
    const dispatch = useDispatch();
    const metaInfo = useContext(MetaInfoContext);
    const {
        componentVersions: { verificationQueueVersion },
    } = metaInfo;

    const enums = useSelector(selectSalaryOpsEnums, shallowEqual);
    const {
        loadingEmployerList, verificationStatus, verificationCasesCount, employerNameList, employerType, employerName,
        verificationCases: { data: casesList, prev: prevToken, next: nextToken },
    } = useSelector(selectSalaryVerificationQueue, shallowEqual);
    const userIdentifier = useMemoizedSelector(selectSalaryAccountUserIdentifierDetails, [SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY]);

    const verificationStatusList = enums.verificationStatusList || FILTER_OPTIONS;
    const { employerTypeList } = enums;
    const { count: casesCount } = verificationCasesCount?.value || {};

    const [searchEmployer, setSearchEmployer] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const debounceSearchEmployer = useDebounce(searchEmployer, 500);

    const makePayloadAndGetVerificationCases = (verificationStatusValue, params = {}) => {
        const payload = {
            verificationStatus: verificationStatusValue,
            verificationQueueVersion,
            ...params,
        };

        dispatch(getVerificationCasesAction(payload));
    };

    const makePayloadAndGetVerificationCasesCount = (verificationStatusValue, params = {}) => {
        const payload = {
            verificationStatus: verificationStatusValue,
            ...params,
        };

        dispatch(getVerificationCasesCountAction(payload));
    };

    const handleVerificationStatusChange = (newFilterValue) => {
        dispatch(setSalaryFilterVerificationStatusAction(newFilterValue));
        makePayloadAndGetVerificationCases(newFilterValue.value, {
            userIdentifier,
            employerType: employerType?.value,
            employerName: employerName?.value,
        });
        makePayloadAndGetVerificationCasesCount(newFilterValue.value);
    };

    const handleEmployerTypeChange = (newFilterValue) => {
        dispatch(setVerificationCasesEmployerTypeAction(newFilterValue));
        makePayloadAndGetVerificationCases(verificationStatus.value, {
            userIdentifier,
            employerType: newFilterValue?.value,
            employerName: employerName?.value,
        });
    };

    const handleEmployerNameChange = (newFilterValue) => {
        setSearchEmployer('');
        dispatch(setVerificationCasesEmployerNameAction(newFilterValue));
        makePayloadAndGetVerificationCases(verificationStatus.value, {
            userIdentifier,
            employerType: employerType?.value,
            employerName: newFilterValue?.value,
        });
    };

    const handleSearchEmployerNameChange = (inputValue) => {
        setSearchEmployer(inputValue);
    };

    const handleSearchEmployerFocus = () => {
        dispatch(setSalaryOpsEmployerListAction({ employer_details: [] }));
    };

    const getPaginatedVerificationCases = ({ prev, next }) => {
        makePayloadAndGetVerificationCases(verificationStatus.value, {
            prev, next, userIdentifier, sortOrder, employerType: employerType?.value, employerName: employerName?.value,
        });
    };

    const handleVerify = (item, index) => {
        dispatch(setVerificationCaseDetailsAction({ ...item, index }));
        dispatch(setSalaryAccountOpsCurViewAction({
            screenKey: SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY, value: VIEW_SINGLE_VERIFICATION_CASE,
        }));
    };

    const getSortingDataBasedOnSortingOrder = (value) => {
        setSortOrder(value);
    };

    const handleSubmit = (identifier) => {
        makePayloadAndGetVerificationCases(verificationStatus.value, {
            userIdentifier: identifier,
            employerType: employerType?.value,
            employerName: employerName?.value,
        });
    };

    useEffect(() => {
        // fetch verification cases only if not present already
        if (!casesList) {
            makePayloadAndGetVerificationCases(verificationStatus.value, {
                userIdentifier,
                employerType: employerType?.value,
                employerName: employerName?.value,
            });
        }
        if (!casesCount) makePayloadAndGetVerificationCasesCount(verificationStatus.value);
    }, []);

    useEffect(() => {
        if (sortOrder) {
            makePayloadAndGetVerificationCases(verificationStatus.value, {
                sortOrder,
                userIdentifier,
                employerType: employerType?.value,
                employerName: employerName?.value,
            });
        }
    }, [sortOrder]);

    useEffect(() => {
        const getEmployerList = () => {
            dispatch(getSalaryOpsEmployerListAction({ searchString: debounceSearchEmployer }));
        };
        if (debounceSearchEmployer || searchEmployer.length < 0) getEmployerList();
    }, [debounceSearchEmployer]);

    return (
        <React.Fragment>
            <div className='heading1 mb-60'>Salary Verification Queue</div>
            <div className='frfssbWrapper mb-30'>
                <div className='sip-cr1'>
                    <FormDropdown
                        label='Filter By Status'
                        options={verificationStatusList}
                        input={{
                            value: verificationStatus,
                            onChange: handleVerificationStatusChange,
                            placeholder: 'Choose...',
                        }}
                        cacheKey='salary-identifier-type'
                        extStyles={{
                            container: 'sip-fc1 mb-15',
                            label: 'sip-fc__lb',
                            inputField: 'sip-fc__ip',
                        }}
                    />
                    <FormDropdown
                        label='Employer Type'
                        options={employerTypeList}
                        input={{
                            value: employerType,
                            onChange: handleEmployerTypeChange,
                            placeholder: 'Choose...',
                            isClearable: true,
                        }}
                        cacheKey='salary-identifier-type'
                        extStyles={{
                            container: 'sip-fc1 mb-15',
                            label: 'sip-fc__lb',
                            inputField: 'sip-fc__ip',
                        }}
                    />
                    <FormDropdown
                        label='Employer Name'
                        options={employerNameList}
                        isLoading={loadingEmployerList}
                        input={{
                            value: employerName,
                            onChange: handleEmployerNameChange,
                            placeholder: 'Choose...',
                            isClearable: true,
                            inputValue: searchEmployer,
                            onInputChange: handleSearchEmployerNameChange,
                            onFocus: handleSearchEmployerFocus,
                        }}
                        cacheKey='salary-identifier-type'
                        extStyles={{
                            container: 'sip-fc1 mb-15',
                            label: 'sip-fc__lb',
                            inputField: 'sip-fc__ip',
                        }}
                    />
                </div>
                <SalaryIdentifierInput
                    isSalaryDataOps
                    isVerificationQueue
                    screenKey={SALARY_ACCOUNT_VERIFICATION_QUEUE_KEY}
                    onSubmit={handleSubmit}
                />
            </div>
            <div className='salops-kv__hd3 mb-30'>
                Total transactions in queue: {casesCount || '-'}
            </div>
            <VerificationCasesTable
                v2Table
                casesList={casesList}
                prevToken={prevToken}
                nextToken={nextToken}
                getPaginatedVerificationCases={getPaginatedVerificationCases}
                handleVerify={handleVerify}
                getSortingDataBasedOnSortingOrder={getSortingDataBasedOnSortingOrder}
            />
        </React.Fragment>
    );
};

export default memo(ViewVerificationCases);

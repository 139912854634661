import React, { memo, useState } from 'react';
import { equals } from 'ramda';

import {
    Button, DropdownContent, DropdownWrapper,
} from '../../../components';

import '../style.scss';

const DepositList = (props) => {
    const { isDepositRequestList, hasDepositList, depositList, setSelectedDeposit,openedActionsDepositId,
        setOpenedActionsDepositId, toggleDetailsModal, toggleTxnsModal, attachEntityAgainstTicket  } = props;
    const [selectedEntityMeta, setSelectedEntityMeta] = useState(null);

    if (!hasDepositList) {
        return null;
    }

    if (depositList && depositList.length === 0) {
        if (isDepositRequestList) return null;

        return (
            <div className='table-nsr deposits-src'>
                {`No search results for deposits ${isDepositRequestList ? 'requests' : ''} found!`}
            </div>
        );
    }

    const onChangeSelectedEntityMeta = (meta) => () => {
        if (equals(selectedEntityMeta, meta)) {
            setSelectedEntityMeta(null);
        } else {
            setSelectedEntityMeta(meta);
        }
    };

    const attachEntityToTicket = () => {
        const callAttachEntityAction = () => new Promise((resolve, reject) => {
            try {
                attachEntityAgainstTicket(selectedEntityMeta, resolve);
            } catch (e) {
                reject(e);
            }
        });

        callAttachEntityAction().then(() => {
            setSelectedEntityMeta(null);
        });
    };

    return (
        <React.Fragment>
            <div className='table-sr deposits-src'>
                <div className='table-sr__label'>
                    {`Search Results for Deposits ${isDepositRequestList ? 'Requests' : ''} List`}
                </div>
                <div className='table-sr__thw'>
                    <div className='f05Wrapper table-sr__th '></div>
                    <div className='table-sr__th'>Name</div>
                    <div className='table-sr__th'>Type</div>
                    <div className='table-sr__th'>Creation{'\n'}Date</div>
                    <div className='table-sr__th'>Maturity{'\n'}Date</div>
                    <div className='table-sr__th'>Account{'\n'}Number</div>
                    <div className='table-sr__th'>Interest{'\n'}Rate</div>
                    <div className='table-sr__th'>Request Id</div>
                    <div className='table-sr__th'>Status</div>
                    <div className='table-sr__th table-sr__th--actions'></div>
                </div>
                <div className='table-sr__tlc'>
                    {
                        depositList.map(item => (
                            <div className='table-sr__tlw table-sr__tlw--aic' key={item.depositId}>
                                <div className='table-sr__tl f05Wrapper'>
                                    <input
                                        type='checkbox'
                                        checked={equals(selectedEntityMeta, item.entityMeta)}
                                        onChange={onChangeSelectedEntityMeta(item.entityMeta)}
                                    />
                                </div>
                                <div className='table-sr__tl'>{item.detailedInfo && item.detailedInfo.name}</div>
                                <div className='table-sr__tl'>{item.type}</div>
                                <div className='table-sr__tl'>{item.creationDate}</div>
                                <div className='table-sr__tl'>{item.maturityDate}</div>
                                <div className='table-sr__tl'>{item.accountNumber}</div>
                                <div className='table-sr__tl'>{item.interestRate}</div>
                                <div className='table-sr__tl'>{item.detailedInfo && item.detailedInfo.requestId}</div>
                                <div className='table-sr__tl'>{item.status}</div>
                                <div className='table-sr__tl table-sr__tl--actions'>
                                    {
                                        item.accountNumber ? (
                                            <DropdownWrapper
                                                visible={openedActionsDepositId === item.depositId}
                                                onOutsideClickHandler={() => setOpenedActionsDepositId('')}
                                                extClasses={{
                                                    container: 'deposits-ad'
                                                }}
                                            >
                                                <div
                                                    className='deposits-ad-label'
                                                    onClick={() => {
                                                        setOpenedActionsDepositId(item.depositId);
                                                        setSelectedDeposit(item.detailedInfo);
                                                    }}
                                                >
                                                    ...
                                                </div>
                                                <DropdownContent
                                                    visible
                                                    extClasses = {{
                                                        container: [
                                                            'deposits-ad-cc height-transition',
                                                            openedActionsDepositId === item.depositId && (
                                                                !isDepositRequestList ? 'deposits-ad-cc--ac' : 'deposits-ad-cc--acrq'
                                                            )
                                                        ]
                                                    }}
                                                >
                                                    <div className='deposits-ad-cc-wr'>
                                                        <div
                                                            className='deposits-ad-cc-wr__label'
                                                            onClick={() => {
                                                                toggleDetailsModal(true);
                                                            }}
                                                        >
                                                            View Details
                                                        </div>
                                                        {
                                                            !isDepositRequestList ? (
                                                                <div
                                                                    className='deposits-ad-cc-wr__label'
                                                                    onClick={() => {
                                                                        toggleTxnsModal(true);
                                                                    }}
                                                                >
                                                                    View Transactions
                                                                </div>
                                                            ) : null
                                                        }
                                                    </div>
                                                </DropdownContent>
                                            </DropdownWrapper>
                                        ) : null
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                !isDepositRequestList ? (
                    <Button
                        primary
                        extClasses='deposits-aecta'
                        disabled={!selectedEntityMeta}
                        label='Mark against Ticket'
                        onClick={attachEntityToTicket}
                    />
                ) : null
            }
        </React.Fragment>
    );
};

export default memo(DepositList);

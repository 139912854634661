/**
 * @file RecentActivity/constants.js - Contains reducers used for recent activity
 */
import { fromJS } from 'immutable';

import {
    GET_RECENT_ACTIVITY, SET_RECENT_ACTIVITY,
    ERR_RECENT_ACTIVITY,
    GET_RECENT_ACTIVITY_DETAILS,
    SET_RECENT_ACTIVITY_DETAILS,
    ERR_RECENT_ACTIVITY_DETAILS,
    RECENT_ACTIVITY_DETAILS_KEY,
} from './constants';

const initialState = fromJS({});

/**
 * ES Lint is necessary in below line because the first parameter is always state
 * that parameter needs to have an initial state.
 */
// eslint-disable-next-line default-param-last
function rewardsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_RECENT_ACTIVITY: {
            const { ticketId } = action.data;

            return state
                .setIn([ticketId, 'loading'], true)
                .setIn([ticketId, 'err'], '');
        }

        case SET_RECENT_ACTIVITY: {
            const {
                ticketId, activityDetails, prev, next,
            } = action.data;

            return state
                .setIn([ticketId, 'loading'], false)
                .setIn([ticketId, 'data'], fromJS(activityDetails))
                .setIn([ticketId, 'prev'], fromJS(prev))
                .setIn([ticketId, 'next'], fromJS(next))
                .setIn(['transactions'], fromJS({}));
        }

        case ERR_RECENT_ACTIVITY: {
            const { ticketId, err, authFail } = action.data;

            return state
                .setIn([ticketId, 'loading'], false)
                .setIn([ticketId, 'err'], err)
                .setIn([ticketId, 'authFail'], authFail)
                .setIn([ticketId, 'data'], fromJS([]))
                .setIn([ticketId, 'prev'], fromJS({}))
                .setIn([ticketId, 'next'], fromJS({}));
        }

        case GET_RECENT_ACTIVITY_DETAILS: {
            const { ticketId } = action.data;

            return state
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'data'], fromJS([]))
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'loading'], true)
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'err'], '');
        }

        case SET_RECENT_ACTIVITY_DETAILS: {
            const {
                ticketId, sections, prev, next,
            } = action.data;

            return state
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'loading'], false)
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'data'], fromJS(sections))
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'prev'], fromJS(prev))
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'next'], fromJS(next));
        }

        case ERR_RECENT_ACTIVITY_DETAILS: {
            const { ticketId, err, authFail } = action.data;

            return state
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'loading'], false)
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'err'], err)
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'authFail'], authFail)
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'data'], fromJS([]))
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'prev'], fromJS({}))
                .setIn([ticketId, RECENT_ACTIVITY_DETAILS_KEY, 'next'], fromJS({}));
        }

        default:
            return state;
    }
}

export default rewardsReducer;

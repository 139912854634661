/**
 * @file UserActions > Export Health Data
 * Trigger push notifications for user to export health data
 * View existing app logs info of the user
 */

import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Loader, Table } from '../../../components';
import { useMemoizedSelector } from '../../../hooks';

import { getUserExportedAppLogsAction, triggerPushNotification } from '../actions';
import { EXPORTED_APP_LOG_LABEL_DATA } from '../constants';
import { makeSelectExportHealthData } from '../selectors';

import './style.scss';

const ExportHealthData = (props) => {
    const { ticketId } = props;

    const dispatch = useDispatch();
    const exportHealthData = useMemoizedSelector(makeSelectExportHealthData, [ticketId]);

    useEffect(() => {
        dispatch(getUserExportedAppLogsAction({ ticketId }));
    }, [dispatch, ticketId]);

    let loading;
    let userExportedAppLogs;
    let err;

    if (exportHealthData) {
        ({ loading, userExportedAppLogs, err } = exportHealthData);
    }

    return (
        <div className='exporthd fccWrapper'>
            <div className='frccWrapper'>
                <Button
                    primary
                    label='Trigger Push Notification'
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(triggerPushNotification({ ticketId }));
                    }}
                    extClasses='exporthd-cta'
                />
                <div className='exporthd-lb'>
                    This action sends a Push notification to user where
                    they can permit to export Fi health data
                </div>
            </div>
            <Table
                header='Users Exported App Logs'
                labelData={EXPORTED_APP_LOG_LABEL_DATA}
                contentData={userExportedAppLogs}
                errLabel={err}
                extClasses={{
                    container: 'exporthd-ueap',
                }}
            />
            <Loader visible={loading} />
        </div>
    );
};

export default memo(ExportHealthData);

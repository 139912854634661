/**
 * @file FileTable: Shows the file entries for a given file type. User can carry out different actions based on the type of file
 * All data is powered by backend and each action leads to rendering a specific screen type
 */

import React, { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Button, DropdownContent, DropdownWrapper, Table, ParameterList,
} from '../../../components';
import { isNilOrEmpty } from '../../../utils';

import {
    getFileEntriesAction, setUsStocksScreenDataAction, setUsStocksViewAction,
} from '../actions';
import { PARAMETER_FILTERS_CONFIG, US_STOCK_SCREENS } from '../constants';
import {
    selectUSStocksAdditionalActions, selectUSStocksFileEntries, selectUSStocksFileType, selectUSStocksTableFilters,
} from '../selectors';

const FileEntries = () => {
    const dispatch = useDispatch();

    const tableData = useSelector(selectUSStocksFileEntries);
    const additionalActions = useSelector(selectUSStocksAdditionalActions);
    const parameterList = useSelector(selectUSStocksTableFilters);
    const fileType = useSelector(selectUSStocksFileType);

    const [filterList, setFiltersList] = useState([]);
    const [openedActionsRowId, setOpenedActionsRowId] = useState('');

    /**
     * Check if the table has actions. Table will have actions in the following 2 cases:
     * 1. If the actions are present at at table level
     * 2. If the actions are present at a row level
     */
    const hasTableActions = useMemo(() => {
        if (isNilOrEmpty(tableData)) return false;

        if (tableData?.actions?.length) return true;

        if (tableData?.rowData[0]?.actions?.length) return true;

        return false;
    }, [tableData]);

    const callFileEntriesApi = (params) => {
        const {
            fileType: curFileType, prevToken, nextToken, filters,
        } = params;
        const payload = { fileType: curFileType.value };

        if (!isNilOrEmpty(prevToken)) payload.prevToken = prevToken;

        if (!isNilOrEmpty(nextToken)) payload.nextToken = nextToken;

        if (!isNilOrEmpty(filters)) payload.filters = filters;

        dispatch(getFileEntriesAction(payload));
    };

    const getPaginatedFileEntries = ({ prev: prevToken, next: nextToken }) => {
        callFileEntriesApi({
            fileType,
            filters: filterList,
            prevToken,
            nextToken,
        });
    };

    const navigateToScreenAction = () => {
        dispatch(setUsStocksViewAction({ curView: US_STOCK_SCREENS.SCREEN_ACTION }));
    };

    const handleActionActionClick = (actionData) => {
        dispatch(setUsStocksScreenDataAction({
            screenActionData: {
                actionData,
            },
        }));
        navigateToScreenAction();
    };

    const handleMenuDotsClick = (rowData) => {
        setOpenedActionsRowId(rowData.id);
    };

    const handleMenuItemClick = (rowData, actionData) => {
        dispatch(setUsStocksScreenDataAction({
            screenActionData: {
                rowData,
                actionData,
            },
        }));
        navigateToScreenAction();
    };

    const onFilterSubmit = (curFilterList) => {
        setFiltersList(curFilterList);
        callFileEntriesApi({
            fileType,
            filters: curFilterList,
        });
    };

    const handleRefreshClick = () => {
        callFileEntriesApi({
            fileType,
            filters: filterList,
        });
    };

    const renderActions = (rowData) => {
        if (!hasTableActions) return null;

        let rowActions = rowData.actions;

        if (!rowActions) rowActions = tableData.actions;

        return (
            <DropdownWrapper
                visible={openedActionsRowId === rowData.id}
                onOutsideClickHandler={() => setOpenedActionsRowId('')}
                extClasses={{
                    container: 'ad',
                }}
            >
                <button
                    type='button'
                    className='ad-dots'
                    onClick={() => handleMenuDotsClick(rowData)}
                >
                    ...
                </button>
                <DropdownContent
                    visible
                    extClasses={{
                        container: ['ad-cc', openedActionsRowId === rowData.id && 'ad-cc--active'],
                    }}
                >
                    {rowActions.map((actionInfo) => (
                        <button
                            key={actionInfo.label}
                            type='button'
                            className='ad-cc__it'
                            onClick={() => handleMenuItemClick(rowData, actionInfo)}
                        >
                            {actionInfo.label}
                        </button>

                    ))}
                </DropdownContent>
            </DropdownWrapper>
        );
    };

    if (!tableData) return null;

    return (
        <React.Fragment>
            <ParameterList
                config={PARAMETER_FILTERS_CONFIG}
                data={{ parameterList }}
                onSubmit={onFilterSubmit}
            />
            <div className='frcsbWrapper mt-60'>
                <div className='heading1'>File Entries</div>
                <Button
                    v2
                    secondary
                    label='Refresh'
                    onClick={handleRefreshClick}
                />
            </div>
            <Table
                v2
                labelData={tableData.columnData}
                objKey='value'
                contentData={tableData.rowData}
                renderActionableColumn={hasTableActions && renderActions}
                prevToken={tableData.prevToken}
                nextToken={tableData.nextToken}
                paginatedClickHandler={getPaginatedFileEntries}
                extClasses={{
                    container: 'mt-15',
                }}
            />
            <div className='frccWrapper my-60'>
                {additionalActions.map((action) => (
                    <Button
                        key={action.label}
                        v2
                        primary
                        label={action.label}
                        extClasses='mx-30'
                        onClick={() => handleActionActionClick(action)}
                    />
                ))}
            </div>
        </React.Fragment>
    );
};

export default memo(FileEntries);

/**
 * @file Manages the utility functions for App Global component
 */

import { isEmpty, isNil } from 'ramda';

/**
 * Returns the object with formatted access level as key & true as its value
 * @example
 * getFormattedAccessLevel('VIEW_LIVENESS_VIDEO') --> { isViewLivenessVideo: true }
 * getUnspecifiedFilteredValue('ADMIN') --> { isAdmin: true }
 *
 * @param {string} str string value passed as it is from BE
 * @returns formatted object with formatted access level as key & true as its value
 */
export const getFormattedAccessLevel = (accessLevel) => {
    if (isEmpty(accessLevel) || isNil(accessLevel)) return {};

    const upperCaseFirstLetter = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;

    const formattedAccessLevel = accessLevel.split('_').map(upperCaseFirstLetter).join('');

    return {
        [`is${formattedAccessLevel}`]: true,
    };
};

/** Function to set access level in meta tag */
export const setAccessLevelInMetaTag = (accessLevel) => {
    const accessLevelMetaTag = document.querySelector('meta[name="access-level"]');

    if (accessLevelMetaTag) { // update the meta content if already exists
        accessLevelMetaTag.setAttribute('content', accessLevel);
    } else { // create the new meta tag
        const meta = document.createElement('meta');
        meta.name = 'access-level';
        meta.content = accessLevel;

        document.getElementsByTagName('head')[0].appendChild(meta);
    }
};

import { call, put, takeLatest, all } from 'redux-saga/effects';

import { clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify } from '../../utils';
import { LIVENESS_ROOT_V2 } from '../../api/routes';

import {
    GET_LIVENESS_VIDEO_URL, SET_LIVENESS_VIDEO_URL, ERR_LIVENESS_VIDEO_URL,
} from './constants';

function* getLivenessVideoURL(action) {
    const { monorailId, actorId, requestId } = action.data;
    let queryString = getQueryStringFromObject({ monorailId });
    queryString = `${queryString}&actorId=${btoa(actorId)}&requestId=${btoa(requestId)}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LIVENESS_ROOT_V2}/video-url?${queryString}`
        );

        yield put({ type: SET_LIVENESS_VIDEO_URL, data: response });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_LIVENESS_VIDEO_URL, data: { err: e.message } });
    }
}

export default function* livenessVideoSaga() {
    yield all(
        [
            yield takeLatest(GET_LIVENESS_VIDEO_URL, getLivenessVideoURL),
        ]
    );
}

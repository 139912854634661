/**
 * @file useBarXScale for BarChart
 * A custom hook to get XScale, ZoomTransform ect...
 */

import { useMemo, useState, useEffect } from 'react';
import * as d3 from 'd3';

import { getD3Time } from './utils';

const defaultTransform = { x: 0, y: 0, k: 1 };

function useBarXScale(props) {
    const { dimensions, data, aggregation } = props;

    const [zoomTransform, setZoomTransform] = useState(defaultTransform);

    // maximum date from data
    const maxDate = useMemo(() => d3.max(data, (d) => d.date), [data]);

    const rangeExtent = [dimensions.marginLeft, dimensions.width - dimensions.marginRight];

    // max date plus one based on aggregation (day, week, month)
    const maxdomain = getD3Time(aggregation).offset((maxDate), 1).getTime();
    // min date based on width and max date for proper spacing in x scale
    const mindomain = getD3Time(aggregation).offset(maxDate, -(dimensions.width / 80)).getTime();

    // X-axis time scale
    const xScaleTemplate = d3.scaleTime()
        .domain([mindomain, maxdomain])
        .range(rangeExtent)
        .nice();

    const [xs, setXScale] = useState({ xScale: xScaleTemplate });

    // To update Xscale whenever the min and max domain values changes
    useEffect(() => {
        setXScale({ xScale: xScaleTemplate.copy().domain([mindomain, maxdomain]) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxdomain, mindomain]);

    // To reset zoom transform on aggregation change
    useEffect(() => {
        setZoomTransform({ ...defaultTransform });
    }, [aggregation]);

    return {
        zoomTransform,
        xScaleTemplate,
        setXScale,
        setZoomTransform,
        xScale: xs.xScale,
    };
}

export function useBarCustomXScale(props) {
    const { dimensions, data, aggregation } = props;

    const [zoomTransform, setZoomTransform] = useState(defaultTransform);

    // maximum date from data
    const maxDate = useMemo(() => d3.max(data, (d) => d.label), [data]);

    const rangeExtent = [dimensions.marginLeft, dimensions.width - dimensions.marginRight];

    // max date plus one based on aggregation (day, week, month)
    const maxdomain = getD3Time(aggregation).offset((maxDate), 1).getTime();
    // min date based on width and max date for proper spacing in x scale
    const mindomain = getD3Time(aggregation).offset(maxDate, -(dimensions.width / 80)).getTime();

    // X-axis time scale
    const xScaleTemplate = d3.scaleTime()
        .domain([mindomain, maxdomain])
        .range(rangeExtent)
        .nice();

    const [xs, setXScale] = useState({ xScale: xScaleTemplate });

    // To update Xscale whenever the min and max domain values changes
    useEffect(() => {
        setXScale({ xScale: xScaleTemplate.copy().domain([mindomain, maxdomain]) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxdomain, mindomain]);

    // To reset zoom transform on aggregation change
    useEffect(() => {
        setZoomTransform({ ...defaultTransform });
    }, [aggregation]);

    return {
        zoomTransform,
        xScaleTemplate,
        setXScale,
        setZoomTransform,
        xScale: xs.xScale,
    };
}

export default useBarXScale;

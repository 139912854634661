/* eslint-disable default-param-last */
/**
 * @file Manages the reducer state & function for risk rule container
 */

import { fromJS } from 'immutable';

import {
    GET_RULE_QUEUE, SET_RULE_QUEUE, ERR_RULE_QUEUE, RESET_RULE, CREATE_RULE, UPADATE_RULE,
    ERR_CREATE_RULE, ERR_UPADATE_RULE, REDUCER_KEYS, SET_TAGS,
} from './constants';

const initialState = fromJS({
    fileEntries: {
        rowData: [],
    },
    loading: false,
    err: '',
    tags: [],
    enums: {},
});

function transactionQueueReducer(state = initialState, action) {
    switch (action.type) {
        case GET_RULE_QUEUE:
        case CREATE_RULE:
        case UPADATE_RULE:
            return state
                .setIn([REDUCER_KEYS.RULE_LOADING], true)
                .setIn([REDUCER_KEYS.RULE_ERROR], '');

        case SET_RULE_QUEUE: {
            const { fileEntries, enums = {} } = action.data;

            return state
                .setIn([REDUCER_KEYS.RULE_FILE_ENTRIES], fromJS(fileEntries))
                .setIn([REDUCER_KEYS.ENUMS], fromJS(enums))
                .setIn([REDUCER_KEYS.RULE_LOADING], false)
                .setIn([REDUCER_KEYS.RULE_ERROR], '');
        }

        case ERR_RULE_QUEUE: {
            const { err } = action.data;

            return state
                .setIn([REDUCER_KEYS.RULE_FILE_ENTRIES], fromJS({}))
                .setIn([REDUCER_KEYS.RULE_LOADING], false)
                .setIn([REDUCER_KEYS.RULE_ERROR], err);
        }

        case ERR_CREATE_RULE:
        case ERR_UPADATE_RULE:
            return state
                .setIn([REDUCER_KEYS.RULE_LOADING], false);

        case RESET_RULE: {
            return initialState;
        }

        case SET_TAGS: {
            return state.setIn([REDUCER_KEYS.TAGS], fromJS(action.data));
        }
        default:
            return state;
    }
}

export default transactionQueueReducer;

/**
 *
 * Call Details
 *
 */

import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { compose } from 'redux';
import Modal from 'react-modal';
import classNames from 'classnames';

import { FormWithSingleInput, Loader } from '../../components';
import { injectReducer, injectSaga } from '../../utils';

import CallDetailsV1 from './CallDetailsV1';
import { getCallRecordingURL, getCallTranscript } from './actions';
import { makeSelectRecordingInfo, makeSelectTranscriptInfo } from './selectors';
import reducer from './reducer';
import saga from './saga';

import './style.scss';

const customStylesForModal = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
    },
    content: {
        top: 0,
        left: 0,
        right: 'auto',
        bottom: 'auto',
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        width: '100%',
        height: '100%',
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 15px',
    },
};

const CallDetails = (props) => {
    const dispatch = useDispatch();

    const [recordingId, setRecordingId] = useState('');
    const [errLabel, setErrLabel] = useState('');
    const [showFullScreenModal, setFullScreenModal] = useState(false);
    // hard code version = 1 for now. To check the old flow, change this to 0
    const [componentVersion] = useState(1);

    const recordingInfo = useSelector(makeSelectRecordingInfo(), shallowEqual);
    const transcriptInfo = useSelector(makeSelectTranscriptInfo(), shallowEqual);

    const { ticketId, userId } = props;

    let loadingRecordingInfo;
    let recordingURL;
    let loadingTranscriptInfo;
    let transcriptData;

    if (recordingInfo[ticketId]) {
        ({ loading: loadingRecordingInfo, recordingURL } = recordingInfo[ticketId]);
    }

    if (transcriptInfo[ticketId]) {
        ({ loading: loadingTranscriptInfo, transcriptData } = transcriptInfo[ticketId]);
    }

    const handleInputChange = (key) => (event) => {
        const { value } = event.target;

        switch (key) {
            case 'recordingId':
                setRecordingId(value);
                break;

            default:
        }
    };

    const handleSubmit = () => {
        if (!recordingId) {
            setErrLabel('Please enter a valid recording id');
            return;
        }

        setErrLabel('');
        dispatch(getCallRecordingURL({ ticketId, userId, recordingId }));
        dispatch(getCallTranscript({ ticketId, userId, recordingId }));
    };

    const renderTranscript = (fullScreenMode) => {
        if (!transcriptData) return null;

        const contentWrClass = classNames({
            'calldtls-trwr': true,
            'calldtls-trwr--modal': fullScreenMode,
        });

        return (
            <div className={contentWrClass}>
                <button
                    type='button'
                    className='link calldtls-trwr__pg'
                    onClick={() => setFullScreenModal(!fullScreenMode)}
                >
                    {`${!fullScreenMode ? 'Open' : 'Close'} Full Screen`}
                </button>
                <div className='calldtls-trwr__desc'>
                    {JSON.stringify(transcriptData, null, 4)}
                </div>
            </div>
        );
    };

    if (componentVersion === 1) {
        return (
            <CallDetailsV1 parentProps={props} />
        );
    }

    return (
        <React.Fragment>
            <div className='calldtls'>
                <FormWithSingleInput
                    label='Enter recording id'
                    labelId='recording-id'
                    input={{
                        value: recordingId,
                        onChange: handleInputChange('recordingId'),
                    }}
                    cta={{
                        primaryLabel: 'Submit',
                        onPrimaryClick: handleSubmit,
                    }}
                    errLabel={errLabel}
                />
                {
                    recordingURL ? (
                        <div className='calldtls-recwr'>
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <audio width='500' controls controlsList='nodownload'>
                                <source src={recordingURL} type='audio/mpeg' />
                                Your browser does not support the audio tag.
                            </audio>
                        </div>
                    ) : null
                }
                {renderTranscript()}
            </div>
            <Modal
                isOpen={showFullScreenModal}
                style={customStylesForModal}
                contentLabel='Full Screen Transcript Details Modal'
            >
                {renderTranscript(true)}
            </Modal>
            <Loader visible={loadingRecordingInfo || loadingTranscriptInfo} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'callDetails', reducer });
const withSaga = injectSaga({ key: 'callDetails', saga });

export default compose(
    withReducer,
    withSaga,
)(CallDetails);

/**
 *
 * MutualFundsList - Component to show the invested mutual funds list by the current user
 *
 */

import React from 'react';
import classNames from 'classnames';

import { Pagination } from '../../../components';

import '../style.scss';

const MutualFundsList = (props) => {
    const {
        data, prev, next, getPaginatedData, handleViewDetails,
    } = props;

    const containerClass = classNames(
        'mfs-src',
    );

    if (!data) return <div className={containerClass} />;

    if (data.length === 0) {
        return (
            <div className={containerClass}>
                <div className='table-nsr table-nsr--mt0'>No search results found!</div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className={containerClass}>
                <div className='table-sr table-sr--mt0'>
                    <div className='table-sr__thw'>
                        <div className='table-sr__th'>Fund Name</div>
                        <div className='table-sr__th'>Fund Status</div>
                        <div className='table-sr__th'>Asset Class</div>
                        <div className='table-sr__th f15Wrapper'>Auto Invest Details</div>
                        <div className='table-sr__th'>First Transacted At</div>
                        <div className='table-sr__th'>Last Transacted At</div>
                        <div className='table-sr__th'>Action</div>
                    </div>
                    <div className='table-sr__tlc'>
                        {
                            data.map(item => (
                                <div className='table-sr__tlw' key={item.id}>
                                    <div className='table-sr__tl'>{item.name}</div>
                                    <div className='table-sr__tl'>{item.status}</div>
                                    <div className='table-sr__tl'>{item.assetClass}</div>
                                    <div className='table-sr__tl f15Wrapper'>{item.autoInvestInfo}</div>
                                    <div className='table-sr__tl'>{item.firstTxnTs}</div>
                                    <div className='table-sr__tl'>{item.lastTxnTs}</div>
                                    <div
                                        className='table-sr__tl link'
                                        onClick={() => {
                                            handleViewDetails(item.id, item.name);
                                        }}
                                    >
                                        View Details
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Pagination prev={prev} next={next} onClickHandler={getPaginatedData} />
            </div>
        </React.Fragment>
    );
};

export default MutualFundsList;

/**
 * @file ScreenAction: On clicking any CTA (at the bottom of the screen or table menu action) on the FileEntries page, it will take the user to a certain screen type.
 * There are specific screen types defined as an enum in BE. This component will read the selected screen data and render the required screen type.
 */

import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isNilOrEmpty } from '../../../utils';
import { Button, KVPairInfo } from '../../../components';

import DevActions from '../../DevActions';
import { SingleCaseHeading } from '../../RiskOps';

import { getUsStocksAcknowledgeFileEntryAction, getUsStocksGenerateFileAction, setUsStocksViewAction } from '../actions';
import { SCREEN_TYPES, US_STOCK_SCREENS } from '../constants';
import { selectUSStocksFileType, selectUSStocksGeneratedFileData, selectUSStocksScreenActionData } from '../selectors';

const GenerateFileEntry = () => {
    const dispatch = useDispatch();

    const { rowData } = useSelector(selectUSStocksScreenActionData);
    const fileType = useSelector(selectUSStocksFileType);
    const generatedFileData = useSelector(selectUSStocksGeneratedFileData);

    const fileEntryId = rowData?.file_id;

    useEffect(() => {
        const payload = { fileType: fileType.value };

        if (fileEntryId) payload.fileEntryId = fileEntryId;

        dispatch(getUsStocksGenerateFileAction(payload));
    }, [fileType.value, fileEntryId, dispatch]);

    if (isNilOrEmpty(generatedFileData)) return null;

    return (
        <KVPairInfo
            title='Generated File Information'
            data={generatedFileData}
            valuesHaveHtml
            extClasses={{
                container: 'app-kv__cr',
                titleHeading: 'app-kv__hd',
                contentLabel: 'app-kv__cl',
                contentValue: 'app-kv__cv',
            }}
        />
    );
};

const InputScreen = () => {
    const { rowData, actionData: { screen_data: screenData } } = useSelector(selectUSStocksScreenActionData);
    const actionKey = screenData.action_data;

    const devActions = screenData[actionKey]?.dev_action_names;
    const fileEntryId = rowData?.file_id;

    const preFilledValues = {
        mergeKey: 'name',
        paramList: [
            { name: 'file_id', value: fileEntryId, isDisabled: true },
            { name: 'fgaClientReqId', value: fileEntryId, isDisabled: true },
        ],
    };

    const extraActions = [setUsStocksViewAction({ curView: US_STOCK_SCREENS.FILE_ENTRIES })];

    if (isNilOrEmpty(devActions)) return null;

    return (
        <DevActions
            containerKey='us-stocks-input-screen'
            actionTypes={devActions}
            preFilledValues={preFilledValues}
            extraActions={extraActions}
        />
    );
};

const AcknowledgeFileEntry = () => {
    const dispatch = useDispatch();

    const { rowData, actionData: { screen_data: screenData } } = useSelector(selectUSStocksScreenActionData);
    const actionKey = screenData.action_data;
    const screenInfo = screenData[actionKey];

    const handleAcknowledgeClick = () => {
        const fileEntryId = rowData?.file_id;
        const payload = { fileEntryId };
        dispatch(getUsStocksAcknowledgeFileEntryAction(payload));
    };

    return (
        <div className='fccWrapper'>
            <div className='app-hl'>{screenInfo.message}</div>
            <Button
                v2
                primary
                extClasses='my-30'
                label={screenInfo.label}
                onClick={handleAcknowledgeClick}
            />
        </div>
    );
};

const ScreenAction = () => {
    const dispatch = useDispatch();

    const { actionData } = useSelector(selectUSStocksScreenActionData);

    const handleBackClick = () => {
        dispatch(setUsStocksViewAction({ curView: US_STOCK_SCREENS.FILE_ENTRIES }));
    };

    return (
        <React.Fragment>
            <SingleCaseHeading
                title={actionData.label}
                onBackArrowCTA={handleBackClick}
            />
            <div className='my-60'>
                {actionData.next_screen_type === SCREEN_TYPES.FILE_ENTRY && (
                    <GenerateFileEntry />
                )}
                {actionData.next_screen_type === SCREEN_TYPES.INPUT_SCREEN && (
                    <InputScreen />
                )}
                {actionData.next_screen_type === SCREEN_TYPES.ACKNOWLEDGE && (
                    <AcknowledgeFileEntry />
                )}
            </div>
        </React.Fragment>
    );
};

export default memo(ScreenAction);

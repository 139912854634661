/*
 *
 * Wealth Onboarding Actions
 *
 */

import {
    GET_ONBOARDING_INFO,
} from './constants';

export const getOnboardingInfo = data => ({
    type: GET_ONBOARDING_INFO,
    data
});

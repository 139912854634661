/**
 * @file VerificationInfo
 * Displays the employer details and transaction details for a particular verification case
 */

import React, { memo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { BackArrow, ExpandableCard, KVPairInfo } from '../../../components';

import ViewEmploymentDetails from '../ViewEmploymentDetails';
import { getVerificationCaseTxnHistoryAction } from '../actions';
import { selectSalaryVerificationCaseTxnHistory } from '../selectors';
import TransactionsHistory from './TransactionsHistory';

const concatProbabilityToName = (obj, nameKey, probabilityKey) => `${obj?.[nameKey] ? obj[nameKey] : ''} ${
    obj?.[probabilityKey]
        ? `(${obj[probabilityKey]})`
        : ''
}`;

const getTxnKVData = (caseDetails, employerDetails) => ([
    {
        label: 'Request ID',
        value: caseDetails.requestId,
    },
    {
        label: 'Txn ID',
        value: caseDetails.txnId,
    },
    {
        label: 'Remitter Name',
        value: concatProbabilityToName(employerDetails, 'remitterName', 'probabilityOfRemitterNameBeingMerchant'),
    },
    {
        label: 'PI Verified Name',
        value: concatProbabilityToName(employerDetails, 'piRemitterName', 'probabilityOfPIRemitterNameBeingMerchant'),
    },
    {
        label: 'UTR',
        value: caseDetails.utr,
    },
    {
        label: 'Payment Protocol',
        value: caseDetails.paymentProtocol,
    },
    {
        label: 'Particulars',
        value: caseDetails.particulars,
    },
    {
        label: 'Txn Timestamp',
        value: caseDetails.txnTime,
    },
    {
        label: 'Remitter PI ID',
        value: caseDetails.remitterId,
    },
    {
        label: 'Percentage Difference From Last Salary',
        value: caseDetails.percentageDiffFromLastSalaryString,
    },
    {
        label: 'Is amount divisible by 1000',
        value: caseDetails.isAmountDivisibleBy1000?.toString() || '',
    },
    {
        label: 'Remitter Actor Name',
        value: concatProbabilityToName(caseDetails, 'remitterActorName', 'probabilityOfRemitterActorNameBeingMerchant'),
    },
    {
        label: 'PI Account Name',
        value: concatProbabilityToName(caseDetails, 'piAccountName', 'probabilityOfPIAccountNameBeingMerchant'),
    },
]);

const getVerificationChecksKVData = (caseDetails) => ([
    {
        label: 'Verification Status',
        value: caseDetails.verificationStatus,
    },
    {
        label: 'Last Updated At',
        value: caseDetails.updateAt,
    },
]);

const getVerificationFailureReasonData = (caseDetails) => ([
    {
        label: 'verification Rejection Reason Category',
        value: caseDetails.verificationFailureReasonCategory,
    },
    {
        label: 'verification Rejection Reason Sub Category',
        value: caseDetails.verificationFailureReasonSubCategory,
    },
    {
        label: 'verification Remarks',
        value: caseDetails.verificationRemark,
    },
]);

const getVerificationChecksKVDataIfVerificationStatusRejected = (caseDetails) => (
    [...getVerificationChecksKVData(caseDetails), ...getVerificationFailureReasonData(caseDetails)]
);

const VerificationInfo = (props) => {
    const { caseDetails, employerDetails, handleBackClick } = props;
    const dispatch = useDispatch();

    const txnHistory = useSelector(selectSalaryVerificationCaseTxnHistory, shallowEqual);

    const [isCardExpanded, setIsCardExpanded] = useState(true);

    const [localEmployerDetails, setLocalEmployerDetails] = useState(employerDetails);

    useEffect(() => {
        if (employerDetails && localEmployerDetails !== employerDetails && employerDetails.unmaskedPhoneNumber) {
            dispatch(getVerificationCaseTxnHistoryAction({
                phoneNumber: employerDetails.unmaskedPhoneNumber,
            }));
            setLocalEmployerDetails(employerDetails);
        }
    }, [employerDetails]);

    return (
        <React.Fragment>
            <div className='frWrapper mb-60'>
                <BackArrow
                    handleClick={handleBackClick}
                />
                <div className='heading1'>Salary Verification Resolution</div>
            </div>
            <ViewEmploymentDetails
                employmentInfo={employerDetails}
            />
            <KVPairInfo
                title='Transaction Details'
                data={getTxnKVData(caseDetails, employerDetails)}
                extClasses={{
                    container: 'salops-kv__cr',
                    titleHeading: 'salops-kv__hd',
                    contentLabel: 'salops-kv__cl',
                    contentValue: 'salops-kv__cv',
                }}
            />
            {txnHistory && txnHistory.data && Array.isArray(txnHistory.data)
            && (
                <div>
                    <ExpandableCard
                        isExpanded={isCardExpanded}
                        setIsExpanded={() => setIsCardExpanded(!isCardExpanded)}
                        extClasses='salops-expandable__cont'
                        extHeadingClasses='salops-expandable-heading'
                        heading='Historical Verified Transactions'
                    >
                        {
                            txnHistory.data.map((datum, index) => (
                                <TransactionsHistory
                                    id={index + 1}
                                    data={datum}
                                    key={datum.txnId}
                                />
                            ))
                        }
                    </ExpandableCard>
                </div>
            )}
            <KVPairInfo
                title='Verification Info'
                data={caseDetails.verificationStatus === 'Rejected'
                    ? getVerificationChecksKVDataIfVerificationStatusRejected(caseDetails)
                    : getVerificationChecksKVData(caseDetails)}
                extClasses={{
                    container: 'salops-kv__cr',
                    titleHeading: 'salops-kv__hd',
                    contentLabel: 'salops-kv__cl',
                    contentValue: 'salops-kv__cv',
                }}
            />
        </React.Fragment>
    );
};

export default memo(VerificationInfo);

/**
 *
 * PictureGuideStep - Component to handle the Picture Guide Step type and and render the fecthed picture guide
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import HelpCircle from '../../../assets/images/svgs/help-circle.svg';
import { Button } from '../../../components';

import PictureGuide from '../../Scripts/PictureGuide';

import { CONDITION_TYPE } from '../constants';

const PictureGuideStep = (props) => {
    const { step, currentStep, getNextNode } = props;
    const {
        title, description, faq, userActionDescription, pictureGuide, nextNode,
    } = step || {};

    const { mappings } = nextNode;

    return (
        <React.Fragment>
            <div className='frcsbWrapper mt-15 mb-15'>
                <div>
                    <div className='sop-step-no'>Step {currentStep + 1}</div>
                    <div className='sop-step-title'>{title}</div>
                    <div className='sop-step-des'>{description}</div>
                </div>
                {
                    faq && (
                        <div className='sop-step-faq'>
                            <div className='frcWrapper'>
                                <img src={HelpCircle} alt='Help Circle' />
                                FAQs
                            </div>
                            <div>
                                {faq}
                            </div>
                        </div>
                    )
                }
            </div>
            <div className='mt-15 py-30'>
                <div className='sop-step-des pb-20'>{userActionDescription}</div>
                {/*
                    sendNotification && (
                        <Button
                            v2
                            primary
                            label='Send notification'
                            onClick={() => {}}
                        />
                    )
                */}
                <PictureGuide item={pictureGuide} />
                <Button
                    v2
                    primary
                    label='Next'
                    onClick={() => getNextNode(mappings?.default)}
                />
            </div>
        </React.Fragment>
    );
};

export const PictureGuideStepPropTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    faq: PropTypes.string,
    userActionDescription: PropTypes.string,
    sendNotification: PropTypes.string,
    nodeId: PropTypes.string,
    pictureGuide: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        guides: PropTypes.arrayOf(
            PropTypes.shape({
                instruction: PropTypes.string,
                url: PropTypes.string,
                tip: PropTypes.string,
                warning: PropTypes.string,
            }),
        ),
    }),
    nextNode: PropTypes.shape({
        next_step_condition_type: PropTypes.oneOf(Object.values(CONDITION_TYPE)),
        mappings: PropTypes.objectOf(
            PropTypes.string,
        ),
    }),
};

PictureGuideStep.propTypes = {
    step: PropTypes.shape(PictureGuideStepPropTypes).isRequired,
    currentStep: PropTypes.number.isRequired,
    getNextNode: PropTypes.func.isRequired,
};

export default PictureGuideStep;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */

/*
 * @file Case Search
 * Page to view the details of the Cases with the help of ActorId or CaseId
 */
import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';

import {
    Button, ExpandableCard, HorizontalIdNavbar, Loader, Table,
} from '../../components';
import { validators } from '../../utils';
import RadioButtonListV1 from '../../components/RadioButtonListV1';
import InputText from '../../components/InputText';

import { RISK_OPS_CASES_CASE_DETAILS_PATH } from '../App/routes';

import { selectCaseSearchDetails, selectCaseSearchError, selectCaseSearchLoader } from './selectors';
import { getCaseSearchAction } from './actions';
import { ID_TYPES_INFO, ID_TYPES_LIST } from './constants';
import './styles.scss';

const CaseSearch = () => {
    const dispatch = useDispatch();

    const loading = useSelector(selectCaseSearchLoader, shallowEqual);
    const caseSearchError = useSelector(selectCaseSearchError, shallowEqual);
    const tableData = useSelector(selectCaseSearchDetails, shallowEqual);

    const history = useHistory();
    const { search } = useLocation();

    const searchParams = React.useMemo(() => new URLSearchParams(search), [search]);
    const initialSearchState = {
        id: searchParams.get('id') || '',
        id_type: searchParams.get('id_type') || 'actorId',
    };

    const [searchState, setSearchState] = useState(initialSearchState);
    const [error, setError] = useState('');
    const [isSearchCardExpanded, setIsSearchCardExpanded] = useState(true);

    const makePayloadAndCallApi = (params = {}, callback = undefined) => {
        const {
            prev, next, actorId, caseId,
        } = params;

        const payload = {
            prevToken: prev,
            nextToken: next,
            actorId,
            caseId,
        };

        dispatch(getCaseSearchAction(payload, callback));
    };

    const getPaginatedTransactionQueue = ({ prev, next }) => {
        const id = searchParams.get('id');
        const id_type = searchParams.get('id_type');

        makePayloadAndCallApi({
            prev, next, [id_type]: id,
        });
    };

    const handleModeChange = (newValue) => {
        if (newValue !== searchState.id_type) {
            setSearchState((pre) => ({ ...pre, id_type: newValue, id: '' }));
            if (error)setError('');
        }
    };

    const handleOnChange = (value) => {
        setSearchState((pre) => ({ ...pre, id: value }));
        if (error)setError('');
    };

    const onSubmit = () => {
        const { id, id_type } = searchState;

        if (id_type === ID_TYPES_INFO.CASE_ID.value) { // enters if its a case id
            if (validators.isInputtedNumberValid(id)) { // validate for only number
                history.replace({ search: new URLSearchParams({ id, id_type }).toString() }); // updating the value in url as search params
                handleReviewClick({ case_id: id })(); // routing to case details page
            } else {
                setError('Case ID should be a valid numerical value.');
            }
        } else {
            makePayloadAndCallApi({
                [id_type]: id,
            }, () => {
                setIsSearchCardExpanded(false);
                history.replace({ search: new URLSearchParams({ id, id_type }).toString() });
            });
        }
    };

    const handleReviewClick = ({ case_id }) => () => {
        const ROUTE_PATH = RISK_OPS_CASES_CASE_DETAILS_PATH;
        history.push(ROUTE_PATH, { caseId: case_id });
    };

    const renderActions = (item) => (
        <div className='frfscWrapper'>
            <Button
                secondary
                label='Review'
                onClick={handleReviewClick(item)}
            />
        </div>
    );

    return (
        <React.Fragment>
            <HorizontalIdNavbar isFixed isEmptyNavbar />
            <div className='csearch app-cc-v1'>
                <ExpandableCard
                    extClasses='csearch-ec'
                    version={2}
                    heading='Search for Cases'
                    isExpanded={isSearchCardExpanded}
                    setIsExpanded={() => setIsSearchCardExpanded(!isSearchCardExpanded)}
                >
                    <div className='mb-30 csearch-px-20'>
                        <div className='frcWrapper'>
                            <div className='heading3 csearch-nowrap'>Select Id type</div>
                            <RadioButtonListV1
                                dataList={ID_TYPES_LIST}
                                listName='id_type'
                                selectedValue={searchState.id_type}
                                setSelectedData={handleModeChange}
                                extClasses={{
                                    container: 'of-auto radio-container',
                                    itemContainer: 'radio-container',
                                }}
                            />
                        </div>
                        <InputText
                            label={searchState.id_type === ID_TYPES_INFO.ACTOR_ID.value ? ID_TYPES_INFO.ACTOR_ID.label : ID_TYPES_INFO.CASE_ID.label}
                            input={{
                                value: searchState.id,
                                onChange: handleOnChange,
                                name: 'id',
                            }}
                        />
                        {error ? (<div className='container-err-label err-label mt-15'>{error}</div>) : null}
                        <Button
                            v2
                            primary
                            disabled={!searchState.id}
                            onClick={onSubmit}
                            label='Submit'
                            extClasses='b2b-btn mt-15 frccWrapper'
                        />
                    </div>
                </ExpandableCard>
                <div className='frcsbWrapper mt-30'>
                    <div className='heading1'>File Entries</div>
                </div>
                {
                    tableData
                        ? (
                            <Table
                                v2
                                enableScrollView
                                labelData={tableData.columnData}
                                contentData={tableData.rowData}
                                renderActionableColumn={renderActions}
                                prevToken={tableData.prevToken}
                                nextToken={tableData.nextToken}
                                paginatedClickHandler={getPaginatedTransactionQueue}
                                errLabel={caseSearchError}
                            />
                        )
                        : null
                }
            </div>
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default CaseSearch;

/**
 * @file EligibleTransactionsForEmisScreen: Component to show the eligible transactions for emis screen.
 */

import React, { useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';

import { Table, Loader } from '../../../components';
import { useMemoizedSelector, useScrollToPosition } from '../../../hooks';

import CardBanner from '../CardBanner';
import LoanOffersScreen from '../LoanOffersScreen';
import { getLoanOffersAction } from '../actions';
import { selectLoanOffers } from '../selectors';
import { ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS_LABEL_DATA, IS_ELIGIBLE_TRANSACTIONS_FOR_EMI_SCREEN, IS_LOAN_OFFERS_SCREEN } from '../constants';

const EligibleTransactionsForEmisScreen = (props) => {
    const {
        allEligibleTransactionsForEmisData, ticketId, userId, loader,
    } = props;

    useScrollToPosition();

    const dispatch = useDispatch();

    const loanOffersDetails = useMemoizedSelector(selectLoanOffers, [ticketId]);

    const {
        data, err, loanTenureValues, isLoanOffersScreen,
    } = loanOffersDetails || {};

    const previewEmiDetails = useCallback((item) => {
        const { externalTransactionId } = item || {};

        const payload = {
            externalTransactionId,
            ticketId,
            userId,
            [IS_LOAN_OFFERS_SCREEN]: true,
        };

        dispatch(getLoanOffersAction(payload));
    }, [ticketId, userId, dispatch]);

    if (isLoanOffersScreen) {
        return (
            <LoanOffersScreen
                data={data}
                loanTenureValues={loanTenureValues}
                ticketId={ticketId}
            />
        );
    }

    return (
        <CardBanner title='Credit Card' discardCurrentScreen={IS_ELIGIBLE_TRANSACTIONS_FOR_EMI_SCREEN} ticketId={ticketId}>
            <Table
                header='Eligible Transcations'
                labelData={ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS_LABEL_DATA}
                contentData={allEligibleTransactionsForEmisData}
                detailsColumnLabel='Preview Emi'
                handleDetailsClick={previewEmiDetails}
            />

            <Loader visible={loader} />

            {err ? <div className='error-label1 wspace-pre-wrap mt-30'>{err}</div> : null}
        </CardBanner>
    );
};

export default memo(EligibleTransactionsForEmisScreen);

/**
 * @file Constants for Transaction queue container
 */

import { RISK_OPS_TXN_CASE_DETAILS_PATH, RISK_OPS_USER_CASE_DETAILS_PATH } from '../App/routes';

// action & reducer constants
export const TRANSACTION_QUEUE_KEY = 'transactionQueue';

export const GET_TRANSACTION_QUEUE = `app/${TRANSACTION_QUEUE_KEY}/GET_TRANSACTION_QUEUE`;
export const SET_TRANSACTION_QUEUE = `app/${TRANSACTION_QUEUE_KEY}/SET_TRANSACTION_QUEUE`;
export const ERR_TRANSACTION_QUEUE = `app/${TRANSACTION_QUEUE_KEY}/ERR_TRANSACTION_QUEUE`;
export const RESET_TRANSACTION_QUEUE = `app/${TRANSACTION_QUEUE_KEY}/RESET_TRANSACTION_QUEUE`;

export const GET_TRANSACTION_QUEUE_FILTER = `app/${TRANSACTION_QUEUE_KEY}/GET_TRANSACTION_QUEUE_FILTER`;
export const SET_TRANSACTION_QUEUE_FILTER = `app/${TRANSACTION_QUEUE_KEY}/SET_TRANSACTION_QUEUE_FILTER`;
export const ERR_TRANSACTION_QUEUE_FILTER = `app/${TRANSACTION_QUEUE_KEY}/ERR_TRANSACTION_QUEUE_FILTER`;

export const GET_PRIORITIZED_CASE = `app/${TRANSACTION_QUEUE_KEY}/GET_PRIORITIZED_CASE`;
export const SET_PRIORITIZED_CASE = `app/${TRANSACTION_QUEUE_KEY}/SET_PRIORITIZED_CASE`;
export const ERR_PRIORITIZED_CASE = `app/${TRANSACTION_QUEUE_KEY}/ERR_PRIORITIZED_CASE`;

// UI constants
export const PARAMETER_FILTERS_CONFIG = {
    cardTitle: 'Filters',
    paramsTitle: 'Fields',
    defaultIsExpanded: false,
    collapseOnSubmit: true,
    warningModal: {
        required: false,
        message: 'Are you sure you want to perform this action?',
        description: 'This change is irreversible and affects production data',
    },
};

// review types
export const REVIEW_TYPE_USER_REVIEW = 'REVIEW_TYPE_USER_REVIEW';
export const REVIEW_TYPE_TRANSACTION_REVIEW = 'REVIEW_TYPE_TRANSACTION_REVIEW';
export const REVIEW_TYPE_AFU_REVIEW = 'REVIEW_TYPE_AFU_REVIEW';
export const REVIEW_TYPE_LEA_COMPLAINT_REVIEW = 'REVIEW_TYPE_LEA_COMPLAINT_REVIEW';
export const REVIEW_TYPE_ESCALATION_REVIEW = 'REVIEW_TYPE_ESCALATION_REVIEW';

// Default filter values for transaction queue

export const TransactionPageConfig = {
    userRisk: {
        label: 'userRisk',
        route: RISK_OPS_USER_CASE_DETAILS_PATH,
        priorityFilter: REVIEW_TYPE_USER_REVIEW,
    },
    riskTxnQueue: {
        label: 'riskTxnQueue',
        route: RISK_OPS_TXN_CASE_DETAILS_PATH,
        priorityFilter: REVIEW_TYPE_TRANSACTION_REVIEW,
    },
    escalations: {
        label: 'escalations',
        route: RISK_OPS_TXN_CASE_DETAILS_PATH,
        priorityFilter: REVIEW_TYPE_ESCALATION_REVIEW,
    },
};

export const getDefaultParamsValue = ({ listType }) => {
    let REVIEW_TYPE_VALUE = [];

    switch (listType) {
        case 'userRisk':
            REVIEW_TYPE_VALUE = [
                {
                    label: REVIEW_TYPE_USER_REVIEW,
                    value: REVIEW_TYPE_USER_REVIEW,
                },
                {
                    label: REVIEW_TYPE_AFU_REVIEW,
                    value: REVIEW_TYPE_AFU_REVIEW,
                },
            ];
            break;

        case 'riskTxnQueue':

            REVIEW_TYPE_VALUE = [
                {
                    label: REVIEW_TYPE_TRANSACTION_REVIEW,
                    value: REVIEW_TYPE_TRANSACTION_REVIEW,
                },
                {
                    label: REVIEW_TYPE_LEA_COMPLAINT_REVIEW,
                    value: REVIEW_TYPE_LEA_COMPLAINT_REVIEW,
                },
            ];
            break;
        case 'escalations':
            REVIEW_TYPE_VALUE = [
                {
                    label: REVIEW_TYPE_ESCALATION_REVIEW,
                    value: REVIEW_TYPE_ESCALATION_REVIEW,
                },
            ];
            break;
        default:
            break;
    }

    return [
        {
            id: 'review_type',
            name: 'review_type',
            value: REVIEW_TYPE_VALUE,
            isDisabled: true,
            type: 9,
        },
        {
            id: 'snooze_case',
            name: 'snooze_case',
            value: 'Not Snoozed',
            type: 5,
        },
    ];
};

/**
 * @file Actions for US stocks flow
 */

import {
    GET_ADDITIONAL_ACTIONS, GET_FILE_ENTRIES, GET_NAV_BAR_ENTITIES, GET_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY, GET_US_STOCKS_GENERATE_FILE,
    GET_US_STOCKS_TABLE_FILTERS, SET_US_STOCKS_FILE_TYPE, SET_US_STOCKS_SCREEN_ACTION_DATA, SET_US_STOCKS_VIEW_DATA,
} from './constants';

// API actions
export const getNavBarEntitiesAction = () => ({
    type: GET_NAV_BAR_ENTITIES,
});

export const getFileEntriesAction = (data) => ({
    type: GET_FILE_ENTRIES,
    data,
});

export const getAdditionalActionsAction = (data) => ({
    type: GET_ADDITIONAL_ACTIONS,
    data,
});

export const getUsStocksTableFiltersAction = (data) => ({
    type: GET_US_STOCKS_TABLE_FILTERS,
    data,
});

export const getUsStocksGenerateFileAction = (data) => ({
    type: GET_US_STOCKS_GENERATE_FILE,
    data,
});

export const getUsStocksAcknowledgeFileEntryAction = (data) => ({
    type: GET_US_STOCKS_ACKNOWLEDGE_FILE_ENTRY,
    data,
});

// UI actions
export const setUsStocksScreenDataAction = (data) => ({
    type: SET_US_STOCKS_SCREEN_ACTION_DATA,
    data,
});

export const setUsStocksViewAction = (data) => ({
    type: SET_US_STOCKS_VIEW_DATA,
    data,
});

export const setUsStocksFileTypeAction = (data) => ({
    type: SET_US_STOCKS_FILE_TYPE,
    data,
});

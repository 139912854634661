/*
 *
 * FederalAgents reducer
 *
 */

import { fromJS } from 'immutable';
import {
    GET_TICKET_LIST, SET_TICKET_LIST, ERR_TICKET_LIST, GET_TICKET_INFO, SET_TICKET_INFO,
    ERR_TICKET_INFO, SET_TICKET_GROUPS, UPDATE_TICKET_GROUP, SET_UPDATE_TICKET_GROUP_FLAG,
    TICKET_GROUPS,
} from './constants';

const initialState = fromJS({
    loading: false,
    err: {
        ticketList: '',
        ticketNotes: '',
        updateTicketGroup: '', 
    },
    ticketList: {
        data: [],
        prev: {},
        next: {},
    },
    ticketInfo: {},
    ticketGroups: TICKET_GROUPS, // keep value incase BE call for fetching ticket group fails
});

function federalAgentsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TICKET_LIST:
            return state
                .set('loading', true)
                .set('ticketInfo', fromJS({}));

        case SET_TICKET_LIST: {
            const { ticketList, prev, next } = action.data;

            return state
                .set('loading', false)
                .setIn(['ticketList', 'data'], fromJS(ticketList))
                .setIn(['ticketList', 'prev'], fromJS(prev))
                .setIn(['ticketList', 'next'], fromJS(next));
        }

        case ERR_TICKET_LIST: {
            const { ticketList } = initialState.toJS();

            return state
                .set('loading', false)
                .set('ticketList', fromJS(ticketList));
        }

        case GET_TICKET_INFO:
            return state.set('loading', true);

        case SET_TICKET_INFO: {
            return state
                .set('loading', false)
                .set('ticketInfo', fromJS(action.data));
        }

        case ERR_TICKET_INFO:
            return state
                .set('loading', false)
                .set('ticketInfo', fromJS({}));

        case SET_TICKET_GROUPS: {
            const { groups } = action.data;

            return state.set('ticketGroups', fromJS(groups));
        }

        case UPDATE_TICKET_GROUP:
            return state.set('loading', true);

        case SET_UPDATE_TICKET_GROUP_FLAG: {
            const { flag } = action.data;

            return state.set('loading', flag);
        }

        default:
            return state;
    }
}

export default federalAgentsReducer;

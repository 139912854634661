/**
*
* ShowInfo
*
*/

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { INFO_TYPES } from './constants';

import './style.scss';

const ShowInfo = props => {
    const { data } = props;

    const renderRow = (field) => {
        return (
            <div key={field.label} className='row-container'>
                <div className='label'>{field.label + ':'}</div>
                <div className='value'>{field.value}</div>
            </div>
        );
    };

    const renderField = (field) => {
        switch (field.type) {
            case INFO_TYPES.ROW:
                return renderRow(field);
            default:
                return null;
        }
    };

    return (
        <div className='show-info-container'>
            {data.map((field) => (renderField(field)))}
        </div>
    );
};

ShowInfo.propTypes = {
    data: PropTypes.array,
};

export default memo(ShowInfo);

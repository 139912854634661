/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

/**
 *
 * SideNavbar for Tickets flow
 *
 */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { UserDetailsContext, MetaInfoContext } from '../../../context';
import { ICONS_URL } from '../../../constants/assets';
// import arrowIcon from '../../assets/images/svgs/arrow.svg';

import { menuStructure } from './utils';

import './style.scss';
import LockHighSensitive from '../../../assets/images/svgs/auth-lock-high-sensitivity.svg';
import LockModerateSensitive from '../../../assets/images/svgs/auth-lock-moderate-sensitivity.svg';
import LockLessSensitive from '../../../assets/images/svgs/auth-lock-less-sensitivity.svg';
import LockInSensitive from '../../../assets/images/svgs/auth-lock-insensitive-sensitivity.svg';
import UnLockHighSensitive from '../../../assets/images/svgs/auth-unlock-high-sensitivity.svg';
import UnLockModerateSensitive from '../../../assets/images/svgs/auth-unlock-moderate-sensitivity.svg';
import UnLockLessSensitive from '../../../assets/images/svgs/auth-unlock-less-sensitivity.svg';
import UnLockInSensitive from '../../../assets/images/svgs/auth-unlock-insensitive-sensitivity.svg';
import { hasAccessToThePage } from '../../../utils/helpers';

import { RudderEvent, pushRudderEvent } from '../../../rudderEvents';

import { makeSelectAgentInfo } from '../../AppV2/selectors';

import { SCREEN } from '../constants';

const mapStateToProps = (state) => ({
    agentInfo: makeSelectAgentInfo()(state),
});

// map of Sensitivity level to svg icon for the lock symbol.
// contains two maps with keys lock & unlock so that it can be easily accessed while generating svg image src.
const LOCK_SYMBOL_SVG_ICONS = {
    lock: {
        [SCREEN.HIGHLY_SENSITIVE_SCREEN]: LockHighSensitive,
        [SCREEN.MODERATELY_SENSITIVE_SCREEN]: LockModerateSensitive,
        [SCREEN.LESS_SENSITIVE_SCREEN]: LockLessSensitive,
        [SCREEN.INSENSITIVE_SCREEN]: LockInSensitive,
    },
    unlock: {
        [SCREEN.HIGHLY_SENSITIVE_SCREEN]: UnLockHighSensitive,
        [SCREEN.MODERATELY_SENSITIVE_SCREEN]: UnLockModerateSensitive,
        [SCREEN.LESS_SENSITIVE_SCREEN]: UnLockLessSensitive,
        [SCREEN.INSENSITIVE_SCREEN]: UnLockInSensitive,
    },
};

class SideNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ticketId: props.ticketId,
            activeId: '',
            expandedIds: ['banking'], // by default, banking profile tab gets opened
        };
    }

    static getDerivedStateFromProps(props, state) {
        /**
         * anytime the ticket tab changes,
         * reset any state linked to prev state
         */
        if (props.ticketId !== state.ticketId) {
            let activeId = '';
            if (props.ticketId) {
                activeId = 'ba-profile';
            }

            return {
                ticketId: props.ticketId,
                activeId,
                expandedIds: ['banking'],
                activeTicket: props.activeTicket,
            };
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        const { location, ticketId } = this.props;
        const isTicketBankingProfile = location.pathname === `/ticket/${ticketId}/banking/profile`;

        // Check if the location pathname has changed
        if (prevProps.location.pathname !== location.pathname) {
            // Check if the current pathname matches the desired path
            if (isTicketBankingProfile) {
                // If yes, set the activeId to 'ba-profile'
                this.setState({ activeId: 'ba-profile' });
            }
        }
    }

    setActiveTab = (id, label) => () => {
        const { agentInfo } = this.props;
        const { ticketId } = this.state;
        const rudderProperties = { tabName: label, ticketId };
        pushRudderEvent(RudderEvent.SherlockV1Agent.AgentClickSherlockSidebarOption, agentInfo.emailId, rudderProperties);
        this.setState({ activeId: id });
    };

    setExpandedIds = (id) => () => {
        this.setState((prevState) => {
            const { expandedIds } = prevState;
            let newExpandedIds;

            if (expandedIds.includes(id)) {
                newExpandedIds = expandedIds.filter((item) => item !== id);
            } else {
                newExpandedIds = [...expandedIds, id];
            }

            return {
                expandedIds: newExpandedIds,
            };
        });
    };

    renderMenuItem = (item) => {
        if (item.hidden) {
            return null;
        }

        const { activeId } = this.state;

        const getImageLockIconClasses = (hasSubMenu) => (classNames({
            'show-lock-symbol': true,
            'lock-inner': hasSubMenu,
        }));

        const getMenuWrapperClasses = (itemId) => (classNames({
            frcWrapper: true,
            'menu-wrapper': true,
            'menu-wrapper--active': !item.isExternal && activeId === itemId,
        }));

        const getSidebarBorderClasses = (itemId) => (classNames({
            'sidebar-border': true,
            'sidebar-border--active': !item.isExternal && activeId === itemId,
        }));

        /**
         * @param linkItem the link item from MenuStructure object
         * @param activeTicket the activeTicket information
         * @returns {*} the svg url for the lock icon based on whether access is enabled/locked for a specific screen.
         */
        const getImageLockSrc = (linkItem, activeTicket) => {
            const authAccessSVGMap = hasAccessToThePage(activeTicket, linkItem.informationLevel)
                ? LOCK_SYMBOL_SVG_ICONS.unlock : LOCK_SYMBOL_SVG_ICONS.lock;
            return authAccessSVGMap[linkItem.informationLevel];
        };

        // eslint-disable-next-line no-shadow
        const renderSubLink = (item, isNestedSubLinks) => {
            const { expandedIds } = this.state;
            const isItemActive = expandedIds.includes(item.id);

            const labelClasses = classNames('menu-item menu-item--category', {
                'menu-item--sub-category': isNestedSubLinks,
            });

            return (
                <React.Fragment key={item.label}>
                    <div className={labelClasses} onClick={this.setExpandedIds(item.id)}>
                        <span>{item.label}</span>
                        <img className={isItemActive ? 'expanded-arrow-icon' : 'collapsed-arrow-icon'} src={ICONS_URL.ARROW} alt='arrow-icon' />
                    </div>
                    {
                        isItemActive ? (
                            item.subLinks
                                .filter((subLinkItem) => !subLinkItem.hidden)
                                .map((subLinkItem) => {
                                    if (subLinkItem.subLinks) {
                                        return renderSubLink(subLinkItem, true);
                                    }

                                    const { activeTicket } = this.props;
                                    const subitemLinkContent = (
                                        <div className={getMenuWrapperClasses(subLinkItem.id)} style={{ position: 'relative' }}>
                                            {/* <div className={getSidebarBorderClasses(subLinkItem.id)} /> */}
                                            {
                                                subLinkItem.informationLevel
                                                && (
                                                    <img
                                                        className={getImageLockIconClasses(isNestedSubLinks)}
                                                        src={getImageLockSrc(subLinkItem, activeTicket)}
                                                        alt='lock-icon'
                                                        height='20px'
                                                        width='20px'
                                                    />
                                                )
                                            }

                                            <div
                                                className={classNames({
                                                    'menu-item': true,
                                                    'submenu-item': true,
                                                    'submenu-item--nested': isNestedSubLinks,
                                                    'submenu-item--active': !item.isExternal && activeId === subLinkItem.id,
                                                    'submenu-item--disabled': subLinkItem.disable,
                                                })}
                                                onClick={!subLinkItem.disable && this.setActiveTab(subLinkItem.id, subLinkItem.label)}
                                            >
                                                {subLinkItem.label}
                                            </div>
                                        </div>
                                    );

                                    return (
                                        subLinkItem.disable ? (
                                            subitemLinkContent
                                        ) : (
                                            <Link to={subLinkItem.path} key={subLinkItem.path}>
                                                {subitemLinkContent}
                                            </Link>
                                        )
                                    );
                                })
                        ) : null
                    }
                </React.Fragment>
            );
        };

        if (item.subLinks) {
            return renderSubLink(item);
        }

        const menuItemClasses = classNames({
            'menu-item': true,
            'menu-item--active': !item.isExternal && activeId === item.id,
            'menu-item--disabled': item.disable,
        });

        const itemLinkContent = (
            <div className={getMenuWrapperClasses(item.id)}>
                <div className={getSidebarBorderClasses(item.id)} />
                <div className={menuItemClasses} onClick={!item.disable && this.setActiveTab(item.id, item.label)}>
                    <span>{item.label}</span>
                </div>
            </div>
        );

        return (
            // eslint-disable-next-line no-nested-ternary
            item.disable ? (
                itemLinkContent
            ) : (
                item.isExternal
                    ? (
                        <Link to={{ pathname: item.path }} key={item.label} target='_blank'>
                            {itemLinkContent}
                        </Link>
                    )
                    : (
                        <Link to={item.path} key={item.label}>
                            {itemLinkContent}
                        </Link>
                    )
            )
        );
    };

    render() {
        const { ticketId } = this.state;
        // const { isAuthorizedForLessSensitiveScreen } = this.props;

        return (
            <MetaInfoContext.Consumer>
                {(
                    {
                        componentVersions: {
                            banners,
                            recentActivityVersion,
                            autoPayVersion,
                            scriptsSearchVersion,
                        },
                        pageVersions: {
                            sendNotifications: sendNotificationsVersion,
                        },
                    },
                ) => (
                    <UserDetailsContext.Consumer>
                        {({ basicInfo }) => (
                            <div className={classNames({
                                'menu-container': banners === 1,
                                'menu-container-v2': banners === 2,
                            })}
                            >
                                {
                                    ticketId ? (
                                        menuStructure({
                                            ticketId,
                                            basicInfo,
                                            recentActivityVersion,
                                            sendNotificationsVersion,
                                            autoPayVersion,
                                            scriptsSearchVersion,
                                        }).map(this.renderMenuItem)
                                    ) : null
                                }
                            </div>
                        )}
                    </UserDetailsContext.Consumer>
                )}
            </MetaInfoContext.Consumer>
        );
    }
}

export default connect(mapStateToProps)(withRouter(SideNavbar));

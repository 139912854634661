/**
 * @file Actions for Developer
 */
import { SET_IDENTIFIER_LIST, SET_ACTIVE_TAB_INDEX, SET_NEW_TAB_ACTIVE } from './constants';

export const setIdentifierList = (data) => ({
    type: SET_IDENTIFIER_LIST,
    data,
});

export const setActiveTabIndex = (data) => ({
    type: SET_ACTIVE_TAB_INDEX,
    data,
});

export const setNewTabActive = (data) => ({
    type: SET_NEW_TAB_ACTIVE,
    data,
});

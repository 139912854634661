import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import Modal from 'react-modal';

import * as serviceWorker from './serviceWorker';
import store from './configureStore';
import { history } from './reducers';

import './stylesheets/index.scss';
import './stylesheets/toast.scss';
import './stylesheets/table.scss';
import './stylesheets/dropdown.scss';

import rootSaga from './sagas';
import AppGlobal from './appGlobal';

store.runSaga(rootSaga);

const MOUNT_NODE = document.getElementById('root');

/**
 * required for screen readers users that other page content be hidden
 * (via the aria-hidden attribute) while the modal is open
 * Learn more: http://reactcommunity.org/react-modal/accessibility/
 */
Modal.setAppElement(MOUNT_NODE);

const render = () => {
    createRoot(MOUNT_NODE).render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <AppGlobal />
            </ConnectedRouter>
        </Provider>,
    );
};

render();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const renderOnboardingCurrentStageInfo = (onboardingInfo) => [
    {
        label: 'Onboarding Stage',
        data: onboardingInfo.currentStage,
    },
];

const renderOnboardingCurrentStageTroubleshootInfo = (onboardingInfo) => [
    {
        label: 'Current Stage',
        value: onboardingInfo.stage,
    },
    {
        label: 'Status',
        value: onboardingInfo.state,
    },
    /*
    {
        label: 'Updated At',
        value: onboardingInfo.updatedTs,
    },
    */
    {
        label: 'RCA L1',
        value: onboardingInfo.level1,
    },
    {
        label: 'RCA L2',
        value: onboardingInfo.level2,
    },
    {
        label: 'RCA L3',
        value: onboardingInfo.level3,
    },
    {
        label: 'Troubleshooting Advice',
        value: onboardingInfo.advice,
    },
    {
        label: 'Additional Details',
        value: onboardingInfo.debugInfo,
        format: 'json',
    },
];

export {
    renderOnboardingCurrentStageInfo,
    renderOnboardingCurrentStageTroubleshootInfo,
};

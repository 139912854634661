/*
 *
 * FacematchKYCImage reducer
 *
 */

import { fromJS } from 'immutable';
import {
    GET_FM_KYC_IMAGE, SET_FM_KYC_IMAGE, ERR_FM_KYC_IMAGE,
    SET_MONORAIL_ID,
} from './constants';

const initialState = fromJS({
    monorailInfo: {
        id: '',
    },
    loading: false,
    err: '',
    imageInfo: {
        url: '',
    },
});

function facematchKYCImageReducer(state = initialState, action) {
    switch (action.type) {
        case GET_FM_KYC_IMAGE:
            return state
                .set('loading', true)
                .set('err', '')
                .setIn(['imageInfo', 'url'], '');

        case SET_FM_KYC_IMAGE: {
            const { imageSrc } = action.data;

            return state
                .set('loading', false)
                .setIn(['imageInfo', 'url'], imageSrc);
        }

        case ERR_FM_KYC_IMAGE: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err);
        }

        case SET_MONORAIL_ID: {
            const { value } = action.data;

            if (value) {
                return state.setIn(['monorailInfo', 'id'], value);
            }

            return initialState;
        }

        default:
            return state;
    }
}

export default facematchKYCImageReducer;

import { createSelector } from 'reselect';

const selectKycOnboarding = (state) => state.get('kycOnboarding');

const makeSelectKycDetails = () => createSelector(
    selectKycOnboarding,
    (substate) => substate?.get('kyc')?.toJS(),
);

const makeSelectOnboardingDetails = () => createSelector(
    selectKycOnboarding,
    (substate) => substate?.get('onboarding')?.toJS(),
);

const makeSelectVKycDetails = () => createSelector(
    selectKycOnboarding,
    (substate) => substate?.get('vkyc')?.toJS(),
);

export {
    makeSelectKycDetails,
    makeSelectOnboardingDetails,
    makeSelectVKycDetails,
};

/**
 * @file Manages the constants for Autopay component
 */

// key to be used for reducers & selectors for autopay
export const AUTOPAY_KEY = 'autopay';

// autopay details of user
export const GET_EXISTING_MANDATE_DETAILS = `app/${AUTOPAY_KEY}/GET_EXISTING_MANDATE_DETAILS`;
export const SET_EXISTING_MANDATE_DETAILS = `app/${AUTOPAY_KEY}/SET_EXISTING_MANDATE_DETAILS`;
export const ERR_EXISTING_MANDATE_DETAILS = `app/${AUTOPAY_KEY}/ERR_EXISTING_MANDATE_DETAILS`;

export const GET_UPCOMING_MANDATE_DETAILS = `app/${AUTOPAY_KEY}/GET_UPCOMING_MANDATE_DETAILS`;
export const SET_UPCOMING_MANDATE_DETAILS = `app/${AUTOPAY_KEY}/SET_UPCOMING_MANDATE_DETAILS`;
export const ERR_UPCOMING_MANDATE_DETAILS = `app/${AUTOPAY_KEY}/ERR_UPCOMING_MANDATE_DETAILS`;

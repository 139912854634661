// The above comment must be removed while pushing the code to production

import {
    all, call, cancel, fork, put, take, takeLatest,
} from 'redux-saga/effects';
import { END, eventChannel } from 'redux-saga';
import {
    clientApiWrapper, getErrLabel, getErrMsg, getQueryStringFromObject, toastify,
} from '../../utils';
import {
    CAPTURE_SCREENSHOT, CONCLUDE_CALL, END_CALL_ENDPOINT, EXTRACT_DOCUMENT_DATA,
    GENERATE_REPORT_ENDPOINT, INITIATE_CALL,
    MATCH_SCORE_ENDPOINT, ONBOARDING_STAGES, PERFORM_CLIENT_ACTION, RECORDING_UPLOAD_ENDPOINT,
    GET_AVAILABLE_CALLS_COUNT,
} from '../../api/routes';
import {
    CAMERA_ORIENTATION, CLIENT_ACTION, GET_AVAILABLE_CALLS,
    GET_CAPTURE_SCREENSHOT, GET_CONCLUDE_VKYC_CALL, GET_END_VKYC_CALL, GET_EXTRACT_DOCUMENT_DETAILS,
    GET_GENERATE_VKYC_REPORT, GET_INITIATE_VKYC_CALL, GET_MATCH_SCORE, GET_ONBOARDING_STAGES, GET_PERFORM_CLIENT_ACTION,
    UPLOAD_SCREEN_RECORDING, GET_VKYC_AVAILABLE_CALLS_COUNT, SET_VKYC_AVAILABLE_CALL_COUNT,
    FLOW_PROGRESS,
} from './constants';
import {
    errAvailableCalls, errEndVKYCCall,
    errGenerateVKYCReport, errInitiateCall, errMatchScore, errOnboardingStages,
    errPerformClientAction, errUploadScreenRecording, setAvailableCalls, setCaptureScreenshot,
    setGenerateVKYCReport, setInitiateCall,
    setMatchScore,
    setOnboardingStages,
    setPerformClientAction,
    setTitle,
    setUploadScreenRecording,
    setVKYCCameraOrientation,
    setLoaderState,
    setRejectionReasons,
    setCurrentStep,
} from './actions';
import { WebSocketService } from '../../utils/webSocket';
import { fetchFile } from './utils/fileStore';
import { getS3UploadUrl } from '../../utils/downloadFile';

const uploadKycContent = async (meetingId, s3Url) => {
    const file = fetchFile(meetingId);
    const formData = new FormData();
    formData.append('files', file);
    await fetch(getS3UploadUrl(s3Url), {
        method: 'PUT',
        body: file,
        headers: {
            ContentType: 'video/mp4',
        },
    });
};

export const WS_URL = `ws://${window.location.host}`;

function createWebSocketConnection(url, emailId) {
    return eventChannel((emitter) => {
        const webSocketService = new WebSocketService(url);
        webSocketService.connect();

        webSocketService.onMessage((data) => {
            emitter({ type: 'message', data });
        });

        webSocketService.socket.onerror = (error) => {
            emitter({ type: 'error', error });
        };

        webSocketService.socket.onclose = () => {
            emitter({ type: 'close' });
            emitter(END);
        };

        // Send the auth-factor message with emailId and ticketId after connecting
        webSocketService.socket.onopen = () => {
            // Request available calls
            webSocketService.sendMessage({
                type: 'getAvailableCall',
                data: { emailId },
            });
        };

        // The subscriber must return an unsubscribe function
        return () => {
            webSocketService.disconnect();
        };
    });
}

function* handleWebSocketMessages(channel) {
    try {
        while (true) {
            const { type, data, error } = yield take(channel);

            if (type === 'message') {
                yield put(setAvailableCalls({ key: 'data', value: data }));
            } else if (type === 'error') {
                yield put(errAvailableCalls({ err: error }));
            } else if (type === 'close') {
                break;
            }
        }
    } finally {
        channel.close();
    }
}

function* getAvailableCallsSaga(action) {
    const { emailId } = action.data;

    const wsChannel = yield call(createWebSocketConnection, WS_URL, emailId);

    // Fork the handler so it runs in parallel
    const task = yield fork(handleWebSocketMessages, wsChannel);

    // Wait for the GET_AVAILABLE_CALLS action to be cancelled
    // yield take(ERR_AVAILABLE_CALLS);

    // Cancel the task if the action is cancelled
    yield cancel(task);

    wsChannel.close();
}

function* getInitiateCall(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject({ ticketId });

    try {
        yield put(setLoaderState(true));
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${INITIATE_CALL}?${queryString}`,
        );
        yield put(setInitiateCall({ key: 'data', value: response }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errInitiateCall({ err: getErrMsg(e) }));
        yield put(setCurrentStep({ progress: FLOW_PROGRESS.END_CALL_FAILED }));
    }
}

function* getConcludeCall(action) {
    const {
        id, Data, meetingId, s3Url,
    } = action?.data || {};
    const requestBody = {
        id,
        Data,
    };

    try {
        yield put(setLoaderState(true));
        if (s3Url) yield call(uploadKycContent, meetingId, s3Url);
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            CONCLUDE_CALL,
            requestBody,
        );
        yield put(setCurrentStep({ progress: FLOW_PROGRESS.END_CALL_SUCCESS }));
        yield put(setInitiateCall({ key: 'data', value: { meetingId: null, recordingUploadURL: '' } }));
        yield put(setTitle({ title: 'VKYC Flow' }));
        yield put(setLoaderState(false));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(`${err} please try again and if unable to proceed then reach out admin`, 'error');
        yield put(setLoaderState(false));
    }
}

function* captureScreenshot(action) {
    const { meetingId, screenKey } = action.data;
    const queryString = getQueryStringFromObject({ id: meetingId });

    try {
        yield put(setLoaderState(true));
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CAPTURE_SCREENSHOT}?${queryString}`,
        );
        yield put(setCaptureScreenshot({ screenKey, value: response }));
        yield put(setLoaderState(false));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(setLoaderState(false));
    }
}

function* performClientAction(action) {
    const { meetingId, clientAction, currentOrientation } = action.data;
    const queryString = getQueryStringFromObject({ meetingId, clientAction });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PERFORM_CLIENT_ACTION}?${queryString}`,
        );
        yield put(setPerformClientAction({ screenKey: 'action', value: response }));
        if (clientAction === CLIENT_ACTION.Flip) {
            let orientation;
            if (currentOrientation === CAMERA_ORIENTATION.back) {
                orientation = CAMERA_ORIENTATION.front;
            } else {
                orientation = CAMERA_ORIENTATION.back;
            }
            yield put(setVKYCCameraOrientation({ screenKey: ['meetings', meetingId], value: { cameraOrientation: orientation } }));
        }
        toastify('Action performed successfully', 'success');
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errPerformClientAction({ screenKey: 'action', err: getErrMsg(e) }));
    }
}

function* getStages(action) {
    const { meetingId } = action.data;
    const queryString = getQueryStringFromObject({ meetingId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ONBOARDING_STAGES}?${queryString}`,
        );
        response.stepNumber = 0;
        response.subStepNumber = 0;
        response.cameraOrientation = CAMERA_ORIENTATION.front;
        yield put(setOnboardingStages({ screenKey: ['meetings', meetingId], value: response }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errOnboardingStages({ screenKey: ['meetings', meetingId], err: getErrMsg(e) }));
    }
}

function* getMatchScore(action) {
    const {
        screenKey,
    } = action.data;
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${MATCH_SCORE_ENDPOINT}`,
            action.data,
        );
        yield put(setMatchScore({ screenKey, value: response }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errMatchScore({ screenKey, err: getErrMsg(e) }));
    }
}

function* extractDetailsFromDocument(action) {
    const {
        documentType, imageUrl, frontImageIdentifier, backImageIdentifier, extractFaceImage, screenKey, meetingId,
    } = action.data;
    const queryString = getQueryStringFromObject({
        documentType, imageUrl, frontImageIdentifier, backImageIdentifier, extractFaceImage, meetingId,
    });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${EXTRACT_DOCUMENT_DATA}?${queryString}`,
        );
        yield put(setOnboardingStages({ screenKey, value: response }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errOnboardingStages({ screenKey, err: getErrMsg(e) }));
    }
}

function* uploadScreenRecord(action) {
    const { formData, meetingId } = action.data;
    try {
        const response = yield call([clientApiWrapper, clientApiWrapper.fileUploadByFormData], 'PUT', `${RECORDING_UPLOAD_ENDPOINT}`, formData, {});

        yield put(setUploadScreenRecording({ screenKey: ['meetings', meetingId, 'recording'], value: response }));
    } catch (error) {
        const err = getErrLabel(error);
        toastify(err, 'error');
        yield put(errUploadScreenRecording({ screenKey: ['meetings', meetingId, 'recording'], err: getErrMsg(err) }));
    }
}

function* getEndVKYCCall(action) {
    const {
        meetingId, isAccepted, screenKey, flowStatus, s3Url, Data, rejectedReason, remarks,
    } = action.data;

    const requestBody = { meetingId, isAccepted };

    try {
        yield put(setLoaderState(true));
        if (s3Url) yield call(uploadKycContent, meetingId, s3Url);

        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            END_CALL_ENDPOINT,
            requestBody,
        );

        // this conclude api will be called for force upload only.

        if (Data) {
            yield put({
                type: GET_CONCLUDE_VKYC_CALL,
                data: {
                    id: meetingId,
                    Data: {
                        ...Data,
                        rejectedReason,
                        remarks,
                    },

                },
            });
        }

        if (flowStatus) yield put(setCurrentStep({ progress: flowStatus }));
        yield put(setLoaderState(false));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errEndVKYCCall({ screenKey, err: getErrMsg(e) }));
        yield put(setLoaderState(false));
    }
}

function* getGenerateVKYCReport(action) {
    const { questionAnswers, meetingId, screenKey } = action.data;
    const requestBody = { meetingId, questionAnswers };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            GENERATE_REPORT_ENDPOINT,
            requestBody,
        );
        yield put(setGenerateVKYCReport({ screenKey, value: response }));
        yield put(setRejectionReasons(response?.rejection_categories));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errGenerateVKYCReport({ screenKey, err: getErrMsg(e) }));
    }
}

function* getAvailableCallsCount() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            GET_AVAILABLE_CALLS_COUNT,
        );
        yield put({ type: SET_VKYC_AVAILABLE_CALL_COUNT, data: response?.waiting_calls });
    } catch (e) {
        yield put({ type: SET_VKYC_AVAILABLE_CALL_COUNT, data: 0 });
    }
}

export default function* ScriptsSaga() {
    yield all(
        [
            yield takeLatest(GET_AVAILABLE_CALLS, getAvailableCallsSaga),
            yield takeLatest(GET_INITIATE_VKYC_CALL, getInitiateCall),
            yield takeLatest(GET_CONCLUDE_VKYC_CALL, getConcludeCall),
            yield takeLatest(GET_CAPTURE_SCREENSHOT, captureScreenshot),
            yield takeLatest(GET_PERFORM_CLIENT_ACTION, performClientAction),
            yield takeLatest(GET_ONBOARDING_STAGES, getStages),
            yield takeLatest(UPLOAD_SCREEN_RECORDING, uploadScreenRecord),
            yield takeLatest(GET_EXTRACT_DOCUMENT_DETAILS, extractDetailsFromDocument),
            yield takeLatest(GET_MATCH_SCORE, getMatchScore),
            yield takeLatest(GET_END_VKYC_CALL, getEndVKYCCall),
            yield takeLatest(GET_GENERATE_VKYC_REPORT, getGenerateVKYCReport),
            yield takeLatest(GET_VKYC_AVAILABLE_CALLS_COUNT, getAvailableCallsCount),
        ],
    );
}

/**
 *
 * VerticalNavbar
 *
 */

import React, { memo, useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { MetaInfoContext } from '../../context';

import { ICONS_URL } from '../../constants/assets';
// import chevronIcon from '../../assets/images/svgs/chevron-bottom.svg';
import cheveronArrowRightIcon from '../../assets/images/svgs/arrow-chevron-right.svg';

import './style.scss';

const VerticalNavbar = (props) => {
    const { pathname: initialPath } = useLocation();

    const {
        navItems, hideHorizontalNavbar, enableExpandableNav = false, isReplaceLink = false,
    } = props;

    // local state variables
    const [activeId, setActiveId] = useState('');
    const [expandedIds, setExpandedIds] = useState([]);
    const [expandNav, setExpandNav] = useState(true);

    const filteredNavItems = navItems.filter((item) => !item.hidden);

    const metaInfo = useContext(MetaInfoContext);

    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;

    let selectedItem;
    let selectedNestedItem;

    const getActiveNavItem = () => {
        for (let i = 0; i < filteredNavItems.length; i += 1) {
            const item = filteredNavItems[i];
            selectedItem = '';
            selectedNestedItem = '';

            if (item.nestedLinks) {
                selectedNestedItem = item.nestedLinks.find((nestedItem) => initialPath.includes(nestedItem.path));

                if (selectedNestedItem) {
                    selectedItem = item;
                }
            } else if (initialPath.includes(item.path)) {
                selectedItem = item;
            }

            if (selectedItem || selectedNestedItem) {
                break;
            }
        }

        return selectedNestedItem || selectedItem || {};
    };

    // set it with an empty object when there is a root developer route
    const activeNavItem = getActiveNavItem();

    // change active id whenever the active nav item gets changed
    useEffect(() => {
        if (activeNavItem.id) {
            setActiveId(activeNavItem.id);

            if (selectedNestedItem) {
                setExpandedIds([selectedItem.id]);
            }
        }
    }, [activeNavItem.id]);

    const toggleExpandableNavItem = (id) => () => {
        let newExpandedIds;

        if (expandedIds.includes(id)) {
            newExpandedIds = expandedIds.filter((item) => item !== id);
        } else {
            newExpandedIds = [...expandedIds, id];
        }

        setExpandedIds(newExpandedIds);
    };

    const renderItemContent = (item, isNestedItem) => {
        const navItemClasses = classNames({
            'vnb-wr__itm': true,
            'vnb-wr__itm--active': activeId === item.id,
            'vnb-wr__itm--disabled': item.disable,
            'vnb-wr__sub-itm': isNestedItem,
        });

        return (
            <Link
                key={item.label}
                to={item.path}
                disabled={item.disable}
                replace={isReplaceLink}
                className={navItemClasses}
                onClick={() => {
                    if (!item.disable) {
                        setActiveId(item.id);
                    }
                }}
            >
                {item.label}
            </Link>
        );
    };

    const renderNavItem = (item) => {
        if (item.nestedLinks) {
            const isNestedNavItemActive = expandedIds.includes(item.id);
            const nestedNavItems = item.nestedLinks.filter((nestedItem) => !nestedItem.hidden);

            const chevronIconClasses = classNames({
                'vnb-collapsed-chevron': isNestedNavItemActive,
                'vnb-expanded-chevron': !isNestedNavItemActive,
            });

            return (
                <React.Fragment key={item.id}>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <div className='vnb-wr__itm' onClick={toggleExpandableNavItem(item.id)}>
                        <span>{item.label}</span>
                        <img
                            className={chevronIconClasses}
                            src={ICONS_URL.CHEVRON_BOTTOM}
                            alt='chevron-icon'
                        />
                    </div>
                    {
                        isNestedNavItemActive ? (
                            nestedNavItems.map((nestedItem) => renderItemContent(nestedItem, true))
                        ) : null
                    }
                </React.Fragment>
            );
        }

        return renderItemContent(item);
    };

    const toggleExpandableNav = () => {
        setExpandNav(!expandNav);
    };

    const containerClasses = classNames({
        'vnb-cr': bannersVersion === 1,
        'vnb-cr-v2': bannersVersion === 2,
        'vnb-cr--without-hz-bar': hideHorizontalNavbar && bannersVersion === 1,
        'vnb-cr-v2--without-hz-bar': hideHorizontalNavbar && bannersVersion === 2,
        'vnb-expand': expandNav,
        'vnb-nexpand': !expandNav,
    });

    return (
        <div className={containerClasses}>
            {(enableExpandableNav)
                ? (
                    <div className='vnb-expandable'>
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
                        <span
                            className='vnb-expandable-container'
                            onClick={toggleExpandableNav}
                        >
                            <img className='vnb-expandable-image' src={cheveronArrowRightIcon} alt='arrow-icon' />
                        </span>
                    </div>
                ) : null}
            {filteredNavItems.map(renderNavItem)}
        </div>
    );
};

export default memo(VerticalNavbar);

/**
 * @file Manages the action functions for transaction view container
 */

import { GET_TRANSACTION_VIEW, GET_TRANSACTION_VIEW_FILTER } from './constants';

export const getTransactionViewAction = (data) => ({
    type: GET_TRANSACTION_VIEW,
    data,
});

export const getTransactionViewFilterAction = (data) => ({
    type: GET_TRANSACTION_VIEW_FILTER,
    data,
});

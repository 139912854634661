/*
 *
 * Wealth Investment Selectors
 *
 */

import { createSelector } from 'reselect';

import { WEALTH_INVESTMENT_KEY } from './constants';

// const selectWealthInvestment = state => state.get(WEALTH_INVESTMENT_KEY);
const selectOnboardingDetails = state => state.getIn([WEALTH_INVESTMENT_KEY, 'onboarding']);

const makeSelectOnboardingDetails = () => createSelector(
    selectOnboardingDetails,
    onboardingDetails => onboardingDetails.toJS(),
);

export {
    makeSelectOnboardingDetails,
};

/**
 * @file Constants for Transaction case detail container
 */

// action & reducer constants
export const TRANSACTION_CASE_DETAILS_KEY = 'transactionCaseDetails';

export const GET_TRANSACTION_CASE_DETAILS = `app/${TRANSACTION_CASE_DETAILS_KEY}/GET_TRANSACTION_CASE_DETAILS`;
export const SET_TRANSACTION_CASE_DETAILS = `app/${TRANSACTION_CASE_DETAILS_KEY}/SET_TRANSACTION_CASE_DETAILS`;
export const ERR_TRANSACTION_CASE_DETAILS = `app/${TRANSACTION_CASE_DETAILS_KEY}/ERR_TRANSACTION_CASE_DETAILS`;

export const GET_TRANSACTION_CASE_ANNOTATION = `app/${TRANSACTION_CASE_DETAILS_KEY}/GET_TRANSACTION_CASE_ANNOTATION`;
export const SET_TRANSACTION_CASE_ANNOTATION = `app/${TRANSACTION_CASE_DETAILS_KEY}/SET_TRANSACTION_CASE_ANNOTATION`;
export const ERR_TRANSACTION_CASE_ANNOTATION = `app/${TRANSACTION_CASE_DETAILS_KEY}/ERR_TRANSACTION_CASE_ANNOTATION`;

export const GET_TRANSACTION_CASE_COMMENTS = `app/${TRANSACTION_CASE_DETAILS_KEY}/GET_TRANSACTION_CASE_COMMENTS`;
export const SET_TRANSACTION_CASE_COMMENTS = `app/${TRANSACTION_CASE_DETAILS_KEY}/SET_TRANSACTION_CASE_COMMENTS`;
export const ERR_TRANSACTION_CASE_COMMENTS = `app/${TRANSACTION_CASE_DETAILS_KEY}/ERR_TRANSACTION_CASE_COMMENTS`;

/**
 * @file Manages the action functions for Agent Feedback Details component
 */

import {
    GET_AGENT_FB_DETAILS, SET_AGENT_FB_DETAILS,
} from './constants';

export const getAgentFeedbackDetails = (data) => ({
    type: GET_AGENT_FB_DETAILS,
    data,
});

export const setAgentFeedbackDetails = () => ({
    type: SET_AGENT_FB_DETAILS,
});

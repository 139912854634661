/**
 * @file Util functions for freshdesk issue info
 */

import { isNilOrEmpty } from '../../utils';

/**
 * Get the array of categories from FD Category Info
 * @param {object} fdCategoryInfo The entire nested FD fields information
 * @returns {array} The list of categories
 */
export const getCategoryList = (fdCategoryInfo) => {
    if (isNilOrEmpty(fdCategoryInfo)) return {};

    return fdCategoryInfo.ticket_categories.product_category_choices;
};

/**
 * Get the category details for the selected category
 * @param {object} fdCategoryInfo The entire nested FD fields information
 * @param {string} categoryKey The selected category
 * @returns {array} The list of category details
 */
export const getCategoryDetails = (fdCategoryInfo, categoryKey) => {
    if (isNilOrEmpty(fdCategoryInfo) || !categoryKey) return {};

    const productCategories = getCategoryList(fdCategoryInfo);
    const indexOfCategory = productCategories.findIndex((categoryInfo) => categoryInfo.product_category === categoryKey);

    return productCategories[indexOfCategory].product_category_details_choices;
};

/**
 * Get the sub category list for the selected category and selected category details
 * @param {object} fdCategoryInfo The entire nested FD fields information
 * @param {string} categoryKey The selected category
 * @param {*} subCategoryKey The selected sub category
 * @returns The list of sub categories
 */
export const getSubCategory = (fdCategoryInfo, categoryKey, subCategoryKey) => {
    if (isNilOrEmpty(fdCategoryInfo) || !categoryKey || !subCategoryKey) return {};

    const categoryDetailsList = getCategoryDetails(fdCategoryInfo, categoryKey);
    const indexOfCategoryDetails = categoryDetailsList.findIndex(
        (categoryDetails) => categoryDetails.product_category_details === subCategoryKey,
    );

    return categoryDetailsList[indexOfCategoryDetails].subcategory_choices;
};

/**
 * Transforms an array of objects into another array with label and value which is needed for rendering a dropdown
 * @param {array} list The original array of objects
 * @param {array} key The key that is needed to select the a particular object key for an item
 * @returns The transform array of objects in label-value format
 */
export const getOptionsFromList = (list, key) => {
    if (isNilOrEmpty(list) || !key) return [];

    return list.map((item) => ({
        label: item[key],
        value: item[key],
    }));
};

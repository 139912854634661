/**
 * @file UserBanner: Renders any on going issue information to agents at a user/actor_id level
 */

import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { KVPairInfo, Loader } from '../../../components';

import { getUserBanners } from '../actions';
import { selectUserBanners } from '../selectors';

const UserBanners = (props) => {
    const { authData } = props;

    const dispatch = useDispatch();

    const userBanners = useSelector(selectUserBanners, shallowEqual);

    let loading;
    let banners;
    let hasData;
    let err;

    if (userBanners[(authData?.authSubValue || '').concat(authData?.authValue)]) {
        ({
            loading = true, banners, hasData = false, err,
        } = userBanners[(authData?.authSubValue || '').concat(authData?.authValue)]);
    }

    useEffect(() => {
        if (!hasData) {
            dispatch(getUserBanners({ ...authData }));
        }
    }, []);

    if (!hasData) return null;

    return (
        <React.Fragment>
            {banners && banners.map((banner) => {
                const { banner_content_v2: bannerContent } = banner;
                const { title_body_content: titleBodyContent, label_message_list_content: labelMessageContent } = bannerContent;

                if (titleBodyContent) {
                    return (
                        <div className='profile-uii-cr mb-30'>
                            <div className='profile-uii-kv-cr'>
                                <div className='profile-uii-kv-cl'>{titleBodyContent.title}</div>
                                <div className='profile-uii-kv-cv'>{titleBodyContent.body}</div>
                            </div>
                        </div>
                    );
                }
                if (labelMessageContent) {
                    const { label_message_list: labelMessageList, banner_heading: bannerHeading } = labelMessageContent;
                    return (
                        <div className='profile-uii-cr mb-30'>
                            <KVPairInfo
                                title={bannerHeading}
                                data={labelMessageList}
                                extClasses={{
                                    container: 'profile-uii-kv-cr',
                                    contentLabel: 'profile-uii-kv-cl',
                                    contentValue: 'profile-uii-kv-cv',
                                    titleHeading: 'profile-uii-kv-title',
                                }}
                                valueKey='message'
                            />
                        </div>
                    );
                }
                return (
                    <div />
                );
            })}

            {!hasData && err && (
                <div className='profile-err err-label'>{err}</div>
            )}
            <Loader visible={loading} />
        </React.Fragment>
    );
};

export default UserBanners;

/**
 * @file TransactionsHistory
 * Displays the txn history details
 */

import React, { memo } from 'react';

import { KVPairInfo } from '../../../../components';

const getTxnKVData = (data) => ([
    {
        label: 'Request ID',
        value: data.requestId,
    },
    {
        label: 'Txn ID',
        value: data.txnId,
    },
    {
        label: 'Remitter Name',
        value: data?.remitterName,
    },
    {
        label: 'PI Verified Name',
        value: data?.piRemitterName,
    },
    {
        label: 'UTR',
        value: data.utr,
    },
    {
        label: 'Payment Protocol',
        value: data.paymentProtocol,
    },
    {
        label: 'Particulars',
        value: data.particulars,
    },
    {
        label: 'Txn Timestamp',
        value: data.txnTime,
    },
    {
        label: 'Remitter PI ID',
        value: data.remitterId,
    },
    {
        label: 'Remitter Actor Name',
        value: data.remitterActorName,
    },
    {
        label: 'PI Account Name',
        value: data.piAccountName,
    },
    {
        label: 'Verified Employer Name',
        value: data.verifiedEmployerName,
    },
]);

const TransactionsHistory = (props) => {
    const { data, id } = props;

    return (
        <KVPairInfo
            title={`Transaction - ${id}`}
            data={data ? getTxnKVData(data) : []}
            extClasses={{
                container: 'salops-kv__cr-v2',
                titleHeading: 'salops-kv__hd3 mb-30',
                contentLabel: 'salops-kv__cl',
                contentValue: 'salops-kv__cv',
                contentWr: 'salops-kv__con-wr',
                titleContainer: 'salops-kv__hd3 fccWrapper',
            }}
        />
    );
};

export default memo(TransactionsHistory);

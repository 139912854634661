/**
 * Search - Manages the search page for the Sherlock V2
 */
import React, {
    memo, useContext, useEffect, useMemo, useState,
} from 'react';
import { compose } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { components } from 'react-select';

import { ReactComponent as FourRectangles } from '../../assets/images/svgs/four-rectangles.svg';
import { ReactComponent as Umbrella } from '../../assets/images/svgs/umbrella.svg';
import { ReactComponent as Phone } from '../../assets/images/svgs/phone.svg';
import { getRedirectionUrlFromGuideType } from '../../utils/formatter';
import { RudderEvent, pushRudderEvent } from '../../rudderEvents';
import { FormDropdown, LoaderOrError, Table } from '../../components';
import { UserDetailsContext } from '../../context';
import { injectReducer, injectSaga } from '../../utils';

import { SHERLOCK_V2_ENTRY_POINT } from '../Scripts/constants';
import { SCRIPTS_PATH, SOP_PATH } from '../AppV2/routes';

import { selectSearchData } from './selectors';
import { getSearchSopAction, getSearchSopMetaAction, setSearchSopAction, setSearchSopMetaAction } from './actions';
import { CATEGORY_VARIABLE_MAPPING } from './constants';

import reducer from './reducer';
import saga from './saga';
import './style.scss';

const Option = (props) => {
    const { data: { label, count } } = props;
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.Option {...props}>
            <div>{label}</div>
            <div>{count}</div>
        </components.Option>
    );
};

const Control = (icon) => ({ children, ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Control {...props}>
        {icon} {children}
    </components.Control>
);

const Row = (props) => {
    const { children, data: { item, idx }, extClasses } = props;
    const guideType = item?.Type?.[item?.Type?.value_v2]?.toLowerCase();
    const location = useLocation();

    return (
        <Link
            key={item.id || idx + 1}
            to={{
                pathname: getRedirectionUrlFromGuideType(guideType, item?.meta),
                state: { prevPath: location.pathname },
            }}
            className={extClasses}
        >
            {
                children
            }
        </Link>
    );
};

const Search = () => {
    const location = useLocation();
    const { search, state: locationState } = location;
    const history = useHistory();
    const dispatch = useDispatch();
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);

    const searchData = useSelector(selectSearchData, shallowEqual);

    const rudderProperties = {
        ticketId: null,
        callId: null,
        entryPoint: SHERLOCK_V2_ENTRY_POINT,
    };

    let searchResults;
    let categoryMap;
    let totalResults;
    let from;
    let size;
    let resultsLoading;
    let metaLoading;
    let resultsErr;
    let metaErr;

    if (searchData) {
        ({
            results: {
                loading: resultsLoading, err: resultsErr, from, size, data: searchResults,
            },
            meta: {
                categoryMap, totalResults, loading: metaLoading, err: metaErr,
            },
        } = searchData);
    }

    const searchParams = new URLSearchParams(search);
    const query = searchParams.get('query');
    const resultType = searchParams.get('type');
    const l1Param = searchParams.get('l1');
    const l2Param = searchParams.get('l2');
    const l3Param = searchParams.get('l3');

    const [L1List, setL1List] = useState([
        {
            label: 'All Product Categories',
            value: 'all',
            count: 0,
        },
    ]);
    const [L2List, setL2List] = useState([
        {
            label: 'All Product Category Details',
            value: 'all',
            count: 0,
        },
    ]);
    const [L3List, setL3List] = useState([
        {
            label: 'All Sub Categories',
            value: 'all',
            count: '0',
        },
    ]);
    const [selectedL1, setSelectedL1] = useState();
    const [selectedL2, setSelectedL2] = useState();
    const [selectedL3, setSelectedL3] = useState();

    const payload = useMemo(() => ({
        query,
        l1: l1Param,
        l2: l2Param,
        l3: l3Param,
        resultType,
    }), [query, l1Param, l2Param, l3Param, resultType]);

    // Returns the object for the specific category param in the specific category list
    const getFilter = (list, value) => (list.find((item) => (item?.value === value)));

    useEffect(() => {
        // Call both the apis with all the query params present at the start of rendering.
        makePayLoadAndCallApi(payload, true);
        // Removes the search results and meta data from the store when the component unmounts.
        return () => {
            dispatch(setSearchSopAction({
                screenKey: ['results'],
                value: {
                    originalArgs: {},
                    from: '0',
                    size: '10',
                    data: null,
                },
            }));
            dispatch(setSearchSopMetaAction({
                screenKey: ['meta'],
                value: {
                    originalArgs: {},
                    categoryMap: null,
                    totalResults: '0',
                },
            }));
        };
    }, []);

    useEffect(() => {
        // If query value or result type changes and search results are already there & previous path was not SOP or Script path then clear all the category filters.
        if (searchResults && !(
            locationState?.prevPath
            && (locationState?.prevPath.includes(SCRIPTS_PATH) || locationState?.prevPath.includes(SOP_PATH))
        )) {
            onChangeL1(true)({}, { action: 'clear' });
        }
    }, [resultType, query]);

    // Populates the L1List according to fetched category map
    useEffect(() => {
        if (categoryMap) {
            const newL1 = [
                {
                    ...L1List[0],
                    count: totalResults,
                },
                ...categoryMap,
            ];
            setL1List(newL1);
        }
    }, [categoryMap]);

    // Populates the selectedL1 according to the search params and updates L2List
    useEffect(() => {
        if (categoryMap) {
            const selected = l1Param && getFilter(categoryMap, l1Param);
            if (selected && (selected?.value !== selectedL1?.value || selected?.count !== selectedL1?.count)) {
                onChangeL1()(selected);
            }
        }
    }, [categoryMap, l1Param]);

    // Populates the selectedL2 according to the search params and updates L3List
    useEffect(() => {
        const selected = l2Param && getFilter(L2List, l2Param);
        if (selected && (selected?.value !== selectedL1?.value || selected?.count !== selectedL1?.count)) {
            onChangeL2()(selected);
        }
    }, [L2List]);

    // Populates the selectedL3 according to the search params
    useEffect(() => {
        const selected = l3Param && getFilter(L3List, l3Param);
        if (selected && (selected?.value !== selectedL1?.value || selected?.count !== selectedL1?.count)) {
            onChangeL3()(selected);
        }
    }, [L3List]);

    // Calculates the total number of results according to filters
    const getTotalResults = () => {
        if (!searchResults) return 0;
        if (l3Param) return selectedL3?.count;
        if (l2Param) return L3List[0]?.count;
        if (l1Param) return L2List[0]?.count;
        if (totalResults) return totalResults;
        return 0;
    };

    const paginationClickHandler = (paginationParams) => {
        makePayLoadAndCallApi({
            ...payload,
            paginationParams,
        });
    };

    const makePayLoadAndCallApi = (requestPayload, bothApiCall) => {
        if (bothApiCall) {
            if (requestPayload?.query) {
                dispatch(getSearchSopMetaAction({
                    query: requestPayload.query, resultType: requestPayload.resultType, screenKey: ['meta'],
                }));
            } else if (requestPayload?.l1) {
                dispatch(getSearchSopMetaAction({ l1: requestPayload.l1, resultType: requestPayload.resultType, screenKey: ['meta'] }));
            } else if (!query) {
                dispatch(getSearchSopMetaAction({ resultType: requestPayload.resultType, screenKey: ['meta'] }));
            }
        }
        dispatch(getSearchSopAction({
            ...requestPayload, screenKey: ['results'], agentEmail: agentInfo.emailId, rudderProperties,
        }));
    };

    const onChangeL1 = (apiCall) => (selectedOption, triggeredAction) => onChangeDDValue({
        apiCall,
        type: 'l1',
        selectedOption,
        optionOnChange: setSelectedL1,
        list: L1List,
        changeOption: selectedL2,
        changeList: L2List,
        changeListOnChange: setL2List,
        isClear: triggeredAction?.action === 'clear',
    });

    const onChangeL2 = (apiCall) => (selectedOption, triggeredAction) => onChangeDDValue({
        apiCall,
        type: 'l2',
        selectedOption,
        optionOnChange: setSelectedL2,
        list: L2List,
        changeOption: selectedL3,
        changeList: L3List,
        changeListOnChange: setL3List,
        isClear: triggeredAction?.action === 'clear',
    });

    const onChangeL3 = (apiCall) => (selectedOption, triggeredAction) => onChangeDDValue({
        apiCall,
        type: 'l3',
        selectedOption,
        optionOnChange: setSelectedL3,
        list: L3List,
        isClear: triggeredAction?.action === 'clear',
    });

    //  Publish Rudder event for Landing on Sherlock Scripts Page and getting the results
    useEffect(() => {
        if (searchResults?.rowData) {
            const properties = {
                queryText: query,
                L1: selectedL1?.value,
                L2: selectedL2?.value,
                L3: selectedL3?.value,
                results: searchResults?.rowData.map((rowDatum) => rowDatum.meta),
                ...rudderProperties,
            };
            pushRudderEvent(RudderEvent.SherlockV2Scripts.LandedSherlockV2ScriptsSearchPage, agentInfo.emailId, properties);
        }
    }, [JSON.stringify(searchResults?.rowData)]);

    // It updates the corresponding selected filter, adds or deletes the respective search param and calls the api for fetching search results
    // Also clears the selected options like if L1 is cleared, L2 and L3 is also cleared.
    const onChangeDDValue = ({
        apiCall, type, selectedOption, optionOnChange, list, changeOption, changeList, changeListOnChange, isClear,
    }) => {
        if (isClear || selectedOption?.value === list[0]?.value) {
            optionOnChange();
            searchParams.delete(type);
            if (type === 'l1' && changeOption?.value !== changeList[0]?.value) onChangeL2()({}, { action: 'clear' });
            if (type === 'l2' && changeOption?.value !== changeList[0]?.value) onChangeL3()({}, { action: 'clear' });
        } else {
            optionOnChange(selectedOption);
            if (changeList) {
                const newList = [
                    {
                        ...changeList[0],
                        count: selectedOption?.count,
                    },
                    ...selectedOption[CATEGORY_VARIABLE_MAPPING[type]],
                ];
                changeListOnChange(newList);
            }
            searchParams.set(type, selectedOption?.value);
        }
        if (apiCall) callApiForCategories(type, selectedOption, list, isClear);
        history.replace({
            search: searchParams.toString(),
        });
    };

    // Call the API for fetching the search results with each category with its own type of payload
    const callApiForCategories = (type, selectedOption, list, isClear) => {
        if (isClear || selectedOption?.value === list[0]?.value) {
            if (type === 'l1') {
                makePayLoadAndCallApi({
                    query, resultType,
                }, true);
            } else if (type === 'l2') {
                makePayLoadAndCallApi({
                    ...payload,
                    l2: null,
                    l3: null,
                });
            } else if (type === 'l3') {
                makePayLoadAndCallApi({
                    ...payload,
                    l3: null,
                });
            }
        } else {
            makePayLoadAndCallApi({
                ...payload,
                [type]: selectedOption?.value,
            });
        }
    };

    const renderCategoryDropdown = (type) => {
        let data = {};
        switch (type) {
            case 'l1': {
                data = {
                    list: L1List,
                    value: selectedL1,
                    onChange: onChangeL1(true),
                    icon: <FourRectangles className='ml-15' />,
                    cacheKey: 'product-categories',
                    isDisabled: L1List?.length === 1,
                };
                break;
            }

            case 'l2': {
                data = {
                    list: L2List,
                    value: selectedL2,
                    onChange: onChangeL2(true),
                    icon: <Umbrella className='ml-15' />,
                    cacheKey: 'product-category-details',
                    isDisabled: !selectedL1 || (selectedL1 && L2List?.length === 1),
                };
                break;
            }

            case 'l3': {
                data = {
                    list: L3List,
                    value: selectedL3,
                    onChange: onChangeL3(true),
                    icon: <Phone className='ml-15' />,
                    cacheKey: 'sub-category-details',
                    isDisabled: !selectedL2 || (selectedL2 && L3List?.length === 1),
                };
                break;
            }
            default: {
                break;
            }
        }
        return (
            <FormDropdown
                neverEmpty
                options={data?.list}
                input={{
                    value: data?.value,
                    onChange: data?.onChange,
                    placeholder: 'Select...',
                    isClearable: (!!data?.value),
                    isDisabled: data?.isDisabled,
                    defaultValue: data?.list[0],
                }}
                cacheKey={data?.cacheKey}
                components={{
                    Control: Control(data?.icon),
                    Option,
                }}
                extStyles={{
                    container: 'scripts-search-dd',
                }}
                extCustomStyles={{
                    control: {
                        borderRadius: '18px',
                    },
                    option: (state) => ({
                        backgroundColor: state.isSelected ? '#EFF2F6' : 'transparent',
                        color: 'black',
                        margin: '0 5px',
                        borderRadius: '10px',
                        width: 'unset',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }),
                }}
            />
        );
    };

    return (
        <div className='search-scr search-scr-cr'>
            <div className='frcsbWrapper'>
                <div className='heading3 scripts-search-heading'>
                    {getTotalResults()} Results
                </div>
                <div className='frWrapper scripts-search-dd m-0'>
                    {renderCategoryDropdown('l1')}
                    {renderCategoryDropdown('l2')}
                    {renderCategoryDropdown('l3')}
                </div>
            </div>
            <Table
                v2
                paginationV2
                objKey='header_key'
                paginatedClickHandler={paginationClickHandler}
                paginationParams={{
                    from,
                    size,
                    totalResults: getTotalResults(),
                }}
                rowDataVersion={2}
                labelData={searchResults?.columnData?.map((col) => ({ ...col, extClasses: 'scripts-search-row-data' }))}
                contentData={searchResults?.rowData}
                components={{
                    row: Row,
                }}
                extClasses={{
                    container: 'scripts-cc scripts-search-tbl',
                    headerCr: 'scripts-text-left scripts-search-tbl-header',
                    contentCr: 'scripts-text-left scripts-search-tbl-cont',
                    value: 'scripts-search-val',
                    rowCr: 'scripts-search-tbl-row',
                }}
            />
            <LoaderOrError loading={resultsLoading || metaLoading} errorLabel={`${metaErr || resultsErr}`} />
        </div>
    );
};

const withReducer = injectReducer({ key: 'search', reducer });
const withSaga = injectSaga({ key: 'search', saga });

export default compose(
    withReducer,
    withSaga,
)(memo(Search));

/**
 * @file Manages the constants for Agent Management component
 */

export const GET_AGENT_LIST = 'app/AgentManagement/GET_AGENT_LIST';
export const SET_AGENT_LIST = 'app/AgentManagement/SET_AGENT_LIST';
export const ERR_AGENT_LIST = 'app/AgentManagement/ERR_AGENT_LIST';

export const SET_AGENT_INFO = 'app/AgentManagement/SET_AGENT_INFO';

export const GET_ACCESS_LEVEL_LIST = 'app/AgentManagement/GET_ACCESS_LEVEL_LIST';
export const SET_ACCESS_LEVEL_LIST = 'app/AgentManagement/SET_ACCESS_LEVEL_LIST';

export const GET_AGENT_TYPE_LIST = 'app/AgentManagement/GET_AGENT_TYPE_LIST';
export const SET_AGENT_TYPE_LIST = 'app/AgentManagement/SET_AGENT_TYPE_LIST';

export const GET_AGENT_STATUS_LIST = 'app/AgentManagement/GET_AGENT_STATUS_LIST';
export const SET_AGENT_STATUS_LIST = 'app/AgentManagement/SET_AGENT_STATUS_LIST';

export const SET_AGENT_FORM_FIELDS = 'app/AgentManagement/SET_AGENT_FORM_FIELDS';
export const RESET_AGENT_FORM_FIELDS = 'app/AgentManagement/RESET_AGENT_FORM_FIELDS';

export const CREATE_AGENT = 'app/AgentManagement/CREATE_AGENT';
export const UPDATE_AGENT = 'app/AgentManagement/UPDATE_AGENT';
export const SET_CREATE_OR_UPDATE_AGENT_LOADER = 'app/AgentManagement/SET_CREATE_OR_UPDATE_AGENT_LOADER';

export const AGENT_LIST_LABEL_DATA = [
    {
        id: 1,
        label: 'Email ID',
        key: 'emailId',
    },
    {
        id: 2,
        label: 'Agent Type',
        key: 'agentType',
    },
    {
        id: 3,
        label: 'Access Level',
        key: 'accessLevelStr',
    },
];

export const AGENT_FORM_FIELDS = {
    EMAIL_ID: 'emailId',
    PHONE_NUMBER: 'phoneNumber',
    FULL_NAME: 'fullName',
    ACCESS_LEVEL: 'accessLevelList',
    AGENT_TYPE: 'agentType',
    AGENT_STATUS: 'agentStatus',
    OZONETEL_ID: 'ozonetelId',
};

export const NAME_FIELD_ARR = ['firstName', 'middleName', 'lastName'];
export const NAME_FIELD_PLACEHOLDER_ARR = ['first name', 'middle name', 'last name'];

export const SUCCESSFUL_AGENT_CREATED_LABEL = 'Agent created successfully';
export const FAILURE_AGENT_CREATED_LABEL = 'Failed to create agent';

export const SUCCESSFUL_AGENT_UPDATED_LABEL = 'Agent updated successfully';
export const FAILURE_AGENT_UPDATED_LABEL = 'Failed to update agent info';

// key to be used for reducers & selectors for agent management
export const AGENT_MANAGEMENT_KEY = 'agentManagement';

/*
 *
 * Facematch review saga
 *
 */

import {
    call, put, takeLatest, all, takeEvery,
} from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getQueryStringFromObject, toastify,
} from '../../utils';
import { RISK_OPS_ROOT, LIVENESS_ROOT } from '../../api/routes';

import { auditLogMediaDownloadSaga } from '../LivenessReview/saga';
import { AUDIT_LOG_MEDIA_DOWNLOAD } from '../LivenessReview/constants';

import {
    GET_FACEMATCH_CASES, SET_FACEMATCH_CASES, ERR_FACEMATCH_CASES, DEQUEUE_FACEMATCH_CASE,
    VIEW_ALL_FACEMATCH_CASES, GET_BASE64_IMAGE, SET_BASE64_IMAGE, VIEW_SINGLE_FACEMATCH_CASE,
    GET_FACEMATCH_PENDING_REVIEW_COUNT, SET_FACEMATCH_PENDING_REVIEW_COUNT,
} from './constants';
import { getFacematchPendingReviewCountAction, setCurFacematchCaseAction, setFacematchViewAction } from './actions';

function* getFacematchCasesSaga(action) {
    const queryString = getQueryStringFromObject(action.data);

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_ROOT}/queue?${queryString}`,
        );

        yield put({ type: SET_FACEMATCH_CASES, data: response.elements });
        return response.elements;
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_FACEMATCH_CASES, data: { err: e.message } });
        yield put({ type: SET_FACEMATCH_CASES, data: [] });
        return [];
    }
}

function* dequeueFacematchCaseSaga(action) {
    let { actorId, recordId } = action.data;
    const { queryParams, index, customContext } = action.data;
    const { payloadType } = queryParams;
    const { bannersVersion } = customContext;

    actorId = btoa(actorId);
    recordId = btoa(recordId);
    const queryString = getQueryStringFromObject({ actorId, recordId, payloadType });

    const bannerStyle = {
        1: '.devs-cc',
        2: '.devs-v2-cc',
    };

    try {
        yield call(
            [clientApiWrapper, clientApiWrapper.delete],
            `${RISK_OPS_ROOT}/queue?${queryString}`,
        );

        const queueElements = yield call(getFacematchCasesSaga, { data: queryParams });
        yield put(getFacematchPendingReviewCountAction({ ...queryParams }));

        let facematchView;
        let curFacematchCase;
        const queueLen = queueElements.length;

        if (queueLen === 0) { // if queue is empty, show all facematch cases view
            facematchView = VIEW_ALL_FACEMATCH_CASES;
            curFacematchCase = {};
        } else { // else show the next case in the queue
            facematchView = VIEW_SINGLE_FACEMATCH_CASE;
            /**
             * if index is less than queue length, set the current case at that index
             * else set the current case as the last case in the queue
             */
            curFacematchCase = index < queueLen ? queueElements[index] : queueElements[queueLen - 1];
        }

        yield put(setFacematchViewAction(facematchView));
        yield put(setCurFacematchCaseAction({ ...curFacematchCase, index }));

        /**
         * scroll to the top for the next case
         * need to add 500ms delay otherwise it scrolls to the top & then again back to the bottom
         */
        setTimeout(() => document.querySelector(bannerStyle[bannersVersion]).scrollTo(0, 0), 500);
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_FACEMATCH_CASES, data: { err: e.message } });
    }
}

function* getBase64Image(action) {
    const { imageType, imagePath } = action.data;
    const queryString = `imagePath=${btoa(imagePath)}`;

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${LIVENESS_ROOT}/fm-kyc-image?${queryString}`,
        );

        const { imageSrc } = response;

        yield put({ type: SET_BASE64_IMAGE, data: { imageType, imageSrc } });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_FACEMATCH_CASES, data: { err: e.message } });
    }
}

function* getPendingReviewCountSaga(action) {
    const { payloadType } = action.data;
    const queryString = getQueryStringFromObject({ payloadType });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${RISK_OPS_ROOT}/pending-review-count?${queryString}`,
        );

        yield put({ type: SET_FACEMATCH_PENDING_REVIEW_COUNT, data: response.count });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put({ type: ERR_FACEMATCH_CASES, data: { err: e.message } });
    }
}

export default function* facematchReviewSaga() {
    yield all(
        [
            yield takeLatest(GET_FACEMATCH_CASES, getFacematchCasesSaga),
            yield takeLatest(DEQUEUE_FACEMATCH_CASE, dequeueFacematchCaseSaga),
            yield takeEvery(GET_BASE64_IMAGE, getBase64Image),
            yield takeLatest(GET_FACEMATCH_PENDING_REVIEW_COUNT, getPendingReviewCountSaga),
            yield takeLatest(AUDIT_LOG_MEDIA_DOWNLOAD, auditLogMediaDownloadSaga),
        ],
    );
}

import {
    GET_RELATED_TICKETS, MERGE_RELATED_TICKETS,
} from './constants';

export const getRelatedTickets = data => ({
    type: GET_RELATED_TICKETS,
    data,
});

export const mergeRelatedTickets = data => ({
    type: MERGE_RELATED_TICKETS,
    data,
});

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Loader, Table } from '../../../components';
import { isNilOrEmpty } from '../../../utils';

import { getActivitiesAction, setSearchParamsAction, setUsStocksViewAction } from '../actions';
import { PORTFOLIO_DETAILS_LABEL_DATA, VIEW_PORTFOLIO_DETAILS_LIST } from '../constants';

import USSDateView from '../USSDateView';
import USSOrderView from '../USSOrderView';

const USSPortfolioDetailSearch = (props) => {
    const {
        ticketId, userId, index, portfolioDetailList,
    } = props;

    const dispatch = useDispatch();

    const handleSubmit = (params) => {
        const {
            textInput, fromDate, toDate, assetName,
        } = params;

        const searchParams = {
            orderId: textInput, fromDate, toDate, assetName,
        };

        let paramsPayload = {};

        if (textInput) {
            paramsPayload = {
                orderId: btoa(textInput),
            };
        }

        if (assetName) {
            paramsPayload = {
                assetName,
            };
        }

        if (fromDate && toDate) {
            paramsPayload = {
                from: fromDate.value,
                to: toDate.value,
            };
        }

        const payload = {
            ...paramsPayload,
            ticketId,
            userId,
            index,
        };

        dispatch(setSearchParamsAction(searchParams));
        dispatch(getActivitiesAction(payload));
        dispatch(setUsStocksViewAction(VIEW_PORTFOLIO_DETAILS_LIST));
    };

    const renderActions = (item) => (
        <div className='frccWrapper'>
            <Button
                link
                label='View Activities'
                onClick={() => {
                    handleSubmit({ assetName: item.name });
                }}
            />
        </div>
    );
    return (
        <div className='uss'>
            <USSOrderView
                ticketId={ticketId}
                userId={userId}
                index={index}
                searchAction={handleSubmit}
            />
            {/* to keep some empty space b/w two search cards */}
            <div className='uss-src' />
            <USSDateView
                ticketId={ticketId}
                userId={userId}
                index={index}
                searchAction={handleSubmit}
                dropdownLabel='Activity Type'
            />
            <div className='uss-src' />
            {!isNilOrEmpty(portfolioDetailList) ? (
                <div>
                    <Table
                        v2
                        labelData={PORTFOLIO_DETAILS_LABEL_DATA}
                        contentData={portfolioDetailList[ticketId].data}
                        renderActionableColumn={renderActions}
                    />
                    <Loader visible={portfolioDetailList[ticketId].loading} />
                </div>
            ) : null }
        </div>
    );
};

export default memo(USSPortfolioDetailSearch);

/**
 * @file RiskReview: This is used to view the risk elements at a user level
 * The API returns different sections that ops can view and take actions on
 */

import React, { memo, useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useMemoizedSelector } from '../../../hooks';
import { MetaInfoContext } from '../../../context';
import { isNilOrEmpty } from '../../../utils';

import { getAdditionalFilters, getIdsFromCase } from '../../RiskOps/utils';
import { dequeueRiskOpsCaseAction } from '../../RiskOps/actions';
import { selectRiskOpsCurCase, selectRiskOpsPayloadType, selectRiskOpsQueueFilters } from '../../RiskOps/selectors';
import { RISK_OPS_USER_AFU_HISTORY_PATH } from '../../App/routes';
import { DequeueCase } from '../../RiskOps';

import { selectIsAFU, selectUserRiskReviewSection } from '../selectors';
import RiskSection from '../RiskSection';
import { getUserRiskDataAction, resetRiskData } from '../actions';
import { USER_RISK_REVIEW_KEY as CONTAINER_KEY } from '../constants';
import { getActorMailId } from '../utils';
import { useTransactionViewEvent } from '../../TransactionViewV2/useTransactionViewEvent';

const RiskReview = () => {
    const metaInfo = useContext(MetaInfoContext);
    const {
        componentVersions: {
            banners: bannersVersion,
        },
    } = metaInfo;
    const dispatch = useDispatch();

    const curCase = useMemoizedSelector(selectRiskOpsCurCase, [CONTAINER_KEY]);
    const {
        recordId, actorId, reviewReasons, caseId,
    } = getIdsFromCase(curCase);
    const reviewSections = useMemoizedSelector(selectUserRiskReviewSection, [actorId]);

    const payloadType = useMemoizedSelector(selectRiskOpsPayloadType, [CONTAINER_KEY]);
    const isAFU = useSelector(selectIsAFU, shallowEqual); // previous AFU History flag
    const { fromDate, toDate, sortOption } = useMemoizedSelector(selectRiskOpsQueueFilters, [CONTAINER_KEY]);
    const transactionViewEvent = useTransactionViewEvent();

    const location = useLocation();

    const { index } = curCase;
    /** current AFU History flag  */
    const isCurrentAFU = !!location.pathname.includes(RISK_OPS_USER_AFU_HISTORY_PATH);

    useEffect(() => {
        if ((isNilOrEmpty(reviewSections) || isAFU !== isCurrentAFU) && actorId) {
            dispatch(resetRiskData());
            dispatch(getUserRiskDataAction({
                actorId, reviewReasons, caseId, isAFU: isCurrentAFU,
            }));
        }
        transactionViewEvent.fireTransactionViewEvents(transactionViewEvent.events.USER_REVIEW.PAGE_LANDING, {
            caseId,
        });

        return () => {
            transactionViewEvent.fireTransactionViewEvents(transactionViewEvent.events.USER_REVIEW.PAGE_EXIT, {
                caseId,
            });
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, actorId, reviewSections, isCurrentAFU, isAFU]);

    if (!actorId || isNilOrEmpty(reviewSections)) return null;

    const dequeueAction = dequeueRiskOpsCaseAction({
        containerKey: CONTAINER_KEY,
        actorId,
        recordId,
        index,
        queryParams: {
            payloadType: payloadType.value,
            ...getAdditionalFilters(fromDate, toDate, sortOption),
        },
        customContext: {
            bannersVersion,
        },
    });

    const actorMailId = reviewSections ? getActorMailId(reviewSections) : null;

    return (
        <div className='urr-cr'>
            {reviewSections.map((sectionData) => (
                <RiskSection
                    key={sectionData.id}
                    sectionData={sectionData}
                    actorMailId={actorMailId}
                />
            ))}
            {!caseId ? <DequeueCase dequeueAction={dequeueAction} /> : ''}
        </div>
    );
};

export default memo(RiskReview);

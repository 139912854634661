/*
 *
 * LogsInfo constants
 *
 */

export const ERR_SEARCH = 'app/LogsInfo/ERR_SEARCH';
export const GET_LOGS = 'app/LogsInfo/GET_LOGS';
export const SET_LOGS = 'app/LogsInfo/SET_LOGS';
export const ERR_LOGS = 'app/LogsInfo/ERR_LOGS';
export const GET_LOG_INFO = 'app/LogsInfo/GET_LOG_INFO';
export const SET_LOG_INFO = 'app/LogsInfo/SET_LOG_INFO';
export const ERR_LOG_INFO = 'app/LogsInfo/ERR_LOG_INFO';
export const SET_LOG_FILTER = 'app/LogsInfo/SET_LOG_FILTER';

export const LOGS_LIST_HEADERS = [
    // { label: "Name", key: "name" },
    { label: 'Agent Email', key: 'agent_email' },
    { label: 'Ticket', key: 'ticket_id' },
    { label: 'Resource', key: 'resource' },
    { label: 'Access Granted', key: 'access_granted' },
    { label: 'Timestamp', key: 'timestamp' },
];

export const ACTION_TYPE = [
    {
        label: 'Reset Filter',
        value: '',
    },
    {
        label: 'Fetch',
        value: 'FETCH',
    },
    {
        label: 'Update',
        value: 'UPDATE',
    },
    {
        label: 'Delete',
        value: 'DELETE',
    },
    {
        label: 'Block',
        value: 'BLOCK',
    },
    {
        label: 'Suspend',
        value: 'SUSPEND',
    },
    {
        label: 'Freeze',
        value: 'FREEZE',
    },
    {
        label: 'View',
        value: 'VIEW',
    },
    {
        label: 'Create',
        value: 'CREATE',
    },
    {
        label: 'Call',
        value: 'CALL',
    },
    {
        label: 'Enable',
        value: 'ENABLE',
    },
    {
        label: 'Disable',
        value: 'DISABLE',
    },
];

export const OBJECT_TYPE = [
    {
        label: 'Reset Filter',
        value: '',
    },
    {
        label: 'Transactions',
        value: 'TRANSACTIONS',
    },
    {
        label: 'Transactions Dispute',
        value: 'TRANSACTIONS_DISPUTE',
    },
    {
        label: 'Agent',
        value: 'AGENT',
    },
    {
        label: 'Rules',
        value: 'RULES',
    },
    {
        label: 'Debit Card',
        value: 'DEBIT_CARD',
    },
    {
        label: 'Savings Account',
        value: 'SAVINGS_ACCOUNT',
    },
    {
        label: 'Dispute Status',
        value: 'DISPUTE_STATUS',
    },
    {
        label: 'Ticket',
        value: 'TICKET',
    },
    {
        label: 'user',
        value: 'USER',
    },
    {
        label: 'Deposits',
        value: 'DEPOSITS',
    },
    {
        label: 'Profile',
        value: 'PROFILE',
    },
    {
        label: 'Kyc',
        value: 'KYC',
    },
    {
        label: 'Upi',
        value: 'UPI',
    },
    {
        label: 'Rewards',
        value: 'REWARDS',
    },
    {
        label: 'OFFERS',
        value: 'Offers',
    },
    {
        label: 'Dispute Channel',
        value: 'DISPUTE_CHANNEL',
    },
    {
        label: 'Dispute Question',
        value: 'DISPUTE_QUESTION',
    },
    {
        label: 'Onboarding',
        value: 'ONBOARDING',
    },
    {
        label: 'Onboarding Scores',
        value: 'ONBOARDING_SCORES',
    },
    {
        label: 'Communications',
        value: 'COMMUNICATIONS',
    },
];

export const STATUS_TYPE = [
    {
        label: 'Reset Filter',
        value: '',
    },
    {
        label: 'Allowed',
        value: 'ALLOWED',
    },
    {
        label: 'Denied',
        value: 'DENIED',
    },
];

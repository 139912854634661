/**
 * @file Manages the selector functions for Transaction queue container
 */

import { createSelector } from 'reselect';

import { TRANSACTION_QUEUE_KEY } from './constants';

export const selectTransactionQueueDetails = createSelector(
    (state) => state.getIn([TRANSACTION_QUEUE_KEY, 'fileEntries']),
    (substate) => substate?.toJS(),
);

export const selectTransactionQueueLoader = createSelector(
    (state) => state.getIn([TRANSACTION_QUEUE_KEY, 'loading']),
    (substate) => substate,
);

export const selectTransactionQueueParameterList = createSelector(
    (state) => state.getIn([TRANSACTION_QUEUE_KEY, 'parameterList']),
    (substate) => substate?.toJS() || [],
);

export const selectTransactionQueueError = createSelector(
    (state) => state.getIn([TRANSACTION_QUEUE_KEY, 'err']),
    (subState) => subState,
);

export const selectTransactionQueueFilters = createSelector(
    (state) => state.getIn([TRANSACTION_QUEUE_KEY, 'filters']),
    (subState) => subState?.toJS() || {},
);

/**
 * @file RenderQuestions
 * Displays the questions dynamic questions while raising a dispute based on the users inputs
 */

import React, { memo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { DropdownContent, DropdownWrapper, FormWithSingleInput, Loader } from '../../../components';
import { isNilOrEmpty } from '../../../utils';

import { getQuestionsInfo, modifyQuestionsInfo, getCreditCardQuestionsInfoAction } from '../actions';
import { makeSelectQuestionList } from '../selectors';

const RenderQuestionValue = (props) => {
    const { item, idx, parentProps } = props;
    const {
        ticketId, userId, index, creditCardTransaction,
    } = parentProps;

    const dispatch = useDispatch();

    const onValueChange = (ansOpt) => () => {
        dispatch(modifyQuestionsInfo({
            ticketId, code: item.code, answer: ansOpt, dd: false,
        }));

        const payload = {
            ticketId,
            userId,
            index,
            questionIndex: idx,
            answer: ansOpt,
        };

        if (creditCardTransaction) {
            dispatch(getCreditCardQuestionsInfoAction({ ...payload, questionCode: item.code }));
        } else {
            dispatch(getQuestionsInfo({ ...payload, id: item.code }));
        }
    };

    /**
     * DROPDOWN = 1;
     * TEXT = 2;
     * AMOUNT = 3;
     * DATE = 4;
     * NUMBER = 5;
     * NO_INPUT = 6;
     */
    switch (item.answerType) {
        case 1: {
            return (
                <DropdownWrapper
                    visible={item.isDDOpen}
                    onOutsideClickHandler={() => dispatch(modifyQuestionsInfo({
                        ticketId, code: item.code, answer: item.answer, dd: false,
                    }))}
                    extClasses={{
                        container: 'custom-dd',
                    }}
                >
                    <button
                        type='button'
                        className='txns-dd__cta'
                        onClick={() => dispatch(modifyQuestionsInfo({
                            ticketId, code: item.code, answer: item.answer, dd: true,
                        }))}
                    >
                        {item.answer || 'Choose...'}
                    </button>
                    <DropdownContent
                        visible
                        extClasses={{
                            container: ['custom-dd__cc height-transition', item.isDDOpen && 'custom-dd__cc--ac'],
                        }}
                    >
                        {item.answerOptions.map((ansOpt) => (
                            <button
                                type='button'
                                key={ansOpt}
                                className='custom-dd__cl'
                                onClick={onValueChange(ansOpt)}
                            >
                                {ansOpt}
                            </button>
                        ))}
                    </DropdownContent>
                </DropdownWrapper>
            );
        }

        case 2:
        case 3:
        case 4:
        case 5: {
            return (
                <FormWithSingleInput
                    hideErrLabel
                    disableFormSubmitOnEnter
                    extClasses={{
                        container: 'txns-form-container--ml20',
                    }}
                    labelId={item.code}
                    input={{
                        value: item.answer,
                        onChange: (e) => {
                            dispatch(modifyQuestionsInfo({ ticketId, code: item.code, answer: e.target.value }));
                        },
                    }}
                />
            );
        }

        /*
        case 4: {
            return (
                <FormWithSingleInput
                    hideErrLabel
                    disableFormSubmitOnEnter
                    extClasses={{
                        container: 'txns-form-container--ml20'
                    }}
                    labelId={item.code}
                    formType='dob'
                    input={{
                        value: item.answer,
                        onChange: (val) => {
                            dispatch(modifyQuestionsInfo({ ticketId, code: item.code, answer: val }));
                        },
                        maxDate: new Date(),
                        minDate: new Date('10-01-2020'),
                    }}
                />
            );
        }
        */

        case 6:
        default: {
            return null;
        }
    }
};

const RenderQuestions = (props) => {
    const { parentProps } = props;
    const { ticketId } = parentProps;

    const questionList = useSelector(makeSelectQuestionList, shallowEqual);

    let questionData;
    let questionLoading;

    if (questionList[ticketId]) {
        ({ data: questionData, loading: questionLoading } = questionList[ticketId]);
    }

    if (isNilOrEmpty(questionData)) return null;

    return (
        <React.Fragment>
            <div className='txns-rdc-qc'>
                {questionData.map((item, idx) => (
                    <div className='txns-rdc-qw' key={item.code}>
                        <div className='txns-rdc-qw__qs'>{`${idx + 1}. ${item.question}${!item.isOptional ? '*' : ''}`}</div>
                        <RenderQuestionValue
                            item={item}
                            idx={idx}
                            parentProps={parentProps}
                        />
                    </div>
                ))}
            </div>
            <Loader visible={questionLoading} />
        </React.Fragment>
    );
};

export default memo(RenderQuestions);

/*
 *
 * TicketSummary constants
 *
 */

export const GET_TICKET_INFO = 'app/TicketSummary/GET_TICKET_INFO';
export const SET_TICKET_INFO = 'app/TicketSummary/SET_TICKET_INFO';
export const ERR_TICKET_INFO = 'app/TicketSummary/ERR_TICKET_INFO';
export const GET_TICKET_NOTES = 'app/TicketSummary/GET_TICKET_NOTES';
export const SET_TICKET_NOTES = 'app/TicketSummary/SET_TICKET_NOTES';
export const ERR_TICKET_NOTES = 'app/TicketSummary/ERR_TICKET_NOTES';
export const SET_TICKET_ID = 'app/TicketSummary/SET_TICKET_ID';

export const INFO_LABEL_MAP = [
    {
        key: 'createdTs',
        label: 'created at',
    },
    {
        key: 'type',
        label: 'type',
    },
    {
        key: 'tags',
        label: 'tags',
    },
    {
        key: 'productCategory',
        label: 'product category',
    },
    {
        key: 'productCategoryDetails',
        label: 'product category details',
    },
    {
        key: 'productSubCategory',
        label: 'product sub-category',
    },
    {
        key: 'txnType',
        label: 'transaction type',
    },
    {
        key: 'txnId',
        label: 'transaction id',
    },
    {
        key: 'disputeStatus',
        label: 'dispute status',
    },
    {
        key: 'createdTs',
        label: 'created at',
    },
    {
        key: 'actorId',
        label: 'actor id',
    },
    {
        key: 'status',
        label: 'status',
    },
    {
        key: 'os',
        label: 'OS',
    },
    {
        key: 'appVersion',
        label: 'app version',
    },
];

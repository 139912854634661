import { createSelector } from 'reselect';

/**
 * Direct selector to the agentListing state domain
 */
export const selectAgentListingDomain = () => (state) => state.get('agentListing');

/**
 * Other specific selectors
 */


/**
 * Default selector used by AgentListing
 */

const makeSelectAgentListing = () => createSelector(
  selectAgentListingDomain(),
  (substate) => substate.toJS()
);

export default makeSelectAgentListing;

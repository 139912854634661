/**
 *
 * CX disputes landing page
 * Displays all CX disputes for a given time range
 * Admin can search disputes based on optional filters
 * Admin can update disputes
 *
 */

import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';

import { Loader, DateRangePicker, Button, FormDropdown } from '../../components';
import { injectReducer, injectSaga } from '../../utils';
import { useMemoizedSelector } from '../../hooks';
import { MetaInfoContext } from '../../context';

import SearchCard from './SearchCard';
import SearchResults from './SearchResults';
import SearchResultsV2 from './SearchResultsV2';

import { getDisputesInfoActionForUser } from './actions';
import { selectDisputesInfo, selectDisputesInfoForActor } from './selectors';
import {
    INITIAL_DATE_RANGE, dateInfo, DATE_RANGE_KEY, DISPUTES, DISPUTE_ESCALATION_MODE_OPTIONS,
} from './constants';

import reducer from './reducer';
import saga from './saga';
import './style.scss';

const Disputes = (props) => {
    const {
        ticketId, userId, index, isDisputeInfoForActor,
    } = props;

    const dispatch = useDispatch();

    const metaInfo = useContext(MetaInfoContext);

    /**
     * Denotes which flow of disputes to be rendered. Value is stored in consul.
     * disputeFlowVersion = 1 is for the old flow where we show only 3 options in the table dropdown
     * disputeFlowVersion = 2 is for the new flow with DMP integration which has more than 3 options in the dropdown
     * Once DMP is live on prod switch the value from 1 to 2
     * Changing the version renders a different React Component for SearchResults component. For DMP flow, it renders SearchResultsV2
     * After DMP is stable, we can remove SearchResults component and just keep SearchResultsV2
     */
    const {
        componentVersions: {
            disputeFlowVersion,
        },
    } = metaInfo;

    // global state
    const disputesInfo = useMemoizedSelector(selectDisputesInfo, [ticketId]);
    const disputesInfoForActor = useMemoizedSelector(selectDisputesInfoForActor, [ticketId]);

    const [fromDate, setFromDate] = useState(INITIAL_DATE_RANGE.fromDate);
    const [toDate, setToDate] = useState(INITIAL_DATE_RANGE.toDate);
    const [selectedEscalationMode, setSelectedEscalationMode] = useState();
    const [formErrLabel, setFormErrLabel] = useState('');

    let loading;
    let apiErr;

    if (disputesInfo) {
        ({ loading, err: apiErr } = disputesInfo);
    }

    if (disputesInfoForActor) {
        ({ loading, err: apiErr } = disputesInfoForActor);
    }

    const handleSearchDisputesForActor = () => {
        if (!fromDate.value || !toDate.value) {
            setFormErrLabel('Please fill all the * marked fields');
            return;
        }
        setFormErrLabel('');

        const payload = {
            fromDate: fromDate.value,
            toDate: toDate.value,
            escalationMode: selectedEscalationMode ? selectedEscalationMode.map((selectedEscmode) => selectedEscmode.value) : [],
        };

        const data = {
            ticketId,
            index,
            payload,
        };

        dispatch(getDisputesInfoActionForUser(data));
    };

    const renderFormMultiSelectDD = (key) => {
        let label;
        let placeholder;
        let value;
        let options;
        let onChange;

        switch (key) {
            case 'escalation': {
                label = 'Escalation Mode';
                placeholder = 'select multiple areas if required';
                value = selectedEscalationMode;
                options = DISPUTE_ESCALATION_MODE_OPTIONS;
                onChange = (ddValue) => {
                    setSelectedEscalationMode(ddValue);
                };
                break;
            }

            default:
                label = 'Default';
                options = [];
        }

        return (
            <div className='frcWrapper'>
                <div className='disputes-dd__lb'>{label}</div>
                <FormDropdown
                    isMulti
                    options={options}
                    input={{
                        value,
                        onChange,
                        placeholder,
                    }}
                    cacheKey='agentform-accesslevel'
                    extStyles={{
                        container: 'create-agent-dd',
                    }}
                />
            </div>
        );
    };

    // search for disputes only on first render
    useEffect(() => {
        handleSearchDisputesForActor();
    }, []);

    return (
        <div className='disputes'>
            {
                /**
                 * isDisputeForActor prop is used for differentiating
                 * between dispute flow for all disputes or for specific actor
                */
                isDisputeInfoForActor ? (
                    <React.Fragment>
                        <div className='fcfsWrapper'>
                            <div className='frfssbWrapper disputes-inp'>
                                <DateRangePicker
                                    key={DATE_RANGE_KEY}
                                    dateInfo={dateInfo}
                                    fromDate={fromDate}
                                    setFromDate={setFromDate}
                                    toDate={toDate}
                                    setToDate={setToDate}
                                />
                            </div>

                            <div className='disputes-inp'>
                                {renderFormMultiSelectDD('escalation')}
                            </div>

                            <Button
                                v2
                                extClasses='disputes-sc__cta disputes-inp'
                                primary
                                label='Search'
                                onClick={handleSearchDisputesForActor}
                            />
                        </div>

                        {
                            formErrLabel ? (
                                <div className='error-label'>{formErrLabel}</div>
                            ) : null
                        }
                    </React.Fragment>
                ) : (
                    <SearchCard
                        ticketId={ticketId}
                        userId={userId}
                        index={index}
                    />
                )
            }

            {/* If DMP flow, render new Search results component else fallback to pervious one */}
            {disputeFlowVersion === 2 ? (
                <SearchResultsV2
                    ticketId={ticketId}
                    userId={userId}
                    index={index}
                />
            ) : (
                <SearchResults
                    ticketId={ticketId}
                    userId={userId}
                    index={index}
                />
            )}

            {apiErr && <div className='disputes-hd1'>{apiErr}</div>}

            <Loader visible={loading} />
        </div>
    );
};

const withReducer = injectReducer({ key: DISPUTES, reducer });
const withSaga = injectSaga({ key: DISPUTES, saga });

export default compose(
    withReducer,
    withSaga,
)(Disputes);

/*
 *
 * Liveness review reducer
 *
 */

import { fromJS } from 'immutable';

import {
    GET_LIVENESS_CASES, SET_LIVENESS_CASES, ERR_LIVENESS_CASES, VIEW_ALL_LIVENESS_CASES,
    SET_LIVENESS_VIEW, GET_LIVENESS_REVIEW_VIDEO, SET_LIVENESS_REVIEW_VIDEO, SET_LIVENESS_PAYLOAD_TYPE,
    PAYLOAD_DD_OG_STATE, SET_CUR_LIVENESS_CASE, SET_LIVENESS_PENDING_REVIEW_COUNT, INITIAL_DATE_RANGE,
    SORT_DD_OG_STATE, SET_LIVENESS_QUEUE_FILTERS, SET_LIVENESS_L2_ANNOTATION_DETAILS,
} from './constants';

const initialState = fromJS({
    livenessCasesList: [],
    curLivenessCase: {},
    livenessView: VIEW_ALL_LIVENESS_CASES,
    livenessVideoUrl: '',
    loading: false,
    err: '',
    livenessPayloadType: PAYLOAD_DD_OG_STATE,
    pendingReviewCount: '',
    l2AnnotationDetails: null,
    queueFilters: {
        fromDate: INITIAL_DATE_RANGE.fromDate,
        toDate: INITIAL_DATE_RANGE.toDate,
        sortOption: SORT_DD_OG_STATE,
    },
});

function livenessReviewReducer(state = initialState, action) {
    switch (action.type) {
        case GET_LIVENESS_CASES:
            return state
                .set('loading', true)
                .set('err', '');

        case GET_LIVENESS_REVIEW_VIDEO:
            return state
                .set('loading', true)
                .set('livenessVideoUrl', '')
                .set('err', '');

        case SET_LIVENESS_CASES: {
            const livenessCasesList = action.data;

            return state
                .set('loading', false)
                .set('livenessCasesList', fromJS(livenessCasesList));
        }

        case ERR_LIVENESS_CASES: {
            const { err } = action.data;

            return state
                .set('loading', false)
                .set('err', err);
        }

        case SET_LIVENESS_VIEW: {
            return state.set('livenessView', action.data);
        }

        case SET_LIVENESS_REVIEW_VIDEO: {
            const { videoUrl } = action.data;

            return state
                .set('loading', false)
                .set('livenessVideoUrl', videoUrl);
        }

        case SET_LIVENESS_PAYLOAD_TYPE: {
            const livenessPayloadType = action.data;

            return state
                .set('livenessPayloadType', fromJS(livenessPayloadType));
        }

        case SET_CUR_LIVENESS_CASE: {
            const curLivenessCase = action.data;

            return state.set('curLivenessCase', fromJS(curLivenessCase));
        }

        case SET_LIVENESS_PENDING_REVIEW_COUNT: {
            return state
                .set('pendingReviewCount', action.data);
        }

        case SET_LIVENESS_QUEUE_FILTERS: {
            const { key, value } = action.data;

            return state
                .setIn(['queueFilters', key], fromJS(value));
        }

        case SET_LIVENESS_L2_ANNOTATION_DETAILS: {
            const l2AnnotationDetails = action.data;

            return state
                .set('l2AnnotationDetails', fromJS(l2AnnotationDetails));
        }

        default:
            return state;
    }
}

export default livenessReviewReducer;

/**
 * @file RecentActivity/constants.js - Contains selectors used for recent activity
 */
import { createSelector } from 'reselect';
import { RECENT_ACTIVITY_ROOT_REDUX } from './constants';

const selectRecentActivityData = createSelector(
    (state) => state.get(RECENT_ACTIVITY_ROOT_REDUX),
    (subState) => subState.toJS(),
);

export {
    selectRecentActivityData,
};

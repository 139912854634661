/**
*
* GeneralKVPairInfo - Component to show the object's key-value pair info with links as a component
*
* Expects data in the form of an array of label value pair:
* {
*   label: '',
*   value: {
*       text: '',
*       link: '',
*   },
* }
*/

import React, { memo, useContext } from 'react';
import classNames from 'classnames';

import backArrow from '../../assets/images/svgs/back-arrow.svg';

import { isNilOrEmpty } from '../../utils';
import { MetaInfoContext } from '../../context';

import CopyToClipboard from '../CopyToClipboard';

import './style.scss';

const RenderValue = (props) => {
    const { parentProps, item, onClick } = props;
    const { extClasses } = parentProps;

    const contentValueClasses = classNames('general-kvpair-info-cwr__cv', extClasses && extClasses.contentValue);

    const {
        value: {
            text, link,
        },
        label: id,
    } = item;

    if (isNilOrEmpty(item)) return null;

    if (link && text) {
        return (
            <div className={contentValueClasses} role='presentation'>
                <a
                    href={link}
                    target='_blank'
                    rel='noreferrer'
                    onClick={onClick(id)}
                >{text}
                </a>
            </div>
        );
    }
    return (
        <div className={contentValueClasses} onClick={onClick(id)} role='presentation'>
            {!isNilOrEmpty(text) ? text : '-'}
            {item?.allowCopyText ? <CopyToClipboard text={text} /> : null}
        </div>
    );
};

const GeneralKVPairInfo = (props) => {
    const {
        title, onBackArrowClickHandler, data, extClasses, onClick = () => {},
    } = props;
    const metaInfo = useContext(MetaInfoContext);
    const { componentVersions: { banners: bannersVersion } } = metaInfo;

    const containerClasses = classNames(
        { [`general-kvpair-info-cr-v${bannersVersion}`]: bannersVersion === 1, fccWrapper: bannersVersion === 2 },
        extClasses && extClasses.container,
    );
    const titleContainerClasses = classNames(
        `general-kvpair-info-hwr-v${bannersVersion}`,
        extClasses && extClasses.titleContainer,
        'frcWrapper',
    );
    const titleHeadingClasses = classNames(`general-kvpair-info-hwr-v${bannersVersion}__hd`, extClasses && extClasses.titleHeading);
    const contentWrClasses = classNames('general-kvpair-info-cwr', extClasses && extClasses.contentWr);
    const contentLabelClasses = classNames('general-kvpair-info-cwr__cl', extClasses && extClasses.contentLabel);

    if (isNilOrEmpty(data)) return null;

    return (
        <div className={containerClasses}>
            {
                (title || onBackArrowClickHandler) && (
                    <div className={titleContainerClasses}>
                        {
                            onBackArrowClickHandler && (
                                <img
                                    className='general-kvpair-info-backarrow'
                                    alt='back arrow'
                                    src={backArrow}
                                    onClick={onBackArrowClickHandler}
                                    role='presentation'
                                />
                            )
                        }
                        {
                            title && (
                                <div className={titleHeadingClasses}>{title}</div>
                            )
                        }
                    </div>
                )
            }
            {
                Array.isArray(data) && data.map((item) => (
                    <div className={contentWrClasses} key={item?.label}>
                        <div className={contentLabelClasses}>{item?.label}</div>
                        <RenderValue
                            parentProps={props}
                            item={item}
                            onClick={onClick}
                        />
                    </div>
                ))
            }
        </div>
    );
};

export default memo(GeneralKVPairInfo);
